import React, { useEffect, useState } from "react";
import {
  FormControl,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  LinearProgress,
} from "@material-ui/core";
import {
  PhoneAndroid,
  CameraAlt,
  Visibility,
  CloudUpload,
} from "@material-ui/icons";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";

import { Button, Col, Row } from "react-bootstrap";

import personal from "assets/images/f1-01.svg";
import pan from "assets/images/PAN Black-01.svg";

import Moment from "react-moment";
import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import { getTokenCookie, showToast } from "utils/tools";
import { Translate } from "react-redux-i18n";
import moment from "moment";
import uploadFileAPI from "utils/fileupload";

const PersonalDetailsFromSevasindhu = ({
  labourData,
  setIsFileUploadedId,
  fileUploadId,
  aadharVerified,
}) => {
  const [labourCardFile, setLabourCardFile] = useState(null);
  const [uploadError, setUploadError] = useState("");
  const [fileId, setFileId] = useState(null);
  const [fileUploading, setFileUploading] = useState(false);

  useEffect(() => {
    if (fileUploadId) {
      setFileId(fileUploadId);
    }
  }, [fileUploadId]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      // Validate file type (e.g., PDF, PNG, JPG)
      const allowedTypes = ["application/pdf", "image/png", "image/jpeg"];
      if (!allowedTypes.includes(file.type)) {
        setUploadError("Only PDF, PNG, or JPG files are allowed.");
        return;
      }
      setLabourCardFile(file);
      setUploadError("");
    }
  };

  const handleFileUpload = async () => {
    if (!labourCardFile) {
      setUploadError("Please select a file to upload.");
      return;
    }
    try {
      setFileUploading(true);
      const labourCard = await uploadFileAPI(
        labourCardFile,
        labourCardFile.name,
        labourData.id,
        "labourCard"
      );
      setFileUploading(false);
      setFileId(labourCard?.image?.fileId);
      setIsFileUploadedId(labourCard?.image?.fileId);
      showToast("SUCCESS", "Labour card uploaded successfully");
    } catch (error) {
      showToast("ERROR", "An error occurred while uploading, Please try again");
    }
  };

  const resetFileUpload = () => {
    setLabourCardFile(null);
    setFileId(null);
    setUploadError("");
    setIsFileUploadedId(null);
  };

  const showFileInNewTab = () => {
    window.open(
      `${SERVER}/upload/v2/file/${fileId}?token=${getTokenCookie()}`,
      "_blank"
    );
  };

  return (
    <>
      <Row>
        <Col>
          <Row className='final all-details-subtitle-row'>
            <Col xs={12} md={12} className='profile-title'>
              <img alt='...' src={personal} />
              <h1>Personal Details as per Labour Card</h1>
            </Col>
          </Row>

          <Row className='px-2'>
            {fileId ? (
              <Col md={12} className='final-personal-col-1 px-3 '>
                <p className='mb-2 text-success'>
                  Labour Card Uploaded Successfully
                </p>
                <FormControl fullWidth>
                  <div className={`upload-box-success`}>
                    <label
                      htmlFor='file-upload'
                      onClick={showFileInNewTab}
                      className={`file-success`}
                    >
                      {"View File"}
                    </label>
                    <Button
                      variant='outline-danger'
                      size='sm'
                      onClick={resetFileUpload}
                      disabled={aadharVerified}
                    >
                      {"Remove File"}
                    </Button>
                  </div>
                </FormControl>
              </Col>
            ) : (
              <Col md={12} className='final-personal-col-1 px-3'>
                <p className='mb-2'>Upload Your Labour Card</p>
                <FormControl fullWidth>
                  <div
                    className={`upload-box ${fileUploading ? "loading" : ""}`}
                  >
                    <input
                      id='file-upload'
                      type='file'
                      accept='.pdf,.png,.jpg,.jpeg'
                      onChange={handleFileChange}
                      className='upload-input'
                    />
                    <label
                      htmlFor='file-upload'
                      className={`file-label ${
                        fileUploading ? "disabled" : ""
                      }`}
                    >
                      {fileUploading ? (
                        "Uploading Labour card..."
                      ) : labourCardFile && !fileUploading ? (
                        <div>
                          <div>{labourCardFile?.name}</div>
                        </div>
                      ) : (
                        <>
                          <CloudUploadOutlinedIcon className='mr-2' /> Upload
                          Labour Card
                        </>
                      )}
                    </label>
                    <Button
                      variant='outline-success'
                      size='sm'
                      onClick={handleFileUpload}
                      disabled={fileUploading}
                    >
                      {fileUploading ? "Uploading..." : "Upload File"}
                    </Button>
                  </div>
                  {fileUploading ? <LinearProgress /> : null}

                  {uploadError && (
                    <div className='error-text'>{uploadError}</div>
                  )}
                </FormControl>
              </Col>
            )}

            <Col md={12} className='final-personal-col-1 px-3 mt-4'>
              <p className='mb-0'>Name</p>
              <FormControl fullWidth>
                <TextField
                  name='firstname'
                  value={`${labourData?.applicantname || ""}`}
                  disabled
                />
              </FormControl>
            </Col>

            <Col md={12} className='final-personal-col-1 px-3 mt-4'>
              <p className='mb-0'>Mobile Number </p>
              <FormControl fullWidth className='formcontrol2'>
                <TextField
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start' className='mb-1 mt-1'>
                        <PhoneAndroid className='phone-icon' />
                        +91
                      </InputAdornment>
                    ),
                  }}
                  value={labourData?.mobileno || ""}
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='px-2 mt-3'>
            <Col md={12} className='final-personal-col-1 px-3 mt-4'>
              <p className='mb-0'>Date Of Birth</p>
              <FormControl fullWidth>
                <TextField
                  name='dob'
                  value={`${
                    moment(labourData?.dob).format("DD-MM-YYYY") || ""
                  }`}
                  disabled
                />
              </FormControl>
            </Col>
            <Col md={12} className='final-personal-col-1 px-3 mt-4'>
              <p className='mb-0'>Age</p>
              <FormControl fullWidth>
                <TextField
                  name='age'
                  value={
                    labourData?.dob
                      ? `${moment().diff(
                          moment(labourData?.dob),
                          "years"
                        )} years, ${moment().diff(
                          moment(labourData?.dob).add(
                            moment().diff(moment(labourData?.dob), "years"),
                            "years"
                          ),
                          "months"
                        )} months, ${moment().diff(
                          moment(labourData?.dob)
                            .add(
                              moment().diff(moment(labourData?.dob), "years"),
                              "years"
                            )
                            .add(
                              moment().diff(
                                moment(labourData?.dob).add(
                                  moment().diff(
                                    moment(labourData?.dob),
                                    "years"
                                  ),
                                  "years"
                                ),
                                "months"
                              ),
                              "months"
                            ),
                          "days"
                        )} days`
                      : ""
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>
          <Row className='px-2 mt-3'>
            <Col md={12} className='final-personal-col-1 px-3 mt-4'>
              <p className='mb-0'>Gender</p>
              <FormControl fullWidth>
                <TextField
                  name='dob'
                  value={`${
                    labourData?.gender == 1
                      ? "MALE"
                      : labourData?.gender == 2
                        ? "FEMALE"
                        : ""
                  }`}
                  disabled
                />
              </FormControl>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default PersonalDetailsFromSevasindhu;
