import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigBoardDetails, getConfigMinistryDetails, getLabourStatusDetails, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { Required } from 'utils/tools';
import { PhoneAndroid } from '@material-ui/icons';


const BoardMasterForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        boardName: "",
        short_name: "",
        code: "",
        description: "",
        address: "",
        phone_no: "",
        alternate_phone_no: "",
        fax: "",
        website: "",
    })


    useEffect(() => {
        // console.log("in useEffect [users.user]")
        // dispatch(getConfigBoardDetails())

        if(props.location.boardid !== undefined && props.location.boardid !== null && users.getConfigBoardDetails !== undefined && users.getConfigBoardDetails.length > 0){

            // console.error("props.location.ministryid: "+props.location.ministryid)
            // console.error("users.getConfigMinistryDetails: "+JSON.stringify(users.getConfigMinistryDetails))

            var index = users.getConfigBoardDetails.map(
                        function(e) {return e.id;}
                        ).indexOf(props.location.boardid)
            

            var val = users.getConfigBoardDetails[index]

            setAllValues({
                ...allValues,
                boardName: val.name,
                short_name: val.short_name,
                code: val.code,
                description: val.description,
                address: val.address,
                phone_no: val.phone_no,
                alternate_phone_no: val.alternate_phone_no,
                fax: val.fax,
                website: val.website,
            })   
        }
    }, []);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })   
    }

    const resetButtonPressed = () => {
        handleClickOpen()
    }

    const saveButtonPressed = () => {
        handleClickOpenSave()
    }

    const [open, setOpen] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleYes = () => {
        setAllValues({
            ...allValues,
            boardName: "",
            short_name: "",
            code: "",
            description: "",
            address: "",
            phone_no: "",
            alternate_phone_no: "",
            fax: "",
            website: "",
        })   
        handleClose()
    };

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };

    const handleCloseSave = () => {
        setOpenSave(false);
    };
    
    const handleYesSave = () => {
        console.error("in useEffect props:"+props.location.userid )

        // if (props.location.userid !== undefined && props.location.userid !== null) {

        //     let dataForRegDetails = {
        //         "board_id": 6,
        //         "name": "Karnataka Building And Other Construction Workers’ Welfare Board Modified",
        //         "code": "KBOCWWB 2",
        //         "short_name": "KBOCWWB 2",
        //         "description": "Karnataka Building And Other Construction Workers’ Welfare Board Modified",
        //         "address": "Banglore",
        //         "phone_no": "null",
        //         "alternate_phone_no": null,
        //         "fax": null,
        //         "website": null,
        //         "user_id": 3
        //     }
        //     dispatch(updateBoardMaster(dataForRegDetails))

            
        // }
        handleCloseSave()
    };


    return (
        <>
            <div className="root">

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Reset the form?"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    This will clear all the data.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleYes} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // fullScreen={fullScreen}
                open={openSave}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Save Changes"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to save the changes made?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseSave} variant="outlined" color="secondary">
                    No
                </Button>
                <Button onClick={handleYesSave} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Board</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Board Master</p>
                    </Link>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Board Name" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Board Name"
                                name="boardName"
                                value={allValues.boardName}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Short Name" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Short Name"
                                name="short_name"
                                value={allValues.short_name}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Code" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Code"
                                name="code"
                                value={allValues.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={6} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Description" />
                        <FormControl fullWidth className="formcontrol1">
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    // rowsMax={4}
                                    // defaultValue="Default Value"
                                    placeholder="Enter Description of Board"
                                    name="description"
                                    value={allValues.description}
                                    onChange={handleChange}
                                />
                            </FormControl>

                    {/* </div> */}
                </Col>
                <Col xs={12} md={6} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Address" />
                        <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                    }
                                }}>
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    // rowsMax={4}
                                    // defaultValue="Default Value"
                                    placeholder="Enter Address of Board"
                                    name="address"
                                    value={allValues.address}
                                    onChange={handleChange}
                                />
                            </FormControl>

                    {/* </div> */}
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Phone Number" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol2">
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={mobile} alt="..." className="phone-icon" />
                                                <p className="countrycode">+91</p>
                                            </InputAdornment>
                                    }}
                                    variant="outlined"
                                    name="phone_no"
                                    value={allValues.phone_no}
                                    onChange={handleChange}
                                />
                            </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <p className="mb-2 mt-3">Alternate Phone Number</p> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Alternate Phone Number"
                                name="alternate_phone_no"
                                value={allValues.alternate_phone_no}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <p className="mb-2 mt-3">Website</p> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Website"
                                name="website"
                                value={allValues.website}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>

                <Col xs={12} className="next-back-button-row mt-4">

                                    <Link to="#" 
                                        onClick={resetButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            Reset
                                        </Button>
                                    </Link>

                                    <Link to="#" 
                                    onClick={saveButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="save-button">
                                            Save
                                        </Button>
                                    </Link>
                    
                </Col>


            </Row>
        </>
    )
}

export default BoardMasterForm;