import React from 'react';
import { Button } from 'react-bootstrap';

const Approve = ({ handleApproveRejectTransaction, loading }) => {
  return (
    <Button
      onClick={() => handleApproveRejectTransaction('Approve')}
      variant="outline-primary"
      className="final-button-accept"
      disabled={loading}
    >
      Approve
    </Button>
  );
};

export default Approve;
