import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import uploadFileAPI from 'utils/fileupload';
import { getTokenCookie } from 'utils/tools';

export const documentUploadTransferBack = (
  schemeAvailID,
  schemeTransactionID,
  remarks,
  verificationDoc,
  deptUserID,
) => {
  return new Promise((resolve, reject) => {
    if (verificationDoc && verificationDoc.length > 0) {
      Promise.all(verificationDoc.map((doc) => uploadFileAPI(doc)))
        .then((promiseResponse) => {
          const verificationResponse = promiseResponse.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));
          console.log(verificationResponse);
          const payLoad = {
            availedId: +schemeAvailID,
            availedTransactionId: +schemeTransactionID,
            remarks: remarks,
            verificationDocuments: verificationResponse,
            departmentUserId: +deptUserID,
          };
          const result = TransferBackAPI(payLoad);
          resolve(result);
        })
        .catch((error) => {
          console.error('Error:', error);
          reject(error);
        });
    }
  });
};

const TransferBackAPI = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/transfer/back`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
