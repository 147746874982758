import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import MISGridRegRen from './MISGridRegRen'
import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead, TextField, InputAdornment, Select, Backdrop, makeStyles, FormControl, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, SERVER, setLocaleWithFallback, loginAdmin, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'

import MISGridScheme from './MISGridScheme';

const NEWMISReport = () => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    console.log("Users",users);

    const [reportSelect,setReportSelect] = useState("")
    const [regSelect,setRegSelect] = useState("")
    const [activeSelect,setActiveSelect] = useState("")
    const [freezeSelect,setFreezeSelect] = useState("")
    const [search,setSearch] = useState(false)


    const handleReportChange = (e) => {
        setReportSelect(e.target.value)
    }


    const handleChange = (e) => {
        console.log(e.target.value);
        setRegSelect(e.target.value);

    }
    const handleChange2 = (e) => {
        console.log(e.target.value);
        setActiveSelect(e.target.value);

    }
    const handleChange3 = (e) => {
        console.log(e.target.value);
        setFreezeSelect(e.target.value);

    }
    const callMISAPI = () => {
        setSearch(!search)
    }
  return (
    <div>
        <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            
                            <div className="logo-div-profile">
                                
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                
                                <h1 className="logo-text">
                                    Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB) <br />
                                    Government of Karnataka
                                </h1>
                            </div>

                        </Col>

                        
                    </Row>

                </Row>
                
                <Row className='d-flex justify-content-center align-items-center container-fluid'>
                    <Col xs={3} className="mb-2 mt-4">
                        
                        <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={reportSelect}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleReportChange}
                                
                            >
                                <MenuItem value="" >
                                    <ListItemText primary="Select Reports type" />
                                </MenuItem>
                                <MenuItem value={"100"} >
                                    <ListItemText primary={"Registration / Renewal Reports"} />
                                </MenuItem>
                                <MenuItem value={"200"} >
                                    <ListItemText primary={"Scheme Reports"} />
                                </MenuItem>
                               
                            </Select>
                            </FormControl>
                        
                </Col>
                </Row>
                
                { reportSelect === "100" ?  
                <MISGridRegRen 
                deptID={users.getDeptUserRole.data[0].department_user_id && users.getDeptUserRole.data[0].department_user_id} 
                roleID={users.getDeptUserRole.data[0].role_id && users.getDeptUserRole.data[0].role_id}

                />
                : reportSelect === "200" ? 
                <MISGridScheme 
                deptID={users.getDeptUserRole.data[0].department_user_id && users.getDeptUserRole.data[0].department_user_id} 
                roleID={users.getDeptUserRole.data[0].role_id && users.getDeptUserRole.data[0].role_id}
                 
                 />
                : null
                }
    </div>
  )
}

export default NEWMISReport