import {
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@material-ui/core";

import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { SERVER } from "store/actions/user.actions";
import PopOver from "./PopOver";
const SchemeDocuments = ({ documents }) => {
  const { schemeName } = useParams();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleNewTab = (url) => {
    window.open(url, "_blank", "noopener");
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/file/${fileID}`;
    window.open(url, "_blank", "noopener");
  };

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      {schemeName !== "Pension Scheme" && (
        <>
          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Scheme Documents</p>
            </Col>
          </Row>
          <Row className='form-row mt-4 config-form searchform'>
            {documents &&
              documents.map((item) => (
                <Col xs={6} className='final-personal-col-1 px-3 mt-4'>
                  <div className=''>
                    <p className='mt-4 mb-2'>{item.document_type}</p>
                    {item &&
                      item.document_upload_file_xml.map((row) => (
                        <div className=''>
                          <FormControl fullWidth>
                            <TextField
                              value={row.file_name}
                              variant='outlined'
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    {(row.file_name.includes("png") ||
                                      row.file_name.includes("jpeg") ||
                                      row.file_name.includes("jpg")) && (
                                      <IconButton
                                        onClick={(ev) =>
                                          handleViewImage(
                                            ev,
                                            row.file_id,
                                            row.file_name
                                          )
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                    {row.file_name.includes("pdf") && (
                                      <IconButton
                                        onClick={() =>
                                          handleViewPDF(row.file_id)
                                        }
                                      >
                                        <VisibilityIcon />
                                      </IconButton>
                                    )}
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        </div>
                      ))}
                  </div>
                </Col>
              ))}
          </Row>
        </>
      )}
    </>
  );
};

export default SchemeDocuments;
