import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
} from '@material-ui/core';
import ConfirmationDialog from 'components/adminPortal/globals/ConfirmationDialog';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

const Transfer = ({
  loading,
  opentransfer,
  handleCloseTransfer,
  transferRemarks,
  circle,
  circleID,
  handleClickTransfer,
  handleTransferChange,
  handleSubmitTransfer,
}) => {
  const users = useSelector((state) => state.users);
  const currentUser = users && users.login_admin && users.login_admin.data;

  return (
    <>
      <Dialog open={opentransfer} onClose={handleCloseTransfer}>
        <DialogTitle>Transfer for Verification</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select the circle to transfer this application for
            verification.
          </DialogContentText>
          <FormControl variant="outlined" fullWidth className="formcontrol5">
            <Select
              className="select-language mt-2 mb-2"
              style={{ width: '50%' }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              value={circleID || '0'}
              name="circleID"
              displayEmpty
              onChange={handleTransferChange}
            >
              <MenuItem value="0">
                <ListItemText primary="-Select Circle-" />
              </MenuItem>
              {circle &&
                circle.length > 0 &&
                circle.map(
                  (row) =>
                    currentUser &&
                    currentUser.circle_id !== row.id && (
                      <MenuItem value={row.id}>
                        <ListItemText primary={`${row.name}`} />
                      </MenuItem>
                    ),
                )}
            </Select>
          </FormControl>
          <TextareaAutosize
            variant="outlined"
            multiline
            rows={3}
            style={{
              width: '500px',
              padding: '10px',
              marginTop: '10px',
            }}
            placeholder="Remarks"
            name="remarkTransfer"
            value={transferRemarks}
            onChange={handleTransferChange}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outline-danger" onClick={handleCloseTransfer}>
            Cancel
          </Button>
          <Button
            variant="success"
            onClick={handleSubmitTransfer}
            disabled={loading}
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        onClick={handleClickTransfer}
        variant="outline-primary"
        className="back-button"
      >
        Transfer for verification
      </Button>
    </>
  );
};

export default Transfer;
