import 'date-fns';
import PersonalDetailsForm from './personal'
import AddressForm from './address'
import FamilyDetailsForm from './family'
import BankDetailsForm from './bank' 
import ReviewPage from './review'
import { LinearProgress, Select, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Edit, Person } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import address from 'assets/images/apartment.svg'
import addressWhite from 'assets/images/apartment-white.svg'
import person from 'assets/images/person black-01.svg'
import personWhite from 'assets/images/person-01.svg'
import family from 'assets/images/family-silhouette.svg'
import familyWhite from 'assets/images/family-silhouette-white.svg'
import bank from 'assets/images/bank-building-grey.svg'
import bankRed from 'assets/images/bank-building.svg'
import certificate from 'assets/images/certificate.svg'
import certificateWhite from 'assets/images/All-01.svg'
import bankWhite from 'assets/images/bank-building-white.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getUserRegistrationDetails, setLocaleWithFallback, update90DaysData, updateBankData, updateFamilyData, updatePersonalData, updateProfileData, updateUploadFile, updateUser } from 'store/actions/user.actions'
import { Translate } from "react-redux-i18n";
import { Link } from 'react-router-dom';
import { getTokenCookie } from 'utils/tools';

const Profile = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: '',
        users: null,
        percentComplete: 0,
        editmode: false
    })

    const [firstLoad, setfirstLoad] = React.useState(true);

    useEffect(() => {
        console.log("in useEffect []- getTokenCookie() :" +getTokenCookie() )
        sessionStorage.clear()
        if (getTokenCookie() === null || getTokenCookie() === undefined || getTokenCookie() === "") {
            props.history.push("/")
        }

    }, []);

    useEffect(() => {

        if (users.user !== undefined && 
            users.user !== null && 
            users.user.board_id !== undefined &&
            users.user.board_id !== null 
            ) {
            console.error("users.user-> firstLoad: "+firstLoad)
            // if(firstLoad){
                console.error("users.user:"+JSON.stringify(users.user))
                if( users.user.tab_name !== undefined && users.user.tab_name !== null && users.user.tab_name !== "Unauthorized" && firstLoad ){
                    console.error("users.user.tab_name:"+users.user.tab_name)
                    switch (users.user.tab_name) {
                        case "personal_details":
                            setfirstLoad(false)
                            setAllValues({
                                ...allValues,
                                activestep: 2
                            })
                            break
                        case "address_details":
                            setfirstLoad(false)
                            setAllValues({
                                ...allValues,
                                activestep: 3
                            })
                            break
                        case "family_details":
                            setfirstLoad(false)
                            setAllValues({
                                ...allValues,
                                activestep: 4
                            })
                            break
                        case "bank_details":
                            setfirstLoad(false)
                            setAllValues({
                                ...allValues,
                                activestep: 5
                            })
                            break
                     
                        default :
                            setfirstLoad(false)
                            setAllValues({
                                ...allValues,
                                activestep: 5
                            })
                            break
                    }
                }
                else if(users.user.tab_name === null){
                    
                    setAllValues({
                        ...allValues,
                        activestep: 1
                    })
                    // setfirstLoad(false)
                }
                else{
                    console.error("users.user.tab_name is undefined")
                    
                }
            // }
            
        }
        else{
            props.history.push("/")
        }
    }, [users.user]);

    useEffect(() => {
        console.log("in useEffect [allValues]: " + JSON.stringify(allValues))
        dispatch(updateProfileData(allValues))
    },[allValues]);

    useEffect(() => {
        console.log("in useEffect");
        console.log("allValues.activestep: " + allValues.activestep);
        if(allValues.activestep !== undefined){
            switch (allValues.activestep) {
                case 1:
                    setAllValues({
                        ...allValues,
                        percentComplete: 0
                    })
                    break
                case 2:
                    setAllValues({
                        ...allValues,
                        percentComplete: 25
                    })
                    break
                case 3:
                    setAllValues({
                        ...allValues,
                        percentComplete: 60
                    })
                    break
                case 4:
                    setAllValues({
                        ...allValues,
                        percentComplete: 75
                    })
                    break
                case 5:
                    setAllValues({
                        ...allValues,
                        percentComplete: 100
                    })
                    break
              
                default:
                    setAllValues({
                        ...allValues,
                        percentComplete: 0
                    })
            }
        }
    }, [allValues.activestep]);

    const editButtonPressed = (step) => {
        console.log("in editButtonPressed->step:" + step)
        
        window.scrollTo(0, 0)

        setAllValues({
            ...allValues,
            activestep: step,
            percentComplete: 100,
            editmode: true
        })
    }
    const saveEditButtonPressed = () => {
        console.error("in saveEditButtonPressed")
        
        window.scrollTo(0, 0)

        setAllValues({
            ...allValues,
            activestep: 5,
            editmode: false
        })
    }

    const backButtonPressed = () => {
        console.log("in backButtonPressed-> allValues.activestep:" + allValues.activestep)
        if (allValues.activestep > 1) {
            setAllValues({
                ...allValues,
                activestep: allValues.activestep - 1,
            });
        }
        else if (allValues.activestep === 1) {
            props.history.push("/registration");
            // history.push("/registration");
        }
        window.scrollTo(0, 0)

        switch (allValues.activestep - 1) {
            case 1:
                console.log('Active Step Set to 1');
                setAllValues({
                    ...allValues,
                    percentComplete: 25,
                    activestep: 1
                })
                break
            case 2:
                setAllValues({
                    ...allValues,
                    percentComplete: 50,
                    activestep: 2
                })
                break
            case 3:
                setAllValues({
                    ...allValues,
                    percentComplete: 75,
                    activestep: 3
                })
                break;
          
            case 4:
                setAllValues({
                    ...allValues,
                    percentComplete: 100,
                    activestep: 5
                })
                break
            default:
                console.log("default")
        }
    }

    const nextButtonPressed = () => {
        console.log("in nextButtonPressed-> allValues.activestep:" + allValues.activestep)
        console.log("in nextButtonPressed-> allValues.activestep + 1:" + (allValues.activestep + 1))

        window.scrollTo(0, 0)

        if (allValues.activestep <= 5) {

            console.log("in if-> allValues.activestep:" + allValues.activestep)

            // dispatch(updateUploadFile(""))

            switch (allValues.activestep + 1) {
                case 1:
                console.log('Active Step Set to 1');
                    setAllValues({
                        ...allValues,
                        percentComplete: 30,
                        activestep: 1
                    })
                    break
                case 2:
                    setAllValues({
                        ...allValues,
                        percentComplete: 40,
                        activestep: 2
                    })
                    break
                case 3:
                    dispatch(updateUploadFile(null))
                    setAllValues({
                        ...allValues,
                        percentComplete: 60,
                        activestep: 3
                    })
                    break
                case 4:
                    dispatch(updateUploadFile(null))
                    setAllValues({
                        ...allValues,
                        percentComplete: 75,
                        activestep: 4
                    })
                    break
                case 5:
                    dispatch(updateUploadFile(null))
                    setAllValues({
                        ...allValues,
                        percentComplete: 100,
                        activestep: 5
                    })
                    break
             
                default:
                console.log('Active Step Set to 1');
                    setAllValues({
                        ...allValues,
                        percentComplete: 0,
                        activestep: 1
                    })
                    break
            }
        }
    }

    const finalSubmitButtonPressed = () => {
        console.log("in finalSubmitButtonPressed")

        // props.history.push("/review")
    }

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,

            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    return (
        <>
        
            <Row className="personal-div">
                <Row className="topbar-row">
                    <Col xs={12} md={8} lg={9} className="top-bar-col">
                        <div className="logo-div-profile">
                            <Link to="/dashboard-user-unorganized">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                            </Link>
                            <h1 className="logo-text">
                                <Translate value="header.titleungoranized" /> <br />
                                <Translate value="header.subungoranized" />
                            </h1>
                        </div>

                    </Col>
                    <Col xs={12} md={4} lg={3}>
                        <Select
                            className="select-language"
                            style={{ width: '100%' }}
                            variant="outlined"
                            labelId="demo-simple-select-required-label"
                            value={allValues.language}
                            name="language"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="" >
                                <ListItemIcon>
                                    <img alt="..." src={language} className="language-img" />
                                </ListItemIcon>
                                <ListItemText primary="Select Language" />
                            </MenuItem>
                            {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
                            <MenuItem value="en">
                                <ListItemText primary="English" />
                            </MenuItem>
                            <MenuItem value="ka">
                                <ListItemText primary="ಕನ್ನಡ" />
                            </MenuItem>
                        </Select>
                    </Col>
                </Row>

                <Row className="profile-title-row">
                    <Col xs={10} className="profile-title">
                        <h1><Translate value="unorganizedheader" /></h1>
                        {/* <h1><Translate value="profileTitle" /></h1> */}
                    </Col>
                </Row>

                <Row className="stepsdiv-above">

                    <Col xs={3} className="steps-col">
                        <div
                            className={`steps-div unorganized
                                ${(allValues.activestep === 1 || allValues.activestep === 6 ) && 'activestep'}`}
                        >
                            {/* <Person /> */}
                            {/* <div>Personal Details</div> */}
                            <img alt="..." src={(allValues.activestep === 1 || allValues.activestep === 6 ) ? personWhite : person} />
                            <div><Translate value="personalDetails" /></div>
                        </div>
                    </Col>
                    <Col xs={3} className="steps-col">
                        <div
                            className={`steps-div unorganized
                                ${(allValues.activestep === 2 || allValues.activestep === 6 ) && 'activestep'}`}
                        >
                            <img alt="..." src={(allValues.activestep === 2 || allValues.activestep === 6 ) ? addressWhite : address} />
                            <div><Translate value="address" /></div>
                        </div>
                    </Col>
                    <Col xs={3} className="steps-col">
                        <div
                            className={`steps-div unorganized
                                ${(allValues.activestep === 3 || allValues.activestep === 6 ) && 'activestep'}`}
                        >
                            <img alt="..." src={(allValues.activestep === 3 || allValues.activestep === 6 ) ? familyWhite : family} />

                            {/* <div><Translate value="familyDetails" /></div> */}
                            <div>
                            <Translate value="dependentDetails" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={3} className="steps-col">
                        <div
                            className={`steps-div unorganized
                                ${(allValues.activestep === 4 || allValues.activestep === 6 ) && 'activestep'}`}
                        >
                            {/* <img alt="..."  src={bank} /> */}
                            <img alt="..." src={(allValues.activestep === 4 || allValues.activestep === 6 ) ? bankWhite : bank} />
                            <div><Translate value="bankDetails" /></div>
                        </div>
                    </Col>

                </Row>

                <Row className="stepsdiv-title-responsive">


                    {
                        allValues.activestep === 1 &&
                        <>
                            <img alt="..." src={address} />
                            <p>Personal Details</p>
                        </>
                    }
                    {
                        allValues.activestep === 2 &&
                        <>
                            <img alt="..." src={address} />
                            <p>Residential Address</p>
                        </>
                    }
                    {
                        allValues.activestep === 3 &&
                        <>
                            <img alt="..." src={family} />
                            <p>Ration Card Details</p>
                        </>
                    }
                    {
                        allValues.activestep === 4 &&
                        <>
                            <img alt="..." src={bankRed} />
                            <p>Bank Details</p>
                        </>
                    }

                </Row>

                {
                        allValues.activestep === 5 &&
                        <Row className="all-details-title-row">
                            <Col xs={12} className="profile-title">
                            <h1><Translate value="findAllDetails" /></h1>
                            {/* </Col> */}
                            {/* <Col xs={2} className="profile-title"> */}
                                {/* <p>Edit your details<Edit /></p> */}
                            </Col>
                        </Row>
                }

                {
                    users.profile_details.editmode ?
                    null
                    :
                    <Row className={`progress-row ${allValues.activestep !== 4 ? 'sticky':null}`}>
                        <Col sm={12}>
                            <div className="progress-text-div">
                                <div className="progress-title"><Translate value="registration" /></div>
                                <div className="progress-percent">{allValues.percentComplete}% <Translate value="complete"/></div>
                            </div>
                            <LinearProgress variant="determinate"
                                value={allValues.percentComplete} className="progress" />
                        </Col>
                    </Row>
                }

                

                <Row className="m-0 p-0 form-component-row">
                    {allValues.activestep === 1 &&
                        <PersonalDetailsForm backButtonPressed={backButtonPressed}
                        saveEditButtonPressed={saveEditButtonPressed}
                            nextButtonPressed={nextButtonPressed} {...props}/>}

                    {allValues.activestep === 2 &&
                        <AddressForm backButtonPressed={backButtonPressed}
                        saveEditButtonPressed={saveEditButtonPressed}
                            nextButtonPressed={nextButtonPressed} {...props} />}

                    {allValues.activestep === 3 &&
                        <FamilyDetailsForm backButtonPressed={backButtonPressed}
                        saveEditButtonPressed={saveEditButtonPressed}
                            nextButtonPressed={nextButtonPressed} {...props} />}

                    {allValues.activestep === 4 &&
                        <BankDetailsForm backButtonPressed={backButtonPressed}
                        saveEditButtonPressed={saveEditButtonPressed}
                            nextButtonPressed={nextButtonPressed} {...props} />}


                    {allValues.activestep === 5 &&
                        <ReviewPage backButtonPressed={backButtonPressed}
                        editButtonPressed={editButtonPressed}
                        finalSubmitButtonPressed={finalSubmitButtonPressed} 
                        {...props} />}
                </Row>

            </Row>

        </>
    )
}

export default Profile;