import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SERVER } from 'store/actions/user.actions'
import { getTokenCookie } from 'utils/tools'
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Paper from '@mui/material/Paper';

const DashboardRegistration = () => {
  const users = useSelector(state => state.users)


  const [registrationCatalog,setRegistrationCatalog] = useState(null)
  const [registrationStatus,setRegistrationStatus] = useState(null)
  const [isDateSelected,setIsDateSelected] = useState(0)
  const [startDate,setStartDate] = useState(null)
  const [endDate,setEndDate] = useState(null)
  const [applicationNumber,setApplicationNumber] = useState(null)
  const [mobileNumber,setMobileNumber] = useState(null)
  const [aadhaarNumber,setAadhaarNumber] = useState(null)


  useEffect(() => {
    const payLoad = {
      "board_id": 1,
      "catalog_name": "Registration Status"
    }
    axios.post(`${SERVER}/global/get_catalog_details`,payLoad).then((res) => {
      // console.log(res.data.data);
      setRegistrationCatalog(res.data.data)
    }).catch((err) => {
      console.log(err);
    })
  },[])

  const handleRegistrationChange = (ev) => {
    // console.log(ev.target.value);
    setRegistrationStatus(ev.target.value)
  }


    const handleRegistrationDetails = () => {
    
      const payLoad = {
      "department_user_id": users.getDeptUserRole.data[0].department_user_id,
      "role_id": users.getDeptUserRole.data[0].role_id,
      "board_id": users.getDeptUserRole.data[0].board_id,
      "registration_status_id": registrationStatus,
      "is_date_selected": isDateSelected,
      "start_date": startDate,
      "end_date": endDate,
      "acck_no": applicationNumber,
      "mobile_no": mobileNumber,
      "aadhaar_no": aadhaarNumber
    }

    axios.post(`${SERVER}/get-labours-status-details`,payLoad,{
      headers:{ 
                'Content-Type': 'application/json', 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
    }).then((res) => {
      console.log(res.data);
    }).catch((err) => {
      console.log(err);
    })

    }

 
  return (
    <>
    <Box sx={{padding:1}}>
  <Paper elevation={2} sx={{width:'100%',height:'100vh',padding:'10px'}}/>
  </Box>
        {/* <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: '100%',
          
        },
      }}
    >
      <Paper elevation={2}>
        <Box sx={{ minWidth: 120}}>
      <FormControl style={{border:'1px solid black',width:'20%'}}>
       
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={registrationStatus}
          
          onChange={handleRegistrationChange}
        >
        {registrationCatalog && 
        registrationCatalog.length > 0 &&
        registrationCatalog.map((_data) => (
          <MenuItem value={_data.value_id}>{_data.value}</MenuItem>
         
          
        ))
        
        }
         
        </Select>
      </FormControl>
 
    </Box>
      </Paper>
      
    </Box> */}
         
         
    </>
  )
}

export default DashboardRegistration