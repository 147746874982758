import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import { showToast } from 'utils/tools';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

const UpdateLabour = () => {
  const [isActiveMobile, setIsActiveMobile] = useState(true);
  const [isActiveReference, setIsActiveReference] = useState(false);

  const [mobileNumber, setMobileNumber] = useState(null);
  const [alreadyRegMobileNumber, setAlreadyRegMobileNumber] = useState(null);
  const [referenceNumber, setRefernceNumber] = useState(null);
  const [refID, setRefID] = useState(null);
  const [showMobileNumber, setShowMobileNumber] = useState(false);

  const updateMobileNumber = () => {
    setIsActiveReference(false);
    setIsActiveMobile(true);
  };

  const updateReferenceNumber = () => {
    setIsActiveMobile(false);
    setIsActiveReference(true);
  };

  const handleUpdateMobileNumber = () => {
    if (mobileNumber) {
      axios
        .delete(`${SERVER}/internal/labour?mobileNumber=${mobileNumber}`, {
          headers: {
            'x-api-key': '106cae6f-8276-4688-a747-d7cb20d28c58',
          },
        })
        .then((res) => {
          showToast('SUCCESS', `${res.data.message}`);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast('ERROR', 'Please enter mobile number');
    }
  };

  const handleUpdateReferenceNumber = () => {
    if (referenceNumber) {
      axios
        .get(
          `${SERVER}/internal/sevasindhu/user?type=REF&value=${referenceNumber}`,
          {
            headers: {
              'x-api-key': '106cae6f-8276-4688-a747-d7cb20d28c58',
            },
          },
        )
        .then(({ data }) => {
          // console.log(data.data);
          const response = data.data;
          const result = response
            .filter(
              (_data) =>
                _data.is_transferred !== null &&
                _data.transferred_date !== null,
            )
            .map((_d) => _d.id);
          console.log(result);
          if (result && result.length > 0) {
            setRefID(result[0]);
            setShowMobileNumber(true);
          } else {
            showToast('ERROR', 'Labour is not transferred');
          }
          // response.map((_data) => {
          // if(_data.is_transferred !== null && _data.transferred_date !== null){

          //   }
          // })
          // if(response.map((_data) => _data.is_transferred !== null && _data.transferred_date !== null)){
          //   console.log("Called");
          //   setShowMobileNumber(true)
          //   setRefID(response.map((_data) => _data.id))
          // }
          // else{
          //   showToast("ERROR","Labour not transferred")
          // }
        })
        .catch((err) => {});
    } else {
      showToast('ERROR', 'Please enter reference number');
    }
  };

  const handleUpdateTransferedData = () => {
    if (alreadyRegMobileNumber) {
      const payLoad = {
        id: refID,
        updateType: 'TRANSFER',
        value: alreadyRegMobileNumber,
      };
      // console.log(refID[0]);
      axios
        .patch(`${SERVER}/internal/sevasindhu/user`, payLoad, {
          headers: {
            'x-api-key': '106cae6f-8276-4688-a747-d7cb20d28c58',
          },
        })
        .then(({ data }) => {
          console.log(data);
          showToast('SUCCESS', 'Labour Updated Successfully');
          setShowMobileNumber(false);
          setAlreadyRegMobileNumber('');
          setRefernceNumber('');
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast('ERROR', 'Please enter mobile numer');
    }
  };

  return (
    <>
      <Box
        sx={{
          display: 'grid',
          placeItems: 'center',
          marginTop: '10%',
          '& > :not(style)': {
            m: 1,
            width: 500,
            height: 300,
          },
        }}
      >
        <Paper elevation={3}>
          <ButtonGroup
            sx={{ width: '100%' }}
            disableElevation
            variant="contained"
            aria-label="Disabled elevation buttons"
          >
            <Button
              color={isActiveMobile ? 'info' : 'inherit'}
              onClick={updateMobileNumber}
              fullWidth
            >
              Update Number
            </Button>
            <Button
              color={isActiveReference ? 'info' : 'inherit'}
              onClick={updateReferenceNumber}
              fullWidth
            >
              Update Already Registered Number
            </Button>
          </ButtonGroup>
          {isActiveMobile ? (
            <div className="d-flex justify-content-center align-items-center my-5 flex-column">
              <input
                placeholder="Enter Mobile Number"
                type="text"
                style={{
                  border: '1px solid grey',
                  height: '40px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
                maxLength={10}
                onChange={(ev) => setMobileNumber(ev.target.value)}
                value={mobileNumber}
              />
              <Button
                onClick={handleUpdateMobileNumber}
                className="my-2"
                color="primary"
                variant="contained"
              >
                Update
              </Button>
            </div>
          ) : null}
          {isActiveReference ? (
            <div className="d-flex justify-content-center align-items-center my-5 flex-column">
              <input
                placeholder="Enter Reference Number"
                type="text"
                style={{
                  border: '1px solid grey',
                  height: '40px',
                  borderRadius: '5px',
                  textAlign: 'center',
                }}
                onChange={(ev) => setRefernceNumber(ev.target.value)}
                value={referenceNumber}
              />
              {showMobileNumber ? (
                <input
                  placeholder="Enter Mobile Number"
                  type="text"
                  style={{
                    border: '1px solid grey',
                    height: '40px',
                    borderRadius: '5px',
                    textAlign: 'center',
                  }}
                  maxLength={10}
                  onChange={(ev) => setAlreadyRegMobileNumber(ev.target.value)}
                  value={alreadyRegMobileNumber}
                />
              ) : null}
              {showMobileNumber ? null : (
                <Button
                  onClick={handleUpdateReferenceNumber}
                  className="my-2"
                  color="primary"
                  variant="contained"
                >
                  Check
                </Button>
              )}
              {showMobileNumber ? (
                <Button
                  onClick={handleUpdateTransferedData}
                  className="my-2"
                  color="primary"
                  variant="contained"
                >
                  Update
                </Button>
              ) : null}
            </div>
          ) : null}
        </Paper>
      </Box>
    </>
  );
};

export default UpdateLabour;
