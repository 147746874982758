import React from 'react';
import { Button } from 'react-bootstrap';
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
  Popover,
  makeStyles,
  Backdrop,
  TextareaAutosize,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  ButtonGroup,
  Checkbox,
} from '@material-ui/core';
const Reject = ({
  loading,
  openRejection,
  handleCloseRejection,
  toggleLang,
  handleToggle,
  handleCheckboxChange,
  handleSubmitReject,
  handleOpenRejectionDialog,
  rejectReasons,
  selectedOptions,
}) => {
  return (
    <>
      <Dialog
        open={openRejection}
        onClose={handleCloseRejection}
        maxWidth="lg"
        fullWidth
      >
        <div className="d-flex justify-content-between align-items-center px-3">
          <div>
            <DialogTitle
              style={{
                fontFamily: 'Arial, Serif',
                fontWeight: 'bold',
              }}
            >
              <h2>ತಿರಸ್ಕರಿಸಲು ಕಾರಣಗಳ ಪಟ್ಟಿ / Rejection Reason List </h2>
            </DialogTitle>
          </div>
          <div>
            <ButtonGroup variant="text" aria-label="Basic button group">
              <Button onClick={handleToggle}>
                {' '}
                {toggleLang
                  ? 'Click here for English'
                  : 'Click here for Kannada'}{' '}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <DialogContent>
          {rejectReasons &&
            rejectReasons.length > 0 &&
            rejectReasons.map((reason) => (
              <div key={reason.id}>
                <Checkbox
                  value={reason.id.toString()}
                  checked={
                    selectedOptions &&
                    selectedOptions.some(
                      (option) => option.value === reason.id.toString(),
                    )
                  }
                  onChange={handleCheckboxChange}
                />
                <label>
                  {toggleLang
                    ? reason.rejection_reason_kannada
                    : reason.rejection_reason}
                </label>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          {/* <RotateLeftIcon /> */}
          <Button className="bg-primary" onClick={handleCloseRejection}>
            Close
          </Button>
          <Button
            className="bg-danger"
            //onClick={rejectButtonPressed}
            onClick={handleSubmitReject}
            disabled={loading}
          >
            Reject
          </Button>
        </DialogActions>
      </Dialog>
      <Button
        // onClick={() => handleApproveRejectTransaction('reject')}
        onClick={handleOpenRejectionDialog}
        variant="outline-primary"
        className="final-button-reject"
      >
        Reject
      </Button>
    </>
  );
};

export default Reject;
