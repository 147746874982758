import 'date-fns';
import { FormControl, TextField, Select, MenuItem, FormControlLabel, ListItemText, InputAdornment, FormHelperText } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { getCatalogDetails, getCityDetails, getCityDetails2, getDistrictDetails, getDistrictDetails2, getStateDetails, getTalukDetails, getVillageDetails, getTalukDetails2, getUser, getVillageDetails2, updateAddressData, updateData, addLabourAddressDetails, getUserRegistrationDetails, getFile, SERVER } from 'store/actions/user.actions';
import { ReactComponent as HomeSVG } from 'assets/images/homesvg.svg';
import { useEffect } from 'react';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import cookie from 'react-cookies';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';
import validator from 'validator';



// TODO: copying value from residence to permanent while check is activated.

const Address = (props) => {

    const myRef = React.createRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const initData = {
        residenceType: "",
        streetRoad: "",
        city: "",
        district: "",
        landmark: "",
        houseBuilding: "",
        areaVillage: "",
        taluk: "",
        state: "",
        pincode: "",
    }
    const [phone, setPhone] = React.useState('')
    const [pinError,setPinError] = React.useState("");
    // const [states, setStates] = React.useState([])
    // const [residence, setResidence] = React.useState([])

    // const [district, setDIstrict] = React.useState([])
    // const [taluk, setTaluk] = React.useState([])
    // const [city, setCity] = React.useState([])
    // const [village, setVillage] = React.useState([])

    // const [district2, setDIstrict2] = React.useState([])
    // const [taluk2, setTaluk2] = React.useState([])
    // const [city2, setCity2] = React.useState([])
    // const [village2, setVillage2] = React.useState([])

    const [allValues, setAllValues] = React.useState({
        residentialData: {
            residenceType: users.address_details.residentialData.residenceType,
            streetRoad: users.address_details.residentialData.streetRoad,
            city: users.address_details.residentialData.city,
            district: users.address_details.residentialData.district,
            landmark: users.address_details.residentialData.landmark,
            houseBuilding: users.address_details.residentialData.houseBuilding,
            areaVillage: users.address_details.residentialData.areaVillage,
            taluk: users.address_details.residentialData.taluk,
            state: users.address_details.residentialData.state,
            pincode: users.address_details.residentialData.pincode,
        },
        addressSameCheckbox: users.address_details.addressSameCheckbox,
        permanentData: {
            residenceType: users.address_details.permanentData.residenceType,
            streetRoad: users.address_details.permanentData.streetRoad,
            city: users.address_details.permanentData.city,
            district: users.address_details.permanentData.district,
            landmark: users.address_details.permanentData.landmark,
            houseBuilding: users.address_details.permanentData.houseBuilding,
            areaVillage: users.address_details.permanentData.areaVillage,
            taluk: users.address_details.permanentData.taluk,
            state: users.address_details.permanentData.state,
            pincode: users.address_details.permanentData.pincode,
        },
        states: [],
        residence: [],
        district: [],
        taluk: [],
        city: [],
        village: [],
        district2: [],
        taluk2: [],
        city2: [],
        village2: [],

        districtHelperText: "",
        cityHelperText: "",
        streetHelperText: "",
        landmarkHelperText: "",
        stateHelperText: "",
        talukHelperText: "",
        villageHelperText: "",
        houseHelperText: "",
        pincodeHelperText: "",
        pinError:false,
        streetlength:false,



    })

    useEffect(() => {
        console.log("in useEffect [allValues]: "+JSON.stringify(allValues,undefined,2))
        dispatch(updateAddressData(allValues))
    }, [allValues]);



    useEffect(() => {
        setAllValues({ ...allValues, phoneNumber: phone })

        dispatch(getStateDetails())
    }, [phone]);

    useEffect(() => {
        // console.log("in useEffect []")
        if (users.generate_otp !== null) {
            setPhone(users.generate_otp.data.phone_number)
            dispatch(getUser(users.generate_otp.data.phone_number))
        }
        else if (users.generate_otp_registration !== null) {
            setPhone(users.generate_otp_registration.data.phone_number)
            dispatch(getUser(users.generate_otp_registration.data.phone_number))
        }
        else {
            props.history.push("/")
        }

        dispatch(addLabourAddressDetails("delete"))
        dispatch(getFile("delete"))
    }, []);

    useEffect(() => {
        // setStates(users.state_details)
        setAllValues({
            ...allValues,
            states: users.state_details
        })
    }, [users.state_details]);

    useEffect(() => {
        // console.log("in useEffect [users.user]")

        if (users.user !== null && users.user.board_id !== null) {

            let dataForRegDetails = {
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "all"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))


            let dataForCatalog = { "board_id": users.user.board_id, "catalog_name": "Type of Residence" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog))

            // let dataForCatalog2 = { "board_id": users.user.board_id, "catalog_name": "Category" };
            // // console.log("dataForCatalog2: " + JSON.stringify(dataForCatalog2))
            // dispatch(getCatalogDetails(dataForCatalog2))


            var AadharData = cookie.load('AadhaarFetchedData')
            if (AadharData !== undefined && AadharData !== "" && allValues.states.length > 0) {
                setAllValues({
                    ...allValues,
                    districtHelperText: `Please select the value above (Aadhaar Data: ${AadharData.District})`,
                    cityHelperText: `Please select the value above (Aadhaar Data: ${AadharData['Village/Town/City']})`,
                    // streetHelperText: "",
                    // landmarkHelperText: "",
                    stateHelperText: `Please select the value above (Aadhaar Data: ${AadharData.State})`,
                    talukHelperText: `Please select the value above (Aadhaar Data: ${AadharData['Sub District']})`,
                    villageHelperText: `Please select the value above (Aadhaar Data: ${AadharData['Village/Town/City']})`,
                    // houseHelperText: "",
                    // pincodeHelperText: "",
                    permanentData: {
                        ...allValues.permanentData,
                        streetRoad: AadharData.Street,
                        city: AadharData['Village/Town/City'],
                        district: AadharData.District,
                        landmark: AadharData.Landmark,
                        houseBuilding: AadharData['House Number'],
                        areaVillage: AadharData['Village/Town/City'],
                        taluk: AadharData['Sub District'],
                        state: AadharData.State,
                        pincode: AadharData.Pincode,
                    },
                    // residentialData: {
                    //     ...allValues.residentialData,
                    //     streetRoad: AadharData.Street,
                    //     city: users.address_details.residentialData.city,
                    //     district: users.address_details.residentialData.district,
                    //     landmark: AadharData.Landmark,
                    //     houseBuilding: AadharData['House Number'],
                    //     // areaVillage: users.address_details.residentialData.areaVillage,
                    //     // taluk: users.address_details.residentialData.taluk,
                    //     state: allValues.states.find
                    //         (o => o.name.toLowerCase() === AadharData.State.toLowerCase()).id,
                    //     pincode: AadharData.Pincode,
                    // },
                })
            }
            else {
                var data = JSON.stringify({
                    "labour_user_id": users.user.id
                });
                var config = {
                    method: 'post',
                    url: SERVER + '/user/get_ad_details',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                    data: data
                }

                axios(config)
                    .then(function (response) {
                        
                        if (response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.length > 0) {
                            console.log("get_ad_details response:" + JSON.stringify(response.data));
                            var AadharDataFetched = response.data.data[0]

                            console.log("AadharDataFetched:" + JSON.stringify(response.data.data[0]));
                            console.log("AadharDataFetched.district:" + AadharDataFetched.district);

                            setAllValues({
                                ...allValues,
                                districtHelperText: `Please select the value above (Aadhaar Data: ${AadharDataFetched.district})`,
                                cityHelperText: `Please select the value above (Aadhaar Data: ${AadharDataFetched.village_city_town})`,
                                stateHelperText: `Please select the value above (Aadhaar Data: ${AadharDataFetched.state})`,
                                talukHelperText: `Please select the value above (Aadhaar Data: ${AadharDataFetched.sub_district})`,
                                villageHelperText: `Please select the value above (Aadhaar Data: ${AadharDataFetched.village_city_town})`,
                                permanentData: { 
                                    streetRoad: AadharDataFetched.street,
                                    city: AadharDataFetched.village_city_town,
                                    district: AadharDataFetched.district,
                                    landmark: AadharDataFetched.landmark,
                                    houseBuilding: AadharDataFetched.house_no,
                                    areaVillage: AadharDataFetched.village_city_town,
                                    taluk: AadharDataFetched.sub_district,
                                    state: AadharDataFetched.state,
                                   pincode: AadharDataFetched.pincode,
                                },
                                // residentialData: {
                                //     ...allValues.residentialData,
                                //     streetRoad: AadharDataFetched.street,
                                //     city: users.address_details.residentialData.city,
                                //     district: users.address_details.residentialData.district,
                                //     landmark: AadharDataFetched.landmark,
                                //     houseBuilding: AadharDataFetched.house_no,
                                //     taluk: allValues.taluk.find
                                //         (o => o.name.toLowerCase() === AadharDataFetched.sub_district.toLowerCase()) !== undefined ? 
                                //         allValues.taluk.find
                                //         (o => o.name.toLowerCase() === AadharDataFetched.sub_district.toLowerCase()).id
                                //         : "",
                                //     state: allValues.states.find
                                //         (o => o.name.toLowerCase() === AadharDataFetched.state.toLowerCase()) !== undefined ?
                                //         allValues.states.find
                                //         (o => o.name.toLowerCase() === AadharDataFetched.state.toLowerCase()).id : "",
                                //     pincode: AadharDataFetched.pincode,
                                // },
                                
                            })
                        }

                    })
                    .catch(function (error) {
                        console.error("get_ad_details error:" + JSON.stringify(error));
                        console.error("get_ad_details error:" + JSON.stringify(error.response));
                    });

            }

        }
    }, [users.user]);

    useEffect(() => {
        if (users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.address_details !== undefined && users.getUserRegistrationDetails.address_details.length > 0) {
            if (users.getUserRegistrationDetails.address_details !== undefined && users.getUserRegistrationDetails.address_details[0].address_base_type !== undefined
                ) {

                console.error("in useeffect users.getUserRegistrationDetails.address_details: " + JSON.stringify(users.getUserRegistrationDetails.address_details))

                var residentialData = {}
                // var permanentData = {}

                if (users.getUserRegistrationDetails.address_details[0].address_base_type === "Residential") {
                    var val = users.getUserRegistrationDetails.address_details[0]

                    residentialData = {
                        residenceType: val.catalog_address_type_id,
                        streetRoad: val.street_name,
                        city: val.city_town_gram_panchayat_id,
                        district: val.district_id,
                        landmark: val.land_mark,
                        houseBuilding: val.door_no,
                        areaVillage: val.village_area_id,
                        taluk: val.taluk_id,
                        state: val.state_id,
                        pincode: val.pin_code,
                    }
                }

                // if (users.getUserRegistrationDetails.address_details[0].address_base_type === "Permanent") {
                //     var val = users.getUserRegistrationDetails.address_details[0]

                //     permanentData = {
                //         residenceType: val.catalog_address_type_id,
                //         streetRoad: val.street_name,
                //         city: val.city_town_gram_panchayat_id,
                //         district: val.district_id,
                //         landmark: val.land_mark,
                //         houseBuilding: val.door_no,
                //         areaVillage: val.village_area_id,
                //         taluk: val.taluk_id,
                //         state: val.state_id,
                //         pincode: val.pin_code,
                //     }
                // }

                setAllValues({
                    ...allValues,
                    residentialData: residentialData,
                    // permanentData: permanentData
                })

            }
        }
    }, [users.getUserRegistrationDetails]);


    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0] !== null && users.catalog_details[0]['name'] === "Type of Residence") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setResidence(users.catalog_details)
            setAllValues({
                ...allValues,
                residence: users.catalog_details
            })
        }
        // else if(users.catalog_details !== null && users.catalog_details[0]['name'] === "Category"){
        //     // console.error("elseif category: "+JSON.stringify(users.catalog_details))
        //     setCategory(users.catalog_details)
        // }
    }, [users.catalog_details]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getDistrictDetails(users.address_details.residentialData.state))
        }
    }, [users.address_details.residentialData.state]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.district_details !== null && users.district_details.error !== undefined && users.district_details.error !== null && users.district_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.district_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.district_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setDIstrict(users.district_details)
            var AadharData = cookie.load('AadhaarFetchedData')
            if (AadharData !== undefined && AadharData !== "" && users.district_details.length > 0) {
                setAllValues({
                    ...allValues,
                    district: users.district_details,
                    residentialData: {
                        ...allValues.residentialData,
                        district: users.district_details.find
                        (o => o.name.toLowerCase() === AadharData.District.toLowerCase()) !== undefined ? users.district_details.find
                        (o => o.name.toLowerCase() === AadharData.District.toLowerCase()).id : "",
                    },
                })
            }
            else {
                setAllValues({
                    ...allValues,
                    district: users.district_details,
                })
            }

        }
    }, [users.district_details]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getTalukDetails(users.address_details.residentialData.district))
        }
    }, [users.address_details.residentialData.district]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.taluk_details !== null && users.taluk_details.error !== undefined && users.taluk_details.error !== null && users.taluk_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.taluk_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.taluk_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setTaluk(users.taluk_details)
            var AadharData = cookie.load('AadhaarFetchedData')
            if (AadharData !== undefined && AadharData !== "" && users.taluk_details.length > 0) {
                setAllValues({
                    ...allValues,
                    taluk: users.taluk_details,
                    residentialData: {
                        ...allValues.residentialData,
                        // streetRoad: AadharData.Street,
                        // city: users.address_details.residentialData.city,
                        // taluk: users.taluk_details.find(o => o.name.toLowerCase() === AadharData['Sub District'].toLowerCase()).id,
                        // landmark: AadharData.Landmark,
                        // houseBuilding: AadharData['House Number'],
                        // areaVillage: users.address_details.residentialData.areaVillage,
                        // taluk: users.address_details.residentialData.taluk,
            taluk: users.taluk_details.find
                (o => o.name.toLowerCase() === AadharData['Sub District'].toLowerCase()) 
                !== undefined ? 
                users.taluk_details.find
                (o => o.name.toLowerCase() === AadharData['Sub District'].toLowerCase()).id 
                : "",
                        // state: users.address_details.residentialData.state,
                        // pincode: AadharData.Pincode,
                    },
                })
            }
            else {

                setAllValues({
                    ...allValues,
                    taluk: users.taluk_details,
                    // alltaluk: allValues.taluk.concat(users.taluk_details)
                })
            }
        }
    }, [users.taluk_details]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getCityDetails(users.address_details.residentialData.taluk))
        }
    }, [users.address_details.residentialData.taluk]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.city_details !== null && users.city_details.error !== undefined && users.city_details.error !== null && users.city_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.city_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.city_details !== null) {
            

            var AadharData = cookie.load('AadhaarFetchedData')
            // if (AadharData !== undefined && AadharData !== "" && users.taluk_details.length > 0) {
            //     setAllValues({
            //         ...allValues,
            //         taluk: users.taluk_details,
            //         residentialData: {
            //             ...allValues.residentialData,
            //             // streetRoad: AadharData.Street,
            //             // city: users.address_details.residentialData.city,
            //             // taluk: users.taluk_details.find(o => o.name.toLowerCase() === AadharData['Sub District'].toLowerCase()).id,
            //             // landmark: AadharData.Landmark,
            //             // houseBuilding: AadharData['House Number'],
            //             // areaVillage: users.address_details.residentialData.areaVillage,
            //             // taluk: users.address_details.residentialData.taluk,
            // city: users.city_details.find
            //     (o => o.name.toLowerCase() === AadharData['Village/Town/City'].toLowerCase()) 
            //     !== undefined ? 
            //     users.city_details.find
            //     (o => o.name.toLowerCase() === AadharData['Village/Town/City'].toLowerCase()).id 
            //     : "",
            //             // state: users.address_details.residentialData.state,
            //             // pincode: AadharData.Pincode,
            //         },
            //     })
            // }
            // else {

                // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setCity(users.city_details)
            setAllValues({
                ...allValues,
                city: users.city_details
            })
            // }
            // }
        }
    }, [users.city_details]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getVillageDetails(users.address_details.residentialData.city))
        }
    }, [users.address_details.residentialData.city]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.village_details !== null && users.village_details.error !== undefined && users.village_details.error !== null && users.village_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.village_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.village_details === "Network Error") {
            console.log("ERROR: " + JSON.stringify(users.village_details))
            showToast("ERROR", "Network Error While Fetching Village!")
        }
        else if (users.village_details !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setVillage(users.village_details)
            setAllValues({
                ...allValues,
                village: users.village_details
            })
        }
    }, [users.village_details]);




    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getDistrictDetails2(users.address_details.permanentData.state))
        }
    }, [users.address_details.permanentData.state]);
    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.district_details2 !== null && users.district_details2.error !== undefined && users.district_details2.error !== null && users.district_details2.error === true) {
            console.log("ERROR: " + JSON.stringify(users.district_details2.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.district_details2 !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setDIstrict2(users.district_details2)
            setAllValues({
                ...allValues,
                district2: users.district_details2
            })
        }
    }, [users.district_details2]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getTalukDetails2(users.address_details.permanentData.district))
        }
    }, [users.address_details.permanentData.district]);
    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.taluk_details2 !== null && users.taluk_details2.error !== undefined && users.taluk_details2.error !== null && users.taluk_details2.error === true) {
            console.log("ERROR: " + JSON.stringify(users.taluk_details2.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.taluk_details2 !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setTaluk2(users.taluk_details2)
            setAllValues({
                ...allValues,
                taluk2: users.taluk_details2
            })
        }
    }, [users.taluk_details2]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getCityDetails2(users.address_details.permanentData.taluk))
        }
    }, [users.address_details.permanentData.taluk]);
    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.city_details2 !== null && users.city_details2.error !== undefined && users.city_details2.error !== null && users.city_details2.error === true) {
            console.log("ERROR: " + JSON.stringify(users.city_details2.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.city_details2 !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setCity2(users.city_details2)
            setAllValues({
                ...allValues,
                city2: users.city_details2
            })
        }
    }, [users.city_details2]);

    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {
            dispatch(getVillageDetails2(users.address_details.permanentData.city))
        }
    }, [users.address_details.permanentData.city]);
    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.village_details2 !== null && users.village_details2.error !== undefined && users.village_details2.error !== null && users.village_details2.error === true) {
            console.log("ERROR: " + JSON.stringify(users.village_details2.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.village_details2 !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            // setVillage2(users.village_details2)
            setAllValues({
                ...allValues,
                village2: users.village_details2
            })
        }
    }, [users.village_details2]);

    const handleChangeCheckbox = (event) => {

        if (event.target.checked) {

            if(allValues.permanentData.state === "Karnataka"){
                setAllValues({
                    ...allValues,
                    // permanentData: allValues.residentialData,
                    // residentialData: allValues.permanentData ,
                    residentialData: {
                        pincode: allValues.permanentData.pincode,
                        landmark: allValues.permanentData.landmark,
                        houseBuilding: allValues.permanentData.houseBuilding,
                        streetRoad: allValues.permanentData.streetRoad,
                    },
                        
                    addressSameCheckbox: event.target.checked
                })
            }
            else{
                showToast("WARN", "Residential Address Out of Karnataka is not Accepted!")
            }



        }
        else {
            setAllValues({
                ...allValues,
                // permanentData: initData,
                residentialData: initData,
                addressSameCheckbox: event.target.checked
            })
        }
    }

    const handleChangeResidential = (event) => {
        if (event.target.name === "pincode") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isNumeric(event.target.value) && event.target.value.length === 6){
                setAllValues({
                    ...allValues,
                    pinError: false,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    pinError: true,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                })
            }
        }
        else if (event.target.name === "streetRoad") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (event.target.value.length < 30){
                setAllValues({
                    ...allValues,
                    streetlength: false,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    streetlength: true,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                })
            }
        }
        else if (event.target.name === "houseBuilding") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (event.target.value.length < 30){
                setAllValues({
                    ...allValues,
                    residenceTypelength: false,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    residenceTypelength: true,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                })
            }
        }
        else if (event.target.name === "landmark") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (event.target.value.length < 30){
                setAllValues({
                    ...allValues,
                    landmarklength: false,
                    residentialData: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                   
                })}
                else{
                setAllValues({
                    ...allValues,
                    streetlength: true,
                    landmarklength: {
                        ...allValues.residentialData,
                        [event.target.name]: event.target.value
                    }
                })
            }
        }
        
        else{
            setAllValues({
                ...allValues,

                residentialData: {
                    ...allValues.residentialData,
                    [event.target.name]: event.target.value
                }
            });
        }
        

    }

    // const handleChangePermanent = (event) => {

    //     if (users.address_details.addressSameCheckbox) {
    //         setAllValues({
    //             ...allValues,
    //             residentialData: {
    //                 ...allValues.residentialData,
    //                 [event.target.name]: event.target.value
    //             },
    //             permanentData: {
    //                 ...allValues.permanentData,
    //                 [event.target.name]: event.target.value
    //             }
    //         })
    //     }
    //     else {
    //         setAllValues({
    //             ...allValues,
    //             permanentData: {
    //                 ...allValues.permanentData,
    //                 [event.target.name]: event.target.value
    //             }
    //         })
    //     }
    // }

    const handleEnter = (event) => {
        console.log("in handleEnter")

        const nextSibling = document.querySelector(
            `.formcontrol${allValues.residentialData.formcontrolnum + 1}>div>div>input`
        );
        console.log("nextSibling:" + nextSibling.className)

        if (nextSibling !== null) {
            nextSibling.focus();
        }
    }

    const nextButtonPressed = () => {

        console.log("users.address_details.permanentData.pincode === '' : " + users.address_details.permanentData.pincode === "")

        if (
            users.address_details.residentialData.residenceType === "" ||
            users.address_details.residentialData.state === "" ||
            users.address_details.residentialData.district === "" ||
            users.address_details.residentialData.taluk === "" ||
            users.address_details.residentialData.city === "" ||
            users.address_details.residentialData.areaVillage === "" ||
            users.address_details.residentialData.streetRoad === "" ||
            users.address_details.residentialData.houseBuilding === "" ||
            allValues.pinError  ||
            users.address_details.residentialData.pincode === "" ||
            allValues.streetlength

            // ||

            // users.address_details.permanentData.residenceType === "" ||
            // users.address_details.permanentData.state === "" ||
            // users.address_details.permanentData.district === "" ||
            // users.address_details.permanentData.taluk === "" ||
            // users.address_details.permanentData.city === "" ||
            // users.address_details.permanentData.areaVillage === "" ||
            // users.address_details.permanentData.streetRoad === "" ||
            // users.address_details.permanentData.houseBuilding === "" ||
            // users.address_details.permanentData.pincode === ""
        ) {
            showToast("WARN", "Please Fill All the Required Fields to Continue!")
        }
        else {
            submitAddress()
        }
    }

    const submitAddress = () => {
        let dataToSubmit = {
            "user_id": users.user.id,
            "addresses": [
                // {
                //     "address_base_type_id": 9,
                //     "address_type_id": users.address_details.permanentData.residenceType,
                //     "door_no": users.address_details.permanentData.houseBuilding,
                //     "street_name": users.address_details.permanentData.streetRoad,
                //     "village_area_id": users.address_details.permanentData.areaVillage,
                //     "city_town_gram_panchayat_id": users.address_details.permanentData.city,
                //     "taluk_id": users.address_details.permanentData.taluk,
                //     "district_id": users.address_details.permanentData.district,
                //     "state_id": users.address_details.permanentData.state,
                //     "land_mark": users.address_details.permanentData.landmark,
                //     "pin_code": users.address_details.permanentData.pincode,
                // },
                {
                    "address_base_type_id": 10,
                    "address_type_id": users.address_details.residentialData.residenceType,
                    "door_no": users.address_details.residentialData.houseBuilding,
                    "street_name": users.address_details.residentialData.streetRoad,
                    "village_area_id": users.address_details.residentialData.areaVillage,
                    "city_town_gram_panchayat_id": users.address_details.residentialData.city,
                    "taluk_id": users.address_details.residentialData.taluk,
                    "district_id": users.address_details.residentialData.district,
                    "state_id": users.address_details.residentialData.state,
                    "land_mark": users.address_details.residentialData.landmark,
                    "pin_code": users.address_details.residentialData.pincode,
                },

            ]
        }

        console.error("dataToSubmit", JSON.stringify(dataToSubmit))


        if (users.getUserRegistrationDetails && users.getUserRegistrationDetails.address_details.length === 0) {
            dispatch(addLabourAddressDetails(dataToSubmit))
        }
        else {
            dispatch(addLabourAddressDetails(dataToSubmit, "update"))
        }

    }

    const init_address_details = {
        residentialData: {
            residenceType: "",
            streetRoad: "",
            city: "",
            district: "",
            landmark: "",
            houseBuilding: "",
            areaVillage: "",
            taluk: "",
            state: "",
            pincode: "",
        },
        addressSameCheckbox: false,
        permanentData: {
            residenceType: "",
            streetRoad: "",
            city: "",
            district: "",
            landmark: "",
            houseBuilding: "",
            areaVillage: "",
            taluk: "",
            state: "",
            pincode: "",
        },
    }

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (users.addLabourAddressDetails !== null && users.addLabourAddressDetails.success !== undefined && users.addLabourAddressDetails.success !== null && users.addLabourAddressDetails.success === true) {
            showToast("SUCCESS", "Address Details Saved Successfully!")
            // props.nextButtonPressed()
            dispatch(updateAddressData(init_address_details))
            dispatch(addLabourAddressDetails("delete"))

            if (users.profile_details.editmode) {
                props.saveEditButtonPressed()
            }
            else {
                props.nextButtonPressed()
            }
        }
        else if (users.addLabourAddressDetails !== null && users.addLabourAddressDetails.success !== undefined && users.addLabourAddressDetails.success !== null && users.addLabourAddressDetails.success === "false") {
            showToast("ERROR", "Error Saving Address Details!")
        }
        else if (users.addLabourAddressDetails !== null && users.addLabourAddressDetails.error !== undefined && users.addLabourAddressDetails.error === true) {
            showToast("ERROR", "Error Saving Family Details!")
        }
        // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
        //     showToast("ERROR","Error Saving Personal Details!")
        // }
    }, [users.addLabourAddressDetails])

    return (
        <>
            <Row className="m-0">

                <Row className="permanent-title-row">
                    <Col xs={12} className="profile-title">
                        <h1><Translate value='permanentAddress'/></h1>
                    </Col>
                </Row>

                <Row className="form-row">
                    <Col xs={12} md={6}>

                        <div className="form-inner-card-right mt-0 residential-div">

                        <Required className="mt-0 mb-2" title={<Translate value="state"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your State"
                                    name="pincode"
                                    value={allValues.permanentData.state}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="taluk"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Taluk"
                                    name="pincode"
                                    value={allValues.permanentData.taluk}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="villageName"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Area / Village Name"
                                    name="pincode"
                                    value={allValues.permanentData.areaVillage}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="houseBuildingNumber"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <HomeSVG />
                                            </InputAdornment>,
                                    }}
                                    variant="outlined"
                                    placeholder="Enter Your House / Building Number"
                                    name="houseBuilding"
                                    value={allValues.permanentData.houseBuilding}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="pincode"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Pincode"
                                    name="pincode"
                                    value={allValues.permanentData.pincode}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                        </div>


                    </Col>
                    <Col xs={12} md={6}>

                        <div className="form-inner-card-right residential-div">

                        <Required className="mt-0 mb-2" title={<Translate value="enterYourDistrict"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your District"
                                    name="pincode"
                                    value={allValues.permanentData.district}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="enterYourCityName"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your City Name"
                                    name="pincode"
                                    value={allValues.permanentData.city}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-3 mb-2" title={<Translate value="streetRoadName"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Street Name / Road Name"
                                    name="pincode"
                                    value={allValues.permanentData.streetRoad}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                            <p className="mt-3 mb-2"><Translate value="landmark"/> </p>
                            <FormControl fullWidth className="formcontrol2" >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Landmark"
                                    name="landmark"
                                    value={allValues.permanentData.landmark}
                                    // onChange={handleChangePermanent}
                                    disabled
                                />
                            </FormControl>

                        </div>
                    </Col>

                </Row>



                {/* <Row className="copy-content-row">
                    <Col xs={12} className="note2 text-center mt-5">

                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={allValues.addressSameCheckbox}
                                    onChange={handleChangeCheckbox}
                                    name="addressSameCheckbox"
                                // color="primary"
                                />
                            }
                            label="Click here to copy the content from the Permanent Address if the Residential Address is same as the Permanent Address"
                        />
                    </Col>
                </Row> */}


                <Row className="residential-title-row">
                    <Col xs={12} className="profile-title">
                        <h1><Translate value ="residentialAddress"/></h1>
                    </Col>
                </Row>

                <Row className="form-row ">
                    <Col xs={12} md={6}>

                        <div className="form-inner-card-right residential-div">

                            {/* <p className="mt-0 mb-2">Type of Residence*</p> */}
                            <Required className="mt-0 mb-1" title={<Translate value="typeOfresidence"/>} />
                            <FormControl variant="outlined" fullWidth style={{marginBottom:"26px"}} >
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.residenceType}
                                    name="residenceType"
                                    displayEmpty
                                    onChange={handleChangeResidential}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {allValues.residence !== undefined && allValues.residence !== null &&
                                        allValues.residence.length > 0 && allValues.residence.map((i) =>
                                            <MenuItem value={i.value_id}>
                                                <ListItemText primary={i.value} />
                                            </MenuItem>
                                        )}
                                </Select>
                            </FormControl>
                            

                            {/* <p className="mt-5 mb-2">Enter Your District*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="enterYourDistrict"/>} />
                            <FormControl variant="outlined" fullWidth >
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.district} 
                                    name="district"
                                    displayEmpty
                                    onChange={handleChangeResidential} 
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {allValues.district !== undefined && allValues.district !== null && Array.isArray(allValues.district) &&
                                        allValues.district.length > 0 && allValues.district.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                        {
                                            allValues.addressSameCheckbox==true?
                                            (  <MenuItem value={allValues.residentialData.district}>
                                            {allValues.residentialData.district}
                                        </MenuItem>):null
                                        }
                                </Select>
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.districtHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">Enter Your City Name*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="enterYourCityName"/>} />
                            <FormControl variant="outlined" fullWidth>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.city}
                                    name="city"
                                    displayEmpty
                                    onChange={handleChangeResidential}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {allValues.city !== undefined && allValues.city !== null &&
                                        allValues.city.length > 0 && allValues.city.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                          {
                                            allValues.addressSameCheckbox==true?
                                            (  <MenuItem value={allValues.residentialData.city}>
                                            {allValues.residentialData.city}
                                        </MenuItem>):null
                                        }
                                </Select>
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.cityHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">Street Name / Road Name*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="streetRoadName"/>} />
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Street/Road Name"
                                    name="streetRoad"
                                    value={allValues.residentialData.streetRoad}
                                    onChange={handleChangeResidential}
                                    helperText={allValues.streetlength ? "Please enter in correct length" : ""}
                                    error = {allValues.streetlength}
                                />
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.streetHelperText}</FormHelperText>

                            <p className="mt-5 mb-2"><Translate value="landmark"/> </p>
                            <FormControl fullWidth className="formcontrol2" >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Landmark"
                                    name="landmark"
                                    value={allValues.residentialData.landmark}
                                    onChange={handleChangeResidential}
                                    error={allValues.landmarklength}
                                    helperText={allValues.landmarklength ? "Please enter in correct format" : ""}
                                />
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.landmarkHelperText}</FormHelperText>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>

                        <div className="form-inner-card-right mt-0 residential-div">

                            {/* <p className="mt-0 mb-2">Enter Your State*</p> */}
                            <Required className="mt-0 mb-2" title={<Translate value="state"/>} />
                            <FormControl variant="outlined" fullWidth >
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.state}
                                    name="state"
                                    displayEmpty
                                    onChange={handleChangeResidential}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {
                                        allValues.states !== undefined && allValues.states !== null &&
                                        allValues.states.length > 0 && allValues.states.map((i) =>
                                           i.name === "Karnataka" &&
                                            //    <>
                                                <MenuItem value={i.id}>
                                                    <ListItemText primary={i.short_name + " | " + i.name} />
                                                </MenuItem>
                                                // </>
                                            
                                        )
                                    }
                                     {/* {
                                            allValues.addressSameCheckbox==true?
                                            (  <MenuItem value={allValues.residentialData.state}>
                                            {allValues.residentialData.state}
                                        </MenuItem>):null
                                    } */}
                                </Select>
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.stateHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">Enter Your Taluk*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="taluk"/>} />
                            <FormControl variant="outlined" fullWidth >
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.taluk}
                                    name="taluk"
                                    displayEmpty
                                    onChange={handleChangeResidential}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {allValues.taluk !== undefined && allValues.taluk !== null &&
                                        allValues.taluk.length > 0 && allValues.taluk.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                     {
                                            allValues.addressSameCheckbox==true?
                                            (  <MenuItem value={allValues.residentialData.taluk}>
                                            {allValues.residentialData.taluk}
                                        </MenuItem>):null
                                    }
                                </Select>
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.talukHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">Enter Your Area / Village Name*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="villageName"/>} />
                            <FormControl variant="outlined" fullWidth >
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.residentialData.areaVillage}
                                    name="areaVillage"
                                    displayEmpty
                                    onChange={handleChangeResidential}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    {allValues.village !== undefined && allValues.village !== null &&
                                        allValues.village.length > 0 && allValues.village.map((i) =>
                                            // <MenuItem value={i.ref_district_code}>
                                            <MenuItem value={i.id}>
                                                <ListItemText primary={i.name} />
                                            </MenuItem>
                                        )}
                                     {
                                            allValues.addressSameCheckbox==true?
                                            (  <MenuItem value={allValues.residentialData.areaVillage}>
                                            {allValues.residentialData.areaVillage}
                                        </MenuItem>):null
                                    }
                                </Select>
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.villageHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">House / Building Number*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="houseBuildingNumber"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <HomeSVG className="houseicon" />
                                            </InputAdornment>,
                                    }}
                                    variant="outlined"
                                    placeholder="Enter Your House / Building Number"
                                    name="houseBuilding"
                                    value={allValues.residentialData.houseBuilding}
                                    onChange={handleChangeResidential}
                                    error={allValues.residenceTypelength}
                                    helperText={allValues.residenceTypelength ? "Please enter in correct format" : ""}
                                    inputProps={{minLength:0,maxLength:15}}
                                    />
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.houseHelperText}</FormHelperText>

                            {/* <p className="mt-5 mb-2">Pincode</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="pincode"/>} />
                            <FormControl fullWidth >
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Pincode"
                                    name="pincode"
                                    value={allValues.residentialData.pincode}
                                    onChange={handleChangeResidential}
                                    error={pinError}
                                    helperText={pinError}
                                    onInput={(e)=>{
                                        e.target.value = e.target.value.replace(/[^0-9]/ig,'')
                                        if(e.target.value.length === 0){
                                            setPinError("Please Enter Pin Code")
                                        }
                                        else if (e.target.value.length < 6){
                                            setPinError("Pin code must contain 6 digits")
                                        }
                                        else{
                                            setPinError("")
                                        }
                                    }}
                                    inputProps={{minLength:0,maxLength:6}}
                                />
                            </FormControl>
                            <FormHelperText className="helperTextAadhar">{allValues.pincodeHelperText}</FormHelperText>


                        </div>


                    </Col>
                </Row>

                <Col xs={12} className="note2 text-center mt-3 personal-col-7">
                        {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
                        <p><span><Translate value="note"/> </span><Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess1"/></p>

                        </Col>



                {/* <Row className="button-inside-form-row mb-5 mt-4">
                    <Col xs={12} className="next-back-button-row mt-4 ">
                        <Link to="#" onClick={props.backButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="danger" className="back-button" >
                                Back
                            </Button>
                        </Link>
                        <Link to="#" onClick={nextButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Next
                        </Button>
                        </Link>
                    </Col>
                </Row>
             */}
                <Row className="button-inside-form-row mb-5">
                    <Col xs={12} className="next-back-button-row mt-4 ">

                        {
                            !users.profile_details.editmode ?
                                <>
                                    <Link to="#" onClick={props.backButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            <Translate value="back"/>
                                        </Button>
                                    </Link>
                                    <Link to="#" onClick={nextButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="next-button">
                                            <Translate value ="saveandContinue"/>
                                        </Button>
                                    </Link>
                                </>

                                :

                                <Link to="#" onClick={nextButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                >
                                     <Button variant="outline-primary" className="next-button">
                                        <Translate value="saveyourDetails"/>
                                    </Button>
                                </Link>

                        }


                    </Col>
                </Row>
            </Row>

        </>
    )

}


export default Address