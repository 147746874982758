export const districtData = (circleData) => {
  return (
    circleData &&
    circleData.reduce((acc, current) => {
      if (!acc.find((item) => item.district_name === current.district_name)) {
        acc.push(current);
      }
      return acc;
    }, [])
  );
};

export const talukData = (circleData) => {
  return (
    circleData &&
    circleData.reduce((acc, current) => {
      if (!acc.find((item) => item.taluk_name === current.taluk_name)) {
        acc.push(current);
      }
      return acc;
    }, [])
  );
};

export const villageData = (circleData) => {
  return (
    circleData &&
    circleData.reduce((acc, current) => {
      if (
        !acc.find(
          (item) => item.village_area_ward === current.village_area_ward,
        )
      ) {
        acc.push(current);
      }
      return acc;
    }, [])
  );
};

export const cityData = (circleData) => {
  return (
    circleData &&
    circleData.reduce((acc, current) => {
      if (
        !acc.find(
          (item) =>
            item.gram_panchayatcity_town_name ===
            current.gram_panchayatcity_town_name,
        )
      ) {
        acc.push(current);
      }
      return acc;
    }, [])
  );
};
