import { Popover } from '@material-ui/core';
import ECardApproved from 'components/adminPortal/PreviewOption/ECardApproved';
import React, { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const ECardView = ({
  labourUserID,
  personalDetails,
  addressDetails,
  familyDetails,
  certificateDetails,
  employerDetails,
}) => {
  const [anchorElEcard, setAnchorElEcard] = useState(null);

  const openEcard = Boolean(anchorElEcard);
  const idEcard = openEcard ? 'simple-popover' : undefined;

  const handleCloseEcard = () => {
    setAnchorElEcard(null);
  };

  const handleClickEcard = (event) => {
    setAnchorElEcard(event.currentTarget);
  };
  return (
    <>
      {
        <>
          <Row className="scheme-subtitle-row">
            <Col xs={12} className="searchform-subtitle">
              <p>Existing E-Card</p>
            </Col>
          </Row>

          <Row className="form-row mt-4 config-form searchform">
            <div>
              <Button variant={'outline-secondary'} onClick={handleClickEcard}>
                <RemoveRedEyeIcon />
                View E-Card
              </Button>
              <Popover
                style={{ width: '70%' }}
                id={idEcard}
                open={openEcard}
                anchorEl={anchorElEcard}
                onClose={handleCloseEcard}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <ECardApproved
                  labourUserID={labourUserID}
                  personalDetails={personalDetails}
                  addressDetails={addressDetails}
                  familyDetails={familyDetails}
                  certificateDetails={certificateDetails}
                  employerDetails={employerDetails}
                />
              </Popover>
            </div>
          </Row>
        </>
      }
    </>
  );
};

export default ECardView;
