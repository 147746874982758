import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import appReducers from './reducers';
import storage from 'redux-persist/lib/storage';
import { persistStore, persistReducer } from 'redux-persist';
import {
  setLocale,
  loadTranslations,
  syncTranslationWithStore,
} from 'react-redux-i18n';

import translations from '../translations';

const persistConfig = {
  key: 'users',
  storage: storage,
  whitelist: ['users'], // which reducer want to store
};
const pReducer = persistReducer(persistConfig, appReducers);
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk)));

const ReduxStore = () => {
  syncTranslationWithStore(store);
  store.dispatch(loadTranslations(translations));
  store.dispatch(setLocale('en'));

  // const persistor = persistStore(store);

  return store;
  // return { persistor, store }
};
const persistor = persistStore(store);

// export default ReduxStore;
export { persistor, ReduxStore };

// export const store = ReduxStore
// export const persistor = persistor
