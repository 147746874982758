import "date-fns";
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  Popover,
  makeStyles,
  Backdrop,
  TextareaAutosize,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import checkGreen from "assets/images/Tick-01.png";
import usericn from "assets/images/Icon awesome-user-circle.svg";
// import moment from 'moment';
import Moment from "react-moment";
import closeicon from "assets/images/closeIcon.svg";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
  getFile,
  uploadFile,
} from "store/actions/user.actions";
import { Link } from "react-router-dom";
import {
  showToast,
  getTokenCookie,
  Required,
  dataURLtoFile,
} from "utils/tools";
import { Visibility } from "@material-ui/icons";
import validator from "validator";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import cookie from "react-cookies";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import folder from "assets/images/Folderwhite-01-01.svg";
import usericon from "assets/images/Icon awesome-user.svg";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";

const SchemeForm = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    props_this: props,
    users: users.profile_details.users,
    natureOfWork: "",
    nameError: false,
    nameNomineeError: false,
    phoneError: false,
    phoneNomineeError: false,
    formcontrolnum: "",
    aadharnum1: "",
    aadharnum2: "",
    aadharnum3: "",
    aadhar: "",
    remarkTransfer: "",
    openDatePicker: false,

    states: [],
    districts: [],
    taluks: [],
    cities: [],
    villages: [],
    relations: [],
    naturesOfWork: [],

    state: "",
    district: "",
    taluk: "",
    city: "",
    village: "",
    relation: "",
    natureOfWork: "",

    getUserDetails: null,
    // personal_details: null,

    // applicantName: "",
    get_dynamic_fields: null,

    marriage_assistance_for: "",
    name_of_person_married: "",
    gender: "",
    age: "",
    marriage_date: "",
    marriage_place: "",
    marriage_certifate: "",

    id_cardfileId: "",
    bank_accountfileId: "",
    employee_certfileId: "",
    marriage_certfileId: "",

    commentInspector: "",
    sanctioned_amount: "",
    amtError: false,
    upload_file: null,

    circleSelected: "",
    costError: "",
  });

  // let { id } = useParams()
  // var idName = id.split('_id=')[0]
  // var role_id = Number(id.split('_id=')[1])

  const [deliveryData, setdeliveryData] = React.useState([]);
  const [accidentDeathDropdownData, setAccidentDeathDropdownData] =
    React.useState([]);

  const [medNumeric, setMedNumeric] = React.useState(false);

  const classes = useStyles();

  const [selected_document_type, setselected_document_type] =
    React.useState("");

  const [circle_details, setcircle_details] = React.useState(null);

  const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] =
    React.useState(null);
  const [anchorElninty, setAnchorElninty] = React.useState(null);
  const [anchorElration, setAnchorElration] = React.useState(null);
  const [getAshadeepaAvailed, setAshadeepaAvailed] = React.useState(null);
  const openration = Boolean(anchorElration);
  const idration = openration ? "simple-popover" : undefined;

  const [anchorElIdCard, setAnchorElIdCard] = React.useState(null);
  const openIdCard = Boolean(anchorElIdCard);
  const idIdCard = openration ? "simple-popover" : undefined;

  // const downloadTxtFile = () => {
  //     //     var xmltext = "<sometag><someothertag></someothertag></sometag>";

  //     //     var filename = "file.xml";
  //     //     var pom = document.createElement('a');
  //     //     var bb = new Blob([xmltext], {type: 'text/plain'});

  //     //     pom.setAttribute('href', window.URL.createObjectURL(bb));
  //     //     pom.setAttribute('download', filename);

  //     //     pom.dataset.downloadurl = ['text/plain', pom.download, pom.href].join(':');
  //     //     pom.draggable = true;
  //     //     pom.classList.add('dragout');

  //     //     pom.click();

  //     // }

  const [anchorElemployee_cert, setAnchorElemployee_cert] =
    React.useState(null);
  const openemployee_cert = Boolean(anchorElemployee_cert);
  const idemployee_cert = openemployee_cert ? "simple-popover" : undefined;

  const [anchorElmarriage_cert, setAnchorElmarriage_cert] =
    React.useState(null);
  const openmarriage_cert = Boolean(anchorElmarriage_cert);
  const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;

  const [loading, setLoading] = React.useState(false);
  const [loadingData, setLoadingData] = React.useState(true);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(true);

  const [verificationDoc, setverificationDoc] = React.useState(false);

  const [AckLetterImg, setAckLetterImg] = React.useState("");
  const [ackId, setackId] = React.useState("");

  const [get_scheme_status, setget_scheme_status] = React.useState({});

  const [accidentStatus, setAccidentStatus] = React.useState(null);
  const [accidentAmount, setAccidentAmount] = React.useState(null);
  const [accidentDeath, setAccidentDeath] = React.useState(null);

  const [schemeDetails, setschemeDetails] = React.useState({});
  const [schemeDetailsAll, setschemeDetailsAll] = React.useState({});
  const [UserDetails, setUserDetails] = React.useState({});
  const [Age, setAge] = React.useState({});
  const [Age60, setAge60] = React.useState({});
  const [AgeAtRegistration, setAgeAtRegistration] = React.useState({});
  const [RenewalDate, setRenewalDate] = React.useState({});
  const [YearsRegistered, setYearsRegistered] = React.useState({});

  const [rejectButton, setrejectButton] = React.useState(true);
  const [approveButton, setapproveButton] = React.useState(false);

  const [showtransferButton, setshowtransferButton] = React.useState(false);
  const [showtransferBackButton, setshowtransferBackButton] =
    React.useState(false);

  const [
    get_scheme_transfer_role_details,
    setget_scheme_transfer_role_details,
  ] = React.useState(null);

  const [opentransfer, setopentransfer] = React.useState(false);
  const [deliveryChildMale, setDeliveryChildMale] = React.useState(false);
  const [accidentDropdownData, setAccidentDropdownData] = React.useState(null);
  const [accidentDropdownAmount, setAccidentDropdownAmount] =
    React.useState(null);
  const [sanctionedAmountMed, setSanctionedAmountMed] = React.useState(null);

  const handleClickOpenTransfer = () => {
    console.log();
    if (
      allValues.commentInspector !== null &&
      allValues.commentInspector.length > 0
    ) {
      console.log("SelectedFiles", selectedfiles);
      if (selectedfiles.length !== 0) {
        showToast(
          "ERROR",
          "Please remove the Verification Document to Transfer for Verification"
        );
      } else {
        setopentransfer(true);
      }
    } else {
      showToast("ERROR", "Please add a comment before Transfer");
    }
  };
  const handleCloseTransfer = () => {
    setopentransfer(false);
  };

  const [isAmountSanctioned, setisAmountSanctioned] = React.useState(false);
  const [isFinalApproval, setisFinalApproval] = React.useState(false);

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState([]);

  const [showVerificationDocUpload, setshowVerificationDocUpload] =
    React.useState(true);
  const [VerificationDocuments, setVerificationDocuments] = React.useState([]);

  const [scheme_name_ad, setscheme_name_ad] = React.useState(null);

  // const scheme_name_ad = users.getSchemesOnStatus.data.recordsets[0].find(o => o.scheme_avail_id === allValues.props_this.location.scheme_avail_id)

  const labour_name1 = users.getDeptUserRole.data[0].role_id;

  const getFieldValues = async (field) => {
    return new Promise((resolve, reject) => {
      var dataNEW = JSON.stringify({
        board_id: 4,
        field_id: field.id,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_fields_value",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          resolve({
            fieldName: field.field_name,
            data: response.data,
          });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  // useEffect(() => {},[setschemeDetails(downloadTxtFile)])
  useEffect(() => {
    console.log("scheme_name_ad1", scheme_name_ad);

    if (scheme_name_ad !== undefined && scheme_name_ad !== null) {
      console.log("scheme_name_ad11", scheme_name_ad);
      setisAmountSanctioned(scheme_name_ad.is_amount_sactioned);
      setisFinalApproval(scheme_name_ad.is_final_approved);

      if (scheme_name_ad.role_id == 2) {
        setshowtransferButton(false);
      }

      if (scheme_name_ad.scheme_name == "BMTC Bus Pass\r\n") {
        setshowVerificationDocUpload(false);
      }

      console.log("Labour Name1", labour_name1);
      if (
        scheme_name_ad.scheme_name.includes("Medical Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
        console.log("Scheme Name1 Medical");
      } else if (
        scheme_name_ad.scheme_name.includes("Accident Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Funeral Expense and Death Assistance"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Continuation of Disability Pension"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Disability Pension") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Pension Scheme") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Delivery Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Thayi Magu Sahaya Hasta") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Marriage Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Assistance For Major Ailments") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      }
      // else if (scheme_name_ad.scheme_name.includes('Shrama Samarthya Toolkit') && labour_name1 === 1) {
      //     setrejectButton(false)

      // }
      else if (
        scheme_name_ad.scheme_name.includes("House Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("BMTC Bus Pass") &&
        labour_name1 === 1
      ) {
        setrejectButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching (UPSC and KPSC) Application"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Accident Assistance") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Funeral Expense and Death Assistance"
        ) &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Disability Pension") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Pension Scheme") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Continuation of Disability Pension"
        ) &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Delivery Assistance") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Marriage Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Thayi Magu Sahaya Hasta") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Medical Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Medical");
      } else if (
        scheme_name_ad.scheme_name.includes("House Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Assistance For Major Ailments") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("BMTC Bus Pass") &&
        labour_name1 === 1
      ) {
        setapproveButton(false);
        console.log("Scheme Name1 Medical");
      } else if (
        scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("KSRTC Bus pass") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching (UPSC and KPSC) Application"
        ) &&
        labour_name1 === 4
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      }
      // else if (scheme_name_ad.scheme_name.includes('BMTC Bus pass') && labour_name1 === 6) {
      //     setapproveButton(true)

      // }
      else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching(UPSC and KPSC) Application"
        ) &&
        users.getDeptUserRole.data[0].role_name === "Joint Secretary"
      ) {
        setapproveButton(true);
      }

      console.log("scheme_name_ad", isAmountSanctioned);
    }

    // if(scheme_name_ad !== null){
    //     console.log("schemeNameAd1",scheme_name_ad.circle_name)
    // }
  }, [scheme_name_ad]);

  useEffect(() => {
    console.log("allValues.circleSelected: ", allValues.circleSelected);

    if (allValues.circleSelected !== "") {
      var config = {
        method: "get",
        url:
          SERVER +
          `/global/get_inspector_details_by_circle_role?board_id=${users.getDeptUserRole.data[0].board_id}&role_id=${users.getDeptUserRole.data[0].role_id}&circle_id=${allValues.circleSelected}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "get_inspector_details_by_circle_role response:",
            response.data
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [allValues.circleSelected]);

  useEffect(() => {
    console.log("inspectorCircle");
    console.error(
      "in useEffect props userid:" +
        allValues.props_this.location.userid +
        " scheme_avail_id:" +
        allValues.props_this.location.scheme_avail_id
    );
    // if(scheme_name_ad.scheme_name.includes("BMTC Bus pass")){
    //     setshowVerificationDocUpload(false)
    // }

    if (
      allValues.props_this.location.userid !== undefined &&
      allValues.props_this.location.userid !== null &&
      allValues.props_this.location.scheme_avail_id !== undefined &&
      allValues.props_this.location.scheme_avail_id !== null
    ) {
      var config = {
        method: "get",
        url:
          SERVER +
          `/schemes/get_scheme_fields_values?labour_user_id=${allValues.props_this.location.userid}&scheme_avail_id=${allValues.props_this.location.scheme_avail_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        // data : data
      };

      axios(config)
        .then(function (response) {
          // console.log("get_scheme_fields_values response: " + JSON.stringify(response.data.data, undefined, 2));
          console.log(
            "get_scheme_fields_values response:1 " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          setschemeDetails(response.data.data);
          if (response.data.data[0][0].field === "Accident Resulted In") {
            setAccidentStatus(response.data.data[0][0].field_value);
          } else if (
            response.data.data[0][8].field.includes("Cost of treatment") &&
            response.data.data[0][8].field_value !== null &&
            response.data.data[0][8].field_value !== undefined
          ) {
            setSanctionedAmountMed(response.data.data[0][8].field_value);
          }
          // else if(response.data.data[0][8].field_value !== null && response.data.data[0][8].field_value !== undefined){
          //     setSanctionedAmountMed(response.data.data[0][8].field_value)
          // }
        })
        .catch(function (error) {
          console.error("get_scheme_fields_values error: " + error);
        });

      let obj = users.getSchemesOnStatus.data.recordsets[0].find(
        (o) =>
          o.scheme_avail_id === allValues.props_this.location.scheme_avail_id
      );
      setschemeDetailsAll(obj);

      var data = JSON.stringify({
        key: "user_id",
        value: allValues.props_this.location.userid,
        board_id: 4,
        procedure_name: "all",
      });
      var config = {
        method: "post",
        url: SERVER + "/user/get-user-registration-details",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config)
        .then(function (response) {
          console.log(
            "users.user.id" + JSON.stringify(response.data, undefined, 2)
          );

          console.log(
            "get-user-registration-details response:" +
              JSON.stringify(response.data, undefined, 2)
          );
          setUserDetails(response.data.data);
        })
        .catch(function (error) {
          console.error("get-user-registration-details error:" + error);
        });

      var config1 = {
        method: "get",
        url:
          SERVER +
          "/schemes/get_scheme_status?schme_avail_id=" +
          allValues.props_this.location.scheme_avail_id,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: "",
      };

      axios(config1)
        .then(function (response) {
          console.log("getScheme Status response: ", response.data);
          setget_scheme_status(response.data);

          setshowtransferBackButton(
            response.data.data.recordsets[1][0].is_transferred
          );
          if (users.getDeptUserRole.role_id === 3) {
            setshowVerificationDocUpload(false);
          }
          console.log("scheme123123", response.data.data.recordsets);
          response.data.data.recordsets[1].map((field) => {
            console.log("in get_scheme_status map: ", field);
            if (field.is_final !== true) {
              console.log(
                "verification_document_id parse: ",
                JSON.parse(field.verification_document_id)
              );

              setVerificationDocuments(
                JSON.parse(field.verification_document_id)
              );
              setverificationDoc("userstat", field.approval_status);

              JSON.parse(field.verification_document_id).forEach((doc) => {
                dispatch(getFile(doc.file_id, doc.file_name));
              });
            } else {
              setshowVerificationDocUpload(false);
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      allValues.props_this.history.goBack();
    }
  }, []);

  console.log("AshaAvailed:", allValues.props_this.location.scheme_avail_id);
  useEffect(() => {
    let data = JSON.stringify({
      board_id: 4,
      role_id: 2,
      department_user_id: 6,
      scheme_id: 39,
      circle_id: 120,
      catalog_value_application_status: 45,
    });
    var config = {
      method: "post",
      url: SERVER + "/schemes/get_ashadeep_availed_schemes",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "Get_Ashadeepa_Availed" + JSON.stringify(response.data.data)
        );
        setAshadeepaAvailed(response.data.data);
        const ashadeepaResponse = response.data.data;
        console.log("AshaResponse", ashadeepaResponse);
        // setgetUserMappedToCircle(response.data.data)
      })
      .catch(function (error) {
        console.error("get_ashadeepa_error: " + error);
      });
  });

  getAshadeepaAvailed.map((scheme) => {
    return console.log(scheme.ashadeepa_scheme_avail_id);
  });

  useEffect(() => {
    if (
      users.getSchemesOnStatus !== undefined &&
      users.getSchemesOnStatus !== null
    ) {
      setscheme_name_ad(
        users.getSchemesOnStatus.data.recordsets[0].find(
          (o) =>
            o.scheme_avail_id === allValues.props_this.location.scheme_avail_id
        )
      );
    }
  }, [users.getSchemesOnStatus]);

  useEffect(() => {
    console.log("useeffect schemeDetails: " + schemeDetails);
    var schemeDetailsString = JSON.stringify(schemeDetails);
    console.log("useeffect schemeDetails:String " + schemeDetailsString);

    if (
      schemeDetails !== undefined &&
      schemeDetails.length > 0 &&
      schemeDetails[1].length > 0
    ) {
      schemeDetails[1].forEach((field) => {
        JSON.parse(field.document_upload_file_xml).forEach((doc) => {
          dispatch(getFile(doc.file_id, doc.file_name));
        });
      });

      console.log("useeffect schemeDetails:1 " + schemeDetails);

      setdeliveryData(schemeDetails[0].find((o) => o.field === "Gender"));
      // setAccidentDropdownData( schemeDetails[0].filter(o => o.field === 'Accident Resulted In'))
    }
  }, [schemeDetails]);

  useEffect(() => {
    if (
      users.getFile !== null &&
      users.getFile !== undefined &&
      users.getFile.fileName !== undefined
    ) {
      sessionStorage.setItem(users.getFile.fileName, users.getFile.data);
      // setAllValues({
      //     ...allValues,
      //     [users.getFile.fileName]: users.getFile.data
      // })
    }
  }, [users.getFile]);

  // useEffect(() => {
  //     console.log("useeffect schemeDetailsAll: "+JSON.stringify(schemeDetailsAll,undefined,2));
  // }, [schemeDetailsAll]);
  useEffect(() => {
    console.log(
      "useeffect UserDetails: " + JSON.stringify(UserDetails, undefined, 2)
    );
    if (
      UserDetails !== null &&
      UserDetails.personal_details !== undefined &&
      UserDetails.personal_details.length > 0
    ) {
      console.log(
        "useeffect UserDetails.personal_details : " +
          UserDetails.personal_details.length
      );
      var dateString = UserDetails.personal_details[0].date_of_birth;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format("DD-MM-YYYY");
      console.log("momentString: " + momentString);
      var age = moment().diff(momentObj, "years");
      console.log("age: " + age);

      setAge(age);

      var age60_1 = moment(momentObj).add(60, "Y");
      var age60 = age60_1.format("MMM DD YYYY");
      setAge60(age60);

      var dateString2 = UserDetails.application_details[0].application_date;
      var dateObj2 = new Date(dateString2);
      var momentObj2 = moment(dateObj2);
      var momentString2 = momentObj2.format("DD-MM-YYYY");
      console.log("momentString2: " + momentString2);

      var startDate = moment(momentString, "DD-MM-YYYY");
      var endDate = moment(momentString2, "DD-MM-YYYY");
      var yearDiff = endDate.diff(startDate, "years");

      console.log("endDate: " + endDate.format("DD MMM YYYY"));

      // var age_at_registration = moment().diff(momentString, 'years', true);
      var age_at_registration = yearDiff;
      console.log("age_at_registration: " + age_at_registration);
      setAgeAtRegistration(age_at_registration);

      var years_registered = moment().diff(momentObj2, "years", false);
      var renewal_date1 = moment(momentObj2).add(3, "Y");
      var renewal_date = renewal_date1.format("MMM DD YYYY");
      setRenewalDate(renewal_date);
      setYearsRegistered(years_registered);

      setLoadingData(false);
      setOpenBackdrop(false);
    }
    // console.log("USerStatus1", UserDetails.certificate_details[0].registration_status)
  }, [UserDetails]);

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    console.log(
      "useeffect schemeDetailsAll: " +
        JSON.stringify(schemeDetailsAll, undefined, 2)
    );
    if (
      schemeDetailsAll !== undefined &&
      schemeDetailsAll !== null &&
      schemeDetailsAll !== ""
    ) {
      console.log(schemeDetailsAll.scheme_name, "schemeDetailsAll.scheme_name");
      if (schemeDetailsAll.scheme_name !== undefined) {
        if (schemeDetailsAll.scheme_name === "BMTC Bus Pass")
          setshowVerificationDocUpload(false);
      }

      console.log("herein_is_transfer_option: ", schemeDetailsAll.scheme_id);
      if (schemeDetailsAll.is_transfer_option) {
        console.log(
          "hereinif_is_transfer_option: ",
          schemeDetailsAll.is_transfer_option
        );

        if (schemeDetailsAll.transfer_back_document_details !== null) {
          JSON.parse(schemeDetailsAll.transfer_back_document_details).forEach(
            (doc) => {
              dispatch(getFile(doc.file_id, doc.file_name));
            }
          );
        }

        var config = {
          method: "get",
          url: `${SERVER}/schemes/get_scheme_transfer_role_details?scheme_id=${schemeDetailsAll.scheme_id}&from_role_id=${users.getDeptUserRole.data[0].role_id}&board_id=${users.getDeptUserRole.data[0].board_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));

            setget_scheme_transfer_role_details(response.data.data);
            console.log(
              "setget_scheme_transfer_role_details",
              response.data.data
            );
          })
          .catch(function (error) {
            console.log(error);
          });

        if (schemeDetailsAll.transferred_date === null) {
          if (
            scheme_name_ad.role_id == 2 ||
            scheme_name_ad.role_id == 4 ||
            scheme_name_ad.role_id == 3 ||
            scheme_name_ad.role_id == 6 ||
            scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit")
          ) {
            setshowtransferButton(false);
          } else if (scheme_name_ad.scheme_name.includes("BMTC Bus Pass")) {
            setshowtransferButton(false);
          } else if (
            scheme_name_ad.scheme_name.includes(
              "Pre Coaching (UPSC and KPSC) Application"
            ) &&
            scheme_name_ad.role_id == 1
          ) {
            setshowtransferButton(true);
          } else {
            setshowtransferButton(true);
          }
        } else if (
          schemeDetailsAll.transferred_date !== null &&
          schemeDetailsAll.transfer_back_document_details !== null
        ) {
          setshowtransferButton(false);
        }

        console.log(
          "schemeDetails1234",
          schemeDetailsAll.transfer_back_document_details
        );

        var configcircle = {
          method: "get",
          url: SERVER + "/config/circle/get_circle_details",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(configcircle)
          .then(function (response) {
            console.log("get_circle_details response:", response.data);
            setcircle_details(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      console.log(
        "schemeDetailsAll.labour_user_id",
        schemeDetailsAll.labour_user_id
      );
      if (schemeDetailsAll.labour_user_id !== undefined) {
        let dataForRegDetails = {
          key: "user_id",
          value: schemeDetailsAll.labour_user_id,
          board_id: 4,
          procedure_name: "all",
        };

        dispatch(getUserRegistrationDetails(dataForRegDetails));
      }

      var dataNEW = JSON.stringify({
        board_id: 4,
        scheme_id: schemeDetailsAll.scheme_id,
        role_id: null,
        tab_id: null,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_dynamic_fields",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_dynamic_fields: " +
              JSON.stringify(response.data, undefined, 2)
          );
          // setAllValues({
          //     ...allValues,
          //     get_dynamic_fields: JSON.stringify(response.data)
          // })

          var arr1 = "";
          console.log("Responsse", response.data.data);
          var fields = response.data.data;

          const myPromises = [];

          fields.forEach((field) => {
            if (field.is_text_or_value === true) {
              myPromises.push(getFieldValues(field));
            }
          });

          Promise.all(myPromises)
            .then((promResponses) => {
              console.log("Promise Responses", promResponses);

              setAllValues({
                ...allValues,
                promResponses: promResponses,
                // promResponses: JSON.stringify(promResponses)
              });
            })
            .catch((err) => {
              console.log("Error in Resolve Promise", err);
            });
          // response.data.data.map((item) => {

          //     if(item.is_text_or_value === true){
          //         var dataNEW = JSON.stringify({
          //             "board_id": 1,
          //             "field_id": item.id
          //         });
          //         var config = {
          //             method: 'post',
          //             url: SERVER+'/schemes/get_fields_value',
          //             headers: {
          //                 'Authorization':`Bearer ${getTokenCookie()}`
          //             },
          //             data : dataNEW
          //           };

          //           axios(config)
          //           .then(function (response) {
          //             console.error("response get_fields_value: "+JSON.stringify(response.data));
          // setAllValues({
          //     ...allValues,
          //     [item.field_name]: JSON.stringify(response.data.data)
          // })
          //             arr1 = {
          //                 ...arr1,
          //                 [item.field_name]: JSON.stringify(response.data.data)
          //             }

          //             console.error("arr1: "+JSON.stringify(arr1))
          //           })
          //           .catch(function (error) {
          //             console.error(error);
          //           });
          //     }
          // })
        })
        .catch(function (error) {
          console.error(error);
        });

      if (schemeDetailsAll.scheme_id !== undefined) {
        var config = {
          method: "get",
          url: `${SERVER}/schemes/get_scheme_transfer_role_details?scheme_id=${schemeDetailsAll.scheme_id}&from_role_id=${users.getDeptUserRole.data[0].role_id}&board_id=${users.getDeptUserRole.data[0].board_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, [schemeDetailsAll]);

  useEffect(() => {
    console.log("showtransferButton: ", showtransferButton);
  }, [showtransferButton]);

  useEffect(() => {
    console.log("allValues: " + JSON.stringify(allValues, undefined, 2));
  }, [allValues]);

  const removefile = (index, docname) => {
    setloadingfiles(true);
    console.log("selectedfiles: ", selectedfiles);
    selectedfiles.splice(index, 1);
    setTimeout(() => setloadingfiles(false), 1);
  };

  const handleChangeSanc = (e) => {
    console.log("Costtt", schemeDetails[0][8].field_value);
    console.log("Costtt", schemeDetailsAll.scheme_name);

    if (
      schemeDetailsAll.scheme_name.includes("Assistance For Major Ailments") ||
      schemeDetailsAll.scheme_name.includes("Medical Assistance")
    ) {
      console.log("qqq1");
      if (users.getDeptUserRole.data[0].role_id === 2) {
        console.log("qqq2");
        if (validator.isNumeric(e.target.value)) {
          // if(e.target.value <= schemeDetails[0][8].field_value ){
          console.log("qqq3");
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
          });
          setMedNumeric(false);

          // }
          // else{
          //     console.log("qqq4")
          //     setAllValues({
          //         ...allValues,
          //         [e.target.name]: e.target.value,
          //         costError : true
          //     })

          // }
        } else {
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
          });
          setMedNumeric(true);
        }
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChange = (e) => {
    // console.log(e.target)
    console.log(
      "e.target.name: ",
      e.target.name + " e.target.value: ",
      e.target.value
    );

    var nameError = false;
    var nameNomineeError = false;
    var phoneError = false;
    var phoneNomineeError = false;

    if (e.target.name === "name") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (validator.isAlpha(e.target.value)) nameError = false;
      else nameError = true;
    } else if (e.target.name === "name_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (validator.isAlpha(e.target.value)) nameNomineeError = false;
      else nameNomineeError = true;
    } else if (e.target.name === "phone") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    } else if (e.target.name === "phone_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    } else if (e.target.name === "phone_nominee") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    }

    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value,
      nameError: nameError,
      nameNomineeError: nameNomineeError,
      phoneError: phoneError,
      phoneNomineeError: phoneNomineeError,
    });

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const handleChange1 = (event) => {
    // if (e.target.name === "amount_sanctioned") {
    //     // console.error("validate:" + validator.isNumeric(e.target.value))
    //     // console.error("phone.length:" + e.target.value.length)
    //     if (validator.isNumeric(e.target.value)){
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             amtError : false
    //         })
    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             amtError : false
    //         })
    //     }
    // }
    setAllValues({
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleClick = (event, data, selecteddoc) => {
    console.error("handleclick data:" + data);
    console.error("handleclick selecteddoc:" + selecteddoc);

    setselected_document_type(selecteddoc);

    if (data === "id_card") setAnchorElIdCard(event.currentTarget);
    // else if (data === "bank_account")
    //     setAnchorElbank_account(event.currentTarget);
    else if (data === "employee_cert")
      setAnchorElemployee_cert(event.currentTarget);
    else if (data === "marriage_cert")
      setAnchorElmarriage_cert(event.currentTarget);
  };

  const handleClose = (event, data) => {
    console.error("handleClose data:" + data);
    if (data === "id_card") setAnchorElIdCard(null);
    // else if (data === "bank_account")
    //     setAnchorElbank_account(null);
    else if (data === "employee_cert") setAnchorElemployee_cert(null);
    else if (data === "marriage_cert") setAnchorElmarriage_cert(null);
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );

      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });

    // if (event.target.checked) {
    //     setAllValues({ ...allValues,
    //         permanentData: state.residentialData
    //     }));
    // }
    // else {
    //     setAllValues({ ...allValues,
    //         permanentData: initData
    //     }));
    // }
  };

  let history = useHistory();

  const transferButtonPressed = () => {
    setLoading(true);
    console.log("transferButtonPressed");

    if (schemeDetailsAll.transferred_date === null) {
      var data = JSON.stringify({
        scheme_avail_id: schemeDetailsAll.scheme_avail_id,
        scheme_transaction_id: schemeDetailsAll.scheme_transaction_id,
        transfer_to_role_id:
          get_scheme_transfer_role_details[0].transfer_to_role_id,
        transfer_with_remarks: allValues.remarkTransfer,
        transfer_to_circle: allValues.circleSelected,
        login_user_id: users.login_admin.data.department_user_id,
      });

      var config = {
        method: "post",
        url: SERVER + "/schemes/transfer_scheme_application",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      console.log("transfer_scheme_application data: ", data);
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          showToast(
            "SUCCESS",
            "Scheme Transferred for Verification Successfully!"
          );

          setLoading(false);
          setSubmitSuccess(true);

          // allValues.props_this.history.goBack()
          allValues.props_this.history.push("/admin/dashboard");
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            "ERROR",
            "Please select the circle and provide remarks to continue."
          );

          setLoading(false);
        });
    } else if (schemeDetailsAll.transferred_date !== null) {
      if (selectedfiles.length <= 0) {
        showToast("ERROR", "Please Upload the Verification Documents!");
      } else if (allValues.commentInspector === "") {
        showToast("ERROR", "Please Fill the Comment Field!");
      } else {
        let promises = [];
        selectedfiles.forEach(async (i) => {
          var selectedblob = i;
          var reader = new FileReader();
          reader.readAsDataURL(selectedblob);
          var formdataobj = "";
          var fileName = "";
          reader.onloadend = await function (event) {
            fileName = selectedblob.name;
            var base64data = event.target.result;
            formdataobj = {
              file: base64data,
              fileType: fileName,
            };
            promises.push(uploadfile(formdataobj, fileName));
            if (promises.length === selectedfiles.length) {
              Promise.all(promises).then((results) => {
                console.log("results of promise: ");
                console.log(results);

                var filelist = [];
                results.forEach((i) => {
                  var filejson = {
                    file_id: i.image.fileId,
                    file_name: i.image.fileType,
                  };
                  filelist.push(filejson);
                });

                console.log("filelist: ", filelist);

                var data = JSON.stringify({
                  scheme_avail_id: schemeDetailsAll.scheme_avail_id,
                  scheme_transaction_id: schemeDetailsAll.scheme_transaction_id,
                  transferred_with_remarks: allValues.commentInspector,
                  transfer_back_document_details: JSON.stringify(filelist),
                  login_user_id: users.login_admin.data.department_user_id,
                });

                var config = {
                  method: "post",
                  url: SERVER + "/schemes/transfer_back_scheme_application",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };
                console.log("transfer_back_scheme_application data: ", data);
                axios(config)
                  .then(function (response) {
                    console.log(
                      "transfer_back_scheme_application response: " +
                        JSON.stringify(response.data)
                    );
                    showToast(
                      "SUCCESS",
                      "Scheme Transferred Back Successfully!"
                    );

                    setLoading(false);
                    setSubmitSuccess(true);

                    // allValues.props_this.history.goBack()
                    allValues.props_this.history.push("/admin/dashboard");
                  })
                  .catch(function (error) {
                    console.log(error);
                    showToast("ERROR", "Some Error occured: ", error.response);

                    setLoading(false);
                  });
              });
            }
          };
        });
      }
    }
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const submitButtonPressed = (e) => {
    console.log("isFinalApproval1", isFinalApproval);
    console.log("isFinalApproval11", isAmountSanctioned);
    console.log("isFinalApproval123", schemeDetailsAll);

    console.log(
      "schemeDetails.transfer_back_document_details1",
      schemeDetailsAll.transfer_back_document_details
    );
    console.log(
      "schemeDetails.transfer_back_document_details2",
      schemeDetailsAll.verification_document_id
    );
    // console.log("schemeDetails.transfer_back_document_details3", selectedfiles.length <= 0)
    // console.log("schemeDetails.transfer_back_document_details4", schemeDetailsAll.transfer_back_document_details)

    // else if(schemeDetailsAll.verification_document_id === null && schemeDetailsAll.transfer_back_document_details !== null || schemeDetailsAll.verification_document_id === null &&  schemeDetailsAll.transfer_back_reason_remarks === null  && schemeDetailsAll.transfer_back_document_details === null ){

    //     if(labour_name1 === 1 ){
    //         showToast("ERROR", "Please Upload the Verification Documents!")

    //     }

    // }

    // else if(schemeDetailsAll.scheme_name.includes('Assistance For Major ailments') || schemeDetailsAll.scheme_name.includes('Medical Assistance')){
    //     console.log("INLOOPSanCt")
    //     if(allValues.costError > sanctionedAmountMed){
    //             console.log("INLOOPSanCt", allValues.costError)
    //             console.log("INLOOPSanCt", schemeDetails[0][8].field_value)
    //             console.log("INLOOPSanCt12", allValues.costError > schemeDetails[0][8].field_value)

    //             showToast("ERROR", "Please enter the amount to be sanctioned correctly")
    //         }

    // }

    console.log(isFinalApproval, "isFinalApproval");

    console.log("schemeName22", schemeDetailsAll.scheme_name);

    if (allValues.commentInspector !== "") {
      // console.log("Inloop12",schemeDetailsAll)
      // console.log("Inloop12",users.getSchemesOnStatus.data.recordsets[0][0].transfer_back_document_details)
      // if(schemeDetailsAll.scheme_name.includes("Marriage Assistance")){
      //     console.log("inside Marriage")

      //      if(schemeDetailsAll.transfer_back_document_details !== undefined && schemeDetailsAll.transfer_back_document_details !== null ){
      //     var data = JSON.stringify({
      //         "schemes_availed_transaction_id": schemeDetailsAll.scheme_transaction_id,
      //         "catalog_value_status_id": 47,
      //         "description": allValues.commentInspector,
      //         "board_id": 4,
      //         "login_user_id": users.login_admin.data.department_user_id,
      //         "verified_document_id": schemeDetailsAll.transfer_back_document_details || schemeDetailsAll.verification_document_id,
      //         "sanctioned_amount": null
      //     });
      //     console.log("Data to approve_reject_scheme: ", data)
      //     var config = {
      //         method: 'post',
      //         url: SERVER + '/schemes/approve_reject_scheme',
      //         headers: {
      //             'Authorization': `Bearer ${getTokenCookie()}`
      //         },
      //         data: data

      //     };

      //     axios(config)
      //         .then(function (response) {
      //             console.log("approve_reject_scheme response: " + JSON.stringify(response.data));

      //             showToast("SUCCESS", "Scheme Approved Successfully!")

      //             // allValues.props_this.history.goBack()
      //             // let history = useHistory();
      //             // history.goBack()
      //             allValues.props_this.history.push("/admin/dashboard")

      //             setLoading(false)
      //             setSubmitSuccess(true)

      //         })
      //         .catch(function (error) {
      //             console.error("approve_reject_scheme error: " + error);
      //         });
      // }
      // else{
      //     let promises = [];
      // selectedfiles.forEach(async (i) => {
      //     var selectedblob = i
      //     var reader = new FileReader();
      //     reader.readAsDataURL(selectedblob);
      //     var formdataobj = ""
      //     var fileName = ""
      //     reader.onloadend = await function (event) {
      //         fileName = selectedblob.name;
      //         var base64data = event.target.result;
      //         formdataobj = {
      //             'file': base64data,
      //             'fileType': fileName
      //         }
      //         promises.push(uploadfile(formdataobj, fileName))
      //         if (promises.length === selectedfiles.length) {
      //             Promise.all(promises)
      //                 .then(results => {
      //                     console.log("results of promise: ")
      //                     console.log(results)

      //                     var filelist = []
      //                     results.forEach((i) => {
      //                         var filejson = {
      //                             "file_id": i.image.fileId,
      //                             "file_name": i.image.fileType
      //                         }
      //                         filelist.push(filejson)
      //                     })

      //                     console.log("filelist: ", filelist)

      //                     var data = JSON.stringify({
      //                         "schemes_availed_transaction_id": schemeDetailsAll.scheme_transaction_id,
      //                         "catalog_value_status_id": 47,
      //                         "description": allValues.commentInspector,
      //                         "board_id": 1,
      //                         "login_user_id": users.login_admin.data.department_user_id,
      //                         "verified_document_id": JSON.stringify(filelist),
      //                         "sanctioned_amount": null
      //                     });
      //                     console.log("Data to approve_reject_scheme: ", data)
      //                     var config = {
      //                         method: 'post',
      //                         url: SERVER + '/schemes/approve_reject_scheme',
      //                         headers: {
      //                             'Authorization': `Bearer ${getTokenCookie()}`
      //                         },
      //                         data: data
      //                     };

      //                     axios(config)
      //                         .then(function (response) {
      //                             console.log("approve_reject_scheme response: " + JSON.stringify(response.data));

      //                             showToast("SUCCESS", "Scheme Approved Successfully!")

      //                             // const approvalResponse = response.data.data;
      //                             // let xmldata = '';
      //                             // if (approvalResponse.hasOwnProperty('is_final') && approvalResponse.hasOwnProperty('xml')) {
      //                             //     if (approvalResponse.is_final) {
      //                             //         xmldata = approvalResponse.xml;
      //                             //         var xmltext = xmldata;
      //                             //         var filename = approvalResponse.xml_file_name
      //                             //         let replacedFileName = filename.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      //                             //         replacedFileName = `${replacedFileName}.xml`;
      //                             //         console.log('Replaced Filename', replacedFileName);
      //                             //         var pom = document.createElement('a');
      //                             //         var bb = new Blob([xmltext], { type: 'application/xml' });

      //                             //         pom.setAttribute('href', window.URL.createObjectURL(bb));
      //                             //         pom.setAttribute('download', replacedFileName);

      //                             //         pom.dataset.downloadurl = ['application/xml', pom.download, pom.href].join(':');
      //                             //         pom.draggable = true;
      //                             //         pom.classList.add('dragout');

      //                             //         pom.click();

      //                             //     }
      //                             // }
      //                             // allValues.props_this.history.goBack()
      //                             // let history = useHistory();
      //                             // history.goBack()
      //                             allValues.props_this.history.push("/admin/dashboard")

      //                             setLoading(false)
      //                             setSubmitSuccess(true)

      //                         })
      //                         .catch(function (error) {
      //                             console.error("approve_reject_scheme error: " + error);
      //                         });
      //                 })
      //         }
      //     }

      // })

      if (schemeDetailsAll.transfer_back_document_details === null) {
        console.log("inside Thayi");

        console.log(
          "BusPAssenter",
          schemeDetailsAll.transfer_back_document_details
        );
        if (
          schemeDetailsAll.transfer_back_document_details !== undefined &&
          schemeDetailsAll.transfer_back_document_details === null
        ) {
          var data = JSON.stringify({
            schemes_availed_transaction_id:
              schemeDetailsAll.scheme_transaction_id,
            catalog_value_status_id: 47,
            description: allValues.commentInspector,
            board_id: 4,
            login_user_id: users.login_admin.data.department_user_id,
            verified_document_id:
              schemeDetailsAll.transfer_back_document_details ||
              schemeDetailsAll.verification_document_id,
            sanctioned_amount: null,
          });
          console.log("Data to approve_reject_scheme: ", data);
          var config = {
            method: "post",
            url: SERVER + "/schemes/approve_reject_scheme",
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(
                "approve_reject_scheme response: " +
                  JSON.stringify(response.data)
              );

              showToast("SUCCESS", "Scheme Approved Successfully!");

              // allValues.props_this.history.goBack()
              // let history = useHistory();
              // history.goBack()
              allValues.props_this.history.push("/admin/dashboard");

              setLoading(false);
              setSubmitSuccess(true);
            })
            .catch(function (error) {
              console.error("approve_reject_scheme error: " + error);
            });
        }
      } else {
        let promises = [];
        selectedfiles.forEach(async (i) => {
          var selectedblob = i;
          var reader = new FileReader();
          reader.readAsDataURL(selectedblob);
          var formdataobj = "";
          var fileName = "";
          reader.onloadend = await function (event) {
            fileName = selectedblob.name;
            var base64data = event.target.result;
            formdataobj = {
              file: base64data,
              fileType: fileName,
            };
            promises.push(uploadfile(formdataobj, fileName));
            if (promises.length === selectedfiles.length) {
              Promise.all(promises).then((results) => {
                console.log("results of promise: ");
                console.log(results);

                var filelist = [];
                results.forEach((i) => {
                  var filejson = {
                    file_id: i.image.fileId,
                    file_name: i.image.fileType,
                  };
                  filelist.push(filejson);
                });

                console.log("filelist: ", filelist);

                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: 47,
                  description: allValues.commentInspector,
                  board_id: 4,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id: JSON.stringify(filelist),
                  sanctioned_amount: null,
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    // const approvalResponse = response.data.data;
                    // let xmldata = '';
                    // if (approvalResponse.hasOwnProperty('is_final') && approvalResponse.hasOwnProperty('xml')) {
                    //     if (approvalResponse.is_final) {
                    //         xmldata = approvalResponse.xml;
                    //         var xmltext = xmldata;
                    //         var filename = approvalResponse.xml_file_name
                    //         let replacedFileName = filename.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
                    //         replacedFileName = `${replacedFileName}.xml`;
                    //         console.log('Replaced Filename', replacedFileName);
                    //         var pom = document.createElement('a');
                    //         var bb = new Blob([xmltext], { type: 'application/xml' });

                    //         pom.setAttribute('href', window.URL.createObjectURL(bb));
                    //         pom.setAttribute('download', replacedFileName);

                    //         pom.dataset.downloadurl = ['application/xml', pom.download, pom.href].join(':');
                    //         pom.draggable = true;
                    //         pom.classList.add('dragout');

                    //         pom.click();

                    //     }
                    // }
                    // allValues.props_this.history.goBack()
                    // let history = useHistory();
                    // history.goBack()
                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              });
            }
          };
        });
      }
    }

    // else {
    //     showToast("ERROR", "Please Fill the Comment Field!")
    //     console.log("approve_reject_scheme1 ", isFinalApproval);

    //     console.log("approve_reject_scheme  ", isAmountSanctioned);

    // }

    console.log(
      "INLOOP412",
      users.getSchemesOnStatus.data.recordsets[0][0]
        .transfer_back_document_details
    );

    console.log("INLOOP4", users.getSchemesOnStatus.data.recordsets);
  };

  const rejectButtonPressed = (e) => {
    // if (allValues.commentInspector !== "") {
    //     setOpenBackdrop(true);
    setLoading(true);
    setSubmitSuccess(false);

    var data = JSON.stringify({
      schemes_availed_transaction_id: schemeDetailsAll.scheme_transaction_id,
      catalog_value_status_id: 46,
      description: allValues.commentInspector,
      board_id: 4,
      login_user_id: users.login_admin.data.department_user_id,
    });
    console.log("Data to approve_reject_scheme: ", data);
    var config = {
      method: "post",
      url: SERVER + "/schemes/approve_reject_scheme",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "approve_reject_scheme response: " + JSON.stringify(response.data)
        );

        showToast("SUCCESS", "Scheme Rejected Successfully!");

        setLoading(false);
        setSubmitSuccess(true);

        // allValues.props_this.history.goBack()
        allValues.props_this.history.push("/admin/dashboard");
      })
      .catch(function (error) {
        console.error("approve_reject_scheme error: " + error);
      });

    // }
    // else {
    //     showToast("ERROR", "Please Fill the Comment Field!")
    // }
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    input.style.display = "block";
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("acknowledgement.pdf");
        window.open(pdf.output("bloburl"), "_blank");
      })
      .then(() => {
        input.style.display = "none";
      });
  };

  //   function returnComponent() {
  //     if (schemeDetails !== undefined) {

  //         return (schemeDetails[0].map((field, i) => {
  //             console.log("schemeDetails-> field:" + JSON.stringify(field))
  //             switch (field.field_type) {
  //                 case "Dropdown": case "Radio Button":
  //                     console.log("in drop")
  //                     return (
  //                         <Col xs={12} md={4} >
  //                             <Required className="mb-2 mt-4" title={field.field_name} />
  //                             <FormControl variant="outlined" fullWidth className="formcontrol5">
  //                                 <Select
  //                                     className="select-marital"
  //                                     labelId="demo-simple-select-required-label"
  //                                     id="demo-simple-select-required"
  //                                     // value={allValues.marriage_assistance_for}
  //                                     // name="marriage_assistance_for"
  //                                     name={field.field}
  //                                     value={field.field_value}
  //                                     displayEmpty
  //                                     onChange={handleChange}
  //                                     disabled
  //                                 >
  //                                     <MenuItem value={field.field_value}>
  //                                         <ListItemText primary={field.field_value} />
  //                                     </MenuItem>

  //                                     {/* {
  //                                         allValues.promResponses.map((i) =>
  //                                             i.fieldName === field.field_name ?
  //                                                 i.data.data.map((j) =>
  //                                                     <MenuItem value={j.id}>
  //                                                         <ListItemText primary={j.value} />
  //                                                     </MenuItem>
  //                                                 )
  //                                                 :
  //                                                 null

  //                                         )
  //                                     } */}
  //                                 </Select>
  //                             </FormControl>
  //                         </Col>
  //                     )

  //                 case "Text":
  //                     console.log("in text")
  //                     return (
  //                         <Col xs={12} md={4} >
  //                             <Required className="mb-2 mt-4" title={field.field_name} />
  //                             <FormControl fullWidth className="formcontrol1">
  //                                 <TextField
  //                                     variant="outlined"
  //                                     // placeholder="Enter Bank Name"
  //                                     name={field.field}
  //                                     value={field.field_value}
  //                                     onChange={handleChange}
  //                                     disabled
  //                                 />
  //                             </FormControl>
  //                         </Col>
  //                     )
  //                 case "Date":
  //                     console.log("in text")
  //                     return (
  //                         <Col xs={12} md={4} >
  //                             <Required className="mt-4 mb-2" title={field.field_name} />
  //                             <MuiPickersUtilsProvider utils={MomentUtils} >
  //                                 <KeyboardDatePicker
  //                                     onClick={() => setPickerStatus(true)}
  //                                     onClose={() => setPickerStatus(false)}
  //                                     open={allValues.openDatePicker}
  //                                     className="datepicker"
  //                                     margin="normal"
  //                                     format="D/MM/yyyy"
  //                                     value={dob}
  //                                     onChange={(date) => { setDob(date) }}
  //                                     placeholder="DD/MM/YYYY"
  //                                     KeyboardButtonProps={{
  //                                         'aria-label': 'change date',
  //                                     }}

  //                                 />
  //                             </MuiPickersUtilsProvider>
  //                         </Col>
  //                     )
  //                 default:
  //                     console.log("in default")
  //                     return <>Blank</>
  //             }
  //         })
  //         )
  //     }
  // }

  //   const dynamicFields = () => {
  //     return returnComponent();
  // }

  var date = new Date();

  return (
    <>
      <div className='root'>
        {/* {
                    loading ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : null
                } */}

        {/* {
                    submitSuccess ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <div style={{display: "block"}}>
                                <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "200px"}}/>
                                <p className="final-success-title" >Successfully submitted for<br/>Scheme!</p>

                                
                            </div>

                            
                        </Backdrop>
                        : null
                } */}

        {/* <CssBaseline /> */}
        <Row className='top-div schemes'>
          <Row className='topbar-row'>
            <Col xs={12} md={8} lg={9} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/admin/dashboard' className='cal-icon-hover-icn'>
                  <img alt='...' className='cal-icon hover-icn' src={back} />
                </a>
                <img id='logo' src={logo} alt='...' className='logo-img' />
                <h1 className='logo-text'>
                  Karnataka Building & Other Construction Workers Welfare
                  Board(K.B.O.C.W.W.B)
                  <br />
                  Government of Karnataka
                </h1>
              </div>
            </Col>

            <Col>
              <div className='usericon-header-logout'>
                <img alt='...' src={usericon} className='usericon-img' />
                <p>{users.login_admin.data.first_name}</p>
                <Tooltip title='Logout' placement='top-start' arrow interactive>
                  <img
                    alt='...'
                    src={logout}
                    className='logout-img'
                    onClick={() => {
                      props.history.push("/admin");
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      {loadingData ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          // onClick={loadingData ? null : handleCloseBackdrop}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <Row className='schemes-title-row'>
            <Col xs={12} md={9}>
              <h1>Schemes | {schemeDetailsAll.scheme_name}</h1>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              {/* <Select
                                    className="select-language"
                                    style={{ width: '100%' }}
                                    variant="outlined"
                                    labelId="demo-simple-select-required-label"
                                    // value={allValues.language}
                                    value={""}
                                    name="language"
                                    displayEmpty
                                // onChange={handleChange}
                                >
                                    <MenuItem value="" >
                                        <ListItemIcon>
                                            <img alt="..." src={usericn} className="language-img" />
                                        </ListItemIcon>
                                        <ListItemText primary={users.login_admin.data.first_name} />
                                    </MenuItem>
                                    <MenuItem value="logout" onClick={() => { allValues.props_this.history.push("/admin") }}>
                                        <ListItemText primary="Logout" />
                                    </MenuItem>
                                </Select> */}
            </Col>
          </Row>

          <Row className='scheme-subtitle-row mt-4'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Applicant Details</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              {/* <Required className="mb-2" title="Registration Number" /> */}
              <p className='mb-2'>Name of the Applicant </p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={schemeDetailsAll.labour_name}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              {/* <Required className="mb-2" title="Registration Number" /> */}
              <p className='mb-2'>Date of birth</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0
                      ? moment(
                          UserDetails.personal_details[0].date_of_birth
                        ).format("DD/MM/YYYY")
                      : ""
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              {/* <Required className="mb-2" title="Registration Number" /> */}
              <p className='mb-2'>Age of Applicant</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={Age} disabled />
              </FormControl>
            </Col>

            <Col xs={12} md={4}>
              {/* <Required className="mb-2" title="Registration Number" /> */}
              <p className='mb-2 mt-4'>Mobile Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    UserDetails.personal_details[0].mobile_no
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Address Where Applicant is Registered</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>State</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].state}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>District</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].district}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Taluk</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].taluk}`
                  }
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Gram Panchayath</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].panhayat_city_town}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Village / Ward</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].ward_area_village}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Bank Details</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Bank Name</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].bank_name}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Account Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].account_no}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Branch Name</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].bank_branch}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>IFSC Code</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].ifsc_code}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          {/* <Row className="scheme-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Registration Details</p>
                            </Col>
                        </Row> */}

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Registration Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    `${UserDetails.personal_details[0].registration_code}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    moment(
                      `${UserDetails.application_details[0].application_date}`
                    ).format("MMM DD YYYY")
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Completion of 60 Years</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={Age60} disabled />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Age at the Time of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={AgeAtRegistration}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Number of years Registered</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={YearsRegistered}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Renewal date of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={RenewalDate} disabled />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>{schemeDetailsAll.scheme_name}</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            {/* {
                    schemeDetails !== undefined ?
                        dynamicFields()
                        : <>Please Wait! Fetching...</>
                } */}

            {schemeDetails !== undefined &&
              schemeDetails[0] !== undefined &&
              schemeDetails[0].map((field) => (
                <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                  <p className='mb-2 mt-4'>{field.field} </p>
                  <FormControl fullWidth className='formcontrol1'>
                    <TextField
                      variant='outlined'
                      // value={field.field_value}
                      value={
                        field.field.includes("Date")
                          ? moment(field.field_value).format("DD-MM-YYYY")
                          : field.field_value
                      }
                      disabled
                    />
                  </FormControl>
                </Col>
              ))}
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Documents</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            {schemeDetails !== undefined &&
              schemeDetails[1].map((item) => (
                <Col xs={6} className='final-personal-col-1 px-3 mt-4'>
                  <div className=''>
                    <p className='mt-4 mb-2'>{item.document_type}</p>
                    {item.document_upload_file_xml !== undefined &&
                      JSON.parse(item.document_upload_file_xml).map((doc) => (
                        <>
                          <div className='browsebutton2-div'>
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name={doc.file_name}
                                value={doc.file_name}
                                disabled
                              />
                            </FormControl>

                            <input
                              type='file'
                              name='photo'
                              id={`upload-photo_${doc.file_id}`}
                            />

                            <label
                              className='schemes-doc-view'
                              onClick={(e) =>
                                handleClick(e, "marriage_cert", doc.file_name)
                              }
                            >
                              <Visibility />
                            </label>
                          </div>
                        </>
                      ))}
                  </div>
                </Col>
              ))}

            <Popover
              id={idmarriage_cert}
              open={openmarriage_cert}
              anchorEl={anchorElmarriage_cert}
              onClose={(e) => handleClose(e, "marriage_cert")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <a href='#'>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={sessionStorage.getItem(selected_document_type)}
                  // src={allValues.selected_document_type}

                  onClick={() => {
                    // var win = window.open();
                    // win.document.write('<iframe src="' + sessionStorage.getItem(selected_document_type) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                    const base64ImageData = `${sessionStorage.getItem(
                      selected_document_type
                    )}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </a>
            </Popover>
          </Row>

          {/* setget_scheme_status */}

          {/* <Row className="scheme-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Remarks</p>
                            </Col>
                        </Row> */}

          {/* <Row className="form-row mt-4 config-form searchform">
                            <Col xs={12} className=" schemes mt-4 bank-col-5">
                                {
                                    get_scheme_status !== undefined && 
                                    // get_scheme_status.length > 0 && 
                                    get_scheme_status.data.recordsets[1].map((field) =>
                                        <>
                                            {
                                                field.scheme_avail_transaction_id !== null &&
                                                    field.description !== null ?

                                                    <p><b>{field.level_approval_name}:&nbsp;&nbsp;   </b> {field.description}</p>

                                                    :
                                                    null
                                            }
                                        </>
                                    )
                                }
                            </Col>
                        </Row> */}

          {isAmountSanctioned && isFinalApproval ? (
            <>
              {/* {console.log(statusActiveLi,"inallValues.statusActiveLi")} */}
              {schemeDetailsAll.scheme_avail_status === "Approved" ? (
                <>
                  <Row className='scheme-subtitle-row'>
                    <Col xs={12} className='searchform-subtitle'>
                      <p> Amount to be sanctioned</p>
                    </Col>
                  </Row>

                  <Row className='form-row mt-4 config-form searchform'>
                    <Col xs={12} className=' amount-sanctioned mt-2 bank-col-5'>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextareaAutosize
                          variant='outlined'
                          multiline
                          name='sanctioned_amount'
                          placeholder='Enter Your Amount'
                          value={schemeDetailsAll.sanctioned_Amount}
                          disabled
                          // helperText = {allValues.costError ? "Amount is more than Labour's Cost" : ""}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  {console.log(schemeDetailsAll, "statusActiveLi")}

                  <Row className='scheme-subtitle-row'>
                    <Col xs={12} className='searchform-subtitle'>
                      <p> Amount to be sanctioned</p>
                    </Col>
                  </Row>

                  <Row className='form-row mt-4 config-form searchform'>
                    <Col xs={12} className=' amount-sanctioned mt-2 bank-col-5'>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextareaAutosize
                          variant='outlined'
                          multiline
                          name='sanctioned_amount'
                          placeholder='Enter Your Amount'
                          value={allValues.sanctioned_amount}
                          onChange={handleChangeSanc}
                          // helperText = {allValues.costError ? "Amount is more than Labour's Cost" : ""}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </>
              )}
            </>
          ) : null}

          {schemeDetailsAll.transfer_reason_remarks !== undefined &&
          schemeDetailsAll.transfer_reason_remarks !== null ? (
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Remark While Tranferring</p>
                </Col>
              </Row>

              <Row className='form-row config-form searchform'>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <FormControl fullWidth className='formcontrol1'>
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={4}
                        placeholder='Remark While Tranferring Back'
                        value={schemeDetailsAll.transfer_reason_remarks}
                        disabled
                      />
                    </FormControl>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}

          {schemeDetailsAll.transfer_back_document_details !== undefined &&
          schemeDetailsAll.transfer_back_document_details !== null ? (
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Transferred Back Document and Remark</p>
                </Col>
              </Row>
              <Row className='form-row config-form searchform'>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <p className='mb-2'>Forwarded Document</p>
                    {schemeDetailsAll.transfer_back_document_details !==
                      undefined &&
                      JSON.parse(
                        schemeDetailsAll.transfer_back_document_details
                      ).map((doc) => (
                        <>
                          <div className='browsebutton2-div'>
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name={doc.file_name}
                                value={doc.file_name}
                                disabled
                              />
                            </FormControl>

                            <input
                              type='file'
                              name='photo'
                              id={`upload-photo_${doc.file_id}`}
                            />

                            <label
                              className='schemes-doc-view'
                              onClick={(e) =>
                                handleClick(e, "marriage_cert", doc.file_name)
                              }
                            >
                              <Visibility />
                            </label>
                          </div>
                        </>
                      ))}
                  </div>
                </Col>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <p className='mb-2'>Remark While Tranferring Back</p>
                    <FormControl fullWidth className='formcontrol1'>
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={3}
                        placeholder='Remark While Tranferring Back'
                        value={schemeDetailsAll.transfer_back_reason_remarks}
                        disabled
                      />
                    </FormControl>
                  </div>
                </Col>

                <Popover
                  id={idmarriage_cert}
                  open={openmarriage_cert}
                  anchorEl={anchorElmarriage_cert}
                  onClose={(e) => handleClose(e, "marriage_cert")}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <a href='#'>
                    <img
                      alt='...'
                      className='avatar border-gray'
                      // id={item.id}
                      src={sessionStorage.getItem(selected_document_type)}
                      // src={allValues.selected_document_type}

                      onClick={() => {
                        // var win = window.open();
                        // win.document.write('<iframe src="' + sessionStorage.getItem(selected_document_type) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                        const base64ImageData = `${sessionStorage.getItem(
                          selected_document_type
                        )}`;
                        const contentType = "image/jpeg";

                        const byteCharacters = atob(
                          base64ImageData.substr(
                            `data:${contentType};base64,`.length
                          )
                        );
                        const byteArrays = [];

                        for (
                          let offset = 0;
                          offset < byteCharacters.length;
                          offset += 1024
                        ) {
                          const slice = byteCharacters.slice(
                            offset,
                            offset + 1024
                          );

                          const byteNumbers = new Array(slice.length);
                          for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                          }

                          const byteArray = new Uint8Array(byteNumbers);

                          byteArrays.push(byteArray);
                        }
                        const blob = new Blob(byteArrays, {
                          type: contentType,
                        });
                        const blobUrl = URL.createObjectURL(blob);

                        window.open(blobUrl, "_blank");
                      }}
                    />
                  </a>
                </Popover>
              </Row>
            </>
          ) : null}

          {/* {schemeDetailsAll.transfer_back_document_details === null ? 
                        <Row className="scheme-subtitle-row">
                            <Col xs={12} className="searchform-subtitle">
                                <p>Verification Document</p>
                            </Col>
                        </Row> :
                         null} */}

          {allValues.props_this.location.showbuttons ? (
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Comment</p>
                </Col>
              </Row>

              <Row className='form-row mt-4 config-form searchform'>
                <Col xs={12} className='note2 schemes mt-4 bank-col-5'>
                  <FormControl fullWidth className='formcontrol1'>
                    <TextareaAutosize
                      variant='outlined'
                      multiline
                      rows={4}
                      name='commentInspector'
                      placeholder='Enter Your Comment'
                      value={allValues.commentInspector}
                      onChange={handleChange}
                    />
                  </FormControl>
                </Col>
              </Row>
            </>
          ) : null}

          <Row className='search-subtitle-row pb-5'>
            {/* <Col xs={12} className="searchform-subtitle">
                    <div className="submit-button-div centerdiv">
                        <Link to="#"
                            // onClick={nextButtonPressed}
                            // style={{ flexGrow: "1" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Submit
                            </Button>
                        </Link>
                    </div>
                </Col> */}

            {allValues.props_this.location.showbuttons ? (
              <Col xs={12} className='next-back-button-row mt-4'>
                <Link
                  to='#'
                  onClick={rejectButtonPressed}
                  style={{ flexGrow: "0.5" }}
                >
                  {rejectButton ? (
                    <Button variant='outline-primary' className='back-button'>
                      Reject
                    </Button>
                  ) : null}
                </Link>

                {showtransferButton ? (
                  <Link
                    to='#'
                    onClick={() => handleClickOpenTransfer()}
                    style={{ flexGrow: "0.5" }}
                  >
                    <Button variant='outline-primary' className='back-button'>
                      Transfer for verification
                    </Button>
                  </Link>
                ) : null}

                <Dialog open={opentransfer} onClose={handleCloseTransfer}>
                  <DialogTitle>Transfer for Verification</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please select the circle to transfer this application for
                      verification.
                    </DialogContentText>
                    <FormControl
                      variant='outlined'
                      fullWidth
                      className='formcontrol5'
                    >
                      <Select
                        className='select-language mt-2 mb-2'
                        style={{ width: "50%" }}
                        variant='outlined'
                        labelId='demo-simple-select-required-label'
                        value={allValues.circleSelected}
                        name='circleSelected'
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value=''>
                          <ListItemText primary='-Select Circle-' />
                        </MenuItem>
                        {circle_details !== null &&
                          circle_details.length > 0 &&
                          circle_details.map((i) => (
                            <MenuItem value={i.id} disabled={!i.isactive}>
                              {console.log("circle_name", i.name)}
                              {/* {console.log("circle_name1", users.inspectorDetails[0].circle+'\r')} */}
                              {scheme_name_ad.circle_name === i.name ? null : (
                                <ListItemText primary={i.name} />
                              )}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextareaAutosize
                      variant='outlined'
                      multiline
                      rows={3}
                      style={{
                        width: "500px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                      placeholder='Remarks'
                      name='remarkTransfer'
                      value={allValues.remarkTransfer}
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant='outline-danger'
                      onClick={handleCloseTransfer}
                    >
                      Cancel
                    </Button>
                    <Button variant='success' onClick={transferButtonPressed}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                {!showtransferBackButton ? (
                  <Link
                    to='#'
                    onClick={() => submitButtonPressed()}
                    style={{ flexGrow: "0.5" }}
                  >
                    {users.getDeptUserRole.data[0].role_id === 3 ? (
                      <Button variant='outline-primary' className='save-button'>
                        Approve
                      </Button>
                    ) : (
                      <Button variant='outline-primary' className='save-button'>
                        Forward
                      </Button>
                    )}
                  </Link>
                ) : null}

                {showtransferBackButton ? (
                  <Link
                    to='#'
                    onClick={() => transferButtonPressed()}
                    style={{ flexGrow: "0.5" }}
                  >
                    <Button variant='outline-primary' className='save-button'>
                      Transfer Back for verification
                    </Button>
                  </Link>
                ) : null}
              </Col>
            ) : null}
          </Row>
        </>
      )}

      {/* ********************   Acknowledgement Letter HTML ************************ */}
      <div>
        {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
        <div
          id='divToPrint'
          className='mt-4'
          style={{
            backgroundColor: "#fff",
            width: "210mm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            // justifyContent: 'center',
            display: "none",
            // border: '2px solid black',
          }}
        >
          <div className='logo-div-ack'>
            <a href='/dashboard-user'>
              <img id='logo' src={logo} alt='...' className='logo-img' />
            </a>{" "}
            <h1 className='logo-text'>
              <Translate value='header.title' /> <br />
              <Translate value='header.sub' />
            </h1>
            <p className='copyright'>Government of Karnataka</p>
          </div>

          <Row className='ack-subtitle-row'>
            <Col xs={12} className='profile-title'>
              <h1>Acknowledgement</h1>
            </Col>
          </Row>

          <p className='ack-success'>
            <img
              src={checkGreen}
              style={{ height: "auto", width: "28px", marginRight: "10px" }}
            />
            {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
            Application Status : Successfully submitted to Labour Inspector
          </p>

          <Row className='ack-table-row mt-5'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Application Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll.scheme_avail_id}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Application Date</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>
                <Moment format='DD MMMM YYYY'>{date}</Moment>
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Requested Service</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll.scheme_name}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Applicant's Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll.labour_name}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Residential Address</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{presentAddress}</p> */}
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Village Ward</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{villageWard}</p> */}
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Mobile Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{mobile}</p> */}
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Occupation (Category)</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{occupation}</p> */}
            </Col>
          </Row>

          {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Circle Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll.circle_name}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Labour Inspector Incharge</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{officerIncharge}</p> */}
            </Col>
          </Row>

          <Row className='ack-table-row-last mt-5'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col-note'>
              <p>NOTE :</p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>1. Submitted application is subject to verification.</p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>2. Login to application to know the status.</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SchemeForm;
