import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

export const ConvertToBase64API = async (imageID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/upload/download_base_64/${imageID}`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
