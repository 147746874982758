
import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, MenuItem, ListItemIcon, ListItemText, CircularProgress, FormControlLabel, Checkbox, makeStyles, Backdrop, Popover, TextareaAutosize } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import checkGreen from 'assets/images/Tick-01.png'
import closeicon from 'assets/images/closeIcon.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import Moment from 'react-moment';
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import usericon from 'assets/images/Icon awesome-user.svg'
import { getUserRegistrationDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link, useParams } from 'react-router-dom';
import { Required, showToast, getTokenCookie, dataURLtoFile } from 'utils/tools';
import { CheckCircle, Visibility } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Translate } from 'react-redux-i18n';
import Button2 from '@material-ui/core/Button';
import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import validator from 'validator';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { finalSubmit } from 'store/actions';
import { isNull } from 'util';
import close from 'assets/images/close-01.svg';
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from '@mui/icons-material/Add';
const AshadeepaformApproveDetails = (props) => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const i18nlang = useSelector(state => state.i18n)
    const hiddenFileInput = React.useRef(null);
    const [lang,setLang] = useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })

    const [getProps, setProps] = useState('')
    // setProps(props.location.catalog_value_id)
    // console.log("CatalogValue:",getProps);
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        props_this:props,
        company_name:"",
        company_name_error:false,
        nature_of_business:"",
        nature_of_business_error:false,
        name_of_md:"",
        name_of_md_error:false,
        contact_number:"",
        contact_number_error:false,
        email_id:"",
        email_id_error:false,
        registration_cert_no:"",
        registration_cert_no_error:false,
        local_address:"",
        local_address_error:false,
        name_of_hr:"",
        name_of_hr_error:false,
        hr_contact_number:"",
        hr_contact_number_error:false,
        hr_email_id:"",
        hr_email_id_error:false,
        commentInspector:''
    

        // category_name:"",
        // category_name_error:false,
        // total_no_of_posts:"",
        // total_no_of_posts_error:false,
        // no_of_posts_filled:"",
        // no_of_posts_filled_error:false,
        // no_of_posts_vacant:"",
        // no_of_posts_vacant_error:false,
        // no_of_ashadeepa:"",
        // no_of_ashadeepa_error:false,
        // salary:"",
        // salary_error:false,
        // no_of_ESI:"",
        // no_of_ESI_error:false,
        // no_of_EPF:"",
        // no_of_EPF_error:false,

        // name_of_apprenticeship:"",
        // name_of_apprenticeship_error:false,
        // no_of_apprenticeship:"",
        // no_of_apprenticeship_error:false,
        // vacancy:"",
        // vacancy_error:false,
        // wages:"",
        // wages_error:false,
        // remarks:"",
        // remarks_error:false,
})

console.log("Props:",allValues.props_this.location.scheme_avail_id);
console.log("PropsCatalog:",allValues.props_this.location.catalog_value_id);
const [cat0Values, setCat0Values] = useState([{
        category_name:"",
        category_name_error:false,
        total_no_of_posts:"",
        total_no_of_posts_error:false,
        no_of_posts_filled:"",
        no_of_posts_filled_error:false,
        no_of_posts_vacant:"",
        no_of_posts_vacant_error:false,
        no_of_ashadeepa:"",
        no_of_ashadeepa_error:false,
        salary:"",
        salary_error:false,
        no_of_ESI:"",
        no_of_ESI_error:false,
        no_of_EPF:"",
        no_of_EPF_error:false,
    }])

    const [cat1Values, setCat1Values] = useState([{
        name_of_apprenticeship:"",
        name_of_apprenticeship_error:false,
        no_of_apprenticeship:"",
        no_of_apprenticeship_error:false,
        vacancy:"",
        vacancy_error:false,
        wages:"",
        wages_error:false,
        remarks:"",
        remarks_error:false,

    }])
        
    
    var timestamp = [Date.now()];
    var randNum = [Math.floor(Math.random() * 100)];
    var unique_id = timestamp.concat(randNum)
    var new_unique_id = unique_id.join("")
    console.log("RandNum:",randNum);
    console.log("TimeStamp:",timestamp);
    console.log("Unique:",unique_id);
    console.log("New:",new_unique_id);
    



    const inputRef = useRef(null);
    const [categoryType,setCategoryType] = useState('');
    const [showCategory,setShowCategory] = useState(false);
    const [addDiv, setAddDiv] = useState('');
    const [addCategory, setAddCategory] = useState('')
    const [fileObj,setFileObj] =useState('');
    const [selectedESIfile, setselectedESIFiles] = React.useState({})
    const [selectedEPFfile, setselectedEPFfile] = React.useState({})
    const [finalSubmit, setFinalSubmit] = React.useState(false)
    // const [selectedfilesMedical, setselectedfilesMedical] = React.useState({})
    // const [selectedfilesMedicalCert, setselectedfilesMedicalCert] = React.useState({})
    // const [selectedfilesXerox, setselectedfilesXerox] = React.useState({})
    

    const [anchorElESI, setAnchorElESI] = React.useState(null);
    const openElESI = Boolean(anchorElESI);
    const idElESI = openElESI ? "simple-popover" : undefined;

    const [anchorElEPF, setAnchorElEPF] = React.useState(null);
    const openElEPF = Boolean(anchorElEPF);
    const idElEPF = openElEPF ? "simple-popover" : undefined;


    const [fontActivate,setFontActivate] = useState(false)
    const [showRemoveBtn, setShowRemoveBtn] = useState(false)
    const [getAshadeepaAvailed, setGetAshadeepaAvailed] = useState([])
    const [filterAshadeepa, setFilterAshadeepa] = useState([{}])
    const [getAshadeepaApproveInfo, setAshadeepaApproveInfo] = useState([]) 
    // const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false)

    const [loadingfiles, setloadingfiles] = React.useState(true)
    const [esiUpload, setESIUpload] = React.useState("")
    const [epfUpload, setEPFUpload] = React.useState("")
    const [selected_document_type, setselected_document_type] = React.useState("")
    
    const [categoryLists,setCategoryLists] = React.useState([{}])


    const [filelistESI, setfilelistESI] = React.useState({})
    const [filelistEPF, setfilelistEPF] = React.useState({})
    
    const [dateOfJoin, setDateOfJoin] = React.useState(null);

    const [filesResponseESI,setfilesResponseESI] = React.useState(null)
    const [filesResponseEPF,setfilesResponseEPF] = React.useState(null)
    


    
    const [states, setStates] = useState([]);
    const [stateId, setStateId] = useState();
    const [district, setDistrict] = useState([]);
    const [districtId, setDistrictId] = useState();
    const [taluk, setTaluk] = useState([]);
    const [talukId, setTalukId] = useState();
    const [city, setCity] = useState([]);
    const [cityId, setCityId] = useState();
    const [village, setVillage] = useState([]);
    const [villageId, setVillageId] = useState();




    

    useEffect(()=>{

        // const catalogData = allValues.props_this.location.catalog_value_id;
        // console.log("CatalogData",catalogData)
        let data = JSON.stringify({
            "board_id":4,
            "role_id":users.getDeptUserRole.data[0].role_id,
            "department_user_id":users.getDeptUserRole.data[0].department_user_id,
            "scheme_id": 39,
            "circle_id":allValues.props_this.location.circle_id,
            "catalog_value_application_status": 47
       }
      
       )
       console.log("AshaDeepaDataAPI:",data);
          var config = {
            method: 'post',
            url: SERVER+'/schemes/get_ashadeep_availed_schemes',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}` 
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            console.log("Get_Ashadeepa_Availed"+JSON.stringify((response.data.data)));
            const resultData = (response.data.data)
            console.log("ResuktData:",resultData);
            setGetAshadeepaAvailed(resultData)                            
          })
          .catch(function (error) {
            console.error("get_ashadeepa_error: "+error);
          });

          //const filterAsha = getAshadeepaAvailed.filter(asha => asha.ashadeepa_scheme_avail_id === allValues.props_this.location.scheme_avail_id)
          //setFilterAshadeepa(filterAsha)
        
         
    },[])


    
console.log('AshadeepaAvailed:',getAshadeepaAvailed);
    useEffect(()=>{
        if(getAshadeepaAvailed !== undefined){
            let filterData = getAshadeepaAvailed.filter(asha => asha.ashadeepa_scheme_avail_id === allValues.props_this.location.scheme_avail_id)
            console.log("Filter:",filterData);
            if(filterData[0] !== undefined && filterData[0] !== null){
                setFilterAshadeepa(filterData[0])
            }
            
        }
    },[getAshadeepaAvailed])

    useEffect(()=>{
        console.log("Filter2:",filterAshadeepa.company_firm_establishment_name);
    },[filterAshadeepa])
  
            
    // const data1 = (getAshadeepaAvailed);
    // data1.filter(asha => asha.ashadeepa_scheme_avail_id === allValues.props_this.location.scheme_avail_id)
    // console.log(data1);
//           const filterAsha = getAshadeepaAvailed.filter(asha => asha.ashadeepa_scheme_avail_id === allValues.props_this.location.scheme_avail_id)
//           setFilterAshadeepa(filterAsha)
          

// console.log("ResponseFilter",filterAsha);
    //address api calls


    
        const approveInfo = async () => {
            try {
                const response = await axios.get(`${SERVER}/schemes/get_ashadeep_scheme_transactions?ashadeepa_scheme_avail_id=${allValues.props_this.location.scheme_avail_id}&scheme_id=39`,
                {
                    headers:{
                        'Authorization':`Bearer ${getTokenCookie()}`
                    }
                })
            console.log("ApproveStatus:",(JSON.stringify(response.data.data[0].approved_rejected_remarks)));
            const approve_info = (response.data.data[0])    
            setAshadeepaApproveInfo(approve_info)
            } catch (error) {
                console.log(error);
            }
        }

        useEffect(()=>{
            approveInfo();
        },[])

console.log("ApprovedState:",getAshadeepaApproveInfo.approved_rejected_remarks
);
    useEffect(()=>{
        if(filterAshadeepa.document_esi_id !== undefined)
        {
            var jsondocs = JSON.parse(filterAshadeepa.document_esi_id)
            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []
            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/welfare_download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };
                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                           
                            var esidoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);
                                var esi = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                esidoc.push(esi)
                            })
                            setESIUpload(esidoc)
                            // console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }
            } 
        }

        if(filterAshadeepa.document_epf_id !== undefined)
        {
            var jsondocs = JSON.parse(filterAshadeepa.document_epf_id)
            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []
            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/welfare_download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };
                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                           
                            var epfdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);
                                var epf = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                epfdoc.push(epf)
                            })
                            setEPFUpload(epfdoc)
                            // console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }
            } 
        }



    },[filterAshadeepa])




    const getStateDetails = async () => {
        try {
            const response = await axios.get(`${SERVER}/global/get_state_details`);
            console.log("StateResponse:",response.data.data);
            setStates(response.data.data)
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    const getDistrictDetails = async () => {
        try {
            const response = await axios.get(`${SERVER}/global/get_district_details?state_id=${stateId}`);
            console.log("DistrictResponse:",response.data.data);
            setDistrict(response.data.data)
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    const getTalukDetails = async () => {
        try {
            const response = await axios.get(`${SERVER}/global/get_taluk_details?district_id=${districtId}`);
            console.log("TalukResponse:",response.data.data);
            setTaluk(response.data.data)
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    const getCityDetails = async () => {
        try {
            const response = await axios.get(`${SERVER}/global/get_city_details?taluk_id=${talukId}`);
            console.log("CityResponse:",response.data.data);
            setCity(response.data.data)
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    const getVillageDetails = async () => {
        try {
            const response = await axios.get(`${SERVER}/global/get_village_details?city_id=${cityId}`);
            console.log("VillageResponse:",response.data.data);
            setVillage(response.data.data)
        } catch (error) {
            console.log(JSON.stringify(error));
        }
    }

    //address ID's

    const handleState = (event) => {
        const getStateID = event.target.value;
        console.log(getStateID);
        setStateId(getStateID)
    }

    const handleDistrict = (event) => {
        const getDistrictID = event.target.value;
        console.log(getDistrictID);
        setDistrictId(getDistrictID)
    }

    const handleTaluk = (event) => {
        const getTalukID = event.target.value;
        console.log(getTalukID);
        setTalukId(getTalukID)
    }

    const handleCity = (event) => {
        const getCityID = event.target.value;
        console.log(getCityID);
        setCityId(getCityID)
    }

    const handleVillage = (event) => {
        const getVillageID = event.target.value;
        console.log(getVillageID);
        setVillageId(getVillageID)
    }

    useEffect(() => {
        getStateDetails()
        getDistrictDetails()
        getTalukDetails()
        getCityDetails()
        getVillageDetails()
    }, [stateId, districtId, talukId, cityId])



    // const handleFileChange = event => {
    //     setFileObj( event.target.files && event.target.files);
    //     if (!fileObj) {
    //       return;
    //     }
    // }

    // const resetFileInput = (event) => {
    //     // reset input value
    //     setFileObj(event.target.value=null)
    //   };
   


    useEffect(() => {
      
        if(dateOfJoin !== null){
        setAllValues({ ...allValues, date_of_join: dateOfJoin, dateSelected :true})
        console.log("date_of_join" + allValues.date_of_join)
        }

  
    
}, [dateOfJoin]);

    const handleChange1 = (e) => {
        console.log("kkkkk", e.target.value)
        if (e.target.value === "ka") {

            setLang({
                ...allValues,
                descriptionsKannada: true,
                [e.target.name]: e.target.value,

            })
            setFontActivate(true)

        }
        else {
            setLang({
                ...allValues,
                descriptionsKannada: false,
                [e.target.name]: e.target.value,

            })
            setFontActivate(false)
        }


        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }


    const handleChangeCheckbox = (event) => {
        setAllValues({ ...allValues, [event.target.name]: event.target.checked });

        
    }

    const handleAddCategory1 = (i, e) => {

        
        let newFormValues = [...cat0Values];
        newFormValues[i][e.target.name] = e.target.value;
        setCat0Values(newFormValues)
     }

     const handleAddCategory2 = (i, e) => {

        
        let newFormValues = [...cat1Values];
        newFormValues[i][e.target.name] = e.target.value;
        setCat1Values(newFormValues)
     }


    const handleRemoveCategory1 = (index) => {
        const values = [...cat0Values];
        values.splice(index,1)
        setCat0Values(values)
    }
    // const handleChangeValidation2 = (e) => {
    //     if(e.target.name==="category_name"){
    //         if(e.target.value.length>=10){
    //             setCatValues(
    //                 {
    //                     ...catValues,
    //                     [e.target.name]:e.target.value,
    //                     category_name_error:false
    //             })
    //         }
    //         else{
    //             setCatValues(
    //                 {
    //                     ...catValues,
    //                     [e.target.name]:e.target.value,
    //                     category_name_error:true
    //             })
    //         }
    //     }
    // }


const addFormCategory1 = () =>{
    setCat0Values([...cat0Values,{
        category_name:"",
        category_name_error:false,
        total_no_of_posts:"",
        total_no_of_posts_error:false,
        no_of_posts_filled:"",
        no_of_posts_filled_error:false,
        no_of_posts_vacant:"",
        no_of_posts_vacant_error:false,
        no_of_ashadeepa:"",
        no_of_ashadeepa_error:false,
        salary:"",
        salary_error:false,
        no_of_ESI:"",
        no_of_ESI_error:false,
        no_of_EPF:"",
        no_of_EPF_error:false,


    }])
}

const addFormCategory2 = () =>{
    setCat1Values([...cat1Values,{
        name_of_apprenticeship:"",
        no_of_apprenticeship:"",
        vacancy:"",
        no_of_ashadeepa:"",
        wages:"",
        remarks:"",

    }])
}



    // const handleSubmit = (e) =>{
    //     e.preventDefault();
    //     console.log("Fields:",catValues);
    // }

    const handleChange = event => {
        setAllValues({
            ...allValues,
            ...allValues,
            [event.target.name]: event.target.value
        })
      };
      const handleClick = (event,data,selecteddoc) => {
        setselected_document_type(selecteddoc)
        if(data==="ESIDoc"){
            setAnchorElESI(event.currentTarget);
        }
       else if(data==="EPFDoc"){
            setAnchorElEPF(event.currentTarget);
        }
      };


      const handleClose = (event,data) => {
        if(data === "ESIDoc"){
            setAnchorElESI(null);
        }
        else if(data === "EPFDoc"){
            setAnchorElEPF(null);
        } 
      }
      
      const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

  




const handleFinalSubmit = () => {

    let categoryList = [];

    if(categoryType === 0){
        for(let i = 0; i < cat0Values.length; i++) {
            categoryList.push({
            
                "is_employment_aprantiship_type": 0,
                "total_number_of_posts": cat0Values[i].total_no_of_posts,
                "total_post_filled": cat0Values[i].no_of_posts_filled,
                "total_vacant_posts": cat0Values[i].no_of_posts_vacant,
                "salary_per_month": cat0Values[i].salary,
                "total_ashadeepa_posts_vacancies": cat0Values[i].no_of_ashadeepa,
                "total_esi_employees": cat0Values[i].no_of_ESI,
                "total_epf_employees": cat0Values[i].no_of_EPF,
                "name_of_apprentiship":"NA",
                "remarks": "NA"
            })
        }
        
    
        console.log("listCategory:",categoryList);
    
    
    }


    if(categoryType === 1){
        for(let i = 0; i < cat1Values.length; i++) {
            categoryList.push({
            
                "is_employment_aprantiship_type": 1,
                "total_number_of_posts": cat1Values[i].total_no_of_posts,
                "total_post_filled": cat1Values[i].no_of_posts_filled,
                "total_vacant_posts": cat1Values[i].no_of_posts_vacant,
                "salary_per_month": cat1Values[i].salary,
                "total_ashadeepa_posts_vacancies": cat1Values[i].no_of_ashadeepa,
                "total_esi_employees": "[]",
                "total_epf_employees": "[]",
                "name_of_apprentiship":cat1Values[i].name_of_apprenticeship,
                "remarks": cat1Values[i].remarks
            })
        }
        
    
        console.log("listCategory:",categoryList);
    
    
    }
    
    var timestamp = Date.now();
    var timestampArray = timestamp.toString();
    var timestampNum = Number(timestampArray.slice(0,8))
    var randNum = Math.floor(Math.random() * 100);
    var randNumArray = randNum.toString();
    var timestampSliced = timestampNum.toString();
    var joinrandNum = timestampSliced.concat(randNumArray);
    var unique_id = Number(joinrandNum)
   

    // var randNum = [Math.floor(Math.random() * 100)];
    // var unique_id = timestamp.concat(randNum)
    // var new_unique_id = unique_id.join("")
    //console.log("RandNum:",randNum);
    console.log("TimeStamp:",timestamp);
    console.log("TimeStamp8:",timestampNum);
    console.log("RandNum:",randNum);
    console.log("Unique_id:",unique_id);
    //console.log("Unique:",unique_id);
    //console.log("New:",new_unique_id);

    
   
    var apiData = {
    
    "board_id": 4,
    "scheme_id": 39,
    "unique_id_generator": unique_id,
    "company_firm_establishment_name": allValues.company_name,
    "nature_of_business": allValues.nature_of_business,
    "name_of_md_proprietor_partner": allValues.name_of_md,
    "contact_number": allValues.contact_number,
    "email_id": allValues.email_id,
    "registration_certificate_number": allValues.registration_cert_no,
    "district_id": districtId,
    "taluk_id": talukId,
    "grampanchayat_city_town_id": cityId,
    "village_area_ward_id": villageId,
    "name_of_hr": allValues.name_of_hr,
    "contact_details_of_hr": allValues.hr_contact_number,
    "email_id_of_hr": allValues.hr_contact_number,
    "scheme_avail_category_details": categoryList,
    "document_esi_id":JSON.stringify(filesResponseESI),
    "document_epf_id":JSON.stringify(filesResponseEPF)

    }
    console.log("APIDATA:",apiData);



    var config = {
        method:'post',
        url:SERVER+'/schemes/insert_ashadeep_scheme',
        
        data:apiData
    };

    axios(config)
            .then(function (response) {
                console.error("response get_fields_value: "+JSON.stringify(response.data));
                console.log("response get_fields_value: "+JSON.stringify(response.data));
                showToast("SUCCESS", "Scheme updated successfully")
                props.history.push("/ashadeepa")              


            })
            .catch(function (error) {
                console.error(error);
                showToast("ERROR","Error in submitting File")
            });

}
    

// useEffect(()=>{
//     if(finalSubmit)
//     handleFinalSubmit();
// },[finalSubmit])


const getfilebase64 = (config) => {
    return new Promise((resolve, reject) => {
        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                resolve(response.data.data)
            })
            .catch(function (error) {
                console.log(error);
                reject(error)
            });
    })
}


const submitPressed = () => {

    if(allValues.commentInspector !==""){
        var data = JSON.stringify({
            "board_id": 4,
            "schemes_availed_transaction_id": filterAshadeepa.transaction_id,
            "catalog_value_status_id": 47,
            "description": allValues.commentInspector,
            "login_user_id": users.login_admin.data.department_user_id,
            "verified_document_id": "NA"
        })
        console.log("Data for Approve:",data);

        var config = {
            method: 'post',
            url: SERVER + '/schemes/approve_reject_ashadeep_schemes',
            headers: {
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: data
        };

            axios(config)
            .then(function (response) {
            console.log("Ashadeepa Scheme Approve Response:"+JSON.stringify(response.data));
            showToast("SUCCESS", "Scheme Approved Successfully!")
            allValues.props_this.history.push("/admin/unorganized-dashboard")
        })
            .catch(function (error) {
            console.error("Ashadeepa Approve error: " + error);
        });
    }
     else{
        showToast("ERROR","Please fill the comment field!")
     }   
    }




    const handleCategory = (event) => {
        setCategoryType(event.target.value)
    }

    console.log("Category:",categoryType);
      const [customError, setCustomError] = useState('')
     const handleChangeValidation = (ev) =>{

        if(ev.target.name === "commentInspector"){

                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    
                })
            
        }
        
        if(ev.target.name === "company_name"){
            if(validator.isAlpha(ev.target.value ,"en-US",{ignore: ' '}) && (ev.target.value.length) < 30){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    company_name_error: false
                })
            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    company_name_error: true
                })
            }
        }


        else if (ev.target.name === "nature_of_business"){
            if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    nature_of_business_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    nature_of_business_error: true
                })
            }
        }
        
      else if(ev.target.name === "name_of_md"){
            if(validator.isAlpha(ev.target.value ,"en-US",{ignore: ' '}) && (ev.target.value.length) < 30){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    name_of_md_error: false
                })
            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    name_of_md_error: true
                })
            }
        }

      else if(ev.target.name === "contact_number"){
            if(validator.isNumeric(ev.target.value ,"en-US",{ignore: ' '}) && (ev.target.value.length) === 10){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    contact_number_error: false
                })
            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    contact_number_error: true
                })
            }
        }

        else if (ev.target.name === "email_id"){
            if (validator.isEmail(ev.target.value)){
                
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    email_id_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    email_id_error: true
                })
            }
        }

        else if(ev.target.name === "registration_cert_no"){
            if(validator.isAlphanumeric(ev.target.value ,"en-US",{ignore: ' '}) && (ev.target.value.length) === 21){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    registration_cert_no_error: false
                })
            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    registration_cert_no_error: true
                })
            }
        }

        else if (ev.target.name === "local_address"){
            if ( ev.target.value.length <= 50){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    local_address_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    local_address_error: true
                })
            }
        }

        else if (ev.target.name === "name_of_hr"){
            if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    name_of_hr_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    name_of_hr_error: true
                })
            }
        }

        else if (ev.target.name === "hr_contact_number"){
            if (validator.isNumeric(ev.target.value) && ev.target.value.length === 10){
                //  medicalAlphaError = false
                //  e.targ
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    hr_contact_number_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    hr_contact_number_error: true
                })
            }
        }




        else if (ev.target.name === "hr_email_id"){
            if (validator.isEmail(ev.target.value)){
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    hr_email_id_error: false
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    [ev.target.name]: ev.target.value,
                    hr_email_id_error: true
                })
            }
        }

        // else if (ev.target.name === "no_of_posts_vacant"){
        //     if(validator.isNumeric(ev.target.value)){
        //         setCatValues({
        //             ...catValues,
        //             [ev.target.name]:ev.target.value,
        //             no_of_posts_vacant_error:false
        //         })
        //     }
        //     else{
        //         setCatValues({
        //             ...catValues,
        //             [ev.target.name]: ev.target.value,
        //             no_of_posts_vacant_error: true
        //         })
        //     }
        // }
        
        


     }

     const handleESIFile = (event) => {

        setloadingfiles(true)

        if(event.target.files[0].size > 2000001){
            showToast("ERROR", "Please upload file of size less than 2MB.")
        }
        else if(!event.target.files[0].type.includes("image")){
            showToast("ERROR", "Please upload file in JPEG or PNG format.")
        }
        else{

        const joined = Array.from(selectedESIfile).concat(Array.from(event.target.files));

        setselectedESIFiles(joined)

        }
        
        setloadingfiles(false)
    }

    
    

    



    


    const submitFiles = (filesResponseESI, filesResponseEPF) => {
        console.log(filesResponseESI,"filesResponseESI")

        if(filesResponseESI !== undefined && filesResponseESI.length > 0 && filesResponseEPF !== undefined && filesResponseEPF.length > 0 ){
        console.log(filesResponseESI,"filesResponseESI")
        console.log(filesResponseEPF,"filesResponseEPF")
        
        // setfilesResponseESI(filesResponseESI)
        // setfilesResponseEPF(filesResponseEPF)
        



        // setFinalSubmit(true)




        let categoryList = [];

    if(categoryType === 0){
        for(let i = 0; i < cat0Values.length; i++) {
            categoryList.push({
            
                "is_employment_aprantiship_type": categoryType,
                "total_number_of_posts": cat0Values[i].total_no_of_posts,
                "total_post_filled": cat0Values[i].no_of_posts_filled,
                "total_vacant_posts": cat0Values[i].no_of_posts_vacant,
                "salary_per_month": cat0Values[i].salary,
                "total_ashadeepa_posts_vacancies": cat0Values[i].no_of_ashadeepa,
                "total_esi_employees": cat0Values[i].no_of_ESI,
                "total_epf_employees": cat0Values[i].no_of_EPF,
                "name_of_apprentiship":"NA",
                "remarks": "NA"
            })
        }
        
    
        console.log("listCategory:",categoryList);
    
    
    }


    if(categoryType === 1){
        for(let i = 0; i < cat1Values.length; i++) {
            categoryList.push({
            
                "is_employment_aprantiship_type": categoryType,
                "total_number_of_posts": cat1Values[i].total_no_of_posts,
                "total_post_filled": cat1Values[i].no_of_posts_filled,
                "total_vacant_posts": cat1Values[i].no_of_posts_vacant,
                "salary_per_month": cat1Values[i].salary,
                "total_ashadeepa_posts_vacancies": cat1Values[i].no_of_ashadeepa,
                "total_esi_employees": "[]",
                "total_epf_employees": "[]",
                "name_of_apprentiship":cat1Values[i].name_of_apprenticeship,
                "remarks": cat1Values[i].remarks
            })
        }
        
    
        console.log("listCategory:",categoryList);
    
    
    }
    
    var timestamp = Date.now();
    var timestampArray = timestamp.toString();
    var timestampNum = Number(timestampArray.slice(0,8))
    var randNum = Math.floor(Math.random() * 100);
    var randNumArray = randNum.toString();
    var timestampSliced = timestampNum.toString();
    var joinrandNum = timestampSliced.concat(randNumArray);
    var unique_id = Number(joinrandNum)
   

    // var randNum = [Math.floor(Math.random() * 100)];
    // var unique_id = timestamp.concat(randNum)
    // var new_unique_id = unique_id.join("")
    //console.log("RandNum:",randNum);
    console.log("TimeStamp:",timestamp);
    console.log("TimeStamp8:",timestampNum);
    console.log("RandNum:",randNum);
    console.log("Unique_id:",unique_id);
    //console.log("Unique:",unique_id);
    //console.log("New:",new_unique_id);

    
   
    var apiData = {
    
    "board_id": 4,
    "scheme_id": 39,
    "unique_id_generator": unique_id,
    "company_firm_establishment_name": allValues.company_name,
    "nature_of_business": allValues.nature_of_business,
    "name_of_md_proprietor_partner": allValues.name_of_md,
    "contact_number": allValues.contact_number,
    "email_id": allValues.email_id,
    "registration_certificate_number": allValues.registration_cert_no,
    "district_id": districtId,
    "taluk_id": talukId,
    "grampanchayat_city_town_id": cityId,
    "village_area_ward_id": villageId,
    "name_of_hr": allValues.name_of_hr,
    "contact_details_of_hr": allValues.hr_contact_number,
    "email_id_of_hr": allValues.hr_contact_number,
    "scheme_avail_category_details": categoryList,
    "document_esi_id":JSON.stringify(filesResponseESI),
    "document_epf_id":JSON.stringify(filesResponseEPF)

    }
    console.log("APIDATA:",apiData);



    var config = {
        method:'post',
        url:SERVER+'/schemes/insert_ashadeep_scheme',
        
        data:apiData
    };

    axios(config)
            .then(function (response) {
                console.error("response get_fields_value: "+JSON.stringify(response.data));
                console.log("response get_fields_value: "+JSON.stringify(response.data));
                showToast("SUCCESS", "Scheme updated successfully")
                props.history.push("/ashadeepa")              


            })
            .catch(function (error) {
                console.error(error);
                showToast("ERROR","Error in submitting File")
            });


        
    }
       
    }

console.log("FontStatus:",fontActivate);

  return (
    <div>
    <div className="root">



<Row className="top-div ">
    <Row className="topbar-row">
        <Col xs={12} md={4} lg={6} className="top-bar-col">
            <div className="logo-div-profile">
                <a href="/boardsHome">
                    <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                    {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                    <Translate value={"header.titleungoranized"}/><br/>
                    <Translate value="header.subungoranized"/> 
                    
                </h1>
            </div>

        </Col>
        <div className='d-flex justify-content-end'>
        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
            <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={lang.language}
                name="language"
                displayEmpty
                onChange={handleChange1}
            >
                <MenuItem value="" >
                    <ListItemIcon>
                        <img alt="..." src={language} className="language-img" />
                    </ListItemIcon>
                    <ListItemText primary="Select Language" />
                </MenuItem>
              
                <MenuItem value="en">
                    <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                    <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
            </Select>
        </Col>
        </div>
        
    </Row>

</Row>
</div>

<Row className="schemes-title-row">
                <Col xs={12} md={9}>
                    
                    <h1><Translate value="schemes" className={fontActivate ? 'fontKan' : null} /> | 
                    <Translate value="ashadeepaTitle" className={fontActivate ? 'fontKan' : null}/>  
                    </h1>

                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Fields</p>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form searchform">
            <Col xs={12} md={4} lg={6} xl={6} xxl={6}>

                    
                    <p className="mb-2">Name of the Company / Firm / Establishment</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="company_name"
                            value={filterAshadeepa.company_firm_establishment_name}
                            // onChange={handleChangeValidation}
                            // helperText={allValues.company_name_error ? "Please enter alphabets only":""}
                            // error={allValues.company_name_error}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Nature of Business / Trade of Firm / Establishment</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="nature_of_business"
                             value={filterAshadeepa.nature_of_business}
                            //  onChange={handleChangeValidation}
                            //  helperText={allValues.nature_of_business_error ? "Please enter alphabets only":""}
                            //  error={allValues.nature_of_business_error}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Name of the Managing Director/ Partner / Proprietor</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="name_of_md"
                             value={filterAshadeepa.name_of_md_proprietor_partner}
                            
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Contact number</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="contact_number"
                             value={filterAshadeepa.contact_number}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Email Id</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="email_id"
                             value={filterAshadeepa.email_id}
                            
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Registration Certificate number (CIN number)</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={filterAshadeepa.registration_certificate_number}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                <p className='mb-2'>State</p>
                
                <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={"Karnataka"}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                            </Col>

                            <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                            <p className='mb-2'>District</p>
                
                            <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={filterAshadeepa.district}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                            </Col>

                            <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                            <p className='mb-2'>Taluk</p>
                
                            <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={filterAshadeepa.taluk_name}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                            </Col>
                      

                            <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                            <p className='mb-2'>City Name</p>
                
                            <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={filterAshadeepa.grampanchayat_city_town}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                            </Col>
                        
                            <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                            <p className='mb-2'>Your Area / Village Name</p>
                
                            <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="registration_cert_no"
                             value={filterAshadeepa.village_area_ward}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                            </Col>
                    






                {/* <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2"><Translate value='localAddress'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="local_address"
                             value={filterAshadeepa[0].}
                            
                            disabled
                            
                        />
                    </FormControl>
                </Col> */}
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">Name of the HR Head</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="name_of_hr"
                             value={filterAshadeepa.name_of_hr}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">HR's Contact number</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="hr_contact_number"
                             value={filterAshadeepa.contact_details_of_hr}
                            
                            disabled
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2">HR's Email Id</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="hr_email_id"
                             value={filterAshadeepa.email_id_of_hr}
                            
                            disabled
                            
                            
                        />
                    </FormControl>
                </Col>
            </Row>
            
            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                  
                  <p>Employment / Apprenticeship Details</p>
                    {/* <p>{categoryType===0 ? <Translate value='empDetails'/> : categoryType === 1 ? <Translate value='apprentDetails'/> : <Translate value='empappDetails'/> }</p> */}
                </Col>
            </Row>
            
            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} md={4}>
                    
                    <p>Category Type *</p>

                    <FormControl fullWidth className="formcontrol1" variant='outlined'>
                    <Select
                    className="select-marital"
                    labelId="demo-simple-select-required-label"
                    id="demo-simple-select-required"
                    // value={allValues.residentialData.taluk}
                    // name="taluk"
                    value={categoryType}
                    onChange={handleCategory}
                    displayEmpty
                    // onChange={handleChangeResidential}
                    >
                    <MenuItem value="">
                    <ListItemText primary="-Select-" />
                    </MenuItem>
                    {/* {allValues.taluk !== undefined && allValues.taluk !== null &&
                    allValues.taluk.length > 0 && allValues.taluk.map((i) =>
                                            // <MenuItem value={i.ref_district_code}> */}
                    <MenuItem value={0}>
                  <ListItemText>Employment Details</ListItemText>  
        
                    </MenuItem>
        <MenuItem value={1}>
           <ListItemText>Apprenticeship Details</ListItemText>
        </MenuItem>            
                                     
                </Select>
                    </FormControl>
                </Col>
               {categoryType === 0 ? cat0Values.map((element,index)=>{
                return (
                    <Row className='mt-2'>
                    {
                    <div className='d-flex justify-content-end'>
                    <CancelIcon onClick={() => handleRemoveCategory1()}></CancelIcon>
                    </div>
                    }
                    {/* <img alt="..." src={close} className="closebtn" onClick={() => handleRemoveCategory1()} /> */}
                <Col xs={12} md={4} >
                
                    
                    <p><Translate value='categoryName'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="category_name"
                            value={element.category_name}

                            // onChange={handleChangeValidation2}
                            onChange={e=> handleAddCategory1(index, e)}   
                            helperText={element.category_name_error ? "Please enter numbers only" : ""}
                            error={element.category_name_error}  
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} >
                    
                    <p><Translate value='totalnoPosts'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="total_no_of_posts"
                            value={element.total_no_of_posts}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='postsFilled'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="no_of_posts_filled"
                            value={element.no_of_posts_filled}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='postsVacant'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="no_of_posts_vacant"
                            value={element.no_of_posts_vacant}
                            onChange={(e)=>
                            { 
                                handleAddCategory1(index, e); 
                               
                            }
                            }
                            helperText={element.no_of_posts_vacant_error ? "Please enter numbers only":""}
                            type={'number'}
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='noOfVacancyAshadeepa'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="no_of_ashadeepa"
                            value={element.no_of_ashadeepa}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}

                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='salaryPerMonth'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="salary"
                            value={element.salary}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}

                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='noOfemployeeESI'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="no_of_ESI"
                            value={element.no_of_ESI}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}

                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} >
                    
                    <p><Translate value='noOfemployeeEPF'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="no_of_EPF"
                            value={element.no_of_EPF}
                            onChange={e=> handleAddCategory1(index, e)}
                            type={'number'}

                        />
                    </FormControl>
                </Col>
                
                
                
                </Row>
                )
               })
                 
                : 
                categoryType === 1 ? cat1Values.map((element,index)=>{
                   return (
                <Row className='mt-2'>
                <Col xs={12} md={4}>
                    
                    <p><Translate value='nameOfApprenticePosts'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='name_of_apprenticeship'
                            value={element.name_of_apprenticeship}
                            onChange={e=> handleAddCategory2(index, e)}

                        />
                    </FormControl>
                </Col>                               

             <Col xs={12} md={4} >
                    
                    <p><Translate value='noOfApprentice'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='no_of_apprenticeship'
                            value={element.no_of_apprenticeship}
                            onChange={e=> handleAddCategory2(index, e)}
                            type={'number'}

                        />
                    </FormControl>
                </Col>                               

                <Col xs={12} md={4} >
                    
                    <p><Translate value='vacant'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='vacany'
                            value={element.vacancy}
                            onChange={e=> handleAddCategory2(index, e)}
                            type={'number'}
                        />
                    </FormControl>
                </Col>                               

                <Col xs={12} md={4} >
                    
                    <p><Translate value='noOfVacancyAshadeepa'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='no_of_ashadeepa'
                            value={element.no_of_ashadeepa}
                            onChange={e=> handleAddCategory2(index, e)}
                            type={'number'}
                        />
                    </FormControl>
                </Col>                               

                <Col xs={12} md={4} >
                    
                    <p><Translate value='wagesForMonth'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='wages'
                            value={element.wages}
                            onChange={e=> handleAddCategory2(index, e)}
                            type={'number'}
                        />
                    </FormControl>
                </Col>                               

                <Col xs={12} md={4} >
                    
                    <p><Translate value='remarks'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name='remarks'
                            value={element.remarks}
                            onChange={e=> handleAddCategory2(index, e)}

                        />
                    </FormControl>
                </Col>  
                
                </Row>       
               )
                })
               
               : null
               } 
    
    { categoryType === 0 ? 
    <div className='d-flex justify-content-center mt-5 mb-5'>
                <Button2 className='add-category-btn' onClick={()=> addFormCategory1()}><Translate value='addCategory'/><AddIcon color='secondary' className='add-icon'/></Button2>
               
                
                </div>                                   
    : null }
    { categoryType === 1 ? 
    <div className='d-flex justify-content-center mt-5 mb-5'>
                <Button2 className='add-category-btn' onClick={()=> addFormCategory2()}><Translate value='addCategory'/><AddIcon color='secondary' className='add-icon'/></Button2>
                </div>                                   
    : null }
{/* Apprenticeship */}

            </Row>
                
            




         

                <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Documents Uploaded</p>
                </Col>
            </Row>

            

            <Row className="form-row mt-4 config-form searchform">
    <Col xs={12} md={6} lg={6} xl={6} className='final-personal-col-1 px-3 mt-4'>
            
            <div className="">
            <p className="mb-2 w-75">List of Number of Employees under ESI</p>
          
          { 
            filterAshadeepa.document_esi_id !== undefined &&
            JSON.parse(filterAshadeepa.document_esi_id).map((doc)=>
            <div className='browsebutton2-div'>
          
                                
          <FormControl fullWidth className='formcontrol1'>
                <TextField
                variant="outlined"
                name={doc.file_name}
                value={doc.file_name}
                
                // onChange={handleChange}
                disabled
                InputProps={{
                    endAdornment:
                <InputAdornment position="start">
                
                </InputAdornment>,
                    }}
                     />
                </FormControl>

                <input type="file" name="photo" 
                id={`upload-photo_${doc.file_id}`} 

                />
                <label className="schemes-doc-view"
                onClick={(e) => handleClick(e, "ESIDoc", esiUpload[0].base_64_content)}
                >
                <Visibility />
                </label>
                                    
          </div>
          
          )}
          
          
          
          
          
            </div>
            <Popover
                    id={idElESI}
                    open={openElESI}
                    anchorEl={anchorElESI}
                    onClose={(e) => handleClose(e, "ESIDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >
                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}
                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');
                                }}
                            // src={allValues.selected_document_type}
                        />
                    </a>
                </Popover>
            </Col>

                    
            <Col xs={12} md={6} lg={6} xl={6} className='final-personal-col-1 px-3 mt-4'>
            
            <div className="">
            <p className="mb-2 w-75">List of Number of Employees under EPF</p>
          { 
            filterAshadeepa.document_epf_id !== undefined &&
            JSON.parse(filterAshadeepa.document_epf_id).map((doc)=>
            <div className='browsebutton2-div'>
          
                                
          <FormControl fullWidth className='formcontrol1'>
                <TextField
                variant="outlined"
                name={doc.file_name}
                value={doc.file_name}
                
                // onChange={handleChange}
                disabled
                InputProps={{
                    endAdornment:
                <InputAdornment position="start">
                
                </InputAdornment>,
                    }}
                     />
                </FormControl>

                <input type="file" name="photo" 
                id={`upload-photo_${doc.file_id}`} 

                />
                <label className="schemes-doc-view"
                onClick={(e) => handleClick(e, "EPFDoc", epfUpload[0].base_64_content)}
                >
                <Visibility />
                </label>
                                    
          </div>
          
          )}
          
          
          
          
          
            </div>
            <Popover
                    id={idElEPF}
                    open={openElEPF}
                    anchorEl={anchorElEPF}
                    onClose={(e) => handleClose(e, "EPFDoc")}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center"
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "center"
                    }}
                >
                    <a href="#" >
                        <img
                            alt="..."
                            className="avatar border-gray"
                            // id={item.id}
                            src={`
                            data:image/jpeg;base64,${selected_document_type}`}
                                onClick={() => {
                                    var win = window.open();
                                    win.document.write('<iframe src="' + `
                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');
                                }}
                            // src={allValues.selected_document_type}
                        />
                    </a>
                </Popover>
            </Col>

           
            
            
               
            </Row>
            {
                <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Remarks</p>
                </Col>
            </Row>
           

            }
            {
                <Row className="form-row mt-4 config-form searchform">
                    <h5>First level Labour inspector remarks: {getAshadeepaApproveInfo.approved_rejected_remarks}
                    </h5>
                </Row>
            }
                            
            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Comment</p>
                </Col>
            </Row>

                {
                    allValues.props_this.location.catalog_value_id === 47 ? 

                    (<Row className="form-row mt-4 config-form searchform">
                                        <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                                            <FormControl fullWidth className="formcontrol1">
                                                <TextareaAutosize
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    name="commentInspector"
                                                    placeholder="Enter Your Comment"
                                                    value={filterAshadeepa.approved_rejected_remarks}
                                                    disabled
                                                    onChange={handleChangeValidation}
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    )
                                    :
                                    (<Row className="form-row mt-4 config-form searchform">
                                        <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                                            <FormControl fullWidth className="formcontrol1">
                                                <TextareaAutosize
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    name="commentInspector"
                                                    placeholder="Enter Your Comment"
                                                    value={allValues.commentInspector}
                                                    onChange={handleChangeValidation}
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                    )



                }
            


                <Row className='search-subtitle-row pb-5'>
                    {
                    filterAshadeepa.is_final_approval === true ?
                        
<Col xs={12} className="next-back-button-row mt-4 ">

{allValues.props_this.location.catalog_value_id === 45 ?
    <>
    
    <Link to="#" 
    //onClick={nextButtonPressed}
        style={{ flexGrow: "0.5" }}
    >
        <Button variant="outline-primary" className="save-button" 
        onClick={submitPressed}
        
        >
        Approve
        </Button>
    </Link>
    <Link to="#" 
    //onClick={nextButtonPressed}
        style={{ flexGrow: "0.5" }}
    >
        <Button variant="outline-primary" className="back-button" 
        onClick={submitPressed}
        
        >
        Reject
        </Button>
    </Link>
</>

 
 : null}

</Col>
:
<Col xs={12} className="next-back-button-row mt-4 ">

                                {allValues.props_this.location.catalog_value_id === 45 ?
                                    <>
                                    
                                    <Link to="#" 
                                    //onClick={nextButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="save-button" 
                                        onClick={submitPressed}
                                        
                                        >
                                        Forward
                                        </Button>
                                    </Link>
                                </>

                                 
                                 : null}
                                
                    </Col>}
                    
                </Row>
    </div>
  )
}

export default AshadeepaformApproveDetails
