import {
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { Button } from 'react-bootstrap';

const DraftReject = ({
  loading,
  handleDraftTransaction,
  openRejection,
  handleCloseRejection,
  handleOpenRejectionDialog,
  rejectReasons,
  handleSubmitReject,
  toggleLang,
  handleToggle,
  handleCheckboxChange,
  handleDraftSubmitReject,
}) => {
  //console.log('openRejection:', openRejection);
  return (
    <>
      <Dialog
        open={openRejection}
        onClose={handleCloseRejection}
        maxWidth="lg"
        fullWidth
      >
        <div className="d-flex justify-content-between align-items-center px-3">
          <div>
            <DialogTitle
              style={{
                fontFamily: 'Arial, Serif',
                fontWeight: 'bold',
              }}
            >
              <h2>ತಿರಸ್ಕರಿಸಲು ಕಾರಣಗಳ ಪಟ್ಟಿ / Rejection Reason List </h2>
            </DialogTitle>
          </div>
          <div>
            <ButtonGroup variant="text" aria-label="Basic button group">
              <Button onClick={handleToggle}>
                {' '}
                {toggleLang
                  ? 'Click here for English'
                  : 'Click here for Kannada'}{' '}
              </Button>
            </ButtonGroup>
          </div>
        </div>
        <DialogContent>
          {rejectReasons &&
            rejectReasons.length > 0 &&
            rejectReasons.map((reason) => (
              <div key={reason.id}>
                <Checkbox value={reason.id} onChange={handleCheckboxChange} />
                <label>
                  {toggleLang
                    ? reason.rejection_reason_kannada
                    : reason.rejection_reason}
                </label>
              </div>
            ))}
        </DialogContent>
        <DialogActions>
          <Button className="bg-primary" onClick={handleCloseRejection}>
            Close
          </Button>
          <Button
            disabled={loading}
            className="bg-danger"
            onClick={handleDraftSubmitReject}
          >
            Draft Reject
          </Button>
        </DialogActions>
      </Dialog>

      <Button
        onClick={handleOpenRejectionDialog}
        variant="outline-primary"
        className="final-button-reject"
      >
        Draft Reject
      </Button>
    </>
  );
};

export default DraftReject;
