import { ApproveSchemeAPI } from 'components/adminPortal/SchemesAdmin/services/approveScheme.service';
import { draftSchemeApprove } from 'components/adminPortal/SchemesAdmin/services/draftScheme.service';
import uploadFileAPI from 'utils/fileupload';

export const DeliveryTransaction = async (data) => {
  if (data.status === 'Forward') {
    try {
      const result = await documentUpload(data);
      return result;
    } catch (error) {
      throw error;
    }
  }
};

const documentUpload = (data) => {
  return new Promise((resolve, reject) => {
    if (
      data.verificationDoc &&
      data.verificationDoc.length > 0 &&
      data.missingDoc &&
      data.missingDoc.length > 0
    ) {
      const promises1 = data.verificationDoc.map((doc) => uploadFileAPI(doc));
      const promises2 = data.missingDoc.map((doc) => uploadFileAPI(doc));

      Promise.all([...promises1, ...promises2])
        .then((responses) => {
          const responses1 = responses.slice(0, promises1.length);
          const responses2 = responses.slice(promises1.length);

          const verificationResponse = responses1.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));
          const missingResponse = responses2.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));

          const result = ApproveSchemeAPI(
            data.status,
            data.labourUserID,
            data.deptUserID,
            data.schemeTransactionID,
            data.order,
            data.remarks,
            verificationResponse,
            missingResponse,
            data.amount,
          );
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    } else if (data.verificationDoc && data.verificationDoc.length > 0) {
      Promise.all(data.verificationDoc.map((doc) => uploadFileAPI(doc)))
        .then((promiseResponse) => {
          const verificationResponse = promiseResponse.map((doc) => ({
            file_id: doc.image.fileId,
            file_name: doc.image.originalFileName,
          }));

          const result = ApproveSchemeAPI(
            data.status,
            data.labourUserID,
            data.deptUserID,
            data.schemeTransactionID,
            data.order,
            data.remarks,
            verificationResponse,
            [],
            data.amount,
          );
          resolve(result);
        })
        .catch((error) => {
          reject(error);
        });
    }
  });
};
