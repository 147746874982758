import { FormControl, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Divider, CircularProgress, IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useEffect, useParams, useState } from 'react';
import { Col, Row, Tooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import graphic from 'assets/images/undraw_under_construction_46pa.svg'
import doc1 from 'assets/documents/Employement certificate by the Employeer.pdf'
import doc2 from 'assets/documents/Employement certificate by the grama panchayath.pdf'
import doc3 from 'assets/documents/Employement certificate by the labour inspector.pdf'
import doc4 from 'assets/documents/Employement certificate by the Registration trade union.pdf'
import validator from 'validator';
import mobile from 'assets/images/Phone 1-01.svg'
import otp from 'assets/images/Phone otp-01.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser, generateOtp, getFile, getUser, getUserRegistrationDetails, loginUser, loginAdmin, SERVER, updateUser } from 'store/actions/user.actions';
import { getTokenCookie, getTokenCookieAdmin, removeTokenCookie, saveTokenCookie, showToast } from 'utils/tools';
import closeIcon from 'assets/images/close-01.svg'
import axios from 'axios';
import Accident from 'components/mainScreens/schemesDetails/accidentScheme';
import { useLocation } from "react-router-dom";
import { set } from 'date-fns';
import Button from '@mui/material/Button';

const Login = (props) => {

    // 6 month removal
    const [isActiveLogin, setIsActiveLogin] = useState(true);
    
    const [isActiveExist, setIsActiveExist] = useState(false);
    const [unorganziedRegisterBtn, setUnorganizedRegisterBtn] = useState(false);
    const [color, setColor] = useState(false);
    const [allValues, setAllValues] = React.useState({
        open: false,
        phone: "",
        otp: '',
        users: props.users,
        generate_otp: props.generate_otp,
        login_user: props.login_user,
        otp_sent: false,
        otp_sending: false,
        logging_in: false,
        phoneError: false,
        phoneErrorMessage: "",
        otpError: false,
        otpErrorMessage: "",
        openSnackbar: false,
        snackbarText: '',
        snackbarType: '',
        vertical: 'top',
        horizontal: 'right',
        // seconds: 60,
        resendReset: false
    });

    // const testvalue = useParams();

    // console.log('incoming_props1: ',testvalue)
    const location = useLocation();

    // useEffect(() => {
    //     // result: '?query=abc'
    //    console.log("getschemeDataboards",location.state.state); // result: 'some_value'
    // }, [location]);

    console.log("accident",props.state);
    console.log('incoming_props: ',props)
    console.log("incoming_props.location: ", props.location.productdetailProps)
    console.log("allValues: ", allValues)
    // console.log("incoming_props: ", props.match.params.fromPage)
    console.log("schemesData",props.location.state);
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    useEffect(() => {
        removeTokenCookie()
        
        dispatch(updateUser(""))
        // dispatch(deleteUser())
        dispatch(getUser("delete"))
        dispatch(loginUser("delete"))

        dispatch(generateOtp("delete"))
        dispatch(getUserRegistrationDetails("delete"))
        dispatch(getFile("delete"))

        localStorage.clear();
        sessionStorage.clear();

        console.log("SERVER", SERVER)

        if(users.getDeptUserRole !== undefined && users.getDeptUserRole !== null && users.getDeptUserRole.data[1] !== undefined && users.getDeptUserRole.data[1].board_id === 4){
            setUnorganizedRegisterBtn(true)
        }
        else{
            setUnorganizedRegisterBtn(false)
        }

        console.log("props", props.location)

        


        // const itemslocalStorage = { ...localStorage };
        // console.log("itemslocalStorage=" + JSON.stringify(Object.keys(itemslocalStorage),undefined,2))
    }, []);


    const changeHandler = e => {
        // console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

        if (e.target.name === "otp") {
            setAllValues({ ...allValues, 
                otpError: false ,
                otpErrorMessage: "",})
        }
        if (e.target.name === "phone") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                setAllValues({
                    ...allValues,
                    phoneError: false,
                    phoneErrorMessage: "",
                    otp: '', 
                    otpError: false, 
                    otpErrorMessage: "",
                    otp_sent: false
                })
            else
                setAllValues({
                    ...allValues,
                    phoneError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                    otp: '', 
                    otpError: false, 
                    otpErrorMessage: "",
                    otp_sent: false
                })
        }

        setAllValues(prevValues => {
            return { ...prevValues, [e.target.name]: e.target.value }
        })
    }

    const handleDownloadListOpen = () => {
        setAllValues((prevState) => ({
            ...prevState,
            open: !prevState.open
        }));
        // setAllValues({...allValues, [e.target.name]: e.target.value})
    };

    const handleLoginClick = () => {
       
        setIsActiveLogin(true)
        setIsActiveExist(false)
    }
    
    const handleExisitingClick = () => {
        props.history.push("/unorganized-registration")
        setIsActiveExist(true)
        setIsActiveLogin(false)
    }


    const handleClose = () => {
        setAllValues((prevState) => ({
            ...prevState,
            open: !prevState.open
        }));
    };

    const getOtp = async (event) => {
        event.preventDefault();
    
        if (!allValues.phoneError && allValues.phone.length === 10) {
            setAllValues(prevState => ({
                ...prevState,
                otp_sending: !prevState.otp_sending,
                generate_otp: ''
            }));

            let dataToLogin = { "phone_number": allValues.phone };
            // console.log("dataToLogin: " + JSON.stringify(dataToLogin))

            dispatch(generateOtp(dataToLogin))
        }
        else {
            setAllValues({
                ...allValues,
                phoneError: true,
                phoneErrorMessage: "Enter Correct Mobile Number!",
            })
        }
    };
    

    
    const login_func = async (event) => { // Make the function asynchronous
        event.preventDefault();

        if (
            users.generate_otp !== null &&
            !allValues.phoneError &&
            allValues.phone.length === 10 &&
            allValues.otp !== ''
        ) {
            setAllValues({
                ...allValues,
                logging_in: !allValues.logging_in,
            });

            let dataToLogin = {
                "phone_number": allValues.phone,
                "otp": allValues.otp,
                "hash": users.generate_otp.data.hash,
            };

            try {
                // Use fetch instead of axios
                const response = await fetch('https://geolocation-db.com/json/');
                const res = await response.json();

                console.log(res);

                // Check if the response status is successful
                if (response.ok) {
                    dispatch(loginUser(dataToLogin));
                } else {
                    // Handle the error case
                    setAllValues({
                        ...allValues,
                        logging_in: false,
                        otpError: true,
                        otpErrorMessage: "Error Sending OTP",
                    });
                }
            } catch (error) {
                console.error(error);
                // Handle the error case
                setAllValues({
                    ...allValues,
                    logging_in: false,
                    otpError: true,
                    otpErrorMessage: "Error Sending OTP",
                });
            }
        } else if (allValues.phoneError || allValues.phone.length !== 10) {
            setAllValues({
                ...allValues,
                phoneError: true,
                phoneErrorMessage: "Enter Correct Mobile Number!",
            });
        } else if (allValues.otp === '') {
            setAllValues({
                ...allValues,
                otpError: true,
                otpErrorMessage: "Please enter correct OTP!",
            });
        } else if (
            users.generate_otp === null ||
            users.generate_otp.data === undefined ||
            users.generate_otp.data.hash === undefined
        ) {
            setAllValues({
                ...allValues,
                otpError: true,
                otpErrorMessage: "Please enter correct OTP!",
            });
        } else {
            setAllValues({
                ...allValues,
                phoneError: true,
                phoneErrorMessage: "Error Sending OTP",
                otpError: true,
                otpErrorMessage: "Please enter correct OTP!",
            });
        }
    };

    // useEffect(() => {
    //     // result: '?query=abc'
    //     try{
    //         if(location.path&&location.state){
    //             props.history.push("/scheme-form/",location.state,"_id=10")
    //         }
    //     }
    //     catch{

    //     }
    //    console.log("getschemeDataboards",location.state); // result: 'some_value'
    // }, [location]);

    useEffect(() => {
        // console.log("users.generate_otp.message:"+users.generate_otp.message)
        try{
            
        if (users.generate_otp !== null && users.generate_otp.message !== null && users.generate_otp.message === 'Success') {
            // console.log("OTP generated Successfully");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: true,
            })
            showToast("SUCCESS", "OTP Sent Successfully!")
            users.generate_otp.message = null
        } else if (users.generate_otp !== null && users.generate_otp.message !== null && users.generate_otp.message !== undefined && users.generate_otp.message !== '') {
            // console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: users.generate_otp.message,
            })
            showToast("ERROR", users.generate_otp.message)
            users.generate_otp = null
        }
        else if (users.generate_otp !== null && 
            users.generate_otp.success === false && 
            users.generate_otp.error !== undefined && 
            users.generate_otp.error !== '') {
            // console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
            })
            showToast("ERROR", users.generate_otp.error)
            showToast("ERROR", "Please try after 30 minutes")
            users.generate_otp = null
        }
        else if (users.generate_otp !== null) {
            // console.log("In else: error");
            console.log("users.generate_otp:" + JSON.stringify(users.generate_otp));
            // setAllValues({
            //     ...allValues,
            //     otp_sending: false,
            //     otp_sent: false,
            //     phoneError: true,
            //     phoneErrorMessage: users.generate_otp,
            // })
            // showToast("ERROR", "Error Sending OTP")
            users.generate_otp = null
        }
        }
        catch(e){
            console.error(e)
        }

    }, [users.generate_otp])
    
    useEffect(() => {
        try{
        if(users.user !== undefined && users.user !== null && users.user !== ""){
            console.log("users.user.board_id:"+users.user.board_id)
            if(users.user.board_id === 1){
                props.history.push("/dashboard-user");

            }
            else if(users.user.board_id === 4){
                props.history.push("/dashboard-user-unorganized");
            }
           
           
        }
        }
        catch(e){
            console.error(e)
        }
    }, [users.user])

    

    useEffect(() => {
        // console.log("users.login_user.message:"+users.login_user.message)

        try{
        if (users.login_user !== null && users.login_user.message !== null && users.login_user.message === 'Valid OTP') {
            // console.log("in if : Valid OTP");
            setAllValues({
                ...allValues,
                logging_in: false,
            })
            saveTokenCookie(users.login_user.jwt)
            showToast("SUCCESS", "Login Successfull!")
            users.login_user.message = null

            // console.log("users.user.board_id:"+users.user.board_id)
            if(users.user.board_id === 1){
                props.history.push("/dashboard-user");
            }
            else if(users.user.board_id === 4){
                props.history.push("/dashboard-user-unorganized");
            }


        }
        else if (users.login_user !== null && users.login_user.message !== null && users.login_user.message === 'In Valid OTP') {
            // console.log("In elseif: In Valid OTP");
            setAllValues({
                ...allValues,
                logging_in: false,
                otpError: true,
            })
            showToast("WARN", "Invalid OTP!")

            users.login_user.message = null
        }
        else if (users.login_user !== null && users.login_user.message === "Access Denied") {
            setAllValues({
                ...allValues,
                logging_in: false,
            })
            showToast("ERROR", "After Funeral scheme is approved, user's profile is deactivated automatically.")

            users.login_user = null
        }
        else if (users.login_user !== null && users.login_user.message !== null) {
            setAllValues({
                ...allValues,
                logging_in: false,
            })
            showToast("ERROR", "Error Logging-in!")

            users.login_user = null
        }
        }
        catch(e){
            console.error(e)
        }
    }, [users.login_user])


    const [seconds, setSeconds] = React.useState(60);
    const [resendReset, setResendReset] = React.useState(false);
    const ResendOTP = () => {
        if (seconds > 0) {
            setTimeout(() => 
            setSeconds(seconds - 1)
            // setAllValues({
            //     ...allValues,
            //     seconds: allValues.seconds - 1
            // })
            , 1000);
            return ( 
                // <Link to="#" disabled>
                    <p className="generate-otp2">
                    Resend OTP after {seconds} seconds
                    </p>
                // </Link>
            )
        } else {
            setResendReset(true)
            setSeconds(60)
            setAllValues({
                ...allValues,
                otp_sent: false,
                // seconds: 60,
                // resendReset: true
            })
            return null
        }
    }

    return (
        <Row className="login-div">
            <Col xs={12} lg={6} xl={6} xxl={6}>
                <Row>
                    <Col sm={12} >
                        <div className="logo-div d-flex justify-content-start text-center">
                            
                            <img id="logo" src={logo} alt="..." className="logo-img-admin1 d-flex justify-content-start"/>
                           <div className = "logo-head d-flex justify-content-start text-center">
                            <h1 className="logo-text d-flex justify-content-start text-center" style={{color:'#CA0027', fontSize:'25px'}}>
                            ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ
                            </h1>
                            <h1 className="logo-text d-flex justify-content-start text-center" style={{color:'#CA0027', fontSize:'25px'}}>
                            Labour Department, Government of Karnataka
                                {/* Karnataka Building & Other Construction <br />Workers Welfare Board (KBOCWWB) */}
                                </h1>
                                </div>
                        </div>
                    </Col>
                    <Col sm={12}
                        className="login-img-col"
                    >
                        <img className="login-img" alt="..." src={graphic} />

                    </Col>
                    <Col sm={12} className="copyright">
                        
                        {/* <p>Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB), Government of Karnataka</p> */}
                    </Col>
                </Row>
            </Col>

            <Col xs={12} lg={6} className="login-form">
                <div className="login-form-container">
                    {/* <h2 className="login-form-head" style={{color:'#535353'}}>ಲಾಗಿನ್ / LOGIN </h2> */}
                    
                    {/* 6 month removal */}
                    
                    <div className='div-bar text-center row'>
                    <Button 
                    variant='contained'
                    className='col w-50 btn-switch text-capitalize text-center' 
                    size='lg' 
                    onClick={handleLoginClick}
                    style={{backgroundColor: isActiveLogin ? '#CA0027' : '#dee2e6',color:isActiveExist ? 'black' : ''}}
                    
                    >Login</Button>
                    <Button 
                    
                    className='col w-50 btn-switch text-capitalize text-center' 
                    variant='contained'
                    size='lg' 
                    onClick={handleExisitingClick}
                    style={{backgroundColor: isActiveExist ? '#CA0027' : '#dee2e6',color:isActiveExist ? '' : 'black'}}>
                   Register</Button>
                    </div>
                    
                    
                    <p className="phno-label" style={{fontSize:'20px', whiteSpace:'nowrap'}}>ನಿಮ್ಮ ಮೊಬೈಲ್ ಸಂಖ್ಯೆಯನ್ನು ನಮೂದಿಸಿ / Enter your mobile number</p>

                    <form>
                        <FormControl fullWidth className="phno-field">
                            <TextField
                                className="size21"
                                name="phone"
                                value={allValues.phone}
                                type="phone"
                                onChange={changeHandler}
                                error={allValues.phoneError}
                                helperText={allValues.phoneError ? "Please enter correct phone number" : ""}
                                // error={allValues.phoneErrorMessage.length > 0}
                                // helperText={allValues.phoneErrorMessage}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={mobile} alt="..." className="phone-icon" />
                                            <p className="countrycode">+91</p>
                                        </InputAdornment>
                                }}
                                variant="outlined"
                            >
                                </TextField>
                        </FormControl>

                        {
                            allValues.otp_sending ?
                                <CircularProgress className="loader-otp" /> 
                                
                                :
                                <>

                                {/* (<Link to="#" onClick={(event) => getOtp(event)}>
                                        <p className="generate-otp">
                                        {allValues.otp_sent ? "Resend OTP":"Generate OTP"}
                                        </p>
                                </Link>)
                                <br/> */}

                                {
                                !allValues.otp_sent ?
                                    <Link to="#" onClick={(event) => getOtp(event)}>
                                        <p className="generate-otp mb-5" style={{color:'#CA0027'}}>
                                        {resendReset ? "Resend new OTP  ":"Generate OTP"}
                                        </p>
                                    </Link>
                                    :
                                    <ResendOTP />
                                    }
                                </>
                        }
                        
                        <p className="phno-label mt-5 mb-0 w-100" style={{fontSize:'20px', whiteSpace:'nowrap'}}>ನೀವು ಸ್ವೀಕರಿಸಿದ OTP ಅನ್ನು ನಮೂದಿಸಿ / Enter your Received OTP</p>
                        <FormControl fullWidth className="otp-form">
                            <TextField
                                className="size31"
                                name="otp"
                                value={allValues.otp}
                                type="password"
                                onChange={changeHandler}
                                error={allValues.otpError}
                                placeholder=""
                                helperText={allValues.otpError ? "Please enter correct OTP!" : ""}
                                // helperText={allValues.otpErrorMessage}
                                // helperText
                                // helperText=""
                                variant="outlined"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        login_func(event)
                                    }
                                }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={otp} alt="..." className="otp-icon" />
                                        </InputAdornment>,
                                }}
                            />
                        </FormControl>

                        {
                            allValues.logging_in ?
                                <>
                                <CircularProgress className="loader-otp" /> 
                                </>
                                :
                                <>
                                <FormControl fullWidth className="loginbuttondiv">
                                <Link to="#" onClick={(event) => login_func(event)}>
                                    <p className="login-button" type="submit">
                                         ಲಾಗಿನ್ / Login
                                    </p>
                                </Link>
                                </FormControl>
                                </>
                        }
                    </form>

                    {
                        getTokenCookieAdmin() !== undefined &&
                        users.login_admin !== null &&
                        users.login_admin !== undefined &&
                        users.login_admin.data !== null &&
                        users.login_admin.data !== undefined &&
                        users.login_admin.data.department_user_id !== null &&
                        users.login_admin.data.department_user_id !== undefined &&
                        users.login_admin.data.department_user_id !== "" ? 

                        <div className="register-button-div">
                    
                     

                            <Link to="/unorganized-registration"
                                style={{ flexGrow: "1" }}
                                >
                                <p className="registration-button2 btn-ripple">
                                    Register for Unorganized Worker
                                </p>
                            </Link>
                          
                      
                        
                        </div>

                        :
                        null
                    }
                    

                    {/* <div className="cert-template-div" style={{whiteSpace:'nowrap'}} onClick={handleDownloadListOpen}>
                        Click here to download Employment Certificate Template <br/>
                        ಉದ್ಯೋಗ ಪ್ರಮಾಣಪತ್ರ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ
                </div> */}

                {/* <div className="cert-template-div" onClick={() => {props.history.push("/privacy-policy")}}>
                        Privacy Policy
                </div>
                <div className="cert-template-div" onClick={() => {props.history.push("/terms&conditions")}}>
                    Terms and Conditions
                </div> */}

                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        aria-labelledby="confirmation-dialog-title"
                        open={allValues.open}
                        onClose={handleClose}
                    >
                        {/* <DialogTitle className="download-dialog-title">Download Employee Certificate</DialogTitle> */}
                        <DialogTitle className="download-dialog-title">
                            Download Employee Certificate
                            {handleClose ? (
                                <IconButton aria-label="close" className="dwnldclosebtn" onClick={handleClose}>
                                {/* <CloseIcon /> */}
                                <img alt="..." src={closeIcon} style={{width: "30px", height: "30px"}}/>
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <DialogContent className="download-dialog">
                            <List component="nav" aria-label="mailbox folders">
                                <a href={doc1} download="Employment certificate by the Employeer" target='_blank' rel="noreferrer">
                                    <ListItem button >
                                        <ListItemText primary="Employment certificate by the Employer" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc2} download="Employment certificate by the Grama Panchayat" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Grama Panchayat" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc3} download="Employment certificate by the Labour Inspector" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Labour Inspector" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc4} download="Employment certificate by the Registration Trade Union" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Registration Trade Union" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                            </List>
                        </DialogContent>
                        <DialogActions className="download-dialog-button">
                            <Button autoFocus color="primary" onClick={handleClose} >
                                Back
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Col>

            <Col sm={12} className="copyright">
                    {/* <Link to="/admin"><p>Admin</p></Link> */}
                    </Col>

            {/* <Snackbar
                anchorOrigin={{ vertical: allValues.vertical, horizontal: allValues.horizontal }}
                open={allValues.openSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allValues.snackbarType} >
                    {allValues.snackbarText}
                </MuiAlert>
            </Snackbar> */}
        </Row>
    )

}

export default Login;