import {
  Backdrop,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
  makeStyles,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { Translate } from "react-redux-i18n";
import {
  Required,
  dataURLtoFile,
  getTokenCookie,
  showToast,
} from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import { useState } from "react";
import axios from "axios";
import { SERVER } from "store/actions/user.actions";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { AddCircleRounded, CheckCircle } from "@material-ui/icons";
import checkgreen from "assets/images/Subtraction 1.svg";
import stringSimilarity from "utils/nameMatching";
import close from "assets/images/closeIcon.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import moment from "moment";
import Button2 from "@mui/material/Button";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SimpleBackdrop from "components/loadingScreen/Backdrop";
import {
  findDuplicates,
  hasEmptyPropertiesFamilyDetails,
} from "utils/checkEmptyArray";
import closeIcon from "assets/images/close.png";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

const AlreadyRegistered = (props) => {
  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();

  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);

  const [fetchResidence, setFetchResidence] = useState(null);
  const [residence, setResidence] = useState(null);

  const [fetchState, setFetchState] = useState(null);
  const [state, setState] = useState(null);

  const [submissionLocation, setSubmissionLocation] = useState(null);

  const [circleData, setCircleData] = React.useState(null);

  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState();

  const [district, setDistrict] = useState(null);
  const [districtId, setDistrictId] = useState(null);
  const [taluk, setTaluk] = useState([]);
  const [talukId, setTalukId] = useState(null);
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState(null);
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState(null);

  const [circleDataFound, setCircleDataFound] = React.useState(null);
  const [circleDataFoundDistrict, setCircleDataFoundDistrict] =
    React.useState(null);
  const [circleDataFoundTaluk, setCircleDataFoundTaluk] = React.useState(null);
  const [circleDataFoundCity, setCircleDataFoundCity] = React.useState(null);
  const [circleDataFoundVillage, setCircleDataFoundVillage] =
    React.useState(null);

  const [streetName, setStreetName] = useState(null);
  const [houseNumber, setHouseNumber] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [pincode, setPincode] = useState(null);

  const [gender, setGender] = useState(null);
  const [fetchCategory, setFetchCategory] = useState(null);
  const [categoryID, setCategoryID] = useState(null);
  const [fetchReligion, setFetchReligion] = useState(null);
  const [religionID, setReligionID] = useState(null);
  const [fetchNatureOfWork, setFetchNatureOfWork] = useState(null);
  const [natureOfWorkID, setNatureOfWorkID] = useState(null);

  const [isMigrant, setIsMigrant] = useState(false);
  const [migrant, setMigrant] = useState(null);

  const [isAyushman, setIsAyushman] = useState(false);
  const [ayushmanCard, setAyushmanCard] = useState(null);

  const [fetchRelation, setFetchRelation] = useState(null);
  const [fetchProfession, setFetchProfession] = useState(null);
  const [fetchEducation, setFetchEducation] = useState(null);

  const [checkBank, setcheckBank] = React.useState(false);
  const [loadingVerifyBank, setLoadingVerifyBank] = React.useState(false);
  const [IFSCCode, setIFSCCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);

  const [bankName, setBankName] = useState(null);
  const [bankBranch, setBankBranch] = useState(null);
  const [bankAddress, setBankAddress] = useState(null);

  const [loading, setLoading] = React.useState(false);
  const [aadhaarToken, setAadhaarToken] = React.useState(null);
  const [redirectback, setRedirectback] = React.useState(false);

  const [aadharDuplicate, setaadharDuplicate] = React.useState(false);
  const [aadharValidated, setAadharValidated] = React.useState(false);
  const [vaultRefNumber, setVaultRefNumber] = React.useState(null);

  const [nameMatched, setNameMatched] = useState(false);
  const [loadingfiles, setloadingfiles] = useState(true);

  const [selectedPassbookFiles, setSelectedPassbookFiles] = useState({});
  const [passbookUploadResults, setPassbookUploadResults] = React.useState({});
  const [removedFile, setRemovedFile] = React.useState(false);
  const [passbookUploadId, setUploadPassbookId] = useState(null);

  const [labourDoc, setLabourDoc] = React.useState(null);
  const [selectedLabour, setSelectedLabour] = useState({});
  const [uploadImageid, setUploadImageid] = React.useState(null);

  const [loadingBackDrop, setLoadingBackDrop] = useState(false);

  const [inputList, setInputList] = useState([
    {
      relation: "",
      firstName: "",
      middleName: "",
      lastName: "",
      aadhaar1: "",
      aadhaar2: "",
      aadhaar3: "",
      dob: "",
      profession: "",
      education: "",
      nominee: false,
    },
  ]);

  const [familyDetails, setFamilyDetails] = useState([
    {
      relation: 49,
      firstName: null,
      middleName: null,
      lastName: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
    },
    {
      relation: 50,
      firstName: null,
      middleName: null,
      lastName: null,

      dob: null,
      profession: null,
      education: null,
      nominee: false,
    },
  ]);

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    props.history.push("/login");
  };

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Type of Residence",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchResidence(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Relation",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchRelation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Category",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchCategory(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Religion",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchReligion(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Nature of Work",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchNatureOfWork(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Education",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchEducation(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Profession",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        // console.log(res.data.data);
        setFetchProfession(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/global/get_state_details`)
      .then((res) => {
        // console.log(res.data.data);
        setFetchState(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    var registration_seva_id = sessionStorage.getItem("registrationID");
    var reference_seva_id = sessionStorage.getItem("referenceID");

    const payLoad = {
      registration_number: registration_seva_id,
      application_reference_number: reference_seva_id,
    };
    const config = {
      method: "post",
      url: `${SERVER}/seva_sindhu/check_user_in_seva_sindhu`,
      data: payLoad,
    };
    axios(config)
      .then((res) => {
        // console.log(res.data.data);
        const { submissionlocation } = res.data.data[0];
        // console.log(submissionlocation,"DATAFOUND");
        setSubmissionLocation(submissionlocation);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    getStateDetails();
    getDistrictDetails();
    getTalukDetails();
    getCityDetails();
    getVillageDetails();
  }, [state, districtId, talukId, cityId]);

  useEffect(() => {
    if (submissionLocation !== null && submissionLocation !== undefined) {
      var data = JSON.stringify({
        ref_seva_sindhu_circle_id: submissionLocation,
      });

      var config = {
        method: "post",
        url: SERVER + "/seva_sindhu/get_circle_mapped_to_villages",

        data: data,
      };

      axios(config).then(function (response) {
        console.log(
          "Circle_data" + JSON.stringify(response.data, undefined, 2)
        );
        setCircleData(response.data.data);
      });
    }
  }, [submissionLocation]);

  useEffect(() => {
    if (circleData !== null && circleData !== undefined) {
      if (circleData.length < 0) {
      } else {
        setCircleDataFound(circleData);
      }
    }
  }, [circleData]);

  useEffect(() => {
    if (circleDataFound !== null && circleDataFound !== undefined) {
      console.log("circleDataFound", circleDataFound);
      // let circleDataFoundDistrict = circleDataFoundDistrict.forEach(
      //     filter(o => o.district_name
      // )
      const circleDataFoundDistrict = [];

      const circleDataFoundTaluk = [];
      const circleDataFoundCity = [];
      const circleDataFoundVillage = [];

      circleDataFound.forEach((field) => {
        console.log("field", field.village_area_ward);
        circleDataFoundDistrict.push(field.district_name);
        circleDataFoundTaluk.push(field.taluk_name);
        circleDataFoundCity.push(field.gram_panchayatcity_town_name);
        // circleDataFoundVillage.push((field.village_area_ward_id))
        circleDataFoundVillage.push(field.village_area_ward);

        setCircleDataFoundDistrict(circleDataFoundDistrict);
        setCircleDataFoundTaluk(circleDataFoundTaluk);
        setCircleDataFoundCity(circleDataFoundCity);
        setCircleDataFoundVillage(circleDataFoundVillage);

        // if(field.district_name === true){
        //     circleDataFoundDistrict.push((field))
        //     console.log("circleDataFoundDistrict", field)

        // }
      });
      console.log("circleDataFoundDistrict", circleDataFoundDistrict);

      // let circleDataFoundDistrict = circleDataFoundDistrict.filter(o => o.district_name )
      // let circleDataFoundTaluk = circleDataFound.filter(o => o.taluk_name)
      // let circleDataFoundCity = circleDataFound.filter(o => o.gram_panchayatcity_town_name)
      // let circleDataFoundVillage = circleDataFound.filter(o => o.village_area_ward)
      console.log("circleDataFoundDistrict", circleDataFoundDistrict);
      console.log("circleDataFoundTaluk", circleDataFoundTaluk);
      console.log("circleDataFoundCity", circleDataFoundCity);
      console.log("circleDataFoundVillage", circleDataFoundVillage);
    }
  }, [circleDataFound]);

  const getStateDetails = async () => {
    try {
      const data = {
        key: "user_id",
        board_id: 1,
        procedure_name: "all",
        catalog_name: "Type of Residence",
      };
      const response = await axios.get(
        `${SERVER}/global/get_state_details`,
        data
      );
      console.log("responseState:", response.data.data);

      setStates(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const handleState = (event) => {
    const getStateId = event.target.value;
    console.log(getStateId);
    setStateId(getStateId);
  };

  const getDistrictDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_district_details?state_id=${state}`
      );
      console.log("responseDistrict:", response.data.data);
      // setDistrict(response.data.data)
      console.log(circleDataFoundDistrict, "circleDataFoundDistrictLoop");
      if (circleDataFoundDistrict !== null) {
        var districtData = response.data.data;
        console.log("districtData", districtData);
        getDistrictFilteredData(districtData, circleDataFoundDistrict);
      } else {
        setDistrict(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getDistrictFilteredData = (districtData, circleDataFoundDistrict) => {
    var fieldArray = [];
    districtData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArray");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundDistrict.indexOf(e) > -1;
    });
    console.log("intersection", intersection);
    // setDistrict(intersection)
    // districtData.forEach((field) => {
    //     if(field.dis)
    // })
    var districtDataFinal = districtData.filter((value) =>
      value.name.includes(intersection)
    );
    // var districtDataFinal = []
    console.log("districtDataFinal", districtDataFinal);
    setDistrict(districtDataFinal);
  };

  const handleDistrict = (event) => {
    const getDistrictId = event.target.value;
    console.log(getDistrictId);
    setDistrictId(getDistrictId);
  };

  const getTalukDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_taluk_details?district_id=${districtId}`
      );
      console.log("responseTaluk:", response.data.data);

      if (circleDataFoundTaluk !== null) {
        var talukData = response.data.data;
        getTalukFilteredData(talukData, circleDataFoundTaluk);
      } else {
        setTaluk(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getTalukFilteredData = (talukData, circleDataFoundTaluk) => {
    var fieldArray = [];
    talukData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArray");
    console.log(circleDataFoundTaluk, "circleDataFoundTaluk");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundTaluk.indexOf(e) > -1;
    });
    console.log("intersectionTaluk", intersection);
    var talukDataFinal = talukData.filter((value) =>
      value.name.includes(intersection)
    );
    var talukDataFinal = [];
    intersection.forEach((field) => {
      let talukDataFina = talukData.filter((value) => value.name === field);
      talukDataFinal.push(...talukDataFina);
    });
    console.log("talukDataFinal", talukDataFinal);
    setTaluk(talukDataFinal);
  };

  const handleTaluk = (event) => {
    const getTalukId = event.target.value;
    setTalukId(getTalukId);
  };

  const getCityDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_city_details?taluk_id=${talukId}`
      );
      console.log("responseCity:", response.data.data);
      if (circleDataFoundCity !== null) {
        var cityData = response.data.data;
        getCityFilteredData(cityData, circleDataFoundCity);
      } else {
        setCity(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };

  const getCityFilteredData = (cityData, circleDataFoundCity) => {
    var fieldArray = [];
    cityData.forEach((field) => {
      console.log("fieldValues", field.name);

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArraygetCityFilteredData");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundCity.indexOf(e) > -1;
    });
    console.log("intersectiongetCityFilteredData", intersection);
    var cityDataFinal = cityData.filter((value) =>
      value.name.includes(intersection)
    );
    var cityDataFinal = [];
    intersection.forEach((field) => {
      let cityDataFina = cityData.filter((value) => value.name === field);
      cityDataFinal.push(...cityDataFina);
    });
    console.log("talukDataFinalgetCityFilteredData", cityDataFinal);
    setCity(cityDataFinal);
  };

  const handleCity = (event) => {
    const getCityId = event.target.value;
    setCityId(getCityId);
  };

  const getVillageDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_village_details?city_id=${cityId}`
      );
      console.log("responseVillage:", response.data.data);
      if (circleDataFoundVillage !== null) {
        var villageData = response.data.data;
        console.log("Village_get_village_details", villageData);
        getVillageFilteredData(villageData, circleDataFoundVillage);
      } else {
        setVillage(response.data.data);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      console.log(JSON.stringify(error.response));
    }
  };
  const getVillageFilteredData = (villageData, circleDataFoundVillage) => {
    console.log("circleDataFoundVillage", circleDataFoundVillage);
    var fieldArray = [];
    villageData.forEach((field) => {
      // console.log("fieldValues",field.name)

      fieldArray.push(field.name);
      // let circleDataFoundDistrictFiltered = circleDataFoundDistrict.filter(value => value === fieldArray)

      // let circleDataFoundDistrictFilered = circleDataFoundDistrict.filter( value => value === field)

      // circleDataFoundDistrict.forEach((circlename) => {
      // var circleName = circlename
      // let circleDataFoundDistrictFiltered = field.filter(value => value.name === circleDataFoundDistrict[0])
      // console.log(circleDataFoundDistrictFiltered,"circleDataFoundDistrictFilered")

      // })
    });
    console.log(fieldArray, "fieldArrayV");

    var intersection = fieldArray.filter(function (e) {
      return circleDataFoundVillage.indexOf(e) > -1;
    });
    console.log("intersectionVillage008", intersection);
    // var villageDataFinal= villageData.filter(value => value.name.includes(intersection))
    var villageDataFinal = villageData.filter((value) =>
      circleDataFoundVillage.some((item) => value.name === item)
    );

    // var villageDataFinal = []
    // intersection.forEach((field) => {
    //     console.log("FOREACHVILLAGE:",field);
    //     let villageDataFina= villageData.filter(value => value.id === field)
    //     console.log("",villageDataFina);
    //     villageDataFinal.push(...villageDataFina)

    // })
    // var villageDataFinal= villageData.filter(value => value.id === 19107)
    console.log("villageDataFinal", villageDataFinal);
    setVillage(villageDataFinal);
  };

  const handleVillage = (event) => {
    console.log("event.target.value", event.target.value);
    const getVillageId = event.target.value;
    setVillageId(getVillageId);
  };

  const handleFormChange = (index, event) => {
    console.log(index, event.target.value, event.target.name, "FAMILYDETAILS");
    let data = [...familyDetails];
    if (event.target.name === "firstName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "middleName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "lastName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^A-Za-z ]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar1") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar2") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "aadhaar3") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else {
      data[index][event.target.name] = event.target.value;
    }
    setFamilyDetails(data);
  };

  const handleDateChange = (date, index, from) => {
    console.log(date, index);
    let data = [...familyDetails];
    data[index][from] = new Date(moment(date));
    setFamilyDetails(data);
  };

  const handleCheckBox = (index, event, from) => {
    console.log("Nominees", index, event.target.checked, from);
    let data = [...familyDetails];

    // Uncheck the previous checkbox in the array
    if (event.target.checked) {
      for (let i = 0; i < data.length; i++) {
        if (i !== index) {
          data[i][from] = 0;
        }
      }
    }

    // Update the current checkbox state
    data[index][from] = event.target.checked ? 1 : 0;
    setFamilyDetails(data);
  };

  const addNewFamilyMember = () => {
    let newfield = {
      relation: null,

      firstName: null,
      middleName: null,
      lastName: null,
      parentChildUniqueID: null,
      dob: null,
      profession: null,
      education: null,
      nominee: false,
      isInsert: 1,
      isActive: 1,
      isDeleted: 0,
      isShowing: true,
    };

    setFamilyDetails([...familyDetails, newfield]);
  };

  const handleKeyDown = (ev) => {
    ev.preventDefault();
  };

  const removeFamilyMembers = (index) => {
    let data = [...familyDetails];
    data.splice(index, 1);
    setFamilyDetails(data);
  };

  const verifyBank = () => {
    if (IFSCCode !== null && IFSCCode !== "" && IFSCCode !== "null") {
      setLoadingVerifyBank(true);

      var config = {
        method: "get",
        url: SERVER + "/global/get_ifsc_details?ifsc=" + IFSCCode,
        headers: {
          "Content-Type": "application/json",
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "bank step1 response: " +
              JSON.stringify(response.data.data, undefined, 2)
          );

          if (
            response.data.success === true &&
            response.data.data !== undefined &&
            response.data.data.BANK !== undefined
          ) {
            showToast("SUCCESS", "Bank Account Details Fetched Successfully");
            setLoadingVerifyBank(false);
            setcheckBank(true);
            setBankName(response.data.data.BANK);
            setBankBranch(response.data.data.BRANCH);
            setBankAddress(response.data.data.ADDRESS);
            // setAllValues({
            //     ...allValues,
            //     bankFetchedData: response.data.data,
            //     is_bank_verified: true,
            //     bankName: response.data.data.BANK,
            //     bankBranch: response.data.data.BRANCH,
            //     bankAddress: response.data.data.ADDRESS,
            // })
          } else {
            setLoadingVerifyBank(false);
            showToast("ERROR", "Bank Details Fetching Failed!");
            // showToast("ERROR", "Bank Details Fetching Failed:  "+response.data.data.msg.status)
          }
        })
        .catch(function (error) {
          console.error("bank step1 error: " + error);
          console.error(
            "bank step1 error.response: " +
              JSON.stringify(error.response, undefined, 2)
          );
          setLoadingVerifyBank(false);
          showToast(
            "ERROR",
            "Bank Details Fetching Failed:  " + error.response.data.errors.msg
          );
          // showToast("ERROR", "Some error occurred while verifying bank details!")
        });
    } else {
      showToast("ERROR", "Please Fill IFSC Code!");
    }
  };

  // **Aadhaar API's

  const verifyAadhaar = () => {
    var currentDateTime = Date.now();
    var randNum = Math.floor(100000 + Math.random() * 900000);
    console.log("randNum: " + randNum);

    console.log(currentDateTime, "currentDateTime");

    //Production Data

    var data = JSON.stringify({
      responseRedirectURL:
        "https://kbocwwb.karnataka.gov.in/already-registered",
    });

    // Local Production Data

    // var data = JSON.stringify({

    //   responseRedirectURL: 'http://localhost:3000/already-registered',
    // });

    //Dev Data

    // var data = JSON.stringify({

    //     "responseRedirectURL": "https://gcdmsalpha.hostg.in:7701/register-address"
    // });

    // local host data

    // var data = JSON.stringify({
    // "deptCode": "9",
    // "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    // "integrationPassword": "@895u5Xxe-2!7!!Y",
    // "txnNo": randNum,
    // "txnDateTime": currentDateTime,
    // "serviceCode": "3",
    // "responseRedirectURL": "http://localhost:3000/register-address"
    // });

    // var data = JSON.stringify({
    //     "deptCode": "9",
    //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
    //     "integrationPassword": "@895u5Xxe-2!7!!Y",
    //     "txnNo": randNum,
    //     "txnDateTime": currentDateTime,
    //     "serviceCode": "3",
    //     "responseRedirectURL": "https://gcdmsdev.web.app/register-address"

    // });

    var config = {
      method: "post",
      url: SERVER + "/global/get_ekyc_token",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        setLoading(false);

        // setAadhaarToken(response.data.token.Toke n);
        var aadharToken = response.data.data.token;
        if (aadharToken !== null) {
          // window.open("https://dbt.karnataka.gov.in:8443/HSM_Service_ASPX/EKYCService.aspx?key=a6ed232e-0bd9-4c24-89d7-67ba59bef639&token=" + aadharToken, "_self")

          //Production Data

          window.open(
            "https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=" +
              aadharToken,
            "_self"
          );

          setRedirectback(true);
        }
      })
      .catch((error) => {
        showToast("warn", "Pleasr Verify Aadhaar !!");
      });
  };

  useEffect(() => {
    if (window.location.toString().includes("Success") && !aadharValidated) {
      var queryString = JSON.stringify(props.location.search);

      var vaultRefN = queryString
        .substring(queryString.lastIndexOf("refno=") + 6)
        .split(" ")[0];
      var vaultRefNo = vaultRefN.substring(0, vaultRefN.length - 1);
      setVaultRefNumber(vaultRefNo);

      var data = JSON.stringify({
        key: "aadhar_no",
        value: vaultRefNo,
        board_id: 1,
      });

      var config = {
        method: "post",
        url: SERVER + "/user/get-user",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config).then(function (response) {
        setLoading(false);
        console.log("checkAadhaarDuplicate response:", response.data);

        var check = true;
        var aadharDuplicate = response.data.data;
        console.log("aadharDuplicate:" + response.data.data);
        setaadharDuplicate(response.data);
      });
    } else if (window.location.toString().includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.");
    }

    // setdocumentUploaded(false)

    // dispatch(addLabourFamilyDetails("delete"))
  }, []);

  useEffect(() => {
    console.log("in useEffect5");

    if (aadharDuplicate) {
      axios
        .get(`${SERVER}/user/get_ad_details/${vaultRefNumber}`, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        })
        .then(function (response) {
          console.log("ResponseEKYCDATA003", response);
          // showToast("SUCCESS", "Aadhar Verified")
          console.log("ResponseEKYCDATA:", response.data.data[0]);

          if (
            response.data.data[0] !== undefined &&
            response.data.data[0] !== null
          ) {
            setAadharValidated(true);
            // showToast("SUCCESS", "Aadhaar Verified Successfully")

            let sevaSindhuName = sessionStorage
              .getItem("LabourName")
              .toLowerCase();
            // let sevaSindhuName = 'Murali R Bharadwaj';
            let aadhaarName = response.data.data[0].name.toLowerCase();
            // let string1 = "Murali R Bharadwaj"
            // let string2 = "Bharadwaj R Murali"

            // stringSimilarity(string1,string2)
            // console.log(sevaSindhuName,aadhaarName,"LOWERCASE");
            if (stringSimilarity(sevaSindhuName, aadhaarName) >= 0.5) {
              showToast("SUCCESS", "Name Matched as per the Aadhaar");
              showToast("SUCCESS", "Aadhaar Verified");
              setNameMatched(true);
            } else {
              setNameMatched(false);
              showToast(
                "ERROR",
                "Name Matching Failed,Name did not matched as per the aadhaar"
              );
              showToast("WARN", "Try again with your Aadhaar number");
            }

            var gender2 = null;
            if (response.data.data[0].gender === "M") {
              gender2 = "27";
              setGender(gender2);
            } else if (response.data.data[0].gender === "F") {
              gender2 = "28";
              setGender(gender2);
            } else {
              gender2 = "29";
              setGender(gender2);
            }
            // console.log('gender: ' + gender2);

            // cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
          }
        })
        .catch(function (error) {
          console.log(error);
          if (error.response.data.errors !== undefined) {
            showToast("ERROR", error.response.data.errors);
          }
        });
    }
  }, [aadharDuplicate]);

  // ** FILE UPLOAD API's

  const handlePassbook = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedPassbookFiles).concat(
        Array.from(event.target.files)
      );
      console.log("joined1", joined);

      setSelectedPassbookFiles(joined);
    }

    setloadingfiles(false);
  };

  const handleLabourChange = (event) => {
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedLabour).concat(
        Array.from(event.target.files)
      );
      console.log("joined", joined);
      setSelectedLabour(joined);
    }

    setloadingfiles(false);
  };

  useEffect(() => {
    console.log("SelectedLabour_out", selectedLabour);

    if (
      selectedLabour !== null &&
      selectedLabour !== undefined &&
      selectedLabour.length > 0
    ) {
      console.log("SelectedLabour_in");
      let promises = [];

      selectedLabour.forEach(async (i) => {
        var selectedblob = i;
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        var formdataobj = "";
        var fileName = "";
        reader.onloadend = await function (event) {
          fileName = selectedblob.name;
          var base64data = event.target.result;
          formdataobj = {
            file: base64data,
            userId: 120,
            fileType: fileName,
          };
          promises.push(fileUploadAPI(formdataobj, fileName));
          if (promises.length === selectedLabour.length) {
            Promise.all(promises).then((results) => {
              console.log("results of promise1: ");
              console.log(results);
              setLabourDoc(results);
            });
          }
        };
      });
    }
  }, [selectedLabour]);
  useEffect(() => {
    console.log("SelectedLabour_Doc_OUT ");

    if (labourDoc !== null && labourDoc.length > 0) {
      console.log("SelectedLabour_Doc_OUT ");

      var filelist1 = [];

      labourDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist1: " + JSON.stringify(filelist1[0].file_id));
      setUploadImageid(filelist1);

      // setfilelistAttested(filelist1)

      // submitFiles(filelist, filelist1)
    }
  }, [labourDoc]);

  useEffect(() => {
    console.log("in useEffect [selectedfiles]: " + selectedPassbookFiles);
    if (selectedPassbookFiles !== null && selectedPassbookFiles.length > 0) {
      uploadPassbook();
    }
  }, [selectedPassbookFiles]);

  const uploadPassbook = () => {
    let promises = [];

    selectedPassbookFiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(fileUploadAPI(formdataobj, fileName));
        if (promises.length === selectedPassbookFiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setPassbookUploadResults(results);
          });
        }
      };
    });
  };

  useEffect(() => {
    console.log("SelectedLabour_Doc_OUT ");

    if (passbookUploadResults !== null && passbookUploadResults.length > 0) {
      console.log("SelectedLabour_Doc_OUT ");

      var filelist1 = [];

      passbookUploadResults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist1: " + JSON.stringify(filelist1[0].file_id));
      setUploadPassbookId(filelist1);

      // setfilelistAttested(filelist1)

      // submitFiles(filelist, filelist1)
    }
  }, [passbookUploadResults]);

  const fileUploadAPI = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const removefile = (index) => {
    setloadingfiles(true);
    setRemovedFile(true);

    // selectedfiles.splice(1,index)
    selectedPassbookFiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  const handleSubmit = () => {
    setLoadingBackDrop(true);
    const families = [];
    const familyData = familyDetails.map((data) => {
      return {
        relationType: data.relation,
        firstName: data.firstName ? data.firstName.trim() : null,
        middleName: data.middleName ? data.middleName.trim() : null,
        lastName: data.lastName ? data.lastName.trim() : null,

        dob: moment(data.dob).format("YYYY-MM-DD"),
        profession: data.profession,
        education: data.education,
        isNominee: data.nominee ? 1 : 0,
        isRegistered: 0,
        registerationNo: sessionStorage.getItem("registrationID"),
      };
    });

    // families.push(familyData);
    const familyDetailsCheck = familyDetails.filter(
      (item) => !hasEmptyPropertiesFamilyDetails(item)
    );
    // const duplicateNames = findDuplicates(familyData, 'aadhar_no');

    if (
      residence &&
      state &&
      districtId &&
      talukId &&
      cityId &&
      villageId &&
      streetName &&
      houseNumber &&
      pincode &&
      accountNumber &&
      IFSCCode &&
      categoryID &&
      religionID &&
      natureOfWorkID &&
      isMigrant &&
      isAyushman
    ) {
      if (familyData.length === familyDetailsCheck.length) {
        // if (duplicateNames && duplicateNames.length === 0) {
        const isNoNomineeSelected = familyDetails.every(
          (item) => !item.nominee
        );
        if (isNoNomineeSelected) {
          showToast("ERROR", "Please select nominee");
        } else {
          if (selectedPassbookFiles && selectedPassbookFiles.length > 0) {
            setOpenBackdrop(true);

            const data = {
              boardId: 1,
              sevasindhuId: +sessionStorage.getItem("TempID"),
              // "temp_id": 131,
              vaultRefNo: +vaultRefNumber,
              mobile: +sessionStorage.getItem("mobileNumber"),
              isInterStateMigrant: isMigrant ? 1 : 0,
              migrateFromStateId: isMigrant === "1" ? migrant : null,
              natureofwork: natureOfWorkID,
              isAyushmanCardHolder: isAyushman ? 1 : 0,
              ayushmanCardNumber: isAyushman === "1" ? ayushmanCard : null,

              religion: religionID,
              cast: categoryID,
              // "verified_mobile_no": "6666666666",
              sevasindhuCard: uploadImageid ? uploadImageid : [],

              address: [
                {
                  addressBaseType: 10,
                  addressType: residence,
                  doorNo: houseNumber,
                  streetName: streetName,
                  villageAreaId: villageId,
                  cityTownGramPanchayatId: cityId,
                  talukId: talukId,
                  districId: districtId,
                  stateId: state,
                  landMark: landmark ? landmark : "",
                  pinCode: +pincode,
                },
              ],
              family: familyData,
              bank: [
                {
                  ifsc: IFSCCode,
                  accountNo: +accountNumber,
                  name: bankName,
                  branch: bankBranch,
                  address: bankAddress,
                  passbookId: "-",
                  passbook: passbookUploadId ? passbookUploadId : [],
                  isNominee: 0,
                  isVerified: 1,
                },
              ],
            };
            console.log("SEVA SINDHU DATA", data);

            const config = {
              method: "put",
              url: SERVER + "/seva_sindhu/v2/register",

              data: data,
            };

            // console.log();
            axios(config)
              .then((res) => {
                //   console.log(res.data.data,"SUBMITRESPONSE");
                setLoadingBackDrop(false);
                showToast("SUCCESS", "Labour Transfered Successfully");
                setSubmitSuccess(true);
              })
              .catch((err) => {
                console.log(err);
                setLoadingBackDrop(false);
                showToast("ERROR", "Something went wrong");
              });
          } else {
            showToast("ERROR", "Please Upload Document");
          }
        }
        // } else {
        //   showToast('ERROR', `Family Member's Aadhaar number cannot be same`);
        // }
      } else {
        showToast("ERROR", "Please fill mandatory family details");
      }
    } else {
      showToast("ERROR", "Please fill all mandatory details");
    }
  };

  return (
    <>
      {loadingBackDrop ? <SimpleBackdrop /> : null}

      {loading ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={loading ? null : handleCloseBackdrop}
        >
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : null}

      {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={loading ? null : handleCloseBackdrop}
        >
          <div style={{ display: "block" }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: "auto", width: "200px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className='final-success-title'>Successfully Submitted</p>{" "}
              <br />
              <p className='final-success-title'>
                Please login to access your E-Card
              </p>
              {/* <a  onClick={() => props.history.push("/")}> login </a> */}
            </div>
            <Link
              to='#'
              // onClick={printDocument}
              style={{ flexGrow: "0.5" }}
            >
              <a
                variant='outline-primary'
                className='final-success-link'
                onClick={() => props.history.push("/login")}
              >
                {" "}
                Login{" "}
              </a>
            </Link>
          </div>
        </Backdrop>
      ) : null}

      <Row className='top-div '>
        <Row className='topbar-row'>
          <Col xs={12} md={4} lg={6} className='top-bar-col'>
            <div className='logo-div-profile'>
              <a href='/login'>
                <img id='logo' src={logo} alt='...' className='logo-img' />
              </a>
              <h1 className='logo-text'>
                {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                <Translate value='header.commonTitle' />
                <br />
                <Translate value='header.subcommonTitle' />
              </h1>
            </div>
          </Col>
          <div className='d-flex justify-content-end'>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              <Select
                className='select-language'
                style={{ width: "100%" }}
                variant='outlined'
                labelId='demo-simple-select-required-label'
                // value={allValues.language}
                name='language'
                displayEmpty
                // onChange={handleChange1}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                    <img alt='...' src={language} className='language-img' />
                  </ListItemIcon>
                  <ListItemText primary='Select Language' />
                </MenuItem>

                <MenuItem value='en'>
                  <ListItemText primary='English' />
                </MenuItem>
                <MenuItem value='ka'>
                  <ListItemText primary='ಕನ್ನಡ' />
                </MenuItem>
              </Select>
            </Col>
          </div>
        </Row>
      </Row>
      <div className='d-flex justify-content-center align-items-center my-5'>
        <>
          {aadharValidated && nameMatched ? (
            <Button2
              startIcon={
                <VerifiedIcon style={{ width: "50px", height: "40px" }} />
              }
              color='success'
              variant='contained'
              onClick={verifyAadhaar}
              disabled={aadharValidated && nameMatched ? true : false}
              sx={{
                "&.Mui-disabled": {
                  background: "#66BB6A",
                  color: "#ffffff",
                },
              }}
            >
              Aadhaar Verified
            </Button2>
          ) : (
            <Button2
              startIcon={
                <VerifiedIcon style={{ width: "50px", height: "40px" }} />
              }
              color='info'
              variant='contained'
              onClick={verifyAadhaar}
            >
              Click here to Verify Aadhaar
            </Button2>
          )}
        </>
      </div>

      {aadharValidated && nameMatched ? (
        <>
          <Row className='m-0'>
            <Row className='residential-title-row'>
              <Col xs={12} className='profile-title'>
                <h1>
                  <Translate value='residentialAddress' />
                </h1>
              </Col>
            </Row>
            <Row className='form-row '>
              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='typeOfresidence' />}
                />
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    // value={allValues.residentialData.residenceType}
                    // name="residenceType"
                    displayEmpty
                    onChange={(ev) => setResidence(ev.target.value)}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {fetchResidence &&
                      fetchResidence.length &&
                      fetchResidence.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourState' />}
                />
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={state}
                    displayEmpty
                    onChange={(ev) => setState(ev.target.value)}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {fetchState &&
                      fetchState.length &&
                      fetchState.map((data) =>
                        data.id === 12 ? (
                          <MenuItem value={data.id}>
                            <ListItemText
                              primary={data.short_name + " | " + data.name}
                            />
                          </MenuItem>
                        ) : null
                      )}
                  </Select>
                </FormControl>
                <FormHelperText className='helperTextAadhar'>
                  {/* {allValues.stateHelperText} */}
                </FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourDistrict' />}
                />
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    // value={allValues.residentialData.district}
                    displayEmpty
                    onChange={handleDistrict}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {district !== undefined &&
                      district !== null &&
                      district.length > 0 &&
                      district.map((i) => (
                        <MenuItem value={i.id}>
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                {/* <p className="mt-5 mb-2">Enter Your Taluk*</p> */}
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourTaluk' />}
                />
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    displayEmpty
                    onChange={handleTaluk}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {taluk !== undefined &&
                      taluk !== null &&
                      taluk.length > 0 &&
                      taluk.map((i) => (
                        <MenuItem value={i.id}>
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourCityName' />}
                />
                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    displayEmpty
                    onChange={handleCity}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {city !== undefined &&
                      city !== null &&
                      city.length > 0 &&
                      city.map((i) => (
                        <MenuItem value={i.id}>
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormHelperText className='helperTextAadhar'>
                  {/* {allValues.cityHelperText} */}
                </FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                {/* <p className="mt-5 mb-2">Enter Your Area / Village Name*</p> */}
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='enterYourAreaVillageName' />}
                />

                <FormControl variant='outlined' fullWidth>
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    displayEmpty
                    onChange={handleVillage}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {village !== undefined &&
                      village !== null &&
                      village.length > 0 &&
                      village.map((i) => (
                        <MenuItem value={i.id}>
                          <ListItemText primary={i.name} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='streetRoadName' />}
                />
                <FormControl fullWidth className='formcontrol1'>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Street/Road Name'
                    name='streetRoad'
                    onChange={(ev) => setStreetName(ev.target.value)}
                    value={streetName}
                  />
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='houseBuildingNumber' />}
                />

                <FormControl fullWidth>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your House / Building Number'
                    onChange={(ev) => setHouseNumber(ev.target.value)}
                    value={houseNumber}
                    inputProps={{ minLength: 0, maxLength: 15 }}
                  />
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <p className='mt-3 mb-2'>
                  <Translate value='landmark' />{" "}
                </p>

                <FormControl fullWidth className='formcontrol2'>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Landmark'
                    value={landmark}
                    onChange={(ev) => setLandmark(ev.target.value)}
                  />
                </FormControl>
                <FormHelperText className='helperTextAadhar'></FormHelperText>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='pincode' />}
                />
                <FormControl fullWidth>
                  <TextField
                    variant='outlined'
                    placeholder='Enter Your Pincode'
                    value={pincode}
                    onChange={(ev) => setPincode(ev.target.value)}
                    inputProps={{ minLength: 0, maxLength: 6 }}
                  />
                </FormControl>
                <FormHelperText className='helperTextAadhar'>
                  {/* {allValues.pincodeHelperText} */}
                </FormHelperText>
              </Col>

              {/* <Col xs={12} className="note2 text-center mt-5 personal-col-7">
                        <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p>
                        <p><span><Translate value="note"/> </span><Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess1"/></p>

                        </Col> */}
              <Col xs={12} md={6} lg={6} xl={6}>
                <div className='residential-div mt-5'>
                  <p className='mb-2 w-50'>
                    <Translate
                      value='uploadLabourCard'
                      style={{ whiteSpace: "nowrap" }}
                    />
                  </p>
                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedLabour !== null &&
                        selectedLabour.length > 0 &&
                        selectedLabour !== undefined &&
                        Array.from(selectedLabour).map((i, index) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='birth'
                              value={i.name}
                              // onChange={handleChange}
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      alt='...'
                                      src={close}
                                      className='removefilebtn'
                                      onClick={() => removefile(index)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))
                      )}
                    </div>

                    <div className='browsebutton2-div'>
                      <label
                        htmlFor={`upload-photo4`}
                        className='browse-button btn2 schemes'
                      >
                        <img alt='...' src={folder} />
                        <Translate value='upload' />
                      </label>
                      <input
                        type='file'
                        id={`upload-photo4`}
                        name='photo'
                        onChange={handleLabourChange}
                        multiple
                      />
                    </div>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={6}>
                <Required
                  className='mt-5 mb-0'
                  title={<Translate value='selectYourGender' />}
                />

                <div className='gender-div'>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='27'
                      id='male'
                      // onChange={handleChange}
                      checked={gender === "27"}
                      disabled={aadharValidated ? true : false}
                    />
                    <label htmlFor='male'>
                      {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                      <img alt='...' src={male} />
                      <span>
                        <Translate value='male' />
                      </span>
                    </label>
                  </div>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='28'
                      id='female'
                      // onChange={handleChange}
                      checked={gender === "28"}
                      disabled={aadharValidated ? true : false}
                    />
                    <label htmlFor='female'>
                      {/* <img alt="..."  className="gender-img" src="https://www.pngkey.com/png/full/203-2037403_flat-faces-icons-circle-girl-flat-icon-png.png" /> */}
                      <img alt='...' src={female} />
                      <span>
                        <Translate value='female' />
                      </span>
                    </label>
                  </div>
                  <div className='button genderradio'>
                    <input
                      type='radio'
                      name='gender'
                      value='29'
                      id='others'
                      // onChange={handleChange}
                      checked={gender === "29"}
                      disabled={aadharValidated ? true : false}
                    />
                    <label htmlFor='others'>
                      {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                      <img alt='...' src={radioon} />
                      <span>
                        <Translate value='others' />
                      </span>
                    </label>
                  </div>
                </div>
              </Col>

              <Col xs={12} md={12} lg={6} xl={6}>
                <Required
                  className='mt-5 mb-0'
                  title={<Translate value='category' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol6'
                  // onKeyPress={event => {
                  //     if (event.key === "Enter") {
                  //         handleEnter();
                  //     }
                  // }}
                >
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={categoryID}
                    name='category'
                    displayEmpty
                    onChange={(ev) => setCategoryID(ev.target.value)}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='Select' />
                    </MenuItem>

                    {fetchCategory &&
                      fetchCategory.length &&
                      fetchCategory.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                    {/* <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    <MenuItem value="General">
                                        <ListItemText primary="General" />
                                    </MenuItem>
                                    <MenuItem value="OBC">
                                        <ListItemText primary="OBC" />
                                    </MenuItem>
                                    <MenuItem value="SC">
                                        <ListItemText primary="SC" />
                                    </MenuItem>
                                    <MenuItem value="ST">
                                        <ListItemText primary="ST" />
                                    </MenuItem>
                                    <MenuItem value="Minority">
                                        <ListItemText primary="Minority" />
                                    </MenuItem> */}
                  </Select>
                </FormControl>

                <Required
                  className='mt-4 mb-2'
                  title={<Translate value='religion' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol5'
                  // onKeyPress={event => {
                  //     if (event.key === "Enter") {
                  //         handleEnter();
                  //     }
                  // }}
                >
                  <Select
                    className='select-marital'
                    labelId='demo-simple-select-required-label'
                    id='demo-simple-select-required'
                    value={religionID}
                    name='religion'
                    displayEmpty
                    onChange={(ev) => setReligionID(ev.target.value)}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='Select' />
                    </MenuItem>

                    {fetchReligion &&
                      fetchReligion.length &&
                      fetchReligion.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.description} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
                <Required
                  className='mt-3 mb-2'
                  title={<Translate value='natureoftheWorkplace' />}
                />
                <FormControl
                  variant='outlined'
                  fullWidth
                  className='formcontrol5'
                >
                  <Select
                    className='select-marital'
                    value={natureOfWorkID}
                    name='natureOfWork'
                    displayEmpty
                    onChange={(ev) => setNatureOfWorkID(ev.target.value)}
                  >
                    <MenuItem value=''>
                      <ListItemText primary='-Select-' />
                    </MenuItem>
                    {fetchNatureOfWork &&
                      fetchNatureOfWork.length &&
                      fetchNatureOfWork.map((i) => (
                        <MenuItem value={i.value_id}>
                          <ListItemText primary={i.value} />
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              </Col>

              <Col xs={12} md={6}>
                <div className='form-inner-card-right mt-5'>
                  <FormControl
                    className='mt-2 mb-2 interstateRadio formcontrol9'
                    variant='outlined'
                    fullWidth
                    component='fieldset'
                    // onKeyPress={event => {
                    //     if (event.key === "Enter") {
                    //         handleEnter();
                    //     }
                    // }}
                  >
                    {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                    <RadioGroup
                      row
                      name='isMigrant'
                      value={isMigrant}
                      onChange={(ev) => setIsMigrant(ev.target.value)}
                    >
                      <p className='mt-2 mb-2' style={{ flexGrow: 1 }}>
                        <Translate value='interstateMigrantWorker' />
                      </p>
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label={<Translate value='yes' />}
                      />
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={<Translate value='no' />}
                      />
                    </RadioGroup>
                  </FormControl>

                  {isMigrant === "1" ? (
                    <>
                      <p className='mt-2 mb-2'>
                        <Translate value='migrantFromState' />
                      </p>
                      <FormControl variant='outlined' fullWidth>
                        <Select
                          className='select-state'
                          labelId='demo-simple-select-required-label'
                          id='demo-simple-select-required'
                          value={migrant}
                          // name="migrantState"
                          displayEmpty
                          onChange={(ev) => setMigrant(ev.target.value)}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='Select' />
                          </MenuItem>

                          {fetchState &&
                            fetchState.length &&
                            fetchState.map(
                              (i) =>
                                i.name !== "Karnataka" && (
                                  <MenuItem value={i.id}>
                                    <ListItemText
                                      primary={i.short_name + " | " + i.name}
                                    />
                                  </MenuItem>
                                )
                            )}
                        </Select>
                      </FormControl>
                    </>
                  ) : null}
                </div>
                <div className='form-inner-card-right mt-0'>
                  <FormControl
                    className='mt-2 mb-2 interstateRadio formcontrol9'
                    variant='outlined'
                    fullWidth
                    component='fieldset'
                    onKeyPress={(event) => {
                      // if (event.key === "Enter") {
                      //     handleEnter();
                      // }
                    }}
                  >
                    {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                    <RadioGroup
                      row
                      name='isAyushMan'
                      value={isAyushman}
                      onChange={(ev) => setIsAyushman(ev.target.value)}
                    >
                      <p className='mt-2 mb-2' style={{ flexGrow: 1 }}>
                        <Translate value='ayushmanholder' />
                      </p>
                      <FormControlLabel
                        value='1'
                        control={<Radio />}
                        label={<Translate value='yes' />}
                      />
                      <FormControlLabel
                        value='0'
                        control={<Radio />}
                        label={<Translate value='no' />}
                      />
                    </RadioGroup>
                  </FormControl>

                  {isAyushman === "1" ? (
                    <>
                      <p className='mt-3 mb-2'>
                        <Translate value='ayushmancardnumber' />{" "}
                      </p>
                      <FormControl fullWidth className='formcontrol2'>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Ayushman Bharath Card Number'
                          name='ayushManCard'
                          value={ayushmanCard}
                          onChange={(ev) => setAyushmanCard(ev.target.value)}
                        />
                      </FormControl>
                    </>
                  ) : null}
                </div>
              </Col>
            </Row>
          </Row>
          <Row className='m-0'>
            <Row className='family-title-row'>
              <Col xs={12} className='profile-title'>
                <h1>Family Details</h1>
              </Col>
            </Row>

            <Row className='form-row'>
              <Col xs={12} className='family-col-3 form-inner-card mt-4'>
                <p>
                  <Translate value='listofFamilyMembers' />
                </p>
              </Col>

              <Col xs={12} className='family-col-3'>
                {familyDetails.map((data, index) => (
                  <Row className={"familymember-card row"}>
                    {data.relation !== 49 && data.relation !== 50 && (
                      <div
                        style={{
                          position: "absolute",
                          top: 0,
                          right: "0",
                          width: "30px",
                          height: "30px",
                        }}
                      >
                        <img
                          alt='...'
                          src={closeIcon}
                          className='closebtn'
                          onClick={() => removeFamilyMembers(index)}
                        />
                      </div>
                    )}
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          {/* <p >Relation :</p> */}
                          <Required title={<Translate value='relation' />} />
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className='select-marital'
                              // value={allValues.membersList[i]["relation_id" + i]}
                              name='relation'
                              displayEmpty
                              value={data.relation}
                              disabled={
                                data.relation === 49 || data.relation === 50
                                  ? true
                                  : false
                              }
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value=''>
                                <ListItemText primary='-Select-' />
                              </MenuItem>
                              {data.relation === 49 || data.relation === 50
                                ? fetchRelation &&
                                  fetchRelation.length &&
                                  fetchRelation.map((i) => (
                                    <MenuItem value={i.value_id}>
                                      <ListItemText primary={i.value} />
                                    </MenuItem>
                                  ))
                                : fetchRelation &&
                                  fetchRelation.length &&
                                  fetchRelation
                                    .filter(
                                      (row) =>
                                        row.value_id !== 49 &&
                                        row.value_id !== 50
                                    )
                                    .map((i) => (
                                      <MenuItem value={i.value_id}>
                                        <ListItemText primary={i.value} />
                                      </MenuItem>
                                    ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          {/* <p >First Name :</p> */}
                          <Required title={<Translate value='firstName' />} />
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Your Name'
                              value={data.firstName}
                              name='firstName'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              // error={allValues.firstnameError}
                              // helperText={allValues.firstnameError ? "Please enter in correct format" : ""}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          <p>
                            <Translate value='middleName' /> :
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Your Name'
                              value={data.middleName}
                              name='middleName'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              // error={allValues.middlenameError}
                              // helperText={allValues.middlenameError ? "Please enter in correct format" : ""}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          {/* <p >Last Name :</p> */}
                          <p className='mt-4 mb-1'>
                            <Translate value='lastName' />{" "}
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <TextField
                              placeholder='Enter Your Name'
                              value={data.lastName}
                              name='lastName'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              // error={allValues.lastnameError}
                              // helperText={allValues.lastnameError ? "Please enter in correct format" : ""}
                            />
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>

                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          {/* <p >DOB :</p> */}
                          <Required title={<Translate value='dOB' />} />
                        </Col>
                        <Col xs={9} style={{ borderBottom: "1px solid grey" }}>
                          <MuiPickersUtilsProvider utils={MomentUtils}>
                            <KeyboardDatePicker
                              minDate={moment().subtract(100, "years")}
                              // onClick={() => setPickerStatus(true)}
                              // onClose={() => setPickerStatus(false)}
                              // open={allValues.openDatePicker}
                              className='datepicker'
                              margin='normal'
                              format='DD/MM/YYYY'
                              // value={allValues.dob}
                              // value={selectedDate}
                              // onChange={ handleDateChange }
                              onKeyDown={handleKeyDown}
                              // onChange={(date) => { handleDateChange(date) }}
                              onChange={(date) =>
                                handleDateChange(date, index, "dob")
                              }
                              value={data.dob && moment(data.dob)}
                              name='dob'
                              placeholder='DD/MM/YYYY'
                              // disabled={isAadhaarVerified ? true : false}
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                            />
                          </MuiPickersUtilsProvider>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          <p>
                            <Translate value='profession' />* :
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className='select-marital'
                              displayEmpty
                              value={data.profession}
                              name='profession'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value=''>
                                <ListItemText primary='-Select-' />
                              </MenuItem>
                              {fetchProfession &&
                                fetchProfession.length &&
                                fetchProfession.map((i) => (
                                  <MenuItem value={i.value_id}>
                                    <ListItemText primary={i.value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} md={6}>
                      <Row>
                        <Col xs={3} className='member-p'>
                          <p>
                            <Translate value='education' />*
                          </p>
                        </Col>
                        <Col xs={9}>
                          <FormControl fullWidth>
                            <Select
                              className='select-marital'
                              displayEmpty
                              value={data.education}
                              name='education'
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            >
                              <MenuItem value=''>
                                <ListItemText primary='-Select-' />
                              </MenuItem>
                              {fetchEducation &&
                                fetchEducation.length &&
                                fetchEducation.map((i) => (
                                  <MenuItem value={i.value_id}>
                                    <ListItemText primary={i.value} />
                                  </MenuItem>
                                ))}
                            </Select>
                          </FormControl>
                        </Col>
                      </Row>
                    </Col>
                    <Col xs={12} className='member-p'>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(ev) =>
                              handleCheckBox(index, ev, "nominee")
                            }
                            color='primary'
                            checked={data.nominee}
                            name='nominee'
                          />
                        }
                        label={<Translate value='nominee' />}
                      />
                    </Col>
                  </Row>
                ))}
              </Col>

              <Col
                xs={12}
                className='addMemberCol mt-4 family-col-10'
                onClick={() => addNewFamilyMember("")}
              >
                <AddCircleRounded />
                <p>
                  <Translate value='addMoreFamilyMembers' />
                </p>
              </Col>

              {/* <Col xs={12} className="note2 text-center mt-4 family-col-11">
        <p><span><Translate value="note"/><Translate value="noteafterfurtherprocess"/></span></p> 
    </Col> */}
            </Row>
            <Row className='m-0'>
              <Row className='bank-title-row'>
                <Col xs={12} className='profile-title'>
                  {/* <h1>Bank Passbook / Statement</h1> */}
                  <h1>Bank Details</h1>
                </Col>
              </Row>

              <Row className='form-row'>
                <Col xs={12} md={6} className='bank-col-1'>
                  <Row className='form-inner-card-right'>
                    <Col xs={12}>
                      {/* <p className="mt-3 mb-2">Account Number*</p> */}
                      <Required
                        className='mt-3 mb-2'
                        title={<Translate value='accountNumber' />}
                      />
                      <FormControl fullWidth>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Your Account Number'
                          name='accountNumber'
                          value={accountNumber}
                          onChange={(ev) => setAccountNumber(ev.target.value)}

                          // error={accError}
                          // helperText={accError}
                          // onInput={(e)=>{
                          //     e.target.value = e.target.value.replace(/[^0-9]/ig,'')
                          //     if(e.target.value.length === 0){
                          //         setAccError("Please Enter Account Number")
                          //     }

                          //     else{
                          //         setAccError("")
                          //     }
                          // }}
                          // inputProps={{minLength:0,maxLength:6}}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12}>
                      {/* <p className="mt-4 mb-2">Enter Your Bank Name*</p> */}
                      <Required
                        className='mt-4 mb-2'
                        title={<Translate value='enterYourBankName' />}
                      />
                      <FormControl
                        fullWidth
                        className='formcontrol1'
                        // onKeyPress={event => {
                        //     if (event.key === "Enter") {
                        //         handleEnter();
                        //     }
                        // }}
                      >
                        <TextField
                          variant='outlined'
                          placeholder='Enter Your Bank Name'
                          name='bankName'
                          value={bankName}
                          // onChange={changeHandler}
                          // error={allValues.bankNameError}
                          // helperText={allValues.bankNameError ? "Please enter in correct format" : ""}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className='bank-col-2'>
                  <Row className='form-inner-card-right'>
                    <Col xs={8} md={10}>
                      <Required
                        className='mt-3 mb-2'
                        title={<Translate value='enterYourIFSCcode' />}
                      />
                      <FormControl
                        fullWidth
                        className='formcontrol1'
                        // onKeyPress={event => {
                        //     if (event.key === "Enter") {
                        //         handleEnter();
                        //     }
                        // }}
                      >
                        <TextField
                          variant='outlined'
                          placeholder="Enter Your Bank's IFSC Code"
                          name='ifscCode'
                          value={IFSCCode}
                          onChange={(ev) => setIFSCCode(ev.target.value)}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={4} md={2}>
                      <div className='checkDiv mt-3'>
                        {checkBank ? (
                          <>
                            {/* <Tooltip title="Bank Account is Verified !"> */}
                            <img
                              alt='...'
                              src={checkgreen}
                              style={{ marginTop: "30px" }}
                            />
                            {/* </Tooltip> */}
                          </>
                        ) : (
                          <Button
                            variant='outline-primary'
                            // disabled={loadingVerifyBank}
                            onClick={verifyBank}
                            style={{ marginTop: "20px" }}
                          >
                            {loadingVerifyBank ? (
                              <>
                                <Translate value='fetching' />
                              </>
                            ) : (
                              <>
                                <Translate value='fetchDetails' />
                              </>
                            )}
                          </Button>
                        )}
                        {loadingVerifyBank && (
                          <CircularProgress
                            size={24}
                            style={{ marginTop: "30px" }}
                            className='buttonProgress'
                          />
                        )}
                      </div>
                    </Col>

                    <Col xs={12}>
                      {/* <Col xs={6} md={12}>
                            {/* <p className="mt-4 mb-2">Enter Your Bank Branch*</p> */}
                      <Required
                        className='mt-4 mb-2'
                        title={<Translate value='enterYourBankBranch' />}
                      />
                      <FormControl fullWidth>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Your Bank Branch'
                          name='bankBranch'
                          value={bankBranch}
                          // value={allValues.bankBranch}
                          // onChange={changeHandler}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
                <Col xs={12} md={6} className='bank-col-3'>
                  <div
                    className='form-inner-card-right row'
                    style={{ marginLeft: "4px", marginRight: "2px" }}
                  >
                    {/* <p className="mt-0 mb-2">Enter Your Bank Address*</p> */}
                    <Required
                      className='mt-0 mb-2'
                      title={<Translate value='enterYourBankAddress' />}
                    />
                    <FormControl
                      fullWidth
                      className='formcontrol1'
                      // onKeyPress={event => {
                      //     if (event.key === "Enter") {
                      //         handleEnter();
                      //     }
                      // }}
                    >
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={3}
                        // rowsMax={4}
                        // defaultValue="Default Value"
                        placeholder='Enter Your Bank Address'
                        name='bankAddress'
                        value={bankAddress}
                        // onChange={handleChange}
                      />
                    </FormControl>
                  </div>
                </Col>
                <Col xs={12} md={6} className='bank-col-4'>
                  <div
                    className=' mt-4'
                    style={{ marginLeft: "22px", marginRight: "40px" }}
                  >
                    {/* <p className="mt-4 mb-2">Upload Your Passbook Document*</p> */}
                    <Required
                      className='mt-4 mb-2'
                      title={<Translate value='uploadYourPassbookDocument' />}
                    />

                    <div className='browsebutton-outerdiv'>
                      <div className='browsebutton2-div filesNames'>
                        {loadingfiles ? (
                          <></>
                        ) : (
                          selectedPassbookFiles !== null &&
                          selectedPassbookFiles.length > 0 &&
                          Array.from(selectedPassbookFiles).map((i, index) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='passbookDocumentName'
                                value={i.name}
                                // onChange={handleChangeBank}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        alt='...'
                                        src={close}
                                        className='removefilebtn'
                                        onClick={() => removefile(index)}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        )}
                      </div>
                      <div className='browsebutton2-div'>
                        <label
                          htmlFor='upload-photo'
                          className='browse-button btn2'
                        >
                          {/* <img alt="..." src={folder} />  */}
                          <Translate value='browse' />
                        </label>
                        <input
                          type='file'
                          name='photo'
                          id='upload-photo'
                          onChange={handlePassbook}
                          multiple
                        />
                      </div>
                    </div>

                    <p className='mt-3 instructions2'>
                      {/* <span><Translate value="instructions"/>*</span><br />
                                Format supporting document should be in JPEG, PNG, or PDF<br />
                                (Size contains 500 KB to 2 MB) */}
                      <span>
                        <Translate value='instructions' />*
                      </span>
                      <br />
                      <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                      <br />
                      <Translate value='sizecontains500KBto2MB' />
                    </p>
                  </div>
                </Col>
              </Row>

              {/* <Row className="form-row">
                <Col xs={12} className="note2 text-center mt-4 bank-col-6">
                    <p><span><Translate value = "note" /></span><Translate value = "noteAftercompletion" /></p>
                </Col>
            </Row> */}
            </Row>

            <Row className='button-inside-form-row mb-5'>
              <Col xs={12} className='next-back-button-row mt-4 '></Col>
            </Row>
          </Row>

          <div className='d-flex justify-content-center align-items-center my-5'>
            <Button2
              style={{ width: "15%", height: "45px" }}
              color='success'
              variant='contained'
              onClick={handleSubmit}
            >
              Submit
            </Button2>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};

export default AlreadyRegistered;
