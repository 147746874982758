import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
export const schemeStatusAPI = (labourUserID, setSchemeStatus) => {
  const payLoad = {
    board_id: 1,
    labour_user_id: labourUserID,
  };
  axios
    .post(`${SERVER}/schemes/get_schemes_by_labor`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
    .then(({ data }) => {
      setSchemeStatus(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const schemeStatusAvailedAPI = (schemeAvailID, setSchemeStatus) => {
  axios
    .get(
      `${SERVER}/schemes/get_scheme_status?schme_avail_id=${schemeAvailID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    )
    .then(({ data }) => {
      setSchemeStatus(data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getOfficerSignature = (fileID, setSignature) => {
  axios
    .get(`${SERVER}/upload/download_base_64/${fileID}`, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
    .then(({ data }) => {
      setSignature(data.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const rejectReasonAPI = async (schemeAvailID, status) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/rejection-reason?availId=${schemeAvailID}&reasonType=${
        status === 'Rejected'
          ? 'FINAL'
          : status === 'Draft Rejected'
            ? 'DRAFT'
            : 'FINAL'
      }`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
