import React from 'react'
import { Card, Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import lm from 'assets/images/lm.png'
import cm from 'assets/images/cm.png'
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

const Header = (props) => {
    console.log(props,"Props");
  return (
    <div className='container-fluid m-0 p-0'>
         <Row className="mainscreen-top-div d-flex justify-content-center container-fluid m-0 p-0" style={{overflowY:'hidden'}}>
        <Row className='topbar-row d-flex justify-content-center containter-fluid'>
        <div className='d-flex justify-content-end container-fluid'>
        <div className='right-card'>
                        
                         <Avatar
                        alt="Remy Sharp"
                        src={lm}
                        sx={{ width: 70, height: 70 }}
                        />
                        <h5 className='minister-name2'>{ props.lang === "en" ? "Shri Santhosh S Lad" : props.lang === "ka" ? "ಶ್ರೀ ಸಂತೋಷ್ ಎಸ್ ಲಾಡ್" : "Shri Santhosh S Lad" }</h5>
                        <p className='minister-designation2'>{props.lang === "en" ? "Hon'ble Minister for Labour Department, Government of Karnataka" : props.lang === "ka" ? "ಮಾನ್ಯ ಕಾರ್ಮಿಕ ಸಚಿವರು, ಕರ್ನಾಟಕ ಸರ್ಕಾರ" : "Hon'ble Minister for Labour Department, Government of Karnataka"}</p>
                        </div>
                        </div>
                        <div className='d-flex justify-content-start'>
                        <div className='left-card'>
                        
                        <Avatar
                        alt="Remy Sharp"
                        src={cm}
                        sx={{ width: 70, height: 70 }}
                        />
                        <h5 className='minister-name'>{ props.lang === "en" ? "Shri Siddaramaiah" : props.lang === "ka" ? "ಶ್ರೀ ಸಿದ್ದರಾಮಯ್ಯ" : "Shri Siddaramaiah" }</h5>
                        <p className='minister-designation'>{props.lang === "en" ? "Hon'ble Chief Minister, Government of Karnataka" : props.lang === "ka" ? "ಮಾನ್ಯ ಮುಖ್ಯಮಂತ್ರಿ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ" : "Hon'ble Chief Minister, Government of Karnataka"}</p>
                        </div>
                        </div>
                        <div className='d-flex justify-content-center'>
       <Link to='/'> <img src={logo} alt='...' className='logo-img'/> </Link>
       </div>
        <h1 className='logo-text d-flex justify-content-center text-center'>ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ <br/> 
                        Labour Department, Government of Karnataka
                        </h1>
                    
                        
          </Row>
        
                    {/* <Row className="topbar-row">
                        <Col xs={12} md={6} lg={6} className="top-bar-col d-flex">
                        <div className='logo-div-profile'>
                        <div className='main-screen-logo'>
                            <img src={logo} alt='...' className='logo-img'/>
                            
                        </div>
                        <div className='main-screen-title d-flex justify-content-center'>
                        <h1 className='logo-text d-flex justify-content-center'>ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ <br/> 
                        Labour Department, Government of Karnataka
                        </h1>
                        </div>
                        <div className='left-card'>
                        
                        <Avatar
                        alt="Remy Sharp"
                        src={cm}
                        sx={{ width: 70, height: 70 }}
                        />
                        <h5 className='minister-name'>Shri Basavaraj Bommai</h5>
                        <p className='minister-designation'>Hon'ble Chief Minister of Karnataka</p>
                        </div>
                        
                        <div className='right-card'>
                        
                        <Avatar
                        alt="Remy Sharp"
                        src={lm}
                        sx={{ width: 70, height: 70 }}
                        />
                        <h5 className='minister-name2'>Shri Arbail Shivaram Hebbar</h5>
                        <p className='minister-designation2'>Hon'ble Minister for Labour Department Government of Karnataka</p>
                        </div>



                        
                        </div>
                            {/* <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img main-screen-logo" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board <br/>(KBOCWWB)
                                    Government of Karnataka
                                </h1>
                            </div> */}

                        {/* </Col> */}
                        
                    {/* </Row> */} 

                </Row>
    </div>
  )
}

export default Header