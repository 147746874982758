import 'date-fns';
import { withStyles, Menu, MenuItem, ListItemText, Select, FormControl } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getTokenCookie, showToast } from 'utils/tools';
import { SERVER } from 'store/actions/user.actions'
import axios from 'axios';

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
        width: '350px'
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const UserRoleMaster = (props) => {
    const users = useSelector(state => state.users);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElRole, setAnchorElRole] = React.useState(null);

    const { location } = props;
    const [roles, setRolesMaster] = useState([]);
    const [boards, setBoardsMaster] = useState([]);

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        user_id: "", //445
        user_name: "", //qqq
        activeBoardItem: [],
        activeRoleItem: [],
        rows: []
    });

    useEffect(() => {
        if (location.state !== undefined) {
            if (location.state.user_name && location.state.user_id) {
                
                setAllValues({ ...allValues, user_name: location.state.user_name, user_id: location.state.user_id })
            }
        }
    }, [location])

    useEffect(()=>{
        if(allValues.user_id !== undefined && allValues.user_id !== ''){
            console.log("in allValues.user_id > if")
            getUserRoles();
        }
    },[allValues.user_id])

    useEffect(() => {
        if (boards.length > 0 && roles.length > 0) {
            console.log("in boards > if")
            getUserRoles();
        }
    }, [boards])

    useEffect(() => {
        if (boards.length > 0 && roles.length > 0) {
            console.log("in roles > if")
            getUserRoles();
        }
    }, [roles])

    useEffect(() => {
        console.log("allvalues: ", allValues)
    }, [allValues])

    const getUserRoles = () => {
        console.log("in getUserRoles")
        var config = {
            method: 'get',
            url: SERVER + `/config/dept_user/get_dept_user_role?user_id=${allValues.user_id}`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_dept_user_role Response', response.data.data)
                var rows1 = []

                response.data.data.forEach((i,key) => {
                    // setRoles1(i.board_id,key)
                    // updateDeptUserRole(i,key)
                    rows1 = rows1.concat({
                        "board_id": i.board_id,
                        "board_name": i.board_short_name,
                        "role_id": i.role_id,
                        "role_name": i.role_name
                    })
                    console.log("i: ",i)
                    console.log("rows1: ",rows1)
                })
                
                setAllValues({
                    ...allValues,
                    rows: rows1,
                })
            })
            .catch(function (error) {
                console.error("error:", error)
            });
    }

    

    const setRoles1 = (board_id,key) => {
        console.log("in setRoles1: board_id=",board_id)
        console.log("in setRoles1: key=",key)
        var config = {
            method: 'get',
            url: SERVER + '/config/role/get_board_roles?board_id='+board_id,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_roles Response', response.data.data)
                // setRolesMaster(response.data.data)

                allValues.rows[key].roles = response.data.data
                setAllValues({
                    ...allValues
                })
            })
            .catch(function (error) {
                console.error('error', error)
            });
    }
    
    
    const updateDeptUserRole = (i,key) => {
        console.log("in updateDeptUserRole: i=",i)
        console.log("in updateDeptUserRole: key=",key)
        return new Promise((resolve, reject) => {
            var data = JSON.stringify({
                "board_id": i.board_id,
                "role_id": i.role_id,
                "user_id": allValues.user_id,
                "added_by": users.getDeptUserRole.data[0].department_user_id
            });
            var config = {
                method: 'post',
                url: SERVER+'/config/dept_user/update_dept_user_role',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
            };
            axios(config)
            .then(function (response) {
            resolve(response);
            })
            .catch(function (error) {
            reject(error);
            });
        })
    }

    useEffect(() => {
        console.log("useeffect allValues.rows")
        console.log("allValues.rows.length: ",allValues.rows.length)
        if(allValues.rows.length >= 1){
            allValues.rows.forEach((i,index)=>{
                if(i.roles === undefined || i.roles.length <= 0){
                    setRoles1(i.board_id,index)
                }
            })
        }
    }, [allValues.rows])

    
    // const setRoles = () => {
    //     var config = {
    //         method: 'get',
    //         url: SERVER + '/config/role/get_roles',
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': `Bearer ${getTokenCookie()}`
    //         },
    //     };
    //     axios(config)
    //         .then(function (response) {
    //             console.log('get_roles Response', response.data.data)
    //             setRolesMaster(response.data.data)
    //         })
    //         .catch(function (error) {
    //             console.error('error', error)
    //         });
    // }

    const setBoards = () => {
        var config = {
            method: 'get',
            url: SERVER + '/global/get_board_details?id=1',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
        };
        axios(config)
            .then(function (response) {
                console.log('get_board_details Response', response.data.data)
                setBoardsMaster(response.data.data)
            })
            .catch(function (error) {
                console.log('error', error)
            });
    }

    useEffect(() => {
        setBoards()
        console.log("location: ", location)
    }, [])

    const onSelectClickHandlerkboc = (event) => {
        setAnchorEl(event.currentTarget);
    }

    const handleClosekboc = () => {
        setAnchorEl(null);
    }

    const onSelectBoardHandler = (event, data, key) => {
        console.log("in onSelectBoardHandler: data= ", data)
        console.log("in onSelectBoardHandler: key= ", key)

        allValues.rows[key].board_id = data.id
        allValues.rows[key].board_name = data.short_name
        setRoles1(data.id,key)
        setAllValues({
            ...allValues
        })
        handleClosekboc();
    }
    
    const onSelectClickHandlerRoleboc = (event) => {
        setAnchorElRole(event.currentTarget);
    }

    const handleCloseRoleboc = () => {
        setAnchorElRole(null);
    }

    const onSelectRoleHandler = (event, data, key) => {
        allValues.rows[key].role_id = data.role_id
        allValues.rows[key].role_name = data.role_name
        setAllValues({
            ...allValues
        })
        // setAllValues({
        //     ...allValues,
        //     activeRoleItem: data
        // })
        handleCloseRoleboc();
        console.log('ActiveR', allValues.activeRoleItem)
    }

    const addNewRow = () => {
        if(allValues.rows.length === boards.length){
            showToast("ERROR", "Cannot Add More Roles");
        }
        else{
            setAllValues({
                ...allValues,
                rows: allValues.rows.concat({
                    "board_id": "",
                    "board_name": "",
                    "role_id": "",
                    "role_name": ""
                })
            })
        }
    }

    const deleteRow = (item) => {
        console.log("in deleteRow: item=" + item)
        var array = [...allValues.rows];
        var index = array.indexOf(item)
        array.splice(index, 1);
        setAllValues({
            ...allValues,
            rows: array,
        });
    }
    
    const saveButtonPressed = () => {
        console.log("in saveButtonPressed")
        if(allValues.rows.length <= 0){
            showToast("ERROR","Please add a Role")
        }
        else{
            let promises = [];
            allValues.rows.forEach((i,key) => {
                promises.push(updateDeptUserRole(i,key))
            })

            console.log("promises: ", promises)

            Promise.all(promises)
            .then((promResponses) => {
                console.log("promResponses: ",promResponses)
                showToast("SUCCESS","Roles Saved Successfully!")

            })
            .catch(function (error) {
                console.error("promResponses error:", error)
                showToast("ERROR","Some error occurred while saving Roles.")
            });
        }
    }

    const userRolesRows = allValues.rows.map((id, key) => {
        return (
            <Row className="form-row role-config-form">
                <Col xs={12} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Board</p>
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={
                                boards!== undefined &&
                                allValues.rows[key]!== undefined &&
                                allValues.rows[key].board_id !== undefined &&
                                allValues.rows[key].board_id
                            }
                            displayEmpty
                            disabled
                        >
                            <MenuItem value="">
                                <ListItemText primary="-" />
                            </MenuItem>
                            {
                            boards.map((row) => (
                                <MenuItem
                                    disabled = { allValues.rows.filter( (item) => item.board_id === row.id ).length > 0 }
                                    onClick={(e) => onSelectBoardHandler(e, row, key)}
                                    value={row.id}
                                    >
                                    <ListItemText primary={row.short_name} />
                                </MenuItem>
                            )
                            )
                            }
                        </Select>
                    </FormControl>
                
                </Col>
                <Col xs={11} md={4} className="personal-col-1">
                    <p className="mb-2 mt-3">Select Role</p>
                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                        <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={
                                allValues.rows[key].role_id
                            }
                            displayEmpty
                            disabled
                        >
                            {/* <MenuItem value={'' || false}> */}
                            <MenuItem value="">
                                <ListItemText primary="-" />
                            </MenuItem>

                            {
                            allValues.rows[key]!==undefined &&
                            allValues.rows[key].roles!==undefined &&
                            allValues.rows[key].roles.map((row) => (
                                <MenuItem 
                                    onClick={(e) => onSelectRoleHandler(e, row, key)}
                                    value={row.role_id}
                                >
                                    <ListItemText primary={row.role_name} />
                                </MenuItem>
                            )
                            )}
                        </Select>
                    </FormControl>
                </Col>
                {/* <Col xs={1} className="mt-5 p-1 pl-5" align="center">
                    <Button variant="outline-danger" className="" onClick={() => deleteRow()}>
                        Delete
                    </Button>
                </Col> */}
                <Col xs={1} className="mt-4 pr-2" align="center">
                    <Button variant="outline-primary" className="map-circle-button" align='right'
                    onClick={()=> 
                        props.history.push("/admin/user-circle-mapping",  
                        { 
                            user_name: allValues.user_name,
                            user_id: allValues.user_id,
                            userId: location.state.userId,
                            userrow: location.state.userrow,
                            currentrow: allValues.rows[key]
                        })
                    }
                    >
                        Map Circle
                    </Button>
                </Col>
            </Row>
        )
    })

    return (

        <>
            <div className="root">
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                 <h1 className="logo-text">
                                    Government of Karnataka <br />
                                    Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                                </h1>
                            </div>

                        </Col>

                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={8} className="title-col">
                    <Link 
                        to="#"
                        // to ={{ 
                        //     pathname: "/admin/user-role-master", 
                        //     user_name: location.state.user_name,
                        //     user_id: location.state.user_id,
                        //     userId: location.userId,
                        //     userrow: location.userrow
                        //     }}   
                        // onClick={() => 
                        //     props.history.push("/admin/user-role-master",  
                        //     { 
                        //         user_name: allValues.user_name,
                        //         user_id: allValues.user_id
                        //     })
                        //     }
                        onClick={() => 
                            props.history.goBack()
                            }
                                >
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">User Role Master</p>
                    </Link>
                </Col>
                <Col xs={4} className="title-col" >
                    <p className="title">User Name : {allValues.user_name}</p>
                </Col>
            </Row>
            <Row className="form-row mt-4 role-config-form-row" >
                {userRolesRows}
                <Row className="form-row mt-1" >
                    <Col xs={12} className="mt-4 pr-1" align="center">
                        <Button variant="outline-primary" className="save-button" 
                        onClick={()=> props.history.push("/admin/userrolemapping")}>
                            Go Back to Users List
                        </Button>
                    </Col>
                </Row>
            </Row>
        </>
    )
}

export default UserRoleMaster;