
import 'date-fns';
import { FormControl, TextField, InputAdornment,Popover, Select, MenuItem, ListItemIcon, ListItemText, CircularProgress, FormControlLabel, Checkbox, makeStyles, Backdrop } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import checkGreen from 'assets/images/Tick-01.png'
import closeicon from 'assets/images/closeIcon.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import MomentUtils from '@date-io/moment';
// import moment from 'moment';
import Moment from 'react-moment';
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import usericon from 'assets/images/Icon awesome-user.svg'
import { getUserRegistrationDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link, useParams } from 'react-router-dom';
import { Required, showToast, getTokenCookie, dataURLtoFile } from 'utils/tools';
import { CheckCircle } from '@material-ui/icons';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import axios from 'axios';
import moment from 'moment';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { Translate } from 'react-redux-i18n';
import { Visibility } from '@material-ui/icons';

import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import validator from 'validator';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import useEnhancedEffect from '@mui/material/utils/useEnhancedEffect';
import UploadFileIcon from '@mui/icons-material/UploadFile';
const WelfareMedical = (props) => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const i18nlang = useSelector(state => state.i18n)
    const hiddenFileInput = React.useRef(null);
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        worker_name:"",
        worker_name_error:false,
        mobile_number:"",
        mobile_number_error:false,
        organization_name:"",
        organization_name_error:false,
        designation:"",
        designation_error:"",
        monthly_salary:"",
        monthly_salary_error:"",
        nature_disease:"",
        nature_disease_error:"",
        duration_treatment:"",
        duration_treatment_error:"",
        amount_spent:"",
        amount_spent_error:"",
        account_no:"",
        account_no_error:"",
        ifsc_code:"",
        ifsc_code_error:"",
        openDatePicker: false,
        postalAddress:"",
        organization_address : "",
        date_of_joining : "" ,
        otherSource : "",
        bank_name : "",
        bank_branch : "",
        amount_spent_bank : "",
        props_this : props




    })


    const inputRef = useRef(null);
    const [schemeData,setSchemeData] =useState([]);
    const [schemeDetailsfiltered,setSchemeDetailsFiltered] =useState([]);
    const [schemeDetails,setSchemeDetails] =useState(null);
    const [schemeDetailsFilteredData,setSchemeDetailsFilteredData] =useState([]);



    const [selectedfiles, setselectedfiles] = React.useState({})
    const [selectedfilesAttested, setselectedfilesAttested] = React.useState({})
    const [selectedfilesMedical, setselectedfilesMedical] = React.useState({})
    const [selectedfileschildPhoto, setselectedfileschildPhoto] = React.useState({})
    const [selectedfilesXerox, setselectedfilesXerox] = React.useState({})


    const [dischargeUpload, setdischargeUpload] = React.useState("")
    const [passbookUpload, setpassbookUpload] = React.useState("")
    const [childPhotoUpload, setchildPhotoUpload] = React.useState("")
    const [birthCertificateUpload, setbirth_certificateUpload] = React.useState("")
    const [selected_document_type, setselected_document_type] = React.useState("");




    const [anchorElmarriage_cert, setAnchorElmarriage_cert] = React.useState(null);
    const openmarriage_cert = Boolean(anchorElmarriage_cert);
    const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;


    const [anchorElpassbook, setAnchorElpassbook] = React.useState(null);
    const openpassbook = Boolean(anchorElpassbook);
    const idpassbook = openpassbook ? "simple-popover" : undefined;


    const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false)

    const [loadingfiles, setloadingfiles] = React.useState(true)
    const [attestedDoc, setAttestedDoc] = React.useState({})
    const [medicalDoc, setMedicalDoc] = React.useState({})
    const [medicalDocCert, setMedicalDocCert] = React.useState({})
    const [filesXerox, setFilesXerox] = React.useState({})



    const [filelist, setfilelist] = React.useState({})
    const [filelistAttested, setfilelistAttested] = React.useState({})
    const [filelistMedical, setfilelistMedical] = React.useState({})
    const [filelistchildPhoto, setfilelistchildPhoto] = React.useState({})
    const [filelistFilesXerox, setfilelistFilesXerox] = React.useState({})

    

    // const handleFileChange = event => {
    //     setFileObj( event.target.files && event.target.files);
    //     if (!fileObj) {
    //       return;
    //     }
    // }

    // const resetFileInput = (event) => {
    //     // reset input value
    //     setFileObj(event.target.value=null)
    //   };
   

    const handleChange1 = (e) => {
        console.log("kkkkk", e.target.value)
        if (e.target.value === "ka") {

            setAllValues({
                ...allValues,
                descriptionsKannada: true,
                [e.target.name]: e.target.value,

            })

        }
        else {
            setAllValues({
                ...allValues,
                descriptionsKannada: false,
                [e.target.name]: e.target.value,

            })
        }


        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }
    const handleChange = event => {
        setAllValues({
            ...allValues,
            ...allValues,
            [event.target.name]: event.target.value
        })
      };
   
     
      const handleClick = (event, data, selecteddoc) => {
        console.error("handleclick data:" + data)
        console.error("handleclick selecteddoc:" + selecteddoc)

        setselected_document_type(selecteddoc)

        if (data === "dischargeDoc"){
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if (data === "passbook"){
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if(data === "birthCert")
            {
                setAnchorElmarriage_cert(event.currentTarget);
    
            }
        else if(data === "childPhoto")
        {
            setAnchorElmarriage_cert(event.currentTarget);

        }
        else if(data === "passbookDoc")
        {
            setAnchorElmarriage_cert(event.currentTarget);

        }

        
        
    
    };
     
    const handleClose = (e, data) => {
            if(data === "dischargeDoc"){
                setAnchorElmarriage_cert(null);

            }
            else if(data === "passbook" ){
                setAnchorElpassbook(null)

            }
            else if(data === "birthCert" ){
                setAnchorElmarriage_cert(null);

            }
            else if(data === "childPhoto" ){
                setAnchorElmarriage_cert(null);

            }
            else if(data === "passbook" ){
                setAnchorElmarriage_cert(null);

            }
            

    };

    const getfilebase64 = (config) => {
        return new Promise((resolve, reject) => {

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));


                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error)
                });

        })
    }


    const nextButtonPressed = () => {

        // try {
        //     // console.log(schemeDetailsFilteredData.id, "approve data");
        //     // if(schemeDetailsFilteredData.id !== undefined){
            var dataSubmit = JSON.stringify({
                "id": schemeDetailsFilteredData.id,
                "board_id": schemeDetailsFilteredData.id,
                "scheme_id": 32,
                "approved_rejected_by": 501,
                "approver_remarks": "Approved ",
                "amount_sanctioned_by_approver": 0,
                "approver_documents": 47
            })
            
            var config = {
                method: 'post',
                url: SERVER+'/welfare/approve_reject_welfare_scheme',
                headers:{
                    'Authorization':`Bearer ${getTokenCookie()}`
                    },
                data : dataSubmit
                };
        
                axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data, "approve data"));
                    showToast("SUCCESS", "Scheme has been approved successfully")
                    props.history.push("/welfaredashboard")
              
        
                })
                .catch(function (error) {
                    console.log(error);
                });
    }

    const rejectButtonPressed = () => {

        // try {
        //     // console.log(schemeDetailsFilteredData.id, "approve data");
        //     // if(schemeDetailsFilteredData.id !== undefined){
            var dataSubmit = JSON.stringify({
                "id": schemeDetailsFilteredData.id,
                "board_id": 1006,
                "scheme_id": 32,
                "approved_rejected_by": 501,
                "approver_remarks": "Approved ",
                "amount_sanctioned_by_approver": 0,
                "approver_documents": 46
            })
            
            var config = {
                method: 'post',
                url: SERVER+'/welfare/approve_reject_welfare_scheme',
                headers:{
                    'Authorization':`Bearer ${getTokenCookie()}`
                    },
                data : dataSubmit
                };
        
                axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data, "approve data"));
                    showToast("SUCCESS", "Scheme has been rejected successfully")
                    props.history.push("/welfaredashboard")
              
        
                })
                .catch(function (error) {
                    console.log(error);
                });
    }


    

    useEffect(() => {
        console.log("schemeDetailsFilteredData",schemeDetailsFilteredData.discharge_summary)
        if (
            schemeDetailsFilteredData.discharge_summary !== undefined &&
            schemeDetailsFilteredData.discharge_summary !== undefined &&
            schemeDetailsFilteredData.discharge_summary.length > 0 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.discharge_summary)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setdischargeUpload(certdoc)
                            // console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }


        if (
            schemeDetailsFilteredData.passbook !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.passbook)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setpassbookUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        if (
            schemeDetailsFilteredData.child_photo !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.child_photo)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setchildPhotoUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        if (
            schemeDetailsFilteredData.birth_certificate !== undefined 
        ) {

            var jsondocs = JSON.parse(schemeDetailsFilteredData.birth_certificate)

            console.log("jsondocs: " + JSON.stringify(jsondocs));
            console.log("jsondocs.length(): " + jsondocs.length);
            var docsFromApi = []

            let promises = []
            for (var i = 0; i < jsondocs.length; i++) {
                var config = {
                    method: 'get',
                    url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                };

                promises.push(getfilebase64(config))
                if (promises.length === jsondocs.length) {
                    Promise.all(promises)
                        .then(results => {
                            // console.log("results of promise: ")
                            // console.log(results)

                            var certdoc = []
                            results.forEach((j, index) => {
                                // console.log("jsondocs_inside: "+JSON.stringify(j));
                                // console.log("jsondocs_inside index: "+index);

                                var cert = {
                                    ['name']: jsondocs[index].file_name,
                                    ['base_64_content']: j.base_64_content,
                                }
                                certdoc.push(cert)
                            })

                            setbirth_certificateUpload(certdoc)
                            console.log("certdoc: "+JSON.stringify(certdoc));
                        })
                }


            }
        }
        // if (
        //     schemeDetailsFilteredData.passbook !== undefined 
        // ) {

        //     var jsondocs = JSON.parse(schemeDetailsFilteredData.passbook)

        //     console.log("jsondocs: " + JSON.stringify(jsondocs));
        //     console.log("jsondocs.length(): " + jsondocs.length);
        //     var docsFromApi = []

        //     let promises = []
        //     for (var i = 0; i < jsondocs.length; i++) {
        //         var config = {
        //             method: 'get',
        //             url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
        //             headers: {
        //                 'Authorization': `Bearer ${getTokenCookie()}`
        //             },
        //         };

        //         promises.push(getfilebase64(config))
        //         if (promises.length === jsondocs.length) {
        //             Promise.all(promises)
        //                 .then(results => {
        //                     // console.log("results of promise: ")
        //                     // console.log(results)

        //                     var certdoc = []
        //                     results.forEach((j, index) => {
        //                         // console.log("jsondocs_inside: "+JSON.stringify(j));
        //                         // console.log("jsondocs_inside index: "+index);

        //                         var cert = {
        //                             ['name']: jsondocs[index].file_name,
        //                             ['base_64_content']: j.base_64_content,
        //                         }
        //                         certdoc.push(cert)
        //                     })

        //                     setpassbookUpload(certdoc)
        //                     console.log("certdoc: "+JSON.stringify(certdoc));
        //                 })
        //         }


        //     }
        // }
        

     },[schemeDetailsFilteredData])


     



   

    


     useEffect(() => {
        console.log("selectedfiles",selectedfiles)
     },[selectedfiles])

     useEffect(() => {
        var config = {
            method: 'get',
            url: SERVER+'/welfare/get_welfare_scheme_availed_details?board_id=1006&scheme_id=32&catalog_value_application_status=45',
            headers: { 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
          
        };
        
        axios(config)
        .then(function (response) {
            console.log("response get_fields_value: "+JSON.stringify(response.data.data[0]));
            setSchemeData(response.data.data)
            // setdischargeUpload(response.data.data[0].photo)
            // setlabourName(response.data.data.labour_name)
        })
        .catch(function (error) {
            console.error(error);
        });

        // console.log("propsID: "+ props);
        console.log("in useEffect props userid:" + allValues.props_this.location.userid + " scheme_avail_id:" + allValues.props_this.location.scheme_avail_id)
 
        
     },[])

    


     useEffect(() => {
        console.log("idScheme2", schemeData)
        console.log("idScheme2", schemeData[0])
        if(schemeData !== undefined){
            let schemeDetailsFiltered = schemeData.filter(o => o.id === allValues.props_this.location.scheme_avail_id)
            console.log("schemeDetailsFiltered", schemeDetailsFiltered)
            if(schemeDetailsFiltered[0] !== undefined && schemeDetailsFiltered[0] !== null){
                setSchemeDetailsFilteredData(schemeDetailsFiltered[0])

            }
        }

     },[schemeData])


  return (
    <div>
    <div className="root">



<Row className="top-div ">
    <Row className="topbar-row">
        <Col xs={12} md={4} lg={6} className="top-bar-col">
            <div className="logo-div-profile">
                <a href="/boardsHome">
                    <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                    {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                    <Translate value="labourTitle" /> 
                    
                </h1>
            </div>

        </Col>
        <div className='d-flex justify-content-end'>
        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
            <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={allValues.language}
                name="language"
                displayEmpty
                onChange={handleChange1}
            >
                <MenuItem value="" >
                    <ListItemIcon>
                        <img alt="..." src={language} className="language-img" />
                    </ListItemIcon>
                    <ListItemText primary="Select Language" />
                </MenuItem>
              
                <MenuItem value="en">
                    <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                    <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
            </Select>
        </Col>
        </div>
        
    </Row>

</Row>
</div>

<Row className="schemes-title-row">
                <Col xs={12} md={9}>
                    
                    <h1> <Translate value="schemes" /> |
                    <Translate value="Delivery Assistance"/>  
                    </h1>

                </Col>
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Worker Details</p>
                </Col>
            </Row>
                
            <Row className="form-row mt-4 config-form searchform">
                <Col xs={12} md={4}>
                    
                    <p className="mb-2">Name of the Beneficiary</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="worker_name"
                            value={schemeDetailsFilteredData.labour_address}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                    
                    <p className="mb-2">Phone Number</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="mobile_number"
                            value={schemeDetailsFilteredData.phone_number}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4}>
                    
                    <p className="mb-2">Aadhaar Number</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="aadhar_no"
                            value={schemeDetailsFilteredData.aadhaar_number}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">Date of Birth</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="aadhar_no"
                            value={schemeDetailsFilteredData.date_of_birth}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">Address</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.labour_address}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">State</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="state_name"
                            value={schemeDetailsFilteredData.labour_address}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">District</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="district_name"
                            value={schemeDetailsFilteredData.district_id}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">Taluk</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="taluk_name"
                            value={schemeDetailsFilteredData.taluk_id}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">Village / Ward</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="village_name"
                            value={schemeDetailsFilteredData.village_area_ward_id}
                            disabled
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} className="mt-1">
                    
                    <p className="mb-2">Number and Street</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            name="street_name"
                            value={schemeDetailsFilteredData.address_number_street}
                            disabled

                           
                        />
                    </FormControl>
                </Col>
                
            </Row>

            <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Bank Details</p>
                </Col>
            </Row>
            <Row className="form-row mt-4 config-form searchform">

               
               
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2">Bank Name</p>
                    
                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.bank_name}
                            disabled
                   
                           
                        />
                        
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">
                    Branch
                    </p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
             
                            variant="outlined"
                            value={schemeDetailsFilteredData.bank_branch}
                            disabled
                           
                        />
                    </FormControl>
                </Col>
            
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">Account No.</p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.ifsc_code}
                            disabled
                            
                        />
                    </FormControl>
                </Col>
                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                    <p className="mb-2 w-50">IFSC Code</p>

                    <FormControl fullWidth className="formcontrol1 w-75">
                        <TextField
                            variant="outlined"
                            value={schemeDetailsFilteredData.ifsc_code}
                            disabled
                            
                        />
                    </FormControl>
                </Col>

                <Col xs={12} md={4} lg={6} xl={6} className="mt-2">
                
                <p className="mb-2 w-75">Amount of Financial assistance sought</p>

                <FormControl fullWidth className="formcontrol1 w-75">
                    <TextField
                        variant="outlined"
                        value={schemeDetailsFilteredData.amount_sought}
                        disabled
                        // helperText={allValues.amount_spent_error ? "Please Enter Numbers only":""}
                        // error = {allValues.amount_spent_error}
                    />
                </FormControl>
            </Col>

                
                </Row>
            




         

                <Row className="scheme-subtitle-row">
                <Col xs={12} className="searchform-subtitle">
                
                    <p>Uploaded Documents</p>
                </Col>
            </Row>

            

<Row className="form-row mt-4 config-form searchform">



        <Col xs={6} className="final-personal-col-1 px-3 mt-4">
            <div className="">
                <p className="mt-4 mb-2">Discharge Summary</p>

                        {
                                schemeDetailsFilteredData.discharge_summary !== undefined &&
                                JSON.parse(schemeDetailsFilteredData.discharge_summary).map((doc) =>
                                    <>
                                        <div className="browsebutton2-div">
                                            <FormControl >
                                                <TextField
                                                    variant="outlined"
                                                    name={doc.file_name}
                                                    value={doc.file_name}
                                                    disabled
                                                />
                                            </FormControl>

                                            <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                            <label className="schemes-doc-view"
                                                onClick={(e) => handleClick(e, "dischargeDoc", dischargeUpload[0].base_64_content)}
                                            >
                                                <Visibility />
                                            </label>
                                        </div>
                                    </>
                                )
                        }       
            </div>







        <Popover
            id={idmarriage_cert}
            open={openmarriage_cert}
            anchorEl={anchorElmarriage_cert}
            onClose={(e) => handleClose(e, "dischargeDoc")}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <a href="#" >

                <img
                    alt="..."
                    className="avatar border-gray"
                    // id={item.id}
                    src={`
                    data:image/jpeg;base64,${selected_document_type}`}

                        onClick={() => {
                            var win = window.open();
                            win.document.write('<iframe src="' + `
                        data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                        }}
                    // src={allValues.selected_document_type}

                
                />
            </a>
        </Popover>
        </Col>


        <Col xs={6} className="final-personal-col-1 px-3 mt-4">
            <div className="">
                <p className="mt-4 mb-2">Passbook</p>

                        {
                                schemeDetailsFilteredData.passbook !== undefined &&
                                JSON.parse(schemeDetailsFilteredData.passbook).map((doc) =>
                                    <>
                                        <div className="browsebutton2-div">
                                            <FormControl >
                                                <TextField
                                                    variant="outlined"
                                                    name={doc.file_name}
                                                    value={doc.file_name}
                                                    disabled
                                                />
                                            </FormControl>

                                            <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                            <label className="schemes-doc-view"
                                                onClick={(e) => handleClick(e, "passbook", passbookUpload[0].base_64_content)}
                                            >
                                                <Visibility />
                                            </label>
                                        </div>
                                    </>
                                )
                        }       
            </div>

        <Popover
            id={idpassbook}
            open={openpassbook}
            anchorEl={anchorElpassbook}
            onClose={(e) => handleClose(e, "passbook")}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <a href="#" >

                <img
                    alt="..."
                    className="avatar border-gray"
                    // id={item.id}
                    src={`
                    data:image/jpeg;base64,${selected_document_type}`}

                        onClick={() => {
                            var win = window.open();
                            win.document.write('<iframe src="' + `
                        data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                        }}
                    // src={allValues.selected_document_type}

                
                />
            </a>
        </Popover>
        </Col>

        <Col xs={6} className="final-personal-col-1 px-3 mt-2">
            <div className="">
                <p className="mt-4 mb-2">Photo of the Child</p>

                        {
                                schemeDetailsFilteredData.child_photo !== undefined &&
                                JSON.parse(schemeDetailsFilteredData.child_photo).map((doc) =>
                                    <>
                                        <div className="browsebutton2-div">
                                            <FormControl >
                                                <TextField
                                                    variant="outlined"
                                                    name={doc.file_name}
                                                    value={doc.file_name}
                                                    disabled
                                                />
                                            </FormControl>

                                            <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                            <label className="schemes-doc-view"
                                                onClick={(e) => handleClick(e, "childPhoto", childPhotoUpload[0].base_64_content)}
                                            >
                                                <Visibility />
                                            </label>
                                        </div>
                                    </>
                                )
                        }       
            </div>







        <Popover
            id={idmarriage_cert}
            open={openmarriage_cert}
            anchorEl={anchorElmarriage_cert}
            onClose={(e) => handleClose(e, "childPhoto")}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <a href="#" >

                <img
                    alt="..."
                    className="avatar border-gray"
                    // id={item.id}
                    src={`
                    data:image/jpeg;base64,${selected_document_type}`}

                        onClick={() => {
                            var win = window.open();
                            win.document.write('<iframe src="' + `
                        data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                        }}
                    // src={allValues.selected_document_type}

                
                />
            </a>
        </Popover>
        </Col>


        <Col xs={6} className="final-personal-col-1 px-3 mt-2">
            <div className="">
                <p className="mt-4 mb-2">Birth Certificate of Child</p>

                        {
                                schemeDetailsFilteredData.birth_certificate !== undefined &&
                                JSON.parse(schemeDetailsFilteredData.birth_certificate).map((doc) =>
                                    <>
                                        <div className="browsebutton2-div">
                                            <FormControl >
                                                <TextField
                                                    variant="outlined"
                                                    name={doc.file_name}
                                                    value={doc.file_name}
                                                    disabled
                                                />
                                            </FormControl>

                                            <input type="file" name="photo" id={`upload-photo_${doc.file_id}`} />

                                            <label className="schemes-doc-view"
                                                onClick={(e) => handleClick(e, "birthCert", birthCertificateUpload[0].base_64_content)}
                                            >
                                                <Visibility />
                                            </label>
                                        </div>
                                    </>
                                )
                        }       
            </div>

        <Popover
            id={idpassbook}
            open={openpassbook}
            anchorEl={anchorElpassbook}
            onClose={(e) => handleClose(e, "birthCert")}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "center"
            }}
            transformOrigin={{
                vertical: "top",
                horizontal: "center"
            }}
        >
            <a href="#" >

                <img
                    alt="..."
                    className="avatar border-gray"
                    // id={item.id}
                    src={`
                    data:image/jpeg;base64,${selected_document_type}`}

                        onClick={() => {
                            var win = window.open();
                            win.document.write('<iframe src="' + `
                        data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                        }}
                    // src={allValues.selected_document_type}

                
                />
            </a>
        </Popover>
        </Col>


</Row>

<Row className="button-inside-form-row mb-5">
    <Col xs={12} className="next-back-button-row mt-4 ">

   
                <>
                    <Link to="#" onClick={rejectButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="danger" className="back-button" >
                            <Translate value="Reject"/>
                        </Button>
                    </Link>
                    <Link to="#" onClick={nextButtonPressed}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="outline-primary" className="save-button">
                            <Translate value="Approve"/>
                        </Button>
                    </Link>
                </>



        


    </Col>
</Row>

     

            




    </div>
  )
}

export default WelfareMedical