import 'date-fns';
import {
  Select,
  MenuItem,
  ListItemText,
  Backdrop,
  CircularProgress,
  makeStyles,
  ListItemIcon,
  Typography,
  Button,
  Tooltip,
} from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  deleteAllCookies,
  getTokenCookie,
  getTokenCookieAdmin,
  showToast,
} from 'utils/tools';
import downloadicn from 'assets/images/Icon material-file-download.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
// import aadhar from 'assets/images/id-card.svg'
// import checkGreen from 'assets/images/Tick-01.svg'
// import pan from 'assets/images/credit-card.svg'
// import { Edit, Person } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  finalSubmit,
  getFile,
  saveAcknowledgementId,
  SERVER,
  SERVER_NAME,
  setLocaleWithFallback,
} from 'store/actions/user.actions';
// import address from 'assets/images/apartment.svg'
import moment from 'moment';
import Moment from 'react-moment';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

import NoImage from 'assets/images/No signature available-01-01.svg';

import QRCode from 'react-qr-code';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

import axios from 'axios';

import html2pdf from 'html2pdf.js';

const ECardApproved = (props) => {
  const {
    labourUserID,
    personalDetails,
    addressDetails,
    familyDetails,
    certificateDetails,
    employerDetails,
  } = props;
  console.log(props, 'PROPSDATA');

  const [base64Content, setBase64Content] = useState('');
  const [base64ContentSignature, setBase64ContentSignature] = useState('');
  const [renewalDetails, setRenewalDetails] = useState(null);
  const [renewalDate, setRenewalDate] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const familyMapped = familyDetails && familyDetails.map((data) => data);
  const RelationFound =
    familyDetails &&
    familyMapped.find(
      (data) =>
        data.parent_child_relation === 'Wife' ||
        data.parent_child_relation === 'Father' ||
        data.parent_child_relation === 'Mother' ||
        data.parent_child_relation === 'Husband',
    );
  const nameOfTheRelationFounded =
    RelationFound &&
    [
      RelationFound?.first_name && RelationFound?.first_name,
      RelationFound?.middle_name && RelationFound?.middle_name,
      RelationFound?.last_name && RelationFound?.last_name,
    ].join(' ');

  const NomineeFind =
    familyMapped && familyMapped.find((data) => data.is_nominee === true);
  const nameOfTheNomineeFounded = [
    NomineeFind?.first_name && NomineeFind?.first_name,
    NomineeFind?.middle_name && NomineeFind?.middle_name,
    NomineeFind?.last_name && NomineeFind?.last_name,
  ].join(' ');
  const relationOfTheNominee =
    NomineeFind?.parent_child_relation && NomineeFind?.parent_child_relation;

  const address = [
    addressDetails.door_no && addressDetails.door_no,
    addressDetails.street_name && addressDetails.street_name,
    addressDetails.land_mark && addressDetails.land_mark,
    addressDetails.panhayat_city_town && addressDetails.panhayat_city_town,
    addressDetails.district && addressDetails.district,
    addressDetails.ward_area_village && addressDetails.ward_area_village,
    addressDetails.taluk && addressDetails.taluk,
    addressDetails.state && addressDetails.state,
    addressDetails.pin_code && addressDetails.pin_code,
  ].join(' ');

  const age = moment().diff(
    moment(
      personalDetails.date_of_birth && personalDetails.date_of_birth,
    ).format('YYYY-MM-DD'),
    'years',
    false,
  );
  console.log(age, 'AGE');

  useEffect(() => {
    if (personalDetails.user_photo_id) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${personalDetails.user_photo_id}`,
        )
        .then((res) => {
          console.log(res.data.data, 'PhotoId');
          setBase64Content(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [personalDetails]);

  useEffect(() => {
    if (certificateDetails.signature_upload_id) {
      axios
        .get(
          `${SERVER}/upload/download_base_64/${certificateDetails.signature_upload_id}`,
        )
        .then((res) => {
          console.log(res.data.data, 'PhotoIdSignature');
          setBase64ContentSignature(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [certificateDetails]);

  useEffect(() => {
    const payLoad = {
      key: 'user_id',
      value: labourUserID,
      board_id: 1,
      procedure_name: 'renewal_90_days_certificate_details',
    };
    axios
      .post(`${SERVER}/user/get-user-renewal-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        console.log('renewalDetails', data.data);
        setRenewalDetails(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  useEffect(() => {
    const payLoad = {
      user_id: labourUserID,
    };

    axios
      .post(`${SERVER}/user/get-renewal-date`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        console.log('renewalDate', data);
        setRenewalDate(data.data.recordset[0].next_renewal_date);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  useEffect(() => {
    if (renewalDate && renewalDetails && renewalDetails.length === 0) {
      console.log('Inside Date');
      if (personalDetails && personalDetails.is_sevasindhu === 0) {
        //  setToDate1(next_renewal_date)
        setFromDate(
          personalDetails.approved_date && personalDetails.approved_date,
        );
        setToDate(
          personalDetails.approved_date &&
            moment(personalDetails.approved_date).add(1, 'y').subtract(1, 'd'),
        );
      }
      if (personalDetails && personalDetails.is_sevasindhu === 1) {
        setToDate(
          personalDetails.validity_to_date && personalDetails.validity_to_date,
        );
        setFromDate(
          personalDetails.validity_from_date &&
            personalDetails.validity_from_date,
        );
      }
    } else if (renewalDate && renewalDetails && renewalDetails.length > 0) {
      setFromDate(
        renewalDate && moment(renewalDate).subtract(1, 'y').subtract(1, 'd'),
      );
      setToDate(renewalDate && moment(renewalDate).subtract(2, 'd'));
    }
  }, [personalDetails, renewalDate, renewalDetails]);

  const printDocument = () => {
    const dwnldicn = document.getElementById('dwnldicn');

    dwnldicn.style.padding = '10px';
    const input = document.getElementById('divToPrint');

    html2canvas(input, {
      scrollY: -window.scrollY,
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        const pdf = new jsPDF();

        pdf.addImage(imgData, 'JPEG', 50, 10, 105, 238);

        window.open(
          pdf.output('bloburl'),
          '_blank',
          'toolbar=0,location=0,menubar=0',
        );
      })
      .then(() => {
        dwnldicn.style.padding = '0px';
      });
  };

  return (
    <>
      <>
        <div className="d-flex justify-content-center flex-row">
          <div
            id="divToPrint"
            className="my-4 "
            style={{
              backgroundColor: '#fff',
              width: '100%',
              minHeight: 'auto',
              marginLeft: 'auto',
              marginRight: 'auto',
              textAlign: 'center',
              background: '#FFFFFF 0% 0% no-repeat padding-box',
              border: '2px solid #00000029',
              // boxShadow: '0px 3px 8px #00000029',
            }}
          >
            <Tooltip title="Download PDF">
              <img
                src={downloadicn}
                id="dwnldicn"
                alt="..."
                className="download-icon"
                onClick={printDocument}
              />
            </Tooltip>
            <div className="logo-div-ack">
              <img
                id="logo"
                src={logo}
                alt="..."
                className="logo-img"
                style={{ marginLeft: '50px' }}
              />

              <h1 className="logo-text idcard" style={{ fontSize: '22px' }}>
                ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
                (ಕಕಇನಿಕಾಕಮಂ) <br />
                Karnataka Building & Other Construction Workers Welfare Board
                (KBOCWWB) <br />
              </h1>
            </div>

            <Row className="ack-table-row idcard profile_qr mt-5">
              <Col xs={6} className="ack-col">
                <div className="picupload idcard">
                  <img
                    src={`data:image/jpeg;base64,${base64Content}`}
                    alt=""
                    width={'200'}
                    height={'200'}
                  />
                </div>
              </Col>
            </Row>

            <Row className="ack-table-row idcard mt-5">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಹೆಸರು / Name:</p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {[
                    personalDetails.first_name && personalDetails.first_name,
                    personalDetails.middle_name && personalDetails.middle_name,
                    personalDetails.last_name && personalDetails.last_name,
                  ].join(' ')}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }} className="100">
                  ತಂದೆ / ತಾಯಿ / ಪತಿ / ಪತ್ನಿ ಹೆಸರು :<br />
                  Father / Mother / Husband / Wife Name
                </p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{nameOfTheRelationFounded}</p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }} className="w-75">
                  ಜನ್ಮ ದಿನಾಂಕ :<br /> Date of Birth
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  <Moment format="DD MMMM YYYY">
                    {personalDetails && personalDetails.date_of_birth}
                  </Moment>
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಲಿಂಗ / Gender :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {personalDetails.gender && personalDetails.gender}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>Marital Status :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {personalDetails.marital_status &&
                    personalDetails.marital_status}
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ವಿಳಾಸ / Address :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{address}</p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard mt-3">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration ID :
                </p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px', marginTop: '3px' }}>
                  {personalDetails && personalDetails.registration_code}
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard mt-3">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನೋಂದಣಿ ದಿನಾಂಕ / Registration Date :
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  <Moment format="DD MMMM YYYY">
                    {personalDetails && personalDetails.approved_date}
                  </Moment>
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಈ ದಿನಾಂಕದಿಂದ / From Date :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  <Moment format="DD MMMM YYYY">{fromDate}</Moment>
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಇಲ್ಲಿಯವರೆಗೆ / To Date :</p>
              </Col>
              <Col xs={6} className="ack-col">
                {/* <p>{ToDate1}</p> */}
                <p style={{ fontSize: '22px' }}>
                  <Moment format="DD MMMM YYYY">{toDate}</Moment>
                </p>
              </Col>
            </Row>

            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ಕೆಲಸದ ಸ್ವರೂಪ :<br />
                  Nature of Work
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {employerDetails &&
                  employerDetails[0].nature_of_work &&
                  employerDetails[0].nature_of_work
                    ? employerDetails[0].nature_of_work
                    : '-'}
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ಮೊಬೈಲ್ ಸಂಖ್ಯೆ :<br /> Mobile Number
                </p>
              </Col>

              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  {personalDetails && personalDetails.mobile_no}
                </p>
              </Col>
            </Row>
            <Row className="ack-table-row"></Row>

            <div className="d-flex justify-content-center align-items-center">
              <Row className="ack-table-row idcard">
                <Col xs={12} className="ack-col nomineeTitle">
                  <p style={{ fontSize: '22px' }}>
                    ನಾಮನಿರ್ದೇಶಿತರ ವಿವರಗಳು / Nominee Details
                  </p>
                </Col>
              </Row>
            </div>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>
                  ನಾಮನಿರ್ದೇಶಿತರ ಹೆಸರು :<br />
                  Nominee Name
                </p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{nameOfTheNomineeFounded}</p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ಸಂಬಂಧ / Relationship :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{relationOfTheNominee}</p>
              </Col>
            </Row>
            <Row className="ack-table-row idcard">
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>ವಯಸ್ಸು / Age :</p>
              </Col>
              <Col xs={6} className="ack-col">
                <p style={{ fontSize: '22px' }}>{age}</p>
              </Col>
            </Row>
            {familyDetails && familyDetails.length > 1 ? (
              <>
                <Row className="ack-table-row"></Row>
                <Row className="ack-table-row idcard">
                  <Col
                    xs={12}
                    className="ack-col nomineeTitle d-flex justify-content-center"
                  >
                    <p style={{ fontSize: '22px' }}>
                      ಕುಟುಂಬದ ವಿವರಗಳು / Family Details
                    </p>
                  </Col>
                </Row>
                <Row
                  className="d-flex justify-content-center"
                  style={{ fontSize: '22px' }}
                >
                  <table
                    className="e-card-table-border"
                    style={{ width: '90%' }}
                  >
                    <tr>
                      <th className="e-card-table-border">SL.No</th>
                      <th className="e-card-table-border">Name</th>
                      <th className="e-card-table-border">Age</th>
                      <th className="e-card-table-border">Relationship</th>
                      <th className="e-card-table-border">Gender</th>
                    </tr>
                    {familyDetails &&
                      familyDetails
                        .filter((data) => data.is_nominee === false)
                        .map((row, index) => (
                          <tr>
                            <td className="e-card-table-border">{index + 1}</td>
                            <td className="e-card-table-border">
                              {[
                                row.first_name,
                                row.middle_name,
                                row.last_name,
                              ].join(' ')}
                            </td>
                            <td className="e-card-table-border">
                              {moment().diff(
                                moment(row.date_of_birth).format('YYYY-MM-DD'),
                                'years',
                                false,
                              )}
                            </td>
                            <td className="e-card-table-border">
                              {row.parent_child_relation}
                            </td>
                            <td className="e-card-table-border">
                              {row.parent_child_relation === 'Father' ||
                              row.parent_child_relation === 'Husband' ||
                              row.parent_child_relation === 'Son' ||
                              row.parent_child_relation === 'Brother'
                                ? 'Male'
                                : row.parent_child_relation === 'Mother' ||
                                    row.parent_child_relation === 'Wife' ||
                                    row.parent_child_relation === 'Daughter' ||
                                    row.parent_child_relation === 'Sister'
                                  ? 'Female'
                                  : row.parent_child_relation === 'Guardian'
                                    ? 'Male / Female'
                                    : ''}
                            </td>
                          </tr>
                        ))}
                  </table>
                </Row>
              </>
            ) : null}
            <Row className="ack-table-row"></Row>

            <div className="d-flex justify-content-around align-items-center">
              <div className="mx-5">
                <p className="w-100 pt-4">
                  ಕಾರ್ಮಿಕರ ಸಹಿ /<br /> Labour Signature
                </p>
                <div
                  className="picupload idcard"
                  style={{
                    width: '200px',
                    height: '84px',
                    border: '2px solid grey',
                  }}
                ></div>
              </div>

              <div className="mx-5">
                <p className="w-100 pt-4">
                  ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಸಹಿ /<br />
                  Labour Inspector Signature
                </p>
                <div
                  className="picupload idcard"
                  style={{ width: '200px', height: '84px' }}
                >
                  {base64ContentSignature ? (
                    <img
                      src={`data:image/jpeg;base64,${base64ContentSignature}`}
                      alt=""
                      width={'180'}
                      height={'80'}
                    />
                  ) : (
                    <img src={NoImage} alt="..." width={'180'} height={'80'} />
                  )}
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-around align-items-center">
              <div className="w-100 mx-5"></div>
              <div className="w-100 mx-5">
                <p>
                  ವೃತ್ತ:
                  {certificateDetails.inspector_circle &&
                    certificateDetails.inspector_circle}
                  <br />
                  ತಾಲ್ಲೂಕು: {addressDetails.taluk && addressDetails.taluk}
                  <br />
                  ಜಿಲ್ಲೆ: {addressDetails.district && addressDetails.district}
                  <br />
                  {/* ಅನುಮೋದನೆಗೊಂಡ ದಿನಾಂಕ: <br/><Moment format='DD MMMM YYYY hh:mm:ss A'>{get_e_card && get_e_card.certificate_details !== undefined && get_renewal && get_renewal > 0 ? (renewalApprovedDate !== undefined && renewalApprovedDate) : get_e_card && get_e_card.personal_details && get_e_card.personal_details[0] && get_e_card.personal_details[0].approved_date}</Moment> */}
                </p>
              </div>
            </div>

            <Row className="d-flex justify-content-center align-items-center"></Row>
          </div>
        </div>
      </>

      {/* } */}
    </>
  );
};

export default ECardApproved;
