/* eslint-disable no-throw-literal */
export const AddressValidator = async (
  residence,
  districtID,
  talukID,
  cityID,
  villageID,
  streetName,
  houseNumber,
  pincode,
) => {
  if (
    !residence ||
    +residence === 0 ||
    !districtID ||
    +districtID === 0 ||
    !talukID ||
    +talukID === 0 ||
    !cityID ||
    +cityID === 0 ||
    !villageID ||
    +villageID === 0 ||
    !streetName ||
    !houseNumber ||
    !pincode
  ) {
    throw {
      success: false,
      msg: 'Please fill all mandatory address details',
    };
  } else {
    return {
      success: true,
      data: {
        residence: +residence,
        districtID: +districtID,
        talukID: +talukID,
        cityID: +cityID,
        villageID: +villageID,
        streetName: streetName,
        houseNumber: houseNumber,
        pincode: +pincode,
      },
    };
  }
};
