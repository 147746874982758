import React from 'react';
import Approve from './Approve';
import Reject from './Reject';
import DraftApprove from './DraftApprove';
import DraftReject from './DraftReject';
import Forward from './Forward';
import Transfer from './Transfer';
import TransferBack from './TransferBack';
import Preview from './Preview';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Transaction = ({
  loading,
  handleForwardTransaction,
  opentransfer,
  handleCloseTransfer,
  transferRemarks,
  circle,
  circleID,
  handleClickTransfer,
  handleTransferChange,
  handleSubmitTransfer,
  handleTransferBackTransaction,
  handleDraftTransaction,
  handleApproveRejectTransaction,
  openRejection,
  handleCloseRejection,
  toggleLang,
  handleToggle,
  handleCheckboxChange,
  handleSubmitReject,
  handleOpenRejectionDialog,
  rejectReasons,
  handleDraftSubmitReject,
  selectedOptions,
}) => {
  const { schemeStatus, approvalAuthority, schemeName } = useParams();

  const showButtons =
    schemeName === 'Continuation of Pension' ||
    schemeName === 'Continuation of Disability Pension';

  return (
    <>
      {/* <Preview /> */}
      {showButtons
        ? null
        : schemeStatus === 'Pending' &&
          +approvalAuthority === 1 && (
            <Transfer
              loading={loading}
              opentransfer={opentransfer}
              handleCloseTransfer={handleCloseTransfer}
              transferRemarks={transferRemarks}
              circle={circle}
              circleID={circleID}
              handleClickTransfer={handleClickTransfer}
              handleTransferChange={handleTransferChange}
              handleSubmitTransfer={handleSubmitTransfer}
            />
          )}
      {showButtons &&
        schemeStatus === 'Pending' &&
        +approvalAuthority === 1 && (
          <>
            <DraftApprove
              loading={loading}
              handleDraftTransaction={handleDraftTransaction}
            />
            <DraftReject
              loading={loading}
              handleDraftTransaction={handleDraftTransaction}
              handleCloseRejection={handleCloseRejection}
              openRejection={openRejection}
              handleOpenRejectionDialog={handleOpenRejectionDialog}
              rejectReasons={rejectReasons}
              handleCheckboxChange={handleCheckboxChange}
              toggleLang={toggleLang}
              handleToggle={handleToggle}
              handleDraftSubmitReject={handleDraftSubmitReject}
            />
          </>
        )}
      {schemeStatus === 'Transferred Scheme Application' &&
        +approvalAuthority === 1 && (
          <TransferBack
            loading={loading}
            handleTransferBackTransaction={handleTransferBackTransaction}
          />
        )}
      {(showButtons
        ? null
        : (schemeStatus === 'Pending' && +approvalAuthority === 1) ||
          (schemeStatus === 'Transferred back Scheme Application' &&
            +approvalAuthority === 1)) && (
        <Forward
          handleTransaction={handleForwardTransaction}
          loading={loading}
        />
      )}
      {(schemeStatus === 'Draft Approved' ||
        schemeStatus === 'Draft Rejected') &&
        +approvalAuthority === 1 && (
          <Approve
            loading={loading}
            handleApproveRejectTransaction={handleApproveRejectTransaction}
          />
        )}

      {(schemeStatus === 'Draft Approved' ||
        schemeStatus === 'Draft Rejected') &&
        +approvalAuthority === 1 && (
          <Reject
            loading={loading}
            handleApproveRejectTransaction={handleApproveRejectTransaction}
            openRejection={openRejection}
            handleCloseRejection={handleCloseRejection}
            toggleLang={toggleLang}
            handleToggle={handleToggle}
            handleCheckboxChange={handleCheckboxChange}
            handleSubmitReject={handleSubmitReject}
            handleOpenRejectionDialog={handleOpenRejectionDialog}
            rejectReasons={rejectReasons}
            selectedOptions={selectedOptions}
          />
        )}
      {schemeStatus === 'Forwarded' && +approvalAuthority === 1 && (
        <DraftApprove
          loading={loading}
          handleDraftTransaction={handleDraftTransaction}
        />
      )}
      {schemeStatus === 'Forwarded' && +approvalAuthority === 1 && (
        <DraftReject
          loading={loading}
          handleDraftTransaction={handleDraftTransaction}
          handleCloseRejection={handleCloseRejection}
          openRejection={openRejection}
          handleOpenRejectionDialog={handleOpenRejectionDialog}
          rejectReasons={rejectReasons}
          handleCheckboxChange={handleCheckboxChange}
          toggleLang={toggleLang}
          handleToggle={handleToggle}
          handleDraftSubmitReject={handleDraftSubmitReject}
        />
      )}
    </>
  );
};

export default Transaction;
