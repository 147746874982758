import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Header2 from '../Header2';
import doc1 from 'assets/images/certificate-03.svg';
import doc2 from 'assets/images/doc2.svg';
import doc3 from 'assets/images/doc3.svg';
import doc from 'assets/images/certificate-03.svg';
import Button2 from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg';
import { setLocaleWithFallback } from 'store/actions/user.actions';
import { useState } from 'react';

const Accident2 = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);

  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });
  const handleChange1 = (e) => {
    console.log('kkkkk', e.target.value);
    if (e.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === 'language')
      dispatch(setLocaleWithFallback(e.target.value));
  };
  return (
    <div>
      <Header2 lang={allValues.language} />
      <div className="d-flex justify-content-end">
        <Col
          xs={12}
          md={4}
          lg={3}
          className="d-flex justify-content-end mt-2 me-2"
        >
          <Select
            className="select-language"
            style={{ width: '100%' }}
            variant="outlined"
            labelId="demo-simple-select-required-label"
            value={allValues.language}
            name="language"
            displayEmpty
            onChange={handleChange1}
          >
            <MenuItem value="">
              <ListItemIcon>
                <img alt="..." src={language} className="language-img" />
              </ListItemIcon>
              <ListItemText primary="Select Language" />
            </MenuItem>
            {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
            <MenuItem value="en">
              <ListItemText primary="English" />
            </MenuItem>
            <MenuItem value="ka">
              <ListItemText primary="ಕನ್ನಡ" />
            </MenuItem>
          </Select>
        </Col>
      </div>
      <Row className="container-fluid">
        <div className="schemes-home-div container-fluid">
          <h3 className="schemesHome-title container-fluid">
            <Translate value="labouraccidentTitle" />
          </h3>
          <p className="schemes-instructions schems-docs-details container-fluid">
            <Translate value="labouraccidentReq1" />
          </p>
          <h3 className="schemesHome-title container-fluid">
            <Translate value="docsRequired" />
          </h3>
        </div>
      </Row>
      <div>
        <Row className="justify-content-center container-fluid">
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={6}
            xl={6}
            className="mapping-cards-col schemes "
          >
            <div className="mapping-card schemes hover2">
              <img src={doc} alt="..." />
              <p>
                <Translate value="labourmedicalDoc1" />
              </p>
            </div>
          </Col>

          <Col
            xs={12}
            sm={4}
            md={4}
            lg={6}
            xl={6}
            className="mapping-cards-col schemes "
          >
            <div className="mapping-card schemes hover2">
              <img src={doc} alt="..." />
              <p>
                <Translate value="labourmedicalDoc2" />
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={6}
            xl={6}
            className="mapping-cards-col schemes "
          >
            <div className="mapping-card schemes hover2">
              <img src={doc} alt="..." />
              <p>
                <Translate value="labourmedicalDoc3" />
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={6}
            xl={6}
            className="mapping-cards-col schemes "
          >
            <div className="mapping-card schemes hover2">
              <img src={doc} alt="..." />
              <p>
                <Translate value="labourmedicalDoc4" />
              </p>
            </div>
          </Col>
          <Col
            xs={12}
            sm={4}
            md={4}
            lg={6}
            xl={6}
            className="mapping-cards-col schemes "
          >
            <div className="mapping-card schemes hover2">
              <img src={doc} alt="..." />
              <p>Self Declaration</p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="d-flex justify-content-center p-5">
        <Button
          variant="success"
          size="lg"
          className="w-25"
          href="/welfareaccident"
          // onClick={() => {
          //     window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          //   }}
        >
          <Translate value="applybtn" />
        </Button>
      </div>
      <div className="d-flex justify-content-end mt-5 me-5 mb-5">
        <Link to="/labourschemesHomePage">
          <Button2 className="back-btn-mainScreens">
            <ArrowBackIosIcon color="secondary" className="back-icon" />
            <Translate value="backbtn" />
          </Button2>
        </Link>
      </div>
    </div>
  );
};

export default Accident2;
