export const KannadaContentSanctionOrder = (
  schemeName,
  getSchemeDetails,
  getSchemeStatusDetails,
) => {
  let kannadaContent = '';
  if (schemeName === 'Medical Assistance') {
    kannadaContent = `ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Delivery Assistance') {
    kannadaContent = `ಹೆರಿಗೆ ಸೌಲಭ್ಯವನ್ನು ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು.ಸದರಿ ಮೊತ್ತವನ್ನು ಫಲಾನುಭವಿಗಳ ಹೆಸರಿನಲ್ಲಿ 3 ವರ್ಷಗಳ ಅವಧಿಗೆ ರಾಷ್ಟ್ರೀಕೃತ ಬ್ಯಾಂಕ್ ನಲ್ಲಿ ನಿಶ್ಚಿತ ಠೇವಣಿ / ಬಾಂಡ್ ಇಡಲಾಗುವುದು `;
  } else if (schemeName === 'Assistance For Major Ailments') {
    kannadaContent = `ಪ್ರಮುಖ ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Marriage Assistance') {
    kannadaContent = `ಮದುವೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Pension Scheme') {
    kannadaContent = `ಪಿಂಚಣಿ  ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Continuation of Pension') {
    kannadaContent = `ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Disability Pension') {
    kannadaContent = `ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಮತ್ತು ಅನುಗ್ರಹರಾಶಿ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಹಾಗು ಮಂಜೂರಾದ ಮೊತ್ತ ಅನುಗ್ರಹರಾಶಿ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಮತ್ತು ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಮಾಸಿಕ ರೂ 2000/- ಗಳಾಗಿರುತ್ತದೆ. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Continuation of Disability Pension') {
    kannadaContent = `ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Accident Assistance') {
    if (
      getSchemeStatusDetails &&
      getSchemeStatusDetails[0].death_status === 1
    ) {
      kannadaContent = `ಅಪಘಾತ ಮರಣ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
        getSchemeDetails &&
        (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
      } /-ಗಳಾಗಿದ್ದು.ಅದರಲ್ಲಿ ಶೇಕಡ 50% ರಷ್ಟು ಮೊತ್ತ ರೂ 2,50,000 /-ಗಳನ್ನು ನಾಮನಿರ್ದೇಶಿತರ ಹೆಸರಿನಲ್ಲಿ ರಾಷ್ಟ್ರೀಕೃತ ಬ್ಯಾಂಕ್ ನಲ್ಲಿ ನಿಶ್ಚಿತ ಠೇವಣಿ / ಬಾಂಡ್ ಇಡಲಾಗುವುದು ಮತ್ತು ಉಳಿಕೆ ಶೇಕಡ 50% ರಷ್ಟು ಮೊತ್ತ ರೂ 2,50,000 /-ಗಳನ್ನು ನಾಮನಿರ್ದೇಶಿತರ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
    } else if (
      getSchemeStatusDetails &&
      getSchemeStatusDetails[0].death_status === 2
    ) {
      kannadaContent = `ಶಾಶ್ವತ ಭಾಗಶ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
        getSchemeDetails &&
        (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
      } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
    } else if (
      getSchemeStatusDetails &&
      getSchemeStatusDetails[0].death_status === 3
    ) {
      kannadaContent = `ಶಾಶ್ವತ ಸಂಪೂರ್ಣ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಮಂಜೂರಾದ ಮೊತ್ತ ರೂ ${
        getSchemeDetails &&
        (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
      } /- ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
    }
  } else if (schemeName === 'Funeral Expense and Death Assistance') {
    kannadaContent = `ಅಂತ್ಯಕ್ರಿಯೆ ವೆಚ್ಚ ಹಾಗೂ ಅನುಗ್ರಹರಾಶಿ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಹಾಗು ಮಂಜೂರಾದ ಮೊತ್ತ ಅನುಗ್ರಹರಾಶಿ ರೂ.71,000/- ಮತ್ತು ಅಂತ್ಯಕ್ರಿಯೆ ವೆಚ್ಚ ರೂ.4000/-ಗಳಾಗಿದ್ದು ಒಟ್ಟು ${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /-ಗಳ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  } else if (schemeName === 'Thayi Magu Sahaya Hasta') {
    kannadaContent = `ತಾಯಿ ಮಗು ಸಹಾಯ ಹಸ್ತ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಹಾಗು ಮಂಜೂರಾದ ಮೊತ್ತ ಮಾಹೆಯಾನ ರೂ.500/-ಗಳಂತೆ ವಾರ್ಷಿಕ ಒಟ್ಟು ರೂ.${
      getSchemeDetails &&
      (getSchemeDetails.schemeDetails1.sanctioned_Amount ?? '')
    } /-ಗಳಾಗಿದ್ದು. ಸದರಿ ಮೊತ್ತವನ್ನು ಡಿಬಿಟಿ ಮೂಲಕ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ ಮಾಡಲಾಗುವುದು.`;
  }
  return kannadaContent;
};
