import * as React from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import { Translate } from 'react-redux-i18n';
import { Button } from 'react-bootstrap';
import { useState } from 'react';
import { ChildDetailsAPI } from 'services/ThaiMagu.service';
import { showToast } from 'utils/tools';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';

export default function RenewalDialog({ childDetails, labourUserID }) {
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const [child, setChild] = useState(null);
  const [schemeAvailID, setSchemeAvailID] = useState(null);
  const [renewalSequence, setRenewalSequence] = useState(null);

  let { id, role_hierarchy, totalCountAvailed, totalAvailedByUser } =
    useParams();
  var idName = id.split('_id=')[0];
  var role_id = Number(id.split('_id=')[1]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleRenewal = () => {
    if (child) {
      setOpen(false);
      history.push(
        `/newSchemeForm/${labourUserID}/${role_id}/${idName}/${role_hierarchy}/${true}/${child}/${false}/${schemeAvailID}/${renewalSequence}/${totalCountAvailed}/${totalAvailedByUser}`,
      );
    } else {
      showToast('ERROR', 'Please select the child');
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const callChildDetails = async (labourUserID, child) => {
    try {
      const payload = {
        labourUserId: labourUserID,
        childValue: child,
      };
      const response = await ChildDetailsAPI(payload);
      if (response.success) {
        const { scheme_avail_id, renewal_sequence_year } = response?.data[0];

        setSchemeAvailID(scheme_avail_id);
        setRenewalSequence(renewal_sequence_year);
      }
    } catch (error) {
      if (error?.response?.data?.errors?.msg)
        showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
      else {
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  React.useEffect(() => {
    if (child && labourUserID) {
      callChildDetails(labourUserID, child);
    }
  }, [child, labourUserID]);

  return (
    <React.Fragment>
      <Button
        onClick={() => handleClickOpen()}
        style={{ flexGrow: '0.5' }}
        variant="outline-primary"
        className="save-button schemedoc"
      >
        <Translate value="Renewal" />
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Select the child</DialogTitle>
        <DialogContent sx={{ width: 500 }}>
          <FormControl variant="outlined" fullWidth className="formcontrol5">
            <Select
              style={{ border: '1px solid grey' }}
              className="select-marital"
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={child || '0'}
              name="maritialStatus"
              onChange={(ev) => setChild(ev.target.value)}
              displayEmpty

              // onChange={(ev) => setMaritialID(ev.target.value)}
            >
              <MenuItem value="0">
                <ListItemText primary="Select Child" />
              </MenuItem>
              {childDetails &&
                childDetails.map((row) => (
                  <MenuItem value={row.value}>
                    <ListItemText primary={row.value} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="success" onClick={handleRenewal}>
            Apply
          </Button>
          <Button variant="danger" onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
