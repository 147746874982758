import uploadFileAPI from 'utils/fileupload';

export const missingDocumentUpload = (missingDoc) => {
  return new Promise((resolve, reject) => {
    if (!missingDoc || missingDoc.length === 0) {
      return reject(new Error('No documents to upload'));
    }

    const uploadPromises = missingDoc.map((doc) => uploadFileAPI(doc));

    Promise.all(uploadPromises)
      .then((responses) => {
        const mappedData = responses.map((response) => ({
          file_id: response.image.fileId,
          file_name: response.image.originalFileName,
        }));
        resolve(mappedData);
      })
      .catch((error) => reject(error));
  });
};
