import React, { useEffect, useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import { FormControl, TextField, Select, MenuItem, ListItemIcon, ListItemText, RadioGroup, FormControlLabel, Radio, CircularProgress, InputAdornment, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { get_schemes, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { encrypt, getTokenCookie, removeTokenCookie, showToast } from 'utils/tools';
import checkGreen from 'assets/images/Tick-01.png'
import schemeAppliedStatusGroupIcon from 'assets/images/schemeAppliedStatusGroupIcon.svg'
import schemeApprovedStatusSubtraction11 from 'assets/images/schemeApprovedStatusSubtraction11.svg'
import schemependingStatusPendingSVG from 'assets/images/schemependingStatusPendingSVG.svg'
import schemeclosestatus from 'assets/images/schemeclosestatus.svg'
import schemeSubtractionstatus from 'assets/images/schemeSubtractionstatus.svg'
import calendar from 'assets/images/calendar.svg'
import Moment from 'react-moment';
import PendingSmallStatusIcon from 'assets/images/PendingSmallStatusIcon.svg'
import time from 'assets/images/time.svg'
import closeIcon from 'assets/images/closeIcon.svg'
import usericon from 'assets/images/Icon awesome-user.svg'
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { Link } from 'react-router-dom';
import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import Eye from 'assets/images/eye.svg'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

// import MomentUtils from '@date-io/moment';
// import moment from 'moment';
// import Moment from 'react-moment';
import moment from 'moment';
import Button2 from '@mui/material/Button';



function ChangePassword(props) {

    const users = useSelector(state => state.users)

    // const [allValues, setAllValues] = React.useState({
    //    newPassword : '',
    //    oldPassword : '',
    //    hash : '',
    //    otp:''
    // })

    const [newPassword, setnewPassword] = React.useState(null)
    const [oldPassword, setoldPassword] = React.useState(null)
    const [confirmpassword, setconfirmpassword] = React.useState(null)



    const [expiryDate, setExpiryDate] = React.useState("")


    const handleChange = (event) => {
        console.log("ET", event.target)
        console.log("dataentered", event.target.name)


        if (event.target.name.includes("newPassword")) {

            setnewPassword(event.target.value)


        }
        else if (event.target.name.includes("oldPassword")) {
            console.log("fff")
            setoldPassword(event.target.value)
        }

        else if (event.target.name.includes("confirmpassword")) {
            console.log("fff")

            setconfirmpassword(event.target.value)


        }
    }

    const changePassword = () => {
        console.log("userss", users.getDeptUserRole.data[0].role_name)
        console.log("userss", oldPassword)
        console.log("userss", newPassword)

        if(newPassword !== null && oldPassword !== null && confirmpassword !== null && newPassword !== "" && oldPassword !== "" && confirmpassword !== ""){
            if (newPassword.length > 6 && newPassword.length < 15 ) {
                if(oldPassword !== newPassword){
                if (newPassword === confirmpassword) {
    
                    var todayDate = moment()
                    // var todayDateMoment = moment(todayDate)
                    // var todayDateMoment = todayDate.format('DD-MM-YYYY');
                    // var todayDateMoment1 = moment(todayDateMoment)
                    // var todayDateMoment2 = moment(todayDateMoment1).add(1, 'Y');
    
                    // var todayDateMomentFormatted = todayDateMoment2.format('DD-MM-YYYY');
                    var new_date = moment(todayDate, "MM-DD-YYYY").add(1, 'years');
                    var newDateFormatted = new_date.format('MM-DD-YYYY');
    
    
    
    
                    console.log(newDateFormatted, "todayDate")
    
    
    
    
    
                    var data1 = JSON.stringify({
                        // "username": users.login_admin.data.username,
                        // // "username": "Labourinspector@68",
                        // "old_password": oldPassword,
                        // "new_password": newPassword,
                        // "next_expiry_date":newDateFormatted,
    
    
                        "username": users.login_admin.data.username,
                        "old_password": oldPassword,
                        "new_password": newPassword,
                        "next_expiry_date": newDateFormatted
                    });
    
                    var config = {
                        method: 'post',
                        url: SERVER + '/config/dept_user/change_dept_user_password',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${getTokenCookie()}`
                        },
                        data: data1
                    };
    
                    axios(config)
                        .then(function (response) {
                            console.log("Number_response", JSON.stringify(response.data));
                            showToast("SUCCESS", "Password has been changed")
                            setnewPassword("")
                            setconfirmpassword("")
                            
    
    
                            // showToast("SUCCESS", "The number has been changed successfully")
                            // setAllValues({
                            //     ...allValues,
                            //     hash : response.data.data.hash
                            // })
    
    
    
    
                            // allValues.props_this.history.goBack()
    
                        })
                        .catch(function (error) {
                            console.log(error);
                            showToast("ERROR", "The existing password entered is invalid.")
    
    
                            // setLoading(false)
                        });
                }
    
                else {
                    showToast("ERROR", "Confirm password and new password is not matching.")
                }}
                else{
                    showToast("ERROR", "The new password cannot be same as the existing password.")
    
                }
            }
            else {
                showToast("ERROR", "Please enter a password with more than 6 and less than 15 characters")
            }
    
        }
        else{
            showToast("ERROR", "Please fill all the password fields")
        }

        
    }

    useEffect(() => {
        console.log(expiryDate, "expiryDate")
    }, [expiryDate])





    return (
        <>
            <div className="root">
            <Box
      sx={{
        display: 'flex',
        justifyContent:'start',
        alignItems:'center',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 400,
          height: 400,
        },
      }}
    >
<Paper>
<div className='d-flex justify-content-center align-items-center flex-column p-2 m-2' style={{}}>
<p style={{fontSize:'24px',color:'#CA0027',padding:'2px',margin:'2px',fontWeight:'700'}}>Change Password</p>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Enter Existing Password'
name="oldPassword"
value={oldPassword}
onChange={handleChange}

/>
</div>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Enter New Password'
    name="newPassword"
    value={newPassword}
    onChange={handleChange}
/>
</div>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Confirm Password'
    name="confirmpassword"
    value={confirmpassword}
    onChange={handleChange}
/>

</div>
<div className='p-2 m-2'> 
 <Button2 onClick={changePassword} className='change-password-submit-btn' variant='contained' color='error'>Submit</Button2>
</div>
</div>
</Paper>
</Box>
        
                        
            </div> 
        </>
    )
}
export default ChangePassword



