import { FormControl, TextField, InputAdornment, Dialog, DialogTitle, DialogContent, DialogActions, List, ListItem, ListItemText, Divider, CircularProgress, IconButton } from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useEffect, useParams, useState } from 'react';
import { Col, Row, Tooltip} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import graphic from 'assets/images/undraw_under_construction_46pa.svg'
import doc1 from 'assets/documents/Employement certificate by the Employeer.pdf'
import doc2 from 'assets/documents/Employement certificate by the grama panchayath.pdf'
import doc3 from 'assets/documents/Employement certificate by the labour inspector.pdf'
import doc4 from 'assets/documents/Employement certificate by the Registration trade union.pdf'
import validator from 'validator';
import mobile from 'assets/images/Phone 1-01.svg'
import otp from 'assets/images/Phone otp-01.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteUser,generateOtp, registerUser,  generateOtpRegistration, getFile, getUser, getUserRegistrationDetails, loginUser, loginAdmin, SERVER, updateUser } from 'store/actions/user.actions';
import { getTokenCookie, getTokenCookieAdmin, removeTokenCookie, saveTokenCookie, showToast } from 'utils/tools';
import closeIcon from 'assets/images/close-01.svg'
import axios from 'axios';
import Accident from 'components/mainScreens/schemesDetails/accidentScheme';
import { useLocation } from "react-router-dom";
import { set } from 'date-fns';
import Button from '@mui/material/Button';
import * as actions from '../../../store/index';
import cookie from 'react-cookies';



const RegistrationID = (props) => {

    // 6 month removal
    const [isVerified, setIsVerified] = useState(false);
    const [disablebtn, setdisablebtn] = useState(true);
    const [isActiveLogin, setIsActiveLogin] = useState(false);
    const [isActiveExist, setIsActiveExist] = useState(true);
    const [color, setColor] = useState(false);
    const [hashNumber, setHashNumber] = useState(null);
    const [userId,setUserId] = useState("");

    const [allValues, setAllValues] = React.useState({
        open: false,
        phone: "",
        otp: '',
        users: props.users,
        generate_otp: props.generate_otp,
        login_user: props.login_user,
        otp_sent: false,
        otp_sending: false,
        logging_in: false,
        phoneError: false,
        phoneErrorMessage: "",
        otpError: false,
        otpErrorMessage: "",
        openSnackbar: false,
        snackbarText: '',
        snackbarType: '',
        vertical: 'top',
        horizontal: 'right',
        // seconds: 60,
        resendReset: false,
        registration_id:"",
        registration_id_error:false,
        reference_id:"",
        reference_id_error:false,
    });

    // const testvalue = useParams();

    // console.log('incoming_props1: ',testvalue)
    const location = useLocation();

    // useEffect(() => {
    //     // result: '?query=abc'
    //    console.log("getschemeDataboards",location.state.state); // result: 'some_value'
    // }, [location]);

    console.log("accident",props.state);
    console.log('incoming_props: ',props)
    console.log("incoming_props.location: ", props.location.productdetailProps)
    console.log("allValues: ", allValues)
    // console.log("incoming_props: ", props.match.params.fromPage)
    console.log("schemesData",props.location.state);
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    useEffect(() => {
        removeTokenCookie()
        
        dispatch(updateUser(""))
        // dispatch(deleteUser())
        dispatch(getUser("delete"))
        dispatch(loginUser("delete"))

        dispatch(generateOtp("delete"))
        dispatch(getUserRegistrationDetails("delete"))
        dispatch(getFile("delete"))

        localStorage.clear();
        sessionStorage.clear();

        console.log("SERVER", SERVER)

        


        // const itemslocalStorage = { ...localStorage };
        // console.log("itemslocalStorage=" + JSON.stringify(Object.keys(itemslocalStorage),undefined,2))
    }, []);


    const changeHandler = e => {
        // console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

        if(e.target.name==="registration_id"){
            if(e.target.value.length < 50){
                setAllValues({
                    ...allValues,
                    [e.target.name] : e.target.value,
                    registration_id_error:false,
                    })
            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name] : e.target.value,
                    registration_id_error:true,
                    })
            }
            
        }


        if(e.target.name==="reference_id"){
            if(e.target.value.length < 20){
                setAllValues({
                    ...allValues,
                    [e.target.name] : e.target.value,
                    reference_id_error:false,
                    })
            }
            else{
                setAllValues({
                    ...allValues,
                    [e.target.name] : e.target.value,
                    reference_id_error:true,
                    })
            }
            
        }


        if (e.target.name === "otp") {
            setAllValues({ ...allValues, 
                otpError: false ,
                otpErrorMessage: "",})
        }
        if (e.target.name === "phone") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                setAllValues({
                    ...allValues,
                    phoneError: false,
                    phoneErrorMessage: "",
                    otp: '', 
                    otpError: false, 
                    otpErrorMessage: "",
                    otp_sent: false
                })
            else
                setAllValues({
                    ...allValues,
                    phoneError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                    otp: '', 
                    otpError: false, 
                    otpErrorMessage: "",
                    otp_sent: false
                })
        }

        setAllValues(prevValues => {
            return { ...prevValues, [e.target.name]: e.target.value }
        })
    }

    const handleDownloadListOpen = () => {
        setAllValues((prevState) => ({
            ...prevState,
            open: !prevState.open
        }));
        // setAllValues({...allValues, [e.target.name]: e.target.value})
    };

    const handleLoginClick = () => {
        props.history.push("/login")
        setIsActiveLogin(true)
        setIsActiveExist(false)
    }
    
    const handleExisitingClick = () => {
        props.history.push("/register-id")
        setIsActiveExist(true)
        setIsActiveLogin(false)
    }




    const handleClose = () => {
        setAllValues((prevState) => ({
            ...prevState,
            open: !prevState.open
        }));
    };

    useEffect(()=>{
                var mobile_no = allValues.phone
        console.log("Mobile_No",mobile_no);

        axios.get(`${SERVER}/user/${mobile_no}`).then((res)=>{
            console.log("USerRes:",res.data);
            console.log("USerRes2:",res.data.length);
            console.log("USerRes3:",res.data.data.length);
            setUserId(res.data.data.length)
        }).catch((err)=>{
                console.log(err);
        })
    },[allValues.phone])

    const getOtp = (event) => {
        event.preventDefault();
        // console.log("in getOtp-> phone:" + allValues.phone)
        // console.log("in getOtp-> users:" + JSON.stringify(users))
        


        console.log("USerID",userId);

        if(userId > 0){
            showToast("ERROR","Mobile Number already registered")
            setAllValues({
                ...allValues,
                phone:""
            })
        }
        else{
            if (!allValues.phoneError && allValues.phone.length === 10) {
            setAllValues(prevState => ({
                ...prevState,
                otp_sending: !prevState.otp_sending,
                generate_otp: ''
            }));

            let dataToLogin = { 
               
                    "mobile_code": "+91",
                    "phone_number": allValues.phone,
                    "board_id": 1
                
             };
            // console.log("dataToLogin: " + JSON.stringify(dataToLogin))

            var config = {
                method: 'post',
                url: SERVER + '/user/non-register-labour-send',
                data: dataToLogin
            };

            axios(config)
                .then(function (response) {
                    console.log("response", response)

                    setAllValues({
                        ...allValues,
                        otp_sending: false,
                        otp_sent: true,
                    })
                    setHashNumber(response.data.data.hash)
                    showToast("SUCCESS", "OTP has been sent Successfully.")

                })
                .catch(function (error) {
                    console.log(error);
                    showToast("ERROR", "There was an error connecting to the server.")

                });
        }
        else {
            setAllValues({
                ...allValues,
                phoneError: true,
                phoneErrorMessage: "Enter Correct Mobile Number!",
            })
        }

        }



        
    }



    const login_func = (event) => {
        event.preventDefault();

        // console.log("in login_func: hash: " + JSON.stringify(users.generate_otp.data.hash))
        // const hash = users.generate_otp.data.hash

        if ( !allValues.phoneError && allValues.phone.length === 10 && allValues.otp !== '') {
            

            let dataToLogin = {
                "otp": allValues.otp,
      
                
                "hash": hashNumber,
                "phone_number": allValues.phone
            }
            // console.log("dataToLogin: " + JSON.stringify(dataToLogin))
            // const res =axios.get('https://geolocation-db.com/json/')
            // console.log(res.data);
            
            var config = {
                method: 'post',
                url: SERVER + '/auth/verify-otp-nonuser',
                data: dataToLogin
            };

            axios(config)
                .then(function (response) {
                    console.log("response", response)
                    sessionStorage.setItem('mobileNumber', allValues.phone)
                    sessionStorage.setItem('registrationID', allValues.registration_id)
                    sessionStorage.setItem('referenceID', allValues.reference_id)
                    props.history.push("/register-address")

                   

                })
                .catch(function (error) {
                    console.log(error);
                    showToast("ERROR", "Please enter correct OTP.")

                });

        }
        else if (allValues.phoneError || allValues.phone.length !== 10) {
            setAllValues({
                ...allValues,
                phoneError: true,
                phoneErrorMessage: "Enter Correct Mobile Number!",
            })
        }
        else if (otp === '') {
            setAllValues({
                ...allValues,
                otpError: true,
                otpErrorMessage: "Please enter correct OTP!"
            })
        }
       
        

    }

    // const getOtp = (event) => {
    //     event.preventDefault();
    //     // console.log("in getOtp-> phone:" + allValues.phone)
    //     // console.log("in getOtp-> users:" + JSON.stringify(users))

    //     if (!allValues.phoneError && allValues.phone.length === 10) {
    //         setAllValues(prevState => ({
    //             ...prevState,
    //             otp_sending: !prevState.otp_sending,
    //             generate_otp: ''
    //         }));
    //         var boardId = 1

    //         let dataToLogin = { 
    //         "mobile_code": "+91",
    //         "phone_number": allValues.phone,
    //         "board_id": boardId
    //         }
    //         // console.log("dataToLogin: " + JSON.stringify(dataToLogin))

    //         try {

    //             const response =  axios.post(`${SERVER}/user/register-labour-send`, dataToLogin);
    //             console.log("response:" + JSON.stringify(response.data));
            



            

    //         } catch (error) {
    //             console.log(JSON.stringify(error));
    //             console.log(JSON.stringify(error.response));
    //             showToast("ERROR", "This number has already been registered.")
               
    //         }
    //     }
    //     else {
    //         setAllValues({
    //             ...allValues,
    //             phoneError: true,
    //             phoneErrorMessage: "Enter Correct Mobile Number!",
    //         })
    //     }
    // }

    // const getOtp = (event) => {
    //     event.preventDefault();
    //     console.log("in getOtp-> phone:" + allValues.phone)
    //     console.log("in getOtp-> users:" + JSON.stringify(users, undefined,2))

    //     if (users !== undefined && users !== null &&!allValues.phoneError && allValues.phone.length === 10) {
    //         setAllValues(prevState => ({
    //             ...prevState,
    //             otp_sending: !prevState.otp_sending,
    //             generate_otp_registration: ''
    //         }));

    //         var boardId = 1
    //         // var boardId = null

    //         // users.get_board.map(
    //         //     obj => {
    //         //         // console.log("obj.code:"+JSON.stringify(obj.code))
    //         //         if (obj.code === "KBOCWWB") {
    //         //             boardId = obj.id
    //         //         }
    //         //     }
    //         // )
    //         // console.log("board_id=" + boardId)

    //         let dataToDispatch = {
    //             "mobile_code": "+91",
    //             "phone_number": allValues.phone,
    //             "board_id": boardId
    //         }

    //         console.log("dataToDispatch: " + JSON.stringify(dataToDispatch))

    //         dispatch(generateOtpRegistration(dataToDispatch))
    //     }
    //     else {
    //         setAllValues({ ...allValues, phoneError: true })
    //     }
    // }


    useEffect(() => {
        // console.log("users.generate_otp_registration:"+JSON.stringify(users.generate_otp_registration));
        // console.log("users.generate_otp_registration.message:" + users.generate_otp_registration.message)
        if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null && users.generate_otp_registration.message === 'Success') {
            console.log("OTP generated Successfully");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: true,
            })
            showToast("SUCCESS", "OTP Sent Successfully!")
            users.generate_otp_registration.message = null
        } else if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null && users.generate_otp_registration.message !== undefined && users.generate_otp_registration.message !== '') {
            console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: users.generate_otp_registration.message,
            })
            showToast("ERROR", users.generate_otp_registration.message)
            users.generate_otp_registration.message = null
        }
        else if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null) {
            console.log("In else: error");
            console.log("users.generate_otp_registration:" + users.generate_otp_registration);
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: "Error Sending OTP",
            })
            showToast("ERROR", "This number has already been registered.")

            users.generate_otp_registration = null
        }

    }, [users.generate_otp_registration])


    const registration_func = (event) => {
        event.preventDefault();

        if (users.generate_otp_registration !== null) {
            console.log("users.register_user: " + JSON.stringify(users.register_user))
            console.log("in registration_func: hash: " + JSON.stringify(users.generate_otp_registration.data.hash))

            if (!allValues.phoneError && allValues.phone.length === 10 && allValues.otp !== '') {
                setAllValues({
                    ...allValues,
                    submitting: !allValues.submitting
                })

                var login_user_id = 1
                
       

                    let dataToRegister = {
                        "hash": users.generate_otp_registration.data.hash,
                        "phone_number": users.generate_otp_registration.data.phone_number,
                        "mobile_code": users.generate_otp_registration.data.mobile_code,
                        "board_id": users.generate_otp_registration.data.board_id,
                        "otp": allValues.otp,
                        "login_user_id": login_user_id
                    }
                    console.log("dataToRegister: " + JSON.stringify(dataToRegister))
                    dispatch(registerUser(dataToRegister))
                

                
            }
            else if (allValues.phoneError || allValues.phone.length !== 10) {
                setAllValues({
                    ...allValues,
                    phoneError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                })
            }
            else if (otp === '') {
                setAllValues({
                    ...allValues,
                    submitting: false,
                    otpError: true,
                    otpErrorMessage: "Enter Valid OTP!"
                })
            }
            else if (users.generate_otp_registration.data.hash === undefined) {
                setAllValues({
                    ...allValues,
                    submitting: false,
                    otpError: true,
                    otpErrorMessage: "Enter Valid OTP!"
                })
            }
            else {
                setAllValues({
                    ...allValues,
                    submitting: false,
                    phoneError: true,
                    otpError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                })
            }
        }
    }

    



    const handleVerify = (ev) => {
        if((allValues.registration_id !=="" && allValues.registration_id.length !== 0)&&
          (allValues.reference_id !=="" && allValues.reference_id.length !== 0))
          {

            console.log("in_Verify")

            var data = JSON.stringify({
                "registration_number": allValues.registration_id,
                "application_reference_number": allValues.reference_id
            });

            var config = {
                method: 'post',
                url: SERVER + '/seva_sindhu/check_user_in_seva_sindhu',
                data: data
            };

            axios(config)
                .then(function (response) {
                 console.log("response_reference", response)
                 if(response.data.data[0].error){
                    showToast("ERROR", response.data.data[0].error)       
                }
                else{
                    if(response.data.data.length>0){
                        setIsVerified(true)
                        setdisablebtn(false)
                        showToast("SUCCESS", "Successfully Verified")
                        sessionStorage.setItem("TempID",response.data.data[0].id)
                        sessionStorage.setItem("LabourName",response.data.data[0].applicantname)
                        // sessionStorage.setItem("LabourName","MURALI")
                     }
                     else{
                        showToast("ERROR", "Details provided are not found in the Database. Kindly visit your jurisdiction LI for New Registration")
    
                     }
                }

                })
                .catch(function (error) {
                    console.log(error);
                    showToast("ERROR", "There was an error connecting to the server.")

                });
            
          }
          else{
            showToast("ERROR", "Please fill all the mandatory fields")
          }
    }


    // useEffect(() => {
    //     // result: '?query=abc'
    //     try{
    //         if(location.path&&location.state){
    //             props.history.push("/scheme-form/",location.state,"_id=10")
    //         }
    //     }
    //     catch{

    //     }
    //    console.log("getschemeDataboards",location.state); // result: 'some_value'
    // }, [location]);

    useEffect(() => {
        // console.log("users.generate_otp.message:"+users.generate_otp.message)
        try{
            
        if (users.generate_otp !== null && users.generate_otp.message !== null && users.generate_otp.message === 'Success') {
            // console.log("OTP generated Successfully");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: true,
            })
            showToast("SUCCESS", "OTP Sent Successfully!")
            users.generate_otp.message = null
        } else if (users.generate_otp !== null && users.generate_otp.message !== null && users.generate_otp.message !== undefined && users.generate_otp.message !== '') {
            // console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: users.generate_otp.message,
            })
            showToast("ERROR", users.generate_otp.message)
            users.generate_otp = null
        }
        else if (users.generate_otp !== null && 
            users.generate_otp.success === false && 
            users.generate_otp.error !== undefined && 
            users.generate_otp.error !== '') {
            // console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
            })
            showToast("ERROR", users.generate_otp.error)
            showToast("ERROR", "Please try after 30 minutes")
            users.generate_otp = null
        }
        else if (users.generate_otp !== null) {
            // console.log("In else: error");
            console.log("users.generate_otp:" + JSON.stringify(users.generate_otp));
            // setAllValues({
            //     ...allValues,
            //     otp_sending: false,
            //     otp_sent: false,
            //     phoneError: true,
            //     phoneErrorMessage: users.generate_otp,
            // })
            // showToast("ERROR", "Error Sending OTP")
            users.generate_otp = null
        }
        }
        catch(e){
            console.error(e)
        }

    }, [users.generate_otp])
    
    useEffect(() => {
        try{
        if(users.user !== undefined && users.user !== null && users.user !== ""){
            console.log("users.user.board_id:"+users.user.board_id)
            if(users.user.board_id === 1){
                
                sessionStorage.setItem('registrationID', allValues.registration_id)
                sessionStorage.setItem('referenceID', allValues.reference_id)


                props.history.push("/register-address");

            }
            
           
           
        }
        }
        catch(e){
            console.error(e)
        }
    }, [users.user])




    const [seconds, setSeconds] = React.useState(60);
    const [resendReset, setResendReset] = React.useState(false);
    const ResendOTP = () => {
        if (seconds > 0) {
            setTimeout(() => 
            setSeconds(seconds - 1)
            // setAllValues({
            //     ...allValues,
            //     seconds: allValues.seconds - 1
            // })
            , 1000);
            return ( 
                // <Link to="#" disabled>
                    <p className="generate-otp2 mb-5">
                    Resend OTP after {seconds} seconds
                    </p>
                // </Link>
            )
        } else {
            setResendReset(true)
            setSeconds(60)
            setAllValues({
                ...allValues,
                otp_sent: false,
                // seconds: 60,
                // resendReset: true
            })
            return null
        }
    }

    return (
        <Row className="login-div">
            <Col xs={12} lg={6} xl={6} xxl={6}>
                <Row>
                    <Col sm={12} >
                        <div className="logo-div d-flex justify-content-start text-center">
                            
                            <img id="logo" src={logo} alt="..." className="logo-img-admin1 d-flex justify-content-start"/>
                           <div className = "logo-head d-flex justify-content-start text-center">
                            <h1 className="logo-text d-flex justify-content-start text-center" style={{color:'#CA0027', fontSize:'25px'}}>
                            ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ
                            </h1>
                            <h1 className="logo-text d-flex justify-content-start text-center" style={{color:'#CA0027', fontSize:'25px'}}>
                            Labour Department, Government of Karnataka
                                {/* Karnataka Building & Other Construction <br />Workers Welfare Board (KBOCWWB) */}
                                </h1>
                                </div>
                        </div>
                    </Col>
                    <Col sm={12}
                        className="login-img-col"
                    >
                        <img className="login-img" alt="..." src={graphic} />

                    </Col>
                    <Col sm={12} className="copyright">
                        
                        {/* <p>Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB), Government of Karnataka</p> */}
                    </Col>
                </Row>
            </Col>

            <Col xs={12} lg={6} className="login-form">
                <div className="login-form-container">
                    {/* <h2 className="login-form-head" style={{color:'#535353'}}>ಲಾಗಿನ್ / LOGIN </h2> */}
                    
                    {/* 6 month removal */}
                    
                    <div className='div-bar text-center row'>
                    <Button 
                    variant='contained'
                    className='col w-50 btn-switch text-capitalize text-center' 
                    size='lg' 
                    onClick={handleLoginClick}
                    style={{backgroundColor: isActiveLogin ? '#CA0027' : '#dee2e6',color:isActiveExist ? 'black' : ''}}
                    
                    >Login With Mobile Number</Button>
                    <Button 
                    
                    className='col w-50 btn-switch text-capitalize text-center' 
                    variant='contained'
                    size='lg' 
                    onClick={handleExisitingClick}
                    style={{backgroundColor: isActiveExist ? '#CA0027' : '#dee2e6',color:isActiveExist ? '' : 'black'}}>
                    Already Registered Construction Worker</Button>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                        <p className='w-100 text-center note-new-reg' style={{color:'#CA0027'}}>Note 1: "If you are already registered user and linked your Mobile number,kindly use 'Login with 
                        Mobile Number' tab above for logging in"</p>
                        <p className='w-100 text-center note-new-reg' style={{color:'#CA0027'}}>Note 2: "For E-Karmika Labour Registration, Reference Number is same as Registration Number"</p>
                    </div>
                    
                <div className='row d-flex flex-column'>
                    <p className="login-form-head-sub2 w-100 mt-5 ms-2 col" style={{fontSize:'21px'}}>Registration Number* / ನೋಂದಣಿ ಸಂಖ್ಯೆ*</p>
                    <div className='col mt-1 mb-4 ms-5 w-100 newReg'>
    
                    <TextField 
                    type={"text"} 
                    className='w-100' 
                    variant='outlined'
                    name='registration_id'
                    value={allValues.registration_id}
                    onChange={changeHandler}
                    disabled = {isVerified ? true : false}
                    // error={allValues.registration_id_error}
                    placeholder=""
                    helperText={allValues.registration_id_error ? "Please enter numbers only" : ""}
        
                    InputProps={{
                    startAdornment:
                    <InputAdornment 
                    position="start">
                    </InputAdornment>
                                }}
                    ></TextField>
            </div>
            <p className='d-flex ms-5 justify-content-end sub-reg-req'>As per Labour Card</p>        
                                
        </div>

        <div className='row d-flex flex-column'>
        <p className="login-form-head-sub2 ms-2 w-100 mt-5 col-12" style={{fontSize:'21px'}}>Reference Number* / ಉಲ್ಲೇಖ ಸಂಖ್ಯೆ*</p>
        <div className='col mt-1 mb-4 w-100 ms-5 align-self-center newReg'>
    
        <TextField 
        type={"text"} 
        className='w-100' 
        variant='outlined'
        name='reference_id'
        disabled = {isVerified ? true : false}
        value={allValues.reference_id}
        onChange={changeHandler}
        // error={allValues.reference_id_error}
        placeholder=""
        helperText={allValues.reference_id_error ? "Please enter numbers only" : ""}
        
        InputProps={{
        startAdornment:
        <InputAdornment position="start">
        </InputAdornment>
        }}>
    </TextField>
        </div>  
        <p className='d-flex ms-5 justify-content-end sub-reg-req'>As per Labour Card</p> 
        { disablebtn ?
        <div className='row ms-4 d-flex justify-content-end' style={{whiteSpace:'nowrap'}}>
        
        <p className="login-button w-25 bottom-btn" onClick={handleVerify}>
                                        {"Verify"}
        </p> 
        </div>
        : null
        }
        </div>

        {  isVerified 
        ?        
        <div className='row d-flex flex-column'>
        <p className="login-form-head-sub2 ms-2 w-100 mt-5 col" style={{fontSize:'21px'}}>Mobile Number* / ಮೊಬೈಲ್ ಸಂಖ್ಯೆ*</p>
        <div className='col mt-1 mb-4 w-100 ms-5 align-self-center newReg'>
    
        <TextField 
        type={"text"} 
        className='w-100' 
        variant='outlined'
        name='phone'
        value={allValues.phone}
        onChange={changeHandler}
        error={allValues.phoneError}
        placeholder=""
        helperText={allValues.phoneError ? "Please enter correct Phone Number" : ""}
                                
        InputProps={{
        startAdornment:
        <InputAdornment position="start">
        </InputAdornment>
        }}>
    </TextField>
        </div>  
         
        <div className='row'>
      

                                {/* (<Link to="#" onClick={(event) => getOtp(event)}>
                                        <p className="generate-otp">
                                        {allValues.otp_sent ? "Resend OTP":"Generate OTP"}
                                        </p>
                                </Link>)
                                <br/> */}

                                {
                                !allValues.otp_sent ?
                                <div className='d-flex justify-content-end align-items-end ms-5'>
                                    <Link to="#" onClick={(event) => getOtp(event)}>
                                        <p className="login-button bottom-btn otp-btn">
                                        {resendReset ? "Resend new OTP  ":"Generate OTP"}
                                        </p>
                                    </Link>
                                    </div>
                                    :
                                    <div className='d-flex justify-content-end align-items-end ms-4'>
                                    <ResendOTP />
                                    </div>
                                    }
                           
        </div>
        </div>
        : null
        }
        
        
        { isVerified ? 
        <div className='row otp-div d-flex flex-column'>
        <p className="login-form-head-sub2 w-100 ms-2 mt-5 col" 
        style={{fontSize:'21px'}}>Enter OTP* / ಒಟಿಪಿ ನಮೂದಿಸಿ*</p>
        <div className='col mt-1 mb-4 w-100 ms-5 align-self-center newReg'>
    
        <TextField 
        type={"password"}
        className='w-100' 
        variant='outlined'
        name='otp'
        value={allValues.otp}
        onChange={changeHandler}
        error={allValues.otpError}
        placeholder=""
        helperText={allValues.otpError ? "Please enter correct OTP!" : ""}
                                
        InputProps={{
        startAdornment:
        <InputAdornment position="start">
        </InputAdornment>
        }}>
    </TextField>
        </div>  
        <div className='d-flex justify-content-end align-items-end ms-4'>
        
                                <Link to="#" onClick={(event) => login_func(event)}>
                                    <p className="login-button bottom-btn" type="submit">
                                         Submit
                                    </p>
                                </Link>
                                     
        
        </div>
        </div>
        : null
        }
                   
                   
                   
                    

                    
                    

                    {/* <div className="cert-template-div" style={{whiteSpace:'nowrap'}} onClick={handleDownloadListOpen}>
                        Click here to download Employment Certificate Template <br/>
                        ಉದ್ಯೋಗ ಪ್ರಮಾಣಪತ್ರ ಟೆಂಪ್ಲೇಟ್ ಡೌನ್‌ಲೋಡ್ ಮಾಡಲು ಇಲ್ಲಿ ಕ್ಲಿಕ್ ಮಾಡಿ
                </div> */}

                {/* <div className="cert-template-div" onClick={() => {props.history.push("/privacy-policy")}}>
                        Privacy Policy
                </div>
                <div className="cert-template-div" onClick={() => {props.history.push("/terms&conditions")}}>
                    Terms and Conditions
                </div> */}

                    <Dialog
                        disableBackdropClick
                        disableEscapeKeyDown
                        maxWidth="xs"
                        aria-labelledby="confirmation-dialog-title"
                        open={allValues.open}
                        onClose={handleClose}
                    >
                        {/* <DialogTitle className="download-dialog-title">Download Employee Certificate</DialogTitle> */}
                        <DialogTitle className="download-dialog-title">
                            Download Employee Certificate
                            {handleClose ? (
                                <IconButton aria-label="close" className="dwnldclosebtn" onClick={handleClose}>
                                {/* <CloseIcon /> */}
                                <img alt="..." src={closeIcon} style={{width: "30px", height: "30px"}}/>
                                </IconButton>
                            ) : null}
                        </DialogTitle>
                        <DialogContent className="download-dialog">
                            <List component="nav" aria-label="mailbox folders">
                                <a href={doc1} download="Employment certificate by the Employeer" target='_blank' rel="noreferrer">
                                    <ListItem button >
                                        <ListItemText primary="Employment certificate by the Employer" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc2} download="Employment certificate by the Grama Panchayat" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Grama Panchayat" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc3} download="Employment certificate by the Labour Inspector" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Labour Inspector" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                                <Divider light />
                                <a href={doc4} download="Employment certificate by the Registration Trade Union" target='_blank' rel="noreferrer">
                                    <ListItem button>
                                        <ListItemText primary="Employment certificate by the Registration Trade Union" />
                                        <GetApp />
                                    </ListItem>
                                </a>
                            </List>
                        </DialogContent>
                        <DialogActions className="download-dialog-button">
                            <Button autoFocus color="primary" onClick={handleClose} >
                                Back
                        </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            </Col>

            <Col sm={12} className="copyright">
                    {/* <Link to="/admin"><p>Admin</p></Link> */}
                    </Col>

            {/* <Snackbar
                anchorOrigin={{ vertical: allValues.vertical, horizontal: allValues.horizontal }}
                open={allValues.openSnackbar}
                autoHideDuration={4000}
                onClose={handleCloseSnackbar}>
                <MuiAlert
                    elevation={6}
                    variant="filled"
                    onClose={handleCloseSnackbar}
                    severity={allValues.snackbarType} >
                    {allValues.snackbarText}
                </MuiAlert>
            </Snackbar> */}
        </Row>
    )

}

export default RegistrationID;