import React, { useEffect, useState } from 'react';
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItemText,
  Tooltip,
  Checkbox,
  TextareaAutosize,
  Popover,
  Typography,
  Backdrop,
  CircularProgress,
  makeStyles,
  ListItemIcon,
} from '@material-ui/core';
import {
  PhoneAndroid,
  CameraAlt,
  Visibility,
  CheckBoxRounded,
  CheckBoxOutlineBlankRounded,
  CheckCircle,
} from '@material-ui/icons';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import aadhar from 'assets/images/Aadhar black-01.svg';
import personal from 'assets/images/f1-01.svg';
import pan from 'assets/images/PAN Black-01.svg';
import male from 'assets/images/Mask Group 1.svg';
import female from 'assets/images/Mask Group 2.svg';
import moment from 'moment';
import Moment from 'react-moment';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import address from 'assets/images/f2-01.svg';
import { Required, getTokenCookie } from 'utils/tools';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const AddressDetails = ({ data }) => {
  const { labourUserID } = useParams();
  const [permanentAddress, setPermanentAddress] = useState([]);

  useEffect(() => {
    const payload = JSON.stringify({
      labour_user_id: labourUserID,
    });

    var config = {
      method: 'post',
      url: SERVER + '/user/get_ad_details',
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payload,
    };

    axios(config)
      .then(function (response) {
        setPermanentAddress(response.data.data[0]);
      })
      .catch(function (error) {
        console.error('getUSerID: ' + JSON.stringify(error));
        // setLoading(false);
      });
  }, []);

  return (
    <>
      <Row className="mt-5 mb-0 final all-details-subtitle-row">
        <Col xs={12} md={12} className="profile-title">
          <img alt="..." src={address} />
          <h1>Address Details</h1>
        </Col>
      </Row>

      <Row className="form-row-final with-border mt-0">
        <Col xs={12} md={12} className="profile-title">
          <h2>Residential Address</h2>
        </Col>
        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p className="mb-0"> Type of Residence </p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.address_type}
              disabled
            />
          </FormControl>

          <p className="mt-3 mb-0">City Name </p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.panhayat_city_town}
              disabled
            />
          </FormControl>

          <p className="mt-3 mb-0">Village Name </p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.panhayat_city_town}
              disabled
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p className="mt-3 mb-0">House/Building Number </p>

          {/* <p className="mb-0" title="House/Building Number" /> */}
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.door_no}
              disabled
            />
          </FormControl>

          <p className="mt-3 mb-0">District</p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.district}
              disabled
            />
          </FormControl>

          <p className="mt-3 mb-0">Taluk</p>
          <FormControl fullWidth>
            <TextField variant="outlined" value={data && data.taluk} disabled />
          </FormControl>
        </Col>

        <Col xs={12} md={4} className="final-personal-col-1 px-3">
          <p className="mb-0">Street/Road Name </p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.street_name}
              disabled
            />
          </FormControl>

          <p className="mt-3 mb-0">Landmark</p>
          <FormControl fullWidth>
            <TextField
              variant="outlined"
              value={data && data.land_mark}
              disabled
            />
          </FormControl>

          <Row>
            <Col xs={6} className="final-personal-col-1 ">
              <p className="mt-3 mb-0">State</p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={data && data.state}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={6} className="final-personal-col-1 ">
              <p className="mt-3 mb-0">Pincode</p>
              <FormControl fullWidth>
                <TextField
                  variant="outlined"
                  value={data && data.pin_code}
                  disabled
                />
              </FormControl>
            </Col>
          </Row>
        </Col>

        <>
          <Col xs={12} md={12} className="profile-title permanentAddress">
            <h2>Permanent Address</h2>
          </Col>
          <Col xs={12} md={4} className="final-personal-col-1 px-3">
            {/* <p className="mb-0" title="Type of Residence" />
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined"
                                                    value={
                                                        users.getUserRegistrationDetails !== undefined &&
                                                            users.getUserRegistrationDetails !== null &&
                                                            users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.address_details !== undefined && users.getUserRegistrationDetails.address_details.length > 0
                                                            ? users.getUserRegistrationDetails.address_details[1].address_base_type : ""}

                                                    disabled
                                                />
                                            </FormControl> */}

            <p className="mt-3 mb-0">City Name</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.village_city_town}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">Village Name </p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.sub_district}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">State</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.state}
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4} className="final-personal-col-1 px-3">
            <Required className="mb-0" title="House/Building Number*" />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.house_no}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">District</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.district}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">Taluk</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.sub_district}
                disabled
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4} className="final-personal-col-1 px-3">
            <Required className="mb-0" title="Street/Road Name" />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.street}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">Landmark</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.landmark}
                disabled
              />
            </FormControl>

            <p className="mt-3 mb-0">Pincode</p>
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                value={permanentAddress && permanentAddress.pincode}
                disabled
              />
            </FormControl>
          </Col>
        </>
      </Row>
    </>
  );
};

export default AddressDetails;
