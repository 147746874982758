import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { useDispatch, useSelector } from 'react-redux';
import {
  get_schemes,
  SERVER,
  setLocaleWithFallback,
  getFile,
} from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
import checkGreen from 'assets/images/Tick-01.png';
import schemeAppliedStatusGroupIcon from 'assets/images/schemeAppliedStatusGroupIcon.svg';
import schemeApprovedStatusSubtraction11 from 'assets/images/schemeApprovedStatusSubtraction11.svg';
import schemependingStatusPendingSVG from 'assets/images/schemependingStatusPendingSVG.svg';
import schemeclosestatus from 'assets/images/schemeclosestatus.svg';
import schemeSubtractionstatus from 'assets/images/schemeSubtractionstatus.svg';
import calendar from 'assets/images/calendar.svg';
import Moment from 'react-moment';
import PendingSmallStatusIcon from 'assets/images/PendingSmallStatusIcon.svg';
import time from 'assets/images/time.svg';
import closeIcon from 'assets/images/closeIcon.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import axios from 'axios';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';
import logout from 'assets/images/Logout.svg';
import { Tooltip } from '@material-ui/core';
import { CameraAlt } from '@material-ui/icons';

import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import NoImage from 'assets/images/No signature available-01-01.svg';

import language from 'assets/images/translate (1).svg';

function SchemeStatus(props) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    searchValueError: false,
    submitting: false,
    check_user_availed_schemes: null,
    schemes_by_labor: null,
  });
  const [schemeStatusData, SetSchemeStatusData] = useState([]);
  const [pendingArray, SetPendingArray] = useState([]);
  const [approvedArray, SetApprovedArray] = useState([]);
  const [rejectedArray, SetRejectedArray] = useState([]);
  const [approvedOrrejectedDate, setapprovedOrrejectedDate] = useState(null);
  const [printDocumentCheck, setprintDocumentCheck] = useState(false);
  const [printDocumentCheck1, setprintDocumentCheck1] = useState(false);
  const [firstLevelApproval, setFirstLevelApproval] = useState(null);
  const [secondLevelApproval, setSecondLevelApproval] = useState(null);

  const [firstLevelApprovalDescription, setFirstLevelApprovalDescription] =
    useState(null);
  const [secondLevelApprovalDescription, setSecondLevelApprovalDescription] =
    useState(null);
  const [secondLevelRejectRemark, setSecondLevelRejectRemark] = useState(null);
  const [secondLevelRejectRemarks, setSecondLevelRejectRemarks] =
    useState(null);
  const [secondLevelApprovalAddress, setsecondLevelApprovalAddress] =
    useState(null);
  const [secondLevelAddress, setsecondLevelAddress] = useState(null);

  const [base64Signature, setBase64Signature] = useState(null);
  const [base64SignatureImg, setBase64SignatureImg] = useState(null);

  const [printSanction, setPrintSanction] = useState(false);

  const [schemename, setschemename] = React.useState('');
  const [appNo, setappNo] = React.useState('');
  const [dateApp, setdateApp] = React.useState('');

  const [schemename1, setschemename1] = React.useState('');
  const [appNo1, setappNo1] = React.useState('');
  const [dateApp1, setdateApp1] = React.useState('');

  const [approvedData, setApprovedData] = React.useState(null);
  const [sanctioned_Amount, setSanctioned_Amount] = React.useState(null);
  const [sanctioned_Amount1, setSanctioned_Amount1] = React.useState(null);

  const [signPic, setsignPic] = React.useState('');
  const [signPhoto, setsignPhoto] = React.useState(
    sessionStorage.getItem('signPic'),
  );

  const [finalstatus, setfinalstatus] = React.useState(false);
  const [statusOrder, setStatusOrder] = React.useState(null);
  const [isApproved, setIsApproved] = React.useState(false);

  const [sakalaNumber, setSakalaNumber] = useState(null);

  const [printApproved, setPrintApproved] = React.useState(false);

  const [appplicantsName, setApplicantsName] = useState(null);
  const [schemesAvailed, setSchemesAvailed] = useState(null);

  const [accidentAvailed, setAccidentAvailed] = useState(null);

  const handleChange1 = (e) => {
    console.log('kkkkk', e.target.value);
    if (e.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === 'language')
      dispatch(setLocaleWithFallback(e.target.value));
  };

  useEffect(() => {
    if (users.user !== undefined && users.user !== null) {
      dispatch(get_schemes(users.user.board_id));

      var data = JSON.stringify({
        board_id: users.user.board_id,
        labour_user_id: users.user.id,
      });

      var config = {
        method: 'post',
        url: SERVER + '/schemes/get_schemes_by_labor',
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            'scheme_status: ' +
              JSON.stringify(response.data.data, undefined, 2),
          );
          console.log(response.data.data);
          SetSchemeStatusData(response.data.data);
          setAllValues({
            ...allValues,
            schemes_by_labor: response.data.data,
          });
          const pending = response.data.data.filter(
            (x) => x.status === 'Pending',
          );
          console.log('pendingList', pending);

          SetPendingArray(pending);
          const approved = response.data.data.filter(
            (x) => x.status === 'Approved',
          );
          console.log('ApprovedList', approved);
          SetApprovedArray(approved);
          const rejected = response.data.data.filter(
            (x) => x.status === 'Rejected',
          );
          SetRejectedArray(rejected);
        })
        .catch(function (error) {
          console.log(error);
        });
      const appno1 = '';
      setappNo(appno1);
      setappNo1(appno1);
    }
    setsignPic(sessionStorage.getItem('signPic'));
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      labour_user_id: users && users.user.id,
      scheme_id: 0,
      check_for: 'all',
    };
    axios
      .post(`${SERVER}/schemes/check_user_availed_schemes`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setSchemesAvailed(data.data);
        setAccidentAvailed(data.data.self);

        // const selfData = data.data.self;
      })
      .catch((err) => {
        if (err) {
          showToast('ERROR', 'Something went wrong');
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  const handleChange = (event) => {
    console.log(event.target);
    console.log(event.target.value);
    setAllValues({
      ...allValues,

      [event.target.name]: event.target.value,
    });

    if (event.target.name === 'language')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const [AckLetterImg, setAckLetterImg] = React.useState('');

  // useEffect(() => {
  //     if(sanctioned_Amount !== null){
  //         setSanctioned_Amount(sanctioned_Amount)
  //         console.log("sanctioned_Amount1",sanctioned_Amount)
  //     }
  // },[sanctioned_Amount])

  // useEffect(() => {
  //     console.log("1111", JSON.stringify(schemename))
  //     console.log("FSS", finalstatus)
  // }, []);

  useEffect(() => {
    if (signPic !== undefined && signPic !== '' && signPic !== null) {
      sessionStorage.setItem('signPic', signPic);
      setsignPhoto(signPic);
    }
  }, [signPic]);

  useEffect(() => {
    setsignPic(sessionStorage.getItem('signPic'));
  }, [sessionStorage.getItem('signPic')]);

  useEffect(() => {
    if (
      secondLevelRejectRemark !== null &&
      secondLevelRejectRemark !== undefined
    ) {
      setSecondLevelRejectRemarks(secondLevelRejectRemark);
    }
  }, [secondLevelRejectRemark]);
  useEffect(() => {
    if (
      secondLevelApprovalAddress !== null &&
      secondLevelApprovalAddress !== undefined
    ) {
      setsecondLevelAddress(secondLevelApprovalAddress);
    }
  }, [secondLevelApprovalAddress]);

  useEffect(() => {
    if (base64Signature !== null && base64Signature !== undefined) {
      setBase64SignatureImg(base64Signature);
    }
  }, [signPic]);

  useEffect(() => {
    if (
      users.getFile !== null &&
      users.getFile !== undefined &&
      users.getFile.fileName !== undefined
    ) {
      if (users.getFile.fileName === 'profilePic') {
        sessionStorage.setItem('profilePic', users.getFile.data);
      }

      if (users.getFile.fileName === 'signPic') {
        sessionStorage.setItem('signPic', users.getFile.data);
        setsignPic(users.getFile.data);
      }
    }
  }, [users.getFile]);

  useEffect(() => {
    if (signPhoto !== '' && signPhoto !== null) {
      setsignPhoto(signPhoto);
    }
  }, [signPhoto]);

  // useEffect(() => {
  //     // var sancAmt = null
  //     if(!sanctioned_Amount && sanctioned_Amount !== null){
  //         console.log("sanctioned_Amount", sanctioned_Amount)
  //         // sancAmt = sanctioned_Amount
  //         setSanctioned_Amount(sanctioned_Amount)
  //     }
  // },[sanctioned_Amount])

  useEffect(() => {
    console.log('schemename', schemename);
    console.log('appNo', appNo);
    console.log('dateApp', dateApp);
    console.log('secondLevelRejectRemarks', secondLevelRejectRemarks);
    console.log('secondLevelAddress', secondLevelAddress);
    console.log('base64SignatureImg', base64SignatureImg);
    console.log('signPhoto', signPhoto);

    if (schemename.includes('Shrama') || schemename.includes('BMTC')) {
      if (
        schemename !== '' &&
        appNo !== '' &&
        dateApp !== '' &&
        secondLevelAddress !== null
      ) {
        // if (schemename !== "" && appNo !== "" && dateApp !== "" ){
        console.log('signPhoto1', signPhoto);
        if (approvedOrrejectedDate !== null) {
          if (isApproved) {
            setPrintSanction(true);
            if (printSanction) {
              printDocumentOrder();
            }

            setapprovedOrrejectedDate(null);
            // setsecondLevelAddress(null)
            // setBase64SignatureImg(null)
            sessionStorage.removeItem('signPic');
          } else if (secondLevelRejectRemarks !== null) {
            if (base64SignatureImg !== null) {
              console.log('rejectOrder');
              setPrintSanction(true);
              if (printSanction) {
                printDocumentOrderReject();
              }

              setSecondLevelRejectRemarks(null);
              // setsecondLevelAddress(null)
              // setBase64SignatureImg(null)
              // setSecondLevelRejectRemarks(null)
              sessionStorage.removeItem('signPic');
            } else {
              // setPrintSanction(true)
              // if(printSanction)

              printDocumentOrderReject();

              setSecondLevelRejectRemarks(null);
            }
            // setsignPhoto("")
          }
        }
      }
    } else {
      if (
        schemename !== '' &&
        appNo !== '' &&
        dateApp !== '' &&
        secondLevelAddress !== null
      ) {
        // if (schemename !== "" && appNo !== "" && dateApp !== "" ){
        console.log('signPhoto2', secondLevelAddress);
        if (approvedOrrejectedDate !== null && base64SignatureImg !== null) {
          if (isApproved) {
            console.log('Approveprint');
            setPrintSanction(true);
            if (printSanction) {
              printDocumentOrder();
            }

            setPrintApproved(true);
            // setapprovedOrrejectedDate(null)
            // setsecondLevelAddress(null)
            setBase64SignatureImg(null);
          } else if (
            secondLevelRejectRemarks !== null &&
            base64SignatureImg !== null
          ) {
            if (base64SignatureImg !== null) {
              // setPrintSanction(true)
              // if(printSanction)

              printDocumentOrderReject();

              setSecondLevelRejectRemarks(null);
              // setsecondLevelAddress(null)
              setBase64SignatureImg(null);
            } else {
              // setPrintSanction(true)
              // if(printSanction)
              //
              printDocumentOrderReject();
              //
            }

            // setsignPhoto("")
          }
        }
      }
    }
  });

  useEffect(() => {
    if (schemename1 !== '' && appNo1 !== '' && dateApp1 !== '') {
      console.log('approvedOrrejectedDate2', approvedOrrejectedDate);
      if (approvedOrrejectedDate === null) {
        setPrintSanction(true);
        if (printSanction) {
          printDocument();
        }

        setapprovedOrrejectedDate(null);
      }
    }
  });

  useEffect(() => {
    if (approvedData !== null) {
      console.log('approvedData11', approvedData[0].sanctioned_Amount);

      setSanctioned_Amount(approvedData[0].sanctioned_Amount);
      console.log('sanctioned_Amount', approvedData[0].sanctioned_Amount);

      setfinalstatus(approvedData[0].approval_status);
      console.log(approvedData[0].approval_status, 'schemestatus');
      if (approvedData[0] !== undefined) {
        dispatch(getFile(approvedData[0].signature_upload_id, 'signPic'));
      }
    }
  }, [approvedData]);

  useEffect(() => {
    if (firstLevelApproval !== null && firstLevelApproval !== undefined) {
      console.log('firstLevelApproval', firstLevelApproval[0].description);
      setFirstLevelApprovalDescription(firstLevelApproval[0].description);
    }
  }, [firstLevelApproval]);

  useEffect(() => {
    if (
      secondLevelApproval !== null &&
      secondLevelApproval !== undefined &&
      secondLevelApproval.length > 0
    ) {
      console.log('secondLevelApproval', secondLevelApproval[0].description);

      setSecondLevelApprovalDescription(secondLevelApproval[0].description);
    }
  }, [secondLevelApproval]);

  // useEffect(() => {
  //     if(
  //         users.getFile !== null && users.getFile !== undefined &&
  //         users.getFile.fileName !== undefined
  //         ){

  //             if(users.getFile.fileName === "profilePic"){
  //                 sessionStorage.setItem('profilePic', users.getFile.data);

  //             }

  //             if(users.getFile.fileName === "signPic"){
  //                 sessionStorage.setItem('signPic', users.getFile.data);

  //             }

  //     }
  // }, [users.getFile])

  // useEffect(() => {
  //     if(schemename1 !== "" && appNo1 !== "" && dateApp1 !== "" ){
  //         printDocumentOrder()

  //     }

  // }, [schemename1,appNo1]);

  useEffect(() => {
    if (finalstatus !== null && finalstatus !== false)
      console.log('finalstatus1', finalstatus);
    {
      if (finalstatus === 'Approved') {
        console.log('finalstatus2', finalstatus);

        setStatusOrder(true);
      } else if (finalstatus === 'Rejected') {
        console.log('finalstatu3', finalstatus);

        setStatusOrder(false);
      }
    }
  }, [finalstatus]);

  const printDocument1 = (
    schemename,
    applicationNo,
    dateApplied,
    sakalaNumber,
    applicantName,
  ) => {
    console.log('sakalaNumber', sakalaNumber);
    setschemename1(schemename);
    setappNo1(applicationNo);
    setdateApp1(dateApplied);
    setSakalaNumber(sakalaNumber);
    setApplicantsName(applicantName);

    setprintDocumentCheck1(true);

    // printDocument()
  };

  const printDocument2 = (
    schemename,
    applicationNo,
    dateApplied,
    approvedOrrejectedDate,
    schemeAvailID,
    sanctionedAmount,
    isApproved,
  ) => {
    console.log('ClickCount');
    setschemename(schemename);
    setappNo(applicationNo);
    setdateApp(dateApplied);
    setapprovedOrrejectedDate(approvedOrrejectedDate);
    setSanctioned_Amount(sanctionedAmount);
    setIsApproved(isApproved);
    console.log('schemeAvailID', schemeAvailID);

    console.log('out_get_scheme_status', schemeAvailID);

    var config = {
      method: 'get',
      url:
        SERVER + '/schemes/get_scheme_status?schme_avail_id=' + schemeAvailID,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    };
    axios(config)
      .then(function (response) {
        console.log('get_scheme_status', response.data.data.recordsets);
        console.log('get_scheme_status', response.data.data.recordsets[1]);
        console.log(
          'get_scheme_status123',
          response.data.data.recordsets[1].filter((x) => x.role_id === 1),
        );
        console.log(
          'get_scheme_status123',
          response.data.data.recordsets[1].filter((x) => x.role_id === 2 || 3),
        );
        setFirstLevelApproval(
          response.data.data.recordsets[1].filter((x) => x.role_id === 1),
        );
        setSecondLevelApproval(
          response.data.data.recordsets[1].filter((x) => x.role_id === 2),
        );
        setSecondLevelApproval(
          response.data.data.recordsets[1].filter((x) => x.role_id === 3),
        );
        var secondLevelApprovalData = response.data.data.recordsets[1].filter(
          (x) => x.role_id === 2 || 3 || 4,
        );
        var secondLevelApprovalDescription =
          secondLevelApprovalData[1] !== undefined &&
          secondLevelApprovalData[1].description;
        var secondLevelApprovalAddress =
          secondLevelApprovalData[1] !== undefined &&
          secondLevelApprovalData[1].address;

        var base64ID =
          secondLevelApprovalData[1] !== undefined &&
          secondLevelApprovalData[1].signature_upload_id;
        console.log('base64ID', base64ID);

        // console.log( secondLevelApprovalData[1].sanctioned_Amount,"secondLevelApprovalData")
        var sanctioned_Amount =
          secondLevelApprovalData[1] !== undefined &&
          secondLevelApprovalData[1].sanctioned_Amount;

        // var firstLevelApprovalData =  response.data.data.recordsets[1].filter(x => x.role_id === 1)
        // console.log(firstLevelApprovalData,"firstLevelApprovalData")
        // var firstLevelApprovalDescription = firstLevelApprovalData[0] !== undefined && firstLevelApprovalData[0].description
        // var firstLevelApprovalAddress = firstLevelApprovalData[0] !== undefined && firstLevelApprovalData[0].address
        // var base64IDfirst = firstLevelApprovalData[0] !== undefined && firstLevelApprovalData[0].signature_upload_id

        console.log(
          firstLevelApprovalDescription,
          'firstLevelApprovalDescription',
        );
        // console.log(base64IDfirst,"base64IDfirst")
        console.log('base64ID');

        if (schemename.includes('Shrama') || schemename.includes('BMTC')) {
          console.log('inside_Shrama');

          var secondLevelApprovalDescription1 =
            secondLevelApprovalData[0] !== undefined &&
            secondLevelApprovalData[0].description;
          var secondLevelApprovalAddress1 =
            secondLevelApprovalData[0] !== undefined &&
            secondLevelApprovalData[0].address;
          var base64ID1 =
            secondLevelApprovalData[0] !== undefined &&
            secondLevelApprovalData[0].signature_upload_id;
          console.log(
            secondLevelApprovalDescription,
            'secondLevelApprovalDescription',
          );
          console.log(secondLevelApprovalAddress, 'secondLevelApprovalAddress');
          console.log(base64ID1, 'base64ID1');

          if (base64ID1 !== null) {
            var config = {
              method: 'get',
              url: SERVER + '/upload/download_base_64/' + base64ID1,
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
            };
            axios(config)
              .then(function (response) {
                console.log(
                  'base64_response',
                  JSON.stringify(response.data.data.base_64_content),
                );
                var base64Signature1 = response.data.data.base_64_content;
                printDocumentRemarksFirstLevel(
                  schemename,
                  applicationNo,
                  dateApplied,
                  approvedOrrejectedDate,
                  sanctioned_Amount,
                  isApproved,
                  secondLevelApprovalDescription1,
                  secondLevelApprovalAddress1,
                  base64Signature1,
                );
              })
              .catch(function (error) {
                console.log(error);
              });
          } else {
            var base64Signature1 = null;
            printDocumentRemarksFirstLevel(
              schemename,
              applicationNo,
              dateApplied,
              approvedOrrejectedDate,
              sanctioned_Amount,
              isApproved,
              secondLevelApprovalDescription1,
              secondLevelApprovalAddress1,
              base64Signature1,
            );
          }
        } else {
          console.log('inside_Others');

          var config2 = {
            method: 'get',
            url: SERVER + '/upload/download_base_64/' + base64ID,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          };
          axios(config2)
            .then(function (response) {
              console.log(
                'base64_response2',
                JSON.stringify(response.data.data.base_64_content),
              );

              var base64Signature = response.data.data.base_64_content;
              printDocumentRemarks(
                schemename,
                applicationNo,
                dateApplied,
                approvedOrrejectedDate,
                sanctioned_Amount,
                isApproved,
                secondLevelApprovalDescription,
                secondLevelApprovalAddress,
                base64Signature,
              );
            })
            .catch(function (error) {
              console.log(error);

              // setLoading(false)
            });
        }

        console.log('in_get_scheme_status', secondLevelApprovalData);
        setApprovedData(
          response.data.data.recordsets[1].filter((x) => x.is_final === true),
        );
        dispatch(
          getFile(secondLevelApprovalData[0].signature_upload_id, 'signPic'),
        );

        // setRolesMaster(response.data.data)
      })
      .catch(function (error) {
        console.error('error', error);
      });

    setprintDocumentCheck(true);

    // printDocumentOrder()
  };

  const printDocumentRemarks = (
    schemename,
    applicationNo,
    dateApplied,
    approvedOrrejectedDate,
    sanctionedAmount,
    isApproved,
    secondLevelApprovalDescription,
    secondLevelApprovalAddress,
    base64Signature,
  ) => {
    console.log('printDocuments2');
    console.log('schemename2', schemename);
    console.log('appNo2', applicationNo);
    console.log('dateApp2', dateApplied);
    console.log('secondLevelRejectRemarks2', secondLevelApprovalDescription);
    console.log('secondLevelAddress2', secondLevelApprovalAddress);
    console.log('sanctionedAmount2', sanctionedAmount);
    console.log('base64SignatureImg2', base64Signature);

    console.log(
      secondLevelApprovalDescription,
      'secondLevelApprovalDescription',
    );
    console.log(secondLevelApprovalAddress, 'secondLevelApprovalAddress');
    console.log(base64Signature, 'base64Signature');
    setschemename(schemename);
    setappNo(applicationNo);
    setdateApp(dateApplied);
    setapprovedOrrejectedDate(approvedOrrejectedDate);
    setSanctioned_Amount(sanctionedAmount);
    setIsApproved(isApproved);
    setSecondLevelRejectRemark(secondLevelApprovalDescription);
    setsecondLevelApprovalAddress(secondLevelApprovalAddress);
    setBase64Signature(base64Signature);
    setBase64SignatureImg(base64Signature);
  };

  const printDocumentRemarksFirstLevel = (
    schemename,
    applicationNo,
    dateApplied,
    approvedOrrejectedDate,
    sanctionedAmount,
    isApproved,
    secondLevelApprovalDescription,
    secondLevelApprovalAddress,
    base64Signature,
  ) => {
    console.log('printDocuments');
    console.log('schemename1', schemename);
    console.log('appNo1', applicationNo);
    console.log('dateApp1', dateApplied);
    console.log('secondLevelRejectRemarks1', secondLevelApprovalDescription);
    console.log('secondLevelAddress1', secondLevelApprovalAddress);
    console.log('sanctionedAmount1', sanctionedAmount);
    console.log('base64SignatureImg1', base64Signature);

    console.log(
      secondLevelApprovalDescription,
      'secondLevelApprovalDescription',
    );
    console.log(secondLevelApprovalAddress, 'secondLevelApprovalAddress');
    console.log(base64Signature, 'base64Signature');
    setschemename(schemename);
    setappNo(applicationNo);
    setdateApp(dateApplied);
    setapprovedOrrejectedDate(approvedOrrejectedDate);
    setSanctioned_Amount(sanctionedAmount);
    setIsApproved(isApproved);
    setSecondLevelRejectRemark(secondLevelApprovalDescription);
    setsecondLevelApprovalAddress(secondLevelApprovalAddress);
    setBase64Signature(base64Signature);
  };
  useEffect(() => {
    if (printSanction) {
      printDocumentOrder();
      printDocument();
      printDocumentOrderReject();
    }
  }, []);
  const printDocumentOrder = () => {
    console.log('CALLED BLOB');
    const input = document.getElementById('orderToPrint');
    input.style.display = 'block';
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save('sanction_order.pdf');

        window.open(
          pdf.output('bloburl'),
          '_blank',
          'toolbar=0,location=0,menubar=0',
        );
        // window.location.reload();
      })
      .then(() => {
        input.style.display = 'none';
      });

    // console.log("scheme_status1: " + schemename);
  };

  const printDocumentOrderReject = () => {
    const input = document.getElementById('orderToPrintReject');
    input.style.display = 'block';
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save('rejection_order.pdf');
        window.open(
          pdf.output('bloburl'),
          '_blank',
          'toolbar=0,location=0,menubar=0',
        );
        window.location.reload();
      })
      .then(() => {
        input.style.display = 'none';
      });

    // console.log("scheme_status1: " + schemename);
  };

  const printDocument = () => {
    console.log('schemename2112', schemename);
    console.log('appNo2112', appNo);
    console.log('dateApp2112', dateApp);
    const input = document.getElementById('divToPrint');
    input.style.display = 'block';
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save('acknowledgement.pdf');
        window.open(
          pdf.output('bloburl'),
          '_blank',
          'toolbar=0,location=0,menubar=0',
        );
        // window.location.reload();
      })
      .then(() => {
        input.style.display = 'none';
      });
  };

  const [idName, setidName] = React.useState('');
  var schemename2 = schemename;
  console.log('ssss', schemename2);

  // var applicantName = '';
  // if (users.user.first_name !== '' && users.user.first_name !== null) {
  //   applicantName += users.user.first_name + ' ';
  // }
  // if (users.user.middle_name !== '' && users.user.middle_name !== null) {
  //   applicantName += users.user.middle_name + ' ';
  // }
  // if (users.user.last_name !== '' && users.user.last_name !== null) {
  //   applicantName += users.user.last_name;
  // }

  const deathAvailed =
    accidentAvailed &&
    accidentAvailed.filter(
      (data) => data.scheme_name === 'Accident Assistance',
    );

  const applicantName =
    schemename1 === 'Funeral Expense and Death Assistance'
      ? appplicantsName && appplicantsName
      : schemename1 === 'Accident Assistance' &&
          deathAvailed[0].death_status === 1
        ? appplicantsName && appplicantsName
        : users && users.user.first_name;

  var date = new Date();

  if (
    users.getUserRegistrationDetails !== null &&
    users.getUserRegistrationDetails !== undefined &&
    users.getUserRegistrationDetails !== 'Network Error' &&
    users.getUserRegistrationDetails.address_details !== undefined &&
    users.getUserRegistrationDetails.address_details.length > 0
  ) {
    var presentAddress = '';
    if (
      users.getUserRegistrationDetails.address_details[0].door_no !== '' &&
      users.getUserRegistrationDetails.address_details[0].door_no !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].door_no + ' ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].street_name !== '' &&
      users.getUserRegistrationDetails.address_details[0].street_name !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].street_name + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].land_mark !== '' &&
      users.getUserRegistrationDetails.address_details[0].land_mark !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].land_mark + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        '' &&
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].panhayat_city_town;
      presentAddress += x + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].district !== '' &&
      users.getUserRegistrationDetails.address_details[0].district !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].district;
      presentAddress += x + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        '' &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      presentAddress += x + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].taluk !== '' &&
      users.getUserRegistrationDetails.address_details[0].taluk !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].taluk;
      presentAddress += x + ', ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].state !== '' &&
      users.getUserRegistrationDetails.address_details[0].state !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].state;
      presentAddress += x + ' - ';
    }
    if (
      users.getUserRegistrationDetails.address_details[0].pin_code !== '' &&
      users.getUserRegistrationDetails.address_details[0].pin_code !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].pin_code;
    }

    var villageWard = '';
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        '' &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      villageWard += x;
    }

    var mobile = '';
    if (
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== '' &&
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== null
    ) {
      mobile += users.getUserRegistrationDetails.personal_details[0].mobile_no;
    }

    var occupation = '';
    if (
      users.getUserRegistrationDetails.employer_details !== '' &&
      users.getUserRegistrationDetails.employer_details.length > 0
    ) {
      var y = users.getUserRegistrationDetails.employer_details
        .map(function (e) {
          return e.catalog_value_employement_status_id;
        })
        .indexOf(25);

      console.log('y=' + y);
      var x =
        users.getUserRegistrationDetails.employer_details[y].nature_of_work;

      occupation += x;
    }

    var officerIncharge =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_first_name;
    var officerIncharge1 =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_middle_name;
    var officerIncharge2 =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_last_name;

    // if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 55) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].inspector_first_name
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 56) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].labour_union_spoke_person
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 57) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 58) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
    // }

    var circleName = '';
    if (
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== '' &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== null
    ) {
      circleName =
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_circle;
    }

    // var applicationNo  = users.getUserRegistrationDetails.application_details[0]
  }

  return (
    <>
      <div className="root">
        <Row className="top-div schemes">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/dashboard-user">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: 'flex' }}>
              <div className="usericon-header-logout">
                <img alt="..." src={usericon} className="usericon-img" />
                <p>{users.user !== null && users.user.first_name}</p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    alt="..."
                    src={logout}
                    className="logout-img"
                    onClick={() => {
                      props.history.push('/');
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
        <div className="d-flex justify-content-end">
          <Col
            xs={12}
            md={4}
            lg={3}
            className="d-flex justify-content-end mt-2 me-2"
          >
            <Select
              className="select-language"
              style={{ width: '100%' }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              value={allValues.language}
              name="language"
              displayEmpty
              onChange={handleChange1}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </div>

        <Row className="schemeStatus">
          <Col lg={6} className="ps-0 pe-0 d-flex justify-content-start">
            <div className="scheme_applied d-flex justify-content-center align-items-center">
              <img
                src={schemeAppliedStatusGroupIcon}
                alt=""
                className="scheme_applied_image"
              />
            </div>
            <div className="d-flex ms-4 justify-content-start align-items-center scheme_applied_content">
              <span className="num">
                {approvedArray.length +
                  pendingArray.length +
                  rejectedArray.length}
              </span>
              <div>
                <span className="text ms-1">
                  <Translate value="schemesApplied" />
                </span>
              </div>
            </div>
          </Col>
          <Col lg={6} className="ps-0 pe-0 d-flex justify-content-start">
            <div className="scheme_approved d-flex justify-content-center align-items-center">
              <img
                src={schemeApprovedStatusSubtraction11}
                alt=""
                className="scheme_approved_image"
              />{' '}
            </div>
            <div className="d-flex ms-4 justify-content-start align-items-center scheme_applied_content">
              <span className="num">{approvedArray.length}</span>
              <div>
                <span className="text ms-1">
                  <Translate value="schemesApproved" />
                </span>
              </div>
            </div>
          </Col>
          <Col lg={6} className="ps-0 pe-0 d-flex justify-content-start">
            <div className="d-flex justify-content-center align-items-center scheme_pending">
              <img
                src={schemependingStatusPendingSVG}
                alt=""
                className="scheme_pending_image"
              />
            </div>
            <div className="d-flex ms-4 justify-content-start align-items-center scheme_applied_content">
              <span className="num">{pendingArray.length}</span>
              <div>
                {/* <span className="text ms-2">Schemes Pending</span> */}
                <span className="text ms-1">
                  <Translate value="schemesPending" />
                </span>
              </div>
            </div>
          </Col>
          <Col lg={6} className="ps-0 pe-0 d-flex justify-content-start">
            <div className="d-flex justify-content-center align-items-center scheme_rejected">
              <img
                src={schemeclosestatus}
                alt=""
                className="scheme_rejected_image"
              />{' '}
            </div>
            <div className="d-flex ms-4 justify-content-start align-items-center scheme_applied_content">
              <span className="num">{rejectedArray.length}</span>
              <div>
                {/* <span className="text ms-2">Schemes Rejected</span> */}
                <span className="text ms-1">
                  <Translate value="schemesRejected" />
                </span>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="container-fluid m-0 p-0">
          <p className="text_approved ">
            <Translate value="Approved" />
          </p>
        </Row>
        <Row className="schemeStatus">
          {approvedArray.length > 0 &&
            approvedArray.map((x, index) => (
              <>
                <Col xs={12} md={6}>
                  {/* <div className="success_bar"></div> */}
                  <div className="bar_content approvedscheme">
                    <div className="container">
                      <Row className="box1 d-flex flex-column p-1  border">
                        <div className="d-flex flex-row justify-content-between">
                          <div className="box1_text">
                            <Translate value="schemeName" />
                          </div>
                          <div className="box1_text">{x.scheme_name}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Scheme Applied Date</div> */}
                          <div className="box1_text">
                            <Translate value="schemeAppliedDate" />
                          </div>
                          <div className="box1_text">
                            <Moment format="DD MMMM YYYY">
                              {x.applied_date}
                            </Moment>
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Status</div> */}
                          <div className="box1_text">
                            <Translate value="status" />
                          </div>
                          <div className="box1_text">
                            {x.status}{' '}
                            <span>
                              <img src={schemeSubtractionstatus} alt="" />
                            </span>
                          </div>
                        </div>
                      </Row>
                      <Row className="box2 border d-flex align-items-center px-1">
                        <div className="w_50">
                          <span className="me-2">
                            <img src={calendar} alt="" />
                          </span>
                          <Moment format="DD MMMM YYYY">
                            {x.approved_rejected_date}
                          </Moment>
                        </div>
                        <div className="float-right time">
                          <span className="me-2">
                            <img src={time} alt="" />
                          </span>
                          {Math.ceil(
                            (new Date(x.approved_rejected_date) - new Date()) /
                              -(1000 * 60 * 60 * 24),
                          ) === 0
                            ? 'Today'
                            : `${Math.ceil(
                                (new Date(x.approved_rejected_date) -
                                  new Date()) /
                                  -(1000 * 60 * 60 * 24),
                              )}
                                                     days ago`}
                        </div>
                      </Row>
                      <Row className="box2 border d-flex align-items-center px-1">
                        <Link
                          to="#"
                          onClick={() => {
                            printDocument1(
                              x.scheme_name,
                              x.scheme_application_code,
                              x.applied_date,
                              x.gsc_no,
                              x.Applicant_name,
                            );
                          }}
                          style={{ flexGrow: '0.5' }}
                        >
                          View Acknowledgement Letter
                        </Link>
                      </Row>
                      <Row className="box2 border d-flex align-items-center px-1">
                        <Link
                          to="#"
                          onClick={() => {
                            printDocument2(
                              x.scheme_name,
                              x.scheme_application_code,
                              x.applied_date,
                              x.approved_rejected_date,
                              x.scheme_avail_id,
                              x.sanction_ammount,
                              x.isapproved,
                            );
                          }}
                          style={{ flexGrow: '0.5' }}
                        >
                          View Sanction Order
                        </Link>
                      </Row>
                    </div>
                  </div>
                </Col>
              </>
            ))}
        </Row>
        <Row className="container-fluid m-0 p-0">
          <p className="text_approved">
            <Translate value="pending" />
          </p>
        </Row>
        <Row className="schemeStatus">
          {pendingArray.length > 0 &&
            pendingArray.map((x, index) => (
              <>
                <Col xs={12} md={6}>
                  {/* <div className="pending_bar"></div> */}
                  <div className="bar_content pendingscheme">
                    <div className="container">
                      <Row className="box1 d-flex flex-column p-1  border">
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Scheme Name</div> */}
                          <div className="box1_text">
                            <Translate value="schemeName" />
                          </div>
                          <div className="box1_text">{x.scheme_name}</div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Scheme Applied Date</div> */}
                          <div className="box1_text">
                            <Translate value="schemeAppliedDate" />
                          </div>
                          <div className="box1_text">
                            <Moment format="DD MMMM YYYY">
                              {x.applied_date}
                            </Moment>
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Status</div> */}
                          <div className="box1_text">
                            <Translate value="status" />
                          </div>
                          <div className="box1_text">
                            {x.status}
                            <span className="ms-2">
                              <img src={PendingSmallStatusIcon} alt="" />
                            </span>
                          </div>
                        </div>
                      </Row>
                      <Row className="box2 border d-flex align-items-center px-1">
                        <Link
                          to="#"
                          onClick={() => {
                            printDocument1(
                              x.scheme_name,
                              x.scheme_application_code,
                              x.applied_date,
                              x.gsc_no,
                              x.Applicant_name,
                            );
                          }}
                          style={{ flexGrow: '0.5' }}
                        >
                          View Acknowledgement Letter
                        </Link>
                      </Row>
                    </div>
                  </div>
                </Col>
              </>
            ))}
        </Row>
        <Row className="container-fluid m-0 p-0">
          <p className="text_approved">
            <Translate value="rejected" />
          </p>
        </Row>
        <Row className="schemeStatus">
          {/* <Col> */}
          {rejectedArray.length > 0 &&
            rejectedArray.map((x, index) => (
              <>
                <Col xs={12} md={6}>
                  <div className="bar_content rejectedscheme">
                    <div className="container">
                      <Row className="box1 d-flex flex-column p-1  border">
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Scheme Name</div> */}
                          <div className="box1_text">
                            <Translate value="schemeName" />
                          </div>
                          <div className="box1_text">{x.scheme_name}</div>
                        </div>

                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Scheme Applied Date</div> */}
                          <div className="box1_text">
                            <Translate value="schemeAppliedDate" />
                          </div>
                          <div className="box1_text">
                            <Moment format="DD MMMM YYYY">
                              {x.applied_date}
                            </Moment>
                          </div>
                        </div>
                        <div className="d-flex flex-row justify-content-between">
                          {/* <div className="box1_text">Status</div> */}
                          <div className="box1_text">
                            <Translate value="status" />
                          </div>
                          <div className="box1_text">
                            {x.status}
                            <span className="ms-2">
                              <img src={closeIcon} alt="" />
                            </span>
                          </div>
                        </div>
                      </Row>

                      <Row className="box2 border d-flex align-items-center px-1">
                        <div className="d-flex flex-row justify-content-between"></div>
                        <div className="w_50">
                          <span className="me-2">
                            <img src={calendar} alt="" />
                          </span>
                          <Moment format="DD MMMM YYYY">
                            {x.approved_rejected_date}
                          </Moment>
                        </div>
                        <div className="float-right time">
                          <span className="me-2">
                            <img src={time} alt="" />
                          </span>
                          {Math.ceil(
                            (new Date(x.approved_rejected_date) - new Date()) /
                              -(1000 * 60 * 60 * 24),
                          ) === 0
                            ? 'Today'
                            : `${Math.ceil(
                                (new Date(x.approved_rejected_date) -
                                  new Date()) /
                                  -(1000 * 60 * 60 * 24),
                              )}
                                                     days ago`}
                        </div>
                      </Row>

                      <Row className="box2 border d-flex align-items-center px-1">
                        <Link
                          to="#"
                          onClick={() => {
                            printDocument1(
                              x.scheme_name,
                              x.scheme_application_code,
                              x.applied_date,
                              x.gsc_no,
                              x.Applicant_name,
                            );
                          }}
                          style={{ flexGrow: '0.5' }}
                        >
                          View Acknowledgement Letter
                        </Link>
                      </Row>
                      <Row className="box2 border d-flex align-items-center px-1">
                        <Link
                          to="#"
                          onClick={() => {
                            printDocument2(
                              x.scheme_name,
                              x.scheme_application_code,
                              x.applied_date,
                              x.approved_rejected_date,
                              x.scheme_avail_id,
                            );
                          }}
                          style={{ flexGrow: '0.5' }}
                        >
                          View Rejection Order
                        </Link>
                      </Row>
                    </div>
                  </div>
                </Col>
              </>
            ))}

          {/* </Col> */}
        </Row>
      </div>

      <div>
        {/* <div className="mb-5">
    <button onClick={printDocument}>Print</button>
</div> */}

        <div
          id="orderToPrint"
          className="mt-4"
          style={{
            backgroundColor: '#fff',
            width: '210mm',
            minHeight: '297mm',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            // justifyContent: 'center',
            display: 'none',
            // border: '2px solid black',
          }}
        >
          <div className="logo-div-ack">
            <a href="/dashboard-user">
              <img id="logo" src={logo} alt="..." className="logo-img" />
            </a>{' '}
            <h1 className="logo-text">
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
              (ಕ.ಕ.ಇ.ನಿ.ಕಾ.ಕ.ಮಂ) <br />
              Karnataka Building & Other Construction Workers Welfare Board
              (K.B.O.C.W.W.B) <br />
            </h1>
          </div>

          <Row className="ack-subtitle-row">
            <Col className="profile-title" style={{ display: 'block' }}>
              {console.log('statusOrder', statusOrder)}
              <h1>ಮಂಜೂರಾತಿ ಆದೇಶ/ Sanction Order</h1>
            </Col>
          </Row>

          <Row className="ack-table-row1 ">
            <Col className="ack-col">
              <p>ಕಟ್ಟಡ ಕಾರ್ಮಿಕರ ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration Number: </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{users.user.registration_code}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1 ">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿ ಸಂಖ್ಯೆ /
                <br />
                Application number{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{appNo}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>ಯೋಜನೆ/ಸೇವೆಯ ಹೆಸರು / Scheme / Service Name:</p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{schemename}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿಯ ದಿನಾಂಕ /<br />
                Date of Application:{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>
                <Moment format="DD MMMM YYYY">{dateApp}</Moment>
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿದಾರರ ಹೆಸರು /<br />
                Name of the Applicant:{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{applicantName}</p>
            </Col>
          </Row>

          <Row className="sanction-lines">
            {schemename} ಯೋಜನೆಯನ್ನು ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು
            <br /> ಮಂಜೂರು ಮಾಡಲಾಗಿದೆ ಹಾಗು ಮಂಜೂರಾದ ಮೊತ್ತ {sanctioned_Amount}{' '}
            ಆಗಿರುತ್ತದೆ
            {console.log('injsxsanc', sanctioned_Amount)}
          </Row>

          <div className="circle-name">
            <div className="circle-name2">
              <div className="circle-name1" style={{ marginTop: '20px' }}>
                <p>ವೃತ್ತದ ಹೆಸರು / Circle Name : {circleName} </p>
              </div>
              <div className="circle-name1">
                <p>
                  ಮಂಜೂರಾತಿ ದಿನಾಂಕ / Date of Sanction :{' '}
                  <Moment format="DD MMMM YYYY">
                    {approvedOrrejectedDate}
                  </Moment>
                </p>

                <div className="alc-lo-signature">
                  <div className="picupload-idcard">
                    {/* {
                                            // sessionStorage.getItem('profilePic') === null ?
                                            signPhoto !== undefined && signPhoto !== "" && signPhoto !== null ? */}

                    <img
                      alt="..."
                      className="avatar idcard border-gray"
                      // src={`${signPhoto}`}
                      src={signPic !== null ? `${signPic}` : NoImage}

                      // src={users.getFile}
                    />
                    {/* :
                                                null
                                        } */}
                    <p style={{ marginTop: '-20px' }}>
                      {' '}
                      ಮಂಜೂರಾತಿ ಅಧಿಕಾರಿ <br /> (Signature OF LO / ALC){' '}
                    </p>
                    <div className="office-address-sanc">
                      {console.log('secondLevelAddress', secondLevelAddress)}
                      <p>{secondLevelAddress}</p>
                    </div>
                    <p style={{ marginTop: '-10px', marginBottom: '20px' }}>
                      {' '}
                      (Office Address){' '}
                    </p>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center">
                <p
                  style={{
                    marginTop: '80px',
                    fontSize: '12px',
                    textAlign: 'center',
                    position: 'relative',
                    right: '20px',
                    width: '500px',
                  }}
                >
                  ಸೂಚನೆ / Note: - ಮಂಜೂರಾದ ಮೊತ್ತ DBT ಮುಖಾಂತರ ನೇರವಾಗಿ ಫಲಾನುಭವಿಗಳ
                  ಆಧಾರ್ ಲಿಂಕ್ NPCI ಮ್ಯಾಪಿಂಗ್ ಆಗಿರುವ ಬ್ಯಾಂಕ್ ಖಾತೆಗೆ ಜಮೆ
                  ಮಾಡಲಾಗುವುದು.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div
          id="orderToPrintReject"
          className="mt-4"
          style={{
            backgroundColor: '#fff',
            width: '210mm',
            minHeight: '297mm',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            // justifyContent: 'center',
            display: 'none',
            // border: '2px solid black',
          }}
        >
          <div className="logo-div-ack">
            <a href="/dashboard-user">
              <img id="logo" src={logo} alt="..." className="logo-img" />
            </a>{' '}
            <h1 className="logo-text">
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
              (ಕ.ಕ.ಇ.ನಿ.ಕಾ.ಕ.ಮಂ) <br />
              Karnataka Building & Other Construction Workers Welfare Board
              (K.B.O.C.W.W.B) <br />
            </h1>
          </div>

          <Row className="ack-subtitle-row">
            <Col className="profile-title" style={{ display: 'block' }}>
              {console.log('statusOrder', statusOrder)}
              <h1>ನಿರಾಕರಣೆ ಆದೇಶ/ Rejection Order</h1>
            </Col>
          </Row>

          <Row className="ack-table-row1 ">
            <Col className="ack-col">
              <p>ಕಟ್ಟಡ ಕಾರ್ಮಿಕರ ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration Number: </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{users.user.registration_code}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1 ">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿ ಸಂಖ್ಯೆ /
                <br />
                Application Number{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{appNo}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>ಯೋಜನೆ/ಸೇವೆಯ ಹೆಸರು/Scheme/Service Name:</p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{schemename}</p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿಯ ದಿನಾಂಕ /<br />
                Date of Application:{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>
                <Moment format="DD MMMM YYYY">{dateApp}</Moment>
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row1">
            <Col className="ack-col">
              <p>
                ಅರ್ಜಿದಾರರ ಹೆಸರು /<br />
                Name of the Applicant:{' '}
              </p>
            </Col>

            <Col>
              <p>:</p>
            </Col>

            <Col className="ack-col">
              <p>{applicantName}</p>
            </Col>
          </Row>

          <Row className="sanction-lines ">
            {schemename} ಯೋಜನೆಯನ್ನು ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು
            ತಿರಸ್ಕರಿಸಲಾಗಿದೆ
            {console.log('injsxsanc', sanctioned_Amount)}
          </Row>

          {/* <Row>
                        {console.log("firstLevelApprovalDescription1", firstLevelApprovalDescription)}
                        {console.log("firstLevelApprovalDescription1", secondLevelApprovalDescription)}

                        <div className='remarks-rejection-first'>
                        <p>First Level Remarks : {firstLevelApprovalDescription} </p> 
                         
                        </div>
                    </Row> */}

          <Row className="remarks-rejection">
            {console.log(
              'firstLevelApprovalDescription1',
              secondLevelRejectRemarks,
            )}

            <div className="remarks-rejection-first">
              {/* {secondLevelApprovalDescription !== null ? <p>Second Level Remarks : {secondLevelApprovalDescription} </p> : null }   */}
              {secondLevelRejectRemarks !== null &&
              secondLevelRejectRemarks !== undefined ? (
                <p>Reason for Rejection : {secondLevelRejectRemarks} </p>
              ) : null}
            </div>
          </Row>

          <div className="circle-name">
            <div className="circle-name2">
              <div className="circle-name1" style={{ marginTop: '20px' }}>
                <p>ವೃತ್ತದ ಹೆಸರು / Circle Name : {circleName} </p>
              </div>
              <div className="circle-name1">
                <p>
                  ಮಂಜೂರಾತಿ ದಿನಾಂಕ / Date of Rejection :{' '}
                  <Moment format="DD MMMM YYYY">
                    {approvedOrrejectedDate}
                  </Moment>
                </p>

                <div className="alc-lo-signature">
                  <div className="picupload-idcard">
                    <img
                      alt="..."
                      className="avatar idcard border-gray"
                      // src={`${signPhoto}`}
                      src={`${signPic}`}

                      // src={users.getFile}
                    />

                    <p style={{ marginTop: '-20px' }}>
                      {' '}
                      ಮಂಜೂರಾತಿ ಅಧಿಕಾರಿ <br /> (Signature OF LO / ALC){' '}
                    </p>
                    <div className="office-address-sanc">
                      <p>{secondLevelAddress}</p>
                    </div>
                    <p style={{ marginTop: '-10px', marginBottom: '20px' }}>
                      {' '}
                      (Office Address){' '}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* ********************   Acknowledgement Letter HTML ************************ */}
      <div>
        {/* <div className="mb-5">
    <button onClick={printDocument}>Print</button>
</div> */}
        <div
          id="divToPrint"
          className="mt-4"
          style={{
            backgroundColor: '#fff',
            width: '210mm',
            minHeight: '297mm',
            marginLeft: 'auto',
            marginRight: 'auto',
            textAlign: 'center',
            // justifyContent: 'center',
            display: 'none',
            // border: '2px solid black',
          }}
        >
          <div className="logo-div-ack">
            <a href="/dashboard-user">
              <img id="logo" src={logo} alt="..." className="logo-img" />
            </a>{' '}
            <h1 className="logo-text">
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
              (ಕ.ಕ.ಇ.ನಿ.ಕಾ.ಕ.ಮಂ) <br />
              Karnataka Building & Other Construction Workers Welfare Board
              (K.B.O.C.W.W.B) <br />
            </h1>
          </div>

          <Row className="ack-subtitle-row">
            <Col xs={12} className="profile-title" style={{ display: 'block' }}>
              <h1>ಸ್ವೀಕೃತಿ/ Acknowledgement</h1>
            </Col>
          </Row>

          <p className="ack-success">
            <img
              src={checkGreen}
              style={{ height: 'auto', width: '28px', marginRight: '10px' }}
              alt=""
            />
            {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
            ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿ: ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರಿಗೆ ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ /
            Application Status : Successfully submitted to Labour Inspector
          </p>

          <Row className="ack-table-row mt-5">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಅಪ್ಲಿಕೇಶನ್ ಸಂಖ್ಯೆ/ Application Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{appNo1}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಸಕಾಲ ಸಂಖ್ಯೆ / Sakala Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{sakalaNumber}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಅರ್ಜಿ ದಿನಾಂಕ/ Application Date</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>
                <Moment format="DD MMMM YYYY">{dateApp1}</Moment>
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ವಿನಂತಿಸಿದ ಸೇವೆ/ Requested Service</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{schemename1}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಅರ್ಜಿದಾರರ ಹೆಸರು/ Applicant's Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{applicantName}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಮನೆಯ ವಿಳಾಸ/ Residential Address</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{presentAddress}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಗ್ರಾಮ ವಾರ್ಡ್/ Village Ward</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{villageWard}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಮೊಬೈಲ್ ಸಂಖ್ಯೆ/ Mobile Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{mobile}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಉದ್ಯೋಗ (ವರ್ಗ)/ Occupation (Category)</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{occupation}</p>
            </Col>
          </Row>

          {/* <Row className="ack-table-row">
        <Col xs={2}></Col>
        <Col xs={3} className="ack-col">
            <p>Documents Submitted</p>
        </Col>
        
        <Col xs={1}>
            <p>:</p>
        </Col>
        
        <Col xs={6} className="ack-col">
            <p>{occupation}</p>
        </Col>
    </Row> */}

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>ಸರ್ಕಲ್ ಹೆಸರು/ Circle Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>{circleName}</p>
            </Col>
          </Row>

          <Row className="ack-table-row">
            <Col xs={2}></Col>
            <Col xs={3} className="ack-col">
              <p>
                {' '}
                ಹಿರಿಯ ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ / ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಕಛೇರಿ <br />
                Senior Labour Inspector / Labour Inspector Office
              </p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className="ack-col">
              <p>
                {officerIncharge} {officerIncharge1} {officerIncharge2}
              </p>
            </Col>
          </Row>

          <Row className="ack-table-row-last mt-5">
            <Col xs={2}></Col>
            <Col xs={10} className="ack-col-note">
              <p>ಸೂಚನೆ/ NOTE :</p>
            </Col>
          </Row>
          <Row className="ack-table-row-last">
            <Col xs={2}></Col>
            <Col xs={10} className="ack-col">
              <p>
                1.ಸಲ್ಲಿಸಿದ ಅರ್ಜಿ ಪರಿಶೀಲನೆಗೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ/ Submitted
                application is subject to verification.
              </p>
            </Col>
          </Row>
          <Row className="ack-table-row-last">
            <Col xs={2}></Col>
            <Col xs={10} className="ack-col">
              <p>
                2.ಸ್ಥಿತಿಯನ್ನು ತಿಳಿಯಲು ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಲಾಗಿನ್ ಮಾಡಿ/ Login to
                application to know the status.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default SchemeStatus;
