import {
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  Pagination,
  Stack,
} from '@mui/material';
import PaginationFunction from './adminPortal/Pagination';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Tooltip2 from '@mui/material/Tooltip';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { getTokenCookie, showToast } from 'utils/tools';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

const TableComponentRegistration = ({ registrationList, appStatus }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [filteredData, setFilteredData] = useState(null);
  const [btnName, setBtnName] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [dept_id, setDeptId] = React.useState();
  const [userId, setUserId] = React.useState();
  const [btnName2, setBtnName2] = React.useState('');
  const [showRemarks, setShowRemarks] = React.useState('');
  const [comment, setComment] = React.useState('');

  const users = useSelector((state) => state.users);

  const PER_PAGE = 10;

  useEffect(() => {
    if (query) {
      const keys = [
        'labour_full_name',
        'application_no',
        'gsc_no',
        'mobile_no',
      ];
      const result = registrationList.filter((item) =>
        keys.some((key) => {
          return (
            item['labour_full_name'].includes(query) ||
            item['application_no'].includes(query) ||
            item['gsc_no'].includes(query) ||
            item['mobile_no'].includes(query)
          );
        }),
      );

      setFilteredData(result);
    }
  }, [query, registrationList]);

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : registrationList && Math.ceil(registrationList.length / PER_PAGE);

  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(registrationList || [], PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const handleComment = (ev) => {
    setComment(ev.target.value);
  };

  const handleOpen = async (dept_user_id, labour_user_id, btn_name) => {
    setOpen(true);
    setDeptId(dept_user_id);
    setUserId(labour_user_id);
    setBtnName2(btn_name);
  };

  const handleClose = () => {
    // setCallUpdateFreeze(true)

    setComment('');
    setOpen(false);
  };

  const handleActivate = async (dept_user_id, labour_user_id, btn_name) => {
    handleOpen(dept_user_id, labour_user_id, btn_name);

    // handleUpdateFreeze(dept_user_id,labour_user_id,btn_name)

    setBtnName(btn_name);
  };

  const handleShowRemarks = (from_loc, comments) => {
    handleOpen();
    setShowRemarks(comments);
    setBtnName(from_loc);
  };

  const handleUpdateFreeze = async () => {
    if (comment !== '' && comment !== undefined && comment.length > 0) {
      if (btnName2 === 'activate') {
        // if(isUpdate){
        var data = {
          login_user_id: dept_id,
          id: userId,
          is_seva_sindhu: 0,
          is_freeze: false,
          active_inactive_comment: comment,
        };

        var config = {
          method: 'post',
          url: SERVER + '/user/update_freeze_or_unfreeze_labour',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };
        await axios(config)
          .then((response) => {
            setBtnName2('Deactivate');
          })
          .catch((error) => {
            console.log(error);
          });
        showToast('SUCCESS', 'Account has been activated');
      } else if (btnName2 === 'deactivate') {
        var data2 = {
          login_user_id: dept_id,
          id: userId,
          is_seva_sindhu: 0,
          is_freeze: true,
          active_inactive_comment: comment,
        };

        var config2 = {
          method: 'post',
          url: SERVER + '/user/update_freeze_or_unfreeze_labour',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data2,
        };
        await axios(config2)
          .then((response) => {
            setBtnName2('Activate');
          })
          .catch((error) => {
            console.log(error);
          });
        showToast('SUCCESS', 'Account has been deactivated');
      }
    }
    setOpen(false);
    setComment('');

    setTimeout(() => {
      window.location.reload();
    }, 3000);
  };

  const EcardClicked = (uid, bid) => {
    // console.log('EcardClicked: ', `/e-card/uid=${uid}_bid=${bid}`);
    // props.history.push(`/e-card/uid=${uid}_bid=${bid}`);
    const win = window.open(`/e-card/uid=${uid}_bid=${bid}`, '_blank');
    win.focus();
  };

  //   console.log(_DATA.currentData(), 'PAGIN');
  return (
    <>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Remarks</DialogTitle>
        <DialogContent style={{ width: '500px' }}>
          {btnName === 'viewremarks' ? (
            <textarea
              rows={4}
              style={{
                border: 'none',
                width: '450px',
                resize: 'none !important',
              }}
              // autoFocus
              // margin="dense"
              // id="name"
              placeholder="Comment"
              type="email"
              // fullWidth
              value={showRemarks}
              // onChange={handleComment}
              // variant="outlined"
              maxLength={200}
            />
          ) : (
            <textarea
              rows={4}
              draggable="false"
              style={{ border: '0.5px solid black', width: '450px' }}
              // autoFocus
              // margin="dense"
              // id="name"
              placeholder="Comment"
              type="email"
              // fullWidth
              value={comment}
              onChange={handleComment}
              // variant="outlined"
              maxLength={200}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>CANCEL</Button>
          {btnName !== 'viewremarks' ? (
            <Button variant="success" onClick={handleUpdateFreeze}>
              Submit
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
      <TableContainer style={{ width: '100%' }} component={Paper}>
        <div className="d-flex justify-content-evenly align-items-center m-2 gap-2">
          <div className="w-75">
            <input
              style={{ resize: 'none' }}
              className="text-center w-100 form-control"
              value={query}
              onChange={(ev) => setQuery(ev.target.value)}
              placeholder={
                'Filter by Application Number,Applicant Name,Sakala Number,Mobile Number'
              }
            />
            <p className="text-center  text-danger">
              Note: Please enter as per the details in the{' '}
              <span style={{ fontWeight: 'bold' }}>"Acknowledgment"</span>
            </p>
          </div>
          <div className="align-self-start">
            <Stack spacing={2} className="w-100">
              <Pagination
                count={count}
                size="medium"
                page={page}
                color="primary"
                variant="outlined"
                shape="circular"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Date
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Applicant Name
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Number
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Status
              </TableCell>
              {appStatus !== 1074 && appStatus !== 1073 && (
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Sakala Number
                </TableCell>
              )}
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                View
              </TableCell>
              {users.getDeptUserRole.data[0].role_id &&
                users.getDeptUserRole.data[0].role_id === 1 &&
                appStatus === 47 && (
                  <TableCell
                    style={{
                      color: 'black',
                      fontWeight: 'bolder',
                      whiteSpace: 'nowrap',
                    }}
                    align="center"
                  >
                    E-Card
                  </TableCell>
                )}
              {users.getDeptUserRole.data[0].role_id &&
                users.getDeptUserRole.data[0].role_id === 1 &&
                appStatus === 47 && (
                  <TableCell
                    style={{ color: 'black', fontWeight: 'bolder' }}
                    align="center"
                  >
                    Action
                  </TableCell>
                )}
              {users.getDeptUserRole.data[0].role_id &&
                users.getDeptUserRole.data[0].role_id === 1 &&
                appStatus === 47 && (
                  <TableCell
                    style={{ color: 'black', fontWeight: 'bolder' }}
                    align="center"
                  >
                    Remarks
                  </TableCell>
                )}
              {appStatus !== 47 && appStatus !== 1074 && appStatus !== 1073 && (
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Sakala Timeline
                </TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.currentData() &&
              _DATA.currentData().map((row) => (
                <TableRow key={row.scheme_transaction_id}>
                  <TableCell align="center">
                    {moment(row.application_date, 'YYYY/MM/DD').format(
                      'DD/MM/YYYY',
                    )}
                  </TableCell>
                  <TableCell align="center">{row.labour_full_name}</TableCell>
                  <TableCell align="center">{row.application_no}</TableCell>
                  <TableCell align="center">
                    {row.registration_status}
                  </TableCell>
                  {appStatus !== 1074 && appStatus !== 1073 && (
                    <TableCell align="center">{row.gsc_no}</TableCell>
                  )}
                  <TableCell align="center">
                    {row.registration_status === 'Pending' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Pending'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />{' '}
                      </Link>
                    )}
                    {row.registration_status === 'Approved' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Approved'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />{' '}
                        {/*View*/}
                      </Link>
                    )}
                    {row.registration_status === 'Draft Approved' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftApproved'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.registration_status === 'Rejected' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Rejected'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.registration_status === 'Draft Rejected' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftRejected'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}

                    {row.registration_status ===
                      'Edit Profile Request Pending' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'EditPending'}/${'Registration'}/${row.id}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.registration_status ===
                      'Edit Profile Request Approved' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'EditApproved'}/${'Registration'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                  </TableCell>
                  {appStatus === 47 && (
                    <TableCell align="center" className="">
                      <Link
                        to="#"
                        onClick={() => EcardClicked(row.labour_user_id, 1)}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />{' '}
                        {/* View <br/> E-Card*/}
                      </Link>
                    </TableCell>
                  )}
                  {users.getDeptUserRole.data[0].role_id &&
                  users.getDeptUserRole.data[0].role_id === 1 &&
                  appStatus === 47 ? (
                    <>
                      <TableCell align="right">
                        {row.IS_Freezed === true ? (
                          <Button
                            onClick={() =>
                              handleActivate(
                                row.department_user_id,
                                row.labour_user_id,
                                'activate',
                                row.active_inactive_comment,
                              )
                            }
                            size="sm"
                            className="w-100"
                            variant="success"
                          >
                            ACTIVATE
                          </Button>
                        ) : row.IS_Freezed === false ||
                          row.IS_Freezed === null ? (
                          <Button
                            onClick={() =>
                              handleActivate(
                                row.department_user_id,
                                row.labour_user_id,
                                'deactivate',
                                row.active_inactive_comment,
                              )
                            }
                            size="sm"
                            className="w-100"
                            variant="danger"
                          >
                            DEACTIVATE
                          </Button>
                        ) : (
                          ''
                        )}
                      </TableCell>

                      <TableCell align="center">
                        {row.active_inactive_comment !== null ? (
                          <Tooltip2 title="View Remarks" placement="top">
                            <img
                              onClick={() =>
                                handleShowRemarks(
                                  'viewremarks',
                                  row.active_inactive_comment,
                                )
                              }
                              alt="..."
                              src={Eye}
                              className="mr-2"
                              style={{
                                marginRight: '8px',
                                cursor: 'pointer',
                              }}
                            />
                          </Tooltip2>
                        ) : (
                          <VisibilityOffIcon
                            sx={{
                              marginRight: '8px',
                              fontSize: 20,
                              color: '#5A5353',
                            }}
                          />
                        )}{' '}
                      </TableCell>
                    </>
                  ) : null}
                  {appStatus !== 47 &&
                    appStatus !== 1074 &&
                    appStatus !== 1073 && (
                      <TableCell align="center">
                        <div className="d-flex justify-content-center align-items-center align-self-center w-100">
                          <p className="text-center">
                            {row.sla_days} days left for Approval
                          </p>
                          <img
                            className="img-sakala align-self-start"
                            alt="..."
                            src={Sakala}
                          />
                        </div>
                      </TableCell>
                    )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableComponentRegistration;
