import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const getUnionList = (setUnionList) => {
  axios
    .get(`${SERVER}/config/union`, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    })
    .then(({ data }) => {
      const updatedUnionData = data.data.map((union) => ({
        ...union,
        name: union.name,
        registeration_no: union.registeration_no ?? 'N/A',
        address: union.address ?? 'N/A',
        spoke_person: union.spoke_person ?? 'N/A',
        phone_no: union.phone_no ?? 'N/A',
        isactive: union.isactive,
      }));
      setUnionList(updatedUnionData);
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getUnionByID = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/config/union/get_union_board_details`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const activeInactiveUnion = async (payLoad) => {
  try {
    const { data } = await axios.post(
      `${SERVER}/config/union/activate_union`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUnion = async (payLoad) => {
  try {
    const { data } = await axios.delete(`${SERVER}/config/union`, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payLoad,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
