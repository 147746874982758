import React, { useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useState } from 'react';
import Eye from 'assets/images/eye.svg';
import { Button, Col, Row } from 'react-bootstrap';
import {
  MenuItem,
  ListItemText,
  Select,
  InputAdornment,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getTokenCookie } from 'utils/tools';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';
const RegistrationDriveList = () => {
  const users = useSelector((state) => state.users);

  const [registrationDriveList, setRegistrationDriveList] = useState(null);
  const [loading, setLoading] = useState(false);

  const [registrationCatalog, setRegistrationCatalog] = useState(null);
  const [appStatus, setAppStatus] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [applicationNumber, setApplicationNumber] = useState(null);
  const [mobileNumber, setMobileNumber] = useState(null);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Registration Status',
    };

    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        console.log(res.data.data);
        const { data } = res.data;
        setRegistrationCatalog(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  //   console.log(appStatus,"appStatus");
  //   console.log(moment(startDate).format('YYYY/MM/DD'), 'startDate');
  //   console.log(endDate, 'endDate');

  useEffect(() => {
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  const handleReset = () => {
    setMobileNumber('');
    setApplicationNumber('');
    setAppStatus(null);
    setStartDate(null);
    setEndDate(null);
    isDateSelected(0);
  };

  const handleRegistrationDetails = () => {
    setLoading(true);
    const payLoad = {
      boardId: 1,
      departmentUserId:
        users && users.getDeptUserRole.data[0].department_user_id,
      roleId: users && users.getDeptUserRole.data[0].role_id,
      driveUserId: users && users.getDeptUserRole.data[0].department_user_id,
      registrationStatus: appStatus,
      isDateSelected: isDateSelected,
      startDate: startDate ? moment(startDate).format('YYYY/MM/DD') : null,
      endDate: endDate ? moment(endDate).format('YYYY/MM/DD') : null,
      ackNo: applicationNumber,
      mobileNo: mobileNumber,
      aadhaarNo: null,
    };
    axios
      .post(`${SERVER}/admin/labours-status/registration-drive`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        setRegistrationDriveList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  return (
    <>
      <h2 className="text-center">Registration Drive Details</h2>
      <Row className="py-2">
        <Col xs={12} md={3}>
          <Select
            className="form-control h-75 py-2"
            style={{ border: '1px solid grey' }}
            labelId="demo-simple-select-required-label"
            id="demo-simple-select-required"
            value={appStatus || '0'}
            name="circleActiveLi"
            displayEmpty
            onChange={(ev) => setAppStatus(ev.target.value)}
            defaultValue={'0'}
          >
            <MenuItem value={'0'}>
              <ListItemText primary="Select Status" />
            </MenuItem>
            {registrationCatalog &&
              registrationCatalog.length &&
              registrationCatalog.map(
                (data) =>
                  (data.value === 'Pending' ||
                    data.value === 'Rejected' ||
                    data.value === 'Approved') && (
                    <MenuItem value={data.value_id}>
                      <ListItemText primary={data.value} />
                    </MenuItem>
                  ),
              )}
          </Select>
        </Col>

        <Col xs={12} md={3}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            style={{ border: '1px solid grey' }}
          >
            <KeyboardDatePicker
              className="datepicker form-control"
              // margin="normal"
              format="DD/MM/YYYY"
              value={startDate}
              onChange={(date) => {
                setStartDate(date);
              }}
              placeholder="Start Date"
              //   disabled={!showFilters ? true : false}
              InputProps={{
                startAdornment: (
                  <div
                    onClick={() => {
                      setStartDate(null);
                      setIsDateSelected(0);
                    }}
                    style={{ position: 'absolute', right: '20%' }}
                  >
                    {startDate ? (
                      <InputAdornment>
                        <CancelIcon color="error" />
                      </InputAdornment>
                    ) : null}
                  </div>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={12} md={3}>
          <MuiPickersUtilsProvider
            utils={MomentUtils}
            style={{ border: '1px solid grey' }}
          >
            <KeyboardDatePicker
              className="datepicker form-control"
              // margin="normal"
              format="DD/MM/YYYY"
              value={endDate}
              onChange={(date) => {
                setEndDate(date);
              }}
              placeholder="End Date"
              //   disabled={!showFilters ? true : false}
              InputProps={{
                startAdornment: (
                  <div
                    onClick={() => {
                      setEndDate(null);
                      setIsDateSelected(0);
                    }}
                    style={{ position: 'absolute', right: '20%' }}
                  >
                    {endDate ? (
                      <InputAdornment>
                        <CancelIcon color="error" />
                      </InputAdornment>
                    ) : null}
                  </div>
                ),
              }}
            />
          </MuiPickersUtilsProvider>
        </Col>
        <Col xs={12} md={3}>
          <input
            type="text"
            className="form-control text-center h-75"
            placeholder="Application Number"
            value={applicationNumber}
            onChange={(ev) => setApplicationNumber(ev.target.value)}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={3}>
          <input
            type="text"
            className="form-control w-100 py-2 text-center"
            placeholder="Mobile Number"
            value={mobileNumber}
            onChange={(ev) => setMobileNumber(ev.target.value)}
          />
        </Col>
        <Col xs={12} md={3}>
          <Button
            onClick={handleRegistrationDetails}
            className="w-100 btn btn-success py-2"
          >
            Search
          </Button>
        </Col>
        <Col xs={12} md={3}>
          <Button onClick={handleReset} className="w-100 btn btn-danger py-2">
            Reset
          </Button>
        </Col>
      </Row>
      <div className="d-flex justify-content-center align-items-center w-100 my-3"></div>
      {loading ? (
        <SimpleBackdrop />
      ) : registrationDriveList && registrationDriveList.length ? (
        <TableContainer style={{ width: '100%' }} component={Paper}>
          <Table style={{ width: '100%' }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Application Date
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Applicant Name
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Application Number
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Status
                </TableCell>
                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  Sakala Number
                </TableCell>

                <TableCell
                  style={{ color: 'black', fontWeight: 'bolder' }}
                  align="center"
                >
                  View
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {registrationDriveList &&
                registrationDriveList.length &&
                registrationDriveList.map((data, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">
                      {moment(data.application_date).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell align="center">
                      {data.labour_full_name}
                    </TableCell>
                    <TableCell align="center">{data.application_no}</TableCell>
                    <TableCell align="center">
                      {data.registration_status}
                    </TableCell>
                    <TableCell align="center">{data.gsc_no}</TableCell>

                    <TableCell align="center">
                      <Link
                        to={`/admin/details/${data.labour_user_id}/${
                          data.board_id
                        }/${data.registration_status}/${'DriveDetails'}/${
                          users && users.getDeptUserRole.data[0].role_name
                        }`}
                      >
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <img alt="..." src={Eye} className="px-1" />
                          <div>View</div>
                        </div>
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : registrationDriveList && registrationDriveList.length === 0 ? (
        'No Applications Found'
      ) : null}
    </>
  );
};

export default RegistrationDriveList;
