import {
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

const ContinuationDisabilityPensionAssitance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  kannadaSelected,
}) => {
  return <></>;
};

export default ContinuationDisabilityPensionAssitance;
