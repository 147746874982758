import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Header2 from '../Header2'
import doc1 from 'assets/images/certificate-03.svg'
import doc2 from 'assets/images/doc2.svg'
import doc3 from 'assets/images/doc3.svg'
import Button2 from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom'
const IdCard = (props) => {
  return (
    <div>
        <Header2/>
        <div className='schemes-home-div'>
        <h3 className='schemesHome-title'>Issue of Duplicate Identity Card</h3>
        <p className='schemes-instructions schems-docs-details'>
        The financial assistance shall be Rs 300/- per day of the hospitalisation to the maximum of Rs 10,000/- for a continuous period of Hospitalization
        subject to actuals.<br/>
        Form 22A to be filled and attested by Doctor of any Government hospital or ESI certified private hospital or DPAR approved hospital.<br/>

        The assistance shall be towards hospitalisation for minimum 48 Hours continuously.<br/>

        Application can be submitted withtin 6 months of hospitalisation commencement date.
        </p>
        <h3 className='schemesHome-title'>Documents Required</h3>
        </div>
        <div>
    <Row className="justify-content-center">
    <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <div className='mapping-card schemes hover2'>
        <img src={doc3} alt='...'/>
           <p>Treatment Bill / Discharge Summary</p> 
        </div>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <div className='mapping-card schemes hover2'>
        <img src={doc1} alt='...'/>
            <p>Medical Certificate</p>
        </div>
        </Col>
        <Col xs={12} sm={4} md={4} className="mapping-cards-col schemes ">
        <div className='mapping-card schemes hover2'>
        <img src={doc2} alt='...'/>
            <p>Form 22A</p>
        </div>
        </Col>
    </Row>    
    </div>
    <div className='d-flex justify-content-center p-5'>
    <Button variant="success" size='lg' className='w-25' href='/'>Apply</Button>
    </div>
    <div className='d-flex justify-content-end mt-5 me-5 mb-5'>
    <Link to='/schemesHomePage'>
    <Button2 className='back-btn-mainScreens'><ArrowBackIosIcon color='secondary' className='back-icon'/>Back</Button2>
    </Link>
    </div>
    </div>
  )
}

export default IdCard