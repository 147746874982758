import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { finalSubmit } from "store/actions";
const WelfareDelivery = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    worker_name: "",
    worker_name_error: false,
    mobile_number: "",
    mobile_number_error: false,
    organization_name: "",
    organization_name_error: false,
    designation: "",
    designation_error: "",
    monthly_salary: "",
    monthly_salary_error: "",
    nature_disease: "",
    nature_disease_error: "",
    duration_treatment: "",
    duration_treatment_error: "",
    amount_spent: "",
    amount_spent_error: "",
    account_no: "",
    account_no_error: "",
    ifsc_code: "",
    ifsc_code_error: "",
    aadhar_no: "",
    aadhar_no_error: "",
    openDatePicker: false,
    state_name: "",
    district_name: "",
    taluk_name: "",
    village_name: "",
    street_name: "",
    state_name_error: "",
    district_name_error: "",
    taluk_name_error: "",
    village_name_error: "",
    street_name_error: "",

    date_of_birth: null,
    dateSelected: false,

    bank_name: "",
    bank_name_error: false,
    bank_branch_name: "",
    bank_branch_name_error: false,
    amount_spent_bank: "",
  });

  const [dateOfBirth, setDateOfBirth] = React.useState(null);

  const [selectedDischargefiles, setselectedDischargefiles] = React.useState(
    {}
  );
  const [selectedPassbookfiles, setselectedPassbookfiles] = React.useState({});
  const [selectedPhotofiles, setselectedPhotofiles] = React.useState({});
  const [selectedBirthfiles, setselectedBirthfiles] = React.useState({});

  const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false);

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [photoDoc, setphotoDoc] = React.useState({});
  const [dischargeDoc, setdischargeDoc] = React.useState({});
  const [passbookDoc, setpassbookDoc] = React.useState({});
  const [birthDoc, setbirthDoc] = React.useState({});

  const [photofilelist, setphotofilelist] = React.useState({});
  const [dischargefilelist, setdischargefilelist] = React.useState({});
  const [passbookfilelist, setpassbookfilelist] = React.useState({});
  const [birthfilelist, setbirthfilelist] = React.useState({});
  const [submit, setSubmit] = React.useState(false);

  const [filesDischargeResponse, setfilesDischargeResponse] =
    React.useState(null);
  const [filesPassbookResponse, setfilesPassbookResponse] =
    React.useState(null);
  const [filesPhotoResponse, setfilesPhotoResponse] = React.useState(null);
  const [filesBirthResponse, setfilesBirthResponse] = React.useState(null);

  const inputRef = useRef(null);
  const [fileObj, setFileObj] = useState("");
  const handleFileChange = (event) => {
    setFileObj(event.target.files && event.target.files[0]);
    if (!fileObj) {
      return;
    }
  };

  useEffect(() => {
    if (dateOfBirth !== null) {
      setAllValues({
        ...allValues,
        date_of_birth: dateOfBirth,
        dateSelected: true,
      });
      console.log("date_of_birth" + allValues.date_of_birth);
    }
  }, [dateOfBirth]);
  // const resetFileInput = (event) => {
  //     // 👇️ reset input value
  //     setFileObj(event.target.value=null)
  //   };

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };
  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };

  const handledischargeChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedDischargefiles).concat(
        Array.from(event.target.files)
      );

      setselectedDischargefiles(joined);
    }

    setloadingfiles(false);
  };

  const handlePassbookChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedPassbookfiles).concat(
        Array.from(event.target.files)
      );

      setselectedPassbookfiles(joined);
    }

    setloadingfiles(false);
  };

  const handlePhotoChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedPhotofiles).concat(
        Array.from(event.target.files)
      );

      setselectedPhotofiles(joined);
    }

    setloadingfiles(false);
  };

  const handleBirthChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedBirthfiles).concat(
        Array.from(event.target.files)
      );

      setselectedBirthfiles(joined);
    }

    setloadingfiles(false);
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data, "uploadFile"));
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const uploadDocDischarge = () => {
    let promises = [];

    selectedDischargefiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedDischargefiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setdischargeDoc(results);
          });
        }
      };
    });
  };

  const uploadDocPassbook = () => {
    let promises = [];

    selectedPassbookfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedPassbookfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setpassbookDoc(results);
          });
        }
      };
    });
  };

  // useEffect(() => {
  //     if(submit){
  //         nextButtonPressed()
  //     }
  // },[submit])

  const uploadDocPhoto = () => {
    let promises = [];

    selectedPhotofiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedPhotofiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setphotoDoc(results);
          });
        }
      };
    });
  };

  const uploadDocBirth = () => {
    let promises = [];

    selectedBirthfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedBirthfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setbirthDoc(results);
          });
        }
      };
    });
  };

  const submitFiles = (
    filesDischargeResponse,
    filesPassbookResponse,
    filesPhotoResponse,
    filesBirthResponse
  ) => {
    console.log(filesDischargeResponse, "filesResponse");
    console.log(filesPassbookResponse, "filesResponseAttested");
    console.log(filesPhotoResponse, "filesResponseMedical");
    console.log(filesBirthResponse, "filesResponseMedicalCert");

    if (
      filesDischargeResponse !== undefined &&
      filesDischargeResponse.length > 0 &&
      filesPassbookResponse !== undefined &&
      filesPassbookResponse.length > 0 &&
      filesPhotoResponse !== undefined &&
      filesPhotoResponse.length > 0 &&
      filesBirthResponse !== undefined &&
      filesBirthResponse.length > 0
    ) {
      console.log(filesDischargeResponse, "filesResponse");
      console.log(filesPassbookResponse, "filesResponseAttested");
      console.log(filesPhotoResponse, "filesResponseMedical");
      console.log(filesBirthResponse, "filesResponseMedicalCert");
    }

    finalSubmit(
      filesDischargeResponse,
      filesPassbookResponse,
      filesPhotoResponse,
      filesBirthResponse
    );
  };

  const nextButtonPressed = () => {
    if (
      allValues.worker_name !== "" &&
      allValues.worker_name !== null &&
      allValues.aadhar_no !== "" &&
      allValues.aadhar_no !== null &&
      allValues.mobile_number !== "" &&
      allValues.mobile_number !== null &&
      allValues.date_of_birth !== "" &&
      allValues.date_of_birth !== null &&
      allValues.postalAddress !== "" &&
      allValues.postalAddress !== null &&
      allValues.state_name !== "" &&
      allValues.state_name !== null &&
      allValues.district_name !== "" &&
      allValues.district_name !== null &&
      allValues.taluk_name !== "" &&
      allValues.taluk_name !== null &&
      allValues.village_name !== "" &&
      allValues.village_name !== null &&
      allValues.street_name !== "" &&
      allValues.street_name !== null &&
      allValues.bank_name !== "" &&
      allValues.bank_name !== null &&
      allValues.bank_branch_name !== "" &&
      allValues.bank_branch_name !== null &&
      allValues.account_no !== "" &&
      allValues.account_no !== null &&
      allValues.ifsc_code !== "" &&
      allValues.ifsc_code !== null
    ) {
      if (
        selectedDischargefiles !== null &&
        selectedDischargefiles.length > 0 &&
        selectedPhotofiles !== null &&
        selectedPhotofiles.length > 0 &&
        selectedPassbookfiles !== null &&
        selectedPassbookfiles.length > 0 &&
        selectedBirthfiles !== null &&
        selectedBirthfiles.length > 0
      ) {
        // uploadDocDischarge()

        // uploadDocPassbook()

        // uploadDocPhoto()

        // uploadDocBirth()
        var dataNEW = JSON.stringify({
          board_id: 1006,
          scheme_id: 32,
          labour_name: allValues.worker_name,
          phone_number: allValues.mobile_number,
          aadhaar_number: "604755673677",
          date_of_birth: "2022-06-23",
          labour_address: allValues.postalAddress,
          district_id: null,
          taluk_id: null,
          grampanchayat_city_id: null,
          village_area_ward_id: null,
          address_number_street: allValues.street_name,
          organisation_name: "ss",
          organisation_address: "dd",
          date_of_joining: "2022-06-23",
          designation: "ss",
          salary: "dd",
          nature_of_disease: "ss",
          duration_of_treatment: "sss",
          amount_spent_for_treatment: 12,
          medical_assistance_from_other_source: "ss",
          bank_name: allValues.bank_name,
          bank_branch: allValues.bank_branch_name,
          account_number: allValues.account_no,
          ifsc_code: allValues.ifsc_code,
          amount_sought: 12,
          application_name: "SOME_APPLICATION NAME",
          applicant_address: "BENGALURU",

          relationship_with_deceased: "Uncle",
          age_of_deceased: "29",
          death_date: "2022-06-22",
          name_legal_hier: "SUbhash",
          relationship_leagl_hier: "Friend",
          gratuity_confimation: "SS",
          amount_sanctioned_by_approver: 2000,
          photo: null,
          employer_document: null,
          medical_document: null,
          medical_certificate: null,
          discharge_summary: JSON.stringify(filesDischargeResponse),
          passbook: JSON.stringify(filesPassbookResponse),
          child_photo: JSON.stringify(filesPhotoResponse),
          birth_certificate: JSON.stringify(filesBirthResponse),
          signature_of_applicant: null,
          ip_address: 123123,
          device_id: 123123,
          approver_documents: null,
          district: allValues.district_name,
          taluk: allValues.taluk_name,
          grampanchayat: allValues.state_name,
          village_area_ward: allValues.village_name,
        });
        var config = {
          method: "post",
          url: SERVER + "/welfare/insert_welfare_scheme",
          data: dataNEW,
        };

        axios(config)
          .then(function (response) {
            console.error(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            console.log(
              "response get_fields_value: " + JSON.stringify(response.data)
            );
            showToast("SUCCESS", "Scheme updated successfully");
            props.history.push("/labourschemesHomePage");
          })
          .catch(function (error) {
            console.error(error);
          });
      } else {
        showToast("ERROR", "Please fill all the documents");
      }

      // uploadDoc()
    } else {
      showToast("ERROR", "Please fill all the mandatory fields");
    }
  };

  useEffect(() => {
    if (photoDoc !== null && photoDoc.length > 0) {
      var filelist = [];

      photoDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist));

      setphotofilelist(filelist);

      // submitFiles(filelist)
      setfilesPhotoResponse(filelist);
    }
  }, [photoDoc]);

  useEffect(() => {
    if (passbookDoc !== null && passbookDoc.length > 0) {
      var filelist1 = [];

      passbookDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist1));

      setpassbookfilelist(filelist1);

      // submitFiles(photofilelist,filelist1)
      setfilesPassbookResponse(filelist1);
    }
  }, [passbookDoc]);

  useEffect(() => {
    if (dischargeDoc !== null && dischargeDoc.length > 0) {
      var filelist2 = [];

      dischargeDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist2));

      setdischargefilelist(filelist2);
      setfilesDischargeResponse(filelist2);

      // submitFiles(photofilelist,passbookfilelist,filelist2)
    }
  }, [dischargeDoc]);

  useEffect(() => {
    if (birthDoc !== null && birthDoc.length > 0) {
      var filelist2 = [];

      birthDoc.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist2));

      setbirthfilelist(filelist2);
      setfilesBirthResponse(filelist2);

      // submitFiles(photofilelist,passbookfilelist,dischargefilelist,filelist2)
    }
  }, [birthDoc]);

  useEffect(() => {
    console.log("selectedDischargefiles", selectedDischargefiles);
    //  selectedPhotofiles !== null && selectedPhotofiles.length > 0 &&  selectedPassbookfiles !== null && selectedPassbookfiles.length > 0 &&  selectedBirthfiles !== null && selectedBirthfiles.length > 0 ){

    if (selectedDischargefiles !== null && selectedDischargefiles.length > 0) {
      uploadDocDischarge();
    }
  }, [selectedDischargefiles]);

  useEffect(() => {
    console.log("selectedPassbookfiles", selectedPassbookfiles);
    if (selectedPassbookfiles !== null && selectedPassbookfiles.length > 0) {
      uploadDocPassbook();
    }
  }, [selectedPassbookfiles]);

  useEffect(() => {
    console.log("selectedPhotofiles", selectedPhotofiles);
    if (selectedPhotofiles !== null && selectedPhotofiles.length > 0) {
      uploadDocPhoto();
    }
  }, [selectedPhotofiles]);

  useEffect(() => {
    console.log("selectedBirthfiles", selectedBirthfiles);
    if (selectedBirthfiles !== null && selectedBirthfiles.length > 0) {
      uploadDocBirth();
    }
  }, [selectedBirthfiles]);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };
  const [customError, setCustomError] = useState("");
  const handleChangeValidation = (ev) => {
    if (ev.target.name === "worker_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          worker_name_error: true,
        });
      }
    } else if (ev.target.name === "mobile_number") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          mobile_number_error: true,
        });
      }
    } else if (ev.target.name === "organization_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          organization_name_error: true,
        });
      }
    } else if (ev.target.name === "designation") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          designation_error: true,
        });
      }
    } else if (ev.target.name === "monthly_salary") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          monthly_salary_error: true,
        });
      }
    } else if (ev.target.name === "nature_disease") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_disease_error: true,
        });
      }
    } else if (ev.target.name === "duration_treatment") {
      if (
        validator.isAlphanumeric(ev.target.value) &&
        ev.target.value.length <= 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          duration_treatment_error: true,
        });
      }
    } else if (ev.target.name === "bank_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_name_error: true,
        });
      }
    } else if (ev.target.name === "bank_branch_name") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_branch_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          bank_branch_name_error: true,
        });
      }
    } else if (ev.target.name === "amount_spent") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length <= 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          amount_spent_error: true,
        });
      }
    } else if (ev.target.name === "account_no") {
      if (validator.isNumeric(ev.target.value) && ev.target.value.length < 20) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          account_no_error: true,
        });
      }
    } else if (ev.target.name === "ifsc_code") {
      if (validator.matches(ev.target.value, "[A-Z]{4}0[A-Z0-9]{6}$")) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          ifsc_code_error: true,
        });
      }
    } else if (ev.target.name === "aadhar_no") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 12
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,

          aadhar_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          aadhar_no_error: true,
        });
      }
    } else if (ev.target.name === "state_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          state_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          state_name_error: true,
        });
      }
    } else if (ev.target.name === "district_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          district_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          district_name_error: true,
        });
      }
    } else if (ev.target.name === "taluk_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          taluk_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          taluk_name_error: true,
        });
      }
    } else if (ev.target.name === "village_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          village_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          village_name_error: true,
        });
      }
    } else if (ev.target.name === "street_name") {
      if (
        validator.isAlphanumeric(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          street_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          street_name_error: true,
        });
      }
    } else {
      setAllValues({
        ...allValues,
        [ev.target.name]: ev.target.value,
      });
    }
  };

  return (
    <div>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/boardsHome'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='labourTitle' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={allValues.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          <h1>
            {" "}
            <Translate value='schemes' /> |
            <Translate value='deliveryTitle' />
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='workerDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='beneficiaryName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='worker_name'
              value={allValues.worker_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.worker_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.worker_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='mobileNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='mobile_number'
              value={allValues.mobile_number}
              onChange={handleChangeValidation}
              helperText={
                allValues.mobile_number_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.mobile_number_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          <p className='mb-2'>
            <Translate value='aadharNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='aadhar_no'
              value={allValues.aadhar_no}
              onChange={handleChangeValidation}
              helperText={
                allValues.aadhar_no_error
                  ? "Please Enter Correct Format only"
                  : ""
              }
              error={allValues.aadhar_no_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='dateofBirth' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <KeyboardDatePicker
                className='datepicker'
                // margin="normal"
                format='DD/MM/YYYY'
                value={allValues.date_of_birth}
                onChange={(date) => {
                  setDateOfBirth(date);
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='address' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='postalAddress'
              value={allValues.postalAddress}
              onChange={handleChangeValidation}
              // helperText={allValues.aadhar_no_error_error ? "Please Enter Correct Format only":""}
              // error = {allValues.aadhar_no_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='state' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='state_name'
              value={allValues.state_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.state_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.state_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='districtName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='district_name'
              value={allValues.district_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.district_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.district_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='talukName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='taluk_name'
              value={allValues.taluk_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.taluk_name_error ? "Please Enter Alphabets only" : ""
              }
              error={allValues.taluk_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='villageWardName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='village_name'
              value={allValues.village_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.village_name_error
                  ? "Please Enter Alphabets only"
                  : ""
              }
              error={allValues.village_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} className='mt-1'>
          <p className='mb-2'>
            <Translate value='numberandStreet' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='street_name'
              value={allValues.street_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.street_name_error
                  ? "Please Enter Correct Format only"
                  : ""
              }
              error={allValues.street_name_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='bankDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2'>
            <Translate value='bankName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.bank_name}
              name='bank_name'
              onChange={handleChangeValidation}
              helperText={
                allValues.bank_name_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.bank_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='bankBranch' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.bank_branch_name}
              name='bank_branch_name'
              onChange={handleChangeValidation}
              helperText={
                allValues.bank_branch_name_error
                  ? "Please Enter Numbers only"
                  : ""
              }
              error={allValues.bank_branch_name_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='accountNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.account_no}
              name='account_no'
              onChange={handleChangeValidation}
              helperText={
                allValues.account_no_error ? "Please Enter Numbers only" : ""
              }
              error={allValues.account_no_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} className='mt-2'>
          <p className='mb-2 w-50'>
            <Translate value='iFSCCode' /> *
          </p>

          <FormControl fullWidth className='formcontrol1 w-75'>
            <TextField
              variant='outlined'
              value={allValues.ifsc_code}
              name='ifsc_code'
              onChange={handleChangeValidation}
              helperText={
                allValues.ifsc_code_error
                  ? "Please Enter Correct Format only"
                  : ""
              }
              error={allValues.ifsc_code_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='uploadDocs' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='dischargeSummary' /> *
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedDischargefiles !== null &&
                selectedDischargefiles.length > 0 &&
                Array.from(selectedDischargefiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='discharge'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handledischargeChange}
                id={`upload-photo`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='passbook' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedPassbookfiles !== null &&
                selectedPassbookfiles.length > 0 &&
                Array.from(selectedPassbookfiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='passbook'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo2`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handlePassbookChange}
                id={`upload-photo2`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='photoofchild' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedPhotofiles !== null &&
                selectedPhotofiles.length > 0 &&
                Array.from(selectedPhotofiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='photo'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo3`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handlePhotoChange}
                id={`upload-photo3`}
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-50'>
            <Translate value='birthCert' style={{ whiteSpace: "nowrap" }} />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedBirthfiles !== null &&
                selectedBirthfiles.length > 0 &&
                Array.from(selectedBirthfiles).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='birth'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo4`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                onChange={handleBirthChange}
                id={`upload-photo4`}
              />
            </div>
          </div>
        </Col>
      </Row>
      <Row className='search-subtitle-row pb-5'>
        <Col xs={12} className='next-back-button-row mt-4 '>
          <>
            <Link
              to='/delivery2'
              onClick={props.backButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='danger' className='back-button'>
                <Translate value='back' />
              </Button>
            </Link>
            <Link
              to='#'
              onClick={nextButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='outline-primary' className='save-button'>
                <Translate value='submit' />
              </Button>
            </Link>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default WelfareDelivery;
