import React, { useEffect, useState } from 'react';
import NomineeFilters from './NomineeFilters';
import { getNomineeDetailsAPI } from 'services/NomineeUpdate.service';
import { getCatalogDetailsAPI } from 'services/catalog.service';
import { getSchemesAPI } from 'services/schemes.service';
import { useSelector } from 'react-redux';
import { showToast } from 'utils/tools';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import NomineeTableComponent from './NomineeTableComponent';

const UpdateNominee = () => {
  const [status, setStatus] = useState(null);
  const [scheme, setScheme] = useState(null);
  const [statusID, setStatusID] = useState(null);
  const [schemeID, setSchemeID] = useState(null);
  const [nomineeDetails, setNomineeDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [submittedStatus, setSubmittedStatus] = useState(null);

  const users = useSelector((state) => state.users);
  const departmentUserID = users?.getDeptUserRole?.data[0]?.department_user_id;

  const callNomineeDetails = async (payload) => {
    try {
      const response = await getNomineeDetailsAPI(payload);
      setNomineeDetails(response?.data);
    } catch (error) {
      if (error?.response?.data?.errors?.msg) {
        showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
      } else {
        showToast('ERROR', 'Something went wrong');
      }
    }
  };
  const getCatalogDetails = async (payload) => {
    try {
      const response = await getCatalogDetailsAPI(payload);
      return response?.data;
    } catch (error) {}
  };
  const getSchemes = async () => {
    try {
      const response = await getSchemesAPI();
      setScheme(response?.data);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchCatalog = async () => {
      const statusPayload = {
        board_id: 1,
        catalog_name: 'Registration Status',
      };

      const statusResponse = await getCatalogDetails(statusPayload);
      setStatus(statusResponse);
    };
    fetchCatalog();
    getSchemes();
  }, []);

  const handleSubmit = async () => {
    if (statusID) {
      const payload = {
        departmentUserID: departmentUserID,
        statusID: statusID,
        schemeID: schemeID,
      };
      setLoading(true);
      try {
        await callNomineeDetails(payload);
        setSubmittedStatus(payload.statusID);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        if (error?.response?.data?.errors?.msg) {
          showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      }
    } else {
      showToast('ERROR', 'Please select application status');
    }
  };

  return (
    <>
      <NomineeFilters
        status={status}
        scheme={scheme}
        schemeID={schemeID}
        setSchemeID={setSchemeID}
        statusID={statusID}
        setStatusID={setStatusID}
        handleSubmit={handleSubmit}
      />
      {loading && <SimpleBackdrop />}
      {!loading && nomineeDetails && nomineeDetails.length ? (
        <NomineeTableComponent
          nomineeDetails={nomineeDetails}
          statusID={submittedStatus}
        />
      ) : !loading && nomineeDetails && nomineeDetails ? (
        <p className="text-center fs-3">No Applications Found</p>
      ) : null}
    </>
  );
};

export default UpdateNominee;
