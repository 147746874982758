import { Box, Paper } from '@material-ui/core'
import React, { useState } from 'react'
import Button2 from '@mui/material/Button';
import { getTokenCookie, showToast } from 'utils/tools';
import { SERVER } from 'store/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

const TransactionPassword = () => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    console.log(users,"USERS::");

    const [oldPassword,setOldPassword] = useState("")
    const [newPassword,setNewPassword] = useState("")
    const [confirmPassword,setConfirmPassword] = useState("")


const handleChange = (ev) => {
    if(ev.target.name === "oldpassword"){
        setOldPassword(ev.target.value)
    }
    else if(ev.target.name === "newpassword"){
        setNewPassword(ev.target.value)
    }
    else if(ev.target.name === "confirmpassword"){
        setConfirmPassword(ev.target.value)
    }
}    

const handleSubmit = async(ev) => {
    ev.preventDefault()
    if(newPassword && confirmPassword)
    {
        if(newPassword.length <= 6 && newPassword.length > 15)
        {
            showToast("WARN","Password should be between 6 and 15 characters")
        }
        else{
            if(newPassword === confirmPassword){
                
                const payload = {
                     "login_id": users.login_admin.data.department_user_id,
                     "username": users.login_admin.data.username,
                     "old_password": oldPassword ? oldPassword : null,
                     "new_password": newPassword
                }
                const config = {
                    method:"PATCH",
                    url:`${SERVER}/config/dept_user/transaction-password`,
                    headers:{
                        'Authorization':`Bearer ${getTokenCookie()}`
                    },
                    data:payload
                }
                await axios(config).then((res) => {
                    console.log("Transaction Response",res.data);
                    showToast("SUCCESS",`${res.data.message}`)
                }).catch((err)=>{
                    console.log(err);
                })

            }
            else{
                showToast("ERROR","Password do not match")
            }
            
        }
    }
    else{
        showToast("ERROR","Please fill all the fields")
    }
}

  return (
    <>
        <div className="root">
            <Box
      sx={{
        display: 'flex',
        justifyContent:'start',
        alignItems:'center',
        flexWrap: 'wrap',
        '& > :not(style)': {
          m: 1,
          width: 400,
          height: 400,
        },
      }}
    >
<Paper>
<div className='d-flex justify-content-center align-items-center flex-column p-2 m-2' style={{}}>
<p style={{fontSize:'24px',color:'#CA0027',padding:'2px',margin:'2px',fontWeight:'700'}}>Transcation Password</p>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Enter Existing Password'
name="oldpassword"
value={oldPassword}
onChange={handleChange}

/>
</div>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Enter New Password'
    name="newpassword"
    value={newPassword}
    onChange={handleChange}
/>
</div>
<div className='p-2 m-2'>
<input className='change-password-input' type='password' placeholder='Confirm Password'
    name="confirmpassword"
    value={confirmPassword}
    onChange={handleChange}
/>

</div>
<div className='p-2 m-2'> 
 <Button2 onClick={handleSubmit} className='change-password-submit-btn' variant='contained' color='error'>Submit</Button2>
</div>
</div>
</Paper>
</Box>
        
                        
</div> 
    </>
  )
}

export default TransactionPassword