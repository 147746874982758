import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, Tooltip, CircularProgress } from '@material-ui/core';
import { PhoneAndroid, CameraAlt, Cached, FormatColorReset } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Webcam from "react-webcam";
import { Link } from 'react-router-dom';
// import folder from 'assets/images/folder (3).svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
// import webcam from 'assets/images/web-cam.svg'
import webcam from 'assets/images/web cam-01.svg'
// import aadhar from 'assets/images/id-card.svg'
import aadharRed from 'assets/images/Aadhar black-01.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
// import pan from 'assets/images/credit-card.svg'
import panRed from 'assets/images/PAN red-01.svg'
import pan from 'assets/images/PAN Black-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
import male from 'assets/images/Mask Group 1.svg'
import female from 'assets/images/Mask Group 2.svg'
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addLabourPersonalDetails, deleteReduxData, getCatalogDetails, getFile, getStateDetails, getUser, getUserRegistrationDetails, SERVER, TS_USERNAME, updateGetFile, updatePersonalData, uploadFile } from 'store/actions/user.actions';
import { getTokenCookie, Required, saveLog, showToast } from 'utils/tools';
import axios from 'axios';
import cookie from 'react-cookies';
import validator from 'validator';
import { Translate } from 'react-redux-i18n';
import moment from 'moment';
import { useLocation } from "react-router-dom";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';



const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "user",
    // facingMode: { exact: "environment" }
};

const Personal = (props) => {

    const myRef = React.createRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const location = useLocation();
    const history = useHistory();


    const [allValues, setAllValues] = React.useState({
        language: "",
        selectedDate: null,
        phoneNumber: "",
        maritalStatus: "",
        category: "",
        migrantState: "",
        isMigrant: false,
        pancheck: false,
        aadharcheck: false,
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,
        formcontrolnum: 1,
        aadharnum1: null,
        aadharnum2: null,
        aadharnum3: null,
        aadhar: null,
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        fatherHusbandName: "",
        pan: "",
        gender: null,
        dob: null,
        documentUploadResponse: "",
        aadhaarFetchedData: null,
        firstnameError:false,
        firstnameErrorMessage:"",
        lastnameError:false,
        lastnameErrorMessage:"",
        panError:false,
        emailError:false
        // panErrorMessage:""
    })
    // const [allValues, setAllValues] = React.useState({
    //     language: "",
    //     addLabourPersonalDetails: users.addLabourPersonalDetails,
    //     maritalStatus: users.personal_details.maritalStatus,
    //     users: props.users,
    //     phoneNumber: users.personal_details.phoneNumber,
    //     category: users.personal_details.category,
    //     migrantState: users.personal_details.migrantState,
    //     isMigrant: users.personal_details.isMigrant,
    //     pancheck: users.personal_details.pancheck,
    //     aadharcheck: users.personal_details.aadharcheck,
    //     imgfile: users.personal_details.imgfile,
    //     webcamEnabled: users.personal_details.webcamEnabled,
    //     open: users.personal_details.open,
    //     setOpen: users.personal_details.setOpen,
    //     openDatePicker: users.personal_details.openDatePicker,
    //     formcontrolnum: users.personal_details.formcontrolnum,
    //     aadharnum1: users.personal_details.aadharnum1,
    //     aadharnum2: users.personal_details.aadharnum2,
    //     aadharnum3: users.personal_details.aadharnum3,
    //     aadhar: users.personal_details.aadhar,
    //     firstname: users.personal_details.firstname,
    //     middlename: users.personal_details.middlename,
    //     lastname: users.personal_details.lastname,
    //     email: users.personal_details.email,
    //     fatherHusbandName: users.personal_details.fatherHusbandName,
    //     pan: users.personal_details.pan,
    //     gender: users.personal_details.gender,
    //     dob: users.personal_details.dob,
    //     documentUploadResponse: users.personal_details.documentUploadResponse,
    //     aadhaarFetchedData: users.personal_details.aadhaarFetchedData
    // })

    const [AadhaarDecrypted, setAadhaarDecrypted] = React.useState('')
    const [phone, setPhone] = React.useState('')
    const [loading, setLoading] = React.useState(false);
    const [aadharDuplicateError, setaadharDuplicateError] = React.useState(false);
    const [profilePic, setprofilePic] = React.useState('');

    const [rotateRefressh, setrotateRefressh] = React.useState('rotate');
    const [captchaError, setcaptchaError] = React.useState('');
    const [aadhaarToken, setAadhaarToken] = React.useState(null);
    const [redirectback, setRedirectback] = React.useState(false);


    

    



    useEffect(() => {
        if(profilePic !== undefined && profilePic !== "" && profilePic !== null){
            sessionStorage.setItem('profilePic', profilePic)
        }
    }, [profilePic]);

    useEffect(() => {
        setprofilePic(sessionStorage.getItem('profilePic'))
    }, [sessionStorage.getItem('profilePic')]);


    useEffect(() => {
        setAllValues({ ...allValues, phoneNumber: phone })

        dispatch(getStateDetails())
    }, [phone]);

    useEffect(() => {
       
        console.log("propstatePersonal", props)

    },[allValues])


    // useEffect(() => {
    //     // console.log("in useEffect []")

    //     if (users.generate_otp !== null) {
    //         setPhone(users.generate_otp.data.phone_number)
    //         // setAllValues({...allValues, phoneNumber: users.generate_otp.data.phone_number})
    //         dispatch(getUser(users.generate_otp.data.phone_number))
    //     }
    //     else if (users.generate_otp_registration !== null) {
    //         setPhone(users.generate_otp_registration.data.phone_number)
    //         // setAllValues({...allValues, phoneNumber: users.generate_otp_registration.data.phone_number})
    //         dispatch(getUser(users.generate_otp_registration.data.phone_number))
    //     }
    //     else {
    //         props.history.push("/")
    //     }

    //     // dispatch(getFile("delete"))
    //     // dispatch(uploadFile("delete"))
    //     // dispatch(addLabourPersonalDetails("delete"))
    //     dispatch(deleteReduxData("getFile"))
    //     dispatch(deleteReduxData("uploadFile"))
    //     dispatch(deleteReduxData("addLabourPersonalDetails"))
    //     sessionStorage.removeItem('profilePic')

    //     getNewCaptcha()
        
    //     setAllValues({
    //         ...allValues,
    //         upload_file: null
    //     })
    //     if (window.location.toString().includes("txnno")){
                        
    //               var config = {
    //                 method: 'post',
    //                 url: "https://gcdmsdev.web.app/egov/ekyc_response",
    //                 headers: { 
    //                     'Content-Type': 'application/json', 
    //                 },
                   
    //               };
                  
    //               axios(config)
    //               .then(function (response) {
              
    //                 console.log("checkAadhaarDuplicate response:"+JSON.stringify(response));
                   
                     
              
                        
    //                 })

    //     }
       
        
    // }, []);

    // useEffect(() => {
    //     if(redirectback){
    //     console.log("inside_redirect")
    //     }

    // },[redirectback])


    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {

            let dataForRegDetails = { 
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "personal_details"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))

            let dataForCatalog = { "board_id": users.user.board_id, "catalog_name": "Marital Status" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))

            dispatch(getCatalogDetails(dataForCatalog))

            let dataForCatalog2 = { "board_id": users.user.board_id, "catalog_name": "Category" };
            // console.log("dataForCatalog2: " + JSON.stringify(dataForCatalog2))

            dispatch(getCatalogDetails(dataForCatalog2))
        }
       

    }, [users.user])

    useEffect(() => {
       if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.length > 0 ){
             console.error("inside if tab_name === personal_details")
                var val = users.getUserRegistrationDetails[0]

                var str = users.getUserRegistrationDetails[0].aadhar_no;
                var aadharArr = [];
                var chunkSize = 4;

                while (str) {
                    if (str.length < chunkSize) {
                        aadharArr.push(str);
                        break;
                    }
                    else {
                        aadharArr.push(str.substr(0, chunkSize));
                        str = str.substr(chunkSize);
                    }
                }

                console.error("aadharArr: "+aadharArr)

                getimage(val.user_photo_id)


                if(
                    users.getUserRegistrationDetails[0].encrypted_aadhar !== undefined &&
                    users.getUserRegistrationDetails[0].encrypted_aadhar !== null &&
                    users.getUserRegistrationDetails[0].encrypted_aadhar !== ""
                    ){
                        var data = JSON.stringify({
                            "decryptString": users.getUserRegistrationDetails[0].encrypted_aadhar
                          });
                          
                          var config = {
                            method: 'post',
                            url: SERVER+'/global/decrypt_data',
                            headers: { 
                              'Content-Type': 'application/json'
                            },
                            data : data
                          };
                          
                          axios(config)
                          .then(function (response) {
                            console.log("AadhaarDecrypted: "+JSON.stringify(response.data));
                            // console.log("AadhaarDecrypted: "+JSON.stringify(response.data.data.decryptedData));
                            setAadhaarDecrypted(response.data.data.decryptedData)
                          })
                          .catch(function (error) {
                            console.log(error);
                          });
                }

        

                setAllValues({
                    ...allValues,
                    maritalStatus: val.catalog_value_marital_status_id,
                    phoneNumber: val.mobile_no,
                    category: val.catalog_value_caste_id,
                    migrantState: val.migrate_from_state_id,
                    isMigrant: val.is_inter_state_migrant ? "1":"0",
                    aadharnum1: aadharArr[0],
                    aadharnum2: aadharArr[1],
                    aadharnum3: aadharArr[2],
                    aadhar: val.aadhar_no,
                    firstname: val.first_name,
                    middlename: val.middle_name,
                    lastname: val.last_name,
                    email: val.mail_id,
                    // fatherHusbandName: users.personal_details.fatherHusbandName,
                    pan: val.pan_no,
                    gender: String(val.catalog_value_gender_id),
                    dob: val.date_of_birth,
                    documentUploadResponse:{
                        fileId: val.user_photo_id
                    },
                    aadharcheck: val.is_aadhar_verified,
                    is_aadhar_verified: val.is_aadhar_verified
                })

                setDob(val.date_of_birth)
            // }
        }
    }, [users.getUserRegistrationDetails]);


    const getimage = (data) =>{
        console.error("getimage data: "+data)
        if(data !== null){
            dispatch(getFile(data))
        }
    }

    const [marital, setMarital] = React.useState([])
    const [category, setCategory] = React.useState([])
    const [states, setStates] = React.useState([])
    const [imagesavedinlocal, setimagesavedinlocal] = React.useState(false)

    useEffect(() =>{
        if(users.getFile !== null){
        sessionStorage.setItem('profilePic', users.getFile.data)
        }
    },[users.getFile])

    useEffect(() => {
        setStates(users.state_details)
    }, [users.state_details]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Marital Status") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setMarital(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Category") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setCategory(users.catalog_details)
        }
    }, [users.catalog_details]);

    const handleAadharChange = (e, num) => {
        // console.log('handleAadharChange num:' + num)
        // console.log(e.target.value)
        // console.log(e.target.value.length)

        if (e.target.value.length === 4) {
            const nextSibling = document.querySelector(
                `.aadhardiv${num + 1}>div>input`
            );
            if (num === 1) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum1: e.target.value,
                    })
                }
            }
            else if (num === 2) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum2: e.target.value,
                    })
                }
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                    aadhar: allValues.aadharnum1 + allValues.aadharnum2 + e.target.value,
                });
            }
        }
        else{
            // console.log("num:"+num)
            if (num === 1) {
                    setAllValues({
                        ...allValues,
                        aadharnum1: e.target.value,
                    })
            }
            else if (num === 2) {
                setAllValues({
                    ...allValues,
                    aadharnum2: e.target.value,
                })
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                })
            }
        }
    }

    const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

    const handleClickOpen = () => {
        setAllValues({
            ...allValues,
            open: !allValues.open
        })
    };

    const handleClose = () => {
        setAllValues({
            ...allValues,
            open: !allValues.open
        })
    };


    const capture = () => {
        const imageSrc = myRef.current.getScreenshot();
        // console.log(imageSrc)

        sessionStorage.setItem('profilePic', imageSrc)
        setprofilePic(imageSrc)
        setAllValues({
            ...allValues,
            // imgfile: imageSrc,
            open: !allValues.open
        })
    }

    const handleChange = (event) => {
        // console.log(event.target)
        // console.log("in handleChange: value="+event.target.value+" name="+event.target.name)

        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })

    }

    const changeHandler = e => {
        // console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

        if (e.target.name === "firstname") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length !== 0 && e.target.value.length < 15){
                setAllValues({
                    ...allValues,
                    firstnameError: false,
                    firstnameErrorMessage: "",
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    firstnameError: true,
                    firstnameErrorMessage: "Enter Correct Name!",
                    [e.target.name]: e.target.value
                })}
        }
        else if (e.target.name === "middlename") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length < 15 || e.target.value.length === 0  ){
                setAllValues({
                    ...allValues,
                    middlenameError: false,
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    middlenameError: true,
                    [e.target.name]: e.target.value
                })}
        }
        
        else if (e.target.name === "lastname"  ) {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length < 15 || e.target.value.length === 0){
                setAllValues({
                    ...allValues,
                    lastnameError: false,
                    lastnameErrorMessage: "",
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    lastnameError: true,
                    lastnameErrorMessage: "Enter Correct Name!",
                    [e.target.name]: e.target.value
                })}
        }

        else if (e.target.name === "pan") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)
            if (validator.matches((e.target.value),'[A-Z]{5}[0-9]{4}[A-Z]{1}') && (e.target.value.length) == 10){
                setAllValues({
                    ...allValues,
                    panError: false,
                    // panErrorMessage: "",
                    [e.target.name]: e.target.value
                })}
            else if (e.target.value.length === 0){
                setAllValues({
                    ...allValues,
                    panError: false,
                    // panErrorMessage: "",
                    [e.target.name]: e.target.value
                })

            }
            else{
                setAllValues({
                    ...allValues,
                    panError: true,
                    // panErrorMessage: "Enter Correct Name!",
                    [e.target.name]: e.target.value
            })}
        }

        else if(e.target.name === "email"){
            if(validator.isEmail(e.target.value) && (e.target.value.length < 30)){
                setAllValues({
                    ...allValues,
                    emailError:false,
                    [e.target.name]: e.target.value

                })
            }else{
                setAllValues({
                    ...allValues,
                    emailError:true,
                    [e.target.name]: e.target.value

                })
            }
        }

    }

    



    



    const [dob, setDob] = React.useState(users.personal_details.dob)

    useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
        setAllValues({ ...allValues, dob: dob })
    }, [dob]);

    useEffect(() => {
        console.log("in useEffect [allValues]: ",allValues)
        dispatch(updatePersonalData(allValues))
    }, [allValues]);

    const handleFileChange = (event) => {
        if(event.target.files[0] !== null)
        { 
            if(event.target.files[0].size > 2000001){
                showToast("ERROR", "Please upload file of size less than 2MB.")
            }
            else if(!event.target.files[0].type.includes("image")){
                showToast("ERROR", "Please upload file in JPEG or PNG format.")
            }
            else{
            // var selectedblob = URL.createObjectURL(event.target.files[0])
            var selectedblob = event.target.files[0]
            var reader = new FileReader();
            reader.readAsDataURL(selectedblob);
            reader.onloadend = function () {
                var base64data = reader.result;
                console.log(base64data);

                sessionStorage.setItem('profilePic', base64data)
                setprofilePic(base64data)
                setAllValues({
                    ...allValues,
                    // imgfile: URL.createObjectURL(event.target.files[0])
                    // imgfile: base64data,
                    documentName: event.target.files[0].name
                })

                // uploadProfilePic(base64data)
            }
            }
        }

    }

    const uploadProfilePic = (base64data) => {
        if(base64data !== null && users.user !== null){
            let formdata = {
                'file': base64data,
                'userId': users.user.id,
                'fileType': 'user_pic'
            }
            console.error("formdata: "+ JSON.stringify(formdata))
            dispatch(uploadFile(formdata,"user_pic"))
        }
    }

    // const handleEmailSubmit = ()=>{
        
    //     if(validator.isEmail(allValues.email)){
    //         setAllValues({
    //             ...allValues,
    //             emailError:false
    //         })
    //     }else{
    //         setAllValues({
    //             ...allValues,
    //             emailError:true
    //         })
    //     }
    // }

    const handleEnter = () => {
        // console.log("in handleEnter")

        var next_formcontrolnum = allValues.formcontrolnum + 1

        var nextSibling = document.querySelector(
            `.formcontrol${next_formcontrolnum} `
        );
        var nextSibling_input = document.querySelector(
            `.formcontrol${next_formcontrolnum} input`
        );
        // console.log("nextSibling:" + nextSibling.className)
        // console.log("nextSibling_input:" + nextSibling_input.className)

        if (nextSibling !== null) {
            if (nextSibling_input.className.includes("Mui-disabled")) {
                next_formcontrolnum = allValues.formcontrolnum + 2
                nextSibling = document.querySelector(
                    `.formcontrol${next_formcontrolnum} `
                );
                nextSibling_input = document.querySelector(
                    `.formcontrol${next_formcontrolnum} input`
                );
            }
            else if (nextSibling_input.className.includes("MuiSelect-nativeInput")) {
                nextSibling_input = document.querySelector(
                    `.formcontrol${next_formcontrolnum} .MuiSelect-select`
                );
            }
            nextSibling_input.focus();

            setAllValues({
                ...allValues,
                formcontrolnum: next_formcontrolnum
            })
        }

        // let   regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        // console.log(regex.test(users.personal_details.pan),"hkavkbas")
        // if(regex.test(users.personal_details.pan)){
        //     setAllValues({
        //         ...allValues,
        //         panError:false
        //     })
        // }else{
        //     setAllValues({
        //         ...allValues,
        //         panError:true
        //     })
        //     console.log("error",allValues.panError)
        // }
    }

    const handleFormFocus = (data) => {
        console.log("in handleFormFocus data:" + data)
    }    

    useEffect(() => {
        // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
        if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'user_pic') {


            if (users.upload_file.userFileName === "user_pic") {
                // setAllValues({
                //     ...allValues,
                //     documentUploadResponse: users.upload_file.image
                // }, () =>{

                // })
                submitPersonal(users.upload_file.image)



            }


        }
        else if (users.upload_file !== "" && users.upload_file !== null && users.upload_file.success === undefined) {
            showToast("ERROR", "Cannot upload the profile image. Please try later.")
        }
    }, [users.upload_file]);


    const nextButtonPressed = () => {
        console.log("users.personal_details.panError:",users.personal_details.panError)
        console.log("users.personal_details.is_aadhar_verified:",users.personal_details.is_aadhar_verified)
        if (
            users.personal_details.firstname === "" ||
            // users.personal_details.lastname === "" ||
            users.personal_details.gender === "null" ||
            users.personal_details.gender === "" ||

            // users.personal_details.aadhar === 0 ||
            users.personal_details.maritalStatus === "" ||
            users.personal_details.maritalStatus === null ||
            users.personal_details.dob === null ||
            users.personal_details.phoneNumber === "" ||
            // users.personal_details.pan === "" ||
            // users.personal_details.isMigrant === "" || 
            // users.personal_details.imgfile === "" ||
            // users.personal_details.aadharcheck === false ||
            // users.personal_details.is_aadhar_verified === null ||
            // users.personal_details.is_aadhar_verified === undefined ||
            // users.personal_details.is_aadhar_verified === false ||
            sessionStorage.getItem('profilePic') === null ||
            users.personal_details.firstnameError === true ||
            users.personal_details.middlenameError === true ||
            users.personal_details.lastnameError === true ||
            users.personal_details.panError === true 


        ) {
            showToast("WARN", "Please Fill All the Required Fields to Continue!")
        }
        else if (
            users.personal_details.isMigrant === "1" && 
            ( users.personal_details.migrantState === "" || users.personal_details.migrantState === null )
            ) {
            showToast("WARN", "Please Select the Migration State!")
        }
        else {

            uploadProfilePic(sessionStorage.getItem('profilePic'))

        }
    }

    const submitPersonal = (documentUploadResponse) => {

        var AadhaarDecryptedFinal = ''
        if(AadhaarDecrypted !== ''){
            AadhaarDecryptedFinal = AadhaarDecrypted
        }
        else{
            AadhaarDecryptedFinal = users.personal_details.aadhar
        }


        let dataToSubmit = {
            "first_name": users.personal_details.firstname,
            "first_name_kannada": null,
            "first_name_hindi": null,
            "middle_name_kannada": null,
            "middle_name_hindi": null,
            "middle_name": users.personal_details.middlename,
            "last_name": users.personal_details.lastname,
            "last_name_kannada": null,
            "last_name_hindi": null,
            "user_id": users.user.id,
            "email": users.personal_details.email,
            // "aadhar_no": users.personal_details.aadhar,
            "aadhar_no": AadhaarDecryptedFinal,
            "pan_no": users.personal_details.pan,
            "martial_status_id": users.personal_details.maritalStatus,
            "caste_id": users.personal_details.category,
            "gender_id": users.personal_details.gender,
            "is_inter_state_migrant": users.personal_details.isMigrant,
            "migrate_from_state_id": users.personal_details.migrantState,
            "user_photo_id": documentUploadResponse.fileId,
            "dob": users.personal_details.dob,
            "is_aadhar_verified": users.personal_details.is_aadhar_verified,
            "catalog_value_qualification_id": null,
            "catalog_value_employement_status": null,
            "income_earned":"",
            "pf_esi_number":"",
            "employer_name": "",
            "employer_contact_country_code": "",
            "employer_contact_number": ""
        }

        console.error("dataToSubmit", JSON.stringify(dataToSubmit))

        dispatch(addLabourPersonalDetails(dataToSubmit))
    }

    const initPersonalState = {
        selectedDate: null,
        phoneNumber: "",
        maritalStatus: "",
        category: "",
        migrantState: "",
        isMigrant: false,
        pancheck: false,
        aadharcheck: false,
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,
        formcontrolnum: 1,
        aadharnum1: null,
        aadharnum2: null,
        aadharnum3: null,
        aadhar: null,
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        fatherHusbandName: "",
        pan: "",
        gender: "",
        dob: null,
        documentUploadResponse: ""
    }

    useEffect(() => {
        console.error("useEffect personalSubmitted:"+allValues.personalSubmitted)
        // if(!allValues.personalSubmitted){
            // console.error("useEffect !personalSunload Abmitted in if")
            // console.error("users.addLabourPersonalDetails.code: "+users.addLabourPersonalDetails.code)
            if (users.addLabourPersonalDetails !== null && 
                users.addLabourPersonalDetails.error !== true && 
                users.addLabourPersonalDetails.success !== undefined && 
                users.addLabourPersonalDetails.success !== null && 
                users.addLabourPersonalDetails.success === true) {
                console.error("useEffect !allValues.personalSubmitted in if > if")
                showToast("SUCCESS", "Personal Details Saved Successfully!")
                
                dispatch(updatePersonalData(initPersonalState))
                // dispatch(getFile("delete"))
                // dispatch(uploadFile("delete"))
                // dispatch(addLabourPersonalDetails("delete"))

                dispatch(deleteReduxData("getFile"))
                dispatch(deleteReduxData("uploadFile"))
                dispatch(deleteReduxData("addLabourPersonalDetails"))

                if (users.profile_details.editmode) {
                    props.saveEditButtonPressed()
                }
                else {
                    props.nextButtonPressed()
                }
            }
            else if (users.addLabourPersonalDetails !== null && 
                users.addLabourPersonalDetails.error !== true && 
                users.addLabourPersonalDetails.success !== undefined && 
                users.addLabourPersonalDetails.success !== null && 
                users.addLabourPersonalDetails.success === "false") {
                console.error("useEffect !allValues.personalSubmitted in if > else if success")
                showToast("ERROR", "Error Saving Personal Details!")
                setLoading(false);
            }
            else if (users.addLabourPersonalDetails !== null && 
                users.addLabourPersonalDetails.error !== undefined && 
                users.addLabourPersonalDetails.error === true) {
                console.error("useEffect !allValues.personalSubmitted in if > else if error")
                showToast("ERROR", "Error Saving Personal Details!")
                setLoading(false);
            }

        // }
    }, [users.addLabourPersonalDetails])



    const [captchaDivShow, setcaptchaDivShow] = React.useState(true);
    const [otpSent, setotpSent] = React.useState(false);

    const [captcha, setcaptcha] = React.useState("");
    const [secretToken, setsecretToken] = React.useState("");
    const [tsTransID, settsTransID] = React.useState("");
    const [tsTransIDAfterOtp, settsTransIDAfterOtp] = React.useState("");

    const getNewCaptcha = () => {

        setcaptcha("")
        setsecretToken("")
        settsTransID("")
        setrotateRefressh('rotate')
        setcaptchaError('')


        var randNum = Math.floor(100000 + Math.random() * 900000)
        console.log("randNum: " + randNum);

        var data = JSON.stringify({
            "transID": randNum,
            "docType": "347"
        });
        var config = {
            method: 'post',
            url: SERVER + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step1 response:" + JSON.stringify(response.data));


                // STEP 2
                var data2 = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER+'/global/auth_bridge_generate_captcha',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data2
                };

                axios(config)
                    .then(function (response) {
                        console.log("step2 response:" + JSON.stringify(response.data));

                        //step3

                        var data3 = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER+'/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data3
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step3 response: " + JSON.stringify(response.data));
                                setcaptcha(response.data.data.msg.captchaCode)
                                // setAllValues({
                                //     ...allValues,
                                //     secretToken: response.data.data.msg.secretToken,
                                //     tsTransID: response.data.data.msg.tsTransID,
                                // })
                                setsecretToken(response.data.data.msg.secretToken)
                                settsTransID(response.data.data.msg.tsTransID)

                                setrotateRefressh('')
                            })
                            .catch(function (error) {
                                console.error("step3 error: " + error);
                                if(error.response !== undefined &&
                                    error.response.data !== undefined &&
                                    error.response.data.errors !== undefined &&
                                    error.response.data.errors.msg !== undefined && 
                                    error.response.data.errors.msg.msg !== undefined  
                                    ){
                                        console.error("step3 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                                        setcaptchaError(error.response.data.errors.msg.msg)
                                    }
                                setrotateRefressh('')
                            });

                    })
                    .catch(function (error) {
                        console.error("step2 error: " + error);
                        if(error.response !== undefined &&
                            error.response.data !== undefined &&
                            error.response.data.errors !== undefined &&
                            error.response.data.errors.msg !== undefined && 
                            error.response.data.errors.msg.msg !== undefined  
                            ){
                                console.error("step2 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                                setcaptchaError(error.response.data.errors.msg.msg)
                            }
                        setLoading(false);
                        setrotateRefressh('')
                    });

            })
            .catch(function (error) {
                console.error("step1 error: " + error);
                if(error.response !== undefined &&
                    error.response.data !== undefined &&
                    error.response.data.errors !== undefined &&
                    error.response.data.errors.msg !== undefined && 
                    error.response.data.errors.msg.msg !== undefined  
                    ){
                        console.error("step1 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                        setcaptchaError(error.response.data.errors.msg.msg)
                    }
                setLoading(false);
                setrotateRefressh('')
            });
    }

    const checkAadhaarDuplicate = (from) => {

        // var currentDateTime = Date.now() 



        // console.log(currentDateTime,"currentDateTime")


        // var data = JSON.stringify({
        //     "deptCode": "9",
        //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
        //     "integrationPassword": "@895u5Xxe-2!7!!Y",
        //     "txnNo": "189",
        //     "txnDateTime": currentDateTime,
        //     "serviceCode": "3",
        //     "responseRedirectURL": "https://gcdmsdev.web.app/profile"
        //     });
              
        //       var config = {
        //         method: 'post',
        //         url: SERVER+'/global/get_ekyc_token',
        //         headers: { 
        //             'Content-Type': 'application/json', 
        //         },
        //         data : data
        //       };
              
        //       axios(config)
        //       .then(function (response) {
        //         setLoading(false)
        //         console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data.token.Token));
        //         setAadhaarToken(response.data.token.Token)
        //         var aadharToken = response.data.token.Token
        //         if(aadharToken !== null) {
        //             window.open("https://dbt.karnataka.gov.in:8443/HSM_Service_ASPX/EKYCService.aspx?key=a6ed232e-0bd9-4c24-89d7-67ba59bef639&token="+aadharToken, "_self")
        //             setRedirectback(true)
        //         }
                 
          
                    
        //         })
    
//OLD AADHAR VALIDATION METHOD

        if(allValues.aadharnum1.length === 4 && allValues.aadharnum2.length === 4 && allValues.aadharnum3.length === 4){
            setLoading(true)
        setaadharDuplicateError(false)
        
        var data = JSON.stringify({
            "key": "aadhar_no",
            "value": allValues.aadhar,
            "board_id": 1
          });
          
          var config = {
            method: 'post',
            url: SERVER+'/user/get-user',
            headers: { 
                'Content-Type': 'application/json',
                'Authorization':`Bearer ${getTokenCookie()}`
            },
            data : data
          };
          
          axios(config)
          .then(function (response) {
            setLoading(false)
            console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data,undefined,2));

            var check = true

            response.data.data.map((value) => {
                if(value.is_registered_user === true && value.labour_user_id !== users.user.id){
                    // setaadharDuplicateError(true)
                    // showToast("ERROR", "Aadhaar Already Registered!")
                    check = false
                }
            })

            if(check && from === "saveAndContinue"){
                nextButtonPressed()
            }
            else if(check){
                handleValidateClick()
            }
            else{
                setaadharDuplicateError(true)
                showToast("ERROR", "Aadhaar Already Registered!")
            }

            
          })
          .catch(function (error) {
            setLoading(false)
            console.log("checkAadhaarDuplicate errir:"+error);
          });
        }
        else{
            showToast("ERROR", "Please enter your Aadhar number correctly")
        }
        
          
    }


    const handleValidateClick = () => {
        if (!loading) {
            setLoading(true);

            var randNum = Math.floor(100000 + Math.random() * 900000)
            console.log("randNum: " + randNum);


            var data = JSON.stringify({
                "transID": randNum,
                "docType": 347,
                "docNumber": allValues.aadhar,
                "secretToken": secretToken,
                "tsTransID": tsTransID,
                "captchaCode": allValues.captchaText,
                "actionType": "otp",
                "consent": 1
            });

            var config = {
                method: 'post',
                url: SERVER + '/global/encrypt_string',
                headers: {
                    'Content-Type': 'application/json',
                    'username': TS_USERNAME
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log("step4 response: " + JSON.stringify(response.data));

                    var data = JSON.stringify({
                        "requestData": response.data.data
                    });
                    var config = {
                        method: 'post',
                        url: SERVER + '/global/auth_bridge_id_search',
                        headers: {
                            'Content-Type': 'application/json',
                            'username': TS_USERNAME
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log("step5 response: " + JSON.stringify(response.data));

                            var data = JSON.stringify({
                                "responseData": response.data.data.responseData
                            });

                            var config = {
                                method: 'post',
                                url: SERVER + '/global/decrypt_string',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'username': TS_USERNAME
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    console.log("step6 response: " + JSON.stringify(response.data))

                                    if (response.data.data.msg === "OTP sent successfully") {
                                        setLoading(false)
                                        setotpSent(true)

                                        settsTransIDAfterOtp(response.data.data.tsTransID)
                                    }
                                    else {
                                        setLoading(false)
                                        showToast("ERROR", response.data.errors.msg)
                                    }

                                })
                                .catch(function (error) {
                                    setLoading(false)
                                    console.error("step6 error:" + error);
                                    console.error("step6 error:" + JSON.stringify(error.response));
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                    // showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")

                                    // showToast("ERROR", error)
                                });
                        })
                        .catch(function (error) {
                            setLoading(false)
                            console.error("step5 error:" + error);
                            console.error("step5 error:" + JSON.stringify(error.response));
                            showToast("ERROR", error.response.data.errors.msg.msg)
                        });
                })
                .catch(function (error) {
                    setLoading(false)
                    console.error("step4 error: " + error);
                    console.error("step4 error:" + JSON.stringify(error.response));
                    showToast("ERROR", error.response.data.errors.msg.msg)
                    // showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                });



            // timer.current = window.setTimeout(() => {
            // setSuccess(true);
            // setLoading(false);
            // }, 2000);
        }
    }

    const validateAadhaar = () => {

        setLoading(true)

        var data = JSON.stringify({
            "transID": tsTransIDAfterOtp,
            "shareCode": "1234",
            "mobileCode": allValues.otp
        });

        var config = {
            method: 'post',
            url: SERVER + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step7 response: " + JSON.stringify(response.data));

                var data = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER + '/global/auth_bridge_aadhar_request',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        console.log("step8 response: " + JSON.stringify(response.data));

                        var data = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER + '/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step9 response: " + JSON.stringify(response.data));

                                if (response.data.data.status === "1") {
                                    showToast("SUCCESS", "Aadhaar Verified Successfully")
                                    setcaptchaDivShow(false)
                                    setotpSent(false)
                                    
                                    var first_name = ""
                                    var middle_name = ""
                                    var last_name = ""
                                    var nameArr = response.data.data.msg.Name.split(' ')
                                    console.log("nameArr: "+nameArr[0])
                                    if (nameArr.length === 1) {
                                        first_name = nameArr[0]
                                    }
                                    else if (nameArr.length === 2) {
                                        first_name = nameArr[0]
                                        last_name = nameArr[1]
                                    }
                                    else if (nameArr.length >= 3) {
                                        first_name = nameArr[0]
                                        middle_name = nameArr[1]
                                        last_name = nameArr[2]
                                    }
                                    console.log("first_name: "+first_name)
                                    console.log("middle_name: "+middle_name)
                                    console.log("last_name: "+last_name)

                                    
                                    var gender = null
                                    if(response.data.data.msg.Gender === "Male"){
                                        gender = "27"
                                    }
                                    else if(response.data.data.msg.Gender === "Female"){
                                        gender = "28"
                                    }
                                    else{
                                        gender = "29"
                                    }
                                    console.log("gender: "+gender)
                                    
                                    cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data.msg), { path: '/' })
                                    
                                    setAllValues({
                                        ...allValues,
                                        aadharcheck: true,
                                        aadhaarFetchedData: response.data.data.msg,
                                        is_aadhar_verified: true,
                                        firstname: first_name,
                                        middlename: middle_name,
                                        lastname: last_name,
                                        gender: gender
                                        
                                    })
                                    setDob(response.data.data.msg.DOB)

                                    setLoading(false)


                                    var config = {
                                        method: 'get',
                                        url: SERVER+`/global/convert_image_base_64?image_link=${response.data.data.msg.Image}`,
                                        headers: { },
                                        // data : data
                                      };
                                      
                                      axios(config)
                                      .then(function (response) {
                                        console.log(JSON.stringify(response.data));
                                        setprofilePic(response.data.data.base_64)
                                        sessionStorage.setItem('profilePic', response.data.data.base_64)
                                      })
                                      .catch(function (error) {
                                        console.log(error);
                                        if(error.response.data.errors.msg.msg !== undefined){
                                            showToast("ERROR", error.response.data.errors.msg.msg)
                                        }
                                      });



                                      var dataAadhaar = JSON.stringify({
                                        "labour_user_id": users.user.id,
                                        "name": response.data.data.msg.Name,
                                        "dob": response.data.data.msg.DOB,
                                        "gender": response.data.data.msg.Gender,
                                        "house_no": response.data.data.msg['House Number'],
                                        "street": response.data.data.msg.Street,
                                        "locality": response.data.data.msg.Locality,
                                        "landmark": response.data.data.msg.Landmark,
                                        "postoffice": response.data.data.msg['Post Office'],
                                        "village_city_town": response.data.data.msg['Village/Town/City'],
                                        "state": response.data.data.msg.State,
                                        "district": response.data.data.msg.District,
                                        "sub_district": response.data.data.msg['Sub District'],
                                        "country": response.data.data.msg.Country,
                                        "pincode": response.data.data.msg.Pincode,
                                        "image": response.data.data.msg.Image,
                                        "father_name": response.data.data.msg['Father Name'],
                                        "document_link": response.data.data.msg['Document link']
                                      });
                                      var configdataAadhaar = {
                                        method: 'post',
                                        url: SERVER+'/user/update_ad_details',
                                        headers: { 
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${getTokenCookie()}` 
                                        },
                                        data : dataAadhaar
                                      };
                                      
                                      axios(configdataAadhaar)
                                      .then(function (response) {
                                        console.log(JSON.stringify(response.data));
                                      })
                                      .catch(function (error) {
                                        console.log(error);
                                        if(
                                            error.response !== undefined &&
                                            error.response.data !== undefined &&
                                            error.response.data.errors !== undefined &&
                                            error.response.data.errors.msg !== undefined &&
                                            error.response.data.errors.msg.msg !== undefined
                                            ){
                                            showToast("ERROR", error.response.data.errors.msg.msg)
                                        }
                                      });
                                      
                                }
                                
                            })
                            .catch(function (error) {
                                console.error("step9 error: " + error);
                                if(error.response.data.errors.msg.msg !== undefined){
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                }
                                setLoading(false)
                            });

                    })
                    .catch(function (error) {
                        console.error("step8 error: " + error);
                        if(error.response.data.errors.msg.msg !== undefined){
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                }
                        setLoading(false)
                    });

            })
            .catch(function (error) {
                console.error("step7 error: " + error);
                if(error.response.data.errors.msg.msg !== undefined){
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                }
                setLoading(false)
            });
    }

    // useEffect(() => {

    //     console.log("in useeffect AadhaarFetchedData Cookie")
    //     var AadharData = cookie.load('AadhaarFetchedData') 
    //     if(AadharData !== undefined && AadharData !==''){
    //         console.log("AadhaarFetchedData Cookie: "+AadharData)

    //         var first_name = ""
    //         var middle_name = ""
    //         var last_name = ""
    //         var nameArr = AadharData.Name.split(' ')
    //         if (nameArr.length === 1) {
    //             first_name = nameArr[0]
    //         }
    //         else if (nameArr.length === 2) {
    //             first_name = nameArr[0]
    //             middle_name = nameArr[1]
    //         }
    //         else if (nameArr.length >= 3) {
    //             first_name = nameArr[0]
    //             middle_name = nameArr[1]
    //             last_name = nameArr[2]
    //         }

    //         setDob(AadharData.DOB)

    //         var gender = null
    //         if(AadharData.Gender === "Male"){
    //             gender = 27
    //         }
    //         else if(AadharData.Gender === "Female"){
    //             gender = 28
    //         }
    //         else{
    //             gender = 29
    //         }

    //         setAllValues({
    //             ...allValues,
    //             aadharcheck: true,
    //             // aadhaarFetchedData: response.data.data.msg,
    //             is_aadhar_verified: true,

    //             firstname: first_name,
    //             middlename: middle_name,
    //             lastname: last_name,

    //             gender: gender
                
    //         })
    //     }

    // }, [cookie.load('AadhaarFetchedData')])    

    return (
        <>
            <Row className="m-0">

                <Row className="form-row mt-4">
                    <Col xs={12} md={8} className="personal-col-1 aadhar-col" 
                    // style={{ marginLeft:"150px", marginRight:"150px", marginBottom:"100px" }}
                    >
                    <Required className="mt-4 mb-2" title={<Translate value ="enterYourAadharCardNumber"/>} />
                            <div className="aadhar-div">
                                <img alt="..."  />
                                <FormControl className="formcontrol7 aadharouter">
                                    <TextField
                                        className="aadhardiv1"
                                        variant="outlined"
                                        placeholder="1234"
                                        onChange={(e) => { handleAadharChange(e, 1) }}
                                        value={allValues.aadharnum1}
                                        // onFocus={handleFormFocus("aadhar1")}
                                        inputProps={{ minLength: 0, maxLength: 4 }}
                                        disabled={allValues.aadharcheck}
                                    />
                                </FormControl>
                                <FormControl className="formcontrol7 aadharouter">
                                    <TextField
                                        className="aadhardiv2 "
                                        variant="outlined"
                                        placeholder="2345"
                                        onChange={(e) => { handleAadharChange(e, 2) }}
                                        value={allValues.aadharnum2}
                                        // onFocus={handleFormFocus("aadhar2")}
                                        inputProps={{ minLength: 0, maxLength: 4 }}
                                        disabled={allValues.aadharcheck}
                                    />
                                </FormControl>
                                <FormControl className="formcontrol7 aadharouter"
                                    onKeyPress={event => {
                                        if (event.key === "Enter") {
                                            handleEnter();
                                        }
                                    }}>
                                    <TextField
                                        className="aadhardiv3 "
                                        variant="outlined"
                                        placeholder="3456"
                                        onChange={(e) => { handleAadharChange(e, 3) }}
                                        value={allValues.aadharnum3}
                                        // onFocus={handleFormFocus("aadhar3")}
                                        inputProps={{ minLength: 0, maxLength: 4 }}
                                        disabled={allValues.aadharcheck}
                                    />
                                </FormControl>
                                {
                                    allValues.aadharcheck ?
                                        (<img alt="..." src={checkgreen} />)
                                        :
                                        (<img alt="..." src={checkgrey} />)
                                }
                            </div>

                            {
                                aadharDuplicateError ? 
                                <p className="aadharDuplicateError">* Aadhaar Already Registered!</p>
                                : null
                            }

                            {
                            !allValues.aadharcheck && captchaDivShow ?
                                <div className="otp-div singlepage captchadiv mt-3 ">
                                    <div className="captchaDivouter">
                                        <div className="captchaDiv">
                                            <img className="captchaImg"
                                                src={`data:image/png;base64,${captcha}`}
                                        />
                                        <Tooltip title="Get New Captcha">
                                            {/* <p>Server issues. Please try again later</p> */}
                                            <Cached className={`refreshIcon ${rotateRefressh}`} 
                                            onClick={getNewCaptcha} />
                                        </Tooltip>
                                    </div>
                                    
                                    <p className="captchaError">{captchaError}</p>

                                    </div>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        label="Enter Captcha"
                                        name="captchaText"
                                        value={allValues.captchaText}
                                        onChange={handleChange}
                                        style={{
                                            maxHeight: "46px",
                                            alignSelf: "center"
                                        }}
                                    />

                                    <div className="checkDiv">
                                        <Button
                                            variant="outline-primary"
                                            // color="primary"
                                            // className={buttonClassname}
                                            disabled={loading}
                                            // onClick={handleValidateClick}
                                            onClick={checkAadhaarDuplicate}
                                        >
                                            Verify
                                        </Button>
                                        {loading && <CircularProgress size={24} className="buttonProgress" />}

                                    </div>
                                </div>

                                :
                                null
                            }

                            {
                            otpSent ?
                                <>
                                    <div className="otp-div mt-3">
                                        <TextField
                                            fullWidth
                                            label="Enter OTP"
                                            name="otp"
                                            value={allValues.otp}
                                            onChange={handleChange} />
                                        <Button
                                            variant="light"
                                            // color="primary"
                                            // className={buttonClassname}
                                            disabled={loading}
                                            onClick={validateAadhaar}
                                        >
                                            Submit
                                        </Button>
                                        {loading && <CircularProgress size={24} className="buttonProgress" />}
                                    </div>
                                </>
                                :
                                null
                            }

                    </Col>
                    <Col xs={12} md={6} className="personal-col-1">

                        <div className="form-inner-card-right">
                            <Required className="mb-2" title={<Translate value ="firstName"/>} />
                            {/* <p className="mb-2">Enter Your Full Name </p>  */}
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your First Name"
                                    name="firstname"
                                    value={allValues.firstname}
                                    type="text"
                                    onChange={changeHandler}
                                    error={allValues.firstnameError}
                                    helperText={allValues.firstnameError ? "Please enter in correct format" : ""}

                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>

                            <p className="mt-4 mb-2"><Translate value="middleName"/> </p>
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Middle Name"
                                    name="middlename"
                                    value={allValues.middlename}
                                    onChange={changeHandler}
                                    error={allValues.middlenameError}
                                    helperText={allValues.middlenameError ? "Please enter in correct format" : ""}
                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>

                            <p className="mt-4 mb-2"><Translate value="lastName"/></p> 
                            {/* <p className="mt-4 mb-2">Last Name </p>  */}
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Last Name"
                                    name="lastname"
                                    value={allValues.lastname}
                                    type="text"
                                    onChange={changeHandler}
                                    error={allValues.lastnameError}
                                    helperText={allValues.lastnameError ? "Please enter in correct format" : ""}
                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>

                            {/* <Required className="mt-4 mb-2" title="Enter Your Mobile Number"/> */}
                            <p className="mt-4 mb-2"><Translate value="enterYourMobileNumber"/></p>
                            <FormControl fullWidth className="formcontrol2"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <PhoneAndroid className="phone-icon" />
                                                +91
                                            </InputAdornment>,
                                    }}
                                    variant="outlined"
                                    value={allValues.phoneNumber}
                                    disabled
                                />
                            </FormControl>

                        </div>
                    </Col>
                    <Col xs={12} md={6} className="personal-col-2">
                    <div className="form-inner-card-right mt-0">
                        <div className="picupload">

                            {
                                // sessionStorage.getItem('profilePic') === null || sessionStorage.getItem('profilePic') === ""
                                // profilePic === '' ?
                                profilePic === undefined || profilePic === "" || profilePic === null ?
                                    (
                                        <>
                                            <CameraAlt className="cameraAlt" />

                                            <Required className="upload-title required-p" title={<Translate value="uploadYourProfilePicture"/>} style={{ width: "auto", marginLeft : "210px" }}>
                                          
                                            </Required>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // src={allValues.imgfile}
                                                // src={sessionStorage.getItem('profilePic')}
                                                // src={profilePic}
                                                src={`${profilePic}`}
                                                key={profilePic}
                                            />
                                            {/* <p className="upload-title">
                                        {allValues.documentName}</p> */}
                                        </>
                                    )
                            }

                            {/* <p className="upload-title">
                                Upload Your Profile Picture</p> */}

                            <div className="browse-buttons-div my-4">

                                <label
                                    htmlFor="upload-photo"
                                    className="browse-button">
                                    <img alt="..." src={folder} /><Translate value="browse"/>
                            </label>
                                <input type="file" name="photo" id="upload-photo" onChange={handleFileChange} />
                                <div className="or-between-buttons" >
                                    or
                                </div>
                                <p onClick={handleClickOpen} className="webcam-button" >
                                    <img alt="..." src={webcam} /> <Translate value="useWebCamera"/>
                                </p>


                            </div>



                            <Dialog
                                open={allValues.open}
                                onClose={handleClose}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            // fullWidth={true}
                            // maxWidth='750px'
                            >
                                <DialogTitle id="scroll-dialog-title"><Translate value="captureImageforProfilePicture"/></DialogTitle>
                                <DialogContent>
                                    <Webcam
                                        audio={false}
                                        height={400}
                                        ref={myRef}
                                        screenshotFormat="image/jpeg"
                                        // width={400}
                                        mirrored
                                        fullWidth
                                        videoConstraints={videoConstraints}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                            </Button>
                                    <Button onClick={capture} color="primary">
                                        Capture
                            </Button>
                                </DialogActions>
                            </Dialog>


                        </div>
                        <p className="instructions">
                            <span><Translate value="instructions"/>* : </span>
                            {/* (Upload your profile picture in jpg/png format only) */}
                            <Translate value="uploadyourprofilepictureinjpgpngformatonly"/>

                        </p>

                        <div className="pic-below-border"></div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="personal-col-3">
                        <div className="form-inner-card-right mt-0">


                            <p className="mt-0 mb-2"><Translate value="enterEmailAddress"/> <span className="optional">(Optional)</span></p>
                            <FormControl fullWidth className="formcontrol3" >
                                {/* onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                        handleEmailSubmit();
                                    }
                                }}> */}
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Email Address"
                                    name="email"
                                    value={allValues.email}
                                    onChange={changeHandler}
                                // onFocus={handleFormFocus()}
                                    error={allValues.emailError}
                                    helperText={allValues.emailError ? "Please enter valid Email Id" : ""}
                                />
                            </FormControl>

                            {/* <p className=" mb-2">Enter Your Father / Husband Name*</p>
                            <FormControl fullWidth  className="formcontrol4"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Father/Husband Name"
                                    name="fatherHusbandName"
                                    value={allValues.fatherHusbandName}
                                    onChange={handleChange}
                                    // onFocus={handleFormFocus()}
                                />
                            </FormControl> */}

                            {/* <p className="mt-5 mb-2">Marital Status*</p> */}
                            <Required className="mt-5 mb-2" title={<Translate value="maritalStatus"/>} />
                            <FormControl variant="outlined" fullWidth className="formcontrol5"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.maritalStatus}
                                    name="maritalStatus"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>
                                    {marital.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                    {/* <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    <MenuItem value="Single">
                                        <ListItemText primary="Single" />
                                    </MenuItem>
                                    <MenuItem value="Married">
                                        <ListItemText primary="Married" />
                                    </MenuItem>
                                    <MenuItem value="Widowed">
                                        <ListItemText primary="Widowed" />
                                    </MenuItem>
                                    <MenuItem value="Divorced">
                                        <ListItemText primary="Divorced" />
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="personal-col-4">
                        <div className="form-inner-card-right mt-0">
                            
                            
                            <p className="mt-4 mb-2"><Translate value="enterYourPANCardNumber"/></p>
                            <FormControl fullWidth className="formcontrol8 pan-div"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="LLNP•••••P"
                                    name="pan"
                                    value={allValues.pan}
                                    onChange={changeHandler}
                                    error={allValues.panError}
                                    helperText={allValues.panError ? "Please Enter valid Pan Card" : ""}
                                    // onFocus={handleFormFocus()}
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img alt="..."  className="panImg"/>
                                            </InputAdornment>,
                                        // endAdornment:
                                        //     <InputAdornment position="start">
                                        //         {
                                        //             allValues.pancheck ?
                                        //                 (<img alt="..." src={checkgreen} />)
                                        //                 :
                                        //                 (<img alt="..." src={checkgrey} />)
                                        //         }
                                        //     </InputAdornment>,
                                    }}
                                />
                            </FormControl>
                            {console.log(allValues.pan)}
                            {/* <p className="mt-5 mb-0">Select Your Gender*</p> */}
                            <Required className="mt-5 mb-0" title={<Translate value="selectYourGender"/>} />
                            <div className="gender-div">
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="27" id="male"
                                        onChange={handleChange} checked={allValues.gender === "27"} />
                                    <label
                                        htmlFor="male"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                                        <img alt="..." src={male} />
                                        <span><Translate value="male"/></span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="28" id="female" onChange={handleChange} checked={allValues.gender === "28"} />
                                    <label
                                        htmlFor="female"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://www.pngkey.com/png/full/203-2037403_flat-faces-icons-circle-girl-flat-icon-png.png" /> */}
                                        <img alt="..." src={female} />
                                        <span><Translate value="female"/></span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="29" id="others" onChange={handleChange} checked={allValues.gender === "29"} />
                                    <label
                                        htmlFor="others"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                                        <img alt="..." src={radioon} />
                                        <span><Translate value="others"/></span>
                                    </label>
                                </div>
                            </div>
                            <div className="gender-below-border"></div>
                        </div>
                    </Col>

                    <Col xs={12} md={6} className="mt-5 personal-col-5">
                        <div className="form-inner-card-right mt-0">
                            {/* <p className=" mb-2">Date of Birth*</p> */}
                            <Required className="mb-2" title={<Translate value="dateofBirth"/>} />
                            <MuiPickersUtilsProvider utils={MomentUtils} >
                                <KeyboardDatePicker
                                    maxDate={new Date()}
                                    // minDate={moment().subtract(18, "years")}
                                    onClick={() => setPickerStatus(true)}
                                    onClose={() => setPickerStatus(false)}
                                    open={allValues.openDatePicker}
                                    className="datepicker"
                                    margin="normal"
                                    format="DD/MM/YYYY"
                                    value={dob}
                                    // value={allValues.dob}
                                    // value={selectedDate}
                                    // onChange={ handleDateChange }
                                    // onChange={(date) => { handleDateChange(date) }}
                                    onChange={(date) => { setDob(date) }}
                                    placeholder="DD/MM/YYYY"
                                    KeyboardButtonProps={{
                                        'aria-label': 'change date',
                                    }}
                                />
                            </MuiPickersUtilsProvider>

                            <p className="mt-5 mb-2"><Translate value="category"/></p>
                            <FormControl variant="outlined" fullWidth className="formcontrol6"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.category}
                                    name="category"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {category.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                    {/* <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    <MenuItem value="General">
                                        <ListItemText primary="General" />
                                    </MenuItem>
                                    <MenuItem value="OBC">
                                        <ListItemText primary="OBC" />
                                    </MenuItem>
                                    <MenuItem value="SC">
                                        <ListItemText primary="SC" />
                                    </MenuItem>
                                    <MenuItem value="ST">
                                        <ListItemText primary="ST" />
                                    </MenuItem>
                                    <MenuItem value="Minority">
                                        <ListItemText primary="Minority" />
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                        </div>

                    </Col>
                    {/* {history.location.state !== undefined && */}
                    <Col xs={12} md={6} className="personal-col-6 mt-4 ">
                        <div className="form-inner-card-right mt-0">
                            <FormControl className="mt-2 mb-2 interstateRadio formcontrol9" variant="outlined" fullWidth component="fieldset"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                                <RadioGroup row name="isMigrant" value={allValues.isMigrant} onChange={handleChange}>
                                    <p className="mt-2 mb-2" style={{ flexGrow: 1 }}><Translate value="interstateMigrantWorker"/></p>
                                    <FormControlLabel value="1" control={<Radio />} label={<Translate value="yes"/>} />
                                    <FormControlLabel value="0" control={<Radio />} label={<Translate value="no"/>} />
                                </RadioGroup>
                            </FormControl>

                            {
                                allValues.isMigrant === "1" ?

                                    (
                                        <>
                                            <p className="mt-2 mb-2"><Translate value="migrantFromState"/></p>
                                            <FormControl variant="outlined" fullWidth >
                                                <Select
                                                    className="select-state"
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    value={allValues.migrantState}
                                                    name="migrantState"
                                                    displayEmpty
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <ListItemText primary="Select" />
                                                    </MenuItem>

                                                    {states.map((i) =>
                                                        i.name !== "Karnataka" &&
                                                        <MenuItem value={i.id}>
                                                            <ListItemText primary={i.short_name + " | " + i.name} />
                                                        </MenuItem>
                                                    )}


                                                </Select>
                                            </FormControl>
                                        </>
                                    )

                                    : null
                            }
                        </div>
                    </Col>
                    <Col xs={12} className="note2 text-center mt-5 personal-col-7">
                        {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
                        <p><span><Translate value="note"/> </span><Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess"/></p>

                    </Col>

                </Row>
                {/* <Row className="button-inside-form-row">
                    <Col xs={12} className="note2 text-center mt-5">
                        <p>Note : After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p>
                    </Col>
                </Row> */}

                <Row className="button-inside-form-row mb-5">
                    <Col xs={12} className="next-back-button-row mt-4 ">

                        {
                            !users.profile_details.editmode ?
                                <>
                                    <Link to="#" onClick={props.backButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            <Translate value="back"/>
                                        </Button>
                                    </Link>

                                    {
                                        loading ?
                                            <CircularProgress className="loader-otp" /> :
                                            <Link to="#" 
                                                onClick={() => checkAadhaarDuplicate("saveAndContinue")}
                                                style={{ flexGrow: "0.5" }}
                                            >
                                                <Button variant="outline-primary" className="next-button">
                                                    <Translate value="saveandContinue"/>
                                                </Button>
                                            </Link>
                                    }
                                    {/* <Link to="#" onClick={nextButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="next-button">
                                            Save and Continue
                                        </Button>
                                    </Link> */}
                                </>

                                :

                                <Link to="#" 
                                    // onClick={nextButtonPressed}
                                    onClick={() => checkAadhaarDuplicate("saveAndContinue")}
                                    style={{ flexGrow: "0.5" }}
                                >
                                    <Button variant="outline-primary" className="next-button">
                                        <Translate value="saveyourDetails"/>
                                    </Button>
                                </Link>

                        }


                    </Col>
                </Row>
            </Row>

        </>
    )

}

export default Personal