import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER, getSchemesOnStatus } from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';
import TableComponent from 'components/TableComponent';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const SchemesList = () => {
  const { id } = useParams();

  const history = useHistory();
  const location = useLocation();

  // Function to get search parameter value by name
  const getSearchParam = (name) => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get(name);
  };

  // Example usage
  useEffect(() => {
    const paramsList = new URLSearchParams(location.search);

    let hasParams = false;
    // through search parameters
    for (const [key, value] of paramsList) {
      if (value && value.trim() !== '') {
        hasParams = true;
        break;
      }
    }
    if (hasParams) {
      handleSchemeSearch();
    }
  }, []);

  const [registrationCatalog, setRegistrationCatalog] = useState(null);
  const [appStatus, setAppStatus] = useState(
    getSearchParam('catalog_value_approval_status') ||
      getSearchParam('statusId'),
  );
  const [getUserMappedToCircle, setgetUserMappedToCircle] = useState(null);
  const [circle, setCirlce] = useState(null);
  const [getSchemes, setGetSchemes] = useState(null);
  const [schemes, setSchemes] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [schemesList, setSchemesList] = useState(null);
  const [loading, setLoading] = useState(false);

  const [schemeStatus, setSchemeStatus] = useState(null);

  const users = useSelector((state) => state.users);
  const dispatch = useDispatch();

  useEffect(() => {
    const payLoad = JSON.stringify({
      board_id: users.getDeptUserRole.data[0].board_id,
      role_id: users.getDeptUserRole.data[0].role_id,
      department_user_id: users.getDeptUserRole.data[0].department_user_id,
    });
    axios
      .post(`${SERVER}/config/circle/get_user_mapped_to_circle`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        setgetUserMappedToCircle(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: 'Registration Status',
    };

    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;

        setRegistrationCatalog(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/schemes/get_schemes?board_id=1`)
      .then((res) => {
        const { data } = res.data;

        setGetSchemes(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (startDate && endDate) {
      setIsDateSelected(1);
    }
  }, [startDate, endDate]);

  const handleSchemeSearch = () => {
    if (id === '3') {
      if (appStatus) {
        setLoading(true);
        const payLoad = {
          board_id: users.getDeptUserRole.data[0].board_id,
          circle_id: circle,
          role_id: users.getDeptUserRole.data[0].role_id,
          catalog_value_approval_status: appStatus,
          department_user_id: users.getDeptUserRole.data[0].department_user_id,
          scheme_id: schemes,
          is_date_selected: isDateSelected,
          start_date: startDate,
          end_date: endDate,
          acck_no: null,
          mobile_no: null,
        };
        axios
          .post(`${SERVER}/schemes/get_schemes_on_status`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then((res) => {
            const { data } = res.data;

            dispatch(getSchemesOnStatus(payLoad));
            const updatedSchemesList = data.recordsets[0].map((data) => ({
              ...data,
              labour_name: data.labour_name.toUpperCase().trim(),
              gsc_no: data.gsc_no ?? 'N/A',
            }));
            setSchemesList(updatedSchemesList);
            setLoading(false);

            const searchParams = new URLSearchParams(location.search);
            for (let i in payLoad) {
              if (payLoad[i] && payLoad[i] !== '0') {
                searchParams.set(i, payLoad[i]);
              } else {
                searchParams.delete(i);
              }
            }

            // Update the URL without refreshing
            history.push({
              pathname: location.pathname,
              search: `?${searchParams.toString()}`,
            });
          });
      } else {
        showToast('ERROR', 'Please select scheme status');
      }
    } else if (id === '17') {
      if (appStatus) {
        setLoading(true);
        const payload = {
          boardId: users.getDeptUserRole.data[0].board_id,
          departmentUserId: users.getDeptUserRole.data[0].department_user_id,
          roleId: users.getDeptUserRole.data[0].role_id,
          circleId: circle,
          statusId: +appStatus,
          schemeId: schemes,
          isDateSelected: isDateSelected,
          startDate: startDate,
          endDate: endDate,
          ackNo: null,
          mobileNo: null,
          aadhaarNo: null,
        };
        axios
          .post(`${SERVER}/schemes/renewal/records`, payload, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then((res) => {
            const { data } = res.data;

            //  dispatch(getSchemesOnStatus(payLoad));
            const updatedSchemesList = data.map((data) => ({
              ...data,
              labour_name: data.labour_name.toUpperCase().trim(),
              gsc_no: data.gsc_no ?? 'N/A',
            }));
            setSchemesList(updatedSchemesList);
            setLoading(false);

            const searchParams = new URLSearchParams(location.search);
            for (let i in payload) {
              if (payload[i] && payload[i] !== '0') {
                searchParams.set(i, payload[i]);
              } else {
                searchParams.delete(i);
              }
            }

            // Update the URL without refreshing
            history.push({
              pathname: location.pathname,
              search: `?${searchParams.toString()}`,
            });
          });
      } else {
        showToast('ERROR', 'Please select scheme status');
      }
    }
  };

  return (
    <div className="">
      {/* <h3 className="text-center">Schemes Details</h3> */}
      <div className="d-flex justify-content-center align-items-center flex-column gap-1">
        <Row className="w-100">
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={appStatus || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setAppStatus(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Scheme Status" />
              </MenuItem>
              {registrationCatalog &&
                registrationCatalog.length &&
                registrationCatalog.map(
                  (data) =>
                    (data.value === 'Pending' ||
                      data.value === 'Rejected' ||
                      data.value === 'Approved' ||
                      data.value === 'Forwarded' ||
                      data.value === 'Draft Approved' ||
                      data.value === 'Draft Rejected' ||
                      data.value === 'Transferred Scheme Application' ||
                      data.value === 'Transferred back Scheme Application') && (
                      <MenuItem value={data.value_id}>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ),
                )}
            </Select>
          </Col>
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={circle || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setCirlce(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Circle" />
              </MenuItem>
              {getUserMappedToCircle &&
                getUserMappedToCircle.length &&
                getUserMappedToCircle.map((data) => (
                  <MenuItem value={data.circle_id}>
                    <ListItemText primary={data.circle_name} />
                  </MenuItem>
                ))}
            </Select>
          </Col>
          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={startDate}
                onChange={(date) => {
                  setStartDate(date);
                }}
                placeholder="Start Date"
                //   disabled={!showFilters ? true : false}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setStartDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {startDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} md={3}>
            <MuiPickersUtilsProvider
              utils={MomentUtils}
              style={{ border: '1px solid grey' }}
            >
              <KeyboardDatePicker
                className="datepicker form-control"
                // margin="normal"
                format="DD/MM/YYYY"
                value={endDate}
                onChange={(date) => {
                  setEndDate(date);
                }}
                placeholder="End Date"
                //   disabled={!showFilters ? true : false}
                InputProps={{
                  startAdornment: (
                    <div
                      onClick={() => {
                        setEndDate(null);
                        setIsDateSelected(0);
                      }}
                      style={{ position: 'absolute', right: '20%' }}
                    >
                      {endDate ? (
                        <InputAdornment>
                          <CancelIcon color="error" />
                        </InputAdornment>
                      ) : null}
                    </div>
                  ),
                }}
              />
            </MuiPickersUtilsProvider>
          </Col>
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={schemes || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setSchemes(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Schemes" />
              </MenuItem>
              {id === '17' &&
                getSchemes &&
                getSchemes.length &&
                getSchemes.map(
                  (data) =>
                    data.name === 'Thayi Magu Sahaya Hasta' && (
                      <MenuItem value={data.id}>
                        <ListItemText primary={data.name} />
                      </MenuItem>
                    ),
                )}
              {id === '3' &&
                getSchemes &&
                getSchemes.length &&
                getSchemes.map((data) => (
                  <MenuItem value={data.id}>
                    <ListItemText primary={data.name} />
                  </MenuItem>
                ))}
            </Select>
          </Col>
          <Col xs={12} md={2}>
            <Button
              onClick={handleSchemeSearch}
              className="w-100 btn btn-success py-2"
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs={12} md={6}></Col>
        </Row>
      </div>
      {loading && <SimpleBackdrop />}
      {!loading && schemesList && schemesList.length ? (
        <TableComponent schemesList={schemesList} schemeStatus={appStatus} />
      ) : !loading && schemesList && schemesList ? (
        <p className="text-center fs-3">No Applications Found</p>
      ) : null}
    </div>
  );
};

export default SchemesList;
