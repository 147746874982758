const { default: axios } = require('axios');
const { SERVER } = require('store/actions/user.actions');
const { getTokenCookie, dataURLtoFile } = require('./tools');

const welfareUploadFileAPI = (
  fileData,
  filename,
  userID,
  fileType,
  options = {},
) => {
  return new Promise((resolve, reject) => {
    const FormData = require('form-data');
    const data = new FormData();
    data.append('file', fileData);
    data.append('userId', userID);
    data.append('fileType', fileType);

    const config = {
      method: 'post',
      url: `${SERVER}/upload/welfare_file`,

      data: data,
    };

    axios(config)
      .then(function (response) {
        resolve({
          ...response.data,
          options,
        });
        // setTimeout(resolve, 100, response.data);
      })
      .catch(function (error) {
        console.log(error);
        reject(error);
      });
  });
};

export default welfareUploadFileAPI;
