import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Translate } from 'react-redux-i18n';
import usericon from 'assets/images/Icon awesome-user.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';

import logout from 'assets/images/Logout.svg';
import language from 'assets/images/translate (1).svg';
import { useState } from 'react';
import { setLocaleWithFallback } from 'store/actions/user.actions';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import BarChartRoundedIcon from '@mui/icons-material/BarChartRounded';

import UpdateIcon from '@mui/icons-material/Update';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import DraftsRoundedIcon from '@mui/icons-material/DraftsRounded';

import { CircularProgress } from '@mui/material';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { schemeStatusAPI } from './schemeStatus.service';
import SchemeStatusSection from './SchemeStatusSection';

const SchemeStatus = () => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const history = useHistory();

  const [translate, setTranslate] = useState({
    lang: users.profile_details.language,
    kannadaSelected: i18nlang.locale === 'ka' ? true : false,
  });

  const { labourUserID } = useParams();

  const [schemeStatus, setSchemeStatus] = useState(null);

  const handleChange = (event) => {
    if (event.target.value === 'ka') {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: true,
        [event.target.name]: event.target.value,
      }));
    } else {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: false,
        [event.target.name]: event.target.value,
      }));
    }

    if (event.target.name === 'lang')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const callSchemeStatus = () => {
    schemeStatusAPI(labourUserID, setSchemeStatus);
  };

  useEffect(callSchemeStatus, [labourUserID]);

  const statusData = [
    {
      status: 'Schemes Applied',
      Icon: BarChartRoundedIcon,
      count: schemeStatus ? (
        schemeStatus.approved_count +
        schemeStatus.pending_count +
        schemeStatus.rejected_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#456eb5',
    },
    {
      status: 'Schemes Pending',
      Icon: UpdateIcon,
      count: schemeStatus ? (
        schemeStatus.pending_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#edc937',
    },
    {
      status: 'Schemes Approved',
      Icon: CheckCircleOutlineRoundedIcon,
      count: schemeStatus ? (
        schemeStatus.approved_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#55b54a',
    },
    {
      status: 'Schemes Rejected',
      Icon: CancelRoundedIcon,
      count: schemeStatus ? (
        schemeStatus.rejected_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#db2218',
    },
    {
      status: 'Schemes Draft Approved',
      Icon: DraftsRoundedIcon,
      count: schemeStatus ? (
        schemeStatus.draft_approved_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#C8DF52',
    },
    {
      status: 'Schemes Draft Rejected',
      Icon: DraftsRoundedIcon,
      count: schemeStatus ? (
        schemeStatus.draft_rejected_count
      ) : (
        <CircularProgress color="inherit" />
      ),
      backgroundColor: '#db2218',
    },
  ];

  return (
    <>
      <div className="root">
        <Row className="top-div schemes">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <Link to={'/dashboard-user'}>
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </Link>
                <h1 className="logo-text">
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: 'flex' }}>
              <div className="usericon-header-logout">
                <img alt="..." src={usericon} className="usericon-img" />
                <p>{users.user !== null && users.user.first_name}</p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    onClick={() => {
                      history.push('/login');
                    }}
                    alt="..."
                    src={logout}
                    className="logout-img"
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
        <div className="d-flex justify-content-end">
          <Col
            xs={12}
            md={4}
            lg={3}
            className="d-flex justify-content-end mt-2 me-2"
          >
            <Select
              className="select-language w-100"
              variant="outlined"
              labelId="demo-simple-select-required-label"
              value={translate.lang}
              name="lang"
              displayEmpty
              onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>

              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </div>
        <div className="d-flex justify-content-center align-items-center  flex-wrap align-content-center gap-5 p-5">
          <Box
            sx={{
              display: 'flex',
              flexWrap: 'wrap',

              '& > :not(style)': {
                m: 3,
                width: 250,
              },
            }}
          >
            {statusData.map(({ Icon, count, status, backgroundColor }) => (
              <Paper
                style={{
                  borderRadius: '20px',
                  border: `1px solid ${backgroundColor}`,
                }}
                elevation={3}
              >
                <div className="d-flex justify-content-around py-2 px-1">
                  <div className="position-relative">
                    <p
                      className="my-3 position-absolute"
                      style={{
                        fontSize: '60px',
                        fontWeight: 'bolder',
                        color: 'grey',
                      }}
                    >
                      {count}
                    </p>
                  </div>
                  <Icon
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      background: `${backgroundColor}`,
                      borderRadius: '100%',
                      width: '85px',
                      height: '85px',
                      color: 'white',
                      marginTop: '10px',
                    }}
                  />
                </div>
                <div>
                  <p
                    className="d-flex justify-content-center align-items-center mt-2"
                    style={{
                      fontSize: '24px',
                      fontWeight: 'bolder',
                      color: 'ThreeDShadow',
                    }}
                  >
                    {status}
                  </p>
                </div>
              </Paper>
            ))}
          </Box>
        </div>
        <>
          <SchemeStatusSection schemeStatus={schemeStatus} />
        </>
      </div>
    </>
  );
};

export default SchemeStatus;
