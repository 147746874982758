import React, { useState } from 'react';
import { FormControl, TextField, TextareaAutosize } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';

import { Col, Row } from 'react-bootstrap';

import { SERVER } from 'store/actions/user.actions';

import { getTokenCookie } from 'utils/tools';

import { Translate } from 'react-redux-i18n';

import bank from 'assets/images/f4-01.svg';
import PopOver from './PopOver';

const BankDetails = ({ data, personalData }) => {
  const bankDetails = data && data[0];
  const nomineeBankDetails = data && data[1] && data[1];
  const bankUpload =
    data &&
    data[0] &&
    data[0].bank_upload_files_xml &&
    JSON.parse(data[0].bank_upload_files_xml);
  const bankNomineeUpload =
    data &&
    data[1] &&
    data[1].bank_upload_files_xml &&
    JSON.parse(data[1].bank_upload_files_xml);

  const [anchorEl, setAnchorEl] = useState(null);
  const [fileID, setFileID] = useState(null);

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handleNewTab = (url) => {
    window.open(url, '_blank', 'noopener');
    handleClose();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/v2/file/${fileID}?token=${getTokenCookie()}`;
    window.open(url, '_blank', 'noopener');
  };

  return (
    <>
      <PopOver
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      <Row className="mt-5 mb-0 final all-details-subtitle-row">
        <Col xs={12} md={12} className="profile-title">
          <img alt="..." src={bank} />
          <h1>Bank Details</h1>
        </Col>
      </Row>

      <Row className="form-row-final mt-4">
        <Col xs={12} md={3} className="final-personal-col-1 px-3">
          <p className="mb-0"> IFSC Code </p>
          <FormControl fullWidth>
            <TextField value={bankDetails && bankDetails.ifsc_code} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={3} className="final-personal-col-1 px-3">
          <p className="mb-0">Account Number</p>
          <FormControl fullWidth>
            <TextField value={bankDetails && bankDetails.account_no} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={3} className="final-personal-col-1 px-3">
          <p className="mb-0">Bank Name </p>
          <FormControl fullWidth>
            <TextField value={bankDetails && bankDetails.bank_name} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={3} className="final-personal-col-1 px-3">
          <p className="mb-0"> Bank Branch </p>
          <FormControl fullWidth>
            <TextField
              value={bankDetails && bankDetails.bank_branch}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
          <p className="mb-0"> Bank Branch </p>
          <FormControl fullWidth>
            <TextareaAutosize
              variant="outlined"
              multiline
              rows={4}
              value={bankDetails && bankDetails.bank_address}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
          <p className="mb-0"> Passbook Document </p>

          {bankUpload && bankUpload.length ? (
            bankUpload.map((row, index) => (
              <>
                <div className="browsebutton2-div final">
                  <FormControl>
                    <TextField
                      variant="outlined"
                      value={row.file_name}
                      style={{ width: '-webkit-fill-available' }}
                      disabled
                    />
                  </FormControl>
                  {!row.file_name.includes('.pdf') ? (
                    <label
                      className="browse-button2"
                      onClick={(ev) =>
                        handleViewImage(ev, row.file_id, row.file_name)
                      }
                    >
                      <Translate value="view" /> <Visibility />
                    </label>
                  ) : (
                    <label
                      className="browse-button2"
                      onClick={() => handleViewPDF(row.file_id)}
                    >
                      <Translate value="view" /> <Visibility />
                    </label>
                  )}
                </div>
              </>
            ))
          ) : (
            <div className="browsebutton2-div final">
              <FormControl>
                <TextField
                  variant="outlined"
                  value={'N/A'}
                  style={{ width: '-webkit-fill-available' }}
                  disabled
                />
              </FormControl>
            </div>
          )}
        </Col>
        {data[1] && data[1].is_nominee === true ? (
          <>
            <Col xs={12} className="profile-title">
              <h2>Nominee Bank Details</h2>
            </Col>
            <Col xs={3} className="final-personal-col-1 px-3">
              <p className="mb-0"> IFSC Code </p>
              <FormControl fullWidth>
                <TextField value={nomineeBankDetails.ifsc_code} disabled />
              </FormControl>
            </Col>
            <Col xs={3} className="final-personal-col-1 px-3">
              <p className="mb-0">Account Number </p>
              <FormControl fullWidth>
                <TextField value={nomineeBankDetails.account_no} disabled />
              </FormControl>
            </Col>
            <Col xs={3} className="final-personal-col-1 px-3">
              <p className="mb-0"> Bank Name </p>
              <FormControl fullWidth>
                <TextField value={nomineeBankDetails.bank_name} disabled />
              </FormControl>
            </Col>
            <Col xs={3} className="final-personal-col-1 px-3">
              <p className="mb-0"> Bank Branch </p>
              <FormControl fullWidth>
                <TextField value={nomineeBankDetails.bank_branch} disabled />
              </FormControl>
            </Col>
            <Col xs={6} className="final-personal-col-1 px-3 mt-3">
              <p className="mb-0">Bank Branch</p>
              <FormControl fullWidth>
                <TextareaAutosize
                  variant="outlined"
                  multiline
                  rows={4}
                  value={nomineeBankDetails.bank_address}
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={6} className="final-personal-col-1 px-3 mt-3">
              <p className="mb-0">Passbook Document </p>

              {bankNomineeUpload && bankNomineeUpload.length ? (
                bankNomineeUpload.map((row, index) => (
                  <>
                    <div className="browsebutton2-div final">
                      <FormControl>
                        <TextField
                          variant="outlined"
                          value={row.file_name}
                          style={{ width: '-webkit-fill-available' }}
                          disabled
                        />
                      </FormControl>
                      {!row.file_name.includes('.pdf') ? (
                        <label
                          className="browse-button2"
                          onClick={(ev) =>
                            handleViewImage(ev, row.file_id, row.file_name)
                          }
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      ) : (
                        <label
                          className="browse-button2"
                          onClick={() => handleViewPDF(row.file_id)}
                        >
                          <Translate value="view" /> <Visibility />
                        </label>
                      )}
                    </div>
                  </>
                ))
              ) : (
                <div className="browsebutton2-div final">
                  <FormControl>
                    <TextField
                      variant="outlined"
                      value={'N/A'}
                      style={{ width: '-webkit-fill-available' }}
                      disabled
                    />
                  </FormControl>
                </div>
              )}
            </Col>
          </>
        ) : null}
      </Row>
    </>
  );
};

export default BankDetails;
