/* eslint-disable no-throw-literal */
export const PersonalDetailsValidator = async (
  maritialStatusID,
  categoryID,
  religionID,
  natureOfWorkID,
  isMigrant,
  isAyushman,
  migrantID,
  ayushmanCardNumber,
  existingCardDoc,
) => {
  if (
    !maritialStatusID ||
    +maritialStatusID === 0 ||
    !categoryID ||
    +categoryID === 0 ||
    !religionID ||
    +religionID === 0 ||
    !natureOfWorkID ||
    +natureOfWorkID === 0 ||
    !isMigrant ||
    !isAyushman
  ) {
    throw {
      success: false,
      msg: 'Please fill all mandatory personal details',
    };
  } else if (isMigrant === '1' && !migrantID) {
    throw {
      success: false,
      msg: 'Please select migrant state',
    };
  } else if (isAyushman === '1' && !ayushmanCardNumber) {
    throw {
      success: false,
      msg: 'Please enter ayushman bharat card number',
    };
  } else if (existingCardDoc && existingCardDoc.length === 0) {
    // eslint-disable-next-line no-throw-literal
    throw {
      success: false,
      msg: 'Please upload existing labour card / seva sindhu labour card',
    };
  } else {
    return {
      success: true,
      data: {
        categoryID: +categoryID,
        religionID: +religionID,
        natureOfWorkID: +natureOfWorkID,
        isMigrant: +isMigrant,
        migrantID: +migrantID,
        isAyushman: +isAyushman,
        ayushmanCardNumber: ayushmanCardNumber,
      },
    };
  }
};
