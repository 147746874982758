import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigBoardDetails, getConfigMinistryDetails, getLabourStatusDetails, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { Required } from 'utils/tools';
import { PhoneAndroid } from '@material-ui/icons';


const BoardMasterForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        ministryName: "",
        short_name: "",
        code: "",
        description: "",
        web_url: "",
        chief_ministers_name: "",
        chief_ministers_image_id: "",
        labour_miniters_name: "",
        labour_ministers_image_id: "",
        logo_image_id: "",
    })


    useEffect(() => {
        // console.log("in useEffect [users.user]")
        // dispatch(getConfigBoardDetails())

        if(props.location.ministryid !== undefined && props.location.ministryid !== null && users.getConfigMinistryDetails !== undefined && users.getConfigMinistryDetails.length > 0){

            // console.error("props.location.ministryid: "+props.location.ministryid)
            // console.error("users.getConfigMinistryDetails: "+JSON.stringify(users.getConfigMinistryDetails))

            var index = users.getConfigMinistryDetails.map(
                        function(e) {return e.id;}
                        ).indexOf(props.location.ministryid)
            

            var val = users.getConfigMinistryDetails[index]

            setAllValues({
                ...allValues,
                ministryName: val.name,
                short_name: val.short_name,
                code: val.code,
                description: val.description,
                web_url: val.web_url,
                chief_ministers_name: val.chief_ministers_name,
                chief_ministers_image_id: val.chief_ministers_image_id,
                labour_miniters_name: val.labour_miniters_name,
                labour_ministers_image_id: val.labour_ministers_image_id,
                logo_image_id: val.logo_image_id
            })   
        }
    }, []);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })   
    }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Board</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Ministry Master</p>
                    </Link>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Ministry Name" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Ministry Name"
                                name="ministryName"
                                value={allValues.ministryName} 
                                onChange={handleChange}
                            // onFocus={handleFormFocus()}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Short Name" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Short Name"
                                name="short_name"
                                value={allValues.short_name} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Code" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Code"
                                name="code"
                                value={allValues.code} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={8} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Description" />
                        <FormControl fullWidth className="formcontrol1">
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={1}
                                    placeholder="Enter Description of Board"
                                    name="description"
                                    value={allValues.description} 
                                    onChange={handleChange}
                                />
                            </FormControl>

                    {/* </div> */}
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Web URL" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Code"
                                name="web_url"
                                value={allValues.web_url} 
                                onChange={handleChange}
                            />
                        </FormControl>

                    {/* </div> */}
                </Col>
                <Col xs={12} md={12} className="personal-col-1">
                        <Required className="mt-4 mb-2" title="Ministry Logo" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    // name="passbookDocumentName"
                                    // value={allValues.passbookDocumentName}
                                    // onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            {/* <label htmlFor="upload-photo" className="browse-button2">
                                <img src={folder} alt="..." /> Browse
                            </label> */}
                            <label
                                htmlFor="upload-photo"
                                className="browse-button btn3">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo" 
                            // onChange={handleFileChange} 
                            />
                        </div>
                        <p className="image-size-text">Image size will be 1mb Max (Jpeg, PNG Format)</p>
                </Col>
                <Col xs={12} md={5} className="personal-col-1">
                        <Required className="mt-4 mb-2" title="Chief Minister Name" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Chief Minister Name"
                                name="chief_ministers_name"
                                value={allValues.chief_ministers_name} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={7} className="personal-col-1">
                        <Required className="mt-4 mb-2" title="Chief Minister Image" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    // name="passbookDocumentName"
                                    // value={allValues.passbookDocumentName}
                                    // onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            {/* <label htmlFor="upload-photo" className="browse-button2">
                                <img src={folder} alt="..." /> Browse
                            </label> */}
                            <label
                                htmlFor="upload-photo"
                                className="browse-button btn3">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo" 
                            // onChange={handleFileChange} 
                            />
                        </div>
                        <p className="image-size-text">Image size will be 1mb Max (Jpeg, PNG Format)</p>
                </Col>
                <Col xs={12} md={5} className="personal-col-1">
                        <Required className="mt-4 mb-2" title="Labour Minister Name" />
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Labour Minister Name"
                                name="labour_miniters_name"
                                value={allValues.labour_miniters_name} 
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={7} className="personal-col-1">
                        <Required className="mt-4 mb-2" title="Labour Minister Image" />
                        <div className="browsebutton2-div">
                            <FormControl >
                                <TextField
                                    variant="outlined"
                                    // placeholder="Enter Your Age"
                                    // name="passbookDocumentName"
                                    // value={allValues.passbookDocumentName}
                                    // onChange={handleChange}
                                    disabled
                                />
                            </FormControl>
                            {/* <label htmlFor="upload-photo" className="browse-button2">
                                <img src={folder} alt="..." /> Browse
                            </label> */}
                            <label
                                htmlFor="upload-photo"
                                className="browse-button btn3">
                                <img alt="..." src={folder} /> Browse
                            </label>
                            <input type="file" name="photo" id="upload-photo" 
                            // onChange={handleFileChange} 
                            />
                        </div>
                        <p className="image-size-text">Image size will be 1mb Max (Jpeg, PNG Format)</p>
                </Col>
                
                <Col xs={12} className="next-back-button-row mt-4">

                                    <Link to="#" 
                                        // onClick={props.backButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            Reset
                                        </Button>
                                    </Link>

                                    <Link to="#" 
                                    // onClick={nextButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="save-button">
                                            Save
                                        </Button>
                                    </Link>
                    
                </Col>


            </Row>
        </>
    )
}

export default BoardMasterForm;