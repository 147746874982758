import React from "react";
import ReactDOM from "react-dom";
import Routes from "./routes";
import "styles/styles.css";
import "styles/styles-responsive.css";

import { Provider } from "react-redux";
import { ReduxStore, persistor } from "./store";
import { PersistGate } from "redux-persist/integration/react";
import "./CSS/googleapis.css";
import "./CSS/bootstrap.min.css";
import axios from "axios";
// require('dotenv').config();
axios.defaults.withCredentials = true;

ReactDOM.render(
  <React.StrictMode>
    <Provider store={ReduxStore()}>
      <PersistGate persistor={persistor}>
        <Routes />
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
