import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const getFieldsValueAPI = async (labourUserID, schemeAvailID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/get_scheme_fields_values?labour_user_id=${labourUserID}&scheme_avail_id=${schemeAvailID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const schemeStatusAPI = async (schemeAvailID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/v2/status?availId=${schemeAvailID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const rejectedReasonsAPI = async (schemeID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/global/rejection-reason?id=${schemeID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const singleRecordAPI = async (schemeAvailID, schemeTransactionID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/v2/status/single?availId=${schemeAvailID}&availedTransactionId=${schemeTransactionID}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const getSchemeInfoAPI = async () => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/get_schemes?board_id=1`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};

export const rejectReasonDraftAPI = async (schemeAvailID) => {
  try {
    const reasonType = 'DRAFT';
    const { data } = await axios.get(
      `${SERVER}/schemes/rejection-reason?availId=${schemeAvailID}&reasonType=${reasonType}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
