import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, Tooltip, Checkbox, TextareaAutosize, Popover, Typography, Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { PhoneAndroid, CameraAlt, Visibility, CheckBoxRounded, CheckBoxOutlineBlankRounded, CheckCircle } from '@material-ui/icons';
import React, { useEffect, useRef } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Webcam from "react-webcam";
import { Link } from 'react-router-dom';
import folder from 'assets/images/folder (3).svg'
import webcam from 'assets/images/web-cam.svg'
// import aadhar from 'assets/images/id-card.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
import personal from 'assets/images/f1-01.svg'
// import checkGreen from 'assets/images/Tick-01.svg'
import checkGreen from 'assets/images/Tick-01.png'
import mobilepic from 'assets/images/Icon awesome-mobile-alt.svg'
// import pan from 'assets/images/credit-card.svg'
import pan from 'assets/images/PAN Black-01.svg'
import edit from 'assets/images/edit-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
// import { Edit, Person } from '@material-ui/icons';
import male from 'assets/images/Mask Group 1.svg'
import female from 'assets/images/Mask Group 2.svg'
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addLabour90DaysDetails, addLabourAddressDetails, addLabourBankDetails, addLabourFamilyDetails, addLabourPersonalDetails, finalSubmit, getApplicationNo, getCatalogDetails, getFile, getStateDetails, getUser, getUserRegistrationDetails, saveAcknowledgementId, SERVER, updatePersonalData, updateProfileData, updateReviewData, uploadFile } from 'store/actions/user.actions';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import family from 'assets/images/f3-01.svg'
// import address from 'assets/images/apartment.svg'
import address from 'assets/images/f2-01.svg'
import bank from 'assets/images/f4-01.svg'
import nintydays from 'assets/images/f5-01.svg'
import moment from 'moment';
import Moment from 'react-moment';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'

import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Translate } from 'react-redux-i18n';
import { format } from 'date-fns';
import axios from 'axios';


const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "user",
    // facingMode: { exact: "environment" }
};

const Review = (props) => {

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffffee',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));

    const myRef = React.createRef();
    const descRef = React.useRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] = React.useState(null);
    const [anchorElninty, setAnchorElninty] = React.useState(null);
    const [anchorElration, setAnchorElration] = React.useState(null);

    const [allValues, setAllValues] = React.useState({
        profliePicUploadResponse: users.review.profliePicUploadResponse,
        rationCardUploadResponse: users.review.rationCardUploadResponse,
        passbookUploadResponse: users.review.passbookUploadResponse,
        passbookNomineeUploadResponse: users.review.passbookNomineeUploadResponse,
        // nintyDaysUploadResponse: users.review.nintyDaysUploadResponse,
        // personalSubmitted: users.review.personalSubmitted,
        // addressSubmitted: users.review.addressSubmitted,
        // familySubmitted: users.review.familySubmitted,
        // bankSubmitted: users.review.bankSubmitted,
        // nintyDaysSubmitted: users.review.nintyDaysSubmitted,
        // finalSubmitted: users.review.finalSubmitted,
        finalSubmitted: false,
        permanentData: { 
            streetRoad: "",
            city: "",
            district: "",
            landmark: "",
            houseBuilding: "",
            areaVillage: "",
            taluk: "",
            state: "",
           pincode: "",
        },
    })

    useEffect(() => {
        // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
        dispatch(updateReviewData(allValues))
    }, [allValues]);

    // const [personalSubmitted, setpersonalSubmitted] = React.useState(false);
    // const [addressSubmitted, setaddressSubmitted] = React.useState(false);
    // const [familySubmitted, setfamilySubmitted] = React.useState(false);
    // const [bankSubmitted, setbankSubmitted] = React.useState(false);
    // const [nintyDaysSubmitted, setninyDaysSubmitted] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);


    const [certificateDocs, setcertificateDocs] = React.useState({});
    const [BankDocs, setBankDocs] = React.useState({});
    const [NomineeBankDocs, setNomineeBankDocs] = React.useState({});
    const [RationDocs, setRationDocs] = React.useState({});
    const [selected_document_type, setselected_document_type] = React.useState("");

    const handleClick = (event, data, selecteddoc) => {
        console.error("handleclick data:" + data)

        setselected_document_type(selecteddoc)

        if (data === "passbook")
            setAnchorEl(event.currentTarget);
        else if (data === "passbook_nominee")
            setAnchorElpassbook_nominee(event.currentTarget);
        else if (data === "ninty")
            setAnchorElninty(event.currentTarget);
        else if (data === "ration")
            setAnchorElration(event.currentTarget);
    };

    const handleClose = (event, data) => {
        console.error("handleClose data:" + data)
        if (data === "passbook")
            setAnchorEl(null);
        else if (data === "passbook_nominee")
            setAnchorElpassbook_nominee(null);
        else if (data === "ninty")
            setAnchorElninty(null);
        else if (data === "ration")
            setAnchorElration(null);
    };

    const openPassbook = Boolean(anchorEl);
    const id = openPassbook ? "simple-popover" : undefined;

    const openPassbookNominee = Boolean(anchorElpassbook_nominee);
    const idpassbook_nominee = openPassbookNominee ? "simple-popover" : undefined;

    const openninty = Boolean(anchorElninty);
    const idninty = openninty ? "simple-popover" : undefined;

    const openration = Boolean(anchorElration);
    const idration = openration ? "simple-popover" : undefined;




    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
    };
    const handleToggleBackdrop = () => {
        setOpenBackdrop(!openBackdrop);
    };
    const finalSubmitButtonPressed = () => {
        console.error("in finalSubmitButtonPressed")

        if (allValues.declaration && allValues.declaration2) {
            console.error("in allValues.declaration: " + allValues.declaration)
            console.error("in allValues.declaration2: " + allValues.declaration2)
            setOpenBackdrop(true);
            setLoading(true);
            setSubmitSuccess(false);

            submitFinalSubmit()
        }
        else {
            showToast("ERROR", "Please Accept the Declarations!")
        }


    }

    const [AckLetterImg, setAckLetterImg] = React.useState("")
    const [ackId, setackId] = React.useState("")

    const submitFinalSubmit = () => {

        let dataToSubmit = {
            "id": users.user.id,
            "board_id": 1
        }

        console.error("dataToSubmit", JSON.stringify(dataToSubmit))

        dispatch(finalSubmit(dataToSubmit))
    }

    useEffect(() => {

        var finalsub = false
        if (users.finalSubmit !== null &&
            users.finalSubmit.data !== undefined &&
            users.finalSubmit.data !== null &&
            users.finalSubmit.data.success !== undefined &&
            users.finalSubmit.data.success === true) {

            let dataToSubmitAppNo = {
                "id": users.user.id,
            }
            dispatch(getApplicationNo(dataToSubmitAppNo))

            setLoading(false)
            setSubmitSuccess(true)
            finalsub = true
            // setAllValues({
            //     ...allValues,
            //     finalSubmitted: true
            // })
            callSaveAcknowledgementId()


        }

        if(users.user.is_sent_approval){
            finalsub = true
        }

        setAllValues({
            ...allValues,
            finalSubmitted: finalsub
        })


    }, [users.finalSubmit])


    const callSaveAcknowledgementId = () => {

        if (ackId !== "") {
            let dataToSubmit = {
                "ack_id": ackId,
                "id": users.user.id
            }

            console.error("dataToSubmit", JSON.stringify(dataToSubmit))

            dispatch(saveAcknowledgementId(dataToSubmit))
        }
    }

    useEffect(() => {
        if (
            users.getUserRegistrationDetails !== undefined &&
            users.getUserRegistrationDetails !== null
            // users.getUserRegistrationDetails.application_details !== undefined && 
            // users.getUserRegistrationDetails.application_details !== null && 
            // users.getUserRegistrationDetails.application_details.length > 0 &&
            // users.getUserRegistrationDetails.application_details[0].application_no !== null
        ) {

            if (users.getUserRegistrationDetails.personal_details !== undefined) {
                dispatch(getFile(
                    users.getUserRegistrationDetails.personal_details[0].user_photo_id,
                    "profilePic")
                )
            }
            if (users.getUserRegistrationDetails.ration_card_details !== undefined) {
                dispatch(getFile(
                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_document_upload_id,
                    "rationCard")
                )
            }
            if (users.getUserRegistrationDetails.bank_details !== undefined) {
                dispatch(getFile(
                    // users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id,
                    users.getUserRegistrationDetails.bank_details[0].account_no,
                    "passbook")
                )
            }

            if (users.getUserRegistrationDetails.bank_details !== undefined && users.getUserRegistrationDetails.bank_details.length > 1) {

                dispatch(getFile(
                    // users.getUserRegistrationDetails.bank_details[1].bank_passbook_upload_id,
                    users.getUserRegistrationDetails.bank_details[1].account_no,
                    "passbook_nominee")
                )
            }
            if (
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails.certificate_details !== undefined &&
                users.getUserRegistrationDetails.certificate_details.length > 0
            ) {

                let jsondocs = JSON.parse(users.getUserRegistrationDetails.certificate_details[0].document_uplaod_files_xml)

                console.log("jsondocs: " + JSON.stringify(jsondocs));
                console.log("jsondocs.length(): " + jsondocs.length);
                var docsFromApi = []

                let promises = []
                for (var i = 0; i < jsondocs.length; i++) {
                    var config = {
                        method: 'get',
                        url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                        headers: {
                            'Authorization': `Bearer ${getTokenCookie()}`
                        },
                    };

                    promises.push(getfilebase64(config))
                    if (promises.length === jsondocs.length) {
                        Promise.all(promises)
                            .then(results => {
                                console.log("results of promise: ")
                                console.log(results)

                                var certdoc = []
                                results.forEach((j, index) => {
                                    console.log("jsondocs_inside: " + JSON.stringify(j));
                                    console.log("jsondocs_inside index: " + index);

                                    var cert = {
                                        ['name']: jsondocs[index].file_name,
                                        ['base_64_content']: j.base_64_content,
                                    }
                                    certdoc.push(cert)
                                })

                                setcertificateDocs(certdoc)
                                console.log("certdoc: " + JSON.stringify(certdoc));
                            })
                    }


                }
            }


            if (
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails.ration_card_details !== undefined &&
                users.getUserRegistrationDetails.ration_card_details.length > 0 &&
                users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== ""
            ) {

                var jsondocs = JSON.parse(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml)

                console.log("jsondocs: " + JSON.stringify(jsondocs));
                console.log("jsondocs.length(): " + jsondocs.length);
                var docsFromApi = []

                let promises = []
                for (var i = 0; i < jsondocs.length; i++) {
                    var config = {
                        method: 'get',
                        url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                        headers: {
                            'Authorization': `Bearer ${getTokenCookie()}`
                        },
                    };

                    promises.push(getfilebase64(config))
                    if (promises.length === jsondocs.length) {
                        Promise.all(promises)
                            .then(results => {
                                // console.log("results of promise: ")
                                // console.log(results)

                                var certdoc = []
                                results.forEach((j, index) => {
                                    // console.log("jsondocs_inside: "+JSON.stringify(j));
                                    // console.log("jsondocs_inside index: "+index);

                                    var cert = {
                                        ['name']: jsondocs[index].file_name,
                                        ['base_64_content']: j.base_64_content,
                                    }
                                    certdoc.push(cert)
                                })

                                setRationDocs(certdoc)
                                // console.log("certdoc: "+JSON.stringify(certdoc));
                            })
                    }


                }
            }

            if (
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails.bank_details !== undefined &&
                users.getUserRegistrationDetails.bank_details.length > 0
            ) {

                var nomineeIndex = null
                var labourIndex = null

                if (users.getUserRegistrationDetails.bank_details.length === 2) {
                    if (users.getUserRegistrationDetails.bank_details[0].is_nominee) {
                        nomineeIndex = 0
                        labourIndex = 1
                    }
                    else if (users.getUserRegistrationDetails.bank_details[1].is_nominee) {
                        nomineeIndex = 1
                        labourIndex = 0
                    }
                }


                if (nomineeIndex === null) {
                    var jsondocs1 = JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);

                    let promises = []
                    for (var i = 0; i < jsondocs1.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs1[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises.push(getfilebase64(config))
                        if (promises.length === jsondocs1.length) {
                            Promise.all(promises)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs1[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setBankDocs(certdoc)
                                    // console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }


                    }
                }
                else {
                    var jsondocs2 = JSON.parse(users.getUserRegistrationDetails.bank_details[labourIndex].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);
                    var docsFromApi = []

                    let promises = []
                    for (var i = 0; i < jsondocs2.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs2[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises.push(getfilebase64(config))
                        if (promises.length === jsondocs2.length) {
                            Promise.all(promises)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs2[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setBankDocs(certdoc)
                                    // console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }
                    }



                    var jsondocs3 = JSON.parse(users.getUserRegistrationDetails.bank_details[nomineeIndex].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);

                    let promises1 = []
                    for (var i = 0; i < jsondocs3.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs3[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises1.push(getfilebase64(config))
                        if (promises1.length === jsondocs3.length) {
                            Promise.all(promises1)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs3[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setNomineeBankDocs(certdoc)
                                    // console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }


                    }
                }

            }



        }
    }, [users.getUserRegistrationDetails])


    const getfilebase64 = (config) => {
        return new Promise((resolve, reject) => {

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));


                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error)
                });

        })
    }



    useEffect(() => {
        if (
            users.getFile !== null && users.getFile !== undefined &&
            users.getFile.fileName !== undefined
        ) {

            if (users.getFile.fileName === "profilePic") {
                sessionStorage.setItem('profilePic', users.getFile.data);
            }
            if (users.getFile.fileName === "rationCard") {
                sessionStorage.setItem('rationCard', users.getFile.data);
            }
            if (users.getFile.fileName === "passbook") {
                sessionStorage.setItem('passbook', users.getFile.data);
            }
            if (users.getFile.fileName === "passbook_nominee") {
                sessionStorage.setItem('passbook_nominee', users.getFile.data);
            }
            if (users.getFile.fileName === "90_days_certificate") {
                sessionStorage.setItem('90_days_certificate', users.getFile.data);
            }

        }
    }, [users.getFile])


    useEffect(() => {
        // console.log("in useEffect []")

        if (users.generate_otp !== null) {
            // setPhone(users.generate_otp.data.phone_number)
            // setAllValues({...allValues, phoneNumber: users.generate_otp.data.phone_number})
            dispatch(getUser(users.generate_otp.data.phone_number))
        }
        else if (users.generate_otp_registration !== null) {
            // setPhone(users.generate_otp_registration.data.phone_number)
            // setAllValues({...allValues, phoneNumber: users.generate_otp_registration.data.phone_number})
            dispatch(getUser(users.generate_otp_registration.data.phone_number))
        }
        else {
            props.history.push("/")
        }

        dispatch(getFile("delete"))
        dispatch(finalSubmit("delete"))
        // setAllValues({
        //     ...allValues,
        //     documentUploadResponse: ""
        // })
        // sessionStorage.setItem('myValueInLocalStorage', 'testing');

        // console.error("localstorage:"+sessionStorage.getItem('myValueInLocalStorage'))
    }, []);

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (users.user.id !== "" && users.user.id !== null) {

            let dataForRegDetails = {
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "all"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))

            let dataToSubmit = {
                "id": users.user.id,
            }
            dispatch(getApplicationNo(dataToSubmit))


            // var finalsub = false
            // if (users.user.is_sent_approval) {
            //     finalsub = true
            // }


            var data = JSON.stringify({
                "labour_user_id": users.user.id
            });
            var config = {
                method: 'post',
                url: SERVER + '/user/get_ad_details',
                headers: {
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data: data
            }

            axios(config)
                .then(function (response) {
                    
                    if (response !== undefined && response.data !== undefined && response.data.data !== undefined && response.data.data.length > 0) {
                        console.log("get_ad_details response:" + JSON.stringify(response.data));
                        var AadharDataFetched = response.data.data[0]

                        console.log("AadharDataFetched:" + JSON.stringify(response.data.data[0]));
                        console.log("AadharDataFetched.district:" + AadharDataFetched.district);

                        setAllValues({
                            ...allValues,
                            finalSubmitted: users.user.is_sent_approval,
                            permanentData: { 
                                streetRoad: AadharDataFetched.street,
                                city: AadharDataFetched.postoffice,
                                district: AadharDataFetched.district,
                                landmark: AadharDataFetched.landmark,
                                houseBuilding: AadharDataFetched.house_no,
                                areaVillage: AadharDataFetched.village_city_town,
                                taluk: AadharDataFetched.sub_district,
                                state: AadharDataFetched.state,
                               pincode: AadharDataFetched.pincode,
                            },
                            
                        })
                    }

                })
                .catch(function (error) {
                    console.error("get_ad_details error:" + JSON.stringify(error));
                    console.error("get_ad_details error:" + JSON.stringify(error.response));
                });

        }
    }, [users.user])

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (AckLetterImg !== "" && AckLetterImg !== null) {

            let formdata = {
                'file': AckLetterImg,
                'userId': users.user.id,
                'fileType': 'reg_ack'
            }

            console.error("formdata: " + JSON.stringify(formdata))
            dispatch(uploadFile(formdata, "acknowledgement"))
        }
    }, [AckLetterImg])

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true) {
            if (users.upload_file.userFileName === "acknowledgement") {
                setAllValues({
                    ...allValues,
                    documentUploadResponse: users.upload_file.image
                })
                setackId(users.upload_file.image.fileId)


            }
        }
    }, [users.upload_file])

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        input.style.display = 'block';
        html2canvas(input, {
            scrollY: -window.scrollY,
            // allowTaint: true,
            // useCORS: true
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                setAckLetterImg(imgData)
                const pdf = new jsPDF();
                pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
                // pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save("acknowledgement.pdf");
                window.open(pdf.output('bloburl'), '_blank')
            })
            .then(() => {
                input.style.display = 'none';
            })
            ;
    }

    var appNo = ""
    if (users.getApplicationNo !== null && users.getApplicationNo !== "") {
        if (users.getApplicationNo.length > 0)
            appNo = users.getApplicationNo[0].application_no
        else
            appNo = "test"
    }

    var applicantName = ""
    if (users.user.first_name !== '' && users.user.first_name !== null) {
        applicantName += (users.user.first_name + " ")
    }
    if (users.user.middle_name !== '' && users.user.middle_name !== null) {
        applicantName += (users.user.middle_name + " ")
    }
    if (users.user.last_name !== '' && users.user.last_name !== null) {
        applicantName += (users.user.last_name)
    }

    var date = new Date()


    if (users.getUserRegistrationDetails !== null && 
        users.getUserRegistrationDetails !== undefined && 
        users.getUserRegistrationDetails.application_details !== null &&
        users.getUserRegistrationDetails.application_details !== undefined &&
        users.getUserRegistrationDetails.application_details.length > 0 &&
        users.getUserRegistrationDetails.application_details[0].application_date !== null
         ) {
             date = users.getUserRegistrationDetails.application_details[0].application_date 
         }


    if (users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== undefined && users.getUserRegistrationDetails.address_details !== undefined && users.getUserRegistrationDetails.address_details.length > 0) {

        var presentAddress = ""
        if (users.getUserRegistrationDetails.address_details[0].door_no !== '' && users.getUserRegistrationDetails.address_details[0].door_no !== null) {
            presentAddress += (users.getUserRegistrationDetails.address_details[0].door_no + " ")
        }
        if (users.getUserRegistrationDetails.address_details[0].street_name !== '' && users.getUserRegistrationDetails.address_details[0].street_name !== null) {
            presentAddress += (users.getUserRegistrationDetails.address_details[0].street_name + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].land_mark !== '' && users.getUserRegistrationDetails.address_details[0].land_mark !== null) {
            presentAddress += (users.getUserRegistrationDetails.address_details[0].land_mark + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].panhayat_city_town !== '' && users.getUserRegistrationDetails.address_details[0].panhayat_city_town !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].panhayat_city_town
            presentAddress += (x + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].district !== '' && users.getUserRegistrationDetails.address_details[0].district !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].district
            presentAddress += (x + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].ward_area_village !== '' && users.getUserRegistrationDetails.address_details[0].ward_area_village !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].ward_area_village
            presentAddress += (x + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].taluk !== '' && users.getUserRegistrationDetails.address_details[0].taluk !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].taluk
            presentAddress += (x + ", ")
        }
        if (users.getUserRegistrationDetails.address_details[0].state !== '' && users.getUserRegistrationDetails.address_details[0].state !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].state
            presentAddress += (x + " - ")
        }
        if (users.getUserRegistrationDetails.address_details[0].pin_code !== '' && users.getUserRegistrationDetails.address_details[0].pin_code !== null) {
            presentAddress += (users.getUserRegistrationDetails.address_details[0].pin_code)
        }



        var villageWard = ""
        if (users.getUserRegistrationDetails.address_details[0].ward_area_village !== '' && users.getUserRegistrationDetails.address_details[0].ward_area_village !== null) {
            var x = users.getUserRegistrationDetails.address_details[0].ward_area_village
            villageWard += (x)
        }

        var mobile = ""
        if (users.getUserRegistrationDetails.personal_details[0].mobile_no !== '' && users.getUserRegistrationDetails.personal_details[0].mobile_no !== null) {

            mobile += (users.getUserRegistrationDetails.personal_details[0].mobile_no)
        }

        var occupation = ""
        if (users.getUserRegistrationDetails.employer_details !== '' && users.getUserRegistrationDetails.employer_details.length > 0) {
            var y = users.getUserRegistrationDetails.employer_details.map(function (e) {
                return e.catalog_value_employement_status_id;
            }).indexOf(25)

            console.log("y=" + y)
            var x = users.getUserRegistrationDetails.employer_details[y].nature_of_work

            occupation += (x)
        }

        var officerIncharge = ""
        if (users.getUserRegistrationDetails.certificate_details.length > 0) {

            if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id) {
                officerIncharge = users.getUserRegistrationDetails.certificate_details[0].inspector_first_name
            }
            // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 56) {
            //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].labour_union_spoke_person
            // }
            // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 57) {
            //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
            // }
            // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 58) {
            //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
            // }
        }

        var circleName = ""
        if (users.getUserRegistrationDetails.certificate_details !== undefined && users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].inspector_circle !== "" && users.getUserRegistrationDetails.certificate_details[0].inspector_circle !== null) {
            circleName = users.getUserRegistrationDetails.certificate_details[0].inspector_circle
        }
    }

    const handleChangeCheckbox = (event) => {
        setAllValues({ ...allValues, [event.target.name]: event.target.checked });
    }


    return (
        <>
            {
                users.getUserRegistrationDetails !== undefined &&
                    users.getUserRegistrationDetails !== null &&

                    users.getUserRegistrationDetails.personal_details !== undefined &&
                    users.getUserRegistrationDetails.address_details !== undefined &&
                    users.getUserRegistrationDetails.family_details !== undefined &&
                    users.getUserRegistrationDetails.ration_card_details !== undefined &&
                    // users.getUserRegistrationDetails.certificate_details !== undefined && 
                    users.getUserRegistrationDetails.bank_details !== undefined &&
                    users.getUserRegistrationDetails.employer_details !== undefined &&

                    users.getUserRegistrationDetails.personal_details.length > 0 &&
                    users.getUserRegistrationDetails.address_details.length > 0 &&
                    users.getUserRegistrationDetails.family_details.length > 0 &&
                    users.getUserRegistrationDetails.ration_card_details.length > 0 &&
                    // users.getUserRegistrationDetails.certificate_details.length > 0 && 
                    users.getUserRegistrationDetails.bank_details.length > 0 &&
                    users.getUserRegistrationDetails.employer_details.length > 0 ?

                    <>

                        <Row className="m-0">

                            {
                                loading ?
                                    <Backdrop className={classes.backdrop} open={openBackdrop}
                                        onClick={loading ? null : handleCloseBackdrop}
                                    >
                                        <CircularProgress color="inherit" />
                                    </Backdrop>
                                    : null
                            }

                            {
                                submitSuccess ?
                                    <Backdrop className={classes.backdrop} open={openBackdrop}
                                        onClick={loading ? null : handleCloseBackdrop}
                                    >
                                        <div style={{ display: "block" }}>
                                            <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "200px" }} />
                                            <p className="final-success-title" ><Translate value="success1" /><br /><Translate value="success2" /><br /><Translate value="success3" /></p>

                                            <p className="final-success-link-p"><Translate value="downloadAckLetter" /><Link to="#"
                                                onClick={printDocument}
                                                style={{ flexGrow: "0.5" }}
                                            >

                                                <span variant="outline-primary" className="final-success-link"> <Translate value="clickHere" /></span>
                                            </Link>
                                            </p>
                                        </div>


                                    </Backdrop>
                                    : null
                            }


                            <Row className="final all-details-subtitle-row">
                                <Col xs={12}  md={12} className="profile-title">
                                    <img alt="..." src={personal} />

                                    <h1><Translate value="personalDetails" /></h1>
                                    {
                                        !allValues.finalSubmitted ?
                                            <p onClick={() => props.editButtonPressed(1)}><Translate value = "edityourdetails" />
                                                <img src={edit} /></p>
                                            :
                                            <p style={{ backgroundColor: "#F3F6F8", boxShadow: "none", color: "black" }}>Submitted  <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "20px" }} /></p>
                                    }
                                    {/* <p onClick={() => props.editButtonPressed(1)}>Edit your details<img src={edit}/></p> */}
                                </Col>



                            </Row>


                            <Row className="form-row-final mt-4">
                                <Col xs={12} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="First Name review" /> */}
                                    <Required className="mb-0" title={<Translate value="firstName" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            name="firstname"
                                            value={users.getUserRegistrationDetails.personal_details[0].first_name}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Middle Name </p> */}
                                    <p className="mt-3 mb-0"><Translate value="middleName" /> </p>
                                    <FormControl fullWidth>
                                        <TextField
                                            name="middlename"
                                            value={users.getUserRegistrationDetails.personal_details[0].middle_name}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Last Name </p> */}
                                    <p className="mt-3 mb-0"><Translate value="lastName" /></p>

                                    <FormControl fullWidth>
                                        <TextField
                                            placeholder="Enter Your Last Name"
                                            value={users.getUserRegistrationDetails.personal_details[0].last_name}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Enter Your PAN Card Number</p> */}
                                    <p className="mt-3 mb-0">< Translate value="enterYourPANCardNumber" /></p>

                                    <FormControl fullWidth className="pan-div">
                                        <TextField
                                            disabled
                                            value={users.getUserRegistrationDetails.personal_details[0].pan_no}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <img alt="..." src={pan} />
                                                    </InputAdornment>,
                                                // endAdornment:
                                                //     <InputAdornment position="start">
                                                //         {
                                                //             users.getUserRegistrationDetails.personal_details[0].pancheck ?
                                                //                 (<img alt="..." src={checkgreen} />)
                                                //                 :
                                                //                 (<img alt="..." src={checkgrey} />)
                                                //         }
                                                //     </InputAdornment>,
                                            }}
                                        />
                                    </FormControl>

                                </Col>

                                <Col xs={12} md={4} className="final-personal-col-2 px-3">
                                    {/* <Required className="mb-0" title="Enter Your Mobile Number" /> */}
                                    <Required className="mb-0" title={<Translate value="enterYourMobileNumber" />} />

                                    <FormControl fullWidth className="formcontrol2">
                                        <TextField
                                            disabled
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <PhoneAndroid className="phone-icon" />
                                                        +91
                                                    </InputAdornment>,
                                            }}
                                            value={users.getUserRegistrationDetails.personal_details[0].mobile_no}
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Enter Email Address <span className="optional">(Optional)</span></p> */}
                                    <p className="mt-3 mb-0"><Translate value="enterEmailAddress" /> <span className="optional">(Optional)</span></p>

                                    <FormControl fullWidth className="formcontrol3">
                                        <TextField
                                            name="email"
                                            value={users.getUserRegistrationDetails.personal_details[0].mail_id}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Enter Your Aadhar Card Number*</p> */}
                                    <p className="mt-3 mb-0"><Translate value="enterYourAadharCardNumber" />*</p>

                                    <FormControl fullWidth className="pan-div">
                                        <TextField
                                            disabled
                                            value={
                                                users.getUserRegistrationDetails.personal_details[0].aadhar_no !== null &&
                                                users.getUserRegistrationDetails.personal_details[0].aadhar_no.replace(/\d(?=\d{4})/g, "X")}
                                            InputProps={{
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <img alt="..." src={aadhar} />
                                                    </InputAdornment>,
                                                // endAdornment:
                                                //     <InputAdornment position="start">
                                                //         {
                                                //             users.getUserRegistrationDetails.personal_details[0].pancheck ?
                                                //                 (<img alt="..." src={checkgreen} />)
                                                //                 :
                                                //                 (<img alt="..." src={checkgrey} />)
                                                //         }
                                                //     </InputAdornment>,
                                            }}
                                        />
                                    </FormControl>

                                    {
                                        users.getUserRegistrationDetails.personal_details[0].is_inter_state_migrant ?
                                            <>
                                                <p className="mt-3 mb-0"><Translate value="migrantFromState" /></p>
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={users.getUserRegistrationDetails.personal_details[0].state}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </>
                                            : null
                                    }

                                </Col>
                                <Col xs={12} md={4} className="final-personal-col-3 px-3">
                                    <div className="picupload">
                                        {
                                            sessionStorage.getItem('profilePic') === null ?
                                                (
                                                    <CameraAlt className="cameraAlt2" />
                                                )
                                                :
                                                (
                                                    <img
                                                        alt="..."
                                                        className="avatar border-gray"
                                                        src={sessionStorage.getItem('profilePic')}
                                                    // src={users.getFile}
                                                    />
                                                )
                                        }


                                    </div>

                                    <div className="mt-3 final-display-flex">
                                        {/* <p className="m-0" >Marital Status*</p> */}
                                        <p className="m-0" ><Translate value="maritalStatus" />*</p>

                                        <p className="m-0 p-0">{users.getUserRegistrationDetails.personal_details[0].marital_status}</p>
                                    </div>

                                    <div className="mt-3 final-display-flex">
                                        {/* <p className="m-0" >Gender*</p> */}
                                        <p className="m-0" ><Translate value="gender" />*</p>

                                        {
                                            {
                                                27: <p className="m-0 p-0">
                                                    <img alt="..." src={male} /><span>Male</span>
                                                </p>,
                                                28: <p className="m-0 p-0">
                                                    <img alt="..." src={female} /><span>Female</span>
                                                </p>,
                                                29: <p className="m-0 p-0">
                                                    {/* <img alt="..." src={radioon}/> */}
                                                    <span>Others</span>
                                                </p>,
                                            }[users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id]
                                        }
                                    </div>

                                    <div className="mt-3 final-display-flex">
                                        {/* <p className="m-0" >DOB*</p> */}
                                        <p className="m-0" ><Translate value="dOB" />*</p>

                                        <p className="m-0 p-0"><Moment format="DD.MM.YYYY">{users.getUserRegistrationDetails.personal_details[0].date_of_birth}</Moment></p>
                                    </div>

                                    <div className="mt-3 final-display-flex">
                                        {/* <p className="m-0" >Category</p> */}
                                        <p className="m-0" ><Translate value="category" /></p>
                                        <p className="m-0 p-0">{users.getUserRegistrationDetails.personal_details[0].caste}</p>
                                    </div>

                                </Col>
                            </Row>

                                

                            <Row className="mt-5 mb-0 final all-details-subtitle-row">
                                <Col xs={12} md={12} className="profile-title mmb-0">
                                    <img alt="..." src={address} />
                                    {/* <h1>Address Details </h1> */}
                                    <h1><Translate value="addressDetails" /></h1>
                                    {/* <p onClick={() => props.editButtonPressed(2)}>Edit your details<img src={edit}/></p> */}
                                    {
                                        !allValues.finalSubmitted ?
                                            <p onClick={() => props.editButtonPressed(2)}><Translate value = "edityourdetails" /><img src={edit} /></p>
                                            :
                                            <p style={{ backgroundColor: "#F3F6F8", boxShadow: "none", color: "black" }}>Submitted  <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "20px" }} /></p>
                                    }
                                </Col>
                            </Row>

                            <Row className="form-row-final with-border mt-0">
                                <Col xs={12} className="profile-title">
                                    {/* <h2>Residential Address</h2> */}
                                    <h2><Translate value="residentialAddress" /></h2>

                                </Col>
                                <Col  xs={12} md={4} className="final-personal-col-1 px-3">
                                <Required className="mt-0 mb-2" title={<Translate value="typeOfresidence"/>} />
                                    <FormControl fullWidth>

                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].address_base_type}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">City Name </p> */}
                                    <p className="mt-3 mb-0"><Translate value="cityName" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].panhayat_city_town}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Village Name </p> */}
                                    <p className="mt-3 mb-0"><Translate value="villageName" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].ward_area_village}

                                            disabled
                                        />
                                    </FormControl>

                                </Col>

                                <Col  xs={12} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="House/Building Number" /> */}
                                    <Required className="mb-0" title={<Translate value="houseBuildingNumber" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].door_no}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">District</p> */}
                                    <p className="mt-3 mb-0"><Translate value="district" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].district}

                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Taluk</p> */}
                                    <p className="mt-3 mb-0"><Translate value="taluk" />*</p>

                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].taluk}

                                            disabled
                                        />
                                    </FormControl>

                                </Col>

                                <Col  xs={12} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Street/Road Name" /> */}
                                    <Required className="mb-0" title={<Translate value="streetRoadName" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].street_name}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Landmark</p> */}
                                    <p className="mt-3 mb-0"><Translate value="landmark" /></p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            value={users.getUserRegistrationDetails.address_details[0].land_mark}
                                            disabled
                                        />
                                    </FormControl>

                                    <Row>
                                        <Col xs={6} className="final-personal-col-1 ">
                                            {/* <p className="mt-3 mb-0">State</p> */}
                                            <p className="mt-3 mb-0"><Translate value="state" />*</p>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined"
                                                    value={users.getUserRegistrationDetails.address_details[0].state}

                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={6} className="final-personal-col-1 ">
                                            {/* <p className="mt-3 mb-0">Pincode</p> */}
                                            {/* <Required className="mt-3 mb-2" title={<Translate value="pincode"/>} /> */}

                                            <p className="mt-3 mb-0"><Translate value="pin_code" />*</p>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined"
                                                    value={users.getUserRegistrationDetails.address_details[0].pin_code}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>


                                </Col>

                                <Col xs={12}  className="profile-title permanentAddress">
                                    {/* <h2>Permanent Address</h2> */}
                                    <h2><Translate value="permanentAddress" /></h2>

                                </Col>
                                <Col xs={12} md={4} className="final-personal-col-1 px-3">
                                {/* <Required className="mt-0 mb-2" title={<Translate value="typeOfresidence"/>} /> */}

                                    {/* <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].address_base_type}
                                            value={users.getUserRegistrationDetails.address_details[0].address_base_type}

                                            disabled
                                        />
                                    </FormControl> */}

                                    {/* <p className="mt-3 mb-0">City Name </p> */}
                                    <Required className="mb-0" title={<Translate value="houseBuildingNumber" />} />

                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].door_no}
                                            value={allValues.permanentData.houseBuilding}
                                            disabled
                                        />
                                    </FormControl>

                                    <p className="mt-3 mb-0"><Translate value="district" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].district}
                                            value={allValues.permanentData.district}

                                            disabled
                                        />
                                    </FormControl>

                                    <p className="mt-3 mb-0"><Translate value="taluk" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].taluk}
                                            value={allValues.permanentData.taluk}
                                            disabled
                                        />
                                    </FormControl>
                                    

                                </Col>

                                <Col xs={12} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="House/Building Number" /> */}
                                    <Required className="mb-0" title={<Translate value="streetRoadName" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].street_name}
                                            value={allValues.permanentData.streetRoad}
                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">District</p> */}
                                    <p className="mt-3 mb-0"><Translate value="landmark" /></p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].land_mark}
                                            value={allValues.permanentData.landmark}
                                            disabled
                                        />
                                    </FormControl>

                                    <Row>
                                        <Col xs={6} className="final-personal-col-1 ">
                                            {/* <p className="mt-3 mb-0">State</p> */}
                                            <p className="mt-3 mb-0"><Translate value="state" />*</p>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined"
                                                    // value={users.getUserRegistrationDetails.address_details[0].state}
                                                    value={allValues.permanentData.state}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={6} className="final-personal-col-1 ">
                                            {/* <p className="mt-3 mb-0">pin_code</p> */}
                                            <p className="mt-3 mb-0"><Translate value="pin_code" />*</p>
                                            <FormControl fullWidth>
                                                <TextField
                                                    variant="outlined"
                                                    // value={users.getUserRegistrationDetails.address_details[0].pin_code}
                                                    value={allValues.permanentData.pincode}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>

                                </Col>

                                <Col xs={12} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Street/Road Name" /> */}
                                    <p className="mb-0"><Translate value="cityName" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].panhayat_city_town}
                                            value={allValues.permanentData.areaVillage}

                                            disabled
                                        />
                                    </FormControl>

                                    {/* <p className="mt-3 mb-0">Landmark</p> */}
                                    <p className="mt-3 mb-0"><Translate value="villageName" />*</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            variant="outlined"
                                            // value={users.getUserRegistrationDetails.address_details[0].ward_area_village}
                                            value={allValues.permanentData.areaVillage}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                            </Row>




                            <Row className="mt-5 mb-0 final all-details-subtitle-row">
                                <Col xs={12} md={12} className="profile-title">
                                    <img alt="..." src={family} />
                                    {/* <h1>Family Details </h1> */}
                                    <h1><Translate value="familyDetails" /></h1>
                                    {/* <p onClick={() => props.editButtonPressed(3)}>Edit your details<img src={edit}/></p> */}
                                    {
                                        !allValues.finalSubmitted ?
                                            <p onClick={() => props.editButtonPressed(3)}><Translate value = "edityourdetails" /><img src={edit} /></p>
                                            :
                                            <p style={{ backgroundColor: "#F3F6F8", boxShadow: "none", color: "black" }}>Submitted  <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "20px" }} /></p>
                                    }
                                </Col>
                            </Row>
                            <Row className="form-row-final mt-4">
                                <Col xs={12} className="profile-title">
                                    {/* <h2>Ration Card Details</h2> */}
                                    <h2><Translate value="rationCardDetails" /></h2>
                                </Col>
                                <Col xs={6} md={4} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Ration Card Number" /> */}
                                    <p className="mb-0" title={<Translate value="rationCardNumber" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.ration_card_details[0].ration_card_no}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={6} md={4} className="final-personal-col-1 px-3">
                                    {/* <p className="mb-0" title="Ration Card Type" /> */}
                                    <p className="mb-0" title={<Translate value="rationCardType" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.ration_card_details[0].ration_card_type}
                                            // value={
                                            //     users.getUserRegistrationDetails.family_details.rationCardTypes[
                                            //         users.family_details.rationCardTypes.map(function(e) {
                                            //             return e.value_id;
                                            //         }).indexOf(users.family_details.rationCardType)
                                            //     ].value
                                            // }
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={4} className="final-personal-col-1 px-3">
                                    <p className="mb-0" title={<Translate value="rationCardDocument" />} />


                                    {
                                        Object.keys(RationDocs).map((id, i) =>
                                            <> 
                                                <div className="browsebutton2-div final">
                                                    <FormControl>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                RationDocs !== undefined &&
                                                                RationDocs.length > 0 &&
                                                                RationDocs[i].name
                                                            }
                                                            style={{ width: "-webkit-fill-available" }}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <label className="browse-button2"
                                                        onClick={(e) => handleClick(e, "ration", RationDocs[i].base_64_content)}>
                                                        <Translate value="view" /> <Visibility />
                                                    </label>
                                                </div>

                                            </>

                                        )
                                    }

                                    <Popover
                                        id={idration}
                                        open={openration}
                                        anchorEl={anchorElration}
                                        onClose={(e) => handleClose(e, "ration")}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                    >
                                        <a href="#" >
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // id={item.id}
                                                src={`
                                            data:image/jpeg;base64,${selected_document_type}`}

                                                onClick={() => {
                                                    var win = window.open();
                                                    win.document.write('<iframe src="' + `
                                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                }}
                                            />
                                        </a>
                                    </Popover>

                                </Col>

                               <Col xs={12} className="profile-title mmb-0">
                                    {/* <h2>Family Member Details</h2> */}
                                    <h2><Translate value="familyMemberDetails" /></h2>
                                </Col>

                                {
                                    users.getUserRegistrationDetails.family_details.map((id, i) => {
                                        return (
                                            <>
                                                <Row className={"mt-0 familymember-card final row" + i}>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Relation :</p> */}
                                                                <p ><Translate value="relation" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth >
                                                                    <TextField
                                                                        value={users.getUserRegistrationDetails.family_details[i].parent_child_relation}

                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >First Name :</p> */}
                                                                <p ><Translate value="firstName" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        value={users.getUserRegistrationDetails.family_details[i].first_name}
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Middle Name :</p> */}
                                                                <p ><Translate value="middleName" /> : </p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        value={users.getUserRegistrationDetails.family_details[i].middle_name}
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Last Name :</p> */}
                                                                <p ><Translate value="lastName" /> :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        value={users.getUserRegistrationDetails.family_details[i].last_name}
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Aadhar :</p> */}
                                                                <p> <Translate value="aadhar" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <div className="aadhar-div">
                                                                    <img alt="..." src={aadhar} />
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            className="aadhardiv1"
                                                                            // value={users.getUserRegistrationDetails.family_details[i].aadhar_no}
                                                                            value={users.getUserRegistrationDetails.family_details[i].aadhar_no.replace(/\d(?=\d{4})/g, "X")}
                                                                            inputProps={{ minLength: 0, maxLength: 4 }}
                                                                            disabled
                                                                        />
                                                                    </FormControl>

                                                                    {/* {
                                                            users.family_details.membersList[i]["aadharcheck" + i] ? */}
                                                                    {/* (<img alt="..." src={checkgreen} />) */}
                                                                    {/* : */}
                                                                    {/* ( */}
                                                                    {/* <img alt="..." src={checkgrey} /> */}
                                                                    {/* ) */}
                                                                    {/* } */}
                                                                </div>

                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >DOB :</p> */}
                                                                <p ><Translate value="dOB" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth>
                                                                    <TextField
                                                                        value={moment(users.getUserRegistrationDetails.family_details[i].date_of_birth).format('DD.MM.YYYY')}
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Profession :</p> */}
                                                                <p ><Translate value="profession" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth >
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            value={users.getUserRegistrationDetails.family_details[i].proffession}

                                                                            disabled
                                                                        />
                                                                    </FormControl>
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={12} md={6} >
                                                        <Row>
                                                            <Col xs={4} className="member-p">
                                                                {/* <p >Education :</p> */}
                                                                <p ><Translate value="education" />* :</p>
                                                            </Col>
                                                            <Col xs={8} >
                                                                <FormControl fullWidth >
                                                                    <FormControl fullWidth>
                                                                        <TextField
                                                                            value={users.getUserRegistrationDetails.family_details[i].education}

                                                                            disabled
                                                                        />
                                                                    </FormControl>
                                                                </FormControl>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xs={10} className="member-p">
                                                        <FormControlLabel
                                                            control={
                                                                <>
                                                                    <Checkbox
                                                                        checked={users.getUserRegistrationDetails.family_details[i].is_nominee}

                                                                        disabled
                                                                    />
                                                                </>
                                                            }
                                                            // label="Nominee"
                                                            label={<Translate value="nominee" />}
                                                        />
                                                    </Col>
                                                    <Col xs={10} className="member-p">
                                                        <Row>
                                                            <Col xs={12} md={8} className="member-p mt-2">
                                                                <FormControl className="interstateRadio" variant="outlined" fullWidth component="fieldset">
                                                                    <RadioGroup row
                                                                        value={users.getUserRegistrationDetails.family_details[i].is_regisrered_user}
                                                                        name={"alreadyRegistered" + i}
                                                                        disabled
                                                                    >
                                                                        {/* <p className="mt-2 mb-2">Already Registered in KBOCWWB</p> */}
                                                                        <p className="pad-new mt-2 mb-2"><Translate value="alreadyRegisteredinKBOCWWB" />*</p>

                                                                        {/* <FormControlLabel className="col-2" value={1} control={<Radio />} label="Yes" /> */}
                                                                        <FormControlLabel className="col-2" value={1} control={<Radio />} label={<Translate value="yes" />} />
                                                                        <FormControlLabel className="col-2" value={0} control={<Radio />} label={<Translate value="no" />} />
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            </Col>
                                                            <Col xs={6} md={4} className="member-p mt-2">
                                                                {
                                                                    users.getUserRegistrationDetails.family_details[i].is_regisrered_user === 1 ?

                                                                        (
                                                                            <div className="regnum">
                                                                                <p className="mt-2 mb-2">KBOCWWB Registration Number</p>
                                                                                <FormControl >
                                                                                    <TextField
                                                                                        variant="outlined"
                                                                                        disabled
                                                                                        value={users.getUserRegistrationDetails.family_details[i].registration_code}
                                                                                        InputProps={{
                                                                                            endAdornment:
                                                                                                <InputAdornment position="start">
                                                                                                    {
                                                                                                        users.family_details.pancheck ?
                                                                                                            (<img alt="..." src={checkgreen} />)
                                                                                                            :
                                                                                                            (<img alt="..." src={checkgrey} />)
                                                                                                    }
                                                                                                </InputAdornment>,
                                                                                        }}
                                                                                    />
                                                                                </FormControl>
                                                                            </div>
                                                                        )

                                                                        : null
                                                                }
                                                            </Col>

                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        )
                                    })
                                }
                            </Row>




                            <Row className="mt-5 mb-0 final all-details-subtitle-row">
                                <Col xs={12} md={12} className="profile-title">
                                    <img alt="..." src={bank} />
                                    {/* <h1>Bank Details</h1> */}
                                    <h1><Translate value="bankDetails" /></h1>
                                    {/* <p onClick={() => props.editButtonPressed(4)}>Edit your details<img src={edit}/></p> */}
                                    {
                                        !allValues.finalSubmitted ?
                                            <p onClick={() => props.editButtonPressed(4)}><Translate value = "edityourdetails" /><img src={edit} /></p>
                                            :
                                            <p style={{ backgroundColor: "#F3F6F8", boxShadow: "none", color: "black" }}>Submitted  <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "20px" }} /></p>
                                    }
                                </Col>
                            </Row>
                            <Row className="form-row-final mt-4">
                                <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="IFSC Code" /> */}
                                    <Required className="mb-0" title={<Translate value="iFSCCode" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.bank_details[0].ifsc_code}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Account Number" /> */}
                                    <Required className="mb-0" title={<Translate value="accountNumber" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.bank_details[0].account_no}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Bank Name" /> */}
                                    <Required className="mb-0" title={<Translate value="bankName" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.bank_details[0].bank_name}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Bank Branch" /> */}
                                    <Required className="mb-0" title={<Translate value="bankBranch" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.bank_details[0].bank_branch}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                                    {/* <Required className="mb-0" title="Bank Branch" /> */}
                                    <Required className="mb-0" title={<Translate value="bankAddress" />} />

                                    <FormControl fullWidth>
                                        <TextareaAutosize
                                            variant="outlined"
                                            multiline
                                            rows={4}
                                            value={users.getUserRegistrationDetails.bank_details[0].bank_address}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">

                                    <Required className="mb-0" title={<Translate value="passbookDocument" />} />

                                    {
                                        Object.keys(BankDocs).map((id, i) =>
                                            <>
                                                <div className="browsebutton2-div final">
                                                    <FormControl>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                BankDocs !== undefined &&
                                                                BankDocs.length > 0 &&
                                                                BankDocs[i].name
                                                            }
                                                            style={{ width: "-webkit-fill-available" }}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <label className="browse-button2"
                                                        onClick={(e) => handleClick(e, "passbook", BankDocs[i].base_64_content)}>
                                                        <Translate value="view" /> <Visibility />
                                                    </label>
                                                </div>

                                            </>

                                        )
                                    }

                                    <Popover
                                        id={id}
                                        open={openPassbook}
                                        anchorEl={anchorEl}
                                        onClose={(e) => handleClose(e, "passbook")}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                    >
                                        <a href="#" >
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // id={item.id}
                                                src={`
                                data:image/jpeg;base64,${selected_document_type}`}

                                                onClick={() => {
                                                    var win = window.open();
                                                    win.document.write('<iframe src="' + `
                                    data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                }}
                                            />
                                        </a>
                                    </Popover>


                                </Col>

                                {
                                    users.getUserRegistrationDetails.bank_details.length > 1 ?
                                        <>
                                            <Col xs={10} className="profile-title">
                                                {/* <h2>Nominee Bank Details</h2> */}
                                                <h2><Translate value="nomineeBankDetails" /></h2>
                                            </Col>
                                            <Col xs={3} className="final-personal-col-1 px-3">
                                                {/* <Required className="mb-0" title="IFSC Code" /> */}
                                                <Required className="mb-0" title={<Translate value="iFSCCode" />} />
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={users.getUserRegistrationDetails.bank_details[1].ifsc_code}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={3} className="final-personal-col-1 px-3">
                                                <Required className="mb-0" title={<Translate value="accountNumber" />} />
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={users.getUserRegistrationDetails.bank_details[1].account_no}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={3} className="final-personal-col-1 px-3">
                                                {/* <Required className="mb-0" title="Bank Name" /> */}
                                                <Required className="mb-0" title={<Translate value="bankName" />} />
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={users.getUserRegistrationDetails.bank_details[1].bank_name}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={3} className="final-personal-col-1 px-3">
                                                {/* <Required className="mb-0" title="Bank Branch" /> */}
                                                <Required className="mb-0" title={<Translate value="bankBranch" />} />
                                                <FormControl fullWidth>
                                                    <TextField
                                                        value={users.getUserRegistrationDetails.bank_details[1].bank_branch}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                                                {/* <Required className="mb-0" title="Bank Address" /> */}
                                                <Required className="mb-0" title={<Translate value="bankAddress" />} />
                                                <FormControl fullWidth>
                                                    <TextareaAutosize
                                                        variant="outlined"
                                                        multiline
                                                        rows={4}
                                                        value={users.getUserRegistrationDetails.bank_details[1].bank_address}
                                                        disabled
                                                    />
                                                </FormControl>
                                            </Col>
                                            <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                                                <Required className="mb-0" title={<Translate value="passbookDocument" />} />

                                                {
                                                    Object.keys(NomineeBankDocs).map((id, i) =>
                                                        <>
                                                            <div className="browsebutton2-div final">
                                                                <FormControl>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={
                                                                            NomineeBankDocs !== undefined &&
                                                                            NomineeBankDocs.length > 0 &&
                                                                            NomineeBankDocs[i].name
                                                                        }
                                                                        style={{ width: "-webkit-fill-available" }}
                                                                        disabled
                                                                    />
                                                                </FormControl>
                                                                <label className="browse-button2"
                                                                    onClick={(e) => handleClick(e, "passbook", NomineeBankDocs[i].base_64_content)}>
                                                                    <Translate value="view" /> <Visibility />
                                                                </label>
                                                            </div>

                                                        </>

                                                    )
                                                }

                                                <Popover
                                                    id={idpassbook_nominee}
                                                    open={openPassbookNominee}
                                                    anchorEl={anchorElpassbook_nominee}
                                                    onClose={(e) => handleClose(e, "passbook_nominee")}
                                                    anchorOrigin={{
                                                        vertical: "bottom",
                                                        horizontal: "center"
                                                    }}
                                                    transformOrigin={{
                                                        vertical: "top",
                                                        horizontal: "center"
                                                    }}
                                                >
                                                    <a href="#" >
                                                        <img
                                                            alt="..."
                                                            className="avatar border-gray"
                                                            // id={item.id}
                                                            src={`
                                            data:image/jpeg;base64,${selected_document_type}`}

                                                            onClick={() => {
                                                                var win = window.open();
                                                                win.document.write('<iframe src="' + `
                                                data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                            }}
                                                        />
                                                    </a>
                                                </Popover>


                                            </Col>

                                        </>
                                        : null
                                }
                            </Row>





                            <Row className="mt-5 mb-0 final all-details-subtitle-row">
                                <Col xs={12} md={12} className="profile-title">
                                    <img alt="..." src={nintydays} />
                                    {/* <h1>Details of 90 Days Working Certificate & Employer Details</h1> */}
                                    <h1 class="text-val"><Translate value="detailsof90DaysWorkingCertificateEmployerDetails" /></h1>

                                    {/* <p onClick={() => props.editButtonPressed(5)}>Edit your details<img src={edit}/></p> */}
                                    {
                                        !allValues.finalSubmitted ?
                                            <p onClick={() => props.editButtonPressed(5)}><Translate value = "edityourdetails" /><img src={edit} /></p>
                                            :
                                            <p style={{ backgroundColor: "#F3F6F8", boxShadow: "none", color: "black" }}>Submitted  <CheckCircle className={classes.backdropCheck} style={{ height: "auto", width: "20px", alignSelf: "center" }} /></p>
                                    }
                                </Col>
                            </Row>

                            {
                                users.getUserRegistrationDetails.employer_details.map((id, i) => {
                                    return (
                                        <>
                                            <Row className="form-row-final mt-3">
                                                <Row className={"mt-0 pt-4 familymember-card final row" + i}>

                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                                        {/* <Required className="mb-0" title="Employer Details" /> */}
                                                        <Required className="mb-0" title={<Translate value="employerDetails" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].employement_status}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                                        {/* <Required className="mb-0" title="Constractor Name" /> */}
                                                        <Required className="mb-0" title={<Translate value="constractorName" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].contractor_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3">
                                                        {/* <Required className="mb-0" title="Constractor Company Name" /> */}
                                                        <Required className="mb-0" title={<Translate value="constractorCompanyName" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].contractor_company_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Mobile Number (Incharge Person)" /> */}
                                                        <Required className="mb-0" style="margin-top:10px;" title={<Translate value="mobileNumberInchargePerson" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].mobile_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Workplace/Site Address" /> */}
                                                        <Required className="mb-0" title={<Translate value="workplaceSiteAddress" />} />
                                                        <FormControl fullWidth>
                                                            <TextareaAutosize
                                                                variant="outlined"
                                                                multiline
                                                                rows={2}
                                                                value={users.getUserRegistrationDetails.employer_details[i].site_address}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="State" /> */}
                                                        <Required className="mb-0" title={<Translate value="state" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].state}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Workplace/District" /> */}
                                                        <Required className="mb-0" title={<Translate value="workplaceDistrict" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].district}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Taluk" /> */}
                                                        <Required className="mb-0" title={<Translate value="taluk" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].taluk}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Town/City/Gram Panchayat" /> */}
                                                        <Required className="mb-0" title={<Translate value="townCityGramPanchayat" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].panhayat_city_town}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Village/Ward Circle" /> */}
                                                        <Required className="mb-0" title={<Translate value="villageWardCircle" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].ward_area_village}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="pin_code" /> */}
                                                        <Required className="mb-0" title={<Translate value="pin_code" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].pin_code}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md={3} className="final-personal-col-1 px-3 mt-3">
                                                        {/* <Required className="mb-0" title="Nature of Work" /> */}
                                                        <Required className="mb-0" title={<Translate value="natureofWork" />} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.employer_details[i].nature_of_work}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>

                                                </Row>

                                            </Row>
                                        </>
                                    )
                                })
                            }

                            <Row className="form-row-final mt-4">
                                <Col xs={12} md= {12} className="profile-title">
                                    <h2><Translate value ="daysWorkCertificateDetails"/></h2>
                                    {/* <h2><Translate value="DaysWorkCertificateDetails"/></h2> */}
                                </Col>
                                <Col xs={12} md= {6} className="final-personal-col-1">
                                    {/* <Required className="mb-0" title="Type of Issuer" /> */}
                                    <Required className="mb-0" title={<Translate value="typeofIssuer" />} />
                                    <FormControl fullWidth>
                                        <TextField
                                            value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].issuer_type}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>
                                <Col xs={12} md= {6} className="final-personal-col-1 px-3">
                                    {/* <Required className="mb-0" title="Issued Date" /> */}
                                    <Required className="mb-0 new-pad" title={<Translate value="issuedDate" />} />
                                    <FormControl fullWidth>
                                        <TextField

                                            value={users.getUserRegistrationDetails.certificate_details.length > 0 && moment(users.getUserRegistrationDetails.certificate_details[0].issue_date).format('DD.MM.YYYY')}
                                            disabled
                                        />
                                    </FormControl>
                                </Col>

                                <Col xs={11} md= {12} className="profile-title">
                                    <Row>
                                        {
                                            {
                                                55: <>
                                                    <Col xs={12} md= {4} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Labour Inspector Name</p> */}
                                                        <p className="mt-0 mb-2 new-pad"><Translate value="labourInspectorName" /></p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].inspector_first_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md= {4} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Circle of the Labour Inspector </p> */}
                                                        <p className="mt-0 mb-2 new-pad"><Translate value="circleoftheLabourInspector" /> </p>

                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].inspector_circle}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={12} md= {4} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2 ">Mobile Number of the Labour Inspector </p> */}
                                                        <p className="mt-0 mb-2 new-space"><Translate value="mobileNumberoftheLabourInspector" /> </p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                InputProps={{
                                                                    startAdornment:
                                                                        <InputAdornment position="start">
                                                                            <img src={mobilepic} alt="..." className="phone-icon" />
                                                                            <p className="countrycode">+91</p>
                                                                        </InputAdornment>,
                                                                }}
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].inspector_mobile_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </>,
                                                56: <>
                                                    <Col xs={10} md= {3} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Union Name</p> */}
                                                        <p className="mt-0 mb-2"><Translate value="unionName" /></p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].labour_union_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={10} md= {3} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Union Registration Number</p> */}
                                                        <p className="mt-0 mb-2"><Translate value="unionRegistrationNumber" /></p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].labour_union_registration_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={10} md= {3} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Name of Issuer</p> */}
                                                        <p className="mt-0 mb-2"><Translate value="nameofIssuer" /></p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].labour_union_spoke_person}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={10} md= {3} className="issuerTypeForm">
                                                        {/* <p className="mt-0 mb-2">Mobile Number (If Available)</p> */}
                                                        <p className="mt-0 mb-2"><Translate value="mobileNumberIfAvailable" /></p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                InputProps={{
                                                                    startAdornment:
                                                                        <InputAdornment position="start">
                                                                            <img src={mobilepic} alt="..." className="phone-icon" />
                                                                            <p className="countrycode">+91</p>
                                                                        </InputAdornment>,
                                                                }}
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].aslabour_union_phone_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </>,
                                                57: <>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Name of Issuer</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].issuer_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Place of Issuer</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].issuer_place}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Mobile Number (If Available)</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                InputProps={{
                                                                    startAdornment:
                                                                        <InputAdornment position="start">
                                                                            <img src={mobile} alt="..." className="phone-icon" />
                                                                            <p className="countrycode">+91</p>
                                                                        </InputAdornment>,
                                                                }}
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].mobile_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </>,
                                                58: <>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Name of Issuer</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].issuer_name}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Designation of Issuer</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].issuer_designation}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col xs={4} className="issuerTypeForm">
                                                        <p className="mt-0 mb-2">Mobile Number (If Available)</p>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                InputProps={{
                                                                    startAdornment:
                                                                        <InputAdornment position="start">
                                                                            <img src={mobile} alt="..." className="phone-icon" />
                                                                            <p className="countrycode">+91</p>
                                                                        </InputAdornment>,
                                                                }}
                                                                value={users.getUserRegistrationDetails.certificate_details.length > 0 && users.getUserRegistrationDetails.certificate_details[0].mobile_no}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </>,
                                            }[
                                            users.getUserRegistrationDetails.certificate_details.length > 0 &&
                                            users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id]
                                        }

                                        {
                                            console.log("certificateDocs: " + JSON.stringify(certificateDocs, undefined, 2))
                                        }
                                        {
                                            console.log("certificateDocs.length: " + Object.keys(certificateDocs, undefined, 2).length)
                                        }



                                        <Col xs={12} md={6} className="final-personal-col-1 px-3 mt-3">
                                            <Required className="mb-0" title={<Translate value="DaysWorkingCertificate" />} />
                                            {
                                                Object.keys(certificateDocs).map((id, i) =>
                                                    // certificateDocs.map((id, i) => 

                                                    <>

                                                        <div className="browsebutton2-div final">
                                                            <FormControl>
                                                                <TextField
                                                                    variant="outlined"
                                                                    value={
                                                                        certificateDocs !== undefined &&
                                                                        certificateDocs.length > 0 &&
                                                                        certificateDocs[i].name
                                                                    }
                                                                    style={{ width: "-webkit-fill-available" }}
                                                                    disabled
                                                                />
                                                            </FormControl>
                                                            <label className="browse-button2"
                                                                onClick={(e) => handleClick(e, "ninty", certificateDocs[i].base_64_content)}
                                                            // onClick={(e) => handleClick(e, "ninty")}
                                                            >
                                                                <Translate value="view" /> <Visibility />
                                                            </label>


                                                        </div>

                                                    </>

                                                )
                                            }

                                            <Popover
                                                id={idninty}
                                                open={openninty}
                                                anchorEl={anchorElninty}
                                                onClose={(e) => handleClose(e, "ninty")}
                                                anchorOrigin={{
                                                    vertical: "bottom",
                                                    horizontal: "center"
                                                }}
                                                transformOrigin={{
                                                    vertical: "top",
                                                    horizontal: "center"
                                                }}
                                            >
                                                <a href="#" >
                                                    <img
                                                        alt="..."
                                                        className="avatar border-gray"
                                                        // id={item.id}
                                                        src={`
                                data:image/jpeg;base64,${selected_document_type}`}

                                                        onClick={() => {
                                                            var win = window.open();
                                                            win.document.write('<iframe src="' + `
                                    data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                        }}
                                                    />
                                                </a>
                                            </Popover>

                                        </Col>

                                    </Row>
                                </Col>
                            </Row>

                            {
                                !allValues.finalSubmitted ?
                                    <>
                                        <Row className="scheme-subtitle-row mt-4">
                                            <Col xs={12} className="searchform-subtitle">
                                                <p><p><Translate value = "declaration" /></p></p>
                                            </Col>
                                        </Row>

                                        <Row className="form-row mt-4 config-form searchform">
                                            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={allValues.declaration}
                                                            onChange={handleChangeCheckbox}
                                                            name="declaration"
                                                        // color="primary"
                                                        />
                                                    }
                                                    label={<Translate value = "declarationmessage" />}
                                                />
                                            </Col>
                                            
                                            <Col xs={12} className="note2 schemes mt-4 bank-col-5">
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={allValues.declaration2}
                                                            onChange={handleChangeCheckbox}
                                                            name="declaration2"
                                                        // color="primary"
                                                        />
                                                    }
                                                    label={<Translate value = "declarationmessage2" />}
                                                />
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    null
                            }



                            <Row className="button-inside-form-row mt-5 mb-5" style={{
                                borderTop: "2px solid #cccccc" }}>
                                <Col xs={12} className="final-button-row mt-4 mb-5">

                                    {
                                        !allValues.finalSubmitted ?
                                            <Link to="#"
                                                onClick={finalSubmitButtonPressed}
                                                style={{ flexGrow: "0.5" }}
                                            >
                                                <Button variant="outline-primary" className="final-button">
                                                    <Translate value="finalSubmit" />
                                                </Button>
                                            </Link>
                                            :
                                            <Link to="#"
                                                onClick={printDocument}
                                                style={{ flexGrow: "0.5" }}
                                            >
                                                <Button variant="outline-primary" className="final-button">
                                                    <Translate value="viewAcknowledgementLetter" />
                                                </Button>
                                            </Link>
                                    }
                                </Col>
                            </Row>

                        </Row>




                        {/* ********************   Acknowledgement Letter HTML ************************ */}
                        <div>
                            {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
                            <div id="divToPrint" className="mt-4" style={{
                                backgroundColor: '#fff',
                                width: '210mm',
                                minHeight: '297mm',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                textAlign: 'center',
                                // justifyContent: 'center',
                                display: 'none'
                                // border: '2px solid black',
                            }}>
                                <div className="logo-div-ack">
                                    <a href="/dashboard-user">
                                        <img id="logo" src={logo} alt="..." className="logo-img" />
                                    </a>
                                    <h1 className="logo-text">
                                    ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕಕಇನಿಕಾಕಮಂ) <br />
                            Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB) <br />
                                    </h1>
                                </div>

                                <Row className="ack-subtitle-row">
                                    <Col xs={10} className="profile-title" style={{ display: "block" }}>
                                        <h1><Translate value="acknowledgement" style={{position:'relative',left:'60px'}} /></h1>
                                    </Col>
                                </Row>

                                <p className="ack-success">
                                    <img src={checkGreen} style={{ height: "auto", width: "28px", marginRight: "10px" }} />
                                    {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
                                    <Translate value="applicationsubmitted"/></p>

                                <Row className="ack-table-row mt-5">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}} ><Translate value="applicationnumber"/></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{appNo}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="applicationdate" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Moment format="DD MMMM YYYY">{date}</Moment></p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="requestedService" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="newregistration" /></p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="applicantName" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{applicantName}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="residentialAddress2" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{presentAddress}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="villageWardCircle2" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{villageWard}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="mobileNumber2" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{mobile}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="profession2" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{occupation}</p>
                                    </Col>
                                </Row>

                                {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="circleName" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{circleName}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="labourInspectorIncharge" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{officerIncharge}</p>
                                    </Col>
                                </Row>

                                <Row className="ack-table-row-last mt-2">
                                    <Col xs={2}></Col>
                                    <Col xs={10} className="ack-col-note">
                                        <p><Translate value="note2" /></p>
                                    </Col>
                                </Row>
                                <Row className="ack-table-row-last">
                                    <Col xs={2}></Col>
                                    <Col xs={10} className="ack-col">
                                        <p><Translate value="acksubmit1" style={{position:'relative',bottom:'20px'}} /></p>
                                    </Col>
                                </Row>
                                <Row className="ack-table-row-last">
                                    <Col xs={2}></Col>
                                    <Col xs={10} className="ack-col">
                                        <p><Translate value="acksubmit2" style={{position:'relative',bottom:'30px'}} /></p>
                                    </Col>
                                </Row>




                            </div>


                        </div>
                    </>

                    : null

            }
        </>
    )

}

export default Review