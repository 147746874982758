import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  TextareaAutosize,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button, Tooltip } from "@mui/material";
import { Translate } from "react-redux-i18n";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  SERVER,
  addLabourAddressDetails,
  addLabourBankDetails,
  addLabourFamilyDetails,
  addLabourPersonalDetails,
  erroraddLabourBankDetails,
  getUserRegistrationDetails,
} from "store/actions/user.actions";
import { Required, getTokenCookie, showToast } from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import Header from "./Header";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import closeIcon from "assets/images/close.png";
import {
  AddCircleRounded,
  CameraAlt,
  Close,
  PhoneAndroid,
} from "@material-ui/icons";
import Webcam from "react-webcam";
import folder from "assets/images/Folderwhite-01-01.svg";
// import webcam from 'assets/images/web-cam.svg'
import webcam from "assets/images/web cam-01.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import uploadFileAPI from "utils/fileupload";
import FolderIcon from "@mui/icons-material/Folder";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import avatar from "assets/images/avatar.png";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import CancelIcon from "@mui/icons-material/Cancel";
import Select2 from "react-select";
import mobile from "assets/images/Phone 1-01.svg";
import { hasEmptyPropertiesEmployerDetails } from "utils/checkEmptyArray";
import SimpleBackdrop from "components/loadingScreen/Backdrop";

const NintyDaysDetails = () => {
  const { labourUserID } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.user);

  const [fetchEmployerDetails, setFetchEmployerDetails] = useState(null);
  const [fetchNatureOfWork, setFetchNatureOfWork] = useState(null);
  const [fetchDistrict, setFetchDistrict] = useState(null);
  const [fetchTaluk, setFetchTaluk] = useState(null);
  const [fetchCity, setFetchCity] = useState(null);
  const [fetchVillage, setFetchVillage] = useState(null);

  const [fetchTypeOfIssuer, setFetchTypeOfIssuer] = useState(null);
  const [issuerTypeID, setIssuerTypeID] = useState(null);
  const [issueDate, setIssueDate] = useState(null);

  const [villageID, setVillageID] = useState(null);
  const [inspectorID, setInspectorID] = useState(null);
  const [inspectorName, setInspectorName] = useState(null);
  const [circleName, setCirlceName] = useState(null);
  const [circleID, setCirlceID] = useState(null);
  const [labourInspectormobileNum, setLabourInspectorMobileNumber] =
    useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [union, setUnion] = useState(null);
  const [unionID, setUnionID] = useState(null);
  const [unionAddress, setUnionAddress] = useState(null);
  const [unionRegistrationNumber, setUnionRegistrationNumber] = useState(null);
  const [unionIssuerName, setUnionIssuerName] = useState(null);
  const [unionMobileNumber, setUnionMobileNumber] = useState(null);

  const [gramIssuerName, setGramIssuerName] = useState(null);
  const [gramIssuerPlace, setGramIssuerPlace] = useState(null);
  const [gramIssuerMobileNumber, setGramIssuerMobileNumber] = useState(null);

  const [constructionIssuerName, setConstructionIssuerName] = useState(null);
  const [constructionDesignation, setConstructionDesignation] = useState(null);
  const [constructionMobileNumber, setConstructionMobileNumber] =
    useState(null);

  const [nintyDaysDoc, setNintyDaysDoc] = useState([]);
  const [nintyDaysDocID, setNintyDaysDocID] = useState(null);
  const nintyDaysDocRef = useRef(null);

  const [submitLoading, setSubmitLoading] = useState(false);
  const [employerDetails, setEmployerDetails] = useState([
    {
      employerStatus: 25,
      employerName: null,
      workplaceSiteAddress: null,
      companyName: null,
      mobileNumber: null,
      state: null,
      district: null,
      taluk: null,
      city: null,
      village: null,
      pinCode: null,
      natureOfWork: null,
    },
  ]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Employer details",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then(({ data }) => {
        setFetchEmployerDetails(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Nature of Work",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then(({ data }) => {
        setFetchNatureOfWork(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Type of Issuer",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then(({ data }) => {
        setFetchTypeOfIssuer(data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "address_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        console.log(data);
        setVillageID(data.data[0].village_area_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "90_days_employer_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        if (data.length > 0) {
          // console.log('DATA', data);
          const result = data.map((data) => {
            callTaluk(null, data.district_id);
            callCity(null, data.taluk_id);
            callVillage(null, data.city_town_gram_panchayat_id);

            return {
              employerStatus: data.catalog_value_employement_status_id,
              employerName: data.contractor_name,
              workplaceSiteAddress: data.site_address,
              companyName: data.contractor_company_name,
              mobileNumber: data.mobile_no,
              state: data.state_id,
              district: data.district_id,
              taluk: data.taluk_id,
              city: data.city_town_gram_panchayat_id,
              village: data.village_area_id,
              pinCode: data.pin_code,
              natureOfWork: data.catalog_value_nature_of_work,
            };
          });
          // console.log('Result', result);
          setEmployerDetails(result);
        }
      })
      .catch((err) => {
        if (err) {
          // showToast('ERROR', `${JSON.stringify(err)}`);
          showToast("ERROR", "Something went wrong");
        } else {
          // setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "90_days_certificate_details",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        const { data } = res.data;

        if (data.length > 0) {
          // console.log('DATA', data);
          setSelectedOption(data[0].labour_union_name);
          setIssuerTypeID(data[0].catalog_value_type_of_issuer_id);
          setIssueDate(data[0].issue_date);
          setUnionID(data[0].labour_union_id);
          setUnionRegistrationNumber(data[0].labour_union_registration_no);
          setUnionAddress(data[0].labour_union_address);
          setUnionIssuerName(data[0].issuer_name);
          setUnionMobileNumber(data[0].mobile_no);

          const employmentDoc = JSON.parse(data[0].document_uplaod_files_xml);
          if (
            employmentDoc &&
            Array.isArray(employmentDoc) &&
            employmentDoc.length > 0
          ) {
            setNintyDaysDocID(employmentDoc);
          }
          // console.log('Result', result);
        }
      })
      .catch((err) => {
        if (err) {
          // showToast('ERROR', `${JSON.stringify(err)}`);
          showToast("ERROR", "Something went wrong");
        } else {
          // setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    if (villageID) {
      axios
        .get(
          `${SERVER}/global/get_inspector_details?board_id=1&village_area_id=${villageID}`
        )
        .then(({ data }) => {
          setInspectorName(
            data.data[0].first_name +
              " " +
              data.data[0].middle_name +
              " " +
              data.data[0].last_name
          );
          setCirlceName(data.data[0].circle);
          setLabourInspectorMobileNumber(data.data[0].mobile_no);
          setInspectorID(data.data[0].department_user_id);
          setCirlceID(data.data[0].circle_id);
        });
    }
  }, [villageID]);

  useEffect(() => {
    if (issuerTypeID === 56) {
      axios
        .get(`${SERVER}/global/get_union_details?board_id=1`)
        .then((res) => {
          const newData = res.data.data.map((item) => ({
            ...item,
            value: item.labour_union_id,
            label: item.name,
          }));
          setUnion(newData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [issuerTypeID]);

  // useEffect(() => {
  //   axios
  //     .get(`${SERVER}/global/get_district_details?state_id=${12}`)
  //     .then((res) => {
  //       const { data } = res.data;
  //       setFetchDistrict(data);
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //     });
  // }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/global/get_district_details?state_id=${12}`)
      .then((res) => {
        const { data } = res.data;
        setFetchDistrict(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const callTaluk = (index, districtID) => {
    axios
      .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchTaluk(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callCity = (index, talukID) => {
    axios
      .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchCity(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const callVillage = (index, cityID) => {
    axios
      .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
      .then((res) => {
        const { data } = res.data;
        setFetchVillage(data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleDropdownSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setUnionID(selectedOption.labour_union_id);
    setUnionAddress(selectedOption.address);
    setUnionRegistrationNumber(selectedOption.registeration_no);
    setUnionIssuerName(selectedOption.spoke_person);
    setUnionMobileNumber(selectedOption.phone_no);
  };

  const addNewEmploymentDetail = () => {
    let newfield = {
      employerStatus: null,
      employerName: null,
      workplaceSiteAddress: null,
      companyName: null,
      mobileNumber: null,
      state: null,
      district: null,
      taluk: null,
      city: null,
      village: null,
      pinCode: null,
      natureOfWork: null,
    };

    setEmployerDetails([...employerDetails, newfield]);
  };

  const handleFormChange = (index, event) => {
    let data = [...employerDetails];
    if (event.target.name === "mobileNumber") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "employerName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^a-zA-Z ]/gi,
        ""
      );
    } else if (event.target.name === "companyName") {
      data[index][event.target.name] = event.target.value.replace(
        /[^a-zA-Z ]/gi,
        ""
      );
    } else if (event.target.name === "pinCode") {
      data[index][event.target.name] = event.target.value.replace(
        /[^0-9]/gi,
        ""
      );
    } else if (event.target.name === "district") {
      data[index][event.target.name] = event.target.value;
      callTaluk(index, event.target.value);
    } else if (event.target.name === "taluk") {
      data[index][event.target.name] = event.target.value;
      callCity(index, event.target.value);
    } else if (event.target.name === "city") {
      data[index][event.target.name] = event.target.value;
      callVillage(index, event.target.value);
    } else {
      data[index][event.target.name] = event.target.value;
    }

    setEmployerDetails(data);
  };

  const handleNintyDaysFile = (ev) => {
    if (
      ev.target.files[0].type === "image/png" ||
      ev.target.files[0].type === "image/jpeg" ||
      ev.target.files[0].type === "image/jpg" ||
      ev.target.files[0].type === "application/pdf"
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...nintyDaysDoc, ...ev.target.files];
        setNintyDaysDoc(newFiles);
      } else {
        showToast("ERROR", "Please upload file size less than 2MB");
      }
    } else {
      showToast("ERROR", "Please upload file in the PNG or JPEG/JPG format");
    }
  };

  const uploadFiles = () => {
    if (nintyDaysDoc && nintyDaysDoc.length > 0) {
      //  console.log(nintyDaysDoc);
      const promises = [];
      nintyDaysDoc.forEach((doc) => {
        promises.push(uploadFileAPI(doc));
      });
      Promise.all(promises)
        .then((promiseResponse) => {
          const mappedData = promiseResponse.map((doc) => doc.image);

          insertNintyDaysDetails(mappedData);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    } else if (nintyDaysDocID && nintyDaysDocID.length > 0) {
      insertNintyDaysDetails(null);
    }
  };

  const insertNintyDaysDetails = (nintyDaysDocument) => {
    // console.log('nintyDaysDoc', nintyDaysDocument);
    if (nintyDaysDoc && nintyDaysDoc.length > 0) {
      const nintyDaysDocument_ = nintyDaysDocument.map((doc) => ({
        file_id: doc.fileId,
        file_name: doc.originalFileName,
      }));
      // console.log('nintyDaysDocument_', nintyDaysDocument_);
      const employerData = employerDetails.map((data) => ({
        catalog_value_employement_status_id: data.employerStatus,
        contractor_company_name: data.companyName.trim(),
        contractor_name: data.employerName.trim(),
        mobile_country_code: "+91",
        mobile_no: data.mobileNumber,
        catalog_value_nature_of_work: data.natureOfWork,
        state_id: data.state,
        site_address: data.workplaceSiteAddress,
        district_id: data.district,
        taluk_id: data.taluk,
        city_town_gram_panchayat_id: data.city,
        village_area_id: data.village,
        pin_code: data.pinCode,
      }));

      try {
        const payLoad = {
          user_id: labourUserID,
          board_id: 1,
          catalog_value_type_of_issuer_id: +issuerTypeID,
          issue_date: moment(issueDate).format("YYYY/MM/DD"),
          document_uploaded_id: "-",
          labour_incpector_id: inspectorID,
          labour_union_id: issuerTypeID === 56 ? unionID : "",
          issuer_name:
            issuerTypeID === 56
              ? unionIssuerName
              : issuerTypeID === 57
                ? gramIssuerName
                : issuerTypeID === 58
                  ? constructionIssuerName
                  : null,
          issuer_place:
            issuerTypeID === 56
              ? unionAddress
              : issuerTypeID === 57
                ? gramIssuerPlace
                : "",
          issuer_designation: constructionDesignation ?? "",
          mobile_country_code: "+91",
          mobile_no:
            issuerTypeID === 56
              ? unionMobileNumber
              : issuerTypeID === 57
                ? gramIssuerMobileNumber
                : issuerTypeID === 58
                  ? constructionMobileNumber
                  : "",
          catalog_value_registration_status_id: 45,
          circle_id: circleID,
          files: nintyDaysDocument_,
          employers: employerData,
        };

        axios
          .post(`${SERVER}/user/add-labour-certificate`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast(
              "SUCCESS",
              "90 Days Certificate Details Saved Successfully"
            );
            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
            history.push(`/profile/review/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
          })
          .catch((err) => {
            // console.log('err:add-labour-certificate', err);
            if (err) {
              setSubmitLoading(false);
              showToast("ERROR", `${JSON.stringify(err)}`);
            } else {
              setSubmitLoading(true);
              showToast("ERROR", "Something went wrong");
            }
            // if (err.response.data.errors.msg) {
            //   setSubmitLoading(false);
            //   showToast('ERROR', `${err.response.data.errors.msg}`);
            // } else {
            //   setSubmitLoading(true);
            //   showToast('ERROR', 'Something went wrong');
            // }
          });
      } catch (error) {}
    } else if (nintyDaysDocID && nintyDaysDocID.length > 0) {
      const nintyDoc = nintyDaysDocID.map((data) => ({
        file_id: data.file_id,
        file_name: data.file_name,
      }));
      // console.log('nintyDaysDocument_', nintyDaysDocument_);
      const employerData = employerDetails.map((data) => ({
        catalog_value_employement_status_id: data.employerStatus,
        contractor_company_name: data.companyName.trim(),
        contractor_name: data.employerName.trim(),
        mobile_country_code: "+91",
        mobile_no: data.mobileNumber,
        catalog_value_nature_of_work: data.natureOfWork,
        state_id: data.state,
        site_address: data.workplaceSiteAddress,
        district_id: data.district,
        taluk_id: data.taluk,
        city_town_gram_panchayat_id: data.city,
        village_area_id: data.village,
        pin_code: data.pinCode,
      }));

      try {
        const payLoad = {
          user_id: labourUserID,
          board_id: 1,
          catalog_value_type_of_issuer_id: +issuerTypeID,
          issue_date: moment(issueDate).format("YYYY/MM/DD"),
          document_uploaded_id: "-",
          labour_incpector_id: inspectorID,
          labour_union_id: issuerTypeID === 56 ? unionID : "",
          issuer_name:
            issuerTypeID === 56
              ? unionIssuerName
              : issuerTypeID === 57
                ? gramIssuerName
                : issuerTypeID === 58
                  ? constructionIssuerName
                  : "",
          issuer_place:
            issuerTypeID === 56
              ? unionAddress
              : issuerTypeID === 57
                ? gramIssuerPlace
                : "",
          issuer_designation: constructionDesignation ?? "",
          mobile_country_code: "+91",
          mobile_no:
            issuerTypeID === 56
              ? unionMobileNumber
              : issuerTypeID === 57
                ? gramIssuerMobileNumber
                : issuerTypeID === 58
                  ? constructionMobileNumber
                  : "",
          catalog_value_registration_status_id: 45,
          circle_id: circleID,
          files: nintyDoc,
          employers: employerData,
        };

        axios
          .post(`${SERVER}/user/add-labour-certificate`, payLoad, {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          })
          .then(({ data }) => {
            setSubmitLoading(false);
            showToast(
              "SUCCESS",
              "90 Days Certificate Details Saved Successfully"
            );
            history.push(`/profile/review/${labourUserID}`);
            window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

            dispatch(
              getUserRegistrationDetails({
                board_id: 1,
                key: "user_id",
                value: labourUserID,
                procedure_name: "all",
              })
            );
          })
          .catch((err) => {
            // console.log('err:add-labour-certificate', err);
            if (err) {
              setSubmitLoading(false);
              showToast("ERROR", `${JSON.stringify(err)}`);
            } else {
              setSubmitLoading(false);
              showToast("ERROR", "Something went wrong");
            }
            // if (err.response.data.errors.msg) {
            //   setSubmitLoading(false);
            //   showToast('ERROR', `${err.response.data.errors.msg}`);
            // } else {
            //   setSubmitLoading(true);
            //   showToast('ERROR', 'Something went wrong');
            // }
          });
      } catch (error) {}
    }

    // console.log('90 days payLoad', payLoad);
  };

  const handleSubmit = () => {
    const employerDetailsCheck = employerDetails.filter(
      (item) => !hasEmptyPropertiesEmployerDetails(item)
    );
    if (employerDetails.length === employerDetailsCheck.length) {
      if (+issuerTypeID !== 0 && issueDate) {
        if (
          (nintyDaysDoc && nintyDaysDoc.length > 0) ||
          (nintyDaysDocID && nintyDaysDocID.length > 0)
        ) {
          if (+issuerTypeID === 56) {
            if (selectedOption) {
              setSubmitLoading(true);
              uploadFiles();
            } else {
              showToast("ERROR", "Please select Union");
            }
          } else {
            setSubmitLoading(true);
            uploadFiles();
          }
        } else {
          showToast("ERROR", "Please upload 90 days working certificate");
        }
      } else {
        showToast("ERROR", "Please fill 90 Days Work Certificate Details");
      }
    } else {
      showToast("ERROR", "Please fill the mandatory employer details");
    }
  };

  const handleRemoveNintyDaysFile = (index) => {
    const newFiles = [...nintyDaysDoc];
    newFiles.splice(index, 1);
    setNintyDaysDoc(newFiles);
    nintyDaysDocRef.current.value = "";
  };

  const removeFamilyMember = (index) => {
    let data = [...employerDetails];
    data.splice(index, 1);
    setEmployerDetails(data);
  };

  const handleBack = () => {
    history.push(`/profile/bank/${labourUserID}/back`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Row className="personal-div">
        {submitLoading ? <SimpleBackdrop /> : null}
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: "100%" }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              // value={allValues.language}
              name="language"
              displayEmpty
              // onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>
        <Row className="m-0">
          <Row className="family-title-row">
            <Col
              xs={12}
              className="profile-title"
              style={{ marginBottom: "-20px" }}
            >
              <h1>
                <Translate value="nintyDays" />
              </h1>
            </Col>
          </Row>
          <Row className="family-title-row">
            <Col xs={12} className="family-col-4">
              <>
                {employerDetails.map((data, index) => (
                  <>
                    <Row
                      className={"form-row employer-card row"}
                      style={{ position: "relative" }}
                    >
                      {index > 0 && (
                        <div
                          style={{
                            position: "absolute",
                            top: 0,
                            right: "0",
                            width: "30px",
                            height: "30px",
                          }}
                        >
                          <img
                            alt="..."
                            src={closeIcon}
                            className="closebtn"
                            onClick={() => removeFamilyMember(index)}
                          />
                        </div>
                      )}
                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="employerDetails" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            value={data.employerStatus || "0"}
                            name="employerStatus"
                            // displayEmpty
                            disabled={data.employerStatus === 25 ? true : false}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="0">
                              <ListItemText primary="Select" />
                            </MenuItem>
                            {fetchEmployerDetails &&
                              fetchEmployerDetails &&
                              fetchEmployerDetails.map((row) => (
                                <MenuItem
                                  value={row.value_id}
                                  disabled={row.value_id === 25 ? true : false}
                                >
                                  <ListItemText
                                    primary={row.value}
                                  ></ListItemText>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        {/* <p className="mt-3 mb-2">Name of Contractor / Developer :</p> */}
                        <Required
                          className="mt-3 mb-2"
                          title={
                            <Translate value="nameofContractorDeveloper" />
                          }
                        />
                        <FormControl fullWidth className="formcontrol1">
                          <TextField
                            variant="outlined"
                            placeholder="Enter Your Employer/Owner Name"
                            name="employerName"
                            value={data.employerName}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                            // error={EmpNameError}
                            // helperText={EmpNameError}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/[^A-Za-z ]/gi, '');
                            //   if (e.target.value.length === 0) {
                            //     setEmpNameError('Please Enter Employer/Owner Name');
                            //   } else {
                            //     setEmpNameError('');
                            //   }
                            // }}
                            inputProps={{ minLength: 0, maxLength: 30 }}
                          />
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="workplaceSiteAddress" />}
                        />
                        <FormControl fullWidth className="formcontrol1">
                          <TextareaAutosize
                            variant="outlined"
                            multiline
                            rows={1}
                            // rowsMax={4}
                            // defaultValue="Default Value"
                            placeholder="Enter Your Workplace/Site Address"
                            name="workplaceSiteAddress"
                            style={{ height: "46px" }}
                            value={data.workplaceSiteAddress}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          />
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="contractorCompanyName" />}
                        />
                        <FormControl fullWidth className="formcontrol1">
                          <TextField
                            variant="outlined"
                            placeholder="Enter Employer/Owner Company Name"
                            name="companyName"
                            value={data.companyName}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                            // error={CompNameError}
                            // helperText={CompNameError}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/[^A-Za-z ]/gi, '');
                            //   if (e.target.value.length === 0) {
                            //     setCompNameError(
                            //       'Please Enter Employer/Owner Company Name',
                            //     );
                            //   } else {
                            //     setCompNameError('');
                            //   }
                            // }}
                            inputProps={{ minLength: 0, maxLength: 30 }}
                          />
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={
                            <Translate value="mobileNumberInchargePerson" />
                          }
                        />
                        <FormControl fullWidth className="formcontrol2">
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobile}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            variant="outlined"
                            placeholder="987xxxxxxx"
                            name="mobileNumber"
                            value={data.mobileNumber}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                            // error={mobileError}
                            // helperText={mobileError}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/[^0-9]/gi, '');
                            //   if (e.target.value.length === 0) {
                            //     setMobileError('Please enter mobile number');
                            //   } else if (e.target.value.length < 10) {
                            //     setMobileError('Mobile number must contain 10 digits');
                            //   } else {
                            //     setMobileError('');
                            //   }
                            // }}
                            inputProps={{ minLength: 0, maxLength: 10 }}
                          />
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="state" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            name="state"
                            value={data.state}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                            displayEmpty
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {
                              <MenuItem value={12}>
                                <ListItemText primary="Karnataka" />
                              </MenuItem>
                            }
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={
                            <Translate value="selectYourDistrictoftheWorkplace" />
                          }
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            value={data.district}
                            name="district"
                            // displayEmpty
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {fetchDistrict &&
                              fetchDistrict.length &&
                              fetchDistrict.map((row) => (
                                <MenuItem value={row.id}>
                                  <ListItemText
                                    primary={row.name}
                                  ></ListItemText>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="talukoftheWorkplace" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            value={data.taluk}
                            name="taluk"
                            // displayEmpty
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {fetchTaluk &&
                              fetchTaluk.length &&
                              fetchTaluk.map((row) => (
                                <MenuItem value={row.id}>
                                  <ListItemText
                                    primary={row.name}
                                  ></ListItemText>
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="townCityGramPanchayat" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol1"
                        >
                          <Select
                            className="select-marital"
                            value={data.city}
                            name="city"
                            // displayEmpty
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {fetchCity &&
                              fetchCity.length &&
                              fetchCity.map((i) => (
                                <MenuItem value={i.id}>
                                  <ListItemText primary={i.name} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="villageWardCircle" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol1"
                        >
                          <Select
                            className="select-marital"
                            value={data.village}
                            name="village"
                            // displayEmpty
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {fetchVillage &&
                              fetchVillage.length &&
                              fetchVillage.map((i) => (
                                <MenuItem value={i.id}>
                                  <ListItemText primary={i.name} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="pincode" />}
                        />
                        <FormControl fullWidth className="formcontrol1">
                          <TextField
                            variant="outlined"
                            placeholder="Enter Your Pincode"
                            name="pinCode"
                            value={data.pinCode}
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                            // error={pinError}
                            // helperText={pinError}
                            // onInput={(e) => {
                            //   e.target.value = e.target.value.replace(/[^0-9]/gi, '');
                            //   if (e.target.value.length === 0) {
                            //     setPinError('Please Enter Pin Code');
                            //   } else if (e.target.value.length < 6) {
                            //     setPinError('Pin code must contain 6 digits');
                            //   } else {
                            //     setPinError('');
                            //   }
                            // }}
                            inputProps={{ minLength: 0, maxLength: 6 }}
                          />
                        </FormControl>
                      </Col>

                      <Col xs={12} md={6}>
                        <Required
                          className="mt-3 mb-2"
                          title={<Translate value="natureoftheWorkplace" />}
                        />
                        <FormControl
                          variant="outlined"
                          fullWidth
                          className="formcontrol5"
                        >
                          <Select
                            className="select-marital"
                            value={data.natureOfWork}
                            name="natureOfWork"
                            displayEmpty
                            onChange={(event) => {
                              handleFormChange(index, event);
                            }}
                          >
                            <MenuItem value="">
                              <ListItemText primary="-Select-" />
                            </MenuItem>
                            {fetchNatureOfWork &&
                              fetchNatureOfWork.length &&
                              fetchNatureOfWork.map((i) => (
                                <MenuItem value={i.value_id}>
                                  <ListItemText primary={i.value} />
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                ))}
              </>
            </Col>
          </Row>
          <Row className="form-row">
            <Col
              xs={12}
              className="addMemberCol mt-4 "
              // onClick={addNewEmploymentDetail}
              onClick={() => addNewEmploymentDetail("")}
            >
              <AddCircleRounded />
              <p>
                <Translate value="addMoreEmplymentDetails" />
              </p>
            </Col>

            <Col xs={12} className="note2 text-center mt-5 personal-col-7">
              {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
              <p>
                <span>
                  <Translate value="note" />{" "}
                </span>
                <Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess2" />
              </p>
            </Col>
          </Row>
          {/* Type of issue */}
          {employerDetails.some((ele) => ele.employerStatus === 25) && (
            <>
              <Row className="nintydays-cert-title-row">
                <Col xs={12} className="profile-title">
                  <h1 style={{ color: "#ca0027" }}>
                    <Translate value="daysWorkCertificateDetails" />
                  </h1>
                </Col>
              </Row>

              <Row className="form-row ninydays-row">
                <Col xs={12} className="nintydays-col-5">
                  <Row className="form-inner-card-plain">
                    <Col xs={12} md={6}>
                      <Required
                        className="mt-2 mb-2"
                        title={<Translate value="typeofIssuer" />}
                      />
                      <FormControl variant="outlined" fullWidth>
                        <Select
                          className="select-marital"
                          value={issuerTypeID || "0"}
                          name="issuerType"
                          displayEmpty
                          onChange={(ev) => setIssuerTypeID(ev.target.value)}
                        >
                          <MenuItem value="0">
                            <ListItemText primary="Select Type of Issuer" />
                          </MenuItem>
                          {fetchTypeOfIssuer &&
                            fetchTypeOfIssuer.length &&
                            fetchTypeOfIssuer.map((i) => (
                              <MenuItem value={i.value_id}>
                                <ListItemText primary={i.value} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={6}>
                      <Required
                        className="mt-2 mb-2"
                        title={<Translate value="issuedDate" />}
                      />
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          minDate={moment().subtract(90, "days")}
                          maxDate={new Date()}
                          // onClick={() => setPickerStatus(true)}
                          // onClose={() => setPickerStatus(false)}
                          // open={users.nintydays_details.openDatePicker}
                          className="datepicker"
                          margin="normal"
                          format="D/MM/yyyy"
                          value={issueDate}
                          onChange={(date) => setIssueDate(date)}
                          // onChange={(date) => {
                          //     setIssuedDate(date)
                          // }}
                          placeholder="DD/MM/YYYY"
                          // KeyboardButtonProps={{
                          //     'aria-label': 'change date',
                          // }}
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                    {issuerTypeID === 55 && (
                      <Col xs={12} md={6} className="issuerTypeForm">
                        <p className="mt-5 mb-2">
                          <Translate value="labourInspectorName" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            name="inspectorName"
                            // onChange={handleChange}
                            value={inspectorName}
                            disabled
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="circleoftheLabourInspector" />{" "}
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            name="inspectorCircle"
                            // onChange={handleChange}
                            value={circleName}
                            disabled
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="mobileNumberoftheLabourInspector" />{" "}
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            name="inspectorPhone"
                            // onChange={handleChange}
                            // InputProps={{
                            //   startAdornment: (
                            //     <InputAdornment position="start">
                            //       <img
                            //         src={mobile}
                            //         alt="..."
                            //         className="phone-icon"
                            //       />
                            //       <p className="countrycode">+91</p>
                            //     </InputAdornment>
                            //   ),
                            // }}
                            value={labourInspectormobileNum}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    )}
                    {issuerTypeID === 56 && (
                      <Col xs={12} md={6} className="issuerTypeForm">
                        <p className="mt-5 mb-2">
                          <Translate value="unionName" />
                        </p>

                        <FormControl fullWidth>
                          <Select2
                            options={union}
                            className="select-marital"
                            onChange={handleDropdownSelect}
                            isSearchable={true}
                            value={selectedOption && selectedOption}
                            placeholder="Search Union"
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="unionRegistrationNumber" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            name="unionRegistrationNumber"
                            // onChange={handleChange}
                            value={unionRegistrationNumber}
                            disabled
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="nameofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            // name="unionIssuerName"
                            onChange={(ev) =>
                              setUnionIssuerName(ev.target.value)
                            }
                            value={unionIssuerName}
                            // disabled
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="mobileNumberIfAvailable" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            // name="unionPhone"
                            onChange={(ev) =>
                              setUnionMobileNumber(ev.target.value)
                            }
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    // src={mobile}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            value={unionMobileNumber}
                          />
                        </FormControl>
                      </Col>
                    )}
                    {issuerTypeID === 57 && (
                      <Col xs={12} md={6} className="issuerTypeForm">
                        <p className="mt-4 mb-2">
                          <Translate value="nameofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder="Enter Issuer Name"
                            name="issuerName"
                            onChange={(ev) =>
                              setGramIssuerName(ev.target.value)
                            }
                            value={gramIssuerName}
                            // helperText={
                            //   allValues.issuerNameError
                            //     ? 'Please enter in correct format'
                            //     : ''
                            // }
                            // error={allValues.issuerNameError}
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="placeofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder="Enter Place of Issuer"
                            name="issuerPlace"
                            onChange={(ev) =>
                              setGramIssuerPlace(ev.target.value)
                            }
                            value={gramIssuerPlace}
                            // helperText={
                            //   allValues.issuerPlaceError
                            //     ? 'Please enter in correct format'
                            //     : ''
                            // }
                            // error={allValues.issuerPlaceError}
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="mobileNumberIfAvailable" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobile}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter Mobile Number of Issuer"
                            name="issuerPhone"
                            onChange={(ev) =>
                              setGramIssuerMobileNumber(ev.target.value)
                            }
                            value={gramIssuerMobileNumber}
                            // helperText={
                            //   allValues.issuerPhoneError
                            //     ? 'Please enter in correct format'
                            //     : ''
                            // }
                            // error={allValues.issuerPhoneError}
                          />
                        </FormControl>
                      </Col>
                    )}
                    {issuerTypeID === 58 && (
                      <Col xs={12} md={6} className="issuerTypeForm">
                        <p className="mt-4 mb-2">
                          <Translate value="nameofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder="Enter Name of Issuer"
                            name="issuerName"
                            // type = "text"
                            onChange={(ev) =>
                              setConstructionIssuerName(ev.target.value)
                            }
                            value={constructionIssuerName}
                            // helperText={allValues.issuerNameError ? "Please enter in correct format" : ""}
                            // error = {allValues.issuerNameError}
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="designationofIssuer" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder="Enter Designation of Issuer"
                            type="text"
                            onChange={(ev) =>
                              setConstructionDesignation(ev.target.value)
                            }
                            value={constructionDesignation}
                            // helperText={allValues.issuerDesignationError ? "Please enter in correct format" : ""}
                            // error = {allValues.issuerDesignationError}
                          />
                        </FormControl>

                        <p className="mt-4 mb-2">
                          <Translate value="mobileNumberIfAvailable" />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <img
                                    src={mobile}
                                    alt="..."
                                    className="phone-icon"
                                  />
                                  <p className="countrycode">+91</p>
                                </InputAdornment>
                              ),
                            }}
                            placeholder="Enter Mobile Number of Issuer"
                            name="issuerPhone"
                            onChange={(ev) =>
                              setConstructionMobileNumber(ev.target.value)
                            }
                            value={constructionMobileNumber}
                            // helperText={allValues.issuerPhoneError ? "Please enter in correct format" : ""}
                            // error = {allValues.issuerPhoneError}
                          />
                        </FormControl>
                      </Col>
                    )}
                  </Row>
                </Col>
                <Col xs={12} className="nintydays-col-6">
                  {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
                  <Required
                    className="mt-3 mb-2"
                    title={
                      <Translate value="uploadYourDaysWorkingCertificate" />
                    }
                  />

                  <div className="browsebutton-outerdiv">
                    <div className="w-100">
                      {nintyDaysDoc &&
                        nintyDaysDoc.length > 0 &&
                        nintyDaysDoc.map((file, index) => (
                          <FormControl fullWidth className="formcontrol3 w-100">
                            <TextField
                              className="w-100"
                              variant="outlined"
                              placeholder="Click Browse to Upload"
                              // name="mail_id"
                              disabled
                              value={file.name}
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {
                                      <IconButton
                                        onClick={() =>
                                          handleRemoveNintyDaysFile(index)
                                        }
                                      >
                                        <CancelIcon />
                                      </IconButton>
                                    }
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))}
                        {console.log({nintyDaysDocID})}
                      {nintyDaysDocID && nintyDaysDocID.length > 0 && (
                        <FormControl fullWidth className="formcontrol3 w-100">
                          <TextField
                            className="w-100"
                            variant="outlined"
                            placeholder="Click Browse to Upload"
                            // name="mail_id"
                            disabled
                            value={nintyDaysDocID[0].file_name}
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  {
                                    <IconButton
                                    onClick = {() => {                                      
                                      setNintyDaysDocID(null)
                                    }}
                                    >
                                      <CancelIcon />
                                    </IconButton>
                                  }
                                </InputAdornment>
                              ),
                            }}
                          />
                        </FormControl>
                      )}
                      {nintyDaysDoc && nintyDaysDoc.length === 0 && (
                        <FormControl fullWidth className="formcontrol3 w-100">
                          <TextField
                            className="w-100"
                            variant="outlined"
                            placeholder="Click Browse to Upload"
                            disabled
                          />
                        </FormControl>
                      )}
                    </div>
                    <div className="browsebutton2-div">
                      <div className="">
                        <input
                          type="file"
                          ref={nintyDaysDocRef}
                          onChange={handleNintyDaysFile}
                          multiple
                          hidden
                        />
                        <Button
                          type="button"
                          variant="contained"
                          color="primary"
                          className="py-2 mx-2"
                          startIcon={<FolderIcon />}
                          onClick={() => nintyDaysDocRef.current.click()}
                        >
                          Browse
                        </Button>
                      </div>
                    </div>
                  </div>

                  <p className="mt-4 instructions-ration">
                    <span>
                      <Translate value="instructions" />* :{" "}
                    </span>
                    <br />
                    <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" />
                    <br />
                    <Translate value="sizecontains500KBto2MB" />
                  </p>
                </Col>
              </Row>
            </>
          )}

          <Row className="button-inside-form-row mb-5">
            <Col xs={12} className="next-back-button-row mt-4 ">
              <Button
                variant="contained"
                color="error"
                style={{ width: "15%" }}
                className="py-3 mx-1"
                onClick={handleBack}
              >
                Back
              </Button>
              <Button
                variant="contained"
                style={{ width: "15%", whiteSpace: "nowrap" }}
                color="success"
                className="py-3 mx-1"
                type="button"
                onClick={handleSubmit}
              >
                Save your details
              </Button>
            </Col>
          </Row>
        </Row>
      </Row>
    </>
  );
};

export default NintyDaysDetails;
