// notifications
export const ERROR_GLOBAL = 'error_global';
export const SUCCESS_GLOBAL = 'success_global';
export const CLEAR_NOTIFICATION = 'clear_notification';


// user
export const GET_DEPT_USERS = 'getDeptUsers';
export const ERROR_GET_DEPT_USERS = 'errorgetDeptUsers';
export const INSERT_DEPT_USER = 'inserrtDeptUser';
export const ERROR_INSERT_DEPT_USER = 'errorinserrtDeptUser'; 
export const GET_DEPT_USER_ROLE = 'getDeptUserRole';
export const ERROR_GET_DEPT_USER_ROLE = 'errorgetDeptUserRole';
export const GET_SCHEMES_ON_STATUS = 'getSchemesOnStatus';
export const ERROR_GET_SCHEMES_ON_STATUS = 'errorgetSchemesOnStatus';
export const GET_SCHEMES = 'get_schemes';
export const ERROR_GET_SCHEMES = 'errorget_schemes';
export const SEARCH_USER = 'searchuser';
export const ERROR_SEARCH_USER = 'errorsearchuser';
export const GET_STATE_MAPPING = 'getStateMapping';
export const ERROR_GET_STATE_MAPPING = 'errorgetStateMapping';
export const GET_CONFIG_BOARD_DETAILS = 'getConfigBoardDetails';
export const ERROR_GET_CONFIG_BOARD_DETAILS = 'errorgetConfigBoardDetails';
export const GET_CONFIG_MINISTRY_DETAILS = 'getConfigMinistryDetails';
export const ERROR_GET_CONFIG_MINISTRY_DETAILS = 'errorgetConfigMinistryDetails';
export const SAVE_ACK_ID = 'saveAcknowledgementId';
export const ERROR_SAVE_ACK_ID = 'errorsaveAcknowledgementId';
export const FINAL_SUBMIT = 'finalSubmit';
export const ERROR_FINAL_SUBMIT = 'errorfinalSubmit';
export const GET_LABOUR_STATUS = 'getLabourStatusDetails';
export const ERROR_GET_LABOUR_STATUS = 'errorgetLabourStatusDetails';
export const GET_FILE = 'GET_FILE';
export const UPDATE_GET_FILE = 'UPDATE_GET_FILE';
export const ERROR_GET_FILE = 'ERROR_GET_FILE';
export const GET_RENEWAL_LABOUR_DETAILS = 'getLabourRenewalDetails';
export const ERROR_GET_RENEWAL_LABOUR_DETAILS='errorgetLabourRenewalDetails';
export const GET_USER_REGISTRATION_DETAILS = 'getUserRegistrationDetails';
export const ERROR_GET_USER_REGISTRATION_DETAILS = 'error_errorgetUserRegistrationDetails';
export const GET_UNION = 'getUnionDetails';
export const ERROR_GET_UNION = 'error_getUnionDetails';
export const GET_INSPECTOR = 'get_inspector';
export const ERROR_GET_INSPECTOR = 'error_get_inspector';
export const UPDATE_90DAYS = 'update90DaysData';
export const UPDATE_BANK = 'updateBankData';
export const ADD_LABOUR_90DAYS = 'addLabour90DaysCertDetails';
export const ERROR_ADD_LABOUR_90DAYS = 'error_addLabour90DaysCertDetails';
export const ADD_LABOUR_BANK = 'addLabourBankDetails';
export const ERROR_ADD_LABOUR_BANK = 'error_addLabourBankDetails';
export const ADD_LABOUR_FAMILY = 'addLabourFamilyDetails';
export const ERROR_ADD_LABOUR_FAMILY = 'error_addLabourFamilyDetails';
export const UPDATE_FAMILY = 'updateFamilyData';
export const ADD_LABOUR_ADDRESS = 'addLabourAddressDetails';
export const ERROR_ADD_LABOUR_ADDRESS = 'error_addLabourAddressDetails';
export const VILLAGE_DETAILS2 = 'VILLAGE_DETAILS2';
export const ERROR_VILLAGE_DETAILS2 = 'error_VILLAGE_DETAILS2';
export const CITY_DETAILS2 = 'CITY_DETAILS2';
export const ERROR_CITY_DETAILS2 = 'error_CITY_DETAILS2';
export const TALUK_DETAILS2 = 'taluk_details2';
export const ERROR_TALUK_DETAILS2 = 'error_taluk_details2';
export const DISTRICT_DETAILS2 = 'district_details2';
export const ERROR_DISTRICT_DETAILS2 = 'error_district_details2';

export const GET_APPLICATION_NO = 'getApplicationNo';
export const ERROR_GET_APPLICATION_NO = 'error_getApplicationNo';
export const VILLAGE_DETAILS = 'VILLAGE_DETAILS';
export const ERROR_VILLAGE_DETAILS = 'error_VILLAGE_DETAILS';
export const CITY_DETAILS = 'CITY_DETAILS';
export const ERROR_CITY_DETAILS = 'error_CITY_DETAILS';
export const TALUK_DETAILS = 'taluk_details';
export const ERROR_TALUK_DETAILS = 'error_taluk_details';
export const DISTRICT_DETAILS = 'district_details';
export const ERROR_DISTRICT_DETAILS = 'error_district_details';
export const UPDATE_ADDRESS = 'update_address';
export const ADD_LABOUR_PERSONAL = 'addLabourPersonalDetails';
export const ERROR_ADD_LABOUR_PERSONAL = 'error_addLabourPersonalDetails';
export const UPDATE_UPLOAD_FILE = 'update_upload_file';
export const UPLOAD_FILE = 'upload_file';
export const ERROR_UPLOAD_FILE = 'error_upload_file';
export const STATE_DETAILS = 'state_details';
export const ERROR_STATE_DETAILS = 'error_state_details';
export const CATALOG_DETAILS = 'catalog_details';
export const ERROR_CATALOG_DETAILS = 'error_catalog_details';
export const UPDATE_Review = 'update_review';
export const UPDATE_PERSONAL = 'update_personal';
export const UPDATE_ADMIN_DASHBOARD = 'update_admin_dashboard';
export const UPDATE_PROFILE = 'update_profile';
export const REGISTER_USER = 'register_user';
export const ERROR_REGISTER = 'error_register';
export const GENERATE_OTP_REGISTRATION = 'generate_otp_registration';
export const ERROR_GENERATE_OTP_REGISTRATION = 'error_generate_otp_registration';
export const GET_BOARD = 'get_board';
export const GET_MINISTRY = 'get_ministry';
export const ERROR_GENERATE_OTP = 'error_generate_otp';
export const ERROR_LOGIN_ADMIN = 'error_loginAdmin';
export const LOGIN_ADMIN = 'loginAdmin';
export const ERROR_LOGIN = 'error_login';
export const LOGIN_USER = 'login_user';
export const GENERATE_OTP = 'generate_otp';
export const AUTH_USER = 'auth_user';
export const SIGN_OUT = 'sign_out';
export const UPDATE_USER_PROFILE = 'update_user_profile';
export const USER_CHANGE_EMAIL = 'user_change_email';
export const USER = 'user';
export const UPDATE_USER = 'update_user';
export const ERROR_GET_USER = 'error_get_user';