import 'date-fns';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Switch, styled, FormControlLabel } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import { useDispatch, useSelector } from 'react-redux';
import { SERVER } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import { getTokenCookie, showToast } from 'utils/tools';
import axios from 'axios';
import { pink, green } from '@material-ui/core/colors';
import { alpha } from '@mui/system';
import Edit from 'assets/images/Icon material-edit.svg'


const CatalogValue = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,

        props: "",

        catalogName: "",
        short_name: "",
        code: "",
        description: "",
        address: "",
        phone_no: "",
        alternate_phone_no: "",
        fax: "",
        website: "",

        ministry_id_selected: null,
        board_id_selected: null,

        isEdit: false,
        rowdata: "",
        catalog_values: "",
    })


    useEffect(() => {
        console.log("allValues: ", allValues)
    }, [allValues]);

    useEffect(() => {
        console.log("props: ", props)
        if (
            props.location.rowdata !== undefined &&
            props.location.rowdata !== null) {
            setAllValues({
                ...allValues,
                props: props
            })
        }
        else{
            props.history.push("/admin/catalog-master")
        }
    }, []);

    useEffect(() => {
        console.log("allValues.props: ", allValues.props)
        if (
            allValues.props !== "" &&
            allValues.props.location !== undefined &&
            allValues.props.location.rowdata !== undefined &&
            allValues.props.location.rowdata !== null) {

            get_catalog_value()

            // setAllValues({
            //     ...allValues,
            //     rowdata: props.location.rowdata,
            //     catalog_values: catalog_values
            // })
        }
        
    }, [allValues.props]);
    

    const get_catalog_value = (event) => {
        var catalog_values = ""

            var config = {
                method: 'get',
                url: SERVER + '/config/catalog/get_catalog_value?master_id=' + allValues.props.location.rowdata.id,
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data: ''
            };

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));
                    catalog_values = response.data.data
                    setAllValues({
                        ...allValues,
                        rowdata: allValues.props.location.rowdata,
                        catalog_values: catalog_values
                    })
                })
                .catch(function (error) {
                    console.log(error);
                    setAllValues({
                        ...allValues,
                        rowdata: allValues.props.location.rowdata,
                    })
                });
    }

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })
    }

    const handleChangeActive = (event,row) => {
        console.log(event.target)
        console.log(event.target.name)
        console.log(event.target.value)
        console.log(event.target.checked)
        console.log(row)
        setAllValues({
            ...allValues,
            selected_id: row.id,
            selected_active: !row.isactive,
        })
        handleClickOpenSave()
    }

    const resetButtonPressed = () => {
        handleClickOpen()
    }

    const saveButtonPressed = () => {
        handleClickOpenSave()
    }

    const [open, setOpen] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleYes = () => {
        setAllValues({
            ...allValues,
            boardName: "",
            short_name: "",
            code: "",
            description: "",
            address: "",
            phone_no: "",
            alternate_phone_no: "",
            fax: "",
            website: "",
        })
        handleClose()
    };

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };

    const handleCloseSave = () => {
        setOpenSave(false);
    };

    const handleYesSave = () => {
        
        var data = JSON.stringify({
        "id": allValues.selected_id,
        "isactive": allValues.selected_active ? 1 : 0,
        "user_id": users.login_admin.data.department_user_id
        });

        var config = {
        method: 'post',
        url: SERVER+'/config/catalog/activate_catalog_value',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenCookie()}`
        },
        data : data
        };

        axios(config)
        .then(function (response) {
            console.log(JSON.stringify(response.data));
            showToast("SUCCESS", "Catalog Value Edited Successfully!")
            get_catalog_value()
        })
        .catch(function (error) {
            console.log(error);
            showToast("ERROR", error.response)
        });


        handleCloseSave()
    };

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: '#11A246',
          '&:hover': {
            backgroundColor: alpha('#11A246', theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: '#11A246',
        },
        '& .MuiSwitch-switchBase': {
            color: '#E80F0F',
            '&:hover': {
              backgroundColor: alpha('#E80F0F', theme.palette.action.hoverOpacity),
            },
        },
        '& .MuiSwitch-switchBase + .MuiSwitch-track': {
        backgroundColor: '#E80F0F',
        },
        '& .MuiSwitch-switchBase.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.5
        },
      }));

    return (
        <>
            <div className="root">

                <Dialog
                    // fullScreen={fullScreen}
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Reset the form?"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            This will clear all the data.
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="outlined" color="secondary">
                            Cancel
                        </Button>
                        <Button onClick={handleYes} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                <Dialog
                    // fullScreen={fullScreen}
                    open={openSave}
                    // onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >
                    <DialogTitle id="responsive-dialog-title">{"Save Changes"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Are you sure you want to save the changes made?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseSave} variant="outlined" color="secondary">
                            No
                        </Button>
                        <Button onClick={handleYesSave} color="primary">
                            Yes
                        </Button>
                    </DialogActions>
                </Dialog>

                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                    Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)<br />
                                    Government of Karnataka
                                </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Link to ={{ 
                                    pathname: "/admin/catalog-value-form", 
                                    rowdata: allValues.rowdata,
                                    }}
                                className="td-link ml-2"
                            >
                                <div className="add-div">
                                <img alt="..." className="cal-icon" src={add}/>
                                <p>Add {allValues.rowdata.name}</p>
                                </div>
                            </Link>
                            
                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title catalog-page">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                    </Link>
                    <p className="title m-0 p-0 ">Catalog Master {'>'} {allValues.rowdata.name}</p>
                </Col>
            </Row>

            <Row className="catalog-value-row">
                {
                    allValues.catalog_values !== "" && 
                    allValues.catalog_values.length > 0 ? 
                    allValues.catalog_values.map((row) => (
                        <>
                        <Col xs={12} md={4} className="catalog-value-col">
                            <div className="catalog-value-col-div">

                            <Row className="flex-row-reverse m-0">
                                <Link to ={{ 
                                        pathname: "/admin/catalog-value-form", 
                                        rowdata: allValues.rowdata,
                                        valRowData: row,
                                        isEdit: true
                                        }}
                                    className="td-link ml-2"
                                >
                                    <img alt="..." src={Edit} className="ml-2"/> Edit
                                </Link>  
                            </Row>
                            <Row className="m-0">
                                <div className="valueDiv">
                                    <p className="m-0 p-0">Name</p>    
                                    <p className="m-0 p-0">:</p>    
                                    <p className="m-0 p-0">{row.name}</p>    
                                </div>
                                <div className="valueDiv">
                                    <p className="m-0 p-0">Value</p>    
                                    <p className="m-0 p-0">:</p>    
                                    <p className="m-0 p-0">{row.value}</p>    
                                </div>
                                <div className="valueDiv">
                                    <p className="m-0 p-0">Status</p>    
                                    <p className="m-0 p-0">:</p>    
                                    <p className="m-0 p-0 d-flex">
                                        {
                                        row.isactive ? 
                                        <p className="m-0 mt-1 p-0 active-status">Active</p> :
                                        <p className="m-0 mt-1 p-0 inactive-status">InActive</p>
                                        }
                                        <GreenSwitch 
                                        defaultChecked={row.isactive} 
                                        color={row.isactive ? "primary":"default"}
                                        name={row.isactive}
                                        onChange={(e)=>handleChangeActive(e,row)}
                                        // disabled
                                        />
                                    </p> 
                                </div>  
                            </Row>
                        
                            </div>
                        </Col>
                        </>
                    ))
                    :
                    null
                }
            </Row>
            </>
    )
}

export default CatalogValue;