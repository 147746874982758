import {
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import React from 'react';
import { Button, Col } from 'react-bootstrap';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { Required } from 'utils/tools';

import VerifiedIcon from '@mui/icons-material/Verified';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
const AccidentAssitance = ({
  handleChange,
  handleDateChange,
  dynamicFields,
  formData,
  familyDetails,
  loading,
  kannadaSelected,
  handleFetchIFSC,
  IFSCCode,
  bankVerified,
  handleResetIFSC,
  aadharVerified,
  aadhaarLoading,
  handleVerifyAadhaar,
  death,
  isactive,
}) => {
  return (
    <>
      {((formData && formData['Accident Resulted In'] === 27) ||
        isactive === 2 ||
        death === 'true') && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            className={`${
              aadharVerified ? 'btn bg-success btn-lg' : 'btn bg-primary btn-lg'
            }`}
            onClick={handleVerifyAadhaar}
            disabled={aadharVerified ? true : false}
          >
            {aadharVerified ? <VerifiedIcon /> : <DoubleArrowIcon />}
            <span className="px-2 fs-5 font-weight-bolder">
              {aadharVerified
                ? 'Aadhaar Verifed'
                : 'Click here to verify the Nominee Aadhaar'}
            </span>
          </Button>
        </div>
      )}
      {loading ? (
        <div className="d-flex justify-content-center align-items-center flex-column">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3">
            Please wait fetching fields....
          </p>
        </div>
      ) : (formData && formData['Accident Resulted In'] === 27) ||
        isactive === 2 ||
        death === 'true' ? (
        dynamicFields &&
        dynamicFields.length &&
        dynamicFields.map(
          (field, index) =>
            field.field_name !== 'Percentage of Disability' && (
              <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={index}>
                <Required
                  className="mb-2 mt-4"
                  title={
                    kannadaSelected
                      ? field.field_name_kannada
                      : field.field_name
                  }
                />
                {field.field_type === 'Text' &&
                  (field.field_name === 'Applicant  Bank IFSC Code' ? (
                    <>
                      <FormControl fullWidth className="formcontrol1">
                        <TextField
                          type={'text'}
                          name={field.field_name}
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          value={IFSCCode}
                          variant="outlined"
                          InputProps={
                            field.field_name ===
                              'Applicant  Bank IFSC Code' && {
                              endAdornment: (
                                <InputAdornment position="end">
                                  {bankVerified ? (
                                    <>
                                      <IconButton>
                                        <VerifiedIcon color="success" />
                                      </IconButton>
                                      <IconButton onClick={handleResetIFSC}>
                                        <RestartAltIcon color="error" />
                                      </IconButton>
                                    </>
                                  ) : (
                                    <Button
                                      disabled={aadharVerified ? false : true}
                                      variant="outline-primary"
                                      className="m-0"
                                      onClick={handleFetchIFSC}
                                    >
                                      Fetch Bank Details
                                    </Button>
                                  )}
                                </InputAdornment>
                              ),
                            }
                          }
                          // helperText={allValues['error_' + field.id]}
                          // error={allValues.globalError}
                          // disabled={
                          //   field.field_name.includes(
                          //     'Name of the dependent ',
                          //   ) && allValues.medicalAssistanceSelf
                          // }
                        />
                      </FormControl>
                    </>
                  ) : (
                    <>
                      <FormControl fullWidth className="formcontrol1">
                        <TextField
                          type={'text'}
                          name={field.field_name}
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          value={formData && formData[field.field_name]}
                          variant="outlined"
                          // helperText={allValues['error_' + field.id]}
                          // error={allValues.globalError}
                          disabled={
                            !aadharVerified ||
                            field.field_name === 'Name of the Applicant' ||
                            field.field_name ===
                              'Relationship with Beneficiary' ||
                            field.field_name ===
                              'Age at the time of accident' ||
                            field.field_name === 'Age of Applicant' ||
                            field.field_name === 'Applicant Bank Name' ||
                            field.field_name === 'Applicant Bank Branch'
                              ? true
                              : false
                          }
                        />
                      </FormControl>
                    </>
                  ))}
                {field.field_type === 'Date' && (
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment('2023-10-17', 'YYYY-MM-DD').subtract(
                        1,
                        'years',
                      )}
                      maxDate={new Date()}
                      // onClick={(e) => onChangeDate(e, field)}
                      // onClose={() => setPickerStatus(false)}
                      // open={allValues.openDatePicker}
                      className="datepicker"
                      margin="normal"
                      format="DD/MM/YYYY"
                      name={field.field_name}
                      // id={id}
                      value={
                        formData && formData[field.field_name]
                          ? formData[field.field_name]
                          : null
                      }
                      onChange={(ev) =>
                        handleChange(ev, field, field.field_name)
                      }
                      disabled={aadharVerified ? false : true}
                      // onChange={setDate}
                      placeholder="DD/MM/YYYY"
                      // error={allValues.globalError}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                )}

                {(field.field_type === 'Dropdown' ||
                  field.field_type === 'Radio Button') && (
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    {field.field_name === 'Applicant Type' ? (
                      <Select
                        className="select-marital"
                        labelId="demo-simple-select-required-label"
                        id="demo-simple-select-required"
                        name={field.field_name}
                        onChange={(ev) =>
                          handleChange(ev, field, field.field_name)
                        }
                        value={149}
                        disabled
                      >
                        <MenuItem value={149} key={index}>
                          <ListItemText primary={'Nominee'} />
                        </MenuItem>
                      </Select>
                    ) : (
                      <>
                        {(aadharVerified && death === 'true') ||
                        isactive === 2 ? (
                          <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            name={field.field_name}
                            onChange={(ev) =>
                              handleChange(ev, field, field.field_name)
                            }
                            value={27}
                            disabled
                          >
                            <MenuItem value={27} key={index}>
                              <ListItemText primary={'Death'} />
                            </MenuItem>
                          </Select>
                        ) : (
                          <Select
                            className="select-marital"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            name={field.field_name}
                            onChange={(ev) =>
                              handleChange(ev, field, field.field_name)
                            }
                            value={
                              (formData && formData[field.field_name]) || '0'
                            }
                          >
                            <MenuItem value="0">
                              <ListItemText primary={`Select`} />
                            </MenuItem>
                            {field.field_data.map((field, index) => (
                              <MenuItem
                                value={field.field_value_id}
                                key={index}
                              >
                                <ListItemText primary={field.field_value} />
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      </>
                    )}
                  </FormControl>
                )}
              </Col>
            ),
        )
      ) : (
        <>
          {dynamicFields &&
            dynamicFields.length &&
            dynamicFields.map(
              (field, index) =>
                (field.field_name === 'Accident Resulted In' ||
                  field.field_name ===
                    'Employer Name at the time of accident' ||
                  field.field_name ===
                    'Employer address at the time of accident' ||
                  field.field_name === 'Percentage of Disability' ||
                  field.field_name === 'Date of Accident' ||
                  field.field_name === 'Age at the time of accident') && (
                  <Col xs={12} md={4} style={{ alignSelf: 'end' }} key={index}>
                    <Required
                      className="mb-2 mt-4"
                      title={
                        kannadaSelected
                          ? field.field_name_kannada
                          : field.field_name
                      }
                    />
                    {field.field_type === 'Text' &&
                      (field.field_name === 'Applicant  Bank IFSC Code' ? (
                        <>
                          <FormControl fullWidth className="formcontrol1">
                            <TextField
                              type={'text'}
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={IFSCCode}
                              variant="outlined"
                              InputProps={
                                field.field_name ===
                                  'Applicant  Bank IFSC Code' && {
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      {bankVerified ? (
                                        <>
                                          <IconButton>
                                            <VerifiedIcon color="success" />
                                          </IconButton>
                                          <IconButton onClick={handleResetIFSC}>
                                            <RestartAltIcon color="error" />
                                          </IconButton>
                                        </>
                                      ) : (
                                        <Button
                                          variant="outline-primary"
                                          className="m-0"
                                          onClick={handleFetchIFSC}
                                        >
                                          Fetch Bank Details
                                        </Button>
                                      )}
                                    </InputAdornment>
                                  ),
                                }
                              }
                              // helperText={allValues['error_' + field.id]}
                              // error={allValues.globalError}
                              // disabled={
                              //   field.field_name.includes(
                              //     'Name of the dependent ',
                              //   ) && allValues.medicalAssistanceSelf
                              // }
                            />
                          </FormControl>
                        </>
                      ) : (
                        <>
                          <FormControl fullWidth className="formcontrol1">
                            <TextField
                              type={
                                field.field_name === 'Percentage of Disability'
                                  ? 'number'
                                  : 'text'
                              }
                              name={field.field_name}
                              onChange={(ev) =>
                                handleChange(ev, field, field.field_name)
                              }
                              value={formData && formData[field.field_name]}
                              variant="outlined"
                              // helperText={allValues['error_' + field.id]}
                              // error={allValues.globalError}
                              disabled={
                                field.field_name === 'Applicant Bank Name' ||
                                field.field_name === 'Applicant Bank Branch' ||
                                field.field_name ===
                                  'Age at the time of accident'
                                  ? true
                                  : false
                              }
                            />
                          </FormControl>
                        </>
                      ))}
                    {field.field_type === 'Date' && (
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          minDate={moment('2023-10-17', 'YYYY-MM-DD').subtract(
                            1,
                            'years',
                          )}
                          maxDate={new Date()}
                          // onClick={(e) => onChangeDate(e, field)}
                          // onClose={() => setPickerStatus(false)}
                          // open={allValues.openDatePicker}
                          className="datepicker"
                          margin="normal"
                          format="DD/MM/YYYY"
                          name={field.field_name}
                          // id={id}
                          value={
                            formData && formData[field.field_name]
                              ? formData[field.field_name]
                              : null
                          }
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          // onChange={setDate}
                          placeholder="DD/MM/YYYY"
                          // error={allValues.globalError}
                          KeyboardButtonProps={{
                            'aria-label': 'change date',
                          }}
                        />
                      </MuiPickersUtilsProvider>
                    )}

                    {(field.field_type === 'Dropdown' ||
                      field.field_type === 'Radio Button') && (
                      <FormControl
                        variant="outlined"
                        fullWidth
                        className="formcontrol5"
                      >
                        <Select
                          className="select-marital"
                          labelId="demo-simple-select-required-label"
                          id="demo-simple-select-required"
                          name={field.field_name}
                          onChange={(ev) =>
                            handleChange(ev, field, field.field_name)
                          }
                          value={
                            (formData && formData[field.field_name]) || '0'
                          }
                        >
                          <MenuItem value="0">
                            <ListItemText primary={`Select`} />
                          </MenuItem>

                          {field.field_data.map((field) => (
                            <MenuItem value={field.field_value_id}>
                              <ListItemText primary={field.field_value} />
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                  </Col>
                ),
            )}
        </>
      )}
    </>
  );
};

export default AccidentAssitance;
