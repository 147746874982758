import { FormControl, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
// import mobile from 'assets/images/Icon awesome-mobile-alt.svg'
import mobile from 'assets/images/Phone 1-01.svg'
// import otp from 'assets/images/business-and-finance.svg'
import otp from 'assets/images/Phone otp-01.svg'
import graphic from 'assets/images/undraw_QA_engineers_dg5p.svg'
import aadhar from 'assets/images/id-card (1).svg'
import ration from 'assets/images/ration-01.svg'
import gas from 'assets/images/book.svg'
import bank from 'assets/images/bank-building.svg'
import { generateOtpRegistration, getBoardDetails, getMinistryDetails, registerUser, updateUser, loginUser} from 'store/actions/user.actions';
import { useDispatch, useSelector } from 'react-redux';
import validator from 'validator';
import { showToast, saveTokenCookie,removeTokenCookie } from 'utils/tools';
import history from "../../history";
import { useLocation } from "react-router-dom";

const Registration = (props) => {
    const location = useLocation();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    
  
  
    const [allValues, setAllValues] = React.useState({
        open: false,
        phone: "",
        otp: '',
        users: props.users,
        generate_otp_registration: props.generate_otp_registration,
        login_user: props.login_user,
        otp_sent: false,
        otp_sending: false,
        submitting: false,
        phoneError: false,
        phoneErrorMessage: "Enter Correct Mobile Number!",
        otpError: false,
        otpErrorMessage: "Enter Valid OTP!",
        openSnackbar: false,
        snackbarText: '',
        snackbarType: '',
        vertical: 'top',
        horizontal: 'right'
    });
    const changeHandler = e => {
        console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

        if (e.target.name === "otp") {
            setAllValues({ ...allValues, otpError: false })
        }
        if (e.target.name === "phone") {
            console.error("validate:" + validator.isNumeric(e.target.value))
            console.error("phone.length:" + e.target.value.length)

            if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
                setAllValues({ ...allValues, phoneError: false, otp: '', otpError: false, otp_sent: false })
            else
                setAllValues({
                    ...allValues,
                    phoneError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number",
                    otp: '', otpError: false, otp_sent: false
                })
        }

        setAllValues(prevValues => {
            return { ...prevValues, [e.target.name]: e.target.value }
        })
    }

   


    useEffect(() => {
        console.log("in useEffect []")
        dispatch(getMinistryDetails())
        removeTokenCookie()
        dispatch(updateUser(""))
        dispatch(loginUser("delete"))
        dispatch(registerUser("delete"))
       
    }, []);

    useEffect(() => {
        console.log("in useEffect [users.get_ministry]")
        // console.log("id=" + users.get_ministry.id)
        // const id = users.get_ministry.id
        if (users.get_ministry!== undefined && users.get_ministry !== null && users.get_ministry.id !== undefined)
            dispatch(getBoardDetails(users.get_ministry.id))
    }, [users.get_ministry]);


    const getOtp = (event) => {
        event.preventDefault();
        console.log("in getOtp-> phone:" + allValues.phone)
        console.log("in getOtp-> users:" + JSON.stringify(users, undefined,2))

        if (users !== undefined && users !== null &&!allValues.phoneError && allValues.phone.length === 10) {
            setAllValues(prevState => ({
                ...prevState,
                otp_sending: !prevState.otp_sending,
                generate_otp_registration: ''
            }));

            var boardId = 1
            // var boardId = null

            // users.get_board.map(
            //     obj => {
            //         // console.log("obj.code:"+JSON.stringify(obj.code))
            //         if (obj.code === "KBOCWWB") {
            //             boardId = obj.id
            //         }
            //     }
            // )
            // console.log("board_id=" + boardId)

            let dataToDispatch = {
                "mobile_code": "+91",
                "phone_number": allValues.phone,
                "board_id": boardId
            }

            console.log("dataToDispatch: " + JSON.stringify(dataToDispatch))

            dispatch(generateOtpRegistration(dataToDispatch))
        }
        else {
            setAllValues({ ...allValues, phoneError: true })
        }
    }


    useEffect(() => {
        // console.log("users.generate_otp_registration:"+JSON.stringify(users.generate_otp_registration));
        // console.log("users.generate_otp_registration.message:" + users.generate_otp_registration.message)
        if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null && users.generate_otp_registration.message === 'Success') {
            console.log("OTP generated Successfully");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: true,
            })
            showToast("SUCCESS", "OTP Sent Successfully!")
            users.generate_otp_registration.message = null
        } else if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null && users.generate_otp_registration.message !== undefined && users.generate_otp_registration.message !== '') {
            console.log("OTP generate Error");
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: users.generate_otp_registration.message,
            })
            showToast("ERROR", users.generate_otp_registration.message)
            users.generate_otp_registration.message = null
        }
        else if (users.generate_otp_registration !== undefined && users.generate_otp_registration !== null && users.generate_otp_registration.message !== null) {
            console.log("In else: error");
            console.log("users.generate_otp_registration:" + users.generate_otp_registration);
            setAllValues({
                ...allValues,
                otp_sending: false,
                otp_sent: false,
                phoneError: true,
                phoneErrorMessage: "Error Sending OTP",
            })
            showToast("ERROR", "Error Sending OTP")

            users.generate_otp_registration = null
        }

    }, [users.generate_otp_registration])


    const registration_func = (event) => {
        event.preventDefault();

        if (users.generate_otp_registration !== null) {
            console.log("users.register_user: " + JSON.stringify(users.register_user))
            console.log("in registration_func: hash: " + JSON.stringify(users.generate_otp_registration.data.hash))
            console.log("in registration_func1")

            if (!allValues.phoneError && allValues.phone.length === 10 && allValues.otp !== '') {
                setAllValues({
                    ...allValues,
                    submitting: !allValues.submitting
                })
                console.log("in registration_func2")

                var login_user_id = ""
                // if(
                //     users.login_admin !== undefined &&
                //     users.login_admin !== null &&
                //     users.login_admin.data !== undefined &&
                //     users.login_admin.data !== null &&
                //     users.login_admin.data.department_user_id !== undefined &&
                //     users.login_admin.data.department_user_id !== null
                //     ){
                //     login_user_id = users.login_admin.data.department_user_id

                    let dataToRegister = {
                        "hash": users.generate_otp_registration.data.hash,
                        "phone_number": users.generate_otp_registration.data.phone_number,
                        "mobile_code": users.generate_otp_registration.data.mobile_code,
                        "board_id": users.generate_otp_registration.data.board_id,
                        "otp": allValues.otp,
                        "login_user_id":  172
                    }
                    console.log("dataToRegister: " + JSON.stringify(dataToRegister))
    
                    dispatch(registerUser(dataToRegister))
                // }
                // else{
                //     setAllValues({
                //         ...allValues,
                //         submitting: false,
                //     })
                //     showToast("ERROR", "Cannot Register! No Admin Login Found.")
                // }

                
            }
            else if (allValues.phoneError || allValues.phone.length !== 10) {
                console.log("in registration_func3")

                setAllValues({
                    ...allValues,
                    phoneError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                })
            }
            else if (otp === '') {
                console.log("in registration_func4")

                setAllValues({
                    ...allValues,
                    submitting: false,
                    otpError: true,
                    otpErrorMessage: "Enter Valid OTP!"
                })
            }
            else if (users.generate_otp_registration.data.hash === undefined) {
                console.log("in registration_func5")

                setAllValues({
                    ...allValues,
                    submitting: false,
                    otpError: true,
                    otpErrorMessage: "Enter Valid OTP!"
                })
            }
            else {
                console.log("in registration_func6  ")

                setAllValues({
                    ...allValues,
                    submitting: false,
                    phoneError: true,
                    otpError: true,
                    phoneErrorMessage: "Enter Correct Mobile Number!",
                })
            }
        }
    }

    // useEffect(()=>{
    //     if(location.state.title){
    //         console.log(location.state.title);
    //     }
    // },[location])
    

    useEffect(() => {
        // console.log("users.login_user.message:"+users.login_user.message)
        if (users.register_user!== undefined && users.register_user !== null && users.register_user.data !== null && users.register_user.data !== undefined
            // && users.register_user.message === "Success"
        ) {

            console.log("in if : Valid OTP");
            setAllValues({
                ...allValues,
                submitting: false,
            })
            saveTokenCookie(users.register_user.jwt)
            showToast("SUCCESS", "Registered Successfully!")
            users.register_user.message = null

            // props.history.push("/");
          
                props.history.push({pathname:"/dashboard-migrant", state:"Migrant"});
           

            
            
            users.register_user.message = null

            
        }
        if (users.register_user!== undefined && users.register_user !== null && users.register_user.message !== undefined && users.register_user.message === 'Invalid OTP') {
            console.log("In elseif: Invalid OTP");
            setAllValues({
                ...allValues,
                submitting: false,
                otpError: true,
                otpErrorMessage: "Invalid OTP!",
            })
            showToast("WARN", "Invalid OTP!")

            users.register_user.message = null
        }
        else if (users.register_user!== undefined && users.register_user !== null && users.register_user.message !== null) {
            setAllValues({
                ...allValues,
                submitting: false,
            })
            showToast("ERROR", "Error while Registering!")

            users.register_user = null
        }

    }, [users.register_user])

    return (
        <Row className="login-div">
            <Col xs={12} lg={6}>
                <Row >
                    <Col sm={12} >
                        <div className="logo-div me-5">
                            <img id="logo" src={logo} alt="..." className="logo-img" />
                            <h1 className="logo-text">
                            ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ <br/> (ಕಕಇನಿಕಾಕಮಂ) <br />
                            Karnataka Building & Other Construction Workers Welfare Board <br/> (KBOCWWB)<br/>                                    </h1>
                        </div>
                    </Col>
                    <Col sm={12} className="registration-img-col" >
                        <img alt="..." className="registration-img" src={graphic} />


                    </Col>

                    <Col xs={12} lg={6}>
                    <div className="reg-form-container mob">
                        <h2 className="reg-form-head">Migrant Registration Form</h2>
                    </div>
                    </Col>

                    <Col sm={12} className="documents-col">
                        <p className="documents-title">For registration the following documents are required</p>


                        <div className="req-document-div-out">

                            <div className="req-document-div">
                                <img alt="..." className="adhar-card-img" src={aadhar} />
                                <div>Aadhar Card
                                <p>(for verfication purpose)</p>
                                </div>
                            </div>

                        </div>

                        <div className="req-document-div mt-4 mb-1">
                            <div className="req-document-div mr-2 ml-0">
                                <img alt="..." src={ration} />
                                <div>Ration Card
                                <p>(for verfication purpose)</p>
                                </div>
                            </div>
                        </div>
                        <div className="req-document-div my-0" style={{marginLeft: "100px", fontSize: "21px"}} >
                            or
                        </div>

                        <div className="req-document-div mt-1 mb-4">
                                <img alt="..." src={gas} />
                                <div>Gas Book
                            </div>
                            {/* </div> */}

                        </div>

                        <div className="req-document-div my-4">
                            <img alt="..." src={bank} />
                            <div style={{display: "block"}}>Bank Passbook or Bank Statement
                                <p className="ml-0">(for scheme purpose Required account number & IFSC code)</p>
                            </div>
                        </div>


                    </Col>

                    {/* <Col sm={12} className="copyright">
                        <p>Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB), Government of Karnataka</p>
                    </Col> */}
                </Row>
            </Col>

            <Col xs={12} lg={6} className="login-form">
                <div className="reg-form-container">
                <h2 className="reg-form-head">Migrant Registration Form</h2> 
                    {/* <p className="login-form-head-sub">with your Mobile Number & OTP</p> */}
                    <p className="reg-phno-label">Enter Your Mobile Number</p>

                    <div className="note">
                        <span className="note-span">Note* </span>
                             : Enter Mobile Number as per the Aadhar Registered
                    </div>

                    <form noValidate >
                        <FormControl fullWidth className="reg-phno-field">
                            <TextField
                                className="size21"
                                name="phone"
                                value={allValues.phone}
                                type="phone"
                                onChange={changeHandler}
                                error={allValues.phoneError}
                                helperText={allValues.phoneError ?
                                    allValues.phoneErrorMessage : null}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={mobile} alt="..." className="phone-icon" />
                                            <p className="countrycode">+91</p>
                                        </InputAdornment>,
                                }}
                                variant="outlined"
                            />
                        </FormControl>

                        {
                            allValues.otp_sending ?
                                <CircularProgress className="loader-otp" /> :
                                <Link to="#" onClick={(event) => getOtp(event)}>
                                    <p className="generate-otp">
                                        {
                                            allValues.otp_sent ?
                                                "Resend OTP" : "Generate OTP"
                                        }
                                    </p>
                                </Link>
                        }


                        <p className="reg-otp-label">
                            Enter Your OTP
                        </p>

                        <FormControl fullWidth className="reg-otp-form">
                            <TextField
                                className="size31"
                                name="otp"
                                value={allValues.otp}
                                type="password"
                                onChange={changeHandler}
                                error={allValues.otpError}
                                placeholder="Enter Your OTP"
                                helperText={allValues.otpError ?
                                    allValues.otpErrorMessage : null}
                                variant="outlined"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        registration_func(event)
                                    }
                                }}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img src={otp} alt="..." className="otp-icon" />
                                        </InputAdornment>,
                                }}
                            />
                        </FormControl>

                        {/* <Link to="/profile" >
                        <p className="login-button">
                            Submit
                        </p>
                        </Link> */}

                        {
                            allValues.submitting ?
                                <CircularProgress className="loader-otp" /> :


                                ///////////// **************** TO BE CORRECTED  ********************
                                <Link to="#" onClick={(event) => registration_func(event)}>
                                    {/* <Link to="/profile" > */}
                                    <p className="login-button" type="submit">
                                        Submit
                            </p>
                                </Link>
                        }

                    </form>

                </div>
                
            </Col>
        </Row>
    )

}

export default Registration