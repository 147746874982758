import React, { useEffect, useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { IconButton, Pagination, Stack } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Switch from '@mui/material/Switch';
import { activeInactiveUnion, deleteUnion } from './unionList.service';
import { useSelector } from 'react-redux';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Chip from '@mui/material/Chip';
import { showToast } from 'utils/tools';
import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
const UnionListTableComponent = ({
  count,
  page,
  handlePageChange,
  unionData,
}) => {
  const users = useSelector((state) => state.users);
  const [status, setStatus] = useState(null);
  const [rowData, setRowData] = useState(null);
  const [index, setIndex] = useState(null);
  const [labourUnionID, setLabourUnionID] = useState(null);
  const [from, setFrom] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, index, labourUnionID, status, from) => {
    setAnchorEl(event.currentTarget);
    setIndex(index);
    setLabourUnionID(labourUnionID);
    setStatus(status);
    setFrom(from);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  useEffect(() => {
    setRowData(unionData);
  }, [unionData]);

  const handleAlert = (from) => {
    if (from === 'switch') {
      handleSwitchToggle(index, labourUnionID);
    } else if (from === 'delete') {
      handleDeleteUnion(labourUnionID);
    }
  };

  const handleSwitchToggle = async (index, unionID) => {
    const updatedRowData = [...rowData];
    updatedRowData[index].isactive = !updatedRowData[index].isactive;

    const updatedUnionId = updatedRowData.filter(
      (_data) => _data.labour_union_id === unionID,
    );
    const payLoad = updatedUnionId.map((_data) => ({
      id: _data.labour_union_id,
      is_active: _data.isactive,
      added_by:
        users &&
        users.getDeptUserRole.data &&
        users.getDeptUserRole.data[0].department_user_id,
    }));
    // console.log(payLoad && payLoad[0]);

    try {
      const res = await activeInactiveUnion(payLoad && payLoad[0]);
      if (res.success) {
        setAnchorEl(null);
        setRowData(updatedRowData);
      }
    } catch (error) {
      setAnchorEl(null);
      showToast('ERROR', 'Something went wrong');
    }
  };

  const handleDeleteUnion = async (unionID) => {
    const updatedRowData = rowData.filter(
      (row) => row.labour_union_id !== unionID,
    );

    try {
      const payLoad = {
        unionId: unionID,
        editedBy:
          users &&
          users.getDeptUserRole.data &&
          users.getDeptUserRole.data[0].department_user_id,
      };
      const res = await deleteUnion(payLoad);
      if (res.success) {
        setAnchorEl(null);
        setRowData(updatedRowData);
      }
    } catch (error) {
      setAnchorEl(null);
      showToast('ERROR', 'Something went wrong');
    }
  };

  return (
    <>
      <div className="px-3">
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Typography sx={{ p: 2 }}>
            {from === 'switch' &&
              `Are you sure to ${
                status ? 'deactivate' : 'activate'
              } the union?`}
            {from === 'delete' &&
              `Are you sure to delete
               the union?`}

            <Stack
              direction="row"
              spacing={1}
              className="d-flex justify-content-center align-items-center p-1"
            >
              <Chip
                label="Yes"
                color="success"
                onClick={() => handleAlert(from)}
                // onDelete={handleDelete}
                variant="outlined"
              />
              <Chip
                label="No"
                color="error"
                onClick={handleClose}
                // onDelete={handleDelete}

                variant="outlined"
              />
            </Stack>
          </Typography>
        </Popover>
        <TableContainer component={Paper}>
          <Table width={100} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell style={{ color: 'black' }} align="center">
                  Union Name
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Registration Number
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Address
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Spoke Person
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Phone Number
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Options
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Status
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Action
                </TableCell>
                <TableCell style={{ color: 'black' }} align="center">
                  Delete
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData &&
                rowData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">{row.registeration_no}</TableCell>
                    <TableCell align="center">{row.address}</TableCell>
                    <TableCell align="center">{row.spoke_person}</TableCell>
                    <TableCell align="center">{row.phone_no}</TableCell>

                    <TableCell align="center">
                      <Link
                        to={`/admin/unionMapping/${row.labour_union_id}/${row.name}`}
                      >
                        <span style={{ color: 'black', cursor: 'pointer' }}>
                          Map
                        </span>
                      </Link>
                      <span>/</span>
                      <Link
                        to={`/admin/unionInsert/${row.labour_union_id}/edit`}
                      >
                        <span style={{ color: 'black', cursor: 'pointer' }}>
                          Edit
                        </span>
                      </Link>
                    </TableCell>
                    <TableCell align="center">
                      {row.isactive ? 'Active' : 'Inactive'}
                    </TableCell>
                    <TableCell align="center">
                      <Switch
                        onClick={(ev) =>
                          handleClick(
                            ev,
                            index,
                            row.labour_union_id,
                            row.isactive,
                            'switch',
                          )
                        }
                        checked={row.isactive}
                        // onChange={() =>
                        //   handleSwitchToggle(index, row.labour_union_id)
                        // }
                        size="small"
                        color="success"
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        onClick={(ev) =>
                          handleClick(
                            ev,
                            null,
                            row.labour_union_id,
                            null,
                            'delete',
                          )
                        }
                      >
                        <DeleteRoundedIcon
                          sx={{ fontSize: 24 }}
                          color="error"
                        />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <div className="d-flex justify-content-end px-3 py-3">
        <div>
          <Stack spacing={2} className="w-100">
            <Pagination
              count={count}
              size="medium"
              page={page}
              color="primary"
              variant="outlined"
              shape="circular"
              onChange={handlePageChange}
            />
          </Stack>
        </div>
      </div>
    </>
  );
};

export default UnionListTableComponent;
