import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const getCircleAPI = async () => {
  try {
    const { data } = await axios.get(
      `${SERVER}/config/circle/get_circle_details`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
