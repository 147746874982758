import 'date-fns';
import { Select, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import React from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import language from 'assets/images/translate (1).svg'
import { useDispatch, useSelector } from 'react-redux';
import { setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import arrow from 'assets/images/Polygon 1.svg'
import usericon from 'assets/images/Icon awesome-user.svg'
import medical from 'assets/images/medd-01.svg'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';


const SchemeDoc = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        searchValueError: false,
        submitting: false,
    })

    let { id } = useParams()
    console.log("param_in_schemeDoc: "+id)

    // var idName = id.split('_id=')[0]

    // useEffect(() => {
    //     if(users.user !== undefined && users.user !== null){
    //         dispatch(get_schemes(users.user.board_id))
    //     }
    // }, []);


    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            searchValueError: false,
            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }
    const applyButtonPressed = (event) => {
        props.history.push("/scheme-form/continuation-of-pension");
    }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div ">
                    <Row className="topbar-row">
                        <Col xs={12} md={4} lg={6} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction<br/>Workers Welfare Board (KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        <Col xs={4} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="schemes-title-row-red">
                <Col xs={12} md={9}>
                    <h1>Schemes</h1>
                </Col>
            </Row>

            <Row className="search-form schemedoc">
                <p className="mb-4 fieldtitle">Disability Pension</p>
                <p className="mt-4 textItem">
                    <img alt="..." src={arrow} className="arrowright1" />
                    The Board or authorized officer may Sanction an amount of Rs. 2000/- (Govt of Karnataka Notification NO 106 LET 2019,Bengaluru, Dated:14-11-2019) per month as disability pension to a beneficiary, who is partially disabled due to any disease or accident at worksite and for an ex¬gratia payment of not more than Rs. 2,00,000/- (Rupees two lakhs only) depending upon the percentage of disability.
                </p>
                <p className="mt-4 textItem">
                    <img alt="..." src={arrow} className="arrowright1" />
                    The beneficiary shall submit the ‘Living Certificate’ in Form XIV (A) to the sanctioning authority for each year.
                </p>
                <p className="mt-4 textItem">
                    <img alt="..." src={arrow} className="arrowright1" />
                    The Disability Pension granted shall be discontinued once the beneficiary attains the age of sixty years.
                </p>
                <p className="mt-4 textItem">
                    <img alt="..." src={arrow} className="arrowright1" />
                    In case of death of the Disability pensioner, either the bank or the legal dependant shall inform the Board or Officer authorized, about the death of the pensioner along with the death certificate in order to close the bank account.
                </p>


                <p className="mt-5 mb-4 fieldtitle">Documents Required</p>
                <Row>
                    <Col xs={6} className="mt-4 doc-required-col">
                        <div>
                            <p>Medical Certificate</p>
                            {/* <img alt="..." src={medical} className="language-img" /> */}
                            <div class="img-container">
                                <img alt="..." src={medical} className="img-img" />
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} className="mt-4 doc-required-col">
                        <p className="mt-4 ml-4 textItem">
                            {/* <img alt="..." src={arrow} className="arrowright1" /> */}
                            ID card issued by the department for the empowerment of differently abled and Senior citizens
                        </p>
                    </Col>
                    
                </Row>
                <Row>
                <Col xs={12} className="next-back-button-row mt-5">

                        <Link to="#" onClick={() => props.history.goBack()}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="danger" className="back-button" >
                                Back
                            </Button>
                        </Link>

                        <Link to="#" 
                        onClick={applyButtonPressed}
                        style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="outline-primary" className="save-button">
                                Apply
                            </Button>
                        </Link>
                    
                </Col>
                </Row>
            </Row>
        </>
    )
}

export default SchemeDoc;