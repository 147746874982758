import React, { useState } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import Header from '../../components/mainScreens/Header'
import doc1 from 'assets/images/doc1.svg'
import doc2 from 'assets/images/doc2.svg'
import doc3 from 'assets/images/doc3.svg'
import Button2 from '@material-ui/core/Button';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link } from 'react-router-dom'
import { ListItemIcon, ListItemText, MenuItem, Select } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { Translate } from 'react-redux-i18n';
import language from 'assets/images/translate (1).svg'
import { setLocaleWithFallback } from 'store/actions/user.actions'
const DetailedTransport = (props) => {
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const i18nlang = useSelector(state => state.i18n)
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })
    const handleChange1 = (e) => {
        console.log("kkkkk", e.target.value)
        if (e.target.value === "ka") {

            setAllValues({
                ...allValues,
                descriptionsKannada: true,
                [e.target.name]: e.target.value,

            })

        }
        else {
            setAllValues({
                ...allValues,
                descriptionsKannada: false,
                [e.target.name]: e.target.value,

            })
        }


        if (e.target.name === "language")
            dispatch(setLocaleWithFallback(e.target.value))
    }

    return (
        <div>
            <Header />
            <div className='d-flex justify-content-end'>
                <Col xs={12} md={4} lg={3} className="d-flex justify-content-end mt-2 me-2">
                    <Select
                        className="select-language"
                        style={{ width: '100%' }}
                        variant="outlined"
                        labelId="demo-simple-select-required-label"
                        value={allValues.language}
                        name="language"
                        displayEmpty
                        onChange={handleChange1}
                    >
                        <MenuItem value="" >
                            <ListItemIcon>
                                <img alt="..." src={language} className="language-img" />
                            </ListItemIcon>
                            <ListItemText primary="Select Language" />
                        </MenuItem>
                        {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                        <MenuItem value="en">
                            <ListItemText primary="English" />
                        </MenuItem>
                        <MenuItem value="ka">
                            <ListItemText primary="ಕನ್ನಡ" />
                        </MenuItem>
                    </Select>
                </Col>
            </div>
            <Row className='container-fluid'>
            <div className='schemes-home-div container-fluid'>
                <h3 className='schemesHome-title container-fluid'><Translate value='accidentandbenefitTitle'/></h3>
                <p className='schemes-instructions schems-docs-details container-fluid'>
                    <h5>Beneficiaries under the scheme: </h5>
                    <li>Drivers who are holding valid DL issued by Transport Department to drive private commercial transport vehicles.</li>
                    <li>Conductors who are holding valid conductor licence issued by Transport Department and registered under the Scheme.</li>
                    <li>Cleaners of private commercial transport vehicles.</li>
                    <h5>Benefits under the Scheme:-</h5>
                    <h5>The benefits available to the beneficiaries under the Scheme are as follows:</h5>
                    <h5>(a)Accident benefit:-</h5>The Private Commercial Transport Workers such as drivers,conductors and cleaners frequently meet with accidents.In case of death,permanent disablement or temporary disablement asaresult of
                    accident,the family who are depending upon their earnings will face the financial crises.
                    To help the family members of the drivers incase of accident the Karnataka State Government has framedaScheme called"The Karnataka State Private Commercial Vehicle Drivers Accident Benefit Scheme"during the year
                    2016-17 and the same has been revised as"The Karnataka State Private Commercial Transport Workers Accident Benefit Scheme",so as to extend the benefits to conductors and cleaners along with drivers.
                    <h5>Applicability of the Scheme:</h5>
                    <li>It extends to the whole of Karnataka.</li>
                    <li>This Scheme is applicable to private commercial vehicle drivers,cleaners&conductors.</li>
                    <li>Age limit is from 20 to 70 years.</li>
                    <li>Accident Benefit is available to both"On-duty and Off-duty"accidents.</li>
                    <h5>Quantum of Accident Benefits:</h5>
                    <li>Accident resulting in death of drivers,conductors and cleaners- an amount of Rs.5.00 lakh to the nominee.</li>
                    <li>Accident resulting in permanent disablement-upto Rs.2.00 lakh to the beneficiary depending upon the ratio of disablement.</li>
                    <li>Accident resulting in temporary disablement/reimbursement of hospital expenses.</li>
                    <li>For hospitalization upto 15 days, 25 %of sum insured or actual hospitalization charges whichever is lower.</li>
                    <li>For hospitalization more than 15 days, actual hospitalization expenses or 50% of sum insured whichever is lower.</li>
                    <h5>(b)Educational Assistance:</h5>
                    <li>From the year 2018-19,Educational Assistance of Rs.10,000/-per annum is being sanctioned to maximum 2 children of the driver who succumbs to death due to accident or suffered total permanent disablement.This
                        benefit will be given to study from l'standard to 12th/PUC.So far,789 beneficiary students have been sanctioned withatotal assistance Rs.78,90,000/-.</li>
                    <li>For this benefit,the children of deceased driver/the driver who suffered total permanent disablement have to submit the prescribed application duly filled in,along with the documents to the concerned officers of the
                        Labour Department and the same will be verified and the benefit will be transferred to the bank account of the beneficiary directly.
                    </li>
                    <h5>(c)Karmika Samman Day:</h5>
                    Karmika Sammana Day is being celebrated in all district head quarters on Ist of March every year and on this occasion,660 private commercial transport Drivers will be honoured with First,Second and Third Award and8
                    Special Awards per district per category,recognizing the best and safe driving,out of which one each for three wheeler and four wheeler separately.
                    First prize consistsagold medal withavalue of Rs.15,000/-,Second prize consistsasilver medal withavalue of Rs.10,000/-,Third prize consistsasilver medal withavalue of Rs.8,CCC/-and Special Award will be Rs.1,000/-in
                    cash/cheque and Appreciation Certificate.
                    <h5>(d)Life Saviour's(Good Samaritan)Scheme:</h5>
                    In road accident cases,India stands 6th place in the world.60 minutes after the occurrence of the accident isagolden period.In the said period,if suitable treatment is given,every year lakhs of lives can be protected.
                    Though people intended to protect the accident victim,lack of knowledge about first aid and future legal consequences and complications make them hesitate to help the victim.
                    If the accident victim is provide with urgent humanitarian help by providing first aid treatment and admitting him to nearest hospital,life of the earning member of the family can be saved avoiding future financial crisis.The
                    Government of Karnataka has introduced Accident Life Saviour Programme during the year 2016-17 and the same will be implemented through the Karnataka
                    State Unorganised Workers Social Security Board with the co-oparation of Transport Department,Health&Family Welfare Department,Red Cross,Home Department and other departments to give first aid training to the
                    commercial drivers.
                </p>

            </div>
            </Row>
            <Row className="search-subtitle-row pb-5">
                <Col xs={12} className="next-back-button-row mt-5">

                    <Link to="#" onClick={() => props.history.goBack()}
                        style={{ flexGrow: "0.5" }}
                    >
                        <Button variant="danger" className="back-button schemedoc" >
                            <Translate value="back" />
                        </Button>
                    </Link>

                    <Link to="/accidentUnorganizedForm"
                        style={{ flexGrow: "0.5" }}
                    >
                    
                        <Button variant="outline-primary" className="save-button schemedoc">
                            <Translate value="apply" />
                        </Button>
                        
                    </Link>

                </Col>
            </Row>
        </div>
    )
}

export default DetailedTransport