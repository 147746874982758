export const employerHandler = (ev, setEmployerDetails) => {
  const { name, value } = ev.target;
  if (name === 'mobileNumber') {
    setEmployerDetails((prev) => ({
      ...prev,
      [name]: value.replace(/[^0-9]/gi, ''),
    }));
  } else if (name === 'employerName') {
    setEmployerDetails((prev) => ({
      ...prev,
      [name]: value.replace(/[^A-Za-z]/gi, ''),
    }));
  } else if (name === 'companyName') {
    setEmployerDetails((prev) => ({
      ...prev,
      [name]: value.replace(/[^A-Za-z]/gi, ''),
    }));
  } else if (name === 'pinCode') {
    setEmployerDetails((prev) => ({
      ...prev,
      [name]: value.replace(/[^0-9]/gi, ''),
    }));
  } else {
    setEmployerDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
  return setEmployerDetails;
};

export const issuerHandler = (ev, setIssuerDetails) => {
  const { name, value } = ev.target;
  if (
    name === 'constructionMobileNumber' ||
    name === 'gramIssuerMobileNumber' ||
    name === 'unionMobileNumber'
  ) {
    setIssuerDetails((prev) => ({
      ...prev,
      [name]: value.replace(/[^0-9]/gi, '').slice(0, 10),
    }));
  } else {
    setIssuerDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  }
};
