import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const getSchemesAPI = async () => {
  try {
    const { data } = await axios.get(
      `${SERVER}/schemes/get_schemes?board_id=1`,
    );
    return data;
  } catch (error) {
    throw error;
  }
};
