import 'date-fns';
import {
  Select,
  Typography,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Popover,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import language from 'assets/images/translate (1).svg';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER, setLocaleWithFallback } from 'store/actions/user.actions';
import { Link } from 'react-router-dom';
import { getTokenCookie, showToast } from 'utils/tools';
import arrow from 'assets/images/Icon ionic-md-arrow-dropright.svg';
import marriageicn from 'assets/images/certificate-01.svg';
import usericon from 'assets/images/Icon awesome-user.svg';
import axios from 'axios';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { Translate } from 'react-redux-i18n';
import logout from 'assets/images/Logout.svg';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

import { ChildListAPI } from 'services/ThaiMagu.service';
import RenewalDialog from './ButtonGroup';

const SchemeDoc = (props) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const labourUserID = users?.user?.id;
  const i18nlang = useSelector((state) => state.i18n);

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    searchValueError: false,
    submitting: false,
    descriptionsKannada: i18nlang.locale === 'ka' ? true : false,
  });

  const [kannadaSchemeName, setKannadaSchemeName] = React.useState(null);
  const [funeraldetails, setfuneraldetails] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [childDetails, setChildDetails] = useState(null);

  let { id, role_hierarchy, totalCountAvailed, totalAvailedByUser } =
    useParams();
  var idName = id.split('_id=')[0];
  var role_id = Number(id.split('_id=')[1]);

  const hide90DaysDocument =
    idName === 'Funeral Expense and Death Assistance' ||
    idName === 'Continuation of Pension' ||
    idName === 'Continuation of Disability Pension';

  const hideSelfDeclaration =
    idName === 'Funeral Expense and Death Assistance' ||
    idName === 'Continuation of Disability Pension' ||
    idName === 'Continuation of Pension';

  useEffect(() => {
    if (i18nlang.locale === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
      });
    }
    // if(allValues.schemeDetails !== undefined && allValues.schemeDetails.scheme_details !== undefined){
    //     setKannadaSchemeName(allValues.schemeDetails.scheme_details[0].scheme_name_kannada)
    // }
  }, [allValues.descriptionsKannada]);

  useEffect(() => {
    if (users.user !== undefined && users.user !== null) {
      var config = {
        method: 'get',
        url:
          SERVER +
          '/schemes/get_scheme_details?board_id=1&scheme_id=' +
          role_id,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        // data : dataNEW
      };

      axios(config)
        .then(function (response) {
          setAllValues({
            ...allValues,
            // promResponses: promResponses,
            // fields: fields,
            schemeDetails: response.data.data,
          });
        })
        .catch((err) => {
          console.log('Error in get_scheme_details', err);
        });
    }
  }, []);

  useEffect(() => {
    if (allValues.schemeDetails !== undefined) {
      setKannadaSchemeName(
        allValues.schemeDetails.scheme_details[0].scheme_name_kannada,
      );
    }
  }, [allValues]);

  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      searchValueError: false,
      [event.target.name]: event.target.value,
    });

    if (event.target.value === 'ka') {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [event.target.name]: event.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [event.target.name]: event.target.value,
      });
    }

    if (event.target.name === 'language')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const id1 = open ? 'simple-popover' : undefined;

  const applyButtonPressed = (event) => {
    if (idName.includes('Funeral Expense and Death Assistance')) {
      setfuneraldetails(true);
    } else {
      // props.history.push("/scheme-form/"+id);
      history.push(
        `/newSchemeForm/${
          users && users.user.id
        }/${role_id}/${idName}/${role_hierarchy}/false/false/false/0/0/${totalCountAvailed}/${totalAvailedByUser}`,
      );
    }
  };
  const applyButtonPressed1 = (event) => {
    // props.history.push('/scheme-form/' + id);
    history.push(
      `/newSchemeForm/${
        users && users.user.id
      }/${role_id}/${idName}/${role_hierarchy}/false/false/false/0/0/${totalCountAvailed}/${totalAvailedByUser}`,
    );
  };

  const callChildDetails = async () => {
    try {
      const response = await ChildListAPI(users.user.id);

      setChildDetails(response.data);
    } catch (error) {
      if (error?.response?.data?.errors?.msg)
        showToast('ERROR', `${error?.response?.data?.errors?.msg}`);
      else {
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  useEffect(() => {
    if (labourUserID) {
      callChildDetails(labourUserID);
    }
  }, [labourUserID]);

  return (
    <>
      <div className="root">
        {/* <CssBaseline /> */}
        <Row className="top-div schemes">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/dashboard-user">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  {allValues.descriptionsKannada ? (
                    <>
                      ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ <br /> ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ
                      ಮಂಡಳಿ (ಕೆಬಿಒಸಿಡಬ್ಲ್ಯುಬಿ){' '}
                    </>
                  ) : (
                    <>
                      Karnataka Building & Other Construction Workers Welfare
                      Board(KBOCWWB) <br /> Government of Karnataka{' '}
                    </>
                  )}
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3}>
              <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={allValues.language}
                name="language"
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem value="">
                  <ListItemIcon>
                    <img alt="..." src={language} className="language-img" />
                  </ListItemIcon>
                  <ListItemText primary="Select Language" />
                </MenuItem>
                {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
                <MenuItem value="en">
                  <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                  <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
              </Select>
            </Col>
          </Row>
        </Row>
      </div>

      <Row className="schemes-title-row">
        <Col xs={12} md={9}>
          <a href="/kbocwwb-schemes-home" className="cal-icon-hover-icn">
            <img
              alt="..."
              className="cal-icon hover-icn"
              src={back}
              style={{ marginTop: '10px' }}
            />
          </a>
          <h1>
            <Translate value="schemes" /> |{' '}
            {allValues.descriptionsKannada ? kannadaSchemeName : idName}
            {/* { 
                       allValues.schemeDetails !== undefined && allValues.schemeDetails.scheme_details !== undefined && allValues.schemeDetails.scheme_details.map((i) =>    
                        allValues.descriptionsKannada ? i.scheme_name_kannada : i.scheme_name )} */}
          </h1>
        </Col>
        <Col
          xs={12}
          md={2}
          lg={3}
          style={{ display: 'flex', marginLeft: '60px', width: '200px' }}
        >
          {/* <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                                <MenuItem value="logout" onClick={()=>{props.history.push("/")}}>
                                    <ListItemText primary="Logout"/>
                                </MenuItem>
                            </Select> */}
          <div className="usericon-header-logout">
            <img alt="..." src={usericon} className="usericon-img" />
            <p>{users.user.first_name}</p>
            <Tooltip title="Logout" placement="top-start" arrow interactive>
              <img
                alt="..."
                src={logout}
                className="logout-img"
                onClick={() => {
                  props.history.push('/login');
                }}
              />
            </Tooltip>
          </div>
        </Col>
      </Row>

      <Row className="search-form schemedoc">
        <p className="mb-4 fieldtitle">
          {/* { 
                       allValues.schemeDetails !== undefined && allValues.schemeDetails.scheme_details !== undefined && allValues.schemeDetails.scheme_details.map((i) =>
                        
                        allValues.descriptionsKannada ? i.scheme_name_kannada : i.scheme_name 
                        
                        
                    )} */}
          {allValues.descriptionsKannada ? kannadaSchemeName : idName}
        </p>

        {allValues.schemeDetails !== undefined &&
          allValues.schemeDetails.scheme_details.map((i) => (
            <p className="mt-4">
              <img alt="..." src={arrow} className="arrowright" />
              {allValues.descriptionsKannada
                ? i.description_kannada
                : i.descriptions}
            </p>
          ))}

        {/* <p className="mt-4">
                    <img alt="..." src={arrow} className="arrowright" />
                    Marriage Assistance under section 22(1) is provisioned for the Amount of Rs. 50,000.</p>
                <p className="mt-4">
                    <img alt="..." src={arrow} className="arrowright" />
                    Marriage Assistance is provisioned for Beneficiary or his/her two childrens</p> */}

        <p className="mt-5 mb-4 fieldtitle">
          <Translate value="documentRequired" />
        </p>
        <Row>
          {/* <Col xs={6} className="mt-4 doc-required-col">
                        <div>
                            <p>Bank Account Details</p>
                            <img alt="..." src={bankicn} className="language-img" />
                        </div>
                    </Col>
                    <Col xs={6} className="mt-4 doc-required-col">
                        <div>
                            <p>Employment Certificate</p>
                            <img alt="..." src={employeeicn} className="language-img" />
                        </div>
                    </Col>
                    <Col xs={6} className="mt-4 doc-required-col">
                        <div>
                            <p>ID Card / Smart Card</p>
                            <img alt="..." src={idicn} className="language-img" />
                        </div>
                    </Col>
                     */}
          {/* <Col xs={6} className="mt-4 doc-required-col">
                        <div>
                            <p>Marriage Certificate</p>
                            <img alt="..." src={marriageicn} className="language-img" />
                        </div>
                    </Col> */}

          {allValues.schemeDetails !== undefined &&
            allValues.schemeDetails.document_details.map((i) => (
              <Col xs={12} md={6} lg={6} className="mt-4 doc-required-col">
                <div>
                  <p>
                    {allValues.descriptionsKannada
                      ? i.document_name_kannada
                      : i.document_name}
                  </p>
                  <img alt="..." src={marriageicn} className="language-img" />
                </div>
              </Col>
            ))}

          {hideSelfDeclaration ? null : (
            <Col xs={12} md={6} lg={6} className="mt-4 doc-required-col">
              <div>
                <p>Self declaration form</p>
                <img alt="..." src={marriageicn} className="language-img" />
              </div>
            </Col>
          )}

          {hide90DaysDocument ? null : (
            <Col xs={12} md={6} lg={6} className="mt-4 doc-required-col">
              <div>
                <p>90 Days work certificate</p>
                <img alt="..." src={marriageicn} className="language-img" />
              </div>
            </Col>
          )}

          {funeraldetails ? (
            <Popover
              id={id1}
              open={funeraldetails}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              className="funeral-popover"
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              // style={{border: '2px solid black', height: "50px", width:"50px"}}
            >
              <p
                style={{ marginLeft: '15px', marginRight: '15px' }}
                sx={{ p: 5 }}
              >
                {' '}
                <br></br>In case of death due to accident kindly make sure to
                avail the "Accident Assistance" scheme and the "Medical
                Assistance" <br></br> scheme before availing the "Funeral
                Assistance" scheme as the user will be deactivated after the
                approval of the <br></br> "Funeral Assistance" scheme and hence
                the user will not be able to avail the benefits from the other
                two schemes. <br></br> <br></br>
                ಅಪಘಾತದಿಂದ ಮರಣ ಸಂಭವಿಸಿದಲ್ಲಿ "ಅಪಘಾತ ನೆರವು" ಯೋಜನೆ ಮತ್ತು "ವೈದ್ಯಕೀಯ
                ನೆರವು" ಪಡೆದುಕೊಳ್ಳುವುದನ್ನು <br></br>ಖಚಿತಪಡಿಸಿಕೊಳ್ಳಿ.
                "ಅಂತ್ಯಕ್ರಿಯೆಯ ನೆರವು" ಯೋಜನೆಯನ್ನು ಪಡೆಯುವ ಮೊದಲು ಯೋಜನೆಯು ಅನುಮೋದನೆಯ
                ನಂತರ ು<br></br> ಬಳಕೆದಾರರನ್ನನಿಷ್ಕ್ರಿಯಗೊಳಿಸಲಾಗುತ್ತದೆ "ಅಂತ್ಯಕ್ರಿಯೆಯ
                ನೆರವು" ಯೋಜನೆ ಮತ್ತು ಅದರಿಂದ ಬಳಕೆದಾರರಿಗೆ ಇತರ ಎರಡುು<br></br>{' '}
                ಯೋಜನೆಗಳಿಂದ ಪ್ರಯೋಜನಗಳನ್ನು ಪಡೆಯಲು ಸಾಧ್ಯವಾಗುವುದಿಲ್ಲ. <br></br>{' '}
                <br></br>
              </p>
              <Button
                style={{
                  marginLeft: '320px',
                  marginRight: '10px',
                  marginBottom: '2px',
                }}
                onClick={() => applyButtonPressed1()}
              >
                Apply
              </Button>
              <Button
                variant="outline-primary"
                style={{ marginBottom: '2px' }}
                onClick={() => props.history.push('/schemes-home')}
              >
                Back
              </Button>
            </Popover>
          ) : null}
        </Row>
        <Row>
          <Col xs={12} className="next-back-button-row mt-5">
            <Link
              to="#"
              onClick={() => history.push('/kbocwwb-schemes-home')}
              style={{ flexGrow: '0.5' }}
            >
              <Button variant="danger" className="back-button schemedoc">
                <Translate value="back" />
              </Button>
            </Link>
            {idName === 'Thayi Magu Sahaya Hasta' &&
              totalCountAvailed !== totalAvailedByUser && (
                <Button
                  onClick={() => applyButtonPressed()}
                  style={{ flexGrow: '0.5' }}
                  variant="outline-primary"
                  className="save-button schemedoc"
                >
                  <Translate value="apply" />
                </Button>
              )}

            {idName !== 'Thayi Magu Sahaya Hasta' && (
              <Button
                onClick={() => applyButtonPressed()}
                style={{ flexGrow: '0.5' }}
                variant="outline-primary"
                className="save-button schemedoc"
              >
                <Translate value="apply" />
              </Button>
            )}

            {/* <Link
              to={`/newSchemeForm/${
                users && users.user.id
              }/${role_id}/${idName}/${role_hierarchy}/${true}`}
            >
              <Button
                variant="outline-primary"
                className="save-button schemedoc"
              >
                <Translate value="Renew" />
              </Button>
            </Link> */}
            {childDetails &&
              childDetails.length > 0 &&
              idName === 'Thayi Magu Sahaya Hasta' && (
                <RenewalDialog
                  childDetails={childDetails}
                  labourUserID={labourUserID}
                />
              )}
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default SchemeDoc;
