import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigBoardDetails, getConfigMinistryDetails, getLabourStatusDetails, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';


const BoardMaster = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })


    useEffect(() => {
        // console.log("in useEffect [users.user]")
            dispatch(getConfigBoardDetails())
    }, []);


    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Link to="/admin/board-master-form" className="add-div-link">
                                <div className="add-div">
                                <img alt="..." className="cal-icon" src={add}/>
                                <p>Add Board</p>
                                </div>
                            </Link>
                        </Col>
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                    <Col xs={12} className="title-col">
                        <Link to="/admin/home">
                            <img alt="..." className="cal-icon hover-icn" src={back}/>
                            <p className="title">Board Master</p>
                        </Link>
                    </Col>
                </Row>
                <Row className="config-page">
                    
                    <Col xs={12} className="table-col">

                    <TableContainer 
                    // component={Paper}
                    >
                        <Table 
                            className="mt-4 config-table"
                            aria-label="simple table"
                            >
                             <TableHead>
                                <TableRow>
                                    <TableCell ></TableCell>
                                    <TableCell align="right">
                                        <Link to="/admin/home">
                                            <img alt="..." className="cal-icon hover-icn" src={home}/>
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left">Board</TableCell>
                                    <TableCell align="left">Short Name</TableCell>
                                    <TableCell align="left">Options</TableCell>
                                    <TableCell align="left">Status</TableCell>
                                    <TableCell ></TableCell>
                                    <TableCell ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                        
                            {
                                users.getConfigBoardDetails !== null && users.getConfigBoardDetails !== "Unauthorized" && users.getConfigBoardDetails.length > 0 ?

                                <>
                                {users.getConfigBoardDetails.map((row) => (
                                    <TableRow key={row.id}>
                                        <TableCell className="blankcol"></TableCell>
                                        <TableCell></TableCell>
                                        <TableCell align="center" width="350px">{row.name}</TableCell>
                                        <TableCell align="center">{row.short_name}</TableCell>
                                        <TableCell align="center">
                                            <Link to ={{ 
                                                    pathname: "/admin/board-master-form", 
                                                    boardid: row.id
                                                    }}
                                                    className="td-link">
                                                <img alt="..." src={Edit} className="mr-2"/>Edit
                                            </Link>
                                        </TableCell>
                                        <TableCell align="center" className="active-cell">
                                            <img alt="..." src={tick} className="mr-2"/>Active</TableCell>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                    </TableRow>
                                ))}
                                </>

                                :

                                <p>No Ministry Details Available</p>
                            }
                              
                            
                            </TableBody>
                        </Table>
                    </TableContainer>

                    </Col>
                            
                </Row>
        </>
    )
}

export default BoardMaster;