import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import PaginationFunction from './adminPortal/Pagination';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import moment from 'moment';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
// import Tooltip2 from '@mui/material/Tooltip';
// import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
// import { useSelector } from 'react-redux';
// import { Button } from 'react-bootstrap';
// import { getTokenCookie, showToast } from 'utils/tools';
// import axios from 'axios';
// import { SERVER } from 'store/actions/user.actions';

const TableComponentRenewal = ({ registrationList, appStatus }) => {
  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const users = useSelector((state) => state.users);
  console.log(registrationList);
  const PER_PAGE = 10;

  useEffect(() => {
    if (query) {
      const keys = [
        'labour_full_name',
        'application_no',
        'gsc_no',
        'mobile_no',
      ];
      const result = registrationList.filter((item) =>
        keys.some((key) => {
          return (
            item['labour_full_name'].includes(query) ||
            item['application_no'].includes(query) ||
            item['gsc_no'].includes(query) ||
            item['mobile_no'].includes(query)
          );
        }),
      );
      console.log(result, 'RESULT:');
      setFilteredData(result);
    }
  }, [query, registrationList]);

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : registrationList && Math.ceil(registrationList.length / PER_PAGE);
  // console.log(count, 'count');
  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(registrationList || [], PER_PAGE);

  // console.log(_DATA, 'PAGIN');
  // console.log(_DATA.maxPage, 'maxPage');

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const EcardClicked = (uid, bid) => {
    // console.log('EcardClicked: ', `/e-card/uid=${uid}_bid=${bid}`);
    // props.history.push(`/e-card/uid=${uid}_bid=${bid}`);
    const win = window.open(`/e-card/uid=${uid}_bid=${bid}`, '_blank');
    win.focus();
  };

  //   console.log(_DATA.currentData(), 'PAGIN');
  return (
    <>
      <TableContainer style={{ width: '100%' }} component={Paper}>
        <div className="d-flex justify-content-evenly align-items-center m-2 gap-2">
          <div className="w-75">
            <input
              style={{ resize: 'none' }}
              className="text-center w-100 form-control"
              value={query}
              onChange={(ev) => setQuery(ev.target.value)}
              placeholder={
                'Filter by Application Number,Applicant Name,Sakala Number,Mobile Number'
              }
            />
            <p className="text-center  text-danger">
              Note: Please enter as per the details in the{' '}
              <span style={{ fontWeight: 'bold' }}>"Acknowledgment"</span>
            </p>
          </div>
          <div className="align-self-start">
            <Stack spacing={2} className="w-100">
              <Pagination
                count={count}
                size="medium"
                page={page}
                color="primary"
                variant="outlined"
                shape="circular"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Date
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Applicant Name
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Number
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Status
              </TableCell>

              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                View
              </TableCell>
              {users.getDeptUserRole.data[0].role_id &&
                users.getDeptUserRole.data[0].role_id === 1 &&
                appStatus === 47 && (
                  <TableCell
                    style={{
                      color: 'black',
                      fontWeight: 'bolder',
                      whiteSpace: 'nowrap',
                    }}
                    align="center"
                  >
                    E-Card
                  </TableCell>
                )}
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.currentData() &&
              _DATA.currentData().map((row) => (
                <TableRow key={row.id}>
                  <TableCell align="center">
                    {moment(row.application_date, 'YYYY/MM/DD').format(
                      'DD/MM/YYYY',
                    )}
                  </TableCell>
                  <TableCell align="center">{row.labour_full_name}</TableCell>
                  <TableCell align="center">{row.application_no}</TableCell>

                  <TableCell align="center">
                    {row.registration_status}
                  </TableCell>

                  <TableCell align="center">
                    {row.registration_status === 'Pending' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Pending'}/${'Renewal'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                        View
                      </Link>
                    )}
                    {row.registration_status === 'Approved' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Approved'}/${'Renewal'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                        View
                      </Link>
                    )}

                    {row.registration_status === 'Rejected' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'Rejected'}/${'Renewal'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.registration_status === 'Draft Approved' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftApproved'}/${'Renewal'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                    {row.registration_status === 'Draft Rejected' && (
                      <Link
                        to={`/admin/details/${row.labour_user_id}/${row.board_id}/${'DraftRejected'}/${'Renewal'}`}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />
                      </Link>
                    )}
                  </TableCell>
                  {appStatus === 47 && (
                    <TableCell align="center" className="">
                      <Link
                        to="#"
                        onClick={() => EcardClicked(row.labour_user_id, 1)}
                      >
                        <img
                          alt="..."
                          src={Eye}
                          className="mr-2"
                          style={{ marginRight: '8px' }}
                        />{' '}
                        {/* View <br/> E-Card*/}
                      </Link>
                    </TableCell>
                  )}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableComponentRenewal;
