import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  MenuItem,
  ListItemText,
  withStyles,
  Menu,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  TableHead,
  TextField,
  InputAdornment,
  Select,
  Backdrop,
  makeStyles,
  FormControl,
  Paper,
  ListItemIcon,
  Checkbox,
} from '@material-ui/core';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import axios from 'axios';
import { Button, ButtonBase, TableFooter } from '@mui/material';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
import { CSVLink } from 'react-csv';
import Excel from '../../assets/images/excel.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import CancelIcon from '@mui/icons-material/Cancel';
const NEWReportsAdmin = ({
  status,
  gender,
  category,
  religion,
  natureOfWork,
  departmentID,
  roleID,
  circleID,
}) => {
  console.log('PropsStatus', status);
  console.log(gender);
  console.log(category);
  console.log(religion);
  console.log(natureOfWork);

  const [allValues, setAllValues] = React.useState({
    start_date: null,
    end_date: null,
    dateSelected: false,
    start_date2: null,
    end_date2: null,
    dateSelected2: false,
    start_date3: null,
    end_date3: null,
    dateSelected3: false,
  });

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: '#ffffffee',
      color: '#000',
      display: 'block',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#6CC17A',
    },
    backdropCheckp: {
      fontSize: '30px',
      fontWeight: 'bold',
    },
  }));
  const classes = useStyles();

  const [reportSelect, setReportSelect] = useState('');
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [regSelect, setRegSelect] = useState(false);
  const [statusSelect, setStatusSelect] = useState([]);
  const [genderID, setGenderID] = useState('');
  const [startDate, setstartDate] = React.useState(null);
  const [endDate, setendDate] = React.useState(null);
  const [districtID, setDistrictID] = useState('');
  const [talukID, setTalukID] = useState('');
  const [fetchDistrict, setFetchDistrict] = useState([]);
  const [fetchTaluk, setFetchTaluk] = useState([]);
  const [fetchCircle, setFetchCircle] = useState([]);
  const [reportData, setReportData] = useState([]);
  const [showDistrict, setShowDistrict] = useState(false);
  const [showTaluk, setShowTaluk] = useState(false);
  const [showCircle, setShowCircle] = useState(false);
  const [CSVOverall, setCSVOverall] = useState([]);
  const [CSVOverallTaluk, setCSVOverallTaluk] = useState([]);
  const [CSVOverallCircle, setCSVOverallCircle] = useState([]);
  const [transferData, setTransferData] = useState([]);
  const [categorySelect, setCategorySelect] = useState([]);
  const [religionSelect, setReligionSelect] = useState([]);
  const [natureOfWorkSelect, setNatureOfWorkSelect] = useState([]);
  const [showFilters, setShowFilters] = useState(true);

  const excelRef = useRef(null);

  const [renSelect, setRenSelect] = useState('');
  const [statusSelect2, setStatusSelect2] = useState([]);
  const [genderID2, setGenderID2] = useState('');
  const [startDate2, setstartDate2] = React.useState(null);
  const [endDate2, setendDate2] = React.useState(null);
  const [districtID2, setDistrictID2] = useState('');
  const [talukID2, setTalukID2] = useState('');
  const [fetchDistrict2, setFetchDistrict2] = useState([]);
  const [fetchTaluk2, setFetchTaluk2] = useState([]);
  const [fetchCircle2, setFetchCircle2] = useState([]);
  const [reportData2, setReportData2] = useState([]);
  const [showDistrict2, setShowDistrict2] = useState(false);
  const [showTaluk2, setShowTaluk2] = useState(false);
  const [showCircle2, setShowCircle2] = useState(false);
  const [CSVOverall2, setCSVOverall2] = useState([]);
  const [CSVOverallTaluk2, setCSVOverallTaluk2] = useState([]);
  const [CSVOverallCircle2, setCSVOverallCircle2] = useState([]);
  const [transferData2, setTransferData2] = useState('');
  const [showFilters2, setShowFilters2] = useState(true);
  const [categorySelect2, setCategorySelect2] = useState([]);
  const [religionSelect2, setReligionSelect2] = useState([]);
  const [natureOfWorkSelect2, setNatureOfWorkSelect2] = useState([]);

  const excelRef2 = useRef(null);

  const [schemeSelect, setSchemeSelect] = useState('');
  const [statusSelect3, setStatusSelect3] = useState('');
  const [fetchSchemes, setFetchSchemes] = useState([]);
  const [schemeId, setSchemeId] = useState('');
  const [genderID3, setGenderID3] = useState('');
  const [startDate3, setstartDate3] = React.useState(null);
  const [endDate3, setendDate3] = React.useState(null);
  const [districtID3, setDistrictID3] = useState('');
  const [talukID3, setTalukID3] = useState('');
  const [fetchDistrict3, setFetchDistrict3] = useState([]);
  const [fetchTaluk3, setFetchTaluk3] = useState([]);
  const [fetchCircle3, setFetchCircle3] = useState([]);
  const [reportData3, setReportData3] = useState([]);
  const [showDistrict3, setShowDistrict3] = useState(false);
  const [showTaluk3, setShowTaluk3] = useState(false);
  const [showCircle3, setShowCircle3] = useState(false);
  const [CSVOverall3, setCSVOverall3] = useState([]);
  const [CSVOverallSchemeCount, setCSVOverallSchemeCount] = useState([]);
  const [CSVOverallTaluk3, setCSVOverallTaluk3] = useState([]);
  const [CSVOverallCircle3, setCSVOverallCircle3] = useState([]);
  const [showSchemes, setShowSchemes] = useState(false);

  const [showFilters3, setShowFilters3] = useState(true);

  const excelRef3 = useRef(null);

  const reportsData = [
    {
      value: 'New Registration Data',
      value_id: 2,
    },
    {
      value: 'Transfered from Seva Sindhu Data',
      value_id: 1,
    },
  ];

  const isAllStatusSelected =
    status && status.length > 0 && statusSelect.length === status.length;

  const isAllReportsDataSelected =
    reportsData.length > 0 && transferData.length === reportsData.length;

  const isAllGenderSelected =
    gender && gender.length > 0 && genderID.length === gender.length;

  const isAllCategorySelected =
    category &&
    category.length > 0 &&
    categorySelect.length === category.length;

  const isAllReligionSelected =
    religion &&
    religion.length > 0 &&
    religionSelect.length === religion.length;

  const isAllNatureOfWorkSelected =
    natureOfWork &&
    natureOfWork.length > 0 &&
    natureOfWorkSelect.length === natureOfWork.length;

  const isAllRenStatusSelected =
    status && status.length > 0 && statusSelect2.length === status.length;

  // const isAllRenReportsDataSelected = reportsData.length > 0 && transferData.length === reportsData.length

  const isAllRenGenderSelected =
    gender && gender.length > 0 && genderID2.length === gender.length;

  const isAllRenCategorySelected =
    category &&
    category.length > 0 &&
    categorySelect2.length === category.length;

  const isAllRenReligionSelected =
    religion &&
    religion.length > 0 &&
    religionSelect2.length === religion.length;

  const isAllRenNatureOfWorkSelected =
    natureOfWork &&
    natureOfWork.length > 0 &&
    natureOfWorkSelect2.length === natureOfWork.length;

  const handleChange = (ev) => {
    console.log(ev.target.value);
    setReportSelect(ev.target.value);

    if (ev.target.value === '205') {
      setRegSelect(true);
    } else if (ev.target.value === '216') {
      setRenSelect(true);
    } else if (ev.target.value === '218') {
      setSchemeSelect(true);
    }
  };
  console.log(reportSelect, 'SelectedReport');
  const handleStatusChange = (ev) => {
    // console.log("handleStatusChange:",ev.target.value);
    const value = ev.target.value;

    const statusSelectArray = [...statusSelect];

    if (value === 'all') {
      setStatusSelect(
        statusSelectArray.length === status.length
          ? []
          : status.map((item) => item.value_id),
      );
      return;
    }

    if (!statusSelectArray.includes(value)) {
      statusSelectArray.push(value);
    } else {
      statusSelectArray.splice(statusSelectArray.indexOf(value), 1);
    }

    setStatusSelect(statusSelectArray);
  };

  console.log('statusSelect', statusSelect);

  const handleGenderChange = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const genderSelectArray = [...genderID];
    if (value === 'all') {
      setGenderID(
        genderSelectArray.length === gender.length
          ? []
          : gender.map((item) => item.value_id),
      );
      return;
    }
    if (!genderSelectArray.includes(value)) {
      genderSelectArray.push(value);
    } else {
      genderSelectArray.splice(genderSelectArray.indexOf(value), 1);
    }
    setGenderID(genderSelectArray);
  };

  console.log('genderData', genderID);

  const handleTransfer = (ev) => {
    const value = ev.target.value;
    const reportsSelectArray = [...transferData];
    if (value === 'all') {
      setTransferData(
        reportsSelectArray.length === reportsData.length
          ? []
          : reportsData.map((item) => item.value_id),
      );
      return;
    }

    if (!reportsSelectArray.includes(value)) {
      reportsSelectArray.push(value);
    } else {
      reportsSelectArray.splice(reportsSelectArray.indexOf(value), 1);
    }

    setTransferData(reportsSelectArray);
  };
  console.log('reportsTypeData', transferData);

  const handleCategoryChange = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const categorySelectArray = [...categorySelect];
    if (value === 'all') {
      setCategorySelect(
        categorySelectArray.length === category.length
          ? []
          : category.map((item) => item.value_id),
      );
      return;
    }
    if (!categorySelectArray.includes(value)) {
      categorySelectArray.push(value);
    } else {
      categorySelectArray.splice(categorySelectArray.indexOf(value), 1);
    }
    setCategorySelect(categorySelectArray);
  };

  const handleReligionChange = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const religionSelectArray = [...religionSelect];
    if (value === 'all') {
      setReligionSelect(
        religionSelectArray.length === religion.length
          ? []
          : religion.map((item) => item.value_id),
      );
      return;
    }
    if (!religionSelectArray.includes(value)) {
      religionSelectArray.push(value);
    } else {
      religionSelectArray.splice(religionSelectArray.indexOf(value), 1);
    }
    setReligionSelect(religionSelectArray);
  };
  console.log('ReligionSel', religionSelect);

  const handleNatureOfWorkChange = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const natureOfWorkSelectArray = [...natureOfWorkSelect];
    if (value === 'all') {
      setNatureOfWorkSelect(
        natureOfWorkSelectArray.length === natureOfWork.length
          ? []
          : natureOfWork.map((item) => item.value_id),
      );
      return;
    }
    if (!natureOfWorkSelectArray.includes(value)) {
      natureOfWorkSelectArray.push(value);
    } else {
      natureOfWorkSelectArray.splice(natureOfWorkSelectArray.indexOf(value), 1);
    }
    setNatureOfWorkSelect(natureOfWorkSelectArray);
  };

  //Renewal Handler

  const handleStatusChange2 = (ev) => {
    // console.log("handleStatusChange:",ev.target.value);
    const value = ev.target.value;

    const statusRenSelectArray = [...statusSelect2];

    if (value === 'all') {
      setStatusSelect2(
        statusRenSelectArray.length === status.length
          ? []
          : status.map((item) => item.value_id),
      );
      return;
    }

    if (!statusRenSelectArray.includes(value)) {
      statusRenSelectArray.push(value);
    } else {
      statusRenSelectArray.splice(statusRenSelectArray.indexOf(value), 1);
    }

    setStatusSelect2(statusRenSelectArray);
  };

  const handleGenderChange2 = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const genderRenSelectArray = [...genderID2];
    if (value === 'all') {
      setGenderID2(
        genderRenSelectArray.length === gender.length
          ? []
          : gender.map((item) => item.value_id),
      );
      return;
    }
    if (!genderRenSelectArray.includes(value)) {
      genderRenSelectArray.push(value);
    } else {
      genderRenSelectArray.splice(genderRenSelectArray.indexOf(value), 1);
    }
    setGenderID2(genderRenSelectArray);
  };

  const handleCategoryChange2 = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const categoryRenSelectArray = [...categorySelect2];
    if (value === 'all') {
      setCategorySelect2(
        categoryRenSelectArray.length === category.length
          ? []
          : category.map((item) => item.value_id),
      );
      return;
    }
    if (!categoryRenSelectArray.includes(value)) {
      categoryRenSelectArray.push(value);
    } else {
      categoryRenSelectArray.splice(categoryRenSelectArray.indexOf(value), 1);
    }
    setCategorySelect2(categoryRenSelectArray);
  };

  const handleReligionChange2 = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const religionRenSelectArray = [...religionSelect2];
    if (value === 'all') {
      setReligionSelect2(
        religionRenSelectArray.length === religion.length
          ? []
          : religion.map((item) => item.value_id),
      );
      return;
    }
    if (!religionRenSelectArray.includes(value)) {
      religionRenSelectArray.push(value);
    } else {
      religionRenSelectArray.splice(religionRenSelectArray.indexOf(value), 1);
    }
    setReligionSelect2(religionRenSelectArray);
  };
  console.log('ReligionSel', religionSelect);

  const handleNatureOfWorkChange2 = (ev) => {
    console.log(ev.target.value);
    const value = ev.target.value;
    const natureOfWorkRenSelectArray = [...natureOfWorkSelect2];
    if (value === 'all') {
      setNatureOfWorkSelect2(
        natureOfWorkRenSelectArray.length === natureOfWork.length
          ? []
          : natureOfWork.map((item) => item.value_id),
      );
      return;
    }
    if (!natureOfWorkRenSelectArray.includes(value)) {
      natureOfWorkRenSelectArray.push(value);
    } else {
      natureOfWorkRenSelectArray.splice(
        natureOfWorkRenSelectArray.indexOf(value),
        1,
      );
    }
    setNatureOfWorkSelect2(natureOfWorkRenSelectArray);
  };

  //Scheme Handler

  //     const handleSchemeChange = (ev) => {
  //     setSchemeId(ev.target.value)
  // }

  const handleStatusChange3 = (ev) => {
    console.log('Status:', ev.target.value);
    setStatusSelect3(ev.target.value);
  };

  const handleGenderChange3 = (ev) => {
    console.log(ev.target.value);
    setGenderID3(ev.target.value);
  };

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (startDate !== null) {
      setAllValues({
        ...allValues,
        start_date: new Date(startDate),
        dateSelected: true,
      });

      console.log('start_date_one', allValues.start_date);
    }
  }, [startDate]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (endDate !== null) {
      setAllValues({ ...allValues, end_date: endDate, dateSelected: true });
      console.log('endd_date' + allValues.end_date);
    }
  }, [endDate]);

  // Renewal Date Change

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (startDate2 !== null) {
      setAllValues({
        ...allValues,
        start_date2: new Date(startDate2),
        dateSelected2: true,
      });

      // console.log("start_date_one", allValues.start_date)
    }
  }, [startDate2]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (endDate2 !== null) {
      setAllValues({ ...allValues, end_date2: endDate2, dateSelected2: true });
      console.log('endd_date' + allValues.end_date);
    }
  }, [endDate2]);

  //Scheme Date Change

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (startDate3 !== null) {
      setAllValues({
        ...allValues,
        start_date3: new Date(startDate3),
        dateSelected3: true,
      });

      // console.log("start_date_one", allValues.start_date)
    }
  }, [startDate3]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (endDate3 !== null) {
      setAllValues({ ...allValues, end_date3: endDate3, dateSelected3: true });
      console.log('endd_date' + allValues.end_date);
    }
  }, [endDate3]);

  const regStatusObj = [];
  statusSelect &&
    statusSelect.forEach((id) => {
      var regId = {
        id: id,
      };
      regStatusObj.push(regId);
    });

  console.log('regStatusObj', regStatusObj);

  const reportDataObj = [];
  transferData &&
    transferData.forEach((id) => {
      var dataTypeId = {
        id: id,
      };
      reportDataObj.push(dataTypeId);
    });

  console.log('reportDataObj', reportDataObj);

  const genderObj = [];
  genderID &&
    genderID.forEach((id) => {
      var genderId = {
        id: id,
      };
      genderObj.push(genderId);
    });

  console.log('genderObj', genderObj);

  const casteObj = [];
  categorySelect &&
    categorySelect.forEach((id) => {
      var casteId = {
        id: id,
      };
      casteObj.push(casteId);
    });

  console.log('casteObj', casteObj);

  const religionObj = [];
  religionSelect &&
    religionSelect.forEach((id) => {
      var religionId = {
        id: id,
      };
      religionObj.push(religionId);
    });

  console.log('religionObj', religionObj);

  const natureOfWorkObj = [];
  natureOfWorkSelect &&
    natureOfWorkSelect.forEach((id) => {
      var natureOfWorkId = {
        id: id,
      };
      natureOfWorkObj.push(natureOfWorkId);
    });

  console.log('natureOfWorkObj', natureOfWorkObj);

  //Renewal Checkbox object

  const renStatusObj = [];
  statusSelect2 &&
    statusSelect2.forEach((id) => {
      var regId = {
        id: id,
      };
      renStatusObj.push(regId);
    });

  console.log('renStatusObj', renStatusObj);

  const renGenderObj = [];
  genderID2 &&
    genderID2.forEach((id) => {
      var genderId = {
        id: id,
      };
      renGenderObj.push(genderId);
    });

  console.log('renGenderObj', renGenderObj);

  const renCasteObj = [];
  categorySelect2 &&
    categorySelect2.forEach((id) => {
      var casteId = {
        id: id,
      };
      renCasteObj.push(casteId);
    });

  console.log('renCasteObj', renCasteObj);

  const renReligionObj = [];
  religionSelect2 &&
    religionSelect2.forEach((id) => {
      var religionId = {
        id: id,
      };
      renReligionObj.push(religionId);
    });

  console.log('renReligionObj', renReligionObj);

  const renNatureOfWorkObj = [];
  natureOfWorkSelect2 &&
    natureOfWorkSelect2.forEach((id) => {
      var natureOfWorkId = {
        id: id,
      };
      renNatureOfWorkObj.push(natureOfWorkId);
    });

  console.log('renNatureOfWorkObj', renNatureOfWorkObj);

  const fetchRegReportsAPI = () => {
    //  if(regSelect)
    //   {

    if (
      regStatusObj.length > 0 &&
      genderObj.length > 0 &&
      reportDataObj.length > 0 &&
      natureOfWorkObj.length > 0 &&
      casteObj.length > 0 &&
      religionObj.length > 0
    ) {
      setOpenBackdrop(true);
      const data = {
        department_user_id: departmentID,
        role_id: roleID,
        board_id: 1,
        is_date_selected: allValues.dateSelected ? 1 : 0,
        start_date: allValues.start_date,
        end_date: allValues.end_date,
        registration_status_id: regStatusObj ? regStatusObj : null,
        catalog_value_gender_id: genderObj ? genderObj : null,
        is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
        nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
        caste: casteObj ? casteObj : null,
        religion: religionObj ? religionObj : null,
        district: null,
        taluk: null,
        circle_id: null,
        age_from: 0,
        age_to: 100,
      };

      var config = {
        method: 'post',
        url: `${SERVER}/reports/get_registration_reports_by_district`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          console.log('ReportAPI', res.data.data);
          setFetchDistrict(res.data.data);
          handleFormatCSV(res.data.data);
          setShowDistrict(true);
          setOpenBackdrop(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast('WARN', 'Please Select All Options');
    }
  };

  // }

  // useEffect(()=>
  //   {

  //    if((regSelect && regSelect) )
  //    {
  //     console.log("INSIDE EFFECT REG");
  //     if(statusSelect || transferData || genderID || natureOfWorkSelect ||categorySelect || religionSelect){
  //       fetchRegReportsAPI()
  //     }

  //    }

  // },[regSelect,statusSelect,transferData,allValues.start_date,allValues.end_date,genderID,natureOfWorkSelect,categorySelect,religionSelect])

  const talukHandler = (district_id) => {
    setShowFilters(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: district_id,
      taluk: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports_by_taluk`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchTaluk(res.data.data);
        handleFormatCSVTaluk(res.data.data);
        setShowDistrict(false);
        setShowTaluk(true);
        setDistrictID(district_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const circleHandler = (district_id, taluk_id) => {
    setShowFilters(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: district_id,
      taluk: taluk_id,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports_by_circle`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchCircle(res.data.data);
        handleFormatCSVCircle(res.data.data);
        setShowDistrict(false);
        setShowTaluk(false);
        setShowCircle(true);
        setDistrictID(district_id);
        setTalukID(taluk_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSV = async (district_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: district_id,
      taluk: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef.current !== null && excelRef.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{
  //     if(reportData)
  //     {
  //         setTimeout(()=>{
  //        excelRef.current !== null && excelRef.current.link.click()
  //         },3000)
  //     }

  // },[reportData])

  const handleCSVDistrictAll = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: null,
      taluk: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);
        setTimeout(() => {
          excelRef.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTaluk = async (district_id, taluk_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: district_id,
      taluk: taluk_id,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef.current !== null && excelRef.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTalukAll = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: districtID,
      taluk: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);
        setTimeout(() => {
          excelRef.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircle = async (district_id, taluk_id, circle_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: district_id,
      taluk: taluk_id,
      circle_id: circle_id,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef.current !== null && excelRef.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircleAll = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: regStatusObj ? regStatusObj : null,
      catalog_value_gender_id: genderObj ? genderObj : null,
      is_from_seva_sindhu: reportDataObj ? reportDataObj : null,
      nature_of_work: natureOfWorkObj ? natureOfWorkObj : null,
      caste: casteObj ? casteObj : null,
      religion: religionObj ? religionObj : null,
      district: districtID,
      taluk: talukID,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_registration_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);
        setTimeout(() => {
          excelRef.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let initialValue = 0;
  const totalCount = fetchDistrict.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValue);

  let initialValue2 = 0;
  const totalCount2 = fetchTaluk.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValue2);

  let initialValue3 = 0;
  const totalCount3 = fetchCircle.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValue3);

  const headersDistrict = [
    { label: 'District', key: 'district' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const headersTaluk = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const headersCircle = [
    { label: 'Circle', key: 'circle_name' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const handleFormatCSV = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverall(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVTaluk = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverallTaluk(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVCircle = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverallCircle(arr);
    console.log('Overall', arr);
  };

  const handleBack = (value) => {
    console.log('BAck', value);
    if (value === 'Taluk') {
      setShowDistrict(true);
      setShowTaluk(false);
      setShowFilters(true);
    } else if (value === 'Circle') {
      setShowCircle(false);
      setShowTaluk(true);
      setShowFilters(false);
      setShowDistrict(false);
    }
  };

  //Renewal API CALLS

  const fetchRenReportsAPI = () => {
    if (
      renStatusObj.length > 0 &&
      renGenderObj.length > 0 &&
      renNatureOfWorkObj.length > 0 &&
      renCasteObj.length > 0 &&
      renReligionObj.length > 0
    ) {
      setOpenBackdrop(true);

      const data = {
        department_user_id: departmentID,
        role_id: roleID,
        board_id: 1,
        is_date_selected: allValues.dateSelected ? 1 : 0,
        start_date: allValues.start_date,
        end_date: allValues.end_date,
        registration_status_id: renStatusObj ? renStatusObj : null,
        catalog_value_gender_id: renGenderObj ? renGenderObj : null,
        nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
        caste: renCasteObj ? renCasteObj : null,
        religion: renReligionObj ? renReligionObj : null,
        district_id: null,
        taluk_id: null,
        circle_id: null,
        age_from: 0,
        age_to: 100,
      };

      var config = {
        method: 'post',
        url: `${SERVER}/reports/get_renewal_reports_by_district`,
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config)
        .then((res) => {
          console.log('ReportRenwalAPI', res.data.data);
          setFetchDistrict2(res.data.data);
          handleFormatCSV2(res.data.data);
          setShowDistrict2(true);
          setOpenBackdrop(false);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      showToast('WARN', 'Please Select All Options');
    }
  };

  const talukHandler2 = (district_id) => {
    setShowFilters2(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: district_id,
      taluk_id: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports_by_taluk`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchTaluk2(res.data.data);
        handleFormatCSVTaluk2(res.data.data);
        setShowDistrict2(false);
        setShowTaluk2(true);
        setDistrictID2(district_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const circleHandler2 = (district_id, taluk_id) => {
    setShowFilters(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: district_id,
      taluk_id: taluk_id,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports_by_circle`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchCircle2(res.data.data);
        handleFormatCSVCircle2(res.data.data);
        setShowDistrict2(false);
        setShowTaluk2(false);
        setShowCircle2(true);
        setDistrictID2(district_id);
        setTalukID2(taluk_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSV2 = async (district_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: district_id,
      taluk_id: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData2(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef2.current !== null && excelRef2.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{
  //     if(reportData)
  //     {
  //         setTimeout(()=>{
  //        excelRef.current !== null && excelRef.current.link.click()
  //         },3000)
  //     }

  // },[reportData])

  const handleCSVDistrictAll2 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: null,
      taluk_id: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData2(res.data.data);
        setTimeout(() => {
          excelRef2.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTaluk2 = async (district_id, taluk_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: district_id,
      taluk_id: taluk_id,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData2(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef2.current !== null && excelRef2.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTalukAll2 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: districtID2,
      taluk_id: null,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData(res.data.data);
        setTimeout(() => {
          excelRef2.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircle2 = async (district_id, taluk_id, circle_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: district_id,
      taluk_id: taluk_id,
      circle_id: circle_id,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        setReportData2(res.data.data);

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef2.current !== null && excelRef2.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircleAll2 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      is_date_selected: allValues.dateSelected ? 1 : 0,
      start_date: allValues.start_date,
      end_date: allValues.end_date,
      registration_status_id: renStatusObj ? renStatusObj : null,
      catalog_value_gender_id: renGenderObj ? renGenderObj : null,
      nature_of_work: renNatureOfWorkObj ? renNatureOfWorkObj : null,
      caste: renCasteObj ? renCasteObj : null,
      religion: renReligionObj ? renReligionObj : null,
      district_id: districtID2,
      taluk_id: talukID2,
      circle_id: null,
      age_from: 0,
      age_to: 100,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_renewal_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        setReportData2(res.data.data);
        setTimeout(() => {
          excelRef2.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let initialValueRen = 0;
  const totalCountRen = fetchDistrict2.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValueRen);

  let initialValueRen2 = 0;
  const totalCountRen2 = fetchTaluk2.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValueRen2);

  let initialValueRen3 = 0;
  const totalCountRen3 = fetchCircle2.reduce(function (accum, curValue) {
    return accum + curValue.total_labours;
  }, initialValueRen3);

  const headersDistrict2 = [
    { label: 'District', key: 'district' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const headersTaluk2 = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const headersCircle2 = [
    { label: 'Circle', key: 'circle_name' },
    { label: 'Total Labour Registered', key: 'total_labours' },
  ];

  const handleFormatCSV2 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverall2(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVTaluk2 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverallTaluk2(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVCircle2 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_labours;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_labours: Total,
    });
    setCSVOverallCircle2(arr);
    console.log('Overall', arr);
  };

  const handleBack2 = (value) => {
    console.log('BAck', value);
    if (value === 'Taluk') {
      setShowDistrict2(true);
      setShowTaluk2(false);
      setShowFilters2(true);
    } else if (value === 'Circle') {
      setShowCircle2(false);
      setShowTaluk2(true);
      setShowFilters2(false);
      setShowDistrict2(false);
    }
  };

  //Scheme API CALLS

  // useEffect(()=> {
  //   axios.get(`${SERVER}/schemes/get_schemes?board_id=1`)
  //   .then((res)=>{
  //     console.log("SchemesData",res.data.data);
  //     setFetchSchemes(res.data.data)
  //   }).catch((err)=>{
  //     console.log(err);
  //   })
  // },[])

  useEffect(() => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      scheme_id: null,
      board_id: 1,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports_count`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('SchemeAPI', res.data.data);
        setFetchSchemes(res.data.data[0]);
        handleFormatCSVSchemeCount(res.data.data[0]);
        setShowDistrict3(false);
        setShowSchemes(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [statusSelect3, allValues.start_date3, allValues.end_date3, genderID3]);

  const districtHandler = (scheme_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      scheme_id: scheme_id,
      board_id: 1,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports_by_district`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportDISTRICTAPI', res.data.data);
        setFetchDistrict3(res.data.data[0]);
        handleFormatCSV3(res.data.data[0]);
        setShowDistrict3(true);
        setShowSchemes(false);
        setSchemeId(scheme_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  // useEffect(()=>
  //     {

  //       const data = {
  //       "department_user_id": departmentID,
  //       "role_id": roleID,
  //       "scheme_id": schemeId,
  //       "board_id": 1,
  //       "catalog_value_approval_status": statusSelect3,
  //       "is_date_selected": allValues.dateSelected3 ? 1 : 0,
  //       "start_date": allValues.start_date3,
  //       "end_date": allValues.end_date3,
  //       "gender_id": genderID3 !== "" ? genderID3 : null,

  //       }

  //       var config = {
  //           method:"post",
  //           url:`${SERVER}/reports/get_scheme_reports_by_district`,
  //           headers: {
  //                   'Content-Type': 'application/json',
  //                   'Authorization': `Bearer ${getTokenCookie()}`
  //               },
  //               data : data

  //       }
  //       axios(config).then((res)=>{
  //           console.log("ReportRenwalAPI",res.data.data);
  //           setFetchDistrict3(res.data.data[0])
  //           handleFormatCSV3(res.data.data[0])
  //           setShowDistrict3(true)

  //       }).catch((err)=>{
  //           console.log(err);
  //       })

  //   },[schemeSelect,schemeId,statusSelect3,allValues.start_date3,allValues.end_date3,genderID3])

  const talukHandler3 = (district_id) => {
    setShowFilters2(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: district_id,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports_by_taluk`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchTaluk3(res.data.data[0]);
        handleFormatCSVTaluk3(res.data.data[0]);
        setShowDistrict3(false);
        setShowTaluk3(true);
        setShowSchemes(false);
        setDistrictID3(district_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const circleHandler3 = (district_id, taluk_id) => {
    setShowFilters(false);
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: district_id,
      taluk_id: taluk_id,
      circle_id: null,
    };

    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports_by_circle`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    axios(config)
      .then((res) => {
        console.log('ReportAPI', res.data.data);
        setFetchCircle3(res.data.data[0]);
        handleFormatCSVCircle3(res.data.data[0]);
        setShowDistrict3(false);
        setShowTaluk3(false);
        setShowCircle3(true);
        setShowSchemes(false);
        setDistrictID3(district_id);
        setTalukID3(taluk_id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSV3 = async (district_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: district_id,
      taluk_id: null,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])

        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef3.current !== null && excelRef3.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // useEffect(()=>{
  //     if(reportData)
  //     {
  //         setTimeout(()=>{
  //        excelRef.current !== null && excelRef.current.link.click()
  //         },3000)
  //     }

  // },[reportData])

  const handleCSVDistrictAll3 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: null,
      taluk_id: null,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportDataScheme:', res.data.data);
        // districtMIS(res.data.data[0])
        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }
        setTimeout(() => {
          excelRef3.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTaluk3 = async (district_id, taluk_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: district_id,
      taluk_id: taluk_id,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef3.current !== null && excelRef3.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVTalukAll3 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: districtID3,
      taluk_id: null,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }
        setTimeout(() => {
          excelRef3.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircle3 = async (district_id, taluk_id, circle_id) => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: districtID3,
      taluk_id: null,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data);
        // districtMIS(res.data.data[0])
        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }

        // setOpenBackdrop(false)
        setTimeout(() => {
          excelRef3.current !== null && excelRef3.current.link.click();
        }, 3000);
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleCSVCircleAll3 = async () => {
    const data = {
      department_user_id: departmentID,
      role_id: roleID,
      board_id: 1,
      scheme_id: schemeId,
      catalog_value_approval_status: statusSelect3,
      is_date_selected: allValues.dateSelected3 ? 1 : 0,
      start_date: allValues.start_date3,
      end_date: allValues.end_date3,
      gender_id: genderID3 !== '' ? genderID3 : null,
      district_id: districtID3,
      taluk_id: talukID3,
      circle_id: null,
    };
    var config = {
      method: 'post',
      url: `${SERVER}/reports/get_scheme_reports`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };
    await axios(config)
      .then((res) => {
        console.log('ReportData001:', res.data.data[0]);
        // districtMIS(res.data.data[0])
        if (res.data.data.length >= 3) {
          const arr1 = res.data.data[0];
          const arr2 = res.data.data[2];
          const newObj = {};

          arr2.map((item1) => {
            const obj = {
              [item1.field_name]: item1.field_value,
            };

            if (newObj[item1.scheme_avail_id]) {
              newObj[item1.scheme_avail_id] = {
                ...newObj[item1.scheme_avail_id],
                ...obj,
              };
            } else {
              newObj[item1.scheme_avail_id] = {
                ...obj,
              };
            }
          });

          const result = arr1.map((item) => {
            // console.log(newObj[item.scheme_avail_id])
            return {
              ...item,
              ...newObj[item.scheme_avail_id],
            };
          });
          setReportData3(result);
        }
        setTimeout(() => {
          excelRef3.current.link.click();
        }, 3000);
        // setOpenBackdrop(false)
        //   var arr = [];
        //   res.data.data[0].forEach((i, key) => {
        //   console.log(i);
        //   arr.push(i);
        // }
        // );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  console.log('fetchDistrict3', fetchDistrict3);
  let initialValueSchemeCount = 0;
  const totalCountSchemeCount = fetchSchemes.reduce(function (accum, curValue) {
    return accum + curValue.total_schemes;
  }, initialValueSchemeCount);

  let initialValueScheme = 0;
  const totalCountScheme = fetchDistrict3.reduce(function (accum, curValue) {
    return accum + curValue.total_schemes;
  }, initialValueScheme);

  let initialValueScheme2 = 0;
  const totalCountScheme2 = fetchTaluk3.reduce(function (accum, curValue) {
    return accum + curValue.total_schemes;
  }, initialValueScheme2);

  let initialValueScheme3 = 0;
  const totalCountScheme3 = fetchCircle3.reduce(function (accum, curValue) {
    return accum + curValue.total_schemes;
  }, initialValueScheme3);

  const headresSchemeCount = [
    { label: 'Scheme Name', key: 'scheme_name' },
    { label: 'Total Scheme Applied', key: 'total_schemes' },
  ];

  const headersDistrict3 = [
    { label: 'District', key: 'district' },
    { label: 'Total Scheme Applied', key: 'total_schemes' },
  ];

  const headersTaluk3 = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Total Scheme Applied', key: 'total_schemes' },
  ];

  const headersCircle3 = [
    { label: 'Circle', key: 'circle_name' },
    { label: 'Total Scheme Applied', key: 'total_schemes' },
  ];

  const handleFormatCSVSchemeCount = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_schemes;
    }, initialValue);
    arr.push({
      scheme_name: 'Total',
      total_schemes: Total,
    });
    setCSVOverallSchemeCount(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSV3 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_schemes;
    }, initialValue);
    arr.push({
      district: 'Total',
      total_schemes: Total,
    });
    setCSVOverall3(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVTaluk3 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_schemes;
    }, initialValue);
    arr.push({
      taluk: 'Total',
      total_schemes: Total,
    });
    setCSVOverallTaluk3(arr);
    console.log('Overall', arr);
  };

  const handleFormatCSVCircle3 = (data) => {
    console.log('FormatData', data);
    const arr = [...data];
    let initialValue = 0;
    const Total = data.reduce(function (accum, curValue) {
      return accum + curValue.total_schemes;
    }, initialValue);
    arr.push({
      circle: 'Total',
      total_schemes: Total,
    });
    setCSVOverallCircle3(arr);
    console.log('Overall', arr);
  };

  const handleBack3 = (value) => {
    console.log('BAck', value);
    if (value === 'District') {
      setShowSchemes(true);
      setShowDistrict3(false);
    } else if (value === 'Taluk') {
      setShowDistrict3(true);
      setShowTaluk3(false);
      setShowFilters3(true);
    } else if (value === 'Circle') {
      setShowCircle3(false);
      setShowTaluk3(true);
      setShowFilters3(false);
      setShowDistrict3(false);
    }
  };

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Row className="container-fluid d-flex justify-content-center align-items-center">
          <Col xs={3} className="">
            <FormControl variant="outlined" fullWidth className="formcontrol5">
              <Select
                className="select-marital w-100"
                style={{ border: '1px solid grey' }}
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={reportSelect}
                name="circleActiveLi"
                displayEmpty
                onChange={handleChange}
                defaultValue={'Select'}
              >
                <MenuItem value={''}>
                  <ListItemText primary="Select Reports" />
                </MenuItem>

                <MenuItem value={'205'}>
                  <ListItemText primary={'Registration Reports'} />
                </MenuItem>
                <MenuItem value={'216'}>
                  <ListItemText primary={'Renewal Reports'} />
                </MenuItem>
                <MenuItem value={'218'}>
                  <ListItemText primary={'Schemes Reports'} />
                </MenuItem>
              </Select>
            </FormControl>
          </Col>
        </Row>
      </div>
      {reportSelect === '205' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Row className="container-fluid d-flex justify-content-center align-items-center">
            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleStatusChange}
                  defaultValue={'Select'}
                  disabled={!showFilters ? true : false}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Report Status" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllStatusSelected}
                        indeterminate={
                          status.length > 0 &&
                          statusSelect.length < status.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {status &&
                    status.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={statusSelect.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={3} className="">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleTransfer}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Reports Data" />
                  </MenuItem>
                  {/* <MenuItem value={2} >
             <ListItemText primary="All" />
         </MenuItem> */}

                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllReportsDataSelected}
                        indeterminate={
                          reportsData.length > 0 &&
                          transferData.length < reportsData.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {reportsData &&
                    reportsData.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={transferData.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleGenderChange}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Gender" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllGenderSelected}
                        indeterminate={
                          gender.length > 0 && genderID.length < gender.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {gender &&
                    gender.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={genderID.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleCategoryChange}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Category" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllCategorySelected}
                        indeterminate={
                          category.length > 0 &&
                          categorySelect.length < category.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {category &&
                    category.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={categorySelect.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleReligionChange}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Religion" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllReligionSelected}
                        indeterminate={
                          religion.length > 0 &&
                          religionSelect.length < religion.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {religion &&
                    religion.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={religionSelect.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.description} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleNatureOfWorkChange}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Nature of Work" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllNatureOfWorkSelected}
                        indeterminate={
                          natureOfWork.length > 0 &&
                          natureOfWorkSelect.length < natureOfWork.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {natureOfWork &&
                    natureOfWork.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              natureOfWorkSelect.indexOf(data.value_id) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  style={{ border: '1px solid grey' }}
                >
                  <KeyboardDatePicker
                    className="datepicker"
                    // margin="normal"
                    format="YYYY/MM/DD"
                    value={allValues.start_date}
                    onChange={(date) => {
                      setstartDate(date);
                    }}
                    placeholder="Start Date"
                    disabled={!showFilters ? true : false}
                    InputProps={{
                      startAdornment: (
                        <div
                          onClick={() =>
                            setAllValues({
                              ...allValues,
                              start_date: null,
                              dateSelected: false,
                            })
                          }
                          style={{ position: 'absolute', right: '20%' }}
                        >
                          {startDate && allValues.start_date ? (
                            <InputAdornment>
                              <CancelIcon color="error" />
                            </InputAdornment>
                          ) : null}
                        </div>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                >
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    style={{ border: '1px solid grey' }}
                  >
                    <KeyboardDatePicker
                      className="datepicker"
                      // margin="normal"
                      format="YYYY/MM/DD"
                      value={allValues.end_date}
                      onChange={(date) => {
                        setendDate(date);
                      }}
                      placeholder="End Date"
                      disabled={!showFilters ? true : false}
                      InputProps={{
                        startAdornment: (
                          <div
                            onClick={() =>
                              setAllValues({
                                ...allValues,
                                end_date: null,
                                dateSelected: false,
                              })
                            }
                            style={{ position: 'absolute', right: '20%' }}
                          >
                            {endDate && allValues.end_date ? (
                              <InputAdornment>
                                <CancelIcon color="error" />
                              </InputAdornment>
                            ) : null}
                          </div>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Button
                  color="success"
                  variant="contained"
                  onClick={fetchRegReportsAPI}
                  className="select-marital"
                  style={{ padding: '10px 0px' }}
                >
                  Search
                </Button>
              </FormControl>
            </Col>

            {/* <Col xs={3} className='my-3'>
    <FormControl variant="outlined" fullWidth className="formcontrol5" >
        <Select
        className="select-marital w-100"
        style={{border:'1px solid grey'}}
        labelId="demo-simple-select-required-label"
        id="demo-simple-select-required"
        value={districtID}
        name="circleActiveLi"
        displayEmpty
        onChange={handleDistrictChange}
        defaultValue={"Select"}
                            >
         <MenuItem value={""} >
             <ListItemText primary="Select District" />
         </MenuItem>
         <MenuItem value={0}>
             <ListItemText primary="All" />
         </MenuItem>
         {
            districtData.map((data)=>(
                <MenuItem value={data.id} >
             <ListItemText primary={data.name} />
                </MenuItem>
            ))
         }
                               
        </Select>
        </FormControl>

    </Col>

    <Col xs={3} className='my-3'>
    <FormControl variant="outlined" fullWidth className="formcontrol5" >
        <Select
        className="select-marital w-100"
        style={{border:'1px solid grey'}}
        labelId="demo-simple-select-required-label"
        id="demo-simple-select-required"
        value={talukID}
        name="circleActiveLi"
        displayEmpty
        onChange={handleTalukChange}
        defaultValue={"Select"}
                            >
         <MenuItem value={""} >
             <ListItemText primary="Select Taluk" />
         </MenuItem>
         
         {
            talukData.map((data)=>(

                <MenuItem value={data.id} >
             <ListItemText primary={data.name} />
                </MenuItem>
            ))
         }
                               
        </Select>
        </FormControl>

    </Col> */}

            {/* <Col xs={3} className='my-3'>
    <FormControl variant="outlined" fullWidth className="formcontrol5" >
         <Button color='success' variant='contained' 
                onClick={callReportsAPI} 
                className='select-marital' style={{padding:'10px 0px'}}>Search</Button>
        </FormControl>

    </Col> */}
          </Row>
        </div>
      ) : null}

      {reportSelect === '216' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Row className="container-fluid d-flex justify-content-center align-items-center">
            <Col xs={5} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleStatusChange2}
                  defaultValue={'Select'}
                  disabled={!showFilters2 ? true : false}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Report Status" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllRenStatusSelected}
                        indeterminate={
                          status.length > 0 &&
                          statusSelect2.length < status.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {status &&
                    status.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={statusSelect2.indexOf(data.value_id) > -1}
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  style={{ border: '1px solid grey' }}
                >
                  <KeyboardDatePicker
                    className="datepicker"
                    // margin="normal"
                    format="YYYY/MM/DD"
                    value={allValues.start_date2}
                    onChange={(date) => {
                      setstartDate2(date);
                    }}
                    placeholder="Start Date"
                    disabled={!showFilters2 ? true : false}
                    InputProps={{
                      startAdornment: (
                        <div
                          onClick={() =>
                            setAllValues({
                              ...allValues,
                              start_date2: null,
                              dateSelected2: false,
                            })
                          }
                          style={{ position: 'absolute', right: '20%' }}
                        >
                          {startDate2 && allValues.start_date2 ? (
                            <InputAdornment>
                              <CancelIcon color="error" />
                            </InputAdornment>
                          ) : null}
                        </div>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                >
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    style={{ border: '1px solid grey' }}
                  >
                    <KeyboardDatePicker
                      className="datepicker"
                      // margin="normal"
                      format="YYYY/MM/DD"
                      value={allValues.end_date2}
                      onChange={(date) => {
                        setendDate2(date);
                      }}
                      RestartAltIcon
                      placeholder="End Date"
                      disabled={!showFilters2 ? true : false}
                      InputProps={{
                        startAdornment: (
                          <div
                            onClick={() =>
                              setAllValues({
                                ...allValues,
                                end_date2: null,
                                dateSelected: false,
                              })
                            }
                            style={{ position: 'absolute', right: '20%' }}
                          >
                            {endDate2 && allValues.end_date2 ? (
                              <InputAdornment>
                                <CancelIcon color="error" />
                              </InputAdornment>
                            ) : null}
                          </div>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleGenderChange2}
                  disabled={!showFilters2 ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Gender" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllRenGenderSelected}
                        indeterminate={
                          gender.length > 0 && genderID2.length < gender.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {gender.map((data, index) => (
                    <MenuItem value={data.value_id} key={data.value_id}>
                      <ListItemIcon>
                        <Checkbox
                          checked={genderID2.indexOf(data.value_id) > -1}
                        />
                      </ListItemIcon>
                      <ListItemText primary={data.value} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleCategoryChange2}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Category" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllRenCategorySelected}
                        indeterminate={
                          category.length > 0 &&
                          categorySelect2.length < category.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {category &&
                    category.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              categorySelect2.indexOf(data.value_id) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleReligionChange2}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Religion" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllRenReligionSelected}
                        indeterminate={
                          religion.length > 0 &&
                          religionSelect2.length < religion.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {religion &&
                    religion.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              religionSelect2.indexOf(data.value_id) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.description} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={0}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleNatureOfWorkChange2}
                  disabled={!showFilters ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={0}>
                    <ListItemText primary="Select Nature of Work" />
                  </MenuItem>
                  <MenuItem value="all">
                    <ListItemIcon>
                      <Checkbox
                        checked={isAllRenNatureOfWorkSelected}
                        indeterminate={
                          natureOfWork.length > 0 &&
                          natureOfWorkSelect2.length < natureOfWork.length
                        }
                      />
                    </ListItemIcon>
                    <ListItemText primary="Select All" />
                  </MenuItem>

                  {natureOfWork &&
                    natureOfWork.map((data, index) => (
                      <MenuItem value={data.value_id} key={data.value_id}>
                        <ListItemIcon>
                          <Checkbox
                            checked={
                              natureOfWorkSelect2.indexOf(data.value_id) > -1
                            }
                          />
                        </ListItemIcon>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Button
                  color="success"
                  variant="contained"
                  onClick={fetchRenReportsAPI}
                  className="select-marital"
                  style={{ padding: '10px 0px' }}
                >
                  Search
                </Button>
              </FormControl>
            </Col>
          </Row>
        </div>
      ) : null}

      {reportSelect === '218' ? (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <Row className="container-fluid d-flex justify-content-center align-items-center">
            {/* <Col xs={5} className="">
                <FormControl variant="outlined" fullWidth className="formcontrol5" >
                            <Select
                                className="select-marital w-100"
                                style={{border:'1px solid grey'}}
                                labelId="demo-simple-select-required-label"
                                id="demo-simple-select-required"
                                value={schemeId}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleSchemeChange}
                                defaultValue={"Select"}
                            >
                                <MenuItem value= {""} >
                                    <ListItemText primary="Select Schemes" />
                                </MenuItem>
                                {
                                    fetchSchemes && fetchSchemes.map((scheme)=>(
                                    <MenuItem value= {scheme.id} >
                                        <ListItemText primary={scheme.name} />
                                    </MenuItem>
                                    ))
                                }
                                
                               
                            </Select>
                        </FormControl>
                </Col> */}
            <Col xs={5} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={statusSelect3}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleStatusChange3}
                  defaultValue={'Select'}
                  disabled={!showFilters3 ? true : false}
                >
                  <MenuItem value={''}>
                    <ListItemText primary="Select Report Status" />
                  </MenuItem>

                  {status &&
                    status.map((data) =>
                      data.value_id === 1147 ||
                      data.value_id === 45 ||
                      data.value_id === 46 ||
                      data.value_id === 47 ? (
                        <MenuItem value={data.value_id}>
                          <ListItemText primary={data.value} />
                        </MenuItem>
                      ) : null,
                    )}
                </Select>
              </FormControl>
            </Col>

            <Col xs={5} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital w-100"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={genderID3}
                  name="circleActiveLi"
                  displayEmpty
                  onChange={handleGenderChange3}
                  disabled={!showFilters3 ? true : false}
                  defaultValue={'Select'}
                >
                  <MenuItem value={''}>
                    <ListItemText primary="Select Gender" />
                  </MenuItem>
                  {gender &&
                    gender.map((data) => (
                      <MenuItem value={data.value_id}>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <MuiPickersUtilsProvider
                  utils={MomentUtils}
                  style={{ border: '1px solid grey' }}
                >
                  <KeyboardDatePicker
                    className="datepicker"
                    // margin="normal"
                    format="YYYY/MM/DD"
                    value={allValues.start_date3}
                    onChange={(date) => {
                      setstartDate3(date);
                    }}
                    placeholder="Start Date"
                    disabled={!showFilters3 ? true : false}
                    InputProps={{
                      startAdornment: (
                        <div
                          onClick={() =>
                            setAllValues({
                              ...allValues,
                              start_date3: null,
                              dateSelected3: false,
                            })
                          }
                          style={{ position: 'absolute', right: '20%' }}
                        >
                          {startDate3 && allValues.start_date3 ? (
                            <InputAdornment>
                              <CancelIcon color="error" />
                            </InputAdornment>
                          ) : null}
                        </div>
                      ),
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            </Col>

            <Col xs={3} className="my-3">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <FormControl
                  variant="outlined"
                  fullWidth
                  className="formcontrol5"
                >
                  <MuiPickersUtilsProvider
                    utils={MomentUtils}
                    style={{ border: '1px solid grey' }}
                  >
                    <KeyboardDatePicker
                      className="datepicker"
                      // margin="normal"
                      format="YYYY/MM/DD"
                      value={allValues.end_date3}
                      onChange={(date) => {
                        setendDate3(date);
                      }}
                      RestartAltIcon
                      placeholder="End Date"
                      disabled={!showFilters3 ? true : false}
                      InputProps={{
                        startAdornment: (
                          <div
                            onClick={() =>
                              setAllValues({
                                ...allValues,
                                end_date3: null,
                                dateSelected3: false,
                              })
                            }
                            style={{ position: 'absolute', right: '20%' }}
                          >
                            {endDate3 && allValues.end_date3 ? (
                              <InputAdornment>
                                <CancelIcon color="error" />
                              </InputAdornment>
                            ) : null}
                          </div>
                        ),
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </FormControl>
              </FormControl>
            </Col>

            {/* <Col xs={3} className='my-3'>
    <FormControl variant="outlined" fullWidth className="formcontrol5" >
        <Select
        className="select-marital w-100"
        style={{border:'1px solid grey'}}
        labelId="demo-simple-select-required-label"
        id="demo-simple-select-required"
        value={genderID3}
        name="circleActiveLi"
        displayEmpty
        onChange={handleGenderChange3}
        disabled={!showFilters3 ? true : false}
        defaultValue={"Select"}
                            >
         <MenuItem value={""} >
             <ListItemText primary="Select Gender" />
         </MenuItem>
         {
            gender && gender.map((data)=>(
                <MenuItem value={data.value_id} >
                    <ListItemText primary={data.value} />
                </MenuItem>
            ))
         }
                               
        </Select>
        </FormControl>

    </Col> */}
          </Row>
        </div>
      ) : null}
      <Backdrop
        className={classes.backdrop}
        open={openBackdrop}
        // onClick={loading ? null : handleCloseBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      {reportSelect === '205' ? (
        <>
          {fetchDistrict.length > 0 && showDistrict ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        District
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Registered
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchDistrict.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() => talukHandler(row.district_id)}
                        >
                          {row.district === null ? 'NA' : row.district}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() => handleCSV(row.district_id)}
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCount}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData}
                        filename={'Registartion Reports'}
                        ref={(el) => (excelRef.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVDistrictAll()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverall}
                headers={headersDistrict}
                filename="District Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchTaluk.length > 0 && showTaluk ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack('Taluk')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Taluk
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Registered
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchTaluk.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            circleHandler(row.district_id, row.taluk_id)
                          }
                        >
                          {row.taluk === null ? 'NA' : row.taluk}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVTaluk(row.district_id, row.taluk_id)
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCount2}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData}
                        filename={'Registration Reports'}
                        ref={(el) => (excelRef.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVTalukAll()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallTaluk}
                headers={headersTaluk}
                filename="Taluk Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchCircle.length > 0 && showCircle ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack('Circle')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Circle
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Registered
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchCircle.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          // onClick={() => talukHandler(row.district_id)}
                        >
                          {row.circle_name === null ? 'NA' : row.circle_name}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVCircle(
                                row.district_id,
                                row.taluk_id,
                                row.circle_id,
                              )
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCount3}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData}
                        filename={'Registartion Reports'}
                        ref={(el) => (excelRef.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVCircleAll()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallCircle}
                headers={headersCircle}
                filename="Circle Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}
        </>
      ) : null}

      {/* Renewal Grid */}

      {reportSelect === '216' ? (
        <>
          {fetchDistrict2.length > 0 && showDistrict2 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        District
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Renewed
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchDistrict2.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() => talukHandler2(row.district_id)}
                        >
                          {row.district === null ? 'NA' : row.district}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData2}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef2.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() => handleCSV2(row.district_id)}
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountRen}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData2}
                        filename={'Registartion Reports'}
                        ref={(el) => (excelRef2.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVDistrictAll2()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverall2}
                headers={headersDistrict2}
                filename="District Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchTaluk2.length > 0 && showTaluk2 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack2('Taluk')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Taluk
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Renewed
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchTaluk2.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            circleHandler2(row.district_id, row.taluk_id)
                          }
                        >
                          {row.taluk === null ? 'NA' : row.taluk}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData2}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef2.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVTaluk2(row.district_id, row.taluk_id)
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountRen2}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData2}
                        filename={'Registration Reports'}
                        ref={(el) => (excelRef2.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVTalukAll2()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallTaluk2}
                headers={headersTaluk2}
                filename="Taluk Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchCircle2.length > 0 && showCircle2 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack2('Circle')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Circle
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Labour Renewed
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchCircle2.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          // onClick={() => talukHandler(row.district_id)}
                        >
                          {row.circle_name === null ? 'NA' : row.circle_name}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_labours}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData2}
                            filename={'Registartion Reports'}
                            ref={(el) => (excelRef2.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVCircle2(
                                row.district_id,
                                row.taluk_id,
                                row.circle_id,
                              )
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountRen3}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData2}
                        filename={'Registartion Reports'}
                        ref={(el) => (excelRef2.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVCircleAll2()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallCircle2}
                headers={headersCircle2}
                filename="Circle Registration Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}
        </>
      ) : null}

      {reportSelect === '218' ? (
        <>
          {
            //Schemes Table
            showSchemes && fetchSchemes.length > 0 ? (
              <TableContainer component={Paper}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: '20px',
                  }}
                >
                  <Table
                    sx={{ minWidth: 650 }}
                    style={{ width: '100%' }}
                    aria-label="simple table"
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ fontWeight: 'bold' }}>
                          SL.No
                        </TableCell>
                        <TableCell style={{ fontWeight: 'bold' }}>
                          Scheme Name
                        </TableCell>

                        <TableCell
                          style={{ fontWeight: 'bold' }}
                          align="center"
                        >
                          Total Scheme
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {fetchSchemes.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                          }}
                        >
                          <TableCell>{index + 1}</TableCell>
                          <TableCell
                            component="th"
                            scope="row"
                            style={{ cursor: 'pointer' }}
                            onClick={() => districtHandler(row.scheme_id)}
                          >
                            {row.scheme_id === null ? 'NA' : row.scheme_name}
                          </TableCell>
                          <TableCell align="center">
                            {row.total_schemes}
                          </TableCell>

                          {/* <TableCell align="center">30</TableCell> */}
                        </TableRow>
                      ))}
                      <ButtonBase />
                    </TableBody>

                    <TableHead>
                      <TableCell></TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Total
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        {totalCountSchemeCount}
                      </TableCell>
                    </TableHead>
                  </Table>
                </div>
                <CSVLink
                  data={CSVOverallSchemeCount}
                  headers={headresSchemeCount}
                  filename="Schemes Extract"
                >
                  <div className="d-flex justify-content-center align-items-center my-3">
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={
                        <img src={Excel} width="20" height="20" alt="" />
                      }
                    >
                      Extract to CSV
                    </Button>
                  </div>
                </CSVLink>
              </TableContainer>
            ) : null
          }
          {fetchDistrict3.length > 0 && showDistrict3 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack3('District')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>
                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        District
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Schemes Applied
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchDistrict3.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() => talukHandler3(row.district_id)}
                        >
                          {row.district === null ? 'NA' : row.district}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_schemes}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData3}
                            filename={'Scheme Reports'}
                            ref={(el) => (excelRef3.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() => handleCSV3(row.district_id)}
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountScheme}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData3}
                        filename={'Scheme Reports'}
                        ref={(el) => (excelRef3.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVDistrictAll3()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverall3}
                headers={headersDistrict3}
                filename="District Schemes Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchTaluk3.length > 0 && showTaluk3 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack3('Taluk')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Taluk
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Schemes Applied
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchTaluk3.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          onClick={() =>
                            circleHandler3(row.district_id, row.taluk_id)
                          }
                        >
                          {row.taluk === null ? 'NA' : row.taluk}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_schemes}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData3}
                            filename={'Scheme Reports'}
                            ref={(el) => (excelRef3.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVTaluk3(row.district_id, row.taluk_id)
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountScheme2}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData3}
                        filename={'Scheme Reports'}
                        ref={(el) => (excelRef3.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVTalukAll3()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallTaluk3}
                headers={headersTaluk3}
                filename="Taluk Scheme Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}

          {fetchCircle3.length > 0 && showCircle3 ? (
            <TableContainer component={Paper}>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '20px',
                }}
              >
                <div style={{ margin: '10px 4px' }}>
                  <button
                    style={{
                      background: 'transparent',
                      outline: 'none',
                      border: 'none',
                    }}
                    onClick={() => handleBack3('Circle')}
                  >
                    <img alt="..." className="cal-icon hover-icn" src={back} />
                  </button>
                </div>

                <Table
                  sx={{ minWidth: 650 }}
                  style={{ width: '100%' }}
                  aria-label="simple table"
                >
                  <TableHead>
                    <TableRow>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        SL.No
                      </TableCell>
                      <TableCell style={{ fontWeight: 'bold' }}>
                        Circle
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Total Schemes Applied
                      </TableCell>

                      <TableCell style={{ fontWeight: 'bold' }} align="center">
                        Extract to CSV/Excel
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fetchCircle3.map((row, index) => (
                      <TableRow
                        key={index}
                        sx={{
                          '&:last-child td, &:last-child th': { border: 0 },
                        }}
                      >
                        <TableCell>{index + 1}</TableCell>
                        <TableCell
                          component="th"
                          scope="row"
                          style={{ cursor: 'pointer' }}
                          // onClick={() => talukHandler(row.district_id)}
                        >
                          {row.circle_name === null ? 'NA' : row.circle_name}
                        </TableCell>
                        <TableCell align="center">
                          {row.total_schemes}
                        </TableCell>

                        {/* <TableCell align="center">30</TableCell> */}

                        <TableCell align="center">
                          <CSVLink
                            data={reportData3}
                            filename={'Scheme Reports'}
                            ref={(el) => (excelRef3.current = el)}
                          ></CSVLink>

                          <Button
                            onClick={() =>
                              handleCSVCircle3(
                                row.district_id,
                                row.taluk_id,
                                row.circle_id,
                              )
                            }
                            size="small"
                            variant="contained"
                            color="success"
                            startIcon={
                              <img src={Excel} width="20" height="20" alt="" />
                            }
                          >
                            Download
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
                    <ButtonBase />
                  </TableBody>

                  <TableHead>
                    <TableCell></TableCell>
                    <TableCell style={{ fontWeight: 'bold' }}>Total</TableCell>
                    <TableCell style={{ fontWeight: 'bold' }} align="center">
                      {totalCountScheme3}
                    </TableCell>
                    <TableCell align="center">
                      <CSVLink
                        data={reportData3}
                        filename={'Scheme Reports'}
                        ref={(el) => (excelRef3.current = el)}
                      ></CSVLink>

                      <Button
                        onClick={() => handleCSVCircleAll3()}
                        size="small"
                        variant="contained"
                        color="success"
                        startIcon={
                          <img src={Excel} width="20" height="20" alt="" />
                        }
                      >
                        Download All
                      </Button>
                    </TableCell>
                  </TableHead>
                </Table>
              </div>
              <CSVLink
                data={CSVOverallCircle3}
                headers={headersCircle3}
                filename="Circle Scheme Extract"
              >
                <div className="d-flex justify-content-center align-items-center my-3">
                  <Button
                    variant="contained"
                    color="success"
                    startIcon={
                      <img src={Excel} width="20" height="20" alt="" />
                    }
                  >
                    Extract to CSV
                  </Button>
                </div>
              </CSVLink>
            </TableContainer>
          ) : null}
        </>
      ) : null}
    </>
  );
};

export default NEWReportsAdmin;
