import React, { useState } from 'react';
import {
  Container,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Button,
  Stack,
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import ReportDashboard from './ReportDashboard';
import { Col, Row } from 'react-bootstrap';
import {
  ListItemText,
  withStyles,
  Menu,
  CircularProgress,
  TextField,
  InputAdornment,
  Backdrop,
  makeStyles,
  FormControl,
} from '@material-ui/core';
import CountRegistrationReport from './CountRegistrationReport';
import CountRenewalReport from './CountRenewalReport';
import CountSchemeReport from './CountSchemeReport';

import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import CountRegistrationDrive from './CountRegistrationDrive';
import CountLabourOfficer from './CountLabourOfficer';

export default function CountReport() {
  const [showDistrictTable, setShowDistrictTable] = useState(true);
  const [showTalukTable, setShowTalukTable] = useState(false);
  const [showCircleTable, setShowCircleTable] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [reportSelect, setReportSelect] = useState('');
  const [showRegistrationReports, setRegistrationReports] = useState('');
  const [showRenewalReports, setRenewalReports] = useState('');
  const [showSchemeReports, setSchemeReports] = useState('');
  const [showTypeDropDown, setShowTypeDropDown] = useState(true);
  const [showDriveReports, setShowDriveReports] = useState('');
  const [showLowiseReports, setShowLowiseReports] = useState(false);

  const handleBack = () => {
    setShowDashboard(true);
    setShowDistrictTable(false);
    setShowTalukTable(false);
    setShowCircleTable(false);
  };

  const handleReportsType = async (selectedValue) => {
    console.log('Selected Reports Type:', selectedValue);

    if (selectedValue === 'Registration Reports') {
      setShowDashboard(false);
      setShowTypeDropDown(false);
      setRegistrationReports(true);
      setRenewalReports(false);
      setSchemeReports(false);
      console.log(1);
    } else if (selectedValue === 'Renewal Reports') {
      setShowDashboard(false);
      setShowTypeDropDown(false);
      setRegistrationReports(false);
      setSchemeReports(false);
      setRenewalReports(true);
      console.log(2);
    } else if (selectedValue === 'Scheme Reports') {
      setShowDashboard(false);
      setShowTypeDropDown(false);
      setRegistrationReports(false);
      setRenewalReports(false);
      setSchemeReports(true);
      console.log(3);
    } else if (selectedValue === 'New Registration Drive Reports') {
      setShowDashboard(false);
      setShowTypeDropDown(false);
      setRegistrationReports(false);
      setRenewalReports(false);
      setSchemeReports(false);
      setShowDriveReports(true);
      console.log(4);
    } else if (selectedValue === 'Labour officer Reports') {
      const newWindow = window.open(
        '/count-labour-officer',
        'CountLabourOfficer',
      );
      if (!newWindow) {
        console.error(
          'Unable to open new window. Please check your browser settings.',
        );
      }
    } else {
      setShowDashboard(false);
      setRegistrationReports(selectedValue === 'Registration Reports');
      setRenewalReports(selectedValue === 'Renewal Reports');
      setSchemeReports(selectedValue === 'Scheme Report');
      setShowDriveReports(selectedValue === 'New Registration Drive Reports');
      setShowLowiseReports(false);
    }
  };

  return (
    <>
      {!showDashboard && showTypeDropDown && (
        <div
          style={{
            padding: '10px 15px',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <button
            style={{
              background: 'transparent',
              outline: 'none',
              border: 'none',
            }}
            onClick={handleBack}
          >
            <img alt="..." className="cal-icon hover-icn" src={back} />
          </button>

          <Row className="d-flex justify-content-center align-items-center container-fluid">
            <Col xs={6} className="mb-2 mt-4">
              <FormControl
                variant="outlined"
                fullWidth
                className="formcontrol5"
              >
                <Select
                  className="select-marital"
                  style={{ border: '1px solid grey' }}
                  labelId="demo-simple-select-required-label"
                  id="demo-simple-select-required"
                  value={reportSelect || ''}
                  name="Reports"
                  displayEmpty
                  onChange={(event) => setReportSelect(event.target.value)}
                >
                  <MenuItem value="">
                    <ListItemText primary="Select Reports type" />
                  </MenuItem>
                  <MenuItem value={'Registration Reports'}>
                    <ListItemText primary={'Registration Reports'} />
                  </MenuItem>
                  <MenuItem value={'Renewal Reports'}>
                    <ListItemText primary={'Renewal Reports'} />
                  </MenuItem>
                  <MenuItem value={'Scheme Report'}>
                    <ListItemText primary={'Scheme Reports'} />
                  </MenuItem>
                  <MenuItem value={'New Registration Drive Reports'}>
                    <ListItemText primary={'New Registration Drive Reports'} />
                  </MenuItem>
                  <MenuItem value={'Labour officer Reports'}>
                    <ListItemText primary={'Officer Wise Reports'} />
                  </MenuItem>
                </Select>
              </FormControl>
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="success"
              onClick={() => handleReportsType(reportSelect)}
            >
              Submit
            </Button>
          </div>
        </div>
      )}

      {showDashboard ? <ReportDashboard /> : null}
      {showRegistrationReports ? <CountRegistrationReport /> : null}
      {showRenewalReports ? <CountRenewalReport /> : null}
      {showSchemeReports ? <CountSchemeReport /> : null}
      {showDriveReports ? <CountRegistrationDrive /> : null}
      {showLowiseReports ? <CountLabourOfficer /> : null}
    </>
  );
}
