import "date-fns";
import axios from "axios";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  RadioGroup,
  FormControlLabel,
  Radio,
  ListItemText,
  TextareaAutosize,
  ListItemIcon,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import checkGreen from "assets/images/Tick-01.png";
import { AddCircleRounded } from "@material-ui/icons";
// import folder from 'assets/images/folder (3).svg'
import folder from "assets/images/Folderwhite-01-01.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import closeicon from "assets/images/closeIcon.svg";
import MomentUtils from "@date-io/moment";
import aadhar from "assets/images/id-card.svg";
import checkgreen from "assets/images/Subtraction 1.svg";
import checkgrey from "assets/images/Subtraction 2.svg";
// import close from 'assets/images/close.png'
import close from "assets/images/close-01.svg";
// import mobile from 'assets/images/Icon awesome-mobile-alt.svg'
import mobile from "assets/images/Phone 1-01.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  addLabour90DaysDetails,
  getApplicationNo,
  getCatalogDetails,
  getCityDetails,
  getDistrictDetails,
  getFile,
  getInspectorDetails,
  getStateDetails,
  getTalukDetails,
  getUnionDetails,
  getUser,
  getUserRegistrationDetails,
  getVillageDetails,
  SERVER,
  update90DaysData,
} from "store/actions/user.actions";
import { showToast } from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import { Translate } from "react-redux-i18n";
import {
  binaryToBase64,
  dataURLtoFile,
  getTokenCookie,
  hexToBase64,
} from "utils/tools";
import validator from "validator";
import Select2 from "react-select";
import Moment from "react-moment";
import html2canvas from "html2canvas";
import { uploadFile } from "store/actions";
import jsPDF from "jspdf";
import SimpleBackdrop from "components/loadingScreen/Backdrop";

const LabourUnionForm = (props) => {
  const { users, selectedOption, union, handleDropdownSelect, handleChange } =
    props;

  var registeration_no =
    selectedOption && selectedOption.registeration_no !== null
      ? selectedOption.registeration_no
      : "";
  var spoke_person =
    selectedOption && selectedOption.spoke_person !== null
      ? selectedOption.spoke_person
      : "";
  var phone_no =
    selectedOption && selectedOption.phone_no !== null
      ? selectedOption.phone_no
      : "";

  return (
    <>
      <Col xs={12} md={6} className='issuerTypeForm'>
        <p className='mt-5 mb-2'>
          <Translate value='unionName' />
        </p>

        <FormControl fullWidth>
          <Select2
            options={union}
            className='select-marital'
            onChange={handleDropdownSelect}
            isSearchable={true}
            value={selectedOption && selectedOption}
            placeholder='Search Union'
          />
        </FormControl>

        <p className='mt-4 mb-2'>
          <Translate value='unionRegistrationNumber' />
        </p>
        <FormControl fullWidth>
          <TextField
            name='unionRegistrationNumber'
            onChange={handleChange}
            value={registeration_no}
            // disabled
          />
        </FormControl>

        <p className='mt-4 mb-2'>
          <Translate value='nameofIssuer' />
        </p>
        <FormControl fullWidth>
          <TextField
            name='unionIssuerName'
            onChange={handleChange}
            value={spoke_person}
            // disabled
          />
        </FormControl>

        <p className='mt-4 mb-2'>
          <Translate value='mobileNumberIfAvailable' />
        </p>
        <FormControl fullWidth>
          <TextField
            name='unionPhone'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={mobile} alt='...' className='phone-icon' />
                  <p className='countrycode'>+91</p>
                </InputAdornment>
              ),
            }}
            value={phone_no}
            // disabled
          />
        </FormControl>
      </Col>
    </>
  );

  // }
};

const NintyDays = (props) => {
  const myRef = React.createRef();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const [allValues, setAllValues] = React.useState({
    noOfEmployementDetails: 1,
    selectedDate: null,
    imgfile: "",
    documentName: "",
    openDatePicker: false,
    issuerType: "",
    presentDetailsGiven: false,
    presentDetailsGivenIndex: "",

    labour_incpector_id: "",
    circle_id: "",

    employerDetails: [],
    states: [],
    districts: [],
    taluks: [],
    citys: [],
    villages: [],
    natureOfWorks: [],
    issuerTypes: [],
    // presentDetailsGiven: false,
    // presentDetailsGivenIndex: '',
    // issuedDate: "",
    documentUploadResponse: "",

    employerDetail: "",
    workPlace: "",
    mobileNumberIncharge: "",
    nameOfContractor: "",
    contractorCompanyName: "",
    state: "",
    district: "",
    taluka: "",
    town: "",
    village: "",
    pinCode: "",
    natureOfWork: "",
    typeOfIssuer: "",
    IssuedDate: new Date(),
    inspectorName: "",
    inspectorCircle: "",
    inspectorPhone: "",
    unionName: "",
    unionRegistrationNumber: "",
    unionIssuerName: "",
    unionPhone: "",
    secretaryIssuerName: "",
    secretaryIssuerPlace: "",
    secretaryIssuerPhone: "",
    constructionIssuerName: "",
    constructionIssuerDesignation: "",
    constructionIssuerPhone: "",
    contractorNameError: false,
    contractorCompanyNameError: false,
    issuerPhoneError: false,
    pinError: false,
    mobileNumberInchargeError: false,
    issuerPlaceError: false,
    issuerDesignationError: false,
    issuerName: "",
    issuerPhone: "",
    issuerDesignation: "",
    issuerPlace: "",
  });

  const [AckLetterImg, setAckLetterImg] = React.useState("");

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (AckLetterImg !== "" && AckLetterImg !== null) {
      let formdata = {
        file: AckLetterImg,
        userId: users.user.id,
        fileType: "reg_ack",
      };

      console.error("formdata: " + JSON.stringify(formdata));
      dispatch(uploadFile(formdata, "acknowledgement"));
    }
  }, [AckLetterImg]);

  useEffect(() => {
    let dataToSubmitAppNo = {
      id: users.user.id,
    };
    dispatch(getApplicationNo(dataToSubmitAppNo));

    // setLoading(false)
    // setSubmitSuccess(true)
    // setAllValues({
    //     ...allValues,
    //     finalSubmitted: true
    // })
    // callSaveAcknowledgementId()
  }, []);

  const handleUserSubmitDetails = async (
    uploadResponse,
    uploadResponsePaySlip,
    uploadSelfDeclarationForm
  ) => {
    setLoading(true);
    console.log("LOADINDSTATE", loading);
    let employers = [
      {
        catalog_value_employement_status_id: allValues.employerDetail,
        contractor_company_name: allValues.contractorCompanyName,
        contractor_name: allValues.nameOfContractor,
        mobile_country_code: "+91",
        mobile_no: allValues.mobileNumberIncharge,
        catalog_value_nature_of_work: allValues.natureOfWork,
        state_id: allValues.state,
        site_address: allValues.workPlace,
        district_id: allValues.district,
        taluk_id: allValues.taluka,
        city_town_gram_panchayat_id: allValues.town,
        village_area_id: allValues.village,
        pin_code: allValues.pinCode,
      },
    ];

    var labour_incpector_id = "";
    var labour_union_id = "";
    var spoke_person = "";
    var address = "";
    var phone_no = "";
    var circle_id = "";
    var designation = "";

    if (users.inspectorDetails !== null && users.inspectorDetails.length > 0) {
      labour_incpector_id = users.inspectorDetails[0].department_user_id;
      phone_no = users.inspectorDetails[0].mobile_no;
      circle_id = users.inspectorDetails[0].circle_id;
    }
    if (
      users.nintydays_details.issuerType === 55 &&
      users.inspectorDetails !== null &&
      users.inspectorDetails.length > 0
    ) {
      labour_incpector_id = users.inspectorDetails[0].department_user_id;
      phone_no = users.inspectorDetails[0].mobile_no;
      circle_id = users.inspectorDetails[0].circle_id;
    }

    if (
      allValues.issuerType === 56 &&
      users.unionDetails !== null &&
      users.unionDetails.length > 0
    ) {
      labour_union_id = selectedOption.labour_union_id;
      spoke_person = selectedOption.spoke_person;
      address = selectedOption.address;
      phone_no = selectedOption.phone_no;
    }
    if (allValues.issuerType === 57) {
      spoke_person = allValues.issuerName;
      address = allValues.issuerPlace;
      phone_no = allValues.issuerPhone;
    }
    if (allValues.issuerType === 58) {
      spoke_person = allValues.issuerName;
      phone_no = allValues.issuerPhone;
      designation = allValues.issuerDesignation;
    }

    console.log(
      "labour_union_id",
      labour_union_id,
      spoke_person,
      address,
      phone_no
    );

    const dataToSubmitToAPI = {
      user_id: users.user.id,
      board_id: users.user.board_id,
      catalog_value_type_of_issuer_id: allValues.issuerType,
      issue_date: allValues.selectedDate,
      document_uploaded_id: "-",
      files: uploadResponse,
      payslips: uploadResponsePaySlip,
      selfDeclaration: uploadSelfDeclarationForm,
      labour_incpector_id: labour_incpector_id,
      labour_union_id: labour_union_id,
      issuer_name: spoke_person,
      issuer_place: address,
      issuer_designation: designation,
      mobile_country_code: "+91",
      mobile_no: phone_no && phone_no.length > 0 ? phone_no : "N/A",
      catalog_value_registration_status_id: 45,
      circle_id: circle_id,
      employers: employers,
    };

    const apiCall = await axios
      .post(SERVER + "/user/renewal/apply", dataToSubmitToAPI, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        console.log(res);
        if (res.data.success === true) {
          console.log("Error_check");
          console.log(res.data.data[0].Error);
          if (res.data.data[0].Error === undefined) {
            setLoading(false);
            showToast("SUCCESS", "Renewal Applied Sucessfully");
            printDocument();
            props.history.push("/dashboard-user");
          } else {
            setLoading(false);
            showToast("ERROR", res.data.data[0].Error);
          }
        }
      });
  };

  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [selectedfiles, setselectedfiles] = React.useState({});
  const [selectedPaySlipfiles, setselectedPaySlipfiles] = React.useState({});
  const [fileuploadresults, setfileuploadresults] = React.useState({});
  const [fileuploadPaySlipresults, setfileuploadPaySlipresults] =
    React.useState({});

  const [selectedSelfDeclarationForm, setSelectedSelfDeclarationForm] =
    useState({});
  const [
    fileuploadSelfDeclarationResults,
    setFileUploadSelfDeclarationResults,
  ] = React.useState({});

  const [phone, setPhone] = React.useState("");
  const [mobError, setMobError] = React.useState("");
  const [pinError, setPinError] = React.useState("");
  const [empError, setEmpError] = React.useState("");
  const [compError, setCompError] = React.useState("");

  const [issuedDate, setIssuedDate] = React.useState("");
  const [union, setUnion] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    console.log("allValues: ", allValues);
  }, [allValues]);

  useEffect(() => {
    setAllValues({ ...allValues, phoneNumber: phone });

    dispatch(getStateDetails());
  }, [phone]);

  useEffect(() => {
    if (
      fileuploadresults !== null &&
      fileuploadresults.length > 0 &&
      // (fileuploadPaySlipresults !== null && fileuploadPaySlipresults.length > 0) &&
      fileuploadSelfDeclarationResults !== null &&
      fileuploadSelfDeclarationResults.length > 0
    ) {
      if (
        fileuploadPaySlipresults !== null &&
        fileuploadPaySlipresults.length > 0
      ) {
        var filelist1 = [];
        fileuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };
          filelist1.push(filejson);
        });
        console.log("filelist: " + JSON.stringify(filelist1));
        // setfilelist(filelist)
        // submit90Days(filelist)

        var filelist3 = [];
        fileuploadSelfDeclarationResults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };
          filelist3.push(filejson);
        });
        var filelist2 = [];
        fileuploadPaySlipresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };
          filelist2.push(filejson);
        });
        handleUserSubmitDetails(filelist1, filelist2, filelist3);
      } else {
        var filelist90 = [];
        fileuploadresults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };
          filelist90.push(filejson);
        });
        // console.log("filelist: " + JSON.stringify(filelist90))
        // setfilelist(filelist)
        // submit90Days(filelist)

        var filelistSelf = [];
        fileuploadSelfDeclarationResults.forEach((i) => {
          var filejson = {
            file_id: i.image.fileId,
            file_name: i.image.fileType,
          };
          filelistSelf.push(filejson);
        });

        handleUserSubmitDetails(filelist90, null, filelistSelf);
      }
    }
  }, [
    fileuploadresults,
    fileuploadPaySlipresults,
    fileuploadSelfDeclarationResults,
  ]);

  useEffect(() => {
    // console.log("in useEffect []")
    if (users.generate_otp !== null) {
      setPhone(users.generate_otp.data.phone_number);
      dispatch(getUser(users.generate_otp.data.phone_number));
    } else if (users.generate_otp_registration !== null) {
      setPhone(users.generate_otp_registration.data.phone_number);
      dispatch(getUser(users.generate_otp_registration.data.phone_number));
    } else {
      props.history.push("/");
    }

    dispatch(getFile("delete"));
    dispatch(addLabour90DaysDetails("delete"));

    console.error(
      "in useEffect allValues.noOfEmployementDetails: " +
        allValues.noOfEmployementDetails
    );

    // if (users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.employer_details !== undefined) {
    //     for (var i = 0; i < users.getUserRegistrationDetails.employer_details.length; i++) {
    //         addNewEmploymentDetail(i,"saved")
    //         console.error("in useEffect [addNewEmploymentDetail]: i=" + i)
    //     }
    // }
    // else

    //  if (allValues.noOfEmployementDetails > 1) {
    //     for (var i = 1; i < allValues.noOfEmployementDetails; i++) {
    //         addNewEmploymentDetail(i)
    //         console.error("in useEffect [addNewEmploymentDetail]: i=" + i)
    //     }
    // }
  }, []);

  useEffect(() => {
    // console.log("in useEffect [users.user]")
    if (users.user !== null && users.user.board_id !== null) {
      let dataForRegDetails = {
        key: "user_id",
        value: users.user.id,
        board_id: users.user.board_id,
        procedure_name: "all",
      };
      dispatch(getUserRegistrationDetails(dataForRegDetails));

      let dataForCatalog = {
        board_id: users.user.board_id,
        catalog_name: "Employer details",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog));

      let dataForCatalog2 = {
        board_id: users.user.board_id,
        catalog_name: "Nature of Work",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog2));

      let dataForCatalog3 = {
        board_id: users.user.board_id,
        catalog_name: "Type of Issuer",
      };
      // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      dispatch(getCatalogDetails(dataForCatalog3));

      // let dataForCatalog4 = { "board_id": users.user.board_id, "catalog_name": "Education" };
      // // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
      // dispatch(getCatalogDetails(dataForCatalog4))
    }
  }, [users.user]);

  useEffect(() => {
    axios.get(`${SERVER}/global/get_union_details?board_id=1`).then((res) => {
      console.log(res.data.data);
      const newData = res.data.data.map((item) => ({
        ...item,
        value: item.labour_union_id,
        label: item.name,
      }));
      setUnion(newData);
    });
  }, []);

  const handleDropdownSelect = (selectedOption) => {
    console.log("SelectedUnion", selectedOption);
    setSelectedOption(selectedOption);
  };

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });
  };

  const handleChange = (event) => {
    console.error(
      "in event.target.name === 'employerDetails'-> event.target.value" +
        event.target.value
    );
    if (event.target.name === "nameOfContractor") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          contractorNameError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          contractorNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerNameError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          issuerNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "unionIssuerName") {
      setSelectedOption({
        ...selectedOption,
        spoke_person: event.target.value,
      });
    } else if (event.target.name === "unionRegistrationNumber") {
      setSelectedOption({
        ...selectedOption,
        registeration_no: event.target.value,
      });
    } else if (event.target.name === "unionPhone") {
      setSelectedOption({
        ...selectedOption,
        phone_no: event.target.value,
      });
    } else if (event.target.name === "issuerDesignation") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerDesignationError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          issuerDesignationError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerPlace") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          issuerPlaceError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          issuerPlaceError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "contractorCompanyName") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isAlpha(event.target.value, "en-US", { ignore: " " }) &&
        event.target.value.length !== 0 &&
        event.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          contractorCompanyNameError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "issuerPhone") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length == 10
      ) {
        setAllValues({
          ...allValues,
          issuerPhoneError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          issuerPhoneError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "mobileNumberIncharge") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length == 10
      ) {
        setAllValues({
          ...allValues,
          mobileNumberInchargeError: false,
          [event.target.name]: event.target.value,
        });
      } else {
        setAllValues({
          ...allValues,
          mobileNumberInchargeError: true,
          [event.target.name]: event.target.value,
        });
      }
    } else if (event.target.name === "pinCode") {
      // console.error("validate:" + validator.isNumeric(e.target.value))
      // console.error("phone.length:" + e.target.value.length)

      if (
        validator.isNumeric(event.target.value) &&
        event.target.value.length !== 0 &&
        event.target.value.length === 6
      ) {
        setAllValues({
          ...allValues,

          [event.target.name]: event.target.value,
          pinError: false,
        });
      } else {
        setAllValues({
          ...allValues,

          [event.target.name]: event.target.value,
          pinError: true,
        });
      }
    } else {
      if (event.target.name === "state") {
        funcDispatchDistrict(event.target.value);
      }
      if (event.target.name === "district") {
        funcDispatchTaluk(event.target.value);
      }
      if (event.target.name === "taluka") {
        funcDispatchCity(event.target.value);
      }
      if (event.target.name === "town") {
        funcDispatchVillage(event.target.value);
      }

      setAllValues({
        ...allValues,
        [event.target.name]: event.target.value,
      });
    }
  };

  const handleDateChange = (date) => {
    console.log(date);
    console.log(new Date(date));
    setAllValues({
      ...allValues,
      selectedDate: new Date(date),
    });
  };

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    setAllValues({ ...allValues, selectedDate: issuedDate });
  }, [issuedDate]);

  useEffect(() => {
    if (users.unionDetails !== null) {
      setAllValues({
        ...allValues,
        unionName: selectedOption,
        unionRegistrationNumber: users.unionDetails[0].registeration_no,
        unionIssuerName: users.unionDetails[0].spoke_person,
        unionPhone: users.unionDetails[0].phone_no,
      });
    }
  }, [users.unionDetails]);

  useEffect(() => {
    if (users.inspectorDetails !== null) {
      var labour_incpector_id = "";
      var circle_id = "";
      var inspectorName = allValues.inspectorName;
      // if (users.inspectorDetails[0].first_name !== null) {
      //     inspectorName = users.inspectorDetails[0].first_name + " "
      // }
      // if (users.inspectorDetails[0].middle_name !== null) {
      //     inspectorName = inspectorName + users.inspectorDetails[0].middle_name
      // }
      // if (users.inspectorDetails[0].last_name !== null) {
      //     inspectorName = inspectorName + users.inspectorDetails[0].last_name
      // }

      // if (users.inspectorDetails !== null && users.inspectorDetails.length>0) {
      //     labour_incpector_id = users.inspectorDetails[0].department_user_id
      //     // phone_no = users.inspectorDetails[0].mobile_no
      //     circle_id = users.inspectorDetails[0].circle_id
      // }
      if (
        users.inspectorDetails !== null &&
        users.inspectorDetails.length > 0
      ) {
        if (users.inspectorDetails[0].first_name !== null) {
          inspectorName = users.inspectorDetails[0].first_name + " ";
        }
        if (users.inspectorDetails[0].middle_name !== null) {
          inspectorName = inspectorName + users.inspectorDetails[0].middle_name;
        }
        if (users.inspectorDetails[0].last_name !== null) {
          inspectorName = inspectorName + users.inspectorDetails[0].last_name;
        }

        if (
          users.inspectorDetails !== null &&
          users.inspectorDetails.length > 0
        ) {
          labour_incpector_id = users.inspectorDetails[0].department_user_id;
          //     // phone_no = users.inspectorDetails[0].mobile_no
          circle_id = users.inspectorDetails[0].circle_id;
        }
      }

      setAllValues({
        ...allValues,
        inspectorName: inspectorName,
        inspectorCircle: users.inspectorDetails[0].circle,
        inspectorPhone: users.inspectorDetails[0].mobile_no,

        labour_incpector_id: users.inspectorDetails[0].department_user_id,
        circle_id: users.inspectorDetails[0].circle_id,
      });
    }
  }, [users.inspectorDetails]);

  useEffect(() => {
    // console.log("in useEffect [dob]: "+JSON.stringify(dob))
    if (
      users.user !== null &&
      users.user.board_id !== undefined
      // &&
      // allValues.presentDetailsGivenIndex !== undefined &&
      // allValues.presentDetailsGivenIndex !== ""
    ) {
      dispatch(
        getInspectorDetails(
          users.user.board_id,
          users.getUserRegistrationDetails.address_details[0].village_area_id
        )
      );

      if (allValues.issuerType === 55) {
        getInspectorDetails(
          users.user.board_id,
          users.getUserRegistrationDetails.address_details[0].village_area_id
        );
      }

      if (allValues.issuerType === 56) {
        dispatch(getUnionDetails(users.user.board_id));
      }
    }
  }, [allValues.issuerType]);

  const handleFileChange = (event) => {
    console.log("event.target.files: ");
    console.log(event.target.files);

    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );
      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handlePaySlipFileChange = (event) => {
    console.log("event.target.files: ");
    console.log(event.target.files);

    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedPaySlipfiles).concat(
        Array.from(event.target.files)
      );
      setselectedPaySlipfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleSelfDeclaration = (event) => {
    console.log("event.target.files: ");
    console.log(event.target.files);

    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedSelfDeclarationForm).concat(
        Array.from(event.target.files)
      );
      setSelectedSelfDeclarationForm(joined);
    }

    setloadingfiles(false);
  };

  const removefile = (index) => {
    setloadingfiles(true);
    console.log("removefile index: " + index);
    // selectedfiles.splice(1,index)
    selectedfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  const removefile2 = (index) => {
    setloadingfiles(true);
    console.log("removefile index: " + index);
    // selectedfiles.splice(1,index)
    selectedPaySlipfiles.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  const removefile3 = (index) => {
    setloadingfiles(true);
    console.log("removefile index: " + index);
    // selectedfiles.splice(1,index)
    selectedSelfDeclarationForm.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  useEffect(() => {
    console.error(
      "in useEffect upload_file: " + JSON.stringify(users.upload_file)
    );
    if (
      users.upload_file !== null &&
      users.upload_file.success !== undefined &&
      users.upload_file.success === true
    ) {
      if (users.upload_file.userFileName === "90_days_certificate") {
        setAllValues({
          ...allValues,
          documentUploadResponse: users.upload_file.image,
        });

        console.error(
          "documentUploadResponse:" +
            JSON.stringify(allValues.documentUploadResponse)
        );

        // submit90Days(users.upload_file.image)
      }
    } else if (
      users.upload_file !== null &&
      users.upload_file.success === undefined
    ) {
      showToast(
        "ERROR",
        "Cannot upload the certificate image. Please try later."
      );
    }
  }, [users.upload_file]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.catalog_details !== null &&
      users.catalog_details.error !== undefined &&
      users.catalog_details.error !== null &&
      users.catalog_details.error === true
    ) {
      // console.error("ERROR: " + JSON.stringify(users.catalog_details.errors))
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Employer details"
    ) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setEmployerDetails(users.catalog_details)
      setAllValues({
        ...allValues,
        employerDetail:
          users.catalog_details[0].value === "Present"
            ? users.catalog_details[0].value_id
            : users.catalog_details[1].value_id,
        employerDetails: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Nature of Work"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setNatureOfWork(users.catalog_details)
      setAllValues({
        ...allValues,
        natureOfWorks: users.catalog_details,
      });
    } else if (
      users.catalog_details !== null &&
      users.catalog_details[0]["name"] === "Type of Issuer"
    ) {
      // console.error("elseif category: "+JSON.stringify(users.catalog_details))
      // setIssuerTypes(users.catalog_details)
      setAllValues({
        ...allValues,
        issuerTypes: users.catalog_details,
      });
    }
    // else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Education") {
    //     // console.error("elseif category: "+JSON.stringify(users.catalog_details))
    //     setEducations(users.catalog_details)
    // }
  }, [users.catalog_details]);

  useEffect(() => {
    // setStates(users.state_details)
    setAllValues({
      ...allValues,
      states: users.state_details,
    });
  }, [users.state_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.district_details !== null &&
      users.district_details.error !== undefined &&
      users.district_details.error !== null &&
      users.district_details.error === true
    ) {
      console.error("ERROR: " + JSON.stringify(users.district_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.district_details !== null) {
      setAllValues({
        ...allValues,
        districts: users.district_details,
      });
    }
  }, [users.district_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.taluk_details !== null &&
      users.taluk_details.error !== undefined &&
      users.taluk_details.error !== null &&
      users.taluk_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.taluk_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.taluk_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setTaluk(users.taluk_details)
      setAllValues({
        ...allValues,
        taluks: users.taluk_details,
      });
    }
  }, [users.taluk_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.city_details !== null &&
      users.city_details.error !== undefined &&
      users.city_details.error !== null &&
      users.city_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.city_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.city_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setCity(users.city_details)
      setAllValues({
        ...allValues,
        citys: users.city_details,
      });
    }
  }, [users.city_details]);

  useEffect(() => {
    // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
    if (
      users.village_details !== null &&
      users.village_details.error !== undefined &&
      users.village_details.error !== null &&
      users.village_details.error === true
    ) {
      console.log("ERROR: " + JSON.stringify(users.village_details.errors));
      // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
    } else if (users.village_details !== null) {
      // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
      // setVillage(users.village_details)
      setAllValues({
        ...allValues,
        villages: users.village_details,
      });
    }
  }, [users.village_details]);

  const funcDispatchDistrict = (data) => {
    console.error("in funcDispatchDistrict [data]:" + data);
    dispatch(getDistrictDetails(data));
  };
  const funcDispatchTaluk = (data) => {
    // console.error("in funcDispatchTaluk [index]:" + index)
    console.error("in funcDispatchTaluk [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getTalukDetails(data));
    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };
  const funcDispatchCity = (data) => {
    // console.error("in funcDispatchCity [index]:" + index)
    console.error("in funcDispatchCity [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getCityDetails(data));

    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };
  const funcDispatchVillage = (data) => {
    // console.error("in funcDispatchVillage [index]:" + index)
    console.error("in funcDispatchVillage [data]:" + data);
    // if(users.user !== null && users.user.board_id !== null && allValues.employmentsList[data].state !== ""){
    dispatch(getVillageDetails(data));

    // dispatch(getDistrictDetails(currentEmployeeState))
    // }
  };

  const LabourInspectorForm = () => {
    return (
      <Col xs={6} className='issuerTypeForm'>
        <p className='mt-5 mb-2'>Labour Inspector Name</p>
        <FormControl fullWidth className='formcontrol1'>
          <TextField
            disabled
            name='inspectorName'
            onChange={handleChange}
            value={allValues.inspectorName}
          />
        </FormControl>

        <p className='mt-4 mb-2'>Circle of the Labour Inspector </p>
        <FormControl fullWidth>
          <TextField
            disabled
            name='inspectorCircle'
            onChange={handleChange}
            value={allValues.inspectorCircle}
          />
        </FormControl>

        <p className='mt-4 mb-2'>Mobile Number of the Labour Inspector </p>
        <FormControl fullWidth>
          <TextField
            disabled
            name='inspectorPhone'
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <img src={mobile} alt='...' className='phone-icon' />
                  <p className='countrycode'>+91</p>
                </InputAdornment>
              ),
            }}
            value={allValues.inspectorPhone}
          />
        </FormControl>
      </Col>
    );
  };

  const [handleLabourUnion, setHandleLabourUnion] = React.useState("");

  const handleLabourChange = (e) => {
    console.log(e.target.value, "calculator");

    const unionId = e.target.value;
    setHandleLabourUnion(e.target.value);
  };

  // const LabourUnionForm = () => {

  //     // if(users.unionDetails !== null){

  //     console.log("SelectedUNion:0001",selectedOption);
  //     console.log(users.unionDetails);
  //     console.log("Founded:",selectedOption && users.unionDetails.find(values => values.name === selectedOption.name));
  //     const selectedUnionData = users.unionDetails !== null && users.unionDetails !== "Network Error" && selectedOption ?
  //         users.unionDetails.find(values => values.name === selectedOption.name)
  //         : undefined

  //     // var name = users.nintydays_details.unionName
  //     var registeration_no = selectedUnionData !== undefined ? selectedUnionData.registeration_no : ""
  //     var spoke_person = selectedUnionData !== undefined ? selectedUnionData.spoke_person : ""
  //     var phone_no = selectedUnionData !== undefined ? selectedUnionData.phone_no : ""

  //     console.log(selectedUnionData)

  //     // var name = users.nintydays_details.unionName
  //     // var registeration_no = users.nintydays_details.unionRegistrationNumber
  //     // var spoke_person = users.nintydays_details.unionIssuerName
  //     // var phone_no = users.nintydays_details.unionPhone

  //     // if (users.unionDetails !== null) {
  //     //     if (users.unionDetails[0].name !== null) {
  //     //         name = users.unionDetails[0].name
  //     //     }
  //     //     if (users.unionDetails[0].registeration_no !== null) {
  //     //         registeration_no = users.unionDetails[0].registeration_no
  //     //     }
  //     //     if (users.unionDetails[0].spoke_person !== null) {
  //     //         spoke_person = users.unionDetails[0].spoke_person
  //     //     }
  //     //     if (users.unionDetails[0].phone_no !== null) {
  //     //         phone_no = users.unionDetails[0].phone_no
  //     //     }

  //     // }

  //     return (
  //         <>
  //             <Col xs={12} md={6} className="issuerTypeForm">
  //                 <p className="mt-5 mb-2"><Translate value="unionName" /></p>

  //                 <FormControl fullWidth>
  //                     {/* <Select
  //                         className="select-marital"
  //                         value={handleLabourUnion}
  //                         name="issuerType"
  //                         displayEmpty
  //                         onChange={handleLabourChange}
  //                     >
  //                         <MenuItem value="">
  //                             <ListItemText primary="-Select-" />
  //                         </MenuItem>

  //                         {
  //                             users.unionDetails !== "Network Error" && users.unionDetails !== null && users.unionDetails.map(union => {
  //                                 return <MenuItem value={union.name}>
  //                                     <ListItemText primary={union.name} />
  //                                 </MenuItem>
  //                             })
  //                         }

  //                     </Select> */}
  //                     <Select2
  //                     options={union}
  //                     className="select-marital"
  //                     onChange={handleDropdownSelect}
  //                     isSearchable={true}
  //                     value={selectedOption && selectedOption}
  //                     placeholder="Search Union"
  //                     />
  //                 </FormControl>

  //                 <p className="mt-4 mb-2"><Translate value="unionRegistrationNumber" /></p>
  //                 <FormControl fullWidth>
  //                     <TextField
  //                         name="unionRegistrationNumber"
  //                         onChange={handleChange}
  //                         value={registeration_no}
  //                         // disabled
  //                     />
  //                 </FormControl>

  //                 <p className="mt-4 mb-2"><Translate value="nameofIssuer" /></p>
  //                 <FormControl fullWidth>
  //                     <TextField
  //                         name="unionIssuerName"
  //                         onChange={handleChange}
  //                         value={spoke_person}
  //                         // disabled
  //                     />
  //                 </FormControl>

  //                 <p className="mt-4 mb-2"><Translate value="mobileNumberIfAvailable" /></p>
  //                 <FormControl fullWidth>
  //                     <TextField
  //                         name="unionPhone"
  //                         onChange={handleChange}
  //                         InputProps={{
  //                             startAdornment:
  //                                 <InputAdornment position="start">
  //                                     <img src={mobile} alt="..." className="phone-icon" />
  //                                     <p className="countrycode">+91</p>
  //                                 </InputAdornment>,
  //                         }}
  //                         value={phone_no}
  //                         // disabled
  //                     />
  //                 </FormControl>
  //             </Col>
  //         </>
  //     )

  //     // }
  // }

  const SecretaryForm = () => {
    return (
      <>
        <Col xs={6} className='issuerTypeForm'>
          <p className='mt-4 mb-2'>Name of Issuer</p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Issuer Name'
              name='secretaryIssuerName'
              // onChange={handleChange}
              onChange={handleChange}
              value={allValues.issuerName}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>Place of Issuer</p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Place of Issuer'
              name='secretaryIssuerPlace'
              onChange={handleChange}
              value={allValues.issuerPlace}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>Mobile Number (If Available)</p>
          <FormControl fullWidth>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={mobile} alt='...' className='phone-icon' />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              placeholder='Enter Mobile Number of Issuer'
              name='secretaryIssuerPhone'
              onChange={handleChange}
              value={allValues.issuerPhone}
              disabled
            />
          </FormControl>
        </Col>
      </>
    );
  };

  const EmployerConstructionForm = () => {
    return (
      <>
        <Col xs={6} className='issuerTypeForm'>
          <p className='mt-4 mb-2'>Name of Issuer</p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Name of Issuer'
              name='constructionIssuerName'
              onChange={handleChange}
              value={allValues.issuerName}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>Designation of Issuer</p>
          <FormControl fullWidth>
            <TextField
              placeholder='Enter Designation of Issuer'
              name='constructionIssuerDesignation'
              onChange={handleChange}
              value={allValues.issuerDesignation}
              disabled
            />
          </FormControl>

          <p className='mt-4 mb-2'>Mobile Number (If Available)</p>
          <FormControl fullWidth>
            <TextField
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <img src={mobile} alt='...' className='phone-icon' />
                    <p className='countrycode'>+91</p>
                  </InputAdornment>
                ),
              }}
              placeholder='Enter Mobile Number of Issuer'
              name='constructionIssuerPhone'
              onChange={handleChange}
              value={allValues.issuerPhone}
              disabled
            />
          </FormControl>
        </Col>
      </>
    );
  };

  const submitButtonPressed = () => {
    if (
      allValues.employerDetail === "" ||
      allValues.workPlace === "" ||
      allValues.pinCode === "" ||
      allValues.state === "" ||
      allValues.district === "" ||
      allValues.town === "" ||
      allValues.taluka === "" ||
      allValues.village === "" ||
      allValues.natureOfWork === "" ||
      allValues.issuerType === "" ||
      allValues.selectedDate === null ||
      allValues.mobileNumberInchargeError ||
      Object.keys(selectedfiles).length == 0
    ) {
      showToast("WARN", "Please Fill All the Required Fields to Continue!");
      console.log("selectedFIle", selectedfiles);
    } else {
      if (selectedfiles !== null && selectedfiles.length > 0) {
        uploadNintyDays();
      } else {
        handleUserSubmitDetails(
          JSON.parse(
            users.getUserRegistrationDetails.certificate_details[0]
              .document_uplaod_files_xml
          )
        );
      }
      if (selectedPaySlipfiles !== null && selectedPaySlipfiles.length > 0) {
        uploadPaySlip();
      }
      if (
        selectedSelfDeclarationForm !== null &&
        selectedSelfDeclarationForm.length > 0
      ) {
        uploadSelfDeclaration();
      } else {
        handleUserSubmitDetails(
          JSON.parse(
            users.getUserRegistrationDetails.certificate_details[0]
              .document_upload_payslip_files_xml
          )
        );
      }
    }
  };

  const uploadNintyDays = () => {
    let promises = [];

    selectedfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setfileuploadresults(results);
          });
        }
      };
    });
  };

  const uploadPaySlip = () => {
    let promises = [];

    selectedPaySlipfiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedPaySlipfiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setfileuploadPaySlipresults(results);
          });
        }
      };
    });
  };

  const uploadSelfDeclaration = () => {
    let promises = [];

    selectedSelfDeclarationForm.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedSelfDeclarationForm.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setFileUploadSelfDeclarationResults(results);
          });
        }
      };
    });
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  //Object Created

  const init_90_days_details = {
    employmentsList: [
      {
        id: 0,
        employerDetails: "",
        workplaceSiteaddress: "",
        inchargePhoneNumber: "",
        contractorDeveloperName: "",
        contractorCompanyName: "",
        pincode: "",
        state: "",
        districtWorkplace: "",
        cityGram: "",
        talukWorkplace: "",
        villageWard: "",
        natureWorkplace: "",
      },
    ],
    noOfEmployementDetails: 1,
    selectedDate: null,
    imgfile: "",
    documentName: "",
    openDatePicker: false,
    issuerType: "",
    presentDetailsGiven: false,
    presentDetailsGivenIndex: "",
    inspectorName: "",
    inspectorCircle: "",
    inspectorPhone: "",
    unionName: "",
    unionRegistrationNumber: "",
    unionIssuerName: "",
    unionPhone: "",
    issuerName: "",
    issuerPlace: "",
    issuerPhone: "",
    issuerDesignation: "",
    documentUploadResponse: null,
  };

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.addLabour90DaysCertDetails !== null &&
      users.addLabour90DaysCertDetails.success !== undefined &&
      users.addLabour90DaysCertDetails.success !== null &&
      users.addLabour90DaysCertDetails.success === true
    ) {
      showToast("SUCCESS", "90 Days Certificate Details Saved Successfully!");

      dispatch(update90DaysData(init_90_days_details));
      dispatch(addLabour90DaysDetails("delete"));

      if (users.profile_details.editmode) {
        props.saveEditButtonPressed();
      } else {
        props.nextButtonPressed();
      }
    } else if (
      users.addLabour90DaysCertDetails !== null &&
      users.addLabour90DaysCertDetails.success !== undefined &&
      users.addLabour90DaysCertDetails.success !== null &&
      users.addLabour90DaysCertDetails.success === "false"
    ) {
      showToast("ERROR", "Error Saving Address Details!");
    }
    // else if(users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success === undefined){
    //     showToast("ERROR","Error Saving Personal Details!")
    // }
  }, [users.addLabour90DaysCertDetails]);

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    input.style.display = "block";
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("renewal-acknowledgement.pdf");
        window.open(pdf.output("bloburl"), "_blank");
      })
      .then(() => {
        input.style.display = "none";
      });
  };

  var appNo = "";
  if (users.getApplicationNo !== null && users.getApplicationNo !== "") {
    if (users.getApplicationNo.length > 0)
      appNo = users.getApplicationNo[0].application_no;
    else appNo = "";
  }

  var applicantName = "";
  if (users.user.first_name !== "" && users.user.first_name !== null) {
    applicantName += users.user.first_name + " ";
  }
  if (users.user.middle_name !== "" && users.user.middle_name !== null) {
    applicantName += users.user.middle_name + " ";
  }
  if (users.user.last_name !== "" && users.user.last_name !== null) {
    applicantName += users.user.last_name;
  }

  if (users.getUserRegistrationDetails.application_details) {
    var date = new Date();
    // var date = users.getUserRegistrationDetails.application_details[0].application_date;
  }

  if (
    users.getUserRegistrationDetails !== null &&
    users.getUserRegistrationDetails !== undefined &&
    users.getUserRegistrationDetails.address_details !== undefined &&
    users.getUserRegistrationDetails.address_details.length > 0
  ) {
    var presentAddress = "";
    if (
      users.getUserRegistrationDetails.address_details[0].door_no !== "" &&
      users.getUserRegistrationDetails.address_details[0].door_no !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].door_no + " ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].street_name !== "" &&
      users.getUserRegistrationDetails.address_details[0].street_name !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].street_name + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].land_mark !== "" &&
      users.getUserRegistrationDetails.address_details[0].land_mark !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].land_mark + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].panhayat_city_town;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].district !== "" &&
      users.getUserRegistrationDetails.address_details[0].district !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].district;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].taluk !== "" &&
      users.getUserRegistrationDetails.address_details[0].taluk !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].taluk;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].state !== "" &&
      users.getUserRegistrationDetails.address_details[0].state !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].state;
      presentAddress += x + " - ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].pin_code !== "" &&
      users.getUserRegistrationDetails.address_details[0].pin_code !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].pin_code;
    }

    var villageWard = "";
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      villageWard += x;
    }

    var mobile = "";
    if (
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== "" &&
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== null
    ) {
      mobile += users.getUserRegistrationDetails.personal_details[0].mobile_no;
    }

    var occupation = "";
    if (
      users.getUserRegistrationDetails.employer_details !== undefined &&
      users.getUserRegistrationDetails.employer_details !== "" &&
      users.getUserRegistrationDetails.employer_details.length > 0
    ) {
      var y = users.getUserRegistrationDetails.employer_details
        .map(function (e) {
          return e.catalog_value_employement_status_id;
        })
        .indexOf(25);

      console.log("y=" + y);
      var x =
        users.getUserRegistrationDetails.employer_details[y].nature_of_work;

      occupation += x;
    }

    var officerIncharge = "";
    if (users.getUserRegistrationDetails.certificate_details.length > 0) {
      // officerIncharge = users.getUserRegistrationDetails.certificate_details[0].inspector_first_name.concat(users.getUserRegistrationDetails.certificate_details[0].inspector_middle_name.concat(users.getUserRegistrationDetails.certificate_details[0].inspector_last_name))

      officerIncharge = [
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_first_name,
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_middle_name,
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_last_name,
      ].join(" ");
    }

    var circleName = "";
    if (
      users.getUserRegistrationDetails.certificate_details !== undefined &&
      users.getUserRegistrationDetails.certificate_details.length > 0 &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== "" &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== null
    ) {
      circleName =
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_circle;
    }
  }

  // useEffect(() => {
  //     // console.error("in useeffect users.getUserRegistrationDetails.length: "+users.getUserRegistrationDetails.length)
  //     // console.error("in useeffect users.getUserRegistrationDetails: "+JSON.stringify(users.getUserRegistrationDetails))
  //     // console.error("users.getUserRegistrationDetails.tab_name: "+users.getUserRegistrationDetails.tab_name)
  //     if (users.getUserRegistrationDetails !== null) {

  //         if (users.getUserRegistrationDetails.employer_details !== undefined &&
  //             users.getUserRegistrationDetails.employer_details.length > 0) {

  //             var membersListData = []
  //             var thispresentDetailsGiven = ""
  //             var thispresentDetailsGivenIndex = ""

  //             for (var i = 0; i < users.getUserRegistrationDetails.employer_details.length; i++) {

  //                 var val = users.getUserRegistrationDetails.employer_details[i]
  //                 var Data = {
  //                     "id": i,
  //                     "employerDetails": val.catalog_value_employement_status_id,
  //                     "workplaceSiteaddress": val.site_address,
  //                     "inchargePhoneNumber": val.mobile_no,
  //                     "contractorDeveloperName": val.contractor_name,
  //                     "contractorCompanyName": val.contractor_company_name,
  //                     "pincode": val.pin_code,
  //                     "state": val.state_id,
  //                     "districtWorkplace": val.district_id,
  //                     "cityGram": val.city_town_gram_panchayat_id,
  //                     "talukWorkplace": val.taluk_id,
  //                     "villageWard": val.village_area_id,
  //                     "natureWorkplace": val.catalog_value_nature_of_work
  //                 }
  //                 membersListData.push(Data)

  //                 funcDispatchDistrict(i, val.state_id)
  //                 funcDispatchTaluk(i, val.district_id)
  //                 funcDispatchCity(i, val.taluk_id)
  //                 funcDispatchVillage(i, val.city_town_gram_panchayat_id)

  //                 console.error("val.catalog_value_employement_status_id: " + val.catalog_value_employement_status_id)
  //                 console.error("allValues.presentDetailsGivenIndex: " + allValues.presentDetailsGivenIndex)

  //                 if (val.catalog_value_employement_status_id === 25 && allValues.presentDetailsGivenIndex === "") {
  //                     console.error("inside if: make present true")
  //                     thispresentDetailsGiven = true
  //                     thispresentDetailsGivenIndex = i
  //                 }

  //             }

  //             var documentName = ""
  //             var selectedDate = ""
  //             var issuerType = ""
  //             var inspectorName = ""
  //             var inspectorCircle = ""
  //             var inspectorPhone = ""

  //             var unionName = ""
  //             var unionRegistrationNumber = ""
  //             var unionIssuerName = ""
  //             var unionPhone = ""

  //             var issuerName = ""
  //             var issuerPlace = ""
  //             var issuerPhone = ""
  //             var issuerDesignation = ""

  //             var issuedDate = ""

  //             if (users.getUserRegistrationDetails.certificate_details.length > 0) {

  //                 var val1 = users.getUserRegistrationDetails.certificate_details[0]

  //                 documentName = val1.document_uploaded_id
  //                 selectedDate = val1.issue_date
  //                 issuerType = val1.catalog_value_type_of_issuer_id
  //                 inspectorName = `${val1.inspector_first_name} ${val1.inspector_middle_name} ${val1.inspector_last_name} `
  //                 inspectorCircle = val1.inspector_circle_id
  //                 inspectorPhone = val1.inspector_mobile_no

  //                 unionName = val1.labour_union_name
  //                 unionRegistrationNumber = val1.labour_union_registration_no
  //                 unionIssuerName = val1.labour_union_spoke_person
  //                 unionPhone = val1.aslabour_union_phone_no

  //                 issuerName = val1.issuer_name
  //                 issuerPlace = val1.issuer_place
  //                 issuerPhone = val1.mobile_no
  //                 issuerDesignation = val1.issuer_designation

  //                 issuedDate = val1.issue_date

  //                 dispatch(getFile(
  //                     users.getUserRegistrationDetails.certificate_details[0].document_uploaded_id,
  //                     "90_days_certificate")
  //                 )
  //             }

  //             setAllValues({
  //                 ...allValues,

  //                 noOfEmployementDetails: users.getUserRegistrationDetails.employer_details.length,

  //                 selectedDate: selectedDate,
  //                 // imgfile: allValues.imgfile,
  //                 documentName: documentName,
  //                 // openDatePicker: allValues.openDatePicker,
  //                 // employmentsList: allValues.employmentsList,
  //                 issuerType: issuerType,

  //                 inspectorName: inspectorName,
  //                 inspectorCircle: inspectorCircle,
  //                 inspectorPhone: inspectorPhone,

  //                 unionName: unionName,
  //                 unionRegistrationNumber: unionRegistrationNumber,
  //                 unionIssuerName: unionIssuerName,
  //                 unionPhone: unionPhone,

  //                 issuerName: issuerName,
  //                 issuerPlace: issuerPlace,
  //                 issuerPhone: issuerPhone,
  //                 issuerDesignation: issuerDesignation,

  //                 issuedDate: allValues.dob,
  //                 documentUploadResponse: allValues.documentUploadResponse,

  //                 presentDetailsGiven: thispresentDetailsGiven,
  //                 presentDetailsGivenIndex: thispresentDetailsGivenIndex,

  //                 employmentsList: membersListData
  //             })

  //         }
  //     }
  // }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    if (users.getFile !== null) {
      sessionStorage.setItem("90_days_certificate", users.getFile.data);
      // setimagesavedinlocal(true)
      // dispatch(updateGetFile(""))
    }
  }, [users.getFile]);

  const typeOfIssuerFunction = () => {
    console.log(allValues.typeOfIssuer);
    if (allValues.typeOfIssuer == 55) {
      return <LabourInspectorForm />;
    } else if (allValues.typeOfIssuer == 56) {
      return <LabourUnionForm />;
    } else if (allValues.typeOfIssuer == 57) {
      return <SecretaryForm />;
    } else if (allValues.typeOfIssuer == 58) {
      return <EmployerConstructionForm />;
    } else {
      return null;
    }
  };

  return (
    <>
      <Row className='top-div'>
        {loading ? <SimpleBackdrop /> : null}
        <Row className='topbar-row'>
          <Col xs={12} md={8} lg={8} className='top-bar-col'>
            <div className='logo-div-profile'>
              <a href='/dashboard-user'>
                <img id='logo' src={logo} alt='...' className='logo-img' />
              </a>
              <h1 className='logo-text'>
                Karnataka Building & Other Construction Workers Welfare Board
                (KBOCWWB)
                <br />
                Government of Karnataka
              </h1>
            </div>
          </Col>
          {/* <Col xs={12} md={4} lg={4} style={{ display: "flex" }}>
                            <div style={{display:"flex",alignItems:"center", marginRight:"20px", marginTop:"3px"}}>
                                <Badge badgeContent={allnotifications!==undefined && allnotifications.totalDocs} color="primary">
                                <img onClick={toggleDrawer(true)} src={NotificationIcon} alt="image"/>
                                </Badge>
                            </div>

                            <div className= "usericon-header-logout">
                                <img alt="..." src={usericon} className="usericon-img" />

                                <p>{users.user !== undefined && users.user !== null && users.user.first_name}</p>               
                                <Tooltip title="Logout" placement="top-start" arrow interactive>
                                    <img alt="..." src={logout} className="logout-img" onClick={()=>{props.history.push("/")}}/>
                                </Tooltip>
                            </div>
                        </Col> */}
        </Row>
      </Row>

      <Row className='m-0'>
        <Row className='family-title-row'>
          <Col
            xs={12}
            className='profile-title'
            style={{ marginBottom: "-20px" }}
          >
            <h1>Details of 90 Days Work Certificate & Employer Details</h1>
          </Col>
        </Row>
        <Row className='family-title-row'>
          <Col xs={12} className='family-col-4'>
            <>
              {/* <img alt="..." src={close} className="closebtn" onClick={() => removeEmployerDetail(0)} /> */}

              <Row className={"form-row p-0 employer-card row" + 0}>
                <Col xs={12} md={6} className='nintydays-col-1'>
                  <Row className='form-inner-card-plain pb-3'>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Employer/Owner Details* :</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.employerDetail}
                          name='employerDetail'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.employerDetails !== null &&
                            allValues.employerDetails.length > 0 &&
                            allValues.employerDetails.map(
                              (index) =>
                                index.value === "Present" && (
                                  <MenuItem value={index.value_id}>
                                    <ListItemText primary={index.value} />
                                  </MenuItem>
                                )
                            )}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Workplace/Site Address*</p>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextareaAutosize
                          variant='outlined'
                          multiline
                          rows={1}
                          // rowsMax={4}
                          // defaultValue="Default Value"
                          placeholder='Enter Your Workplace/Site Address'
                          name='workPlace'
                          style={{ height: "46px" }}
                          value={allValues.workPlace}
                          onChange={handleChange}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>
                        Mobile Number ( Incharge Person )*
                      </p>
                      <FormControl fullWidth className='formcontrol2'>
                        <TextField
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  src={mobile}
                                  alt='...'
                                  className='phone-icon'
                                />
                                <p className='countrycode'>+91</p>
                              </InputAdornment>
                            ),
                          }}
                          variant='outlined'
                          placeholder='987xxxxxxx'
                          name='mobileNumberIncharge'
                          value={allValues.mobileNumberIncharge}
                          onChange={handleChange}
                          // helperText={allValues.mobileNumberInchargeError ? "Please enter in correct format" : ""}
                          // error = {allValues.mobileNumberInchargeError}
                          error={mobError}
                          helperText={mobError}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/gi,
                              ""
                            );
                            if (e.target.value.length === 0) {
                              setMobError("Please enter mobile number");
                            } else if (e.target.value.length < 10) {
                              setMobError(
                                "Mobile number should contain 10 digits"
                              );
                            } else {
                              setMobError("");
                            }
                          }}
                          inputProps={{ minLength: 0, maxLength: 10 }}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={6} className='nintydays-col-2'>
                  <Row className='form-inner-card-plain pb-3'>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Name of Employer/Owner* :</p>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Your Employer/Owner Name'
                          name='nameOfContractor'
                          value={allValues.nameOfContractor}
                          onChange={handleChange}
                          error={empError}
                          helperText={empError}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z]/gi,
                              ""
                            );
                            if (e.target.value.length === 0) {
                              setEmpError("Please enter employer name");
                            } else {
                              setEmpError("");
                            }
                          }}
                          inputProps={{ minLength: 0, maxLength: 30 }}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>
                        Employer/Owner Company Name* :
                      </p>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Employer/Owner Company NAme'
                          name='contractorCompanyName'
                          value={allValues.contractorCompanyName}
                          onChange={handleChange}
                          error={compError}
                          helperText={compError}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^A-Za-z]/gi,
                              ""
                            );
                            if (e.target.value.length === 0) {
                              setCompError("Please enter company name");
                            } else {
                              setCompError("");
                            }
                          }}
                          inputProps={{ minLength: 0, maxLength: 30 }}
                        />
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>State*</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          name='state'
                          value={allValues.state}
                          onChange={handleChange}
                          displayEmpty
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.states !== undefined &&
                            allValues.states !== null &&
                            allValues.states.length > 0 &&
                            allValues.states.map(
                              (i) =>
                                i.name === "Karnataka" && (
                                  //    <>
                                  <MenuItem value={i.id}>
                                    <ListItemText
                                      primary={i.short_name + " | " + i.name}
                                    />
                                  </MenuItem>
                                )
                              // </>
                            )}
                          {allValues.addressSameCheckbox == true ? (
                            <MenuItem value={allValues.residentialData.state}>
                              {allValues.residentialData.state}
                            </MenuItem>
                          ) : null}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={6} className='nintydays-col-3'>
                  <Row className='form-inner-card-plain pt-0'>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>
                        Select Your District of the Workplace* :
                      </p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.district}
                          name='district'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.districts !== null &&
                            allValues.districts.length > 0 &&
                            allValues.districts.map((i) => (
                              <MenuItem value={i.id}>
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Town/City/Gram Panchayat* :</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol1'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.town}
                          name='town'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.citys !== null &&
                            allValues.citys.length > 0 &&
                            allValues.citys.map((i) => (
                              <MenuItem value={i.id}>
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Pincode* :</p>
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          placeholder='Enter Your Pincode'
                          name='pinCode'
                          value={allValues.pinCode}
                          onChange={handleChange}
                          error={pinError}
                          helperText={pinError}
                          onInput={(e) => {
                            e.target.value = e.target.value.replace(
                              /[^0-9]/gi,
                              ""
                            );
                            if (e.target.value.length === 0) {
                              setPinError("Please enter pincode");
                            } else if (e.target.value.length < 6) {
                              setPinError("Pin code must contain 6 digits");
                            } else {
                              setPinError("");
                            }
                          }}
                          inputProps={{ minLength: 0, maxLength: 6 }}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </Col>

                <Col xs={12} md={6} className='nintydays-col-4'>
                  <Row className='form-inner-card-plain pt-0'>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Taluk of the Workplace* :</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.taluka}
                          name='taluka'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.taluks !== null &&
                            allValues.taluks.length > 0 &&
                            allValues.taluks.map((i) => (
                              <MenuItem value={i.id}>
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Village/Ward Circle* :</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol1'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.village}
                          name='village'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.villages !== undefined &&
                            allValues.villages.length > 0 &&
                            allValues.villages.map((i) => (
                              // <MenuItem value={i.ref_district_code}>
                              <MenuItem value={i.id}>
                                <ListItemText primary={i.name} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col xs={12} md={12}>
                      <p className='mt-3 mb-2'>Nature of the Workplace* :</p>
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          value={allValues.natureOfWork}
                          name='natureOfWork'
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='-Select-' />
                          </MenuItem>
                          {allValues.natureOfWorks !== null &&
                            allValues.natureOfWorks.length > 0 &&
                            allValues.natureOfWorks.map((i) => (
                              <MenuItem value={i.value_id}>
                                <ListItemText primary={i.value} />
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          </Col>
        </Row>

        {/* <Row className="form-row">
                    <Col xs={12} className="addMemberCol mt-4 "
                        // onClick={addNewEmploymentDetail}
                        onClick={() => addNewEmploymentDetail()}
                    >
                        <AddCircleRounded /><p>Add More Emplyment Details</p>
                    </Col>
                </Row> */}

        {/* {
                    allValues.presentDetailsGiven ? */}
        <>
          <Row className='nintydays-cert-title-row'>
            <Col xs={12} className='profile-title'>
              <h1 style={{ color: "#ca0027" }}>
                90 Days Work Certificate Details
              </h1>
            </Col>
          </Row>

          <Row className='form-row ninydays-row'>
            <Col xs={12} className='nintydays-col-5'>
              <Row className='form-inner-card-plain'>
                <Col xs={12} md={6}>
                  <p className='mt-2 mb-2'>Type of Issuer*</p>
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      value={allValues.issuerType}
                      name='issuerType'
                      displayEmpty
                      onChange={handleChange}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {allValues.issuerTypes !== null &&
                        allValues.issuerTypes.length > 0 &&
                        allValues.issuerTypes.map((i) => (
                          <MenuItem value={i.value_id}>
                            <ListItemText primary={i.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
                <Col xs={12} md={6}>
                  <p className='mt-2 mb-2'>Issued Date* :</p>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment().subtract(90, "days")}
                      maxDate={new Date()}
                      onClick={() => setPickerStatus(true)}
                      onClose={() => setPickerStatus(false)}
                      open={allValues.openDatePicker}
                      className='datepicker'
                      margin='normal'
                      format='D/MM/yyyy'
                      value={allValues.selectedDate}
                      // onChange={(date) => handleDateChange(date) }
                      onChange={(date) => {
                        setIssuedDate(date);
                      }}
                      placeholder='DD/MM/YYYY'
                      // KeyboardButtonProps={{
                      //     'aria-label': 'change date',
                      // }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>

                {
                  {
                    55: <LabourInspectorForm />,
                    56: (
                      <LabourUnionForm
                        users={users}
                        selectedOption={selectedOption}
                        union={union}
                        handleDropdownSelect={handleDropdownSelect}
                        handleChange={handleChange}
                      />
                    ),
                    57: (
                      <Col xs={12} md={6} className='issuerTypeForm'>
                        <p className='mt-4 mb-2'>
                          <Translate value='nameofIssuer' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder='Enter Issuer Name'
                            name='issuerName'
                            // onChange={handleChange}
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            value={allValues.issuerName}
                            helperText={
                              allValues.issuerNameError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerNameError}
                          />
                        </FormControl>

                        <p className='mt-4 mb-2'>
                          <Translate value='placeofIssuer' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder='Enter Place of Issuer'
                            name='issuerPlace'
                            onChange={handleChange}
                            value={allValues.issuerPlace}
                            helperText={
                              allValues.issuerPlaceError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerPlaceError}
                          />
                        </FormControl>

                        <p className='mt-4 mb-2'>
                          <Translate value='mobileNumberIfAvailable' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <img
                                    src={mobile}
                                    alt='...'
                                    className='phone-icon'
                                  />
                                  <p className='countrycode'>+91</p>
                                </InputAdornment>
                              ),
                            }}
                            placeholder='Enter Mobile Number of Issuer'
                            name='issuerPhone'
                            onChange={handleChange}
                            value={allValues.issuerPhone}
                            helperText={
                              allValues.issuerPhoneError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerPhoneError}
                          />
                        </FormControl>
                      </Col>
                    ),
                    58: (
                      <Col xs={12} md={6} className='issuerTypeForm'>
                        <p className='mt-4 mb-2'>
                          <Translate value='nameofIssuer' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder='Enter Name of Issuer'
                            name='issuerName'
                            onChange={handleChange}
                            value={allValues.issuerName}
                            helperText={
                              allValues.issuerNameError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerNameError}
                          />
                        </FormControl>

                        <p className='mt-4 mb-2'>
                          <Translate value='designationofIssuer' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            placeholder='Enter Designation of Issuer'
                            name='issuerDesignation'
                            onChange={handleChange}
                            value={allValues.issuerDesignation}
                            helperText={
                              allValues.issuerDesignationError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerDesignationError}
                          />
                        </FormControl>

                        <p className='mt-4 mb-2'>
                          <Translate value='mobileNumberIfAvailable' />
                        </p>
                        <FormControl fullWidth>
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position='start'>
                                  <img
                                    src={mobile}
                                    alt='...'
                                    className='phone-icon'
                                  />
                                  <p className='countrycode'>+91</p>
                                </InputAdornment>
                              ),
                            }}
                            placeholder='Enter Mobile Number of Issuer'
                            name='issuerPhone'
                            onChange={handleChange}
                            value={allValues.issuerPhone}
                            helperText={
                              allValues.issuerPhoneError
                                ? "Please enter in correct format"
                                : ""
                            }
                            error={allValues.issuerPhoneError}
                          />
                        </FormControl>
                      </Col>
                    ),
                  }[allValues.issuerType]
                }
              </Row>
            </Col>

            <Col xs={12} className='nintydays-col-6'>
              {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
              <p className='mt-3 mb-2'>
                <Translate value='uploadYourDaysWorkingCertificate' />
              </p>

              <div className='browsebutton-outerdiv'>
                <div className='browsebutton2-div filesNames'>
                  {loadingfiles ? (
                    <></>
                  ) : (
                    selectedfiles !== null &&
                    selectedfiles.length > 0 &&
                    Array.from(selectedfiles).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='documentName'
                          value={i.name}
                          onChange={handleChange}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  alt='...'
                                  src={closeicon}
                                  className='removefilebtn'
                                  onClick={() => removefile(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))
                  )}
                </div>
                <div className='browsebutton2-div'>
                  <label htmlFor='upload-photo' className='browse-button btn2'>
                    <img alt='...' src={folder} /> Browse
                  </label>
                  <input
                    type='file'
                    name='photo'
                    id='upload-photo'
                    onChange={handleFileChange}
                    multiple
                  />
                </div>
              </div>

              <p className='mt-3 mb-2'>
                <Translate value='uploadPaySlip' />
              </p>

              <div className='browsebutton-outerdiv'>
                <div className='browsebutton2-div filesNames'>
                  {loadingfiles ? (
                    <></>
                  ) : (
                    selectedPaySlipfiles !== null &&
                    selectedPaySlipfiles.length > 0 &&
                    Array.from(selectedPaySlipfiles).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='documentName'
                          value={i.name}
                          onChange={handleChange}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  alt='...'
                                  src={closeicon}
                                  className='removefilebtn'
                                  onClick={() => removefile2(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))
                  )}
                </div>
                <div className='browsebutton2-div'>
                  <label htmlFor='upload-photo2' className='browse-button btn2'>
                    <img alt='...' src={folder} /> Browse
                  </label>
                  <input
                    type='file'
                    name='photo2'
                    id='upload-photo2'
                    onChange={handlePaySlipFileChange}
                    multiple
                  />
                </div>
              </div>

              <p className='mt-3 mb-2'>
                <Translate value='selfdeclaration' />
              </p>

              <div className='browsebutton-outerdiv'>
                <div className='browsebutton2-div filesNames'>
                  {loadingfiles ? (
                    <></>
                  ) : (
                    selectedSelfDeclarationForm !== null &&
                    selectedSelfDeclarationForm.length > 0 &&
                    Array.from(selectedSelfDeclarationForm).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='documentName'
                          value={i.name}
                          onChange={handleChange}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  alt='...'
                                  src={closeicon}
                                  className='removefilebtn'
                                  onClick={() => removefile3(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))
                  )}
                </div>
                <div className='browsebutton2-div'>
                  <label htmlFor='upload-photo3' className='browse-button btn2'>
                    <img alt='...' src={folder} /> Browse
                  </label>
                  <input
                    type='file'
                    name='photo3'
                    id='upload-photo3'
                    onChange={handleSelfDeclaration}
                    multiple
                  />
                </div>
              </div>

              <p className='mt-4 instructions-ration'>
                <span>
                  <Translate value='instructions' />*
                </span>
                <br />
                <Translate value='formatsupportingdocumentshouldbeinJPEGPNGorPDF' />
                <br />
                <Translate value='sizecontains500KBto2MB' />
              </p>
            </Col>
          </Row>
        </>
        {/* :
                        null

                } */}

        {/* <Row className="button-inside-form-row mb-5">
                    <Col xs={12} className="next-back-button-row mt-4 family-col-8">
                        <Link to="#" onClick={props.backButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="danger" className="back-button" >
                                Back
                                </Button>
                        </Link>
                        <Link to="#"
                            onClick={submitButtonPressed}
                            style={{ flexGrow: "0.5" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Submit
                            </Button>
                        </Link>
                    </Col>
                </Row> */}

        <Row className='button-inside-form-row mb-5'>
          <Col xs={12} className='next-back-button-row mt-4 '>
            <Link to='/dashboard-user' style={{ flexGrow: "0.5" }}>
              <Button variant='danger' className='back-button'>
                Back
              </Button>
            </Link>
            <Link
              to='#'
              onClick={submitButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button
                variant='outline-primary'
                className='next-button withback'
                // onClick={handleUserSubmitDetails}
              >
                Submit
              </Button>
            </Link>
          </Col>
        </Row>
      </Row>

      <div>
        {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
        <div
          id='divToPrint'
          className='mt-4'
          style={{
            backgroundColor: "#fff",
            width: "210mm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            // justifyContent: 'center',
            display: "none",
            // border: '2px solid black',
          }}
        >
          <div className='logo-div-ack'>
            <a href='/dashboard-user'>
              <img id='logo' src={logo} alt='...' className='logo-img' />
            </a>
            <h1 className='logo-text'>
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ
              (ಕಕಇನಿಕಾಕಮಂ) <br />
              Karnataka Building & Other Construction Workers Welfare Board
              (KBOCWWB) <br />
            </h1>
          </div>

          <Row className='ack-subtitle-row'>
            <Col xs={10} className='profile-title' style={{ display: "block" }}>
              <h1>
                <Translate
                  value='acknowledgement'
                  style={{ position: "relative", left: "60px" }}
                />
              </h1>
            </Col>
          </Row>

          <p className='ack-success'>
            <img
              src={checkGreen}
              style={{ height: "auto", width: "28px", marginRight: "10px" }}
            />
            {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
            <Translate value='applicationsubmitted' />
          </p>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                <Translate value='applicantName' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                {applicantName}
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p
                className='w-75'
                style={{ position: "relative", right: "60px" }}
              >
                <Translate value='applicationnumber' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>{appNo}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                <Translate value='applicationdate' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                <Moment format='DD MMMM YYYY'>{date}</Moment>
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p
                className='w-75'
                style={{ position: "relative", right: "60px" }}
              >
                <Translate value='requestedService' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>Renewal</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                <Translate value='applicantAddress' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                {presentAddress}
              </p>
            </Col>
          </Row>

          {/* <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="villageWardCircle2" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{villageWard}</p>
                                    </Col>
                                </Row> */}

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p
                className='w-75'
                style={{ position: "relative", right: "60px" }}
              >
                <Translate value='mobileNumber2' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>{mobile}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p
                className='w-75'
                style={{ position: "relative", right: "60px" }}
              >
                <Translate value='ackNatureOfWork' />
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                {occupation}
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                ಅರ್ಜಿ ಸ್ವೀಕೃತಗೊಂಡ ಕಛೇರಿಯ ವಿವರ / <br /> Details of the Office
                which Application Recevied
              </p>
            </Col>

            <Col xs={1}>
              <p style={{ position: "relative", right: "60px" }}>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p style={{ position: "relative", right: "60px" }}>
                Labour Inspector Office
              </p>
              <p style={{ position: "relative", right: "60px" }}>
                Circle: {circleName} <br /> District:{" "}
                {users.getUserRegistrationDetails.address_details &&
                  users.getUserRegistrationDetails.address_details[0].district}
              </p>
            </Col>
          </Row>

          {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>/
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

          {/* <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="circleName" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{circleName}</p>
                                    </Col>
                                </Row> */}

          {/* <Row className="ack-table-row">
                                    <Col xs={2}></Col>
                                    <Col xs={3} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}><Translate value="labourInspectorIncharge" /></p>
                                    </Col>

                                    <Col xs={1}>
                                        <p style={{position:'relative',right:'60px'}}>:</p>
                                    </Col>

                                    <Col xs={6} className="ack-col">
                                        <p style={{position:'relative',right:'60px'}}>{officerIncharge}</p>
                                    </Col>
                                </Row> */}

          <Row className='ack-table-row-last mt-2'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col-note'>
              <p>
                <Translate value='note2' />
              </p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>
                <Translate
                  value='acksubmit1'
                  style={{ position: "relative", bottom: "20px" }}
                />
              </p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>
                <Translate
                  value='acksubmit2'
                  style={{ position: "relative", bottom: "30px" }}
                />
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default NintyDays;
