import "date-fns";
import {
  FormControl,
  TextField,
  Select,
  MenuItem,
  ListItemText,
  CircularProgress,
  Popover,
  makeStyles,
  Backdrop,
  TextareaAutosize,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import checkGreen from "assets/images/Tick-01.png";
import InputLabel from "@material-ui/core/InputLabel";
import Moment from "react-moment";
import closeicon from "assets/images/closeIcon.svg";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
  getFile,
} from "store/actions/user.actions";
import { useParams } from "react-router-dom";
import {
  showToast,
  getTokenCookie,
  Required,
  dataURLtoFile,
} from "utils/tools";
import { AddCircleRounded, CheckCircle, Visibility } from "@material-ui/icons";
import validator from "validator";
import axios from "axios";
import moment from "moment";

import { Translate } from "react-redux-i18n";
import folder from "assets/images/Folderwhite-01-01.svg";
import usericon from "assets/images/Icon awesome-user.svg";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";

import Button2 from "@mui/material/Button";
import SchemeSanctionOrder from "./PreviewOption/SchemeSanctionOrder";
import { isNullOrUndefined } from "util";
import ECard from "./PreviewOption/ECard";
import ECardApproved from "./PreviewOption/ECardApproved";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { set } from "date-fns";
import { gridQuickFilterValuesSelector } from "@mui/x-data-grid";
import ButtonGroup from "@mui/material/ButtonGroup";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";

const SchemeForm = (props) => {
  const params = useParams();

  // Required params in allValues.props_this.location.

  /* the params are
   * params.boardID
   * params.scheme_avail_id
   * params.userid
   */

  // const _scheme_avail_id = params.fieldID;
  // const _userid = params.labourUserID;

  // const { userid, schemeId, schemeStatuss, scheme_name } = params;
  console.log(params, "PARAMS");
  console.log(props, "PROPSDATA");

  const pathName = props.location.pathname.split("/");
  // console.log('pathName', pathName);

  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    props_this: props,
    users: users.profile_details.users,
    natureOfWork: "",
    nameError: false,
    nameNomineeError: false,
    phoneError: false,
    phoneNomineeError: false,
    formcontrolnum: "",
    aadharnum1: "",
    aadharnum2: "",
    aadharnum3: "",
    aadhar: "",
    remarkTransfer: "",
    openDatePicker: false,

    states: [],
    districts: [],
    taluks: [],
    cities: [],
    villages: [],
    relations: [],
    naturesOfWork: [],
    state: "",
    district: "",
    taluk: "",
    city: "",
    village: "",
    relation: "",
    getUserDetails: null,
    get_dynamic_fields: null,
    marriage_assistance_for: "",
    name_of_person_married: "",
    gender: "",
    age: "",
    marriage_date: "",
    marriage_place: "",
    marriage_certifate: "",

    id_cardfileId: "",
    bank_accountfileId: "",
    employee_certfileId: "",
    marriage_certfileId: "",

    commentInspector: "",
    sanctioned_amount: "",
    amtError: false,
    upload_file: null,

    circleSelected: "",
    costError: "",
  });

  const [deliveryData, setdeliveryData] = useState([]);

  const [medNumeric, setMedNumeric] = useState(false);

  const classes = useStyles();

  const [selected_document_type, setselected_document_type] = useState("");

  const [circle_details, setcircle_details] = useState(null);

  const [anchorElIdCard, setAnchorElIdCard] = useState(null);

  const [anchorElemployee_cert, setAnchorElemployee_cert] = useState(null);
  const openemployee_cert = Boolean(anchorElemployee_cert);
  const idemployee_cert = openemployee_cert ? "simple-popover" : undefined;

  const [anchorElmarriage_cert, setAnchorElmarriage_cert] = useState(null);
  const openmarriage_cert = Boolean(anchorElmarriage_cert);
  const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;

  const [anchorElLabourPhoto, setAnchorElLabourPhoto] = useState(null);
  const openLabourPhoto = Boolean(anchorElLabourPhoto);
  const idLabourPhoto = openLabourPhoto ? "simple-popover" : undefined;

  const [anchorElSecondLevel, setAnchorElSecondLevel] = useState(null);
  const openSecondLevel = Boolean(anchorElSecondLevel);
  const idSecondLevel = openSecondLevel ? "simple-popover" : undefined;

  const [anchorElSelfDeclaration, setAnchorElSelfDeclaration] = useState(null);
  const openSelfDeclaration = Boolean(anchorElSelfDeclaration);
  const idSelfDeclaration = openSelfDeclaration ? "simple-popover" : undefined;

  const [anchorElMissingDoc, setAnchorElMissingDoc] = useState(null);
  const openMissingDoc = Boolean(anchorElMissingDoc);
  const idMissingDOc = openMissingDoc ? "simple-popover" : undefined;

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(true);

  const [get_scheme_status, setget_scheme_status] = useState({});

  const [accidentStatus, setAccidentStatus] = useState(null);
  const [accidentAmount, setAccidentAmount] = useState(null);
  const [verificationDoc, setverificationDoc] = useState(false);

  const [educationStatus, setEducationStatus] = useState(null);
  const [educationAmount, setEducationAmount] = useState(null);
  const [eduActualFees, setEduActualFees] = useState(null);
  const [disableAmount, setDisableAmount] = useState(false);

  const [schemeDetails, setschemeDetails] = useState({});
  const [schemeDetailsAll, setschemeDetailsAll] = useState({});
  const [UserDetails, setUserDetails] = useState({});
  const [Age, setAge] = useState({});
  const [Age60, setAge60] = useState({});
  const [AgeAtRegistration, setAgeAtRegistration] = useState({});
  const [RenewalDate, setRenewalDate] = useState({});
  const [YearsRegistered, setYearsRegistered] = useState({});

  const [rejectButton, setrejectButton] = useState(true);
  const [approveButton, setapproveButton] = useState(false);

  const [showtransferButton, setshowtransferButton] = useState(false);
  const [showtransferBackButton, setshowtransferBackButton] = useState(false);

  const [
    get_scheme_transfer_role_details,
    setget_scheme_transfer_role_details,
  ] = useState(null);

  const [opentransfer, setopentransfer] = useState(false);
  const [deliveryChildMale, setDeliveryChildMale] = useState(false);

  const [sanctionedAmountMed, setSanctionedAmountMed] = useState(0);

  const [labourPhotoBase64, setLabourPhotoBase64] = useState("");
  const [verificationDocBase64, setVerificationDocBase64] = useState("");
  const [employerCertificateBase64, setEmployerCertificateBase64] =
    useState("");
  const [employerCertificateID, setEmployerCertificateID] = useState("");
  const [documentBase64, setDocumentBase64] = useState(null);

  const [documentID, setDocumentID] = useState(null);
  const [documentImgBase64, setDocumentImgBase64] = useState(null);

  const [documentImgID, setDocumentImgID] = useState(null);

  const [selfDeclarationBase64, setSelfDeclarationBase64] = useState("");
  const [selfDeclarationID, setSelfDeclarationID] = useState("");

  const [missingDocBase64, setMissingDocBase64] = useState("");
  const [missingDocID, setMissingDocID] = useState("");

  const [openTransaction, setOpenTranscation] = useState(false);
  const [transactionPassword, setTransactionPassword] = useState("");
  const [approveReject, setApproveReject] = useState("");

  const [unionDetails, setUnionDetails] = useState([]);

  const [openPreview, setOpenPreview] = useState(false);

  const [schemeStatus, setSchemeStatus] = useState(null);
  const [schemeDetailsData, setSchemeDetailsData] = useState(null);

  const [order, setOrder] = useState(null);

  const [isAmountSanctioned, setisAmountSanctioned] = useState(false);
  const [isFinalApproval, setisFinalApproval] = useState(false);

  const [loadingfiles, setloadingfiles] = useState(true);
  const [selectedfiles, setselectedfiles] = useState([]);
  const [selectedLabourPhotofiles, setselectedLabourPhotofiles] = useState([]);

  const [labourPhoto, setLabourPhoto] = useState([]);

  const [showVerificationDocUpload, setshowVerificationDocUpload] =
    useState(true);
  const [VerificationDocuments, setVerificationDocuments] = useState([]);
  const [missingDocuments, setMissingDocuments] = useState([]);
  const [VerificationDocuments2, setVerificationDocument2] = useState([]);
  const [LabourUploadPhoto, setLabourUploadPhoto] = useState([]);

  const [selectMissingFiles, setSelectedMissingFiles] = useState({});
  const [missingFilesResults, setMissingFilesResult] = useState(null);

  const [scheme_name_ad, setscheme_name_ad] = useState(null);

  const labour_name1 = users.getDeptUserRole.data[0].role_id;

  const [draftApprove, setdraftApprove] = useState(false);
  const [draftSuccess, setSuccessDraft] = useState(false);

  const [anchorElEcard, setAnchorElEcard] = useState(null);

  const openEcard = Boolean(anchorElEcard);
  const idEcard = openEcard ? "simple-popover" : undefined;

  const [personalDetails, setPersonalDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState(null);
  const [familyDetails, setFamilyDetails] = useState(null);
  const [rationCardDetails, setRationCardDetails] = useState(null);
  const [bankDetails, setBankDetails] = useState(null);
  const [certificateDetails, setCertificateDetails] = useState(null);
  const [employerDetails, setEmployerDetails] = useState(null);
  const [amountLimit, setAmountLimit] = useState(null);

  const [displayNonDeathFields, setDisplayNonDeathFields] = useState(null);
  const [openDialoge, setOpenDialoge] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [rejectReason, setRejectReasons] = useState(null);
  const [rejectLang, setRejectLang] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // If checked, add the option to selectedOptions, else remove it
    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, { value }]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option.value !== value)
      );
    }
  };

  //To RESET the Selected CheckBoxssss
  // const handleResetSelection = () => {
  //   setSelectedOptions(null);
  // };

  //Function to handle form submission
  const handleSubmitReject = (event) => {
    event.preventDefault();
    console.log(loading, "loadinTrue");
    setLoading(true);
    const rejectReason = selectedOptions.map((option) => ({
      value: +option.value,
    }));
    rejectButtonPressed(rejectReason);
    console.log(rejectReason);
    console.log(loading, "loadingFalse");
    setLoading(false);
  };

  //API for Rejection Reasons
  useEffect(() => {
    if (schemeDetailsAll && schemeDetailsAll.scheme_id) {
      axios
        .get(
          `${SERVER}/global/rejection-reason?id=${schemeDetailsAll.scheme_id}`,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        )
        .then((res) => {
          console.log("data", res.data);
          const { data } = res.data;
          setRejectReasons(data);
        })
        .catch((err) => {
          console.log(err, "RejectionError");
        });
    }
  }, [schemeDetailsAll]);

  //RejectReasonsDialogeBox
  const handleRejectButtonPressed = () => {
    setOpenDialoge(true);
  };

  //To Close rejectReason DialogBox
  const handleCloseReject = () => {
    setOpenDialoge(false);
  };

  //handleEcard
  const handleCloseEcard = () => {
    setAnchorElEcard(null);
  };

  const handleClickEcard = (event) => {
    setAnchorElEcard(event.currentTarget);
  };

  //Transfer Button
  const handleClickOpenTransfer = () => {
    if (
      allValues.commentInspector !== null &&
      allValues.commentInspector.length > 0
    ) {
      if (selectedfiles.length !== 0) {
        showToast(
          "ERROR",
          "Please remove the Verification Document to Transfer for Verification"
        );
      } else {
        setopentransfer(true);
      }
    } else {
      showToast("ERROR", "Please add a comment before Transfer");
    }
  };

  //Closing Trasnfer button
  const handleCloseTransfer = () => {
    setopentransfer(false);
  };

  //Calling Fields Values
  const getFieldValues = async (field) => {
    return new Promise((resolve, reject) => {
      var dataNEW = JSON.stringify({
        board_id: 1,
        field_id: field.id,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_fields_value",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          resolve({
            fieldName: field.field_name,
            data: response.data,
          });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (props && props.location.userid) {
      const payLoad = {
        board_id: 1,
        labour_user_id: props.location.userid,
      };
      const config = {
        method: "post",
        url: `${SERVER}/schemes/get_schemes_by_labor`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payLoad,
      };
      axios(config)
        .then((res) => {
          const schemeStatusData = res.data.data.map((data) => data);
          console.log("schemeStatusData", schemeStatusData);

          const schemeDetails =
            props && props.location.schemeDetails.scheme_name;

          const schemeDetailsDataNew =
            schemeStatusData &&
            schemeStatusData.filter(
              (data) => data.scheme_name === schemeDetails
            );

          setSchemeStatus(schemeDetailsDataNew);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    setSchemeDetailsData(
      props &&
        props.location &&
        props.location.schemeDetails &&
        props.location.schemeDetails
    );
  }, [props]);

  //Union_Board Details
  useEffect(() => {
    if (
      props &&
      props.location.schemeDetails.labour_union_id &&
      props.location.schemeDetails.labour_union_id
    ) {
      axios
        .post(
          `${SERVER}/config/union/get_union_board_details`,
          { id: props.location.schemeDetails.labour_union_id },
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        )
        .then((res) => {
          console.log(res.data.data[0], "Union Details");
          setUnionDetails(res.data.data[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props]);

  //rendering approve and reject Buttons
  useEffect(() => {
    if (scheme_name_ad !== undefined && scheme_name_ad !== null) {
      setisAmountSanctioned(scheme_name_ad.is_amount_sactioned);
      setisFinalApproval(scheme_name_ad.is_final_approved);

      if (scheme_name_ad.role_id === 2) {
        setshowtransferButton(false);
      }

      if (scheme_name_ad.scheme_name === "BMTC Bus Pass\r\n") {
        setshowVerificationDocUpload(false);
      }

      if (
        scheme_name_ad.scheme_name.includes("Medical Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Accident Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Funeral Expense and Death Assistance"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Continuation of Disability Pension"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Disability Pension") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Pension Scheme") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Continuation of Pension") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Delivery Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Thayi Magu Sahaya Hasta") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Marriage Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Assistance For Major Ailments") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("House Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("BMTC Bus Pass") &&
        labour_name1 === 1
      ) {
        setrejectButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching (UPSC and KPSC) Application"
        ) &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Education Assistance") &&
        labour_name1 === 1
      ) {
        setrejectButton(false);
      } else if (
        scheme_name_ad.scheme_name.includes("Accident Assistance") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Funeral Expense and Death Assistance"
        ) &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Disability Pension") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Pension Scheme") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Continuation of Pension") &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Continuation of Disability Pension"
        ) &&
        labour_name1 === 3
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Delivery Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes("Marriage Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Thayi Magu Sahaya Hasta") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Medical Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Medical");
      } else if (
        scheme_name_ad.scheme_name.includes("House Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Assistance For Major Ailments") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("Education Assistance") &&
        labour_name1 === 2
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("BMTC Bus Pass") &&
        labour_name1 === 1
      ) {
        setapproveButton(false);
        console.log("Scheme Name1 Medical");
      } else if (
        scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes("KSRTC Bus pass") &&
        labour_name1 === 1
      ) {
        setapproveButton(true);
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching (UPSC and KPSC) Application"
        ) &&
        labour_name1 === 4
      ) {
        setapproveButton(true);
        console.log("Scheme Name1 Accident");
      } else if (
        scheme_name_ad.scheme_name.includes(
          "Pre Coaching(UPSC and KPSC) Application"
        ) &&
        users.getDeptUserRole.data[0].role_name === "Joint Secretary"
      ) {
        setapproveButton(true);
      }

      console.log("scheme_name_ad", isAmountSanctioned);
    }
  }, [scheme_name_ad]);

  //getting inspector details by Circle Role
  useEffect(() => {
    if (allValues.circleSelected !== "") {
      var config = {
        method: "get",
        url:
          SERVER +
          `/global/get_inspector_details_by_circle_role?board_id=${users.getDeptUserRole.data[0].board_id}&role_id=${users.getDeptUserRole.data[0].role_id}&circle_id=${allValues.circleSelected}`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: "",
      };

      axios(config)
        .then(function (response) {
          console.log(
            "get_inspector_details_by_circle_role response:",
            response.data
          );
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [allValues.circleSelected]);

  useEffect(() => {
    if (
      allValues.props_this.location.userid !== undefined &&
      allValues.props_this.location.userid !== null &&
      allValues.props_this.location.scheme_avail_id !== undefined &&
      allValues.props_this.location.scheme_avail_id !== null
    ) {
      var config = {
        method: "get",
        url:
          SERVER +
          `/schemes/get_scheme_fields_values?labour_user_id=${allValues.props_this.location.userid}&scheme_avail_id=${allValues.props_this.location.scheme_avail_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        // data : data
      };

      axios(config)
        .then(function (response) {
          console.log("API called inside");
          console.log("API called inside 1 ", response);
          console.log("fieldsValue", response.data.data[0]);
          setschemeDetails(response.data.data);
          const fieldValues = response.data.data[0].filter(
            (_data) => _data.field === "Cost of treatment"
          );

          setSanctionedAmountMed(fieldValues && fieldValues[0].field_value);

          const accidentAssisanceFieldsFilter = response.data.data[0].filter(
            (field) => field.field_value === "Death"
          );

          setDisplayNonDeathFields(accidentAssisanceFieldsFilter.length);
          console.log("API called inside 2");
          const costOfTreatment = response.data.data[0].map(
            (_data) => _data.field === "Cost of treatment"
          );
          console.log("costOfTreatment", costOfTreatment);

          const missingDocs = response.data.data[2].map(
            (data) => data.missing_document_id
          );
          console.log("API called inside 3 ", missingDocs);

          const missingDocsParsedData =
            missingDocs.length > 0 ? missingDocs[0] : null;

          setMissingDocuments(JSON.parse(JSON.parse(missingDocsParsedData)));

          if (response.data.data[0][0].field === "Accident Resulted In") {
            console.log("in if? ");
            setAccidentStatus(response.data.data[0][0].field_value);
          } else if (response.data.data[0][8].field === "Cost of treatment") {
            console.log("in ielse? ", response.data.data[0][8].field_value);

            //setEnteredAmount(response.data.data[0][8].field_value);
            console.log(
              "setSactionedAmount",
              response.data.data[0][8].field_value
            );
          }

          if (
            response.data.data[0].filter(
              (field) => field.field === "Current Course Studying"
            )
          ) {
            let schemeField = response.data.data[0].filter(
              (field) => field.field === "Current Course Studying"
            );
            let courseField = schemeField[0].field_value;

            console.log("CouseField:", courseField);
            setEducationStatus(courseField);
          }
          if (
            response.data.data[0].filter(
              (field) => field.field === "Tuition Fees"
            )
          ) {
            console.log("Insidide Tuituin");
            var schemeField2 = response.data.data[0].filter(
              (field) => field.field === "Tuition Fees"
            );
            var tuitionField = schemeField2[0].field_value;
            setEduActualFees(schemeField2[0].field_value);
            console.log("TuitionField", tuitionField);
          }
        })
        .catch(function (error) {
          console.error("get_scheme_fields_values error: " + error);
        });

      let obj =
        users &&
        users.getSchemesOnStatus &&
        users.getSchemesOnStatus.data &&
        users.getSchemesOnStatus.data.recordsets &&
        users.getSchemesOnStatus.data.recordsets[0] &&
        users.getSchemesOnStatus.data.recordsets[0].find(
          (e) =>
            e.scheme_avail_id === allValues.props_this.location.scheme_avail_id
        );
      console.log("obj", obj);
      setschemeDetailsAll(obj);

      var data = JSON.stringify({
        key: "user_id",
        value: allValues.props_this.location.userid,
        board_id: 1,
        procedure_name: "all",
      });
      var config2 = {
        method: "post",
        url: SERVER + "/user/get-user-registration-details",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      axios(config2)
        .then(function (response) {
          setUserDetails(response.data.data);
          setPersonalDetails(response.data.data.personal_details[0]);
          setAddressDetails(response.data.data.address_details[0]);
          setFamilyDetails(response.data.data.family_details);
          setCertificateDetails(response.data.data.certificate_details[0]);
          setEmployerDetails(response.data.data.employer_details);
        })
        .catch(function (error) {
          console.error("get-user-registration-details error:" + error);
        });

      var config1 = {
        method: "get",
        url:
          SERVER +
          "/schemes/get_scheme_status?schme_avail_id=" +
          allValues.props_this.location.scheme_avail_id,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: "",
      };

      axios(config1)
        .then(function (response) {
          console.log("getScheme Status response: ", response.data);
          setget_scheme_status(response.data);
          console.log(
            "get_scheme_status?.data?.recordsets[1][0]?.description ",
            response.data.data.recordsets[1][0].order_remarks
          );
          console.log(
            "get_scheme_status?.data?.recordsets[1][0]?.description ",
            response.data.data.recordsets[1][1].order_remarks
          );
          allValues.commentInspector =
            response.data.data.recordsets[1][1].description;
          setOrder(
            response.data.data.recordsets[1][1].draft_appr_rej_order_remarks
          );
          setshowtransferBackButton(
            response.data.data.recordsets[1][0].is_transferred
          );
          if (users.getDeptUserRole.role_id === 3) {
            setshowVerificationDocUpload(false);
          }
          console.log("scheme123123", response.data.data.recordsets);
          response.data.data.recordsets[1].map((field) => {
            console.log("in get_scheme_status map: ", field);
            if (field.is_final !== true) {
              console.log(
                "verification_document_id parse: ",
                JSON.parse(field.verification_document_id)
              );

              setVerificationDocuments(
                JSON.parse(field.verification_document_id)
              );
              setLabourUploadPhoto(
                JSON.parse(field.labour_card_holder_photo_id)
              );
              setverificationDoc("userstat", field.approval_status);

              JSON.parse(field.verification_document_id).forEach((doc) => {
                dispatch(getFile(doc.file_id, doc.file_name));
              });
            }
            if (field.is_final) {
              console.log(
                "Verification Doc2",
                JSON.parse(field.verification_document_id)
              );

              setVerificationDocument2(
                JSON.parse(field.verification_document_id)
              );
            } else {
              setshowVerificationDocUpload(false);
            }
          });
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      allValues.props_this.history.goBack();
    }
  }, []);

  //accidentStatus
  useEffect(() => {
    if (accidentStatus !== null && accidentStatus !== undefined) {
      if (accidentStatus === "Permanent Total Disablement") {
        setAccidentAmount(200000);
      } else if (accidentStatus === "Permanent Partial Disablement") {
        setAccidentAmount(100000);
      } else if (accidentStatus === "Death") {
        setAccidentAmount(500000);
      }
    }
  }, [accidentStatus]);

  //EducationStatus
  useEffect(() => {
    if (
      educationStatus !== null &&
      educationStatus !== undefined &&
      eduActualFees !== null &&
      eduActualFees !== undefined
    ) {
      if (
        educationStatus === "Nursery" ||
        educationStatus === "Pre School" ||
        educationStatus === "LKG" ||
        educationStatus === "UKG" ||
        educationStatus === "1st Standard" ||
        educationStatus === "2nd Standard" ||
        educationStatus === "3rd Standard" ||
        educationStatus === "4th Standard"
      ) {
        setEducationAmount(5000);
        setDisableAmount(true);
      } else if (
        educationStatus === "5th Standard" ||
        educationStatus === "6th Standard" ||
        educationStatus === "7th Standard" ||
        educationStatus === "8th Standard"
      ) {
        setEducationAmount(8000);
        setDisableAmount(true);
      } else if (
        educationStatus === "9th Standard" ||
        educationStatus === "10th Standard"
      ) {
        setEducationAmount(12000);
        setDisableAmount(true);
      } else if (
        educationStatus === "First PUC" ||
        educationStatus === "Second PUC"
      ) {
        setEducationAmount(15000);
        setDisableAmount(true);
      } else if (
        educationStatus === "Diploma" ||
        educationStatus === "ITI" ||
        educationStatus === "Polytechnic"
      ) {
        setEducationAmount(20000);
        setDisableAmount(true);
      } else if (
        educationStatus === "BSc Nursing" ||
        educationStatus === "BSc GNM" ||
        educationStatus === "BSc ANM" ||
        educationStatus === "BSc Paramedical Course"
      ) {
        setEducationAmount(40000);
        setDisableAmount(true);
      } else if (
        educationStatus === "D.Ed" ||
        educationStatus === "Graduation (Any Discipline)" ||
        educationStatus === "PHD" ||
        educationStatus === "M.Phil"
      ) {
        setEducationAmount(25000);
        setDisableAmount(true);
      } else if (
        educationStatus === "B.Ed" ||
        educationStatus === "Any Post Graduation for maximum of 2 years subject"
      ) {
        setEducationAmount(35000);
        setDisableAmount(true);
      } else if (educationStatus === "LLB" || educationStatus === "LLM") {
        setEducationAmount(30000);
        setDisableAmount(true);
      } else if (educationStatus === "BE" || educationStatus === "Btech") {
        setEducationAmount(50000);
        setDisableAmount(true);
      } else if (
        educationStatus === "Mtech" ||
        educationStatus === "Medical (MBBS/ BAMS/ BDS/ BHMS)" ||
        educationStatus === "ME"
      ) {
        setEducationAmount(60000);
        setDisableAmount(true);
      } else if (educationStatus === "MD") {
        setEducationAmount(75000);
        setDisableAmount(true);
      } else {
        setEducationAmount(null);
        setDisableAmount(false);
      }
    }
  }, [educationStatus, eduActualFees]);

  //SchemeONStatus
  useEffect(() => {
    if (
      users.getSchemesOnStatus !== undefined &&
      users.getSchemesOnStatus !== null
    ) {
      setscheme_name_ad(
        users.getSchemesOnStatus.data.recordsets[0].find(
          (o) =>
            o.scheme_avail_id === allValues.props_this.location.scheme_avail_id
        )
      );
    }
  }, [users.getSchemesOnStatus]);

  //SchemeDetails
  useEffect(() => {
    var schemeDetailsString = JSON.stringify(schemeDetails);

    if (schemeDetails && schemeDetails.length) {
      console.log("Scheme DETAILS::", schemeDetails);

      schemeDetails &&
        schemeDetails[1].forEach((field) => {
          JSON.parse(field.document_upload_file_xml).forEach((doc) => {
            dispatch(getFile(doc.file_id, doc.file_name));
          });
        });

      console.log("useeffect schemeDetails:1 " + schemeDetails);

      setdeliveryData(schemeDetails[0].find((o) => o.field === "Gender"));
    }
  }, [schemeDetails]);

  const handlePreview = () => {
    setOpenPreview(!openPreview);
  };

  //Delivery
  useEffect(() => {
    if (deliveryData !== undefined) {
      if (
        deliveryData.field_value !== undefined &&
        deliveryData.field_value === "Male"
      ) {
        console.log("deliveryData1", deliveryData.field_value);
        setDeliveryChildMale(true);
      }
    }
  }, [deliveryData]);

  //To Get PersonalDetails
  useEffect(() => {
    console.log(
      "useeffect UserDetails: " + JSON.stringify(UserDetails, undefined, 2)
    );
    if (
      UserDetails !== null &&
      UserDetails.personal_details !== undefined &&
      UserDetails.personal_details.length > 0
    ) {
      console.log(
        "useeffect UserDetails.personal_details : " +
          UserDetails.personal_details.length
      );
      var dateString = UserDetails.personal_details[0].date_of_birth;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format("DD-MM-YYYY");
      console.log("momentString: " + momentString);
      var age = moment().diff(momentObj, "years");
      console.log("age: " + age);

      setAge(age);

      var age60_1 = moment(momentObj).add(60, "Y");
      var age60 = age60_1.format("MMM DD YYYY");
      setAge60(age60);

      var dateString2 = UserDetails.application_details[0].application_date;
      var dateObj2 = new Date(dateString2);
      var momentObj2 = moment(dateObj2);
      var momentString2 = momentObj2.format("DD-MM-YYYY");
      console.log("momentString2: " + momentString2);

      var startDate = moment(momentString, "DD-MM-YYYY");
      var endDate = moment(momentString2, "DD-MM-YYYY");
      var yearDiff = endDate.diff(startDate, "years");

      console.log("endDate: " + endDate.format("DD MMM YYYY"));

      var age_at_registration = yearDiff;
      console.log("age_at_registration: " + age_at_registration);
      setAgeAtRegistration(age_at_registration);

      var years_registered = moment().diff(momentObj2, "years", false);
      var renewal_date1 = moment(momentObj2).add(3, "Y");
      var renewal_date = renewal_date1.format("MMM DD YYYY");
      setRenewalDate(renewal_date);
      setYearsRegistered(years_registered);

      setLoadingData(false);
      setOpenBackdrop(false);
    }
  }, [UserDetails]);

  useEffect(() => {
    if (
      schemeDetailsAll !== undefined &&
      schemeDetailsAll !== null &&
      schemeDetailsAll !== ""
    ) {
      if (schemeDetailsAll.scheme_name !== undefined) {
        if (schemeDetailsAll.scheme_name === "BMTC Bus Pass")
          setshowVerificationDocUpload(false);
      }

      console.log("herein_is_transfer_option: ", schemeDetailsAll.scheme_id);
      if (schemeDetailsAll.is_transfer_option) {
        console.log(
          "hereinif_is_transfer_option: ",
          schemeDetailsAll.is_transfer_option
        );

        if (schemeDetailsAll.transfer_back_document_details !== null) {
          JSON.parse(schemeDetailsAll.transfer_back_document_details).forEach(
            (doc) => {
              dispatch(getFile(doc.file_id, doc.file_name));
            }
          );
        }
        // if (schemeDetailsAll.transfer_back_document_details !== null) {
        //   try {
        //     const documentDetails = JSON.parse(
        //       schemeDetailsAll.transfer_back_document_details,
        //     );

        //     documentDetails.forEach((doc) => {
        //       dispatch(getFile(doc.file_id, doc.file_name));
        //     });
        //   } catch (error) {
        //     console.error('Error parsing JSON:', error);
        //   }
        // }

        var config = {
          method: "get",
          url: `${SERVER}/schemes/get_scheme_transfer_role_details?scheme_id=${schemeDetailsAll.scheme_id}&from_role_id=${users.getDeptUserRole.data[0].role_id}&board_id=${users.getDeptUserRole.data[0].board_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));

            setget_scheme_transfer_role_details(response.data.data);
            console.log(
              "setget_scheme_transfer_role_details",
              response.data.data
            );
          })
          .catch(function (error) {
            console.log(error);
          });

        if (schemeDetailsAll.transferred_date === null) {
          if (
            scheme_name_ad.role_id === 2 ||
            scheme_name_ad.role_id === 4 ||
            scheme_name_ad.role_id === 3 ||
            scheme_name_ad.role_id === 6 ||
            scheme_name_ad.scheme_name.includes("Shrama Samarthya Toolkit")
          ) {
            setshowtransferButton(false);
          } else if (scheme_name_ad.scheme_name.includes("BMTC Bus Pass")) {
            setshowtransferButton(false);
          } else if (
            scheme_name_ad.scheme_name.includes(
              "Pre Coaching (UPSC and KPSC) Application"
            ) &&
            scheme_name_ad.role_id === 1
          ) {
            setshowtransferButton(true);
          } else {
            setshowtransferButton(true);
          }
        } else if (
          schemeDetailsAll.transferred_date !== null &&
          schemeDetailsAll.transfer_back_document_details !== null
        ) {
          setshowtransferButton(false);
        }

        var configcircle = {
          method: "get",
          url: SERVER + "/config/circle/get_circle_details",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(configcircle)
          .then(function (response) {
            setcircle_details(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });
      }

      if (schemeDetailsAll.labour_user_id !== undefined) {
        let dataForRegDetails = {
          key: "user_id",
          value: schemeDetailsAll.labour_user_id,
          board_id: users.getDeptUserRole.data[0].board_id,
          procedure_name: "all",
        };

        dispatch(getUserRegistrationDetails(dataForRegDetails));
      }

      var dataNEW = JSON.stringify({
        board_id: 1,
        scheme_id: schemeDetailsAll.scheme_id,
        role_id: null,
        tab_id: null,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_dynamic_fields",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_dynamic_fields: " +
              JSON.stringify(response.data, undefined, 2)
          );

          var arr1 = "";
          console.log("Responsse", response.data.data);
          var fields = response.data.data;

          const myPromises = [];

          fields.forEach((field) => {
            if (field.is_text_or_value === true) {
              myPromises.push(getFieldValues(field));
            }
          });

          Promise.all(myPromises)
            .then((promResponses) => {
              console.log("Promise Responses", promResponses);

              setAllValues({
                ...allValues,
                promResponses: promResponses,
                // promResponses: JSON.stringify(promResponses)
              });
            })
            .catch((err) => {
              console.log("Error in Resolve Promise", err);
            });
        })
        .catch(function (error) {
          console.error(error);
        });

      if (schemeDetailsAll.scheme_id !== undefined) {
        var config = {
          method: "get",
          url: `${SERVER}/schemes/get_scheme_transfer_role_details?scheme_id=${schemeDetailsAll.scheme_id}&from_role_id=${users.getDeptUserRole.data[0].role_id}&board_id=${users.getDeptUserRole.data[0].board_id}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: "",
        };

        axios(config)
          .then(function (response) {
            console.log(JSON.stringify(response.data));
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }
  }, [schemeDetailsAll]);

  const handleClickPDFEMP = (event, data, selecteddoc) => {
    setEmployerCertificateID(selecteddoc);

    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(employerCertificateBase64) +
        "'></iframe>"
    );
  };

  const handleClickPDFSELFDECLARATION = (event, data, selecteddoc) => {
    console.log("selecteddoc");
    setSelfDeclarationID(selecteddoc);

    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(selfDeclarationBase64) +
        "'></iframe>"
    );
  };
  useEffect(() => {
    console.log("showtransferButton: ", showtransferButton);
  }, [showtransferButton]);

  useEffect(() => {
    console.log("allValues: " + JSON.stringify(allValues, undefined, 2));
  }, [allValues]);

  const removefile = (index, docname) => {
    setloadingfiles(true);
    console.log("selectedfiles: ", selectedfiles);
    selectedfiles.splice(index, 1);
    setTimeout(() => setloadingfiles(false), 1);
  };

  const removeMissingfiles = (index, docname) => {
    console.log(index, docname, "REMOVED::");
    setloadingfiles(true);

    selectMissingFiles.splice(index, 1);
    setTimeout(() => setloadingfiles(false), 1);
  };

  const handleChangeSanc = (e) => {
    if (
      schemeDetailsAll.scheme_name.includes("Assistance For Major Ailments") ||
      schemeDetailsAll.scheme_name.includes("Medical Assistance")
    ) {
      if (users.getDeptUserRole.data[0].role_id === 2) {
        if (validator.isNumeric(e.target.value)) {
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
          });
          setMedNumeric(false);
        } else {
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
          });
          setMedNumeric(true);
        }
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
        });
      }
    } else if (schemeDetailsAll.scheme_name.includes("Education Assistance")) {
      console.log(e.target.value);
      console.log("Inside Education Sanc");
      console.log("AmountEduSanc", educationAmount);

      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleChange = (e) => {
    console.log(
      "e.target.name: ",
      e.target.name + " e.target.value: ",
      e.target.value
    );

    var nameError = false;
    var nameNomineeError = false;
    var phoneError = false;
    var phoneNomineeError = false;

    if (e.target.name === "name") {
      if (validator.isAlpha(e.target.value)) nameError = false;
      else nameError = true;
    } else if (e.target.name === "name_nominee") {
      if (validator.isAlpha(e.target.value)) nameNomineeError = false;
      else nameNomineeError = true;
    } else if (e.target.name === "phone") {
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    } else if (e.target.name === "phone_nominee") {
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    } else if (e.target.name === "phone_nominee") {
      if (validator.isNumeric(e.target.value) && e.target.value.length === 10)
        phoneNomineeError = false;
      else phoneNomineeError = true;
    }

    setAllValues({
      ...allValues,
      [e.target.name]: e.target.value,
      nameError: nameError,
      nameNomineeError: nameNomineeError,
      phoneError: phoneError,
      phoneNomineeError: phoneNomineeError,
    });

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handleTransactionClose = () => {
    setOpenTranscation(false);
  };

  const handleTransactionModal = async (ev) => {
    ev.preventDefault();
    if (transactionPassword) {
      const payload = {
        login_id: users.login_admin.data.department_user_id,
        password: transactionPassword,
      };
      const config = {
        method: "POST",
        url: `${SERVER}/config/dept_user/transaction-password`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payload,
      };
      await axios(config)
        .then((res) => {
          console.log(res.data, "Transcation Check");

          showToast("SUCCESS", `${res.data.message}`);
          setOpenTranscation(false);
          if (approveReject === "approve") {
            submitButtonPressed();
          } else if (approveReject === "reject") {
            rejectButtonPressed();
          } else if (approveReject === "transfer") {
            handleClickOpenTransfer();
          } else if (approveReject === "transferBack") {
            transferButtonPressed();
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data.errors.msg);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        });
    } else {
      showToast("ERROR", "Please Enter Transaction Password");
    }
  };

  const handleClick = (event, data, selecteddoc, selectedDocId) => {
    console.error(
      "handleclick data:" + data,
      event,
      selecteddoc,
      selectedDocId
    );
    console.error("handleclick selecteddoc:" + selecteddoc);

    setselected_document_type(selecteddoc);

    if (data === "id_card") {
      setAnchorElIdCard(event.currentTarget);
    } else if (data === "employee_cert") {
      setAnchorElemployee_cert(event.currentTarget);
      setEmployerCertificateID(selectedDocId);
    } else if (data === "marriage_cert") {
      setAnchorElmarriage_cert(event.currentTarget);
      setDocumentImgID(selectedDocId);
    } else if (data === "labour_photo") {
      setAnchorElLabourPhoto(event.currentTarget);
    } else if (data === "second-level-verification") {
      setAnchorElSecondLevel(event.currentTarget);
    } else if (data === "selfDeclaration") {
      setAnchorElSelfDeclaration(event.currentTarget);
      setSelfDeclarationID(selectedDocId);
    } else if (data === "missingDoc") {
      setAnchorElMissingDoc(event.currentTarget);
      setMissingDocID(selectedDocId);
    }
  };

  const handleClick2 = (event, data, id) => {
    console.log("CLICKED:", id);
    setDocumentID(id);
  };

  const showPDF = (selecteddoc) => {
    console.log(selecteddoc);

    const linkSource = `data:application/pdf;base64,${selecteddoc}`;
    const downloadLink = document.createElement("a");
    const fileName = "file.pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();

    setDocumentID(null);
    setDocumentBase64(null);
  };

  const handleClose = (event, data) => {
    console.error("handleClose data:" + data);
    if (data === "id_card") setAnchorElIdCard(null);
    else if (data === "employee_cert") setAnchorElemployee_cert(null);
    else if (data === "marriage_cert") setAnchorElmarriage_cert(null);
    else if (data === "labour_photo") {
      setAnchorElLabourPhoto(null);
    } else if (data === "second-level-verification") {
      setAnchorElSecondLevel(null);
    } else if (data === "selfDeclaration") {
      setAnchorElSelfDeclaration(null);
    } else if (data === "missingDoc") {
      setAnchorElMissingDoc(null);
    }
  };

  const handleMissingFile = (ev) => {
    console.log(ev.target.files);
    setloadingfiles(true);

    if (ev.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !ev.target.files[0].type === "image/png" ||
      !ev.target.files[0].type === "image/jpeg" ||
      !ev.target.files[0].type === "image/jpg" ||
      !ev.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectMissingFiles).concat(
        Array.from(ev.target.files)
      );

      setSelectedMissingFiles(joined);
    }

    setloadingfiles(false);
  };

  const handleFileChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedfiles).concat(
        Array.from(event.target.files)
      );

      setselectedfiles(joined);
    }

    setloadingfiles(false);
  };

  const handleLabourPhotoChange = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedLabourPhotofiles).concat(
        Array.from(event.target.files)
      );

      setselectedLabourPhotofiles(joined);
    }

    setloadingfiles(false);
  };

  const transferButtonPressed = () => {
    setLoading(true);
    console.log("transferButtonPressed");

    if (schemeDetailsAll.transferred_date === null) {
      setLoading(true);
      setOpenBackdrop(true);
      setSubmitSuccess(false);
      var data = JSON.stringify({
        scheme_avail_id: schemeDetailsAll.scheme_avail_id,
        scheme_transaction_id: schemeDetailsAll.scheme_transaction_id,
        transfer_to_role_id:
          get_scheme_transfer_role_details[0].transfer_to_role_id,
        transfer_with_remarks: allValues.remarkTransfer,
        transfer_to_circle: allValues.circleSelected,
        login_user_id: users.login_admin.data.department_user_id,
      });

      var config = {
        method: "post",
        url: SERVER + "/schemes/transfer_scheme_application",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };
      console.log("transfer_scheme_application data: ", data);
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          showToast(
            "SUCCESS",
            "Scheme Transferred for Verification Successfully!"
          );

          setLoading(false);
          setOpenBackdrop(false);
          setSubmitSuccess(true);

          allValues.props_this.history.push("/admin/dashboard");
        })
        .catch(function (error) {
          console.log(error);
          showToast(
            "ERROR",
            "Please select the circle and provide remarks to continue."
          );

          setLoading(false);
        });
    } else if (schemeDetailsAll.transferred_date !== null) {
      if (selectedfiles.length <= 0) {
        showToast("ERROR", "Please Upload the Verification Documents!");
      } else if (allValues.commentInspector === "") {
        showToast("ERROR", "Please Fill the Comment Field!");
      } else {
        let promises = [];
        selectedfiles.forEach(async (i) => {
          var selectedblob = i;
          var reader = new FileReader();
          reader.readAsDataURL(selectedblob);
          var formdataobj = "";
          var fileName = "";
          reader.onloadend = await function (event) {
            fileName = selectedblob.name;
            var base64data = event.target.result;
            formdataobj = {
              file: base64data,
              fileType: fileName,
            };
            promises.push(uploadfile(formdataobj, fileName));
            if (promises.length === selectedfiles.length) {
              Promise.all(promises).then((results) => {
                console.log("results of promise: ");
                console.log(results);

                var filelist = [];
                results.forEach((i) => {
                  var filejson = {
                    file_id: i.image.fileId,
                    file_name: i.image.fileType,
                  };
                  filelist.push(filejson);
                });

                console.log("filelist: ", filelist);
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);

                var data = JSON.stringify({
                  scheme_avail_id: schemeDetailsAll.scheme_avail_id,
                  scheme_transaction_id: schemeDetailsAll.scheme_transaction_id,
                  transferred_with_remarks: allValues.commentInspector,
                  transfer_back_document_details: JSON.stringify(filelist),
                  login_user_id: users.login_admin.data.department_user_id,
                });

                var config = {
                  method: "post",
                  url: SERVER + "/schemes/transfer_back_scheme_application",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };
                console.log("transfer_back_scheme_application data: ", data);
                axios(config)
                  .then(function (response) {
                    console.log(
                      "transfer_back_scheme_application response: " +
                        JSON.stringify(response.data)
                    );
                    showToast(
                      "SUCCESS",
                      "Scheme Transferred Back Successfully!"
                    );

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);

                    allValues.props_this.history.push("/admin/dashboard");
                  })
                  .catch(function (error) {
                    console.log(error);
                    showToast("ERROR", "Some Error occured: ", error.response);

                    setLoading(false);
                  });
              });
            }
          };
        });
      }
    }
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    let promises = [];
    selectedLabourPhotofiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedLabourPhotofiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);

            var filelist = [];
            results.forEach((i) => {
              var filejson = {
                file_id: i.image.fileId,
                file_name: i.image.fileType,
              };
              filelist.push(filejson);
            });

            setLabourPhoto(filelist && filelist.length > 0 && filelist);
          });
        }
      };
    });
  }, [selectedLabourPhotofiles]);

  useEffect(() => {
    if (LabourUploadPhoto) {
      const id = LabourUploadPhoto.map((data) => data.file_id);
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("FILEBASE64", res.data.data);
          setLabourPhotoBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [LabourUploadPhoto]);

  useEffect(() => {
    if (VerificationDocuments2) {
      const id = VerificationDocuments2.map((data) => data.file_id);
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("FILEBASE64", res.data.data);
          setVerificationDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [VerificationDocuments2]);

  useEffect(() => {
    if (employerCertificateID) {
      const id = employerCertificateID && employerCertificateID;
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("EMPFILEBASE64", res.data.data);
          setEmployerCertificateBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [employerCertificateID]);

  useEffect(() => {
    if (documentID) {
      const id = documentID && documentID;
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("EMPFILEBASE64", res.data.data);
          setDocumentBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [documentID]);

  useEffect(() => {
    if (documentImgID) {
      const id = documentImgID && documentImgID;
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          setDocumentImgBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [documentImgID]);

  useEffect(() => {
    if (documentBase64) {
      showPDF(documentBase64);
    }
  }, [documentBase64]);

  useEffect(() => {
    if (selfDeclarationID) {
      const id = selfDeclarationID && selfDeclarationID;
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("EMPFILEBASE64", res.data.data);
          setSelfDeclarationBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [selfDeclarationID]);

  useEffect(() => {
    if (missingDocID) {
      const id = missingDocID && missingDocID;
      axios
        .get(`${SERVER}/upload/download_base_64/${id}`)
        .then((res) => {
          console.log("EMPFILEBASE64", res.data.data);
          setMissingDocBase64(res.data.data.base_64_content);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [missingDocID]);

  useEffect(() => {
    if (selectMissingFiles && selectMissingFiles.length > 0) {
      callMissingFilesUpload();
    }
  }, [selectMissingFiles]);

  const callMissingFilesUpload = () => {
    let promises = [];
    selectMissingFiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectMissingFiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);

            var filelist = [];
            results.forEach((i) => {
              var filejson = {
                file_id: i.image.fileId,
                file_name: i.image.fileType,
              };
              filelist.push(filejson);
            });

            console.log("filelist: ", filelist);
            setMissingFilesResult(filelist);
          });
        }
      };
    });
  };

  const submitButtonPressed = (status = null) => {
    console.log("schemeDetailsAll", schemeDetailsAll);
    console.log(status, "hereeee");

    if (
      (schemeDetailsAll.transfer_back_document_details === undefined &&
        schemeDetailsAll.transfer_back_document_details === null) ||
      (schemeDetailsAll.transfer_back_document_details === null &&
        schemeDetailsAll.verification_document_id === null &&
        selectedfiles.length <= 0 &&
        showVerificationDocUpload &&
        schemeDetailsAll.role_id === 1)
    ) {
      showToast("ERROR", "Please Upload the Verification Documents!");

      console.log("INLOOP1");
    } else if (isFinalApproval && isAmountSanctioned) {
      const sanctionedAmountAmountInt = parseInt(allValues.sanctioned_amount);
      const sanctionedMedInt = parseInt(sanctionedAmountMed);
      const medicalLimit = 20000;
      const majorElementsLimit = 200000;
      console.log("sanctionedMedInt", sanctionedMedInt);
      if (schemeDetailsAll.scheme_name === `Medical Assistance\r\n`) {
        console.log("Inside Medical");
        if (
          allValues.commentInspector !== "" &&
          allValues.sanctioned_amount !== "" &&
          order !== ""
        ) {
          if (sanctionedAmountAmountInt <= sanctionedMedInt) {
            if (
              sanctionedAmountAmountInt >= 1 &&
              sanctionedAmountAmountInt <= medicalLimit
            ) {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);

              // Check if File exists
              if (selectedfiles && selectedfiles.length > 0) {
                let promises = [];
                selectedfiles.forEach(async (i) => {
                  try {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = function (event) {
                      try {
                        fileName = selectedblob.name;
                        var base64data = event.target.result;
                        formdataobj = {
                          file: base64data,
                          fileType: fileName,
                        };
                        promises.push(uploadfile(formdataobj, fileName));
                        if (promises.length === selectedfiles.length) {
                          Promise.all(promises).then((results) => {
                            console.log("results of promise: ", results);

                            var filelist = [];
                            results.forEach((i) => {
                              var filejson = {
                                file_id: i.image.fileId,
                                file_name: i.image.fileType,
                              };
                              filelist.push(filejson);
                            });

                            console.log("filelist: ", filelist);

                            console.log("Called Second Level Medical");
                            var Before = JSON.stringify({
                              schemes_availed_transaction_id:
                                schemeDetailsAll.scheme_transaction_id,
                              catalog_value_status_id: approveButton
                                ? 47
                                : 1147,
                              description: allValues.commentInspector,
                              board_id: 1,
                              login_user_id:
                                users.login_admin.data.department_user_id,
                              sanctioned_amount: allValues.sanctioned_amount,
                              verified_document_id:
                                filelist && filelist
                                  ? JSON.stringify(filelist)
                                  : null,
                              order_remarks: order ? order : null,
                              missingDocuments:
                                missingFilesResults && missingDocuments
                                  ? JSON.stringify(missingFilesResults)
                                  : null,
                            });

                            console.log(
                              "Data to draft-approve-reject: ",
                              Before
                            );
                            var config = {
                              method: "post",
                              url: SERVER + "/schemes/approve_reject_scheme",
                              headers: {
                                Authorization: `Bearer ${getTokenCookie()}`,
                              },
                              data: Before,
                            };

                            axios(config)
                              .then(function (response) {
                                console.log(
                                  "draft-approve response: ",
                                  response.data
                                );

                                showToast(
                                  "SUCCESS",
                                  "Scheme Transferred Successfully"
                                );

                                allValues.props_this.history.push(
                                  "/admin/dashboard"
                                );

                                setLoading(false);
                                setSubmitSuccess(true);
                              })
                              .catch(function (error) {
                                console.error(
                                  "draft_approve_reject error: ",
                                  error
                                );
                                showToast("ERROR", "Failed to Transfer Scheme");
                              });
                          });
                        }
                      } catch (error) {
                        console.error("Error while processing file: ", error);
                        showToast("ERROR", "Failed to process file");
                      }
                    };
                  } catch (error) {
                    console.error("Error while reading file: ", error);
                    showToast("ERROR", "Failed to read file");
                  }
                });
              } else if (
                props &&
                props.location.schemeDetails.scheme_avail_status === "Forwarded"
              ) {
                console.log('Inside "Forwarded" status block');
                console.log("Scheme avail status:", props.location);
                setOpenBackdrop(true);
                setLoading(true);

                setSubmitSuccess(false);
                var DraftPayload = JSON.stringify({
                  availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                  statusId: status,
                  description: allValues.commentInspector,
                  boardId: 1,
                  departmentUserId: users.login_admin.data.department_user_id,
                  sanctionedAmount: allValues.sanctioned_amount,
                  verifiedDocuments: [],
                  // filelist && filelist
                  //   ? JSON.stringify(filelist)
                  //   : null,
                  orderRemarks: order ? order : null,
                  missingDocuments: [],
                  // missingFilesResults &&
                  // JSON.stringify(missingFilesResults),
                });
                console.log("Data to approve_reject_scheme: ", DraftPayload);
                var DraftConfig = {
                  method: "post",
                  url: SERVER + "/schemes/draft/approve-reject",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: DraftPayload,
                };

                axios(DraftConfig)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    if (status === 1152) {
                      showToast("SUCCESS", "Draft Approved Successfully!!");
                    } else {
                      showToast("ERROR", "Draft Rejected Successfully!!");
                    }

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
                //Final submit API
              } else {
                console.log("Entering final approval/rejection block");
                setOpenBackdrop(true);
                setLoading(true);
                setSubmitSuccess(false);
                var approveRej = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  sanctioned_amount: allValues.sanctioned_amount,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && JSON.stringify(missingFilesResults),
                });
                console.log("Data to FinalApprove: ", approveRej);
                var configLastMed = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: approveRej,
                };

                axios(configLastMed)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: ",
                      response.data
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: ", error);
                    showToast("ERROR", "Failed to Approve/Reject Scheme");
                  });
              }
            } else {
              showToast("ERROR", "Please enter a correct amount");
            }
          } else {
            showToast("ERROR", "Sanctioned amount exceeds requested amount");
          }
        } else {
          showToast("ERROR", "Please fill all the required fields");
        }
      } else if (
        schemeDetailsAll.scheme_name === "Assistance For Major Ailments"
      ) {
        console.log("Inside Assistance");
        if (
          allValues.commentInspector !== "" &&
          allValues.sanctioned_amount !== "" &&
          order !== ""
        ) {
          if (sanctionedAmountAmountInt <= sanctionedMedInt) {
            if (
              sanctionedAmountAmountInt >= 1 &&
              sanctionedAmountAmountInt <= majorElementsLimit
            ) {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);
              //Check If File exits with this API call
              if (selectedfiles && selectedfiles.length > 0) {
                let promises = [];
                selectedfiles &&
                  selectedfiles.length > 0 &&
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called Second Level Medical");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);
                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            sanctioned_amount: allValues.sanctioned_amount,
                            verified_document_id:
                              filelist && filelist
                                ? JSON.stringify(filelist)
                                : null,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Transfered Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                //Draft Approve or Reject Scheme API call
              } else if (
                props &&
                props.location.schemeDetails.scheme_avail_status === "Forwarded"
              ) {
                console.log(
                  props.location.schemeDetails.scheme_avail_status,
                  "ffffffffffffffff"
                );
                setOpenBackdrop(true);
                setLoading(true);
                setSubmitSuccess(false);
                var data1 = JSON.stringify({
                  availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                  statusId: status,
                  description: allValues.commentInspector,
                  boardId: 1,
                  departmentUserId: users.login_admin.data.department_user_id,
                  sanctionedAmount: allValues.sanctioned_amount,
                  verifiedDocuments: null,
                  orderRemarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && JSON.stringify(missingFilesResults),
                });
                console.log("Data to draft-approve-reject: ", data1);
                var config1 = {
                  method: "post",
                  url: SERVER + "/schemes/draft/approve-reject",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data1,
                };

                axios(config1)
                  .then(function (response) {
                    console.log(
                      "draft-approve response: " + JSON.stringify(response.data)
                    );

                    console.log("showToast", status);
                    if (status === 1152) {
                      showToast("SUCCESS", "Draft Approved Successfully!");
                    } else {
                      showToast("ERROR", "Draft Rejected Successfully!");
                    }

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("draft_approve_reject error: " + error);
                  });
              } else {
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data2 = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  sanctioned_amount: allValues.sanctioned_amount,

                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data2);
                var config2 = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data2,
                };

                axios(config2)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              }
            } else {
              showToast("ERROR", "PLease enter correct amount");
            }
          } else {
            showToast("ERROR", "Sanctioned amount exceeded requested amount");
          }
        } else {
          showToast("ERROR", "Please fill all the required fields!");
        }
      }
    } else {
      console.log(isFinalApproval, "isFinalApproval");
      console.log(isAmountSanctioned, "isAmountSanctioned");

      if (isFinalApproval === true && isAmountSanctioned === null) {
        console.log("INLOOP3");

        if (schemeDetailsAll.scheme_name.includes("Accident Assistance")) {
          console.log("accidentAmount1", accidentAmount);
          console.log("draftApprove ", draftApprove);
          if (allValues.commentInspector !== "") {
            console.log("Called 1");
            setOpenBackdrop(true);
            setLoading(true);
            setSubmitSuccess(false);

            //IF else condition for file-exists or not
            if (selectedfiles && selectedfiles.length > 0) {
              console.log("in if 1");
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 6");
                      setLoading(true);
                      setOpenBackdrop(true);
                      setSubmitSuccess(false);
                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        sanctioned_amount: accidentAmount,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Forwarded Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve or Reject Scheme API call
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              setLoading(true);
              setOpenBackdrop(true);
              setSubmitSuccess(false);
              console.log("status for accident ", status);
              var dataAcd = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: accidentAmount,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("data to draft-approve", dataAcd);
              var configAccident = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataAcd,
              };

              axios(configAccident)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", " Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setOpenBackdrop(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
              //Final Submit API
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);

              console.log("in else accident");

              var finalData = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,
                sanctioned_amount: accidentAmount,

                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to approve_reject_scheme: ", finalData);
              var config3 = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: finalData,
              };

              axios(config3)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
              console.log("approve_reject_scheme1 ", isFinalApproval);

              console.log("approve_reject_scheme  ", isAmountSanctioned);
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
          }
        }

        //education assistance:
        else if (
          schemeDetailsAll.scheme_name.includes("Education Assistance")
        ) {
          const sanctionedAmountAmountInt = parseInt(
            allValues.sanctioned_amount
          );
          console.log("SanctionedAmount111", sanctionedAmountAmountInt);
          console.log("IN Education");
          if (allValues.commentInspector !== "") {
            console.log("Called 2");
            setOpenBackdrop(true);
            setLoading(true);
            setSubmitSuccess(false);

            //IF Else condition for file-exists or not
            if (selectedfiles && selectedfiles.length > 0) {
              console.log("in if 1");
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 6");
                      setLoading(true);
                      setOpenBackdrop(true);
                      setSubmitSuccess(false);
                      var dataEdu = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        // "verified_document_id": filesObj,
                        sanctioned_amount: educationAmount
                          ? educationAmount
                          : sanctionedAmountAmountInt,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", dataEdu);
                      var configEdu = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: dataEdu,
                      };

                      axios(configEdu)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast("SUCCESS", "Scheme Approved Successfully!");

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve or Reject Scheme API call
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);
              console.log("status for accident ", status);
              var dataEdu = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: accidentAmount,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("data to draft-approve", dataAcd);
              var configEdu = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataEdu,
              };

              axios(configEdu)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", " Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setOpenBackdrop(false);
                  setLoading(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
              //   //Final Approve
            } else {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);
              console.log("in else accident");
              var dataEdu2 = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,
                // "verified_document_id": filesObj,
                sanctioned_amount: educationAmount
                  ? educationAmount
                  : sanctionedAmountAmountInt,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });

              var configEdu2 = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataEdu2,
              };

              axios(configEdu2)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setOpenBackdrop(false);
                  setLoading(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
              console.log("approve_reject_scheme1 ", isFinalApproval);

              console.log("approve_reject_scheme  ", isAmountSanctioned);
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
          }
        } else if (
          schemeDetailsAll.scheme_name.includes("Delivery Assistance")
        ) {
          console.log("deliveryChildMale", deliveryChildMale);
          if (allValues.commentInspector !== "") {
            console.log("Called 3");

            // IF else condition to check file-exists or not
            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 6");
                      setOpenBackdrop(true);
                      setLoading(true);
                      setSubmitSuccess(false);
                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        sanctioned_amount: deliveryChildMale ? 50000 : 50000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Forwarded Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);

              console.log("status for Delivery", status);
              var dataDeli = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: deliveryChildMale ? 50000 : 50000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("data to draft-approve", dataAcd);
              var configDeli = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataDeli,
              };

              axios(configDeli)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", " Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setOpenBackdrop(false);
                  setLoading(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
              //
            } else {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);

              var NoFiledata = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                sanctioned_amount: deliveryChildMale ? 50000 : 50000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to approve_reject_scheme: ", NoFiledata);
              var NoFileConfig = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: NoFiledata,
              };

              axios(NoFileConfig)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setOpenBackdrop(false);
                  setLoading(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
            console.log("approve_reject_scheme1 ", isFinalApproval);

            console.log("approve_reject_scheme  ", isAmountSanctioned);
          }
        } else if (
          schemeDetailsAll.scheme_name.includes("Marriage Assistance")
        ) {
          console.log("inside Marriage");

          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("Called 7");
            setLoading(true);
            setSubmitSuccess(false);
            setOpenBackdrop(true);
            var data = JSON.stringify({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verified_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: 50000,
              order_remarks: order ? order : null,
              missingDocuments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log("Data to approve_reject_scheme: ", data);
            var config = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            // If else condition to check file exists or not
            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");
                      setLoading(true);
                      setSubmitSuccess(false);
                      setOpenBackdrop(true);

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: null,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults && missingFilesResults,
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Transfered Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setSubmitSuccess(true);
                          setOpenBackdrop(false);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve or Reject Scheme API call
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log(
                props.location.schemeDetails.scheme_avail_status,
                "Forwarded"
              );
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              setSuccessDraft(false);
              var MarPayload = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: null,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Data to draft-approve-reject: ", MarPayload);
              var configMar = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: MarPayload,
              };

              axios(configMar)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setOpenBackdrop(false);
                  setSubmitSuccess(true);
                  setSuccessDraft(true);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var daraMar = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: null,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to approve_reject_scheme: ", daraMar);
              var marConfig = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: daraMar,
              };

              axios(marConfig)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (
          schemeDetailsAll.scheme_name.includes(
            "Funeral Expense and Death Assistance"
          )
        ) {
          console.log("Inside Funeral");

          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("Inside transfer back docs");
            setLoading(true);
            setSubmitSuccess(false);
            setOpenBackdrop(true);
            var payloadFun = JSON.stringify({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verifie_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: 54000,
              order_remarks: order ? order : null,
              missingDouments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log("Data to approve_reject Funeral scheme: ", payloadFun);
            var configFun = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: payloadFun,
            };

            axios(configFun)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            //If else condition to check file exists or not
            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");
                      setLoading(true);
                      setOpenBackdrop(true);
                      setSubmitSuccess(false);

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: 54000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Transfered Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve and Rejecr API call for Funeral Scheme
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log(
                props.location.schemeDetails.scheme_avail_status,
                "Forwarded"
              );
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var payloadFuneral = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: 54000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Data to draft-approve-reject: ", payloadFuneral);
              var configFuneral = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: payloadFuneral,
              };

              axios(configFuneral)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var dataFuneral = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: 54000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to final funeral: ", dataFuneral);
              var funeralConfig = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataFuneral,
              };

              axios(funeralConfig)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (
          schemeDetailsAll.scheme_name === "Pension Scheme" ||
          schemeDetailsAll.scheme_name === "Disability Pension" ||
          schemeDetailsAll.scheme_name ===
            "Continuation of Disability Pension" ||
          schemeDetailsAll.scheme_name === "Continuation of Pension"
        ) {
          console.log("Inside Pension");
          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("Inside transfer back document details");
            setLoading(true);
            setSubmitSuccess(false);
            setOpenBackdrop(true);
            var paylodPension = JSON.stringify({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verifie_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: 2000,
              order_remarks: order ? order : null,
              missingDouments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log(
              "Data to approve_reject Funeral scheme: ",
              paylodPension
            );
            var configPension = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: paylodPension,
            };

            axios(configPension)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");
                console.log(showToast, "show toast");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            //If else condition to check file exists or not

            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");
                      setLoading(true);
                      setSubmitSuccess(false);
                      setOpenBackdrop(true);

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: 2000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Transfered Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve and Reject API call for Pension Schemess
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log("pension scheme forwarded for approval");
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var pensionPayload = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: 2000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Draft Pension ", pensionPayload);
              var pensionConfig = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: pensionPayload,
              };

              axios(pensionConfig)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
              //
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var dataPension = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: 2000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to final funeral: ", dataPension);
              var configPension2 = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataPension,
              };

              axios(configPension2)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (
          schemeDetailsAll.scheme_name.includes("Thayi Magu Sahaya Hasta")
        ) {
          console.log("Inside Thayi Magu");
          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("Transfer back Inside Thayi");
            setLoading(true);
            setOpenBackdrop(true);
            setSubmitSuccess(false);
            var payloadThayi = JSON.stringify({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verifie_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: 6000,
              order_remarks: order ? order : null,
              missingDouments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log(
              "Data to approve_reject Funeral scheme: ",
              payloadThayi
            );
            var configThayi = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: payloadThayi,
            };

            axios(configThayi)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            //If Else condition to check file exists or not
            //Forwarding
            if (selectedfiles && selectedfiles.length > 0) {
              setOpenBackdrop(true);
              setLoading(true);
              setSubmitSuccess(false);
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: 6000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast("SUCCESS", "6!");

                          allValues.props_this.history.push("/admin/dashboard");

                          setOpenBackdrop(false);
                          setLoading(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve and Reject API call
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log("Thayi scheme forwarded for approval");
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var thayiPayload = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: 6000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Draft Thayi", thayiPayload);
              var thayiConfig = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: thayiPayload,
              };
              axios(thayiConfig)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
              //
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var thayiFinalApprove = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: 6000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to final funeral: ", thayiFinalApprove);
              var configThayiApprove = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: thayiFinalApprove,
              };

              axios(configThayiApprove)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (
          schemeDetailsAll.scheme_name.includes("Disability Pension")
        ) {
          console.log("inside Disability");
          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("called IT");
            setLoading(true);
            setOpenBackdrop(true);
            setSubmitSuccess(false);
            var payloadDiasbility = JSON.stringfy({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verified_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: 2000,
              order_remarks: order ? order : null,
              missingDocuments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log(
              "Data to approve_reject Funeral scheme: ",
              payloadDiasbility
            );
            var config_disability = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: payloadDiasbility,
            };

            axios(config_disability)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: 2000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Transfered Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve and Reject API call
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log("Disability Pension");
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var payloadDis = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: 2000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Draft Disability Pension payload: ", payloadDis);
              var configDis = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: payloadDis,
              };
              axios(configDis)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
              //
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var disFinalApprove_2 = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: 2000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to final funeral: ", disFinalApprove_2);
              var configDis = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: disFinalApprove_2,
              };

              axios(configDis)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setOpenBackdrop(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (
          schemeDetailsAll.scheme_name.includes(
            "Pre Coaching (UPSC and KPSC) Application"
          )
        ) {
          console.log("UPSC and KPSC");
          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details !== null
          ) {
            console.log("UPSC and KPSC Transfer Back");
            setLoading(true);
            setSubmitSuccess(false);
            setOpenBackdrop(true);
            var payloadUpsc = JSON.stringfy({
              schemes_availed_transaction_id:
                schemeDetailsAll.scheme_transaction_id,
              catalog_value_status_id: approveButton ? 47 : 1147,
              description: allValues.commentInspector,
              board_id: 1,
              login_user_id: users.login_admin.data.department_user_id,
              verified_document_id:
                schemeDetailsAll.transfer_back_document_details ||
                schemeDetailsAll.verification_document_id,
              sanctioned_amount: null,
              order_remarks: order ? order : null,
              missingDocuments:
                missingFilesResults && missingFilesResults.length > 0
                  ? missingFilesResults
                  : [],
            });
            console.log(
              "Data to approve_reject Funeral scheme: ",
              payloadDiasbility
            );
            var config_Upsc = {
              method: "post",
              url: SERVER + "/schemes/approve_reject_scheme",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: payloadUpsc,
            };

            axios(config_Upsc)
              .then(function (response) {
                console.log(
                  "approve_reject_scheme response: " +
                    JSON.stringify(response.data)
                );

                showToast("SUCCESS", "Scheme Forwarded Successfully!");

                allValues.props_this.history.push("/admin/dashboard");

                setLoading(false);
                setOpenBackdrop(false);
                setSubmitSuccess(true);
              })
              .catch(function (error) {
                console.error("approve_reject_scheme error: " + error);
              });
          } else {
            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 8");
                      setLoading(true);
                      setOpenBackdrop(true);
                      setSubmitSuccess(false);

                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        labour_card_holder_photo_id:
                          JSON.stringify(labourPhoto),
                        sanctioned_amount: 6000,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast(
                            "SUCCESS",
                            "Scheme Transfered Successfully!"
                          );

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
              //Draft Approve or Reject For UPSC
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log("Disability Pension");
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var draftPayload = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: null,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("Draft Disability Pension payload: ", draftPayload);
              var configDraft = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: draftPayload,
              };
              axios(configDraft)
                .then(function (response) {
                  console.log(
                    "draft-approve response: " + JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", "Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully!");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("draft_approve_reject error: " + error);
                });
              //
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var finalApprove = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                sanctioned_amount: 2000,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to final funeral: ", finalApprove);
              var config_approve = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: finalApprove,
              };

              axios(config_approve)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setOpenBackdrop(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          }
        } else if (schemeDetailsAll.scheme_name.includes("BMTC Bus Pass")) {
          console.log("inside BMTC");
          if (
            schemeDetailsAll.transfer_back_document_details !== undefined &&
            schemeDetailsAll.transfer_back_document_details === null
          ) {
          }
        } else {
          if (allValues.commentInspector !== "") {
            console.log("Called 4");

            // IF else conditon to check file-exists or not

            if (selectedfiles && selectedfiles.length > 0) {
              let promises = [];
              selectedfiles.forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (promises.length === selectedfiles.length) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);

                      var filelist = [];
                      results.forEach((i) => {
                        var filejson = {
                          file_id: i.image.fileId,
                          file_name: i.image.fileType,
                        };
                        filelist.push(filejson);
                      });

                      console.log("filelist: ", filelist);

                      console.log("Called 6");
                      setOpenBackdrop(true);
                      setLoading(true);
                      setSubmitSuccess(false);
                      var data = JSON.stringify({
                        schemes_availed_transaction_id:
                          schemeDetailsAll.scheme_transaction_id,
                        catalog_value_status_id: approveButton ? 47 : 1147,
                        description: allValues.commentInspector,
                        board_id: 1,
                        login_user_id:
                          users.login_admin.data.department_user_id,
                        verified_document_id:
                          JSON.stringify(filelist) && JSON.stringify(filelist)
                            ? JSON.stringify(filelist)
                            : null,
                        sanctioned_amount: null,
                        order_remarks: order ? order : null,
                        missingDocuments:
                          missingFilesResults &&
                          JSON.stringify(missingFilesResults),
                      });
                      console.log("Data to approve_reject_scheme: ", data);
                      var config = {
                        method: "post",
                        url: SERVER + "/schemes/approve_reject_scheme",
                        headers: {
                          Authorization: `Bearer ${getTokenCookie()}`,
                        },
                        data: data,
                      };

                      axios(config)
                        .then(function (response) {
                          console.log(
                            "approve_reject_scheme response: " +
                              JSON.stringify(response.data)
                          );

                          showToast("SUCCESS", "Scheme Approved Successfully!");

                          allValues.props_this.history.push("/admin/dashboard");

                          setLoading(false);
                          setOpenBackdrop(false);
                          setSubmitSuccess(true);
                        })
                        .catch(function (error) {
                          console.error(
                            "approve_reject_scheme error: " + error
                          );
                        });
                    });
                  }
                };
              });
            } else if (
              props &&
              props.location.schemeDetails.scheme_avail_status === "Forwarded"
            ) {
              console.log("status", status);
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              var noFile = JSON.stringify({
                availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                statusId: status,
                description: allValues.commentInspector,
                boardId: 1,
                departmentUserId: users.login_admin.data.department_user_id,
                sanctionedAmountMed: deliveryChildMale ? 50000 : 50000,
                verifiedDocuments: [],
                orderRemarks: order ? order : null,
                missingDocuments: [],
              });
              console.log("data to draft-approve", noFile);
              var configNew = {
                method: "post",
                url: SERVER + "/schemes/draft/approve-reject",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataDeli,
              };

              axios(configNew)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  if (status === 1152) {
                    showToast("SUCCESS", " Draft Approved Successfully!");
                  } else {
                    showToast("ERROR", "Draft Rejected Successfully");
                  }

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            } else {
              setLoading(true);
              setSubmitSuccess(false);
              setOpenBackdrop(true);
              const NoFiledata = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,

                sanctioned_amount: null,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to approve_reject_scheme: ", data);
              var NoFileConfig = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: NoFiledata,
              };

              axios(NoFileConfig)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Approved Successfully!");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setSubmitSuccess(true);
                  setOpenBackdrop(false);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
            console.log("approve_reject_scheme1 ", isFinalApproval);

            console.log("approve_reject_scheme  ", isAmountSanctioned);
          }
        }
      } else {
        console.log("INLOOP5");
        if (isFinalApproval === false && isAmountSanctioned === true) {
          if (allValues.commentInspector !== "") {
            if (
              schemeDetailsAll.transfer_back_document_details !== null &&
              selectedfiles.length === 0
            ) {
              console.log("Called 5");
              setLoading(true);
              setOpenBackdrop(true);
              setSubmitSuccess(false);
              var data = JSON.stringify({
                schemes_availed_transaction_id:
                  schemeDetailsAll.scheme_transaction_id,
                catalog_value_status_id: approveButton ? 47 : 1147,
                description: allValues.commentInspector,
                board_id: 1,
                login_user_id: users.login_admin.data.department_user_id,
                verified_document_id:
                  schemeDetailsAll.transfer_back_document_details,
                sanctioned_amount: null,
                order_remarks: order ? order : null,
                missingDocuments:
                  missingFilesResults && missingFilesResults.length > 0
                    ? missingFilesResults
                    : [],
              });
              console.log("Data to approve_reject_scheme: ", data);
              var config = {
                method: "post",
                url: SERVER + "/schemes/approve_reject_scheme",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  console.log(
                    "approve_reject_scheme response: " +
                      JSON.stringify(response.data)
                  );

                  showToast("SUCCESS", "Scheme Forwarded Successfully!16");

                  allValues.props_this.history.push("/admin/dashboard");

                  setLoading(false);
                  setOpenBackdrop(false);
                  setSubmitSuccess(true);
                })
                .catch(function (error) {
                  console.error("approve_reject_scheme error: " + error);
                });
            } else {
              //If else condition to check file-exists or not
              if (selectedfiles && selectedfiles.length > 0) {
                let promises = [];
                selectedfiles.forEach(async (i) => {
                  var selectedblob = i;
                  var reader = new FileReader();
                  reader.readAsDataURL(selectedblob);
                  var formdataobj = "";
                  var fileName = "";
                  reader.onloadend = await function (event) {
                    fileName = selectedblob.name;
                    var base64data = event.target.result;
                    formdataobj = {
                      file: base64data,
                      fileType: fileName,
                    };
                    promises.push(uploadfile(formdataobj, fileName));
                    if (promises.length === selectedfiles.length) {
                      Promise.all(promises).then((results) => {
                        console.log("results of promise: ");
                        console.log(results);

                        var filelist = [];
                        results.forEach((i) => {
                          var filejson = {
                            file_id: i.image.fileId,
                            file_name: i.image.fileType,
                          };
                          filelist.push(filejson);
                        });

                        console.log("filelist: ", filelist);

                        console.log("Called 6");
                        setLoading(true);
                        setOpenBackdrop(true);
                        setSubmitSuccess(false);
                        var data = JSON.stringify({
                          schemes_availed_transaction_id:
                            schemeDetailsAll.scheme_transaction_id,
                          catalog_value_status_id: approveButton ? 47 : 1147,
                          description: allValues.commentInspector,
                          board_id: 1,
                          verified_document_id:
                            JSON.stringify(filelist) && JSON.stringify(filelist)
                              ? JSON.stringify(filelist)
                              : null,
                          labour_card_holder_photo_id:
                            JSON.stringify(labourPhoto),
                          login_user_id:
                            users.login_admin.data.department_user_id,
                          sanctioned_amount: null,
                          order_remarks: order ? order : null,
                          missingDocuments:
                            missingFilesResults &&
                            JSON.stringify(missingFilesResults),
                        });
                        console.log("Data to approve_reject_scheme: ", data);
                        var config = {
                          method: "post",
                          url: SERVER + "/schemes/approve_reject_scheme",
                          headers: {
                            Authorization: `Bearer ${getTokenCookie()}`,
                          },
                          data: data,
                        };

                        axios(config)
                          .then(function (response) {
                            console.log(
                              "approve_reject_scheme response: " +
                                JSON.stringify(response.data)
                            );

                            showToast(
                              "SUCCESS",
                              "Scheme Forwarded Successfully!"
                            );

                            allValues.props_this.history.push(
                              "/admin/dashboard"
                            );

                            setLoading(false);
                            setOpenBackdrop(false);
                            setSubmitSuccess(true);
                          })
                          .catch(function (error) {
                            console.error(
                              "approve_reject_scheme error: " + error
                            );
                          });
                      });
                    }
                  };
                });
                // } else if (
                //   props &&
                //   props.location.schemeDetails.scheme_avail_status === 'Forwarded'
                // ) {
                //   console.log(
                //     props.location.schemeDetails.scheme_avail_status,
                //     'newww',
                //   );
                //   var draftPayload = JSON.stringify({
                //     availedTransactionId: schemeDetailsAll.scheme_transaction_id,
                //     statusId: status,
                //     description: allValues.commentInspector,
                //     boardId: 1,
                //     departmentUserId: users.login_admin.data.department_user_id,
                //     sanctionedAmount: null,
                //     verificationDocuments: [],
                //     orderRemarks: order ? order : null,
                //     missingDocuments: [],
                //   });
                //   console.log('data to draft-approve: ', draftPayload);
                //   var config5 = {
                //     method: 'post',
                //     url: SERVER + '/schemes/draft/approve-reject',
                //     headers: {
                //       Authorization: `Bearer ${getTokenCookie()}`,
                //     },
                //     data: draftPayload,
                //   };
                //   axios(config5)
                //     .then(function (response) {
                //       console.log(
                //         'draft-approve response: ' +
                //           JSON.stringify(response.data),
                //       );

                //       if (status === 1152) {
                //         showToast('SUCCESS', 'Draft Approved Successfully!');
                //       } else {
                //         showToast('ERROR', 'Draft Rejected Successfully!');
                //       }

                //       allValues.props_this.history.push('/admin/dashboard');

                //       setLoading(false);
                //       setSubmitSuccess(true);
                //     })
                //     .catch(function (error) {
                //       console.error('draft_approve_reject error: ' + error);
                //     });
              } else {
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                const NoFileData = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  //    "verified_document_id": JSON.stringify(filelist) && JSON.stringify(filelist) ? JSON.stringify(filelist) : null,
                  labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                  login_user_id: users.login_admin.data.department_user_id,
                  sanctioned_amount: null,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                const NoFileConfig = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: NoFileData,
                };

                axios(NoFileConfig)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              }
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
            console.log("approve_reject_scheme1 ", isFinalApproval);

            console.log("approve_reject_scheme  ", isAmountSanctioned);
          }
        } else if (
          (isFinalApproval === false && isAmountSanctioned === null) ||
          (isFinalApproval === false && isAmountSanctioned === false)
        ) {
          console.log("schemeName22", schemeDetailsAll.scheme_name);

          if (allValues.commentInspector !== "") {
            console.log("Inloop12", schemeDetailsAll);
            console.log(
              "Inloop12",
              users.getSchemesOnStatus.data.recordsets[0][0]
                .transfer_back_document_details
            );
            if (schemeDetailsAll.scheme_name.includes("Marriage Assistance")) {
              console.log("inside Marriage");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 7");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var dataMed = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: 50000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", dataMed);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: dataMed,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Forwarded Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                // If else condition to check file exists or not
                if (selectedfiles && selectedfiles.length > 0) {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 8");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);
                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: null,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Transfered Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  var daraMar = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: null,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", daraMar);
                  var marConfig = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: daraMar,
                  };

                  axios(marConfig)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Accident Assistance")
            ) {
              console.log("accidentAmount1", accidentAmount);
              console.log("inside Accident");
              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                setLoading(true);
                setSubmitSuccess(false);
                setOpenBackdrop(true);
                console.log("Called 9");
                const data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: accidentAmount,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme:123 ", data);
                const config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Fowarded Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 10");
                          setLoading(true);
                          setSubmitSuccess(false);
                          setOpenBackdrop(true);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: accidentAmount,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );
                              setLoading(false);
                              setSubmitSuccess(true);
                              setOpenBackdrop(false);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setSubmitSuccess(false);
                  setOpenBackdrop(true);
                  const NoFileData = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: accidentAmount,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const NoFileConfig = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: NoFileData,
                  };

                  axios(NoFileConfig)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes(
                "Funeral Expense and Death Assistance"
              )
            ) {
              console.log("inside Funeral");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 11");
                setLoading(true);
                setSubmitSuccess(false);
                setOpenBackdrop(true);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: 54000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 12");
                          setLoading(true);
                          setSubmitSuccess(false);
                          setOpenBackdrop(true);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: 54000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: 54000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name === "Pension Scheme" ||
              schemeDetailsAll.scheme_name === "Disability Pension" ||
              schemeDetailsAll.scheme_name ===
                "Continuation of Disability Pension" ||
              schemeDetailsAll.scheme_name === "Continuation of Pension"
            ) {
              console.log("inside Pension");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 13");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: 2000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Forwarded Successfully10!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 14");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: 2000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Approved Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else if (
                  props &&
                  props.location.schemeDetails.scheme_avail_status ===
                    "Forwarded"
                ) {
                  console.log("status for accident ", status);
                  var payload = JSON.stringify({
                    availedTransactionId:
                      schemeDetailsAll.scheme_transaction_id,
                    statusId: status,
                    description: allValues.commentInspector,
                    boardId: 1,
                    departmentUserId: users.login_admin.data.department_user_id,
                    sanctionedAmountMed: accidentAmount,
                    verifiedDocuments: [],
                    orderRemarks: order ? order : null,
                    missingDocuments: [],
                  });
                  console.log("data to draft-approve", payload);
                  var configPayload = {
                    method: "post",
                    url: SERVER + "/schemes/draft/approve-reject",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: dataAcd,
                  };

                  axios(configPayload)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      if (status === 1152) {
                        showToast("SUCCESS", " Draft Approved Successfully!");
                      } else {
                        showToast("ERROR", "Draft Rejected Successfully");
                      }

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: 2000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Delivery Assistance")
            ) {
              console.log("inside Delivery");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 15");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: deliveryChildMale ? 50000 : 50000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Transfered Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                //Forwarding Scheme
                console.log("deliveryChildMale", deliveryChildMale);

                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 16");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: deliveryChildMale
                              ? 50000
                              : 50000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: deliveryChildMale ? 50000 : 50000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Thayi Magu Sahaya Hasta")
            ) {
              console.log("inside Thayi");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 17");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: 6000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Forwarded Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 18");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: 6000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,
                    //    "verified_document_id": JSON.stringify(filelist) && JSON.stringify(filelist) ? JSON.stringify(filelist) : null,
                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: 6000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Disability Pension")
            ) {
              console.log("inside Disability");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 19");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: 2000,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Transfered Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 20");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: 2000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: 2000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (
              schemeDetailsAll.scheme_name.includes(
                "Pre Coaching (UPSC and KPSC) Application"
              )
            ) {
              console.log("inside Pre Coaching");

              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                console.log("Called 21");
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: null,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Transfered Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 22");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);

                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: 6000,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log("Data to approve_reject_scheme: ", data);
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,

                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: 6000,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log("Data to approve_reject_scheme: ", data);
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            } else if (schemeDetailsAll.scheme_name.includes("BMTC Bus Pass")) {
              console.log("inside Thayi");

              console.log(
                "BusPAssenter",
                schemeDetailsAll.transfer_back_document_details
              );
              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details === null
              ) {
                console.log("Called 23");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: null,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    // allValues.props_this.history.goBack()
                    // let history = useHistory();
                    // history.goBack()
                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Shrama Samarthya Toolkit")
            ) {
              console.log("inside Thayi");

              console.log(
                "BusPAssenter",
                schemeDetailsAll.transfer_back_document_details
              );
              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details === null
              ) {
                console.log("Called 25");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: null,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme: ", data);
                var config = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data,
                };

                axios(config)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              }
            } else if (
              schemeDetailsAll.scheme_name.includes("Education Assistance")
            ) {
              console.log(
                "Education_Verification2:",
                schemeDetailsAll.verification_document_id
              );
              if (
                schemeDetailsAll.transfer_back_document_details !== undefined &&
                schemeDetailsAll.transfer_back_document_details !== null
              ) {
                console.log("Called 27");
                setLoading(true);
                setOpenBackdrop(true);
                setSubmitSuccess(false);
                var data2 = JSON.stringify({
                  schemes_availed_transaction_id:
                    schemeDetailsAll.scheme_transaction_id,
                  catalog_value_status_id: approveButton ? 47 : 1147,
                  description: allValues.commentInspector,
                  board_id: 1,
                  login_user_id: users.login_admin.data.department_user_id,
                  verified_document_id:
                    schemeDetailsAll.transfer_back_document_details ||
                    schemeDetailsAll.verification_document_id,
                  sanctioned_amount: null,
                  order_remarks: order ? order : null,
                  missingDocuments:
                    missingFilesResults && missingFilesResults.length > 0
                      ? missingFilesResults
                      : [],
                });
                console.log("Data to approve_reject_scheme Edu1: ", data2);
                var config2 = {
                  method: "post",
                  url: SERVER + "/schemes/approve_reject_scheme",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: data2,
                };

                axios(config2)
                  .then(function (response) {
                    console.log(
                      "approve_reject_scheme response: " +
                        JSON.stringify(response.data)
                    );

                    showToast("SUCCESS", "Scheme Approved Successfully!");

                    allValues.props_this.history.push("/admin/dashboard");

                    setLoading(false);
                    setOpenBackdrop(false);
                    setSubmitSuccess(true);
                  })
                  .catch(function (error) {
                    console.error("approve_reject_scheme error: " + error);
                  });
              } else {
                if (selectedfiles && selectedfiles.length > 0) {
                  let promises = [];
                  selectedfiles.forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (promises.length === selectedfiles.length) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);

                          var filelist = [];
                          results.forEach((i) => {
                            var filejson = {
                              file_id: i.image.fileId,
                              file_name: i.image.fileType,
                            };
                            filelist.push(filejson);
                          });

                          console.log("filelist: ", filelist);

                          console.log("Called 28");
                          setLoading(true);
                          setOpenBackdrop(true);
                          setSubmitSuccess(false);
                          var data = JSON.stringify({
                            schemes_availed_transaction_id:
                              schemeDetailsAll.scheme_transaction_id,
                            catalog_value_status_id: approveButton ? 47 : 1147,
                            description: allValues.commentInspector,
                            board_id: 1,
                            login_user_id:
                              users.login_admin.data.department_user_id,
                            verified_document_id:
                              JSON.stringify(filelist) &&
                              JSON.stringify(filelist)
                                ? JSON.stringify(filelist)
                                : null,
                            labour_card_holder_photo_id:
                              JSON.stringify(labourPhoto),
                            sanctioned_amount: null,
                            order_remarks: order ? order : null,
                            missingDocuments:
                              missingFilesResults &&
                              JSON.stringify(missingFilesResults),
                          });
                          console.log(
                            "Data to approve_reject_scheme Education: ",
                            data
                          );
                          var config = {
                            method: "post",
                            url: SERVER + "/schemes/approve_reject_scheme",
                            headers: {
                              Authorization: `Bearer ${getTokenCookie()}`,
                            },
                            data: data,
                          };

                          console.log(
                            "Data to approve_reject_scheme Edu2: ",
                            data
                          );

                          axios(config)
                            .then(function (response) {
                              console.log(
                                "approve_reject_scheme response: " +
                                  JSON.stringify(response.data)
                              );

                              showToast(
                                "SUCCESS",
                                "Scheme Forwarded Successfully!"
                              );

                              allValues.props_this.history.push(
                                "/admin/dashboard"
                              );

                              setLoading(false);
                              setOpenBackdrop(false);
                              setSubmitSuccess(true);
                            })
                            .catch(function (error) {
                              console.error(
                                "approve_reject_scheme error: " + error
                              );
                            });
                        });
                      }
                    };
                  });
                } else {
                  setLoading(true);
                  setOpenBackdrop(true);
                  setSubmitSuccess(false);
                  const data = JSON.stringify({
                    schemes_availed_transaction_id:
                      schemeDetailsAll.scheme_transaction_id,
                    catalog_value_status_id: approveButton ? 47 : 1147,
                    description: allValues.commentInspector,
                    board_id: 1,
                    login_user_id: users.login_admin.data.department_user_id,
                    // "verified_document_id": JSON.stringify(filelist) && JSON.stringify(filelist) ? JSON.stringify(filelist) : null,
                    labour_card_holder_photo_id: JSON.stringify(labourPhoto),
                    sanctioned_amount: null,
                    order_remarks: order ? order : null,
                    missingDocuments:
                      missingFilesResults &&
                      JSON.stringify(missingFilesResults),
                  });
                  console.log(
                    "Data to approve_reject_scheme Education: ",
                    data
                  );
                  const config = {
                    method: "post",
                    url: SERVER + "/schemes/approve_reject_scheme",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: data,
                  };

                  console.log("Data to approve_reject_scheme Edu2: ", data);

                  axios(config)
                    .then(function (response) {
                      console.log(
                        "approve_reject_scheme response: " +
                          JSON.stringify(response.data)
                      );

                      showToast("SUCCESS", "Scheme Approved Successfully!");

                      allValues.props_this.history.push("/admin/dashboard");

                      setLoading(false);
                      setOpenBackdrop(false);
                      setSubmitSuccess(true);
                    })
                    .catch(function (error) {
                      console.error("approve_reject_scheme error: " + error);
                    });
                }
              }
            }
          } else {
            showToast("ERROR", "Please Fill the Comment Field!");
            console.log("approve_reject_scheme1 ", isFinalApproval);

            console.log("approve_reject_scheme  ", isAmountSanctioned);
          }
        }
      }
    }
    console.log(
      "INLOOP412",
      users.getSchemesOnStatus.data.recordsets[0][0]
        .transfer_back_document_details
    );

    console.log("INLOOP4", users.getSchemesOnStatus.data.recordsets);
  };

  const rejectButtonPressed = (e) => {
    if (allValues.commentInspector !== "") {
      setOpenBackdrop(true);
      setLoading(true);
      setSubmitSuccess(false);

      var data = JSON.stringify({
        schemes_availed_transaction_id: schemeDetailsAll.scheme_transaction_id,
        catalog_value_status_id: 46,
        description: allValues.commentInspector,
        board_id: 1,
        login_user_id: users.login_admin.data.department_user_id,
        order_remarks: order ? order : null,
        missingDocuments:
          missingFilesResults && missingFilesResults.length > 0
            ? missingFilesResults
            : [],
      });
      console.log("Data to approve_reject_scheme: ", data);
      var config = {
        method: "post",
        url: SERVER + "/schemes/approve_reject_scheme",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(
            "approve_reject_scheme response: " + JSON.stringify(response.data)
          );

          showToast("ERROR", "Scheme Rejected Successfully!");

          setLoading(false);
          setOpenBackdrop(false);
          setSubmitSuccess(true);

          // allValues.props_this.history.goBack()
          allValues.props_this.history.push("/admin/dashboard");
        })
        .catch(function (error) {
          console.error("approve_reject_scheme error: " + error);
        });
    } else {
      showToast("ERROR", "Please Fill the Comment Field!");
    }
  };

  useEffect(() => {
    if (selectMissingFiles && selectMissingFiles.length > 0) {
      callMissingFileUpload();
    }
  }, [selectMissingFiles]);

  const callMissingFileUpload = () => {
    let promises = [];
    selectMissingFiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectMissingFiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);

            var filelist = [];
            results.forEach((i) => {
              var filejson = {
                file_id: i.image.fileId,
                file_name: i.image.fileType,
              };
              filelist.push(filejson);
            });

            console.log("filelist: ", filelist);
            setMissingFilesResult(filelist);
          });
        }
      };
    });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  var date = new Date();

  return (
    <>
      <div className='root'>
        {loading ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress color='inherit' />
              <p>Please wait...</p>
            </div>
          </Backdrop>
        ) : null}

        {loading ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CircularProgress color='inherit' />
              <p>Please wait...</p>
            </div>
          </Backdrop>
        ) : null}

        {draftSuccess ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <div style={{ display: "block" }}>
              <CheckCircle
                className={classes.backdropCheck}
                style={{ height: "auto", width: "200px" }}
              />
              <p className='final-success-title'>Draft Saved Successfully</p>
            </div>
          </Backdrop>
        ) : null}

        {/* <CssBaseline /> */}
        <Row className='top-div schemes'>
          <Row className='topbar-row'>
            <Col xs={12} md={8} lg={9} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/admin/dashboard/3' className='cal-icon-hover-icn'>
                  <img alt='...' className='cal-icon hover-icn' src={back} />
                </a>
                <img id='logo' src={logo} alt='...' className='logo-img' />
                <h1 className='logo-text'>
                  Karnataka Building & Other Construction Workers Welfare
                  Board(K.B.O.C.W.W.B)
                  <br />
                  Government of Karnataka
                </h1>
              </div>
            </Col>

            <Col>
              <div className='usericon-header-logout'>
                <img alt='...' src={usericon} className='usericon-img' />
                <p>{users.login_admin.data.first_name}</p>
                <Tooltip title='Logout' placement='top-start' arrow interactive>
                  <img
                    alt='...'
                    src={logout}
                    className='logout-img'
                    onClick={() => {
                      props.history.push("/admin");
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      {loadingData ? (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color='inherit' />
        </Backdrop>
      ) : (
        <>
          <Row className='schemes-title-row'>
            <Col xs={12} md={9}>
              <h1>
                Schemes | {schemeDetailsAll && schemeDetailsAll?.scheme_name}
              </h1>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row mb-4'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Applicant Details</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Name of the Applicant </p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={schemeDetailsAll?.labour_name}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of birth</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0
                      ? moment(
                          UserDetails.personal_details[0].date_of_birth
                        ).format("DD/MM/YYYY")
                      : ""
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Age of Applicant</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={Age} disabled />
              </FormControl>
            </Col>

            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Mobile Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    UserDetails.personal_details[0].mobile_no
                  }
                  disabled
                />
              </FormControl>
            </Col>
            {/* <Col xs={12} md={4}>
              <p className="mb-2 mt-4">AADHAAR Number</p>
              <FormControl fullWidth className="formcontrol1">
                <TextField
                  variant="outlined"
                  value={
                    UserDetails?.personal_details?.[0]?.aadhar_no?.replace(
                      /\d(?=\d{4})/g,
                      'X',
                    ) ?? ''
                  }
                  disabled
                />
              </FormControl>
            </Col> */}

            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Ration Card Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    UserDetails.ration_card_details[0].ration_card_no
                  }
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={8}>
              <p className='mb-2 mt-4'>Present Address of Applicant</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].ward_area_village}, ${UserDetails.address_details[0].panhayat_city_town}, ${UserDetails.address_details[0].taluk}, ${UserDetails.address_details[0].district}, ${UserDetails.address_details[0].state}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Address Where Applicant is Registered</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>State</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].state}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>District</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].district}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Taluk</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].taluk}`
                  }
                  disabled
                />
              </FormControl>
            </Col>

            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Gram Panchayath</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].panhayat_city_town}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Village / Ward</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.address_details !== undefined &&
                    UserDetails.address_details.length > 0 &&
                    `${UserDetails.address_details[0].ward_area_village}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Bank Details</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Account Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].account_no}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Bank Name</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].bank_name}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Branch Name</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].bank_branch}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>IFSC Code</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.bank_details !== undefined &&
                    UserDetails.bank_details.length > 0 &&
                    `${UserDetails.bank_details[0].ifsc_code}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>Registration Details</p>
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            <Col xs={12} md={4}>
              <p className='mb-2'>Registration Number</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails !== null &&
                    UserDetails.personal_details !== undefined &&
                    UserDetails.personal_details.length > 0 &&
                    `${UserDetails.personal_details[0].registration_code}`
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={
                    UserDetails &&
                    UserDetails.personal_details[0].is_sevasindhu === 1
                      ? moment(
                          UserDetails.personal_details[0].validity_from_date
                        ).format("DD-MM-YYYY")
                      : moment(
                          UserDetails.personal_details[0].approved_date
                        ).format("DD-MM-YYYY")
                  }
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2'>Date of Completion of 60 Years</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={Age60} disabled />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Age at the Time of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={AgeAtRegistration}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Number of years Registered</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField
                  variant='outlined'
                  value={YearsRegistered}
                  disabled
                />
              </FormControl>
            </Col>
            <Col xs={12} md={4}>
              <p className='mb-2 mt-4'>Renewal date of Registration</p>
              <FormControl fullWidth className='formcontrol1'>
                <TextField variant='outlined' value={RenewalDate} disabled />
              </FormControl>
            </Col>
          </Row>

          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              {schemeDetailsAll &&
                schemeDetailsAll?.scheme_name !== "Pension Scheme" &&
                schemeDetailsAll?.scheme_name !==
                  "Continuation of Disability Pension" && (
                  <p>{schemeDetailsAll?.scheme_name}</p>
                )}
            </Col>
          </Row>

          <Row className='form-row mt-4 config-form searchform'>
            {schemeDetails &&
              schemeDetails[0]?.map((field) => (
                <Col
                  xs={12}
                  md={4}
                  style={{ alignSelf: "end" }}
                  key={field.field}
                >
                  <p className='mb-2 mt-4'>{field.field} </p>
                  <FormControl fullWidth className='formcontrol1'>
                    <TextField
                      variant='outlined'
                      value={
                        field.field.includes("Date")
                          ? moment(field.field_value).format("DD-MM-YYYY")
                          : field.field_value
                      }
                      disabled
                    />
                  </FormControl>
                </Col>
              ))}
          </Row>

          {props.location.schemeDetails.scheme_name !==
            "Funeral Expense and Death Assistance" &&
            displayNonDeathFields !== 1 &&
            props.location.schemeDetails.scheme_name !==
              "Continuation of Pension" &&
            props.location.schemeDetails.scheme_name !==
              "Continuation of Disability Pension" && (
              <>
                <Row className='scheme-subtitle-row'>
                  <Col xs={12} className='searchform-subtitle'>
                    <p>
                      <Translate value='daysWorkCertificateDetails' />
                    </p>
                  </Col>
                </Row>
                <Row className='form-row ninydays-row'>
                  <Col xs={12} className='nintydays-col-5'>
                    <Row className='form-inner-card-plain'>
                      {props &&
                      props.location.schemeDetails
                        .catalog_value_type_of_issuer_id === 55 ? (
                        <>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='typeofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                // name="inspectorName"
                                // onChange={handleChange}
                                value={
                                  props &&
                                  props.location.schemeDetails
                                    .catalog_value_type_of_issuer_id === 55
                                    ? "Labour Inspector"
                                    : props.location.schemeDetails
                                          .catalog_value_type_of_issuer_id ===
                                        56
                                      ? "Registered Trade Union"
                                      : props.location.schemeDetails
                                            .catalog_value_type_of_issuer_id ===
                                          57
                                        ? "Gram Panchayat PDO/Secretary"
                                        : props.location.schemeDetails
                                              .catalog_value_type_of_issuer_id ===
                                            57
                                          ? "Employer of the construction activity"
                                          : ""
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='issuedDate' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  moment(
                                    props.location.schemeDetails.issue_date
                                  ).format("DD-MM-YYYY")
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='labourInspectorName' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails
                                    .labour_inspector_name
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={
                                <Translate value='circleoftheLabourInspector' />
                              }
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.circle_name
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                        </>
                      ) : props &&
                        props.location.schemeDetails
                          .catalog_value_type_of_issuer_id === 56 ? (
                        <>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='typeofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={"Registered Trade Union"}
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='issuedDate' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  moment(
                                    props.location.schemeDetails.issue_date
                                  ).format("DD-MM-YYYY")
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={12}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='unionName' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={unionDetails && unionDetails.name}
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={
                                <Translate value='unionRegistrationNumber' />
                              }
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  unionDetails && unionDetails.registeration_no
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='nameofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.issuer_name
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={12}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='placeofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.issuer_place
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                        </>
                      ) : props &&
                        props.location.schemeDetails
                          .catalog_value_type_of_issuer_id === 57 ? (
                        <>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='typeofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={"Gram Panchayat PDO/Secretary"}
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='issuedDate' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  moment(
                                    props.location.schemeDetails.issue_date
                                  ).format("DD-MM-YYYY")
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='nameofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.issuer_name
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={4}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='placeofIssuer' />}
                            />
                            <FormControl
                              className='formcontrol1 w-100'
                              fullWidth
                            >
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.issuer_place
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                        </>
                      ) : props &&
                        props.location.schemeDetails
                          .catalog_value_type_of_issuer_id === 58 ? (
                        <>
                          <Col xs={12} md={8}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='typeofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={"Employer of the construction activity"}
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='issuedDate' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  moment(
                                    props.location.schemeDetails.issue_date
                                  ).format("DD-MM-YYYY")
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='nameofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails.issuer_name
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                          <Col xs={12} md={6}>
                            <Required
                              className='mt-2 mb-2'
                              title={<Translate value='designationofIssuer' />}
                            />
                            <FormControl className='formcontrol1' fullWidth>
                              <TextField
                                variant='outlined'
                                value={
                                  props &&
                                  props.location.schemeDetails
                                    .issuer_designation
                                }
                                disabled
                              />
                            </FormControl>
                          </Col>
                        </>
                      ) : (
                        ""
                      )}

                      <Col xs={6} className='final-personal-col-1 px-3 mt-4'>
                        <div className=''>
                          <p className='mt-4 mb-2'>
                            {"90 Days Work Certificate"}
                          </p>
                          {props &&
                            props.location.schemeDetails
                              .employement_certificate_document_uploaded_id &&
                            JSON.parse(
                              props.location.schemeDetails
                                .employement_certificate_document_uploaded_id
                            ).map((doc) => (
                              <>
                                <div className='browsebutton2-div'>
                                  <FormControl>
                                    <TextField
                                      variant='outlined'
                                      name={doc.file_name}
                                      value={doc.file_name}
                                      disabled
                                    />
                                  </FormControl>

                                  <input
                                    type='file'
                                    name='photo'
                                    id={`upload-photo_${doc.file_id}`}
                                  />

                                  {!doc.file_name.includes(".pdf") ? (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClick(
                                          e,
                                          "employee_cert",
                                          doc.file_name,
                                          doc.file_id
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  ) : (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClickPDFEMP(
                                          e,
                                          "employee_cert",
                                          doc.file_id
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  )}
                                </div>
                              </>
                            ))}
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}

          {schemeDetailsAll?.scheme_name !== "Pension Scheme" && (
            <Row className='scheme-subtitle-row'>
              <Col xs={12} className='searchform-subtitle'>
                <p>Documents</p>
              </Col>
            </Row>
          )}

          <Row className='form-row mt-4 config-form searchform'>
            {schemeDetails !== undefined &&
              schemeDetails !== null &&
              schemeDetails.length > 0 &&
              schemeDetails[1].map((item) => (
                <Col xs={6} className='final-personal-col-1 px-3 mt-4'>
                  <div className=''>
                    <p className='mt-4 mb-2'>{item.document_type}</p>
                    {item.document_upload_file_xml !== undefined &&
                      JSON.parse(item.document_upload_file_xml).map((doc) => (
                        <>
                          <div className='browsebutton2-div'>
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name={doc.file_name}
                                value={doc.file_name}
                                disabled
                              />
                            </FormControl>

                            <input
                              type='file'
                              name='photo'
                              id={`upload-photo_${doc.file_id}`}
                            />

                            {!doc.file_name.includes(".pdf") ? (
                              <label
                                className='schemes-doc-view'
                                onClick={(e) =>
                                  handleClick(
                                    e,
                                    "marriage_cert",
                                    doc.file_name,
                                    doc.file_id
                                  )
                                }
                              >
                                <Visibility />
                              </label>
                            ) : (
                              <label
                                className='schemes-doc-view'
                                onClick={(e) =>
                                  handleClick2(e, "marriage_cert", doc.file_id)
                                }
                              >
                                <Visibility />
                              </label>
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                </Col>
              ))}

            <Popover
              id={idmarriage_cert}
              open={openmarriage_cert}
              anchorEl={anchorElmarriage_cert}
              onClose={(e) => handleClose(e, "marriage_cert")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <img
                alt='...'
                className='avatar border-gray'
                src={`data:image/jpeg;base64,${documentImgBase64}`}
                onClick={() => {
                  const base64ImageData = `data:image/jpeg;base64,${documentImgBase64}`;

                  const contentType = "image/jpeg";

                  const byteCharacters = atob(
                    base64ImageData.substr(`data:${contentType};base64,`.length)
                  );
                  const byteArrays = [];

                  for (
                    let offset = 0;
                    offset < byteCharacters.length;
                    offset += 1024
                  ) {
                    const slice = byteCharacters.slice(offset, offset + 1024);

                    const byteNumbers = new Array(slice.length);
                    for (let i = 0; i < slice.length; i++) {
                      byteNumbers[i] = slice.charCodeAt(i);
                    }

                    const byteArray = new Uint8Array(byteNumbers);

                    byteArrays.push(byteArray);
                  }
                  const blob = new Blob(byteArrays, { type: contentType });
                  const blobUrl = URL.createObjectURL(blob);

                  window.open(blobUrl, "_blank");
                }}
              />
            </Popover>

            <Popover
              id={idemployee_cert}
              open={openemployee_cert}
              anchorEl={anchorElemployee_cert}
              onClose={(e) => handleClose(e, "employee_cert")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={`data:image/jpeg;base64,${employerCertificateBase64}`}
                  // src={allValues.selected_document_type}

                  onClick={() => {
                    // var win = window.open();
                    // win.document.write('<iframe src="' + sessionStorage.getItem(selected_document_type) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                    const base64ImageData = `data:image/jpeg;base64,${employerCertificateBase64}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </>
            </Popover>

            <Popover
              id={idSelfDeclaration}
              open={openSelfDeclaration}
              anchorEl={anchorElSelfDeclaration}
              onClose={(e) => handleClose(e, "selfDeclaration")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={`data:image/jpeg;base64,${selfDeclarationBase64}`}
                  // src={allValues.selected_document_type}

                  onClick={() => {
                    // var win = window.open();
                    // win.document.write('<iframe src="' + sessionStorage.getItem(selected_document_type) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                    const base64ImageData = `data:image/jpeg;base64,${selfDeclarationBase64}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </>
            </Popover>

            <Popover
              id={idLabourPhoto}
              open={openLabourPhoto}
              anchorEl={anchorElLabourPhoto}
              onClose={(e) => handleClose(e, "labour_photo")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <a href='#'>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={`data:image/jpeg;base64,${labourPhotoBase64}`}
                  onClick={() => {
                    const base64ImageData = `data:image/jpeg;base64,${labourPhotoBase64}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </a>
            </Popover>

            <Popover
              id={idSecondLevel}
              open={openSecondLevel}
              anchorEl={anchorElSecondLevel}
              onClose={(e) => handleClose(e, "second-level-verification")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <a href='#'>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={`data:image/jpeg;base64,${verificationDocBase64}`}
                  // src={allValues.selected_document_type}

                  onClick={() => {
                    // var win = window.open();
                    // win.document.write('<iframe src="' + sessionStorage.getItem(selected_document_type) + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                    const base64ImageData = `data:image/jpeg;base64,${verificationDocBase64}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </a>
            </Popover>
            <Popover
              id={idMissingDOc}
              open={openMissingDoc}
              anchorEl={anchorElMissingDoc}
              onClose={(e) => handleClose(e, "missingDoc")}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <a href='#'>
                <img
                  alt='...'
                  className='avatar border-gray'
                  // id={item.id}
                  src={`data:image/jpeg;base64,${missingDocBase64}`}
                  // src={allValues.selected_document_type}

                  onClick={() => {
                    const base64ImageData = `data:image/jpeg;base64,${missingDocBase64}`;
                    const contentType = "image/jpeg";

                    const byteCharacters = atob(
                      base64ImageData.substr(
                        `data:${contentType};base64,`.length
                      )
                    );
                    const byteArrays = [];

                    for (
                      let offset = 0;
                      offset < byteCharacters.length;
                      offset += 1024
                    ) {
                      const slice = byteCharacters.slice(offset, offset + 1024);

                      const byteNumbers = new Array(slice.length);
                      for (let i = 0; i < slice.length; i++) {
                        byteNumbers[i] = slice.charCodeAt(i);
                      }

                      const byteArray = new Uint8Array(byteNumbers);

                      byteArrays.push(byteArray);
                    }
                    const blob = new Blob(byteArrays, { type: contentType });
                    const blobUrl = URL.createObjectURL(blob);

                    window.open(blobUrl, "_blank");
                  }}
                />
              </a>
            </Popover>
          </Row>
          {/* SELF DECLARATION FORM */}
          {props.location.schemeDetails.scheme_name !==
            "Funeral Expense and Death Assistance" &&
            displayNonDeathFields !== 1 &&
            props.location.schemeDetails.scheme_name !==
              "Continuation of Pension" &&
            props.location.schemeDetails.scheme_name !==
              "Continuation of Disability Pension" && (
              <>
                <Row className='scheme-subtitle-row'>
                  <Col xs={12} className='searchform-subtitle'>
                    <p>Self Declaration Document</p>
                  </Col>
                </Row>
                <Row className='form-row config-form searchform'>
                  <Col xs={6} className='final-personal-col-1 px-3 mt-4'>
                    <div className=''>
                      <p className='mt-4 mb-2'>{"Self Declaration Form"}</p>
                      {props &&
                        props?.location?.schemeDetails
                          ?.document_upload_selfdeclaration_files_xml &&
                        JSON.parse(
                          props?.location?.schemeDetails
                            ?.document_upload_selfdeclaration_files_xml
                        ).map((doc) => (
                          <>
                            <div className='browsebutton2-div'>
                              <FormControl>
                                <TextField
                                  variant='outlined'
                                  name={doc.file_name}
                                  value={doc.file_name}
                                  disabled
                                />
                              </FormControl>

                              <input
                                type='file'
                                name='photo'
                                id={`upload-photo_${doc.file_id}`}
                              />

                              {!doc.file_name.includes(".pdf") ? (
                                <label
                                  className='schemes-doc-view'
                                  onClick={(e) =>
                                    handleClick(
                                      e,
                                      "selfDeclaration",
                                      doc.file_name,
                                      doc.file_id
                                    )
                                  }
                                >
                                  <Visibility />
                                </label>
                              ) : (
                                <label
                                  className='schemes-doc-view'
                                  onClick={(e) =>
                                    handleClickPDFSELFDECLARATION(
                                      e,
                                      "selfDeclaration",
                                      doc.file_id
                                    )
                                  }
                                >
                                  <Visibility />
                                </label>
                              )}
                            </div>
                          </>
                        ))}
                    </div>
                  </Col>
                </Row>
              </>
            )}

          {users?.getDeptUserRole &&
          users?.getDeptUserRole?.data[0]?.role_id !== 1 &&
          get_scheme_status?.data?.recordsets &&
          get_scheme_status?.data?.recordsets[1] &&
          get_scheme_status?.data?.recordsets[1]?.map(
            (data) => data?.approval_status === "Approved"
          ) ? (
            <Row className='scheme-subtitle-row'>
              <Col xs={12} className='searchform-subtitle'>
                <p>Remarks</p>
              </Col>
            </Row>
          ) : null}

          {users &&
          users?.getDeptUserRole &&
          users?.getDeptUserRole?.data[0]?.role_id !== 1 &&
          get_scheme_status &&
          get_scheme_status?.data?.recordsets &&
          get_scheme_status?.data?.recordsets[1] &&
          get_scheme_status?.data?.recordsets[1]?.map(
            (data) => data?.approval_status === "Approved"
          ) ? (
            <Row className='form-row mt-4 config-form searchform'>
              <Col xs={12} className=' schemes mt-4 bank-col-5'>
                <p>
                  <b>
                    {get_scheme_status &&
                      get_scheme_status &&
                      get_scheme_status?.data?.recordsets &&
                      get_scheme_status?.data?.recordsets.length > 1 &&
                      get_scheme_status?.data?.recordsets[1][0]
                        ?.level_approval_name}
                    :&nbsp;&nbsp;{" "}
                  </b>{" "}
                  {get_scheme_status &&
                    get_scheme_status?.data?.recordsets &&
                    get_scheme_status?.data?.recordsets.length > 1 &&
                    get_scheme_status?.data?.recordsets[1][0]?.description}{" "}
                  &&
                </p>
                <br />
                {}

                {/* <p>
                  <b>
                    {get_scheme_status?.data?.recordsets &&
                      get_scheme_status?.data?.recordsets.length > 1 &&
                      get_scheme_status?.data?.recordsets[1][1] &&
                      schemeDetailsAll.scheme_name !==
                        'Medical Assistance'?.level_approval_name}
                    :&nbsp;&nbsp;{' '}
                  </b>{' '}
                  {get_scheme_status?.data?.recordsets &&
                    get_scheme_status?.data?.recordsets.length > 1 &&
                    get_scheme_status?.data?.recordsets[1][1]?.description}
                </p>
                <br /> */}
              </Col>
            </Row>
          ) : null}

          {users &&
          users?.getDeptUserRole &&
          users?.getDeptUserRole?.data[0]?.role_id !== 1 &&
          users.getDeptUserRole.data[0].role_id !== 2 &&
          get_scheme_status?.data?.recordsets &&
          get_scheme_status?.data?.recordsets[1] &&
          get_scheme_status?.data?.recordsets[1]?.map(
            (data) => data?.approval_status === "Approved"
          ) &&
          schemeDetailsAll.scheme_name !== "Delivery Assistance" &&
          schemeDetailsAll.scheme_name !== "Accident Assistance" &&
          schemeDetailsAll.scheme_name !== "Medical Assistance" ? (
            <Row className='scheme-subtitle-row'>
              <Col xs={12} className='searchform-subtitle'>
                <p>Order</p>
              </Col>
            </Row>
          ) : null}

          {users?.getDeptUserRole &&
          users?.getDeptUserRole?.data[0]?.role_id !== 1 &&
          get_scheme_status?.data?.recordsets &&
          get_scheme_status?.data?.recordsets[1] &&
          get_scheme_status?.data?.recordsets[1]?.map(
            (data) => data?.approval_status === "Approved"
          ) &&
          schemeDetailsAll &&
          schemeDetailsAll.scheme_name !== "Delivery Assistance" &&
          schemeDetailsAll.scheme_name !== "Accident Assistance" &&
          schemeDetailsAll.scheme_name !== "Medical Assistance" ? (
            <Row className='form-row mt-4 config-form searchform'>
              <Col xs={12} className=' schemes mt-4 bank-col-5'>
                <p>
                  <b>
                    {get_scheme_status?.data?.recordsets &&
                      get_scheme_status?.data?.recordsets.length > 1 &&
                      get_scheme_status?.data?.recordsets[1][1]
                        ?.level_approval_name}
                    :&nbsp;&nbsp;{" "}
                  </b>{" "}
                  {get_scheme_status?.data?.recordsets &&
                    get_scheme_status?.data?.recordsets.length > 1 &&
                    get_scheme_status?.data?.recordsets[1][1]?.order_remarks}
                </p>
                <br />
              </Col>
            </Row>
          ) : null}

          {isAmountSanctioned && isFinalApproval ? (
            <>
              {props &&
              props?.location?.schemeDetails?.is_approval_authority ? (
                schemeDetailsAll?.scheme_avail_status === "Approved" ? (
                  <>
                    <Row className='scheme-subtitle-row'>
                      <Col xs={12} className='searchform-subtitle'>
                        <p> Amount to be sanctioned</p>
                      </Col>
                    </Row>

                    <Row className='form-row mt-4 config-form searchform'>
                      <Col
                        xs={12}
                        className=' amount-sanctioned mt-2 bank-col-5'
                      >
                        <FormControl fullWidth className='formcontrol1'>
                          <TextareaAutosize
                            variant='outlined'
                            multiline
                            name='sanctioned_amount'
                            placeholder='Enter Your Amount'
                            value={schemeDetailsAll?.sanctioned_Amount}
                            disabled
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className='scheme-subtitle-row'>
                      <Col xs={12} className='searchform-subtitle'>
                        <p> Amount to be sanctioned</p>
                      </Col>
                    </Row>

                    <Row className='form-row mt-4 config-form searchform'>
                      <Col
                        xs={12}
                        className=' amount-sanctioned mt-2 bank-col-5'
                      >
                        <FormControl fullWidth className='formcontrol1'>
                          <TextareaAutosize
                            variant='outlined'
                            multiline
                            name='sanctioned_amount'
                            placeholder='Enter Your Amount'
                            value={allValues.sanctioned_amount}
                            onChange={handleChangeSanc}
                            // helperText = {allValues.costError ? "Amount is more than Labour's Cost" : ""}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  </>
                )
              ) : null}
            </>
          ) : schemeDetailsAll?.scheme_name.includes("Education Assistance") &&
            isAmountSanctioned === null &&
            isFinalApproval ? (
            <>
              {schemeDetailsAll?.scheme_avail_status === "Approved" ? (
                <>
                  {schemeDetailsAll?.scheme_id === 17 ? null : (
                    <Row className='scheme-subtitle-row'>
                      <Col xs={12} className='searchform-subtitle'>
                        <p> Amount to be sanctioned</p>
                      </Col>
                    </Row>
                  )}

                  {schemeDetailsAll?.scheme_id === 17 ? null : (
                    <Row className='form-row mt-4 config-form searchform'>
                      <Col
                        xs={12}
                        className=' amount-sanctioned mt-2 bank-col-5'
                      >
                        <FormControl fullWidth className='formcontrol1'>
                          <TextareaAutosize
                            variant='outlined'
                            multiline
                            name='sanctioned_amount'
                            placeholder='Enter Your Amount'
                            value={schemeDetailsAll?.sanctioned_Amount}
                            disabled
                            // helperText = {allValues.costError ? "Amount is more than Labour's Cost" : ""}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  )}
                </>
              ) : (
                <>
                  {schemeDetailsAll?.scheme_id === 17 ? null : (
                    <Row className='scheme-subtitle-row'>
                      <Col xs={12} className='searchform-subtitle'>
                        <p> Amount to be sanctioned</p>
                      </Col>
                    </Row>
                  )}

                  {schemeDetailsAll?.scheme_id === 17 ? null : (
                    <Row className='form-row mt-4 config-form searchform'>
                      <Col
                        xs={12}
                        className=' amount-sanctioned mt-2 bank-col-5'
                      >
                        <FormControl fullWidth className='formcontrol1'>
                          <TextareaAutosize
                            variant='outlined'
                            multiline
                            name='sanctioned_amount'
                            placeholder='Enter Your Amount'
                            value={
                              allValues.sanctioned_amount ||
                              (educationAmount && educationAmount)
                            }
                            onChange={handleChangeSanc}
                            // helperText = {allValues.costError ? "Amount is more than Labour's Cost" : ""}
                            disabled={disableAmount ? true : false}
                          />
                        </FormControl>
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </>
          ) : null}

          {schemeDetailsAll?.transfer_reason_remarks !== undefined &&
          schemeDetailsAll?.transfer_reason_remarks !== null ? (
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Remark While Transferring</p>
                </Col>
              </Row>

              <Row className='form-row config-form searchform'>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <FormControl fullWidth className='formcontrol1'>
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={4}
                        placeholder='Remark While Tranferring Back'
                        value={schemeDetailsAll?.transfer_reason_remarks}
                        disabled
                      />
                    </FormControl>
                  </div>
                </Col>
              </Row>
            </>
          ) : null}

          {schemeDetailsAll?.transfer_back_document_details !== undefined &&
          schemeDetailsAll?.transfer_back_document_details !== null ? (
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Transferred Back Document and Remark</p>
                </Col>
              </Row>
              <Row className='form-row config-form searchform'>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <p className='mb-2'>Forwarded Document</p>
                    {schemeDetailsAll &&
                      schemeDetailsAll?.transfer_back_document_details !==
                        undefined &&
                      JSON.parse(
                        schemeDetailsAll &&
                          schemeDetailsAll?.transfer_back_document_details
                      ).map((doc) => (
                        <>
                          <div className='browsebutton2-div'>
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name={doc.file_name}
                                value={doc.file_name}
                                disabled
                              />
                            </FormControl>

                            <input
                              type='file'
                              name='photo'
                              id={`upload-photo_${doc.file_id}`}
                            />
                            {!doc.file_name.includes(".pdf") ? (
                              <label
                                className='schemes-doc-view'
                                onClick={(e) =>
                                  handleClick(e, "marriage_cert", doc.file_name)
                                }
                              >
                                <Visibility />
                              </label>
                            ) : (
                              <label
                                className='schemes-doc-view'
                                onClick={(e) =>
                                  handleClick2(e, "marriage_cert", doc.file_id)
                                }
                              >
                                <Visibility />
                              </label>
                            )}
                          </div>
                        </>
                      ))}
                  </div>
                </Col>
                <Col xs={6} className='final-personal-col-1 px-3'>
                  <div className=''>
                    <p className='mb-2'>Remark While Transferring Back</p>
                    <FormControl fullWidth className='formcontrol1'>
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={3}
                        placeholder='Remark While Tranferring Back'
                        value={schemeDetailsAll?.transfer_back_reason_remarks}
                        disabled
                      />
                    </FormControl>
                  </div>
                </Col>

                <Popover
                  id={idmarriage_cert}
                  open={openmarriage_cert}
                  anchorEl={anchorElmarriage_cert}
                  onClose={(e) => handleClose(e, "marriage_cert")}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <a href='#'>
                    <img
                      alt='...'
                      className='avatar border-gray'
                      // id={item.id}
                      src={sessionStorage.getItem(selected_document_type)}
                      // src={allValues.selected_document_type}

                      onClick={() => {
                        const base64ImageData = `${sessionStorage.getItem(
                          selected_document_type
                        )}`;
                        const contentType = "image/jpeg";

                        const byteCharacters = atob(
                          base64ImageData.substr(
                            `data:${contentType};base64,`.length
                          )
                        );
                        const byteArrays = [];

                        for (
                          let offset = 0;
                          offset < byteCharacters.length;
                          offset += 1024
                        ) {
                          const slice = byteCharacters.slice(
                            offset,
                            offset + 1024
                          );

                          const byteNumbers = new Array(slice.length);
                          for (let i = 0; i < slice.length; i++) {
                            byteNumbers[i] = slice.charCodeAt(i);
                          }

                          const byteArray = new Uint8Array(byteNumbers);

                          byteArrays.push(byteArray);
                        }
                        const blob = new Blob(byteArrays, {
                          type: contentType,
                        });
                        const blobUrl = URL.createObjectURL(blob);

                        window.open(blobUrl, "_blank");
                      }}
                    />
                  </a>
                </Popover>
              </Row>
            </>
          ) : null}

          {schemeDetailsAll?.transfer_back_document_details === null &&
          props &&
          props.location.schemeDetails.is_approval_authority ? (
            <Row className='scheme-subtitle-row'>
              <Col xs={12} className='searchform-subtitle'>
                <p>Verification Document</p>
              </Col>
            </Row>
          ) : null}

          {showVerificationDocUpload &&
          schemeDetailsAll?.transfer_back_document_details === null &&
          users.getDeptUserRole.role_id !== 3 ? (
            <>
              <Row className='form-row config-form searchform'>
                <Col xs={6} className=' amount-sanctioned mt-2 bank-col-5'>
                  <Required className='mb-2' title='Verification Document' />
                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedfiles !== null &&
                        selectedfiles.length > 0 &&
                        Array.from(selectedfiles).map((i, ind) => (
                          <FormControl>
                            <TextField
                              variant='outlined'
                              name='verificationDocumentName'
                              value={i.name}
                              onChange={handleChange}
                              disabled
                              InputProps={{
                                endAdornment: (
                                  <InputAdornment position='start'>
                                    <img
                                      alt='...'
                                      src={closeicon}
                                      className='removefilebtn'
                                      onClick={() => removefile(ind)}
                                    />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </FormControl>
                        ))
                      )}
                    </div>
                    <div className='browsebutton2-div'>
                      <label
                        htmlFor={`upload-photo`}
                        className='browse-button btn2 schemes'
                      >
                        <img alt='...' src={folder} />
                        <Translate value='browse' />
                      </label>

                      <input
                        type='file'
                        name='photo'
                        id={`upload-photo`}
                        onChange={(e) => handleFileChange(e)}
                        multiple
                      />
                    </div>
                  </div>
                </Col>
                {props &&
                props.location.schemeDetails.scheme_avail_status ===
                  "Pending" ? (
                  <Col xs={6} className=' amount-sanctioned mt-2 bank-col-5'>
                    <p className='mb-2'>Any other Missing Documents</p>
                    <div className='browsebutton-outerdiv'>
                      <div className='browsebutton2-div filesNames'>
                        {loadingfiles ? (
                          <></>
                        ) : (
                          selectMissingFiles !== null &&
                          selectMissingFiles.length > 0 &&
                          Array.from(selectMissingFiles).map((i, ind) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='missing'
                                value={i.name}
                                onChange={handleChange}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        alt='...'
                                        src={closeicon}
                                        className='removefilebtn'
                                        onClick={() => removeMissingfiles(ind)}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        )}
                      </div>
                      <div className='browsebutton2-div'>
                        <label
                          htmlFor={`upload-photo2`}
                          className='browse-button btn2 schemes'
                        >
                          <img alt='...' src={folder} />
                          <Translate value='browse' />
                        </label>

                        <input
                          type='file'
                          name='photo'
                          id={`upload-photo2`}
                          onChange={(e) => handleMissingFile(e)}
                          multiple
                        />
                      </div>
                    </div>
                  </Col>
                ) : null}

                {schemeDetailsAll?.scheme_name === "Education Assistance" ? (
                  <Col xs={6} className=' amount-sanctioned mt-2 bank-col-5'>
                    <Required
                      className='mb-2'
                      title='Labour Card Holder Photo'
                    />
                    <div className='browsebutton-outerdiv'>
                      <div className='browsebutton2-div filesNames'>
                        {loadingfiles ? (
                          <></>
                        ) : (
                          selectedLabourPhotofiles !== null &&
                          selectedLabourPhotofiles.length > 0 &&
                          Array.from(selectedLabourPhotofiles).map((i, ind) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='labourPhoto'
                                value={i.name}
                                onChange={handleChange}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        alt='...'
                                        src={closeicon}
                                        className='removefilebtn'
                                        onClick={() => removefile(ind)}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        )}
                      </div>
                      <div className='browsebutton2-div'>
                        <label
                          htmlFor={`upload-photo-labour`}
                          className='browse-button btn2 schemes'
                        >
                          <img alt='...' src={folder} />
                          <Translate value='browse' />
                        </label>

                        <input
                          type='file'
                          name='photo-labour'
                          id={`upload-photo-labour`}
                          onChange={(e) => handleLabourPhotoChange(e)}
                          multiple
                        />
                      </div>
                    </div>
                  </Col>
                ) : null}
              </Row>
            </>
          ) : (
            <>
              {(VerificationDocuments !== undefined &&
                VerificationDocuments !== null) ||
              LabourUploadPhoto ? (
                <>
                  <Row className='form-row config-form searchform'>
                    <Col xs={6} className='final-personal-col-1 px-3 '>
                      <div className=''>
                        <p className='mb-2'>Verification Document</p>
                        {VerificationDocuments !== undefined &&
                          VerificationDocuments !== null &&
                          VerificationDocuments.map((doc) => (
                            <>
                              <div
                                key={doc.file_id}
                                className='browsebutton2-div'
                              >
                                <FormControl>
                                  <TextField
                                    variant='outlined'
                                    name={doc.file_name}
                                    value={doc.file_name}
                                    disabled
                                  />
                                </FormControl>

                                <input
                                  type='file'
                                  name='photo'
                                  id={`upload-photo_${doc.file_id}`}
                                />

                                {!doc.file_name.includes(".pdf") ? (
                                  <label
                                    className='schemes-doc-view'
                                    onClick={(e) =>
                                      handleClick(
                                        e,
                                        "marriage_cert",
                                        doc.file_name
                                      )
                                    }
                                  >
                                    <Visibility />
                                  </label>
                                ) : (
                                  <label
                                    className='schemes-doc-view'
                                    onClick={(e) =>
                                      handleClick2(
                                        e,
                                        "marriage_cert",
                                        doc.file_id
                                      )
                                    }
                                  >
                                    <Visibility />
                                  </label>
                                )}
                              </div>
                            </>
                          ))}
                      </div>
                    </Col>
                    {missingDocuments && missingDocuments.length > 0 ? (
                      <Col xs={6} className='final-personal-col-1 px-3 '>
                        <div className=''>
                          <p className='mb-2'>Any Other Missing Documents</p>
                          {missingDocuments &&
                            Array.isArray(missingDocuments) &&
                            missingDocuments.length > 0 &&
                            missingDocuments.map((doc, index) => (
                              <>
                                <div key={index} className='browsebutton2-div'>
                                  <FormControl>
                                    <TextField
                                      variant='outlined'
                                      name={doc.file_name}
                                      value={doc.file_name}
                                      disabled
                                    />
                                  </FormControl>

                                  <input
                                    type='file'
                                    name='photo'
                                    id={`upload-photo_${doc.file_id}`}
                                  />

                                  {!doc.file_name.includes(".pdf") ? (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClick(
                                          e,
                                          "missingDoc",
                                          doc.file_name,
                                          doc.file_id
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  ) : (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClick2(
                                          e,
                                          "missingDoc",
                                          doc.file_id
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  )}
                                </div>
                              </>
                            ))}
                        </div>
                      </Col>
                    ) : null}
                    {props &&
                    props.location.schemeDetails.scheme_avail_status ===
                      "Forwarded" ? (
                      <Col
                        xs={6}
                        className=' amount-sanctioned mt-2 bank-col-5'
                      >
                        <p className='mb-2'>Any other Missing Documents</p>
                        <div className='browsebutton-outerdiv'>
                          <div className='browsebutton2-div filesNames'>
                            {loadingfiles ? (
                              <></>
                            ) : (
                              selectMissingFiles !== null &&
                              selectMissingFiles.length > 0 &&
                              Array.from(selectMissingFiles).map((i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant='outlined'
                                    name='missing'
                                    value={i.name}
                                    onChange={handleChange}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='start'>
                                          <img
                                            alt='...'
                                            src={closeicon}
                                            className='removefilebtn'
                                            onClick={() =>
                                              removeMissingfiles(ind)
                                            }
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            )}
                          </div>
                          <div className='browsebutton2-div'>
                            <label
                              htmlFor={`upload-photo2`}
                              className='browse-button btn2 schemes'
                            >
                              <img alt='...' src={folder} />
                              <Translate value='browse' />
                            </label>

                            <input
                              type='file'
                              name='photo'
                              id={`upload-photo2`}
                              onChange={(e) => handleMissingFile(e)}
                              multiple
                            />
                          </div>
                        </div>
                      </Col>
                    ) : null}
                    {schemeDetailsAll?.scheme_name ===
                    "Education Assistance" ? (
                      <Col xs={6} className='final-personal-col-1 px-3 '>
                        <div className=''>
                          <p className='mb-2'>Labour Card Holder Photo</p>
                          {LabourUploadPhoto !== undefined &&
                            LabourUploadPhoto !== null &&
                            LabourUploadPhoto.map((doc) => (
                              <>
                                <div className='browsebutton2-div'>
                                  <FormControl>
                                    <TextField
                                      variant='outlined'
                                      name={doc.file_name}
                                      value={doc.file_name}
                                      disabled
                                    />
                                  </FormControl>

                                  <input
                                    type='file'
                                    name='labourPhoto'
                                    id={`upload-photo_${doc.file_id}`}
                                  />

                                  {!doc.file_name.includes(".pdf") ? (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClick(
                                          e,
                                          "labour_photo",
                                          doc.file_name
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  ) : (
                                    <label
                                      className='schemes-doc-view'
                                      onClick={(e) =>
                                        handleClick2(
                                          e,
                                          "labour_photo",
                                          doc.file_id
                                        )
                                      }
                                    >
                                      <Visibility />
                                    </label>
                                  )}
                                </div>
                              </>
                            ))}
                        </div>
                      </Col>
                    ) : null}
                  </Row>
                </>
              ) : null}
            </>
          )}

          {
            <>
              <Row className='scheme-subtitle-row'>
                <Col xs={12} className='searchform-subtitle'>
                  <p>Existing E-Card</p>
                </Col>
              </Row>

              <Row className='form-row mt-4 config-form searchform'>
                <div>
                  <Button
                    variant={"outline-secondary"}
                    onClick={handleClickEcard}
                  >
                    <RemoveRedEyeIcon />
                    View E-Card
                  </Button>
                  <Popover
                    style={{ width: "70%" }}
                    id={idEcard}
                    open={openEcard}
                    anchorEl={anchorElEcard}
                    onClose={handleCloseEcard}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <ECardApproved
                      labourUserID={pathName[4]}
                      personalDetails={personalDetails}
                      addressDetails={addressDetails}
                      familyDetails={familyDetails}
                      certificateDetails={certificateDetails}
                      employerDetails={employerDetails}
                    />
                  </Popover>
                </div>
              </Row>
            </>
          }

          {props && props.location.schemeDetails.is_approval_authority ? (
            allValues.props_this.location.showbuttons ? (
              <>
                <Row className='scheme-subtitle-row'>
                  <Col xs={12} className='searchform-subtitle'>
                    <p>Remarks </p>
                  </Col>
                </Row>

                <Row className='form-row mt-4 config-form searchform'>
                  <Col xs={12} md={6} className='note2 schemes mt-4 bank-col-5'>
                    <FormControl fullWidth className='formcontrol1'>
                      <TextareaAutosize
                        variant='outlined'
                        multiline
                        rows={4}
                        name='commentInspector'
                        placeholder='Remarks'
                        value={allValues.commentInspector}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Col>
                  {users && users.getDeptUserRole.data[0].role_id !== 1 ? (
                    <Col
                      xs={12}
                      md={6}
                      className='note2 schemes mt-4 bank-col-5'
                    >
                      <FormControl fullWidth className='formcontrol1'>
                        <TextareaAutosize
                          variant='outlined'
                          multiline
                          rows={4}
                          // name="commentInspector"
                          placeholder='Order'
                          value={order}
                          onChange={(ev) => setOrder(ev.target.value)}
                        />
                      </FormControl>
                    </Col>
                  ) : null}
                </Row>
              </>
            ) : null
          ) : null}

          <Row className='search-subtitle-row pb-5'>
            {allValues.props_this.location.showbuttons &&
            props &&
            props.location.schemeDetails.scheme_avail_status !== "Forwarded" &&
            props.location.schemeDetails.is_approval_authority ? (
              <Col xs={12} className='next-back-button-row mt-4'>
                <Button
                  onClick={handlePreview}
                  variant='outline-primary'
                  className='final-button-accept preview'
                >
                  Preview
                </Button>

                {/* {rejectButton ? (
                  <Button
                    variant="outline-primary"
                    className="back-button"
                    onClick={rejectButtonPressed}
                    style={{ flexGrow: '0.5' }}
                  >
                    Reject
                  </Button>
                ) : null} */}

                {rejectButton ? (
                  <Button
                    variant='outlined'
                    className='back-button'
                    onClick={handleRejectButtonPressed}
                    style={{ flexGrow: "0.5" }}
                  >
                    Reject
                  </Button>
                ) : null}

                <Dialog
                  open={openDialoge}
                  onClose={handleClose}
                  maxWidth='lg'
                  fullWidth
                >
                  <div className='d-flex justify-content-between align-items-center px-3'>
                    <div>
                      <DialogTitle
                        style={{
                          fontFamily: "Arial, Serif",
                          fontWeight: "bold",
                        }}
                      >
                        <h2>
                          ತಿರಸ್ಕರಿಸಲು ಕಾರಣಗಳ ಪಟ್ಟಿ / Rejection Reason List{" "}
                        </h2>
                      </DialogTitle>
                    </div>
                    <div>
                      <ButtonGroup
                        variant='text'
                        aria-label='Basic button group'
                      >
                        <Button onClick={() => setRejectLang(!rejectLang)}>
                          {" "}
                          {rejectLang
                            ? "Click here for English"
                            : "Click here for Kannada"}{" "}
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  <DialogContent>
                    {rejectReason &&
                      rejectReason.length > 0 &&
                      rejectReason.map((reason) => (
                        <div key={reason.id}>
                          <Checkbox
                            value={reason.id}
                            onChange={handleCheckboxChange}
                          />
                          <label>
                            {rejectLang
                              ? reason.rejection_reason_kannada
                              : reason.rejection_reason}
                          </label>
                        </div>
                      ))}
                  </DialogContent>
                  <DialogActions>
                    {/* <RotateLeftIcon /> */}
                    <Button2
                      variant='contained'
                      color='primary'
                      onClick={handleCloseReject}
                    >
                      Close
                    </Button2>
                    <Button2
                      variant='contained'
                      color='error'
                      //onClick={rejectButtonPressed}
                      onClick={handleSubmitReject}
                    >
                      Reject
                    </Button2>
                  </DialogActions>
                </Dialog>

                {showtransferButton ? (
                  <Button
                    variant='outline-primary'
                    className='back-button'
                    onClick={handleClickOpenTransfer}
                    style={{ flexGrow: "0.5" }}
                  >
                    Transfer for verification
                  </Button>
                ) : null}

                <Dialog open={opentransfer} onClose={handleCloseTransfer}>
                  <DialogTitle>Transfer for Verification</DialogTitle>
                  <DialogContent>
                    <DialogContentText>
                      Please select the circle to transfer this application for
                      verification.
                    </DialogContentText>
                    <FormControl
                      variant='outlined'
                      fullWidth
                      className='formcontrol5'
                    >
                      <Select
                        className='select-language mt-2 mb-2'
                        style={{ width: "50%" }}
                        variant='outlined'
                        labelId='demo-simple-select-required-label'
                        value={allValues.circleSelected}
                        name='circleSelected'
                        displayEmpty
                        onChange={handleChange}
                      >
                        <MenuItem value=''>
                          <ListItemText primary='-Select Circle-' />
                        </MenuItem>
                        {circle_details !== null &&
                          circle_details.length > 0 &&
                          circle_details.map((i) => (
                            <MenuItem value={i.id} disabled={!i.isactive}>
                              {scheme_name_ad.circle_name === i.name ? null : (
                                <ListItemText primary={i.name} />
                              )}
                            </MenuItem>
                          ))}
                      </Select>
                    </FormControl>
                    <TextareaAutosize
                      variant='outlined'
                      multiline
                      rows={3}
                      style={{
                        width: "500px",
                        padding: "10px",
                        marginTop: "10px",
                      }}
                      placeholder='Remarks'
                      name='remarkTransfer'
                      value={allValues.remarkTransfer}
                      onChange={handleChange}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant='outline-danger'
                      onClick={handleCloseTransfer}
                    >
                      Cancel
                    </Button>
                    <Button variant='success' onClick={transferButtonPressed}>
                      Submit
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog open={openTransaction} onClose={handleTransactionClose}>
                  <DialogContent>
                    <DialogContentText>
                      Enter Transaction Password for Approval / Reject
                    </DialogContentText>
                    <div className='d-flex justify-content-center'>
                      <input
                        className='change-password-input'
                        type='password'
                        placeholder='Enter Transcation Password'
                        onChange={(ev) =>
                          setTransactionPassword(ev.target.value)
                        }
                        value={transactionPassword}
                      />
                    </div>
                  </DialogContent>
                  <DialogActions>
                    <Button2
                      variant='contained'
                      color='error'
                      onClick={(ev) => setTransactionPassword("")}
                    >
                      Reset
                    </Button2>
                    <Button2
                      variant='contained'
                      color='success'
                      onClick={handleTransactionModal}
                    >
                      Continue
                    </Button2>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openPreview}
                  onClose={handlePreviewClose}
                  maxWidth={"md"}
                >
                  <DialogContent>
                    <SchemeSanctionOrder
                      schemesDetailsData={schemeDetailsData}
                      schemesStatusData={schemeStatus}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button2
                      variant='contained'
                      color='error'
                      onClick={handlePreviewClose}
                    >
                      Close
                    </Button2>

                    {/* <Button2
                      variant="contained"
                      color="success"
                      onClick={() => {
                        handlePreviewClose();
                        submitButtonPressed();
                      }}
                    >
                      Forward / Approve
                    </Button2> */}
                    {/* {rejectButton ? (
                      <Button2
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handlePreviewClose();
                          rejectButtonPressed();
                        }}
                      >
                        Reject
                      </Button2>
                    ) : null} */}
                  </DialogActions>
                </Dialog>

                {!showtransferBackButton ? (
                  approveButton ||
                  scheme_name_ad.scheme_name.includes("BMTC Bus Pass") ? (
                    <Button
                      variant='outline-primary'
                      className='save-button'
                      onClick={() => submitButtonPressed()}
                      style={{ flexGrow: "0.5" }}
                    >
                      Approve
                    </Button>
                  ) : (
                    <Button
                      variant='outline-primary'
                      className='save-button'
                      onClick={() => submitButtonPressed()}
                      style={{ flexGrow: "0.5" }}
                    >
                      Forward
                    </Button>
                  )
                ) : null}

                {showtransferBackButton ? (
                  <Button
                    variant='outline-primary'
                    className='save-button'
                    onClick={() => transferButtonPressed()}
                    style={{ flexGrow: "0.5" }}
                  >
                    Transfer Back for verification
                  </Button>
                ) : null}
              </Col>
            ) : null}
          </Row>

          {/* Butttons for Draft Approve/Reject */}
          <Row className='search-subtitle-row pb-5'>
            {allValues.props_this.location.showbuttons &&
              props &&
              props.location.schemeDetails.scheme_avail_status ===
                "Forwarded" &&
              props.location.schemeDetails.is_approval_authority && (
                <Col xs={12} className='next-back-button-row mt-4'>
                  <>
                    <Button
                      variant='outline-primary'
                      className='final-button-accept '
                      onClick={() => {
                        //setdraftApprove(true);
                        submitButtonPressed(1152);
                      }}
                    >
                      Draft Approve
                    </Button>
                    <Button
                      variant='outline-secondary'
                      className='final-button-reject'
                      onClick={() => {
                        console.log("Draft Reject button Clicked");
                        //setdraftApprove(false);
                        submitButtonPressed(1153);
                      }}
                    >
                      Draft Reject
                    </Button>
                  </>
                </Col>
              )}
          </Row>
        </>
      )}

      {/* Acknowledgement letter */}
      <div>
        <div
          id='divToPrint'
          className='mt-4'
          style={{
            backgroundColor: "#fff",
            width: "210mm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",

            display: "none",
          }}
        >
          <div className='logo-div-ack'>
            <a href='/dashboard-user'>
              <img id='logo' src={logo} alt='...' className='logo-img' />
            </a>{" "}
            <h1 className='logo-text'>
              <Translate value='header.title' /> <br />
              <Translate value='header.sub' />
            </h1>
            <p className='copyright'>Government of Karnataka</p>
          </div>
          <Row className='ack-subtitle-row'>
            <Col xs={12} className='profile-title'>
              <h1>Acknowledgement</h1>
            </Col>
          </Row>
          <p className='ack-success'>
            <img
              src={checkGreen}
              alt='check'
              style={{ height: "auto", width: "28px", marginRight: "10px" }}
            />
            Application Status : Successfully submitted to Labour Inspector
          </p>
          <Row className='ack-table-row mt-5'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Application Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll?.scheme_avail_id}</p>
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Application Date</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>
                <Moment format='DD MMMM YYYY'>{date}</Moment>
              </p>
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Requested Service</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll?.scheme_name}</p>
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Applicant's Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll?.labour_name}</p>
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Residential Address</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{presentAddress}</p> */}
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Village Ward</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'></Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Mobile Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{mobile}</p> */}
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Occupation (Category)</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>
          </Row>
          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Circle Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{schemeDetailsAll && schemeDetailsAll.circle_name}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>Labour Inspector Incharge</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              {/* <p>{officerIncharge}</p> */}
            </Col>
          </Row>
          <Row className='ack-table-row-last mt-5'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col-note'>
              <p>NOTE :</p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>1. Submitted application is subject to verification.</p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>2. Login to application to know the status.</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SchemeForm;
