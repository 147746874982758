import 'date-fns';
import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Check from 'assets/images/check (3).svg'
import downloadicn from 'assets/images/Icon material-file-download.svg'
import Eye from 'assets/images/eye.svg'
import Pending from 'assets/images/wall-clock.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getCatalogDetails, getLabourStatusDetails, get_schemes, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'

import { CancelRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import { getTokenCookie } from 'utils/tools';
import axios from 'axios';
import { Paper } from '@mui/material';

import { CSVLink } from 'react-csv'
import NEWReportsAdmin from './NEWReportsAdmin';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import ReportDashboard from './ReportsDashboard/ReportDashboard';

const KBOCWWB = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        // activestep: users.profile_details.activestep,
        activestep: 1,
        percentComplete: users.profile_details.percentComplete,
        editmode: users.profile_details.editmode,

        reportsSelected: "",

        statusActive: "",
        statusActiveLi: "",
        schemesSelected: "",

    })

    useEffect(() => {

        dispatch(deleteReduxData("get_schemes"))
        dispatch(deleteReduxData("getCatalogDetails"))

    }, []);

    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
            width: '350px'
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));


    // const [extraparams, setextraparams] = React.useState({})
    const [ReportDataNew, setReportDataNew] = React.useState([])
    const [ReportData, setReportData] = React.useState([{}])
    const [ShowSchemesSel, setShowSchemesSel] = React.useState(false)
    const [reportsSel, setreportsSel] = React.useState(["Registrations", "Schemes", "Renewals"])
    const [statusSel, setstatusSel] = React.useState([])
    const [genderSel,setGenderSel] = useState(null)
    const [categorySel,setCategorySel] = useState(null)
    const [religionSel,setReligionSel] = useState(null)
    const [natureOfWorkSel,setNatureOfWorkSel] = useState(null)
    const [schemesSel, setschemesSel] = React.useState(null)

    const [Loading, setLoading] = React.useState(false);
    const [circlename, setcirclename] = React.useState(null);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElstatus, setAnchorElstatus] = React.useState(null);
    const [anchorElschemes, setAnchorElschemes] = React.useState(null);
    const [showDashboard,setShowDashboard] = useState(false)
    const [showDataReports,setShowDataReports] = useState(true)

    const onSelectReportsSelHandler = (event, data) => {

        setAllValues({
            ...allValues,
            reportsSelected: data
        })
        setAnchorEl(null);
    }

    const onSelectStatusHandler = (event, data) => {
        setAllValues({
            ...allValues,
            statusActiveLi: data
        })
        setAnchorElstatus(null);
    }

    const onSelectSchemesHandler = (event, data) => {
        setAllValues({
            ...allValues,
            schemesSelected: data
        })
        setAnchorElschemes(null);
    }

    const onSelectClickHandlerkboc = (event, data) => {
        if (data === "status") {
            setAnchorElstatus(event.currentTarget);
        }
        else if (data === "schemes") {
            setAnchorElschemes(event.currentTarget);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    }
    const handleClosekboc = (event, data) => {
        if (data === "status") {
            setAnchorElstatus(null);
        }
        else if (data === "schemes") {
            setAnchorElschemes(null);
        }
        else {
            setAnchorEl(null);
        }
    }


    const getMax = (arr) => {
        var maxval = arr[0]

        arr.map((i, key) => {
            console.log("i.length= " + Object.keys(i).length)
            console.log("maxval.length= " + Object.keys(maxval).length)
            if (Object.keys(i).length > Object.keys(maxval).length) {
                maxval = i
            }
        })

        console.log("maxval= " + JSON.stringify(maxval))
        return maxval
    }



    useEffect(() => {
        console.log("allValues: ")
        console.log(JSON.stringify(allValues, undefined, 2))
    }, [allValues]);

    useEffect(() => {

            let dataForCatalog = {
                    "board_id": users.getDeptUserRole.data[0].board_id,
                    "catalog_name": "Registration Status"
                };
                dispatch(getCatalogDetails(dataForCatalog))

                let dataForCatalogGender = {
                    "board_id": users.getDeptUserRole.data[0].board_id, "catalog_name": "Gender"
                }
                dispatch(getCatalogDetails(dataForCatalogGender))
                let dataForCatalogCaste = {
                    "board_id": users.getDeptUserRole.data[0].board_id, "catalog_name": "Category"
                }
                
                dispatch(getCatalogDetails(dataForCatalogCaste))
                let dataForCatalogReligion = {
                    "board_id": users.getDeptUserRole.data[0].board_id, "catalog_name": "Religion"
                }
                
                dispatch(getCatalogDetails(dataForCatalogReligion))
                let dataForCatalogNatureofWork = {
                    "board_id": users.getDeptUserRole.data[0].board_id, "catalog_name": "Nature of Work"
                }
                
                dispatch(getCatalogDetails(dataForCatalogNatureofWork))
       
    }, []);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details[0]['name'] === "Registration Status") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setstatusSel(users.catalog_details)
        }

        else if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details[0]['name'] === "Gender") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setGenderSel(users.catalog_details)
        }
        else if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details[0]['name'] === "Category") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setCategorySel(users.catalog_details)
        }
        else if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details[0]['name'] === "Religion") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setReligionSel(users.catalog_details)
        }

        else if (
            users.catalog_details !== undefined &&
            users.catalog_details !== null &&
            users.catalog_details[0]['name'] === "Nature of Work") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setNatureOfWorkSel(users.catalog_details)
        }
        
    }, [users.catalog_details]);


    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.get_schemes !== null &&
            users.get_schemes.error !== undefined &&
            users.get_schemes.error !== null &&
            users.get_schemes.error === true) {
            console.log("ERROR: " + JSON.stringify(users.get_schemes.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.get_schemes !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setschemesSel(users.get_schemes.data)
        }
    }, [users.get_schemes]);

        useEffect(() => {
        

        if (users.getDeptUserRole !== null && users.getDeptUserRole.data !== undefined && users.getDeptUserRole.data.length > 0) {
            let data = JSON.stringify({
                "board_id": users.getDeptUserRole.data[0].board_id,
                "role_id": users.getDeptUserRole.data[0].role_id,
                "department_user_id": users.getDeptUserRole.data[0].department_user_id
            })
            var config = {
                method: 'post',
                url: SERVER + '/config/circle/get_user_mapped_to_circle',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log("getUserMappedToCircle response: " + JSON.stringify(response.data, undefined, 2));
                    console.log("CircleID:",response.data.data);

                    setcirclename(response.data.data[0].circle_id)
                })
                .catch(function (error) {
                    console.error("getUserMappedToCircle error: " + error);
                });

        }

    }, [users.user]);

    const handleBack = () => {
            setShowDashboard(true)
            setShowDataReports(false)
    }



    return (
        <>
     {showDataReports ?    <div style={{margin:'10px 4px'}}>
        <button style={{background:'transparent',outline:'none',border:'none'}} onClick={handleBack}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
        </div>
        : null}
      {showDataReports ? <NEWReportsAdmin status={statusSel} gender={genderSel} category={categorySel} religion={religionSel} natureOfWork={natureOfWorkSel} departmentID={users.getDeptUserRole.data[0].department_user_id && users.getDeptUserRole.data[0].department_user_id} roleID={users.getDeptUserRole.data[0].role_id && users.getDeptUserRole.data[0].role_id} circleID={circlename && circlename}/> : null}
        {showDashboard ? <ReportDashboard/> : null}
        </>
    )
}

export default KBOCWWB;