import React, { useEffect, useState } from 'react';
import {
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import LabourDetails from './LabourDetails/LabourDetails';
import { SERVER } from 'store/actions/user.actions';
import axios from 'axios';
import { getTokenCookie, showToast } from 'utils/tools';
import {
  getUnionBoardDetails,
  labourDetailsAPI,
} from './services/labourDetails.service';
import SchemeFields from './SchemeFields';
import {
  getFieldsValueAPI,
  schemeStatusAPI,
  singleRecordAPI,
} from './services/schemes.service';
import SchemeDocuments from './SchemeDocuments';
import NintyDaysSelfDec from './LabourDetails/NintyDaysSelfDec';
import SchemeTransactions from './SchemeTransactions/SchemeTransactions';
import { Button, Col, Row } from 'react-bootstrap';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import ECardView from './LabourDetails/ECardView';
const SchemesMain = () => {
  const {
    labourUserID,
    schemeID,
    schemeName,
    schemeAvailID,
    schemeStatus,
    schemeTransactionID,
  } = useParams();

  const history = useHistory();

  const [labourDetails, setLabourDetails] = useState(null);
  const [fields, setFields] = useState(null);
  const [documents, setDocuments] = useState(null);
  const [loading, setLoading] = useState(false);
  const [status, setStatus] = useState(null);
  const [union, setUnion] = useState(null);
  const callFieldsValue = async (labourUserID, schemeAvailID) => {
    try {
      const res = await getFieldsValueAPI(labourUserID, schemeAvailID);

      if (res.success) {
        setFields(res.data && res.data[0]);
        setDocuments(res.data && res.data[1]);
      }
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  const callLabourDetails = async (labourUserID) => {
    try {
      const payLoad = {
        board_id: 1,
        key: 'user_id',
        value: labourUserID,
        procedure_name: 'all',
      };

      const res = await labourDetailsAPI(payLoad);
      //   console.log(res.data);
      setLabourDetails(res.data);
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  const singleSchemeStatus = async (schemeAvailID, schemeTransactionID) => {
    try {
      const res = await singleRecordAPI(schemeAvailID, schemeTransactionID);

      setStatus(res.data);
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  //To Get Union Details
  const unionDetails = async (labourUnionID) => {
    console.log('Executing Union Details');
    try {
      const payLoad = {
        id: labourUnionID,
      };

      const res = await getUnionBoardDetails(payLoad);

      setUnion(res.data && res.data[0]);
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  useEffect(() => callLabourDetails(labourUserID), [labourUserID]);
  useEffect(
    () => callFieldsValue(labourUserID, schemeAvailID),
    [labourUserID, schemeAvailID],
  );

  useEffect(
    () => singleSchemeStatus(schemeAvailID, schemeTransactionID),
    [schemeTransactionID, schemeAvailID],
  );

  //To Get Union Details
  useEffect(() => {
    if (status && status.catalog_value_type_of_issuer_id === 56) {
      unionDetails(status.labour_union_id);
    }
  }, [status]);

  const handleBackClick = () => {
    history.goBack();
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      <Row className="top-div schemes">
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <div onClick={handleBackClick} className="cal-icon-hover-icn">
                <img alt="..." className="cal-icon hover-icn" src={back} />
              </div>
              <img id="logo" src={logo} alt="..." className="logo-img" />
              <h1 className="logo-text">
                Karnataka Building & Other Construction Workers Welfare
                Board(K.B.O.C.W.W.B)
                <br />
                Government of Karnataka
              </h1>
            </div>
          </Col>
        </Row>
      </Row>
      <LabourDetails schemeName={schemeName} labourData={labourDetails} />
      {schemeName === 'Pension Scheme' ||
      schemeName === 'Continuation of Pension' ||
      schemeName === 'Continuation of Disability Pension' ? null : (
        <SchemeFields fields={fields} />
      )}
      <SchemeDocuments documents={documents} />
      <ECardView
        labourUserID={labourUserID}
        personalDetails={labourDetails && labourDetails.personal_details[0]}
        addressDetails={labourDetails && labourDetails.address_details[0]}
        familyDetails={labourDetails && labourDetails.family_details}
        certificateDetails={
          labourDetails && labourDetails.certificate_details[0]
        }
        employerDetails={labourDetails && labourDetails.employer_details}
      />
      <NintyDaysSelfDec
        fields={fields}
        schemeName={schemeName}
        status={status}
        union={union}
      />
      <SchemeTransactions
        fields={fields}
        loading={loading}
        setLoading={setLoading}
        status={status}
      />
    </>
  );
};

export default SchemesMain;
