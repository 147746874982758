import * as React from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  TextareaAutosize,
} from '@material-ui/core';
import { Button } from 'react-bootstrap';
import { Divider } from '@mui/material';

const ConfirmationDialog = ({
  openDialog,
  handleCloseConfirmationDialog,
  handleSuccessConfirmationDialog,
  confirmationType,
  confirmationMessage,
}) => {
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleCloseConfirmationDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className="text-center">
          {confirmationType}
        </DialogTitle>
        <Divider />
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center font-weight-bolder"
            style={{ fontWeight: 'bolder' }}
          >
            Are you sure you want to {confirmationMessage} the scheme
            application ?
          </DialogContentText>
          <div className="text-danger">
            Note: Once you {confirmationMessage} the application, the
            application cannot be reverted back.
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            className="px-3"
            onClick={handleSuccessConfirmationDialog}
            variant="success"
          >
            Yes
          </Button>
          <Button
            className="px-3"
            onClick={handleCloseConfirmationDialog}
            variant="danger"
          >
            No
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default ConfirmationDialog;
