import 'date-fns';
import { withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, useMediaQuery, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getConfigBoardDetails, getConfigMinistryDetails, getLabourStatusDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import { PhoneAndroid } from '@material-ui/icons';
import axios from 'axios';
import validator from 'validator';

const CircleMasterForm = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        circleName: "",
        short_name: "",
        code: "",
        description: "",
        address: "",
        phone_no: "",
        alternate_phone_no: "",
        circle_code:"",
        fax: "",
        website: "",
        isEdit: false,
        circle_code_error:false,
    })


    useEffect(() => {
        if(props.location.rowdata !== undefined && 
            props.location.rowdata !== null){

            console.error("props.location.rowdata: "+JSON.stringify(props.location.rowdata))

            var val = props.location.rowdata

            setAllValues({
                ...allValues,
                circleName: val.name,
                code: val.code,
                circleCode:val.circle_code,
                props: props.location.rowdata,
                isEdit: true
            })   
        }
    }, []);

    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
    }, [allValues]);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })   
        if(event.target.name === "circle_code"){
            if(validator.isNumeric(event.target.value) && event.target.value.length <=4)
            {
                setAllValues({
                    ...allValues,
                    circle_code_error:false,
                    [event.target.name]:event.target.value
                })
            }
            else{
                setAllValues({
                    ...allValues,
                    circle_code_error:true,
                    [event.target.name]:event.target.value
                })
            }
        }
    }

    const resetButtonPressed = () => {
        handleClickOpen()
    }

    const saveButtonPressed = () => {
        handleClickOpenSave()
    }

    const [open, setOpen] = React.useState(false);
    const [openSave, setOpenSave] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    
    const handleYes = () => {
        setAllValues({
            ...allValues,
            circleName: "",
            short_name: "",
            code: "",
            description: "",
            address: "",
            phone_no: "",
            alternate_phone_no: "",
            fax: "",
            website: "",
            circle_code:"",
        })   
        handleClose()
    };

    const handleClickOpenSave = () => {
        setOpenSave(true);
    };

    const handleCloseSave = () => {
        setOpenSave(false);
    };
    
    const handleYesSave = () => {
        console.error("in useEffect props:"+props.location.userid )

        if(
            allValues.circleName === "" ||
            // allValues.short_name === "" ||
            allValues.circle_code === "" ||
            allValues.code === ""
        ){
            showToast("ERROR", "Please fill all the required fields.")
        
        }
        else if(allValues.isEdit){
            var data = JSON.stringify({
                "id": allValues.props.id,
                "name": allValues.circleName,
                "code": allValues.code,
                "circle_code": allValues.circle_code,
                "user_id": users.getDeptUserRole.data[0].department_user_id
                
              });
              
              var config = {
                method: 'post',
                url: SERVER +'/config/circle/update_circle',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                showToast("SUCCESS", "Circle Updated Successfully!")
                props.history.push("/admin/circle-master")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
        else{
            var data = JSON.stringify({
                "name": allValues.circleName,
                // "short_name": allValues.short_name,
                "code": allValues.code,
                "circle_code":allValues.circle_code,
                "user_id": users.getDeptUserRole.data[0].department_user_id
                
              });
              
              var config = {
                method: 'post',
                url: SERVER +'/config/circle/insert_circle',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log("Cirlce-details",JSON.stringify(response.data));
                showToast("SUCCESS", "Circle Added Successfully!")
                props.history.push("/admin/circle-master")

              })
              .catch(function (error) {
                console.log(error);
                showToast("ERROR", error.response)
                
              });
        }
        

        handleCloseSave()
    };


    return (
        <>
            <div className="root">

            <Dialog
                // fullScreen={fullScreen}
                open={open}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Reset the form?"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    This will clear all the data.
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="secondary">
                    Cancel
                </Button>
                <Button onClick={handleYes} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                // fullScreen={fullScreen}
                open={openSave}
                // onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle id="responsive-dialog-title">{"Save Changes"}</DialogTitle>
                <DialogContent>
                <DialogContentText>
                    Are you sure you want to save the changes made?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseSave} variant="outlined" color="secondary">
                    No
                </Button>
                <Button onClick={handleYesSave} color="primary">
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)<br/>
                                    Government of Karnataka
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="add-div">
                             <img alt="..." className="cal-icon" src={add}/>
                             <p>Add Board</p>
                            </div>
                        </Col> */}
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Circle Master</p>
                    </Link>
                </Col>
            </Row>

            <Row className="form-row mt-4 config-form">
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Circle Name" />
                        {/* <p className="mb-2">Enter Your Full Name </p>  */}
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Circle Name"
                                name="circleName"
                                value={allValues.circleName}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>
                {/* <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Short Name" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Short Name"
                                name="short_name"
                                value={allValues.short_name}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col> */}
                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Circle Short Name" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Address"
                                name="code"
                                value={allValues.code}
                                onChange={handleChange}
                            />
                        </FormControl>
                </Col>

                <Col xs={12} md={4} className="personal-col-1">
                        <Required className="mb-2" title="Circle Code" />
                        <FormControl fullWidth className="formcontrol1">
                            <TextField
                                variant="outlined"
                                placeholder="Enter Circle Code"
                                name="circle_code"
                                value={allValues.circle_code}
                                onChange={handleChange}
                                helperText={allValues.circle_code_error ? "Please Enter Numbers Only" : null}
                                error={allValues.circle_code_error}
                            />
                        </FormControl>
                </Col>

                {/* <Col xs={12} md={6} className="personal-col-1">
                        <Required className="mb-2 mt-3" title="Description" />
                        <FormControl fullWidth className="formcontrol1">
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    // rowsMax={4}
                                    // defaultValue="Default Value"
                                    placeholder="Enter Description of Board"
                                    name="description"
                                    value={allValues.description}
                                    onChange={handleChange}
                                />
                            </FormControl>
                </Col> */}
                
                <Col xs={12} className="next-back-button-row mt-4">

                                    <Link to="#" 
                                        onClick={resetButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            Reset
                                        </Button>
                                    </Link>

                                    <Link to="#" 
                                    //onClick={saveButtonPressed}
                                    style={{ flexGrow: "0.5" }}
                                    >
                                        <Button onClick={handleYesSave} variant="outline-primary" className="save-button">
                                            Save
                                        </Button>
                                    </Link>
                    
                </Col>


            </Row>
        </>
    )
}

export default CircleMasterForm;