import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { Autocomplete } from "@mui/material";
import Select2 from "react-select";

const SchemeForm = (props) => {
  console.log("props", props.location.pathname);
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const [allValues, setAllValues] = React.useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    natureOfWork: "",
    nameError: false,
    nameNomineeError: false,
    phoneError: false,
    phoneNomineeError: false,
    formcontrolnum: "",
    aadharnum1: "",
    aadharnum2: "",
    aadharnum3: "",
    aadhar: "",
    openDatePicker: false,
    medicalAssistanceSelf: false,
    medicalAlphaError: false,
    // medicalCostError:false,
    // minimumDateAdmissionMedical:"01-01-1990",
    // minimumDateAdmissionMedicalDis:"01-01-1990",
    globalError: false,

    states: [],
    districts: [],
    taluks: [],
    cities: [],
    villages: [],
    relations: [],
    naturesOfWork: [],

    // state: "",
    // district: "",
    // taluk: "",
    // city: "",
    // village: "",
    // relation: "",
    // natureOfWork: "",

    getUserDetails: null,
    // personal_details: null,

    // applicantName: "",
    get_dynamic_fields: null,

    // marriage_assistance_for: "",
    // name_of_person_married: "",
    // gender: "",
    // age: "",
    // marriage_date: "",
    // marriage_place: "",
    // marriage_certifate: "",

    id_cardfileId: "",
    bank_accountfileId: "",
    employee_certfileId: "",
    marriage_certfileId: "",

    promResponses: "",

    showBrideData: false,
    descriptionsKannada: i18nlang.locale === "ka" ? true : false,
    showDeathfields: false,
    showMedicalDisability: false,
    showTuitionField: false,
    selfselected: false,
    check_user_availed_schemes: null,
    marriageDeduplication: null,
    marriageCertificateNumber: null,
    showDependentDrowDown: false,
    showBride: false,
    showGroom: false,
    showGroomDropdown: false,
    showBrideDropdown: false,
  });

  let { id } = useParams();
  var idName = id.split("_id=")[0];
  console.log(idName, "IDNAME");
  var role_id = Number(id.split("_id=")[1]);

  const [dob, setDob] = React.useState(users.personal_details.dob);

  const classes = useStyles();

  const [minus_one_doc_for_affidavit, setminus_one_doc_for_affidavit] =
    React.useState(false);
  const [minus_one_doc_for_postmortem, setminus_one_doc_for_postmortem] =
    React.useState(false);
  const [minus_one_doc_for_disability, setminus_one_doc_for_disability] =
    React.useState(false);
  const [marrCertNum, setMarrCertNum] = React.useState("");

  const [filesxmlPresent, setfilesxmlPresent] = React.useState(true);
  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [filelist, setfilelist] = React.useState([]);
  const [selectedfiles, setselectedfiles] = React.useState([]);
  const [fileuploadresults, setfileuploadresults] = React.useState({});
  const [currentdocname, setcurrentdocname] = React.useState({});

  const [fileList90Days, setFileList90Days] = React.useState([]);
  const [selectedFiles90Days, setSelectedFiles90Days] = React.useState([]);
  const [fileUploadResults90Days, setFileUploadResults90Days] = React.useState(
    {}
  );

  const [selfMarriage, setselfMarriage] = React.useState(null);
  const [selfMarriageState, setselfMarriageState] = React.useState(false);

  const [deliveryAssistanceChild, setDeliveryAssistanceChild] =
    React.useState(null);

  const [deliveryChildState, setDeliveryChildState] = React.useState(false);

  const [hideSelfMarriage, setHideSelfMarriage] = React.useState(false);
  const [dependantError, setdependantError] = React.useState(false);

  const [appNo, setappNo] = React.useState("");
  const [role_id_from_hirarchy, setrole_id_from_hirarchy] = React.useState("");
  const [CircleDetails, setCircleDetails] = React.useState({});

  const [kannadaSchemeName, setKannadaSchemeName] = React.useState("");

  const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] =
    React.useState(null);
  const [anchorElninty, setAnchorElninty] = React.useState(null);
  const [anchorElration, setAnchorElration] = React.useState(null);

  const openration = Boolean(anchorElration);
  const idration = openration ? "simple-popover" : undefined;

  const [anchorElIdCard, setAnchorElIdCard] = React.useState(null);
  const openIdCard = Boolean(anchorElIdCard);
  const idIdCard = openration ? "simple-popover" : undefined;

  const [anchorElbank_account, setAnchorElbank_account] = React.useState(null);
  const openbank_account = Boolean(anchorElbank_account);
  const idbank_account = openration ? "simple-popover" : undefined;

  const [anchorElemployee_cert, setAnchorElemployee_cert] =
    React.useState(null);
  const openemployee_cert = Boolean(anchorElemployee_cert);
  const idemployee_cert = openemployee_cert ? "simple-popover" : undefined;

  const [anchorElmarriage_cert, setAnchorElmarriage_cert] =
    React.useState(null);
  const openmarriage_cert = Boolean(anchorElmarriage_cert);
  const idmarriage_cert = openmarriage_cert ? "simple-popover" : undefined;

  const [loading, setLoading] = React.useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [
    marriageCertificateNumberPresent,
    setMarriageCertificateNumberPresent,
  ] = React.useState(false);

  const [AckLetterImg, setAckLetterImg] = React.useState("");
  const [ackId, setackId] = React.useState("");
  const [nameOfDateElement, setnameOfDateElement] = React.useState("");

  const [datefieldname, setdatefieldname] = React.useState("");
  const [datefieldid, setdatefieldid] = React.useState("");
  const [fixedFees, setFixedFees] = React.useState("");
  const [disableTuitionFees, setDisableTuitionFees] = React.useState(false);

  const [hospitalID, setHospitalID] = useState("");
  const [hospitalName, setHospitalName] = useState("");

  const [typeOfIssuer, setTypeOfIssuer] = useState([]);
  const [issuer, setIssuer] = useState(null);
  const [issuedDate, setIssuedDate] = useState(null);

  const [showLIForm, setShowLIForm] = useState(false);
  const [showUnionForm, setShowUnionForm] = useState(false);
  const [showGramaForm, setShowGramaForm] = useState(false);
  const [showEmployerForm, setShowEmployerForm] = useState(false);

  const [inspectorName, setInspectorName] = useState(
    users &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_first_name
  );
  const [inspectorPhone, setInspectorPhone] = useState(
    users &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_mobile_no
  );
  const [inspectorCircle, setInspectorCircle] = useState(
    users &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle_id
  );

  const [union, setUnion] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  const [issuerNameGram, setIssuerNameGram] = useState("");
  const [issuerPlaceGram, setIssuerPlaceGram] = useState("");
  const [issuerPhoneGram, setIssuerPhoneGram] = useState("");

  const [issuerNameEmployer, setIssuerNameEmployer] = useState("");
  const [issuerDesignation, setIssuerDesignation] = useState("");
  const [issuerPhoneEmployer, setIssuerPhoneEmployer] = useState("");

  const [openPicker2, setOpenPicker2] = useState(false);
  const [getDynamicFields, setGetDynamicFields] = useState(null);
  const [getDynamicFieldValue, setGetDynamicFieldValue] = useState(null);
  const [schemeDetails, setSchemeDetails] = useState(null);
  const [schemeDetails2, setSchemeDetails2] = useState(null);

  const [selectedSelfDeclarationFiles, setSelectedSelfDeclarationFiles] =
    useState([]);
  const [selfDeclarationFileList, setSelfDeclarationFileList] = useState(null);
  const [loadingFiles, setLoadingFiles] = useState(true);
  const [removedFile, setRemovedFile] = React.useState(false);

  const setDate = (date) => {
    console.log("in setDate: date= " + date);
    setDob(date);
  };

  const onChangeDate = (e, field) => {
    console.log("field ", field);

    setdatefieldname(field.field_name);
    setdatefieldid(field.id);

    setPickerStatus(true);
    let name = e.currentTarget.parentElement.textContent;
    name = name.split("*")[0].trim();
    console.log(name);
    setnameOfDateElement(name);
  };

  const getFieldValues = async (field) => {
    return new Promise((resolve, reject) => {
      var dataNEW = JSON.stringify({
        board_id: 1,
        field_id: field.id,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_fields_value",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          resolve({
            fieldName: field.field_name,
            data: response.data,
          });
        })
        .catch(function (error) {
          reject(error);
        });
    });
  };

  //90 Days Certificate API CALL's

  useEffect(() => {
    const payload = {
      board_id: 1,
      catalog_name: "Type of Issuer",
    };
    const config = {
      method: "post",
      url: `${SERVER}/global/get_catalog_details`,
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payload,
    };
    axios(config)
      .then((res) => {
        console.log(res.data, "Type of issuer");
        setTypeOfIssuer(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios.get(`${SERVER}/global/get_union_details?board_id=1`).then((res) => {
      console.log(res.data.data);
      const newData = res.data.data.map((item) => ({
        ...item,
        value: item.labour_union_id,
        label: item.name,
      }));
      setUnion(newData);
    });
  }, []);

  const handleDropdownSelect = (selectedOption) => {
    console.log("SelectedUnion", selectedOption);
    setSelectedOption(selectedOption);
  };

  useEffect(() => {
    if (issuer === 55) {
      // LI
      setShowLIForm(true);
      setShowUnionForm(false);
      setShowGramaForm(false);
      setShowEmployerForm(false);
    } else if (issuer === 56) {
      // Union
      setShowLIForm(false);
      setShowUnionForm(true);
      setShowGramaForm(false);
      setShowEmployerForm(false);
    } else if (issuer === 57) {
      // Gram Panchyat
      setShowLIForm(false);
      setShowUnionForm(false);
      setShowGramaForm(true);
      setShowEmployerForm(false);
    } else if (issuer === 58) {
      // Employer of the construction activity
      setShowLIForm(false);
      setShowUnionForm(false);
      setShowGramaForm(false);
      setShowEmployerForm(true);
    }
  }, [issuer]);

  useEffect(() => {
    console.log("i18lan", i18nlang.locale);
    if (i18nlang.locale === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
      });
    } else if (i18nlang.locale === "en") {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
      });
    }

    var data = JSON.stringify({
      board_id: users.user.board_id,
      labour_user_id: users.user.id,
      scheme_id: 0,
      check_for: "all",
    });

    var config = {
      method: "post",
      url: SERVER + "/schemes/check_user_availed_schemes",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data,
    };

    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data.data));

        setAllValues({
          ...allValues,
          check_user_availed_schemes: response.data.data.self,
        });
        console.log(
          "check_user_availed_schemes1",
          response.data.data.self.find((o) => o.is_self === 0)
        );

        // if(response.data.data.self.filter(o => o.scheme_name === "Marriage Assistance") !== null){
        //     console.log("schemeName2212")
        //     setselfMarriage(response.data.data.self.find(o => o.is_self === 0))
        // }
        setselfMarriage(
          response.data.data.self.filter(
            (o) => o.scheme_name === "Marriage Assistance"
          )
        );

        setDeliveryAssistanceChild(
          response.data.data.self.filter(
            (o) => o.scheme_name === "Delivery Assistance"
          )
        );

        // setselfMarriage(allValues.check_user_availed_schemes.find(o => o.scheme_name === "Marriage Assistance"))
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);

  //  useEffect(() => {
  //         setAllValues({
  //             ...allValues,
  //             showDeathfields : false
  //         })

  // },[allValues.showDeathfields])

  // useEffect(() => {
  //     if(allValues.check_user_availed_schemes !== undefined && allValues.check_user_availed_schemes !== null){
  //         console.log("check_user_availed_schemes1", allValues.check_user_availed_schemes.self.find(o => o.scheme_name === "Marriage Assistance")  )
  //         setselfMarriage(allValues.check_user_availed_schemes.self.find(o => o.scheme_name === "Marriage Assistance"))
  //         // if(allValues.check_user_availed_schemes.self.find(o => o.scheme_name === "Marriage Assistance")){

  //         // }
  //         console.log("selfMarriage2", selfMarriage)

  //     }

  // },[allValues.check_user_availed_schemes])

  useEffect(() => {
    console.log("marriageCertificateNumber1212", marrCertNum);
    if (marrCertNum) {
      var data = JSON.stringify({
        certificate_no: marrCertNum,
        scheme_id: 1,
      });

      var config = {
        method: "post",
        url: SERVER + "/global/check_mirrage_certificate",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify("dedup1", response.data.data));

          setAllValues({
            ...allValues,
            marriageDeduplication: response.data,
          });
          console.log(
            "marriageDeduplication11",
            response.data.data[0].is_cerificate_present
          );
          if (response.data.data[0].is_cerificate_present === 1) {
            setMarriageCertificateNumberPresent(true);
            // showToast("ERROR", "The entered Marriage Certificate Number has already been availed")
          } else {
            setMarriageCertificateNumberPresent(false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [marrCertNum]);

  useEffect(() => {
    console.log(selfMarriage, "selfMarriage111");
    if (selfMarriage !== null) {
      var selfMarriageSelected = selfMarriage.find((o) => o.is_self === 1);
      console.log("selfMarriage1", selfMarriageSelected);
      if (
        selfMarriageSelected !== undefined &&
        selfMarriageSelected.total_aviled_by_user !== undefined
      ) {
        if (
          selfMarriageSelected.total_aviled_by_user === 1 &&
          selfMarriageSelected.is_self === 1
        ) {
          setselfMarriageState(true);
          console.log("selfMarriage123");
        }
      }
    }
  }, [selfMarriage]);

  useEffect(() => {
    console.log("deliveryAssistanceChild", deliveryAssistanceChild);
    if (
      deliveryAssistanceChild !== null &&
      deliveryAssistanceChild !== undefined &&
      idName === "Delivery Assistance"
    ) {
      var deliveryAssistanceChildSelected = deliveryAssistanceChild.find(
        (o) => o.is_self === 0
      );
      console.log(
        "deliveryAssistanceChildSelected",
        deliveryAssistanceChildSelected
      );
      if (
        deliveryAssistanceChildSelected !== undefined &&
        deliveryAssistanceChildSelected.total_aviled_by_user !== undefined
      ) {
        if (
          deliveryAssistanceChildSelected.total_aviled_by_user === 1 &&
          deliveryAssistanceChildSelected.is_self === 0
        ) {
          setDeliveryChildState(true);
          console.log("selfMarriage1234");
        } else {
          setDeliveryChildState(false);
        }
      }
    }
  }, [deliveryAssistanceChild]);

  // useEffect(() => {
  //     if(allValues.promResponses !== undefined && allValues.promResponses[0]){
  //         if(allValues.promResponses[0].data.data[2].value == "Self"){
  //             // setselfDropdown(true)
  //         }

  //         }
  // }, [allValues.promResponses])

  useEffect(() => {
    // console.error("in useEffect imgfile ")
    if (
      users.user.id !== undefined &&
      users.user.id !== "" &&
      users.user.id !== null
    ) {
      let dataForRegDetails = {
        key: "user_id",
        value: users.user.id,
        board_id: users.user.board_id,
        procedure_name: "all",
      };
      dispatch(getUserRegistrationDetails(dataForRegDetails));

      var dataNEW = JSON.stringify({
        board_id: 1,
        scheme_id: role_id,
        role_id: null,
        tab_id: null,
      });
      var config = {
        method: "post",
        url: SERVER + "/schemes/get_dynamic_fields",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: dataNEW,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_dynamic_fields: " +
              JSON.stringify(response.data, undefined, 2)
          );
          // setAllValues({
          //     ...allValues,
          //     get_dynamic_fields: JSON.stringify(response.data)
          // })

          var arr1 = "";
          console.log("Responsse", response.data.data);
          var fields = response.data.data;
          setGetDynamicFields(response.data.data);

          const myPromises = [];

          fields.forEach((field) => {
            if (field.is_text_or_value === true) {
              myPromises.push(getFieldValues(field));
            }
          });

          Promise.all(myPromises)
            .then((promResponses) => {
              console.log("Promise Responses", promResponses);
              console.log("fields006", fields);
              setGetDynamicFieldValue(promResponses);

              var config = {
                method: "get",
                url:
                  SERVER +
                  "/schemes/get_scheme_details?board_id=1&scheme_id=" +
                  role_id,
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                // data : dataNEW
              };

              axios(config)
                .then(function (response) {
                  console.log("get_scheme_details Responses", response);
                  setSchemeDetails(response.data.data.document_details);
                  setSchemeDetails2(response.data.data.scheme_details);

                  // setAllValues({
                  //     ...allValues,
                  //     promResponses: promResponses,
                  //     // fields: fields,
                  //     schemeDetails: response.data.data
                  // })
                })
                .catch((err) => {
                  console.log("Error in get_scheme_details", err);
                });

              // setAllValues({
              //     ...allValues,
              //     promResponses: promResponses,
              //     fields: fields
              //     // promResponses: JSON.stringify(promResponses)
              // })
            })
            .catch((err) => {
              console.log("Error in Resolve Promise", err);
            });
        })
        .catch(function (error) {
          console.error(error);
        });
    }
  }, [users.user]);

  useEffect(() => {
    console.log("dob changed: dob= " + dob);
    console.log("datefieldid: ", datefieldid);
    // if (role_id === 1) {

    var minimumDateAdmissionMedical = "01-01-1990";
    var minimumDateAdmissionMedicalDis = moment();

    if (datefieldid === 64) {
      var dateStringMed = dob;
      console.log("dateStringMed: ", dateStringMed);
      var dateObjMed = new Date(dateStringMed);
      var momentObjMed = moment(dateObjMed);
      // var momentAddDay = moment(momentObjMed).add(1, 'days');
      // var momentStringMed = momentAddDay.format('DD-MM-YYYY');
      var momentObjStringMed = momentObjMed.format("DD-MM-YYYY");
      minimumDateAdmissionMedical = momentObjMed;

      // minimumDateAdmissionMedical = momentStringMed
      // console.log("momentStringMedObj = = " + minimumDateAdmissionMedical)
      // console.log("momentStringMed: " + minimumDateAdmissionMedical)
      //
      // console.log(minimumDateAdmissionMedical,"minimum Admission Date")

      // var minimumDateAdmissionMedical1 = "01-01-1990"
    } else if (datefieldid === 65) {
      var dateStringMed1 = dob;
      console.log("dateStringMed1: ", dateStringMed1);
      var dateObjMed1 = new Date(dateStringMed1);
      var momentObjMed1 = moment(dateObjMed1);
      var momentObjStringMed1 = momentObjMed1.format("DD-MM-YYYY");

      minimumDateAdmissionMedicalDis = momentObjMed1;
      // console.log("momentStringMedObj1: " + momentObjStringMed1)
    }

    // if(momentObjStringMed > momentStringMed){
    //     showToast("Minimum required date is 24 hours after admission.")

    // }
    console.log("momentStringMedObjad == " + minimumDateAdmissionMedical);
    console.log("momentStringMedObjdis == " + minimumDateAdmissionMedicalDis);
    // console.log("momentStringMedObj ==== " + dayDiffMed);
    // if (dayDiffMed){
    //         showToast("Minimum required date is 24 hours after admission.")
    //         console.log("momentStringMedObj ==== " + dayDiffMed);

    // }

    setAllValues({
      ...allValues,
      [nameOfDateElement]: dob,
      // [minimumDateAdmissionMedical]: minimumDateAdmissionMedical
    });
    // }
  }, [dob]);

  useEffect(() => {
    // console.log("in useEffect getUserRegistrationDetails -> outside if")
    // console.log("getUserRegistrationDetails: "+users.getUserRegistrationDetails)
    // console.log("users.getUserRegistrationDetails.personal_details: "+users.getUserRegistrationDetails.personal_details)
    // console.log("users.getUserRegistrationDetails.personal_details.length: "+users.getUserRegistrationDetails.personal_details.length)
    if (
      users.getUserRegistrationDetails !== undefined &&
      users.getUserRegistrationDetails !== "Network Error" &&
      users.getUserRegistrationDetails !== null &&
      users.getUserRegistrationDetails.personal_details !== undefined &&
      users.getUserRegistrationDetails.personal_details.length > 0 &&
      users.getUserRegistrationDetails.ration_card_details !== undefined &&
      users.getUserRegistrationDetails.ration_card_details.length > 0 &&
      users.getUserRegistrationDetails.address_details !== undefined &&
      users.getUserRegistrationDetails.address_details.length > 0
    ) {
      console.log("in useEffect getUserRegistrationDetails -> in if");

      var address = "";
      var state = "";
      var district = "";
      var taluk = "";
      var gram = "";
      var village = "";
      if (
        users.getUserRegistrationDetails.address_details[0]
          .address_base_type === "Residential"
      ) {
        address =
          users.getUserRegistrationDetails.address_details[0].door_no +
          ", " +
          users.getUserRegistrationDetails.address_details[0].street_name +
          ", " +
          users.getUserRegistrationDetails.address_details[0].land_mark;
        state = users.getUserRegistrationDetails.address_details[0].state;
        district = users.getUserRegistrationDetails.address_details[0].district;
        taluk = users.getUserRegistrationDetails.address_details[0].taluk;
        gram =
          users.getUserRegistrationDetails.address_details[0]
            .panhayat_city_town;
        village =
          users.getUserRegistrationDetails.address_details[0].ward_area_village;
      } else if (
        users.getUserRegistrationDetails.address_details[0]
          .address_base_type === "Residential"
      ) {
        address =
          users.getUserRegistrationDetails.address_details[0].door_no +
          ", " +
          users.getUserRegistrationDetails.address_details[0].street_name +
          ", " +
          users.getUserRegistrationDetails.address_details[0].land_mark;
        state = users.getUserRegistrationDetails.address_details[0].state;
        district = users.getUserRegistrationDetails.address_details[0].district;
        taluk = users.getUserRegistrationDetails.address_details[0].taluk;
        gram =
          users.getUserRegistrationDetails.address_details[0]
            .panhayat_city_town;
        village =
          users.getUserRegistrationDetails.address_details[0].ward_area_village;
      }

      var bank_name = "";
      var account_no = "";
      var bank_branch = "";
      var ifsc_code = "";
      if (
        users.getUserRegistrationDetails.bank_details[0].is_nominee === false ||
        users.getUserRegistrationDetails.bank_details[0].is_nominee !== false
      ) {
        bank_name = users.getUserRegistrationDetails.bank_details[0].bank_name;
        account_no =
          users.getUserRegistrationDetails.bank_details[0].account_no;
        bank_branch =
          users.getUserRegistrationDetails.bank_details[0].bank_branch;
        ifsc_code = users.getUserRegistrationDetails.bank_details[0].ifsc_code;
      } else if (
        users.getUserRegistrationDetails.bank_details[1].is_nominee === false ||
        users.getUserRegistrationDetails.bank_details[1].is_nominee !== false
      ) {
        bank_name = users.getUserRegistrationDetails.bank_details[1].bank_name;
        account_no =
          users.getUserRegistrationDetails.bank_details[1].account_no;
        bank_branch =
          users.getUserRegistrationDetails.bank_details[1].bank_branch;
        ifsc_code = users.getUserRegistrationDetails.bank_details[1].ifsc_code;
      }

      var dateString =
        users.getUserRegistrationDetails.personal_details[0].date_of_birth;
      var dateObj = new Date(dateString);
      var momentObj = moment(dateObj);
      var momentString = momentObj.format("DD-MM-YYYY");
      // console.log("momentString: " + momentString)

      // var dateObj = new Date(dateString);
      var momentObjToday = moment();
      var momentStringToday = momentObjToday.format("DD-MM-YYYY");
      console.log("momentStringToday: " + momentStringToday);

      var dateString2 =
        users.getUserRegistrationDetails.personal_details[0].application_date;
      var dateStringReg =
        users.getUserRegistrationDetails.personal_details[0].approved_date;
      var dateObj2 = new Date(dateString2);
      var dateObjReg = new Date(dateStringReg);
      var momentObj2 = moment(dateObj2);
      var momentReg = moment(dateObjReg);
      var momentString2 = momentObj2.format("DD-MM-YYYY");
      var momentStringReg = momentReg.format("DD-MM-YYYY");
      console.log("momentString2: " + momentString2);

      var startDate = moment(momentString, "DD-MM-YYYY");
      var endDate = moment(momentString2, "DD-MM-YYYY");
      var yearReg = moment(momentStringReg, "DD-MM-YYYY");
      var yearDiff = endDate.diff(startDate, "years");

      console.log("endDate: " + endDate.format("DD MMM YYYY"));

      // var age_at_registration = moment().diff(momentString, 'years', true);
      var age_at_registration = yearDiff;
      console.log("age_at_registration: " + age_at_registration);

      var years_registered = moment().diff(yearReg, "years", false);

      var renewal_date1 = moment(momentObj2).add(1, "Y");
      var renewal_date = renewal_date1.format("MMM DD YYYY");

      var age60_1 = moment(momentObj).add(60, "Y");
      var age60 = age60_1.format("MMM DD YYYY");

      // var age = moment(momentString, "DD-MM-YYYY").fromNow();

      var age = moment(momentStringToday, "DD-MM-YYYY").diff(
        moment(momentString, "DD-MM-YYYY"),
        "years"
      );
      console.log("age60: " + age60);
      console.log("age: " + age);

      var middlename =
        users.getUserRegistrationDetails.personal_details[0].middle_name ===
        null
          ? ""
          : users.getUserRegistrationDetails.personal_details[0].middle_name;

      var applicantName =
        users.getUserRegistrationDetails.personal_details[0].first_name +
        " " +
        middlename +
        " " +
        users.getUserRegistrationDetails.personal_details[0].last_name;

      setAllValues({
        ...allValues,
        applicantName: applicantName,
        dob: momentString,
        age: age,
        mobile: users.getUserRegistrationDetails.personal_details[0].mobile_no,
        aadhaar: users.getUserRegistrationDetails.personal_details[0].aadhar_no,

        ration:
          users.getUserRegistrationDetails.ration_card_details[0]
            .ration_card_no,

        address: address,
        state: state,
        district: district,
        taluk: taluk,
        gram: gram,
        village: village,

        bank_name: bank_name,
        account_no: account_no,
        bank_branch: bank_branch,
        ifsc_code: ifsc_code,

        registration_code:
          users.getUserRegistrationDetails.personal_details[0]
            .registration_code,
        registration_date:
          users.getUserRegistrationDetails.personal_details[0].is_sevasindhu ===
          1
            ? moment(
                users.getUserRegistrationDetails.personal_details[0]
                  .validity_from_date
              ).format("DD-MM-YYYY")
            : moment(
                users.getUserRegistrationDetails.personal_details[0]
                  .approved_date
              ).format("DD-MM-YYYY"),

        age_at_registration: age_at_registration,
        years_registered: years_registered,
        renewal_date: renewal_date,
        age60: age60,
      });

      // dispatch(getFile(
      //     users.getUserRegistrationDetails.personal_details[0].user_photo_id,
      //     "profilePic")
      // )

      // dispatch(getFile(
      //     users.getUserRegistrationDetails.ration_card_details[0].ration_card_document_upload_id,
      //     "rationCard")
      // )

      // dispatch(getFile(
      //     // users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id,
      //     users.getUserRegistrationDetails.bank_details[0].account_no,
      //     "passbook")
      // )

      // dispatch(getFile(
      //     // users.getUserRegistrationDetails.bank_details[1].bank_passbook_upload_id,
      //     users.getUserRegistrationDetails.bank_details[1].account_no,
      //     "passbook_nominee")
      // )

      // dispatch(getFile(
      //     users.getUserRegistrationDetails.certificate_details[0].document_uploaded_id,
      //     "90_days_certificate")
      // )
    }
  }, [users.getUserRegistrationDetails]);

  useEffect(() => {
    console.log("allValues: " + JSON.stringify(allValues, undefined, 2));

    if (schemeDetails && schemeDetails.length) {
      setKannadaSchemeName(
        schemeDetails2 &&
          schemeDetails2.length &&
          schemeDetails2[0] &&
          schemeDetails2[0].scheme_name_kannada
      );
    }
  }, [schemeDetails]);

  // useEffect(() => {
  //     if(allValues['Marriage Assistance is for'] !== undefined ){
  //         if(allValues.promResponses[0].data.data[0].id === 8 && allValues.promResponses[0].data.data[0].value === "Son" ){
  //             console.log("INBRIDELOOP5")
  //             console.log("INBRIDELOOP1",allValues.promResponses[0].data.data)
  //             setAllValues({
  //                 ...allValues,
  //                 showBrideData: true
  //             })
  //         }

  //         else if(users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 28 && allValues.promResponses[0].data.data[2].id === 10 )
  //         {
  //             console.log("INBRIDELOOP2",allValues.promResponses[0].data.data[0].id)
  //             setAllValues({
  //                 ...allValues,
  //                 showBrideData: false
  //             })
  //         }
  //         else if( users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 31 && allValues.promResponses[2].data.data[2].id === 10 && allValues.promResponses[0].data.data[0].value === "Self")
  //         {
  //             console.log("INBRIDELOOP2",allValues.promResponses[0].data.data[0].id)
  //             setAllValues({
  //                 ...allValues,
  //                 showBrideData: true
  //             })
  //         }
  //         // if(
  //         //         allValues.promResponses[0].data.data[1].id === 9 && allValues.promResponses[0].data.data[1].value === "Daughter")
  //         //         console.log(allValues.promResponses[0].data.data,"INBRIDELOOP4")

  //         //         {
  //         //             console.log("INBRIDELOOP3")
  //         //             setAllValues({
  //         //                 ...allValues,
  //         //                 showBrideData: false
  //         //             })
  //         //         }

  //     }

  // },[allValues['Marriage Assistance is for']]);

  useEffect(() => {
    if (i18nlang.locale === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
      });
    }
  }, [allValues.descriptionsKannada]);

  // useEffect(() => {
  //     console.log("in allValues['Marriage Assistance is for']")
  //     if(
  //         allValues['Marriage Assistance is for'] !== undefined
  //         // &&
  //         // allValues['Gender'] !== undefined
  //         ){
  //         if(
  //
  //             allValues['Marriage Assistance is for'] === 10 &&
  //             users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 28 &&
  //             allValues.promResponses[0].data.data[1].id == 9
  //             // allValues.promResponses[0].data.data
  //             // allValues['Gender'] !== undefined &&
  //             // allValues['Gender'] === 2
  //             ){
  //             setAllValues({
  //                 ...allValues,
  //                 showBrideData: false
  //             })
  //         }
  //         else {
  //             setAllValues({
  //                 ...allValues,
  //                 showBrideData: true
  //             })
  //         }
  //     }

  // }, [ allValues['Marriage Assistance is for'] , allValues['Gender'] ]);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setAllValues({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
    } else {
      setAllValues({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const handleHospital = (e) => {
    setHospitalID(e && e.id);
    setHospitalName(e && e.value);
  };

  const handleChange = (e, field) => {
    console.log("fieldsDynamic", e, field);
    console.log(e.target);
    console.log("SchemeName: ", e.target.value);
    console.log(e.target.name + " : " + e.target.value);

    var nameError = false;
    var nameNomineeError = false;
    var phoneError = false;
    var phoneNomineeError = false;
    var medicalAssistanceSelf = false;

    // var medicalAlphaError = true

    if (e.target.name.includes("Person Hospitalized- Selection")) {
      if (e.target.value === 174) {
        // medicalAssistanceSelf = true
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          nameError: nameError,
          nameNomineeError: nameNomineeError,
          phoneError: phoneError,
          phoneNomineeError: phoneNomineeError,
          medicalAssistanceSelf: true,
          "Name of the dependent (If Dependent is selected above)":
            applicantName,
          ["error_" + 93]: false,
          globalError: false,
          showDependentDrowDown: false,
          // medicalAlphaError : true,
          // medicalCostError : medicalCostError
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          nameError: nameError,
          nameNomineeError: nameNomineeError,
          phoneError: phoneError,
          phoneNomineeError: phoneNomineeError,
          medicalAssistanceSelf: false,
          "Name of the dependent (If Dependent is selected above)": "",
          showDependentDrowDown: true,

          // medicalAlphaError : false,
          // medicalCostError : medicalCostError
        });
      }
    } else if (e.target.name.includes("Person Hospitalized")) {
      if (e.target.value === 38) {
        // medicalAssistanceSelf = true
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          nameError: nameError,
          nameNomineeError: nameNomineeError,
          phoneError: phoneError,
          phoneNomineeError: phoneNomineeError,
          medicalAssistanceSelf: true,
          "Name of the dependent  (If Dependent is selected above)":
            applicantName,
          ["error_" + 60]: false,
          globalError: false,
          showDependentDrowDown: false,

          // medicalAlphaError : true,
          // medicalCostError : medicalCostError
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          nameError: nameError,
          nameNomineeError: nameNomineeError,
          phoneError: phoneError,
          phoneNomineeError: phoneNomineeError,
          "Name of the dependent  (If Dependent is selected above)": "",
          medicalAssistanceSelf: false,
          showDependentDrowDown: true,

          // medicalAlphaError : false,
          // medicalCostError : medicalCostError
        });
      }
    } else if (e.target.name === "Marriage Assistance is for") {
      if (e.target.value === 10) {
        if (
          users.getUserRegistrationDetails.personal_details[0].gender === "Male"
        ) {
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
            showGroom: true,
            showBride: false,
            "Groom Name": applicantName,
            "Bride Name": "",
            ["error_" + 60]: false,
            globalError: false,
            showGroomDropdown: false,
            showBrideDropdown: false,

            // medicalAlphaError : true,
            // medicalCostError : medicalCostError
          });
        }
        if (
          users.getUserRegistrationDetails.personal_details[0].gender ===
          "Female"
        ) {
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
            showGroom: false,
            showBride: true,
            "Bride Name": applicantName,
            "Groom Name": "",
            ["error_" + 60]: false,
            globalError: false,
            showGroomDropdown: false,
            showBrideDropdown: false,

            // medicalAlphaError : true,
            // medicalCostError : medicalCostError
          });
        }
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          nameError: nameError,
          nameNomineeError: nameNomineeError,
          phoneError: phoneError,
          phoneNomineeError: phoneNomineeError,
          showGroom: true,
          showBride: true,
          "Groom Name": "",
          "Bride Name": "",
          showGroomDropdown: false,
          showBrideDropdown: false,

          // medicalAlphaError : false,
          // medicalCostError : medicalCostError
        });
      }
      //Son
      if (e.target.value === 8) {
        console.log(e.target.value, "SOn");
        console.log("Son selected");
        if (
          users.getUserRegistrationDetails.family_details.filter(
            (member) => member.catalog_value_parent_child_type_id === 54
          ).length > 0
        ) {
          let findSon = users.getUserRegistrationDetails.family_details.filter(
            (member) => member.catalog_value_parent_child_type_id === 54
          );

          console.log("Added Son", findSon);
          // let sonName = findSon.map((data)=> data.first_name)

          let sonName = [
            findSon.first_name,
            findSon.middle_name,
            findSon.last_name,
          ].join(" ");
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
            showGroom: true,
            showBride: false,
            showGroomDropdown: true,
            showBrideDropdown: false,
            "Groom Name": sonName,
            "Bride Name": "",

            // medicalAlphaError : false,
            // medicalCostError : medicalCostError
          });
        } else {
          setAllValues({
            ...allValues,
            showGroom: true,
            showBride: true,
            showGroomDropdown: false,
            showBrideDropdown: false,
          });
          showToast(
            "WARN",
            "Selected Dependent (Son) is not added in the family details"
          );
        }
      }
      //Daughter
      if (e.target.value === 9) {
        if (
          users.getUserRegistrationDetails.family_details.filter(
            (member) => member.catalog_value_parent_child_type_id === 53
          ).length > 0
        ) {
          let findDaughter =
            users.getUserRegistrationDetails.family_details.filter(
              (member) => member.catalog_value_parent_child_type_id === 53
            );
          let daughterName = [
            findDaughter.first_name,
            findDaughter.middle_name,
            findDaughter.lastname,
          ].join(" ");
          setAllValues({
            ...allValues,
            [e.target.name]: e.target.value,
            nameError: nameError,
            nameNomineeError: nameNomineeError,
            phoneError: phoneError,
            phoneNomineeError: phoneNomineeError,
            showGroom: false,
            showBride: true,
            "Groom Name": "",
            "Bride Name": daughterName,
            showGroomDropdown: false,
            showBrideDropdown: true,

            // medicalAlphaError : false,
            // medicalCostError : medicalCostError
          });
        } else {
          setAllValues({
            ...allValues,
            showGroom: true,
            showBride: true,
          });
          showToast(
            "WARN",
            "Selected Dependent (Daughter) is not added in the family details"
          );
        }
      }
    } else if (e.target.name.includes("Accident Resulted In")) {
      if (e.target.value === 27) {
        console.log("INDEATHASSISTANCE1");
        console.log("promresponses1");
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showDeathfields: true,
          showMedicalDisability: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showDeathfields: false,
          showMedicalDisability: true,
        });
      }
    } else if (e.target.name.includes("Current Course Studying")) {
      if (
        e.target.value === 219 ||
        e.target.value === 218 ||
        e.target.value === 206 ||
        e.target.value === 208 ||
        e.target.value === 207
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees("");
        setDisableTuitionFees(false);
      } else if (
        e.target.value === 210 ||
        e.target.value === 196 ||
        e.target.value === 199 ||
        e.target.value === 201 ||
        e.target.value === 191 ||
        e.target.value === 192 ||
        e.target.value === 193 ||
        e.target.value === 194
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(5000);
        setDisableTuitionFees(true);
      } else if (
        e.target.value === 195 ||
        e.target.value === 226 ||
        e.target.value === 227 ||
        e.target.value === 228
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(8000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 229 || e.target.value === 190) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(12000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 216 || e.target.value === 200) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(15000);
        setDisableTuitionFees(true);
      } else if (
        e.target.value === 215 ||
        e.target.value === 209 ||
        e.target.value === 198
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(20000);
        setDisableTuitionFees(true);
      } else if (
        e.target.value === 223 ||
        e.target.value === 222 ||
        e.target.value === 221 ||
        e.target.value === 224
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(40000);
        setDisableTuitionFees(true);
      } else if (
        e.target.value === 214 ||
        e.target.value === 217 ||
        e.target.value === 197 ||
        e.target.value === 213
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(25000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 231 || e.target.value === 230) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(35000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 211 || e.target.value === 212) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(30000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 220 || e.target.value === 225) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(50000);
        setDisableTuitionFees(true);
      } else if (
        e.target.value === 205 ||
        e.target.value === 203 ||
        e.target.value === 204
      ) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(60000);
        setDisableTuitionFees(true);
      } else if (e.target.value === 202) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: true,
        });
        setFixedFees(75000);
        setDisableTuitionFees(true);
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          showTuitionField: false,
        });
      }
    } else if (e.target.name.includes("Tuition Fees")) {
      setFixedFees(e.target.value);
    }
    // else if (e.target.name.includes("Accident Resulted In")){
    //     if (e.target.value == 28 || 29){
    //         console.log("MedicalDISABility")
    //         console.log("promresponses1", )
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             showMedicalDisability: true,
    //         })

    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             showMedicalDisability: false,
    //         })
    //     }

    // }
    else if (e.target.name.includes("Name of the dependent")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the name of the dependent",
          globalError: true,
        });
      }
    }

    // else if (e.target.name.includes("Name of the dependent")) {
    //     if (e.target.value===38) {
    //         //  medicalAlphaError = false
    //         //  e.targ
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             nameError: nameError,
    //             nameNomineeError: nameNomineeError,
    //             phoneError: phoneError,
    //             phoneNomineeError: phoneNomineeError,
    //             medicalAssistanceSelf: true,
    //             "Name of the dependent  (If Dependent is selected above)": applicantName,
    //             ["error_" + field.id]: "",
    //             globalError: false,
    //             // medicalAlphaError : true,
    //             // medicalCostError : medicalCostError
    //         })

    //     }

    //     else {
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             nameError: nameError,
    //             nameNomineeError: nameNomineeError,
    //             phoneError: phoneError,
    //             phoneNomineeError: phoneNomineeError,
    //             medicalAssistanceSelf: false,
    //             // ["error_" + field.id]: "Please enter correct format(Alphabet only)",
    //             globalError: true,
    //             // medicalAlphaError : true,
    //             // medicalCostError : medicalCostError
    //         })
    //     }

    // }
    else if (e.target.name.includes("Name of the Doctor Treated")) {
      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 30
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "Please enter correct format(Alphabet only)",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Groom Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Groom Name",
          globalError: true,
        });
      }
    } else if (
      e.target.name.includes("Employer Name at the time of accident")
    ) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the name",
          globalError: true,
        });
      }
    } else if (
      e.target.name.includes("Employer address at the time of accident")
    ) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.slice(0, 50),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 50),
          ["error_" + field.id]: "Enter Address",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Tools Owned")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the tools",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Skills Required")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the required skills",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Tools Required")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the required tools",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Place of Marriage")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Place of Marriage",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Marriage Certificate Number")) {
      //  medicalAlphaError = false
      //  e.targ
      let limit = 20;
      let new_value = e.target.value.slice(0, limit);
      let new_under = new_value.replace(/[^0-9a-zA-z]/gi, "");
      let alpha_numeric = new_under.replace(
        /[`~!@#$%^&*()_|+\-=?;:'",.<>\{\}\[\]\\\/]/gi,
        ""
      );

      // replace(/[^0-9a-zA-z]/ig,'')

      setAllValues({
        ...allValues,
        [e.target.name]: new_value,
        ["error_" + field.id]: "",
        globalError: false,
        // marriageCertificateNumber: e.target.value,
      });
      setMarrCertNum(alpha_numeric);
      if (alpha_numeric.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: new_value,
          ["error_" + field.id]: "Please Enter Marriage Certificate Number",
          globalError: true,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: new_value,
          ["error_" + field.id]: "",
          globalError: false,
        });
      }
    } else if (e.target.name.includes("Duration of Treatment in days")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value
          .replace(/[^A-Za-z0-9]/gi, "")
          .slice(0, 7),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z0-9]/gi, "")
            .slice(0, 7),
          ["error_" + field.id]: "Enter Duration of treatment",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Duration of treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value
          .replace(/[^A-Za-z0-9]/gi, "")
          .slice(0, 7),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z0-9]/gi, "")
            .slice(0, 7),
          ["error_" + field.id]: "Enter Duration of treatment",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Age")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
          ["error_" + field.id]: "Enter Age",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Experience")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value
          .replace(/[^A-Za-z0-9]/gi, "")
          .slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Z0-9]/gi, "")
            .slice(0, 10),
          ["error_" + field.id]: "Enter the experience",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Relationship with Benifiaciary")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 20),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 20),
          ["error_" + field.id]: "Enter Relationship",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Current age of Child")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "Enter Age",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Applicant Bank Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Bank Name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Name of the Person Married")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the name of married person",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Name of the Hospital where")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter name of the hospital",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Applicant Bank Branch")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Branch Name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Description of Treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Description",
          globalError: true,
        });
      }
    }
    // else if (e.target.name.includes("Hospital/Clinic name")){
    //     if (validator.isAlpha(e.target.value)){
    //         //  medicalAlphaError = false
    //         //  e.targ
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //                 ["error_"+field.id]: "",
    //                 globalError: false
    //         })

    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             ["error_"+field.id]: "Please enter correct format(Number only)",
    //             globalError: true                })
    //     }
    // }
    else if (
      e.target.name.includes("Name of the treating Doctor/ Hospital authority")
    ) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Name of the Doctor",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Name of the Applicant")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Full Name of the Child")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Name of the Child",
          globalError: true,
        });
      }
      // else if(e.target.value.length > 31){
      //     setAllValues({
      //     ...allValues,
      //     [e.target.name]: e.target.value.replace(/[^A-Za-z]/ig,''),
      //     ["error_" + field.id]: "Cannot exceed 30 Characters",
      //     globalError: true
      // })
      // }
    } else if (e.target.name.includes("Bank Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value
          .replace(/[^A-Za-z ]/gi, "")
          .slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z ]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Bank Name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Bank Name")) {
      if (
        validator.isAlpha(e.target.value, "en-US", { ignore: " " }) &&
        e.target.value.length < 20
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "Please enter correct format(Alphabets only)",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Bride's Bank Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter Bank Name",
          globalError: true,
        });
      }
    }
    // else if (e.target.name.includes("Relationship with Beneficiary")) {
    //     setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value.replace(/[^A-Za-z]/ig,'').slice(0,20),
    //             ["error_" + field.id]: "",
    //             globalError: false
    //         })
    //         if(e.target.value.length === 0){
    //           setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value.replace(/[^A-Za-z]/ig,'').slice(0,20),
    //             ["error_" + field.id]: "Enter Relationship",
    //             globalError: true
    //         })
    //     }
    // }
    else if (e.target.name.includes("IFSC Code")) {
      // if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && (e.target.value.length) < 20){

      if (
        validator.matches(e.target.value.slice(0, 11), "[A-Z]{4}0[A-Z0-9]{6}$")
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter correct format",
          globalError: true,
        });
      }
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter ISFC Code",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Bride's Bank IFSC code")) {
      // if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && (e.target.value.length) < 20){

      if (
        validator.matches(e.target.value.slice(0, 11), "[A-Z]{4}0[A-Z0-9]{6}$")
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter correct format",
          globalError: true,
        });
      }
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter ISFC Code",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Cost of treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
          ["error_" + field.id]: "Enter the cost",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Account Number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
          ["error_" + field.id]: "Enter Account Number",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Enter your income")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
          ["error_" + field.id]: "Enter the income",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Number of days employed per year")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
          ["error_" + field.id]:
            "Enter the days employed per year (in numbers)",
          globalError: true,
        });
      }
    }

    // else if (e.target.name.includes("Current age of Child")){
    //     if (validator.isAlphaNumeric(e.target.value) && (e.target.value.length) < 2){
    //         //  medicalAlphaError = false
    //         //  e.targ
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //                 ["error_"+field.id]: "",
    //                 globalError: false
    //         })

    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             ["error_"+field.id]: "Please enter correct format(Alphabets &Number only)",
    //             globalError: true
    //         })
    //     }
    // }
    else if (e.target.name.includes("Place of death")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter place of death",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Cause of death")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter cause of death",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Applicant Account Number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
          ["error_" + field.id]: "Enter account number",
          globalError: true,
        });
      }
    }

    // else if (e.target.name.includes("Applicant Account Number")){
    //     if (validator.isNumeric(e.target.value) && (e.target.value.length) < 20){
    //         //  medicalAlphaError = false
    //         //  e.targ
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //                 ["error_"+field.id]: "",
    //                 globalError: false
    //         })

    //     }
    //     else{
    //         setAllValues({
    //             ...allValues,
    //             [e.target.name]: e.target.value,
    //             ["error_"+field.id]: "Please enter correct format(Number only)",
    //             globalError: true
    //         })
    //     }
    // }
    else if (e.target.name.includes("Applicant Bank IFSC Code")) {
      if (
        validator.matches(e.target.value.slice(0, 11), "[A-Z]{4}0[A-Z0-9]{6}$")
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter correct format",
          globalError: true,
        });
      }
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.slice(0, 11),
          ["error_" + field.id]: "Please enter ISFC Code",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Aadhaar Number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 12),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 12),
          ["error_" + field.id]: "Enter Aadhaar Number",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Disability Id card Number")) {
      if (
        validator.isAlphanumeric(e.target.value) &&
        e.target.value.length < 20
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "",
          globalError: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          ["error_" + field.id]: "Please enter correct format(Number only)",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Percentage of Disability")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
          ["error_" + field.id]: "Enter percentage of disability",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Cost of Treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
          ["error_" + field.id]: "Enter Cost of treatment",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Duration of Treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value
          .replace(/[^A-Za-z0-9]/gi, "")
          .slice(0, 3),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z0-9]/gi, "")
            .slice(0, 3),
          ["error_" + field.id]: "Enter duration of treatment",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Age at the time of death")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
          ["error_" + field.id]: "Enter the age",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Phone Number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 10),
          ["error_" + field.id]: "Enter Mobile Number",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Age of Applicant")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 3),
          ["error_" + field.id]: "Enter the age",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Bride's Aadhaar number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 12),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, "").slice(0, 12),
          ["error_" + field.id]: "Enter Aadhaar Number",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Bride's Bank Account Number")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value.replace(/[^0-9]/gi, ""),
          ["error_" + field.id]: "Enter Account Number",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Type of Treatment")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter type of treatment",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Name of the Child")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the name of the child",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Board Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the board name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("School/ College Name")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the school / college name",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("From(Source address)")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the souce address",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("To(Destination Address)")) {
      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value.replace(/[^A-Za-z]/gi, "").slice(0, 30),
        ["error_" + field.id]: "",
        globalError: false,
      });
      if (e.target.value.length === 0) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value
            .replace(/[^A-Za-z]/gi, "")
            .slice(0, 30),
          ["error_" + field.id]: "Enter the destination address",
          globalError: true,
        });
      }
    } else if (e.target.name.includes("Marriage Assistance is for")) {
      console.log("INBRIDELOOP9", e.target.value);
      if (e.target.value === 8) {
        // console.log("INBRIDELOOP1", allValues.promResponses[0].data.data)
        setAllValues({
          ...allValues,
          showBrideData: true,
          [e.target.name]: e.target.value,
        });
      } else if (e.target.value === 9) {
        console.log("INBRIDELOOP6");
        // console.log("INBRIDELOOP1",allValues.promResponses[0].data.data)
        setAllValues({
          ...allValues,
          showBrideData: true,
          [e.target.name]: e.target.value,
        });
      } else if (
        e.target.value === 10 &&
        users.getUserRegistrationDetails.personal_details[0]
          .catalog_value_gender_id === 28
      ) {
        console.log("INBRIDELOOP101");
        // console.log("INBRIDELOOP1",allValues.promResponses[0].data.data)
        setAllValues({
          ...allValues,
          showBrideData: true,
          [e.target.name]: e.target.value,
          selfselected: true,
        });
      } else if (
        e.target.value === 10 &&
        users.getUserRegistrationDetails.personal_details[0]
          .catalog_value_gender_id === 27
      ) {
        console.log("INBRIDELOOP102");
        // console.log("INBRIDELOOP1",allValues.promResponses[0].data.data)
        setAllValues({
          ...allValues,
          showBrideData: true,
          [e.target.name]: e.target.value,
          selfselected: true,
        });
      }

      // else if(users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 28 && allValues.promResponses[0].data.data[2].id === 10 )
      // {
      //     console.log("INBRIDELOOP2",allValues.promResponses[0].data.data[0].id)
      //     setAllValues({
      //         ...allValues,
      //         showBrideData: false,
      //         [e.target.name]: e.target.value,
      //     })
      // }
      // else if( users.getUserRegistrationDetails.personal_details[0].catalog_value_gender_id === 31 && allValues.promResponses[0].data.data[2].id === 10)
      // {
      //     console.log("INBRIDELOOP2",allValues.promResponses[0].data.data[0].id)
      //     setAllValues({
      //         ...allValues,
      //         showBrideData: true,
      //         [e.target.name]: e.target.value,
      //     })
      // }
    } else {
      //my
      // console.log(e.target.value, "value value")
      // console.log(e.target.name, "name value")

      // if(e.target.name === "From(Source address)" ){
      //     console.log(allValues["From(Source address)"],"line 508")
      // }

      //

      setAllValues({
        ...allValues,
        [e.target.name]: e.target.value,
        nameError: nameError,
        nameNomineeError: nameNomineeError,
        phoneError: phoneError,
        phoneNomineeError: phoneNomineeError,
        medicalAssistanceSelf: medicalAssistanceSelf,
        // medicalAlphaError : medicalAlphaError,
        // medicalCostError : medicalCostError
      });
    }

    // if(e.target.value == 62 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value ==  63 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value == 66 ){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }
    // if(e.target.value == 68){
    //     if (validator.isAlpha(e.target.value) && e.target.value.length < 30 && e.target.value.length !== 0){
    //         medicalAlphaError = false
    //     }
    // }

    // var medicalCostError = false
    // if(e.target.value == 67){
    //     if (validator.isNumeric(e.target.value) && e.target.value.length < 30){
    //         medicalCostError = true
    //     }
    // }

    // setAllValues({
    //     ...allValues,
    //     [e.target.name]: e.target.value,
    //     nameError: nameError,
    //     nameNomineeError: nameNomineeError,
    //     phoneError: phoneError,
    //     phoneNomineeError: phoneNomineeError,
    //     medicalAssistanceSelf:medicalAssistanceSelf,
    //     medicalAlphaError : medicalAlphaError,
    //     // medicalCostError : medicalCostError
    // })

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const setPickerStatus2 = (status) => {
    setOpenPicker2(status);
  };

  const handleClick = (event, data) => {
    console.error("handleclick data:" + data);
    if (data === "id_card") setAnchorElIdCard(event.currentTarget);
    else if (data === "bank_account")
      setAnchorElbank_account(event.currentTarget);
    else if (data === "employee_cert")
      setAnchorElemployee_cert(event.currentTarget);
    else if (data === "marriage_cert")
      setAnchorElmarriage_cert(event.currentTarget);
  };

  const handleClose = (event, data) => {
    console.error("handleClose data:" + data);
    if (data === "id_card") setAnchorElIdCard(null);
    else if (data === "bank_account") setAnchorElbank_account(null);
    else if (data === "employee_cert") setAnchorElemployee_cert(null);
    else if (data === "marriage_cert") setAnchorElmarriage_cert(null);
  };

  useEffect(() => {
    console.log("useeffect selectedfiles: ");
    console.log(selectedfiles);
  }, [selectedfiles]);

  const handleFileChange = (event, data) => {
    console.log("handleFileChange data: " + data);
    // console.log("selectedfiles.length: " + selectedfiles.length)
    // console.log("selectedfiles[data]: " + selectedfiles[data])
    // console.log("selectedfiles[data].length: " + selectedfiles[data].length)
    setloadingfiles(true);
    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      var joined = {};
      if (selectedfiles[data] !== undefined) {
        // joined = selectedfiles[data]
        console.log("here");
        console.log(
          Array.from(selectedfiles[data]).concat(Array.from(event.target.files))
        );
        joined = {
          ...selectedfiles,
          [data]: Array.from(selectedfiles[data]).concat(
            Array.from(event.target.files)
          ),
        };
      } else {
        joined = {
          ...selectedfiles,
          [data]: Array.from(event.target.files),
        };
      }

      // joined[data] = Array.from(event.target.files)

      // console.log("joined: ")
      // console.log(joined)

      setselectedfiles(joined);
    }

    setloadingfiles(false);

    // var selectedblob = event.target.files[0]
    // var reader = new FileReader();
    // reader.readAsDataURL(selectedblob);
    // reader.onloadend = function () {
    //     var base64data = reader.result;
    //     console.log(base64data);

    //     sessionStorage.setItem(data, base64data)
    //     setAllValues({
    //         ...allValues,
    //         // imgfile: base64data,
    //         [data]: event.target.files[0].name
    //     })
    // }
  };

  const handleFileChange90Days = (event) => {
    console.log("event.target.files: ", event.target.files);
    console.log(event.target.files);

    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedFiles90Days).concat(
        Array.from(event.target.files)
      );
      setSelectedFiles90Days(joined);
    }

    setloadingfiles(false);
  };

  const removefile = (index, docname) => {
    setloadingfiles(true);
    // console.log("removefile index: " + index)
    // console.log("docname: " + docname)
    console.log("selectedfiles: ", selectedfiles);
    console.log("selectedfiles[docname]", selectedfiles[docname]);
    // console.log("selectedfiles[docname].length",selectedfiles[docname].length)
    // selectedfiles.splice(1,index)

    if (selectedfiles[docname].length > 1) {
      selectedfiles[docname].splice(index, 1);
    } else {
      delete selectedfiles[docname];
    }

    setTimeout(() => setloadingfiles(false), 1);
  };

  const removefile90Days = (index) => {
    setloadingfiles(true);
    console.log("removefile index: " + index);
    // setRemovedFile(true)
    // selectedfiles.splice(1,index)
    selectedFiles90Days.splice(index, 1);

    setTimeout(() => setloadingfiles(false), 1);
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });

    // if (event.target.checked) {
    //     setAllValues({ ...allValues,
    //         permanentData: state.residentialData
    //     }));
    // }
    // else {
    //     setAllValues({ ...allValues,
    //         permanentData: initData
    //     }));
    // }
  };

  function UploadDispatch(data1) {
    return new Promise((resolve, reject) => {
      console.error("UploadDispatch data inside: " + JSON.stringify(data1));
      var formData = new FormData();
      // formData.append('file', data.file);
      formData.append("file", dataURLtoFile(data1.file, "newfile"));
      formData.append("userId", data1.userId);
      formData.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: formData,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  }

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (fileuploadresults !== null && fileuploadresults.length > 0) {
      var filelist1 = filelist;
      var filelist2 = [];
      fileuploadresults.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };
        filelist2.push(filejson);
        // filelist1.push({[currentdocname] : filejson })
      });

      filelist1.push({ [currentdocname]: filelist2 });

      console.log("filelist1: " + JSON.stringify(filelist1));
      setfilelist(filelist1);
      console.log("filelist_after_set: " + JSON.stringify(filelist));

      if (
        (filelist1.length === schemeDetails.length &&
          !minus_one_doc_for_affidavit) ||
        (filelist1.length === schemeDetails.length &&
          !minus_one_doc_for_postmortem &&
          !minus_one_doc_for_disability)
      ) {
        var data = JSON.stringify({
          board_id: 1,
          role_hierarchy_master_id: role_id,
        });
        var config = {
          method: "post",
          url: SERVER + "/schemes/get_role_hirarchy",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(
              "get_role_hirarchy response:" + JSON.stringify(response.data)
            );

            // Call get_department_user_by_area

            setrole_id_from_hirarchy(response.data.data[0].role_id);

            var dataDepUser = JSON.stringify({
              board_id: 1,
              role_id_from_hirarchy: response.data.data[0].role_id,
              village_area_id:
                users.getUserRegistrationDetails.address_details[0]
                  .village_area_id,
            });

            var configDepUser = {
              method: "post",
              url: SERVER + "/schemes/get_department_user_by_area",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: dataDepUser,
            };

            axios(configDepUser)
              .then(function (response2) {
                console.log(
                  "get_department_user_by_area response:" +
                    JSON.stringify(response2.data)
                );

                // setTimeout(function() {
                console.log("filelist1_before_call: ", filelist1);
                submitButtonPressed1(response2.data.data, null, filelist1);
                // }.bind(this), 3000)
              })
              .catch(function (error) {
                console.error("get_department_user_by_area error:" + error);
              });
          })
          .catch(function (error) {
            console.error("get_role_hirarchy error:" + error);
          });
      } else if (
        (minus_one_doc_for_affidavit &&
          filelist1.length === schemeDetails.length - 1) ||
        (minus_one_doc_for_postmortem &&
          filelist1.length === schemeDetails.length - 1) ||
        (minus_one_doc_for_disability &&
          filelist1.length === schemeDetails.length - 1)
      ) {
        var data = JSON.stringify({
          board_id: 1,
          role_hierarchy_master_id: role_id,
        });
        var config = {
          method: "post",
          url: SERVER + "/schemes/get_role_hirarchy",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: data,
        };

        axios(config)
          .then(function (response) {
            console.log(
              "get_role_hirarchy response:" + JSON.stringify(response.data)
            );

            // Call get_department_user_by_area

            setrole_id_from_hirarchy(response.data.data[0].role_id);

            var dataDepUser = JSON.stringify({
              board_id: 1,
              role_id_from_hirarchy: response.data.data[0].role_id,
              village_area_id:
                users.getUserRegistrationDetails.address_details[0]
                  .village_area_id,
            });

            var configDepUser = {
              method: "post",
              url: SERVER + "/schemes/get_department_user_by_area",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: dataDepUser,
            };

            axios(configDepUser)
              .then(function (response2) {
                console.log(
                  "get_department_user_by_area response:" +
                    JSON.stringify(response2.data)
                );

                // setTimeout(function() {
                console.log("filelist1_before_call: ", filelist1);
                submitButtonPressed1(response2.data.data, null, filelist1);
                // }.bind(this), 3000)
              })
              .catch(function (error) {
                console.error("get_department_user_by_area error:" + error);
              });
          })
          .catch(function (error) {
            console.error("get_role_hirarchy error:" + error);
          });
      }
    }
  }, [fileuploadresults]);

  // console.log("FieldsValue:002",allValues.fields);

  const myPromisesDocs = [];

  const submitButtonPressed = (e) => {
    console.log("mandatoryforDeath0");
    if (marriageCertificateNumberPresent) {
      console.log("marriageDedup12");

      showToast(
        "ERROR",
        "The entered Marriage Certificate Number has already exist in the system"
      );
    } else {
      var mandatoryFilled = true;
      getDynamicFields &&
        getDynamicFields.length &&
        getDynamicFields.forEach((field) => {
          console.log("field: ", field);
          console.log("field.is_required: ", field.is_required);
          if (field.is_required === true) {
            console.log(
              "allValues[field.field_name]: ",
              allValues[field.field_name]
            );
            if (
              allValues[field.field_name] === undefined ||
              allValues[field.field_name] === ""
            ) {
              console.log("mandatoryforDeath1");
              if (allValues.showDeathfields) {
                console.log("mandatoryLoop00 ");
                if (
                  field.field_name.includes("Applicant Type") ||
                  field.field_name.includes("Name of the Applicant") ||
                  field.field_name.includes("Phone Number") ||
                  field.field_name.includes("Age of Applicant") ||
                  field.field_name.includes("Permanent Address") ||
                  field.field_name.includes("Relationship with Beneficiary") ||
                  field.field_name.includes("Aadhaar Number") ||
                  field.field_name.includes("Applicant Bank Name") ||
                  field.field_name.includes("Applicant Account Number") ||
                  field.field_name.includes("Applicant Bank Branch") ||
                  field.field_name.includes("Applicant  Bank IFSC Code") ||
                  field.field_name.includes("Hospital/Clinic name")
                ) {
                  console.log("mandatoryLoop1 ");
                  mandatoryFilled = false;
                } else if (
                  field.field_name.includes(
                    "Employer Name at the time of accident"
                  ) ||
                  field.field_name.includes(
                    "Employer address at the time of accident"
                  ) ||
                  field.field_name.includes("Accident Resulted In")
                ) {
                  mandatoryFilled = false;
                }
              } else if (
                !allValues.showDeathfields &&
                idName === "Accident Assistance"
              ) {
                if (
                  field.field_name.includes(
                    "Employer Name at the time of accident"
                  ) ||
                  field.field_name.includes(
                    "Employer address at the time of accident"
                  ) ||
                  field.field_name.includes("Accident Resulted In")
                ) {
                  console.log("mandatoryLoopD1");
                  mandatoryFilled = false;
                }
              } else if (
                !allValues.showTuitionField &&
                idName === "Education Assistance"
              ) {
                if (field.field_name.includes("Tuition Fees")) {
                  mandatoryFilled = false;
                }
              } else if (field.field_name.includes("Hospital/Clinic name")) {
                mandatoryFilled = true;
              } else if (allValues.showBrideData) {
                console.log("mandatoryLoop9 ");

                console.log("mandatoryLoop4 ");

                if (field.field_name.includes("Bride")) {
                  if (
                    allValues[field.field_name] === undefined ||
                    allValues[field.field_name] === ""
                  ) {
                    mandatoryFilled = false;
                  }
                }
              } else {
                console.log("mandatoryLoop8");
                mandatoryFilled = false;
              }
            }
          }
        });
      console.log("mandatoryLoop10");

      var minus_one_doc_for_affidavit1 = false;
      var minus_one_doc_for_postmortem1 = false;
      var minus_one_doc_for_disability1 = false;

      if (
        allValues["Married Outside Karnataka"] !== undefined &&
        allValues["Married Outside Karnataka"] !== 177
      ) {
        minus_one_doc_for_affidavit1 = true;
        setminus_one_doc_for_affidavit(true);
      } else if (
        allValues["Accident Resulted In"] !== undefined &&
        allValues["Accident Resulted In"] !== 27
      ) {
        minus_one_doc_for_postmortem1 = true;
        setminus_one_doc_for_postmortem(true);
      } else if (
        allValues["Accident Resulted In"] !== undefined &&
        allValues["Accident Resulted In"] === 27
      ) {
        minus_one_doc_for_disability1 = true;
        setminus_one_doc_for_disability(true);
      }

      console.log(
        "minus_one_doc_for_postmortem1: ",
        minus_one_doc_for_postmortem1
      );
      console.log(
        "minus_one_doc_for_affidavit1: ",
        minus_one_doc_for_affidavit1
      );
      // console.log("allValues.schemeDetails.document_details.length: ", allValues.schemeDetails.length)
      console.log(
        "Object.keys(selectedfiles).length: ",
        Object.keys(selectedfiles).length
      );
      // console.log("(allValues.schemeDetails.document_details.length - 1): ", (allValues.schemeDetails.length - 1))
      console.log(!mandatoryFilled, "MandatoryField:");
      if (!mandatoryFilled) {
        console.log("in if 1");
        showToast("ERROR", "Please fill all the mandatory fields.");
      }

      if (idName !== "Funeral Expense and Death Assistance") {
        console.log("INSIDE OTHER SCHEMES");
        if (allValues["Accident Resulted In"] !== 27) {
          if (
            !issuer ||
            !issuedDate ||
            (selectedFiles90Days && selectedFiles90Days.length === 0)
          ) {
            showToast(
              "ERROR",
              "Please fill all the details in 90 Days Work Certificate Tab"
            );
          } else {
            // submitButtonPressedFinal()
            if (
              allValues["Married Outside Karnataka"] === 177 &&
              !minus_one_doc_for_affidavit1 &&
              Object.keys(selectedfiles).length !== schemeDetails.length
            ) {
              console.log("in else 5");
              showToast("ERROR", "Please upload the documents.");
            } else if (
              minus_one_doc_for_affidavit1 &&
              Object.keys(selectedfiles).length !== schemeDetails.length - 1
            ) {
              console.log("in else 2");
              showToast("ERROR", "Please upload the documents.");
            } else if (
              minus_one_doc_for_postmortem1 &&
              Object.keys(selectedfiles).length !== schemeDetails.length - 1
            ) {
              console.log("in else 3");
              showToast("ERROR", "Please upload the documents.");
            } else if (
              allValues["Accident Resulted In"] !== undefined &&
              allValues["Accident Resulted In"] === 27 &&
              !Object.keys(selectedfiles).includes("Postmortem Report") &&
              !minus_one_doc_for_postmortem1
            ) {
              console.log("in else 4");
              showToast("ERROR", "Please upload the documents.");
            } else if (
              allValues["Accident Resulted In"] !== undefined &&
              allValues["Accident Resulted In"] === 27 &&
              !Object.keys(selectedfiles).includes("FIR COPY") &&
              !minus_one_doc_for_postmortem1
            ) {
              console.log("in else 4");
              showToast("ERROR", "Please upload the documents.");
            }
            //   else if(Object.keys(selectedfiles).length !== (allValues.schemeDetails.document_details.length - 1)
            //     ){
            //     console.log("in else 1")
            //     if(!minus_one_doc_for_postmortem1 ){
            //         showToast("ERROR","Please upload all the documents.")
            //     }
            // }
            else if (
              Object.keys(selectedfiles).length !== schemeDetails.length
            ) {
              console.log("in else 9");
              console.log("in else 9", minus_one_doc_for_postmortem1);

              if (allValues["Accident Resulted In"] === 27) {
                if (!minus_one_doc_for_postmortem1) {
                  submitButtonPressedFinal();
                }
              } else if (minus_one_doc_for_postmortem1) {
                console.log("INSIDE SUBMISSON PENS");
                submitButtonPressedFinal();
              } else if (minus_one_doc_for_affidavit1) {
                submitButtonPressedFinal();
              } else {
                showToast("ERROR", "Please upload the documents.");
              }
            } else if (
              allValues["Date of admission to Hospital /Clinic"] !==
                undefined &&
              allValues["Date of Discharge from Hospital/Clinic"] !== undefined
            ) {
              console.log("in else 3");

              var adDate = moment(
                new Date(allValues["Date of admission to Hospital /Clinic"])
              );
              var disDate = moment(
                new Date(allValues["Date of Discharge from Hospital/Clinic"])
              );
              var dayDiffMed = disDate.diff(adDate, "days");

              var dayad = 1;
              var dayadMoment = new Date(dayad);
              console.log("dayDiffMed Loop: ", dayDiffMed);
              if (dayDiffMed < 2) {
                showToast(
                  "ERROR",
                  "Minimum required date is 48 hours after admission."
                );
              } else if (
                allValues["Date of admission to Hospital /Clinic"] !== undefined
              ) {
                var todaysDate = new Date();
                console.log("Admission datee", adDate);
                console.log("Today's datee", todaysDate);
                var dayDiffMed1 = adDate.diff(todaysDate, "months");
                if (dayDiffMed1 < -6) {
                  showToast(
                    "ERROR",
                    "Maximum period is 6 months from admission date to apply."
                  );
                } else {
                  console.log("Submit button click");
                  submitButtonPressedFinal();
                }
              } else {
                console.log("Submit button click");
                submitButtonPressedFinal();
              }
            } else if (
              getDynamicFields[0] !== undefined &&
              getDynamicFields[0].id === 45
            ) {
              console.log("in else 4");

              var birthDate = moment(
                new Date(allValues["Date of Child Birth"])
              );
              console.log("Birth2 Date", birthDate);
              var todayDate = new Date();
              console.log("Today's Date", todayDate);
              var dayDiff = birthDate.diff(todayDate, "months");

              // var dayad = 1
              // var dayadMoment = new Date(dayad)
              console.log("dayDiffMed Loop: ", dayDiff);
              if (dayDiff < -6) {
                showToast(
                  "ERROR",
                  "Maximum date to apply is 6 months after delivery of child."
                );
              } else {
                submitButtonPressedFinal();
              }
            } else if (
              allValues["Date of death"] !== undefined
              // allValues.fields[0].id == 54
            ) {
              console.log("in else 5");
              console.log("in death date loop");
              var deathDate = moment(new Date(allValues["Date of death"]));
              console.log("Death Date1", deathDate);
              var todaysDate = new Date();
              console.log("Today's Date", todayDate);
              var dayDiff = deathDate.diff(todaysDate, "years");

              // var dayad = 1
              // var dayadMoment = new Date(dayad)
              console.log("dayDiffMed Loop: ", dayDiff);
              if (dayDiff <= -1) {
                showToast(
                  "ERROR",
                  "Maximum date to apply is 1 year after the date of death."
                );
              } else {
                submitButtonPressedFinal();
              }
            } else if (
              getDynamicFields[0] !== undefined &&
              getDynamicFields[0].id === 54
            ) {
              console.log("in else 6");

              var birthDate1 = moment(
                new Date(allValues["Date of Child Birth"])
              );
              console.log("Birth Date1", birthDate1);
              var todaysDate = new Date();
              console.log("Today's Date", todayDate);
              var dayDiff = birthDate1.diff(todaysDate, "years");

              // var dayad = 1
              // var dayadMoment = new Date(dayad)
              console.log("dayDiffMed Loop: ", dayDiff);
              if (dayDiff < -3) {
                showToast(
                  "ERROR",
                  "Maximum date to apply is 3 year after the date of delivery."
                );
              } else {
                submitButtonPressedFinal();
              }
            } else {
              submitButtonPressedFinal();
            }
          }
        } else {
          if (
            minus_one_doc_for_postmortem1 &&
            Object.keys(selectedfiles).length !== schemeDetails.length - 1
          ) {
            console.log("in else 3");
            showToast("ERROR", "Please upload the documents.");
          } else if (
            allValues["Accident Resulted In"] !== undefined &&
            allValues["Accident Resulted In"] === 27 &&
            !Object.keys(selectedfiles).includes("Postmortem Report") &&
            !minus_one_doc_for_postmortem1
          ) {
            console.log("in else 4");
            showToast("ERROR", "Please upload the documents.");
          } else if (
            allValues["Accident Resulted In"] !== undefined &&
            allValues["Accident Resulted In"] === 27 &&
            !Object.keys(selectedfiles).includes("FIR COPY") &&
            !minus_one_doc_for_postmortem1
          ) {
            console.log("in else 4");
            showToast("ERROR", "Please upload the documents.");
          } else {
            submitButtonPressedFinal();
          }
        }
      } else {
        console.log("in else 7 final");
        if (!Object.keys(selectedfiles).includes("Death Certificate")) {
          console.log("in else 4");
          showToast("ERROR", "Please upload the documents.");
        } else if (
          !Object.keys(selectedfiles).includes("Nominee/Legal Hier's Aadhaar")
        ) {
          console.log("in else 4");
          showToast("ERROR", "Please upload the documents.");
        } else {
          submitButtonPressedFinal();
        }
      }
    }
  };

  useEffect(() => {
    if (
      fileUploadResults90Days !== null &&
      fileUploadResults90Days.length > 0
    ) {
      var filelist = [];
      fileUploadResults90Days.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };
        filelist.push(filejson);
      });
      console.log("filelist90Days: " + JSON.stringify(filelist));
      setFileList90Days(filelist);
      // setfilelist(filelist)
      // submit90Days(filelist)
    }
  }, [fileuploadresults]);

  const uploadNintyDays = () => {
    let promises = [];

    selectedFiles90Days.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: users.user.id,
          fileType: fileName,
        };
        console.log(formdataobj, "FILEDATA:");
        promises.push(uploadfile90Days(formdataobj, fileName));
        if (promises.length === selectedFiles90Days.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setFileUploadResults90Days(results);
          });
        }
      };
    });

    // if(sessionStorage.getItem('90_days_certificate') !== null && users.user !== null){
    //     let formdata = {
    //         'file': sessionStorage.getItem('90_days_certificate'),
    //         'userId': users.user.id,
    //         'fileType': '90_days_certificate'
    //     }
    //     console.error("formdata 90_days_certificate: "+ JSON.stringify(formdata))
    //     dispatch(uploadFile(formdata,"90_days_certificate"))
    // }
  };

  const uploadfile90Days = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const submitButtonPressedFinal = (e) => {
    // console.log("momentStringMedObjSubmit == " + minimumDateAdmissionMedicalDis);
    // var dayDiffMed = minimumDateAdmissionMedicalDis.diff(minimumDateAdmissionMedical, 'days');

    if (allValues.declaration !== undefined && allValues.declaration === true) {
      if (idName !== "Funeral Expense and Death Assistance") {
        if (allValues["Accident Resulted In"] !== 27) {
          if (
            selectedSelfDeclarationFiles &&
            selectedSelfDeclarationFiles.length > 0
          ) {
            setOpenBackdrop(true);
            setLoading(true);
            setSubmitSuccess(false);
            if (schemeDetails.length > 0) {
              schemeDetails.forEach((field) => {
                console.log("INFOREACH1");
                console.log("INFOREACH1", allValues["Accident Resulted In"]);

                if (
                  field.document_name === "Affidavit" &&
                  allValues["Married Outside Karnataka"] !== undefined &&
                  allValues["Married Outside Karnataka"] === 177
                ) {
                  selectedfiles[field.document_name].forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        userId: users.user.id,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (
                        promises.length ===
                        selectedfiles[field.document_name].length
                      ) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);
                          setcurrentdocname(field.document_name);
                          setfileuploadresults(results);
                        });
                      }
                    };
                  });
                } else if (
                  field.document_name === "Medical Disability Certificate" &&
                  allValues["Accident Resulted In"] !== undefined &&
                  allValues["Accident Resulted In"] === 27
                ) {
                  //Do nothing if Death is selected and Medical Disability is not mandatory
                } else if (
                  field.document_name === "Affidavit" &&
                  allValues["Married Outside Karnataka"] !== undefined &&
                  allValues["Married Outside Karnataka"] !== 177
                ) {
                } else if (
                  field.document_name === "Postmortem Report" &&
                  allValues["Accident Resulted In"] !== undefined &&
                  allValues["Accident Resulted In"] === 27
                ) {
                  console.log("INFOREACH2", allValues["Accident Resulted In"]);
                  selectedfiles[field.document_name].forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        userId: users.user.id,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (
                        promises.length ===
                        selectedfiles[field.document_name].length
                      ) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);
                          setcurrentdocname(field.document_name);
                          setfileuploadresults(results);
                        });
                      }
                    };
                  });
                } else if (
                  field.document_name === "Postmortem Report" &&
                  allValues["Accident Resulted In"] !== undefined &&
                  allValues["Accident Resulted In"] !== 27
                ) {
                  console.log("INFOREACH3", allValues["Accident Resulted In"]);
                } else {
                  selectedfiles[field.document_name].forEach(async (i) => {
                    var selectedblob = i;
                    var reader = new FileReader();
                    reader.readAsDataURL(selectedblob);
                    var formdataobj = "";
                    var fileName = "";
                    reader.onloadend = await function (event) {
                      fileName = selectedblob.name;
                      var base64data = event.target.result;
                      formdataobj = {
                        file: base64data,
                        userId: users.user.id,
                        fileType: fileName,
                      };
                      promises.push(uploadfile(formdataobj, fileName));
                      if (
                        promises.length ===
                        selectedfiles[field.document_name].length
                      ) {
                        Promise.all(promises).then((results) => {
                          console.log("results of promise: ");
                          console.log(results);
                          setcurrentdocname(field.document_name);
                          setfileuploadresults(results);
                        });
                      }
                    };
                  });
                }

                let promises = [];
              });
            } else {
              var data = JSON.stringify({
                board_id: 1,
                role_hierarchy_master_id: role_id,
              });
              var config = {
                method: "post",
                url: SERVER + "/schemes/get_role_hirarchy",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: data,
              };

              axios(config)
                .then(function (response) {
                  console.log(
                    "get_role_hirarchy response:" +
                      JSON.stringify(response.data)
                  );

                  // Call get_department_user_by_area

                  setrole_id_from_hirarchy(response.data.data[0].role_id);

                  var dataDepUser = JSON.stringify({
                    board_id: 1,
                    role_id_from_hirarchy: response.data.data[0].role_id,
                    village_area_id:
                      users.getUserRegistrationDetails.address_details[0]
                        .village_area_id,
                  });

                  var configDepUser = {
                    method: "post",
                    url: SERVER + "/schemes/get_department_user_by_area",
                    headers: {
                      Authorization: `Bearer ${getTokenCookie()}`,
                    },
                    data: dataDepUser,
                  };

                  axios(configDepUser)
                    .then(function (response2) {
                      console.log(
                        "get_department_user_by_area response:" +
                          JSON.stringify(response2.data)
                      );

                      // setTimeout(function() {
                      submitButtonPressed1(response2.data.data, null, "");
                      // }.bind(this), 3000)
                    })
                    .catch(function (error) {
                      console.error(
                        "get_department_user_by_area error:" + error
                      );
                    });
                })
                .catch(function (error) {
                  console.error("get_role_hirarchy error:" + error);
                });
            }
          } else {
            showToast("WARN", "Please upload Self Declaration Document");
          }
        } else {
          setOpenBackdrop(true);
          setLoading(true);
          setSubmitSuccess(false);
          if (schemeDetails.length > 0) {
            schemeDetails.forEach((field) => {
              console.log("INFOREACH1");
              console.log("INFOREACH1", allValues["Accident Resulted In"]);

              if (
                field.document_name === "Affidavit" &&
                allValues["Married Outside Karnataka"] !== undefined &&
                allValues["Married Outside Karnataka"] === 177
              ) {
                selectedfiles[field.document_name].forEach(async (i) => {
                  var selectedblob = i;
                  var reader = new FileReader();
                  reader.readAsDataURL(selectedblob);
                  var formdataobj = "";
                  var fileName = "";
                  reader.onloadend = await function (event) {
                    fileName = selectedblob.name;
                    var base64data = event.target.result;
                    formdataobj = {
                      file: base64data,
                      userId: users.user.id,
                      fileType: fileName,
                    };
                    promises.push(uploadfile(formdataobj, fileName));
                    if (
                      promises.length ===
                      selectedfiles[field.document_name].length
                    ) {
                      Promise.all(promises).then((results) => {
                        console.log("results of promise: ");
                        console.log(results);
                        setcurrentdocname(field.document_name);
                        setfileuploadresults(results);
                      });
                    }
                  };
                });
              } else if (
                field.document_name === "Medical Disability Certificate" &&
                allValues["Accident Resulted In"] !== undefined &&
                allValues["Accident Resulted In"] === 27
              ) {
                //Do nothing if Death is selected and Medical Disability is not mandatory
              } else if (
                field.document_name === "Affidavit" &&
                allValues["Married Outside Karnataka"] !== undefined &&
                allValues["Married Outside Karnataka"] !== 177
              ) {
              } else if (
                field.document_name === "Postmortem Report" &&
                allValues["Accident Resulted In"] !== undefined &&
                allValues["Accident Resulted In"] === 27
              ) {
                console.log("INFOREACH2", allValues["Accident Resulted In"]);
                selectedfiles[field.document_name].forEach(async (i) => {
                  var selectedblob = i;
                  var reader = new FileReader();
                  reader.readAsDataURL(selectedblob);
                  var formdataobj = "";
                  var fileName = "";
                  reader.onloadend = await function (event) {
                    fileName = selectedblob.name;
                    var base64data = event.target.result;
                    formdataobj = {
                      file: base64data,
                      userId: users.user.id,
                      fileType: fileName,
                    };
                    promises.push(uploadfile(formdataobj, fileName));
                    if (
                      promises.length ===
                      selectedfiles[field.document_name].length
                    ) {
                      Promise.all(promises).then((results) => {
                        console.log("results of promise: ");
                        console.log(results);
                        setcurrentdocname(field.document_name);
                        setfileuploadresults(results);
                      });
                    }
                  };
                });
              } else if (
                field.document_name === "Postmortem Report" &&
                allValues["Accident Resulted In"] !== undefined &&
                allValues["Accident Resulted In"] !== 27
              ) {
                console.log("INFOREACH3", allValues["Accident Resulted In"]);
              } else {
                selectedfiles[field.document_name].forEach(async (i) => {
                  var selectedblob = i;
                  var reader = new FileReader();
                  reader.readAsDataURL(selectedblob);
                  var formdataobj = "";
                  var fileName = "";
                  reader.onloadend = await function (event) {
                    fileName = selectedblob.name;
                    var base64data = event.target.result;
                    formdataobj = {
                      file: base64data,
                      userId: users.user.id,
                      fileType: fileName,
                    };
                    promises.push(uploadfile(formdataobj, fileName));
                    if (
                      promises.length ===
                      selectedfiles[field.document_name].length
                    ) {
                      Promise.all(promises).then((results) => {
                        console.log("results of promise: ");
                        console.log(results);
                        setcurrentdocname(field.document_name);
                        setfileuploadresults(results);
                      });
                    }
                  };
                });
              }

              let promises = [];
            });
          } else {
            var data = JSON.stringify({
              board_id: 1,
              role_hierarchy_master_id: role_id,
            });
            var config = {
              method: "post",
              url: SERVER + "/schemes/get_role_hirarchy",
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
              data: data,
            };

            axios(config)
              .then(function (response) {
                console.log(
                  "get_role_hirarchy response:" + JSON.stringify(response.data)
                );

                // Call get_department_user_by_area

                setrole_id_from_hirarchy(response.data.data[0].role_id);

                var dataDepUser = JSON.stringify({
                  board_id: 1,
                  role_id_from_hirarchy: response.data.data[0].role_id,
                  village_area_id:
                    users.getUserRegistrationDetails.address_details[0]
                      .village_area_id,
                });

                var configDepUser = {
                  method: "post",
                  url: SERVER + "/schemes/get_department_user_by_area",
                  headers: {
                    Authorization: `Bearer ${getTokenCookie()}`,
                  },
                  data: dataDepUser,
                };

                axios(configDepUser)
                  .then(function (response2) {
                    console.log(
                      "get_department_user_by_area response:" +
                        JSON.stringify(response2.data)
                    );

                    // setTimeout(function() {
                    submitButtonPressed1(response2.data.data, null, "");
                    // }.bind(this), 3000)
                  })
                  .catch(function (error) {
                    console.error("get_department_user_by_area error:" + error);
                  });
              })
              .catch(function (error) {
                console.error("get_role_hirarchy error:" + error);
              });
          }
        }
      } else {
        setOpenBackdrop(true);
        setLoading(true);
        setSubmitSuccess(false);
        if (schemeDetails.length > 0) {
          schemeDetails.forEach((field) => {
            console.log("INFOREACH1");
            console.log("INFOREACH1", allValues["Accident Resulted In"]);

            if (
              field.document_name === "Affidavit" &&
              allValues["Married Outside Karnataka"] !== undefined &&
              allValues["Married Outside Karnataka"] === 177
            ) {
              selectedfiles[field.document_name].forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    userId: users.user.id,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (
                    promises.length ===
                    selectedfiles[field.document_name].length
                  ) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);
                      setcurrentdocname(field.document_name);
                      setfileuploadresults(results);
                    });
                  }
                };
              });
            } else if (
              field.document_name === "Medical Disability Certificate" &&
              allValues["Accident Resulted In"] !== undefined &&
              allValues["Accident Resulted In"] === 27
            ) {
              //Do nothing if Death is selected and Medical Disability is not mandatory
            } else if (
              field.document_name === "Affidavit" &&
              allValues["Married Outside Karnataka"] !== undefined &&
              allValues["Married Outside Karnataka"] !== 177
            ) {
            } else if (
              field.document_name === "Postmortem Report" &&
              allValues["Accident Resulted In"] !== undefined &&
              allValues["Accident Resulted In"] === 27
            ) {
              console.log("INFOREACH2", allValues["Accident Resulted In"]);
              selectedfiles[field.document_name].forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    userId: users.user.id,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (
                    promises.length ===
                    selectedfiles[field.document_name].length
                  ) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);
                      setcurrentdocname(field.document_name);
                      setfileuploadresults(results);
                    });
                  }
                };
              });
            } else if (
              field.document_name === "Postmortem Report" &&
              allValues["Accident Resulted In"] !== undefined &&
              allValues["Accident Resulted In"] !== 27
            ) {
              console.log("INFOREACH3", allValues["Accident Resulted In"]);
            } else {
              selectedfiles[field.document_name].forEach(async (i) => {
                var selectedblob = i;
                var reader = new FileReader();
                reader.readAsDataURL(selectedblob);
                var formdataobj = "";
                var fileName = "";
                reader.onloadend = await function (event) {
                  fileName = selectedblob.name;
                  var base64data = event.target.result;
                  formdataobj = {
                    file: base64data,
                    userId: users.user.id,
                    fileType: fileName,
                  };
                  promises.push(uploadfile(formdataobj, fileName));
                  if (
                    promises.length ===
                    selectedfiles[field.document_name].length
                  ) {
                    Promise.all(promises).then((results) => {
                      console.log("results of promise: ");
                      console.log(results);
                      setcurrentdocname(field.document_name);
                      setfileuploadresults(results);
                    });
                  }
                };
              });
            }

            let promises = [];
          });
        } else {
          var data = JSON.stringify({
            board_id: 1,
            role_hierarchy_master_id: role_id,
          });
          var config = {
            method: "post",
            url: SERVER + "/schemes/get_role_hirarchy",
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
            data: data,
          };

          axios(config)
            .then(function (response) {
              console.log(
                "get_role_hirarchy response:" + JSON.stringify(response.data)
              );

              // Call get_department_user_by_area

              setrole_id_from_hirarchy(response.data.data[0].role_id);

              var dataDepUser = JSON.stringify({
                board_id: 1,
                role_id_from_hirarchy: response.data.data[0].role_id,
                village_area_id:
                  users.getUserRegistrationDetails.address_details[0]
                    .village_area_id,
              });

              var configDepUser = {
                method: "post",
                url: SERVER + "/schemes/get_department_user_by_area",
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
                data: dataDepUser,
              };

              axios(configDepUser)
                .then(function (response2) {
                  console.log(
                    "get_department_user_by_area response:" +
                      JSON.stringify(response2.data)
                  );

                  // setTimeout(function() {
                  submitButtonPressed1(response2.data.data, null, "");
                  // }.bind(this), 3000)
                })
                .catch(function (error) {
                  console.error("get_department_user_by_area error:" + error);
                });
            })
            .catch(function (error) {
              console.error("get_role_hirarchy error:" + error);
            });
        }
      }
    } else {
      showToast("WARN", "Please Accept the Declaration!");
    }
  };

  // useEffect(() => {
  //   if (idName === 'Education Assistance') {
  //     const data = {
  //       board_id: 1,
  //       scheme_id: 30,
  //       role_id: null,
  //       tab_id: null,
  //     };
  //     var config = {
  //       method: 'post',
  //       url: SERVER + '/schemes/get_dynamic_fields_and_value',
  //       headers: {
  //         Authorization: `Bearer ${getTokenCookie()}`,
  //       },
  //       data: data,
  //     };
  //     axios(config).then((res) => {
  //       console.log('DynamicFields:001', res.data.data);
  //       var filterData = res.data.data.filter(
  //         (field) => field.field_name === 'Current Course Studying',
  //       );

  //       console.log(
  //         filterData[0].field_data.filter(
  //           (field) => field.field_value_id === 214,
  //         ),
  //       );
  //       var filterFields = filterData[0].field_data.filter(
  //         (field_id) => field_id.field_value_id === 214,
  //       );
  //       var NewField = filterFields[0].field_value_id;
  //       console.log('New', NewField);
  //       // setFixedFees(NewField)
  //     });
  //   }
  // }, []);

  useEffect(() => {
    if (selectedFiles90Days && selectedFiles90Days.length > 0) {
      uploadNintyDays();
    }
  }, [selectedFiles90Days]);

  const submitButtonPressed1 = (deptUserDetails, DocsIdMap, filelistNew) => {
    console.log("param_in_schemeform: " + id);
    console.log(
      "deptUserDetails: " + JSON.stringify(deptUserDetails, undefined, 2)
    );
    console.log(fileList90Days, "fileList90Days");
    var datadocs = [];
    console.log("filelistNew: ", filelistNew);

    if (
      schemeDetails.length > 0 &&
      filelistNew !== undefined &&
      filelistNew !== ""
    ) {
      // console.log("filelist_in_submit: ",filelist)
      console.log("filelist_in_submit: ", filelistNew);
      schemeDetails.forEach((field, index) => {
        console.log("index: " + index);
        console.log("field.document_name: ", field.document_name);
        console.log("document_name_all: ", JSON.stringify(allValues));
        console.log(
          "`${field.document_name}_fileid`: ",
          `${field.document_name}_fileid`
        );
        console.log(
          "`selectedfiles[field.document_name]`: ",
          selectedfiles[field.document_name]
        );
        // console.log("filelist[field.document_name]: "+ filelist[index][field.document_name])
        // console.log("filelistNew[field.document_name]: ", filelistNew[index][field.document_name])
        //     })
        // })
        // console.log("fileIdFound: " + fileIdFound)

        var filesObj = "";
        filelistNew.forEach((ob, i) => {
          console.log("ob: ", ob);
          if (ob[field.document_name] !== undefined) {
            filesObj = ob[field.document_name];
          }
        });
        console.log("filesObj: ", filesObj);

        if (minus_one_doc_for_affidavit) {
          if (field.document_name === "Affidavit") {
            // do Nothing
          } else {
            datadocs.push({
              document_upload_id: "-",
              description: field.document_name,
              document_type: field.document_name,
              scheme_document_id: field.id,
              files: filesObj,
            });
          }
        } else if (minus_one_doc_for_postmortem) {
          if (field.document_name === "Postmortem Report") {
            // do Nothing
          } else {
            datadocs.push({
              document_upload_id: "-",
              description: field.document_name,
              document_type: field.document_name,
              scheme_document_id: field.id,
              files: filesObj,
            });
          }
        } else if (minus_one_doc_for_disability) {
          if (field.document_name === "Medical Disability Certificate") {
            // do Nothing
          } else {
            datadocs.push({
              document_upload_id: "-",
              description: field.document_name,
              document_type: field.document_name,
              scheme_document_id: field.id,
              files: filesObj,
            });
          }
        } else {
          datadocs.push({
            document_upload_id: "-",
            description: field.document_name,
            document_type: field.document_name,
            scheme_document_id: field.id,
            files: filesObj,
          });
        }
      });

      console.log("datadocs: " + JSON.stringify(datadocs, undefined, 2));
    }

    const files =
      selfDeclarationFileList &&
      selfDeclarationFileList.map((data) => ({
        file_id: data.file_id,
        file_name: data.file_name,
      }));

    var dataNEW = {
      board_id: users.user.board_id,
      scheme_id: role_id,
      labour_user_id: users.user.id,
      is_self: allValues.selfselected ? 1 : 0, ///////// TO BE CHANGED
      applied_for_person_id: users.user.id, ///////// TO BE CHANGED
      applied_date: moment(new Date()).format("YYYY-MM-DD"),
      scheme_approval_status_id: 45,
      unique_id: 12456899, ///////// TO BE CHANGED
      circle_id: deptUserDetails[0].circle_id,
      role_id_from_hirarchy: 1, ///////// TO BE CHANGED
      department_user_id: deptUserDetails[0].department_user_id,
      is_renewal: 0,
      documents: datadocs,
      type_of_issuer_id: issuer,
      labour_incpector_id:
        users.getUserRegistrationDetails.certificate_details[0]
          .labour_incpector_id,
      issue_date: issuedDate,
      document_uploaded_id: fileList90Days
        ? JSON.stringify(fileList90Days)
        : null,
      labour_union_id:
        selectedOption && selectedOption.labour_union_id !== null
          ? selectedOption.labour_union_id
          : "",
      issuer_name:
        issuer === 56
          ? selectedOption && selectedOption.spoke_person
          : issuer === 57
            ? issuerNameGram && issuerNameGram
            : issuer === 58
              ? issuerNameEmployer && issuerNameEmployer
              : "",
      issuer_place:
        issuer === 56
          ? selectedOption && selectedOption.address
          : issuer === 57
            ? issuerPlaceGram && issuerPlaceGram
            : "",
      issuer_designation: issuerDesignation,
      selfDeclaration: files,
    };

    console.log("dataNEW in else : " + JSON.stringify(dataNEW, undefined, 2));

    var config = {
      method: "post",
      url: SERVER + "/schemes/create_scheme_txn",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: dataNEW,
    };

    axios(config)
      .then(function (response) {
        console.log(
          "response create_scheme_txn: " + JSON.stringify(response.data)
        );
        console.log("in_create_scheme_txn: ");
        var Scheme_Avail_id_acknowledgement_id =
          response.data.data.scheme_avail_details[0]
            .Scheme_Avail_id_acknowledgement_id;

        submitButtonPressed2(Scheme_Avail_id_acknowledgement_id);

        console.log(
          "response.data.data.scheme_avail_details[0].Error: " +
            response.data.data.scheme_avail_details[0].Error
        );

        if (response.data.data.scheme_avail_details[0].Error !== undefined) {
          showToast("ERROR", response.data.data.scheme_avail_details[0].Error);
          setLoading(false);
        } else {
          console.log("in_else_create_Scheme");
          console.log(
            "in response.data.data.scheme_avail_details[0].Scheme_Avail_id_acknowledgement_id: " +
              response.data.data.scheme_avail_details[0]
                .Scheme_Avail_id_acknowledgement_id
          );
          // var Scheme_Avail_id_acknowledgement_id = response.data.data[0].Scheme_Avail_id_acknowledgement_id
          var Scheme_Avail_id_acknowledgement_id =
            response.data.data.scheme_avail_details[0]
              .Scheme_Avail_id_acknowledgement_id;

          console.log(
            "in Scheme_Avail_id_acknowledgement_id: " +
              Scheme_Avail_id_acknowledgement_id
          );

          // appNo = response.data.data.scheme_aplication_details[0].scheme_application_code
          setappNo(
            response.data.data.scheme_aplication_details[0]
              .scheme_application_code
          );
          console.log("in appNo: " + appNo);

          setappNo(
            response.data.data.scheme_aplication_details[0]
              .scheme_application_code
          );
          console.log("after setappNo ");

          console.log(
            "Scheme_Avail_id_acknowledgement_id: " +
              Scheme_Avail_id_acknowledgement_id
          );
          console.log("appNo: " + appNo);

          submitButtonPressed2(Scheme_Avail_id_acknowledgement_id);
        }
      })
      .catch(function (error) {
        console.error(error);
        console.error("response error: " + JSON.stringify(error.response));

        showToast("ERROR", "Some error occured while applying for Scheme!");
        setLoading(false);
      });
  };

  const submitButtonPressed2 = (Scheme_Avail_id_acknowledgement_id) => {
    var scheme_avail_id = "";
    var data1 = JSON.stringify({
      board_id: 1,
      labour_user_id: users.user.id,
    });

    var config1 = {
      method: "post",
      url: SERVER + "/schemes/get_schemes_by_labor",
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: data1,
    };

    axios(config1)
      .then(function (response) {
        console.log(JSON.stringify(response.data));

        scheme_avail_id = Scheme_Avail_id_acknowledgement_id;
        console.log("scheme_avail_id: " + scheme_avail_id);

        var fieldMap = [];

        getDynamicFields &&
          getDynamicFields.length &&
          getDynamicFields.forEach((field) => {
            console.log("InsideFieldMap");

            if (minus_one_doc_for_affidavit) {
              if (
                field.id === 141 ||
                field.id === 142 ||
                field.id === 143 ||
                field.id === 145 ||
                field.id === 147
              ) {
                // do nothing
              } else {
                console.log("1field");
                fieldMap.push({
                  field_id: field.id,
                  field_value: allValues[field.field_name],
                  is_text_or_value: field.is_text_or_value === false ? 0 : 1,
                });
              }
            } else if (!allValues.showDeathfields) {
              if (
                field.id === 147 ||
                field.id === 152 ||
                field.id === 146 ||
                field.id === 148 ||
                field.id === 149 ||
                field.id === 150 ||
                field.id === 151 ||
                field.id === 153 ||
                field.id === 154 ||
                field.id === 155 ||
                field.id === 156 ||
                field.id === 157 ||
                field.id === 158
              ) {
                // do nothing for death dropdown
                console.log("In insert scheme");
              } else {
                console.log("2field");
                if (field.field_name === "Tuition Fees") {
                  fieldMap.push({
                    field_id: field.id,
                    field_value: fixedFees,
                    is_text_or_value: field.is_text_or_value === false ? 0 : 1,
                  });
                }
                if (field.field_name === "Hospital/Clinic name") {
                  fieldMap.push({
                    field_id: field.id,
                    field_value: hospitalID,
                    is_text_or_value: field.is_text_or_value === false ? 0 : 1,
                  });
                } else {
                  fieldMap.push({
                    field_id: field.id,
                    field_value: allValues[field.field_name],
                    is_text_or_value: field.is_text_or_value === false ? 0 : 1,
                  });
                }
              }
            } else {
              console.log("3field");
              if (
                field.id === 141 ||
                field.id === 142 ||
                field.id === 143 ||
                field.id === 145 ||
                field.id === 147
              ) {
                // do nothing
              } else {
                console.log("4field");
                fieldMap.push({
                  field_id: field.id,
                  field_value: allValues[field.field_name],
                  is_text_or_value: field.is_text_or_value === false ? 0 : 1,
                });
              }
            }
          });

        var dataNEW = JSON.stringify({
          board_id: 1,
          labour_id: users.user.id,
          scheme_avail_id: scheme_avail_id,
          session_user_id: users.user.id,
          fields: fieldMap,
        });
        var config = {
          method: "post",
          url: SERVER + "/schemes/insert_scheme_values",
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          data: dataNEW,
        };

        console.log("SchemeSubmit", dataNEW);

        axios(config)
          .then(function (response) {
            console.error(
              "response insert_scheme_values: " + JSON.stringify(response.data)
            );

            showToast("SUCCESS", "Applied for Scheme Successfully!");

            setLoading(false);
            setSubmitSuccess(true);
          })
          .catch(function (error) {
            console.error("response error: " + JSON.stringify(error));

            showToast("ERROR", "Some error occured while applying for Scheme!");
            setLoading(false);
          });
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    props.history.push("/schemes-home");
  };

  const printDocument = () => {
    const input = document.getElementById("divToPrint");
    input.style.display = "block";
    html2canvas(input, {
      scrollY: -window.scrollY,
      // allowTaint: true,
      // useCORS: true
    })
      .then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        setAckLetterImg(imgData);
        const pdf = new jsPDF();
        pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
        // pdf.addImage(imgData, 'JPEG', 0, 0);
        pdf.save("acknowledgement.pdf");
        window.open(pdf.output("bloburl"), "_blank");
      })
      .then(() => {
        input.style.display = "none";
      });
  };

  // var appNo = ""
  // if (users.getApplicationNo !== null && users.getApplicationNo !== "") {
  //     if (users.getApplicationNo.length > 0)
  //         appNo = users.getApplicationNo[0].application_no
  //     else
  //         appNo = "test"
  // }

  var applicantName = "";
  if (users.user.first_name !== "" && users.user.first_name !== null) {
    applicantName += users.user.first_name + " ";
  }
  if (users.user.middle_name !== "" && users.user.middle_name !== null) {
    applicantName += users.user.middle_name + " ";
  }
  if (users.user.last_name !== "" && users.user.last_name !== null) {
    applicantName += users.user.last_name;
  }

  var date = new Date();

  if (
    users.getUserRegistrationDetails !== null &&
    users.getUserRegistrationDetails !== undefined &&
    users.getUserRegistrationDetails !== "Network Error" &&
    users.getUserRegistrationDetails.address_details !== undefined &&
    users.getUserRegistrationDetails.address_details.length > 0
  ) {
    var presentAddress = "";
    if (
      users.getUserRegistrationDetails.address_details[0].door_no !== "" &&
      users.getUserRegistrationDetails.address_details[0].door_no !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].door_no + " ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].street_name !== "" &&
      users.getUserRegistrationDetails.address_details[0].street_name !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].street_name + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].land_mark !== "" &&
      users.getUserRegistrationDetails.address_details[0].land_mark !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].land_mark + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].panhayat_city_town !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].panhayat_city_town;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].district !== "" &&
      users.getUserRegistrationDetails.address_details[0].district !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].district;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].taluk !== "" &&
      users.getUserRegistrationDetails.address_details[0].taluk !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].taluk;
      presentAddress += x + ", ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].state !== "" &&
      users.getUserRegistrationDetails.address_details[0].state !== null
    ) {
      var x = users.getUserRegistrationDetails.address_details[0].state;
      presentAddress += x + " - ";
    }
    if (
      users.getUserRegistrationDetails.address_details[0].pin_code !== "" &&
      users.getUserRegistrationDetails.address_details[0].pin_code !== null
    ) {
      presentAddress +=
        users.getUserRegistrationDetails.address_details[0].pin_code;
    }

    var villageWard = "";
    if (
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        "" &&
      users.getUserRegistrationDetails.address_details[0].ward_area_village !==
        null
    ) {
      var x =
        users.getUserRegistrationDetails.address_details[0].ward_area_village;
      villageWard += x;
    }

    var mobile = "";
    if (
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== "" &&
      users.getUserRegistrationDetails.personal_details[0].mobile_no !== null
    ) {
      mobile += users.getUserRegistrationDetails.personal_details[0].mobile_no;
    }

    var occupation = "";
    if (
      users.getUserRegistrationDetails.employer_details !== "" &&
      users.getUserRegistrationDetails.employer_details.length > 0
    ) {
      var y = users.getUserRegistrationDetails.employer_details
        .map(function (e) {
          return e.catalog_value_employement_status_id;
        })
        .indexOf(25);

      console.log("y=" + y);
      var x =
        users.getUserRegistrationDetails.employer_details[y].nature_of_work;

      occupation += x;
    }

    var officerIncharge =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_first_name;
    var officerIncharge1 =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_middle_name;
    var officerIncharge2 =
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_last_name; // if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 55) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].inspector_first_name
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 56) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].labour_union_spoke_person
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 57) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
    // }
    // else if (users.getUserRegistrationDetails.certificate_details[0].catalog_value_type_of_issuer_id === 58) {
    //     officerIncharge = users.getUserRegistrationDetails.certificate_details[0].issuer_name
    // }

    var circleName = "";
    if (
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== "" &&
      users.getUserRegistrationDetails.certificate_details[0]
        .inspector_circle !== null
    ) {
      circleName =
        users.getUserRegistrationDetails.certificate_details[0]
          .inspector_circle;
    }
  }

  const arr1 = [];
  console.log(typeof arr1, "ArrayData");

  const hospitalList =
    getDynamicFieldValue &&
    getDynamicFieldValue.length &&
    getDynamicFieldValue.filter(
      (data) => data.fieldName === "Hospital/Clinic name"
    );
  const hospitalListData = hospitalList && hospitalList.map((data) => data);
  // const hospitalListData2 = hospitalListData && hospitalListData[0].map((data) => data)
  console.log(hospitalList, "HospitalLIst");
  const filteredHospitalList =
    hospitalListData &&
    hospitalListData[0] &&
    hospitalListData[0].data &&
    hospitalList[0].data.data &&
    hospitalListData[0].data.data;
  console.log(filteredHospitalList, "HospitalLIstData");
  console.log(typeof filteredHospitalList, "HospitalLIstData2");
  const autoCompleteList =
    filteredHospitalList &&
    filteredHospitalList.map((data) => ({
      ...data,
      label: data.value,
      id: data.id,
    }));
  // console.log(hospitalListData2,"hospitalListData2");

  function returnComponent() {
    if (
      getDynamicFields &&
      getDynamicFields.length > 0 &&
      getDynamicFieldValue &&
      getDynamicFieldValue.length > 0
    ) {
      // console.log(getDynamicFields,"sgssfhgsdfdgtt")
      return (
        getDynamicFields &&
        getDynamicFields.length > 0 &&
        getDynamicFields.map((field, i) => {
          console.log("allValues.fields-> field:" + JSON.stringify(field));
          console.log("allValuesDynamic", getDynamicFields[field.field_name]);
          switch (field.field_type) {
            case "Dropdown":
            case "Radio Button":
              console.log("in drop");
              return (
                <>
                  {allValues["Accident Resulted In"] !== 27 &&
                  field.field_name.includes("Applicant Type") &&
                  idName === "Accident Assistance" ? (
                    <>
                      {allValues.showDeathfields ? (
                        <>
                          <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                            {field.is_required ? (
                              <Required
                                className='mb-2 mt-4'
                                title={
                                  allValues.descriptionsKannada
                                    ? field.field_nake_kannada
                                    : field.field_name
                                }
                              />
                            ) : (
                              <p className='mb-2 mt-4'>
                                {allValues.descriptionsKannada
                                  ? field.field_nake_kannada
                                  : field.field_name}{" "}
                              </p>
                            )}
                            <FormControl
                              variant='outlined'
                              fullWidth
                              className='formcontrol5'
                            >
                              <Select
                                className='select-marital'
                                labelId='demo-simple-select-required-label'
                                id='demo-simple-select-required'
                                // value={allValues.marriage_assistance_for}
                                // name="marriage_assistance_for"
                                name={field.field_name}
                                value={
                                  allValues[field.field_name] !== undefined
                                    ? allValues[field.field_name]
                                    : ""
                                }
                                displayEmpty
                                onChange={handleChange}
                              >
                                <MenuItem value=''>
                                  <ListItemText primary='Select' />
                                </MenuItem>

                                {getDynamicFieldValue &&
                                  getDynamicFieldValue.map((i) =>
                                    i.fieldName === field.field_name
                                      ? i.data.data.map((j) => (
                                          <MenuItem value={j.id}>
                                            <ListItemText primary={j.value} />
                                          </MenuItem>
                                        ))
                                      : null
                                  )}
                              </Select>
                            </FormControl>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : field.field_name.includes(
                      "Hospital/Clinic name"
                    ) ? null : (
                    <>
                      <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                        {field.is_required ? (
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                        ) : (
                          <p className='mb-2 mt-4'>
                            {allValues.descriptionsKannada
                              ? field.field_nake_kannada
                              : field.field_name}{" "}
                          </p>
                        )}
                        <FormControl
                          variant='outlined'
                          fullWidth
                          className='formcontrol5'
                        >
                          <Select
                            className='select-marital'
                            labelId='demo-simple-select-required-label'
                            id='demo-simple-select-required'
                            // value={allValues.marriage_assistance_for}
                            // name="marriage_assistance_for"
                            name={field.field_name}
                            value={
                              allValues[field.field_name] !== undefined
                                ? allValues[field.field_name]
                                : ""
                            }
                            displayEmpty
                            onChange={handleChange}
                          >
                            <MenuItem value=''>
                              <ListItemText primary='Select' />
                            </MenuItem>

                            {/* { setHideSelfMarriage && ? null :
                                            allValues.promResponses.map((i) =>
                                                i.fieldName === field.field_name ?
                                                    i.data.data.map((j) =>
                                                        <MenuItem value={j.id}>
                                                            <ListItemText primary={j.value} />
                                                        </MenuItem>
                                                    )
                                                    :
                                                    null

                                            )
                                        } : */}
                            {getDynamicFieldValue &&
                              getDynamicFieldValue.map((i) =>
                                i.fieldName === field.field_name
                                  ? i.data.data.map((j) => (
                                      <MenuItem value={j.id}>
                                        {console.log(
                                          "deliveryChildState",
                                          deliveryChildState
                                        )}
                                        {(deliveryChildState === true &&
                                          j.value === "Child 1") ||
                                        (selfMarriageState &&
                                          j.value === "Self") ? null : (
                                          <ListItemText primary={j.value} />
                                        )}
                                      </MenuItem>
                                    ))
                                  : null
                              )}
                          </Select>
                        </FormControl>
                      </Col>
                    </>
                  )}

                  {field.field_name.includes("Hospital/Clinic name") ? (
                    <>
                      <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                        {field.is_required ? (
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                        ) : (
                          <p className='mb-2 mt-4'>
                            {allValues.descriptionsKannada
                              ? field.field_nake_kannada
                              : field.field_name}{" "}
                          </p>
                        )}
                        <FormControl
                          variant='outlined'
                          fullWidth
                          className='formcontrol5'
                        >
                          <Autocomplete
                            disablePortal
                            id='combo-box-demo'
                            style={{ width: "inherit" }}
                            options={autoCompleteList && autoCompleteList}
                            sx={{ width: 300 }}
                            value={hospitalName}
                            onChange={(value, newValue) =>
                              handleHospital(newValue)
                            }
                            renderInput={(params) => (
                              <TextField
                                className='select-marital'
                                {...params}
                                label='List'
                              />
                            )}
                          />
                        </FormControl>
                      </Col>
                    </>
                  ) : null}
                </>
              );

            case "Text":
              console.log("in text, field.field_name: ", field.field_name);

              return (
                <>
                  {console.log(" in101")}
                  {(field.field_name.includes("Applicant") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Name of the Applicant") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Phone Number") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Age of Applicant") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Permanent Address") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Relationship with Beneficiary") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Aadhaar Number") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Applicant Bank Name") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Applicant Account Number") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Applicant Bank Branch") &&
                    idName === "Accident Assistance") ||
                  (field.field_name.includes("Applicant Bank IFSC Code") &&
                    idName === "Accident Assistance") ? (
                    <>
                      {console.log(" in102")}
                      {allValues.showBrideData === true ? (
                        <>
                          {console.log(" in103")}
                          <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                            {field.is_required ? (
                              <Required
                                className='mb-2 mt-4'
                                title={
                                  allValues.descriptionsKannada
                                    ? field.field_nake_kannada
                                    : field.field_name
                                }
                              />
                            ) : (
                              <p className='mb-2 mt-4'>
                                {allValues.descriptionsKannada
                                  ? field.field_nake_kannada
                                  : field.field_name}{" "}
                              </p>
                            )}
                            {/* <Required className="mb-2 mt-4" title={field.field_name} /> */}
                            <FormControl fullWidth className='formcontrol1'>
                              <TextField
                                variant='outlined'
                                // placeholder="Enter Bank Name"
                                name={field.field_name}
                                value={allValues[field.field_name]}
                                // value={
                                //     field.field_name.includes("Name of the dependent  ") &&
                                //     allValues.medicalAssistanceSelf ? allValues.applicantName : allValues[field.field_name]}
                                onChange={(e) => handleChange(e, field)}
                                // type = {field.field_name.includes("Cost") ? "number": "text"}
                                // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                                helperText={allValues["error_" + field.id]}
                                error={allValues.globalError}
                                disabled={
                                  field.field_name.includes(
                                    "Name of the dependent "
                                  ) && allValues.medicalAssistanceSelf
                                }

                                //value={allValues[field.field_name]}
                                //my
                                // {
                                //     field.field_name == "From(Source address)"?
                                //     inputProps={{ maxLength: 12 }}
                                //     :null
                                // }
                              />
                            </FormControl>
                          </Col>
                        </>
                      ) : null}
                    </>
                  ) : (
                    <>
                      {"Name of the dependent  (If Dependent is selected above)" ? null : (
                        <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                          {console.log(" in104")}
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                          <FormControl fullWidth className='formcontrol1'>
                            <TextField
                              variant='outlined'
                              // placeholder="Enter Bank Name"
                              name={field.field_name}
                              value={field.field_name}
                              // value={
                              //     field.field_name.includes("Name of the dependent  ") &&
                              //     allValues.medicalAssistanceSelf ? allValues.applicantName : allValues[field.field_name]}
                              onChange={(e) => handleChange(e, field)}
                              // type = {field.field_name.includes("Cost") ? "number": "text"}
                              // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                              helperText={allValues["error_" + field.id]}
                              error={allValues.globalError}
                              disabled={
                                field.field_name.includes(
                                  "Name of the dependent "
                                )
                                  ? allValues.medicalAssistanceSelf
                                  : field.field_name.includes("Groom Name")
                                    ? allValues.showGroom
                                    : field.field_name.includes("Bride Name")
                                      ? allValues.showBride
                                      : false
                              }

                              //value={allValues[field.field_name]}
                              //my
                              // {
                              //     field.field_name == "From(Source address)"?
                              //     inputProps={{ maxLength: 12 }}
                              //     :null
                              // }
                            />
                          </FormControl>
                        </Col>
                      )}
                    </>
                  )}

                  {field.field_name === "Groom Name" &&
                  allValues.showGroomDropdown ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in105")}
                      {field.is_required ? (
                        <Required
                          className='mb-2 mt-4'
                          title={
                            allValues.descriptionsKannada
                              ? field.field_nake_kannada
                              : field.field_name
                          }
                        />
                      ) : (
                        <p className='mb-2 mt-4'>
                          {allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name}{" "}
                        </p>
                      )}
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          labelId='demo-simple-select-required-label'
                          id='demo-simple-select-required'
                          // value={allValues.marriage_assistance_for}
                          // name="marriage_assistance_for"
                          name={field.field_name}
                          value={
                            allValues[field.field_name] !== undefined
                              ? allValues[field.field_name]
                              : ""
                          }
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='Select' />
                          </MenuItem>

                          {users.getUserRegistrationDetails.family_details &&
                            users.getUserRegistrationDetails.family_details
                              .filter(
                                (member) =>
                                  member.catalog_value_parent_child_type_id ===
                                  54
                              )
                              .map((fam) => (
                                <MenuItem value={fam.first_name.trim()}>
                                  <ListItemText
                                    primary={[
                                      fam.first_name,
                                      fam.middle_name,
                                      fam.last_name,
                                    ].join(" ")}
                                  />
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Col>
                  ) : field.field_name === "Groom Name" ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in104")}
                      <Required
                        className='mb-2 mt-4'
                        title={
                          allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name
                        }
                      />
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          // placeholder="Enter Bank Name"
                          name={field.field_name}
                          value={allValues[field.field_name]}
                          // value={
                          //     field.field_name.includes("Name of the dependent  ") &&
                          //     allValues.medicalAssistanceSelf ? allValues.applicantName : allValues[field.field_name]}
                          onChange={(e) => handleChange(e, field)}
                          // type = {field.field_name.includes("Cost") ? "number": "text"}
                          // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                          helperText={allValues["error_" + field.id]}
                          error={allValues.globalError}
                          disabled={
                            field.field_name.includes("Name of the dependent ")
                              ? allValues.medicalAssistanceSelf
                              : field.field_name.includes("Groom Name")
                                ? allValues.showGroom
                                : field.field_name.includes("Bride Name")
                                  ? allValues.showBride
                                  : false
                          }

                          //value={allValues[field.field_name]}
                          //my
                          // {
                          //     field.field_name == "From(Source address)"?
                          //     inputProps={{ maxLength: 12 }}
                          //     :null
                          // }
                        />
                      </FormControl>
                    </Col>
                  ) : null}

                  {field.field_name === "Bride Name" &&
                  allValues.showBrideDropdown ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in105")}
                      {field.is_required ? (
                        <Required
                          className='mb-2 mt-4'
                          title={
                            allValues.descriptionsKannada
                              ? field.field_nake_kannada
                              : field.field_name
                          }
                        />
                      ) : (
                        <p className='mb-2 mt-4'>
                          {allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name}{" "}
                        </p>
                      )}
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          labelId='demo-simple-select-required-label'
                          id='demo-simple-select-required'
                          // value={allValues.marriage_assistance_for}
                          // name="marriage_assistance_for"
                          name={field.field_name}
                          value={
                            allValues[field.field_name] !== undefined
                              ? allValues[field.field_name]
                              : ""
                          }
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='Select' />
                          </MenuItem>

                          {users.getUserRegistrationDetails.family_details &&
                            users.getUserRegistrationDetails.family_details
                              .filter(
                                (member) =>
                                  member.catalog_value_parent_child_type_id ===
                                  53
                              )
                              .map((fam) => (
                                <MenuItem value={fam.first_name.trim()}>
                                  {/* <ListItemText primary={[fam.first_name,fam.middle_name,fam.last_name].join(" ")} /> */}
                                  <ListItemText
                                    primary={[
                                      fam.first_name,
                                      fam.middle_name,
                                      fam.last_name,
                                    ].join(" ")}
                                  />
                                </MenuItem>
                              ))}
                        </Select>
                      </FormControl>
                    </Col>
                  ) : field.field_name === "Bride Name" ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in104")}
                      <Required
                        className='mb-2 mt-4'
                        title={
                          allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name
                        }
                      />
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          // placeholder="Enter Bank Name"
                          name={field.field_name}
                          value={allValues[field.field_name]}
                          // value={
                          //     field.field_name.includes("Name of the dependent  ") &&
                          //     allValues.medicalAssistanceSelf ? allValues.applicantName : allValues[field.field_name]}
                          onChange={(e) => handleChange(e, field)}
                          // type = {field.field_name.includes("Cost") ? "number": "text"}
                          // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                          helperText={allValues["error_" + field.id]}
                          error={allValues.globalError}
                          disabled={
                            field.field_name.includes("Name of the dependent ")
                              ? allValues.medicalAssistanceSelf
                              : field.field_name.includes("Groom Name")
                                ? allValues.showGroom
                                : field.field_name.includes("Bride Name")
                                  ? allValues.showBride
                                  : false
                          }

                          //value={allValues[field.field_name]}
                          //my
                          // {
                          //     field.field_name == "From(Source address)"?
                          //     inputProps={{ maxLength: 12 }}
                          //     :null
                          // }
                        />
                      </FormControl>
                    </Col>
                  ) : null}
                  {(field.field_name ===
                    "Name of the dependent  (If Dependent is selected above)" ||
                    field.field_name ===
                      "Name of the dependent (If Dependent is selected above)") &&
                  allValues.showDependentDrowDown ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in105")}
                      {field.is_required ? (
                        <Required
                          className='mb-2 mt-4'
                          title={
                            allValues.descriptionsKannada
                              ? field.field_nake_kannada
                              : field.field_name
                          }
                        />
                      ) : (
                        <p className='mb-2 mt-4'>
                          {allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name}{" "}
                        </p>
                      )}
                      <FormControl
                        variant='outlined'
                        fullWidth
                        className='formcontrol5'
                      >
                        <Select
                          className='select-marital'
                          labelId='demo-simple-select-required-label'
                          id='demo-simple-select-required'
                          // value={allValues.marriage_assistance_for}
                          // name="marriage_assistance_for"
                          name={field.field_name}
                          value={
                            allValues[field.field_name] !== undefined
                              ? allValues[field.field_name]
                              : ""
                          }
                          displayEmpty
                          onChange={handleChange}
                        >
                          <MenuItem value=''>
                            <ListItemText primary='Select' />
                          </MenuItem>

                          {users.getUserRegistrationDetails.family_details &&
                            users.getUserRegistrationDetails.family_details.map(
                              (fam) => (
                                <MenuItem value={fam.first_name}>
                                  <ListItemText
                                    primary={[
                                      fam.first_name,
                                      fam.middle_name,
                                      fam.last_name,
                                    ].join(" ")}
                                  />
                                </MenuItem>
                              )
                            )}
                        </Select>
                      </FormControl>
                    </Col>
                  ) : (
                    <>
                      {field.field_name === "Tuition Fees" ||
                      field.field_name === "Groom Name" ||
                      field.field_name === "Bride Name" ? null : (
                        <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                          {console.log(" in106")}
                          <Required
                            className='mb-2 mt-4'
                            title={
                              allValues.descriptionsKannada
                                ? field.field_nake_kannada
                                : field.field_name
                            }
                          />
                          <FormControl fullWidth className='formcontrol1'>
                            <TextField
                              variant='outlined'
                              // placeholder="Enter Bank Name"
                              name={field.field_name}
                              value={allValues[field.field_name]}
                              // value={
                              //     field.field_name.includes("Name of the dependent  ") &&
                              //     allValues.medicalAssistanceSelf ? allValues.applicantName : allValues[field.field_name]}
                              onChange={(e) => handleChange(e, field)}
                              // type = {field.field_name.includes("Cost") ? "number": "text"}
                              // helperText={field.field_name.includes("Name of the dependent") && allValues.medicalAlphaError ? "Please enter in correct format" :  "" }
                              helperText={allValues["error_" + field.id]}
                              error={allValues.globalError}
                              disabled={
                                field.field_name.includes(
                                  "Name of the dependent "
                                )
                                  ? allValues.medicalAssistanceSelf
                                  : field.field_name.includes("Groom Name")
                                    ? allValues.showGroom
                                    : field.field_name.includes("Bride Name")
                                      ? allValues.showBride
                                      : false
                              }

                              //value={allValues[field.field_name]}
                              //my
                              // {
                              //     field.field_name == "From(Source address)"?
                              //     inputProps={{ maxLength: 12 }}
                              //     :null
                              // }
                            />
                          </FormControl>
                        </Col>
                      )}
                    </>
                  )}

                  {field.field_name === "Tuition Fees" &&
                  allValues.showTuitionField === true ? (
                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                      {console.log(" in107")}
                      <Required
                        className='mb-2 mt-4'
                        title={
                          allValues.descriptionsKannada
                            ? field.field_nake_kannada
                            : field.field_name
                        }
                      />
                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          // placeholder="Enter Bank Name"
                          name={field.field_name}
                          // value={allValues[field.field_name]}
                          value={fixedFees}
                          onChange={(e) => handleChange(e, field)}
                          helperText={allValues["error_" + field.id]}
                          error={allValues.globalError}
                          disabled={disableTuitionFees ? true : false}

                          //value={allValues[field.field_name]}
                          //my
                          // {
                          //     field.field_name == "From(Source address)"?
                          //     inputProps={{ maxLength: 12 }}
                          //     :null
                          // }
                        />
                      </FormControl>
                    </Col>
                  ) : null}
                </>
              );

            case "Date":
              console.log("in date");
              return (
                <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                  {field.is_required ? (
                    <Required
                      className='mb-2 mt-4'
                      title={
                        allValues.descriptionsKannada
                          ? field.field_nake_kannada
                          : field.field_name
                      }
                    />
                  ) : (
                    <p className='mb-2 mt-4'>{field.field_name} </p>
                  )}
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={
                        field.field_name.includes("Date Of Marriage")
                          ? moment().subtract(6, "months")
                          : new Date(-999999)
                      }
                      maxDate={new Date()}
                      onClick={(e) => onChangeDate(e, field)}
                      onClose={() => setPickerStatus(false)}
                      open={allValues.openDatePicker}
                      className='datepicker'
                      margin='normal'
                      format='DD/MM/YYYY'
                      name={field.field_name}
                      id={id}
                      value={
                        allValues[field.field_name] !== undefined
                          ? moment(allValues[field.field_name])
                          : allValues[field.field_nake_kannada] !== undefined
                            ? moment(allValues[field.field_nake_kannada])
                            : null
                      }
                      onChange={setDate}
                      placeholder='DD/MM/YYYY'
                      error={allValues.globalError}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>
              );

            default:
              console.log("in default");
              return null;
          }
        })
      );
    }
  }

  const dynamicFields = () => {
    return returnComponent();
  };

  console.log("getDynamicFields", getDynamicFields);

  // Self Declaration FILE UPLOAD

  const handleSelfDeclarationUpload = (event) => {
    setLoadingFiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !event.target.files[0].type === "image/png" ||
      !event.target.files[0].type === "image/jpeg" ||
      !event.target.files[0].type === "image/jpg" ||
      !event.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedSelfDeclarationFiles).concat(
        Array.from(event.target.files)
      );

      setSelectedSelfDeclarationFiles(joined);
    }

    setLoadingFiles(false);
  };

  const uploadFileAPI = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", users && users.user.id);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  useEffect(() => {
    if (
      selectedSelfDeclarationFiles &&
      selectedSelfDeclarationFiles.length > 0
    ) {
      callQualificationDocUpload();
    }
  }, [selectedSelfDeclarationFiles]);

  const callQualificationDocUpload = () => {
    let promises = [];
    selectedSelfDeclarationFiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          fileType: fileName,
        };
        promises.push(uploadFileAPI(formdataobj, fileName));
        if (promises.length === selectedSelfDeclarationFiles.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);

            var filelist = [];
            results.forEach((i) => {
              var filejson = {
                file_id: i.image.fileId,
                file_name: i.image.fileType,
              };
              filelist.push(filejson);
            });

            console.log("filelist: ", filelist);
            setSelfDeclarationFileList(filelist);
          });
        }
      };
    });
  };

  const removeFileSelfDeclaration = (index) => {
    setLoadingFiles(true);
    console.log("removefile index: " + index);
    setRemovedFile(true);
    // selectedfiles.splice(1,index)
    selectedSelfDeclarationFiles.splice(index, 1);

    setTimeout(() => setLoadingFiles(false), 1);
  };

  return (
    <>
      <div className='root'>
        {loading ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <CircularProgress color='inherit' />
          </Backdrop>
        ) : null}

        {submitSuccess ? (
          <Backdrop
            className={classes.backdrop}
            open={openBackdrop}
            onClick={loading ? null : handleCloseBackdrop}
          >
            <div style={{ display: "block" }}>
              <CheckCircle
                className={classes.backdropCheck}
                style={{ height: "auto", width: "200px" }}
              />
              <p className='final-success-title'>
                Successfully submitted for
                <br />
                Scheme!
              </p>

              <p className='final-success-link-p'>
                View Acknowledgement Letter{" "}
                <Link
                  to='#'
                  onClick={printDocument}
                  style={{ flexGrow: "0.5" }}
                >
                  <span
                    variant='outline-primary'
                    className='final-success-link'
                  >
                    {" "}
                    Click Here
                  </span>
                </Link>
              </p>
            </div>
          </Backdrop>
        ) : null}

        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/dashboard-user'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value='header.title' /> <br />
                  <Translate value='header.sub' />
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              <Select
                className='select-language'
                style={{ width: "100%" }}
                variant='outlined'
                labelId='demo-simple-select-required-label'
                value={allValues.language}
                name='language'
                displayEmpty
                onChange={handleChange1}
              >
                <MenuItem value=''>
                  <ListItemIcon>
                    <img alt='...' src={language} className='language-img' />
                  </ListItemIcon>
                  <ListItemText primary='Select Language' />
                </MenuItem>
                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                <MenuItem value='en'>
                  <ListItemText primary='English' />
                </MenuItem>
                <MenuItem value='ka'>
                  <ListItemText primary='ಕನ್ನಡ' />
                </MenuItem>
              </Select>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              {/* <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                            // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                                <MenuItem value="logout" onClick={() => { props.history.push("/") }}>
                                    <ListItemText primary="Logout" />
                                </MenuItem>
                            </Select> */}
              <div className='usericon-header-logout'>
                <img alt='...' src={usericon} className='usericon-img' />

                <p>
                  {users.user !== undefined &&
                    users.user !== null &&
                    users.user.first_name}
                </p>
                <Tooltip title='Logout' placement='top-start' arrow interactive>
                  <img
                    alt='...'
                    src={logout}
                    className='logout-img'
                    onClick={() => {
                      props.history.push("/");
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          {/* <h1>Schemes | {idName}</h1> */}
          <Link onClick={props.history.goBack} className='cal-icon-hover-icn'>
            <img
              alt='...'
              className='cal-icon hover-icn'
              src={back}
              style={{ marginTop: "10px" }}
            />
          </Link>
          <h1>
            {" "}
            <Translate value='schemes' /> |
            {allValues.descriptionsKannada ? kannadaSchemeName : idName}
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row mt-4'>
        <Col xs={12} className='searchform-subtitle'>
          {/* <p>Applicant Details</p> */}
          <p>
            <Translate value='applicantDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          {/* <Required className="mb-2" title="Registration Number" /> */}

          {/* <p className="mb-2">Name of the Applicant </p> */}
          <p className='mb-2'>
            <Translate value='nameoftheApplicant' />{" "}
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.applicantName}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <Required className="mb-2" title="Registration Number" /> */}

          {/* <p className="mb-2">Date of birth</p> */}
          <p className='mb-2'>
            <Translate value='dateofbirth' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.dob} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <Required className="mb-2" title="Registration Number" /> */}

          {/* <p className="mb-2">Age of Applicant</p> */}
          <p className='mb-2'>
            <Translate value='ageofApplicant' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.age} disabled />
          </FormControl>
        </Col>

        <Col xs={12} md={4}>
          {/* <Required className="mb-2" title="Registration Number" /> */}
          {/* <p className="mb-2 mt-4">Mobile Number</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='mobileNumber' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.mobile} disabled />
          </FormControl>
        </Col>
        {/* <Col xs={12} md={4}>
                    <Required className="mb-2" title="Registration Number" />
                    <p className="mb-2 mt-4">AADHAAR Number</p>
                    <p className="mb-2 mt-4"><Translate value="aADHAARNumber" /></p>
                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                            variant="outlined"
                            value={allValues.aadhaar}
                            disabled
                        />
                    </FormControl>
                </Col> */}
        <Col xs={12} md={4}>
          {/* <Required className="mb-2" title="Registration Number" /> */}
          {/* <p className="mb-2 mt-4">Ration Card Number</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='rationCardNumber' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.ration} disabled />
          </FormControl>
        </Col>

        <Col xs={12} md={8}>
          {/* <Required className="mb-2" title="Registration Number" /> */}
          {/* <p className="mb-2 mt-4">Present Address of Applicant</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='presentAddressofApplicant' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.address} disabled />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          {/* <p>Address Where Applicant is Registered</p> */}
          <p>
            <Translate value='addressWhereApplicantisRegistered' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">State</p> */}
          <p className='mb-2'>
            <Translate value='state' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.state} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">District</p> */}
          <p className='mb-2'>
            <Translate value='district' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.district} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Taluk</p> */}
          <p className='mb-2'>
            <Translate value='taluk' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.taluk} disabled />
          </FormControl>
        </Col>

        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">Gram Panchayat</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='gramPanchayat' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.gram} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">Village / Ward</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='villageWard' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.village} disabled />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          {/* <p>Bank Details</p> */}
          <p>
            <Translate value='bankDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Bank Name</p> */}
          <p className='mb-2'>
            <Translate value='bankName' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.bank_name}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Account Number</p> */}
          <p className='mb-2'>
            <Translate value='accountNumber' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.account_no}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Branch Name</p> */}
          <p className='mb-2'>
            <Translate value='branchName' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.bank_branch}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">IFSC Code</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='iFSCCode' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.ifsc_code}
              disabled
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          {/* <p>Registration Details</p> */}
          <p>
            <Translate value='registrationDetails' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Registration Number</p> */}
          <p className='mb-2'>
            <Translate value='registrationNumber' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.registration_code}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Date of Registration</p> */}
          <p className='mb-2'>
            <Translate value='dateofRegistration' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.registration_date}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2">Date of Completion of 60 Years</p> */}
          <p className='mb-2'>
            <Translate value='dateofCompletionof60Years' />
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField variant='outlined' value={allValues.age60} disabled />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">Age at the Time of Registration</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='ageattheTimeofRegistration' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.age_at_registration}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">Number of years Registered</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='numberofyearsRegistered' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.years_registered}
              disabled
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4}>
          {/* <p className="mb-2 mt-4">Renewal date of Registration</p> */}
          <p className='mb-2 mt-4'>
            <Translate value='renewaldateofRegistration' />
          </p>
          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              value={allValues.renewal_date}
              disabled
            />
          </FormControl>
        </Col>
        {props.location &&
        props.location.pathname.includes("Continuation of Pension") ? (
          <Col xs={12} md={4}>
            {/* <p className="mb-2 mt-4">Renewal date of Registration</p> */}
            <p className='mb-2 mt-4'>
              <Translate value='pensionNumber' />
            </p>
            <FormControl fullWidth className='formcontrol1'>
              <TextField
                variant='outlined'
                value={
                  users.getUserRegistrationDetails.personal_details[0] &&
                  users.getUserRegistrationDetails.personal_details[0]
                    .pension_no
                }
                disabled
              />
            </FormControl>
          </Col>
        ) : props.location &&
          props.location.pathname.includes(
            "Continuation of Disability Pension"
          ) ? (
          <Col xs={12} md={4}>
            {/* <p className="mb-2 mt-4">Renewal date of Registration</p> */}
            <p className='mb-2 mt-4'>
              <Translate value='disabilityPensionNumber' />
            </p>
            <FormControl fullWidth className='formcontrol1'>
              <TextField
                variant='outlined'
                value={
                  users.getUserRegistrationDetails.personal_details[0] &&
                  users.getUserRegistrationDetails.personal_details[0]
                    .disability_pension_no
                }
                disabled
              />
            </FormControl>
          </Col>
        ) : null}
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>{allValues.descriptionsKannada ? kannadaSchemeName : idName}</p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        {getDynamicFields && getDynamicFields.length ? (
          dynamicFields()
        ) : (
          <>Please wait fetching</>
        )}

        {/* {
                    (getDynamicFields && getDynamicFields.length) || (getDynamicFieldValue && getDynamicFieldValue.length)  ?

                        <>
                        {
                            getDynamicFields && getDynamicFields.map((field,i)=>(

                                field.field_type === "Dropdown" || field.field_type === "Radio Button" ? 
                                <>
                                  <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                                                    {
                                                        field.is_required ?
                                                            <Required className="mb-2 mt-4" title={allValues.descriptionsKannada ? field.field_nake_kannada : field.field_name} />
                                                            :
                                                            <p className="mb-2 mt-4">{allValues.descriptionsKannada ? field.field_nake_kannada : field.field_name} </p>
                                                    }
                                                    <FormControl variant="outlined" fullWidth className="formcontrol5">
                                                        <Select
                                                            className="select-marital"
                                                            labelId="demo-simple-select-required-label"
                                                            id="demo-simple-select-required"
                                                            name={field.field_name}
                                                            value={allValues[field.field_name] !== undefined ?
                                                                allValues[field.field_name] : ""
                                                            }
                                                            displayEmpty
                                                            onChange={handleChange}
                                                        >
                                                            <MenuItem value="">
                                                                <ListItemText primary="Select" />
                                                            </MenuItem>

                                                            {
                                                              getDynamicFieldValue &&  getDynamicFieldValue.map((i) =>
                                                                    i.fieldName === field.field_name ?
                                                                        i.data.data.map((j) =>
                                                                            <MenuItem value={j.id}>

                                                                                <ListItemText primary={j.value} />



                                                                            </MenuItem>
                                                                        )
                                                                        :
                                                                        null

                                                                )
                                                            }
                                                        </Select>
                                                    </FormControl>
                                                </Col>  
                                </>
                                : field.field_type === "Text" ?
                                <>
                                    <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                                                            {
                                                                field.is_required ?
                                                                    <Required className="mb-2 mt-4" title={allValues.descriptionsKannada ? field.field_nake_kannada : field.field_name} />
                                                                    :
                                                                    <p className="mb-2 mt-4">{allValues.descriptionsKannada ? field.field_nake_kannada : field.field_name} </p>
                                                            }
                                                            <Required className="mb-2 mt-4" title={field.field_name} />
                                                            <FormControl fullWidth className="formcontrol1">
                                                                <TextField
                                                                    variant="outlined"
                                                                    placeholder="Enter Bank Name"
                                                                    name={field.field_name}
                                                                    value={allValues[field.field_name]}
                                                                    onChange={(e) => handleChange(e, field)}
                                                                    helperText={allValues["error_" + field.id]}
                                                                    error={allValues.globalError}
                                                                    disabled={
                                                                        field.field_name.includes("Name of the dependent ") &&
                                                                        allValues.medicalAssistanceSelf
                                                                    }

                                                                


                                                                />
                                                            </FormControl>
                                                        </Col>
                                </>
                                : field.field_type === "Date" ? 
                                <>
                                  <Col xs={12} md={4} style={{ alignSelf: "end" }}>
                                {
                                    field.is_required ?
                                        <Required className="mb-2 mt-4" title={allValues.descriptionsKannada ? field.field_nake_kannada : field.field_name} />
                                        :
                                        <p className="mb-2 mt-4">{field.field_name} </p>
                                }
                                <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        minDate={
                                            field.field_name.includes("Date Of Marriage") ?
                                                moment().subtract(6, "months")
                                                :
                                                new Date(-999999)
                                        }
                                        maxDate={new Date()}
                                        onClick={(e) => onChangeDate(e, field)}
                                        onClose={() => setPickerStatus(false)}
                                        open={allValues.openDatePicker}
                                        className="datepicker"
                                        margin="normal"
                                        format="DD/MM/YYYY"
                                        name={field.field_name}
                                        id={id}
                                        value={
                                            allValues[field.field_name] !== undefined ?
                                                moment(allValues[field.field_name]) 
                                                : allValues[field.field_nake_kannada] !== undefined ? moment(allValues[field.field_nake_kannada]) : null
                                        }
                                        onChange={setDate}
                                        placeholder="DD/MM/YYYY"
                                        error={allValues.globalError}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>

                            </Col>  
                                </> : <></>

                            ))
                        }

                        </>
                        
                        : <>Please Wait! Fetching...</>
                } */}
      </Row>
      {idName === "Funeral Expense and Death Assistance" ||
      allValues["Accident Resulted In"] === 27 ? null : (
        <>
          <Row className='scheme-subtitle-row'>
            <Col xs={12} className='searchform-subtitle'>
              <p>
                <Translate value='daysWorkCertificateDetails' />
              </p>
            </Col>
          </Row>
          <Row className='form-row ninydays-row'>
            <Col xs={12} className='nintydays-col-5'>
              <Row className='form-inner-card-plain'>
                <Col xs={12} md={6}>
                  <Required
                    className='mt-2 mb-2'
                    title={<Translate value='typeofIssuer' />}
                  />
                  <FormControl variant='outlined' fullWidth>
                    <Select
                      className='select-marital'
                      value={issuer}
                      displayEmpty
                      onChange={(ev) => setIssuer(ev.target.value)}
                    >
                      <MenuItem value=''>
                        <ListItemText primary='-Select-' />
                      </MenuItem>
                      {typeOfIssuer &&
                        typeOfIssuer.length > 0 &&
                        typeOfIssuer.map((i) => (
                          <MenuItem value={i.value_id}>
                            <ListItemText primary={i.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className='mt-2 mb-2'
                    title={<Translate value='issuedDate' />}
                  />
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <KeyboardDatePicker
                      minDate={moment().subtract(90, "days")}
                      maxDate={new Date()}
                      onClick={() => setPickerStatus2(true)}
                      onClose={() => setPickerStatus2(false)}
                      open={openPicker2}
                      className='datepicker'
                      margin='normal'
                      format='D/MM/yyyy'
                      value={issuedDate}
                      // onChange={(date) => handleDateChange(date) }
                      onChange={(date) => {
                        setIssuedDate(date);
                      }}
                      placeholder='DD/MM/YYYY'
                      // KeyboardButtonProps={{
                      //     'aria-label': 'change date',
                      // }}
                    />
                  </MuiPickersUtilsProvider>
                </Col>

                {showLIForm === true ? (
                  <Col xs={12} md={6} className='issuerTypeForm'>
                    <p className='mt-5 mb-2'>
                      <Translate value='labourInspectorName' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name='inspectorName'
                        onChange={handleChange}
                        value={inspectorName}
                        disabled
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='circleoftheLabourInspector' />{" "}
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name='inspectorCircle'
                        onChange={handleChange}
                        value={inspectorCircle}
                        disabled
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='mobileNumberoftheLabourInspector' />{" "}
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        name='inspectorPhone'
                        onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={mobile}
                                alt='...'
                                className='phone-icon'
                              />
                              <p className='countrycode'>+91</p>
                            </InputAdornment>
                          ),
                        }}
                        value={inspectorPhone}
                        disabled
                      />
                    </FormControl>
                  </Col>
                ) : showUnionForm === true ? (
                  <Col xs={12} md={6} className='issuerTypeForm'>
                    <p className='mt-5 mb-2'>
                      <Translate value='unionName' />
                    </p>

                    <FormControl fullWidth>
                      {/* <Select
                            className="select-marital"
                            value={handleLabourUnion}
                            name="issuerType"
                            displayEmpty
                            onChange={handleLabourChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="-Select-" />
                            </MenuItem>

                            {
                                users.unionDetails !== "Network Error" && users.unionDetails !== null && users.unionDetails.map(union => {
                                    return <MenuItem value={union.name}>
                                        <ListItemText primary={union.name} />
                                    </MenuItem>
                                })
                            }
                            
                        </Select> */}
                      <Select2
                        options={union}
                        className='select-marital'
                        onChange={handleDropdownSelect}
                        isSearchable={true}
                        value={selectedOption && selectedOption}
                        placeholder='Search Union'
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='unionRegistrationNumber' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        // name="unionRegistrationNumber"
                        // onChange={(ev) => console.log(ev.target.value,"UNIONVAL") }
                        value={
                          selectedOption &&
                          selectedOption.registeration_no !== null
                            ? selectedOption.registeration_no
                            : ""
                        }
                        // disabled
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='nameofIssuer' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        // name="unionIssuerName"
                        // onChange={handleChange}
                        value={
                          selectedOption && selectedOption.spoke_person !== null
                            ? selectedOption.spoke_person
                            : ""
                        }
                        // disabled
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='mobileNumberIfAvailable' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        // name="unionPhone"
                        // onChange={handleChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={mobile}
                                alt='...'
                                className='phone-icon'
                              />
                              <p className='countrycode'>+91</p>
                            </InputAdornment>
                          ),
                        }}
                        value={
                          selectedOption && selectedOption.phone_no !== null
                            ? selectedOption.phone_no
                            : ""
                        }
                        // disabled
                      />
                    </FormControl>
                  </Col>
                ) : showGramaForm ? (
                  <Col xs={12} md={6} className='issuerTypeForm'>
                    <p className='mt-4 mb-2'>
                      <Translate value='nameofIssuer' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder='Enter Issuer Name'
                        // name="issuerName"
                        // onChange={handleChange}
                        // onChange={(e) => { handleChange(e) }}
                        // value={allValues.issuerName}
                        // helperText={allValues.issuerNameError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerNameError}
                        onChange={(ev) => setIssuerNameGram(ev.target.value)}
                        value={issuerNameGram}
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='placeofIssuer' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder='Enter Place of Issuer'
                        name='issuerPlace'
                        // onChange={handleChange}
                        // value={allValues.issuerPlace}
                        // helperText={allValues.issuerPlaceError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerPlaceError}
                        onChange={(ev) => setIssuerPlaceGram(ev.target.value)}
                        value={issuerPlaceGram}
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='mobileNumberIfAvailable' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={mobile}
                                alt='...'
                                className='phone-icon'
                              />
                              <p className='countrycode'>+91</p>
                            </InputAdornment>
                          ),
                        }}
                        placeholder='Enter Mobile Number of Issuer'
                        name='issuerPhone'
                        // onChange={handleChange}
                        // value={allValues.issuerPhone}
                        // helperText={allValues.issuerPhoneError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerPhoneError}
                        onChange={(ev) => setIssuerPhoneGram(ev.target.value)}
                        value={issuerPhoneGram}
                      />
                    </FormControl>
                  </Col>
                ) : showEmployerForm ? (
                  <Col xs={12} md={6} className='issuerTypeForm'>
                    <p className='mt-4 mb-2'>
                      <Translate value='nameofIssuer' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder='Enter Name of Issuer'
                        name='issuerName'
                        // type = "text"
                        // onChange={handleChange}
                        // value={allValues.issuerName}
                        // helperText={allValues.issuerNameError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerNameError}
                        onChange={(ev) =>
                          setIssuerNameEmployer(ev.target.value)
                        }
                        value={issuerNameEmployer}
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='designationofIssuer' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        placeholder='Enter Designation of Issuer'
                        // name="issuerDesignation"
                        type='text'
                        // onChange={handleChange}
                        // value={allValues.issuerDesignation}
                        // helperText={allValues.issuerDesignationError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerDesignationError}
                        onChange={(ev) => setIssuerDesignation(ev.target.value)}
                        value={issuerDesignation}
                      />
                    </FormControl>

                    <p className='mt-4 mb-2'>
                      <Translate value='mobileNumberIfAvailable' />
                    </p>
                    <FormControl fullWidth>
                      <TextField
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              <img
                                src={mobile}
                                alt='...'
                                className='phone-icon'
                              />
                              <p className='countrycode'>+91</p>
                            </InputAdornment>
                          ),
                        }}
                        placeholder='Enter Mobile Number of Issuer'
                        name='issuerPhone'
                        // onChange={handleChange}
                        // value={allValues.issuerPhone}
                        // helperText={allValues.issuerPhoneError ? "Please enter in correct format" : ""}
                        // error = {allValues.issuerPhoneError}
                        onChange={(ev) =>
                          setIssuerPhoneEmployer(ev.target.value)
                        }
                        value={issuerPhoneEmployer}
                      />
                    </FormControl>
                  </Col>
                ) : null}
              </Row>
            </Col>
            <Col xs={12} className='nintydays-col-6'>
              {/* <p className="mt-3 mb-2">Upload Your 90 Days Working Certificate</p> */}
              <Required
                className='mt-3 mb-2'
                title={<Translate value='uploadYourDaysWorkingCertificate' />}
              />

              <div className='browsebutton-outerdiv'>
                <div className='browsebutton2-div filesNames'>
                  {loadingfiles ? (
                    <></>
                  ) : (
                    selectedFiles90Days !== null &&
                    selectedFiles90Days.length > 0 &&
                    Array.from(selectedFiles90Days).map((i, index) => (
                      <FormControl>
                        <TextField
                          variant='outlined'
                          name='90DaysDocument'
                          value={i.name}
                          onChange={handleChange}
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='start'>
                                <img
                                  alt='...'
                                  src={closeicon}
                                  className='removefilebtn'
                                  onClick={() => removefile90Days(index)}
                                />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    ))
                  )}
                </div>
                <div className='browsebutton2-div'>
                  <label
                    htmlFor='upload-photo-90days'
                    className='browse-button btn2'
                  >
                    <img alt='...' src={folder} /> <Translate value='browse' />
                  </label>
                  <input
                    type='file'
                    name='90-days-photo'
                    id='upload-photo-90days'
                    onChange={handleFileChange90Days}
                    multiple
                  />
                </div>
              </div>

              {/* <p className="mt-4 instructions-ration">
                                        <span><Translate value="instructions" />* : </span><br />
                                        <Translate value="formatsupportingdocumentshouldbeinJPEGPNGorPDF" /><br />
                                        <Translate value="sizecontains500KBto2MB" />
                                    </p> */}
            </Col>
          </Row>
        </>
      )}

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='documents' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        {/* <Row className="outerouter" > */}
        {schemeDetails &&
          schemeDetails.length &&
          schemeDetails.map((item) => (
            <>
              {item.document_name === "Affidavit" ||
              item.document_name === "Postmortem Report" ? (
                //  item.document_name === "Medical Disability Certificate"
                <>
                  {(allValues["Married Outside Karnataka"] !== undefined &&
                    allValues["Married Outside Karnataka"] === 177) ||
                  (allValues["Accident Resulted In"] === 27 &&
                    allValues.showDeathfields) ? (
                    <Col
                      xs={12}
                      md={6}
                      className='final-personal-col-1 px-3 mt-4 browsecol'
                    >
                      <Required className='mb-2' title={item.document_name} />
                      <div className='browsebutton-outerdiv'>
                        <div className='browsebutton2-div filesNames'>
                          {loadingfiles ? (
                            <></>
                          ) : (
                            selectedfiles !== null &&
                            selectedfiles[item.document_name] !== undefined &&
                            selectedfiles[item.document_name] !== null &&
                            selectedfiles[item.document_name].length > 0 &&
                            Array.from(selectedfiles[item.document_name]).map(
                              (i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant='outlined'
                                    name='passbookDocumentName'
                                    value={i.name}
                                    onChange={handleChange}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position='start'>
                                          <img
                                            alt='...'
                                            src={closeicon}
                                            className='removefilebtn'
                                            onClick={() =>
                                              removefile(
                                                ind,
                                                item.document_name
                                              )
                                            }
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              )
                            )
                          )}
                        </div>
                        <div className='browsebutton2-div'>
                          <label
                            htmlFor={`upload-photo${item.id}`}
                            className='browse-button btn2 schemes'
                          >
                            <img alt='...' src={folder} />
                            <Translate value='browse' />
                          </label>
                          {/* <input type="file" name="photo" id={`upload-photo${item.id}`}
                                                onChange={(e) => handleFileChange(e, item.document_name)}
                                            /> */}
                          <input
                            type='file'
                            name='photo'
                            id={`upload-photo${item.id}`}
                            onChange={(e) =>
                              handleFileChange(e, item.document_name)
                            }
                            multiple
                          />
                        </div>
                      </div>
                    </Col>
                  ) : null}
                </>
              ) : (
                <Col
                  xs={12}
                  md={6}
                  className='final-personal-col-1 px-3 mt-4 browsecol'
                >
                  <Required
                    className='mb-2'
                    title={
                      allValues.descriptionsKannada
                        ? item.document_name_kannada
                        : item.document_name
                    }
                  />
                  <div className='browsebutton-outerdiv'>
                    <div className='browsebutton2-div filesNames'>
                      {loadingfiles ? (
                        <></>
                      ) : (
                        selectedfiles !== null &&
                        selectedfiles[item.document_name] !== undefined &&
                        selectedfiles[item.document_name] !== null &&
                        selectedfiles[item.document_name].length > 0 &&
                        Array.from(selectedfiles[item.document_name]).map(
                          (i, ind) => (
                            <FormControl>
                              <TextField
                                variant='outlined'
                                name='passbookDocumentName'
                                value={i.name}
                                onChange={handleChange}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position='start'>
                                      <img
                                        alt='...'
                                        src={closeicon}
                                        className='removefilebtn'
                                        onClick={() =>
                                          removefile(ind, item.document_name)
                                        }
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          )
                        )
                      )}
                    </div>
                    <div className='browsebutton2-div'>
                      <label
                        htmlFor={`upload-photo${item.id}`}
                        className='browse-button btn2 schemes'
                      >
                        <img alt='...' src={folder} />
                        <Translate value='browse' />
                      </label>
                      {/* <input type="file" name="photo" id={`upload-photo${item.id}`}
                                                onChange={(e) => handleFileChange(e, item.document_name)}
                                            /> */}
                      <input
                        type='file'
                        name='photo'
                        id={`upload-photo${item.id}`}
                        onChange={(e) =>
                          handleFileChange(e, item.document_name)
                        }
                        multiple
                      />
                    </div>
                  </div>
                </Col>
              )}
            </>
          ))}

        <Col xs={12} className='final-personal-col-1 px-3 mt-4'>
          <p className='note-scheme'>
            ಸೂಚನೆ / Note:
            <Translate value='documentshouldbeinJPEGPNGandPDFformatonlyandlessthan50KB' />
          </p>
        </Col>
        {/* </Row> */}
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          {/* <p>Declaration</p> */}
          <p>
            <Translate value='declaration' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} className='note2 schemes mt-4 bank-col-5'>
          <FormControlLabel
            control={
              <Checkbox
                checked={allValues.declaration}
                onChange={handleChangeCheckbox}
                name='declaration'
                // color="primary"
              />
            }
            label={
              <Translate value='theFactsMentionedaboveisBestofMyKnowledgeandInformation' />
            }
          />
        </Col>
        {idName === "Funeral Expense and Death Assistance" ||
        allValues["Accident Resulted In"] === 27 ? null : (
          <>
            <p className='mb-2 mt-3 text-left' style={{ fontSize: "20px" }}>
              <Translate value='selfdeclaration' />
            </p>
            <div className='browsebutton-outerdiv d-flex justify-content-start align-items-center'>
              <div className='browsebutton2-div filesNames w-50 d-flex justify-content-center align-items-center'>
                {loadingFiles ? (
                  <></>
                ) : (
                  selectedSelfDeclarationFiles !== null &&
                  selectedSelfDeclarationFiles.length > 0 &&
                  Array.from(selectedSelfDeclarationFiles).map((i, ind) => (
                    <FormControl>
                      <TextField
                        variant='outlined'
                        name='qualificationDoc'
                        value={i.name}
                        // onChange={handleChange}
                        disabled
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position='start'>
                              <img
                                alt='...'
                                src={closeicon}
                                className='removefilebtn'
                                onClick={() => removeFileSelfDeclaration(ind)}
                              />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </FormControl>
                  ))
                )}
              </div>
              <div className='browsebutton2-div'>
                <label
                  htmlFor={`upload-photo2`}
                  className='browse-button btn2 schemes'
                >
                  <img alt='...' src={folder} />
                  <Translate value='browse' />
                </label>
                {/* <input type="file" name="photo" id={`upload-photo${item.id}`}
                                                onChange={(e) => handleFileChange(e, item.document_name)}
                                            /> */}
                <input
                  type='file'
                  name='photo'
                  id={`upload-photo2`}
                  onChange={(e) => handleSelfDeclarationUpload(e)}
                  multiple
                />
              </div>
            </div>
          </>
        )}
      </Row>

      <Row className='search-subtitle-row pb-5'>
        {/* <Col xs={12} className="searchform-subtitle">
                    <div className="submit-button-div centerdiv">
                        <Link to="#"
                            // onClick={nextButtonPressed}
                            // style={{ flexGrow: "1" }}
                        >
                            <Button variant="outline-primary" className="next-button">
                                Submit
                            </Button>
                        </Link>
                    </div>
                </Col> */}

        <Col xs={12} className='next-back-button-row mt-4'>
          <Link
            to='#'
            onClick={() => props.history.push("/schemes-home")}
            style={{ flexGrow: "0.5" }}
          >
            <Button variant='outline-primary' className='back-button'>
              <Translate value='cancel' />
            </Button>
          </Link>

          <Link
            to='#'
            onClick={submitButtonPressed}
            style={{ flexGrow: "0.5" }}
          >
            <Button variant='outline-primary' className='save-button'>
              <Translate value='submit' />
            </Button>
          </Link>
        </Col>
      </Row>

      {/* ********************   Acknowledgement Letter HTML ************************ */}
      <div>
        {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
        <div
          id='divToPrint'
          className='mt-4'
          style={{
            backgroundColor: "#fff",
            width: "210mm",
            minHeight: "297mm",
            marginLeft: "auto",
            marginRight: "auto",
            textAlign: "center",
            // justifyContent: 'center',
            display: "none",
            // border: '2px solid black',
          }}
        >
          <div className='logo-div-ack'>
            <a href='/dashboard-user'>
              <img id='logo' src={logo} alt='...' className='logo-img' />
            </a>{" "}
            <h1 className='logo-text'>
              <Translate value='header.title' /> <br />
              <Translate value='header.sub' />
            </h1>
            <p className='copyright'>Government of Karnataka</p>
          </div>

          <Row className='ack-subtitle-row'>
            <Col xs={12} className='profile-title' style={{ display: "block" }}>
              <h1>ಸ್ವೀಕೃತಿ /Acknowledgement</h1>
            </Col>
          </Row>

          <p className='ack-success'>
            <img
              src={checkGreen}
              style={{ height: "auto", width: "28px", marginRight: "10px" }}
            />
            {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
            ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿ: ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರಿಗೆ ಯಶಸ್ವಿಯಾಗಿ ಸಲ್ಲಿಸಲಾಗಿದೆ /
            Application Status : Successfully submitted to Labour Inspector
          </p>

          <Row className='ack-table-row mt-5'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಅರ್ಜಿ ಸಂಖ್ಯೆ / Application Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{appNo}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಅರ್ಜಿ ದಿನಾಂಕ / Application Date</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>
                <Moment format='DD MMMM YYYY'>{date}</Moment>
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ವಿನಂತಿಸಿದ ಸೇವೆ /Requested Service</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{idName}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಅರ್ಜಿದಾರರ ಹೆಸರು / Applicant's Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{applicantName}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಮನೆಯ ವಿಳಾಸ / Residential Address</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{presentAddress}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಹಳ್ಳಿ ವಾರ್ಡ್ / Village Ward</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{villageWard}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಮೊಬೈಲ್ ಸಂಖ್ಯೆ / Mobile Number</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{mobile}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಉದ್ಯೋಗ (ವರ್ಗ) / Occupation (Category)</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{occupation}</p>
            </Col>
          </Row>

          {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ವೃತ್ತದ ಹೆಸರು / Circle Name</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>{circleName}</p>
            </Col>
          </Row>

          <Row className='ack-table-row'>
            <Col xs={2}></Col>
            <Col xs={3} className='ack-col'>
              <p>ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಉಸ್ತುವಾರಿ / Labour Inspector Incharge</p>
            </Col>

            <Col xs={1}>
              <p>:</p>
            </Col>

            <Col xs={6} className='ack-col'>
              <p>
                {officerIncharge} {officerIncharge1} {officerIncharge2}
              </p>
            </Col>
          </Row>

          <Row className='ack-table-row-last mt-5'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col-note'>
              <p>ಸೂಚನೆ / NOTE:</p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>
                1. ಸಲ್ಲಿಸಿದ ಅರ್ಜಿ ಪರಿಶೀಲನೆಗೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ / Submitted
                application is subject to verification.
              </p>
            </Col>
          </Row>
          <Row className='ack-table-row-last'>
            <Col xs={2}></Col>
            <Col xs={10} className='ack-col'>
              <p>
                2. ಸ್ಥಿತಿಯನ್ನು ತಿಳಿಯಲು ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಲಾಗಿನ್ ಮಾಡಿ / Login to
                application to know the status.
              </p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default SchemeForm;
