import {
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React from 'react';

const TableDriveComponent = ({ fetchDistrcitReports }) => {
  return (
    <>
      <TableContainer style={{ width: '100%' }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">District</TableCell>
              <TableCell align="center">Pending</TableCell>
              <TableCell align="center">Approved</TableCell>
              <TableCell align="center">Rejected</TableCell>
              <TableCell align="center">Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {fetchDistrcitReports &&
              fetchDistrcitReports.length &&
              fetchDistrcitReports.map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">{row.district}</TableCell>
                  <TableCell align="center">{row.Pending}</TableCell>
                  <TableCell align="center">{row.Approved}</TableCell>
                  <TableCell align="center">{row.Rejected}</TableCell>
                  <TableCell align="center">{row.total}</TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default TableDriveComponent;
