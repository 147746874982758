import React, { useEffect, useRef, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { getTokenCookie, showToast } from 'utils/tools';

import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';

import EmployerDetails from './EmployerDetails';
import { employerHandler, issuerHandler } from './formhandler';
import IssuerDetails from './IssuerDetails';
import {
  Link,
  useHistory,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import {
  EmployerDetailsAPI,
  NatureOfWorkAPI,
  TypeOfIssuerAPI,
} from './renewalDependent.service';
import {
  cityAPI,
  districtAPI,
  talukAPI,
  villageAPI,
} from './addressCascadindDropdown.service';
import RenewalDocuments from './RenewalDocuments';
import { Button } from '@mui/material';
import { hasEmptyPropertiesEmployerDetails } from 'utils/checkEmptyArray';
import { CheckCircle } from '@material-ui/icons';

import uploadFileAPI from 'utils/fileupload';
import { renewalAPIService } from './renewal.service';
import moment from 'moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import { Backdrop, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#ffffffee',
    color: '#000',
    display: 'block',
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  backdropCheck: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#6CC17A',
  },
  backdropCheckp: {
    fontSize: '30px',
    fontWeight: 'bold',
  },
}));

const Renewals = () => {
  const classes = useStyles();
  const { labourUserID } = useParams();
  const history = useHistory();
  const [employerDetails, setEmployerDetails] = useState({
    employerStatus: 25,
    employerName: null,
    workplaceSiteAddress: null,
    companyName: null,
    mobileNumber: null,
    state: null,
    district: null,
    taluk: null,
    city: null,
    village: null,
    pinCode: null,
    natureOfWork: null,
  });

  const [issuerDetails, setIssuerDetails] = useState({
    issuerTypeID: null,
    issueDate: null,
    inspectorID: null,
    inspectorName: null,
    circleName: null,
    circleID: null,
    labourInspectormobileNum: null,
    gramIssuerName: null,
    gramIssuerMobileNumber: null,
    gramIssuerPlace: null,
    constructionIssuerName: null,
    constructionMobileNumber: null,
    constructionDesignation: null,
    unionID: null,
    unionAddress: null,
    unionIssuerName: null,
    unionMobileNumber: null,
    unionRegistrationNumber: null,
  });

  const [fetchEmployerDetails, setFetchEmployerDetails] = useState(null);
  const [fetchNatureOfWork, setFetchNatureOfWork] = useState(null);
  const [fetchTypeOfIssuer, setFetchTypeOfIssuer] = useState(null);

  const [fetchDistrict, setFetchDistrict] = useState(null);
  const [fetchTaluk, setFetchTaluk] = useState(null);
  const [fetchCity, setFetchCity] = useState(null);
  const [fetchVillage, setFetchVillage] = useState(null);

  const [villageID, setVillageID] = useState(null);

  const [selectedOption, setSelectedOption] = useState(null);
  const [union, setUnion] = useState(null);

  const [nintyDaysDoc, setNintyDaysDoc] = useState([]);
  const nintyDaysDocRef = useRef(null);

  const [selfDeclarationDoc, setSelfDeclarationDoc] = useState([]);
  const selfDeclarationDocRef = useRef(null);

  const [paySlipDoc, setPaySlipDoc] = useState([]);
  const paySlipDocRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [applicationDate, setApplicationDate] = useState(null);

  const callEmployerDetails = () => {
    EmployerDetailsAPI(setFetchEmployerDetails);
  };

  const callNatureOfWork = () => {
    NatureOfWorkAPI(setFetchNatureOfWork);
  };

  const callTypeOfIssuer = () => {
    TypeOfIssuerAPI(setFetchTypeOfIssuer);
  };

  const callDistrict = () => {
    districtAPI(setFetchDistrict);
  };

  const callTaluk = () => {
    talukAPI(employerDetails.district, setFetchTaluk);
  };

  const callCity = () => {
    cityAPI(employerDetails.taluk, setFetchCity);
  };

  const callVillage = () => {
    villageAPI(employerDetails.city, setFetchVillage);
  };

  useEffect(callEmployerDetails, []);
  useEffect(callNatureOfWork, []);
  useEffect(callTypeOfIssuer, []);
  useEffect(callDistrict, []);
  useEffect(callTaluk, [employerDetails.district]);
  useEffect(callCity, [employerDetails.taluk]);
  useEffect(callVillage, [employerDetails.city]);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'address_details',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        setVillageID(data.data[0].village_area_id);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  useEffect(() => {
    if (villageID) {
      axios
        .get(
          `${SERVER}/global/get_inspector_details?board_id=1&village_area_id=${villageID}`,
        )
        .then(({ data }) => {
          if (data.data.length > 0) {
            setIssuerDetails((prev) => ({
              ...prev,
              inspectorName: `${data.data[0].first_name} ${
                data.data[0].middle_name ?? ''
              } ${data.data[0].last_name ?? ''}`,
              circleName: data.data[0].circle,
              labourInspectormobileNum: data.data[0].mobile_no,
              inspectorID: data.data[0].department_user_id,
              circleID: data.data[0].circle_id,
            }));
          } else {
            showToast(
              'ERROR',
              'Problem in fetching the details of the inspector. Please contact your respective jurisdiction labour office.',
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [villageID]);

  useEffect(() => {
    if (issuerDetails.issuerTypeID === 56) {
      axios
        .get(`${SERVER}/global/get_union_details?board_id=1`)
        .then((res) => {
          const newData = res.data.data.map((item) => ({
            ...item,
            value: item.labour_union_id,
            label: item.name,
          }));
          setUnion(newData);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [issuerDetails.issuerTypeID]);

  const handleFormChange = (ev) => {
    employerHandler(ev, setEmployerDetails);
  };

  const handleIssuerChange = (ev) => {
    issuerHandler(ev, setIssuerDetails);
  };

  const handleIssuerDate = (ev) => {
    setIssuerDetails((prev) => ({
      ...prev,
      issueDate: ev,
    }));
  };

  const handleDropdownSelect = (selectedOption) => {
    setSelectedOption(selectedOption);
    setIssuerDetails((prev) => ({
      ...prev,
      unionID: selectedOption.labour_union_id,
      unionAddress: selectedOption.address,
      unionRegistrationNumber: selectedOption.registeration_no,
      unionIssuerName: selectedOption.spoke_person,
      unionMobileNumber: selectedOption.phone_no,
    }));
  };

  const handleNintyDaysFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...nintyDaysDoc, ...ev.target.files];
        setNintyDaysDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handlePaySlipsFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...paySlipDoc, ...ev.target.files];
        setPaySlipDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleSelfDeclarationFile = (ev) => {
    if (
      ev.target.files[0].type === 'image/png' ||
      ev.target.files[0].type === 'image/jpeg' ||
      ev.target.files[0].type === 'image/jpg' ||
      ev.target.files[0].type === 'application/pdf'
    ) {
      if (ev.target.files[0].size < 2000001) {
        const newFiles = [...selfDeclarationDoc, ...ev.target.files];
        setSelfDeclarationDoc(newFiles);
      } else {
        showToast('ERROR', 'Please upload file size less than 2MB');
      }
    } else {
      showToast('ERROR', 'Please upload file in the PNG or JPEG/JPG format');
    }
  };

  const handleRemoveNintyDaysFile = (index) => {
    const newFiles = [...nintyDaysDoc];
    newFiles.splice(index, 1);
    setNintyDaysDoc(newFiles);
    nintyDaysDocRef.current.value = '';
  };
  const handleRemovePaySlipsFile = (index) => {
    const newFiles = [...paySlipDoc];
    newFiles.splice(index, 1);
    setPaySlipDoc(newFiles);
    paySlipDocRef.current.value = '';
  };
  const handleRemoveSelfDeclarationFiles = (index) => {
    const newFiles = [...selfDeclarationDoc];
    newFiles.splice(index, 1);
    setSelfDeclarationDoc(newFiles);
    selfDeclarationDocRef.current.value = '';
  };

  const renewalDocumentsUpload = (
    nintyDaysDoc,
    selfDeclarationDoc,
    paySlipDoc,
  ) => {
    if (nintyDaysDoc && selfDeclarationDoc && paySlipDoc) {
      const promises1 = [];
      nintyDaysDoc.forEach((doc) => {
        promises1.push(uploadFileAPI(doc));
      });
      const promises2 = [];
      selfDeclarationDoc.forEach((doc) => {
        promises2.push(uploadFileAPI(doc));
      });

      const promises3 = [];
      paySlipDoc.forEach((doc) => {
        promises3.push(uploadFileAPI(doc));
      });

      const allPromises = [...promises1, ...promises2, ...promises3];

      Promise.all(allPromises)
        .then((responses) => {
          const responses1 = responses.slice(0, promises1.length);
          const responses2 = responses.slice(
            promises1.length,
            promises1.length + promises2.length,
          );
          const responses3 = responses.slice(
            promises1.length + promises2.length,
          );

          const nintyDaysResponse = responses1.map((doc) => doc.image);
          const selfDeclarationResponse = responses2.map((doc) => doc.image);
          const paySlipResponse = responses3.map((doc) => doc.image);
          // Continue processing or handle the responses as needed
          insertRenewals(
            nintyDaysResponse,
            selfDeclarationResponse,
            paySlipResponse,
          );
        })
        .catch((error) => {
          // Handle errors from any promise in allPromises
          console.error('Error:', error);
        });
    } else if (nintyDaysDoc && selfDeclarationDoc) {
      const promises1 = [];
      nintyDaysDoc.forEach((doc) => {
        promises1.push(uploadFileAPI(doc));
      });
      const promises2 = [];
      selfDeclarationDoc.forEach((doc) => {
        promises2.push(uploadFileAPI(doc));
      });

      const allPromises = [...promises1, ...promises2];

      Promise.all(allPromises)
        .then((responses) => {
          // Handle responses from all promises

          // Split the responses for promises1 and promises2 if needed
          const responses1 = responses.slice(0, promises1.length);
          const responses2 = responses.slice(promises1.length);

          const nintyDaysResponse = responses1.map((doc) => doc.image);
          const selfDeclarationResponse = responses2.map((doc) => doc.image);

          // Continue processing or handle the responses as needed
          insertRenewals(nintyDaysResponse, selfDeclarationResponse);
        })
        .catch((error) => {
          // Handle errors from any promise in allPromises
          console.error('Error:', error);
        });
    }
  };

  const insertRenewals = async (
    nintyDaysFile,
    selfDeclarationFile,
    paySlipFile,
  ) => {
    const employmentCertificates = nintyDaysFile.map((file) => ({
      file_id: file.fileId,
      file_name: file.originalFileName,
    }));
    const payslips = paySlipFile.map((file) => ({
      file_id: file.fileId,
      file_name: file.originalFileName,
    }));
    const selfDeclaration = selfDeclarationFile.map((file) => ({
      file_id: file.fileId,
      file_name: file.originalFileName,
    }));
    // const data = {
    //   employerDetails,
    //   issuerDetails,
    //   nintyDaysFile,
    //   selfDeclarationFile,
    //   paySlipFile,
    // };
    const employers = {
      employerType: employerDetails.employerStatus,
      companyName: employerDetails.companyName,
      contractorName: employerDetails.employerName,
      mobileCountryCode: '+91',
      mobileNo: employerDetails.mobileNumber,
      siteAddress: employerDetails.workplaceSiteAddress,
      natureOfWork: employerDetails.natureOfWork,
      stateID: employerDetails.state,
      districtID: employerDetails.district,
      talukID: employerDetails.taluk,
      gramPanchayatID: employerDetails.city,
      villageAreaID: employerDetails.village,
      pinCode: +employerDetails.pinCode,
    };

    const payLoad = {
      labourUserID: +labourUserID,
      boardID: 1,
      typeOfIssuerId: issuerDetails.issuerTypeID,
      issueDate: moment(issuerDetails.issueDate).format('YYYY-MM-DD'),

      inspectorID: issuerDetails.inspectorID,
      unionID: issuerDetails.issuerTypeID === 56 ? issuerDetails.unionID : null,
      issuerName:
        issuerDetails.issuerTypeID === 56
          ? issuerDetails.unionIssuerName
          : issuerDetails.issuerTypeID === 57
            ? issuerDetails.gramIssuerName
            : issuerDetails.issuerTypeID === 58
              ? issuerDetails.constructionIssuerName
              : null,
      issuerPlace:
        issuerDetails.issuerTypeID === 56
          ? issuerDetails.unionAddress
          : issuerDetails.issuerTypeID === 57
            ? issuerDetails.gramIssuerPlace
            : null,
      issuerDesignation: issuerDetails.constructionDesignation ?? null,
      mobileCountryCode: '+91',
      mobileNo:
        issuerDetails.issuerTypeID === 56
          ? issuerDetails.unionMobileNumber
          : issuerDetails.issuerTypeID === 57
            ? issuerDetails.gramIssuerMobileNumber
            : issuerDetails.issuerTypeID === 58
              ? issuerDetails.constructionMobileNumber
              : null,

      circleID: issuerDetails.circleID,
      employers,
      employmentCertificates,
      payslips,
      selfDeclaration,
    };
    console.log('RenewalPayload', payLoad);

    try {
      const response = await renewalAPIService(payLoad);
      if (response.success) {
        const { data } = response;
        setApplicationDate(data.application_date);
        setLoading(false);
        setOpenBackdrop(true);
        setSubmitSuccess(true);
        showToast('SUCCESS', 'Renewal Applied Successfully');
      }
    } catch (error) {
      if (error.response?.data?.errors?.msg) {
        setLoading(false);
        showToast('ERROR', `${error.response?.data?.errors?.msg}`);
      } else {
        setLoading(false);
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  const handleSubmit = () => {
    const employerDetailsCheck =
      !hasEmptyPropertiesEmployerDetails(employerDetails);

    if (employerDetailsCheck) {
      if (issuerDetails.issuerTypeID && issuerDetails.issueDate) {
        if (issuerDetails.issuerTypeID === 56) {
          if (!issuerDetails.unionIssuerName) {
            showToast('ERROR', 'Please enter issuer name');
            return;
          }
        } else if (issuerDetails.issuerTypeID === 57) {
          if (!issuerDetails.gramIssuerName) {
            showToast('ERROR', 'Please enter issuer name');
            return;
          }
          if (!issuerDetails.gramIssuerPlace) {
            showToast('ERROR', 'Please enter the place of the issuer');
            return;
          }
        } else if (issuerDetails.issuerTypeID === 58) {
          if (!issuerDetails.constructionIssuerName) {
            showToast('ERROR', 'Please enter issuer name');
            return;
          }
          if (!issuerDetails.constructionDesignation) {
            showToast('ERROR', 'Please enter the issuer designation');
            return;
          }
        }
        if (
          nintyDaysDoc &&
          nintyDaysDoc.length > 0 &&
          selfDeclarationDoc &&
          selfDeclarationDoc.length > 0
        ) {
          setLoading(true);

          renewalDocumentsUpload(
            nintyDaysDoc,
            selfDeclarationDoc,
            paySlipDoc && paySlipDoc.length > 0 ? paySlipDoc : [],
          );
        } else {
          showToast('ERROR', 'Please fill mandatory documents');
        }
      } else {
        showToast('ERROR', 'Please fill mandatory issuer details');
      }
    } else {
      showToast('ERROR', 'Please fill mandatory employer details');
    }
  };

  const handleBack = () => {
    history.push('/dashboard-user');
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    history.push('/dashboard-user');
  };

  return (
    <>
      {loading && <SimpleBackdrop />}
      {submitSuccess && (
        <Backdrop
          className={classes.backdrop}
          open={openBackdrop}
          onClick={handleCloseBackdrop}
        >
          <div style={{ display: 'block' }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: 'auto', width: '200px' }}
            />
            <p className="final-success-title">
              Successfully submitted for
              <br />
              Renewal
            </p>
            <Link
              to={`/view/renewalack/${labourUserID}?applicationDate=${applicationDate}`}
              style={{ flexGrow: '0.5' }}
              target="_blank"
            >
              <p className="final-success-link-p">
                View Acknowledgement Letter{' '}
                {/* <span variant="outline-primary" className="final-success-link">
                  {' '}
                  Click Here
                </span> */}
              </p>
            </Link>
          </div>
        </Backdrop>
      )}
      <Row className="top-div">
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={8} className="top-bar-col">
            <div className="logo-div-profile">
              <a href="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </a>
              <h1 className="logo-text">
                Karnataka Building & Other Construction Workers Welfare Board
                (KBOCWWB)
                <br />
                Government of Karnataka
              </h1>
            </div>
          </Col>
        </Row>
      </Row>
      <EmployerDetails
        fetchEmployerDetails={fetchEmployerDetails}
        fetchNatureOfWork={fetchNatureOfWork}
        fetchTypeOfIssuer={fetchTypeOfIssuer}
        fetchDistrict={fetchDistrict}
        fetchTaluk={fetchTaluk}
        fetchCity={fetchCity}
        fetchVillage={fetchVillage}
        handleFormChange={handleFormChange}
        employerDetails={employerDetails}
      />
      <IssuerDetails
        issuerDetails={issuerDetails}
        fetchTypeOfIssuer={fetchTypeOfIssuer}
        handleIssuerChange={handleIssuerChange}
        handleDropdownSelect={handleDropdownSelect}
        selectedOption={selectedOption}
        handleIssuerDate={handleIssuerDate}
        union={union}
      />
      <RenewalDocuments
        nintyDaysDoc={nintyDaysDoc}
        paySlipDoc={paySlipDoc}
        selfDeclarationDoc={selfDeclarationDoc}
        nintyDaysDocRef={nintyDaysDocRef}
        paySlipDocRef={paySlipDocRef}
        selfDeclarationDocRef={selfDeclarationDocRef}
        handleNintyDaysFile={handleNintyDaysFile}
        handlePaySlipsFile={handlePaySlipsFile}
        handleSelfDeclarationFile={handleSelfDeclarationFile}
        handleRemoveNintyDaysFile={handleRemoveNintyDaysFile}
        handleRemovePaySlipsFile={handleRemovePaySlipsFile}
        handleRemoveSelfDeclarationFiles={handleRemoveSelfDeclarationFiles}
      />
      <Row className="button-inside-form-row mb-5">
        <Col xs={12} className="next-back-button-row mt-4 ">
          <Button
            variant="contained"
            color="error"
            style={{ width: '15%' }}
            className="py-3 mx-1"
            onClick={handleBack}
          >
            Back
          </Button>
          <Button
            variant="contained"
            style={{ width: '15%', whiteSpace: 'nowrap' }}
            color="success"
            className="py-3 mx-1"
            type="button"
            onClick={handleSubmit}
            disabled={loading}
          >
            Submit
          </Button>
        </Col>
      </Row>
    </>
  );
};

export default Renewals;
