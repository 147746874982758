import 'date-fns';
import { Select, MenuItem, ListItemIcon, ListItemText, withStyles, Menu, Dialog, Typography, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import incomplete from 'assets/images/2931158-200-01 copy.svg'
import pendingicon from 'assets/images/2931158-200-01.svg'
import usericon from 'assets/images/Icon awesome-user.svg'
import checkwhite from 'assets/images/Subtraction 1.svg'
import Registration from 'assets/images/Registration svg-01.svg'
import Schemes from '../../assets/images/scheme-svgrepo-com.svg'
import language from 'assets/images/translate (1).svg'
// import SchemeStatus from 'assets/images/scheme-svgrepo-com (1).svg'
import rejected from 'assets/images/close (2).svg'
import { useDispatch, useSelector } from 'react-redux';
import { getUser, getUserRegistrationDetails,registerUser, SERVER, setLocaleWithFallback } from 'store/actions/user.actions'
import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import Badge from '@mui/material/Badge';
import NotificationIcon from 'assets/images/NotificationIcon.svg'
import { Link } from 'react-router-dom';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { removeTokenCookieAdmin, getTokenCookie, saveTokenCookie, showToast } from 'utils/tools';

import axios from 'axios';
import moment from 'moment';
import notification from 'assets/images/Icon material-notifications-active.svg'
import { Translate } from 'react-redux-i18n';
const Dashboard = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [renewaldate, setrenewaldate] = React.useState("");

    const [currentPage, setCurrentPage] = React.useState(1);
    const [postsPerPage, setPostPerPage] = React.useState(4);
    const [pageNumber, setPageNumber] = React.useState(0);

    const [notification, setNotification] = React.useState([]);
    const [showAppStatus, setshowAppStatus] = React.useState(true)
    const [get_user_appeal, setget_user_appeal] = React.useState("")
    const [open, setOpen] = React.useState(false);
    const [opennotification, setopennotification] = React.useState(false);
    const [status, setStatus] = React.useState("")
    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
    })

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,

            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
          width: '350px'
        },
      })((props) => (
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...props}
        />
      ));
      

    useEffect(() => {
        console.log("allValues: "+ JSON.stringify(allValues,undefined,2))
    },[allValues])

    useEffect(() => {
        console.log("status: "+ JSON.stringify(status,undefined,2))
    },[status])


    useEffect(() => {
        console.log("useffect get_user_appeal: "+ JSON.stringify(get_user_appeal,undefined,2))

        if(get_user_appeal === undefined && 
            users.getUserRegistrationDetails !== undefined && 
            users.getUserRegistrationDetails !== null && 
            users.getUserRegistrationDetails.personal_details !== undefined && 
            users.getUserRegistrationDetails.personal_details.length > 0 &&
            users.getUserRegistrationDetails.personal_details[0].is_approved){
            setStatus("approved")
        }
        else if(
            get_user_appeal === "" && 
            users.getUserRegistrationDetails !== undefined && 
            users.getUserRegistrationDetails !== null && 
            users.getUserRegistrationDetails.personal_details !== undefined && 
            users.getUserRegistrationDetails.personal_details.length > 0 &&
            users.getUserRegistrationDetails.personal_details[0].is_approved){
            setStatus("approved")
        }
        else if(get_user_appeal === undefined && 
            users.getUserRegistrationDetails !== null &&
            users.getUserRegistrationDetails.personal_details !== undefined &&
            users.getUserRegistrationDetails.personal_details !== null &&
            users.getUserRegistrationDetails.personal_details.length > 0 &&
            users.getUserRegistrationDetails.personal_details[0].is_appealed !== null
            ){
            setStatus("appealed")
        }

    },[get_user_appeal])

    useEffect(() => {
        if (getTokenCookie() === null || getTokenCookie() === undefined || getTokenCookie() === "") {
            props.history.push("/")
        }
        else if (users.generate_otp !== null){

            console.log("in dashboardLabour>generate_otp > getTokenCookie():" + JSON.stringify(getTokenCookie()))
            if(getTokenCookie() !== undefined){
                dispatch(getUser(users.generate_otp.data.phone_number))
            }
        }
        else if (users.generate_otp_registration !== null){

            console.log("in dashboardLabour>generate_otp_registration > getTokenCookie():" + JSON.stringify(getTokenCookie()))
            if(getTokenCookie() !== undefined){
                dispatch(getUser(users.generate_otp_registration.data.phone_number))
            }
        }
        // dispatch(getUserRegistrationDetails("delete"))
        setStatus("")
    }, []);

    useEffect(() => {
        if (users.user !== null){

            if (users.user.id !== undefined && users.user.id !== null) {

                let dataForRegDetails = { 
                    "key": "user_id",
                    "value": users.user.id,
                    "board_id": 1,
                    "procedure_name": "all"
                }
                dispatch(getUserRegistrationDetails(dataForRegDetails))
    
                

                var config = {
                    method: 'get',
                    url: SERVER+'/appeal/get_user_appeal?board_id=1&user_id='+users.user.id,
                    headers: { 
                        'Content-Type': 'application/json',
                        'Authorization':`Bearer ${getTokenCookie()}`
                    },
                  };
                  
                  axios(config)
                  .then(function (response) {
                    console.log("get_user_appeal response: "+JSON.stringify(response.data.data[0], undefined,2))
                    // console.log("get_user_appeal.length response: "+response.data.data[0].length)

                    setget_user_appeal(response.data.data[0])
                  })
                  .catch(function (error) {
                    console.error("get_user_appeal error: "+error);
                  });
                  
            }


            if(users.user.is_sent_approval){
                if(users.user.is_approved){
                    setStatus("approved")
                }
                else if(users.user.is_rejected !== undefined && users.user.is_rejected){
                    setStatus("rejected")
                }
                else if(users.user.is_rejected === undefined && users.user.is_rejected === null){
                    setStatus("pending")
                }
            }
            else{
                setStatus("incomplete")
            }
        }
        
    }, [users.user]);
    
    useEffect(() => {
        console.log("in_useffect_users.getUserRegistrationDetails")
        console.error("in_useffect_users.getUserRegistrationDetails: "+ JSON.stringify(users.getUserRegistrationDetails, undefined, 2))
        if (
            users.getUserRegistrationDetails !== undefined && 
            users.getUserRegistrationDetails !== null && 
            users.getUserRegistrationDetails.personal_details !== undefined && 
            users.getUserRegistrationDetails.personal_details.length > 0 
            // &&
            // get_user_appeal !== undefined
            ){
                console.error("users.getUserRegistrationDetails.personal_details[0]: "+ JSON.stringify(users.getUserRegistrationDetails.personal_details[0], undefined, 2))
            if(users.getUserRegistrationDetails.personal_details[0].is_sent_approval){
                if(users.getUserRegistrationDetails.personal_details[0].is_approved){
                    // setStatus("Your Registration is Approved Successfully")
                    setStatus("approved")
                }
                else if(users.getUserRegistrationDetails.personal_details[0].is_rejected){
                    // setStatus("Your Registration is Pending for Approval")
                    setStatus("rejected")
                }
                else if(users.getUserRegistrationDetails.personal_details[0].is_rejected === null){
                    // setStatus("Your Registration is Pending for Approval")
                    setStatus("pending")
                }
            }
            else{
                // setStatus("Please Complete Your Registration")
                setStatus("incomplete")
            }
        }
        
    }, [users.getUserRegistrationDetails]);


    const SchemesClicked = () => {

        if(users.user !== null ){
            props.history.push("/schemes-unorganized");
        }
        else{
            showToast("ERROR", "Your Application is Not Yet Approved!")
        }
        
    }
    const EcardClicked = () => {

        if(users.user !== null && users.user.is_approved){
            props.history.push("/e-card");
        }
        else{
            showToast("ERROR", "Your Application is Not Yet Approved!")
        }
        
    }
    const RenewalClicked = () => {
        console.log("RenewalClicked")
        if(
            users.getUserRegistrationDetails !== undefined &&
            users.getUserRegistrationDetails.application_details !== undefined &&
            users.getUserRegistrationDetails.application_details.length > 0 &&
            users.getUserRegistrationDetails.application_details[0].application_date !== undefined
            )
        {
            var dateString2 = users.getUserRegistrationDetails.application_details[0].application_date;
            var dateObj2 = new Date(dateString2);
            var momentObj2 = moment(dateObj2);
            var momentString2 = momentObj2.format('DD-MM-YYYY');
            var years_registered = moment().diff(momentString2, 'years', false)
            var renewal_date1 = moment(momentObj2).add(3, 'Y')
            var renewal_date = renewal_date1.format('MMM DD YYYY')

            console.log("years_registered= "+years_registered)

            if(years_registered >= 3){
                props.history.push("/renewals");
            }
            else{
                showToast("ERROR", "You Cannot Renew Before 3 Years of Registration!")
            }
        }
        else{
            showToast("ERROR", "You Cannot Renew Right Now!")
        }
        
    }
    useEffect(() => {

        if(opennotification){

        
        var config = {
            method: 'post',
            url: SERVER + '/notifications/get_user_notifications?limit=1000',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: {
                "receiver_id": users.user.id,
                "is_global": false,
                "is_labour": true
            }
        };
        axios(config)
            .then(function (response) {
                console.log("notification", response.data.docs);

                setNotification(response.data.docs);

            })
            .catch(function (error) {
                console.error("notification error: " + error);
            });



        

            var datagetrenewaldate = JSON.stringify({
                "user_id": users.user.id
              });
              
              var configgetrenewaldate = {
                method: 'post',
                url: SERVER+'/user/get-renewal-date',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : datagetrenewaldate
              };
              
              axios(configgetrenewaldate)
              .then(function (response) {
                console.log(JSON.stringify(response.data));
                setrenewaldate(response.data.data.recordsets[0][0].next_renewal_date)
                console.log("next_renewal_date: "+response.data.data.recordsets[0][0].next_renewal_date)

              })
              .catch(function (error) {
                console.log(error);
              });
            }

    }, [opennotification])



   

    const handleClickOpen = () => {

        if (get_user_appeal !== undefined && get_user_appeal !== "") {
            setOpen(true);
            setshowAppStatus(false)
        }

    };

    const handleClose = () => {
        setOpen(false);
        setshowAppStatus(true)
    };
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = notification.slice(indexOfFirstPost, indexOfLastPost);
    const nextPage = () => setCurrentPage(currentPage + 1);
    const prevPage = () => setCurrentPage(currentPage - 1);
    const paginate = pageNum => setCurrentPage(pageNum);
    const pageNumbers = [];

    for (let i = 1; i <= Math.ceil(notification.length / postsPerPage); i++) {
        pageNumbers.push(i);
    }
    const updateNotification = async (_id) => {
        var config = {
            method: 'patch',
            url: SERVER + '/notifications/update_notification_read',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data:
            {
                "id": _id,
                // "reader_id": 3
                "reader_id": users.user.id
            }
        };
        await axios(config)
            .then(function (response) {
                console.log(response);
                console.log("updated notification", response.data.data);

            })
            .catch(function (error) {
                console.error("notification error: " + error);
            });

        var config = {
            method: 'post',
            url: SERVER + '/notifications/get_user_notifications?limit=1000',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: {
                "reader_id": users.user.id,
                "receiver_id": users.user.id,
                "is_global": false,
                "is_labour": true
            }
        };
        await axios(config)
            .then(function (response) {
                console.log("notification", response.data.docs);
                setNotification(response.data.docs);

            })
            .catch(function (error) {
                console.error("notification error: " + error);
            });
    }
    const clearAllNotification = () => {
        notification.map(x => {
            updateNotification(x._id);
        })
    }
    const changePage = ({ selected }) => {
        setPageNumber(selected);
      };



    const AppealButtonPressed = () => {

        // setshowAppStatus(false)
        console.log("AppealButtonPressed get_user_appeal: "+JSON.stringify(get_user_appeal,undefined,2))

        if(get_user_appeal !== undefined && get_user_appeal !== "" ){
          
            var data = JSON.stringify({
                "board_id": get_user_appeal.board_id,
                "user_id": users.user.id,
                "approval_id": get_user_appeal.approval_id,
                "labour_work_certificate_id": get_user_appeal.labour_work_certificate_id,
                "appeal_to_user_id": get_user_appeal.appeal_to_user_id
            });
            
            var config = {
                method: 'post',
                url: SERVER+'/appeal/make_an_appeal',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization':`Bearer ${getTokenCookie()}`
                },
                data : data
            };
            
            axios(config)
            .then(function (response) {
                console.log("make_an_appeal response: "+JSON.stringify(response.data));

                if(response.data.success !== undefined && response.data.success === true){
                    handleClose()
                    showToast("SUCCESS", "Successfully Appealed!")
                    // props.history.push("/dashboard-user");
                    // window.location.reload();

                    let dataForRegDetails = { 
                        "key": "user_id",
                        "value": users.user.id,
                        "board_id": 4,
                        "procedure_name": "all"
                    }
                    dispatch(getUserRegistrationDetails(dataForRegDetails))

                    var config = {
                        method: 'get',
                        url: SERVER+'/appeal/get_user_appeal?board_id=1&user_id='+users.user.id,
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization':`Bearer ${getTokenCookie()}`
                        },
                      };
                      
                      axios(config)
                      .then(function (response) {
                        console.log("get_user_appeal response: "+JSON.stringify(response.data.data[0], undefined,2))
                        // console.log("get_user_appeal.length response: "+response.data.data[0].length)
    
                        setget_user_appeal(response.data.data[0])
                      })
                      .catch(function (error) {
                        console.error("get_user_appeal error: "+error);
                      });

                      
                    setshowAppStatus(true)
                }

            })
            .catch(function (error) {
                console.log("make_an_appeal error:"+error);
                handleClose()
                setshowAppStatus(true)
            });

            

        }
          
        
    }
    const logOut = () => {
        dispatch(registerUser("delete"))
        removeTokenCookieAdmin()
        props.history.push("/")
    }

    // const [open, setOpen] = React.useState(false);

    // const handleClickOpen = () => {

    //     if(get_user_appeal !== undefined && get_user_appeal !== "" ){
    //         setOpen(true);
    //         setshowAppStatus(false)
    //     }

    // };

    // const handleClose = () => {
    //     setOpen(false);
    // };

    return (
        <>
            <div className="root">

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Appeal"}</DialogTitle>
                <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to Appeal?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button onClick={handleClose} color="primary">
                    No
                </Button>
                <Button onClick={AppealButtonPressed} color="primary" autoFocus>
                    Yes
                </Button>
                </DialogActions>
            </Dialog>

                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={8} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                <Translate value='header.titleungoranized'/><br/>
                                <Translate value='header.subungoranized'/>

                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                        <img alt="..." src={notification } className="language-img" />
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user !== null && users.user.first_name} />
                                </MenuItem>
                                <MenuItem value="logout" onClick={()=>{props.history.push("/")}}>
                                    <ListItemText primary="Logout"/>
                                </MenuItem>
                            </Select>
                        </Col> */}
                        <Col xs={12} md={4} lg={4} style={{ display: "flex" }}>
                                <div style={{ display: "flex", alignItems: "center",marginRight:"40px", marginTop:"3px"}}>

                                <Badge badgeContent={notification.length} color="primary">
                                    <img onClick={()=> setopennotification(true)} src={NotificationIcon} alt="image"  /*height="25px"*/ />
                                </Badge>
                            </div>
                            <div className= "usericon-header-logout">
                                <img alt="..." src={usericon} className="usericon-img" />

                                <p>{users.user !== null && users.user.first_name}</p>               
                                <Tooltip title="Logout" placement="top-start" arrow interactive>
                                    <img alt="..." src={logout} className="logout-img" onClick={logOut}/>
                                </Tooltip>
                            </div>
                            <div className='d-flex justify-content-end ms-5'>
        <Col xs={12} md={4} lg={3} className="d-flex justify-content-end mt-2 me-2 ms-2 w-100">
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        </div>
                            </Col>
                    </Row>

                </Row>

                <Row className="dashboarduser-row2">
                    
                    <Col xs={12} sm={12} md={12} className="dashboarduser-col2 status">
                        <div className="ApplicationStatus"><Translate value='applicationStatus'/></div>
                        {/* <p>{status}</p> */}

                        {
                            showAppStatus ?
                            {
                                "approved":
                                    <>
                                        <div className="statusbar approved">
                                            <img alt="..." src={checkwhite}/>
                                            <p><Translate value='approvedStatus'/></p>
                                        </div>
                                    </>,
                                "pending":
                                    <>
                                        <div className="statusbar pending">
                                            <img alt="..." src={pendingicon}/>
                                            <p><Translate value='pendingStatus'/></p>
                                        </div>
                                    </>,
                                "incomplete":
                                    <>
                                        <Link to="/profile-unorganized">
                                            <div className="statusbar incomplete">
                                                <img alt="..." src={incomplete}/>
                                                <p><Translate value='incompleteStatus'/></p>
                                            </div>
                                        </Link>
                                    </>,
                                "rejected":
                                    <>
                                        <div className="statusbar rejected">
                                            <img alt="..." src={rejected}/>
                                            <p><Translate value='rejectedStatus'/>
                                            {
                                                get_user_appeal === undefined || 
                                                (
                                                users.getUserRegistrationDetails !== undefined
                                                &&
                                                users.getUserRegistrationDetails !== null
                                                &&
                                                users.getUserRegistrationDetails.personal_details !== undefined
                                                &&
                                                users.getUserRegistrationDetails.personal_details.length>0
                                                && 
                                                users.getUserRegistrationDetails.personal_details[0].is_appealed 
                                                && 
                                                users.getUserRegistrationDetails.personal_details[0].is_rejected) ?
                                                // get_user_appeal !== "" ?

                                                null
                                                :
                                                <>
                                                <Link to="#"
                                                    onClick={handleClickOpen}
                                                    className="ml-3 appealLink"
                                                >
                                                    <Translate value='clickAppeal'/>
                                                </Link>
                                                </>
                                                // : 
                                                // null
                                            }
                                            </p>
                                        </div>
                                    </>,
                                "appealed":
                                    <>
                                        <div className="statusbar pending">
                                            <img alt="..." src={pendingicon}/>
                                            <p><Translate value='appealStatus'/></p>
                                        </div>
                                    </>,
                            }[status]

                            :
                            <>
                            </>
                        }
                    </Col>

                    {/* <Col xs={12} sm={12} md={12} className="dashboarduser-col-noti">
                        <p> - No New Notifications - </p>
                    </Col> */}
                    
                </Row>
                
                <Row className="dashboarduser-row">
                    
                    <Col xs={6} sm={3} md={3} className="dashboarduser-col mt-3">
                        <Link to="/profile-unorganized">
                        <div className="icondiv">
                                <img alt="..." src={Registration}/>
                                <p><Translate value='dashRegistration'/></p>
                        </div>
                        </Link>
                    </Col>
                    <Col xs={6} sm={3} md={3} className="dashboarduser-col mt-3">
                        <Link to="#" onClick={SchemesClicked}>
                        <div className="icondiv">
                                <img alt="..." src={Schemes}/>
                                <p><Translate value='dashSchemes'/></p>
                        </div>
                        </Link>
                    </Col>
                    {/* <Col xs={6} sm={3} md={3} className="dashboarduser-col mt-3">
                        <Link to="/schemeStatus">
                        <div className="icondiv">
                                <img alt="..." src={SchemeStatus}/>
                                <p>Schemes Status</p>
                        </div>
                        </Link>
                    </Col> */}
                    
                </Row>

                {/* <Row className="dashboarduser-row-id">
                    <Col xs={12} md={6} className="dashboarduser-col id-col">
                        <Link to="#" onClick={EcardClicked} className="id-link">
                                <img alt="..." src={ECard}/>
                                <p>E-Card</p>
                        </Link>
                    </Col>
                </Row> */}





                {/* <Row className="dashboarduser-row2">
                    
                    <Col xs={12} sm={12} md={12} className="dashboarduser-col2">
                        <p>Notifications</p>
                    </Col>

                    <Col xs={12} sm={12} md={12} className="dashboarduser-col-noti">
                        <p> - No New Notifications - </p>
                    </Col>
                    
                </Row>

                <div>
                    {currentPosts.map((x) => <Row className="notification">
                        <Col onClick={() => updateNotification(x._id)}>
                            <div className="d-flex flex-column">
                                <div className="d-inline-flex justify-content-end " > <button className="btn btn-danger"onClick={() => updateNotification(x._id)}>X</button></div>
                                <div className="d-flex justify-content-between pt-3">
                                    <div>  {x.message}</div>
                                    <div className="">
                                        {new Date(x.createdAt).toLocaleString()}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>)}

                    <nav>
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a className="page-link" href="#" onClick={() => prevPage()}>Previous</a>
                                </li>
                                {pageNumbers.map(num => (
                                    <li className="page-item" key={num}>
                                        <a onClick={() => paginate(num)} href="#" className="page-link">{num}</a>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <a className="page-link" href="#" onClick={() => nextPage()}>Next</a>
                                </li>
                                <button className="btn btn-primary" onClick={() => clearAllNotification()}>Clear</button>
                            </ul>
                        </nav> */}
                    
                    {/* <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        // pageCount={pageCount}
                        pageCount={pageNumbers.length}
                        onPageChange={changePage}
                        containerClassName={"paginationBttns"}
                        previousLinkClassName={"previousBttn"}
                        nextLinkClassName={"nextBttn"}
                        disabledClassName={"paginationDisabled"}
                        activeClassName={"paginationActive"}
                    />
                     <button className="btn btn-primary" onClick={() => clearAllNotification()}>Clear</button> */}




                    {/* </div> */}


                    

                {/* {currentPosts.map((x) => 
                
                    <Row className="notification">
                        <Col onClick={()=>updateNotification(x._id)}>{x.message} </Col>
                    </Row>
                    )
                }
                <div className="pgn">
                    <nav>
                    <ul className="pagination justify-content-center">
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => prevPage()}>Previous</a>
                        </li>
                        {pageNumbers.map(num => (
                            <li className="page-item" key={num}>
                                <a onClick={() => paginate(num)} href="#" className="page-link">{num}</a>
                            </li>
                        ))}
                        <li className="page-item">
                            <a className="page-link" href="#" onClick={() => nextPage()}>Next</a>
                        </li>
                    </ul>
                    </nav>

                </div> */}
                <Modal
                    open={opennotification}
                    onClose={()=> setopennotification(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    style={{overflow:'scroll'}}
                >
                    <Box className="modal_box">
                        <Typography> Notification</Typography>
                        <Row> {currentPosts.map((x, index) => <li key="index" className="list_noti"
                        >
                            <div className="d-flex flex-column">
                                <div className="d-inline-flex justify-content-end " onClick={() => updateNotification(x._id)}> <button className="btn btn-danger" color="primary">X</button></div>
                                <div>  {x.message}</div>
                            </div>

                        </li>

                        )}</Row>
                        <nav>
                            <ul className="pagination justify-content-center">
                                <li className="page-item">
                                    <a className="page-link" href="#" onClick={() => prevPage()}>Previous</a>
                                </li>
                                {pageNumbers.map(num => (
                                    <li className="page-item" key={num}>
                                        <a onClick={() => paginate(num)} href="#" className="page-link">{num}</a>
                                    </li>
                                ))}
                                <li className="page-item">
                                    <a className="page-link" href="#" onClick={() => nextPage()}>Next</a>
                                </li>
                                <button className="btn btn-primary" onClick={() => clearAllNotification()}>Clear</button>
                            </ul>
                        </nav>
                    </Box>
                </Modal>
            

                
            </div>

        </>
    )
}

export default Dashboard;