import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const boardDetails = async () => {
  try {
    const { data } = await axios.get(`${SERVER}/global/get_board_details?id=1`);
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const insertMappingUnion = async (payLoad) => {
  try {
    const { data } = await axios.put(`${SERVER}/config/union/map`, payLoad, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
    });
    return data;
  } catch (error) {
    throw error;
  }
};

export const getUnionMappedDetails = async (unionID) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/config/union/map?unionId=${unionID}`,

      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data.data;
  } catch (error) {
    throw error;
  }
};

export const unmapUnion = async (payLoad) => {
  try {
    const { data } = await axios.delete(`${SERVER}/config/union/map`, {
      headers: {
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payLoad,
    });
    return data;
  } catch (error) {
    throw error;
  }
};
