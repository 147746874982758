export const AccidentForwardValidator = (data) => {
  if (
    (data.verificationDoc && data.verificationDoc.length > 0) ||
    (data.verificationDocument && data.verificationDocument.length > 0)
  ) {
    if (data.remarks) {
      return { success: true, data: data };
    } else {
      return { success: false, msg: 'Please fill the remarks' };
    }
  } else {
    return { success: false, msg: 'Please upload verification document' };
  }
};

export const AccidentDraftValidator = (data) => {
  console.log('Draft Data', data);
  if (data.from === 'DraftApprove') {
    if (data.accidentResultedIn === 'Death') {
      if (data.order) {
        return { success: true, data: data };
      } else {
        return {
          success: false,
          msg: 'Please fill the order',
        };
      }
    } else if (data.enteredAmount) {
      if (data.enteredAmount <= data.sanctionedAmount) {
        if (data.order) {
          return { success: true, data: data };
        } else {
          return {
            success: false,
            msg: 'Please fill the order',
          };
        }
      } else {
        return {
          success: false,
          msg: `Limit exceeded, maximum amount limit is ${data.sanctionedAmount} for Accident Assistance resulted in ${data.accidentResultedIn}`,
        };
      }
    } else {
      return { success: false, msg: 'Please enter amount' };
    }
  } else if (data.from === 'DraftReject') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  }
};

export const AccidentApproveRejectValidator = (data) => {
  if (data.from === 'Approve') {
    if (data.accidentResultedIn === 'Death') {
      if (data.order) {
        return { success: true, data: data };
      } else {
        return {
          success: false,
          msg: 'Please fill the order',
        };
      }
    } else if (data.enteredAmount) {
      if (data.enteredAmount <= data.sanctionedAmount) {
        if (data.order) {
          return { success: true, data: data };
        } else {
          return {
            success: false,
            msg: 'Please fill the order',
          };
        }
      } else {
        return {
          success: false,
          msg: `Limit exceeded, maximum amount limit is ${data.sanctionedAmount} for Accident Assistance resulted in ${data.accidentResultedIn}`,
        };
      }
    } else {
      return { success: false, msg: 'Please enter amount' };
    }
  } else if (data.from === 'Reject') {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: 'Please fill the order',
      };
    }
  }
};
