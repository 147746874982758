import React,{useState,useEffect} from 'react'
import DBTMISReports from './DBTMISReports'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';

import { MenuItem, ListItemText, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, CircularProgress, TableHead, TextField, InputAdornment, Select, Backdrop, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getLabourStatusDetails, getSchemesOnStatus, get_schemes, SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { Button, Col, Row } from 'react-bootstrap';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { getTokenCookie } from 'utils/tools';
import MomentUtils from '@date-io/moment';
import axios from 'axios';

const IVSNameMatchDBT = () => {
 const [goBack,setGoBack] = useState(false)

 const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        
        users: users.profile_details.users,
        schemesSelected: "",
        start_date : null,
        end_date : null,  
    })

    const [activeNum,setActiveNum] = useState("")
    const [schemesSel, setschemesSel] = React.useState(null)
    const [anchorElschemes, setAnchorElschemes] = React.useState(null);
    const [startDate, setstartDate] = React.useState(null);
    const [endDate, setendDate] = React.useState(null);
    const [dbtTotalCounts,setDbtTotalCounts] = React.useState([])
    const [dbtPaymentCounts,setDbtPaymentCounts] = React.useState([])
    const [dbtIVSFailedCounts,setDbtIVSFailedCounts] = React.useState([])
    const [dbtIVSNameCounts,setDbtIVSNameCounts] = React.useState([])
            const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
            width: '350px'
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));
        useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
        if(startDate !== null){
        setAllValues({ ...allValues,  start_date: new Date(startDate), dateSelected : true })
       
        console.log("start_date_one", allValues.start_date)
        }
    }, [startDate]);

    useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
            if(endDate !== null){
            setAllValues({ ...allValues, end_date: endDate, dateSelected : true})
            console.log("endd_date" + allValues.end_date)
            }

      
        
    }, [endDate]);
    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
        console.log("allValuesSchemeSelect",allValues.schemesSelected)
        let datatoSubmit = users.getDeptUserRole.data[0].board_id
        dispatch(get_schemes(datatoSubmit))
        


    }, []);

        useEffect(() => {
            console.log("SchemsData:",users);
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.get_schemes !== null &&
            users.get_schemes.error !== undefined &&
            users.get_schemes.error !== null &&
            users.get_schemes.error === true) {
            console.log("ERROR: " + JSON.stringify(users.get_schemes.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.get_schemes !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setschemesSel(users.get_schemes.data)
        }
    }, [users.get_schemes]);

    console.log("ALLVALUES:",allValues);

    const callDBTMIS = async() => {
        var data = {
            
            "board_id": users.get_board[0].id,
            "scheme_id":allValues.schemesSelected === "All" ? null : allValues.schemesSelected.id,
            "starte_date": allValues.start_date,
            "end_date": allValues.end_date,

        }
        console.log("APIDATA",data);
        var config = {
            method: 'post',
                url: SERVER+'/reports/get_dbt_ivs_failed_name_matching_records',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
        }
       await axios(config).then((res)=>{
            console.log("APIResponse:",res.data);
            
            
        }).catch((error)=>{
            console.log(error);
        })
    }

    // const handleClick = (num) => {
    //     console.log(num);
    //     setActiveNum(num)
    // }

    const onSelectClickHandlerkboc = (event, data) => {
        
        if (data === "schemes") {
            setAnchorElschemes(event.currentTarget);
        }
        
    }
    const handleClosekboc = (event, data) => {
        
         if (data === "schemes") {
            setAnchorElschemes(null);
        }
         
    }

    const onSelectSchemesHandler = (event, data) => {
        setAllValues({
            ...allValues,
            schemesSelected: data
        })
        setAnchorElschemes(null);
    }


    const handleBack = () => {
        setGoBack(true)
    }


        const AllSchemes = () => { 
        const schemeList = 
                                    schemesSel !== undefined &&
                                    schemesSel !== null &&
                                    schemesSel.map((row) => (
                                        <MenuItem
                                            onClick={(e) => onSelectSchemesHandler(e, row)}
                                            className={allValues.schemesSelected === row ? "active" : null}
                                        >
                                            <ListItemText primary={row.name} />
                                        </MenuItem>
                                    )
                                    );
        return <> <MenuItem
                    onClick={(e) => onSelectSchemesHandler(e, "All")}
                    className={allValues.schemesSelected === "All" ? "active" : null}
                                        >
                                            <ListItemText primary={"All"} />
                                        </MenuItem>{schemeList}</>
    }
  return (
    
    <>
    {goBack ? <DBTMISReports/> : null}
    {goBack === false ?
    <>
    <div className='dbt-info-back-btn'>
    <button style={{background:'transparent',outline:'none',border:'none'}} onClick={handleBack}><img alt="..." className="cal-icon hover-icn" src={back}/></button>
    <h1>IVS Failed (Name Matching) Records</h1>
    
    </div>
    <div className='dbt-dropdown-filter-main'>
                <Col xs={3} className="kbocwwb-col">
                            <div aria-controls="customized-menu" aria-haspopup="true"
                                className={`kbocwwb-col-div hvr-icon-hang ${allValues.statusActive}`}
                                onClick={(e) => onSelectClickHandlerkboc(e, "schemes")}
                            >
                                <p>{allValues.schemesSelected ? allValues.schemesSelected === "All" ? "All" : allValues.schemesSelected.name : "Schemes"  }</p>
                                <img alt="..." className="hvr-icon" />
                            </div>
                            <StyledMenu
                                id="customized-menu"
                                className="customized-menu"
                                anchorEl={anchorElschemes}
                                keepMounted
                                open={Boolean(anchorElschemes)}
                                onClose={(e) => handleClosekboc(e, "schemes")}
                            >
                                <AllSchemes/>

                                {/* {
                                    schemesSel !== undefined &&
                                    schemesSel !== null && 
                                    <AllSchemes/> &&
                                    schemesSel.map((row) => (
                                        <MenuItem
                                            onClick={(e) => onSelectSchemesHandler(e, row)}
                                            className={allValues.schemesSelected === row ? "active" : null}
                                        >
                                            <ListItemText primary={row.name} />
                                        </MenuItem>
                                    )
                                    )} */}

                            </StyledMenu>

                        </Col>
                        <Col xs={2} className="benificiaries-col w-25">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                        
                                        className="datepicker"
                                        // margin="normal"
                                        format="YYYY/MM/DD"
                                        value = {allValues.start_date}                       
                                        onChange={(date) => { setstartDate(date)}}                                                          
                                        placeholder="Start Date"
                                        
                                  
                                    />
                                </MuiPickersUtilsProvider>
                        </div>
                    </Col>
                    <Col xs={2} className="benificiaries-col w-25">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                    <KeyboardDatePicker
                                     
                                        className="datepicker"
                                        // margin="normal"
                                        format="YYYY/MM/DD"
                                        value = {allValues.end_date}                       
                                        onChange={(date) => { setendDate(date)}}                                                          
                                        placeholder="End Date"
                                        
                                  
                                    />
                                </MuiPickersUtilsProvider>
                        </div>
                    </Col>
                    <Col xs={3} className="kbocwwb-col">
                        <div  onClick={callDBTMIS} aria-controls="customized-menu" aria-haspopup="true" className="kbocwwb-col-div" 
                        >
                       <p>Search</p> 
                        </div>
                    </Col>
                    
    </div>
    </>
     : null}
    
    </>
  )
}

export default IVSNameMatchDBT