import React, { useState, memo } from 'react';
import { Popover } from '@material-ui/core';
const PopOverImage = ({
  id,
  open,
  anchorEl,
  selected_document_type,
  handleClose,
  popImgCall,
  imgTitle,
}) => {
  console.log('POPIMAGE:', { selected_document_type });
  const [corretImg, setCorretImg] = useState(true);
  const handlerCheckImage = () => {
    setCorretImg(false);
  };

  const handlerCloseBtn = (event, data) => {
    handleClose(event, data);
  };

  return (
    <>
      <img
        onError={handlerCheckImage}
        alt="..."
        className="avatar border-gray"
        src={`data:image/jpeg;base64,${selected_document_type}`}
        style={{ display: 'none' }}
      />

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={(e) => handlerCloseBtn(e, imgTitle)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {corretImg == true ? (
          <a href="#">
            <img
              alt="..."
              className="avatar border-gray"
              src={`data:image/jpeg;base64,${selected_document_type}`}
              onClick={() => {
                const base64ImageData =
                  'data:image/jpg;base64,' + selected_document_type;
                const contentType = 'image/png';
                const byteCharacters = atob(
                  base64ImageData.substr(`data:${contentType};base64,`.length),
                );
                const byteArrays = [];
                for (
                  let offset = 0;
                  offset < byteCharacters.length;
                  offset += 1024
                ) {
                  const slice = byteCharacters.slice(offset, offset + 1024);
                  const byteNumbers = new Array(slice.length);
                  for (let i = 0; i < slice.length; i++) {
                    byteNumbers[i] = slice.charCodeAt(i);
                  }
                  const byteArray = new Uint8Array(byteNumbers);
                  byteArrays.push(byteArray);
                }
                const blob = new Blob(byteArrays, { type: contentType });
                const blobUrl = URL.createObjectURL(blob);
                window.open(blobUrl, '_blank');
              }}
            />
          </a>
        ) : (
          <div>No image found</div>
        )}
      </Popover>
    </>
  );
};

export default memo(PopOverImage);
