import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const rejectedReasonsAPI = async (id) => {
  try {
    const { data } = await axios.get(
      `${SERVER}/global/rejection-reason?id=${id}`,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
