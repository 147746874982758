import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Col, Row } from 'react-bootstrap';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import axios from 'axios';
import Moment from 'react-moment';

const SchemeSanctionOrder = (props) => {
  const { schemesDetailsData, schemesStatusData } = props;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: 800,
            height: 500,
          },
        }}
      >
        <Paper elevation={0}>
          <div className="d-flex justify-content-around align-items-center flex-column">
            <img src={logo} alt="logo" height={'80'} />
            <h5>
              ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕ ಕ ಇ ನಿ
              ಕಾ ಕ ಮಂ)
            </h5>
            <h5>
              Karnataka Building & Other Construction Workers Welfare Board (K B
              O C W W B)
            </h5>
          </div>
          <div className="d-flex justify-content-center">
            <TableContainer component={Paper} style={{ width: '80%' }}>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow className="d-flex flex-column">
                    <TableCell
                      style={{ color: 'black' }}
                      align="justify"
                      className="d-flex flex-row"
                    >
                      <Col style={{ paddingLeft: '10%' }}>
                        Registration Number
                      </Col>
                      <Col>{schemesDetailsData.registration_code}</Col>
                    </TableCell>

                    <TableCell
                      style={{ color: 'black' }}
                      align="justify"
                      className="d-flex flex-row"
                    >
                      <Col style={{ paddingLeft: '10%' }}>
                        Application Number
                      </Col>
                      <Col>
                        {schemesStatusData &&
                          schemesStatusData[0] &&
                          schemesStatusData[0].scheme_application_code}
                      </Col>
                    </TableCell>

                    <TableCell
                      style={{ color: 'black' }}
                      align="justify"
                      className="d-flex flex-row"
                    >
                      <Col style={{ paddingLeft: '10%' }}>
                        Name of the Applicant
                      </Col>
                      <Col>{schemesDetailsData.labour_name}</Col>
                    </TableCell>

                    <TableCell
                      style={{ color: 'black' }}
                      align="justify"
                      className="d-flex flex-row"
                    >
                      <Col style={{ paddingLeft: '10%' }}>Application Date</Col>
                      <Col>
                        <Moment format="DD MMMM YYYY">
                          {schemesStatusData &&
                            schemesStatusData[0] &&
                            schemesStatusData[0].applied_date}
                        </Moment>
                      </Col>
                    </TableCell>

                    <TableCell
                      style={{ color: 'black' }}
                      align="justify"
                      className="d-flex flex-row"
                    >
                      <Col style={{ paddingLeft: '10%' }}>
                        Scheme Service Name
                      </Col>
                      <Col>{schemesDetailsData.scheme_name}</Col>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </TableContainer>
          </div>
          {/* <div className='d-flex justify-content-center flex-column align-items-center text-justify mt-3 py-2'>
        <Row>
        <Col className='px-5'>
        <p style={{textAlign:'left'}}>Registration Number</p>
        </Col>
        <Col className='px-5'>
        <p style={{textAlign:'left'}}>KA23BLU00100000020</p>
        </Col>
        </Row>

        <Row>
        <Col className='px-5'>
        <p style={{textAlign:'left'}}>Reference Number</p>
        </Col>
        <Col className='px-5'>
        <p style={{textAlign:'left'}}>KA23BLU00100000020</p>
        </Col>
        </Row>

          <Row>
        <Col className='px-5'>
        <p>Name of the Applicant</p>
        </Col>
        <Col className='px-5'>
        <p>KA23BLU00100000020</p>
        </Col>
        </Row>

        <Row>
        <Col className='px-5'>
        <p>Date of Applicant</p>
        </Col>
        <Col className='px-5'>
        <p>KA23BLU00100000020</p>
        </Col>
        </Row>

        <Row>
        <Col className='px-5'>
        <p>Requested Service</p>
        </Col>
        <Col className='px-5'>
        <p>KA23BLU00100000020</p>
        </Col>
        </Row>
     
      </div> */}
        </Paper>
      </Box>
    </>
  );
};

export default SchemeSanctionOrder;
