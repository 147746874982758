import React from 'react';
import { Switch, Route, BrowserRouter, Router } from 'react-router-dom';
import Login from 'components/login';
import Registration from 'components/registration';
import Admin from 'components/adminLogin';
import AdminPortal from 'components/adminPortal';
import Profile from 'components/profile';
import MainLayout from './components/hoc/mainLayout';
import App from './app';
import history from './history';

const Routes = () => {
  return (
    // <Router history={history}>
    <BrowserRouter basename={'/'}>
      <MainLayout>
        <Switch>
          <App />
          {/* <Route path="/admin/home" component={AdminPortal} />
          <Route path="/admin" component={Admin} />
          <Route path="/profile" component={Profile} />
          <Route path="/registration" component={Registration} />
          <Route exact path="/" component={Login} /> */}
        </Switch>
      </MainLayout>
    </BrowserRouter>
    // </Router>
  );
};

export default Routes;
