import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';

import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';
import checkGreen from 'assets/images/Tick-01.png';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import moment from 'moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
const RegistrationAcknowledgment = (props) => {
  const { labourUserID } = useParams();
  //   const currentUrl = window.location.href;

  //   const searchParams = new URLSearchParams(
  //     currentUrl.slice(currentUrl.indexOf('?')),
  //   );

  //   const { applicationDate } = Object.fromEntries(searchParams.entries());

  const [getUserDetails, setGetUsetDetails] = useState(null);

  const [displayDate, setDisplayDate] = useState(false);
  const currentDate = moment().format('DD/MM/YYYY');
  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'all',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        setGetUsetDetails((prev) => ({
          ...prev,
          personalDetails: data.data.personal_details,
          addressDetails: data.data.address_details,
          certificateDetails: data.data.certificate_details,
          employerDetails: data.data.employer_details,
          applicationDetails: data.data.application_details,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  const generatePDF = () => {
    setDisplayDate(true);
    const element = document.getElementById('ack');
    const opt = {
      filename: `${getUserDetails?.applicationDetails[0]?.application_no}_Registration_Acknowledgement`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf(element, opt).then(() => {
      setDisplayDate(false);
    });
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: '70%',
          },
        }}
      >
        <Paper elevation={3}>
          <div className="d-flex justify-content-between">
            <div>
              <>
                <IconButton
                  style={{
                    color: 'transparent',
                    background: 'transparent',
                    cursor: 'default',
                  }}
                >
                  <DownloadIcon style={{ color: 'transparent' }} />
                </IconButton>
              </>
            </div>
            <div id="ack">
              {displayDate && (
                <div className="d-flex justify-content-end align-items-center px-3 ">
                  <p style={{ fontSize: '14px' }}>Printed on:{currentDate}</p>
                </div>
              )}
              <div className="d-flex justify-content-center align-items-center flex-column">
                <div>
                  <img src={logo} alt="logo" height={'80'} />
                </div>
                <div className="d-flex justify-content-center flex-column align-items-center">
                  <h5 className="text-center">
                    ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕಕ
                    ಇನಿಕಾಕಮಂ)
                  </h5>
                  <h5 className="text-center">
                    Karnataka Building & Other Construction Workers Welfare
                    Board (KBOCWWB)
                  </h5>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-center flex-column py-1">
                <h3
                  className="w-100 text-center py-3"
                  style={{ background: '#F4F8F9' }}
                >
                  ಸ್ವೀಕೃತಿ /Acknowledgement
                </h3>
                <div className="d-flex justify-content-center align-items-center py-2">
                  <img
                    src={checkGreen}
                    style={{
                      width: '28px',
                      height: '28px',
                    }}
                    alt=".."
                  />
                  <p className="w-50">
                    {' '}
                    ಅಪ್ಲಿಕೇಶನ್ ಸ್ಥಿತಿ: ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರಿಗೆ ಯಶಸ್ವಿಯಾಗಿ
                    ಸಲ್ಲಿಸಲಾಗಿದೆ / Application Status : Successfully submitted
                    to Labour Inspector
                  </p>
                </div>
              </div>
              {getUserDetails ? (
                <>
                  <div className="d-flex justify-content-center">
                    <table style={{ borderCollapse: 'collapse' }}>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಅರ್ಜಿ ಸಂಖ್ಯೆ / Application Number
                        </td>
                        <td className="px-5 py-2">
                          {
                            getUserDetails?.applicationDetails[0]
                              ?.application_no
                          }
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಸಕಾಲ ಸಂಖ್ಯೆ / Sakala Number
                        </td>
                        <td className="px-5 py-2">
                          {' '}
                          {getUserDetails?.employerDetails[0]?.gsc_no}
                        </td>
                      </tr>

                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಅರ್ಜಿ ದಿನಾಂಕ / Application Date
                        </td>
                        <td className="px-5 py-2">
                          {moment(
                            getUserDetails?.applicationDetails[0]
                              ?.application_date,
                            'YYYY-MM-DD',
                          ).format('DD/MM/YYYY')}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ವಿನಂತಿಸಿದ ಸೇವೆ /Requested Service
                        </td>
                        <td className="px-5 py-2">Registration</td>
                      </tr>

                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಅರ್ಜಿದಾರರ ಹೆಸರು / Applicant's Name
                        </td>
                        <td className="px-5 py-2">
                          {`${getUserDetails?.personalDetails[0]?.first_name} ${
                            getUserDetails?.personalDetails[0]?.middle_name ??
                            ''
                          } ${
                            getUserDetails?.personalDetails[0]?.last_name ?? ''
                          }`}
                        </td>
                      </tr>

                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 ">
                          ಮನೆಯ ವಿಳಾಸ / Residential Address
                        </td>
                        <td className="px-5 w-50">
                          {getUserDetails &&
                            getUserDetails.addressDetails &&
                            `${
                              getUserDetails.addressDetails[0].door_no ?? ''
                            } ${
                              getUserDetails.addressDetails[0].street_name ?? ''
                            } ${
                              getUserDetails.addressDetails[0].land_mark ?? ''
                            } ${
                              getUserDetails.addressDetails[0]
                                .panhayat_city_town ?? ''
                            } ${
                              getUserDetails.addressDetails[0].district ?? ''
                            } ${
                              getUserDetails.addressDetails[0]
                                .ward_area_village ?? ''
                            } ${getUserDetails.addressDetails[0].taluk ?? ''} ${
                              getUserDetails.addressDetails[0].state ?? ''
                            } ${
                              getUserDetails.addressDetails[0].pin_code ?? ''
                            }`}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಹಳ್ಳಿ ವಾರ್ಡ್ / Village Ward
                        </td>
                        <td className="px-5 py-2">
                          {getUserDetails &&
                            getUserDetails.addressDetails &&
                            getUserDetails.addressDetails[0].ward_area_village}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಮೊಬೈಲ್ ಸಂಖ್ಯೆ / Mobile Number
                        </td>
                        <td className="px-5 py-2">
                          {getUserDetails &&
                            getUserDetails.personalDetails &&
                            getUserDetails.personalDetails[0].mobile_no}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಕೆಲಸದ ಸ್ವರೂಪ / Nature of Work
                        </td>
                        <td className="px-5 py-2">
                          {getUserDetails &&
                            getUserDetails.employerDetails &&
                            getUserDetails.employerDetails[0].nature_of_work}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ವೃತ್ತದ ಹೆಸರು / Circle Name
                        </td>
                        <td className="px-5 py-2">
                          {getUserDetails &&
                            getUserDetails.certificateDetails &&
                            getUserDetails.certificateDetails[0]
                              .inspector_circle}
                        </td>
                      </tr>
                      <tr className="table-row-pdf-docuemnts">
                        <td className="px-5 py-2">
                          ಹಿರಿಯ ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ / ಕಾರ್ಮಿಕ ನಿರೀಕ್ಷಕರ ಹೆಸರು
                          <br />
                          Senior Labour Inspector / Labour Inspector Name
                        </td>
                        <td className="px-5 py-2">
                          {getUserDetails &&
                            getUserDetails.certificateDetails &&
                            `${getUserDetails.certificateDetails[0].inspector_first_name} ${getUserDetails.certificateDetails[0].inspector_middle_name} ${getUserDetails.certificateDetails[0].inspector_last_name}`}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="d-flex justify-content-center align-items-center flex-column py-1">
                    <p className="text-danger" style={{ fontWeight: 'bolder' }}>
                      ಸೂಚನೆ / NOTE:
                    </p>
                    <p style={{ fontWeight: 'bolder' }}>
                      {' '}
                      1.ಸಲ್ಲಿಸಿದ ಅರ್ಜಿ ಪರಿಶೀಲನೆಗೆ ಒಳಪಟ್ಟಿರುತ್ತದೆ / Submitted
                      application is subject to verification.
                    </p>
                    <p style={{ fontWeight: 'bolder' }}>
                      2.ಸ್ಥಿತಿಯನ್ನು ತಿಳಿಯಲು ಅಪ್ಲಿಕೇಶನ್‌ಗೆ ಲಾಗಿನ್ ಮಾಡಿ / Login to
                      application to know the status.
                    </p>
                  </div>
                </>
              ) : (
                <SimpleBackdrop />
              )}
            </div>
            <div className="d-flex justify-content-center align-items-start">
              <IconButton onClick={generatePDF}>
                <DownloadIcon />
              </IconButton>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default RegistrationAcknowledgment;
