import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import React, { Suspense, useEffect, useState } from 'react';
import { Pagination, Stack } from '@mui/material';
import PaginationFunction from '../../adminPortal/Pagination';
import Eye from 'assets/images/eye.svg';
import Sakala from 'assets/images/sakala.png';
import moment from 'moment';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import { useSelector } from 'react-redux';
const NomineeTableComponent = ({ nomineeDetails, statusID, submitSuccess }) => {
  const { id } = useParams();

  const [page, setPage] = useState(1);
  const [query, setQuery] = useState(null);
  const [filteredData, setFilteredData] = useState(null);

  const users = useSelector((state) => state.users);
  const PER_PAGE = 10;

  useEffect(() => {
    if (query) {
      const keys = ['labour_user_name'];
      const result = nomineeDetails.filter((item) =>
        keys.some((key) => {
          return item['labour_user_name'].includes(query);
        }),
      );

      setFilteredData(result);
    }
  }, [query, nomineeDetails]);

  const count =
    query && query
      ? filteredData && Math.ceil(filteredData.length / PER_PAGE)
      : nomineeDetails && Math.ceil(nomineeDetails.length / PER_PAGE);

  let _DATA =
    query && query
      ? PaginationFunction(filteredData || [], PER_PAGE)
      : PaginationFunction(nomineeDetails || [], PER_PAGE);

  const handlePageChange = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  return (
    <>
      <TableContainer style={{ width: '100%' }} component={Paper}>
        <div className="d-flex justify-content-evenly align-items-center m-2 gap-2">
          <div className="w-75">
            <input
              style={{ resize: 'none' }}
              className="text-center w-100 form-control"
              value={query}
              onChange={(ev) => setQuery(ev.target.value)}
              placeholder={'Filter by Benificiary Name'}
            />
          </div>
          <div className="align-self-start">
            <Stack spacing={2} className="w-100">
              <Pagination
                count={count}
                size="medium"
                page={page}
                color="primary"
                variant="outlined"
                shape="circular"
                onChange={handlePageChange}
              />
            </Stack>
          </div>
        </div>

        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Application Date
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Beneficiary Name
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                {(statusID === 45 || statusID === 46) && 'Name matching score'}
              </TableCell>
              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                Scheme Name
              </TableCell>

              <TableCell
                style={{ color: 'black', fontWeight: 'bolder' }}
                align="center"
              >
                {statusID === 45 &&
                users?.getDeptUserRole?.data[0]?.role_id === 1
                  ? 'View'
                  : statusID === 47
                    ? 'Updated Nominee Name'
                    : null}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {_DATA.currentData() &&
              _DATA.currentData().map((row, index) => (
                <TableRow key={index}>
                  <TableCell align="center">
                    {moment(row.applied_date, 'YYYY-MM-DD').format(
                      'DD/MM/YYYY',
                    )}
                  </TableCell>
                  <TableCell align="center">{row.labour_user_name}</TableCell>
                  <TableCell align="center">
                    {(statusID === 45 || statusID === 46) &&
                      row.name_matching_score}
                  </TableCell>
                  <TableCell align="center">{row.scheme_name}</TableCell>

                  <TableCell align="center">
                    {row.status === 'Pending' &&
                      users?.getDeptUserRole?.data[0]?.role_id === 1 && (
                        <>
                          <Suspense fallback={<div>Loading...</div>}>
                            <Link
                              to={`/nominee/update?parentChildID=${row.parent_child_nominee_user_id}&labourName=${row.labour_user_name}&nomineeName=${row.parent_child_nominee_name}&nameMatchingScore=${row.name_matching_score}`}
                            >
                              <img
                                alt="..."
                                src={Eye}
                                className="mr-2"
                                style={{ marginRight: '8px' }}
                              />
                            </Link>
                          </Suspense>
                        </>
                      )}
                    {row.status === 'Approved' && row.updated_nominee_name}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default NomineeTableComponent;
