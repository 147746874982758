import React from 'react';

const MobileCLick = (props) => {


  
    const handleClick = () => {
      const id = '3401707'
      const token = '4fcf3f78138f61fe6273dbd1d6c05f15e30496d7a085919bbcdcf11f271dcecf427755bd751ebaa6ecb4409a4928e9e75f69162c4adb463b6877cd041142b5aeb8c56c610988e1b965cea8dc3d6b4fb6c498a8e43bca239d390bbb81e4fb7bd56b2e3393614e33b5268234b7af65d8720af1bc08fb5a53995da32b0927d7da623105465f3b148b8ea71005f397dd6c5b1297953dfafd0a0555ae5d4201a67f0c'
      props.history.push(`/mobile-registration?user_id=${id}&token=${token}`)                                                                                                      
    }
    return(
    <div>
        <button onClick={handleClick}>CLick here</button>
    </div>
    )
}

export default MobileCLick;