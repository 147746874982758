export const DisabilityForwardValidator = (data) => {
  if (
    (data.verificationDoc && data.verificationDoc.length > 0) ||
    (data.verificationDocument && data.verificationDocument.length > 0)
  ) {
    if (data.remarks) {
      return { success: true, data: data };
    } else {
      return { success: false, msg: "Please fill the remarks" };
    }
  } else {
    return { success: false, msg: "Please upload verification document" };
  }
};

export const DisabilityDraftValidator = (data) => {
  if (data.from === "DraftApprove") {
    if (data.amount) {
      // if (data.enteredAmount <= data.amountCost) {
      if (data.enteredAmount <= data.sanctionedAmount) {
        if (data.order) {
          return { success: true, data: data };
        } else {
          return {
            success: false,
            msg: "Please fill the order",
          };
        }
      } else {
        return {
          success: false,
          msg: `Limit exceeded, maximum amount limit is ${data.amountLimit} for Disability Pension`,
        };
      }
      // } else {
      //   return {
      //     success: false,
      //     msg: 'Entered amount exceeds cost of the treatment',
      //   };
      // }
    } else {
      return { success: false, msg: "Please enter amount" };
    }
  } else if (data.from === "DraftReject") {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: "Please fill the order",
      };
    }
  }
};

export const DisabilityApproveRejectValidator = (data) => {
  if (data.from === "Approve") {
    if (data.amount) {
      // if (data.enteredAmount <= data.amountCost) {
      if (data.enteredAmount <= data.sanctionedAmount) {
        if (data.order) {
          return { success: true, data: data };
        } else {
          return {
            success: false,
            msg: "Please fill the order",
          };
        }
      } else {
        return {
          success: false,
          msg: `Limit exceeded, maximum amount limit is ${data.amountLimit} for Disability Pension`,
        };
      }
      // } else {
      //   return {
      //     success: false,
      //     msg: 'Entered amount exceeds cost of the treatment',
      //   };
      // }
    } else {
      return { success: false, msg: "Please enter amount" };
    }
  } else if (data.from === "Reject") {
    if (data.order) {
      return { success: true, data: data };
    } else {
      return {
        success: false,
        msg: "Please fill the order",
      };
    }
  }
};
