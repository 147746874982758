import React, { useEffect, useState } from 'react';
import LabourDetailsScheme from './LabourDetails';
import { Button, Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Translate } from 'react-redux-i18n';
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
  Tooltip,
} from '@material-ui/core';
import language from 'assets/images/translate (1).svg';
import usericon from 'assets/images/Icon awesome-user.svg';
import logout from 'assets/images/Logout.svg';
import {
  Link,
  useHistory,
  useLocation,
  useParams,
} from 'react-router-dom/cjs/react-router-dom.min';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import { SERVER, setLocaleWithFallback } from 'store/actions/user.actions';
import { Required, getTokenCookie, showToast } from 'utils/tools';
import axios from 'axios';
import DynamicFields from '../DynamicSchemes/DynamicFields';
import { useDispatch, useSelector } from 'react-redux';
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  updateNomineeEKYCVaultRefNumber,
} from './Aadhaar.service';
import stringSimilarity from 'utils/nameMatching';
import VerifiedIcon from '@mui/icons-material/Verified';
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import mergeNames from 'utils/mergeNames';
import NameMatchingDialog from './NameMatchingDialog';
const NewSchemeForm2 = (props) => {
  const {
    labourUserID,
    schemeName,
    schemeID,
    roleID,
    death,
    totalCountAvailed,
    totalAvailedByUser,
  } = useParams();
  const [loading, setLoading] = useState(false);
  const [aadhaarVerified, setAadhaarVerified] = useState(false);
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const [labourDetails, setLabourDetails] = useState(null);
  const [schemes, setSchemes] = useState(null);
  const [open, setOpen] = React.useState(false);

  const [translate, setTranslate] = useState({
    lang: users.profile_details.language,
    kannadaSelected: i18nlang.locale === 'ka' ? true : false,
  });

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'all',
    };

    const config = {
      method: 'post',
      url: `${SERVER}/user/get-user-registration-details`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payLoad,
    };

    axios(config)
      .then((res) => {
        // console.log(res.data);
        setLabourDetails(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  useEffect(() => {
    axios
      .get(`${SERVER}/schemes/get_schemes?board_id=1`)
      .then(({ data }) => {
        setSchemes(data.data);
      })
      .catch((err) => {
        if (err) {
          showToast('ERROR', 'Something went wrong');
        } else {
          showToast('ERROR', 'Something went wrong');
        }
      });
  }, []);

  const handleChange = (event) => {
    if (event.target.value === 'ka') {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: true,
        [event.target.name]: event.target.value,
      }));
    } else {
      setTranslate((prev) => ({
        ...prev,
        kannadaSelected: false,
        [event.target.name]: event.target.value,
      }));
    }

    if (event.target.name === 'lang')
      dispatch(setLocaleWithFallback(event.target.value));
  };

  const handleVerifyAadhaar = async () => {
    const nomineeDetails =
      labourDetails &&
      labourDetails?.family_details?.length > 0 &&
      labourDetails?.family_details?.filter((fam) => fam.is_nominee);

    if (nomineeDetails.length === 0) {
      showToast(
        'ERROR',
        'Nominee details not found! Please add nominee details to continue..',
      );
      return;
    }

    try {
      //Production Data

      const payLoad = {
        responseRedirectURL: `https://kbocwwb.karnataka.gov.in/newSchemeForm/${labourUserID}/${schemeID}/${schemeName}/${roleID}/${false}/${false}/${true}/0/0/${totalCountAvailed}/${totalAvailedByUser}`,
      };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/newSchemeForm/${labourUserID}/${schemeID}/${schemeName}/${roleID}/${false}/${false}/${true}/0/0/${totalCountAvailed}/${totalAvailedByUser}`,
      // };

      /* ==== DEV DATA ==== */
      // const payLoad = {
      //   responseRedirectURL: `http://gcdmsdbac.hostg.in:5353/newSchemeForm/${labourUserID}/${schemeID}/${schemeName}/${roleID}`,
      // };

      const response = await EKYCWEBHOOK(payLoad);
      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        '_self',
      );
    } catch (error) {
      showToast(
        'ERROR',
        'Something went wrong! Please try again after some time.',
      );
    }
  };

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    setLoading(true);
    try {
      const payLoad = {
        key: 'aadhar_no',
        value: vaultRefNo,
        board_id: 1,
      };

      // const response = await getUserOnVaultReferenceNumber(payLoad);

      // if (response.data.length !== 0) {
      //   setLoading(false);
      //   showToast(
      //     'ERROR',
      //     'Aadhaar already registered with other mobile number',
      //   );

      //   return;
      // }

      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);

      if (aadhaarData.length > 0) {
        const { name } = aadhaarData[0];
        const { gender } = aadhaarData[0];
        const { dob } = aadhaarData[0];
        const _gender = gender === 'M' ? '27' : gender === 'F' ? '28' : '29';
        const { image } = aadhaarData[0];
        const aadhaarName = name;
        const nomineeDetails =
          labourDetails &&
          labourDetails?.family_details?.length > 0 &&
          labourDetails?.family_details?.filter((fam) => fam.is_nominee);

        const nomineeFName = nomineeDetails && nomineeDetails[0].first_name;
        const nomineeMName = nomineeDetails && nomineeDetails[0].middle_name;
        const nomineeLName = nomineeDetails && nomineeDetails[0].last_name;
        const nomineeName = `${nomineeFName} ${nomineeMName ?? ''} ${
          nomineeLName ?? ''
        }`;

        if (
          stringSimilarity(
            mergeNames(nomineeFName, nomineeMName, nomineeLName),
            aadhaarName,
          ) >= 0.75
        ) {
          setLoading(false);
          setAadhaarVerified(true);
          showToast('SUCCESS', 'Name Matched as per the Aadhaar');
        } else {
          setLoading(false);
          setAadhaarVerified(false);
          setOpen(true);
          showToast(
            'ERROR',
            'Name Matching Failed,name did not matched as per the aadhaar',
          );
        }
        const parentChildRelationID = nomineeDetails[0]?.parent_child_user_id;
        const nameMatchingScore =
          stringSimilarity(
            mergeNames(nomineeFName, nomineeMName, nomineeLName),
            aadhaarName,
          ) * 100;

        const updateEKYCPayLoad = {
          labourUserID: +labourUserID,
          parentChildUserID: +parentChildRelationID,
          aadharNo: vaultRefNo,
          nameMatchingScore: nameMatchingScore ? nameMatchingScore : 0,
          schemeID: +schemeID,
        };

        await updateNomineeEKYCVaultRefNumber(updateEKYCPayLoad);
      } else {
        setLoading(false);
        setAadhaarVerified(false);
        showToast('ERROR', 'Aadhaar Verification failed, please try again. ');
      }
    } catch (error) {
      showToast('ERROR', 'Something went wrong');
    }
  };

  useEffect(() => {
    const currentUrl = window.location.toString();
    const queryString = new URLSearchParams(location.search);
    if (currentUrl.includes('Success')) {
      const vaultRefNo = queryString.get('vaultrefno');
      if (vaultRefNo && labourDetails) {
        callGetUserOnVaultReferenceNumber(vaultRefNo);
      }
    } else if (currentUrl.includes('Failure')) {
      showToast('ERROR', 'Aadhaar Verification failed, please try again.');
    }
  }, [location.search, labourDetails]);

  return (
    <>
      {open && <NameMatchingDialog />}
      <div className="root">
        <Row className="top-div ">
          <Row className="topbar-row">
            <Col xs={12} md={4} lg={6} className="top-bar-col">
              <div className="logo-div-profile">
                <a href="/dashboard-user">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </a>
                <h1 className="logo-text">
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value="header.title" /> <br />
                  <Translate value="header.sub" />
                </h1>
              </div>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: 'flex' }}>
              <Select
                className="select-language"
                style={{ width: '100%' }}
                variant="outlined"
                labelId="demo-simple-select-required-label"
                value={translate.lang}
                name="lang"
                displayEmpty
                onChange={handleChange}
              >
                <MenuItem value="">
                  <ListItemIcon>
                    <img alt="..." src={language} className="language-img" />
                  </ListItemIcon>
                  <ListItemText primary="Select Language" />
                </MenuItem>
                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                <MenuItem value="en">
                  <ListItemText primary="English" />
                </MenuItem>
                <MenuItem value="ka">
                  <ListItemText primary="ಕನ್ನಡ" />
                </MenuItem>
              </Select>
            </Col>
            <Col xs={12} md={4} lg={3} style={{ display: 'flex' }}>
              {/* <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                            // onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.user.first_name} />
                                </MenuItem>
                                <MenuItem value="logout" onClick={() => { props.history.push("/") }}>
                                    <ListItemText primary="Logout" />
                                </MenuItem>
                            </Select> */}
              <div className="usericon-header-logout">
                <Link to={'/login'}>
                  <img alt="..." src={usericon} className="usericon-img" />

                  {/* <p>{users.user !== undefined && users.user !== null && users.user.first_name}</p> */}
                  <Tooltip
                    title="Logout"
                    placement="top-start"
                    arrow
                    interactive
                  >
                    <img alt="..." src={logout} className="logout-img" />
                  </Tooltip>
                </Link>
              </div>
            </Col>
          </Row>
        </Row>
      </div>
      <Row className="schemes-title-row">
        <Col xs={12} md={9}>
          {/* <h1>Schemes | {idName}</h1> */}
          <Link
            to={`/scheme/${schemeName}_id=${schemeID}/${roleID}/${totalCountAvailed}/${totalAvailedByUser}`}
            className="cal-icon-hover-icn"
          >
            <img
              alt="..."
              className="cal-icon hover-icn"
              src={back}
              style={{ marginTop: '10px' }}
            />
          </Link>
          <h1>
            {' '}
            <Translate value="schemes" /> |{' '}
            {translate.kannadaSelected
              ? schemes &&
                schemes.length &&
                schemes
                  .filter((scheme) => scheme.name === schemeName)
                  .map((row) => row.name_kannada)
              : schemes &&
                schemes.length &&
                schemes
                  .filter((scheme) => scheme.name === schemeName)
                  .map((row) => row.name)}
          </h1>
        </Col>
      </Row>
      {schemeName === 'Funeral Expense and Death Assistance' && (
        <div className="d-flex justify-content-center align-items-center mt-3">
          <Button
            className={`${
              aadhaarVerified
                ? 'btn bg-success btn-lg'
                : 'btn bg-primary btn-lg'
            }`}
            onClick={handleVerifyAadhaar}
            disabled={aadhaarVerified ? true : false}
          >
            {aadhaarVerified ? <VerifiedIcon /> : <DoubleArrowIcon />}
            <span className="px-2 fs-5 font-weight-bolder">
              {aadhaarVerified
                ? 'Aadhaar Verifed'
                : 'Click here to verify the Nominee Aadhaar'}
            </span>
          </Button>
        </div>
      )}
      {schemeName === 'Funeral Expense and Death Assistance' && loading && (
        <div className="d-flex justify-content-center align-items-center flex-column py-3">
          <CircularProgress color="inherit" />
          <p className="align-self-center py-3 fs-4">
            Please wait verifying aadhaar details....
          </p>
        </div>
      )}
      {schemeName === 'Funeral Expense and Death Assistance' &&
        aadhaarVerified && (
          <>
            <LabourDetailsScheme labourData={labourDetails} />

            <DynamicFields
              labourData={labourDetails}
              kannadaSelected={translate.kannadaSelected}
              schemes={schemes}
              aadhaarVerified={aadhaarVerified}
              aadhaarLoading={loading}
              handleVerifyAadhaar={handleVerifyAadhaar}
            />
          </>
        )}
      {schemeName !== 'Funeral Expense and Death Assistance' && (
        <>
          <LabourDetailsScheme labourData={labourDetails} />

          <DynamicFields
            labourData={labourDetails}
            kannadaSelected={translate.kannadaSelected}
            schemes={schemes}
            aadhaarVerified={aadhaarVerified}
            aadhaarLoading={loading}
            handleVerifyAadhaar={handleVerifyAadhaar}
            death={death}
          />
        </>
      )}
    </>
  );
};

export default NewSchemeForm2;
