import {
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  TextareaAutosize,
} from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { Required } from 'utils/tools';
import mobile from 'assets/images/Phone 1-01.svg';

const EmployerDetails = ({
  fetchEmployerDetails,
  fetchNatureOfWork,
  fetchTypeOfIssuer,
  fetchDistrict,
  fetchTaluk,
  fetchCity,
  fetchVillage,
  handleFormChange,
  employerDetails,
}) => {
  return (
    <>
      <Row className="m-0">
        <Row className="family-title-row">
          <Col
            xs={12}
            className="profile-title"
            style={{ marginBottom: '-20px' }}
          >
            <h1>Details of 90 Days Work Certificate & Employer Details</h1>
          </Col>
        </Row>
      </Row>
      <Row className="family-title-row">
        <Col xs={12} className="family-col-4">
          <>
            <>
              <Row
                className={'form-row employer-card row'}
                style={{ position: 'relative' }}
              >
                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="employerDetails" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.employerStatus || '0'}
                      name="employerStatus"
                      // displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="0">
                        <ListItemText primary="Select" />
                      </MenuItem>
                      {fetchEmployerDetails &&
                        fetchEmployerDetails &&
                        fetchEmployerDetails.map(
                          (row) =>
                            row.value === 'Present' && (
                              <MenuItem value={row.value_id}>
                                <ListItemText
                                  primary={row.value}
                                ></ListItemText>
                              </MenuItem>
                            ),
                        )}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  {/* <p className="mt-3 mb-2">Name of Contractor / Developer :</p> */}
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="nameofContractorDeveloper" />}
                  />
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      variant="outlined"
                      placeholder="Enter Your Employer/Owner Name"
                      name="employerName"
                      value={employerDetails.employerName}
                      onChange={handleFormChange}
                      inputProps={{ minLength: 0, maxLength: 30 }}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="workplaceSiteAddress" />}
                  />
                  <FormControl fullWidth className="formcontrol1">
                    <TextareaAutosize
                      variant="outlined"
                      multiline
                      rows={1}
                      // rowsMax={4}
                      // defaultValue="Default Value"
                      placeholder="Enter Your Workplace/Site Address"
                      name="workplaceSiteAddress"
                      style={{ height: '46px' }}
                      value={employerDetails.workplaceSiteAddress}
                      onChange={handleFormChange}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="contractorCompanyName" />}
                  />
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      variant="outlined"
                      placeholder="Enter Employer/Owner Company Name"
                      name="companyName"
                      value={employerDetails.companyName}
                      onChange={handleFormChange}
                      inputProps={{ minLength: 0, maxLength: 30 }}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="mobileNumberInchargePerson" />}
                  />
                  <FormControl fullWidth className="formcontrol2">
                    <TextField
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <img
                              src={mobile}
                              alt="..."
                              className="phone-icon"
                            />
                            <p className="countrycode">+91</p>
                          </InputAdornment>
                        ),
                      }}
                      variant="outlined"
                      placeholder="987xxxxxxx"
                      name="mobileNumber"
                      value={employerDetails.mobileNumber}
                      onChange={handleFormChange}
                      inputProps={{ minLength: 0, maxLength: 10 }}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="state" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      name="state"
                      value={employerDetails.state}
                      onChange={handleFormChange}
                      displayEmpty
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {
                        <MenuItem value={12}>
                          <ListItemText primary="Karnataka" />
                        </MenuItem>
                      }
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={
                      <Translate value="selectYourDistrictoftheWorkplace" />
                    }
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.district}
                      name="district"
                      // displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {fetchDistrict &&
                        fetchDistrict.length &&
                        fetchDistrict.map((row) => (
                          <MenuItem value={row.id}>
                            <ListItemText primary={row.name}></ListItemText>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="talukoftheWorkplace" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.taluk}
                      name="taluk"
                      // displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {fetchTaluk &&
                        fetchTaluk.length &&
                        fetchTaluk.map((row) => (
                          <MenuItem value={row.id}>
                            <ListItemText primary={row.name}></ListItemText>
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="townCityGramPanchayat" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol1"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.city}
                      name="city"
                      // displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {fetchCity &&
                        fetchCity.length &&
                        fetchCity.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="villageWardCircle" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol1"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.village}
                      name="village"
                      // displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {fetchVillage &&
                        fetchVillage.length &&
                        fetchVillage.map((i) => (
                          <MenuItem value={i.id}>
                            <ListItemText primary={i.name} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="pincode" />}
                  />
                  <FormControl fullWidth className="formcontrol1">
                    <TextField
                      variant="outlined"
                      placeholder="Enter Your Pincode"
                      name="pinCode"
                      value={employerDetails.pinCode}
                      onChange={handleFormChange}
                      inputProps={{ minLength: 0, maxLength: 6 }}
                    />
                  </FormControl>
                </Col>

                <Col xs={12} md={6}>
                  <Required
                    className="mt-3 mb-2"
                    title={<Translate value="natureoftheWorkplace" />}
                  />
                  <FormControl
                    variant="outlined"
                    fullWidth
                    className="formcontrol5"
                  >
                    <Select
                      className="select-marital"
                      value={employerDetails.natureOfWork}
                      name="natureOfWork"
                      displayEmpty
                      onChange={handleFormChange}
                    >
                      <MenuItem value="">
                        <ListItemText primary="-Select-" />
                      </MenuItem>
                      {fetchNatureOfWork &&
                        fetchNatureOfWork.length &&
                        fetchNatureOfWork.map((i) => (
                          <MenuItem value={i.value_id}>
                            <ListItemText primary={i.value} />
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                </Col>
              </Row>
            </>
          </>
        </Col>
      </Row>
    </>
  );
};

export default EmployerDetails;
