import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spinner } from "react-bootstrap";
import { Translate } from "react-redux-i18n";
import { useLocation, useHistory, Link } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import personal from "assets/images/f1-01.svg";
import {
  FormControl,
  TextField,
  InputAdornment,
  RadioGroup,
  FormControlLabel,
  Radio,
  Tooltip,
} from "@material-ui/core";
import CircularProgress from "@mui/material/CircularProgress";
import { Backdrop, Paper, makeStyles } from "@material-ui/core";
import { Typography } from "@mui/material";
import { AddCircleRounded, CheckCircle } from "@material-ui/icons";
import {
  EKYCWEBHOOK,
  getAadhaarDetails,
  getUserOnVaultReferenceNumber,
} from "../../components/SevaSindhu/SevaSindhuServices/Aadhaar.service";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import { getTokenCookie, showToast } from "utils/tools";
import { SERVER } from "store/actions/user.actions";
import axios from "axios";
import PersonalDetailsFromSevasindhu from "./LabourDetailsFromSevasindhu";
import Aadhaar from "components/SevaSindhu/Aadhaar/Aadhaar";
import stringSimilarity from "utils/nameMatching";
import moment from "moment";
import SuccessBackdrop from "components/SevaSindhu/SuccessBackdrop";

const SevasindhuLabourDetails = () => {
  const location = useLocation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [aadhaarLoading, setAadhaarLoading] = useState(false);
  const [aadharVerified, setAadhaarVerified] = useState(false);
  const [labourDetails, setLabourDetails] = useState(null);
  const [vaultReferenceNumber, setVaultReferenceNumber] = useState(null);
  const [open, setOpen] = useState(true);

  const [aadhaarData, setAadhaarData] = useState(null);

  const [submitSuccess, setSubmitSuccess] = useState(false);

  const [applicantName, setApplicantName] = useState(null);
  const [DOB, setDOB] = useState(null);
  const [genderID, setGenderID] = useState(null);
  const [userPhotoID, setUserPhotoID] = useState(null);
  const [isFileUploadedId, setIsFileUploadedId] = useState(false);
  const [surveyResponse, setSurveyResponse] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (labourDetails) console.log(labourDetails);

    console.log(aadhaarData);
  }, [labourDetails, aadhaarData]);

  const queryParams = new URLSearchParams(location.search);

  const registrationNumber = queryParams.get("registrationNumber");
  const referenceNumber = queryParams.get("referenceNumber");
  const mobileNumber = queryParams.get("mobileNumber");

  const insertAndUpdateSurveyDetails = async () => {
    try {
      setIsSubmitting(true);
      const response = await axios.post(`${SERVER}/survey/insert-data`, {
        ss_user_id: labourDetails?.id,
        registrationNo: labourDetails?.regno,
        submissionLocation: labourDetails?.submissionlocation,
        labour_card_file_id: isFileUploadedId,
        refNo: labourDetails?.spdv_appl_ref_no,
        phoneNo: mobileNumber,
        name_ss: labourDetails.applicantname,
        dob_ss: moment(labourDetails.dob).format("YYYY-MM-DD"),
        gender_ss:
          labourDetails?.gender == 1
            ? "Male"
            : labourDetails?.gender == 2
              ? "Female"
              : "",
        vault_ref_no: vaultReferenceNumber,
        name_aadhaar: aadhaarData?.name,
        dob_aadhaar: moment(aadhaarData?.dob).format("YYYY-MM-DD"),
        gender_aadhaar: aadhaarData.gender,
        dob_match_status:
          moment(aadhaarData?.dob).format("YYYY-MM-DD") ===
          moment(labourDetails.dob).format("YYYY-MM-DD")
            ? 1
            : 0,
      });
      console.log(response.data);
      setIsSubmitting(false);
      setSurveyResponse(response.data?.data?.[0]);
      showToast("SUCCESS", "Aadhaar Validated Successfully");
      setSubmitSuccess(true);
    } catch (error) {
      const errorMsg =
        error?.response?.data?.errors?.msg || "Something went wrong";
      showToast("ERROR", errorMsg);
      setSubmitSuccess(false);
      setIsSubmitting(false);
    } finally {
      setIsSubmitting(false);
      setLoading(false);
    }
  };

  const handleVerifyAadhaar = async () => {
    try {
      setAadhaarLoading(true);
      //Production Data

      // const payLoad = {
      //   responseRedirectURL: ` https://kbocwwb.karnataka.gov.in/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${isFileUploadedId}`,
      // };

      // Local Production Data

      // const payLoad = {
      //   responseRedirectURL: `http://localhost:3000/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${isFileUploadedId}`,
      // };

      // UAT
      const payLoad = {
        responseRedirectURL: `https://kbocwwb.metahos.com/seva-sindhu-survey?registrationNumber=${registrationNumber}&referenceNumber=${referenceNumber}&mobileNumber=${mobileNumber}&fileId=${isFileUploadedId}`,
      };

      const response = await EKYCWEBHOOK(payLoad);
      console.log(response);
      const { token } = response.data;
      // const {token} = response.data.token;

      // window.open(
      //   `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
      //   '_self',
      // );
      setAadhaarLoading(false);
      window.open(
        `https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token=${token}`,
        "_self"
      );
    } catch (error) {
      showToast(
        "ERROR",
        "Something went wrong! Please try again after some time."
      );
    }
  };

  const callGetUserOnVaultReferenceNumber = async (vaultRefNo) => {
    setAadhaarLoading(true);
    try {
      const { data: aadhaarData } = await getAadhaarDetails(vaultRefNo);
      setAadhaarData(aadhaarData[0]);

      if (aadhaarData.length > 0) {
        setAadhaarLoading(false);
        setUserPhotoID(aadhaarData[0]?.image || null);
        setAadhaarVerified(true);
      } else {
        setAadhaarLoading(false);
        setAadhaarVerified(false);
        showToast("ERROR", "Aadhaar Verification failed, please try again. ");
      }
    } catch (error) {
      setAadhaarLoading(false);
      setAadhaarVerified(false);
      showToast("ERROR", "Something went wrong");
    }
  };

  useEffect(() => {
    const currentUrl = window.location.toString();
    const queryString = new URLSearchParams(location.search);
    if (currentUrl.includes("Success")) {
      const vaultRefNo = queryString.get("vaultrefno");
      const fileId = queryString.get("fileId");
      if (fileId) {
        setIsFileUploadedId(fileId);
      }
      if (vaultRefNo) {
        console.log("klasdjfaj", vaultRefNo);
        setVaultReferenceNumber(vaultRefNo);
        callGetUserOnVaultReferenceNumber(vaultRefNo);
      }
    } else if (currentUrl.includes("Failure")) {
      showToast("ERROR", "Aadhaar Verification failed, please try again.");
    }
  }, [location.search]);

  const getLabourDetails = async () => {
    if (registrationNumber && referenceNumber) {
      setLoading(true);
      try {
        const response = await axios.post(
          `${SERVER}/seva_sindhu/check_user_in_seva_sindhu_for_survey`,
          {
            registration_number: registrationNumber,
            application_reference_number: referenceNumber,
          }
        );

        if (response.data.data.length > 0) {
          setLabourDetails(response.data.data[0]?.[0]);
        } else {
          showToast("ERROR", "No records found");
        }
      } catch (error) {
        const errorMsg =
          error?.response?.data?.errors?.msg || "Something went wrong";
        showToast("ERROR", errorMsg);
      } finally {
        setLoading(false);
      }
    } else {
      showToast("ERROR", "Invalid details provided");
      history.push("/survey");
    }
  };

  const handleClose = () => {
    history.push("/");
    setOpen(false);
  };

  useEffect(() => {
    getLabourDetails();
  }, []);

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffff",
      opacity: "0.5",

      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));
  const classes = useStyles();

  return (
    <>
      {submitSuccess ? (
        <Backdrop
          className={classes.backdrop}
          open={open}
          onClick={handleClose}
        >
          <div style={{ display: "block" }}>
            <CheckCircle
              className={classes.backdropCheck}
              style={{ height: "auto", width: "200px" }}
            />
            <div style={{ display: "flex", flexDirection: "column" }}>
              <p className='final-success-title'>
                Aadhaar Verified Successfully
              </p>
              <p style={{ fontSize: 22, color: "#01a301" }}>
                Verification Token No:{" "}
                <span style={{ textDecoration: "underline" }}>
                  {surveyResponse?.tokenNo || ""}
                </span>
              </p>
              <Link>
                <Typography variant='h5'>Back to Home</Typography>
              </Link>
            </div>
          </div>
        </Backdrop>
      ) : null}

      <Row className='top-div'>
        <Row className='topbar-row'>
          <Col xs={12} md={4} lg={6} className='top-bar-col'>
            <div className='logo-div-profile'>
              <a href='/login'>
                <img
                  id='logo'
                  src={logo}
                  alt='Karnataka Logo'
                  className='logo-img'
                />
              </a>
              <h1 className='logo-text'>
                <Translate value='header.commonTitle' />
                <br />
                <Translate value='header.subcommonTitle' />
              </h1>
            </div>
          </Col>
          <div className='d-flex justify-content-end'>
            <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
              {/* Language selection component can be added here */}
            </Col>
          </div>
        </Row>
      </Row>
      {loading && (
        <div
          style={{ height: "80vh" }}
          className='d-flex align-items-center justify-content-center'
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <div>
              <Spinner animation='border' variant='primary' />
            </div>
            <div>Loading labour details...</div>
          </div>
        </div>
      )}
      {labourDetails && !loading && (
        <>
          <Row className='mx-2'>
            <Col md={6}>
              <PersonalDetailsFromSevasindhu
                labourData={labourDetails}
                setIsFileUploadedId={setIsFileUploadedId}
                fileUploadId={isFileUploadedId}
                aadharVerified={aadharVerified}
              />
            </Col>
            <Col md={6}>
              <Row className='final all-details-subtitle-row'>
                <Col xs={12} md={12} className='profile-title'>
                  <img alt='...' src={personal} />
                  <h1>Personal Details as per Aadhaar</h1>
                </Col>
              </Row>
              {aadharVerified && aadhaarData ? (
                <div>
                  <Row>
                    <div className='d-flex justify-content-center align-items-center my-3'>
                      {userPhotoID ? (
                        <img
                          style={{
                            border: "1px solid #ddd",
                            borderRadius: "4px",
                            padding: "5px",
                          }}
                          src={`${SERVER}/upload/v2/file/${userPhotoID}?token=${getTokenCookie()}`}
                          width={250}
                          height={250}
                          alt='userPhoto'
                        />
                      ) : (
                        <CircularProgress />
                      )}
                    </div>
                  </Row>
                  <Row className='px-5'>
                    <Col md={12} className='final-personal-col-1 px-3'>
                      <p className='mb-0'>Name</p>
                      <FormControl fullWidth>
                        <TextField
                          name='firstname'
                          value={`${aadhaarData?.name || ""}`}
                          disabled
                        />
                      </FormControl>
                    </Col>

                    <Col md={12} className='final-personal-col-1 px-3'>
                      <p className='mb-0'>Age </p>
                      <FormControl fullWidth className='formcontrol2'>
                        <TextField disabled value={aadhaarData?.age || ""} />
                      </FormControl>
                    </Col>
                  </Row>

                  <Row className='px-5 mt-3'>
                    <Col md={12} className='final-personal-col-1 px-3'>
                      <p className='mb-0'>Date Of Birth</p>
                      <FormControl fullWidth>
                        <TextField
                          name='dob'
                          value={`${
                            moment(aadhaarData?.dob).format("DD-MM-YYYY") || ""
                          }`}
                          disabled
                        />
                      </FormControl>
                    </Col>
                    <Col md={12} className='final-personal-col-1 px-3'>
                      <p className='mb-0'>Gender</p>
                      <FormControl fullWidth>
                        <TextField
                          name='dob'
                          value={`${aadhaarData?.gender}`}
                          disabled
                        />
                      </FormControl>
                    </Col>
                  </Row>
                </div>
              ) : (
                <Col>
                  <Tooltip
                    title={"Please Upload Labour Card to Verify the Aadhaar"}
                    arrow
                    placement='top'
                    disableHoverListener={isFileUploadedId}
                  >
                    <div className='d-flex justify-content-center align-items-center py-2'>
                      <Button
                        className={`${
                          aadharVerified
                            ? "btn bg-success btn-lg"
                            : "btn bg-primary btn-lg"
                        }`}
                        onClick={handleVerifyAadhaar}
                        disabled={
                          aadharVerified || !isFileUploadedId || aadhaarLoading
                        }
                      >
                        {aadharVerified ? (
                          <VerifiedIcon />
                        ) : (
                          <DoubleArrowIcon />
                        )}
                        <span className='px-2 fs-5 font-weight-bolder'>
                          {aadharVerified
                            ? "Aadhaar Verifed"
                            : "Click here to verify the Aadhaar"}
                        </span>
                      </Button>
                    </div>
                  </Tooltip>
                </Col>
              )}
            </Col>
          </Row>

          {aadharVerified && (
            <Row className='mb-3'>
              <Col className='d-flex justify-content-center mt-4'>
                <Button
                  onClick={insertAndUpdateSurveyDetails}
                  size='lg'
                  variant='success'
                  style={{ width: "60vw" }}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Submitting..." : "Submit"}
                </Button>
              </Col>
            </Row>
          )}
        </>
      )}
    </>
  );
};

export default SevasindhuLabourDetails;
