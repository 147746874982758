import 'date-fns';
import { TableContainer, Table, TableBody, TableRow, TableCell, TableHead, TableFooter, TablePagination, makeStyles, useTheme, FormControl, Select, ListItemText, MenuItem } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import { useDispatch, useSelector } from 'react-redux';
import { deleteReduxData, getDistrictDetails,getTalukDetails, getStateDetails } from 'store/actions/user.actions'
import { Link } from 'react-router-dom';

import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { Required } from 'utils/tools';


const MinistryMaster = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        state_details: "",
        state: "",
        district_details: "",
        district: "",
        taluk_details: ""
    })

    const [rows, setrows] = React.useState("");


    useEffect(() => {

        setrows("")

        dispatch(deleteReduxData("getStateDetails"))
        dispatch(deleteReduxData("getDistrictDetails"))
        dispatch(deleteReduxData("getTalukDetails"))

        dispatch(getStateDetails())


    }, []);

    useEffect(() => {
    
        console.log("allValues.state: "+allValues.state)
        if(allValues.state === ""){
            setrows("")
        }
    
    }, [allValues.state]);

    useEffect(() => {
        console.log("allValues: ")
        console.log(JSON.stringify(allValues, undefined,2))
    }, [allValues]);

    useEffect(() => {
        
        console.log("users.state_details: "+JSON.stringify(users.state_details))

        if(
            users.state_details !== undefined &&
            users.state_details !== null &&
            users.state_details !== ""
            ){
                setAllValues({
                    ...allValues,
                    state_details: users.state_details,
                })   

        }

    }, [users.state_details])

    useEffect(() => {

        if(
            allValues.state !== undefined &&
            allValues.state !== null &&
            allValues.state !== ""
            ){
                
                dispatch(getDistrictDetails(allValues.state))

        }

    }, [allValues.state])

    useEffect(() => {
        
        console.log("users.district_details: "+JSON.stringify(users.district_details))

        if(
            users.district_details !== undefined &&
            users.district_details !== null &&
            users.district_details !== ""
            ){
                setrows(users.district_details)
                setAllValues({
                    ...allValues,
                    district_details: users.district_details,
                })   

        }

    }, [users.district_details])

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const useStyles1 = makeStyles((theme) => ({
        root: {
            flexShrink: 0,
            marginLeft: theme.spacing(2.5),
        },
    }));

    function TablePaginationActions(props) {
        const classes = useStyles1();
        const theme = useTheme();
        const { count, page, rowsPerPage, onChangePage } = props;

        const handleBackButtonClick = (event) => {
            onChangePage(event, page - 1);
        };

        const handleNextButtonClick = (event) => {
            onChangePage(event, page + 1);
        };

        return (
            <div className={classes.root}>
                
                <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
               
            </div>
        );
    }

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                    Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)<br />
                                    Government of Karnataka
                                </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <a  href="/admin/mapping-master-district-form" >
                                <div className="add-div">
                                    <img alt="..." className="cal-icon" src={add} />
                                    <p>Add District</p>
                                </div>
                            </a>
                        </Col>
                    </Row>

                </Row>
            </div>

            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    {/* <Link to="/admin/home"> */}
                    <Link to="#" onClick={() => props.history.goBack()}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                    </Link>
                    <p className="title-p">Mapping Master {'>'} District</p>
                </Col>
            </Row>


            <Row className="form-row mt-4 ">
                <Col xs={12} md={4} className="personal-col-1">
                    <Required className="mb-2" title="State" />
                    <FormControl fullWidth className="formcontrol1">
                        <Select
                            className="select-marital pl-2"
                            labelId="demo-simple-select-required-label"
                            id="demo-simple-select-required"
                            value={allValues.state}
                            name="state"
                            displayEmpty
                            onChange={handleChange}
                        >
                            <MenuItem value="">
                                <ListItemText primary="Select" />
                            </MenuItem>
                            {
                                allValues.state_details !== undefined &&
                                allValues.state_details !== null &&
                                allValues.state_details !== "" &&
                                allValues.state_details.map((i) =>
                                    <MenuItem value={i.id}>
                                        <ListItemText primary={i.name} />
                                    </MenuItem>
                                )}
                        </Select>
                    </FormControl>
                </Col>
                
            </Row>


            <Row className="config-page">

                <Col xs={12} className="table-col">

                    <TableContainer
                    // component={Paper}
                    >
                        <Table
                            className="mt-4 config-table"
                            aria-label="simple table"
                        >
                            <TableHead>
                                <TableRow>
                                    <TableCell align="right" width="190px">
                                        <Link to="/admin/home">
                                            <img alt="..." className="cal-icon hover-icn" src={home} />
                                        </Link>
                                    </TableCell>
                                    <TableCell align="left" width="240px">District Name</TableCell>
                                    <TableCell align="center">State</TableCell>
                                    <TableCell align="center">Options</TableCell>
                                    <TableCell width="200pxpx"></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    allValues.state !== "" &&
                                    rows !== "" &&
                                    (rowsPerPage > 0
                                        ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                        : rows
                                    ).map((row) => (
                                        <TableRow key={row.id}>
                                            {/* <TableCell></TableCell> */}
                                            <TableCell></TableCell>
                                            <TableCell align="left">{row.name}</TableCell>
                                            <TableCell align="center">{row.state_name}</TableCell>
                                            <TableCell align="center">
                                                <Link to={{
                                                    pathname: "/admin/mapping-master-district-form",
                                                    rowdata: row,
                                                    state: allValues.state
                                                }}
                                                    className="td-link"
                                                >
                                                    <img alt="..." src={Edit} className="mr-2" /> Edit
                                                </Link>
                                            </TableCell>
                                            <TableCell></TableCell>
                                        </TableRow>
                                    ))}

                                {emptyRows > 0 && (
                                    <TableRow style={{ height: 53 * emptyRows }}>
                                        <TableCell colSpan={6} />
                                    </TableRow>
                                )}
                            </TableBody>

                            <TableFooter>
                                {/* <TableRow className="table-pagination-footer-row">
                                    <TableCell colSpan="3">
                                        <img alt="..." src={prev} className="mr-2"/>
                                        <p>Page 1 of 4</p>
                                        <img alt="..." src={next} className="mr-2"/>
                                    </TableCell>
                                </TableRow> */}
                                <TableRow>
                                    <TablePagination
                                        className="table-pagination-footer"
                                        rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                                        // colSpan={6}
                                        count={rows.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        // SelectProps={{
                                        //     inputProps: { 'aria-label': 'rows per page' },
                                        //     // native: true,
                                        // }}
                                        labelRowsPerPage={""}
                                        onChangePage={handleChangePage}
                                        onChangeRowsPerPage={handleChangeRowsPerPage}
                                        ActionsComponent={TablePaginationActions}
                                    />

                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                </Col>

            </Row>
        </>
    )
}

export default MinistryMaster;