import React, { useState, useEffect } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { SERVER, getDeptUserRole } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import {
  Container,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ReportDashboard from './ReportDashboard';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Col, Row } from 'react-bootstrap';
import {
  ListItemText,
  withStyles,
  Menu,
  CircularProgress,
  TextField,
  InputAdornment,
  Backdrop,
  makeStyles,
  FormControl,
  Grid,
} from '@material-ui/core';
import CountReport from './CountReports';
import { CSVLink } from 'react-csv';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { useRef } from 'react';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

export default function CountSchemeReport() {
  const [fetchRegistrationDistrictReport, setFetchRegistrationDistrictReport] =
    useState({});
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState('');
  const users = useSelector((state) => state.users);
  const [fetchRegistrationTalukReport, setFetchRegistrationTalukReport] =
    useState({});
  const [fetchRegistrationCircleReport, setFetchRegistrationCircleReport] =
    useState({});
  const [showDistrictTable, setShowDistrictTable] = useState(false);
  const [showTalukTable, setShowTalukTable] = useState(false);
  const [showCircleTable, setShowCircleTable] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [reportSelect, setReportSelect] = useState('');
  const [showRegistrationReports, setRegistrationReports] = useState('');
  const [showRenewalReports, setRenewalReports] = useState('');
  const [showSchemeReports, setSchemeReports] = useState('');
  const [showTypeDropDown, setShowTypeDropDown] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState('All Schemes');
  const [showTypeBackButton, setShowTypeBackButton] = useState(true);
  const [isSchemeTypeSelected, setIsSchemeTypeSelected] = useState(true);
  const [loadingOptions, setLoadingOptions] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  const [fetchRegistrationSchemeReport, setFetchRegistrationSchemeReport] =
    useState({});
  const [showSchemeTable, setShowSchemeTable] = useState(true);

  const schemeNames = Array.from(
    new Set(
      fetchRegistrationSchemeReport.data?.map((item) => item.scheme_name),
    ),
  );

  const filteredData = fetchRegistrationSchemeReport.data?.filter((item) => {
    console.log('selectedScheme ' + selectedScheme);
    return (
      selectedScheme === 'All Schemes' || item.scheme_name === selectedScheme
    );
  });

  //SchemeChange handle
  const handleSchemeChange = (e) => {
    console.log('Scheme changed ', e.target.value);
    setSelectedScheme(e.target.value);
    setIsSchemeTypeSelected(true);
  };

  //CSV Headers for Schemes
  const csvSchemeHeaders = [
    { label: 'Scheme Name', key: 'scheme_name' },
    { label: 'Transferred Scheme Application', key: 'transferred' },
    { label: 'Total', key: 'total' },
    { label: 'Forwarded', key: 'forwarded' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Headers for district
  const csvHeaders = [
    { label: 'District', key: 'district' },
    { label: 'Scheme Name', key: 'scheme_name' },
    { label: 'Transferred Scheme Application', key: 'transferred' },
    { label: 'Total', key: 'total' },
    { label: 'Forwarded', key: 'forwarded' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Headers for Taluk
  const csvHeaders_taluk = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Scheme Name', key: 'scheme_name' },
    { label: 'Transferred Scheme Application', key: 'transferred' },
    { label: 'Total', key: 'total' },
    { label: 'Forwarded', key: 'forwarded' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV headers for Circle
  const csvHeaders_circle = [
    { label: 'Circle', key: 'circle' },
    { label: 'Scheme Name', key: 'scheme_name' },
    { label: 'Transferred Scheme Application', key: 'transferred' },
    { label: 'Total', key: 'total' },
    { label: 'Forwarded', key: 'forwarded' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Data FOr district
  const prepareCSVData = (data) => {
    const csvData = [];
    console.log('preparing csv data ', data);
    ((data && data.data) || []).forEach((districtData) => {
      csvData.push({
        district: districtData.district || '',
        scheme_name: districtData.scheme_name || '',
        transferred: districtData['Transferred Scheme Application'] || 0,
        total: districtData.total || 0,
        forwarded: districtData.Forwarded || 0,
        approved: districtData.Approved || 0,
        rejected: districtData.Rejected || 0,
        pending: districtData.Pending || 0,
      });
    });
    csvData.push({
      district: 'Grand Totals',
      transferred: districtTotals.transfered,
      total: districtTotals.total || 0,
      forwarded: districtTotals.forwarded || 0,
      approved: districtTotals.approved || 0,
      rejected: districtTotals.rejected || 0,
      pending: districtTotals.pending || 0,
    });
    return csvData;
  };

  //CSV data for Schemes
  const prepareSchemeCSVData = (data) => {
    const csvData = [];
    (data || []).forEach((schemeData) => {
      csvData.push({
        scheme_name: schemeData.scheme_name || '',
        transferred: schemeData['Transferred Scheme Application'] || 0,
        total: schemeData.total || 0,
        forwarded: schemeData.Forwarded || 0,
        approved: schemeData.Approved || 0,
        rejected: schemeData.Rejected || 0,
        pending: schemeData.Pending || 0,
      });
    });
    csvData.push({
      scheme_name: 'Grand Totals',
      transferred: schemeTotals.transfered,
      total: schemeTotals.total || 0,
      forwarded: schemeTotals.forwarded || 0,
      approved: schemeTotals.approved || 0,
      rejected: schemeTotals.rejected || 0,
      pending: schemeTotals.pending || 0,
    });

    return csvData;
  };

  //CSV data for taluk
  const prepareTalukCSVData = (data) => {
    const csvData = [];
    ((data && data.data) || []).forEach((talukData) => {
      csvData.push({
        taluk: talukData.taluk || '',
        scheme_name: talukData.scheme_name || '',
        transferred: talukData['Transferred Scheme Application'] || 0,
        total: talukData.total || 0,
        forwarded: talukData.Forwarded || 0,
        approved: talukData.Approved || 0,
        rejected: talukData.Rejected || 0,
        pending: talukData.Pending || 0,
      });
    });
    csvData.push({
      taluk: 'Grand Totals',
      transferred: talukTotals.transfered,
      total: talukTotals.total || 0,
      forwarded: talukTotals.forwarded || 0,
      approved: talukTotals.approved || 0,
      rejected: talukTotals.rejected || 0,
      pending: talukTotals.pending || 0,
    });
    return csvData;
  };

  //CSV data for Circle
  const prepareCircleCSVData = (data) => {
    const csvData = [];
    ((data && data.data) || []).forEach((circleData) => {
      csvData.push({
        circle: circleData.circle_name || '',
        scheme_name: circleData.scheme_name || '',
        transferred: circleData['Transferred Scheme Application'] || 0,
        total: circleData.total || 0,
        forwarded: circleData.Forwarded || 0,
        approved: circleData.Approved || 0,
        rejected: circleData.Rejected || 0,
        pending: circleData.Pending || 0,
      });
    });
    csvData.push({
      circle: 'Grand Totals',
      transferred: circleTotals.transfered,
      total: circleTotals.total || 0,
      forwarded: circleTotals.forwarded || 0,
      approved: circleTotals.approved || 0,
      rejected: circleTotals.rejected || 0,
      pending: circleTotals.pending || 0,
    });
    return csvData;
  };

  //CircleAPI
  const handleCircleClick = async (districtId, talukId, schemeId) => {
    try {
      const circlePayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'CIRCLE',
        schemeId: schemeId,
        districtId: districtId,
        talukId: talukId,
        circleId: null,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/scheme`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: circlePayload,
      };

      const circleResponse = await axios(config);
      setFetchRegistrationCircleReport(circleResponse.data);
      setShowTalukTable(false);
      setShowCircleTable(true);
    } catch (error) {
      console.log('Error fetching circle data:', error);
    }
  };

  //Scheme API
  const handleSchemeClick = async (schemeId) => {
    try {
      const schemePayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'DISTRICT',
        schemeId: schemeId,
        districtId: null,
        talukId: null,
        circleId: null,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };
      const schemeConfig = {
        method: 'POST',
        url: `${SERVER}/reports/count/scheme`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: schemePayload,
      };
      const schemeResponse = await axios(schemeConfig);
      setFetchRegistrationDistrictReport(schemeResponse.data);
      setShowSchemeTable(false);
      setShowDistrictTable(true);
      //setShowTalukTable(false);
    } catch (error) {
      console.log('Error fetching taluk data:', error);
    }
  };

  //Taluk API
  const handleTalukClick = async (districtID, schemeId) => {
    try {
      console.log('scheme id recevied in handletalukdata ', schemeId);
      const talukPayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'TALUK',
        schemeId: schemeId,
        districtId: districtID,
        talukId: null,
        circleId: null,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };
      const talukConfig = {
        method: 'POST',
        url: `${SERVER}/reports/count/scheme`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: talukPayload,
      };
      const talukResponse = await axios(talukConfig);
      setFetchRegistrationTalukReport(talukResponse.data);
      //setShowSchemeTable(false);
      setShowDistrictTable(false);
      setShowTalukTable(true);
    } catch (error) {
      console.log('Error fetching taluk data:', error);
    }
  };

  //Back button
  const handleBack = () => {
    setShowDashboard(true);
    setShowTypeBackButton(false);
    setShowSchemeTable(false);
    setShowDistrictTable(false);
    setShowTalukTable(false);
    setShowCircleTable(false);
  };

  //District API
  const fetchData = async () => {
    setLoading(true);
    try {
      setLoadingOptions(true);
      console.log('fetching data');

      const payLoad = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'SCHEME',
        schemeId: null,
        districtId: null,
        talukId: null,
        circleId: null,

        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/scheme`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payLoad,
      };

      const response = await axios(config);
      setFetchRegistrationSchemeReport(response.data);
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    } finally {
      setLoadingOptions(false);
    }
  };

  //Fetching All schemes at first render
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      //(loaded for the first time)
      fetchData();
      isFirstRun.current = false;
    } else {
      if (startDate && endDate) {
        setIsDateSelected(1);
      }
    }
  }, [startDate, endDate]);

  //For Calculating Totals in Table
  const calculateTotals = (data) => {
    let total = 0;
    let approved = 0;
    let rejected = 0;
    let pending = 0;
    let forwarded = 0;
    let transfered = 0;

    ((data && data.data) || []).forEach((district) => {
      if (
        selectedScheme === 'All Schemes' ||
        district.scheme_name === selectedScheme
      ) {
        total += district.total || 0;
        forwarded += district.Forwarded || 0;
        transfered += district['Transferred Scheme Application'] || 0;
        approved += district.Approved || 0;
        rejected += district.Rejected || 0;
        pending += district.Pending || 0;
      }
    });

    return { transfered, total, forwarded, approved, rejected, pending };
  };

  const schemeTotals = calculateTotals(fetchRegistrationSchemeReport);
  const districtTotals = calculateTotals(fetchRegistrationDistrictReport);
  const talukTotals = calculateTotals(fetchRegistrationTalukReport);
  const circleTotals = calculateTotals(fetchRegistrationCircleReport);

  console.log(moment(startDate).format(), 'startDate');

  return (
    <>
      {!showDashboard && !showTypeDropDown && (
        <div
          style={{
            padding: '10px 15px',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <button
            style={{
              background: 'transparent',
              outline: 'none',
              border: 'none',
            }}
            onClick={handleBack}
          >
            <img alt="..." className="cal-icon hover-icn" src={back} />
          </button>
          {showSchemeTable && (
            <>
              <>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <Col
                    xs={2}
                    className="benificiaries-col "
                    justifyContent="center"
                  >
                    <div
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      className="benificiaries-col-div hvr-icon-hang"
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="datepicker"
                          margin="normal"
                          format="DD/MM/YYYY"
                          value={startDate}
                          placeholder="Start Date"
                          onChange={(ev) => setStartDate(ev)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Col>
                  <Col
                    xs={2}
                    className="benificiaries-col "
                    justifyContent="center"
                  >
                    <div
                      aria-controls="customized-menu"
                      aria-haspopup="true"
                      className="benificiaries-col-div hvr-icon-hang"
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          className="datepicker"
                          margin="normal"
                          format="DD/MM/YYYY"
                          value={endDate}
                          placeholder="End Date"
                          onChange={(ev) => setEndDate(ev)}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </Col>
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '10vh',
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={fetchData}
                  >
                    Confirm
                  </Button>
                </div>
              </>
            </>
          )}
        </div>
      )}

      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        ></Box>

        {showTypeBackButton && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTypeDropDown(true);
            }}
          >
            Back to Select Reports Type
          </Button>
        )}

        {showSchemeTable && !showTypeDropDown && (
          <div>
            {/* Dropdown for selecting scheme */}
            <div style={{ marginBottom: '10px', textAlign: 'center' }}>
              <FormControl
                sx={{ minWidth: '500px', margin: '0 auto', marginTop: '10px' }}
              >
                <InputLabel id="scheme-select-label">Select Scheme</InputLabel>
                <Select
                  className="select-marital"
                  style={{ border: '1px solid grey', width: '400px' }}
                  MenuProps={{
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'left',
                    },
                    transformOrigin: {
                      vertical: 'top',
                      horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                  }}
                  labelId="scheme-select-label"
                  id="scheme-select"
                  value={selectedScheme}
                  label="Select scheme"
                  placeholder="Select Scheme"
                  onChange={handleSchemeChange}
                >
                  <MenuItem value="All Schemes">All Schemes</MenuItem>
                  {loadingOptions ? (
                    <MenuItem disabled>Loading...</MenuItem>
                  ) : (
                    schemeNames.map((scheme, index) => (
                      <MenuItem key={index} value={scheme}>
                        {scheme}
                      </MenuItem>
                    ))
                  )}
                </Select>
              </FormControl>

              {/* SCHEMES TABLE */}
              {isSchemeTypeSelected && !showTypeDropDown && (
                <div>
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }}>
                      <TableHead>
                        <TableCell align="center">Scheme Name</TableCell>
                        <TableCell align="center">
                          Transferred Scheme Application
                        </TableCell>
                        <TableCell align="center">Total</TableCell>
                        <TableCell align="center">Forwarded</TableCell>
                        <TableCell align="center">Approved</TableCell>
                        <TableCell align="center">Rejected</TableCell>
                        <TableCell align="center">Pending</TableCell>
                      </TableHead>

                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              align="center"
                              style={{ color: 'red', fontWeight: 'bold' }}
                            >
                              Loading...
                            </TableCell>
                          </TableRow>
                        ) : (filteredData || []).length === 0 ? (
                          <TableRow>
                            <TableCell
                              colSpan={6}
                              align="center"
                              style={{
                                color: 'blue',
                                fontWeight: 'bold',
                                justifyConten: 'center',
                              }}
                            >
                              No Data Found
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {(filteredData || []).map((schemeData) => (
                              <TableRow key={schemeData.scheme_id}>
                                <TableCell
                                  align="center"
                                  onClick={() =>
                                    handleSchemeClick(schemeData.scheme_id)
                                  }
                                  style={{ cursor: 'pointer' }}
                                >
                                  {schemeData.scheme_name}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData[
                                    'Transferred Scheme Application'
                                  ] || 0}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData.total || 0}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData.Forwarded || 0}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData.Approved || 0}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData.Rejected || 0}
                                </TableCell>
                                <TableCell align="center">
                                  {schemeData.Pending || 0}
                                </TableCell>
                              </TableRow>
                            ))}
                            <TableRow>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                Grand Total
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.transfered}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.total}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.forwarded}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.approved}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.rejected}
                              </TableCell>
                              <TableCell
                                align="center"
                                style={{ fontWeight: 'bold' }}
                              >
                                {schemeTotals.pending}
                              </TableCell>
                            </TableRow>
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <div style={{ marginTop: '10px', textAlign: 'center' }}>
                    \{/* Download button for Schemes */}
                    <CSVLink
                      data={prepareSchemeCSVData(filteredData)}
                      headers={csvSchemeHeaders}
                      filename={'scheme_data.csv'}
                      className="btn btn-primary"
                      target="_blank"
                    >
                      Download
                    </CSVLink>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        {/* DISTRICT TABLE */}
        {showDistrictTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">District</TableCell>
                  <TableCell align="center">Scheme Name</TableCell>
                  <TableCell align="center">
                    Transfered Scheme Application
                  </TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Forwarded</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    (fetchRegistrationDistrictReport.data || []).map(
                      (districtData) => {
                        return (
                          <TableRow key={districtData.districtId}>
                            <TableCell
                              align="center"
                              onClick={() =>
                                handleTalukClick(
                                  districtData.district_id,
                                  districtData.scheme_id,
                                )
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              {districtData.district}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.scheme_name}
                            </TableCell>
                            <TableCell align="center">
                              {districtData['Transferred Scheme Application'] ||
                                0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.total || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Forwarded || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Approved || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Rejected || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Pending || 0}
                            </TableCell>
                          </TableRow>
                        );
                      },
                    )
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.transfered}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.forwarded}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {districtTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button for DISTRICT*/}
              <CSVLink
                data={prepareCSVData(fetchRegistrationDistrictReport)}
                headers={csvHeaders}
                filename={'scheme_district_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Taluk Table */}
        {showTalukTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">Taluk</TableCell>
                  <TableCell align="center">Scheme Name</TableCell>
                  <TableCell align="center">
                    Transfered Scheme Application
                  </TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Forwarded</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    (fetchRegistrationTalukReport.data || []).map((taluk) => {
                      return (
                        <TableRow key={taluk.taluk_id}>
                          <TableCell
                            align="center"
                            onClick={() =>
                              handleCircleClick(
                                taluk.district_id,
                                taluk.taluk_id,
                                taluk.scheme_id,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {taluk.taluk}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.scheme_name}
                          </TableCell>
                          <TableCell align="center">
                            {taluk['Transferred Scheme Application'] || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Forwarded || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Rejected || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Pending || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.transfered}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.forwarded}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button for Taluk*/}
              <CSVLink
                data={prepareTalukCSVData(fetchRegistrationTalukReport)}
                headers={csvHeaders_taluk}
                filename={'scheme_taluk_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Circle Table */}
        {showCircleTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center">Circle</TableCell>
                  <TableCell align="center">Scheme Name</TableCell>
                  <TableCell align="center">
                    Transfered Scheme Application
                  </TableCell>
                  <TableCell align="center">Total</TableCell>
                  <TableCell align="center">Forwarded</TableCell>
                  <TableCell align="center">Approved</TableCell>
                  <TableCell align="center">Rejected</TableCell>
                  <TableCell align="center">Pending</TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    (fetchRegistrationCircleReport.data || []).map((circle) => {
                      return (
                        <TableRow key={circle.circle_id}>
                          <TableCell align="center">
                            {circle.circle_name}
                          </TableCell>
                          <TableCell align="center">
                            {circle.scheme_name}
                          </TableCell>
                          <TableCell align="center">
                            {circle['Transferred Scheme Application'] || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Forwarded || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Rejected || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Pending || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  <TableRow>
                    <TableCell
                      align="center"
                      backgroundColor="red"
                      style={{ fontWeight: 'bold' }}
                    >
                      Grand Total
                    </TableCell>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.transfered}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.forwarded}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/*  Download button for Circle */}
              <CSVLink
                data={prepareCircleCSVData(fetchRegistrationCircleReport)}
                headers={csvHeaders_circle}
                filename={'scheme_circle_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {showDistrictTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowDistrictTable(false);
              setShowSchemeTable(true);
            }}
          >
            Back to Schemes
          </Button>
        )}

        {showTalukTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTalukTable(false);
              setShowDistrictTable(true);
            }}
          >
            Back to District
          </Button>
        )}

        {showCircleTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowCircleTable(false);
              setShowTalukTable(true);
            }}
          >
            Back to Taluk
          </Button>
        )}
      </Container>
      {showDashboard ? <ReportDashboard /> : null}
      {showTypeDropDown ? <CountReport /> : null}
    </>
  );
}
