import * as actions from "./index";
import axios from "axios";
import { setLocale } from "react-redux-i18n";
import { supportedLocales, fallbackLocale } from "../../config/i18n";
import { dataURLtoFile, getTokenCookie, saveTokenCookie } from "utils/tools";
import { API_BASE_URL } from "config/environment";

// export const SERVER_NAME = "https://www.karmika.in/";

//Dev SERVER (FIREBASE)

// export const SERVER_NAME = "https://gcdmsdev.web.app/";
// export const SERVER = "https://gcdmsdev.hostg.in/api";
// export const SERVER_2 = "https://gcdmsdev.hostg.in/api";

//Production SERVER

// export const SERVER_NAME = "https://kbocwwb.karnataka.gov.in/";
// export const SERVER = "https://apikbocwwb.karnataka.gov.in/api";
// export const SERVER_2 = "https://apikbocwwb.karnataka.gov.in/api";

// Production TEST SERVER

// export const SERVER_NAME = 'http://kbocwwb.karnataka.gov.in/';
// export const SERVER = 'http://apikbocwwb.karnataka.gov.in/api';
// export const SERVER_2 = 'http://apikbocwwb.karnataka.gov.in/api';

// DEV URL
// export const SERVER_NAME = 'http://gcdmsdbac.hostg.in:5353';
// export const SERVER = 'http://gcdmsdbac.hostg.in/api';
// export const SERVER_2 = 'http://gcdmsdbac.hostg.in/api';

// QA URL

// export const SERVER_NAME = 'https://gcdms.test.hostg.in:5050';
// export const SERVER = 'https://gcdms.test.hostg.in:5051/api';
// export const SERVER_2 = 'https://gcdms.test.hostg.in:5051/api';

// export const SERVER = API_BASE_URL;

// LOCAL URL
// export const SERVER_NAME = "http://localhost:3000";
// export const SERVER = "http://localhost:8585/api";
// export const SERVER_2 = "http://localhost:8585/api";

// UAT URL
export const SERVER_NAME = "https://kbocwwb.metahos.com";
export const SERVER = "https://kbocwwb.metahos.com:9001/api";
export const SERVER_2 = "https://kbocwwb.metahos.com:9001/api";

export const TS_USERNAME = "test.globals@authbridge.com";

axios.defaults.headers.post["Content-Type"] = "application/json";

export const updateAdminDashboardData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateAdminDashboard(data));
  };
};

export const deleteReduxData = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      dispatch(actions[dataToSubmit]({ data: null }));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getDeptUserRole = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.getDeptUserRole({ data: null }));
    } else {
      try {
        const response = await axios.get(
          `${SERVER}/config/dept_user/get_dept_user_role?user_id=` +
            dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.getDeptUserRole({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.errorgetDeptUserRole({ data: error.response.data })
            );
        } else {
          dispatch(actions.errorgetDeptUserRole({ data: error.message }));
        }
      }
    }
  };
};

export const insertDeptUser = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/api/config/dept_user/insert_dept_user`,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.insertDeptUser({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorinsertDeptUser({ data: error.response.data }));
      } else {
        dispatch(actions.errorinsertDeptUser({ data: error.message }));
      }
    }
  };
};

export const getSchemesOnStatus = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/schemes/get_schemes_on_status`,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.getSchemesOnStatus({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetSchemesOnStatus({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorgetSchemesOnStatus({ data: error.message }));
      }
    }
  };
};

export const get_schemes = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.get_schemes({ data: null }));
    } else {
      try {
        const response = await axios.get(
          `${SERVER}/schemes/get_schemes?board_id=` + dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.get_schemes({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorget_schemes({ data: error.response.data }));
        } else {
          dispatch(actions.errorget_schemes({ data: error.message }));
        }
      }
    }
  };
};
export const searchuser = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.searchuser({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER_2}/user/search-user`,
          dataToSubmit
        );

        dispatch(actions.searchuser({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorsearchuser({ data: error.response.data }));
        } else {
          dispatch(actions.errorgetStateMapping({ data: error.message }));
        }
      }
    }
  };
};
export const getStateMapping = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${SERVER}/config/address/get_states`, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      });

      dispatch(actions.getStateMapping({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorgetStateMapping({ data: error.response.data }));
      } else {
        dispatch(actions.errorgetStateMapping({ data: error.message }));
      }
    }
  };
};
export const getConfigBoardDetails = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_board_details?id=1`
      );

      dispatch(actions.getConfigBoardDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetConfigBoardDetails({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorgetConfigBoardDetails({ data: error.message }));
      }
    }
  };
};
export const getConfigMinistryDetails = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/config/ministry/get_ministry_details`
      );

      dispatch(actions.getConfigMinistryDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetConfigMinistryDetails({
              data: error.response.data,
            })
          );
      } else {
        dispatch(
          actions.errorgetConfigMinistryDetails({ data: error.message })
        );
      }
    }
  };
};

export const saveAcknowledgementId = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/user/save-acknowledgement-id`,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.saveAcknowledgementId({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorsaveAcknowledgementId({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorsaveAcknowledgementId({ data: error.message }));
      }
    }
  };
};

export const finalSubmit = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.finalSubmit({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/user/registration/submit`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.finalSubmit({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorfinalSubmit({ data: error.response.data }));
        } else {
          dispatch(actions.errorfinalSubmit({ data: error.message }));
        }
      }
    }
  };
};
export const getRenewalLabourDetails = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/user/get-renewal-labors`,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.getRenewalLabourDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetRenewalLabourDetails({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorgetRenewalLabourDetails({ data: error.message }));
      }
    }
  };
};
export const getLabourStatusDetails = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.getLabourStatusDetails({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/admin/get-labours-status-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.getLabourStatusDetails({ data: response.data.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.errorgetLabourStatusDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.errorgetLabourStatusDetails({ data: error.message })
          );
        }
      }
    }
  };
};
export const getUserRegistrationDetails = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.getUserRegistrationDetails({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/user/get-user-registration-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.getUserRegistrationDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.errorgetUserRegistrationDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.errorgetUserRegistrationDetails({ data: error.message })
          );
        }
      }
    }
  };
};
export const getApplicationNo = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

      const response = await axios.post(
        `${SERVER}/user/get-application-no`,
        dataToSubmit,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.getApplicationNo({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetApplicationNo({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorgetApplicationNo({ data: error.message }));
      }
    }
  };
};

export const updateProfileData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateProfileData(data));
  };
};

export const getUnionDetails = (board_id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_union_details?board_id=${board_id}`
      );

      dispatch(actions.getUnionDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorgetUnionDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorgetUnionDetails({ data: error.message }));
      }
    }
  };
};

export const getInspectorDetails = (board_id, village_area_id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_inspector_details?board_id=${board_id}&&village_area_id=${village_area_id}`
      );

      dispatch(actions.getInspectorDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorgetInspectorDetails({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorgetInspectorDetails({ data: error.message }));
      }
    }
  };
};

export const update90DaysData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.update90DaysData(data));
  };
};
export const updateBankData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateBankData(data));
  };
};

export const addLabour90DaysDetails = (dataToSubmit, str) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.addLabour90DaysDetails({ data: null }));
    } else if (str === "update") {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.patch(
          `${SERVER}/user/update-labour-certificate`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabour90DaysDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabour90DaysDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabour90DaysDetails({ data: error.message })
          );
        }
      }
    } else {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-certificate`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabour90DaysDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabour90DaysDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabour90DaysDetails({ data: error.message })
          );
        }
      }
    }
  };
};

export const addLabourBankDetails = (dataToSubmit, str) => {
  return async (dispatch) => {
    if (str === "delete") {
      dispatch(actions.addLabourBankDetails({ data: null }));
    } else if (str === "update") {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.patch(
          `${SERVER}/user/update-labour-bank-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourBankDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourBankDetails({ data: error.response.data })
            );
        } else {
          dispatch(actions.erroraddLabourBankDetails({ data: error.message }));
        }
      }
    } else {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-bank-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourBankDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourBankDetails({ data: error.response.data })
            );
        } else {
          dispatch(actions.erroraddLabourBankDetails({ data: error.message }));
        }
      }
    }
  };
};

export const addLabourFamilyDetails = (dataToSubmit, str) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.addLabourFamilyDetails({ data: null }));
    } else if (str === "update") {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.patch(
          `${SERVER}/user/update-labour-family-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourFamilyDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourFamilyDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabourFamilyDetails({ data: error.message })
          );
        }
      }
    } else {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-family-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourFamilyDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourFamilyDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabourFamilyDetails({ data: error.message })
          );
        }
      }
    }
  };
};

export const updateFamilyData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateFamilyData(data));
  };
};

export const addLabourAddressDetails = (dataToSubmit, str) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.addLabourAddressDetails({ data: null }));
    } else if (str === "update") {
      try {
        const response = await axios.patch(
          `${SERVER}/user/update-labour-address`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourAddressDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourAddressDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabourAddressDetails({ data: error.message })
          );
        }
      }
    } else {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-address`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourAddressDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.erroraddLabourAddressDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.erroraddLabourAddressDetails({ data: error.message })
          );
        }
      }
    }
  };
};

export const getVillageDetails2 = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_village_details?city_id=` + data
      );

      dispatch(actions.getVillageDetails2({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorVillageDetails2({ data: error.response.data }));
      } else {
        dispatch(actions.errorVillageDetails2({ data: error.message }));
      }
    }
  };
};
export const getCityDetails2 = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_city_details?taluk_id=` + data
      );

      dispatch(actions.getCityDetails2({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorCityDetails2({ data: error.response.data }));
      } else {
        dispatch(actions.errorCityDetails2({ data: error.message }));
      }
    }
  };
};
export const getTalukDetails2 = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_taluk_details?district_id=` + data
      );

      dispatch(actions.getTalukDetails2({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorTalukDetails2({ data: error.response.data }));
      } else {
        dispatch(actions.errorTalukDetails2({ data: error.message }));
      }
    }
  };
};
export const getDistrictDetails2 = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_district_details?state_id=` + data
      );

      dispatch(actions.getDistrictDetails2({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorDistrictDetails2({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorDistrictDetails2({ data: error.message }));
      }
    }
  };
};

export const getVillageDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_village_details?city_id=` + data
      );

      dispatch(actions.getVillageDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorVillageDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorVillageDetails({ data: error.message }));
      }
    }
  };
};
export const getCityDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_city_details?taluk_id=` + data
      );

      dispatch(actions.getCityDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorCityDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorCityDetails({ data: error.message }));
      }
    }
  };
};
export const getTalukDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_taluk_details?district_id=` + data
      );

      dispatch(actions.getTalukDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorTalukDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorTalukDetails({ data: error.message }));
      }
    }
  };
};
export const getDistrictDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_district_details?state_id=` + data
      );

      dispatch(actions.getDistrictDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorDistrictDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorDistrictDetails({ data: error.message }));
      }
    }
  };
};

// export const updateLabourPersonalDetails = (data) => {
//     return async (dispatch, getState) => {
//         dispatch(actions.updateAddress(data))
//     }
// }

export const addLabourPersonalDetails = (dataToSubmit, str) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.addLabourPersonalDetails({ data: null }));
    } else if (str === "update") {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-personal-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourPersonalDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.errorAddLabourPersonalDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.errorAddLabourPersonalDetails({ data: error.message })
          );
        }
      }
    } else {
      try {
        // const response = await axios.post(`${SERVER}/user/add-labour-personal-details`, dataToSubmit);

        const response = await axios.post(
          `${SERVER}/user/add-labour-personal-details`,
          dataToSubmit,
          {
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          }
        );

        dispatch(actions.addLabourPersonalDetails({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(
              actions.errorAddLabourPersonalDetails({
                data: error.response.data,
              })
            );
        } else {
          dispatch(
            actions.errorAddLabourPersonalDetails({ data: error.message })
          );
        }
      }
    }
  };
};

export const updateAddressData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateAddress(data));
  };
};
export const updatePersonalData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updatePersonal(data));
  };
};

export const updateUploadFile = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateUploadFile(data));
  };
};

export const uploadFile = (data, filename) => {
  // console.error("in uploadFile action:" + JSON.stringify(data))
  // console.error("in uploadFile action:" + data.file)

  // const files = Array.of(data.file)

  return async (dispatch) => {
    if (data === "delete") {
      dispatch(actions.getFile({ data: null }));
    } else {
      try {
        var formData = new FormData();
        // formData.append('file', data.file);
        formData.append("file", dataURLtoFile(data.file, "newfile"));
        formData.append("userId", data.userId);
        formData.append("fileType", data.fileType);

        // for (var value of formData.values()) {
        //     console.error("in formData value:" + value)
        //  }

        const response = await axios.post(`${SERVER}/upload/file`, formData, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        if (filename !== undefined && filename !== null) {
          response.data = {
            ...response.data,
            userFileName: filename,
          };
        }

        dispatch(actions.uploadFile({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorUploadFile({ data: error.response.data }));
        } else {
          dispatch(actions.errorUploadFile({ data: error.message }));
        }
      }
    }
  };
};
export const updateGetFile = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.getFile({ data }));
  };
};

export const getFile = (data, filename) => {
  return async (dispatch) => {
    if (data === "delete") {
      dispatch(actions.getFile({ data: null }));
    } else {
      try {
        const response = await axios.get(`${SERVER}/upload/file/` + data, {
          headers: {
            "Content-Type": "image/jpeg",
            Authorization: `Bearer ${getTokenCookie()}`,
          },
          responseType: "arraybuffer",
        });

        var buffer = Buffer.from(response.data, "binary").toString("base64");

        var base64data = "data:image/jpeg;base64," + buffer;
        dispatch(
          actions.getFile({
            data: {
              data: base64data,
              fileName: filename,
            },
          })
        );
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorgetFile({ data: error.response.data }));
        } else {
          dispatch(actions.errorgetFile({ data: error.message }));
        }
      }
    }
  };
};

export const getStateDetails = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${SERVER}/global/get_state_details`);

      dispatch(actions.getStateDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorStateDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorStateDetails({ data: error.message }));
      }
    }
  };
};

export const getCatalogDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/global/get_catalog_details`,
        data
      );

      dispatch(actions.getCatalogDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorCatalogDetails({ data: error.response.data }));
      } else {
        dispatch(actions.errorCatalogDetails({ data: error.message }));
      }
    }
  };
};

export const updateReviewData = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateReview(data));
  };
};

export const registerUser = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.registerUser({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/user/register-labour`,
          dataToSubmit
        );

        dispatch(actions.registerUser({ data: response.data }));

        dispatch(getUser(dataToSubmit.phone_number));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorRegister({ data: error.response.data }));
        } else {
          dispatch(actions.errorRegister({ data: error.message }));
        }
      }
    }
  };
};

export const generateOtpRegistration = (dataToSubmit) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(
        `${SERVER}/user/register-labour-send`,
        dataToSubmit
      );

      dispatch(actions.generateOtpRegistration({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(
            actions.errorGenerateOtpRegistration({ data: error.response.data })
          );
      } else {
        dispatch(actions.errorGenerateOtpRegistration({ data: error.message }));
      }
    }
  };
};

export const getBoardDetails = (id) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_board_details?id=` + id
      );

      dispatch(actions.getBoardDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorLogin({ data: error.response.data }));
      } else {
        dispatch(actions.errorLogin({ data: error.message }));
      }
    }
  };
};

export const getMinistryDetails = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(`${SERVER}/global/get_ministry_details`);

      dispatch(actions.getMinistryDetails({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorLogin({ data: error.response.data }));
      } else {
        dispatch(actions.errorLogin({ data: error.message }));
      }
    }
  };
};

export function setLocaleWithFallback(desiredLocale) {
  const finalLocale = Object.keys(supportedLocales).includes(desiredLocale)
    ? desiredLocale
    : fallbackLocale;
  return (dispatch) => dispatch(setLocale(finalLocale));
}

export const loginAdmin = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.loginAdmin({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/auth/username-login`,
          dataToSubmit
        );

        dispatch(actions.loginAdmin({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorloginAdmin({ data: error.response.data }));
        } else {
          dispatch(actions.errorloginAdmin({ data: error.message }));
        }
      }
    }
  };
};
export const loginUser = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.loginUser({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/auth/verify-otp`,
          dataToSubmit
        );

        dispatch(actions.loginUser({ data: response.data }));

        saveTokenCookie(response.data.jwt);

        dispatch(getUser(dataToSubmit.phone_number));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorLogin({ data: error.response.data }));
        } else {
          dispatch(actions.errorLogin({ data: error.message }));
        }
      }
    }
  };
};

export const generateOtp = (dataToSubmit) => {
  return async (dispatch) => {
    if (dataToSubmit === "delete") {
      dispatch(actions.errorGenerateOtp({ data: null }));
    } else {
      try {
        const response = await axios.post(
          `${SERVER}/auth/otp-login`,
          dataToSubmit
        );

        dispatch(actions.generateOtp({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorGenerateOtp({ data: error.response.data }));
        } else {
          dispatch(actions.errorGenerateOtp({ data: error.message }));
        }
      }
    }
  };
};

export const updateData = (data) => {
  return async (dispatch, getState) => {
    // try{
    // const profile =await axios.patch(`/api/users/profile`,{
    //     data:data
    // }, getAuthHeader());

    // const userData ={
    //     ...getState().users.data,
    //     fullname: data.fullname,
    // }
    dispatch(actions.userUpdateProfile(data));
    // }catch(error){
    //     dispatch(actions.errorGlobal(error.response.data.message))
    // }
  };
};

export const updateUser = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateUser(data));
  };
};

export const updateAdminDashboard = (data) => {
  return async (dispatch, getState) => {
    dispatch(actions.updateUser(data));
  };
};

export const deleteUser = () => {
  return async (dispatch, getState) => {
    dispatch(actions.getUser({ data: null }));
  };
};
export const getUser = (data) => {
  return async (dispatch) => {
    if (data === "delete") {
      dispatch(actions.errorGetUser({ data: null }));
    } else {
      try {
        // var dataToSubmit = {"key":"mobile","value":data};

        const response = await axios.get(`${SERVER}/user/` + data, {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        });

        dispatch(actions.getUser({ data: response.data }));
      } catch (error) {
        if (error.response !== undefined) {
          if (error.response.data !== undefined)
            dispatch(actions.errorGetUser({ data: error.response.data }));
        } else {
          dispatch(actions.errorGetUser({ data: error.message }));
        }
      }
    }
  };
};

export const getDeptUsers = () => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/config/dept_user/get_dept_users`,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.getDeptUsers({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorGetDeptUsers({ data: error.response.data }));
      } else {
        dispatch(actions.errorGetDeptUsers({ data: error.message }));
      }
    }
  };
};

export const getDeptUserDetails = (data) => {
  return async (dispatch) => {
    try {
      const response = await axios.get(
        `${SERVER}/admin/get-department-user-details?department_user_id=${data}`,
        {
          headers: {
            Authorization: `Bearer ${getTokenCookie()}`,
          },
        }
      );

      dispatch(actions.getDeptUsers({ data: response.data }));
    } catch (error) {
      if (error.response !== undefined) {
        if (error.response.data !== undefined)
          dispatch(actions.errorGetDeptUsers({ data: error.response.data }));
      } else {
        dispatch(actions.errorGetDeptUsers({ data: error.message }));
      }
    }
  };
};
