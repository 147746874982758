import 'date-fns';
import { FormControl, TextField, InputAdornment, TextareaAutosize, RadioGroup, Radio, FormControlLabel,Select, MenuItem, ListItemText, Tooltip,  Dialog, DialogTitle, DialogContent,CircularProgress, DialogActions } from '@material-ui/core';
import { PhoneAndroid,CameraAlt, Cached } from '@material-ui/icons';
import React, { useEffect } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import Webcam from "react-webcam";
import MomentUtils from '@date-io/moment';
import { Link } from 'react-router-dom';
import webcam from 'assets/images/web cam-01.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'

// import folder from 'assets/images/folder (3).svg'
// import webcam from 'assets/images/web-cam.svg'
// import aadhar from 'assets/images/id-card.svg'
// import pan from 'assets/images/credit-card.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
import male from 'assets/images/Mask Group 1.svg'
import female from 'assets/images/Mask Group 2.svg'
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addLabourPersonalDetails, getCatalogDetails, getFile, getStateDetails, getUser, getUserRegistrationDetails, SERVER, TS_USERNAME, updatePersonalData, uploadFile } from 'store/actions/user.actions';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import axios from 'axios';
import cookie from 'react-cookies';
import validator from 'validator';
import { Translate } from 'react-redux-i18n';


const videoConstraints = {
    width: 720,
    height: 720,
    facingMode: "user",
    // facingMode: { exact: "environment" }
};

const Personal = (props) => {

    const myRef = React.createRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)
    const [phoneError,setPhoneError] = React.useState("")

    const [adhaarVerified, setAdhaarVerified] = React.useState(false)
    const [allValues, setAllValues] = React.useState({
        language: "",
        selectedDate: null,
        phoneNumber: "",
        maritalStatus: "",
        category: "",
        migrantState: "",
        isMigrant: false,
        pancheck: false,
        aadharcheck: false,
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,
        formcontrolnum: 1,
        aadharnum1: null,
        aadharnum2: null,
        aadharnum3: null,
        aadhar: null,
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        fatherHusbandName: "",
        pan: "",
        gender: "",
        dob: null,
        documentUploadResponse: "",
        aadhaarFetchedData: null,
        education: "",
        religion: "",
        selfemployed: "",
        natureWorkplace: "",
        employerContact: "",
        employerName: "",
        address: "",
        income: "",
        firstnameError:false,
        firstnameErrorMessage:"",
        lastnameError:false,
        lastnameErrorMessage:"",
        middlenameError: false,
        employernameError:false,
        employercontactError:false



    })
    // const [allValues, setAllValues] = React.useState({
    //     language: "",
    //     addLabourPersonalDetails: users.addLabourPersonalDetails,
    //     maritalStatus: users.personal_details.maritalStatus,
    //     users: props.users,
    //     phoneNumber: users.personal_details.phoneNumber,
    //     category: users.personal_details.category,
    //     migrantState: users.personal_details.migrantState,
    //     isMigrant: users.personal_details.isMigrant,
    //     pancheck: users.personal_details.pancheck,
    //     aadharcheck: users.personal_details.aadharcheck,
    //     imgfile: users.personal_details.imgfile,
    //     webcamEnabled: users.personal_details.webcamEnabled,
    //     open: users.personal_details.open,
    //     setOpen: users.personal_details.setOpen,
    //     openDatePicker: users.personal_details.openDatePicker,
    //     formcontrolnum: users.personal_details.formcontrolnum,
    //     aadharnum1: users.personal_details.aadharnum1,
    //     aadharnum2: users.personal_details.aadharnum2,
    //     aadharnum3: users.personal_details.aadharnum3,
    //     aadhar: users.personal_details.aadhar,
    //     firstname: users.personal_details.firstname,
    //     middlename: users.personal_details.middlename,
    //     lastname: users.personal_details.lastname,
    //     email: users.personal_details.email,
    //     fatherHusbandName: users.personal_details.fatherHusbandName,
    //     pan: users.personal_details.pan,
    //     gender: users.personal_details.gender,
    //     dob: users.personal_details.dob,
    //     documentUploadResponse: users.personal_details.documentUploadResponse,
    //     aadhaarFetchedData: users.personal_details.aadhaarFetchedData
    // })

    const [phone, setPhone] = React.useState('')
    const [loading, setLoading] = React.useState(false);
    const [profilePic, setprofilePic] = React.useState('');

    const [rotateRefressh, setrotateRefressh] = React.useState('rotate');
    const [captchaError, setcaptchaError] = React.useState('');
    const [aadhaarToken, setAadhaarToken] = React.useState(null);
    const [redirectback, setRedirectback] = React.useState(false);
    const [vaultRefNumber, setVaultRefNumber] = React.useState(null);
    const [imageEKYC, setImageEKYC] = React.useState(null);
    const [fileUploaded, setFileUploaded] = React.useState(false);
    const [vaultRef, setVaultRef] = React.useState(null);
    const [nextButtonClicked, setNextButtonClicked] = React.useState(false);
    const [aadharValidated, setAadharValidated] = React.useState(false);
    const [aadharDuplicate, setaadharDuplicate] = React.useState(false);


    console.log("AllVaues:",allValues);

    useEffect(() => {
        if (profilePic !== undefined && profilePic !== "" && profilePic !== null) {
            sessionStorage.setItem('profilePic', profilePic)
        }
    }, [profilePic]);

    useEffect(() => {
        setprofilePic(sessionStorage.getItem('profilePic'))
    }, [sessionStorage.getItem('profilePic')]);
    useEffect(() => {
        setVaultRef(sessionStorage.getItem('vaultRefNumber'))
    },[sessionStorage.getItem('vaultRefNumber')])


    useEffect(() => {
        setAllValues({ ...allValues, phoneNumber: phone })

        dispatch(getStateDetails())
    }, [phone]);

    useEffect(() => {
        console.log("in useEffect10")

        if(redirectback){
        console.log("inside_redirect")
        }

    },[redirectback]) 


    useEffect(() => {
        // console.log("in useEffect []")

        if (users.generate_otp !== null) {
            setPhone(users.generate_otp.data.phone_number)
            // setAllValues({...allValues, phoneNumber: users.generate_otp.data.phone_number})
            dispatch(getUser(users.generate_otp.data.phone_number))
        }
        else if (users.generate_otp_registration !== null) {
            setPhone(users.generate_otp_registration.data.phone_number)
            // setAllValues({...allValues, phoneNumber: users.generate_otp_registration.data.phone_number})
            dispatch(getUser(users.generate_otp_registration.data.phone_number))
        }
        else {
            props.history.push("/")
        }

        dispatch(getFile("delete"))
        dispatch(addLabourPersonalDetails("delete"))

        sessionStorage.removeItem('profilePic')

        getNewCaptcha()

        setAllValues({
            ...allValues,
            upload_file: null
        })

        console.log("NatureOfWork", NatureofWork)


        if (window.location.toString().includes("Success") && !aadharValidated){
            var queryString = JSON.stringify(props.location.search)

            var vaultRefN =  queryString.substring(queryString.lastIndexOf('refno=') + 6).split(' ')[0]
            var vaultRefNo = vaultRefN.substring(0, vaultRefN.length - 1);
            setVaultRefNumber(vaultRefNo)

            var data = JSON.stringify({
                        "key": "aadhar_no",
                        "value": vaultRefNo,
                        "board_id": 4
                      });
                      
                      var config = {
                        method: 'post',
                        url: SERVER+'/user/get-user',
                        headers: { 
                            'Content-Type': 'application/json',
                            'Authorization':`Bearer ${getTokenCookie()}`
                        },
                        data : data
                      };
                      
                      axios(config)
                      .then(function (response) {
                        setLoading(false)
                        console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data,undefined,2));
            
                            var check = true
                            var aadharDuplicate = response.data.data
                            console.log("aadharDuplicate:" + response.data.data);
                            setaadharDuplicate(response.data)      
                        })

        } 
          else if (window.location.toString().includes("Failure")){
            showToast("ERROR","Aadhaar Verification failed, please try again.")
        }

    }, []);

    useEffect(() => {
        if(aadharDuplicate){
            console.log("inAadharDuplicate",aadharDuplicate.data)
            if( aadharDuplicate.data.length === 0){
                    console.log(aadharDuplicate.data,"aadhaarData");
                    // // setaadharDuplicateError(true)
                    // // showToast("ERROR", "Aadhaar Already Registered!")
                    // check = false
                    // var queryString = props.location.search

                
                    console.log("props12", vaultRefNumber);

                    var data1 = JSON.stringify({
                        
                            "labour_user_id": users.user.id,
                            "vault_ref_no":vaultRefNumber
                        
                    });

                    var config = {
                        method: 'post',
                        url: SERVER + "/global/update_ekyc_vault_number",
                        headers: { 
                            'Content-Type': 'application/json', 
                        },
                        data : data1
                    
                    };
                    axios(config)
                    .then(function (response) {
                        // showToast("SUCCESS", "Aadhar Verified")
                        // prePopulateAadharData()

                        setAadharValidated(true)
                    })
                    .catch(function (error) {
                        console.log(error);
                        if(error.response.data.errors.msg.msg !== undefined){
                            showToast("ERROR", error.response.data.errors.msg.msg)
                        }
                    });
                }
            else{
                // setaadharDuplicateError(true)
                showToast("ERROR", "Aadhaar Already Registered!")
            }
        }
    },[aadharDuplicate])

    useEffect(()=> {
       if(vaultRefNumber !== null && vaultRefNumber !== undefined){
        sessionStorage.setItem('vaultRefNumber', vaultRefNumber)

       }


       else if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.personal_details !== null && users.getUserRegistrationDetails.personal_details !== undefined && users.getUserRegistrationDetails.personal_details[0] !== undefined && users.getUserRegistrationDetails.personal_details[0] !== null && users.getUserRegistrationDetails.personal_details[0].aadhar_no !== null ){
        setVaultRef(users.getUserRegistrationDetails.personal_details[0].aadhar_no )
       }
    },[vaultRefNumber])



    useEffect(() => {
        console.log("in useEffect5")

        if(aadharValidated){
            var data1 = JSON.stringify({
                
                "labour_user_id": users.user.id,
             
            
        });
    
        var config = {
            method: 'post',
            url: SERVER + "/user/get_ad_details",
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${getTokenCookie()}`
    
            },
            data : data1
           
          };
          axios(config)
          .then(function (response) {
            // showToast("SUCCESS", "Aadhar Verified")
            console.log("ResponseEKYC", response.data.data[0].id)
            if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
                setAdhaarVerified(true)
                showToast("SUCCESS", "Aadhaar Verified Successfully")
          
                
                var first_name = ""
                var middle_name = ""
                var last_name = ""
                var nameArr = response.data.data[0].name.split(' ')
                console.log("nameArr: "+nameArr[0])
                if (nameArr.length === 1) {
                    first_name = nameArr[0]
                }
                else if (nameArr.length === 2) {
                    first_name = nameArr[0]
                    last_name = nameArr[1]
                }
                else if (nameArr.length >= 3) {
                    first_name = nameArr[0]
                    middle_name = nameArr[1]
                    last_name = nameArr[2]
                }
                console.log("first_name: "+first_name)
                console.log("middle_name: "+middle_name)
                console.log("last_name: "+last_name)
    
                
                var gender2 = null
                if(response.data.data[0].gender === "M"){
                    gender2 = "27"
                }
                else if(response.data.data[0].gender === "F"){
                    gender2 = "28"
                }
                else{
                    gender2 = "29"
                }
                console.log("gender: "+gender2)
                
                cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
                
                setAllValues({
                    ...allValues,
                    aadharcheck: true,
                    aadhaarFetchedData: response.data.data[0],
                    is_aadhar_verified: true,
                    firstname: first_name,
                    middlename: middle_name,
                    lastname: last_name,
                    gender: gender2
                    
                })
                setDob(response.data.data[0].dob) 
    
                setLoading(false)
                var base64Str = response.data.data[0].document_link
                setprofilePic(`data:image/png;base64,${base64Str}`)
                // getimage(response.data.data[0].image)

                setImageEKYC (response.data.data[0].image)

                
                sessionStorage.setItem('profilePicEKYC', response.data.data[0].document_link)
                // const base64 = 'data:image/jpeg;base64,'+response.data.data[0].image
                // const pathToSaveImage = './public/image.png'

                // const path = converBase64ToImage(base64, pathToSaveImage) 
                // var base64String = response.data.data[0].image
                
                // var base64Stringify = JSON.stringify(base64String)
                // console.log("base64String",base64Stringify)


                

                // var base64Str = `data:image/png;base64,${base64Stringify}`
                // var path = './public/image.png';
                // var optionalObj = {'fileName': 'imageFileName', 'type':'png'};
                // base64ToImage(base64Str,path,optionalObj); 
    
                // var imageInfo = base64ToImage(base64Str,path,optionalObj); 

                // console.log("imageInfo",imageInfo)

                // var file = dataURLtoFile('data:text/plain;base64,aGVsbG8gd29ybGQ=','hello.txt');
    
                // var config = {
                //     method: 'get',
                //     url: SERVER+`/global/convert_image_base_64?image_link=${response.data.data[0].image}`,
                //     headers: { },
                //     // data : data
                //   };
                  
                //   axios(config)
                //   .then(function (response) {
                //     console.log(JSON.stringify(response.data));
                //     setprofilePic(response.data.data.base_64)
                //     sessionStorage.setItem('profilePic', response.data.data.base_64)
                //   })
                //   .catch(function (error) {
                //     console.log(error);
                //     if(error.response.data.errors !== undefined){
                //         showToast("ERROR", error.response.data.errors)
                //     }
                //   });
    
    
    
                //   var dataAadhaar = JSON.stringify({
                //     "labour_user_id": users.user.id,
                //     "name": response.data.data.msg.Name,
                //     "dob": response.data.data.msg.DOB,
                //     "gender": response.data.data.msg.Gender,
                //     "house_no": response.data.data.msg['House Number'],
                //     "street": response.data.data.msg.Street,
                //     "locality": response.data.data.msg.Locality,
                //     "landmark": response.data.data.msg.Landmark,
                //     "postoffice": response.data.data.msg['Post Office'],
                //     "village_city_town": response.data.data.msg['Village/Town/City'],
                //     "state": response.data.data.msg.State,
                //     "district": response.data.data.msg.District,
                //     "sub_district": response.data.data.msg['Sub District'],
                //     "country": response.data.data.msg.Country,
                //     "pincode": response.data.data.msg.Pincode,
                //     "image": response.data.data.msg.Image,
                //     "father_name": response.data.data.msg['Father Name'],
                //     "document_link": response.data.data.msg['Document link']
                //   });
                //   var configdataAadhaar = {
                //     method: 'post',
                //     url: SERVER+'/user/update_ad_details',
                //     headers: { 
                //         'Content-Type': 'application/json',
                //         'Authorization': `Bearer ${getTokenCookie()}` 
                //     },
                //     data : dataAadhaar
                //   };
                  
                //   axios(configdataAadhaar)
                //   .then(function (response) {
                //     console.log(JSON.stringify(response.data));
                //   })
                //   .catch(function (error) {
                //     console.log(error);
                //     if(
                //         error.response !== undefined &&
                //         error.response.data !== undefined &&
                //         error.response.data.errors !== undefined &&
                //         error.response.data.errors.msg !== undefined &&
                //         error.response.data.errors.msg.msg !== undefined
                //         ){
                //         showToast("ERROR", error.response.data.errors.msg.msg)
                //     }
                //   });
                  
            }
           
          })
          .catch(function (error) {
            console.log(error);
            if(error.response.data.errors !== undefined){
                showToast("ERROR", error.response.data.errors)
            }
          });
        }

    },[aadharValidated])


    useEffect(() => {
        if (users.user !== null && users.user.board_id !== null) {

            let dataForRegDetails = {
                "key": "user_id",
                "value": users.user.id,
                "board_id": users.user.board_id,
                "procedure_name": "personal_details"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))

            let dataForCatalog = { "board_id": users.user.board_id, "catalog_name": "Marital Status" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))

            dispatch(getCatalogDetails(dataForCatalog))

            let dataForCatalog2 = { "board_id": users.user.board_id, "catalog_name": "Category" };
            // console.log("dataForCatalog2: " + JSON.stringify(dataForCatalog2))

            dispatch(getCatalogDetails(dataForCatalog2))


            let dataForCatalog3 = { "board_id": users.user.board_id, "catalog_name": "Education" };
            dispatch(getCatalogDetails(dataForCatalog3))
            
            let dataForCatalog4 = { "board_id": users.user.board_id, "catalog_name": "Religion" };
            dispatch(getCatalogDetails(dataForCatalog4))

            let dataForCatalog5 = { "board_id": users.user.board_id, "catalog_name": "Income" };
            dispatch(getCatalogDetails(dataForCatalog5))

            let dataForCatalog6 = { "board_id": users.user.board_id, "catalog_name": "Self Employed" };
            dispatch(getCatalogDetails(dataForCatalog6))

            let dataForCatalog7 = { "board_id": users.user.board_id, "catalog_name": "Nature of Work" };
            dispatch(getCatalogDetails(dataForCatalog7))
            
            
            
            
            
        }


    }, [users.user])

    useEffect(() => {
        if(users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.length > 0 ){
             if(users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "personal_details" )   {  
             console.error("inside if tab_name === personal_details")
                     var val = users.getUserRegistrationDetails[0]
 
                     // var str = users.getUserRegistrationDetails[0].aadhar_no;
                     // var aadharArr = [];
                     // var chunkSize = 4;
 
                     // while (str) {
                     //     if (str.length < chunkSize) {
                     //         aadharArr.push(str);
                     //         break;
                     //     }
                     //     else {
                     //         aadharArr.push(str.substr(0, chunkSize));
                     //         str = str.substr(chunkSize);
                     //     }
                     // }
 
                     // console.error("aadharArr: "+aadharArr)
 
                     // getimage(val.image)
 
 
                     // if(
                     //     users.getUserRegistrationDetails[0] !== undefined &&
                     //     users.getUserRegistrationDetails[0] !== null &&
                     //     users.getUserRegistrationDetails[0] !== ""
                     //     ){
                     //         var data = JSON.stringify({
                     //             "decryptString": users.getUserRegistrationDetails[0].encrypted_aadhar
                     //           });
                             
                     //           var config = {
                     //             method: 'post',
                     //             url: SERVER+'/global/decrypt_data',
                     //             headers: { 
                     //               'Content-Type': 'application/json'
                     //             },
                     //             data : data
                     //           };
                             
                     //           axios(config)
                     //           .then(function (response) {
                     //             console.log("AadhaarDecrypted: "+JSON.stringify(response.data));
                     //             // console.log("AadhaarDecrypted: "+JSON.stringify(response.data.data.decryptedData));
                     //             setAadhaarDecrypted(response.data.data.decryptedData)
                     //           })
                     //           .catch(function (error) {
                     //             console.log(error);
                     //           });
                     // }
 
             
                     getimage(val.user_photo_id)
                     setAllValues({
                         ...allValues,
                         maritalStatus: val.catalog_value_marital_status_id,
                         phoneNumber: val.mobile_no,
                         category: val.catalog_value_caste_id,
                         migrantState: val.migrate_from_state_id,
                         isMigrant: val.is_inter_state_migrant ? "1":"0",
                         // aadhar_no : vaultRef,
                         aadhar_no : users.getUserRegistrationDetails[0] !== undefined &&
                             users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0].aadhar_no ,
 
                         firstname: val.first_name,
                         middlename: val.middle_name,
                         lastname: val.last_name,
                         email: val.mail_id,
                         // fatherHusbandName: users.personal_details.fatherHusbandName,
                         pan: val.pan_no,
                         gender: String(val.catalog_value_gender_id),
                         dob: val.date_of_birth,
                         
                         aadharcheck: val.is_aadhar_verified,
                         is_aadhar_verified: val.is_aadhar_verified,
                         religion : val.catalog_value_religion_id,
                         employerName : val.employer_name,
                         selfemployed : val.catalog_value_employement_status,
                         education : val.catalog_value_qualification_id,
                         natureWorkplace: val.catalog_value_nature_of_work,
                         income:val.income_earned[0],
                         employerContact:val.employer_contact_number
                         

                     })
 
                     setDob(val.date_of_birth)
                     setImageEKYC(val.user_photo_id)
                     // setprofilePicEKYC(response.data.data[0].document_link)
                     // getimage(response.data.data[0].image)
                 // }
             }   
             else if(users.getUserRegistrationDetails !== null &&  users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0] !== undefined && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "address_details"){
                 console.log("inside_address_loop")
                 var val = users.getUserRegistrationDetails[0]
 
 
                 getimage(val.user_photo_id)
                 setAllValues({
                     ...allValues,
                     maritalStatus: val.catalog_value_marital_status_id,
                     phoneNumber: val.mobile_no,
                     category: val.catalog_value_caste_id,
                     migrantState: val.migrate_from_state_id,
                     isMigrant: val.is_inter_state_migrant ? "1":"0",
                     // aadhar_no : vaultRef,
                     aadhar_no : users.getUserRegistrationDetails[0] !== undefined &&
                     users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0].aadhar_no ,
 
                     firstname: val.first_name,
                     middlename: val.middle_name,
                     lastname: val.last_name,
                     email: val.mail_id,
                     // fatherHusbandName: users.personal_details.fatherHusbandName,
                     pan: val.pan_no,
                     gender: String(val.catalog_value_gender_id),
                     dob: val.date_of_birth,
                    
                     aadharcheck: val.is_aadhar_verified,
                     is_aadhar_verified: val.is_aadhar_verified,
                     religion : val.catalog_value_religion_id,
                         employerName : val.employer_name,
                         selfemployed : val.catalog_value_employement_status,
                         education : val.catalog_value_qualification_id,
                         natureWorkplace: val.catalog_value_nature_of_work,
                         income:val.income_earned[0],
                         employerContact:val.employer_contact_number
                 })
 
                 setDob(val.date_of_birth)
                 setImageEKYC(val.user_photo_id)
     
             //     var data1 = JSON.stringify({
                     
             //         "labour_user_id": users.user.id,
                  
                 
             // });
         
             // var config = {
             //     method: 'post',
             //     url: SERVER + "/user/get_ad_details",
             //     headers: { 
             //         'Content-Type': 'application/json', 
             //         'Authorization': `Bearer ${getTokenCookie()}`
         
             //     },
             //     data : data1
                
             //   };
             //   axios(config)
             //   .then(function (response) {
             //     // showToast("SUCCESS", "Aadhar Verified")
             //     console.log("ResponseEKYC", response.data.data[0].id)
             //     if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
             //         // showToast("SUCCESS", "Aadhaar Verified Successfully")
             //         var val = users.getUserRegistrationDetails[0]
 
                     
             //         var first_name = ""
             //         var middle_name = ""
             //         var last_name = ""
             //         var nameArr = response.data.data[0].name.split(' ')
             //         console.log("nameArr: "+nameArr[0])
             //         if (nameArr.length === 1) {
             //             first_name = nameArr[0]
             //         }
             //         else if (nameArr.length === 2) {
             //             first_name = nameArr[0]
             //             last_name = nameArr[1]
             //         }
             //         else if (nameArr.length >= 3) {
             //             first_name = nameArr[0]
             //             middle_name = nameArr[1]
             //             last_name = nameArr[2]
             //         }
             //         console.log("first_name: "+first_name)
             //         console.log("middle_name: "+middle_name)
             //         console.log("last_name: "+last_name)
         
                     
             //         var gender2 = null
             //         if(response.data.data[0].gender === "M"){
             //             gender2 = "27"
             //         }
             //         else if(response.data.data[0].Gender2 === "F"){
             //             gender2 = "28"
             //         }
             //         else{
             //             gender2 = "29"
             //         }
             //         console.log("gender: "+gender2)
                     
             //         cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
                     
             //         setAllValues({
             //             ...allValues,
             //             aadharcheck: true,
             //             aadhaarFetchedData: response.data.data[0],
             //             is_aadhar_verified: true,
             //             firstname: first_name,
             //             middlename: middle_name,
             //             lastname: last_name,
             //             gender: gender2,
             //             aadharcheck: val.is_aadhar_verified,
             //             maritalStatus: val.catalog_value_marital_status_id,
             //             category: val.catalog_value_caste_id,
             //             migrantState: val.migrate_from_state_id,
             //             isMigrant: val.is_inter_state_migrant ? "1":"0",
             //             pan: val.pan_no,
                         
             //         })
             //         setDob(response.data.data[0].dob) 
         
             //         setLoading(false)
             //         var base64Str =  response.data.data[0].document_link
             //         setprofilePic(`data:image/png;base64,${base64Str}`)
 
             //         getimage(val.user_photo_id)
     
             //         setImageEKYC (response.data.data[0].image)
     
                     
             //         sessionStorage.setItem('profilePic', response.data.data[0].document_link)
 
                       
             //     }
                
             //   })
             //   .catch(function (error) {
             //     console.log(error);
             //     if(error.response.data.errors !== undefined){
             //         showToast("ERROR", error.response.data.errors)
             //     }
             //   }); 
             }
             else if(users.getUserRegistrationDetails !== null &&  users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0] !== undefined && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "family_details"){
                 console.log("inside_address_loop")
 
                 var val = users.getUserRegistrationDetails[0]
 
 
                 getimage(val.user_photo_id)
                 setAllValues({
                     ...allValues,
                     maritalStatus: val.catalog_value_marital_status_id,
                     phoneNumber: val.mobile_no,
                     category: val.catalog_value_caste_id,
                     migrantState: val.migrate_from_state_id,
                     isMigrant: val.is_inter_state_migrant ? "1":"0",
                     // aadhar_no : vaultRef,
                     aadhar_no : users.getUserRegistrationDetails[0] !== undefined &&
                     users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0].aadhar_no ,
 
                     firstname: val.first_name,
                     middlename: val.middle_name,
                     lastname: val.last_name,
                     email: val.mail_id,
                     // fatherHusbandName: users.personal_details.fatherHusbandName,
                     pan: val.pan_no,
                     gender: String(val.catalog_value_gender_id),
                     dob: val.date_of_birth,
                     
                     aadharcheck: val.is_aadhar_verified,
                     is_aadhar_verified: val.is_aadhar_verified,
                     religion : val.catalog_value_religion_id,
                     employerName : val.employer_name,
                     selfemployed : val.catalog_value_employement_status,
                     education : val.catalog_value_qualification_id,
                     natureWorkplace: val.catalog_value_nature_of_work,
                     income:val.income_earned[0],
                     employerContact:val.employer_contact_number
                 })
 
                 setDob(val.date_of_birth)
                 setImageEKYC(val.user_photo_id)
     
             //     var data1 = JSON.stringify({
                     
             //         "labour_user_id": users.user.id,
                  
                 
             // });
         
             // var config = {
             //     method: 'post',
             //     url: SERVER + "/user/get_ad_details",
             //     headers: { 
             //         'Content-Type': 'application/json', 
             //         'Authorization': `Bearer ${getTokenCookie()}`
         
             //     },
             //     data : data1
                
             //   };
             //   axios(config)
             //   .then(function (response) {
             //     // showToast("SUCCESS", "Aadhar Verified")
             //     console.log("ResponseEKYC", response.data.data[0].id)
             //     if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
             //         // showToast("SUCCESS", "Aadhaar Verified Successfully")
             //         var val = users.getUserRegistrationDetails[0]
 
                     
             //         var first_name = ""
             //         var middle_name = ""
             //         var last_name = ""
             //         var nameArr = response.data.data[0].name.split(' ')
             //         console.log("nameArr: "+nameArr[0])
             //         if (nameArr.length === 1) {
             //             first_name = nameArr[0]
             //         }
             //         else if (nameArr.length === 2) {
             //             first_name = nameArr[0]
             //             last_name = nameArr[1]
             //         }
             //         else if (nameArr.length >= 3) {
             //             first_name = nameArr[0]
             //             middle_name = nameArr[1]
             //             last_name = nameArr[2]
             //         }
             //         console.log("first_name: "+first_name)
             //         console.log("middle_name: "+middle_name)
             //         console.log("last_name: "+last_name)
         
                     
             //         var gender2 = null
             //         if(response.data.data[0].gender === "M"){
             //             gender2 = "27"
             //         }
             //         else if(response.data.data[0].Gender2 === "F"){
             //             gender2 = "28"
             //         }
             //         else{
             //             gender2 = "29"
             //         }
             //         console.log("gender: "+gender2)
                     
             //         cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
                     
             //         setAllValues({
             //             ...allValues,
             //             aadharcheck: true,
             //             aadhaarFetchedData: response.data.data[0],
             //             is_aadhar_verified: true,
             //             firstname: first_name,
             //             middlename: middle_name,
             //             lastname: last_name,
             //             gender: gender2,
             //             aadharcheck: val.is_aadhar_verified,
             //             maritalStatus: val.catalog_value_marital_status_id,
             //             category: val.catalog_value_caste_id,
             //             migrantState: val.migrate_from_state_id,
             //             isMigrant: val.is_inter_state_migrant ? "1":"0",
             //             pan: val.pan_no,
                         
             //         })
             //         setDob(response.data.data[0].dob) 
         
             //         setLoading(false)
 
             //         var base64Str =  response.data.data[0].document_link
             //         setprofilePic(`data:image/png;base64,${base64Str}`)
             //         // setprofilePic(response.data.data[0].document_link)
             //         getimage(val.user_photo_id)
     
             //         setImageEKYC (response.data.data[0].image)
     
                     
             //         sessionStorage.setItem('profilePic', response.data.data[0].document_link)
 
                       
             //     }
                
             //   })
             //   .catch(function (error) {
             //     console.log(error);
             //     if(error.response.data.errors !== undefined){
             //         showToast("ERROR", error.response.data.errors)
             //     }
             //   }); 
             }
             else if(users.getUserRegistrationDetails !== null &&  users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0] !== undefined && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "bank_details"){
                 console.log("inside_address_loop")
 
                 var val = users.getUserRegistrationDetails[0]
 
 
                 getimage(val.user_photo_id)
                 setAllValues({
                     ...allValues,
                     maritalStatus: val.catalog_value_marital_status_id,
                     phoneNumber: val.mobile_no,
                     category: val.catalog_value_caste_id,
                     migrantState: val.migrate_from_state_id,
                     isMigrant: val.is_inter_state_migrant ? "1":"0",
                     // aadhar_no : vaultRef,
                     aadhar_no : val.aadhar_no ,
 
                     firstname: val.first_name,
                     middlename: val.middle_name,
                     lastname: val.last_name,
                     email: val.mail_id,
                     // fatherHusbandName: users.personal_details.fatherHusbandName,
                     pan: val.pan_no,
                     gender: String(val.catalog_value_gender_id),
                     dob: val.date_of_birth,
                     
                     aadharcheck: val.is_aadhar_verified,
                     is_aadhar_verified: val.is_aadhar_verified,
                     religion : val.catalog_value_religion_id,
                     employerName : val.employer_name,
                     selfemployed : val.catalog_value_employement_status,
                     education : val.catalog_value_qualification_id,
                     natureWorkplace: val.catalog_value_nature_of_work,
                     income:val.income_earned[0],
                     employerContact:val.employer_contact_number
                     
                 })
 
                 setDob(val.date_of_birth)
                 setImageEKYC(val.user_photo_id)
     
             //     var data1 = JSON.stringify({
                     
             //         "labour_user_id": users.user.id,
                  
                 
             // });
         
             // var config = {
             //     method: 'post',
             //     url: SERVER + "/user/get_ad_details",
             //     headers: { 
             //         'Content-Type': 'application/json', 
             //         'Authorization': `Bearer ${getTokenCookie()}`
         
             //     },
             //     data : data1
                
             //   };
             //   axios(config)
             //   .then(function (response) {
             //     // showToast("SUCCESS", "Aadhar Verified")
             //     console.log("ResponseEKYC", response.data.data[0].id)
             //     if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
             //         // showToast("SUCCESS", "Aadhaar Verified Successfully")
             //         var val = users.getUserRegistrationDetails[0]
 
                     
             //         var first_name = ""
             //         var middle_name = ""
             //         var last_name = ""
             //         var nameArr = response.data.data[0].name.split(' ')
             //         console.log("nameArr: "+nameArr[0])
             //         if (nameArr.length === 1) {
             //             first_name = nameArr[0]
             //         }
             //         else if (nameArr.length === 2) {
             //             first_name = nameArr[0]
             //             last_name = nameArr[1]
             //         }
             //         else if (nameArr.length >= 3) {
             //             first_name = nameArr[0]
             //             middle_name = nameArr[1]
             //             last_name = nameArr[2]
             //         }
             //         console.log("first_name: "+first_name)
             //         console.log("middle_name: "+middle_name)
             //         console.log("last_name: "+last_name)
         
                     
             //         var gender2 = null
             //         if(response.data.data[0].gender === "M"){
             //             gender2 = "27"
             //         }
             //         else if(response.data.data[0].Gender2 === "F"){
             //             gender2 = "28"
             //         }
             //         else{
             //             gender2 = "29"
             //         }
             //         console.log("gender: "+gender2)
                     
             //         cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
                     
             //         setAllValues({
             //             ...allValues,
             //             aadharcheck: true,
             //             aadhaarFetchedData: response.data.data[0],
             //             is_aadhar_verified: true,
             //             firstname: first_name,
             //             middlename: middle_name,
             //             lastname: last_name,
             //             gender: gender2,
             //             aadharcheck: val.is_aadhar_verified,
             //             maritalStatus: val.catalog_value_marital_status_id,
             //             category: val.catalog_value_caste_id,
             //             migrantState: val.migrate_from_state_id,
             //             isMigrant: val.is_inter_state_migrant ? "1":"0",
             //             pan: val.pan_no,
                         
             //         })
             //         setDob(response.data.data[0].dob) 
         
             //         setLoading(false)
             //         setprofilePic(response.data.data[0].document_link)
             //         // getimage(response.data.data[0].image)
             //         getimage(val.user_photo_id)
             //         var base64Str =  response.data.data[0].document_link
             //         setprofilePic(`data:image/png;base64,${base64Str}`)
 
 
     
             //         setImageEKYC (response.data.data[0].image)
     
                     
             //         sessionStorage.setItem('profilePic', response.data.data[0].document_link)
 
                       
             //     }
                
             //   })
             //   .catch(function (error) {
             //     console.log(error);
             //     if(error.response.data.errors !== undefined){
             //         showToast("ERROR", error.response.data.errors)
             //     }
             //   }); 
             }
             else if(users.getUserRegistrationDetails !== null &&  users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0] !== undefined && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "90_days_certificate_details"){
                 console.log("inside_address_loop")
                 var val = users.getUserRegistrationDetails[0]
 
 
                 getimage(val.user_photo_id)
                 setAllValues({
                     ...allValues,
                     maritalStatus: val.catalog_value_marital_status_id,
                     phoneNumber: val.mobile_no,
                     category: val.catalog_value_caste_id,
                     migrantState: val.migrate_from_state_id,
                     isMigrant: val.is_inter_state_migrant ? "1":"0",
                     // aadhar_no : vaultRef,
                     aadhar_no : users.getUserRegistrationDetails[0] !== undefined &&
                     users.getUserRegistrationDetails[0] !== null && users.getUserRegistrationDetails[0].aadhar_no ,
 
                     firstname: val.first_name,
                     middlename: val.middle_name,
                     lastname: val.last_name,
                     email: val.mail_id,
                     // fatherHusbandName: users.personal_details.fatherHusbandName,
                     pan: val.pan_no,
                     gender: String(val.catalog_value_gender_id),
                     dob: val.date_of_birth,
                     
                     aadharcheck: val.is_aadhar_verified,
                     is_aadhar_verified: val.is_aadhar_verified
                 })
 
                 setDob(val.date_of_birth)
                 setImageEKYC(val.user_photo_id)
     
             //     var data1 = JSON.stringify({
                     
             //         "labour_user_id": users.user.id,
                  
                 
             // });
         
             // var config = {
             //     method: 'post',
             //     url: SERVER + "/user/get_ad_details",
             //     headers: { 
             //         'Content-Type': 'application/json', 
             //         'Authorization': `Bearer ${getTokenCookie()}`
         
             //     },
             //     data : data1
                
             //   };
             //   axios(config)
             //   .then(function (response) {
             //     // showToast("SUCCESS", "Aadhar Verified")
             //     console.log("ResponseEKYC", response.data.data[0].id)
             //     if (response.data.data[0] !== undefined && response.data.data[0] !== null) {
             //         // showToast("SUCCESS", "Aadhaar Verified Successfully")
             //         var val = users.getUserRegistrationDetails[0]
 
                     
             //         var first_name = ""
             //         var middle_name = ""
             //         var last_name = ""
             //         var nameArr = response.data.data[0].name.split(' ')
             //         console.log("nameArr: "+nameArr[0])
             //         if (nameArr.length === 1) {
             //             first_name = nameArr[0]
             //         }
             //         else if (nameArr.length === 2) {
             //             first_name = nameArr[0]
             //             last_name = nameArr[1]
             //         }
             //         else if (nameArr.length >= 3) {
             //             first_name = nameArr[0]
             //             middle_name = nameArr[1]
             //             last_name = nameArr[2]
             //         }
             //         console.log("first_name: "+first_name)
             //         console.log("middle_name: "+middle_name)
             //         console.log("last_name: "+last_name)
         
                     
             //         var gender2 = null
             //         if(response.data.data[0].gender === "M"){
             //             gender2 = "27"
             //         }
             //         else if(response.data.data[0].Gender2 === "F"){
             //             gender2 = "28"
             //         }
             //         else{
             //             gender2 = "29"
             //         }
             //         console.log("gender: "+gender2)
                     
             //         cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data[0]), { path: '/' })
                     
             //         setAllValues({
             //             ...allValues,
             //             aadharcheck: true,
             //             aadhaarFetchedData: response.data.data[0],
             //             is_aadhar_verified: true,
             //             firstname: first_name,
             //             middlename: middle_name,
             //             lastname: last_name,
             //             gender: gender2,
             //             aadharcheck: val.is_aadhar_verified,
             //             maritalStatus: val.catalog_value_marital_status_id,
             //             category: val.catalog_value_caste_id,
             //             migrantState: val.migrate_from_state_id,
             //             isMigrant: val.is_inter_state_migrant ? "1":"0",
             //             pan: val.pan_no,
                         
             //         })
             //         setDob(response.data.data[0].dob) 
         
             //         setLoading(false)
             //         setprofilePic(response.data.data[0].document_link)
             //         // getimage(response.data.data[0].image)
     
             //         setImageEKYC (response.data.data[0].image)
     
                     
             //         sessionStorage.setItem('profilePic', response.data.data[0].document_link)
 
                       
             //     }
                
             //   })
             //   .catch(function (error) {
             //     console.log(error);
             //     if(error.response.data.errors !== undefined){
             //         showToast("ERROR", error.response.data.errors)
             //     }
             //   }); 
             }
         }
     }, [users.getUserRegistrationDetails]);

    // useEffect(() => {
    //     if (users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.length > 0) {
    //         console.error("inside if tab_name === personal_details")
    //         var val = users.getUserRegistrationDetails[0]

    //         var str = users.getUserRegistrationDetails[0].aadhar_no;
    //         var aadharArr = [];
    //         var chunkSize = 4;

    //         while (str) {
    //             if (str.length < chunkSize) {
    //                 aadharArr.push(str);
    //                 break;
    //             }
    //             else {
    //                 aadharArr.push(str.substr(0, chunkSize));
    //                 str = str.substr(chunkSize);
    //             }
    //         }

    //         console.error("aadharArr: " + aadharArr)

    //         getimage(val.user_photo_id)



    //         setAllValues({
    //             ...allValues,
    //             income: val.income_earned  !== null ? val.income_earned[0]: "",
    //             religion: val.catalog_value_religion_id,
    //             maritalStatus: val.catalog_value_marital_status_id,
    //             phoneNumber: val.mobile_no,
    //             category: val.catalog_value_caste_id,
    //             migrantState: val.migrate_from_state_id,
    //             isMigrant: val.is_inter_state_migrant ? "1" : "0",
    //             aadharnum1: aadharArr[0],
    //             aadharnum2: aadharArr[1],
    //             aadharnum3: aadharArr[2],
    //             aadhar: val.aadhar_no,
    //             firstname: val.first_name,
    //             middlename: val.middle_name,
    //             lastname: val.last_name,
    //             email: val.mail_id,
    //             // fatherHusbandName: users.personal_details.fatherHusbandName,
    //             pan: val.pan_no,
    //             gender: String(val.catalog_value_gender_id),
    //             education: val.catalog_value_qualification_id,
    //             selfemployed: val.catalog_value_employement_status,
    //             employerContact: val.employer_contact_number,
    //             employerName: val.employer_name,
    //             income: val.income_earned  !== null ? val.income_earned[0]: "",
    //             dob: val.date_of_birth,
    //             documentUploadResponse: {
    //                 fileId: val.user_photo_id
    //             },
    //             aadharcheck: val.is_aadhar_verified,
    //             is_aadhar_verified: val.is_aadhar_verified
    //         })

    //         setDob(val.date_of_birth)
    //         // }
    //     }
    // }, [users.getUserRegistrationDetails]);


    const getimage = (data) => {
        console.error("getimage data: " + data)
        if (data !== null) {
            dispatch(getFile(data))
        }
    }

    const [marital, setMarital] = React.useState([])
    const [category, setCategory] = React.useState([])
    const [Education, setEducation] = React.useState([])
    const [Religion, setReligion] = React.useState([])
    const [Income, setIncome] = React.useState([])
    const [SelfEmployed, setSelfEmployed] = React.useState([])
    const [NatureofWork, setNatureofWork] = React.useState([])
    const [states, setStates] = React.useState([])
    const [imagesavedinlocal, setimagesavedinlocal] = React.useState(false)

    useEffect(() => {
        if (users.getFile !== null) {
            sessionStorage.setItem('profilePic', users.getFile.data)
        }
    }, [users.getFile])

    useEffect(() => {
        setStates(users.state_details)
    }, [users.state_details]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Marital Status") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setMarital(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Category") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setCategory(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Education") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setEducation(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Religion") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setReligion(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Income") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setIncome(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Self Employed") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            setSelfEmployed(users.catalog_details)
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Nature of Work") {
            // console.error("elseif category: "+JSON.stringify(users.catalog_details))
            console.log("Natureofwork:",users.catalog_details);
            setNatureofWork(users.catalog_details)
        }
    }, [users.catalog_details]);

    const isNumeric = (str) => {
        if (typeof str != "string") return false
        return !isNaN(str) && !isNaN(parseFloat(str))
    }

    const handleAadharChange = (e, num) => {
        // console.log('handleAadharChange num:' + num)
        // console.log(e.target.value)
        // console.log(e.target.value.length)

        if (!isNumeric(e.target.value) && e.target.value !== "") {
            return;
        }

        if (e.target.value.length === 4) {
            const nextSibling = document.querySelector(
                `.aadhardiv${num + 1}>div>input`
            );
            if (num === 1) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum1: e.target.value,
                    })
                }
            }
            else if (num === 2) {
                if (nextSibling !== null) {
                    nextSibling.focus();
                    setAllValues({
                        ...allValues,
                        formcontrolnum: allValues.formcontrolnum + 1,
                        aadharnum2: e.target.value,
                    })
                }
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                    aadhar: allValues.aadharnum1 + allValues.aadharnum2 + e.target.value,
                });
            }
        }
        else {
            // console.log("num:"+num)
            if (num === 1) {
                setAllValues({
                    ...allValues,
                    aadharnum1: e.target.value,
                })
            }
            else if (num === 2) {
                setAllValues({
                    ...allValues,
                    aadharnum2: e.target.value,
                })
            }
            else if (num === 3) {
                setAllValues({
                    ...allValues,
                    aadharnum3: e.target.value,
                })
            }
        }
    }

    const checkAadhaarDuplicate = (from) => {
        setNextButtonClicked(true)

        // if(from === "saveAndContinue" && aadharValidated){
        //     nextButtonPressed()
        // }
        if(from === "saveAndContinue"){
            console.log("INSIDENEXT1");
            nextButtonPressed()
        }
        else if(from === "saveAndContinue" && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "personal_details" ){
            console.log("INSIDENEXT2");
            nextButtonPressed()
        }
        else if(from === "saveAndContinue" && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "90_days_certificate_details"){
            console.log("INSIDENEXT3");
            nextButtonPressed()
        }
        else if(from === "saveAndContinue" && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "address_details"){
            console.log("INSIDENEXT4");
            nextButtonPressed()
        }
        else if(from === "saveAndContinue" && users.getUserRegistrationDetails[0].tab_name !== null && users.getUserRegistrationDetails[0].tab_name === "bank_details"){
            console.log("INSIDENEXT5");
            nextButtonPressed()
        }
        else{
            console.log("INSIDENEXT6");
            // showToast("ERROR", "Please verify your aadhar to progress.")
            var currentDateTime = Date.now() 
            var randNum = Math.floor(100000 + Math.random() * 900000)
            console.log("randNum: " + randNum);
    
    
    
            console.log(currentDateTime,"currentDateTime")
    
            // Production Data
    
            var data = JSON.stringify({
                "deptCode": "9",
                "integrationKey": "0dac67ae-00bb-47fb-8c10-f5fc1776e91d",
                "integrationPassword": "-t-69hpP8K?Do-VX",
                "txnNo": randNum,
                "txnDateTime": currentDateTime,
                "serviceCode": "3",
                "responseRedirectURL": "https://kbocwwb.karnataka.gov.in/profile-unorganized"
            });

            // Local Production Data
            // var data = JSON.stringify({
            //     "deptCode": "9",
            //     "integrationKey": "0dac67ae-00bb-47fb-8c10-f5fc1776e91d",
            //     "integrationPassword": "-t-69hpP8K?Do-VX",
            //     "txnNo": randNum,
            //     "txnDateTime": currentDateTime,
            //     "serviceCode": "3",
            //     "responseRedirectURL": "http://localhost:3000/profile-unorganized"
            // });
            
            // DEVQA
            // var data = JSON.stringify({
            //     "deptCode": "9",
            //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
            //     "integrationPassword": "@895u5Xxe-2!7!!Y",
            //     "txnNo": randNum,
            //     "txnDateTime": currentDateTime,
            //     "serviceCode": "3",
            //     "responseRedirectURL": "https://gcdmsalpha.hostg.in:7701/profile-unorganized"
            // });

            // localhost
            //  var data = JSON.stringify({
            //     "deptCode": "9",
            //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
            //     "integrationPassword": "@895u5Xxe-2!7!!Y",
            //     "txnNo": randNum,
            //     "txnDateTime": currentDateTime,
            //     "serviceCode": "3",
            //     "responseRedirectURL": "http://localhost:3000/profile-unorganized"
            // });

            // var data = JSON.stringify({
            //     "deptCode": "9",
            //     "integrationKey": "a6ed232e-0bd9-4c24-89d7-67ba59bef639",
            //     "integrationPassword": "@895u5Xxe-2!7!!Y",
            //     "txnNo": randNum,
            //     "txnDateTime": currentDateTime,
            //     "serviceCode": "3",
            //     "responseRedirectURL": "https://gcdmsdev.web.app/profile-unorganized"
            // });
                 
                  var config = {
                    method: 'post',
                    url: SERVER+'/global/get_ekyc_token',
                    headers: { 
                        'Content-Type': 'application/json', 
                    },
                    data : data
                  };
                  
                  axios(config)
                  .then(function (response) {
                    setLoading(false)
                    console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data.token.Token));
                    setAadhaarToken(response.data.token.Token)
                    var aadharToken = response.data.token.Token
                    if(aadharToken !== null) {
                     //Production Data

                    window.open("https://dbt.karnataka.gov.in/HSMService/EKYCService.aspx?key=0dac67ae-00bb-47fb-8c10-f5fc1776e91d&token="+aadharToken, "_self")

                        // window.open("https://dbt.karnataka.gov.in:8443/HSM_Service_ASPX/EKYCService.aspx?key=a6ed232e-0bd9-4c24-89d7-67ba59bef639&token="+aadharToken, "_self")
                        setRedirectback(true)
                        
                    }
                     
              
                        
                    })
        }


    
// OLD AADHAR VALIDATION METHOD

        // if(allValues.aadharnum1.length === 4 && allValues.aadharnum2.length === 4 && allValues.aadharnum3.length === 4){
        //     setLoading(true)
        // setaadharDuplicateError(false)
        
        // var data = JSON.stringify({
        //     "key": "aadhar_no",
        //     "value": allValues.aadhar,
        //     "board_id": 1
        //   });
          
        //   var config = {
        //     method: 'post',
        //     url: SERVER+'/user/get-user',
        //     headers: { 
        //         'Content-Type': 'application/json',
        //         'Authorization':`Bearer ${getTokenCookie()}`
        //     },
        //     data : data
        //   };
          
        //   axios(config)
        //   .then(function (response) {
        //     setLoading(false)
        //     console.log("checkAadhaarDuplicate response:"+JSON.stringify(response.data,undefined,2));

        //     var check = true

        //     response.data.data.map((value) => {
        //         if(value.is_registered_user === true && value.labour_user_id !== users.user.id){
        //             // setaadharDuplicateError(true)
        //             // showToast("ERROR", "Aadhaar Already Registered!")
        //             check = false
        //         }
        //     })

        //     if(check && from === "saveAndContinue"){
        //         nextButtonPressed()
        //     }
        //     else if(check){
        //         handleValidateClick()
        //     }
        //     else{
        //         setaadharDuplicateError(true)
        //         showToast("ERROR", "Aadhaar Already Registered!")
        //     }

            
        //   })
        //   .catch(function (error) {
        //     setLoading(false)
        //     console.log("checkAadhaarDuplicate errir:"+error);
        //   });
        // }
        // else{
        //     showToast("ERROR", "Please enter your Aadhar number correctly")
        // }
        
          
    }

    const setPickerStatus = (status) => {
        setAllValues({
            ...allValues,
            openDatePicker: status
        });
    };

    const handleClickOpen = () => {
        setAllValues({
            ...allValues,
            open: !allValues.open
        })
    };

    const handleClose = () => {
        setAllValues({
            ...allValues,
            open: !allValues.open
        })
    };

    const changeHandler = e => {
        // console.log("in changeHandler: value=" + e.target.value + " name=" + e.target.name)

        if (e.target.name === "firstname") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length !== 0){
                setAllValues({
                    ...allValues,
                    firstnameError: false,
                    firstnameErrorMessage: "",
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    firstnameError: true,
                    firstnameErrorMessage: "Enter Correct Name!",
                    [e.target.name]: e.target.value
                })}
        }
        
        else if (e.target.name === "lastname") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length !== 0){
                setAllValues({
                    ...allValues,
                    lastnameError: false,
                    lastnameErrorMessage: "",
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    lastnameError: true,
                    lastnameErrorMessage: "Enter Correct Name!",
                    [e.target.name]: e.target.value
                })}
            
        }

        else if (e.target.name === "middlename") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value) && e.target.value.length !== 0){
                setAllValues({
                    ...allValues,
                    middlenameError: false,
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    middlenameError: true,
                    [e.target.name]: e.target.value
                })}
            
        }

        else if (e.target.name === "employerName") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isAlpha(e.target.value ,"en-US",{ignore: ' '}) && e.target.value.length < 50){
                setAllValues({
                    ...allValues,
                    employernameError: false,
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    employernameError: true,
                    [e.target.name]: e.target.value
                })}
        }

        
        else if (e.target.name === "employerContact") {
            // console.error("validate:" + validator.isNumeric(e.target.value))
            // console.error("phone.length:" + e.target.value.length)

            if (validator.isNumeric(e.target.value) && e.target.value.length === 10){
                setAllValues({
                    ...allValues,
                    employercontactError: false,
                    [e.target.name]: e.target.value
                })}
                else{
                setAllValues({
                    ...allValues,
                    employercontactError: true,
                    [e.target.name]: e.target.value
                })}
        }
    }

    const capture = () => {
        const imageSrc = myRef.current.getScreenshot();
        // console.log(imageSrc)

        sessionStorage.setItem('profilePic', imageSrc)
        setprofilePic(imageSrc)
        setFileUploaded(true)
        setAllValues({
            ...allValues,
            // imgfile: imageSrc,
            open: !allValues.open
        })
        uploadProfilePic(imageSrc)
        
    }

    const handleChange = (event) => {
        console.log(event.target)
        // console.log("in handleChange: value="+event.target.value+" name="+event.target.name)

        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })
        console.log("natureWorkplace1",allValues.natureWorkplace)
        console.log("religion1",allValues.religion)
        console.log("religion1",allValues.income)




    }

    useEffect(() => {
        
        if(allValues.natureWorkplace !== undefined){
            setAllValues({
                ...allValues,
                natureWorkplace : allValues.natureWorkplace
            })
        }
        console.log(allValues.natureWorkplace,"natureWorkplace2")

    },[allValues.natureWorkplace]);

    const [dob, setDob] = React.useState(users.personal_details.dob)

    useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
        setAllValues({ ...allValues, dob: dob })
    }, [dob]);

    useEffect(() => {
        console.log("in useEffect [allValues]: ",allValues)
        dispatch(updatePersonalData(allValues))
    }, [allValues]);

    const handleFileChange = (event) => {
        if (event.target.files[0] !== null) {
            if(event.target.files[0].size > 2000001){
                showToast("ERROR", "Please upload file of size less than 2MB.")
            }
            else if(!event.target.files[0].type.includes("image")){
                showToast("ERROR", "Please upload file in JPEG or PNG format.")
            }
            else{
            // var selectedblob = URL.createObjectURL(event.target.files[0])
            var selectedblob = event.target.files[0]
            var reader = new FileReader();
            reader.readAsDataURL(selectedblob);
            reader.onloadend = function () {
                var base64data = reader.result;
                console.log(base64data);

                sessionStorage.setItem('profilePic', base64data)
                setprofilePic(base64data)
                setAllValues({
                    ...allValues,
                    // imgfile: URL.createObjectURL(event.target.files[0])
                    // imgfile: base64data,
                    documentName: event.target.files[0].name
                })

                // uploadProfilePic(base64data)
            }
            }
        }

    }

    const uploadProfilePic = (base64data) => {
        if (base64data !== null && users.user !== null) {
            let formdata = {
                'file': base64data,
                'userId': users.user.id,
                'fileType': 'user_pic'
            }
            console.error("formdata: " + JSON.stringify(formdata))
            dispatch(uploadFile(formdata, "user_pic"))
        }
    }

    const handleEmailSubmit = () => {

        if (validator.isEmail(allValues.email)) {
            setAllValues({
                ...allValues,
                emailError: false
            })
        } else {
            setAllValues({
                ...allValues,
                emailError: true
            })
        }
    }

    const handleEnter = () => {
        // console.log("in handleEnter")

        var next_formcontrolnum = allValues.formcontrolnum + 1

        var nextSibling = document.querySelector(
            `.formcontrol${next_formcontrolnum} `
        );
        var nextSibling_input = document.querySelector(
            `.formcontrol${next_formcontrolnum} input`
        );
        // console.log("nextSibling:" + nextSibling.className)
        // console.log("nextSibling_input:" + nextSibling_input.className)

        if (nextSibling !== null) {
            if (nextSibling_input.className.includes("Mui-disabled")) {
                next_formcontrolnum = allValues.formcontrolnum + 2
                nextSibling = document.querySelector(
                    `.formcontrol${next_formcontrolnum} `
                );
                nextSibling_input = document.querySelector(
                    `.formcontrol${next_formcontrolnum} input`
                );
            }
            else if (nextSibling_input.className.includes("MuiSelect-nativeInput")) {
                nextSibling_input = document.querySelector(
                    `.formcontrol${next_formcontrolnum} .MuiSelect-select`
                );
            }
            nextSibling_input.focus();

            setAllValues({
                ...allValues,
                formcontrolnum: next_formcontrolnum
            })
        }

        let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
        console.log(regex.test(users.personal_details.pan), "hkavkbas")
        if (regex.test(users.personal_details.pan)) {
            setAllValues({
                ...allValues,
                panError: false
            })
        } else {
            setAllValues({
                ...allValues,
                panError: true
            })
            console.log("error", allValues.panError)
        }
    }

    const handleFormFocus = (data) => {
        console.log("in handleFormFocus data:" + data)
    }

    useEffect(() => {
        // console.log("in useEffect [allValues]: "+JSON.stringify(allValues))
        if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true && users.upload_file.image.fileType === 'user_pic') {


            if (users.upload_file.userFileName === "user_pic") {
                // setAllValues({
                //     ...allValues,
                //     documentUploadResponse: users.upload_file.image
                // }, () =>{

                // })
                submitPersonal(users.upload_file.image)



            }


        }
        else if (users.upload_file !== null && users.upload_file.success === undefined) {
            showToast("ERROR", "Cannot upload the profile image. Please try later.")
        }
    }, [users.upload_file]);


    const nextButtonPressed = () => {

        
        if (
            users.personal_details.firstname === "" ||
            // users.personal_details.lastname === "" ||
            users.personal_details.gender === "" ||
            users.personal_details.aadhar === 0 ||
            users.personal_details.maritalStatus === "" ||
            users.personal_details.dob === null ||
            users.personal_details.phoneNumber === "" ||
            allValues.natureWorkplace === "" ||
            allValues.religion === "" ||
            allValues.employerName === "" ||
            allValues.selfemployed === "" ||
            (allValues.isMigrant === "" || allValues.isMigrant === null || allValues.isMigrant === undefined || allValues.isMigrant.length === 0 ) ||
            
            // (allValues.isMigrant === 1 && allValues.migrantState.length === 0)|| 
            
            // users.personal_details.pan === "" ||
            // users.personal_details.isMigrant === "" || 
            // users.personal_details.imgfile === "" ||
            // users.personal_details.aadharcheck === false ||
            // users.personal_details.is_aadhar_verified === null ||
            // users.personal_details.is_aadhar_verified === undefined ||
            // users.personal_details.is_aadhar_verified === false ||
            sessionStorage.getItem('profilePic') === null ||
            users.personal_details.firstnameError === true ||
            // users.personal_details.lastnameError === true ||
            users.personal_details.middlenameError === true ||
            users.personal_details.employernameError === true ||
            users.personal_details.employercontactError === true


        ) {
            console.log("NEXTBUTTON1");
            showToast("WARN", "Please Fill All the Required Fields to Continue!")
        }
        
        else if(users.personal_details.isMigrant === "1" && (users.personal_details.migrantState === 0 || users.personal_details.migrantState === "") ){
            showToast("WARN","Please Select Migrant State" )
            console.log("NEXTBUTTON2");
        }
        else if(fileUploaded) {
            console.log("NEXTBUTTON3");
                uploadProfilePic(sessionStorage.getItem('profilePic'))
        }
        else{
            console.log("NEXTBUTTON4");
                submitPersonal()
            }
        }
    

    const submitPersonal = (documentUploadResponse) => {
        console.log("DOCUMENTRESPONE",documentUploadResponse,nextButtonClicked,imageEKYC);
        // if((documentUploadResponse !== undefined && nextButtonClicked) || 
        // (imageEKYC !== null && nextButtonClicked)
        // )
        // {

        let dataToSubmit = {
            "first_name": users.personal_details.firstname,
            "first_name_kannada": null,
            "first_name_hindi": null,
            "middle_name_kannada": null,
            "middle_name_hindi": null,
            "middle_name": users.personal_details.middlename,
            "last_name": users.personal_details.lastname,
            "last_name_kannada": null,
            "last_name_hindi": null,
            "user_id": users.user.id,
            "email": users.personal_details.email,
            "aadhar_no": vaultRef !== null && vaultRef !== undefined ? vaultRef :  vaultRefNumber,
            "pan_no": users.personal_details.pan,
            "martial_status_id": users.personal_details.maritalStatus,
            "caste_id": users.personal_details.category,
            "gender_id": users.personal_details.gender,
            "is_inter_state_migrant": users.personal_details.isMigrant,
            "migrate_from_state_id": users.personal_details.migrantState,
            // "user_photo_id": fileUploaded ? documentUploadResponse.fileId : imageEKYC,
            "user_photo_id": "6575b67e3c8eee4cf60621f2",
            "dob": users.personal_details.dob,
            "is_aadhar_verified": users.personal_details.is_aadhar_verified,
            "catalog_value_qualification_id": allValues.education,
            "qualification": allValues.education,
            "catalog_value_employement_status": allValues.selfemployed,
            "pf_esi_number": "",
            "employement_status": allValues.selfemployed,
            "catalog_value_religion_id": allValues.religion,
            "religion": allValues.religion,
            "employer_name": allValues.employerName,
            "employer_contact_number": allValues.employerContact,
            "employer_contact_country_code": "+91",
            "catalog_valuenature_of_work": allValues.natureWorkplace,
            "income_earned": allValues.income,
            "is_ayushman_card_holder":0,
        }

        console.log("income_earned", allValues.income)
        console.error("dataToSubmit", JSON.stringify(dataToSubmit))


        dispatch(addLabourPersonalDetails(dataToSubmit))
        // }
    }

    const initPersonalState = {
        selectedDate: null,
        phoneNumber: "",
        maritalStatus: "",
        category: "",
        migrantState: "",
        isMigrant: false,
        pancheck: false,
        aadharcheck: false,
        imgfile: "",
        webcamEnabled: false,
        open: false,
        setOpen: false,
        openDatePicker: false,
        formcontrolnum: 1,
        aadharnum1: null,
        aadharnum2: null,
        aadharnum3: null,
        aadhar: null,
        firstname: "",
        middlename: "",
        lastname: "",
        email: "",
        fatherHusbandName: "",
        pan: "",
        gender: "",
        dob: null,
        documentUploadResponse: ""
    }

    useEffect(() => {
        console.error("useEffect personalSubmitted:" + allValues.personalSubmitted)
        // if(!allValues.personalSubmitted){
        // console.error("useEffect !personalSunload Abmitted in if")
        // console.error("users.addLabourPersonalDetails.code: "+users.addLabourPersonalDetails.code)
        if (users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success !== undefined && users.addLabourPersonalDetails.success !== null && users.addLabourPersonalDetails.success === true) {
            console.error("useEffect !allValues.personalSubmitted in if > if")
            showToast("SUCCESS", "Personal Details Saved Successfully!")

            dispatch(updatePersonalData(initPersonalState))
            dispatch(getFile("delete"))
            dispatch(addLabourPersonalDetails("delete"))

            if (users.profile_details.editmode) {
                props.saveEditButtonPressed()
            }
            else {
                props.nextButtonPressed()
            }
        }
        else if (users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.success !== undefined && users.addLabourPersonalDetails.success !== null && users.addLabourPersonalDetails.success === "false") {
            console.error("useEffect !allValues.personalSubmitted in if > else if success")
            showToast("ERROR", "Error Saving Personal Details!")
            setLoading(false);
        }
        else if (users.addLabourPersonalDetails !== null && users.addLabourPersonalDetails.error !== undefined && users.addLabourPersonalDetails.error === true) {
            console.error("useEffect !allValues.personalSubmitted in if > else if error")
            showToast("ERROR", "Error Saving Personal Details!")
            setLoading(false);
        }

        // }
    }, [users.addLabourPersonalDetails])



    const [captchaDivShow, setcaptchaDivShow] = React.useState(true);
    const [otpSent, setotpSent] = React.useState(false);

    const [captcha, setcaptcha] = React.useState("");
    const [secretToken, setsecretToken] = React.useState("");
    const [tsTransID, settsTransID] = React.useState("");
    const [tsTransIDAfterOtp, settsTransIDAfterOtp] = React.useState("");

    const getNewCaptcha = () => {

        setcaptcha("")
        setsecretToken("")
        settsTransID("")
        setrotateRefressh('rotate')
        setcaptchaError('')


        var randNum = Math.floor(100000 + Math.random() * 900000)
        console.log("randNum: " + randNum);

        var data = JSON.stringify({
            "transID": randNum,
            "docType": "347"
        });
        var config = {
            method: 'post',
            url: SERVER + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step1 response:" + JSON.stringify(response.data));


                // STEP 2
                var data2 = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER + '/global/auth_bridge_generate_captcha',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data2
                };

                axios(config)
                    .then(function (response) {
                        console.log("step2 response:" + JSON.stringify(response.data));

                        //step3

                        var data3 = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER + '/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data3
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step3 response: " + JSON.stringify(response.data));
                                setcaptcha(response.data.data.msg.captchaCode)
                                // setAllValues({
                                //     ...allValues,
                                //     secretToken: response.data.data.msg.secretToken,
                                //     tsTransID: response.data.data.msg.tsTransID,
                                // })
                                setsecretToken(response.data.data.msg.secretToken)
                                settsTransID(response.data.data.msg.tsTransID)

                                setrotateRefressh('')
                            })
                            .catch(function (error) {
                                console.error("step3 error: " + error);
                                if (error.response !== undefined &&
                                    error.response.data !== undefined &&
                                    error.response.data.errors !== undefined &&
                                    error.response.data.errors.msg !== undefined &&
                                    error.response.data.errors.msg.msg !== undefined
                                ) {
                                    console.error("step3 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                                    setcaptchaError(error.response.data.errors.msg.msg)
                                }
                                setrotateRefressh('')
                            });

                    })
                    .catch(function (error) {
                        console.error("step2 error: " + error);
                        if (error.response !== undefined &&
                            error.response.data !== undefined &&
                            error.response.data.errors !== undefined &&
                            error.response.data.errors.msg !== undefined &&
                            error.response.data.errors.msg.msg !== undefined
                        ) {
                            console.error("step2 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                            setcaptchaError(error.response.data.errors.msg.msg)
                        }
                        setLoading(false);
                        setrotateRefressh('')
                    });

            })
            .catch(function (error) {
                console.error("step1 error: " + error);
                if (error.response !== undefined &&
                    error.response.data !== undefined &&
                    error.response.data.errors !== undefined &&
                    error.response.data.errors.msg !== undefined &&
                    error.response.data.errors.msg.msg !== undefined
                ) {
                    console.error("step1 error.response.data.errors.msg.msg: " + error.response.data.errors.msg.msg);
                    setcaptchaError(error.response.data.errors.msg.msg)
                }
                setLoading(false);
                setrotateRefressh('')
            });
    }

    const handleValidateClick = () => {
        if (!loading) {
            setLoading(true);

            var randNum = Math.floor(100000 + Math.random() * 900000)
            console.log("randNum: " + randNum);


            var data = JSON.stringify({
                "transID": randNum,
                "docType": 347,
                "docNumber": allValues.aadhar,
                "secretToken": secretToken,
                "tsTransID": tsTransID,
                "captchaCode": allValues.captchaText,
                "actionType": "otp",
                "consent": 1
            });

            var config = {
                method: 'post',
                url: SERVER + '/global/encrypt_string',
                headers: {
                    'Content-Type': 'application/json',
                    'username': TS_USERNAME
                },
                data: data
            };

            axios(config)
                .then(function (response) {
                    console.log("step4 response: " + JSON.stringify(response.data));

                    var data = JSON.stringify({
                        "requestData": response.data.data
                    });
                    var config = {
                        method: 'post',
                        url: SERVER + '/global/auth_bridge_id_search',
                        headers: {
                            'Content-Type': 'application/json',
                            'username': TS_USERNAME
                        },
                        data: data
                    };

                    axios(config)
                        .then(function (response) {
                            console.log("step5 response: " + JSON.stringify(response.data));

                            var data = JSON.stringify({
                                "responseData": response.data.data.responseData
                            });

                            var config = {
                                method: 'post',
                                url: SERVER + '/global/decrypt_string',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'username': TS_USERNAME
                                },
                                data: data
                            };

                            axios(config)
                                .then(function (response) {
                                    console.log("step6 response: " + JSON.stringify(response.data))

                                    if (response.data.data.msg === "OTP sent successfully") {
                                        setLoading(false)
                                        setotpSent(true)

                                        settsTransIDAfterOtp(response.data.data.tsTransID)
                                    }
                                    else {
                                        setLoading(false)
                                        showToast("ERROR", response.data.errors.msg)
                                    }

                                })
                                .catch(function (error) {
                                    setLoading(false)
                                    console.error("step6 error:" + error);
                                    console.error("step6 error:" + JSON.stringify(error.response));
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                    // showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")

                                    // showToast("ERROR", error)
                                });
                        })
                        .catch(function (error) {
                            setLoading(false)
                            console.error("step5 error:" + error);
                            console.error("step5 error:" + JSON.stringify(error.response));
                            showToast("ERROR", error.response.data.errors.msg.msg)
                        });
                })
                .catch(function (error) {
                    setLoading(false)
                    console.error("step4 error: " + error);
                    console.error("step4 error:" + JSON.stringify(error.response));
                    showToast("ERROR", error.response.data.errors.msg.msg)
                    // showToast("ERROR", "Something went wrong! Please verify Aadhaar again.")
                });



            // timer.current = window.setTimeout(() => {
            // setSuccess(true);
            // setLoading(false);
            // }, 2000);
        }
    }

    const validateAadhaar = () => {

        setLoading(true)

        var data = JSON.stringify({
            "transID": tsTransIDAfterOtp,
            "shareCode": "1234",
            "mobileCode": allValues.otp
        });

        var config = {
            method: 'post',
            url: SERVER + '/global/encrypt_string',
            headers: {
                'Content-Type': 'application/json',
                'username': TS_USERNAME
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log("step7 response: " + JSON.stringify(response.data));

                var data = JSON.stringify({
                    "requestData": response.data.data
                });

                var config = {
                    method: 'post',
                    url: SERVER + '/global/auth_bridge_aadhar_request',
                    headers: {
                        'Content-Type': 'application/json',
                        'username': TS_USERNAME
                    },
                    data: data
                };

                axios(config)
                    .then(function (response) {
                        console.log("step8 response: " + JSON.stringify(response.data));

                        var data = JSON.stringify({
                            "responseData": response.data.data.responseData
                        });

                        var config = {
                            method: 'post',
                            url: SERVER + '/global/decrypt_string',
                            headers: {
                                'Content-Type': 'application/json',
                                'username': TS_USERNAME
                            },
                            data: data
                        };

                        axios(config)
                            .then(function (response) {
                                console.log("step9 response: " + JSON.stringify(response.data));

                                if (response.data.data.status === "1") {
                                    showToast("SUCCESS", "Aadhaar Verified Successfully")
                                    setcaptchaDivShow(false)
                                    setotpSent(false)

                                    var first_name = ""
                                    var middle_name = ""
                                    var last_name = ""
                                    var nameArr = response.data.data.msg.Name.split(' ')
                                    console.log("nameArr: " + nameArr[0])
                                    if (nameArr.length === 1) {
                                        first_name = nameArr[0]
                                    }
                                    else if (nameArr.length === 2) {
                                        first_name = nameArr[0]
                                        last_name = nameArr[1]
                                    }
                                    else if (nameArr.length >= 3) {
                                        first_name = nameArr[0]
                                        middle_name = nameArr[1]
                                        last_name = nameArr[2]
                                    }
                                    console.log("first_name: " + first_name)
                                    console.log("middle_name: " + middle_name)
                                    console.log("last_name: " + last_name)


                                    var gender = null
                                    if (response.data.data.msg.Gender === "Male") {
                                        gender = "27"
                                    }
                                    else if (response.data.data.msg.Gender === "Female") {
                                        gender = "28"
                                    }
                                    else {
                                        gender = "29"
                                    }
                                    console.log("gender: " + gender)

                                    cookie.save('AadhaarFetchedData', JSON.stringify(response.data.data.msg), { path: '/' })

                                    setAllValues({
                                        ...allValues,
                                        aadharcheck: true,
                                        aadhaarFetchedData: response.data.data.msg,
                                        is_aadhar_verified: true,
                                        firstname: first_name,
                                        middlename: middle_name,
                                        lastname: last_name,
                                        gender: gender

                                    })
                                    setDob(response.data.data.msg.DOB)

                                    setLoading(false)


                                    var config = {
                                        method: 'get',
                                        url: SERVER + `/global/convert_image_base_64?image_link=${response.data.data.msg.Image}`,
                                        headers: {},
                                        // data : data
                                    };

                                    axios(config)
                                        .then(function (response) {
                                            console.log(JSON.stringify(response.data));
                                            setprofilePic(response.data.data.base_64)
                                            sessionStorage.setItem('profilePic', response.data.data.base_64)
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            if (error.response.data.errors.msg.msg !== undefined) {
                                                showToast("ERROR", error.response.data.errors.msg.msg)
                                            }
                                        });



                                    var dataAadhaar = JSON.stringify({
                                        "labour_user_id": users.user.id,
                                        "name": response.data.data.msg.Name,
                                        "dob": response.data.data.msg.DOB,
                                        "gender": response.data.data.msg.Gender,
                                        "house_no": response.data.data.msg['House Number'],
                                        "street": response.data.data.msg.Street,
                                        "locality": response.data.data.msg.Locality,
                                        "landmark": response.data.data.msg.Landmark,
                                        "postoffice": response.data.data.msg['Post Office'],
                                        "village_city_town": response.data.data.msg['Village/Town/City'],
                                        "state": response.data.data.msg.State,
                                        "district": response.data.data.msg.District,
                                        "sub_district": response.data.data.msg['Sub District'],
                                        "country": response.data.data.msg.Country,
                                        "pincode": response.data.data.msg.Pincode,
                                        "image": response.data.data.msg.Image,
                                        "father_name": response.data.data.msg['Father Name'],
                                        "document_link": response.data.data.msg['Document link']
                                    });
                                    var configdataAadhaar = {
                                        method: 'post',
                                        url: SERVER + '/user/update_ad_details',
                                        headers: {
                                            'Content-Type': 'application/json',
                                            'Authorization': `Bearer ${getTokenCookie()}`
                                        },
                                        data: dataAadhaar
                                    };

                                    axios(configdataAadhaar)
                                        .then(function (response) {
                                            console.log(JSON.stringify(response.data));
                                        })
                                        .catch(function (error) {
                                            console.log(error);
                                            if (
                                                error.response !== undefined &&
                                                error.response.data !== undefined &&
                                                error.response.data.errors !== undefined &&
                                                error.response.data.errors.msg !== undefined &&
                                                error.response.data.errors.msg.msg !== undefined
                                            ) {
                                                showToast("ERROR", error.response.data.errors.msg.msg)
                                            }
                                        });

                                }

                            })
                            .catch(function (error) {
                                console.error("step9 error: " + error);
                                if (error.response.data.errors.msg.msg !== undefined) {
                                    showToast("ERROR", error.response.data.errors.msg.msg)
                                }
                                setLoading(false)
                            });

                    })
                    .catch(function (error) {
                        console.error("step8 error: " + error);
                        if (error.response.data.errors.msg.msg !== undefined) {
                            showToast("ERROR", error.response.data.errors.msg.msg)
                        }
                        setLoading(false)
                    });

            })
            .catch(function (error) {
                console.error("step7 error: " + error);
                if (error.response.data.errors.msg.msg !== undefined) {
                    showToast("ERROR", error.response.data.errors.msg.msg)
                }
                setLoading(false)
            });
    }

    // useEffect(() => {

    //     console.log("in useeffect AadhaarFetchedData Cookie")
    //     var AadharData = cookie.load('AadhaarFetchedData') 
    //     if(AadharData !== undefined && AadharData !==''){
    //         console.log("AadhaarFetchedData Cookie: "+AadharData)

    //         var first_name = ""
    //         var middle_name = ""
    //         var last_name = ""
    //         var nameArr = AadharData.Name.split(' ')
    //         if (nameArr.length === 1) {
    //             first_name = nameArr[0]
    //         }
    //         else if (nameArr.length === 2) {
    //             first_name = nameArr[0]
    //             middle_name = nameArr[1]
    //         }
    //         else if (nameArr.length >= 3) {
    //             first_name = nameArr[0]
    //             middle_name = nameArr[1]
    //             last_name = nameArr[2]
    //         }

    //         setDob(AadharData.DOB)

    //         var gender = null
    //         if(AadharData.Gender === "Male"){
    //             gender = 27
    //         }
    //         else if(AadharData.Gender === "Female"){
    //             gender = 28
    //         }
    //         else{
    //             gender = 29
    //         }

    //         setAllValues({
    //             ...allValues,
    //             aadharcheck: true,
    //             // aadhaarFetchedData: response.data.data.msg,
    //             is_aadhar_verified: true,

    //             firstname: first_name,
    //             middlename: middle_name,
    //             lastname: last_name,

    //             gender: gender

    //         })
    //     }

    // }, [cookie.load('AadhaarFetchedData')])    

    console.log("Religion",allValues.religion);

    return (
        <>
            <Row className="m-0">
            <Row xs = {12} >
                                    { !users.personal_details.is_aadhar_verified === true ? 
                                        <div className="ekyc-verify d-flex justify-content-center" style = {{ marginTop : "50px"}}>
                                    <h3 style = {{marginRight : "20px", marginTop : "10px"}}> Click here to verify your Aadhaar</h3>
                                        <Button className='aadhar-verify-btn'
                                            variant="outline-primary"
                                            // Rowor="primary"
                                            // className={buttonClassname}
                                            disabled={loading}
                                            // onClick={handleValidateClick}
                                            onClick={checkAadhaarDuplicate}
                                        >
                                            Verify
                                        </Button>
                                        {loading && <CircularProgress size={24} className="buttonProgress" />}
                                    </div>

                                   : 
                                   <div className="ekyc-verify d-flex justify-content-center" style = {{ marginTop : "50px"}}>
                                    <h3 style = {{marginRight : "20px", marginTop : "10px"}}>Aadhaar Verified Successfully</h3>
                                        
                                        
                                    </div> }
                                    
                                    </Row>
                 <Col xs={12} md={12} className="personal-col-2">
                    <div className="form-inner-card-right mt-0">
                        <div className="picupload">

                            {
                                // sessionStorage.getItem('profilePic') === null || sessionStorage.getItem('profilePic') === ""
                                // profilePic === '' ?
                                profilePic === undefined || profilePic === "" || profilePic === null ?
                                    (
                                        <>
                                            <CameraAlt className="cameraAlt" />

                                            <p className="upload-title required-p" style={{ width: "auto" }}>
                                               <Translate value="uploadYourProfilePicture"/> <span>*</span>
                                            </p>
                                        </>
                                    )
                                    :
                                    (
                                        <>
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // src={allValues.imgfile}
                                                // src={sessionStorage.getItem('profilePic')}
                                                // src={profilePic}
                                                src={`${profilePic}`}
                                                key={profilePic}
                                            />
                                            {/* <p className="upload-title">
                                        {allValues.documentName}</p> */}
                                        </>
                                    )
                            }

                            {/* <p className="upload-title">
                                Upload Your Profile Picture</p> */}

                            <div className="browse-buttons-div my-4">

                                <label
                              
                                    htmlFor="upload-photo"
                                    className="browse-button">
                                    <img alt="..." src={folder} /><Translate value="browse"/>
                            </label>
                                <input type="file" name="photo" id="upload-photo" onChange={handleFileChange} />
                                <div className="or-between-buttons" >
                                    or
                                </div>
                                <p onClick={handleClickOpen} className="webcam-button" >
                                    <img alt="..." src={webcam} /> <Translate value="useWebCamera"/>
                                </p>


                            </div>



                            <Dialog
                                open={allValues.open}
                                onClose={handleClose}
                                aria-labelledby="scroll-dialog-title"
                                aria-describedby="scroll-dialog-description"
                            // fullWidth={true}
                            // maxWidth='750px'
                            >
                                <DialogTitle id="scroll-dialog-title"><Translate value="captureImageforProfilePicture"/></DialogTitle>
                                <DialogContent>
                                    <Webcam
                                        audio={false}
                                        height={400}
                                        ref={myRef}
                                        screenshotFormat="image/jpeg"
                                        // width={400}
                                        mirrored
                                        fullWidth
                                        videoConstraints={videoConstraints}
                                    />
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={handleClose} color="primary">
                                        Cancel
                            </Button>
                                    <Button onClick={capture} color="primary">
                                        Capture
                            </Button>
                                </DialogActions>
                            </Dialog>


                        </div>
                        <p className="instructions" style = {{marginLeft: "600px"}}>
                            <span><Translate value="instructions"/>*</span>
                            {/* (Upload your profile picture in jpg/png format only) */}
                            <Translate value="uploadyourprofilepictureinjpgpngformatonly"/>

                        </p>

                        <div className="pic-below-border"></div>
                        </div>
                    </Col>
              {/* {allValues.firstname && allValues.firstname.length>0 &&   <Row className="form-row mt-4">
                    <Col xs={12} md={12} className="personal-col-1">
                    <div className="browse-buttons-div">
                        <img
                            alt="..."
                            className="avatar border-gray"
                            src={`${profilePic}`}
                            key={profilePic}
                        />
                    </div>
                    </Col>
                    </Row>} */}
                    <Row className="form-row mt-4">
                    <Col xs={12} md={6} className="personal-col-1">

                        <div className="form-inner-card-right">
                        <Required className="mb-2" title={<Translate value ="firstName"/>} />
                            {/* <p className="mb-2">Enter Your Full Name </p>  */}
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your First Name"
                                    name="firstname"
                                    value={allValues.firstname}
                                    onChange={changeHandler}
                                    error={allValues.firstnameError}
                                    helperText={allValues.firstnameError ? "Please enter in correct format" : ""}
                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>


                            <p className="mt-4 mb-1" ><Translate value="lastName"/> </p>
                            {/* <p className="mt-4 mb-2">Last Name </p>  */}
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Last Name"
                                    name="lastname"
                                    value={allValues.lastname}
                                    onChange={changeHandler}
                                    error={allValues.lastnameError}
                                    helperText={allValues.lastnameError ? "Please enter in correct format" : ""}
                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>

                            {/* <p className="mt-5 mb-2">Marital Status*</p> */}
                            <Required className="mt-4 mb-2" title={<Translate value="maritalStatus"/>} />
                            <FormControl variant="outlined" fullWidth className="formcontrol5"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.maritalStatus}
                                    name="maritalStatus"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>
                                    {marital.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                    {/* <MenuItem value="">
                                        <ListItemText primary="-Select-" />
                                    </MenuItem>
                                    <MenuItem value="Single">
                                        <ListItemText primary="Single" />
                                    </MenuItem>
                                    <MenuItem value="Married">
                                        <ListItemText primary="Married" />
                                    </MenuItem>
                                    <MenuItem value="Widowed">
                                        <ListItemText primary="Widowed" />
                                    </MenuItem>
                                    <MenuItem value="Divorced">
                                        <ListItemText primary="Divorced" />
                                    </MenuItem> */}
                                </Select>
                            </FormControl>
                            {/* <Required className="mt-4 mb-2" title="Enter Your Mobile Number"/> */}
                            <p className="mt-4 mb-2"><Translate value="enterYourMobileNumber"/></p>
                            <FormControl fullWidth className="formcontrol2"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <PhoneAndroid className="phone-icon" />
                                                +91
                                            </InputAdornment>,
                                    }}
                                    variant="outlined"
                                    value={allValues.phoneNumber}
                                    disabled
                                />
                            </FormControl>

                            <Required className="mt-4 mb-2" title={<Translate value="educationlevel" />} />
                            <FormControl variant="outlined" fullWidth className="formcontrol5"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.education}
                                    name="education"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {Education.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <Required className="mt-4 mb-2" title={<Translate value="selfemployed" />} />
                            <FormControl variant="outlined" fullWidth className="formcontrol5"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.selfemployed}
                                    name="selfemployed"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {SelfEmployed.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>

                            <p className="mt-4 mb-2"><Translate value="employercontact"/></p>
                            <FormControl fullWidth className="formcontrol2"
                            // onKeyPress={event => {
                            //     if (event.key === "Enter") {
                            //         // handleEnter();
                            //     }
                            // }}>
                            >
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <PhoneAndroid className="phone-icon" />
                                                +91
                                            </InputAdornment>,
                                    }}
                                    variant="outlined"
                                    name="employerContact"
                                    onChange={changeHandler}
                                    value={allValues.employerContact}
                                    error={phoneError}
                                    helperText={phoneError}
                                    onInput={(e)=>{
                                        e.target.value = e.target.value.replace(/[^0-9]/ig,'')
                                        if(e.target.value.length === 0){
                                            setPhoneError("Please Enter Mobile Number")
                                        }
                                        else if (e.target.value.length < 10){
                                            setPhoneError("Mobile number must contain 10 digits")
                                        }
                                        else{
                                            setPhoneError("")
                                        }
                                    }}
                                    inputProps={{minLength:0,maxLength:10}}

                                />
                            </FormControl>


                            <Required className="mt-4 mb-2" title={<Translate value="employername" />} />
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Employer Name"
                                    name="employerName"
                                    value={allValues.employerName}
                                    onChange={changeHandler}
                                    error={allValues.employernameError}
                                    helperText={allValues.employernameError ? "Please enter in correct format" : ""}                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>
                        </div>
                    </Col>


                    <Col xs={12} md={6} className="personal-col-3">
                        <div className="form-inner-card-right">


                        <p className="mb-2"><Translate value="middleName"/> </p>
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Middle Name"
                                    name="middlename"
                                    value={allValues.middlename}
                                    onChange={changeHandler}
                                    error={allValues.middlenameError}
                                    helperText={allValues.middlenameError ? "Please enter in correct format" : ""}
                                // onFocus={handleFormFocus()}
                                />
                            </FormControl>

                            {/* <p className="mt-5 mb-0">Select Your Gender*</p> */}
                            <Required className="mt-4 mb-0  " title={<Translate value="selectYourGender"/>} />
                            <div className="gender-div">
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="27" id="male"
                                        onChange={handleChange} checked={allValues.gender === "27"} />
                                    <label
                                        htmlFor="male"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/avatars-21/512/avatar-circle-human-male-4-512.png" /> */}
                                        <img alt="..." src={male} />
                                        <span><Translate value='male'/></span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="28" id="female" onChange={handleChange} checked={allValues.gender === "28"} />
                                    <label
                                        htmlFor="female"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://www.pngkey.com/png/full/203-2037403_flat-faces-icons-circle-girl-flat-icon-png.png" /> */}
                                        <img alt="..." src={female} />
                                        <span><Translate value='female'/></span>
                                    </label>
                                </div>
                                <div className="button genderradio">
                                    <input type="radio" name="gender" value="29" id="others" onChange={handleChange} checked={allValues.gender === "29"} />
                                    <label
                                        htmlFor="others"
                                    >
                                        {/* <img alt="..."  className="gender-img" src="https://cdn4.iconfinder.com/data/icons/we-are-different-v1-1/512/transgender-1-512.png" /> */}

                                        <img alt="..." src={radioon} />
                                        <span><Translate value='others'/></span>
                                    </label>
                                </div>
                            </div>

                            <p className="mt-4 mb-2"><Translate value="category"/></p>
                            <FormControl variant="outlined" fullWidth className="formcontrol6"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.category}
                                    name="category"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {category.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>
                            {/* <p className=" mb-2">Enter Your Father / Husband Name*</p>
                            <FormControl fullWidth  className="formcontrol4"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <TextField
                                    variant="outlined"
                                    placeholder="Enter Your Father/Husband Name"
                                    name="fatherHusbandName"
                                    value={allValues.fatherHusbandName}
                                    onChange={handleChange}
                                    // onFocus={handleFormFocus()}
                                />
                            </FormControl> */}
                            <div className="mt-4">
                                {/* <p className=" mb-2">Date of Birth*</p> */}
                                <Required className="mb-2" title={<Translate value="dateofBirth"/>} />
                                <MuiPickersUtilsProvider utils={MomentUtils} >
                                    <KeyboardDatePicker
                                        maxDate={new Date()}
                                        onClick={() => setPickerStatus(true)}
                                        onClose={() => setPickerStatus(false)}
                                        open={allValues.openDatePicker}
                                        className="datepicker"
                                        margin="normal"
                                        format="D/MM/yyyy"
                                        value={dob}
                                        // value={allValues.dob}
                                        // value={selectedDate}
                                        // onChange={ handleDateChange }
                                        // onChange={(date) => { handleDateChange(date) }}
                                        onChange={(date) => { setDob(date) }}
                                        placeholder="DD/MM/YYYY"
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                </MuiPickersUtilsProvider>


                            </div>

                            <Required className="mt-4 mb-2" title={<Translate value="religion"/>} />
                            <FormControl variant="outlined" fullWidth className="formcontrol5"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.religion}
                                    name="religion"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {Religion.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>


                            <Required className="mt-4 mb-2" title={<Translate value="natureofWork" />} />
                            {/* <p className="mt-3 mb-2">Nature of the Workplace*</p> */}
                            <FormControl variant="outlined" fullWidth className="formcontrol5">
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.natureWorkplace}
                                    name="natureWorkplace"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {NatureofWork.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>



                            <p className="mt-4 mb-2"><Translate value="income"/></p>
                            <FormControl variant="outlined" fullWidth className="formcontrol5">
                                <Select
                                    className="select-marital"
                                    labelId="demo-simple-select-required-label"
                                    id="demo-simple-select-required"
                                    value={allValues.income}
                                    name="income"
                                    displayEmpty
                                    onChange={handleChange}
                                >
                                    <MenuItem value="">
                                        <ListItemText primary="Select" />
                                    </MenuItem>

                                    {Income.map((i) =>
                                        <MenuItem value={i.value_id}>
                                            <ListItemText primary={i.value} />
                                        </MenuItem>
                                    )}
                                </Select>
                            </FormControl>



                            {/* <p className="mt-4 mb-2"><Translate value="employeraddress"/></p>
                            <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                    }
                                }}>
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3}
                                    // rowsMax={4}
                                    // defaultValue="Default Value"
                                    placeholder="Enter Address of Employer"
                                    name="address"
                                    value={allValues.address}
                                    onChange={handleChange}
                                />
                            </FormControl> */}
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="personal-col-7 mt-4 ">
                        <div className="form-inner-card-right mt-0">
                            <FormControl className="mt-2 mb-2 interstateRadio formcontrol9" variant="outlined" fullWidth component="fieldset"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        handleEnter();
                                    }
                                }}>
                                {/* <p className="mt-2 mb-2 mr-2">Interstate Migrant Worker</p> */}
                                <RadioGroup row name="isMigrant" value={allValues.isMigrant} onChange={handleChange}>
                                 <Required className="mt-4 mb-2" title={<Translate value="interstateMigrantWorker"/>} />   
                                    <FormControlLabel value="1" control={<Radio />} label={<Translate value="yes"/>} />
                                    <FormControlLabel value="0" control={<Radio />} label={<Translate value="no"/>} />
                                </RadioGroup>
                            </FormControl>

                            {
                                allValues.isMigrant === "1" ?

                                    (
                                        <>
                                            {/*<p className="mt-2 mb-2"><Translate value="migrantFromState"/>*</p>*/}
                                            <Required className="mt-4 mb-2" title={<Translate value="migrantFromState" />} />
                                            <FormControl variant="outlined" fullWidth >
                                                <Select
                                                    className="select-state"
                                                    labelId="demo-simple-select-required-label"
                                                    id="demo-simple-select-required"
                                                    value={allValues.migrantState}
                                                    name="migrantState"
                                                    displayEmpty
                                                    onChange={handleChange}
                                                >
                                                    <MenuItem value="">
                                                        <ListItemText primary="Select" />
                                                    </MenuItem>

                                                    {states.map((i) =>
                                                        i.name !== "Karnataka" &&
                                                        <MenuItem value={i.id}>
                                                            <ListItemText primary={i.short_name + " | " + i.name} />
                                                        </MenuItem>
                                                    )}


                                                </Select>
                                            </FormControl>
                                        </>
                                    )

                                    : null
                            }
                        </div>
                    </Col>
                    <Col xs={12} className="note2 text-center mt-3 personal-col-7">
                        {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
                        <p><span><Translate value="note"/> </span><Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess"/></p>

                    </Col>


                </Row>
                {/* <Row className="button-inside-form-row">
                    <Col xs={12} className="note2 text-center mt-5">
                        <p>Note : After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p>
                    </Col>
                </Row> */}

                <Row className="button-inside-form-row mb-5">
                    <Col xs={12} className="next-back-button-row mt-4 ">

                        {
                            !users.profile_details.editmode ?
                                <>
                                    <Link to="#" onClick={props.backButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="danger" className="back-button" >
                                            <Translate value="back"/>
                                        </Button>
                                    </Link>

                                    {
                                        loading ?
                                            <CircularProgress className="loader-otp" /> :
                                            <Link to="#" 
                                            onClick={() => checkAadhaarDuplicate("saveAndContinue")}
                                                style={{ flexGrow: "0.5" }}
                                            >
                                                <Button variant="outline-primary" className="next-button">
                                                    <Translate value="saveandContinue"/>
                                                </Button>
                                            </Link>
                                    }
                                    {/* <Link to="#" onClick={nextButtonPressed}
                                        style={{ flexGrow: "0.5" }}
                                    >
                                        <Button variant="outline-primary" className="next-button">
                                            Save and Continue
                                        </Button>
                                    </Link> */}
                                </>

                                :

                                <Link to="#" 
                                onClick={() => checkAadhaarDuplicate("saveAndContinue")}
                                    style={{ flexGrow: "0.5" }}
                                >
                                     <Button variant="outline-primary" className="next-button">
                                        <Translate value="saveyourDetails"/>
                                    </Button>
                                </Link>

                        }


                    </Col>
                </Row>
            </Row>

        </>
    )

}

export default Personal