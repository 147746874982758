import { CircularProgress, FormControl, TextField } from '@material-ui/core';
import moment from 'moment';
import React, { Suspense, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getFieldsValueAPI } from './services/schemes.service';
import { useState } from 'react';

const SchemeFields = ({ fields }) => {
  const { schemeName } = useParams();

  return (
    <>
      <Suspense fallback={<CircularProgress color="inherit" />}>
        <Row className="scheme-subtitle-row">
          <Col xs={12} className="searchform-subtitle">
            {<p>{schemeName} Fields</p>}
          </Col>
        </Row>
        <Row className="form-row mt-4 config-form searchform">
          {fields &&
            fields.map((field) => (
              <Col
                xs={12}
                md={4}
                style={{ alignSelf: 'end' }}
                key={field.field}
              >
                <p className="mb-2 mt-4">{field.field} </p>
                <FormControl fullWidth className="formcontrol1">
                  <TextField
                    variant="outlined"
                    value={field.field_value}
                    disabled
                  />
                </FormControl>
              </Col>
            ))}
        </Row>
      </Suspense>
    </>
  );
};

export default SchemeFields;
