import React, { useEffect, useState } from 'react';

import { Col, Row, Button } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  FormControl,
  InputAdornment,
  TextField,
  TextareaAutosize,
} from '@material-ui/core';
import { Required, showToast } from 'utils/tools';
import mobile from 'assets/images/Phone 1-01.svg';
import { insertUnion, updateUnion } from './unionInsert.service';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getUnionByID } from '../UnionList/unionList.service';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
const UnionForm = () => {
  const users = useSelector((state) => state.users);
  const history = useHistory();

  const { unionID, isEdit } = useParams();

  const [unionName, setUnionName] = useState('');
  const [regNo, setRegNo] = useState('');
  const [mobileNo, setMobileNo] = useState('');
  const [spokePerson, setSpokePerson] = useState('');

  const [address, setAddress] = useState('');

  const [disable, setDisable] = useState(false);

  const getUnionByIDAPI = async () => {
    const payLoad = {
      id: unionID,
    };
    const res = await getUnionByID(payLoad);

    if (res && res.length > 0) {
      const unionDetails = res.map((_data) => _data);

      setUnionName(unionDetails[0].name);
      setRegNo(unionDetails[0].registeration_no);
      setMobileNo(unionDetails[0].phone_no);
      setSpokePerson(unionDetails[0].spoke_person);
      setAddress(unionDetails[0].address);
    }
  };

  useEffect(() => {
    if (unionID && isEdit === 'edit') {
      getUnionByIDAPI();
    }
  }, [unionID, isEdit]);

  const handleChange = (ev) => {
    if (ev.target.name === 'union_name') {
      setUnionName(ev.target.value);
    } else if (ev.target.name === 'reg_no') {
      setRegNo(ev.target.value);
    } else if (ev.target.name === 'mobile_number') {
      setMobileNo(ev.target.value.slice(0, 10));
    } else if (ev.target.name === 'spoke_person') {
      setSpokePerson(ev.target.value);
    } else if (ev.target.name === 'address') {
      setAddress(ev.target.value);
    }
  };

  const handleSubmit = async () => {
    setDisable(true);
    const payLoad = {
      name: unionName,
      registerationNo: regNo,
      address: address,
      spokePerson: spokePerson,
      phoneCountryCode: '+91',
      mobile: +mobileNo,
      addedBy:
        users &&
        users.getDeptUserRole.data &&
        users.getDeptUserRole.data[0].department_user_id,
    };

    if (isEdit === 'edit') {
      try {
        const result = await updateUnion({
          ...payLoad,
          unionId: +unionID,
          editedBy:
            users &&
            users.getDeptUserRole.data &&
            users.getDeptUserRole.data[0].department_user_id,
        });

        showToast('SUCCESS', `${result?.message}`);
        history.push('/admin/unionList');
      } catch (error) {
        setDisable(false);
        showToast('ERROR', 'Something went wrong');
      }
    } else {
      try {
        const result = await insertUnion(payLoad);

        showToast('SUCCESS', `${result?.message}`);
        history.push('/admin/unionList');
      } catch (error) {
        setDisable(false);
        showToast('ERROR', 'Something went wrong');
      }
    }
  };

  return (
    <>
      <div className="root">
        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <Link to={'/admin/home'}>
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </Link>
                <h1 className="logo-text">
                  Government of Karnataka <br />
                  Karnataka Building & Other Construction Workers Welfare Board
                  (KBOCWWB)
                </h1>
              </div>
            </Col>
          </Row>
        </Row>
        <div className="position-relative ms-5">
          <div className="position-absolute" style={{ top: '10px', left: 25 }}>
            <Link to={'/admin/unionList'}>
              <img alt="..." className="cal-icon hover-icn" src={back} />
            </Link>
          </div>
        </div>
        <Row className="form-row mt-4 config-form">
          <Col xs={12} md={4} className="personal-col-1">
            <Required className="mb-2 mt-3" title="Union Name" />
            <FormControl
              fullWidth
              className="formcontrol1"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // handleEnter();
                }
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter Union Name"
                name="union_name"
                value={unionName}
                onChange={handleChange}
              />
            </FormControl>
          </Col>
          <Col xs={12} md={4} className="personal-col-1">
            <p className="mb-2 mt-3">Registration Number</p>
            <FormControl
              fullWidth
              className="formcontrol1"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // handleEnter();
                }
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter Registration Number"
                name="reg_no"
                value={regNo}
                onChange={handleChange}
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4} className="personal-col-1">
            <Required className="mb-2 mt-3" title="Mobile Number" />
            <FormControl fullWidth className="formcontrol2">
              <TextField
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={mobile}
                        alt="country code"
                        className="phone-icon"
                      />
                      <p className="countrycode">+91</p>
                    </InputAdornment>
                  ),
                }}
                variant="outlined"
                name="mobile_number"
                value={mobileNo}
                type="number"
                placeholder="Enter Mobile Number"
                onChange={handleChange}
              />
            </FormControl>
          </Col>

          <Col xs={12} md={4} className="personal-col-1">
            <Required className="mb-2 mt-3" title="Spoke Person" />
            <FormControl
              fullWidth
              className="formcontrol1"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // handleEnter();
                }
              }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter Spoke Person Name"
                name="spoke_person"
                value={spokePerson}
                onChange={handleChange}
              />
            </FormControl>
          </Col>

          <Col xs={12} md={6} className="personal-col-1">
            <Required className="mb-2 mt-3" title="Address" />
            <FormControl
              fullWidth
              className="formcontrol1"
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  // handleEnter();
                }
              }}
            >
              <TextareaAutosize
                variant="outlined"
                multiline
                rows={3}
                placeholder="Enter Address"
                name="address"
                value={address}
                onChange={handleChange}
              />
            </FormControl>
          </Col>

          <Col xs={12} className="mt-4" align="center">
            <Button
              disabled={disable}
              onClick={handleSubmit}
              className="btn btn-success py-2 px-3"
            >
              {isEdit === 'edit' ? 'Update Union' : 'Add Union'}
            </Button>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default UnionForm;
