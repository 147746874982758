import React from 'react';
import { Button } from 'react-bootstrap';

const TransferBack = ({ handleTransferBackTransaction, loading }) => {
  return (
    <Button
      onClick={handleTransferBackTransaction}
      variant="outline-primary"
      className="final-button-accept"
      disabled={loading}
    >
      Transfer back
    </Button>
  );
};

export default TransferBack;
