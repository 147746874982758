import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  InputAdornment,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Button } from "@mui/material";
import { Translate } from "react-redux-i18n";
import {
  Link,
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  SERVER,
  addLabourAddressDetails,
  addLabourPersonalDetails,
  getUserRegistrationDetails,
} from "store/actions/user.actions";
import { Required, getTokenCookie, showToast } from "utils/tools";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import language from "assets/images/translate (1).svg";
import Header from "./Header";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import { CameraAlt, PhoneAndroid } from "@material-ui/icons";
import Webcam from "react-webcam";
import folder from "assets/images/Folderwhite-01-01.svg";
// import webcam from 'assets/images/web-cam.svg'
import webcam from "assets/images/web cam-01.svg";
import male from "assets/images/Mask Group 1.svg";
import female from "assets/images/Mask Group 2.svg";
import radioon from "assets/images/Icon ionic-ios-radio-button-on-1.svg";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import uploadFileAPI from "utils/fileupload";
import FolderIcon from "@mui/icons-material/Folder";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import avatar from "assets/images/avatar.png";
import moment from "moment";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useDispatch, useSelector } from "react-redux";
import SimpleBackdrop from "components/loadingScreen/Backdrop";

const AddresDetails = () => {
  const { labourUserID, isEdited } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();

  const [fetchTypeOfResidence, setFetchTypeOfResidence] = useState(null);
  const [residenceID, setResidenceID] = useState(null);
  const [fetchDistrict, setFetchDistrict] = useState(null);
  const [fetchTaluk, setFetchTaluk] = useState(null);
  const [fetchCity, setFetchCity] = useState(null);
  const [fetchVillage, setFetchVillage] = useState(null);
  const [streetName, setStreetName] = useState(null);
  const [houseNumber, setHouseNumber] = useState(null);
  const [landmark, setLandmark] = useState(null);
  const [pinCode, setPinCode] = useState(null);
  const [stateID, setStateID] = useState(null);
  const [districtID, setDistrictID] = useState(null);
  const [talukID, setTalukID] = useState(null);
  const [cityID, setCityID] = useState(null);
  const [villageID, setVillageID] = useState(null);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [permanentAddress, setPermanentAddress] = useState({
    state: null,
    district: null,
    taluk: null,
    city: null,
    village: null,
    streetName: null,
    houseNumber: null,
    landmark: null,
    pinCode: null,
  });

  useEffect(() => {
    const payLoad = {
      labour_user_id: labourUserID,
    };
    axios
      .post(`${SERVER}/user/get_ad_details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.data.length > 0) {
          const { data } = res.data;
          setPermanentAddress((prev) => ({
            ...prev,
            state: data[0].state,
            district: data[0].district,
            taluk: data[0].sub_district,
            city: data[0].district,
            village: data[0].village_city_town,
            streetName: data[0].street ?? "",
            houseNumber: data[0].house_no,
            landmark: data[0].landmark,
            pinCode: data[0].pincode,
          }));
        }
      })
      .catch((err) => {
        console.log(err);
        showToast("ERROR", "Something went wrong");
      });
  }, [labourUserID]);

  useEffect(() => {
    // setSubmitLoading(true);
    const payLoad = {
      board_id: 1,
      key: "user_id",
      value: labourUserID,
      procedure_name: "all",
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then((res) => {
        if (res.data.data.address_details.length > 0) {
          const { data } = res.data;
          const { address_details } = data;
          setResidenceID(address_details[0].catalog_address_type_id);
          setStateID(address_details[0].state_id);
          setDistrictID(address_details[0].district_id);
          setTalukID(address_details[0].taluk_id);
          setCityID(address_details[0].city_town_gram_panchayat_id);
          setVillageID(address_details[0].village_area_id);
          setStreetName(address_details[0].street_name);
          setHouseNumber(address_details[0].door_no);
          setLandmark(address_details[0].land_mark ?? "");
          setPinCode(address_details[0].pin_code);
        }
      })
      .catch((err) => {
        if (err.response.data.errors.msg) {
          setSubmitLoading(false);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        } else {
          setSubmitLoading(false);
          showToast("ERROR", "Something went wrong");
        }
      });
  }, []);

  useEffect(() => {
    const payLoad = {
      board_id: 1,
      catalog_name: "Type of Residence",
    };
    axios
      .post(`${SERVER}/global/get_catalog_details`, payLoad)
      .then((res) => {
        const { data } = res.data;
        setFetchTypeOfResidence(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    axios
      .get(`${SERVER}/global/get_district_details?state_id=${12}`)
      .then((res) => {
        const { data } = res.data;
        setFetchDistrict(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    if (districtID) {
      axios
        .get(`${SERVER}/global/get_taluk_details?district_id=${districtID}`)
        .then((res) => {
          const { data } = res.data;
          setFetchTaluk(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [districtID]);

  useEffect(() => {
    if (talukID) {
      axios
        .get(`${SERVER}/global/get_city_details?taluk_id=${talukID}`)
        .then((res) => {
          const { data } = res.data;
          setFetchCity(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [talukID]);

  useEffect(() => {
    if (cityID) {
      axios
        .get(`${SERVER}/global/get_village_details?city_id=${cityID}`)
        .then((res) => {
          const { data } = res.data;
          setFetchVillage(data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [cityID]);

  const handleSubmit = (ev) => {
    if (
      +residenceID !== 0 &&
      +stateID !== 0 &&
      +districtID !== 0 &&
      +talukID !== 0 &&
      +cityID !== 0 &&
      +villageID !== 0 &&
      streetName !== 0 &&
      houseNumber !== 0 &&
      +pinCode !== 0
    ) {
      if (isEdited === "edit" || isEdited === "back") {
        //Update API
        setSubmitLoading(true);
        try {
          try {
            const payLoad = {
              user_id: labourUserID,
              addresses: [
                {
                  user_id: labourUserID,
                  address_base_type_id: 10,
                  address_type_id: residenceID,
                  door_no: houseNumber,
                  street_name: streetName,
                  village_area_id: villageID,
                  city_town_gram_panchayat_id: cityID,
                  taluk_id: talukID,
                  district_id: districtID,
                  state_id: stateID,
                  land_mark: landmark ?? "",
                  pin_code: pinCode,
                },
              ],
            };
            axios
              .patch(`${SERVER}/user/update-labour-address`, payLoad, {
                headers: {
                  Authorization: `Bearer ${getTokenCookie()}`,
                },
              })
              .then(({ data }) => {
                setSubmitLoading(false);
                showToast("SUCCESS", "Address details updated successfully");
                dispatch(
                  getUserRegistrationDetails({
                    board_id: 1,
                    key: "user_id",
                    value: labourUserID,
                    procedure_name: "all",
                  })
                );
                history.push(
                  `/profile/${
                    isEdited === "back"
                      ? "family"
                      : isEdited === "edit"
                        ? "review"
                        : ""
                  }/${labourUserID}`
                );
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
              })
              .catch((err) => {
                if (err.response.data.errors.msg) {
                  showToast("ERROR", `${err.response.data.errors.msg}`);
                  setSubmitLoading(false);
                } else {
                  showToast(
                    "ERROR",
                    "Something went wrong,try again after sometime."
                  );
                  setSubmitLoading(false);
                }
              });
          } catch (error) {
            console.log(error);
          }
        } catch (error) {}
      } else {
        //Insert API
        setSubmitLoading(true);
        try {
          const payLoad = {
            user_id: labourUserID,
            addresses: [
              {
                user_id: labourUserID,
                address_base_type_id: 10,
                address_type_id: residenceID,
                door_no: houseNumber,
                street_name: streetName,
                village_area_id: villageID,
                city_town_gram_panchayat_id: cityID,
                taluk_id: talukID,
                district_id: districtID,
                state_id: stateID,
                land_mark: landmark ?? "",
                pin_code: pinCode,
              },
            ],
          };
          axios
            .post(`${SERVER}/user/add-labour-address`, payLoad, {
              headers: {
                Authorization: `Bearer ${getTokenCookie()}`,
              },
            })
            .then(({ data }) => {
              setSubmitLoading(false);
              showToast("SUCCESS", "Address details saved successfully");
              dispatch(
                getUserRegistrationDetails({
                  board_id: 1,
                  key: "user_id",
                  value: labourUserID,
                  procedure_name: "all",
                })
              );
              history.push(`/profile/family/${labourUserID}`);
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            })
            .catch((err) => {
              if (err.response.data.errors.msg) {
                showToast("ERROR", `${err.response.data.errors.msg}`);
                setSubmitLoading(false);
              } else {
                showToast(
                  "ERROR",
                  "Something went wrong,try again after sometime."
                );
                setSubmitLoading(false);
              }
            });
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      showToast("ERROR", "Please fill all mandatory details");
    }
  };

  const handleBack = () => {
    history.push(`/profile/personal/${labourUserID}/back`);
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  return (
    <>
      <Row className="personal-div">
        <Row className="topbar-row">
          <Col xs={12} md={8} lg={9} className="top-bar-col">
            <div className="logo-div-profile">
              <Link to="/dashboard-user">
                <img id="logo" src={logo} alt="..." className="logo-img" />
              </Link>
              <h1 className="logo-text">
                <Translate value="header.title" /> <br />
                <Translate value="header.sub" />
              </h1>
            </div>
          </Col>
          <Col xs={12} md={4} lg={3}>
            <Select
              className="select-language"
              style={{ width: "100%" }}
              variant="outlined"
              labelId="demo-simple-select-required-label"
              // value={allValues.language}
              name="language"
              displayEmpty
              // onChange={handleChange}
            >
              <MenuItem value="">
                <ListItemIcon>
                  <img alt="..." src={language} className="language-img" />
                </ListItemIcon>
                <ListItemText primary="Select Language" />
              </MenuItem>
              {/* <MenuItem value="en">English</MenuItem>
                                    <MenuItem value="ka">Kannada</MenuItem> */}
              <MenuItem value="en">
                <ListItemText primary="English" />
              </MenuItem>
              <MenuItem value="ka">
                <ListItemText primary="ಕನ್ನಡ" />
              </MenuItem>
            </Select>
          </Col>
        </Row>

        <Row className="profile-title-row">
          <Col xs={10} className="profile-title">
            {/* <h1>Construction Workers Profile Details</h1> */}
            <Link
              to="/dashboard-user"
              className="profile-title-image"
              // onClick={() => props.history.goBack()}
            >
              <img alt="..." className="cal-icon hover-icn" src={back} />
            </Link>
            <h1>
              <Translate value="profileTitle" />
            </h1>
          </Col>
        </Row>
      </Row>
      <Row className="m-0">
        {submitLoading ? <SimpleBackdrop /> : null}

        <Row className="permanent-title-row">
          <Col xs={12} className="profile-title">
            <h1>
              <Translate value="permanentAddress" />
            </h1>
          </Col>
        </Row>

        <Row className="form-row">
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="state" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your State"
                value={permanentAddress.state}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="district" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your District"
                value={permanentAddress.district}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="taluk" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Taluk"
                value={permanentAddress.taluk}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="cityName" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your City Name"
                value={permanentAddress.city}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="villageName" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Village Name"
                value={permanentAddress.village}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="streetNameRoadName" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Street Name/ Road Name"
                value={permanentAddress.streetName}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="houseBuildingNumber" />}
            />
            <FormControl fullWidth>
              <TextField
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <HomeSVG />
                //     </InputAdornment>
                //   ),
                // }}
                variant="outlined"
                placeholder="Enter Your House / Building Number"
                value={permanentAddress.houseNumber}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <p className="mt-3 mb-2">
              <Translate value="landmark" />{" "}
            </p>
            <FormControl fullWidth className="formcontrol2">
              <TextField
                variant="outlined"
                placeholder="Enter Your Landmark"
                value={permanentAddress.landmark}
                disabled
              />
            </FormControl>
          </Col>
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="pincode" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Pincode"
                value={permanentAddress.pinCode}
                disabled
              />
            </FormControl>
          </Col>
        </Row>

        <Row className="residential-title-row">
          <Col xs={12} className="profile-title">
            <h1>
              <Translate value="residentialAddress" />
            </h1>
          </Col>
        </Row>

        <Row className="form-row ">
          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="typeOfresidence" />}
            />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={residenceID || "0"}
                // name="residenceType"
                // displayEmpty
                onChange={(ev) => setResidenceID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Type of Residence" />
                </MenuItem>
                {fetchTypeOfResidence &&
                  fetchTypeOfResidence.length &&
                  fetchTypeOfResidence.map((i) => (
                    <MenuItem value={i.value_id}>
                      <ListItemText primary={i.value} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Col>

          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="enterYourState" />}
            />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={stateID || "0"}
                // name="state"
                displayEmpty
                onChange={(ev) => setStateID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select State" />
                </MenuItem>
                <MenuItem value={12}>
                  <ListItemText primary="Karnataka" />
                </MenuItem>
              </Select>
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">{allValues.stateHelperText}</FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="enterYourDistrict" />}
            />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={districtID || "0"}
                // name="district"
                // displayEmpty
                onChange={(ev) => setDistrictID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Distrcit" />
                </MenuItem>
                {fetchDistrict &&
                  fetchDistrict.length &&
                  fetchDistrict.map((row) => (
                    <MenuItem value={row.id}>
                      <ListItemText primary={row.name}></ListItemText>
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">{allValues.districtHelperText}</FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            {/* <p className="mt-5 mb-2">Enter Your Taluk*</p> */}
            <Required
              className="mt-3 mb-2"
              title={<Translate value="enterYourTaluk" />}
            />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={talukID || "0"}
                // name="taluk"
                // displayEmpty
                onChange={(ev) => setTalukID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Taluk" />
                </MenuItem>
                {fetchTaluk &&
                  fetchTaluk.length &&
                  fetchTaluk.map((row) => (
                    <MenuItem value={row.id}>
                      <ListItemText primary={row.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">{allValues.talukHelperText}</FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="enterYourCityName" />}
            />
            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={cityID || "0"}
                // name="city"
                // displayEmpty
                onChange={(ev) => setCityID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select City" />
                </MenuItem>
                {fetchCity &&
                  fetchCity.length &&
                  fetchCity.map((row) => (
                    <MenuItem value={row.id}>
                      <ListItemText primary={row.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">{allValues.cityHelperText}</FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            {/* <p className="mt-5 mb-2">Enter Your Area / Village Name*</p> */}
            <Required
              className="mt-3 mb-2"
              title={<Translate value="enterYourAreaVillageName" />}
            />

            <FormControl variant="outlined" fullWidth>
              <Select
                className="select-marital"
                labelId="demo-simple-select-required-label"
                id="demo-simple-select-required"
                value={villageID || "0"}
                onChange={(ev) => setVillageID(ev.target.value)}
              >
                <MenuItem value="0">
                  <ListItemText primary="Select Village" />
                </MenuItem>
                {fetchVillage &&
                  fetchVillage.length &&
                  fetchVillage.map((row) => (
                    <MenuItem value={row.id}>
                      <ListItemText primary={row.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">{allValues.villageHelperText}</FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            {/* <p className="mt-5 mb-2">Street Name / Road Name*</p> */}
            <Required
              className="mt-3 mb-2"
              title={<Translate value="streetRoadName" />}
            />
            <FormControl
              fullWidth
              className="formcontrol1"
              // onKeyPress={(event) => {
              //   if (event.key === 'Enter') {
              //     handleEnter();
              //   }
              // }}
            >
              <TextField
                variant="outlined"
                placeholder="Enter Your Street/Road Name"
                name="streetRoad"
                value={streetName}
                onChange={(ev) => setStreetName(ev.target.value)}
                // helperText={
                //   allValues.streetlength ? 'Please enter in correct length' : ''
                // }
                // error={allValues.streetlength}
              />
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">
              {allValues.streetHelperText}
            </FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            {/* <p className="mt-5 mb-2">House / Building Number*</p> */}
            <Required
              className="mt-3 mb-2"
              title={<Translate value="houseBuildingNumber" />}
            />

            <FormControl fullWidth>
              <TextField
                // InputProps={{
                //   startAdornment: (
                //     <InputAdornment position="start">
                //       <HomeSVG className="houseicon" />
                //     </InputAdornment>
                //   ),
                // }}
                variant="outlined"
                placeholder="Enter Your House / Building Number"
                name="houseBuilding"
                value={houseNumber}
                onChange={(ev) => setHouseNumber(ev.target.value)}
                // error={allValues.residenceTypelength}
                // helperText={
                //   allValues.residenceTypelength
                //     ? 'Please enter in correct format'
                //     : ''
                // }
                inputProps={{ minLength: 0, maxLength: 15 }}
              />
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">
              {allValues.houseHelperText}
            </FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            {/* <p className="mt-5 mb-2">Landmark </p> */}
            <p className="mt-3 mb-2">
              <Translate value="landmark" />{" "}
            </p>

            <FormControl fullWidth className="formcontrol2">
              <TextField
                variant="outlined"
                placeholder="Enter Your Landmark"
                name="landmark"
                value={landmark}
                onChange={(ev) => setLandmark(ev.target.value)}
                // error={allValues.landmarklength}
                // helperText={
                //   allValues.landmarklength
                //     ? 'Please enter in correct format'
                //     : ''
                // }
              />
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">
              {allValues.landmarkHelperText}
            </FormHelperText> */}
          </Col>

          <Col xs={12} md={6}>
            <Required
              className="mt-3 mb-2"
              title={<Translate value="pincode" />}
            />
            <FormControl fullWidth>
              <TextField
                variant="outlined"
                placeholder="Enter Your Pincode"
                name="pincode"
                value={pinCode}
                onChange={(ev) =>
                  setPinCode(ev.target.value.replace(/[^0-9]/g, ""))
                }
                // error={pinError}
                // helperText={pinError}
                // onInput={(e) => {
                //   e.target.value = e.target.value.replace(/[^0-9]/gi, '');
                //   if (e.target.value.length === 0) {
                //     setPinError('Please Enter Pin Code');
                //   } else if (e.target.value.length < 6) {
                //     setPinError('Pin code must contain 6 digits');
                //   } else {
                //     setPinError('');
                //   }
                // }}
                inputProps={{ minLength: 0, maxLength: 6 }}
              />
            </FormControl>
            {/* <FormHelperText className="helperTextAadhar">
              {allValues.pincodeHelperText}
            </FormHelperText> */}
          </Col>

          <Col xs={12} className="note2 text-center mt-5 personal-col-7">
            {/* <p><span>Note :</span> After the completion of the mandatory fields in Personal Details, then only the system will allow the further process.</p> */}
            <p>
              <span>
                <Translate value="note" />{" "}
              </span>
              <Translate value="afterthecompletionofthemandatoryfieldsinPersonalDetailsthenonlythesystemwillallowthefurtherprocess1" />
            </p>
          </Col>
        </Row>

        <Row className="button-inside-form-row mb-5">
          <Col xs={12} className="next-back-button-row mt-4 "></Col>
        </Row>
        <Row className="button-inside-form-row mb-5">
          <Col
            xs={12}
            className="d-flex justify-content-end align-items-center w-100"
          >
            {isEdited === "edit" ? null : (
              <Button
                variant="contained"
                color="error"
                style={{ width: "15%" }}
                className="py-3 mx-1"
                onClick={handleBack}
              >
                Back
              </Button>
            )}
            <Button
              variant="contained"
              style={{ width: "15%", whiteSpace: "nowrap" }}
              color="success"
              className="py-3 mx-1"
              type="button"
              onClick={handleSubmit}
            >
              {isEdited === "edit" ? "Save your details" : " Save and continue"}
            </Button>
          </Col>
        </Row>
      </Row>
    </>
  );
};

export default AddresDetails;
