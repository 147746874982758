import "date-fns";
import {
  FormControl,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  ListItemIcon,
  ListItemText,
  CircularProgress,
  FormControlLabel,
  Checkbox,
  makeStyles,
  Backdrop,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import checkGreen from "assets/images/Tick-01.png";
import closeicon from "assets/images/closeIcon.svg";
import folder from "assets/images/Folderwhite-01-01.svg";
import MomentUtils from "@date-io/moment";
// import moment from 'moment';
import Moment from "react-moment";
import language from "assets/images/translate (1).svg";
import { useDispatch, useSelector } from "react-redux";
import usericon from "assets/images/Icon awesome-user.svg";
import {
  getUserRegistrationDetails,
  SERVER,
  setLocaleWithFallback,
} from "store/actions/user.actions";
import { Link, useParams } from "react-router-dom";
import {
  Required,
  showToast,
  getTokenCookie,
  dataURLtoFile,
} from "utils/tools";
import { CheckCircle } from "@material-ui/icons";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import axios from "axios";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { Translate } from "react-redux-i18n";
import Button2 from "@material-ui/core/Button";
import logout from "assets/images/Logout.svg";
import { Tooltip } from "@material-ui/core";
import validator from "validator";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { finalSubmit } from "store/actions";
import { isNull } from "util";
import close from "assets/images/close-01.svg";
import CancelIcon from "@mui/icons-material/Cancel";
import AddIcon from "@mui/icons-material/Add";
const Ashadeepaform = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const i18nlang = useSelector((state) => state.i18n);
  const hiddenFileInput = React.useRef(null);
  const [lang, setLang] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
  });
  const [allValues, setAllValues] = useState({
    language: users.profile_details.language,
    users: users.profile_details.users,
    company_name: "",
    company_name_error: false,
    nature_of_business: "",
    nature_of_business_error: false,
    name_of_md: "",
    name_of_md_error: false,
    contact_number: "",
    contact_number_error: false,
    email_id: "",
    email_id_error: false,
    registration_cert_no: "",
    registration_cert_no_error: false,
    local_address: "",
    local_address_error: false,
    name_of_hr: "",
    name_of_hr_error: false,
    hr_contact_number: "",
    hr_contact_number_error: false,
    hr_email_id: "",
    hr_email_id_error: false,
    category_name_error: false,
    total_no_of_posts_error: false,
    no_of_posts_filled_error: false,
    no_of_posts_vacant_error: false,
    no_of_ashadeepa_error: false,
    no_of_ashadeepa_error2: false,
    salary_error: false,
    no_of_ESI_error: false,
    no_of_EPF_error: false,
    name_of_apprenticeship_error: false,
    no_of_apprenticeship_error: false,
    vacancy_error: false,
    wages_error: false,
    remarks_error: false,

    // category_name:"",
    // category_name_error:false,
    // total_no_of_posts:"",
    // total_no_of_posts_error:false,
    // no_of_posts_filled:"",
    // no_of_posts_filled_error:false,
    // no_of_posts_vacant:"",
    // no_of_posts_vacant_error:false,
    // no_of_ashadeepa:"",
    // no_of_ashadeepa_error:false,
    // salary:"",
    // salary_error:false,
    // no_of_ESI:"",
    // no_of_ESI_error:false,
    // no_of_EPF:"",
    // no_of_EPF_error:false,

    // name_of_apprenticeship:"",
    // name_of_apprenticeship_error:false,
    // no_of_apprenticeship:"",
    // no_of_apprenticeship_error:false,
    // vacancy:"",
    // vacancy_error:false,
    // wages:"",
    // wages_error:false,
    // remarks:"",
    // remarks_error:false,
  });

  const [cat0Values, setCat0Values] = useState([
    {
      category_name: "",
      total_no_of_posts: "",
      no_of_posts_filled: "",
      no_of_posts_vacant: "",
      no_of_ashadeepa: "",
      salary: "",
      no_of_ESI: "",
      no_of_EPF: "",
    },
  ]);

  const [cat1Values, setCat1Values] = useState([
    {
      name_of_apprenticeship: "",
      no_of_apprenticeship: "",
      no_of_ashadeepa2: "",
      vacancy: "",
      wages: "",
      remarks: "",
    },
  ]);

  var timestamp = [Date.now()];
  var randNum = [Math.floor(Math.random() * 100)];
  var unique_id = timestamp.concat(randNum);
  var new_unique_id = unique_id.join("");
  console.log("RandNum:", randNum);
  console.log("TimeStamp:", timestamp);
  console.log("Unique:", unique_id);
  console.log("New:", new_unique_id);

  const inputRef = useRef(null);
  const [categoryType, setCategoryType] = useState("");
  const [showCategory, setShowCategory] = useState(false);
  const [addDiv, setAddDiv] = useState("");
  const [addCategory, setAddCategory] = useState("");
  const [fileObj, setFileObj] = useState("");
  const [selectedESIfile, setselectedESIFiles] = React.useState({});
  const [selectedEPFfile, setselectedEPFfile] = React.useState({});
  const [finalSubmit, setFinalSubmit] = React.useState(false);
  // const [selectedfilesMedical, setselectedfilesMedical] = React.useState({})
  // const [selectedfilesMedicalCert, setselectedfilesMedicalCert] = React.useState({})
  // const [selectedfilesXerox, setselectedfilesXerox] = React.useState({})

  const [fontActivate, setFontActivate] = useState(false);
  const [showRemoveBtn, setShowRemoveBtn] = useState(false);

  // const [selectedfilesPhoto, setselectedfilesPhoto] = React.useState(false)

  const [loadingfiles, setloadingfiles] = React.useState(true);
  const [esiUpload, setESIUpload] = React.useState({});
  const [epfUpload, setEPFUpload] = React.useState({});

  const [categoryLists, setCategoryLists] = React.useState([{}]);

  const [filelistESI, setfilelistESI] = React.useState({});
  const [filelistEPF, setfilelistEPF] = React.useState({});

  const [dateOfJoin, setDateOfJoin] = React.useState(null);

  const [filesResponseESI, setfilesResponseESI] = React.useState(null);
  const [filesResponseEPF, setfilesResponseEPF] = React.useState(null);

  const [states, setStates] = useState([]);
  const [stateId, setStateId] = useState();
  const [district, setDistrict] = useState([]);
  const [districtId, setDistrictId] = useState();
  const [taluk, setTaluk] = useState([]);
  const [talukId, setTalukId] = useState();
  const [city, setCity] = useState([]);
  const [cityId, setCityId] = useState();
  const [village, setVillage] = useState([]);
  const [villageId, setVillageId] = useState();

  //address api calls

  const getStateDetails = async () => {
    try {
      const response = await axios.get(`${SERVER}/global/get_state_details`);
      console.log("StateResponse:", response.data.data);
      setStates(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getDistrictDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_district_details?state_id=${stateId}`
      );
      console.log("DistrictResponse:", response.data.data);
      setDistrict(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getTalukDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_taluk_details?district_id=${districtId}`
      );
      console.log("TalukResponse:", response.data.data);
      setTaluk(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getCityDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_city_details?taluk_id=${talukId}`
      );
      console.log("CityResponse:", response.data.data);
      setCity(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  const getVillageDetails = async () => {
    try {
      const response = await axios.get(
        `${SERVER}/global/get_village_details?city_id=${cityId}`
      );
      console.log("VillageResponse:", response.data.data);
      setVillage(response.data.data);
    } catch (error) {
      console.log(JSON.stringify(error));
    }
  };

  //address ID's

  const handleState = (event) => {
    const getStateID = event.target.value;
    console.log(getStateID);
    setStateId(getStateID);
  };

  const handleDistrict = (event) => {
    const getDistrictID = event.target.value;
    console.log(getDistrictID);
    setDistrictId(getDistrictID);
  };

  const handleTaluk = (event) => {
    const getTalukID = event.target.value;
    console.log(getTalukID);
    setTalukId(getTalukID);
  };

  const handleCity = (event) => {
    const getCityID = event.target.value;
    console.log(getCityID);
    setCityId(getCityID);
  };

  const handleVillage = (event) => {
    const getVillageID = event.target.value;
    console.log(getVillageID);
    setVillageId(getVillageID);
  };

  useEffect(() => {
    getStateDetails();
    getDistrictDetails();
    getTalukDetails();
    getCityDetails();
    getVillageDetails();
  }, [stateId, districtId, talukId, cityId]);

  // const handleFileChange = event => {
  //     setFileObj( event.target.files && event.target.files);
  //     if (!fileObj) {
  //       return;
  //     }
  // }

  // const resetFileInput = (event) => {
  //     // reset input value
  //     setFileObj(event.target.value=null)
  //   };

  useEffect(() => {
    if (dateOfJoin !== null) {
      setAllValues({
        ...allValues,
        date_of_join: dateOfJoin,
        dateSelected: true,
      });
      console.log("date_of_join" + allValues.date_of_join);
    }
  }, [dateOfJoin]);

  const handleChange1 = (e) => {
    console.log("kkkkk", e.target.value);
    if (e.target.value === "ka") {
      setLang({
        ...allValues,
        descriptionsKannada: true,
        [e.target.name]: e.target.value,
      });
      setFontActivate(true);
    } else {
      setLang({
        ...allValues,
        descriptionsKannada: false,
        [e.target.name]: e.target.value,
      });
      setFontActivate(false);
    }

    if (e.target.name === "language")
      dispatch(setLocaleWithFallback(e.target.value));
  };

  const handleChangeCheckbox = (event) => {
    setAllValues({ ...allValues, [event.target.name]: event.target.checked });
  };

  const handleAddCategory1 = (i, e) => {
    let newFormValues = [...cat0Values];
    newFormValues[i][e.target.name] = e.target.value;
    setCat0Values(newFormValues);

    if (e.target.name === "category_name") {
      if (validator.isAlpha(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          category_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          category_name_error: true,
        });
      }
    } else if (e.target.name === "total_no_of_posts") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          total_no_of_posts_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          total_no_of_posts_error: true,
        });
      }
    } else if (e.target.name === "no_of_posts_filled") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_posts_filled_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_posts_filled_error: true,
        });
      }
    } else if (e.target.name === "no_of_posts_vacant") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_posts_vacant_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_posts_vacant_error: true,
        });
      }
    } else if (e.target.name === "no_of_ashadeepa") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ashadeepa_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ashadeepa_error: true,
        });
      }
    } else if (e.target.name === "salary") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          salary_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          salary_error: true,
        });
      }
    } else if (e.target.name === "no_of_ESI") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ESI_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ESI_error: true,
        });
      }
    } else if (e.target.name === "no_of_EPF") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_EPF_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_EPF_error: true,
        });
      }
    }
  };

  const handleAddCategory2 = (i, e) => {
    let newFormValues = [...cat1Values];
    newFormValues[i][e.target.name] = e.target.value;
    setCat1Values(newFormValues);

    if (e.target.name === "name_of_apprenticeship") {
      if (validator.isAlpha(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          name_of_apprenticeship_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          name_of_apprenticeship_error: true,
        });
      }
    } else if (e.target.name === "no_of_apprenticeship") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_apprenticeship_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_apprenticeship_error: true,
        });
      }
    } else if (e.target.name === "vacancy") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          vacancy_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          vacancy_error: true,
        });
      }
    } else if (e.target.name === "no_of_ashadeepa2") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ashadeepa_error2: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          no_of_ashadeepa_error2: true,
        });
      }
    } else if (e.target.name === "wages") {
      if (validator.isNumeric(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          wages_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          wages_error: true,
        });
      }
    } else if (e.target.name === "remarks") {
      if (validator.isAlpha(e.target.value)) {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          remarks_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [e.target.name]: e.target.value,
          remarks_error: true,
        });
      }
    }
  };

  const handleRemoveCategory1 = (index) => {
    console.log(index, "INdexValue");
    const values = [...cat0Values];

    values.splice(index, 1);
    setCat0Values(values);
  };

  const handleRemoveCategory2 = (index) => {
    console.log(index, "INdexValue");
    const values = [...cat1Values];

    values.splice(index, 1);
    setCat1Values(values);
  };
  // const handleChangeValidation2 = (e) => {
  //     if(e.target.name==="category_name"){
  //         if(e.target.value.length>=10){
  //             setCatValues(
  //                 {
  //                     ...catValues,
  //                     [e.target.name]:e.target.value,
  //                     category_name_error:false
  //             })
  //         }
  //         else{
  //             setCatValues(
  //                 {
  //                     ...catValues,
  //                     [e.target.name]:e.target.value,
  //                     category_name_error:true
  //             })
  //         }
  //     }
  // }

  const addFormCategory1 = () => {
    setCat0Values([
      ...cat0Values,
      {
        category_name: "",
        category_name_error: false,
        total_no_of_posts: "",
        total_no_of_posts_error: false,
        no_of_posts_filled: "",
        no_of_posts_filled_error: false,
        no_of_posts_vacant: "",
        no_of_posts_vacant_error: false,
        no_of_ashadeepa: "",
        no_of_ashadeepa_error: false,
        salary: "",
        salary_error: false,
        no_of_ESI: "",
        no_of_ESI_error: false,
        no_of_EPF: "",
        no_of_EPF_error: false,
      },
    ]);
  };

  const addFormCategory2 = () => {
    setCat1Values([
      ...cat1Values,
      {
        name_of_apprenticeship: "",
        no_of_apprenticeship: "",
        vacancy: "",
        no_of_ashadeepa2: "",
        wages: "",
        remarks: "",
      },
    ]);
  };

  // const handleSubmit = (e) =>{
  //     e.preventDefault();
  //     console.log("Fields:",catValues);
  // }

  const handleChange = (event) => {
    setAllValues({
      ...allValues,
      ...allValues,
      [event.target.name]: event.target.value,
    });
  };
  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };
  const setPickerStatus = (status) => {
    setAllValues({
      ...allValues,
      openDatePicker: status,
    });
  };

  const handleFinalSubmit = () => {
    let categoryList = [];

    if (categoryType === 0) {
      for (let i = 0; i < cat0Values.length; i++) {
        categoryList.push({
          is_employment_aprantiship_type: 0,
          total_number_of_posts: cat0Values[i].total_no_of_posts,
          total_post_filled: cat0Values[i].no_of_posts_filled,
          total_vacant_posts: cat0Values[i].no_of_posts_vacant,
          salary_per_month: cat0Values[i].salary,
          total_ashadeepa_posts_vacancies: cat0Values[i].no_of_ashadeepa,
          total_esi_employees: cat0Values[i].no_of_ESI,
          total_epf_employees: cat0Values[i].no_of_EPF,
          name_of_apprentiship: "NA",
          remarks: "NA",
        });
      }

      console.log("listCategory:", categoryList);
    }

    if (categoryType === 1) {
      for (let i = 0; i < cat1Values.length; i++) {
        categoryList.push({
          is_employment_aprantiship_type: 1,
          total_number_of_posts: cat1Values[i].total_no_of_posts,
          total_post_filled: cat1Values[i].no_of_posts_filled,
          total_vacant_posts: cat1Values[i].no_of_posts_vacant,
          salary_per_month: cat1Values[i].salary,
          total_ashadeepa_posts_vacancies: cat1Values[i].no_of_ashadeepa2,
          total_esi_employees: "[]",
          total_epf_employees: "[]",
          name_of_apprentiship: cat1Values[i].name_of_apprenticeship,
          remarks: cat1Values[i].remarks,
        });
      }

      console.log("listCategory:", categoryList);
    }

    var timestamp = Date.now();
    var timestampArray = timestamp.toString();
    var timestampNum = Number(timestampArray.slice(0, 8));
    var randNum = Math.floor(Math.random() * 100);
    var randNumArray = randNum.toString();
    var timestampSliced = timestampNum.toString();
    var joinrandNum = timestampSliced.concat(randNumArray);
    var unique_id = Number(joinrandNum);

    // var randNum = [Math.floor(Math.random() * 100)];
    // var unique_id = timestamp.concat(randNum)
    // var new_unique_id = unique_id.join("")
    //console.log("RandNum:",randNum);
    console.log("TimeStamp:", timestamp);
    console.log("TimeStamp8:", timestampNum);
    console.log("RandNum:", randNum);
    console.log("Unique_id:", unique_id);
    //console.log("Unique:",unique_id);
    //console.log("New:",new_unique_id);

    var apiData = {
      board_id: 4,
      scheme_id: 39,
      unique_id_generator: unique_id,
      company_firm_establishment_name: allValues.company_name,
      nature_of_business: allValues.nature_of_business,
      name_of_md_proprietor_partner: allValues.name_of_md,
      contact_number: allValues.contact_number,
      email_id: allValues.email_id,
      registration_certificate_number: allValues.registration_cert_no,
      district_id: districtId,
      taluk_id: talukId,
      grampanchayat_city_town_id: cityId,
      village_area_ward_id: villageId,
      name_of_hr: allValues.name_of_hr,
      contact_details_of_hr: allValues.hr_contact_number,
      email_id_of_hr: allValues.hr_contact_number,
      scheme_avail_category_details: categoryList,
      document_esi_id: JSON.stringify(filesResponseESI),
      document_epf_id: JSON.stringify(filesResponseEPF),
    };
    console.log("APIDATA:", apiData);

    var config = {
      method: "post",
      url: SERVER + "/schemes/insert_ashadeep_scheme",

      data: apiData,
    };

    axios(config)
      .then(function (response) {
        console.error(
          "response get_fields_value: " + JSON.stringify(response.data)
        );
        console.log(
          "response get_fields_value: " + JSON.stringify(response.data)
        );
        showToast("SUCCESS", "Scheme updated successfully");
        props.history.push("/ashadeepa");
      })
      .catch(function (error) {
        console.error(error);
        showToast("ERROR", "Error in submitting File");
      });
  };

  // useEffect(()=>{
  //     if(finalSubmit)
  //     handleFinalSubmit();
  // },[finalSubmit])

  const nextButtonPressed = () => {
    if (
      allValues.company_name !== "" &&
      allValues.company_name.length !== 0 &&
      allValues.nature_of_business !== "" &&
      allValues.nature_of_business.length !== 0 &&
      allValues.name_of_md !== "" &&
      allValues.name_of_md.length !== 0 &&
      allValues.contact_number !== "" &&
      allValues.contact_number.length !== 0 &&
      allValues.email_id !== "" &&
      allValues.email_id.length !== 0 &&
      allValues.registration_cert_no !== "" &&
      allValues.registration_cert_no.length !== 0 &&
      allValues.name_of_hr !== "" &&
      allValues.name_of_hr.length !== 0 &&
      allValues.hr_contact_number !== "" &&
      allValues.hr_contact_number.length !== 0 &&
      allValues.hr_email_id !== "" &&
      allValues.hr_email_id.length !== 0
    ) {
      if (
        selectedESIfile !== null &&
        selectedESIfile.length > 0 &&
        selectedEPFfile !== null &&
        selectedEPFfile.length > 0
      ) {
        uploadESI();
        uploadEPF();
      } else {
        showToast("ERROR", "Please upload all the documents");
      }
    } else {
      showToast("ERROR", "Please fill all the mandatory fields");
    }
  };

  const handleCategory = (event) => {
    setCategoryType(event.target.value);
  };

  console.log("Category:", categoryType);
  const [customError, setCustomError] = useState("");
  const handleChangeValidation = (ev) => {
    if (ev.target.name === "company_name") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          company_name_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          company_name_error: true,
        });
      }
    } else if (ev.target.name === "nature_of_business") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_of_business_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          nature_of_business_error: true,
        });
      }
    } else if (ev.target.name === "name_of_md") {
      if (
        validator.isAlpha(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length < 30
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          name_of_md_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          name_of_md_error: true,
        });
      }
    } else if (ev.target.name === "contact_number") {
      if (
        validator.isNumeric(ev.target.value, "en-US", { ignore: " " }) &&
        ev.target.value.length === 10
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          contact_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          contact_number_error: true,
        });
      }
    } else if (ev.target.name === "email_id") {
      if (validator.isEmail(ev.target.value)) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          email_id_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          email_id_error: true,
        });
      }
    } else if (ev.target.name === "registration_cert_no") {
      const regExp =
        "^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$";
      if (
        validator.matches(ev.target.value, regExp) &&
        ev.target.value.length === 21
      ) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          registration_cert_no_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          registration_cert_no_error: true,
        });
      }
    } else if (ev.target.name === "local_address") {
      if (ev.target.value.length <= 50) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          local_address_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          local_address_error: true,
        });
      }
    } else if (ev.target.name === "name_of_hr") {
      if (validator.isAlpha(ev.target.value) && ev.target.value.length <= 30) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          name_of_hr_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          name_of_hr_error: true,
        });
      }
    } else if (ev.target.name === "hr_contact_number") {
      if (
        validator.isNumeric(ev.target.value) &&
        ev.target.value.length === 10
      ) {
        //  medicalAlphaError = false
        //  e.targ
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          hr_contact_number_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          hr_contact_number_error: true,
        });
      }
    } else if (ev.target.name === "hr_email_id") {
      if (validator.isEmail(ev.target.value)) {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          hr_email_id_error: false,
        });
      } else {
        setAllValues({
          ...allValues,
          [ev.target.name]: ev.target.value,
          hr_email_id_error: true,
        });
      }
    }

    // else if (ev.target.name === "no_of_posts_vacant"){
    //     if(validator.isNumeric(ev.target.value)){
    //         setCatValues({
    //             ...catValues,
    //             [ev.target.name]:ev.target.value,
    //             no_of_posts_vacant_error:false
    //         })
    //     }
    //     else{
    //         setCatValues({
    //             ...catValues,
    //             [ev.target.name]: ev.target.value,
    //             no_of_posts_vacant_error: true
    //         })
    //     }
    // }
  };

  const handleESIFile = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedESIfile).concat(
        Array.from(event.target.files)
      );

      setselectedESIFiles(joined);
    }

    setloadingfiles(false);
  };

  const handleEPFFile = (event) => {
    setloadingfiles(true);

    if (event.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (!event.target.files[0].type.includes("image")) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedEPFfile).concat(
        Array.from(event.target.files)
      );

      setselectedEPFfile(joined);
    }

    setloadingfiles(false);
  };

  const uploadfile = (data1, filename) => {
    console.log("inside uploadFile: " + JSON.stringify(data1));
    console.log("inside uploadFile: filename= " + JSON.stringify(filename));
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", data1.userId);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/welfare_file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "x-api-key": "106cae6f-8276-4688-a747-d7cb20d28c58",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data, "uploadFile"));
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  const uploadESI = () => {
    let promises = [];

    selectedESIfile.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedESIfile.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise: ");
            console.log(results);
            setESIUpload(results);
          });
        }
      };
    });
  };

  const uploadEPF = () => {
    let promises = [];

    selectedEPFfile.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          userId: 120,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectedEPFfile.length) {
          Promise.all(promises).then((results) => {
            console.log("results of promise1: ");
            console.log(results);
            setEPFUpload(results);
          });
        }
      };
    });
  };

  const submitFiles = (filesResponseESI, filesResponseEPF) => {
    console.log(filesResponseESI, "filesResponseESI");

    if (
      filesResponseESI !== undefined &&
      filesResponseESI.length > 0 &&
      filesResponseEPF !== undefined &&
      filesResponseEPF.length > 0
    ) {
      console.log(filesResponseESI, "filesResponseESI");
      console.log(filesResponseEPF, "filesResponseEPF");

      // setfilesResponseESI(filesResponseESI)
      // setfilesResponseEPF(filesResponseEPF)

      // setFinalSubmit(true)

      let categoryList = [];

      if (categoryType === 0) {
        for (let i = 0; i < cat0Values.length; i++) {
          categoryList.push({
            is_employment_aprantiship_type: categoryType,
            total_number_of_posts: cat0Values[i].total_no_of_posts,
            total_post_filled: cat0Values[i].no_of_posts_filled,
            total_vacant_posts: cat0Values[i].no_of_posts_vacant,
            salary_per_month: cat0Values[i].salary,
            total_ashadeepa_posts_vacancies: cat0Values[i].no_of_ashadeepa,
            total_esi_employees: cat0Values[i].no_of_ESI,
            total_epf_employees: cat0Values[i].no_of_EPF,
            name_of_apprentiship: "NA",
            remarks: "NA",
          });
        }

        console.log("listCategory:", categoryList);
      }

      if (categoryType === 1) {
        for (let i = 0; i < cat1Values.length; i++) {
          categoryList.push({
            is_employment_aprantiship_type: categoryType,
            total_number_of_posts: cat1Values[i].total_no_of_posts,
            total_post_filled: cat1Values[i].no_of_posts_filled,
            total_vacant_posts: cat1Values[i].no_of_posts_vacant,
            salary_per_month: cat1Values[i].salary,
            total_ashadeepa_posts_vacancies: cat1Values[i].no_of_ashadeepa,
            total_esi_employees: "[]",
            total_epf_employees: "[]",
            name_of_apprentiship: cat1Values[i].name_of_apprenticeship,
            remarks: cat1Values[i].remarks,
          });
        }

        console.log("listCategory:", categoryList);
      }

      var timestamp = Date.now();
      var timestampArray = timestamp.toString();
      var timestampNum = Number(timestampArray.slice(0, 8));
      var randNum = Math.floor(Math.random() * 100);
      var randNumArray = randNum.toString();
      var timestampSliced = timestampNum.toString();
      var joinrandNum = timestampSliced.concat(randNumArray);
      var unique_id = Number(joinrandNum);

      // var randNum = [Math.floor(Math.random() * 100)];
      // var unique_id = timestamp.concat(randNum)
      // var new_unique_id = unique_id.join("")
      //console.log("RandNum:",randNum);
      console.log("TimeStamp:", timestamp);
      console.log("TimeStamp8:", timestampNum);
      console.log("RandNum:", randNum);
      console.log("Unique_id:", unique_id);
      //console.log("Unique:",unique_id);
      //console.log("New:",new_unique_id);

      var apiData = {
        board_id: 4,
        scheme_id: 39,
        unique_id_generator: unique_id,
        company_firm_establishment_name: allValues.company_name,
        nature_of_business: allValues.nature_of_business,
        name_of_md_proprietor_partner: allValues.name_of_md,
        contact_number: allValues.contact_number,
        email_id: allValues.email_id,
        registration_certificate_number: allValues.registration_cert_no,
        district_id: districtId,
        taluk_id: talukId,
        grampanchayat_city_town_id: cityId,
        village_area_ward_id: villageId,
        name_of_hr: allValues.name_of_hr,
        contact_details_of_hr: allValues.hr_contact_number,
        email_id_of_hr: allValues.hr_email_id,
        scheme_avail_category_details: categoryList,
        document_esi_id: JSON.stringify(filesResponseESI),
        document_epf_id: JSON.stringify(filesResponseEPF),
      };
      console.log("APIDATA:", apiData);

      var config = {
        method: "post",
        url: SERVER + "/schemes/insert_ashadeep_scheme",

        data: apiData,
      };

      axios(config)
        .then(function (response) {
          console.error(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          console.log(
            "response get_fields_value: " + JSON.stringify(response.data)
          );
          showToast("SUCCESS", "Scheme updated successfully");
          props.history.push("/ashadeepa");
        })
        .catch(function (error) {
          console.error(error);
          showToast("ERROR", "Error in submitting File");
        });
    }
  };

  useEffect(() => {
    if (esiUpload !== null && esiUpload.length > 0) {
      var filelist1 = [];

      esiUpload.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist1.push(filejson);
      });

      console.log("filelist: " + JSON.stringify(filelist1));

      setfilelistESI(filelist1);

      submitFiles(filelist1);
    }
  }, [esiUpload]);

  useEffect(() => {
    if (epfUpload !== null && epfUpload.length > 0) {
      var filelist2 = [];

      epfUpload.forEach((i) => {
        var filejson = {
          file_id: i.image.fileId,
          file_name: i.image.fileType,
        };

        filelist2.push(filejson);
      });

      console.log("filelist1: " + JSON.stringify(filelist2));

      setfilelistEPF(filelist2);

      submitFiles(filelistESI, filelist2);
    }
  }, [epfUpload]);

  console.log("FontStatus:", fontActivate);

  return (
    <div>
      <div className='root'>
        <Row className='top-div '>
          <Row className='topbar-row'>
            <Col xs={12} md={4} lg={6} className='top-bar-col'>
              <div className='logo-div-profile'>
                <a href='/boardsHome'>
                  <img id='logo' src={logo} alt='...' className='logo-img' />
                </a>
                <h1 className='logo-text'>
                  {/* Karnataka Building & Other Construction<br />Workers Welfare Board (KBOCWWB) */}
                  <Translate value={"header.titleungoranized"} />
                  <br />
                  <Translate value='header.subungoranized' />
                </h1>
              </div>
            </Col>
            <div className='d-flex justify-content-end'>
              <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                <Select
                  className='select-language'
                  style={{ width: "100%" }}
                  variant='outlined'
                  labelId='demo-simple-select-required-label'
                  value={lang.language}
                  name='language'
                  displayEmpty
                  onChange={handleChange1}
                >
                  <MenuItem value=''>
                    <ListItemIcon>
                      <img alt='...' src={language} className='language-img' />
                    </ListItemIcon>
                    <ListItemText primary='Select Language' />
                  </MenuItem>

                  <MenuItem value='en'>
                    <ListItemText primary='English' />
                  </MenuItem>
                  <MenuItem value='ka'>
                    <ListItemText primary='ಕನ್ನಡ' />
                  </MenuItem>
                </Select>
              </Col>
            </div>
          </Row>
        </Row>
      </div>

      <Row className='schemes-title-row'>
        <Col xs={12} md={9}>
          <h1>
            <Translate
              value='schemes'
              className={fontActivate ? "fontKan" : null}
            />{" "}
            |
            <Translate
              value='ashadeepaTitle'
              className={fontActivate ? "fontKan" : null}
            />
          </h1>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='ashadeepaField' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            {" "}
            <Translate value='companyNameFirm' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='company_name'
              value={allValues.company_name}
              onChange={handleChangeValidation}
              helperText={
                allValues.company_name_error
                  ? "Please enter alphabets only"
                  : ""
              }
              error={allValues.company_name_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='businessTrade' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='nature_of_business'
              value={allValues.nature_of_business}
              onChange={handleChangeValidation}
              helperText={
                allValues.nature_of_business_error
                  ? "Please enter alphabets only"
                  : ""
              }
              error={allValues.nature_of_business_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='mdnamePartner' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='name_of_md'
              value={allValues.name_of_md}
              onChange={handleChangeValidation}
              helperText={
                allValues.name_of_md_error ? "Please enter alphabets only" : ""
              }
              error={allValues.name_of_md_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='contactNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='contact_number'
              value={allValues.contact_number}
              onChange={handleChangeValidation}
              helperText={
                allValues.contact_number_error
                  ? "Please enter numbers only"
                  : ""
              }
              error={allValues.contact_number_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='emailId' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='email_id'
              value={allValues.email_id}
              onChange={handleChangeValidation}
              helperText={
                allValues.email_id_error ? "Please enter in correct format" : ""
              }
              error={allValues.email_id_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='regCertNumber' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='registration_cert_no'
              value={allValues.registration_cert_no}
              onChange={handleChangeValidation}
              helperText={
                allValues.registration_cert_no_error
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.registration_cert_no_error}
            />
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='enterYourState' /> *
          </p>

          <FormControl variant='outlined' fullWidth className='formcontrol1'>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={states}
              // value={allValues.residentialData.district}
              // name="district"
              // displayEmpty
              onChange={handleState}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {states !== undefined &&
                states !== null &&
                states.length > 0 &&
                states.map(
                  (i) =>
                    i.name === "Karnataka" && (
                      <MenuItem value={i.id}>
                        <ListItemText primary={i.name} />
                      </MenuItem>
                    )
                )}
            </Select>
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='enterYourDistrict' /> *
          </p>

          <FormControl variant='outlined' fullWidth>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={allValues.residentialData.district}
              // name="district"
              // displayEmpty
              onChange={handleDistrict}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {district !== undefined &&
                district !== null &&
                district.length > 0 &&
                district.map((i) => (
                  <MenuItem value={i.id}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='enterYourTaluk' /> *
          </p>

          <FormControl variant='outlined' fullWidth>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={allValues.residentialData.district}
              // name="district"
              // displayEmpty
              onChange={handleTaluk}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {taluk !== undefined &&
                taluk !== null &&
                taluk.length > 0 &&
                taluk.map((i) => (
                  <MenuItem value={i.id}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='enterYourCityName' /> *
          </p>

          <FormControl variant='outlined' fullWidth>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={allValues.residentialData.district}
              // name="district"
              // displayEmpty
              onChange={handleCity}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {city !== undefined &&
                city !== null &&
                city.length > 0 &&
                city.map((i) => (
                  <MenuItem value={i.id}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Col>

        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='enterYourAreaVillageName' /> *
          </p>

          <FormControl variant='outlined' fullWidth>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={allValues.residentialData.district}
              // name="district"
              // displayEmpty
              onChange={handleVillage}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {village !== undefined &&
                village !== null &&
                village.length > 0 &&
                village.map((i) => (
                  <MenuItem value={i.id}>
                    <ListItemText primary={i.name} />
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </Col>

        {/* <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
                
                    <p className="mb-2"><Translate value='localAddress'/> *</p>

                    <FormControl fullWidth className="formcontrol1">
                        <TextField
                             variant="outlined"
                             name="local_address"
                             value={allValues.local_address}
                             onChange={handleChangeValidation}
                            
                        />
                    </FormControl>
                </Col> */}
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='hrName' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='name_of_hr'
              value={allValues.name_of_hr}
              onChange={handleChangeValidation}
              helperText={
                allValues.name_of_hr_error ? "Please enter alphabets only" : ""
              }
              error={allValues.name_of_hr_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='hrContact' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='hr_contact_number'
              value={allValues.hr_contact_number}
              onChange={handleChangeValidation}
              helperText={
                allValues.hr_contact_number_error
                  ? "Please enter numbers only"
                  : ""
              }
              error={allValues.hr_contact_number_error}
            />
          </FormControl>
        </Col>
        <Col xs={12} md={4} lg={6} xl={6} xxl={6}>
          <p className='mb-2'>
            <Translate value='hrEmailId' /> *
          </p>

          <FormControl fullWidth className='formcontrol1'>
            <TextField
              variant='outlined'
              name='hr_email_id'
              value={allValues.hr_email_id}
              onChange={handleChangeValidation}
              helperText={
                allValues.hr_email_id_error
                  ? "Please enter in correct format"
                  : ""
              }
              error={allValues.hr_email_id_error}
            />
          </FormControl>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            {categoryType === 0 ? (
              <Translate value='empDetails' />
            ) : categoryType === 1 ? (
              <Translate value='apprentDetails' />
            ) : (
              <Translate value='empappDetails' />
            )}
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={4}>
          <p>Category Type *</p>

          <FormControl fullWidth className='formcontrol1' variant='outlined'>
            <Select
              className='select-marital'
              labelId='demo-simple-select-required-label'
              id='demo-simple-select-required'
              // value={allValues.residentialData.taluk}
              // name="taluk"
              value={categoryType}
              onChange={handleCategory}
              displayEmpty
              // onChange={handleChangeResidential}
            >
              <MenuItem value=''>
                <ListItemText primary='-Select-' />
              </MenuItem>
              {/* {allValues.taluk !== undefined && allValues.taluk !== null &&
                    allValues.taluk.length > 0 && allValues.taluk.map((i) =>
                                            // <MenuItem value={i.ref_district_code}> */}
              <MenuItem value={0}>
                <ListItemText>Employment Details</ListItemText>
              </MenuItem>
              <MenuItem value={1}>
                <ListItemText>Apprenticeship Details</ListItemText>
              </MenuItem>
            </Select>
          </FormControl>
        </Col>
        {categoryType === 0
          ? cat0Values.map((element, index) => {
              return (
                <Row className='mt-2'>
                  {
                    <div className='d-flex justify-content-end'>
                      <CancelIcon
                        onClick={() => handleRemoveCategory1(1)}
                      ></CancelIcon>
                    </div>
                  }
                  {/* <img alt="..." src={close} className="closebtn" onClick={() => handleRemoveCategory1()} /> */}
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='categoryName' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='category_name'
                        value={element.category_name}
                        // onChange={handleChangeValidation2}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.category_name_error
                            ? "Please enter Alphabets only"
                            : ""
                        }
                        error={allValues.category_name_error}
                      />
                    </FormControl>
                  </Col>

                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='totalnoPosts' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='total_no_of_posts'
                        value={element.total_no_of_posts}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.total_no_of_posts_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.total_no_of_posts_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='postsFilled' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='no_of_posts_filled'
                        value={element.no_of_posts_filled}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.no_of_posts_filled_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.no_of_posts_filled_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='postsVacant' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='no_of_posts_vacant'
                        value={element.no_of_posts_vacant}
                        onChange={(e) => {
                          handleAddCategory1(index, e);
                        }}
                        helperText={
                          allValues.no_of_posts_vacant_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.no_of_posts_vacant_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='noOfVacancyAshadeepa' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='no_of_ashadeepa'
                        value={element.no_of_ashadeepa}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.no_of_ashadeepa_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.no_of_ashadeepa_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='salaryPerMonth' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='salary'
                        value={element.salary}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.salary_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.salary_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='noOfemployeeESI' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='no_of_ESI'
                        value={element.no_of_ESI}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.no_of_ESI_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.no_of_ESI_error}
                      />
                    </FormControl>
                  </Col>
                  <Col xs={12} md={4}>
                    <p>
                      <Translate value='noOfemployeeEPF' /> *
                    </p>

                    <FormControl fullWidth className='formcontrol1'>
                      <TextField
                        variant='outlined'
                        name='no_of_EPF'
                        value={element.no_of_EPF}
                        onChange={(e) => handleAddCategory1(index, e)}
                        helperText={
                          allValues.no_of_EPF_error
                            ? "Please enter numbers only"
                            : ""
                        }
                        error={allValues.no_of_EPF_error}
                      />
                    </FormControl>
                  </Col>
                </Row>
              );
            })
          : categoryType === 1
            ? cat1Values.map((element, index) => {
                return (
                  <Row className='mt-2'>
                    {
                      <div className='d-flex justify-content-end'>
                        <CancelIcon
                          onClick={() => handleRemoveCategory2(1)}
                        ></CancelIcon>
                      </div>
                    }
                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='nameOfApprenticePosts' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='name_of_apprenticeship'
                          value={element.name_of_apprenticeship}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.name_of_apprenticeship_error
                              ? "Please Enter Alphabets Only"
                              : null
                          }
                          error={allValues.name_of_apprenticeship_error}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='noOfApprentice' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='no_of_apprenticeship'
                          value={element.no_of_apprenticeship}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.no_of_apprenticeship_error
                              ? "Please Enter Alphabets Only"
                              : null
                          }
                          error={allValues.no_of_apprenticeship_error}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='vacant' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='vacancy'
                          value={element.vacancy}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.vacancy_error
                              ? "Please Enter Numbers Only"
                              : null
                          }
                          error={allValues.vacancy_error}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='noOfVacancyAshadeepa' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='no_of_ashadeepa2'
                          value={element.no_of_ashadeepa2}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.no_of_ashadeepa_error2
                              ? "Please Enter Numbers Only"
                              : null
                          }
                          error={allValues.no_of_ashadeepa_error2}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='wagesForMonth' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='wages'
                          value={element.wages}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.wages_error
                              ? "Enter in numbers only"
                              : null
                          }
                          error={allValues.wages_error}
                        />
                      </FormControl>
                    </Col>

                    <Col xs={12} md={4}>
                      <p>
                        <Translate value='remarks' /> *
                      </p>

                      <FormControl fullWidth className='formcontrol1'>
                        <TextField
                          variant='outlined'
                          name='remarks'
                          value={element.remarks}
                          onChange={(e) => handleAddCategory2(index, e)}
                          helperText={
                            allValues.remarks_error
                              ? "Enter in Alphabets Only"
                              : null
                          }
                          error={allValues.remarks_error}
                        />
                      </FormControl>
                    </Col>
                  </Row>
                );
              })
            : null}

        {categoryType === 0 ? (
          <div className='d-flex justify-content-center mt-5 mb-5'>
            <Button2
              className='add-category-btn'
              onClick={() => addFormCategory1()}
            >
              <Translate value='addCategory' />
              <AddIcon color='secondary' className='add-icon' />
            </Button2>
          </div>
        ) : null}
        {categoryType === 1 ? (
          <div className='d-flex justify-content-center mt-5 mb-5'>
            <Button2
              className='add-category-btn'
              onClick={() => addFormCategory2()}
            >
              <Translate value='addCategory' />
              <AddIcon color='secondary' className='add-icon' />
            </Button2>
          </div>
        ) : null}
        {/* Apprenticeship */}
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='docsRequired' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} md={6} lg={6} xl={6}>
          <p className='mb-2 w-75'>
            <Translate value='listESI' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedESIfile !== null &&
                selectedESIfile.length > 0 &&
                Array.from(selectedESIfile).map((i, index) => (
                  <FormControl fullWidth className='formcontrol1'>
                    <TextField
                      variant='outlined'
                      name='photo'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                id='upload-photo'
                onChange={handleESIFile}
                multiple
              />
            </div>
          </div>
        </Col>

        <Col xs={12} md={6} lg={6} xl={6} className='mb-2'>
          <p className='mb-2 w-75'>
            <Translate value='listEPF' />*
          </p>
          <div className='browsebutton-outerdiv'>
            <div className='browsebutton2-div filesNames w-75'>
              {loadingfiles ? (
                <></>
              ) : (
                selectedEPFfile !== null &&
                selectedEPFfile.length > 0 &&
                Array.from(selectedEPFfile).map((i, index) => (
                  <FormControl>
                    <TextField
                      variant='outlined'
                      name='attestedDoc'
                      value={i.name}
                      onChange={handleChange}
                      disabled
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position='start'></InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                ))
              )}
            </div>

            <div className='browsebutton2-div newschemes-browserbtn'>
              <label
                htmlFor={`upload-photo2`}
                className='browse-button btn2 schemes'
              >
                <img alt='...' src={folder} />
                <Translate value='browse' />
              </label>
              <input
                type='file'
                name='photo'
                id='upload-photo2'
                onChange={handleEPFFile}
                multiple
              />
            </div>
          </div>
        </Col>
      </Row>

      <Row className='scheme-subtitle-row'>
        <Col xs={12} className='searchform-subtitle'>
          <p>
            <Translate value='declaration' />
          </p>
        </Col>
      </Row>

      <Row className='form-row mt-4 config-form searchform'>
        <Col xs={12} className='note2 schemes mt-4 bank-col-5'>
          <FormControlLabel
            control={
              <Checkbox
                checked={allValues.declaration}
                onChange={handleChangeCheckbox}
                name='declaration'
                // color="primary"
              />
            }
            label={
              <Translate value='theFactsMentionedaboveisBestofMyKnowledgeandInformation' />
            }
          />
        </Col>
      </Row>

      <Row className='search-subtitle-row pb-5'>
        <Col xs={12} className='next-back-button-row mt-4 '>
          <>
            <Link
              to='/ashadeepa'
              onClick={props.backButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button variant='danger' className='back-button'>
                <Translate value='back' />
              </Button>
            </Link>
            <Link
              to='#'
              onClick={nextButtonPressed}
              style={{ flexGrow: "0.5" }}
            >
              <Button
                variant='outline-primary'
                className='save-button'
                // onClick={handleFinalSubmit}
              >
                <Translate value='submit' />
              </Button>
            </Link>
          </>
        </Col>
      </Row>
    </div>
  );
};

export default Ashadeepaform;

//   <Row className='mt-2'>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='categoryName'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="category_name"
//                             value={element.category_name}
//                             // onChange={handleChangeValidation2}
//                             onChange={e=> handleAddCategory(index, e)}
//                             helperText={element.category_name_error ? "Please enter numbers only" : ""}
//                             error={element.category_name_error}
//                         />
//                     </FormControl>
//                 </Col>

//                 <Col xs={12} md={4} >

//                     <p><Translate value='totalnoPosts'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="total_no_of_posts"
//                             value={element.total_no_of_posts}
//                             onChange={e=> handleAddCategory(index, e)}
//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='postsFilled'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="no_of_posts_filled"
//                             value={element.no_of_posts_filled}
//                             onChange={e=> handleAddCategory(index, e)}
//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='postsVacant'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="no_of_posts_vacant"
//                             value={element.no_of_posts_vacant}
//                             onChange={(e)=>
//                             {
//                                 handleAddCategory(index, e);

//                             }
//                             }
//                             helperText={element.no_of_posts_vacant_error ? "Please enter numbers only":""}
//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='noOfVacancyAshadeepa'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="no_of_ashadeepa"
//                             value={element.no_of_ashadeepa}
//                             onChange={e=> handleAddCategory(index, e)}

//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='salaryPerMonth'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="salary"
//                             value={element.salary}
//                             onChange={e=> handleAddCategory(index, e)}

//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='noOfemployeeESI'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="no_of_ESI"
//                             value={element.no_of_ESI}
//                             onChange={e=> handleAddCategory(index, e)}

//                         />
//                     </FormControl>
//                 </Col>
//                 <Col xs={12} md={4} >

//                     <p><Translate value='noOfemployeeEPF'/> *</p>

//                     <FormControl fullWidth className="formcontrol1">
//                         <TextField
//                             variant="outlined"
//                             name="no_of_EPF"
//                             value={element.no_of_EPF}
//                             onChange={e=> handleAddCategory(index, e)}

//                         />
//                     </FormControl>
//                 </Col>

//                 </Row>

// Apprenticeship

//      <Row className='mt-2'>
//     <Col xs={12} md={4}>

//         <p><Translate value='nameOfApprenticePosts'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='name_of_apprenticeship'
//                 value={element.name_of_apprenticeship || allValues.name_of_apprenticeship}
//                 onChange={e=> handleAddCategory(index, e)}

//             />
//         </FormControl>
//     </Col>

//  <Col xs={12} md={4} >

//         <p><Translate value='noOfApprentice'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='no_of_apprenticeship'
//                 value={element.no_of_apprenticeship || allValues.no_of_apprenticeship}
//                 onChange={e=> handleAddCategory(index, e)}

//             />
//         </FormControl>
//     </Col>

//     <Col xs={12} md={4} >

//         <p><Translate value='vacant'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='vacany'
//                 value={element.vacancy || allValues.vacancy}
//                 onChange={e=> handleAddCategory(index, e)}

//             />
//         </FormControl>
//     </Col>

//     <Col xs={12} md={4} >

//         <p><Translate value='noOfVacancyAshadeepa'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='no_of_ashadeepa'
//                 value={element.no_of_ashadeepa || allValues.no_of_ashadeepa}
//                 onChange={e=> handleAddCategory(index, e)}

//             />
//         </FormControl>
//     </Col>

//     <Col xs={12} md={4} >

//         <p><Translate value='wagesForMonth'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='wages'
//                 value={element.wages || allValues.wages}
//                 onChange={e=> handleAddCategory(index, e)}
//             />
//         </FormControl>
//     </Col>

//     <Col xs={12} md={4} >

//         <p><Translate value='remarks'/> *</p>

//         <FormControl fullWidth className="formcontrol1">
//             <TextField
//                 variant="outlined"
//                 name='remarks'
//                 value={element.remarks || allValues.remarks}
//                 onChange={e=> handleAddCategory(index, e)}

//             />
//         </FormControl>
//     </Col>

//     </Row>

//         if((allValues.company_name !== "" && allValues.company_name.length !== 0 ) &&
//    (allValues.nature_of_business !== "" && allValues.nature_of_business.length !== 0) &&
//    (allValues.name_of_md !== "" && allValues.name_of_md.length !== 0) &&
//    (allValues.contact_number !== "" && allValues.contact_number.length !== 0) &&
//    (allValues.email_id !== "" && allValues.email_id.length > 0) &&
//    (allValues.registration_cert_no !== "" && allValues.registration_cert_no.length !== 0) &&
//    (allValues.local_address !== "" && allValues.local_address.length !== 0) &&
//    (states !== "" && states.length !== 0) &&
//    (district !== "" && district.length !== 0) &&
//    (taluk !== "" && taluk.length !== 0) &&
//    (city !== "" && city.length !== 0) &&
//    (village !== "" && village.length !== 0) &&
//    (allValues.name_of_hr !== "" && allValues.name_of_hr.length !== 0) &&
//    (allValues.hr_contact_number !== "" && allValues.hr_contact_number.length !== 0) &&
//    (allValues.hr_email_id !== "" && allValues.hr_email_id.length !== 0)
//     )

//     {

//         if(categoryType ===1)

//         if((cat0Values.category_name !== "" && cat0Values.category_name !== 0)&&
//         (cat0Values.total_no_of_posts !=="" && cat0Values.total_no_of_posts.length !== 0)&&
//         (cat0Values.no_of_posts_filled !=="" && cat0Values.no_of_posts_filled.length !==0)&&
//         (cat0Values.no_of_posts_vacant !=="" && cat0Values.no_of_posts_vacant.length !==0)&&
//         (cat0Values.no_of_ashadeepa !=="" && cat0Values.no_of_ashadeepa.length !==0)&&
//         (cat0Values.salary !=="" && cat0Values.salary.length !==0)&&
//         (cat0Values.no_of_ESI !=="" && cat0Values.no_of_ESI.length !==0)&&
//         (cat0Values.no_of_EPF !=="" && cat0Values.no_of_EPF.length !==0)
//         )
//         {
//             let categoryList = [];

// if(categoryType === 1){
//     for(let i = 0; i < cat0Values.length; i++) {
//         categoryList.push({

//             "is_employment_aprantiship_type": 0,
//             "total_number_of_posts": cat0Values[i].total_no_of_posts,
//             "total_post_filled": cat0Values[i].no_of_posts_filled,
//             "total_vacant_posts": cat0Values[i].no_of_posts_vacant,
//             "salary_per_month": cat0Values[i].salary,
//             "total_ashadeepa_posts_vacancies": cat0Values[i].no_of_ashadeepa,
//             "total_esi_employees": cat0Values[i].no_of_ESI,
//             "total_epf_employees": cat0Values[i].no_of_EPF,
//             "name_of_apprentiship":"N/A",
//             "remarks": "N/A"
//         })
//     }

//     console.log("listCategory:",categoryList);

// }

// if(categoryType === 2){
//     for(let i = 0; i < cat1Values.length; i++) {
//         categoryList.push({

//             "is_employment_aprantiship_type": 1,
//             "total_number_of_posts": 0,
//             "total_post_filled": 0,
//             "total_vacant_posts": cat1Values[i].no_of_posts_vacant,
//             "salary_per_month": 7809,
//             "total_ashadeepa_posts_vacancies": cat1Values[i].no_of_ashadeepa,
//             "total_esi_employees": 0,
//             "total_epf_employees": 0,
//             "name_of_apprentiship":cat1Values[i].name_of_apprenticeship,
//             "remarks": cat1Values[i].remarks
//         })
//     }

//     console.log("listCategory:",categoryList);

// }

// var apiData = JSON.stringify({
//     "board_id": 4,
//     "scheme_id": 39,
//     "unique_id_generator": 4537693,
//     "company_firm_establishment_name": allValues.company_name,
//     "nature_of_business": allValues.nature_of_business,
//     "name_of_md_proprietor_partner": allValues.name_of_md,
//     "contact_number": allValues.contact_number,
//     "email_id": allValues.email_id,
//     "registration_certificate_number": allValues.registration_cert_no,
//     "district_id": districtId,
//     "taluk_id": talukId,
//     "grampanchayat_city_town_id": cityId,
//     "village_area_ward_id": villageId,
//     "name_of_hr": allValues.name_of_hr,
//     "contact_details_of_hr": allValues.contact_details_of_hr,
//     "email_id_of_hr": allValues.hr_email_id,
//     "scheme_avail_category_details": categoryList

//     })
// console.log("APIDATA:",apiData);

//     var config = {
//         method:'post',
//         url:SERVER+'/schemes/insert_ashadeep_scheme',
//         data:apiData
//     };

//     axios(config)
//             .then(function (response) {
//                 console.error("response get_fields_value: "+JSON.stringify(response.data));
//                 console.log("response get_fields_value: "+JSON.stringify(response.data));
//                 showToast("SUCCESS", "Scheme updated successfully")
//                 props.history.push("/ashadeepa")

//             })
//             .catch(function (error) {
//                 console.error(error);
//             });

//         }

//     // if(selectedfiles !== null && selectedfiles.length > 0 && selectedfilesAttested !== null && selectedfilesAttested.length > 0 &&  selectedfilesMedical !== null && selectedfilesMedical.length > 0 &&  selectedfilesMedicalCert !== null && selectedfilesMedicalCert.length > 0 && selectedfilesXerox !== null && selectedfilesXerox.length > 0){
//     //             uploadDoc()

//     //             uploadDocAttested()

//     //             uploadDocMedical()

//     //             uploadDocMedicalCert()

//     //             uploadDocFilesXerox()
//     //         }
//     //         else{
//     //             showToast("ERROR", "Please fill all the documents")
//     //         }

//     //         // uploadDoc()

//     }
//     else
//     {
//         showToast("ERROR", "Please fill all the mandatory fields")
//     }

// }
