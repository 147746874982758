import 'date-fns';
import { Select, MenuItem,TextField, ListItemIcon, ListItemText, InputAdornment, AppBar, Toolbar, Typography, Drawer, List, ListItem, Divider, withStyles, Menu, TableContainer, Table, TableBody, TableRow, TableCell, Paper } from '@material-ui/core';
import React, { useEffect } from 'react';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-dashboard 2.svg'
import homewhite from 'assets/images/Icon material-dashboard.svg'
import user from 'assets/images/Icon awesome-user-edit.svg'
import userwhite from 'assets/images/Icon awesome-user-edit 2.svg'
import board from 'assets/images/Icon awesome-chalkboard-teacher.svg'
import boardwhite from 'assets/images/Icon awesome-chalkboard-teacher 2.svg'
import config from 'assets/images/Icon awesome-tools.svg'
import configwhite from 'assets/images/Icon awesome-tools 2.svg'
import mapping from 'assets/images/Icon awesome-link.svg'
import mappingwhite from 'assets/images/Icon awesome-link 2.svg'
import reports from 'assets/images/Icon material-report.svg'
import reportswhite from 'assets/images/Icon material-report.svg'
import vector from 'assets/images/Wall post-amico.svg'
import arrowDown from 'assets/images/Icon ionic-md-arrow-dropdown (2).svg'
import search from 'assets/images/Icon ionic-ios-search.svg'
import closeIcon from 'assets/images/close-01.svg'
import Check from 'assets/images/check (3).svg'
import Eye from 'assets/images/eye.svg'
import Pending from 'assets/images/wall-clock.svg'
import calIcon from 'assets/images/Icon ionic-ios-calendar 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getCatalogDetails, getLabourStatusDetails, getSchemesOnStatus, get_schemes, SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { Translate } from "react-redux-i18n";

import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import axios from 'axios';
import { getTokenCookie } from 'utils/tools';
import { CancelRounded } from '@material-ui/icons';
import { Link } from 'react-router-dom';
import cookie from 'react-cookies';
import moment from 'moment';
import Sakala from 'assets/images/sakala.png'
import validator from 'validator';


const BenificiarySchemes = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        // activestep: users.profile_details.activestep,
        activestep: 1,
        percentComplete: users.profile_details.percentComplete,
        editmode: users.profile_details.editmode,
        statusActive: "active",
        statusActiveLi: "",
        circleActiveLi: null,
        schemeID: null,
        appli_ref_no:null,
        start_date : null,
        end_date : null,
        dateSelected: false,
        schemesSelected:null,
     
       

    })

    const handleChange = (event) => {
        console.log(event.target.value)
        if(event.target.name.includes("circleActiveLi")){
        // const getcircle_ID = JSON.stringify(event.target.value);

        setAllValues({
            ...allValues,
            [event.target.name]: event.target.value
        })
            setCircleId(event.target.value);
        

        
      
        }
        else{
            setAllValues({
                ...allValues,
                [event.target.name]: event.target.value
            })
        }
        
        console.log("ValuesCircle:",event.target.value)


        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    

    const [aadhar_no, setaadhar_no] = React.useState(null);
    const [mob_no, setmob_no] = React.useState(null);

    const handleChange1 = (event) => {
        setaadhar_no(event.target.value)
    }
    const handleChange2 = (event) => {
        setmob_no(event.target.value)
    }

    const onItemClickHandler = (num) =>{
        setAllValues({
            ...allValues,
            activestep: num
        })
    }

    const StyledMenu = withStyles({
        paper: {
          border: '1px solid #d3d4d5',
          width: '350px'
        },
      })((props) => (
        <Menu
          elevation={0}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          {...props}
        />
      ));
      

    const [getCircleId, setCircleId] = React.useState();
    const [getUserMappedToCircle, setgetUserMappedToCircle] = React.useState([{}]);
    const [getTotalAshadeepa, setGetTotalAshadeepa] = React.useState(null);
    const [getAshadeepaAvailed, setAshadeepaAvailed] = React.useState(null);
    const [getApprovedAshadeepaAvailed, setApprovedAshadeepaAvailed] = React.useState(null);
    const [getRejectedAshadeepaAvailed, setRejectedAshadeepaAvailed] = React.useState(null);
    const [pendingstatus, setpendingStatus] = React.useState(null);
  

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElschemes, setAnchorElschemes] = React.useState(null);

    const [schemeID, setschemeID] = React.useState(null);

    

 
    const [anchorElCircle, setAnchorElCircle] = React.useState(null);
    const [anchorElconfig, setAnchorElconfig] = React.useState(null);
    const [anchorElmapping, setAnchorElmapping] = React.useState(null);
    
    const [startDate, setstartDate] = React.useState(null);
    const [endDate, setendDate] = React.useState(null);

    const [schemesSel, setschemesSel] = React.useState(null)

    const [ShowSchemesSel, setShowSchemesSel] = React.useState(false)


    



    const onSelectStatusHandler = (event,data) =>{

        setAllValues({
            ...allValues,
            statusActiveLi: data
        })
        handleClosekboc()
    }

    
    const onSelectCircleHandler = (event,data) =>{
        
        setAllValues({
            ...allValues,
            circleActiveLi: data
        })
        handleCloseCircle()
    }

    const onSelectSchemesHandler = (e, data, row) => {
        if(data !== undefined && data !== null){
        setAllValues({
            ...allValues,
            schemesSelected: data.id
        })  
        }
        setAnchorElschemes(null);

        
        
        // setschemeID(e.target.value === users.get_schemes.data[row].id)
        console.log("qqqq",allValues.schemesSelected)
    }



    const onSelectClickHandlerkboc1 = (event, data, ) => {
  
            setAnchorElschemes(event.currentTarget);
  
  
        
    }

    const onSelectClickHandlerkboc = (event,data) =>{
        console.log("inHAndleSTatus")

        
        if(data === "kbocwwb"){
            setAnchorEl(event.currentTarget);
        }
        else if(data === "circle"){
            setAnchorElCircle(event.currentTarget);
        }
    }
    const handleClosekboc = (event,data) =>{
        if (data === "schemes") {
            setAnchorElschemes(null);
        }
    }
    const handleCloseCircle = (event,data) =>{
            setAnchorElCircle(null);
    }

    const [regstatus, setregstatus] = React.useState([])

    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
        console.log("allValuesSchemeSelect",allValues.schemesSelected)
        let datatoSubmit = users.getDeptUserRole.data[0].board_id
        dispatch(get_schemes(datatoSubmit))
        


    }, [allValues]);


    useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
        if(startDate !== null){
        setAllValues({ ...allValues,  start_date: new Date(startDate), dateSelected : true })
       
        console.log("start_date", allValues.start_date)
        }
    }, [startDate]);

    useEffect(() => {
        // console.log("in useEffect [dob]: "+JSON.stringify(dob))
            if(endDate !== null){
            setAllValues({ ...allValues, end_date: endDate, dateSelected : true})
            console.log("endd_date" + allValues.end_date)
            }

      
        
    }, [endDate]);

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.get_schemes !== null &&
            users.get_schemes.error !== undefined &&
            users.get_schemes.error !== null &&
            users.get_schemes.error === true) {
            console.log("ERROR: " + JSON.stringify(users.get_schemes.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.get_schemes !== null) {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setschemesSel(users.get_schemes.data)
            console.log(schemesSel, "GetSchemes1")
        }
    }, [users.get_schemes]);



    console.log("SHow_AllValues:",allValues);
    console.log("CircleID",getCircleId);

    useEffect(() => {

        if(regstatus !== []){
            var pendingStatus = null
            console.error("regstatus.length: "+regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {

                console.error("regstatus: "+JSON.stringify(regstatus[i]))
                
                if(regstatus[i].value === "Pending"){
                    console.error("pending status: "+regstatus[i].value_id)
                    pendingStatus = regstatus[i].value_id
                    setAllValues({
                        ...allValues,
                        statusActive: "active",
                        statusActiveLi: regstatus[i].value
                    })
                }
            }


            // let dataToSubmit = {
            // "department_user_id": users.login_admin.data.department_user_id,
            // "role_id": 1,
            // "board_id": 1,
            // "registration_status_id": pendingStatus
            // }

            if(allValues.circleActiveLi !== null && allValues.circleActiveLi !== ""){

                let dataToSubmit = {
                    "board_id": users.getDeptUserRole.data[0].board_id,
                    "circle_id": allValues.circleActiveLi,
                    "role_id": users.getDeptUserRole.data[0].role_id,
                    "catalog_value_approval_status": pendingStatus,
                    "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                    "scheme_id" : allValues.schemesSelected,
                    "is_date_selected":allValues.dateSelected ? 1 : 0,
                    "start_date":allValues.start_date,
                    "end_date": allValues.end_date ,
                    "acck_no" : allValues.appli_ref_no,
                    "mobile_no": mob_no,
                    "aadhaar_no": aadhar_no
               
                }
                
                dispatch(getSchemesOnStatus(dataToSubmit))
        
            }
        }
        
    }, [regstatus]);


    useEffect(() => {

        if(regstatus !== []){
            var selectedStatus = null
            console.error("regstatus.length: "+regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {

                console.error("regstatus: "+JSON.stringify(regstatus[i]))
                
                if(regstatus[i].value === allValues.statusActiveLi){
                    console.error("selected status: "+regstatus[i].value_id)
                    selectedStatus = regstatus[i].value_id
                }
            }
            
        }

        var pendingStatus = null
        console.error("useeffect allValues.statusActiveLi: "+allValues.statusActiveLi)
        if(regstatus !== [] && regstatus.length > 0 ){
            // setLoading(true)
            var selectedStatus = null
            console.error("regstatus.length: "+regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {

                console.error("regstatus: "+JSON.stringify(regstatus[i]))
                
                if(regstatus[i].value === allValues.statusActiveLi){
                    console.error("selected status: "+regstatus[i].value_id)
                    selectedStatus = regstatus[i].value_id
                }
            }
            setpendingStatus(selectedStatus)

          

                let dataToSubmit = {
                    "board_id": users.getDeptUserRole.data[0].board_id,
                    "circle_id": allValues.circleActiveLi,
                    "role_id": users.getDeptUserRole.data[0].role_id,
                    "catalog_value_approval_status": selectedStatus,
                    "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                    "scheme_id" : allValues.schemesSelected,
                    "is_date_selected":allValues.dateSelected ? 1 : 0,
                    "start_date":allValues.start_date,
                    "end_date":allValues.end_date,
                    "acck_no" : allValues.appli_ref_no,
                    "mobile_no": mob_no == "" ? null : mob_no,
                    "aadhaar_no": aadhar_no
               
                }
                
                dispatch(getSchemesOnStatus(dataToSubmit))
        
            } 
        
        
    }, [allValues]);

    useEffect(() => {
        if(mob_no !== null && mob_no.length === 10)
        {
            let dataToSubmit = {
                "board_id": users.getDeptUserRole.data[0].board_id,
                "circle_id": allValues.circleActiveLi,
                "role_id": users.getDeptUserRole.data[0].role_id,
                "catalog_value_approval_status": pendingstatus,
                "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                "scheme_id" : allValues.schemesSelected,
                "is_date_selected":allValues.dateSelected ? 1 : 0,
                "start_date":allValues.start_date,
                "end_date":allValues.end_date,
                "acck_no" : allValues.appli_ref_no,
                "mobile_no": mob_no,
                "aadhaar_no": aadhar_no
           
            }
            
            dispatch(getSchemesOnStatus(dataToSubmit))
        }
        else {
            if(mob_no == "" )
            {
                {
                    let dataToSubmit = {
                        "board_id": users.getDeptUserRole.data[0].board_id,
                        "circle_id": allValues.circleActiveLi,
                        "role_id": users.getDeptUserRole.data[0].role_id,
                        "catalog_value_approval_status": pendingstatus,
                        "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                        "scheme_id" : allValues.schemesSelected,
                        "is_date_selected":allValues.dateSelected ? 1 : 0,
                        "start_date":allValues.start_date,
                        "end_date":allValues.end_date,
                        "acck_no" : allValues.appli_ref_no,
                        "mobile_no": null,
                        "aadhaar_no": aadhar_no
                
                    }
                    
                    dispatch(getSchemesOnStatus(dataToSubmit))
                }
            }
        }
    
        
    }, [mob_no] ) ;

    useEffect(() => {
       if(aadhar_no !== null && aadhar_no.length === 12)
        {
            {
                let dataToSubmit = {
                    "board_id": users.getDeptUserRole.data[0].board_id,
                    "circle_id": allValues.circleActiveLi,
                    "role_id": users.getDeptUserRole.data[0].role_id,
                    "catalog_value_approval_status": pendingstatus,
                    "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                    "scheme_id" : allValues.schemesSelected,
                    "is_date_selected":allValues.dateSelected ? 1 : 0,
                    "start_date":allValues.start_date,
                    "end_date":allValues.end_date,
                    "acck_no" : allValues.appli_ref_no,
                    "mobile_no": mob_no,
                    "aadhaar_no": aadhar_no
               
                }
                
                dispatch(getSchemesOnStatus(dataToSubmit))
            }
        }
        else {
            if(aadhar_no == "" )
            {
                {
                    let dataToSubmit = {
                        "board_id": users.getDeptUserRole.data[0].board_id,
                        "circle_id": allValues.circleActiveLi,
                        "role_id": users.getDeptUserRole.data[0].role_id,
                        "catalog_value_approval_status": pendingstatus,
                        "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                        "scheme_id" : allValues.schemesSelected,
                        "is_date_selected":allValues.dateSelected ? 1 : 0,
                        "start_date":allValues.start_date,
                        "end_date":allValues.end_date,
                        "acck_no" : allValues.appli_ref_no,
                        "mobile_no": mob_no,
                        "aadhaar_no": null
                
                    }
                    
                    dispatch(getSchemesOnStatus(dataToSubmit))
                }
            }
        }
        
    }, [aadhar_no] ) ;

    useEffect(() =>{
        setShowSchemesSel(true)
    },[allValues.schemesSelected])

    
    
    useEffect(() => {
        var pendingStatus = null
        console.error("regstatus.length: "+regstatus.length)
        for (var i = 0; i < regstatus.length; i++) {

            console.error("regstatus: "+JSON.stringify(regstatus[i]))
            
            if(regstatus[i].value === "Pending"){
                console.error("pending status: "+regstatus[i].value_id)
                pendingStatus = regstatus[i].value_id
            }
        }
        if(allValues.circleActiveLi !== null && allValues.circleActiveLi !== ""){

            let dataToSubmit = {
                "board_id": users.getDeptUserRole.data[0].board_id,
                "circle_id": allValues.circleActiveLi,
                "role_id": users.getDeptUserRole.data[0].role_id,
                "catalog_value_approval_status": pendingStatus,
                "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                "scheme_id":allValues.schemesSelected,
                "is_date_selected":allValues.dateSelected ? 1 : 0,
                "start_date":allValues.start_date,
                "end_date":allValues.end_date,
                "acck_no":allValues.appli_ref_no,
                "mobile_no": mob_no,
                "aadhaar_no": aadhar_no
                
            }
            
            dispatch(getSchemesOnStatus(dataToSubmit))
    
        }
    }, [allValues.circleActiveLi]);

    useEffect(() => {
        console.error("useeffect allValues.statusActiveLi: "+allValues.statusActiveLi)
        if(regstatus !== []){
            var selectedStatus = null
            console.error("regstatus.length: "+regstatus.length)
            for (var i = 0; i < regstatus.length; i++) {

                console.error("regstatus: "+JSON.stringify(regstatus[i]))
                
                if(regstatus[i].value === allValues.statusActiveLi){
                    console.error("selected status: "+regstatus[i].value_id)
                    selectedStatus = regstatus[i].value_id
                }
            }


            let dataToSubmit = {
                "board_id": users.getDeptUserRole.data[0].board_id,
                "circle_id": allValues.circleActiveLi,
                "role_id": users.getDeptUserRole.data[0].role_id,
                "catalog_value_approval_status": selectedStatus,
                "department_user_id": users.getDeptUserRole.data[0].department_user_id,
                "scheme_id":allValues.schemesSelected,
                "is_date_selected":allValues.dateSelected ? 1 : 0,
                "start_date":allValues.start_date,
                "end_date":allValues.end_date,
                "acck_no":allValues.appli_ref_no,
                "mobile_no": mob_no,
                "aadhaar_no": aadhar_no
            }
            
            dispatch(getSchemesOnStatus(dataToSubmit))
        }
        
    }, [allValues.statusActiveLi]);

    useEffect(() => {
        // console.log("in useEffect [users.user]")
        // if (users.user !== null && users.user.board_id !== null) {

            let dataForCatalog = { "board_id": 1, "catalog_name": "Registration Status" };
            // console.log("dataForCatalog: " + JSON.stringify(dataForCatalog))
            dispatch(getCatalogDetails(dataForCatalog))
        // }
        // }

        if(users.getDeptUserRole !== null && users.getDeptUserRole.data !== undefined && users.getDeptUserRole.data.length > 0){
            let data = JSON.stringify({
                "board_id": users.getDeptUserRole.data[0].board_id,
                "role_id": users.getDeptUserRole.data[0].role_id,
                "department_user_id": users.getDeptUserRole.data[0].department_user_id
           })
              var config = {
                method: 'post',
                url: SERVER+'/config/circle/get_user_mapped_to_circle',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}` 
                },
                data : data
              };
              
              axios(config)
              .then(function (response) {
                console.log("getUserMappedToCircle response: "+JSON.stringify(response.data.data, undefined,2));

                setgetUserMappedToCircle(response.data.data)
              })
              .catch(function (error) {
                console.error("getUserMappedToCircle error: "+error);
              });
        }

    }, []);

   

    
    
    useEffect(() => {
        console.log("useeffect getUserMappedToCircle: ",(getUserMappedToCircle))
       

    }, [getUserMappedToCircle]);
    

    useEffect(() => {
        // console.log("in useEffect [users.catalog_details]",JSON.stringify(users.catalog_details))
        if (users.catalog_details !== null && users.catalog_details.error !== undefined && users.catalog_details.error !== null && users.catalog_details.error === true) {
            console.log("ERROR: " + JSON.stringify(users.catalog_details.errors))
            // showToast("ERROR",users.catalog_details.errors.msg[0]['msg'] + " " + users.catalog_details.errors.msg[0]['param'])
        }
        else if (users.catalog_details !== null && users.catalog_details[0]['name'] === "Registration Status") {
            // console.error("elseif marital: "+JSON.stringify(users.catalog_details))
            setregstatus(users.catalog_details)
        }
    }, [users.catalog_details]);


    //ashadeepa get total_schemes


        var data = {
            "board_id":4,
            "role_id":2,
            "department_user_id":6

        }

        const getAshadeepaTotal = async () => {
            try {
                const response = await axios.get(`${SERVER}/schemes/get_total_ashadeep_availed_schemes?board_id=${data.board_id}&role_id=${data.role_id}&department_user_id=${data.department_user_id}`,
                {
                    headers:{
                        'Authorization':`Bearer ${getTokenCookie()}`
                    }
                }
                )
                console.log("responseAshadeepaTotal:" + JSON.stringify(response.data.data));
            } catch (error) {
                console.log(error);
            }
        }


  useEffect(()=>{
    getAshadeepaTotal();
  },[])


//   "board_id":users.getDeptUserRole.data[0].board_id,
//         "role_id":users.getDeptUserRole.data[0].role_id,
//         "department_user_id": users.getDeptUserRole.data[0].department_user_id,
        
  useEffect(()=>{
    let data = JSON.stringify({
        "board_id":4,
        "role_id":users.getDeptUserRole.data[0].role_id,
        "department_user_id": users.getDeptUserRole.data[0].department_user_id,
        "scheme_id": 39,
        "circle_id":allValues.circleActiveLi,
        "catalog_value_application_status": 45
   }
  
   )
   console.log("AshaDeepaDataAPI:",data);
      var config = {
        method: 'post',
        url: SERVER+'/schemes/get_ashadeep_availed_schemes',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenCookie()}` 
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log("Get_Ashadeepa_Availed"+JSON.stringify((response.data.data)));
        setAshadeepaAvailed(response.data.data)

        // setgetUserMappedToCircle(response.data.data)
      })
      .catch(function (error) {
        console.error("get_ashadeepa_error: "+error);
      });
    
  },[getCircleId])


  console.log("SchemeStatus:",allValues.statusActive);
  useEffect(()=>{
    let data = JSON.stringify({
        "board_id":4,
        "role_id":users.getDeptUserRole.data[0].role_id,
        "department_user_id": users.getDeptUserRole.data[0].department_user_id,
        "scheme_id": 39,
        "circle_id":allValues.circleActiveLi,
        "catalog_value_application_status": 47
   }
  
   )
   console.log("AshaDeepaDataAPI:",data);
      var config = {
        method: 'post',
        url: SERVER+'/schemes/get_ashadeep_availed_schemes',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenCookie()}` 
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log("Get_Ashadeepa_Availed"+JSON.stringify((response.data.data)));
        setApprovedAshadeepaAvailed(response.data.data)

        // setgetUserMappedToCircle(response.data.data)
      })
      .catch(function (error) {
        console.error("get_ashadeepa_error: "+error);
      });
    
  },[allValues.circleActiveLi, allValues.statusActiveLi])

  useEffect(()=>{
    let data = JSON.stringify({
        "board_id":4,
        "role_id":users.getDeptUserRole.data[0].role_id,
        "department_user_id": users.getDeptUserRole.data[0].department_user_id,
        "scheme_id": 39,
        "circle_id":allValues.circleActiveLi,
        "catalog_value_application_status": 46
   }
  
   )
   console.log("AshaDeepaDataAPI:",data);
      var config = {
        method: 'post',
        url: SERVER+'/schemes/get_ashadeep_availed_schemes',
        headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${getTokenCookie()}` 
        },
        data : data
      };
      
      axios(config)
      .then(function (response) {
        console.log("Get_Ashadeepa_Availed"+JSON.stringify((response.data.data)));
        setRejectedAshadeepaAvailed(response.data.data)

        // setgetUserMappedToCircle(response.data.data)
      })
      .catch(function (error) {
        console.error("get_ashadeepa_error: "+error);
      });
    
  },[allValues.circleActiveLi, allValues.statusActiveLi])



    return (
        <>
            {/* <div className="root"> */}
                {/* <CssBaseline /> */}
                {/* <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                                <h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={notification} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.login_admin.data.first_name} />
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
                </div> */}

                <Row className="kbocwwb-page">
                    <Col xs={12}>
                        <p className="title">Beneficiaries Schemes Details</p>
                    </Col>
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>From</p>
                            <div>
                                <img alt="..." className="cal-icon" src={calIcon}/>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>To</p>
                            <div>
                                <img alt="..." className="cal-icon" src={calIcon}/>
                            </div>
                        </div>
                    </Col> */}
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>Scheme | All</p>
                            <div>
                                <img alt="..." className="cal-icon" src={calIcon}/>
                            </div>
                        </div>
                    </Col> */}
                    <Col xs={2} className="benificiaries-col ">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <Select
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.circleActiveLi}
                                name="circleActiveLi"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value= {"" || null} >
                                    <ListItemText primary="-Circle-" />
                                </MenuItem>
                                {
                                getUserMappedToCircle !== null && getUserMappedToCircle.length > 0 && getUserMappedToCircle.map((row) => (
                                <MenuItem value={row.circle_id} >
                                    <ListItemText primary={row.circle_name} />
                                </MenuItem>
                                ))
                                }
                            </Select>
                        </div>
                    </Col>
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" 
                        className={`benificiaries-col-div hvr-icon-hang ${allValues.statusActive}`} 
                        onClick={(e) => onSelectClickHandlerkboc(e,"circle")}>
                            <p>{allValues.circleActiveLi}</p>
                            <div>
                                <img alt="..." 
                                className="arrow-icon" 
                                src={arrowDown}/>
                            </div>
                        </div>
                        <StyledMenu
                            id="customized-menu"
                            className="customized-menu"
                            anchorEl={anchorElCircle}
                            keepMounted
                            open={Boolean(anchorElCircle)}
                            onClose={(e) => handleCloseCircle(e,"circle")}
                        >
                            {
                                getUserMappedToCircle !== null && getUserMappedToCircle.length > 0 && getUserMappedToCircle.map((row) => (

                                <MenuItem onClick={(e) => onSelectCircleHandler(e,row.circle_name)} 
                                className={allValues.statusActiveLi === "circle_name" ? "active" : null}
                                >
                                    <ListItemText primary={row.circle_name}/>
                                </MenuItem>

                                ))
                            }
                            
                        </StyledMenu>

                    </Col> */}
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className={`benificiaries-col-div hvr-icon-hang ${allValues.statusActive}`} onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}>
                            <p>{allValues.statusActiveLi}</p>
                            <div>
                                <img alt="..." 
                                className="arrow-icon" 
                                src={arrowDown}/>
                            </div>
                        </div>
                        <StyledMenu
                            id="customized-menu"
                            className="customized-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={(e) => handleClosekboc(e,"kbocwwb")}
                        >
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Pending")} className={allValues.statusActiveLi === "Pending" ? "active" : null}>
                                <ListItemText primary="Pending"/>
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Approved")} className={allValues.statusActiveLi === "Approved" ? "active" : null}> 
                                <ListItemText primary="Approved" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Rejected")} className={allValues.statusActiveLi === "Rejected" ? "active" : null}>
                                <ListItemText primary="Rejected" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Seek Clarification")} className={allValues.statusActiveLi === "Seek Clarification" ? "active" : null}>
                                <ListItemText primary="Seek Clarification" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Transfered")} className={allValues.statusActiveLi === "Transfered" ? "active" : null}>
                                <ListItemText primary="Transfered" />
                            </MenuItem>
                        </StyledMenu>

                    </Col> */}
                    {/* <Col xs={2} className="benificiaries-col fromdate">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        // onClick={(e) => onSelectClickHandlerkboc(e,"kbocwwb")}
                        >
                            <p>Search</p>
                            <div>
                                <img alt="..." className="cal-icon" src={calIcon}/>
                            </div>
                        </div>
                    </Col> */}
                    
                    <Col xs={2} className="benificiaries-col ">
                        <div aria-controls="customized-menu" aria-haspopup="true" className="benificiaries-col-div hvr-icon-hang" 
                        >
                            <Select
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.statusActiveLi}
                                name="statusActiveLi"
                                displayEmpty
                                onChange={handleChange}
                            >
                            <MenuItem value="" >
                                <ListItemText primary="-Status-" />
                            </MenuItem>
                            <MenuItem value="Pending" >
                                <ListItemText primary="Pending" />
                            </MenuItem>
                            <MenuItem value="Approved" >
                                <ListItemText primary="Approved" />
                            </MenuItem>
                            <MenuItem value="Rejected" >
                                <ListItemText primary="Rejected" />
                            </MenuItem>
                            {console.log("FFFFF",users.getDeptUserRole.data[0].role_id)}
                            {users.getDeptUserRole.data[0].role_id == 1 ?
                                <MenuItem value="Transferred Scheme Application" >
                                    <ListItemText primary="Transferred Scheme Application" />
                                </MenuItem>
                            : null
                            }
                            
                            {/* <MenuItem value="Seek Clarification" >
                                <ListItemText primary="Seek Clarification" />
                            </MenuItem>
                            <MenuItem value="Transfered" >
                                <ListItemText primary="Transfered" />
                            </MenuItem> */}
                            {/* <MenuItem onClick={(e) => onSelectStatusHandler(e,"Pending")} className={allValues.statusActiveLi === "Pending" ? "active" : null}>
                            <ListItemText primary="Pending"/>
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Approved")} className={allValues.statusActiveLi === "Approved" ? "active" : null}> 
                                <ListItemText primary="Approved" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Rejected")} className={allValues.statusActiveLi === "Rejected" ? "active" : null}>
                                <ListItemText primary="Rejected" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Seek Clarification")} className={allValues.statusActiveLi === "Seek Clarification" ? "active" : null}>
                                <ListItemText primary="Seek Clarification" />
                            </MenuItem>
                            <MenuItem onClick={(e) => onSelectStatusHandler(e,"Transfered")} className={allValues.statusActiveLi === "Transfered" ? "active" : null}>
                                <ListItemText primary="Transfered" />
                            </MenuItem> */}
                            </Select>
                        </div>
                    </Col>
                    

                    <Col xs={12}>

                    <TableContainer 
                    // component={Paper}
                    >
                        <Table 
                        className="mt-4"
                         aria-label="simple table"
                         style={{border: "0.5px solid #D9D4D4"}}>
                            <TableBody>
                            
                            {
                                {
                                'Pending': 
                                    <>
                                    {
                                        users.getSchemesOnStatus !== null && 
                                        users.getSchemesOnStatus !== "Unauthorized" && 
                                        users.getSchemesOnStatus !== "Network Error" && 
                                        users.getSchemesOnStatus.error === undefined 
                                        // && 
                                        // users.getLabourStatusDetails !== undefined &&
                                        // users.getLabourStatusDetails !== null &&
                                        // users.getLabourStatusDetails.length > 0 
                                        ?

                                        <>
                                        {
                                            
                                            users.getSchemesOnStatus !== undefined && users.getSchemesOnStatus.data !== undefined && users.getSchemesOnStatus.data.recordsets[0].map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.labour_name}</TableCell>
                                                <TableCell align="center">{row.scheme_name}</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center">

                                                    <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/>Pending</TableCell>

                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname: "/admin/scheme-details-unorganized", 
                                                        userid: row.labour_user_id,
                                                        showbuttons: true,
                                                        scheme_avail_id: row.scheme_avail_id
                                                        
                                                      }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.sla_days === 0 ? null : row.sla_days+" days left for Approval"} 
                                                </TableCell>
                                                <img className='img-sakala' alt='...' src={Sakala}/>
                                            </TableRow>
                                        ))
                                           && getAshadeepaAvailed ? getAshadeepaAvailed.map((row)=>(
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.company_firm_establishment_name}</TableCell>
                                                <TableCell align="center">Ashadeepa Scheme</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center">

                                                    <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/>Pending</TableCell>

                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname: "/ashadeepaschemedetails", 
                                                        
                                                        showbuttons: true,
                                                        scheme_avail_id: row.ashadeepa_scheme_avail_id,
                                                        catalog_value_id:45,
                                                        circle_id:allValues.circleActiveLi,
                                                      }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.sla_days === 0 ? null : row.sla_days+" days left for Approval"} 
                                                </TableCell>
                                                <img className='img-sakala' alt='...' src={Sakala}/>
                                            </TableRow>
                                           )) : null }
                                        </>

                                        :

                                        <p>
                                            No Pending Schemes Applications for Selected Circle.
                                        </p>
                                    }
                                    </>
                                ,
                                'Approved': 
                                    <>
                                    {
                                        users.getSchemesOnStatus !== null && 
                                        users.getSchemesOnStatus !== "Unauthorized" && 
                                        users.getSchemesOnStatus !== "Network Error" && 
                                        users.getSchemesOnStatus.error === undefined 
                                        // && 
                                        // users.getLabourStatusDetails !== undefined &&
                                        // users.getLabourStatusDetails !== null &&
                                        // users.getLabourStatusDetails.length > 0 
                                        ?

                                        <>
                                        {users.getSchemesOnStatus !== undefined && users.getSchemesOnStatus.data !== undefined && users.getSchemesOnStatus.data.recordsets[0].map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.labour_name}</TableCell>
                                                <TableCell align="center">{row.scheme_name}</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center">

                                                    <img alt="..." src={Check} className="mr-2" style={{marginRight:"8px"}}/>Approved</TableCell>

                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname: "scheme-details-unorganized", 
                                                        userid: row.labour_user_id,
                                                        showbuttons: false,
                                                        scheme_avail_id: row.scheme_avail_id,
                                                        
                                                    }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                                
                                            </TableRow>
                                        )) && getApprovedAshadeepaAvailed ? getApprovedAshadeepaAvailed.map((row)=>(
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.company_firm_establishment_name}</TableCell>
                                                <TableCell align="center">Ashadeepa Scheme</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center">

                                                    <img alt="..." src={Check} className="mr-2" style={{marginRight:"8px"}}/>Approved</TableCell>

                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname:"/ashadeepaschemedetails", 
                                                        
                                                        showbuttons: true,
                                                        scheme_avail_id: row.ashadeepa_scheme_avail_id,
                                                        catalog_value_id:47,
                                                        circle_id:allValues.circleActiveLi,
                                                      }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.sla_days === 0 ? null : row.sla_days+" days left for Approval"} 
                                                </TableCell>
                                                <img className='img-sakala' alt='...' src={Sakala}/>
                                            </TableRow>
                                        )): null}
                                        </>

                                        :

                                        <p>
                                            No Approved Schemes Applications for Selected Circle.
                                        </p>
                                    }
                                    </>
                                ,
                                'Rejected': 
                                    <>
                                    {
                                        users.getSchemesOnStatus !== null && 
                                        users.getSchemesOnStatus !== "Unauthorized" && 
                                        users.getSchemesOnStatus !== "Network Error" && 
                                        users.getSchemesOnStatus.error === undefined 
                                        // && 
                                        // users.getLabourStatusDetails !== undefined &&
                                        // users.getLabourStatusDetails !== null &&
                                        // users.getLabourStatusDetails.length > 0 
                                        ?

                                        <>
                                        {users.getSchemesOnStatus !== undefined && users.getSchemesOnStatus.data !== undefined && users.getSchemesOnStatus.data.recordsets[0].map((row) => (
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.labour_name}</TableCell>
                                                <TableCell align="center">{row.scheme_name}</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center" style={{color: '#DB0909'}}>

                                                    <CancelRounded style={{color: '#DB0909' , marginRight:"8px"}} className="mr-2" />

                                                    Rejected
                                                </TableCell>
                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname: "/admin/scheme-details", 
                                                        userid: row.labour_user_id,
                                                        showbuttons: false,
                                                        scheme_avail_id: row.scheme_avail_id
                                                    }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )) && getRejectedAshadeepaAvailed ? getRejectedAshadeepaAvailed.map((row)=>(
                                            <TableRow key={row.name}>
                                                <TableCell align="center">{row.company_firm_establishment_name}</TableCell>
                                                <TableCell align="center">Ashadeepa Scheme</TableCell>
                                                <TableCell align="center">
                                                    {
                                                moment(row.applied_date).format('DD/MM/YYYY')
                                                }</TableCell>
                                                <TableCell align="center">

                                                    
                                                <CancelRounded style={{color: '#DB0909' , marginRight:"8px"}} className="mr-2" />

                                                    Rejected
                                                    </TableCell>

                                                <TableCell align="center">
                                                    <Link 
                                                    to ={{ 
                                                        pathname:"/ashadeepaschemedetails", 
                                                        
                                                        showbuttons: true,
                                                        scheme_avail_id: row.ashadeepa_scheme_avail_id,
                                                        catalog_value_id:46,
                                                        circle_id:allValues.circleActiveLi,
                                                      }}
                                                    >

                                                    <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                                    </Link>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {row.sla_days === 0 ? null : row.sla_days+" days left for Approval"} 
                                                </TableCell>
                                                <img className='img-sakala' alt='...' src={Sakala}/>
                                            </TableRow>
                                        )) : null
                                        }
                                        </>

                                        :

                                        <p>
                                            No Rejected Schemes Applications for Selected Circle.
                                        </p>
                                    }
                                    </>
                                // ,
                                // 'Seek Clarification': 
                                //     <>
                                //     {/* {
                                //         users.getLabourStatusDetails !== null && users.getLabourStatusDetails !== "Unauthorized" && users.getLabourStatusDetails.length > 0 ?

                                //         <>
                                //         {users.getLabourStatusDetails.map((row) => (
                                //             <TableRow key={row.name}>
                                //                 <TableCell align="center">{row.labour_full_name}</TableCell>
                                //                 <TableCell align="center">Application No:    {row.application_no}</TableCell>
                                //                 <TableCell align="center">
                                //                     <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/>Seek Clarification</TableCell>
                                //                 <TableCell align="center">
                                //                     <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>Details</TableCell>
                                //             </TableRow>
                                //         ))}
                                //         </>

                                //         :

                                //         <p>No 'Seek Clarification' applications</p>
                                //     } */}
                                //     </>
                                // ,
                                // 'Transferred Scheme Application': 
                                // <>
                                // {
                                //     users.getSchemesOnStatus !== null && 
                                //     users.getSchemesOnStatus !== "Unauthorized" && 
                                //     users.getSchemesOnStatus !== "Network Error" && 
                                //     users.getSchemesOnStatus.error === undefined 
                                //     // && 
                                //     // users.getLabourStatusDetails !== undefined &&
                                //     // users.getLabourStatusDetails !== null &&
                                //     // users.getLabourStatusDetails.length > 0 
                                //     ?

                                //     <>
                                //     {users.getSchemesOnStatus !== undefined && users.getSchemesOnStatus.data !== undefined && users.getSchemesOnStatus.data.recordsets[0].map((row) => (
                                //         <TableRow key={row.name}>
                                //             <TableCell align="center">{row.labour_name}</TableCell>
                                //             <TableCell align="center">{row.scheme_name}</TableCell>
                                //             <TableCell align="center">
                                //                 {
                                //             moment(row.applied_date).format('DD/MM/YYYY')
                                //             }</TableCell>
                                //             <TableCell align="center">

                                //                 <img alt="..." src={Pending} className="mr-2" style={{marginRight:"8px"}}/>Pending</TableCell>

                                //             <TableCell align="center">
                                //                 <Link 
                                //                 to ={{ 
                                //                     pathname: "/admin/scheme-details", 
                                //                     userid: row.labour_user_id,
                                //                     showbuttons: true,
                                //                     scheme_avail_id: row.scheme_avail_id
                                //                   }}
                                //                 >

                                //                 <img alt="..." src={Eye} className="mr-2" style={{marginRight:"8px"}}/>View

                                //                 </Link>
                                //             </TableCell>
                                //             <TableCell align="center">
                                //                 {row.sla_days} days left for Approval
                                //             </TableCell>
                                //         </TableRow>
                                //     ))}
                                //     </>

                                //     :

                                //     <p>
                                //         No Pending Schemes Applications for Selected Circle.
                                //     </p>
                                // }
                                // </>
                                ,
                                }[allValues.statusActiveLi]

                            }
                            
                            </TableBody>
                        </Table>
                    </TableContainer>

                    </Col>
                            
                </Row>
        </>
    )
}

export default BenificiarySchemes;