import 'date-fns';
import { Select, MenuItem, ListItemIcon, ListItemText, AppBar, Toolbar, Typography, Drawer, List, ListItem, Divider, withStyles, Menu } from '@material-ui/core';
import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'

import language from 'assets/images/translate (1).svg'
// import home from 'assets/images/Icon material-dashboard 2.svg'
import home from 'assets/images/Icon material-home.svg'

import homewhite from 'assets/images/Icon material-dashboard.svg'
import register from 'assets/images/register-01.svg'
import user from 'assets/images/Icon awesome-user-edit.svg'
import userwhite from 'assets/images/Icon awesome-user-edit 2.svg'
import board from 'assets/images/Icon awesome-chalkboard-teacher.svg'
import boardwhite from 'assets/images/Icon awesome-chalkboard-teacher 2.svg'
import config from 'assets/images/Icon awesome-tools.svg'
import configwhite from 'assets/images/Icon awesome-tools 2.svg'
import mapping from 'assets/images/Icon awesome-link.svg'
import arrowForward from 'assets/images/Icon ionic-ios-arrow-forward.svg'
import reports from 'assets/images/Icon material-report.svg'
import reportswhite from 'assets/images/Icon material-report.svg'
import pending from 'assets/images/Pending SVG-01.svg'
import failedRed from 'assets/images/Failed svg-01.svg'
import tick from 'assets/images/tick-01 (1).svg'
import registerRed from 'assets/images/register-01-red.svg'
import schemes from 'assets/images/Schemes svg-01.svg'
import renewal from 'assets/images/Renewal-01.svg'
import checkgreen from 'assets/images/checked-01.svg'
import closed from 'assets/images/clsoed-01 (1).svg'
import pendingyellow from 'assets/images/Pending-01.svg'
import notification from 'assets/images/Icon material-notifications-active.svg'
import { useDispatch, useSelector } from 'react-redux';
import { SERVER, setLocaleWithFallback, update90DaysData, updateProfileData, updateUploadFile } from 'store/actions/user.actions'
import { Translate } from "react-redux-i18n";
import usericon from 'assets/images/Icon awesome-user.svg'
import logout from 'assets/images/Logout.svg'
import { Tooltip } from '@material-ui/core';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';
import KSUWSSB from './KSUWSSB'; 
import { getTokenCookie } from 'utils/tools';
import axios from 'axios';
import BenificiarySchemes from './benificiarySchemesUnorganized';


const Dashboard = (props) => {

    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const [allValues, setAllValues] = React.useState({
        language: users.profile_details.language,
        users: users.profile_details.users,
        // activestep: users.profile_details.activestep,
        activestep: 2,
        percentComplete: users.profile_details.percentComplete,
        editmode: users.profile_details.editmode,

        // get_registration_counts: null
        // get_registration_counts: {
        //     "approved": {
        //         "total_registrations": "-"
        //     },
        //     "pending": {
        //         "total_registrations": "-"
        //     },
        //     "rejected": {
        //         "total_registrations": "-"
        //     },
        //     "seekClarificationData": {
        //         "total_registrations": "-"
        //     },
        //     "total": {
        //         "total_registrations": "-"
        //     }
        // },
        // get_scheme_counts: null
        // get_scheme_counts: {
        //     "approved": {
        //         "total_scheme_for_approval": "-"
        //     },
        //     "pending": {
        //         "total_scheme_for_approval": "-"
        //     },
        //     "rejected": {
        //         "total_scheme_for_approval": "-"
        //     },
        //     "total": {
        //         "total_scheme_for_approval": "-"
        //     }
        // }

    })

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,

            [event.target.name]: event.target.value
        })

        if (event.target.name === "language")
            dispatch(setLocaleWithFallback(event.target.value))
    }

    const onItemClickHandler = (num) => {
        setAllValues({
            ...allValues,
            activestep: num
        })
    }

    const StyledMenu = withStyles({
        paper: {
            border: '1px solid #d3d4d5',
            width: '350px'
        },
    })((props) => (
        <Menu
            elevation={0}
            getContentAnchorEl={null}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            {...props}
        />
    ));

    const [open, setOpen] = React.useState(false)

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElksu, setAnchorElksu] = React.useState(null);
    const [anchorElconfig, setAnchorElconfig] = React.useState(null);
    const [anchorElmapping, setAnchorElmapping] = React.useState(null);

    const onSelectClickHandlerkboc = (event, data) => {
        setAnchorEl(event.currentTarget);
    }
    const handleClosekboc = (event, data) => {
        setAnchorEl(null);
    }

    const onSelectClickHandlerksu = (event, data) => {
        setAnchorElksu(event.currentTarget);
    }
    const handleCloseksu = (event, data) => {
        setAnchorElksu(null);
    }


    const onSelectClickHandlerconfig = (event, data) => {
        setAnchorElconfig(event.currentTarget);
    }
    const handleCloseconfig = (event, data) => {
        setAnchorElconfig(null);
    }

    const onSelectClickHandlermapping = (event, data) => {
        setAnchorElmapping(event.currentTarget);
    }
    const handleClosemapping = (event, data) => {
        setAnchorElmapping(null);
    }

    useEffect(() => {
        console.log("allValues: " + JSON.stringify(allValues, undefined, 2))
    }, [allValues])


    useEffect(() => {
        if (
            users.getDeptUserRole.data[0].role_name !== undefined &&
            users.getDeptUserRole.data[0].role_name === "Joint Secretary") {
            setAllValues({
                ...allValues,
                activestep: 5,
            })

        }
        else if (allValues.activestep === 1) {
            callDataApis()
        }

    }, [allValues.activestep])

    const callDataApis = () => {
        var get_registration_counts = null
        var get_scheme_counts = null
        var data = JSON.stringify({
            "board_id": 4,
            "department_user_id": users.login_admin.data.department_user_id,
            "status": "all"
        });
        var config = {
            method: 'post',
            url: SERVER + '/dashboard/get_registration_counts',
            headers: {
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data: data
        };

        axios(config)
            .then(function (response) {
                console.log(JSON.stringify(response.data));
                get_registration_counts = response.data.data




                var data2 = JSON.stringify({
                    "board_id": 4,
                    "department_user_id": users.login_admin.data.department_user_id,
                    "status": "all"
                });

                var config2 = {
                    method: 'post',
                    url: SERVER + '/dashboard/get_scheme_counts',
                    headers: {
                        'Authorization': `Bearer ${getTokenCookie()}`
                    },
                    data: data2
                };

                axios(config2)
                    .then(function (response) {
                        console.log(JSON.stringify(response.data));

                        setAllValues({
                            ...allValues,
                            // ...allValues.get_registration_counts,

                            get_registration_counts: get_registration_counts,
                            get_scheme_counts: response.data.data
                        })

                    })
                    .catch(function (error) {
                        console.log(error);
                    });

            })
            .catch(function (error) {
                console.log(error);
            });




    }

    useEffect(() => {

        if (users.login_admin === null || users.login_admin.data === undefined) {
            props.history.push("/admin")
        }
        // else{
        //     callDataApis()  
        // }

        else if (
            users.getDeptUserRole.data[0].role_name !== undefined &&
            users.getDeptUserRole.data[0].role_name === "Joint Secretary") {
            setAllValues({
                ...allValues,
                activestep: 5,
            })

        }
    }, []);

    const openDrawer = () => {
        setOpen(!open)
        console.log("drae")
    }

    const toggleDrawer = () => {
        // if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        //   return;
        // }
        console.log('Drawer Toggle')
        setOpen(false)
        // setState({ ...state, [anchor]: open });
      };

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="dashboard-drawer">< MenuIcon fontSize="large" onClick={openDrawer} /></div>
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a>
                                <h1 className="logo-text">
                                  Karnataka State Unorganized Worker Social Security Board (KSUWSSB)
                            </h1>
                            </div>

                        </Col>
                        {/* <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                // value={allValues.language}
                                value={""}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={usericon} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.login_admin.data.first_name} />
                                </MenuItem>
                                <MenuItem value="logout" onClick={()=>{props.history.push("/admin")}}>
                                    <ListItemText primary="Logout"/>
                                </MenuItem>
                            </Select>
                        </Col> */}

                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <div className="usericon-header-logout">
                                <img alt="..." src={usericon} className="usericon-img" />
                                <p>{users.login_admin.data.first_name}</p>
                                <Tooltip title="Logout" placement="top-start" arrow interactive>
                                    <img alt="..." src={logout} className="logout-img" onClick={() => { props.history.push("/admin") }} />
                                </Tooltip>
                            </div>
                            <div className='d-flex justify-content-end ms-5'>
        <Col xs={12} md={4} lg={3} className="d-flex justify-content-end mt-2 me-2 ms-2 w-100">
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                value={allValues.language}
                                name="language"
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={language} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary="Select Language" />
                                </MenuItem>
                                {/* <MenuItem value="en">English</MenuItem>
                                        <MenuItem value="ka">Kannada</MenuItem> */}
                                <MenuItem value="en">
                                    <ListItemText primary="English" />
                                </MenuItem>
                                <MenuItem value="ka">
                                    <ListItemText primary="ಕನ್ನಡ" />
                                </MenuItem>
                            </Select>
                        </Col>
                        </div>
                        </Col>
                    </Row>

                </Row>

                <Drawer

                    // variant="persistent"
                    anchor="left"
                    open={open}
                    className="dashboard-drawer"
                    onClose={() => toggleDrawer()}
                // className="drawer dashboard"
                // variant="permanent"
                >
                    <div className="drawerContainer">
                        <List>

                            {
                                users.getDeptUserRole.data[0].role_name !== undefined &&
                                    users.getDeptUserRole.data[0].role_name === "Joint Secretary" ?

                                    null
                                    :
                                    <>

                                        {/* <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 1 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(1)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    src={allValues.activestep === 1 ? homewhite : home} />
                                            </ListItemIcon>
                                            <ListItemText primary="Dashboard" />
                                        </ListItem> */}
                                        
                                        <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 2 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(2)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 2 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={register} />
                                            </ListItemIcon>
                                            <ListItemText primary="Registration Details" />
                                        </ListItem>

                                        {   
                                        users.getDeptUserRole.data !== undefined &&
                                            // users.getDeptUserRole.data[0].board_id !== undefined &&
                                            // users.getDeptUserRole.data[0].board_id === 4 &&
                                            users.getDeptUserRole.data[0].role_id !== undefined &&
                                            users.getDeptUserRole.data[0].role_id === 2 ?
                                            <Link style={{color: "black"}}
                                                    to={{  
                                                        pathname: "/unorganized-login", 
                                                        }} 
                                                    // params={{frompage: "admin"}}
                                                    target="_blank"
                                                    >
                                            <ListItem button key="" className={`listitem 
                                                ${allValues.activestep === 7 && 'activeitem'}`} 
                                                >
                                                    <ListItemIcon>
                                                        <img alt="..." 
                                                            className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                            src={register} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="New Registration" />
                                            </ListItem>
                                            </Link>
                                            :
                                            null
                                        }

                                

                                      

                                        {/* <ListItem button key="" className={`listitem 
                                            ${allValues.activestep === 3 && 'activeitem'}`}
                                            onClick={() => props.history.push("/unorganized-registration")} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={register} />
                                            </ListItemIcon>
                                            <ListItemText primary="New Registration" />
                                        </ListItem> */}



                                        {/* <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 3 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(3)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={schemes} />
                                            </ListItemIcon>
                                            <ListItemText primary="Schemes Benificiaries" />
                                        </ListItem>
                                        <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 4 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(4)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 4 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={renewal} />
                                            </ListItemIcon>
                                            <ListItemText primary="Renewals" />
                                        </ListItem> */}

                                    </>
                            }

                            {
                                users.getDeptUserRole.data[0].role_name !== undefined &&
                                    users.getDeptUserRole.data[0].role_name === "Joint Secretary" ?

                                    <>
                                        <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 5 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(5)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 5 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    // src={allValues.activestep === 5 ? reportswhite : reports} />
                                                    src={reports} />
                                            </ListItemIcon>
                                            <ListItemText primary="Dashboard" />
                                        </ListItem>
                                    </>
                                    :
                                    null
                            }
                        </List>

                        {/* <div className="drawer-img-div">
                            <img alt="..." src={vector} />
                        </div> */}
                    </div>
                </Drawer>




                <Drawer
                    className="drawer dashboard"
                    variant="permanent"
                >
                    <div className="drawerContainer">
                        <List>
                            {
                                users.getDeptUserRole.data[0].role_name !== undefined &&
                                    users.getDeptUserRole.data[0].role_name === "Joint Secretary" ?
                                    null
                                    :
                                    <>
                                        {/* <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 1 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(1)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    src={allValues.activestep === 1 ? homewhite : home} />
                                            </ListItemIcon>
                                            <ListItemText primary="Dashboard" />
                                        </ListItem> */}
                                        
                                        <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 2 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(2)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 2 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={register} />
                                            </ListItemIcon>
                                            <ListItemText primary="Registration Details" />
                                        </ListItem>

                                        {
                                            users.getDeptUserRole.data[0].board_id !== undefined &&
                                            users.getDeptUserRole.data[0].board_id === 4 &&
                                            users.getDeptUserRole.data[0].role_id === 2 ?

                                            <Link style={{color: "black"}}
                                                    to={{  
                                                        pathname: "/unorganized-login", 
                                                        // from : "unorganized"
                                                        }} 
                                                    // params={{frompage: "admin"}}
                                                    target="_blank"
                                                    >
                                            <ListItem button key="" className={`listitem 
                                                ${allValues.activestep === 7 && 'activeitem'}`} 
                                                >
                                                    <ListItemIcon>
                                                        <img alt="..." 
                                                            className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                            src={register} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="New Registration" />
                                            </ListItem>
                                            
                                            </Link>
                                            

                                            :
                                            null

                                        }

                                        {   users.getDeptUserRole.data[1] !== undefined &&
                                            users.getDeptUserRole.data[1].board_id !== undefined &&
                                            users.getDeptUserRole.data[1].board_id === 4 &&
                                            users.getDeptUserRole.data[1].role_id !== undefined &&
                                            users.getDeptUserRole.data[1].role_id === 2 ?
                                            <Link style={{color: "black"}}
                                                    to={{  
                                                        pathname: "/unorganized-login", 
                                                        }} 
                                                    // params={{frompage: "admin"}}
                                                    target="_blank"
                                                    >
                                            <ListItem button key="" className={`listitem 
                                                ${allValues.activestep === 7 && 'activeitem'}`} 
                                                >
                                                    <ListItemIcon>
                                                        <img alt="..." 
                                                            className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                            src={register} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="New Registration" />
                                            </ListItem>
                                            </Link>
                                            :
                                            null
                                        }

                                        {   users.getDeptUserRole.data[1] !== undefined &&
                                            users.getDeptUserRole.data[1].board_id !== undefined &&
                                            users.getDeptUserRole.data[1].board_id === 4 &&
                                            users.getDeptUserRole.data[1].role_id !== undefined &&
                                            users.getDeptUserRole.data[1].role_id === 5 ?
                                            <Link style={{color: "black"}}
                                                    to={{  
                                                        pathname: "/unorganized-login", 
                                                        }} 
                                                    // params={{frompage: "admin"}}
                                                    target="_blank"
                                                    >
                                            <ListItem button key="" className={`listitem 
                                                ${allValues.activestep === 7 && 'activeitem'}`} 
                                                >
                                                    <ListItemIcon>
                                                        <img alt="..." 
                                                            className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                            src={register} />
                                                    </ListItemIcon>
                                                    <ListItemText primary="New Registration" />
                                            </ListItem>
                                            </Link>
                                            :
                                            null
                                        }

                                         <ListItem button key="3" className={`listitem ${allValues.activestep === 3 && 'activeitem'}`}
                                        onClick={() => onItemClickHandler(3)} >
                                        <ListItemIcon>   
                                            <img alt="..."
                                                className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                
                                                src={schemes} />
                                        </ListItemIcon>
                                        <ListItemText primary="Schemes Beneficiaries" />
                                    </ListItem>

                                        {/* <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 3 && 'activeitem'}`}
                                            onClick={() => props.history.push("/unorganized-registration")} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={register} />
                                            </ListItemIcon>
                                            <ListItemText primary="New Registration" />
                                        </ListItem> */}



                                        {/* <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 3 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(3)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 3 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={schemes} />
                                            </ListItemIcon>
                                            <ListItemText primary="Schemes Benificiaries" />
                                        </ListItem>
                                        <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 4 && 'activeitem'}`}
                                            onClick={() => onItemClickHandler(4)} >
                                            <ListItemIcon>
                                                <img alt="..."
                                                    className={allValues.activestep === 4 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                    src={renewal} />
                                            </ListItemIcon>
                                            <ListItemText primary="Renewals" />
                                        </ListItem> */}
                                    </>
                            }

                            {/* {
                                users.getDeptUserRole.data[0].role_name !== undefined &&
                                    users.getDeptUserRole.data[0].role_name === "Joint Secretary" ?
                                    <ListItem button key="" className={`listitem 
                                ${allValues.activestep === 5 && 'activeitem'}`}
                                        onClick={() => onItemClickHandler(5)} >
                                        <ListItemIcon>
                                            <img alt="..."
                                                className={allValues.activestep === 5 ? 'dashboardIconActive' : 'dashboardIcon'}
                                                // src={allValues.activestep === 5 ? reportswhite : reports} />
                                                src={reports} />
                                        </ListItemIcon>
                                        <ListItemText primary="Dashboard" />
                                    </ListItem>
                                    : null
                            } */}



                        </List>

                        {/* <div className="drawer-img-div">
                            <img alt="..." src={vector} />
                        </div> */}
                    </div>
                </Drawer>


                <main className="content dashboard">

                    {
                        {
                            2: <KSUWSSB />
                            ,
                            3:
                                <BenificiarySchemes />
                            ,

                        }[allValues.activestep]
                    }

                </main>
            </div>

        </>
    )
}

export default Dashboard;



