import 'date-fns';
import { FormControl, TextField, InputAdornment, Select, MenuItem, RadioGroup, FormControlLabel, Radio, Dialog, DialogTitle, DialogContent, DialogActions, ListItemText, Tooltip, Checkbox, TextareaAutosize, Popover, Typography, Backdrop, CircularProgress, makeStyles, ListItemIcon } from '@material-ui/core';
import { PhoneAndroid, CameraAlt, Visibility, CheckBoxRounded, CheckBoxOutlineBlankRounded, CheckCircle } from '@material-ui/icons';
import React, { useEffect, useRef, useState } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import Webcam from "react-webcam";
import { Link } from 'react-router-dom';
import folder from 'assets/images/folder (3).svg'
import notification from 'assets/images/Icon material-notifications-active.svg'
import webcam from 'assets/images/web-cam.svg'
// import aadhar from 'assets/images/id-card.svg'
import aadhar from 'assets/images/Aadhar black-01.svg'
import personal from 'assets/images/f1-01.svg'
// import checkGreen from 'assets/images/Tick-01.svg'
import checkGreen from 'assets/images/Tick-01.png'
import mobilepic from 'assets/images/Icon awesome-mobile-alt.svg'
// import pan from 'assets/images/credit-card.svg'
import pan from 'assets/images/PAN Black-01.svg'
import edit from 'assets/images/edit-01.svg'
import checkgreen from 'assets/images/Subtraction 1.svg'
import checkgrey from 'assets/images/Subtraction 2.svg'
// import { Edit, Person } from '@material-ui/icons';
import male from 'assets/images/Mask Group 1.svg'
import female from 'assets/images/Mask Group 2.svg'
import radioon from 'assets/images/Icon ionic-ios-radio-button-on-1.svg'
import { useDispatch, useSelector } from 'react-redux';
import { addLabour90DaysDetails, addLabourAddressDetails, addLabourBankDetails, addLabourFamilyDetails, addLabourPersonalDetails, getApplicationNo, getCatalogDetails, getFile, getStateDetails, getUser, getUserRegistrationDetails, SERVER, updatePersonalData, updateProfileData, updateReviewData, uploadFile } from 'store/actions/user.actions';
import { getTokenCookie, Required, showToast } from 'utils/tools';
import family from 'assets/images/f3-01.svg'
// import address from 'assets/images/apartment.svg'
import address from 'assets/images/f2-01.svg'
import bank from 'assets/images/f4-01.svg'
import nintydays from 'assets/images/f5-01.svg'
import moment from 'moment';
import Moment from 'react-moment';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'

import html2canvas from "html2canvas"
import jsPDF from "jspdf"
import { Translate } from 'react-redux-i18n';
import { format } from 'date-fns';
import axios from 'axios';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'


const UnorganizedDetails = (props) => {

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: '#ffffffee',
            color: '#000',
            display: 'block',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        },
        backdropCheck: {
            zIndex: theme.zIndex.drawer + 1,
            color: '#6CC17A',
        },
        backdropCheckp: {
            fontSize: '30px',
            fontWeight: 'bold'
        }
    }));

    const myRef = React.createRef();
    const descRef = React.useRef();
    const dispatch = useDispatch()
    const users = useSelector(state => state.users)

    const classes = useStyles();
    const [address,setAddress] = useState([])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [anchorElpassbook_nominee, setAnchorElpassbook_nominee] = React.useState(null);
    const [anchorElninty, setAnchorElninty] = React.useState(null);
    const [anchorElration, setAnchorElration] = React.useState(null);
    const [BankDocs, setBankDocs] = React.useState({});
    const [NomineeBankDocs, setNomineeBankDocs] = React.useState({});
    const [RationDocs, setRationDocs] = React.useState({});
    const [selected_document_type, setselected_document_type] = React.useState("");




    const [allValues, setAllValues] = React.useState({
        profliePicUploadResponse: users.review.profliePicUploadResponse,
        rationCardUploadResponse: users.review.rationCardUploadResponse,
        passbookUploadResponse: users.review.passbookUploadResponse,
        passbookNomineeUploadResponse: users.review.passbookNomineeUploadResponse,
        nintyDaysUploadResponse: users.review.nintyDaysUploadResponse,
        personalSubmitted: users.review.personalSubmitted,
        addressSubmitted: users.review.addressSubmitted,
        familySubmitted: users.review.familySubmitted,
        bankSubmitted: users.review.bankSubmitted,
        nintyDaysSubmitted: users.review.nintyDaysSubmitted,
        rejectreason: ""
    })

    useEffect(() => {
        dispatch(updateReviewData(allValues))
    }, [allValues]);
    
    const [frompage, setfrompage] = React.useState("");
    const [showbuttons, setshowbuttons] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [submitSuccess, setSubmitSuccess] = React.useState(false);
    const [rejectSuccess, setRejectSuccess] = React.useState(false);
    const [openBackdrop, setOpenBackdrop] = React.useState(false);


    const getfilebase64 = (config) => {
        return new Promise((resolve, reject) => {

            axios(config)
                .then(function (response) {
                    console.log(JSON.stringify(response.data));


                    resolve(response.data.data)
                })
                .catch(function (error) {
                    console.log(error);
                    reject(error)
                });

        })
    }


    const handleClick = (event, data, selecteddoc) => {
        setselected_document_type(selecteddoc)
        console.error("handleclick data:" + data)
        if (data === "passbook")
            setAnchorEl(event.currentTarget);
        else if (data === "passbook_nominee")
            setAnchorElpassbook_nominee(event.currentTarget);
        else if (data === "ninty")
            setAnchorElninty(event.currentTarget);
        else if (data === "ration")
            setAnchorElration(event.currentTarget);
    };

    const handleClose = (event, data) => {
        console.error("handleClose data:" + data)
        if (data === "passbook")
            setAnchorEl(null);
        else if (data === "passbook_nominee")
            setAnchorElpassbook_nominee(null);
        else if (data === "ninty")
            setAnchorElninty(null);
        else if (data === "ration")
            setAnchorElration(null);
    };

    const openPassbook = Boolean(anchorEl);
    const id = openPassbook ? "simple-popover" : undefined;

    const openPassbookNominee = Boolean(anchorElpassbook_nominee);
    const idpassbook_nominee = openPassbookNominee ? "simple-popover" : undefined;

    const openninty = Boolean(anchorElninty);
    const idninty = openninty ? "simple-popover" : undefined;

    const openration = Boolean(anchorElration);
    const idration = openration ? "simple-popover" : undefined;


    

    const handleCloseBackdrop = () => {
        setOpenBackdrop(false);
        props.history.push("/admin/unorganized-dashboard");
    }; 
    const handleAccept = () => {
        console.error("in handleAccept")
        setOpenBackdrop(true);
        setLoading(true);
        setSubmitSuccess(false);
        setRejectSuccess(false);

        


        var dataNEW = JSON.stringify({
            "user_id":users.getUserRegistrationDetails.personal_details[0].id,
            "approval_certificate_id":users.getUserRegistrationDetails.certificate_details[0].labour_work_certificate_id,
            "board_id": 4,
            "catalog_value_registration_satus_id": 47,
            "department_user_id": users.login_admin.data.department_user_id,
            "approval_description": "Approved by "+ users.login_admin.data.username,
            "order_remarks":"TEST-REMARKS",

        });
        var config = {
            method: 'post',
            url: SERVER+'/admin/approve-labour',
            headers: { 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
            data : dataNEW
        };
            
        axios(config)
        .then(function (response) {
            console.error("response approve-labour: "+JSON.stringify(response.data));
            

            setLoading(false);
            setSubmitSuccess(true);

        })
        .catch(function (error) {
            console.error("error approve-labour: "+JSON.stringify(error));
            setLoading(false);
        });
    }
    const handleReject = () => {
        console.error("in handleReject")
        setOpenBackdrop(true);
        setLoading(true);
        setRejectSuccess(false);
        setSubmitSuccess(false);


        var dataNEW = JSON.stringify({
            "user_id":users.getUserRegistrationDetails.personal_details[0].id,
            "approval_certificate_id":users.getUserRegistrationDetails.certificate_details[0].labour_work_certificate_id,
            "board_id": 4,
            "catalog_value_registration_satus_id": 46,
            "department_user_id": users.login_admin.data.department_user_id,
            "reject_description": allValues.rejectreason
        });
        
        var config = {
            method: 'post',
            url: SERVER+'/admin/reject-labour',
            headers: { 
                'Authorization':`Bearer ${getTokenCookie()}`
            },
            data : dataNEW
        };
            
        axios(config)
        .then(function (response) {
            console.error("response reject-labour: "+JSON.stringify(response.data));
            
            setLoading(false);
            setRejectSuccess(true);

        })
        .catch(function (error) {
            console.error("error reject-labour: "+JSON.stringify(error));
            setLoading(false);
        });
    }

    
    const [AckLetterImg, setAckLetterImg] = React.useState("")


    useEffect(() => {
        console.error("in useEffect props:"+props.location.userid )

        if (props.location.userid !== undefined && props.location.userid !== null) {

            let dataForRegDetails = { 
                "key": "user_id",
                "value": props.location.userid,
                "board_id": 4,
                "procedure_name": "all"
            }
            dispatch(getUserRegistrationDetails(dataForRegDetails))
        }

        if (props.location.showbuttons !== undefined && props.location.showbuttons !== null) {

            console.log("props.location.showbuttons: "+props.location.showbuttons)
            setshowbuttons(props.location.showbuttons)
        }

        if (props.location.frompage !== undefined && props.location.frompage !== null) {

            console.log("props.location.frompage: "+props.location.frompage)
            setfrompage(props.location.frompage)
        }

    }, []);

    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (AckLetterImg !== "" && AckLetterImg !== null) {

            let formdata = {
                'file': AckLetterImg,
                'userId': users.user.id,
                'fileType': 'reg_ack'
            }

            console.error("formdata: " + JSON.stringify(formdata))
            dispatch(uploadFile(formdata, "acknowledgement"))
        }
    }, [AckLetterImg])

   useEffect(() => {
        if (
            users.getUserRegistrationDetails !== undefined &&
            users.getUserRegistrationDetails !== null
            // users.getUserRegistrationDetails.application_details !== undefined && 
            // users.getUserRegistrationDetails.application_details !== null && 
            // users.getUserRegistrationDetails.application_details.length > 0 &&
            // users.getUserRegistrationDetails.application_details[0].application_no !== null
        ) {

            if (users.getUserRegistrationDetails.personal_details !== undefined && users.getUserRegistrationDetails.personal_details !== null && users.getUserRegistrationDetails.personal_details[0] !== undefined) {
                dispatch(getFile(
                    users.getUserRegistrationDetails.personal_details[0].user_photo_id,
                    "profilePic")
                )
            }
            if (users.getUserRegistrationDetails.ration_card_details !== undefined) {
                dispatch(getFile(
                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_document_upload_id,
                    "rationCard")
                )
            }
            if (users.getUserRegistrationDetails.bank_details !== undefined && users.getUserRegistrationDetails.bank_details !== null && users.getUserRegistrationDetails.bank_details[0] !== undefined) {
                dispatch(getFile(
                    // users.getUserRegistrationDetails.bank_details[0].bank_passbook_upload_id,
             users.getUserRegistrationDetails.bank_details[0].account_no,
                    "passbook")
                )
            }

            if (users.getUserRegistrationDetails.bank_details !== undefined && users.getUserRegistrationDetails.bank_details.length > 1 && users.getUserRegistrationDetails.bank_details[0] !== undefined) {

                dispatch(getFile(
                    // users.getUserRegistrationDetails.bank_details[1].bank_passbook_upload_id,
                    users.getUserRegistrationDetails.bank_details[1].account_no,
                    "passbook_nominee")
                )
            }
            // if (
            //     users.getUserRegistrationDetails !== undefined &&
            //     users.getUserRegistrationDetails.certificate_details !== undefined &&
            //     users.getUserRegistrationDetails.certificate_details.length > 0
            // ) {

            //     let jsondocs = JSON.parse(users.getUserRegistrationDetails.certificate_details[0].document_uplaod_files_xml)

            //     console.log("jsondocs: " + JSON.stringify(jsondocs));
            //     console.log("jsondocs.length(): " + jsondocs.length);
            //     var docsFromApi = []

            //     let promises = []
            //     for (var i = 0; i < jsondocs.length; i++) {
            //         var config = {
            //             method: 'get',
            //             url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
            //             headers: {
            //                 'Authorization': `Bearer ${getTokenCookie()}`
            //             },
            //         };

            //         promises.push(getfilebase64(config))
            //         if (promises.length === jsondocs.length) {
            //             Promise.all(promises)
            //                 .then(results => {
            //                     console.log("results of promise: ")
            //                     console.log(results)

            //                     var certdoc = []
            //                     results.forEach((j, index) => {
            //                         console.log("jsondocs_inside: " + JSON.stringify(j));
            //                         console.log("jsondocs_inside index: " + index);

            //                         var cert = {
            //                             ['name']: jsondocs[index].file_name,
            //                             ['base_64_content']: j.base_64_content,
            //                         }
            //                         certdoc.push(cert)
            //                     })

            //                     setcertificateDocs(certdoc)
            //                     console.log("certdoc: " + JSON.stringify(certdoc));
            //                 })
            //         }


            //     }
            // }


            if (
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails.ration_card_details !== undefined &&
                users.getUserRegistrationDetails.ration_card_details.length > 0 &&
                users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml !== ""
            ) {

                var jsondocs = JSON.parse(users.getUserRegistrationDetails.ration_card_details[0].ration_card_files_xml)

                console.log("jsondocs: " + JSON.stringify(jsondocs));
                console.log("jsondocs.length(): " + jsondocs.length);
                var docsFromApi = []

                let promises = []
                for (var i = 0; i < jsondocs.length; i++) {
                    var config = {
                        method: 'get',
                        url: SERVER + '/upload/download_base_64/' + jsondocs[i].file_id,
                        headers: {
                            'Authorization': `Bearer ${getTokenCookie()}`
                        },
                    };

                    promises.push(getfilebase64(config))
                    if (promises.length === jsondocs.length) {
                        Promise.all(promises)
                            .then(results => {
                                // console.log("results of promise: ")
                                // console.log(results)

                                var certdoc = []
                                results.forEach((j, index) => {
                                    // console.log("jsondocs_inside: "+JSON.stringify(j));
                                    // console.log("jsondocs_inside index: "+index);

                                    var cert = {
                                        ['name']: jsondocs[index].file_name,
                                        ['base_64_content']: j.base_64_content,
                                    }
                                    certdoc.push(cert)
                                })

                                setRationDocs(certdoc)
                                // console.log("certdoc: "+JSON.stringify(certdoc));
                            })
                    }


                }
            }

            if (
                users.getUserRegistrationDetails !== undefined &&
                users.getUserRegistrationDetails.bank_details !== undefined &&
                users.getUserRegistrationDetails.bank_details.length > 0
            ) {

                var nomineeIndex = null
                var labourIndex = null

                if (users.getUserRegistrationDetails.bank_details.length === 2) {
                    if (users.getUserRegistrationDetails.bank_details[0].is_nominee) {
                        nomineeIndex = 0
                        labourIndex = 1
                    }
                    else if (users.getUserRegistrationDetails.bank_details[1].is_nominee) {
                        nomineeIndex = 1
                        labourIndex = 0
                    }
                }


                if (nomineeIndex === null) {
                    var jsondocs1 = JSON.parse(users.getUserRegistrationDetails.bank_details[0].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);

                    let promises = []
                    for (var i = 0; i < jsondocs1.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs1[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises.push(getfilebase64(config))
                        if (promises.length === jsondocs1.length) {
                            Promise.all(promises)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs1[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setBankDocs(certdoc)
                                    // console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }


                    }
                }
                else {
                    var jsondocs2 = JSON.parse(users.getUserRegistrationDetails.bank_details[labourIndex].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);
                    var docsFromApi = []

                    let promises = []
                    for (var i = 0; i < jsondocs2.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs2[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises.push(getfilebase64(config))
                        if (promises.length === jsondocs2.length) {
                            Promise.all(promises)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs2[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setBankDocs(certdoc)
                                    // console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }
                    }



                    var jsondocs3 = JSON.parse(users.getUserRegistrationDetails.bank_details[nomineeIndex].bank_upload_files_xml)
                    // console.log("jsondocs: "+JSON.stringify(jsondocs));
                    // console.log("jsondocs.length(): "+jsondocs.length);

                    let promises1 = []
                    for (var i = 0; i < jsondocs3.length; i++) {
                        var config = {
                            method: 'get',
                            url: SERVER + '/upload/download_base_64/' + jsondocs3[i].file_id,
                            headers: {
                                'Authorization': `Bearer ${getTokenCookie()}`
                            },
                        };

                        promises1.push(getfilebase64(config))
                        if (promises1.length === jsondocs3.length) {
                            Promise.all(promises1)
                                .then(results => {
                                    // console.log("results of promise: ")
                                    // console.log(results)

                                    var certdoc = []
                                    results.forEach((j, index) => {
                                        // console.log("jsondocs_inside: "+JSON.stringify(j));
                                        // console.log("jsondocs_inside index: "+index);

                                        var cert = {
                                            ['name']: jsondocs3[index].file_name,
                                            ['base_64_content']: j.base_64_content,
                                        }
                                        certdoc.push(cert)
                                    })

                                    setNomineeBankDocs(certdoc)
                                    console.log("certdoc: "+JSON.stringify(certdoc));
                                })
                        }


                    }
                }

            }



        }
    }, [users.getUserRegistrationDetails])

    useEffect(() => {
        if(
            users.getFile !== null && users.getFile !== undefined && 
            users.getFile.fileName !== undefined 
            ){

                if(users.getFile.fileName === "profilePic"){
                    sessionStorage.setItem('profilePic', users.getFile.data);
                }
                if(users.getFile.fileName === "rationCard"){
                    sessionStorage.setItem('rationCard', users.getFile.data);
                }
                if(users.getFile.fileName === "passbook"){
                    sessionStorage.setItem('passbook', users.getFile.data);
                }
                if(users.getFile.fileName === "passbook_nominee"){
                    sessionStorage.setItem('passbook_nominee', users.getFile.data);
                }
                if(users.getFile.fileName === "90_days_certificate"){
                    sessionStorage.setItem('90_days_certificate', users.getFile.data);
                }

        }
    }, [users.getFile])


    useEffect(() => {
        // console.error("in useEffect imgfile ")
        if (users.upload_file !== null && users.upload_file.success !== undefined && users.upload_file.success === true) {
            if (users.upload_file.userFileName === "acknowledgement") {
                setAllValues({
                    ...allValues,
                    documentUploadResponse: users.upload_file.image
                })
            }
        }
    }, [users.upload_file])

    const printDocument = () => {
        const input = document.getElementById('divToPrint');
        input.style.display = 'block';
        html2canvas(input, {
            scrollY: -window.scrollY,
            // allowTaint: true,
            // useCORS: true
        })
        .then((canvas) => {
            const imgData = canvas.toDataURL('image/png');
            setAckLetterImg(imgData)
            const pdf = new jsPDF();
            pdf.addImage(imgData, 'JPEG', 0, 0, 210, 297);
            // pdf.addImage(imgData, 'JPEG', 0, 0);
            pdf.save("acknowledgement.pdf");
            window.open(pdf.output('bloburl'), '_blank')
          })
          .then(() => {
            input.style.display = 'none';
          })
        ;
      }

    var appNo = ""
    var applicantName = ""
    var date = new Date()
    var presentAddress = ""
    var villageWard = ""
    var mobile = ""
    var occupation = ""
    var officerIncharge = ""
    var circleName = ""

    const handleChange = (event) => {
        console.log(event.target)
        console.log(event.target.value)
        setAllValues({
            ...allValues,

            [event.target.name]: event.target.value
        })
    }

    useEffect(() => {
        console.error("allValues: "+ JSON.stringify(allValues,undefined,2))
    }, [allValues])
    
    const [openSaveDraft, setopenSaveDraft] = React.useState(false)
    const handleClickOpenSaveDraft = () => {
        setopenSaveDraft(true)
    }
    const handleCloseSaveDraft = () => {
        setopenSaveDraft(false)
    }
    const handleClickSaveDraft = () => {
        setopenSaveDraft(false)
        
        handleReject()

    }

    useEffect(()=>{
        var dateData = {
            "labour_user_id":props.location.userid,
        }
        var config = {
            method: 'post',
            url: SERVER + "/user/get_ad_details",
            headers: { 
                'Content-Type': 'application/json', 
                'Authorization': `Bearer ${getTokenCookie()}`
    
            },
            data : dateData
           
          };

          axios(config).then(function(response){
            console.log("DateData:",response.data.data[0].dob);
            setAddress(response.data.data[0]);
          }).catch(function(error){
            console.log(error);
          })
    },[])
  


    if(!users.getUserRegistrationDetails || users.getUserRegistrationDetails?.address_details?.length == 0 || 
        !users.getUserRegistrationDetails.personal_details || users.getUserRegistrationDetails?.personal_details?.length==0){
        return (
        <Backdrop className={classes.backdrop} open={openBackdrop}>
            <CircularProgress color="inherit" />
        </Backdrop>
        )
    }
    return (
        <>
        <Dialog
                disableBackdropClick
                disableEscapeKeyDown
                maxWidth="xl"
                aria-labelledby="confirmation-dialog-title"
                open={openSaveDraft}
                onClose={handleCloseSaveDraft}
            >
                <DialogTitle id="form-dialog-title">Reject</DialogTitle>
                <DialogContent>
                    <TextareaAutosize
                        variant="outlined"
                        multiline
                        rows={3}
                        style={{ width: "500px" }}
                        placeholder="Remarks"
                        name="rejectreason"
                        value={allValues.rejectreason}
                        onChange={handleChange}
                        
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseSaveDraft} variant="outline-secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleClickSaveDraft} variant="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>


        <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                            <a href="/admin/unorganized-dashboard" className="cal-icon-hover-icn" style={{ marginTop:"20px" }} >
                                <img alt="..." className="cal-icon hover-icn" src={back}/>
                            </a>
                            <Link to="/admin/unorganized-dashboard">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                            </Link>
                                {/* <img id="logo" src={logo} alt="..." className="logo-img" /> */}
                                <h1 className="logo-text">
                                Karnataka State Unorganized Worker Social Security Board (KSUWSSB)
                            </h1>
                            </div>

                        </Col>
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Select
                                className="select-language"
                                style={{ width: '100%' }}
                                variant="outlined"
                                labelId="demo-simple-select-required-label"
                                name="language"
                                value=""
                                displayEmpty
                                onChange={handleChange}
                            >
                                <MenuItem value="" >
                                    <ListItemIcon>
                                        <img alt="..." src={notification} className="language-img" />
                                    </ListItemIcon>
                                    <ListItemText primary={users.login_admin.data.first_name}/>
                                </MenuItem>
                                <MenuItem value="logout" onClick={()=>{props.history.push("/admin")}}>
                                    <ListItemText primary="Logout"/>
                                </MenuItem>
                            </Select>
                        </Col>
                    </Row>

                </Row>
                </div>


                {
                users.getUserRegistrationDetails === null ?
                    <CircularProgress className="loader-otp" /> :
                <Row className="m-0">

                {
                    loading ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        : null
                }
                
                {
                    submitSuccess ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <div style={{display: "block"}}>
                                <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "200px"}}/>
                                <p className="final-success-title" >Application Accepted Successfully!</p>
                            </div>

                            
                        </Backdrop>
                        : null
                }
                {
                    rejectSuccess ?
                        <Backdrop className={classes.backdrop} open={openBackdrop}
                            onClick={loading ? null : handleCloseBackdrop}
                        >
                            <div style={{display: "block"}}>
                                <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "200px"}}/>
                                <p className="final-success-title" >Application Rejected Successfully!</p>
                            </div>

                            
                        </Backdrop>
                        : null
                }


                <Row className="final all-details-subtitle-row">
                    <Col xs={12} className="profile-title">
                        <img alt="..." src={personal}/>
                        <h1>Personal Details</h1> 
                    </Col>
                </Row>

                <Row className="form-row-final mt-4">
                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="First Name" />
                        <FormControl fullWidth>
                            <TextField
                                name="firstname"
                                // value={users.getUserRegistrationDetails.personal_details[0].first_name}
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details !== undefined 
                                    ? users.getUserRegistrationDetails.personal_details[0].first_name : ""}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Middle Name </p>
                        <FormControl fullWidth>
                            <TextField
                                name="middlename"
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details !== undefined 
                                    ? users.getUserRegistrationDetails.personal_details[0].middle_name : ""}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Last Name </p>
                        <FormControl fullWidth>
                            <TextField
                                placeholder="Enter Your Last Name"
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details !== undefined 
                                    ? users.getUserRegistrationDetails.personal_details[0].last_name : ""}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Education Level</p> 
                            <FormControl  fullWidth> 
                            <TextField 
                                placeholder="Education"
                                name="education"
                                value={users.getUserRegistrationDetails.personal_details[0].qualification} 
                                disabled
                            />
                            </FormControl>

                            <Required className="mt-3 mb-2" title="Nature of Work" /> 
                            <FormControl variant="outlined" fullWidth className="formcontrol5">
                                
                                <TextField 
                                    placeholder="Nature of Work"
                                    name="natureWorkplace"
                                    value={users.getUserRegistrationDetails.personal_details[0].nature_of_work}
                                    disabled
                                />
                            </FormControl>

                    </Col>

                    <Col xs={4} className="final-personal-col-2 px-3">
                        <Required className="mb-0" title="Mobile Number" />
                        <FormControl fullWidth className="formcontrol2">
                            <TextField
                                disabled
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <PhoneAndroid className="phone-icon" />
                                                +91
                                            </InputAdornment>,
                                }}
                                value={
                                    users.getUserRegistrationDetails !== null && 
                                    users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details !== undefined 
                                    ? users.getUserRegistrationDetails.personal_details[0].mobile_no : ""}
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Email Address <span className="optional">(Optional)</span></p>
                        <FormControl fullWidth className="formcontrol3">
                            <TextField
                                name="email"
                                value={ 
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" &&
                                    users.getUserRegistrationDetails.personal_details?.length>0  
                                    ? users.getUserRegistrationDetails.personal_details[0].mail_id : ""}
                                disabled
                            />
                        </FormControl>


                        {/* <p className="mt-3 mb-0">Aadhar Card Number*</p> */}

                        {/* <FormControl fullWidth className="pan-div">
                            <TextField
                                disabled
                                value={
                                    users.getUserRegistrationDetails !== null && 
                                    users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.personal_details !== undefined &&
                                    users.getUserRegistrationDetails.personal_details.length>0  
                                    ? users.getUserRegistrationDetails.personal_details[0].aadhar_no.replace(/\d(?=\d{4})/g, "X") : ""}
                                InputProps={{
                                    startAdornment:
                                        <InputAdornment position="start">
                                            <img alt="..." src={aadhar} />
                                        </InputAdornment>,
                                }}
                            />
                        </FormControl> */}
                        <p className="mt-3 mb-0">Religion</p>
                            <FormControl  fullWidth className="formcontrol5"> 
                                <TextField 
                                    placeholder="Religion"
                                    name="religion"
                                    value={users.getUserRegistrationDetails.personal_details[0].religion} 
                                    disabled
                                    />
                            </FormControl>
                            {users.getUserRegistrationDetails.personal_details[0].employerContact !== "" && users.getUserRegistrationDetails.personal_details[0].employerContact !== null
                                ?
                                <>
                                    <p className="mt-3 mb-0">Employer Contact</p>
                                    <FormControl  fullWidth className="formcontrol5"> 
                                        <TextField 
                                            // placeholder="contact"
                                            name="contact"
                                            value={users.getUserRegistrationDetails.personal_details[0].employer_contact_number} 
                                            disabled
                                        />
                                    </FormControl>
                                </>
                                :
                                null
                            }

                            
                        {
                            users.getUserRegistrationDetails !== undefined && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details[0].is_inter_state_migrant ?
                               
                                <>
                                    <p className="mt-3 mb-0">Migrant From State</p>
                                    <FormControl fullWidth>
                                        <TextField
                                            value={
                                                users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details !== undefined 
                                                ? users.getUserRegistrationDetails.personal_details[0].state : ""}
                                            disabled
                                        />
                                    </FormControl>
                                </>

                                : null
                        }

                    </Col>
                    <Col xs={4} className="final-personal-col-3 px-3">
                        <div className="picupload">
                            {
                                sessionStorage.getItem('profilePic') === null ?
                                    (
                                        <CameraAlt className="cameraAlt2" />
                                    )
                                    :
                                    (
                                        <img
                                            alt="..."
                                            className="avatar border-gray"
                                            src={sessionStorage.getItem('profilePic')}
                                            // src={users.personal_details.imgfile}
                                        />
                                    )
                            }


                        </div>

                        <Required className="mb-0" title="Marital Status" /> 
                        <FormControl fullWidth className="formcontrol2">
                            <TextField
                                disabled 
                                value={users.getUserRegistrationDetails.personal_details[0].marital_status}
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Gender</p>
                        <FormControl fullWidth className="formcontrol3">
                            <TextField
                                name="email"
                                value={users.getUserRegistrationDetails.personal_details[0].gender}
                                disabled
                            />
                        </FormControl>
                  
                        <p className="mt-3 mb-0">DOB</p>
                        <FormControl fullWidth className="pan-div">
                            <TextField
                                disabled
                                // value={users.getUserRegistrationDetails.personal_details[0].date_of_birth } 
                                value={moment(users.getUserRegistrationDetails.personal_details[0].date_of_birth ).format('DD.MM.YYYY')}
                            />
                        </FormControl>
        
                         <p className="mt-3 mb-0">Category</p>
                            <FormControl  fullWidth className="formcontrol5"> 
                                <TextField 
                                    placeholder="Religion"
                                    name="religion"
                                    disabled
                                    value={users.getUserRegistrationDetails.personal_details[0].caste} 
                                />
                            </FormControl>

                     <p className="mt-3 mb-0">Income</p>
                            <FormControl  fullWidth className="formcontrol5"> 
                                <TextField 
                                    // placeholder="income"
                                    name="income"
                                    disabled
                                    value={users.getUserRegistrationDetails.personal_details[0].income_earned[0]=== 1080 ? "Per Day" : users.getUserRegistrationDetails.personal_details[0].income_earned[0]=== 1082 ? "Per Month" : "" } 
                                />
                            </FormControl>


                    </Col>
                </Row>


                <Row className="form-row-final mt-3">
                <Col xs={4} className="final-personal-col-2 px-3">
                <Required className="mt-3 mb-2" title="Employer Name" /> 
                            <FormControl variant="outlined" fullWidth className="formcontrol5">
                                
                                <TextField 
                                    // placeholder="Self Employed"
                                    name="selfemployed"
                                    value={users.getUserRegistrationDetails.personal_details[0].employer_name}
                                    disabled
                                />
                            </FormControl>
                </Col>
                <Col xs={4} className="final-personal-col-2 px-3">
                <Required className="mt-3 mb-2" title="Self Employed" /> 
                            <FormControl variant="outlined" fullWidth className="formcontrol5">
                                
                                <TextField 
                                    placeholder="Self Employed"
                                    name="selfemployed"
                                    value={users.getUserRegistrationDetails.personal_details[0].employement_status}
                                    disabled
                                />
                            </FormControl>
                </Col>  
                {users.getUserRegistrationDetails !== undefined && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.personal_details[0].employer_address ?

                <Col xs={8} className="final-personal-col-2 px-3">
                    <p className="mt-3 mb-2">Employer Address</p>
                        <FormControl fullWidth className="formcontrol1"
                        >
                            <TextareaAutosize 
                            disabled
                                multiline
                                rows={3} 
                                placeholder="Enter Address of Employer"
                                name="address"
                                value={users.getUserRegistrationDetails.personal_details[0].employer_address} 
                            />
                    </FormControl>
                    </Col>
                    :
                    null
                    }
                </Row>

                <Row className="mt-5 mb-0 final all-details-subtitle-row">
                    <Col xs={12} className="profile-title">
                        <img alt="..." src={address} />
                        <h1>Address Details</h1> 
                    </Col>
                </Row>


                 <Row className="form-row-final with-border mt-0">
                   <Col xs={12} className="profile-title">
                        <h2>Permanent Address</h2>
                    </Col>
                    <Col xs={4} className="final-personal-col-1 px-3">
                        {/* <Required className="mb-0" title="Type of Residence" />
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].address_base_type}
                                
                                disabled
                            />
                        </FormControl> */}

                        <p className="mt-3 mb-0">City Name </p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.village_city_town}
                                
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Village Name </p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.village_city_town}
                                
                                disabled
                            />
                        </FormControl>

                    </Col>

                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="House/Building Number" />
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.house_no}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">District</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.district}
                                
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Taluk</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.sub_district}
                                
                                disabled
                            />
                        </FormControl>

                    </Col>

                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Street/Road Name" />
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.street}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Landmark</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={address.landmark}
                                disabled
                            />
                        </FormControl>

                        <Row>
                            <Col xs={6} className="final-personal-col-1 ">
                                <p className="mt-3 mb-0">State</p>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        value={address.state}
                                        
                                        disabled
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={6} className="final-personal-col-1 ">
                                <p className="mt-3 mb-0">pin_code</p>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        value={address.pincode}
                                        disabled
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                       </Col>
                  </Row>
 
              
 

                <Row className="form-row-final with-border mt-0">

                    <Col xs={12} className="profile-title">
                        <h2>Residential Address</h2>
                    </Col>
                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Type of Residence" />
                        <FormControl fullWidth>
                            
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].address_base_type}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">City Name </p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].panhayat_city_town}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Village Name </p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].ward_area_village}
                                
                                disabled
                            />
                        </FormControl>

                    </Col>

                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="House/Building Number" />
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].door_no}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">District</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].district}
                                
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Taluk</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].taluk}
                                
                                disabled
                            />
                        </FormControl>

                    </Col>

                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Street/Road Name" />
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].street_name}
                                disabled
                            />
                        </FormControl>

                        <p className="mt-3 mb-0">Landmark</p>
                        <FormControl fullWidth>
                            <TextField
                                variant="outlined"
                                value={users.getUserRegistrationDetails.address_details[0].land_mark}
                                disabled
                            />
                        </FormControl>

                        <Row>
                            <Col xs={6} className="final-personal-col-1 ">
                                <p className="mt-3 mb-0">State</p>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        value={users.getUserRegistrationDetails.address_details[0].state}
                                        
                                        disabled
                                    />
                                </FormControl>
                            </Col>
                            <Col xs={6} className="final-personal-col-1 ">
                                <p className="mt-3 mb-0">Pincode</p>
                                <FormControl fullWidth>
                                    <TextField
                                        variant="outlined"
                                        value={users.getUserRegistrationDetails.address_details[0].pin_code}
                                        disabled
                                    />
                                </FormControl>
                            </Col>
                        </Row>


                    </Col> 

                </Row>



                <Row className="mt-5 mb-0 final all-details-subtitle-row">
                    <Col xs={12} className="profile-title">
                        <img alt="..." src={family} />
                        <h1>Family Details</h1>
                        
                    </Col>
                </Row>
                <Row className="form-row-final mt-4">
                    <Col xs={12} className="profile-title">
                        <h2>Ration Card Details</h2>
                    </Col>
                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Ration Card Number" />
                        <FormControl fullWidth>
                            <TextField
                                value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.ration_card_details !== undefined 
                                    ? users.getUserRegistrationDetails.ration_card_details[0].ration_card_no : ""}
                                
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Ration Card Type" />
                        <FormControl fullWidth>
                            <TextField
                                value={
                                    users.getUserRegistrationDetails !== null && 
                                    users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.ration_card_details !== undefined 
                                    ? 
                                    users.getUserRegistrationDetails.ration_card_details[0].ration_card_type : ""}
                                
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={4} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Ration Card Document" />
                        <div className="browsebutton2-div final">
                            <FormControl>
                                <TextField
                                    variant="outlined"
                                    value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.ration_card_details !== undefined 
                                        ? users.getUserRegistrationDetails.ration_card_details[0].ration_card_document_upload_id : ""}
                                    
                                    disabled
                                />
                            </FormControl>
                            <label className="browse-button2" onClick={(e) => handleClick(e, "ration")}>
                                {/* <img alt="..." src={Visibility} /> */}
                                View <Visibility />
                            </label>
                            <Popover
                                id={idration} open={openration} anchorEl={anchorElration} onClose={(e) => handleClose(e, "ration")}
                                anchorOrigin={{
                                    vertical: "bottom",
                                    horizontal: "center"
                                }}
                                transformOrigin={{
                                    vertical: "top",
                                    horizontal: "center"
                                }}
                            >
                                <img
                                    alt="..."
                                    className="avatar border-gray"
                                    src={sessionStorage.getItem('rationCard')}
                                    // src={users.bank_details.imgfile}
                                />
                            </Popover>
                        </div>
                    </Col>

                    <Col xs={12} className="profile-title mmb-0">
                        <h2>Family Member Details</h2>
                    </Col>

                    {
                        users.getUserRegistrationDetails !== undefined && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details.map((id, i) => {
                            console.log("in family_details map: i="+i)
                            return (
                                <>
                                    <Row className={"mt-0 familymember-card final row" + i}>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Relation :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth >
                                                        <TextField
                                                            value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                ? users.getUserRegistrationDetails.family_details[i].parent_child_relation : ""}
                                                            
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >First Name :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                ? users.getUserRegistrationDetails.family_details[i].first_name : ""}
                                                            
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Middle Name :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                ? users.getUserRegistrationDetails.family_details[i].middle_name : ""}
                                                            
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Last Name :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth>
                                                        <TextField
                                                            value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                ? users.getUserRegistrationDetails.family_details[i].last_name : ""}
                                                            
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Aadhar :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <div className="aadhar-div">
                                                        <img alt="..." src={aadhar} />
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                className="aadhardiv"
                                                                value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails.family_details !== undefined 
                                                                    ? users.getUserRegistrationDetails.family_details[i].aadhar_no.replace(/\d(?=\d{4})/g, "X") : ""}

                                                                
                                                                inputProps={{ minLength: 0, maxLength: 4 }}
                                                                disabled
                                                            />
                                                        </FormControl>
                                                        {/* {
                                                            users.family_details.membersList[i]["aadharcheck" + i] ?
                                                                (<img alt="..." src={checkgreen} />)
                                                                : */}
                                                                {/* (<img alt="..." src={checkgrey} />) */}
                                                        {/* } */}
                                                    </div>

                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >DOB :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth>
                                                        <TextField
                                                         value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                            ? moment(users.getUserRegistrationDetails.family_details[i].date_of_birth).format('DD.MM.YYYY') : ""}
                                                            
                                                            disabled
                                                        />
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Profession :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth >
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                    ? users.getUserRegistrationDetails.family_details[i].proffession : ""}
                                                                    
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} md={6} >
                                            <Row>
                                                <Col xs={3} className="member-p">
                                                    <p >Education :</p>
                                                </Col>
                                                <Col xs={9} >
                                                    <FormControl fullWidth >
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                value={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                    ? users.getUserRegistrationDetails.family_details[i].education : ""}
                                                                 
                                                                disabled
                                                            />
                                                        </FormControl>
                                                    </FormControl>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} className="member-p">
                                            <FormControlLabel
                                                control={
                                                    <>
                                                        <Checkbox
                                                            checked={users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && users.getUserRegistrationDetails.family_details !== undefined 
                                                                ? users.getUserRegistrationDetails.family_details[i].is_nominee : ""
                                                             }
                                                            disabled
                                                        />
                                                    </>
                                                }
                                                label="Nominee"
                                            />
                                        </Col> 
                                    </Row>
                                </>
                            )
                        })
                    }
                </Row>




                <Row className="mt-5 mb-0 final all-details-subtitle-row">
                    <Col xs={12} className="profile-title">
                        <img alt="..." src={bank} />
                        <h1>Bank Details</h1>
                        
                    </Col>
                </Row>
                <Row className="form-row-final mt-4">
                    <Col xs={3} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="IFSC Code" />
                        <FormControl fullWidth>
                            <TextField
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.bank_details !== undefined 
                                    ? users.getUserRegistrationDetails.bank_details[0].ifsc_code : ""}
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={3} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Account Number" />
                        <FormControl fullWidth>
                            <TextField
                            value={
                                users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && 
                                users.getUserRegistrationDetails.bank_details !== undefined 
                                ? users.getUserRegistrationDetails.bank_details[0].account_no : ""}
                            disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={3} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Bank Name" />
                        <FormControl fullWidth>
                            <TextField
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.bank_details !== undefined 
                                    ? users.getUserRegistrationDetails.bank_details[0].bank_name : ""}
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={3} className="final-personal-col-1 px-3">
                        <Required className="mb-0" title="Bank Branch" />
                        <FormControl fullWidth>
                            <TextField
                                 value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.bank_details !== undefined 
                                    ? users.getUserRegistrationDetails.bank_details[0].bank_branch : ""}
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                        <Required className="mb-0" title="Bank Address" />
                        <FormControl fullWidth>
                            <TextareaAutosize
                                variant="outlined"
                                multiline
                                rows={4}
                                value={
                                    users.getUserRegistrationDetails !== null && users.getUserRegistrationDetails !== "Unauthorized" && 
                                    users.getUserRegistrationDetails.bank_details !== undefined 
                                    ? users.getUserRegistrationDetails.bank_details[0].bank_address : ""}
                                disabled
                            />
                        </FormControl>
                    </Col>
                    <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                        <Required className="mb-0" title="Passbook Document" />
                        <div className="browsebutton2-div final">
                            {
                                        Object.keys(BankDocs).map((id, i) =>
                                            <>
                                                
                                                    <FormControl>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                BankDocs !== undefined &&
                                                                BankDocs.length > 0 &&
                                                                BankDocs[i].name
                                                            }
                                                            style={{ width: "-webkit-fill-available" }}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <label className="browse-button2"
                                                        onClick={(e) => handleClick(e, "passbook", BankDocs[i].base_64_content)}>
                                                        <Translate value="view" /> <Visibility />
                                                    </label>
                                               

                                            </>

                                        )
                                    }
                                        
                                      <Popover
                                        id={id}
                                        open={openPassbook}
                                        anchorEl={anchorEl}
                                        onClose={(e) => handleClose(e, "passbook")}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                    >
                                        <a href="#" >
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // id={item.id}
                                                src={`
                                data:image/jpeg;base64,${selected_document_type}`}

                                                onClick={() => {
                                                    var win = window.open();
                                                    win.document.write('<iframe src="' + `
                                    data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                }}
                                            />
                                        </a>
                                    </Popover>  
                        </div>
                    </Col>

                    {
                        users.getUserRegistrationDetails.bank_details.length > 1 ?
                            <>
                                <Col xs={12} className="profile-title">
                                    <h2>Nominee Bank Details</h2>
                                </Col>
                                <Col xs={3} className="final-personal-col-1 px-3">
                                        <Required className="mb-0" title={<Translate value="iFSCCode" />} />
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={users.getUserRegistrationDetails.bank_details[1].ifsc_code}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={3} className="final-personal-col-1 px-3">
                                        <Required className="mb-0" title={<Translate value="accountNumber" />} />
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={users.getUserRegistrationDetails.bank_details[1].account_no}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={3} className="final-personal-col-1 px-3">
                                        <Required className="mb-0" title={<Translate value="bankName" />} />
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={users.getUserRegistrationDetails.bank_details[1].bank_name}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={3} className="final-personal-col-1 px-3">
                                        <Required className="mb-0" title={<Translate value="bankBranch" />} />
                                            <FormControl fullWidth>
                                                <TextField
                                                    value={users.getUserRegistrationDetails.bank_details[1].bank_branch}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                                        <Required className="mb-0" title={<Translate value="bankAddress" />} />
                                            <FormControl fullWidth>
                                                <TextareaAutosize
                                                    variant="outlined"
                                                    multiline
                                                    rows={4}
                                                    value={users.getUserRegistrationDetails.bank_details[1].bank_address}
                                                    disabled
                                                />
                                            </FormControl>
                                        </Col>
                                <Col xs={6} className="final-personal-col-1 px-3 mt-3">
                                    <Required className="mb-0" title="Passbook Document" />
                                    <div className="browsebutton2-div final">
                                        {
                                        Object.keys(BankDocs).map((id, i) =>
                                            <>
                                                
                                                    <FormControl>
                                                        <TextField
                                                            variant="outlined"
                                                            value={
                                                                BankDocs !== undefined &&
                                                                BankDocs.length > 0 &&
                                                                BankDocs[i].name
                                                            }
                                                            style={{ width: "-webkit-fill-available" }}
                                                            disabled
                                                        />
                                                    </FormControl>
                                                    <label className="browse-button2"
                                                        onClick={(e) => handleClick(e, "passbook", BankDocs[i].base_64_content)}>
                                                        <Translate value="view" /> <Visibility />
                                                    </label>
                                                

                                            </>

                                        )
                                    }
                                        
                                      <Popover
                                        id={id}
                                        open={openPassbook}
                                        anchorEl={anchorEl}
                                        onClose={(e) => handleClose(e, "passbook")}
                                        anchorOrigin={{
                                            vertical: "bottom",
                                            horizontal: "center"
                                        }}
                                        transformOrigin={{
                                            vertical: "top",
                                            horizontal: "center"
                                        }}
                                    >
                                        <a href="#" >
                                            <img
                                                alt="..."
                                                className="avatar border-gray"
                                                // id={item.id}
                                                src={`
                                data:image/jpeg;base64,${selected_document_type}`}

                                                onClick={() => {
                                                    var win = window.open();
                                                    win.document.write('<iframe src="' + `
                                    data:image/jpeg;base64,${selected_document_type}` + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" style="-webkit-transform:scale(0.5);-moz-transform-scale(0.5);" allowfullscreen></iframe>');

                                                }}
                                            />
                                        </a>
                                    </Popover>  
                                        
                                        
                                        
                                    </div>
                                </Col>

                            </>
                            : null
                    }
                </Row>
 

                <Row className="mt-5 mb-0 final all-details-subtitle-row">
                    <Col xs={12} className="profile-title">
                        <img alt="..." src={nintydays} />
                        <h1>Remark</h1>
                        
                    </Col>
                </Row>
                
                
                <Row className="form-row-final mt-4">
                    <Col xs={4} className="final-personal-col-1 px-3">
                    <p><b>{users.getUserRegistrationDetails.personal_details[0].approval_rejection_description}</b></p>
                    </Col>
                </Row>


                {
                 showbuttons && 
                 frompage === "Appealed" && 
                 users.getDeptUserRole.data[0].role_name === "Assistant Labour Commissioner" ? 
                 <>
                    <Row className="button-inside-form-row mt-5 mb-5" style={{
                        borderTop: "2px solid #cccccc", textAlign: "end !important"
                    }}>
                        <Col xs={12} className="final-button-row mt-4 mb-5" style={{
                        textAlign: "end !important"
                    }}>

                        <Button variant="outline-primary " className="final-button-accept" 
                        onClick={() => handleAccept()}>
                                        Accept
                            </Button>
                        <Button variant="outline-primary" className="final-button-reject"
                        onClick={() => handleClickOpenSaveDraft() }
                        // onClick={() => handleReject()}
                        >
                                        Reject
                            </Button>

                        
                        </Col>
                    </Row>
                 </>
                 :
                 null
                }

{
                 showbuttons && 
                 frompage === "Pending" ? 
                 <>
                    <Row className="button-inside-form-row mt-5 mb-5" style={{
                        borderTop: "2px solid #cccccc", textAlign: "end !important"
                    }}>
                        <Col xs={12} className="final-button-row mt-4 mb-5" style={{
                        textAlign: "end !important"
                    }}>

                        <Button variant="outline-primary " className="final-button-accept" 
                        onClick={() => handleAccept()}>
                                        Accept
                            </Button>
                        <Button variant="outline-primary" className="final-button-reject"
                        onClick={() => handleClickOpenSaveDraft() }
                        // onClick={() => handleReject()}
                        >
                                        Reject
                            </Button>

                        
                        </Col>
                    </Row>
                 </>
                 :
                 null
                }


            </Row>

                }


{/* ********************   Acknowledgement Letter HTML ************************ */}
            <div>
                {/* <div className="mb-5">
                    <button onClick={printDocument}>Print</button>
                </div> */}
                <div id="divToPrint" className="mt-4" style={{
                    backgroundColor: '#fff',
                    width: '210mm',
                    minHeight: '297mm',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    textAlign: 'center',
                    // justifyContent: 'center',
                    display: 'none'
                    // border: '2px solid black',
                }}>
                    <div className="logo-div-ack">
                            
                            <a href ="/dashboard-user">
                                <img id="logo" src={logo} alt="..." className="logo-img" />
                            </a>                        <h1 className="logo-text">
                            <Translate value="header.title" /> <br />
                            <Translate value="header.sub" />
                        </h1>
                        <p className="copyright">
                            Government of Karnataka
                        </p>
                    </div>

                    <Row className="ack-subtitle-row">
                        <Col xs={12} className="profile-title">
                            <h1>Acknowledgement</h1>
                        </Col>
                    </Row>

                    <p className="ack-success">
                        <img src={checkGreen} style={{height: "auto", width: "28px", marginRight: "10px"}} />
                        {/* <CheckCircle className={classes.backdropCheck} style={{height: "auto", width: "28px", marginRight: "10px"}}/> */}
                        Application Status : Successfully submitted to Labour Inspector</p>

                    <Row className="ack-table-row mt-5">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Application Number</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{appNo}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Application Date</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p><Moment format="DD MMMM YYYY">{date}</Moment></p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Requested Service</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>New Registration</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Applicant's Name</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{applicantName}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Residential Address</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{presentAddress}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Village Ward</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{villageWard}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Mobile Number</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{mobile}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Occupation (Category)</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row>

                    {/* <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Documents Submitted</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{occupation}</p>
                        </Col>
                    </Row> */}

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Circle Name</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{circleName}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row">
                        <Col xs={2}></Col>
                        <Col xs={3} className="ack-col">
                            <p>Labour Officer Incharge</p>
                        </Col>
                        
                        <Col xs={1}>
                            <p>:</p>
                        </Col>
                        
                        <Col xs={6} className="ack-col">
                            <p>{officerIncharge}</p>
                        </Col>
                    </Row>

                    <Row className="ack-table-row-last mt-5">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col-note">
                            <p>NOTE :</p>
                        </Col>
                    </Row>
                    <Row className="ack-table-row-last">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col">
                            <p>1. Submitted application is subject to verification.</p>
                        </Col>
                    </Row>
                    <Row className="ack-table-row-last">
                        <Col xs={2}></Col>
                        <Col xs={10} className="ack-col">
                            <p>2. Login to application to know the status.</p>
                        </Col>
                    </Row>




                </div>


            </div>
        
        </>
    )

}

export default UnorganizedDetails