import 'date-fns';
import { withStyles, Menu, IconButton } from '@material-ui/core';
import React, { useEffect,  useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';
import right from 'assets/images/Icon feather-arrow-right-circle (1).svg';
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import Edit from 'assets/images/Icon material-edit.svg';
import Tick from 'assets/images/check (3) 2.svg'
// import closeIcon from 'assets/images/close-01.svg'
import closeIcon from 'assets/images/closeIcon.svg'
import home from 'assets/images/Icon material-home.svg' 
import { useDispatch, useSelector } from 'react-redux';
import { getDeptUsers, getLabourStatusDetails, SERVER, setLocaleWithFallback } from 'store/actions/user.actions';
import { Link } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';
import Paper from '@mui/material/Paper';
import { getTokenCookie } from 'utils/tools';
import axios from 'axios';



const RoleMaster = (props) => { 
    const dispatch = useDispatch();
    const users = useSelector(state => state.users); 
    const [allValues, setAllValues] = useState({
        language: users.profile_details.language,
        users: users.getDeptUsers,
    });
    const [rows, setRows] = useState([]);
    const [page, setPage] = React.useState(1);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [totalPages, setTotalPages] = React.useState(0);

    useEffect(() => {
        var config = {
            method: 'get',
            url: SERVER + '/config/role/get_roles',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${getTokenCookie()}`
            },
            data : ''
          };
          
          axios(config)
          .then(function (response) {
            console.log("get_roles response:",response.data)
            setRows(response.data.data);
          })
          .catch(function (error) {
            console.log(error);
          });

    }, []);

 
    // useEffect(()=>{ 
    //     if(allValues.users && allValues.users.length>0){
    //         if(allValues.users.length<10){
    //             setTotalPages(1);
    //             let data= JSON.parse(JSON.stringify(allValues.users));
    //             data = data.slice(0,allValues.users.length);
    //             setRows(data);
    //         }else{
    //             const total = allValues.users.length/rowsPerPage;
    //             setTotalPages(Math.ceil(total));
    //             //set initial page data
    //             let data= JSON.parse(JSON.stringify(allValues.users));
    //             data = data.slice(0,rowsPerPage);
    //             setRows(data);
    //         } 
    //     } 
    // },[allValues])

    const handleNext=()=>{
        console.log('next');
        if(page+1<=totalPages){
            let data= JSON.parse(JSON.stringify(allValues.users));
            data = data.slice(page*rowsPerPage, page*rowsPerPage+rowsPerPage);
            setRows(data);
            setPage(page+1);
        }
    }

    const handleBack=()=>{
        console.log('back');
        if(page-1>=1){
            let data= JSON.parse(JSON.stringify(allValues.users));
            data = data.slice((page-1)*rowsPerPage-rowsPerPage, (page-1)*rowsPerPage);
            setRows(data);
            setPage(page-1);
        }
    }

    const handleChangePage = (event, newPage) => {
    setPage(newPage);
    };

    return (
        <>
            <div className="root">
                {/* <CssBaseline /> */}
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a><h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board(KBOCWWB)
                            </h1>
                            </div>

                        </Col> 
                        <Col xs={12} md={4} lg={3} style={{ display: "flex" }}>
                            <Link to="/admin/role-master-form" className="add-div-link">
                                <div className="add-div">
                                <img alt="..." className="cal-icon" src={add}/>
                                <p>Add Role</p>
                                </div>
                            </Link>
                        </Col>
                    </Row>

                </Row>
                </div>

                <Row className="config-page-row-title">
                    <Col xs={12} className="title-col">
                        <Link to="/admin/home">
                            <img alt="admin" className="cal-icon hover-icn" src={back}/>
                        </Link>
                            <p className="title-p">Roles</p>
                    </Col>
                </Row>

               {/* <div className="config-page-row-title">        */}
               <Paper sx={{ width: '100%', mb: 5 }}>     
               <TableContainer component={Paper}>
                    <Table  aria-label="simple table">
                        <TableHead>
                        <TableRow>
                            <TableCell className="custom-font" align="center">
                            <span className="mr-2">  
                            <img alt="..." className="cal-icon hover-icn" src={home}/> 
                            </span>
                            </TableCell>
                            <TableCell className="custom-font" >Role Name</TableCell>
                            <TableCell className="custom-font" >Short Name</TableCell>
                            <TableCell className="custom-font" >Code</TableCell>
                            <TableCell className="custom-font" >Status</TableCell>
                            <TableCell className="custom-font" >Options</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <TableRow
                            key={row.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                            <TableCell />
                            <TableCell >{row.name}</TableCell>
                            <TableCell >{row.short_name}</TableCell>
                            <TableCell > {row.code}</TableCell>
                            <TableCell  className="active-cell">
                                    {
                                       row.isactive ?  
                                       <div className="active">
                                       <img alt="status" src={Tick} className="mr-2"/>Active
                                       </div>
                                       :
                                       <div className="inactive">
                                       <img alt="status" src={closeIcon} className="mr-2"/>Inactive
                                       </div>

                                    }
                            </TableCell>
                            <TableCell >
                                <Link to ={{ 
                                        pathname: "/admin/role-master-mapping", 
                                        rowdata: row,
                                        }}
                                    className="td-link mr-2"
                                >
                                    Map
                                </Link>
                                |
                                <Link to ={{ 
                                        pathname: "/admin/role-master-form", 
                                        rowdata: row,
                                        }}
                                    className="td-link ml-2"
                                >
                                    Edit <img alt="..." src={Edit} className="ml-2"/>
                                </Link>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody> 
                    </Table>
            </TableContainer> 
            </Paper>
          
            {/* <div className="footer-buttons">
                <Link onClick={handleBack} style={{marginTop: '5px'}}>
                    <img alt="admin" className="cal-icon hover-icn" src={back}/>
                </Link>
                <Link onClick={handleNext} className="mr-2">
                    <img alt="admin" className="cal-icon hover-icn" src={right}/>
                </Link>
                    Page {page} of {totalPages}  
            </div> */}
          
        </>
    )
}

export default RoleMaster;