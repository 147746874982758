import {
  FormControl,
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { Translate } from 'react-redux-i18n';
import { Required } from 'utils/tools';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import Select2 from 'react-select';
import mobile from 'assets/images/Phone 1-01.svg';

const IssuerDetails = ({
  issuerDetails,
  fetchTypeOfIssuer,
  handleIssuerChange,
  handleDropdownSelect,
  selectedOption,
  handleIssuerDate,
  union,
}) => {
  return (
    <>
      <Row className="nintydays-cert-title-row">
        <Col xs={12} className="profile-title">
          <h1>
            <Translate value="daysWorkCertificateDetails" />
          </h1>
        </Col>
      </Row>

      <Row className="form-row ninydays-row">
        <Col xs={12} className="nintydays-col-5">
          <Row className="form-inner-card-plain">
            <Col xs={12} md={6}>
              <Required
                className="mt-2 mb-2"
                title={<Translate value="typeofIssuer" />}
              />
              <FormControl variant="outlined" fullWidth>
                <Select
                  className="select-marital"
                  value={issuerDetails.issuerTypeID}
                  name="issuerTypeID"
                  displayEmpty
                  onChange={handleIssuerChange}
                >
                  <MenuItem value="0">
                    <ListItemText primary="Select Type of Issuer" />
                  </MenuItem>
                  {fetchTypeOfIssuer &&
                    fetchTypeOfIssuer.length &&
                    fetchTypeOfIssuer.map((i) => (
                      <MenuItem value={i.value_id}>
                        <ListItemText primary={i.value} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Col>
            <Col xs={12} md={6}>
              <Required
                className="mt-2 mb-2"
                title={<Translate value="issuedDate" />}
              />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <KeyboardDatePicker
                  minDate={moment().subtract(90, 'days')}
                  maxDate={new Date()}
                  className="datepicker"
                  margin="normal"
                  format="D/MM/yyyy"
                  value={issuerDetails.issueDate}
                  onChange={handleIssuerDate}
                  placeholder="DD/MM/YYYY"
                />
              </MuiPickersUtilsProvider>
            </Col>
            {issuerDetails.issuerTypeID === 55 && (
              <Col xs={12} md={6} className="issuerTypeForm">
                <p className="mt-5 mb-2">
                  <Translate value="labourInspectorName" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="inspectorName"
                    // onChange={handleChange}
                    value={issuerDetails.inspectorName}
                    disabled
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="circleoftheLabourInspector" />{' '}
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="circleName"
                    // onChange={handleChange}
                    value={issuerDetails.circleName}
                    disabled
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="mobileNumberoftheLabourInspector" />{' '}
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="labourInspectormobileNum"
                    value={issuerDetails.labourInspectormobileNum}
                    disabled
                  />
                </FormControl>
              </Col>
            )}
            {issuerDetails.issuerTypeID === 56 && (
              <Col xs={12} md={6} className="issuerTypeForm">
                <p className="mt-5 mb-2">
                  <Translate value="unionName" />
                </p>

                <FormControl fullWidth>
                  <Select2
                    options={union}
                    className="select-marital"
                    onChange={handleDropdownSelect}
                    isSearchable={true}
                    value={selectedOption && selectedOption}
                    placeholder="Search Union"
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="unionRegistrationNumber" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="unionRegistrationNumber"
                    // onChange={handleChange}
                    value={issuerDetails.unionRegistrationNumber}
                    disabled
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="nameofIssuer" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="unionIssuerName"
                    onChange={handleIssuerChange}
                    value={issuerDetails.unionIssuerName}
                    // disabled
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="mobileNumberIfAvailable" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    name="unionMobileNumber"
                    onChange={handleIssuerChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img
                            // src={mobile}
                            alt="..."
                            className="phone-icon"
                          />
                          <p className="countrycode">+91</p>
                        </InputAdornment>
                      ),
                    }}
                    value={issuerDetails.unionMobileNumber}
                  />
                </FormControl>
              </Col>
            )}
            {issuerDetails.issuerTypeID === 57 && (
              <Col xs={12} md={6} className="issuerTypeForm">
                <p className="mt-4 mb-2">
                  <Translate value="nameofIssuer" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter Issuer Name"
                    name="gramIssuerName"
                    onChange={handleIssuerChange}
                    value={issuerDetails.gramIssuerName}
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="placeofIssuer" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter Place of Issuer"
                    name="gramIssuerPlace"
                    onChange={handleIssuerChange}
                    value={issuerDetails.gramIssuerPlace}
                  />
                </FormControl>

                <p className="mt-4 mb-2">
                  <Translate value="mobileNumberIfAvailable" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={mobile} alt="..." className="phone-icon" />
                          <p className="countrycode">+91</p>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter Mobile Number of Issuer"
                    name="gramIssuerMobileNumber"
                    onChange={handleIssuerChange}
                    value={issuerDetails.gramIssuerMobileNumber}
                  />
                </FormControl>
              </Col>
            )}
            {issuerDetails.issuerTypeID === 58 && (
              <Col xs={12} md={6} className="issuerTypeForm">
                <p className="mt-4 mb-2">
                  <Translate value="nameofIssuer" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter Name of Issuer"
                    name="constructionIssuerName"
                    // type = "text"
                    onChange={handleIssuerChange}
                    value={issuerDetails.constructionIssuerName}
                  />
                </FormControl>
                <Required
                  className="mt-4 mb-2"
                  title={<Translate value="designationofIssuer" />}
                />{' '}
                <FormControl fullWidth>
                  <TextField
                    placeholder="Enter Designation of Issuer"
                    type="text"
                    name="constructionDesignation"
                    onChange={handleIssuerChange}
                    value={issuerDetails.constructionDesignation}
                  />
                </FormControl>
                <p className="mt-4 mb-2">
                  <Translate value="mobileNumberIfAvailable" />
                </p>
                <FormControl fullWidth>
                  <TextField
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <img src={mobile} alt="..." className="phone-icon" />
                          <p className="countrycode">+91</p>
                        </InputAdornment>
                      ),
                    }}
                    placeholder="Enter Mobile Number of Issuer"
                    name="constructionMobileNumber"
                    onChange={handleIssuerChange}
                    value={issuerDetails.constructionMobileNumber}
                  />
                </FormControl>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default IssuerDetails;
