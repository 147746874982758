import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';

export const ApproveSchemeAPI = async (
  from,
  labourUserID,
  deptUserID,
  schemeTransactionID,
  order,
  remarks,
  verificationDoc,
  missingDoc,
  amount,
  rejectReasons,
  renewalSequence,
  finalLevelMissingDoc,
) => {
  const payLoad = {
    labourUserId: +labourUserID,
    availedTransactionId: +schemeTransactionID,
    statusId:
      from === 'Forward'
        ? 1147
        : from === 'Approve'
          ? 47
          : from === 'Reject'
            ? 46
            : null,
    remarks: remarks ? remarks : null,
    boardId: 1,
    departmentUserId: +deptUserID,
    verificationDocuments: verificationDoc,
    labourCard: [],
    sanctionedAmount: +amount,
    renewalSequenceYear:
      renewalSequence === '0' ? 1 : renewalSequence === '1' ? 2 : 0,
    order: order ? order : null,
    missingDocuments: missingDoc,
    rejectionReason:
      from === 'Approve' || from === 'Forward' ? [] : rejectReasons,
    finalLevelMissingDocuments: from === 'Approve' ? finalLevelMissingDoc : [],
  };

  try {
    const { data } = await axios.post(
      `${SERVER}/schemes/v2/approve-reject`,
      payLoad,
      {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      },
    );
    return data;
  } catch (error) {
    throw error;
  }
};
