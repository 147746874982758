import React, { useState, useEffect, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import axios from 'axios';
import { SERVER, getDeptUserRole } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import {
  Container,
  InputLabel,
  Box,
  Select,
  MenuItem,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import ReportDashboard from './ReportDashboard';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Col, Row } from 'react-bootstrap';
import {
  ListItemText,
  withStyles,
  Menu,
  CircularProgress,
  TextField,
  InputAdornment,
  Backdrop,
  makeStyles,
  FormControl,
  Typography,
  Grid,
} from '@material-ui/core';
import CountReport from './CountReports';
import { CSVLink } from 'react-csv';
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment'; // Add this import
import moment from 'moment';
import { useRef } from 'react';
import back from 'assets/images/Icon awesome-arrow-circle-left.svg';

export default function CountRenewalReport() {
  const [fetchRegistrationDistrictReport, setFetchRegistrationDistrictReport] =
    useState({});
  const [filter, setFilter] = useState('');
  const users = useSelector((state) => state.users);
  const [fetchRegistrationTalukReport, setFetchRegistrationTalukReport] =
    useState({});
  const [fetchRegistrationCircleReport, setFetchRegistrationCircleReport] =
    useState({});
  const [showDistrictTable, setShowDistrictTable] = useState(true);
  const [showTalukTable, setShowTalukTable] = useState(false);
  const [showCircleTable, setShowCircleTable] = useState(false);
  const [showDashboard, setShowDashboard] = useState(false);
  const [reportSelect, setReportSelect] = useState('');
  const [showRegistrationReports, setRegistrationReports] = useState('');
  const [showRenewalReports, setRenewalReports] = useState('');
  const [showSchemeReports, setSchemeReports] = useState('');
  const [showTypeDropDown, setShowTypeDropDown] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingTalukTable, setLoadingTalukTable] = useState(false);
  const [loadingCircleTable, setLoadingCircleTable] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isDateSelected, setIsDateSelected] = useState(0);
  //const [showFilterDates, setShowFilterDates] = useState(true);
  const [showTypeBackButton, setShowTypeBackButton] = useState(true);

  const handleReportChange = (e) => {
    setReportSelect(e.target.value);
  };

  //CSV Headers for DISTRICT table
  const csvHeaders = [
    { label: 'District', key: 'district' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Headers for TALUK table
  const csvHeaders_taluk = [
    { label: 'Taluk', key: 'taluk' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Headers for CIRCLE table
  const csvHeaders_circle = [
    { label: 'Circle', key: 'circle' },
    { label: 'Total', key: 'total' },
    { label: 'Approved', key: 'approved' },
    { label: 'Rejected', key: 'rejected' },
    { label: 'Pending', key: 'pending' },
  ];

  //CSV Data for District
  const prepareCSVData = (data) => {
    const csvData = [];
    ((data && data.data) || []).forEach((districtData) => {
      csvData.push({
        district: districtData.district || '',
        total: districtData.total || 0,
        approved: districtData.Approved || 0,
        rejected: districtData.Rejected || 0,
        pending: districtData.Pending || 0,
      });
    });
    csvData.push({
      district: 'Grand Totals',
      total: districtTotals.total || 0,
      approved: districtTotals.approved || 0,
      rejected: districtTotals.rejected || 0,
      pending: districtTotals.pending || 0,
    });
    return csvData;
  };

  //CSV Data for Taluk
  const prepareTalukCSVData = (data) => {
    const csvData = [];
    ((data && data.data) || []).forEach((talukData) => {
      csvData.push({
        taluk: talukData.taluk || '',
        total: talukData.total || 0,
        approved: talukData.Approved || 0,
        rejected: talukData.Rejected || 0,
        pending: talukData.Pending || 0,
      });
    });
    csvData.push({
      taluk: 'Grand Totals',
      total: talukTotals.total || 0,
      approved: talukTotals.approved || 0,
      rejected: talukTotals.rejected || 0,
      pending: talukTotals.pending || 0,
    });
    return csvData;
  };

  //CSV Data for Circle
  const prepareCircleCSVData = (data) => {
    const csvData = [];
    ((data && data.data) || []).forEach((circleData) => {
      csvData.push({
        circle: circleData.circle_name || '',
        total: circleData.total || 0,
        approved: circleData.Approved || 0,
        rejected: circleData.Rejected || 0,
        pending: circleData.Pending || 0,
      });
    });
    csvData.push({
      circle: 'Grand Totals',
      total: circleTotals.total || 0,
      approved: circleTotals.approved || 0,
      rejected: circleTotals.rejected || 0,
      pending: circleTotals.pending || 0,
    });
    return csvData;
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      fetchData();
      isFirstRun.current = false;
    } else {
      if (startDate && endDate) {
        console.log('set date selected');
        setIsDateSelected(1);
      }
    }
  }, [startDate, endDate]);

  //CircleAPI
  const handleCircleClick = async (districtId, talukId) => {
    try {
      setLoadingCircleTable(true);
      const circlePayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'CIRCLE',
        districtId: districtId,
        talukId: talukId,
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/renewal`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: circlePayload,
      };

      const circleResponse = await axios(config);
      setFetchRegistrationCircleReport(circleResponse.data);
      setShowTalukTable(false);
      setShowCircleTable(true);
    } catch (error) {
      console.log('Error fetching circle data:', error);
    } finally {
      setLoadingCircleTable(false);
    }
  };

  //TalukAPI
  const handleTalukClick = async (districtID) => {
    try {
      setLoadingTalukTable(true);

      const talukPayload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'TALUK',
        districtId: districtID,
        talukId: '',
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const talukConfig = {
        method: 'POST',
        url: `${SERVER}/reports/count/renewal`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: talukPayload,
      };

      const talukResponse = await axios(talukConfig);

      setLoadingTalukTable(false);
      setFetchRegistrationTalukReport(talukResponse.data);
      setShowDistrictTable(false);
      setShowTalukTable(true);
    } catch (error) {
      console.log('Error fetching taluk data:', error);
      setLoadingTalukTable(false);
    }
  };

  //Back to DropDown
  const handleBack = () => {
    //setFilter('');
    setShowDashboard(true);
    setShowTypeBackButton(false);
    setShowDistrictTable(false);
    setShowTalukTable(false);
    setShowCircleTable(false);
  };

  const fetchData = async () => {
    setLoading(true);
    try {
      const payload = {
        boardId: users.getDeptUserRole.data[0].board_id,
        departmentUserId: users.getDeptUserRole.data[0].department_user_id,
        roleId: users.getDeptUserRole.data[0].role_id,
        reportType: 'DISTRICT',
        districtId: '',
        talukId: '',
        isDateSelected: isDateSelected,
        startDate: startDate,
        endDate: endDate,
      };

      const config = {
        method: 'post',
        url: `${SERVER}/reports/count/renewal`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payload,
      };

      const response = await axios(config);
      setFetchRegistrationDistrictReport(response.data);

      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  const calculateTotals = (data) => {
    let total = 0;
    let approved = 0;
    let rejected = 0;
    let pending = 0;

    ((data && data.data) || []).forEach((district) => {
      total += district.total || 0;
      approved += district.Approved || 0;
      rejected += district.Rejected || 0;
      pending += district.Pending || 0;
    });

    return { total, approved, rejected, pending };
  };

  const districtTotals = calculateTotals(fetchRegistrationDistrictReport);
  const talukTotals = calculateTotals(fetchRegistrationTalukReport);
  const circleTotals = calculateTotals(fetchRegistrationCircleReport);

  const handleReportsType = (selectedValue) => {
    console.log('Selected Reports Type:', selectedValue);
    if (selectedValue === '100') {
      setRegistrationReports(true);
      setRenewalReports(false);
      setSchemeReports(false);
      console.log(1);
    } else if (selectedValue === '200') {
      setRegistrationReports(false);
      setRenewalReports(true);
      setSchemeReports(false);
      console.log(2);
    } else if (selectedValue === '300') {
      setRegistrationReports(false);
      setRenewalReports(false);
      setSchemeReports(true);
      console.log(3);
    }
  };

  const handleDate = (ev) => {
    console.log(moment(ev).format(), 'DATE');
  };
  console.log(moment(startDate).format(), 'startDate');

  return (
    <>
      {!showDashboard && !showTypeDropDown && (
        <div
          style={{
            padding: '10px 15px',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          <button
            style={{
              background: 'transparent',
              outline: 'none',
              border: 'none',
            }}
            onClick={handleBack}
          >
            <img alt="..." className="cal-icon hover-icn" src={back} />
          </button>
          {showDistrictTable && (
            <>
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Col
                  xs={2}
                  className="benificiaries-col "
                  justifyContent="center"
                >
                  <div
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    className="benificiaries-col-div hvr-icon-hang"
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        className="datepicker"
                        margin="normal"
                        format="DD/MM/YYYY"
                        value={startDate}
                        placeholder="Start Date"
                        onChange={(ev) => setStartDate(ev)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Col>
                <Col
                  xs={2}
                  className="benificiaries-col "
                  justifyContent="center"
                >
                  <div
                    aria-controls="customized-menu"
                    aria-haspopup="true"
                    className="benificiaries-col-div hvr-icon-hang"
                  >
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <KeyboardDatePicker
                        className="datepicker"
                        margin="normal"
                        format="DD/MM/YYYY"
                        value={endDate}
                        placeholder="End Date"
                        onChange={(ev) => setEndDate(ev)}
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                </Col>
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  height: '10vh',
                }}
              >
                <Button variant="contained" color="success" onClick={fetchData}>
                  Confirm
                </Button>
              </div>
            </>
          )}
        </div>
      )}

      <Container maxWidth="lg">
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 2,
          }}
        ></Box>

        {showTypeBackButton && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTypeDropDown(true);
            }}
          >
            Back to Select Reports Type
          </Button>
        )}
        {showDistrictTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    District
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Total
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Approved
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Rejected
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Pending
                  </TableCell>
                </TableHead>

                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red', fontWeight: 'bold' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (fetchRegistrationDistrictReport.data || []).length ===
                    0 ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'blue', fontWeight: 'bold' }}
                      >
                        No Data Found
                      </TableCell>
                    </TableRow>
                  ) : (
                    <>
                      {(fetchRegistrationDistrictReport.data || []).map(
                        (districtData) => (
                          <TableRow key={districtData.district_id}>
                            <TableCell
                              align="center"
                              onClick={() =>
                                handleTalukClick(districtData.district_id)
                              }
                              style={{ cursor: 'pointer' }}
                            >
                              {districtData.district}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.total || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Approved || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Rejected || 0}
                            </TableCell>
                            <TableCell align="center">
                              {districtData.Pending || 0}
                            </TableCell>
                          </TableRow>
                        ),
                      )}
                      <TableRow>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          Grand Total
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.total}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.approved}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.rejected}
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ fontWeight: 'bold' }}
                        >
                          {districtTotals.pending}
                        </TableCell>
                      </TableRow>
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button with CSVLink */}
              <CSVLink
                data={prepareCSVData(fetchRegistrationDistrictReport)}
                headers={csvHeaders}
                filename={'renewal_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Taluk Table */}
        {showTalukTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Taluk
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Total
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Approved
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Rejected
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Pending
                  </TableCell>
                </TableHead>

                <TableBody>
                  {loadingTalukTable ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    (fetchRegistrationTalukReport.data || []).map((taluk) => {
                      return (
                        <TableRow key={taluk.taluk_id}>
                          <TableCell
                            align="center"
                            onClick={() =>
                              handleCircleClick(
                                taluk.district_id,
                                taluk.taluk_id,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {taluk.taluk}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Rejected || 0}
                          </TableCell>
                          <TableCell align="center">
                            {taluk.Pending || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {talukTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button with CSVLink */}
              <CSVLink
                data={prepareTalukCSVData(fetchRegistrationTalukReport)}
                headers={csvHeaders_taluk}
                filename={'taluk_renewal_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {/* Circle Table */}
        {showCircleTable && !showTypeDropDown && (
          <div>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }}>
                <TableHead>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Circle
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Total
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Approved
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Rejected
                  </TableCell>
                  <TableCell align="center" style={{ fontWeight: 'bold' }}>
                    Pending
                  </TableCell>
                </TableHead>

                <TableBody>
                  {loadingCircleTable ? (
                    <TableRow>
                      <TableCell
                        colSpan={6}
                        align="center"
                        style={{ color: 'red' }}
                      >
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    (fetchRegistrationCircleReport.data || []).map((circle) => {
                      return (
                        <TableRow
                          key={circle.circle_id}
                          style={{ cursor: 'pointer' }}
                        >
                          <TableCell align="center">
                            {circle.circle_name}
                          </TableCell>
                          <TableCell align="center">
                            {circle.total || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Approved || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Received || 0}
                          </TableCell>
                          <TableCell align="center">
                            {circle.Pending || 0}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                  <TableRow>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      Grand Total
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.total}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.approved}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.rejected}
                    </TableCell>
                    <TableCell align="center" style={{ fontWeight: 'bold' }}>
                      {circleTotals.pending}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            <div style={{ marginTop: '10px', textAlign: 'center' }}>
              {/* Download button with CSVLink */}
              <CSVLink
                data={prepareCircleCSVData(fetchRegistrationCircleReport)}
                headers={csvHeaders_circle}
                filename={'circle_renewal_data.csv'}
                className="btn btn-primary"
                target="_blank"
              >
                Download
              </CSVLink>
            </div>
          </div>
        )}

        {showTalukTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowTalukTable(false);
              setShowDistrictTable(true);
            }}
          >
            Back to District
          </Button>
        )}

        {showCircleTable && !showTypeDropDown && (
          <Button
            onClick={() => {
              setShowCircleTable(false);
              setShowTalukTable(true);
            }}
          >
            Back to Taluk
          </Button>
        )}
      </Container>
      {showDashboard ? <ReportDashboard /> : null}
      {showTypeDropDown ? <CountReport /> : null}
    </>
  );
}
