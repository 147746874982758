import {
  FormControl,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
  ListItem,
  ListItemText,
  Divider,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { GetApp } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';
import graphic from 'assets/images/Admin SVG.svg';
import Helpline from 'assets/images/customer-support.svg';
import doc1 from 'assets/documents/Employement certificate by the Employeer.pdf';
import doc2 from 'assets/documents/Employement certificate by the grama panchayath.pdf';
import doc3 from 'assets/documents/Employement certificate by the labour inspector.pdf';
import doc4 from 'assets/documents/Employement certificate by the Registration trade union.pdf';
import validator from 'validator';
// import mobile from 'assets/images/Icon awesome-mobile-alt.svg'
import mobile from 'assets/images/Phone 1-01.svg';
// import otp from 'assets/images/business-and-finance.svg'
import otp from 'assets/images/Phone otp-01.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  generateOtp,
  getDeptUserRole,
  loginAdmin,
  loginUser,
} from 'store/actions/user.actions';
import {
  removeTokenCookie,
  removeTokenCookieAdmin,
  saveTokenCookie,
  saveTokenCookieAdmin,
  showToast,
} from 'utils/tools';
import CloseIcon from '@material-ui/icons/Close';
import closeIcon from 'assets/images/close-01.svg';
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from 'react-simple-captcha';

const Login = (props) => {
  const [allValues, setAllValues] = React.useState({
    open: false,
    phone: '',
    otp: '',
    users: props.users,
    generate_otp: props.generate_otp,
    login_user: props.login_user,
    otp_sent: false,
    otp_sending: false,
    logging_in: false,
    phoneError: false,
    phoneErrorMessage: 'Error Sending OTP',
    otpError: false,
    openSnackbar: false,
    snackbarText: '',
    snackbarType: '',
    vertical: 'top',
    horizontal: 'right',
    seconds: 10,
    resendReset: false,

    useridError: false,
    useridErrorMessage: 'Please enter correct User ID',
    passwordError: false,
    passwordErrorMessage: 'Please enter correct Password',
    userid: '',
    password: '',
  });

  /**
   * 2021
   */
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [captachaButton, setcaptachaButton] = React.useState(false);

  // useEffect(() => {
  //     removeTokenCookieAdmin()
  //     dispatch(loginAdmin("delete"))

  //     console.log("in1")
  //     logOut()

  // }, []);

  const logOut = (event) => {
    props.history.goBack();
  };

  const changeHandler = (e) => {
    if (e.target.name === 'userid') {
      setAllValues({
        ...allValues,
        useridError: false,
        useridErrorMessage: '',
      });
    } else if (e.target.name === 'password') {
      setAllValues({
        ...allValues,
        passwordError: false,
        passwordErrorMessage: '',
      });
    }

    setAllValues((prevValues) => {
      return { ...prevValues, [e.target.name]: e.target.value };
    });
  };

  const handleDownloadListOpen = () => {
    setAllValues((prevState) => ({
      ...prevState,
      open: !prevState.open,
    }));
    // setAllValues({...allValues, [e.target.name]: e.target.value})
  };

  const login_func = (event) => {
    event.preventDefault();

    // console.log("in login_func: hash: " + JSON.stringify(users.generate_otp.data.hash))
    // const hash = users.generate_otp.data.hash

    let user_captcha = document.getElementById('user_captcha_input').value;
    var testCaptcha = 'CAPTCHA';
    // if(user_captcha === testCaptcha || validateCaptcha(user_captcha) == true)
    if (validateCaptcha(user_captcha) == true) {
      showToast('SUCCESS', 'Captcha Matched');
      loadCaptchaEnginge(6);
      document.getElementById('user_captcha_input').value = '';
      setcaptachaButton(true);
      if (allValues.userid !== '' && allValues.password !== '') {
        setAllValues({
          ...allValues,
          logging_in: !allValues.logging_in,
        });

        let dataToLogin = {
          username: allValues.userid,
          password: allValues.password,
        };

        dispatch(loginAdmin(dataToLogin));
      } else if (allValues.userid === '') {
        setAllValues({
          ...allValues,
          useridError: true,
          useridErrorMessage: 'Please enter correct User ID',
        });
      } else if (allValues.password === '') {
        setAllValues({
          ...allValues,
          passwordError: true,
          passwordErrorMessage: 'Please enter correct Password',
        });
      } else {
        setAllValues({
          ...allValues,
          useridError: true,
          useridErrorMessage: 'Some Error Occured!',
          passwordError: true,
        });
      }
    } else {
      showToast('ERROR', 'Captcha Does Not Match');
      document.getElementById('user_captcha_input').value = '';
      setcaptachaButton(false);
    }
  };

  useEffect(() => {
    // dispatch(loginAdmin("delete"))
    // logOut()
    // if(users.login_admin == null) {
    //     props.history.push("/admin")
    // }
    dispatch(loginAdmin('delete'));
    removeTokenCookie();
    dispatch(getDeptUserRole('delete'));

    return loadCaptchaEnginge(6);
  }, []);

  // const captchaSubmit = () => {
  //     let user_captcha = document.getElementById('user_captcha_input').value;

  //     if (validateCaptcha(user_captcha)==true) {
  //         showToast("SUCCESS", "Captcha Matched")
  //         loadCaptchaEnginge(6);
  //         document.getElementById('user_captcha_input').value = "";
  //         setcaptachaButton(true)
  //     }

  //     else {
  //         showToast("ERROR", "Captcha Does Not Match")
  //         document.getElementById('user_captcha_input').value = "";
  //         setcaptachaButton(false)
  //     }
  // };

  useEffect(() => {
    if (captachaButton) {
      if (
        users.login_admin !== null &&
        users.login_admin.message !== null &&
        users.login_admin.success === true
      ) {
        setAllValues({
          ...allValues,
          logging_in: false,
        });
        saveTokenCookieAdmin(users.login_admin.jwt);
        showToast('SUCCESS', 'Login Successfull!');
        users.login_admin.message = null;

        let dataForRole = users.login_admin.data.department_user_id;

        dispatch(getDeptUserRole(dataForRole));

        // props.history.push("/admin/home");
        props.history.push('/admin/home');
      } else if (
        users.login_admin !== null &&
        users.login_admin.success !== null &&
        users.login_admin.success === false
      ) {
        setAllValues({
          ...allValues,
          logging_in: false,
          useridError: true,
          useridErrorMessage: users.login_admin.errors.msg,
        });
        showToast('ERROR', users.login_admin.errors.msg);

        users.login_admin.message = null;
      }
    }
  }, [users.login_admin]);

  return (
    <Row className="login-div">
      <Col xs={12} lg={6} xl={6} xxl={6}>
        <Row>
          <Col sm={12}>
            <div className="logo-div d-flex justify-content-start text-center">
              <img
                id="logo"
                src={logo}
                alt="..."
                className="logo-img-admin1 d-flex justify-content-start"
              />
              <div className="logo-head d-flex justify-content-start text-center">
                <h1
                  className="logo-text d-flex justify-content-start text-center"
                  style={{ color: '#CA0027', fontSize: '25px' }}
                >
                  ಕಾರ್ಮಿಕ ಇಲಾಖೆ, ಕರ್ನಾಟಕ ಸರ್ಕಾರ
                </h1>
                <h1
                  className="logo-text d-flex justify-content-start text-center"
                  style={{ color: '#CA0027', fontSize: '25px' }}
                >
                  Labour Department, Government of Karnataka
                  {/* Karnataka Building & Other Construction <br />Workers Welfare Board (KBOCWWB) */}
                </h1>
              </div>
            </div>
          </Col>
          <Col sm={12} className="login-img-col">
            <img className="login-img" alt="..." src={graphic} />
          </Col>
        </Row>
      </Col>

      <Col xs={12} lg={6} className="login-form admin">
        <div className="login-form-container">
          <h2 className="login-form-head admin">Login</h2>

          <form>
            <TextField
              fullWidth
              className="userid-textfield"
              label="User ID"
              type="text"
              name="userid"
              onChange={changeHandler}
              value={allValues.userid}
              error={allValues.useridError}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  const nextSibling = document.getElementById('passwordfield');
                  if (nextSibling !== null) {
                    nextSibling.focus();
                  }
                }
              }}
              helperText={
                allValues.useridError ? allValues.useridErrorMessage : ''
              }
            />

            <TextField
              fullWidth
              id="passwordfield"
              className="userid-textfield"
              label="Password"
              type="password"
              name="password"
              onChange={changeHandler}
              value={allValues.password}
              error={allValues.passwordError}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  const nextSibling =
                    document.getElementById('user_captcha_input');
                  if (nextSibling !== null) {
                    nextSibling.focus();
                  }
                }
              }}
              helperText={
                allValues.passwordError ? allValues.passwordErrorMessage : ''
              }
            />

            <div>
              <div className="col mt-4">
                <LoadCanvasTemplate />
              </div>

              <div className="col mt-3">
                <TextField
                  fullWidth
                  id="user_captcha_input"
                  // className="userid-textfield"
                  label="Enter Captcha Value"
                  type="text"
                  // name="password"
                  // onChange={changeHandler}
                  // value={allValues.password}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      login_func(event);
                    }
                  }}
                />
              </div>
              {/* <div className="col mt-3">
                                <div>
                                    <input placeholder="Enter Captcha Value" 
                                        id="user_captcha_input" type="text"/>
                                </div>
                            </div> */}

              {/* <div className="col mt-3"> */}
              {/* <div><button class="btn btn-primary"  onClick={() => captchaSubmit()}>Submit</button></div> */}
              {/* </div> */}
            </div>

            <div className="admin-login-div">
              {allValues.logging_in ? (
                <CircularProgress className="loader-otp" />
              ) : (
                <Link
                  to="#"
                  // to="/admin/home"
                  style={{ flexGrow: '0.5' }}
                  onClick={(event) => login_func(event)}
                >
                  <p className="registration-button1 btn-ripple" type="submit">
                    Login
                  </p>
                </Link>
              )}
              {/* <Link to="#"
                            style={{ flexGrow: "1" }}
                            >
                            <p className="registration-button2 btn-ripple">
                                Forgot Password
                            </p>
                        </Link> */}
            </div>
          </form>

          {/* <div className="helpline">
                    <img src={Helpline} alt="..." className="helpline-img" />Helpline Support
                    </div> */}
        </div>
      </Col>
    </Row>
  );
};

export default Login;
