import 'date-fns';
import { withStyles, Menu, MenuItem,ListItemText, TableContainer, Table, TableBody, TableRow, TableCell, TableHead, FormControl, TextField, TextareaAutosize, InputAdornment, Backdrop, CircularProgress, makeStyles, FormControlLabel, Checkbox, Dialog, DialogContent, DialogActions} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Col, Row, Button, OverlayTrigger } from 'react-bootstrap';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png'
import home from 'assets/images/Icon material-home.svg'
import add from 'assets/images/Icon ionic-ios-add-circle.svg'
import back from 'assets/images/Icon awesome-arrow-circle-left.svg'
import Edit from 'assets/images/Icon material-edit.svg'
import mobile from 'assets/images/Phone 1-01.svg'
import folder from 'assets/images/Folderwhite-01-01.svg'
import tick from 'assets/images/check (3) 2.svg'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CameraAlt, PhoneAndroid } from '@material-ui/icons'; 
import { showToast, getTokenCookie, Required, dataURLtoFile } from 'utils/tools';
import Webcam from "react-webcam";
import webcam from 'assets/images/web cam-01.svg'
import { getFile, SERVER } from 'store/actions/user.actions'
import axios from 'axios';
import validator from 'validator';
import { Translate } from 'react-redux-i18n';
import { DialogTitle } from '@mui/material';
import Button2 from '@mui/material/Button';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';

const UnionMasterForm = (props) => {

     const {location} = props;
    console.log("Location:",location);

    const users = useSelector(state => state.users);
    const [unionName,setUnionName] = useState("")
    const [regNo,setRegNo] = useState("")
    const [mobileNo,setMobileNo] = useState("")
    const [spokePerson,setSpokePerson] = useState("")
    const [addedBy,setAddedBy] = useState("")
    const [address,setAddress] = useState("")
    const [isEdit,setIsEdit] = useState(false)

    const [labourUnionId,setLabourUnionId] = useState("")
    const [isActive,setIsActive] = useState(null)


   

        useEffect(() => {
        console.log("location: ",location)
        if(location.rowdata !== undefined && location.rowdata !== null ){
            console.log("location.rowdata: ",location.rowdata)
            var value = location.rowdata
            setIsEdit(true)
            console.log("ROWDATA:",value);
            setUnionName(value.name)
            setRegNo(value.registeration_no)
            setMobileNo(value.phone_no)
            setSpokePerson(value.spoke_person)
            setAddress(value.address)
            // setAddedBy(value.)
            setLabourUnionId(value.labour_union_id)
            setIsActive({isClicked: false,value:value.isactive})
              
        }
    }, []);

    const handleChange = (ev) => {
        if(ev.target.name === "union_name"){
            setUnionName(ev.target.value)
        }
        else if(ev.target.name === "reg_no"){
            setRegNo(ev.target.value)
        }
        else if(ev.target.name === "mobile_number"){
            setMobileNo(ev.target.value.slice(0,10))
        }
        else if(ev.target.name === "spoke_person"){
            setSpokePerson(ev.target.value)
        }
        
        else if(ev.target.name === "address"){
            console.log("Address::",ev.target.value);
            setAddress(ev.target.value)
        }
    }


    const addUnion =  async () => {

        if(isEdit){
            const payLoad = {
        "name": unionName,
        "registeration_no": regNo,
        "address": address,
        "spoke_person": spokePerson,
        "phone_country_code": "+91",
        "phone_no": mobileNo,
        "added_by": users.getDeptUserRole.data[0].department_user_id
        }
        const config = {
            method:'patch',
            url:`${SERVER}/config/union/update_union/${location.rowdata.labour_union_id}`,
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : payLoad
        }
      await axios(config).then((res)=>{
            console.log(res);
            showToast("SUCCESS","Union Updated Successfully")
            props.history.push("/admin/union-master")
        }).catch((err)=>{
            console.log(err);
        })
            

        }
        else{
        const payLoad = {
        "name": unionName,
        "registeration_no": regNo,
        "address": address,
        "spoke_person": spokePerson,
        "phone_country_code": "+91",
        "phone_no": mobileNo,
        "added_by": users.getDeptUserRole.data[0].department_user_id
        }
        const config = {
            method:'post',
            url:`${SERVER}/config/union/insert_union`,
            headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
                data : payLoad
        }
      await axios(config).then((res)=>{
            console.log(res);
            showToast("SUCCESS","Union Added Successfully")
            props.history.push("/admin/union-master")
        }).catch((err)=>{
            console.log(err);
        })
        
    }

        
    }

    

    const handleActivate = () => {
        setIsActive({isClicked:true,value:!isActive.value})
        
    }

    useEffect(()=>{
        if(isActive && isActive.isClicked ){
            activateUnion()
        }
        
    },[isActive])

 const activateUnion = () => {
    const payLoad = {
            "id": labourUnionId,
            "is_active": isActive.value ? 1 : 0,
            "added_by": users && users.getDeptUserRole.data[0].department_user_id
        }
        const config = {
            method:'post',
            url:`${SERVER}/config/union/activate_union`,
            headers:{
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${getTokenCookie()}`
                },
            data:payLoad
        }
        axios(config).then((res)=>{
            console.log("ActiveStatus",res.data.data);
        }).catch((err)=>{
            console.log(err);
        })

 }  

  return (
    <div>
           <div className="root"> 
                <Row className="top-div">
                    <Row className="topbar-row">
                        <Col xs={12} md={8} lg={9} className="top-bar-col">
                            <div className="logo-div-profile">
                                <a href="/admin/home">
                                    <img id="logo" src={logo} alt="..." className="logo-img" />
                                </a><h1 className="logo-text">
                                    Government of Karnataka <br />
                                Karnataka Building & Other Construction Workers Welfare Board (KBOCWWB)
                            </h1>
                            </div>

                        </Col>
                 
                    </Row>

                </Row>
            </div>
            <Row className="config-page-row-title">
                <Col xs={12} className="title-col">
                    <Link to="#" onClick={() => props.history.push("/admin/union-master")}>
                        <img alt="..." className="cal-icon hover-icn" src={back} />
                        <p className="title">Union Master</p>
                        
                    </Link> 
                  {}<Button2 onClick={handleActivate} variant='contained' startIcon={isActive && isActive.value ? (<ToggleOnIcon color='success' style={{fontSize:'35px'}}/>) : (<ToggleOffIcon color='error' style={{fontSize:'35px'}}/>) } start style={{position:'absolute',right:'30px',background:'inherit',color:'inherit'}} className="cal-icon">{isActive && isActive.value ? "Active" : "Inactive"}</Button2>
                </Col>
            </Row>
            <Row className="form-row mt-4 config-form">    
                <Col xs={12} md={4} className="personal-col-1">
                       <Required className="mb-2 mt-3" title="Union Name" /> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Union Name"
                                name="union_name"
                                value={unionName} 
                                onChange={handleChange} 
                                // helperText = {allValues.firstnameError ? "Please enter alphabets only" : "" }
                                // error = {allValues.firstnameError}


                            />
                        </FormControl>
                </Col>
                <Col xs={12} md={4} className="personal-col-1">
                       <p className="mb-2 mt-3">Registration Number</p> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Registration Number"
                                name="reg_no"
                                value={regNo} 
                                onChange={handleChange} 
                                // helperText = {allValues.middle_nameError ? "Please enter alphabets only" : ""}
                                // error = {allValues.middle_nameError}

                            />
                        </FormControl>
                </Col>

                            <Col xs={12} md={4} className="personal-col-1">
                        {/* <Required className="mb-2 mt-3" title="Phone Number" /> */}
                        <Required className="mb-2 mt-3" title = "Mobile No" /> 
                        <FormControl fullWidth className="formcontrol2">
                                <TextField
                                    InputProps={{
                                        startAdornment:
                                            <InputAdornment position="start">
                                                <img src={mobile} alt="country code" className="phone-icon" />
                                                <p className="countrycode">+91</p>
                                            </InputAdornment>
                                    }}
                                    variant="outlined"
                                    name="mobile_number"
                                    value={mobileNo}
                                    type='number'
                                    onChange={handleChange}
                                    // helperText = {allValues.mobile_numberError ? "Please enter correct number" : ""}
                                    // error = {allValues.mobile_numberError}
                                />
                            </FormControl>
                </Col>

                <Col xs={12} md={4} className="personal-col-1">
                       <Required className="mb-2 mt-3" title = "Spoke Person" /> 
                        <FormControl fullWidth className="formcontrol1"
                            onKeyPress={event => {
                                if (event.key === "Enter") {
                                    // handleEnter();
                                }
                            }}>
                            <TextField
                                variant="outlined"
                                placeholder="Enter Spoke Person Name"
                                name="spoke_person"
                                value={spokePerson} 
                                onChange={handleChange} 
                                // helperText = {allValues.last_nameError ? "Please enter alphabets only" : ""}
                                // error = {allValues.last_nameError}

                            />
                        </FormControl>
                </Col>
                                 
                <Col xs={12} md={6} className="personal-col-1">
                        <Required className="mb-2 mt-3" title ="Address" /> 
                        <FormControl fullWidth className="formcontrol1"
                                onKeyPress={event => {
                                    if (event.key === "Enter") {
                                        // handleEnter();
                                    }
                                }}>
                                <TextareaAutosize
                                    variant="outlined"
                                    multiline
                                    rows={3} 
                                    placeholder="Enter Address"
                                    name="address"
                                    value={address}
                                    onChange={handleChange}
                                />
                            </FormControl> 
                </Col>



                


                
                <Col xs={12} className="mt-4" align="center">  
                    {/* { */}
                        {/* loading ? */}
                            
                            {/* <CircularProgress color="inherit" /> */}
                            {/* :  */}
                            <Button variant="outline-primary" className="save-button" 
                            onClick={addUnion}
                            // disabled={isActive && isActive.value ? false : true}
                            >
                            Add Union
                            </Button> 
                    {/* } */}
                    
                </Col> 

                
            </Row>
    </div>
  )
}

export default UnionMasterForm