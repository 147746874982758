import axios from "axios";
import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { Link, useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Required,
  dataURLtoFile,
  getTokenCookie,
  showToast,
  testJSON,
} from "utils/tools";
import {
  FormControl,
  TextField,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Tooltip,
  TextareaAutosize,
  Backdrop,
  CircularProgress,
  makeStyles,
  DialogContentText,
} from "@material-ui/core";
import { Visibility, CheckCircle } from "@material-ui/icons";
import back from "assets/images/Icon awesome-arrow-circle-left.svg";
import { Col, Row, Button } from "react-bootstrap";
import logo from "assets/images/Karnataka_logo_100_Pixels.png";
import usericon from "assets/images/Icon awesome-user.svg";
import logout from "assets/images/Logout.svg";
import { useDispatch, useSelector } from "react-redux";
import { SERVER } from "../../store/actions/user.actions";
import LabourDetails from "./LabourDetails";
import Button2 from "@mui/material/Button";
import ECard from "./PreviewOption/ECard";

import folder from "assets/images/Folderwhite-01-01.svg";
import closeicon from "assets/images/closeIcon.svg";
import { Translate } from "react-redux-i18n";
import PopOverImage from "./globals/popOverImage";
import AddIcon from "@mui/icons-material/AddCircleOutlined";
import RemoveCircleOutlinedIcon from "@mui/icons-material/RemoveCircleOutlined";
import Reject from "./globals/RegRenRejReasons";
import { rejectedReasonsAPI } from "services/RegistrationandRenewal.service";
import SimpleBackdrop from "components/loadingScreen/Backdrop";
import PopOver from "./LabourRegistrationDetails/PopOver";
import moment from "moment";
import RejectedReasonsDialog from "components/adminPortal/globals/RejectedReasonsDialog";
import rejected from "assets/images/close (2).svg";

const RegistrationProfileDetails = (props) => {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);

  const { labourUserID, boardID, status, page, editProfileID } = useParams();

  const useStyles = makeStyles((theme) => ({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: "#ffffffee",
      color: "#000",
      display: "block",
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    backdropCheck: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#6CC17A",
    },
    backdropCheckp: {
      fontSize: "30px",
      fontWeight: "bold",
    },
  }));

  const classes = useStyles();

  const [personalDetails, setPersonalDetails] = useState(null);
  const [addressDetails, setAddressDetails] = useState([]);
  const [familyDetails, setFamilyDetails] = useState([]);
  const [bankDetails, setBankDetails] = useState([]);
  const [employerDetails, setEmployerDetails] = useState([]);
  const [certificateDetails, setCertificateDetails] = useState([]);
  const [rationCardDetails, setRationCardDetails] = useState([]);

  const [loading, setLoading] = React.useState(false);
  const [disableBtn, setDisableBtn] = useState(false);
  const [submitSuccess, setSubmitSuccess] = React.useState(false);
  const [rejectSuccess, setRejectSuccess] = React.useState(false);
  const [draftSuccess, setSuccessDraft] = React.useState(false);
  const [getUsers, setGetUsers] = useState([]);

  const [openBackdrop, setOpenBackdrop] = React.useState(false);

  const [rejectRemarks, setRejectRemarks] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const [transactionPassword, setTransactionPassword] = useState("");
  const [isTransactionMatched, setIsTransactionMatched] = useState(false);
  const [approveReject, setApproveReject] = useState("");
  const [openPreview, setOpenPreview] = useState(false);

  const [selectedFiles, setSelectedFiles] = useState({});
  const [loadingFiles, setLoadingFiles] = useState(false);
  const [order, setOrder] = useState("");
  const [remarks, setRemarks] = useState("");
  const [selectMissingFiles, setSelectedMissingFiles] = useState({});
  const [missingFilesResults, setMissingFilesResult] = useState(null);

  const [currentImgClick, setCurrentImgClick] = React.useState("");
  const [anchorElVerification, setAnchorElVerification] = React.useState(null);
  const [anchorElMissing, setAnchorElMissing] = React.useState(null);
  const [selected_document_type, setselected_document_type] =
    React.useState("");
  const [verificationDoc, setVerificationDoc] = useState({});
  const [missingDocs, setMissingDocs] = useState({});
  const [certificateDocument, setCertificateDocument] = useState(null);
  const [draftReasons, setDraftReasons] = useState(null);
  const [finalRejectedReasons, setFinalRejectedReasons] = useState(null);
  const [isDraftDocument, setIsDraftDocument] = useState(true);
  const [isAddBtnActive, setIsAddBtnActive] = useState(false);

  const [verificationDocList, setVerificationList] = useState([]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [fileID, setFileID] = useState(null);
  const openVerification = Boolean(anchorElVerification);
  const idVerification = openVerification ? "simple-popover" : undefined;

  const openDocument = Boolean(anchorEl);
  const documentid = openDocument ? "simple-popover" : undefined;

  const openMissingDoc = Boolean(anchorElMissing);
  const idMissingDoc = openMissingDoc ? "simple-popover" : undefined;
  const [openRejection, setOpenRejection] = useState(false);
  const [toggleLang, setToggleLang] = useState(false);
  const [rejectReasons, setRejectReasons] = useState(null);
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [wordCount, setWordCount] = useState(0);
  const [langValue, setLangValue] = useState("eng");
  const [isLastRejectedReasonsVisible, setLastRejectedReasonVisibility] =
    useState(false);
  /* word limit */
  const wordLimit = 200;
  const hideWordsLimits = status === "Approved" || status === "Rejected";
  useEffect(() => {
    const payload = {
      key: "user_id",
      value: labourUserID,
      board_id: boardID,
      procedure_name: "all",
    };

    const config = {
      method: "post",
      url: `${SERVER}/user/get-user-registration-details`,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payload,
    };

    axios(config)
      .then((res) => {
        console.log(res.data);
        setGetUsers(res.data.data);
        setPersonalDetails(res.data.data.personal_details[0]);
        setAddressDetails(res.data.data.address_details[0]);
        setFamilyDetails(res.data.data.family_details.map((family) => family));
        setRationCardDetails(res.data.data.ration_card_details[0]);
        setBankDetails(res.data.data.bank_details.map((bank) => bank));
        setCertificateDetails(res.data.data.certificate_details[0]);
        setEmployerDetails(
          res.data.data.employer_details.map((employer) => employer)
        );
        setCertificateDocument(
          res.data.data.certificate_details.map((data) => data)
        );
        // setDraftReasons(res.data.data.draft_rejection_reasons);
        const initialSelectedOptions =
          res.data.data.draft_rejection_reasons.map((item) => ({
            value: item.id.toString(),
          }));
        setSelectedOptions(initialSelectedOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [boardID, labourUserID]);

  useEffect(() => {
    if (
      certificateDetails &&
      (status === "Approved" || status === "Rejected")
    ) {
      setOrder(certificateDetails.order_remarks);
      setRemarks(certificateDetails.remarks);

      //         console.log("INSIDE FILE USEEFEECT");
      // console.log(certificateDocument && certificateDocument.length && certificateDocument[0].dcument_verification_id,"certificateDetails");
    }
    if (
      certificateDetails &&
      (status === "DraftApproved" || status === "DraftRejected")
    ) {
      setOrder(certificateDetails.draft_order_remarks);
      setRemarks(certificateDetails.draft_remarks);
    }
  }, [certificateDetails]);

  useEffect(() => {
    if (
      certificateDocument &&
      certificateDocument.length > 0 &&
      (status === "Approved" || status === "Rejected")
    ) {
      // console.log(certificateDocument && certificateDocument[0].dcument_verification_id,"DOCUMENT");
      if (
        certificateDocument &&
        certificateDocument.length &&
        certificateDocument[0].dcument_verification_id
      ) {
        const fileData =
          certificateDocument && certificateDocument[0].dcument_verification_id;

        const newFileData = JSON.parse(fileData);
        setVerificationDoc(newFileData);
        const MappedData = newFileData.map((data) => data);

        var jsondocs = MappedData;

        // var jsondocs = typeof certificateDetails.dcument_verification_id !== "object" && JSON.parse(certificateDetails.dcument_verification_id)

        var docsFromApi = [];

        let promises = [];
        for (var i = 0; i < jsondocs.length; i++) {
          var config = {
            method: "get",
            url: SERVER + "/upload/download_base_64/" + jsondocs[i].file_id,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          };

          promises.push(getfilebase64(config));
          if (promises.length === jsondocs.length) {
            Promise.all(promises).then((results) => {
              var certdoc = [];
              results.forEach((j, index) => {
                // console.log("jsondocs_inside: "+JSON.stringify(j));
                // console.log("jsondocs_inside index: "+index);

                var cert = {
                  ["name"]: jsondocs[index].file_name,
                  ["base_64_content"]: j.base_64_content,
                  ["contentType"]: j.contentType,
                };
                certdoc.push(cert);
              });

              // setVerificationDoc(certdoc);
              // console.log("certdoc: "+JSON.stringify(certdoc));
            });
          }
        }
      }
      if (
        certificateDocument &&
        certificateDocument.length &&
        certificateDocument[0].missing_document_id
      ) {
        const fileData =
          certificateDocument && certificateDocument[0].missing_document_id;

        const newFileData = JSON.parse(fileData);
        setMissingDocs(newFileData);
        const MappedData = newFileData.map((data) => data);

        var jsondocs3 = MappedData;

        var docsFromApi = [];

        let promises = [];
        for (var j = 0; j < jsondocs3.length; j++) {
          var config2 = {
            method: "get",
            url: SERVER + "/upload/download_base_64/" + jsondocs3[j].file_id,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          };

          promises.push(getfilebase64(config));
          if (promises.length === jsondocs3.length) {
            Promise.all(promises).then((results) => {
              var certdoc = [];
              results.forEach((j, index) => {
                // console.log("jsondocs_inside: "+JSON.stringify(j));
                // console.log("jsondocs_inside index: "+index);

                var cert = {
                  ["name"]: jsondocs3[index].file_name,
                  ["base_64_content"]: j.base_64_content,
                  ["contentType"]: j.contentType,
                };
                certdoc.push(cert);
              });

              // setMissingDocs(certdoc);
              // console.log("certdoc: "+JSON.stringify(certdoc));
            });
          }
        }
      }
    }

    /* Draft Document */

    if (
      certificateDocument &&
      certificateDocument.length > 0 &&
      (status === "DraftApproved" || status === "DraftRejected")
    ) {
      // console.log(certificateDocument && certificateDocument[0].dcument_verification_id,"DOCUMENT");

      if (
        certificateDocument &&
        certificateDocument.length &&
        certificateDocument[0].draft_document_verification_id
      ) {
        const fileData =
          certificateDocument &&
          certificateDocument[0].draft_document_verification_id;

        const newFileData = JSON.parse(fileData);
        // setVerificationDoc(newFileData);
        const MappedData = newFileData.map((data) => data);

        var jsondocs2 = MappedData;
        setVerificationList(MappedData);

        // var jsondocs = typeof certificateDetails.dcument_verification_id !== "object" && JSON.parse(certificateDetails.dcument_verification_id)

        var docsFromApi = [];

        let promises = [];
        for (var j = 0; j < jsondocs2.length; j++) {
          var config2 = {
            method: "get",
            url: SERVER + "/upload/download_base_64/" + jsondocs2[j].file_id,
            headers: {
              Authorization: `Bearer ${getTokenCookie()}`,
            },
          };

          promises.push(getfilebase64(config2));
          if (promises.length === jsondocs2.length) {
            Promise.all(promises).then((results) => {
              var certdoc = [];
              results.forEach((j, index) => {
                // console.log("jsondocs_inside: "+JSON.stringify(j));
                // console.log("jsondocs_inside index: "+index);

                var cert = {
                  ["name"]: jsondocs2[index].file_name,
                  ["base_64_content"]: j.base_64_content,
                  ["contentType"]: j.contentType,
                };
                certdoc.push(cert);
              });

              setVerificationDoc(certdoc);
            });
          }
        }
      }
    }
  }, [certificateDocument, status, certificateDetails]);

  const handleClick = (event, data, selecteddoc) => {
    setselected_document_type(selecteddoc);

    if (data === "verification") {
      setCurrentImgClick("verification");
      setAnchorElVerification(event.currentTarget);
    } else if (data === "missing") {
      setCurrentImgClick("missing");
      setAnchorElMissing(event.currentTarget);
    }
  };

  const handleClose = (event, data) => {
    console.error("handleClose data:" + data);
    if (data === "verification") {
      setCurrentImgClick("verification");
      setAnchorElVerification(null);
    } else if (data === "missing") {
      setCurrentImgClick("missing");
      setAnchorElMissing(null);
    }
  };

  const popImgCall = useCallback(() => {}, [currentImgClick]);

  const handleClick2 = (event, data, selecteddoc) => {
    let pdfWindow = window.open("");
    pdfWindow.document.write(
      "<iframe width='100%' height='100%' src='data:application/pdf;base64, " +
        encodeURI(selecteddoc) +
        "'></iframe>"
    );
  };

  const getfilebase64 = (config) => {
    return new Promise((resolve, reject) => {
      axios(config)
        .then(function (response) {
          resolve(response.data.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  // useEffect(()=>{
  //     if (certificateDetails && status === "Approved") {

  //         }

  // },[certificateDetails])

  const handleChange = (ev) => {
    console.log(ev.target.value);
    setRejectRemarks(ev.target.value);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
    props.history.push("/admin/dashboard");
  };

  const handleTransactionOpen = (from) => {
    setOpen(true);
    setApproveReject(from);
  };

  const handleTransactionClose = () => {
    setOpen(false);
  };

  const handlePreviewClose = () => {
    setOpenPreview(false);
  };

  const handleTransactionModal = async (ev) => {
    ev.preventDefault();
    if (transactionPassword) {
      const payload = {
        login_id: users.login_admin.data.department_user_id,
        password: transactionPassword,
      };
      const config = {
        method: "POST",
        url: `${SERVER}/config/dept_user/transaction-password`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payload,
      };
      await axios(config)
        .then((res) => {
          setIsTransactionMatched(true);
          showToast("SUCCESS", `${res.data.message}`);
          setOpen(false);
          if (approveReject === "approve") {
            handleAccept();
          } else if (approveReject === "reject") {
            handleClickOpenSaveDraft();
          }
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response.data.errors.msg);
          showToast("ERROR", `${err.response.data.errors.msg}`);
        });
    } else {
      showToast("ERROR", "Please Enter Transaction Password");
    }
  };

  const handlePreview = () => {
    setOpenPreview(!openPreview);
  };

  const handleFileChange = (ev) => {
    setLoadingFiles(true);

    if (ev.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !ev.target.files[0].type === "image/png" ||
      !ev.target.files[0].type === "image/jpeg" ||
      !ev.target.files[0].type === "image/jpg" ||
      !ev.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectedFiles).concat(
        Array.from(ev.target.files)
      );

      setSelectedFiles(joined);
    }

    setLoadingFiles(false);
  };

  const handleMissingFile = (ev) => {
    setLoadingFiles(true);

    if (ev.target.files[0].size > 2000001) {
      showToast("ERROR", "Please upload file of size less than 2MB.");
    } else if (
      !ev.target.files[0].type === "image/png" ||
      !ev.target.files[0].type === "image/jpeg" ||
      !ev.target.files[0].type === "image/jpg" ||
      !ev.target.files[0].type === "application/pdf"
    ) {
      showToast("ERROR", "Please upload file in JPEG or PNG format.");
    } else {
      const joined = Array.from(selectMissingFiles).concat(
        Array.from(ev.target.files)
      );

      setSelectedMissingFiles(joined);
    }

    setLoadingFiles(false);
  };

  const removefile = (index, docname) => {
    setLoadingFiles(true);
    // console.log("selectedfiles: ", selectedfiles)
    selectedFiles.splice(index, 1);
    setTimeout(() => setLoadingFiles(false), 1);
  };

  const removeMissingfiles = (index, docname) => {
    setLoadingFiles(true);
    // console.log("selectedfiles: ", selectedfiles)
    selectMissingFiles.splice(index, 1);
    setTimeout(() => setLoadingFiles(false), 1);
  };

  const removefileDraft = (index, docname) => {
    // setVerificationDoc({})
    setLoadingFiles(true);
    // console.log("selectedfiles: ", selectedfiles)

    verificationDoc.splice(index, 1);
    setVerificationList([]);
    setIsDraftDocument(false);
    setTimeout(() => setLoadingFiles(false), 1);
  };

  useEffect(() => {
    if (selectMissingFiles && selectMissingFiles.length > 0) {
      callMissingFileUpload();
    }
  }, [selectMissingFiles]);

  const callMissingFileUpload = () => {
    let promises = [];
    selectMissingFiles.forEach(async (i) => {
      var selectedblob = i;
      var reader = new FileReader();
      reader.readAsDataURL(selectedblob);
      var formdataobj = "";
      var fileName = "";
      reader.onloadend = await function (event) {
        fileName = selectedblob.name;
        var base64data = event.target.result;
        formdataobj = {
          file: base64data,
          fileType: fileName,
        };
        promises.push(uploadfile(formdataobj, fileName));
        if (promises.length === selectMissingFiles.length) {
          Promise.all(promises).then((results) => {
            var filelist = [];
            results.forEach((i) => {
              var filejson = {
                file_id: i.image.fileId,
                file_name: i.image.fileType,
              };
              filelist.push(filejson);
            });

            setMissingFilesResult(filelist);
          });
        }
      };
    });
  };

  const uploadDocumentAndCallApproveOrReject = (from, rejectReasons) => {
    if (remarks && order && selectedFiles && selectedFiles.length > 0) {
      setDisableBtn(true);
      let promises = [];
      selectedFiles.forEach(async (i) => {
        var selectedblob = i;
        var reader = new FileReader();
        reader.readAsDataURL(selectedblob);
        var formdataobj = "";
        var fileName = "";
        reader.onloadend = await function (event) {
          fileName = selectedblob.name;
          var base64data = event.target.result;
          formdataobj = {
            file: base64data,
            fileType: fileName,
          };
          promises.push(uploadfile(formdataobj, fileName, labourUserID));
          if (promises.length === selectedFiles.length) {
            Promise.all(promises).then((results) => {
              var filelist = [];
              results.forEach((i) => {
                var filejson = {
                  file_id: i.image.fileId,
                  file_name: i.image.fileType,
                };
                filelist.push(filejson);
              });

              // updateDetails(filelist)
              if (from === "draftApprove") {
                handleDraft(filelist, "draftApprove");
              } else if (from === "draftReject") {
                handleDraft(filelist, "draftReject", rejectReasons);
              }
            });
          }
        };
      });
    } else {
      showToast("ERROR", "Please fill all mandatory fields");
    }
  };

  const uploadDocument2 = (from, reasons) => {
    if (
      remarks &&
      order &&
      ((verificationDocList && verificationDocList.length > 0) ||
        (selectedFiles && selectedFiles.length > 0))
    ) {
      setDisableBtn(true);
      if (selectedFiles && selectedFiles.length > 0) {
        let promises = [];
        selectedFiles.forEach(async (i) => {
          var selectedblob = i;
          var reader = new FileReader();
          reader.readAsDataURL(selectedblob);
          var formdataobj = "";
          var fileName = "";
          reader.onloadend = await function (event) {
            fileName = selectedblob.name;
            var base64data = event.target.result;
            formdataobj = {
              file: base64data,
              fileType: fileName,
            };
            promises.push(uploadfile(formdataobj, fileName, labourUserID));
            if (promises.length === selectedFiles.length) {
              Promise.all(promises).then((results) => {
                var filelist = [];
                results.forEach((i) => {
                  var filejson = {
                    file_id: i.image.fileId,
                    file_name: i.image.fileType,
                  };
                  filelist.push(filejson, ...verificationDocList);
                });

                // updateDetails(filelist)
                if (from === "approve") {
                  handleAccept(filelist);
                } else if (from === "reject") {
                  handleReject(filelist, reasons);
                }
                // else if(from === "draftApprove")
                // {
                //     handleDraft(filelist,"draftApprove")
                // }
                // else if(from === "draftReject")
                // {
                //     handleDraft(filelist,"draftReject")
                // }
              });
            }
          };
        });
      } else {
        if (from === "approve") {
          handleAccept(verificationDocList);
        } else if (from === "reject") {
          handleReject(verificationDocList, reasons);
        }
      }
    } else {
      showToast("ERROR", "Please fill all mandatory details");
    }
  };

  const uploadfile = (data1, filename, userID) => {
    return new Promise((resolve, reject) => {
      var FormData = require("form-data");

      var data = new FormData();
      data.append("file", dataURLtoFile(data1.file, "newfile"));
      data.append("userId", userID);
      data.append("fileType", data1.fileType);

      var config = {
        method: "post",
        url: SERVER + "/upload/file",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          resolve(response.data);
          // setTimeout(resolve, 100, response.data);
        })
        .catch(function (error) {
          console.log(error);
          reject(error);
        });
    });
  };

  // Draft Approval or Reject

  const handleDraft = (fileList, draftStatus, reasons) => {
    const verificatioinDocList = fileList.map((data) => ({
      fileId: data.file_id,
      fileName: data.file_name,
    }));
    setOpenBackdrop(true);
    setLoading(true);
    setSuccessDraft(false);

    const payLoad = {
      userId: labourUserID,
      boardId: boardID,
      departmentUserId: users.login_admin.data.department_user_id,
      approvalCertificateId:
        certificateDetails && certificateDetails.labour_work_certificate_id,
      registrationSatusId:
        draftStatus === "draftApprove"
          ? 1152
          : draftStatus === "draftReject"
            ? 1153
            : null,
      description: remarks,
      orderRemarks: order,
      verificationDocuments: fileList,
    };

    const payloadV2 = {
      userId: +labourUserID,
      boardId: +boardID,
      departmentUserId: users.login_admin.data.department_user_id,
      approvalCertificateId:
        certificateDetails && certificateDetails.labour_work_certificate_id,
      registrationSatusId:
        draftStatus === "draftApprove"
          ? 1152
          : draftStatus === "draftReject"
            ? 1153
            : null,
      remarks: remarks,
      order: order,
      verificationDocuments: fileList,
      rejectionReason: reasons ? reasons : [],
    };

    const config = {
      method: "post",
      // url: SERVER + '/admin/draft/registration-renewal/approve-reject',
      url: SERVER + "/admin/draft/v2/registration-renewal/approve-reject",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getTokenCookie()}`,
      },
      data: payloadV2,
    };

    // console.log('draftRejected', payloadV2);

    axios(config)
      .then((res) => {
        setLoading(false);
        setOpenRejection(false);
        setSuccessDraft(true);
      })
      .catch((err) => {
        setDisableBtn(false);
        setLoading(false);
        if (err?.response?.data?.errors?.msg) {
          showToast("ERROR", `${err?.response?.data?.errors?.msg}`);
        } else {
          showToast("ERROR", "Something went wrong");
        }
      });
  };

  const handleAccept = (files) => {
    // setOpen(true)
    setOpenBackdrop(true);
    setLoading(true);
    setSubmitSuccess(false);
    setRejectSuccess(false);

    if (page === "Renewal") {
      // var dataNEW = JSON.stringify({
      //   user_id: labourUserID,
      //   board_id: boardID,
      //   approval_certificate_id:
      //     certificateDetails &&
      //     certificateDetails.labour_work_certificate_id,
      //   catalog_value_registration_satus_id: 47,
      //   department_user_id: users.login_admin.data.department_user_id,
      //   approval_description: remarks,
      //   order_remarks: order,
      //   documents: files && files ? JSON.stringify(files) : null,
      //   missingDocuments:
      //     missingFilesResults && JSON.stringify(missingFilesResults),
      // });

      const payload = {
        labourUserId: +labourUserID,
        boardId: +boardID,
        approvalCertificateId:
          certificateDetails && certificateDetails.labour_work_certificate_id,
        statusId: 47,
        departmentUserId: users.login_admin.data.department_user_id,
        remarks: remarks,
        order: order,
        verificationDocuments: files && files ? files : [],
        missingDocuments:
          missingFilesResults && missingFilesResults ? missingFilesResults : [],
        rejectionReason: [],
      };
      const config2 = {
        method: "post",
        // url: SERVER + '/user/approve-renewal',
        url: SERVER + "/user/renewal/approve-reject",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payload,
      };

      axios(config2)
        .then(function (response) {
          setLoading(false);
          setSubmitSuccess(true);
        })
        .catch(function (error) {
          setDisableBtn(false);
          setLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    } else if (status === "EditPending") {
      var data = JSON.stringify({
        edit_profile_id: editProfileID,
        labour_user_id: labourUserID,
        user_id: users.login_admin.data.department_user_id,
      });

      var config = {
        method: "post",
        url: SERVER + "/admin/approve-labour-edit",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          setSubmitSuccess(true);
        })
        .catch(function (error) {
          console.log(error);
          setDisableBtn(false);
          setLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    } else {
      // const dataNEW = JSON.stringify({
      //   userId: labourUserID,
      //   labourWorkCertificateId:
      //     certificateDetails && certificateDetails.labour_work_certificate_id,
      //   boardId: 1,
      //   registrationSatus: 47,
      //   departmentUserId: users.login_admin.data.department_user_id,
      //   description: remarks,
      //   orderRemarks: order,
      //   documents: files && files ? files : [],
      //   missingDocuments:
      //     missingFilesResults && missingFilesResults ? missingFilesResults : [],
      // });

      const payload = {
        userId: +labourUserID,
        labourWorkCertificateId:
          certificateDetails && certificateDetails.labour_work_certificate_id,
        boardId: +boardID,

        departmentUserId: users.login_admin.data.department_user_id,
        remarks: remarks,
        order: order,
        verificationDocuments: files && files ? files : [],
        missingDocuments:
          missingFilesResults && missingFilesResults ? missingFilesResults : [],
      };

      const config = {
        method: "post",
        // url: SERVER + '/admin/approve-labour',
        url: `${SERVER}/admin/v2/approve-labour`,
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payload,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          setSubmitSuccess(true);
        })
        .catch(function (error) {
          setDisableBtn(false);
          setLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    }
  };

  const handleReject = (files, reasons) => {
    setOpenBackdrop(true);
    setLoading(true);
    setRejectSuccess(false);
    setSubmitSuccess(false);

    if (page === "Renewal") {
      // var dataNEW = JSON.stringify({
      //   user_id: labourUserID,
      //   board_id: boardID,
      //   approval_certificate_id:
      //     certificateDetails &&
      //     certificateDetails.labour_work_certificate_id,
      //   catalog_value_registration_satus_id: 46,
      //   department_user_id: users.login_admin.data.department_user_id,
      //   approval_description: remarks,
      //   order_remarks: order,
      //   documents: files && files ? JSON.stringify(files) : null,
      //   missingDocuments:
      //     missingFilesResults && JSON.stringify(missingFilesResults),
      // });
      const payloadV2 = {
        boardId: +boardID,
        labourUserId: +labourUserID,
        departmentUserId: users.login_admin.data.department_user_id,
        approvalCertificateId:
          certificateDetails && certificateDetails.labour_work_certificate_id,
        statusId: 46,
        remarks: remarks,
        order: order,
        verificationDocuments: files && files ? files : [],
        missingDocuments: missingFilesResults ? missingFilesResults : [],
        rejectionReason: reasons,
      };
      var config = {
        method: "post",
        // url: SERVER + '/user/approve-renewal',
        url: SERVER + "/user/renewal/approve-reject",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payloadV2,
      };

      axios(config)
        .then(function (response) {
          setLoading(false);
          setRejectSuccess(true);
        })
        .catch(function (error) {
          setDisableBtn(false);
          setDisableBtn(false);
          setLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    } else {
      // var dataNEW = JSON.stringify({
      //   user_id: labourUserID,
      //   approval_certificate_id:
      //     certificateDetails && certificateDetails.labour_work_certificate_id,
      //   board_id: boardID,
      //   catalog_value_registration_satus_id: 46,
      //   department_user_id: users.login_admin.data.department_user_id,
      //   reject_description: remarks,
      //   order_remarks: order,
      //   documents: files && files ? files : null,
      // });

      const payloadV2 = {
        boardID: 1,
        departmentUserID: users.login_admin.data.department_user_id,
        labourUserID: +labourUserID,
        approvalCertificateID:
          certificateDetails && certificateDetails.labour_work_certificate_id,
        remarks: remarks,
        order: order,
        verificationDocuments: files,
        rejectionReason: reasons,
      };

      var registrationConfig = {
        method: "post",
        // url: SERVER + '/admin/reject-labour',
        url: SERVER + "/admin/v2/reject-labour",
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
        data: payloadV2,
      };

      axios(registrationConfig)
        .then(function (response) {
          setLoading(false);
          setOpenRejection(false);
          setRejectSuccess(true);
        })
        .catch(function (error) {
          setDisableBtn(false);
          setLoading(false);
          if (error?.response?.data?.errors?.msg) {
            showToast("ERROR", `${error?.response?.data?.errors?.msg}`);
          } else {
            showToast("ERROR", "Something went wrong");
          }
        });
    }
  };
  const [openSaveDraft, setopenSaveDraft] = React.useState(false);
  const handleClickOpenSaveDraft = () => {
    setopenSaveDraft(true);
  };
  const handleCloseSaveDraft = () => {
    setopenSaveDraft(false);
  };

  const handleClickSaveDraft = () => {
    if (rejectRemarks === null || rejectRemarks === "") {
      showToast("ERROR", "Please fill remarks field");
    } else {
      setopenSaveDraft(false);
      uploadDocumentAndCallApproveOrReject("reject");
      // handleReject()
    }
  };

  // const handleCheckboxChange = (event) => {
  //   const { value, checked } = event.target;

  //   // If checked, add the option to selectedOptions, else remove it
  //   if (checked) {
  //     setSelectedOptions((prevOptions) => [...prevOptions, { value }]);
  //   } else {
  //     setSelectedOptions((prevOptions) =>
  //       prevOptions.filter((option) => option.value !== value),
  //     );
  //   }
  // };

  /* +++++ Rejection Reasons Handlers +++++ */

  const callRejectReasons = async (id) => {
    const result = await rejectedReasonsAPI(id);

    setRejectReasons(result.data);
  };

  useEffect(() => {
    if (page) {
      if (page === "Registration") {
        callRejectReasons(1001);
      } else if (page === "Renewal") {
        callRejectReasons(1002);
      }
    }
  }, [page]);

  const handleCloseRejection = () => {
    setOpenRejection(false);
  };

  const handleToggle = () => {
    setToggleLang(!toggleLang);
  };
  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    // If checked, add the option to selectedOptions, else remove it
    if (checked) {
      setSelectedOptions((prevOptions) => [...prevOptions, { value }]);
    } else {
      setSelectedOptions((prevOptions) =>
        prevOptions.filter((option) => option.value !== value)
      );
    }
  };

  // const handleSubmitReject = () => {
  //   if (selectedOptions && selectedOptions.length > 0) {
  //     const rejectReason = selectedOptions.map((option) => ({
  //       id: +option.value,
  //     }));
  //     // handleApproveRejectTransaction('Reject', rejectReason);
  //   } else {
  //     showToast('ERROR', 'Please select atleast any one of the reason');
  //   }
  // };
  const handleSubmitReject = () => {
    if (selectedOptions && selectedOptions.length > 0) {
      setOpenRejection(false);
      setLoading(true);

      const rejectReason = selectedOptions.map((option) => ({
        id: +option.value,
      }));
      // handleDraftTransaction('DraftReject', rejectReason);
      if (status === "Pending") {
        uploadDocumentAndCallApproveOrReject("draftReject", rejectReason);
      } else if (status === "DraftRejected" || status === "DraftApproved") {
        uploadDocument2("reject", rejectReason);
      }
    } else {
      showToast("ERROR", "Please select atleast any one of the reason");
    }
  };

  const handleOpenRejectionDialog = () => {
    if (
      remarks &&
      order &&
      ((selectedFiles && selectedFiles.length > 0) ||
        (verificationDoc && verificationDoc.length > 0))
    ) {
      //console.log('Opening dialog...');
      setDisableBtn(true);
      setOpenRejection(true);
    } else {
      showToast("ERROR", "Please fill all mandatory fields");
    }
  };

  const handleComments = (e) => {
    const { name, value } = e.target;
    if (name === "remarks") {
      setRemarks(value);
    } else if (name === "order") {
      setOrder(value);
    }
  };

  const handleViewImage = (event, fileID) => {
    setAnchorEl(event.currentTarget);
    setFileID(fileID);
  };

  const handleNewTab = (url) => {
    window.open(url, "_blank", "noopener");
    handleClosePopOver();
  };

  const handleViewPDF = (fileID) => {
    const url = `${SERVER}/upload/file/${fileID}`;
    window.open(url, "_blank", "noopener");
  };

  const handleClosePopOver = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <PopOver
        id={documentid}
        open={openDocument}
        anchorEl={anchorEl}
        onClose={handleClosePopOver}
        handleNewTab={handleNewTab}
        fileID={fileID && fileID}
      />
      {loading && <SimpleBackdrop />}
      <Reject
        // handleApproveRejectTransaction={handleApproveRejectTransaction}
        openRejection={openRejection}
        handleCloseRejection={handleCloseRejection}
        toggleLang={toggleLang}
        handleToggle={handleToggle}
        handleCheckboxChange={handleCheckboxChange}
        handleSubmitReject={handleSubmitReject}
        handleOpenRejectionDialog={handleOpenRejectionDialog}
        rejectReasons={rejectReasons}
        selectedOptions={selectedOptions}
      />

      <Dialog open={open} onClose={handleTransactionClose}>
        <DialogContent>
          <DialogContentText>
            Enter Transaction Password for Approval / Reject
          </DialogContentText>
          <div className="d-flex justify-content-center">
            <input
              className="change-password-input"
              type="password"
              placeholder="Enter Transcation Password"
              onChange={(ev) => setTransactionPassword(ev.target.value)}
              value={transactionPassword}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button2
            variant="contained"
            color="error"
            onClick={(ev) => setTransactionPassword("")}
          >
            Reset
          </Button2>
          <Button2
            variant="contained"
            color="success"
            onClick={handleTransactionModal}
          >
            Continue
          </Button2>
        </DialogActions>
      </Dialog>

      {/* E-card preview option */}
      <Dialog
        style={{ width: "100%" }}
        open={openPreview}
        onClose={handlePreviewClose}
      >
        <DialogContent>
          {/* <DialogContentText>
            E-Card Preview
          </DialogContentText> */}
          <ECard
            personalDetails={personalDetails}
            addressDetails={addressDetails}
            familyDetails={familyDetails}
            rationCardDetails={rationCardDetails}
            bankDetails={bankDetails}
            certificateDetails={certificateDetails}
            employerDetails={employerDetails}
          />
        </DialogContent>
        <DialogActions>
          <Button2
            variant="contained"
            color="error"
            onClick={handlePreviewClose}
          >
            Close
          </Button2>
          {status === "Pending" ? (
            <>
              {/* <Button2
                variant="contained"
                color="success"
                onClick={() =>
                  uploadDocumentAndCallApproveOrReject('draftApprove')
                }
              >
                Draft Approve
              </Button2> */}
            </>
          ) : status === "DraftApproved" || status === "DraftRejected" ? (
            <>
              {/* <Button2
                variant="contained"
                color="success"
                onClick={() => uploadDocument2('approve')}
              >
                Approve
              </Button2> */}
            </>
          ) : null}
          {status === "Pending" ? (
            <>
              {/* <Button2
                variant="contained"
                color="success"
                onClick={() =>
                  uploadDocumentAndCallApproveOrReject('draftReject')
                }
              >
                Draft Reject
              </Button2> */}
            </>
          ) : status === "DraftApproved" || status === "DraftRejected" ? (
            <>
              {/* <Button2
                variant="contained"
                color="success"
                onClick={() => uploadDocument2('reject')}
              >
                Reject
              </Button2> */}
            </>
          ) : null}
        </DialogActions>
      </Dialog>

      <div className="root">
        {/* <CssBaseline /> */}
        <Row className="top-div">
          <Row className="topbar-row">
            <Col xs={12} md={8} lg={9} className="top-bar-col">
              <div className="logo-div-profile">
                <a
                  onClick={props.history.goBack}
                  className="cal-icon-hover-icn mt-3"
                >
                  <img alt="..." className="cal-icon hover-icn" src={back} />
                </a>
                <Link to="/admin/dashboard">
                  <img id="logo" src={logo} alt="..." className="logo-img" />
                </Link>
                {/* <img id="logo" src={logo} alt="..." className="logo-img" /> */}
                <h1 className="logo-text">
                  Government of Karnataka <br />
                  Karnataka Building & Other Construction Workers Welfare Board
                  (KBOCWWB)
                </h1>
              </div>
            </Col>
            <Col className="notifications-logout-usericon-dashboard">
              <div className="usericon-header-logout">
                <img alt="..." src={usericon} className="usericon-img" />
                <p>{users.login_admin.data.first_name}</p>
                <Tooltip title="Logout" placement="top-start" arrow interactive>
                  <img
                    alt="..."
                    src={logout}
                    className="logout-img"
                    onClick={() => {
                      props.history.push("/admin");
                    }}
                  />
                </Tooltip>
              </div>
            </Col>
          </Row>
        </Row>
      </div>

      <>
        {getUsers && getUsers === null ? (
          <CircularProgress className="loader-otp" />
        ) : (
          <Row className="m-0">
            {loading ? (
              <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                onClick={loading ? null : handleCloseBackdrop}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : null}

            {submitSuccess ? (
              <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                onClick={loading ? null : handleCloseBackdrop}
              >
                <div style={{ display: "block" }}>
                  <CheckCircle
                    className={classes.backdropCheck}
                    style={{ height: "auto", width: "200px" }}
                  />
                  <p className="final-success-title">
                    Application Accepted Successfully!
                  </p>
                </div>
              </Backdrop>
            ) : null}
            {rejectSuccess ? (
              <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                onClick={loading ? null : handleCloseBackdrop}
              >
                <div style={{ display: "block" }}>
                  <CheckCircle
                    className={classes.backdropCheck}
                    style={{ height: "auto", width: "200px" }}
                  />
                  <p className="final-success-title">
                    Application Rejected Successfully!
                  </p>
                </div>
              </Backdrop>
            ) : null}
            {draftSuccess ? (
              <Backdrop
                className={classes.backdrop}
                open={openBackdrop}
                onClick={loading ? null : handleCloseBackdrop}
              >
                <div style={{ display: "block" }}>
                  <CheckCircle
                    className={classes.backdropCheck}
                    style={{ height: "auto", width: "200px" }}
                  />
                  <p className="final-success-title">
                    Draft Saved Successfully!
                  </p>
                </div>
              </Backdrop>
            ) : null}
            {console.log({
              anmol:
                status === "Pending" &&
                personalDetails?.re_registration_count > 0,
              status,
            })}
            {status === "Pending" &&
              personalDetails?.re_registration_count > 0 && (
                <>
                  <div className="statusbar outer">
                    <div className="statusbar rejected d-flex">
                      <img alt="..." src={rejected} />
                      <p>
                        Application has been rejected{" "}
                        {personalDetails?.re_registration_count} times, Last
                        Rejection Date -{" "}
                        {moment(personalDetails?.last_rejection_date).format(
                          "DD/MM/YY"
                        )}
                        <span
                          style={{ cursor: "pointer" }}
                          onClick={() => setLastRejectedReasonVisibility(true)}
                        >
                          {" "}
                          Click here to view rejected reasons
                        </span>
                      </p>
                    </div>
                    {/* <div className="statusbar reason">
                  <p className="mb-2 reason-title">
                    <Translate value="reason" />
                  </p>
                  <p
                    className="pl-0"
                    style={{
                      fontFamily: 'inherit',
                      color: 'red',
                      fontWeight: 'thicker',
                    }}
                  >
                    {' '}
                    {users.getUserRegistrationDetails !== undefined &&
                      users.getUserRegistrationDetails !== null &&
                      users.getUserRegistrationDetails
                        .personal_details !== undefined &&
                      users.getUserRegistrationDetails
                        .personal_details[0] !== undefined &&
                      users.getUserRegistrationDetails.personal_details[0]
                        .approval_reject_description}
                  </p>
                </div> */}
                  </div>
                  <div className="d-flex justify-content-start">
                    {/* <Button
                  className="w-100 text-start"
                  variant="text"
                  style={{ cursor: 'pointer' }}
                  // onClick={() =>
                  //   handleClickOpen(
                  //     row.scheme_avail_id,
                  //     row.scheme_name,
                  //     row.status,
                  //   )
                  // }
                >
                  Click here to view rejected reasons
                </Button> */}
                    {
                      <RejectedReasonsDialog
                        open={isLastRejectedReasonsVisible}
                        handleClose={() =>
                          setLastRejectedReasonVisibility(false)
                        }
                        reasons={
                          testJSON(personalDetails?.last_rejected_reasons_json)
                            ? JSON.parse(
                                personalDetails?.last_rejected_reasons_json
                              )
                            : []
                        }
                        // oldReasons={

                        // }
                        serviceName={"Registration Rejected Reasons"}
                        // loading={reasonLoading}
                        value={langValue}
                        handleChange={(event) =>
                          setLangValue(event.target.value)
                        }
                      />
                    }
                  </div>
                </>
              )}

            {personalDetails ? (
              <LabourDetails
                personalDetails={personalDetails}
                addressDetails={addressDetails}
                familyDetails={familyDetails}
                rationCardDetails={rationCardDetails}
                bankDetails={bankDetails}
                certificateDetails={certificateDetails}
                employerDetails={employerDetails}
              />
            ) : (
              <CircularProgress
                color="inherit"
                style={{ margin: "300px auto", height: "auto", width: "100px" }}
              />
            )}

            <>
              {/* <Row className="scheme-subtitle-row my-3 py-4">
                <Col xs={12} className="searchform-subtitle my-3 py-4">
                    
                </Col>
            </Row> */}

              <Row className="form-row config-form searchform">
                <Col
                  xs={12}
                  md={6}
                  className="amount-sanctioned mt-2 bank-col-5"
                >
                  <p className="mb-2">Remarks</p>
                  <FormControl fullWidth className="formcontrol1">
                    <textarea
                      variant="outlined"
                      value={remarks}
                      name="remarks"
                      maxLength={wordLimit}
                      onChange={(e) => handleComments(e)}
                      disabled={status === "Approved" ? true : false}
                    />
                  </FormControl>
                  {!hideWordsLimits && (
                    <div className="d-flex justify-content-end align-items-center py-2">
                      <p>
                        Words limit: {remarks?.length}/{wordLimit}
                      </p>
                    </div>
                  )}
                </Col>

                {status === "Pending" ? (
                  <Col md={6} className="amount-sanctioned mt-2 bank-col-5">
                    <Required className="mb-2" title="Verification Document" />
                    <div className="browsebutton-outerdiv">
                      <div className="browsebutton2-div filesNames">
                        {loadingFiles ? (
                          <></>
                        ) : (
                          selectedFiles !== null &&
                          selectedFiles.length > 0 &&
                          Array.from(selectedFiles).map((i, ind) => (
                            <FormControl>
                              <TextField
                                variant="outlined"
                                name="verificationDocumentName"
                                value={i.name}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        alt="..."
                                        src={closeicon}
                                        className="removefilebtn"
                                        onClick={() => removefile(ind)}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        )}
                      </div>
                      <div className="browsebutton2-div">
                        <label
                          htmlFor={`upload-photo`}
                          className="browse-button btn2 schemes"
                        >
                          <img alt="..." src={folder} />
                          <Translate value="browse" />
                        </label>

                        <input
                          type="file"
                          name="photo"
                          id={`upload-photo`}
                          onChange={(e) => handleFileChange(e)}
                          multiple
                        />
                      </div>
                    </div>
                  </Col>
                ) : status === "Approved" || status === "Rejected" ? (
                  <Col
                    xs={12}
                    md={6}
                    className="amount-sanctioned mt-2 bank-col-5"
                  >
                    <p className="mb-0">Verification Document</p>
                    {verificationDoc &&
                      verificationDoc.length > 0 &&
                      verificationDoc.map((doc, i) => (
                        <>
                          <div className="browsebutton2-div final">
                            <FormControl>
                              <TextField
                                variant="outlined"
                                value={doc.file_name}
                                style={{ width: "-webkit-fill-available" }}
                                disabled
                              />
                            </FormControl>
                            {doc.file_name.includes(".png") ||
                            doc.file_name.includes(".jpeg") ||
                            doc.file_name.includes(".jpg") ? (
                              <label
                                className="browse-button2"
                                onClick={(ev) =>
                                  handleViewImage(
                                    ev,
                                    doc.file_id,
                                    doc.file_name
                                  )
                                }
                              >
                                <Translate value="view" /> <Visibility />
                              </label>
                            ) : (
                              <label
                                className="browse-button2"
                                onClick={() => handleViewPDF(doc.file_id)}
                              >
                                <Translate value="view" /> <Visibility />
                              </label>
                            )}
                          </div>
                        </>
                      ))}
                  </Col>
                ) : null}
                {status === "DraftApproved" || status === "DraftRejected" ? (
                  <>
                    <Col md={6} className="amount-sanctioned mt-2 bank-col-5">
                      <Required
                        className="mb-2"
                        title="Verification Document"
                      />
                      {isDraftDocument ? (
                        <div className="browsebutton-outerdiv">
                          <div className="browsebutton2-div filesNames">
                            {loadingFiles ? (
                              <></>
                            ) : (
                              verificationDoc !== null &&
                              verificationDoc.length > 0 &&
                              Array.from(verificationDoc).map((i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant="outlined"
                                    name="verificationDocumentName"
                                    value={i.name}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            alt="..."
                                            src={closeicon}
                                            className="removefilebtn"
                                            onClick={() => removefileDraft(ind)}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            )}
                          </div>
                          <div className="browsebutton2-div">
                            {!isAddBtnActive ? (
                              <label
                                onClick={() =>
                                  setIsAddBtnActive(!isAddBtnActive)
                                }
                                className="browse-button btn2 schemes"
                              >
                                {!isAddBtnActive ? (
                                  <>
                                    <AddIcon sx={{ fontSize: 30 }} />
                                    <Translate
                                      className="mt-1 px-2"
                                      value="Add"
                                    />
                                  </>
                                ) : null}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      ) : null}
                      {isAddBtnActive || !isDraftDocument ? (
                        <div className="browsebutton-outerdiv">
                          <div className="browsebutton2-div filesNames">
                            {loadingFiles ? (
                              <></>
                            ) : (
                              selectedFiles !== null &&
                              selectedFiles.length > 0 &&
                              Array.from(selectedFiles).map((i, ind) => (
                                <FormControl>
                                  <TextField
                                    variant="outlined"
                                    name="verificationDocumentName"
                                    value={i.name}
                                    disabled
                                    InputProps={{
                                      endAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            alt="..."
                                            src={closeicon}
                                            className="removefilebtn"
                                            onClick={() => removefile(ind)}
                                          />
                                        </InputAdornment>
                                      ),
                                    }}
                                  />
                                </FormControl>
                              ))
                            )}
                          </div>
                          <div className="browsebutton2-div">
                            <label
                              htmlFor={`upload-photo`}
                              className="browse-button btn2 schemes"
                            >
                              <img alt="..." src={folder} />
                              <Translate value="browse" />
                            </label>
                            {isAddBtnActive ? (
                              <label
                                onClick={() =>
                                  setIsAddBtnActive(!isAddBtnActive)
                                }
                                className="ms-2 browse-button btn2 schemes"
                              >
                                {isAddBtnActive ? (
                                  <>
                                    <RemoveCircleOutlinedIcon
                                      sx={{ fontSize: 30 }}
                                    />
                                    <Translate
                                      className="mt-1 px-2"
                                      value="Remove"
                                    />
                                  </>
                                ) : null}
                              </label>
                            ) : null}

                            <input
                              type="file"
                              name="photo"
                              id={`upload-photo`}
                              onChange={(e) => handleFileChange(e)}
                              multiple
                            />
                          </div>
                        </div>
                      ) : null}
                    </Col>
                  </>
                ) : null}

                <Col xs={12} className="amount-sanctioned mt-2 bank-col-5">
                  <p className="mb-2">Order</p>
                  <FormControl fullWidth className="formcontrol1">
                    <textarea
                      variant="outlined"
                      value={order}
                      name="order"
                      maxLength={wordLimit}
                      onChange={(e) => handleComments(e)}
                      // onChange={(ev) => setOrder(ev.target.value)}
                      disabled={status === "Approved" ? true : false}
                    />
                  </FormControl>
                  {!hideWordsLimits && (
                    <div className="d-flex justify-content-end align-items-center py-2">
                      <p>
                        Words limit: {order?.length}/{wordLimit}
                      </p>
                    </div>
                  )}
                </Col>
              </Row>
              <Row className="form-row config-form searchform">
                {status === "DraftApproved" || status === "DraftRejected" ? (
                  <Col md={6} className="amount-sanctioned mt-2 bank-col-5">
                    <p className="mb-2">Any Missing Document</p>
                    <div className="browsebutton-outerdiv">
                      <div className="browsebutton2-div filesNames">
                        {loadingFiles ? (
                          <></>
                        ) : (
                          selectMissingFiles !== null &&
                          selectMissingFiles.length > 0 &&
                          Array.from(selectMissingFiles).map((i, ind) => (
                            <FormControl>
                              <TextField
                                variant="outlined"
                                name="missingFiles"
                                value={i.name}
                                disabled
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="start">
                                      <img
                                        alt="..."
                                        src={closeicon}
                                        className="removefilebtn"
                                        onClick={() => removeMissingfiles(ind)}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </FormControl>
                          ))
                        )}
                      </div>
                      <div className="browsebutton2-div">
                        <label
                          htmlFor={`upload-photo-2`}
                          className="browse-button btn2 schemes"
                        >
                          <img alt="..." src={folder} />
                          <Translate value="browse" />
                        </label>

                        <input
                          type="file"
                          name="photo2"
                          id={`upload-photo-2`}
                          onChange={(e) => handleMissingFile(e)}
                          multiple
                        />
                      </div>
                    </div>
                  </Col>
                ) : status === "Approved" || status === "Rejected" ? (
                  <Col
                    xs={12}
                    md={6}
                    className="amount-sanctioned mt-2 bank-col-5"
                  >
                    <p className="mb-0">Any Missing Document</p>
                    {missingDocs &&
                      missingDocs.length > 0 &&
                      missingDocs.map((doc, i) => (
                        <>
                          <div className="browsebutton2-div final">
                            <FormControl>
                              <TextField
                                variant="outlined"
                                value={doc.file_name}
                                style={{ width: "-webkit-fill-available" }}
                                disabled
                              />
                            </FormControl>
                            {doc.file_name.includes(".png") ||
                            doc.file_name.includes(".jpeg") ||
                            doc.file_name.includes(".jpg") ? (
                              <label
                                className="browse-button2"
                                onClick={(ev) =>
                                  handleViewImage(
                                    ev,
                                    doc.file_id,
                                    doc.file_name
                                  )
                                }
                              >
                                <Translate value="view" /> <Visibility />
                              </label>
                            ) : (
                              <label
                                className="browse-button2"
                                onClick={() => handleViewPDF(doc.file_id)}
                              >
                                <Translate value="view" /> <Visibility />
                              </label>
                            )}
                          </div>
                        </>
                      ))}
                  </Col>
                ) : null}
              </Row>
            </>

            {/* {
                          personalDetails && personalDetails.approval_reject_description !== null ?
                                <Row className="scheme-subtitle-row">
                                    <Col xs={12} md={12} className="searchform-subtitle">
                                        <p>Remarks</p>
                                    </Col>
                                </Row>
                                :
                                null
            } */}
            {/* <Row className="form-row mt-4 config-form searchform">
                            <Col xs={12} md={12} className=" schemes mt-4 bank-col-5">
                                {
                                  personalDetails && personalDetails.approval_reject_description !== null ?

                                        <p class="text-pad1">
                                            <b>Approval/Rejection Remarks:&nbsp;&nbsp;&nbsp;&nbsp;   </b>
                                            {personalDetails.approval_reject_description}
                                        </p>

                                        :
                                        null
                                }
                            </Col>
                            <Col xs={12} md={12} className=" schemes mt-4 bank-col-5">
                                {
                                    personalDetails && personalDetails.appeal_remarks !== null ?

                                        <p class="text-pad1">
                                            <b>Reason for Appeal by Labour:&nbsp;&nbsp;&nbsp;&nbsp;   </b>
                                            {personalDetails && personalDetails.appeal_remarks}
                                        </p>

                                        :
                                        null
                                }
                            </Col>
                        </Row> */}

            {/* <form onSubmit={handleSubmit}>
              {rejectedReasons.map((_data) => (
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={_data.reason}
                        onChange={handleCheckboxChange}
                      />
                    }
                    label={_data.reason}
                  />
                </FormGroup>
              ))}

              <button type="submit">Send</button>
            </form> */}

            {users.getDeptUserRole.data[0].role_name === "Labour Inspector" ? (
              status === "Pending" ||
              status === "EditPending" ||
              status === "DraftApproved" ||
              status === "DraftRejected" ? (
                <>
                  <Row
                    className="button-inside-form-row mt-5 mb-5"
                    style={{
                      borderTop: "2px solid #cccccc",
                      textAlign: "end !important",
                    }}
                  >
                    <Col
                      xs={12}
                      md={12}
                      className="final-button-row mt-4 mb-5"
                      style={{
                        textAlign: "end !important",
                      }}
                    >
                      <Button
                        variant="outline-primary "
                        className="final-button-accept preview"
                        // onClick={() => handleTransactionOpen("approve")}>
                        onClick={handlePreview}
                      >
                        Preview
                      </Button>
                      {status === "EditPending" ? (
                        <Button
                          variant="outline-primary "
                          className="final-button-accept"
                          // onClick={() => handleTransactionOpen("approve")}>
                          onClick={handleAccept}
                          //  onClick={() => uploadDocumentAndCallApproveOrReject("approve")}
                          //  onClick={() => uploadDocument2("approve")}
                          // onClick={() => handleClickOpenSaveDraft()}
                        >
                          Approve
                        </Button>
                      ) : null}

                      {status === "Pending" ? (
                        <>
                          <Button
                            variant="outline-primary "
                            disabled={disableBtn}
                            className="final-button-accept"
                            // onClick={() => handleTransactionOpen("approve")}>
                            // onClick={handleDraft}
                            onClick={() =>
                              uploadDocumentAndCallApproveOrReject(
                                "draftApprove"
                              )
                            }
                          >
                            Draft Approve
                          </Button>

                          <Button
                            variant="outline-primary "
                            className="final-button-reject"
                            disabled={disableBtn}
                            // onClick={() => handleTransactionOpen("approve")}>
                            // onClick={handleDraft}
                            // onClick={() =>
                            //   uploadDocumentAndCallApproveOrReject(
                            //     'draftReject',
                            //   )
                            // }
                            onClick={() =>
                              handleOpenRejectionDialog("draftReject")
                            }
                          >
                            Draft Reject
                          </Button>
                        </>
                      ) : status === "DraftApproved" ||
                        status === "DraftRejected" ? (
                        <>
                          <Button
                            variant="outline-primary"
                            className="final-button-accept"
                            // onClick={() => handleTransactionOpen("approve")}>
                            // onClick={handleAccept}
                            //  onClick={() => uploadDocumentAndCallApproveOrReject("approve")}
                            onClick={() => uploadDocument2("approve")}
                            disabled={disableBtn}
                            // onClick={() => handleClickOpenSaveDraft()}
                          >
                            Approve
                          </Button>

                          {
                            // frompage === "Renewal" ||
                            status === "EditPending" ? null : (
                              <>
                                <Button
                                  variant="outline-primary"
                                  className="final-button-reject"
                                  // onClick={() => handleTransactionOpen("reject")}
                                  // onClick={() => handleReject()}
                                  // onClick={() => uploadDocumentAndCallApproveOrReject("reject")}
                                  // onClick={() => uploadDocument2('reject')}
                                  onClick={() =>
                                    handleOpenRejectionDialog("reject")
                                  }
                                  // onClick={() => handleClickOpenSaveDraft()}
                                >
                                  Reject
                                </Button>
                              </>
                            )
                          }
                        </>
                      ) : null}
                    </Col>
                  </Row>
                </>
              ) : (
                <Row
                  className="button-inside-form-row mt-5 mb-5"
                  style={{
                    borderTop: "2px solid #cccccc",
                    textAlign: "end !important",
                  }}
                ></Row>
              )
            ) : (
              <Row
                className="button-inside-form-row mt-5 mb-5"
                style={{
                  borderTop: "2px solid #cccccc",
                  textAlign: "end !important",
                }}
              ></Row>
            )}
          </Row>
        )}
      </>
    </>
  );
};

export default RegistrationProfileDetails;
