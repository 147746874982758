import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import logo from 'assets/images/Karnataka_logo_100_Pixels.png';

import { IconButton } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import html2pdf from 'html2pdf.js';

import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import {
  getOfficerSignature,
  rejectReasonAPI,
  schemeStatusAPI,
  schemeStatusAvailedAPI,
} from 'components/schemes/NewSchemeStatus/schemeStatus.service';
import axios from 'axios';
import { SERVER } from 'store/actions/user.actions';
import { getTokenCookie } from 'utils/tools';
import moment from 'moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';

const SchemesRejectionOrder = (props) => {
  const { schemeName, labourUserID, schemeAvailID } = useParams();
  const [schemeStatus, setSchemeStatus] = useState(null);
  const [getUserDetails, setGetUsetDetails] = useState(null);
  const [getSchemeDetails, setGetSchemeDetails] = useState(null);
  const [getschemeStatus, setGetSchemeStatus] = useState(null);
  const [getSchemeStatusDetails, setSchemeStatusDetails] = useState(null);
  const [displayDate, setDisplayDate] = useState(false);
  const [signature, setSignature] = useState(null);
  const [reasons, setReasons] = useState(null);
  const currentDate = moment().format('DD/MM/YYYY');
  const [toggleLang, setToggleLang] = useState(false);
  useEffect(() => {
    const payLoad = {
      board_id: 1,
      key: 'user_id',
      value: labourUserID,
      procedure_name: 'all',
    };
    axios
      .post(`${SERVER}/user/get-user-registration-details`, payLoad, {
        headers: {
          Authorization: `Bearer ${getTokenCookie()}`,
        },
      })
      .then(({ data }) => {
        setGetUsetDetails((prev) => ({
          ...prev,
          personalDetails: data.data.personal_details,
          addressDetails: data.data.address_details,
          certificateDetails: data.data.certificate_details,
          employerDetails: data.data.employer_details,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [labourUserID]);

  const callSchemeAvailedStatus = () => {
    schemeStatusAvailedAPI(schemeAvailID, setSchemeStatus);
  };

  const callSchemeStatus = () => {
    schemeStatusAPI(labourUserID, setGetSchemeStatus);
  };

  const callRejectionReason = async (schemeAvailID) => {
    const result = await rejectReasonAPI(schemeAvailID, 'Rejected');
    setReasons(result.data);
  };

  useEffect(() => {
    if (schemeAvailID) {
      callRejectionReason(schemeAvailID);
    }
  }, [schemeAvailID]);

  useEffect(callSchemeStatus, [labourUserID]);
  useEffect(callSchemeAvailedStatus, [schemeAvailID]);

  useEffect(() => {
    if (getschemeStatus && schemeAvailID) {
      const filteredStatus =
        getschemeStatus &&
        getschemeStatus.data.filter(
          (_schemeStatus) => _schemeStatus.scheme_avail_id === +schemeAvailID,
        );
      setSchemeStatusDetails(filteredStatus);
    }
  }, [schemeAvailID, getschemeStatus]);

  useEffect(() => {
    if (schemeStatus) {
      const filteredData1 = schemeStatus.data.recordsets[1][1];
      const filteredData2 = schemeStatus.data.recordset[0];
      const filteredData3 = schemeStatus.data.recordsets[0][0];
      setGetSchemeDetails((prev) => ({
        ...prev,
        schemeDetails1: filteredData1,
        schemeDetails2: filteredData2,
        schemeDetails3: filteredData3,
      }));
    }
  }, [schemeStatus]);

  const callSignatureAPI = (fileID) => {
    getOfficerSignature(fileID, setSignature);
  };

  useEffect(() => {
    if (getSchemeDetails) {
      callSignatureAPI(getSchemeDetails.schemeDetails1.signature_upload_id);
    }
  }, [getSchemeDetails]);

  const generatePDF = () => {
    setDisplayDate(true);
    const element = document.getElementById('ack');
    const opt = {
      filename: `${
        getSchemeDetails &&
        getSchemeDetails.schemeDetails1.scheme_application_code
      }_${
        getSchemeDetails && getSchemeDetails.schemeDetails1.scheme_name
      }_Rejection_Order`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    html2pdf(element, opt).then(() => {
      setDisplayDate(false);
    });
  };
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          flexWrap: 'wrap',
          '& > :not(style)': {
            m: 1,
            width: '70%',
          },
        }}
      >
        <Paper elevation={3}>
          <div style={{ position: 'relative' }}>
            <div
              className="form-check form-switch"
              style={{ position: 'absolute', top: 5, right: 50 }}
            >
              <label className="form-check-label" htmlFor="langSwitch">
                {toggleLang ? 'Switch to English' : 'Switch to Kannada'}
              </label>
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                id="langSwitch"
                onClick={() => setToggleLang(!toggleLang)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-between">
            <div>
              <>
                <IconButton
                  style={{
                    color: 'transparent',
                    background: 'transparent',
                    cursor: 'default',
                  }}
                >
                  <DownloadIcon style={{ color: 'transparent' }} />
                </IconButton>
              </>
            </div>
            <div id="ack" className="sanction-outer-container">
              <div className="sanction-content-container">
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <div>
                    <img src={logo} alt="logo" height={'80'} />
                  </div>
                  <div className="d-flex justify-content-center flex-column align-items-center">
                    <h5 className="text-center">
                      ಕರ್ನಾಟಕ ಕಟ್ಟಡ ಮತ್ತು ಇತರೆ ನಿರ್ಮಾಣ ಕಾರ್ಮಿಕರ ಕಲ್ಯಾಣ ಮಂಡಳಿ (ಕಕ
                      ಇನಿಕಾಕಮಂ)
                    </h5>
                    <h5 className="text-center">
                      Karnataka Building & Other Construction Workers Welfare
                      Board (KBOCWWB)
                    </h5>
                  </div>
                </div>
                <div className="d-flex justify-content-center align-items-center flex-column">
                  <h3
                    className="w-100 text-center py-3"
                    style={{ background: '#F4F8F9' }}
                  >
                    ತಿರಸ್ಕೃತ ಆದೇಶ / Rejection Order
                  </h3>
                </div>
                {getSchemeStatusDetails &&
                getSchemeDetails &&
                getUserDetails ? (
                  <>
                    <div
                      className="d-flex justify-content-center"
                      style={{ fontWeight: 'bolder' }}
                    >
                      <table style={{ borderCollapse: 'collapse' }}>
                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            {schemeName === 'Delivery Assistance'
                              ? 'ಹೆರಿಗೆ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                              : schemeName === 'Medical Assistance'
                                ? 'ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                : schemeName === 'Marriage Assistance'
                                  ? 'ಮದುವೆ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                  : schemeName ===
                                      'Assistance For Major Ailments'
                                    ? 'ಪ್ರಮುಖ ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                    : schemeName === 'Pension Scheme'
                                      ? 'ಪಿಂಚಣಿ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                      : schemeName === 'Continuation of Pension'
                                        ? 'ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ  ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                        : schemeName === 'Disability Pension'
                                          ? 'ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                          : schemeName ===
                                              'Continuation of Disability Pension'
                                            ? 'ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ  ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                            : schemeName ===
                                                  'Accident Assistance' &&
                                                getSchemeStatusDetails[0]
                                                  .death_status === 1
                                              ? 'ಅಪಘಾತ ಮರಣ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                              : schemeName ===
                                                    'Accident Assistance' &&
                                                  getSchemeStatusDetails[0]
                                                    .death_status === 2
                                                ? 'ಶಾಶ್ವತ ಭಾಗಶ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                                : schemeName ===
                                                      'Accident Assistance' &&
                                                    getSchemeStatusDetails[0]
                                                      .death_status === 3
                                                  ? 'ಶಾಶ್ವತ ಸಂಪೂರ್ಣ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                                  : schemeName ===
                                                      'Funeral Expense and Death Assistance'
                                                    ? 'ಅಂತ್ಯಕ್ರಿಯೆ ವೆಚ್ಚ ಮತ್ತು ಅನುಗ್ರಹರಾಶಿ ಸಹಾಯಧನ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                                    : schemeName ===
                                                        'Thayi Magu Sahaya Hasta'
                                                      ? 'ತಾಯಿ ಮಗು ಸಹಾಯ ಹಸ್ತ ತಿರಸ್ಕೃತ ಆದೇಶ ಸಂಖ್ಯೆ'
                                                      : ''}
                          </td>
                          <td className="px-5 py-2">
                            {' '}
                            {getSchemeDetails &&
                              getSchemeDetails.schemeDetails1
                                .scheme_application_code}
                          </td>
                        </tr>

                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ನೋಂದಣಿ ಸಂಖ್ಯೆ / Registration Number
                          </td>
                          <td className="px-5 py-2">
                            {getUserDetails &&
                              getUserDetails.personalDetails &&
                              getUserDetails.personalDetails[0]
                                .registration_code}
                          </td>
                        </tr>

                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ಅರ್ಜಿ ಸಂಖ್ಯೆ / Application Number
                          </td>
                          <td className="px-5 py-2">
                            {' '}
                            {getSchemeDetails &&
                              getSchemeDetails.schemeDetails1
                                .scheme_application_code}
                          </td>
                        </tr>
                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ಸಕಾಲ ಸ್ವೀಕೃತಿ ಸಂಖ್ಯೆ/ Sakala Reference Number
                          </td>
                          <td className="px-5 py-2">
                            {' '}
                            {getSchemeDetails &&
                              getSchemeDetails.schemeDetails3.gsc_no}
                          </td>
                        </tr>
                        {schemeName === 'Continuation of Pension' && (
                          <tr className="table-row-pdf-docuemnts">
                            <td className="px-5 py-2">
                              ಪಿಂಚಣಿ ಸಂಖ್ಯೆ / Pension ID
                            </td>
                            <td className="px-5 py-2">
                              {' '}
                              {getUserDetails.personalDetails[0].pension_no}
                            </td>
                          </tr>
                        )}
                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ಅರ್ಜಿ ದಿನಾಂಕ / Application Date
                          </td>
                          <td className="px-5 py-2">
                            {getSchemeDetails &&
                              moment(
                                getSchemeDetails.schemeDetails2.applied_date,
                              ).format('DD/MM/YYYY')}
                          </td>
                        </tr>
                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ಯೋಜನೆ ಹೆಸರು / Scheme Name
                          </td>
                          <td className="px-5 py-2">
                            {schemeName === 'Accident Assistance' &&
                            getSchemeStatusDetails[0].death_status === 1
                              ? `${getSchemeStatusDetails[0].scheme_name} (Death)`
                              : schemeName === 'Accident Assistance' &&
                                  getSchemeStatusDetails[0].death_status === 2
                                ? `${getSchemeStatusDetails[0].scheme_name} (Permanent Partial Disablement)`
                                : schemeName === 'Accident Assistance' &&
                                    getSchemeStatusDetails[0].death_status === 3
                                  ? `${getSchemeStatusDetails[0].scheme_name} (Permanent Total Disablement)`
                                  : schemeName === 'Thayi Magu Sahaya Hasta' &&
                                      getSchemeStatusDetails[0].is_renewal
                                    ? `${schemeName} (Renewal)`
                                    : schemeName}
                          </td>
                        </tr>
                        <tr className="table-row-pdf-docuemnts">
                          <td className="px-5 py-2">
                            ಅರ್ಜಿದಾರರ ಹೆಸರು / Applicant's Name
                          </td>
                          <td className="px-5 py-2">
                            {schemeName ===
                            'Funeral Expense and Death Assistance'
                              ? getSchemeStatusDetails[0].Applicant_name_funeral
                              : getSchemeStatusDetails[0].death_status === 1
                                ? getSchemeStatusDetails[0]
                                    .Applicant_name_accident
                                : getUserDetails &&
                                  getUserDetails.personalDetails &&
                                  `${
                                    getUserDetails.personalDetails[0]
                                      .first_name ?? ''
                                  }${
                                    getUserDetails.personalDetails[0]
                                      .middle_name ?? ''
                                  } ${
                                    getUserDetails.personalDetails[0]
                                      .last_name ?? ''
                                  }`}
                          </td>
                        </tr>
                        {(schemeName ===
                          'Funeral Expense and Death Assistance' ||
                          (schemeName === 'Accident Assistance' &&
                            getSchemeStatusDetails[0].death_status === 1)) && (
                          <>
                            <tr className="table-row-pdf-docuemnts">
                              <td className="px-5 py-2">
                                ಫಲಾನುಭವಿ/ಮೃತ ಕಾರ್ಮಿಕನ ಹೆಸರು /
                                Beneficiary/Deceased worker's name
                              </td>
                              <td className="px-5 py-2">
                                {getUserDetails &&
                                  getUserDetails.personalDetails &&
                                  `${
                                    getUserDetails.personalDetails[0]
                                      .first_name ?? ''
                                  } ${
                                    getUserDetails.personalDetails[0]
                                      .middle_name ?? ''
                                  } ${
                                    getUserDetails.personalDetails[0]
                                      .last_name ?? ''
                                  }`}
                              </td>
                            </tr>
                          </>
                        )}
                      </table>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      <p style={{ textAlign: 'center' }}>
                        {/* {getSchemeDetails &&
                          getSchemeDetails.schemeDetails1.scheme_name}{' '} */}
                        {schemeName === 'Delivery Assistance'
                          ? 'ಹೆರಿಗೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                          : schemeName === 'Medical Assistance'
                            ? 'ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                            : schemeName === 'Marriage Assistance'
                              ? 'ಮದುವೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                              : schemeName === 'Assistance For Major Ailments'
                                ? 'ಪ್ರಮುಖ ವೈದ್ಯಕೀಯ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                : schemeName === 'Pension Scheme'
                                  ? 'ಪಿಂಚಣಿ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                  : schemeName === 'Continuation of Pension'
                                    ? 'ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ ಕೋರಿ ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                    : schemeName === 'Disability Pension'
                                      ? 'ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                      : schemeName ===
                                          'Continuation of Disability Pension'
                                        ? 'ದುರ್ಬಲತೆ ಪಿಂಚಣಿ ಮುಂದುವರಿಕೆ ಕೋರಿ ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                        : schemeName ===
                                              'Accident Assistance' &&
                                            getSchemeStatusDetails[0]
                                              .death_status === 1
                                          ? 'ಅಪಘಾತ ಮರಣ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                          : schemeName ===
                                                'Accident Assistance' &&
                                              getSchemeStatusDetails[0]
                                                .death_status === 2
                                            ? 'ಶಾಶ್ವತ ಭಾಗಶ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                            : schemeName ===
                                                  'Accident Assistance' &&
                                                getSchemeStatusDetails[0]
                                                  .death_status === 3
                                              ? 'ಶಾಶ್ವತ ಸಂಪೂರ್ಣ ದುರ್ಬಲತೆ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                              : schemeName ===
                                                  'Funeral Expense and Death Assistance'
                                                ? 'ಅಂತ್ಯಕ್ರಿಯೆ ವೆಚ್ಚ ಮತ್ತು ಅನುಗ್ರಹರಾಶಿ ಸಹಾಯಧನ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                                : schemeName ===
                                                    'Thayi Magu Sahaya Hasta'
                                                  ? 'ತಾಯಿ ಮಗು ಸಹಾಯ ಹಸ್ತ ಪಡೆಯಲು ಸಲ್ಲಿಸಿದ್ದ ನಿಮ್ಮ ಅರ್ಜಿಯನ್ನು ಈ ಕೆಳಕಂಡ ಕಾರಣಗಳಿಂದ ತಿರಸ್ಕರಿಸಲಾಗಿದೆ'
                                                  : ''}
                      </p>
                    </div>
                    <div className="">
                      {reasons && reasons.length > 0 ? (
                        reasons.map((reason, index) => (
                          <div
                            style={{
                              fontSize: '15px',
                              textAlign: 'justify',
                              width: '75%',
                              margin: '0 15%',
                            }}
                          >
                            {toggleLang ? (
                              <>
                                {`${index + 1}. ${
                                  reason.rejection_reason_kannada
                                }`}
                              </>
                            ) : (
                              <>{`${index + 1}. ${reason.rejection_reason}`}</>
                            )}
                          </div>
                        ))
                      ) : (
                        <div
                          style={{
                            fontSize: '15px',
                            textAlign: 'justify',
                            width: '75%',
                            margin: '0 15%',
                          }}
                        >
                          1.{getSchemeDetails?.schemeDetails1?.order_remarks}
                        </div>
                      )}
                    </div>
                    <hr />
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="px-5">
                        <p>
                          ವೃತ್ತದ ಹೆಸರು / Circle Name :{' '}
                          {getUserDetails &&
                            getUserDetails.certificateDetails &&
                            getUserDetails.certificateDetails[0]
                              .inspector_circle}{' '}
                        </p>
                        <p>
                          ನಿರಾಕರಣೆಯ ದಿನಾಂಕ / Date of Rejection :{' '}
                          {getSchemeDetails &&
                            moment(
                              getSchemeDetails.schemeDetails1
                                .approved_rejected_date,
                            ).format('DD/MM/YYYY')}
                        </p>
                      </div>

                      <div className="d-flex justify-content-center align-items-center flex-column px-5">
                        <div className="signature-img-div">
                          <img
                            src={`data:image/png;base64, ${
                              signature && signature.base_64_content
                            }`}
                            width={150}
                            height={70}
                            alt="..."
                          />
                        </div>
                        <div>
                          <p className="text-center">
                            {schemeName === 'Continuation of Pension' ||
                            schemeName === 'Pension Scheme' ||
                            schemeName === 'Accident Assistance' ||
                            schemeName === 'Disability Pension' ||
                            schemeName ===
                              'Continuation of Disability Pension' ||
                            schemeName ===
                              'Funeral Expense and Death Assistance'
                              ? 'ಸಹಾಯಕ ಕಾರ್ಮಿಕ ಆಯುಕ್ತರು / Signatue of ALC'
                              : 'ಕಾರ್ಮಿಕ ಅಧಿಕಾರಿ ಸಹಿ / Signature of LO'}
                          </p>
                          <p
                            className="text-center"
                            style={{ fontSize: '14px' }}
                          >
                            {getSchemeDetails &&
                              getSchemeDetails.schemeDetails1.address}
                          </p>
                        </div>
                      </div>
                    </div>

                    {displayDate && (
                      <div className="d-flex justify-content-end align-items-center px-3">
                        <p style={{ fontSize: '14px' }}>
                          Printed on:{currentDate}
                        </p>
                      </div>
                    )}
                  </>
                ) : (
                  <SimpleBackdrop />
                )}
              </div>
            </div>
            <div className="d-flex justify-content-center align-items-start">
              <IconButton onClick={generatePDF}>
                <DownloadIcon />
              </IconButton>
            </div>
          </div>
        </Paper>
      </Box>
    </>
  );
};

export default SchemesRejectionOrder;
