import {
  InputAdornment,
  ListItemText,
  MenuItem,
  Select,
} from '@material-ui/core';
import axios from 'axios';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { SERVER, getSchemesOnStatus } from 'store/actions/user.actions';
import { getTokenCookie, showToast } from 'utils/tools';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import SimpleBackdrop from 'components/loadingScreen/Backdrop';
import CancelIcon from '@mui/icons-material/Cancel';
import TableComponent from 'components/TableComponent';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom';

const NomineeFilters = ({
  status,
  scheme,
  schemeID,
  setSchemeID,
  statusID,
  setStatusID,
  handleSubmit,
}) => {
  return (
    <div className="">
      {/* <h3 className="text-center">Schemes Details</h3> */}
      <div className="d-flex justify-content-center align-items-center flex-column gap-1">
        <Row className="w-100">
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={statusID || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setStatusID(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Status" />
              </MenuItem>
              {status &&
                status.length &&
                status.map(
                  (data) =>
                    (data.value === 'Pending' ||
                      data.value === 'Rejected' ||
                      data.value === 'Approved') && (
                      <MenuItem value={data.value_id}>
                        <ListItemText primary={data.value} />
                      </MenuItem>
                    ),
                )}
            </Select>
          </Col>
          <Col xs={12} md={3}>
            <Select
              className="form-control w-100 h-75 py-2"
              style={{ border: '1px solid grey' }}
              labelId="demo-simple-select-required-label"
              id="demo-simple-select-required"
              value={schemeID || '0'}
              name="circleActiveLi"
              displayEmpty
              onChange={(ev) => setSchemeID(ev.target.value)}
              defaultValue={'0'}
            >
              <MenuItem value={'0'}>
                <ListItemText primary="Select Schemes" />
              </MenuItem>
              {scheme &&
                scheme.length &&
                scheme.map(
                  (data) =>
                    (data.name === 'Accident Assistance' ||
                      data.name === 'Funeral Expense and Death Assistance') && (
                      <MenuItem value={data.id}>
                        <ListItemText primary={data.name} />
                      </MenuItem>
                    ),
                )}
            </Select>
          </Col>

          <Col xs={12} md={2}>
            <Button
              onClick={handleSubmit}
              className="w-100 btn btn-success py-2"
            >
              Search
            </Button>
          </Col>
        </Row>
        <Row className="w-100">
          <Col xs={12} md={6}></Col>
        </Row>
      </div>
      {/* {loading && <SimpleBackdrop />}
      {!loading && schemesList && schemesList.length ? (
        <TableComponent schemesList={schemesList} schemeStatus={appStatus} />
      ) : !loading && schemesList && schemesList ? (
        <p className="text-center fs-3">No Applications Found</p>
      ) : null} */}
    </div>
  );
};

export default NomineeFilters;
